import { useEffect, useState } from "react";
import { inject, observer } from 'mobx-react';
import { Box, styled } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { useGetBookDraftBuildRequests } from "../../../managers/ExportsManager";
import { Avatar } from "@mui/material";
import ReactTimeAgo from 'react-time-ago'
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import StandardUIDialog from "../../UI/StandardUIDialog";
import StandardUIButton from "../../UI/StandardUIButton";
import StandardUICheckbox from "../../UI/StandardUICheckbox";
import StretchBox from '../../boilerPlate/StretchBox';
import ScrollBox from '../../boilerPlate/ScrollBox';


function ExportBook(props) {

    let [buildTypes, setBuildTypes] = useState({});
    let { bookDraftId } = props;

    const { buildRequests, loading, error, client } = useGetBookDraftBuildRequests(props, bookDraftId);

    const CREATE_BOOK = gql` mutation RequestbuildBatch(
        $bookDraftId: String!,
        $buildTypes: [String]!
    ) {
        requestbuildBatch(input: {
            bookDraftId: $bookDraftId, 
            buildTypes: $buildTypes
        }) {
            id
            ownerId
            buildTypes
            completedAt
            createdAt

        }
    }`;


    const [
        requestbuildBatch,
        { data: mutationData, loading: mutationLoading, error: mutationError }] =
        useMutation(CREATE_BOOK);



    const GET_PRESIGNED_URL = gql`
    query getPresignedUrl($key: String!) {
      getPresignedUrl(key: $key)
              }
    `;


    useEffect(() => {

        //console.log(buildRequests)

    }, [buildRequests]);

    const theme = useTheme();

    return (

        <StandardUIDialog
            open={props.open}
            onClose={props.onClose}
            bookDraftId={props.bookDraftId}
            title='Export Book'
            dialogContent={
                <StretchBox >
                    <ScrollBox >
                        <Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-around'
                                }}>


                                    <StandardUICheckbox label={'Generic'} checked={buildTypes.epub} onChange={(e) => {

                                        let newValues = buildTypes;
                                        buildTypes.epub = e.target.checked;
                                        setBuildTypes(buildTypes);
                                        //console.log(buildTypes);
                                    }} />
                                    <StandardUICheckbox label={'Kindle'} checked={buildTypes.kindle} onChange={(e) => {

                                        let newValues = buildTypes;
                                        buildTypes.kindle = e.target.checked;
                                        setBuildTypes(buildTypes);
                                        //console.log(buildTypes);
                                    }} />
                                    <StandardUICheckbox label={'Google'} checked={buildTypes.googlePlay} onChange={(e) => {

                                        let newValues = buildTypes;
                                        buildTypes.googlePlay = e.target.checked;
                                        setBuildTypes(buildTypes);
                                        //console.log(buildTypes);
                                    }} />
                                    <StandardUICheckbox label={'iBooks'} checked={buildTypes.iBooks} onChange={(e) => {

                                        let newValues = buildTypes;
                                        buildTypes.iBooks = e.target.checked;
                                        setBuildTypes(buildTypes);
                                        //console.log(buildTypes);
                                    }} />
                                </Box>


                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-around'
                                }}>



                                    <StandardUICheckbox label={'Kobo'} checked={buildTypes.kobo} onChange={(e) => {

                                        let newValues = buildTypes;
                                        buildTypes.kobo = e.target.checked;
                                        setBuildTypes(buildTypes);
                                        //console.log(buildTypes);
                                    }} />
                                    <StandardUICheckbox label={'Nook'} checked={buildTypes.nook} onChange={(e) => {

                                        let newValues = buildTypes;
                                        buildTypes.nook = e.target.checked;
                                        setBuildTypes(buildTypes);
                                        //console.log(buildTypes);
                                    }} />
                                    <StandardUICheckbox disabled label={'Print'} checked={buildTypes.print} onChange={(e) => {

                                        let newValues = buildTypes;
                                        buildTypes.print = e.target.checked;
                                        setBuildTypes(buildTypes);
                                        //console.log(buildTypes);
                                    }} />
                                    <StandardUICheckbox disabled label={'Docx'} checked={buildTypes.docx} onChange={(e) => {

                                        let newValues = buildTypes;
                                        buildTypes.docx = e.target.checked;
                                        setBuildTypes(buildTypes);
                                        //console.log(buildTypes);
                                    }} />
                                </Box>
                            </Box>
                        </Box>
                    </ScrollBox>
                </StretchBox>
            }
            cardActions={
                <StandardUIButton
                    buttonAction='Export'
                    onClick={(e) => {


                        e.preventDefault();

                        let actualBuildTypes = [];
                        Object.keys(buildTypes).forEach((key) => {

                            if (buildTypes[key] == true) {
                                actualBuildTypes.push(key);
                            }
                        });

                        //console.log(actualBuildTypes);
                        requestbuildBatch({
                            variables: {
                                bookDraftId: bookDraftId,
                                //buildTypes: ["epub","nook"],
                                buildTypes: actualBuildTypes
                            },
                            refetchQueries: ['listUserBuildRequests']

                        });


                    }}
                />
            }
        />

    );
}

export default inject('stores')(observer(ExportBook));