import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import AttrType from './AttrType';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';

const ItemTypeDetail = (props) => {

	let { uiState } = props;
	const { universe, itemType } = props;


	let attrTypeItems = [];
	try {
		attrTypeItems = itemType.itemTypeAttrTypes.map((m) => {

			return <ListItem>
				<ListItemAvatar>
					<Avatar>
						<ImageIcon />
					</Avatar>
				</ListItemAvatar>
				<AttrType attrType={m.attrType} />
			</ListItem>


		});

	} catch (err) {
		console.log(err);
	}

	const onDragEnd = () => {

	}

	// <DraggableList items={attrTypeItems} onDragEnd={onDragEnd} />

	return (
		<div>
			<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

				{attrTypeItems}

			</List>
		</div>
	);
};


export default inject('stores')(observer(ItemTypeDetail));