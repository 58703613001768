import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LaurasButton from './LaurasButton';
import TextField from '@mui/material/TextField';
import LaurasTextField from './LaurasTextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const LaurasDialog = (props) => {


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));



    return (
        <Dialog
            // sx={{
            //     "& .MuiDialog-container": {
            //         "& .MuiPaper-root": {
            //             width: "100%",
            //             maxWidth: "500px",  // Set your width here
            //         },
            //     },
            // }}
            open={props.open}
            onClose={props.onClose}>
            <div
                style={{ display: 'flex', justifyContent: 'right' }}>
                <IconButton
                    onClick={props.onClose}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogTitle
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1em 0' }}
                variant={theme.landmarkText.variant}
            >
                {props.title}
            </DialogTitle>
            <DialogContent
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}
            >
                <Card
                    variant="outlined"
                    style={{ marginBottom: '3em' }}
                >
                    <CardContent
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <UploadFileIcon fontSize='large' />
                        <Typography variant="body2">
                            Choose a file or drag and drop here
                        </Typography>
                    </CardContent>
                </Card>
                <LaurasTextField label='Title' />
                <LaurasTextField label='Subtitle' />
                <LaurasTextField label='Author' />
            </DialogContent>
            <CardActions
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
            >
                <LaurasButton
                    buttonAction='Create Book'
                />
            </CardActions>
        </Dialog>
    )
}
export default inject('stores')(observer(LaurasDialog));