import React, { useState, useEffect } from "react";
import { Grid, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import config from '../../../aws-exports';
import { useManagerGetUserGroupUsers } from '../../../managers/ConnectionsManager';
import ReactTimeAgo from 'react-time-ago';
import EmptyContent from "../../EmptyContent";
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const ListGroupMembers = (props) => {

    let { userGroup, showRemoveIcon = true } = props;

    const { users, loading: listUserGroupUsersLoading, error: listUserGroupUsersError } = useManagerGetUserGroupUsers(props, userGroup?.id);


    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');

    let [showCreateBookDialog, setShowCreateBookDialog] = useState(false);
    let [showDeleteBookDialog, setShowDeleteBookDialog] = useState(false);
    let [selectedBook, setSelectedBook] = useState(null);


    const isRoute = () => {
        return 'primary';
    }


    const theme = useTheme();



    const generateSecondaryActions = (user) => {

        const secondaryActions = [];

        if (showRemoveIcon) {
            secondaryActions.push(<IconButton
                onClick={(e) => {
                    e.stopPropagation();
                }}>
                <RemoveCircleOutlineIcon />
            </IconButton>)
        }

        return secondaryActions;
    }



    return (
        <div>
            <List>
                {users && users.length > 0 ?
                    users.map((user) => {


                        return <ListItem
                            secondaryAction={
                                generateSecondaryActions(user)
                            }
                        > <ListItemAvatar>
                                <Avatar variant="rounded"
                                    // bucketName={config.user_uploads_bucket_name}
                                    s3Src={user?.profilePicUrl}
                                >
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={user?.name}
                                secondary='Member Since'
                            />
                        </ListItem>


                    }

                    )
                    :
                    <EmptyContent tagline={'Members of this group will appear here!'} />
                }
            </List>
        </div>
    )
}

export default inject('stores')(observer(ListGroupMembers));