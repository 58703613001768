import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import StandardTabs from "../StandardTabs";
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton, { StandardListButton } from "../StandardButton";
import UserConnectionsList from "../lists/UserConnectionsList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../managers/BooksManager';
import UserGroupsList from "../lists/UserGroupsList";
import { useManagerAddBoxsetContributor, useManagerAddBoxsetContributedBook, useManagerLoadBoxSetContributors } from '../../managers/BoxsetsManager';
import { User } from "../../domain/bookModel";
import OwnedBooksList from "../lists/OwnedBooksList";
import { useManagerShareBookWithUserGroup } from "../../managers/ConnectionsManager";

const AddGroupSharePanel = (props) => {

  let user = props.stores.userStore.user;
  let { userGroup } = props;

  let { shareBookWithUserGroup, loading: shareBookWithUserGroupLoading, error: shareBookWithUserGroupError} = useManagerShareBookWithUserGroup(props);


  // let [boxsetBooks, setBoxsetBooks] = useState([]);

  // const { addBoxSetContributedBook, loading: addBoxSetContributedBookLoading, error: addBoxSetContributedBookError } = useManagerAddBoxsetContributedBook(props);
  // const { addBoxsetContributor, loading: createBoxSetContributorLoading, error: createBoxSetContributorError } = useManagerAddBoxsetContributor(props);

  // let {
  //   boxsetContributors,
  //   loading: lazyLoadBoxSetContributorsLoading,
  //   error: lazyLoadBoxSetContributorsError
  // } = useManagerLoadBoxSetContributors(props, boxset?.id);


  // useEffect(() => {

  //   try {

  //     let bookData = [];
  //     boxsetContributors.forEach((boxsetContributor)=>{

  //       boxsetContributor.contributedBooks.items.forEach((bsb)=>{

  //         bookData.push({
  //           boxsetContributor,
  //           boxsetBook:bsb
  //         }) 
  //       })

  //     });

  //     setBoxsetBooks(bookData);

  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, [boxsetContributors]);

  return (
    <Grid container direction={'column'}>
      <Grid item>
        <OwnedBooksList
          filteredBooks={[]}
          showSelectIcon
          onSelected={(book) => {

            if (book) {
              shareBookWithUserGroup({
                bookId: book.id,
                userGroupId: userGroup.id,
              }, () => {
             
                //onSuccess
                if(props.onGroupShareCreated){
                  props.onGroupShareCreated();
                }
  
              }, () => {
               
                //onError
  
              })
            }

          }}
        />
      </Grid>
     
    </Grid>
  )
}

export default inject('stores')(observer(AddGroupSharePanel));