import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import QuillEditor from './QuillEditor';
import Delta from 'quill-delta';
import { Box, Paper } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { useAddBookDraftDocumentPart, useLazyGetDocumentPart, useTransferEditsToOriginalBatch } from '../../persistance/persistanceHooks';
import FullpageImageEditor from './FullpageImageEditor';
import { useTheme } from '@mui/material/styles';

const WordCount = (props) => {

    const theme = useTheme();

    let { bookDraftDocumentPart, readOnly = false, userComments = [], disabled } = props;


    let userId = props.stores?.bookStore.userId;

 


    return (    
        <Box container direction={'column'} sx={{
            justifyContent: 'flex-start',
            marginRight:'1em'
        }}>
            {!readOnly &&

                <Box item sx={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                }}>

                    <Typography variant="h7" sx={{
                        color:theme.palette.write.bookTitle.color
                    }}>{bookDraftDocumentPart.documentPart.totalNumWords} parent words:{bookDraftDocumentPart.documentPart.getCalculatedWords()}</Typography>

                </Box>
            }
            {!readOnly &&

                <Box item sx={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                }}>
                    <Typography  variant="h7" sx={{
                        color:theme.palette.write.bookTitle.color
                    }}>{bookDraftDocumentPart.documentPart.numWords} words</Typography>
                </Box>

            }
        </Box>
    );
};


export default inject('stores')(observer(WordCount));
