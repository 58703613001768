import React, { useEffect, useState } from 'react';
import { Box, ListItemIcon, styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { Button } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { useNavigate, useLocation } from "react-router-dom";
import SortIcon from '@mui/icons-material/Sort';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export const StandardListHeader = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();




  const selectRoute = (route) => {

    navigate(route);
    props.onRouteSelected();

  }

  const options = [
    <MenuItem
      onClick={(e) => {
        props.stores.bookStore.sortOrder = 'asc';  //'asc','desc'
        props.stores.bookStore.sortOrderBy = 'alpha'; // 'alpha', 'createdAt'
      }}>
      A <ArrowForwardIcon /> Z
    </MenuItem>,
    <MenuItem onClick={(e) => {
      props.stores.bookStore.sortOrder = 'desc';  //'asc','desc'
      props.stores.bookStore.sortOrderBy = 'alpha'; // 'alpha', 'createdAt'
    }}>
      Z <ArrowForwardIcon /> A
    </MenuItem>,
    <MenuItem onClick={(e) => {
      props.stores.bookStore.sortOrder = 'desc';  //'asc','desc'
      props.stores.bookStore.sortOrderBy = 'createdAt'; // 'alpha', 'createdAt'
    }}>
      Newest
    </MenuItem>,
    <MenuItem onClick={(e) => {
      props.stores.bookStore.sortOrder = 'asc';  //'asc','desc'
      props.stores.bookStore.sortOrderBy = 'createdAt'; // 'alpha', 'createdAt'
    }}>
      Oldest
    </MenuItem>
  ];



  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingLeft: '1em',
      paddingRight: '1em',
      //border:'1px solid red'

    }}>

      <Box sx={{
        flex: 2,
        width: '100%',
        //border:'1px solid red'
      }} >
        {props.showBackArrow &&
          <ListItemIcon onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            selectRoute(props.backRoute);
          }}>
            <ArrowBackSharpIcon sx={{ color: props.color }} />
          </ListItemIcon>
        }

      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        flex: 8

      }}>

        <Typography sx={{ color: props.color }} variant={'h5'} noWrap>{props.title}</Typography>
        <Typography sx={{ color: props.color }} variant={'caption'}>{props.subtitle}</Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: "flex-end",
        flex: 2,
        width: '100%',
        //border:'1px solid red'
      }}>

        {!props.hideSort &&
          <SortIcon sx={{ color: props.color }} onClick={(e) => {
            e.preventDefault();
            //console.log(book);
            handleClickListItem(e);
            // props.stores.bookStore.sortOrder = 'desc';  //'asc','desc'
            // props.stores.bookStore.sortOrderBy = 'alpha'; // 'alpha', 'createdAt'
          }} />
        }
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
          {/* <MenuItem
            selected={index === selectedIndex}
            onClick={(e) => {
              props.stores.bookStore.sortOrder = 'asc';  //'asc','desc'
              props.stores.bookStore.sortOrderBy = 'alpha'; // 'alpha', 'createdAt'
            }}>
            A <ArrowForwardIcon /> Z
          </MenuItem>
          <MenuItem onClick={(e) => {
            props.stores.bookStore.sortOrder = 'desc';  //'asc','desc'
            props.stores.bookStore.sortOrderBy = 'alpha'; // 'alpha', 'createdAt'
          }}>
            Z <ArrowForwardIcon /> A
          </MenuItem>
          <MenuItem onClick={(e) => {
            props.stores.bookStore.sortOrder = 'desc';  //'asc','desc'
            props.stores.bookStore.sortOrderBy = 'createdAt'; // 'alpha', 'createdAt'
          }}>
            Newest
          </MenuItem>
          <MenuItem onClick={(e) => {
            props.stores.bookStore.sortOrder = 'asc';  //'asc','desc'
            props.stores.bookStore.sortOrderBy = 'createdAt'; // 'alpha', 'createdAt'
          }}>
            Oldest
          </MenuItem> */}
        </Menu>

      </Box>

    </Box>

  )
}


export default inject('stores')(observer(StandardListHeader));