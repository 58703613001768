import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import config from '../../aws-exports';
import StandardButton from '../StandardButton';

const ButtonTableCell = (props) => {


  

  return (
   
    <StandardButton {...props}>
      {props.children}
    </StandardButton> 

  )
}
export default inject('stores')(observer(ButtonTableCell));