import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import StandardButton from '../StandardButton';
import StandardDialog from '../StandardDialog';
import CreateSnapshotPanel from '../panels/CreateSnapshotPanel';
import ExportDialog from '../panels/ExportDialog';

const CreateExportsAssembly = (props) => {
    
    let [showExportDialog, setShowExportDialog] = useState(false);
    
    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    const theme = useTheme();

    return (
        <>
            <Box sx={{
                display: 'flex',
                //justifyContent: 'flex-end',
                //width: '100%',
                //border:'1px solid yellow'
            }}>
                <Button variant="contained" sx={{
                    color: '#ffffff',
                    backgroundColor: theme.palette.secondary.main
                }}
                onClick={(e)=>{
                    e.preventDefault();
                    setShowExportDialog(true);
                }}>Export</Button>
                {/* <StandardButton variant="contained" sx={{
                    color: '#ffffff',
                    backgroundColor: theme.palette.secondary.main
                }}
                >Export</StandardButton> */}

            </Box>

            <StandardDialog
                open={showExportDialog}
                onClose={() => {
                    setShowExportDialog(false)
                }}
                onOk={() => {


                }}
                title={'Export'}
                dialogContent={
                    // <ExportDialog bookDraftId={props.book?.primaryBookDraftId} />
                    <ExportDialog bookDraftId={props.bookDraftId} />
                }
            >
                {/* <CreateSnapshotPanel book={props.book} /> */}
            </StandardDialog>
        </>


    )
}

export default inject('stores')(observer(CreateExportsAssembly));