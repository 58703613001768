import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Feather from 'mdi-material-ui/Feather';
import IconButton from '@mui/material/IconButton';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ListItemButton from '@mui/material/ListItemButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const StandardUISquareList = (props) => {

    let {  bucketName, s3Src, title, details, showRestoreIcon = false, showWriteIcon = true, showFormatIcon = true, showReadIcon = false } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    let srcImg = 'https://' + bucketName + ".s3.amazonaws.com/" + s3Src;

    const secondaryActions = [];

    if (showWriteIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <Feather />
        </IconButton>)
    }

    if (showFormatIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <FormatPaintIcon />
        </IconButton>)
    }

    if (showReadIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <ManageSearchIcon />
        </IconButton>)
    }

    if (showRestoreIcon) {
        secondaryActions.push(<IconButton
            onClick={props.onRestoreClick}>
            <MoreVertIcon />
        </IconButton>)
    }


    return (
        <List>
            {isSmallScreen ?
                <ListItemButton
                    sx={{ paddingLeft: ' 0.25em' }}
                    onClick={props.onClick}
                    selected={props.selected}
                >
                    <ListItem
                        secondaryAction={
                            secondaryActions
                        }
                    >
                        <ListItemAvatar>
                            <Avatar variant="rounded"
                                src={s3Src}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={props.primary}
                            secondary={props.secondary}
                        />
                    </ListItem>
                </ListItemButton>

                :
                <ListItemButton
                    onClick={props.onClick}
                    selected={props.selected}
                >
                    <ListItem
                        secondaryAction={
                            secondaryActions
                        }
                    >
                        <ListItemAvatar>
                            <Avatar variant="rounded"
                                src={s3Src}>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={props.primary}
                            secondary={props.secondary}
                        />
                    </ListItem>
                </ListItemButton>
            }

        </List>
    )
}
export default inject('stores')(observer(StandardUISquareList));