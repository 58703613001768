import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Task from './Task';

// const Container = styled.div`
//   margin: 8px;
//   border: 1px solid lightgrey;
//   border-radius: 2px;
// `;
// const Title = styled.h3`
//   padding: 8px;
// `;
// const TaskList = styled.div`
//   padding: 8px;
// `;

export default class Column extends React.Component {
    render() {
        return (
            <div>
                <div>{this.props.column.title}</div>
                <Droppable
                    droppableId={this.props.column.id}
                    type="COLUMN">
                    {(provided, snapshot) => {
                        return (
                            <div ref={provided.innerRef} innerRef={provided.innerRef} {...provided.droppableProps}>
                                {this.props.tasks.map((task, index) => (
                                    <Task key={task.id} task={task} index={index} type="TASK" />
                                ))}
                                {provided.placeholder}
                            </div>
                        )
                    }
                    }
                </Droppable>
            </div>
        );
    }
}
