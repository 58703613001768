import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';


const StandardUIHelpLabel = (props) => {

    // let helpUrlSuffix = '';

    // if(props.helpContent){
    //     helpUrlSuffix = props.helpContent;
    // }

    const theme = useTheme();


    return (
        <div style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',

        }}>
            {/* <Typography
                variant="h7"
                component="div"
                sx={{
                    // textTransform: "capitalize",
                    // fontWeight: 'bold',
                }}>{props.title}</Typography> */}
            {props.title}
            <IconButton sx={{ marginLeft: '0.5em', justifyContent: 'center', alignItems: 'center' }} >
                <InfoOutlinedIcon sx={{ color: 'lightgrey'}} />
            </IconButton>
        </div>
    );
}

export default inject('stores')(observer(StandardUIHelpLabel));