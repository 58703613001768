import { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import App from './App';
import { Amplify } from 'aws-amplify';
import { createAuthLink } from 'aws-appsync-auth-link';
// import { persistCache, CachePersistor } from "apollo-cache-persist";
import { persistCache, LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import localforage from 'localforage';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  useQuery,
  HttpLink,
  createHttpLink,
  gql
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import SerializingLink from 'apollo-link-serialize';
import QueueLink from 'apollo-link-queue';
import config from './aws-exports';
import { Auth } from 'aws-amplify'
/**
 * Following along with the
 * tutorial https://ui.docs.amplify.aws/react/guides/auth-protected?
 * Update this import to match the path to your aws-exports.js file:
 * import aws_exports from "./aws-exports";
 */
import aws_exports from './aws-exports';

import './index.css';
import { NestedListComponent2 } from './components/tree/DNDTree/NestedListComponent2';
import { AppContextProvider } from './AppContext';
import apollo from "./apolloClient";
import helpers from './helpers/helpers';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Button } from '@mui/material';
//import apollo from "./apolloClientWora";

const container = document.getElementById('root');
const root = createRoot(container);

Amplify.configure(aws_exports);

const AWS = require("aws-sdk");

let bucketRegion = config.user_uploads_bucket_region;//"us-east-1";
let IdentityPoolId = config.identity_pool_id

AWS.config.update({
  region: bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId
  })
});

//let persistor = null;

function ApolloWrapper(props) {

  let [client, setClient] = useState();
  //const [toaster, setToaster] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  useEffect(() => {


    let init = async function () {

      apollo.persistor.restore();
      setClient(apollo.client);


      let processNextMutation = () => {

        let pendingMutations = JSON.parse(localStorage.getItem('pendingMutations')) || [];
        pendingMutations = helpers.mutationSort(pendingMutations, 'ASC',);

        /*
        if (pendingMutations.length > 0) {

          let mutationData = pendingMutations[0];
          client.mutate({
            mutation: JSON.parse(mutationData.query),
            variables: JSON.parse(mutationData.variables),
            context: {
              isPersistRetry: true
            }

          })
            .then((obj) => {

              console.log(obj);

              //Mutation executed successfully, remove from pending list
              const updatedPendingMutations = pendingMutations.filter(
                (item) => item !== mutationData
              );
              localStorage.setItem('pendingMutations', JSON.stringify(updatedPendingMutations));
              processNextMutation();
            })
            .catch((err) => {

              console.log(err);

            });

        }
        */

      };

      processNextMutation();



      /*
      const pendingMutations = JSON.parse(localStorage.getItem('pendingMutations')) || [];

      // name:operation.operationName,
      // query:JSON.stringify(operation.query),
      // variables:JSON.stringify(operation.variables),
      // context:JSON.stringify(savedContext),
      // id:uuid.v1()

      pendingMutations = helpers.mutationSort(pendingMutations, 'ASC', );

      pendingMutations.forEach(async (mutationData) => {
        //console.log(mutationData);

        
        try {


          // await apollo.client.mutate({
          //   mutation: JSON.parse(mutationData.query),
          //   variables: JSON.parse(mutationData.variables),
          //   context:{
          //     isPersistRetry:true
          //   }

          // })
          // .then((obj)=>{

          //   console.log(obj);

          // //Mutation executed successfully, remove from pending list
          // const updatedPendingMutations = pendingMutations.filter(
          //   (item) => item !== mutationData
          // );
          // localStorage.setItem('pendingMutations', JSON.stringify(updatedPendingMutations));

          // })
          // .catch((err)=>{

          //   console.log(err);

          // });
    
          

        } catch (err) {
          // Handle mutation execution error
          console.log(err)
        }
        




      })

*/


    };

    init();

  }, []);

  if (!client) {

    return <div></div>
  }


  let contextValues = {
    persistor: apollo.persistor,
    client: apollo.client,
    cache: apollo.cache,
    //toaster:enqueueSnackbar,
    //setToaster:setToaster
  }


 

  return (

    <ApolloProvider client={client}>
      {/* <NestedListComponent2 /> */}
      {/* {props.children} */}
      <StrictMode>
        <AppContextProvider value={contextValues}>
          <SnackbarProvider autoHideDuration={2000}>
            <App />
          </SnackbarProvider> 
        </AppContextProvider>
      </StrictMode>
    </ApolloProvider>

  );
}

// root.render(<ApolloWrapper>
//   <StrictMode>
//     <App />
//   </StrictMode>
// </ApolloWrapper>);

root.render(<ApolloWrapper />);
