import React, { useEffect, useState } from 'react';
import { styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import BaseControl from "./BaseControl";
import { useTheme } from '@mui/material/styles';
import { Box, } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormatImageSelector from './FormatImageSelector';
import TemplateSelector from './TemplateSelector';

function FormatImageSourceSelector(props) {

  const theme = useTheme();
  const [options, setOptions] = useState([]);

  let baseControl = new BaseControl(props);

  const { classes, element, bookDraft, documentPart } = props;


  let canEdit = true;


  let isOwner = true;

  let title = baseControl.convertTitle(element.title, documentPart);

  const label = { inputProps: { 'aria-label': title } };

  let value = baseControl.getValue();

  let item = baseControl.getEntityItem();

  useEffect(() => {

    setOptions(element.templateSelectors.map((templateSelector) => {

      return {
        value: templateSelector.key,
        label: templateSelector.label,
        subLabel: templateSelector.subLabel,
        selectorConfig: templateSelector
      }

    }))
  }, [element, bookDraft, documentPart, documentPart?.bookDraftDocumentPart.getBoxSetBook()?.recipeSource]);


  let isVisible = baseControl.isVisible();
  if (!isVisible) {
    return <div style={{
      height: '0em'
    }}></div>
  }


  // let options = element.templateSelectors.map((templateSelector) => {

  //   return {
  //     value: templateSelector.key,
  //     label: templateSelector.label,
  //     selectorConfig: templateSelector
  //   }

  // })

  return (

    <Box sx={{
      displayx: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      //border:'1px solid blue'
    }}>

      {/* <div><Typography>FormatImageSourceSelector: {title}</Typography></div> */}


      <FormControl sx={{
        width: '100%',
        //border:'3px solid green'

      }}>
        <FormLabel >{title}</FormLabel>
        <RadioGroup
          row
          onChange={(e) => {
            let newValue = e.target.value;
            baseControl.updateValue({
              value: newValue
            });
          }}

          value={value}
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            //border:'3px solid yellow'
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            //border: '2px solid black',
            alignItems: 'flex-start'
          }}>
            {options.map((option) => {
              return <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'flex-start',
                //border:'3px solid purple',
                alignItems: 'center'
              }}>
                <FormControlLabel value={option.value} control={<Radio color={'secondary'} />} label={baseControl.convertTitle(option.label, documentPart)} />
                <Typography variant={'h7'} color={'gray'}>{baseControl.convertTitle(option.subLabel, documentPart)}</Typography>


                {/* <TemplateSelector
                  key={option.selectorConfig.recipeParamName}
                  bookDraft={bookDraft}
                  documentPart={documentPart}
                  element={option.selectorConfig}
                  canEdit={canEdit}
                  onUpdateRecipe={props.onUpdateRecipe}
                  onUpdateEntity={props.onUpdateEntity} /> */}


              </Box>


            })}
          </Box>


        </RadioGroup>
        {options.map((option) => {
          if(option.value===value){
            return <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              border: '2px solid black',
              alignItems: 'flex-start',
              justifyContent:'center'
            }}>
              <TemplateSelector
                key={option.selectorConfig.recipeParamName}
                bookDraft={bookDraft}
                documentPart={documentPart}
                element={option.selectorConfig}
                canEdit={canEdit}
                onUpdateRecipe={props.onUpdateRecipe}
                onUpdateEntity={props.onUpdateEntity} />
              
            </Box>
          }
          




        })}
      </FormControl>





    </Box>


  );
}


export default inject('stores')(observer(FormatImageSourceSelector));



