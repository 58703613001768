import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { Paper, Typography } from '@mui/material';
import StandardHelpLabel from '../../StandardHelpLabel';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { useCreateSharedBookUserComment} from '../../../persistance/persistanceHooks';

let $ = require("jquery");

const CommentPopper = (props) => {

    let { open, onClose = () => { }, anchorEl, characterBlot, documentPart,commentId } = props;
    let { userComments } = props.stores.bookStore;
    const [userCommentPojo, setUserCommentPojo] = useState(null)
    const [linkType, setLinkType] = useState(characterBlot?.domNode?.dataset?.linkType)
    const [createSharedBookUserComment, { data: createSharedBookUserCommentData, loading: createSharedBookUserCommentLoading, error: createSharedBookUserCommentError }] = useCreateSharedBookUserComment();

    useEffect(() => {

       

        try{
            const commentId = $(characterBlot.domNode).data("uuid");

            setUserCommentPojo(userComments.filter(f=>f.id==commentId)[0]);
        }catch(err){
            console.log(err);
        }
        

    }, [userComments,characterBlot]);

    useEffect(() => {

        //setHref(characterBlot?.domNode?.dataset?.href);
        setLinkType(characterBlot?.domNode?.dataset?.linkType);

    }, [characterBlot?.domNode?.dataset?.href]);

    const updateComment = (e) => {

        //console.log(userComments);
        //console.log(characterBlot);
        const commentId = $(characterBlot.domNode).data("uuid");

        // let userCommentPojo = null;

        // try{
        //     userCommentPojo = userComments.filter(f=>f.id==commentId)[0];
        // }catch(err){
        //     console.log(err);
        // }
        const bookDraft = documentPart?.bookDraftDocumentPart.bookDraft;
        const book = bookDraft?.book;

        

        //console.log(commentId);

        let commentText = null;
        if(e.target && userCommentPojo){
            userCommentPojo.comment = e.target.value;
            setUserCommentPojo(userCommentPojo);
        }


        // createSharedBookUserComment({
        //     documentPartId: documentPart.id,
        //     sharedBookId: book.id,
        //     sharedBookDraftId: bookDraft.id,
        //     //commentorUserId: userId,
        //     commentId: commentId,
        //     comment: commentText
        // }, () => {
        //     props.stores.bookStore.userCommentsTimestamp = Date.now();
        // });


    }
    return (
        <Popper
            id={'linkPopper'}
            open={open}
            onMouseLeave={() => {
                onClose();

            }}
            sx={{
                marginLeft: '1em'
            }}
            anchorEl={anchorEl}
            transition
            placement="right-start"
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper elevation={1}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '0em',
                            backgroundColor: '#F9E79F',
                            width: '20em',
                            minHeight: '10em'
                        }}>
                            <StandardHelpLabel title={'Comment'} />
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <div>{userCommentPojo?.comment}</div>
                                <TextareaAutosize
                                    aria-label="empty textarea"
                                    placeholder="Description"
                                    style={{
                                        width: '100%',
                                        borderRadius: '0.25em',
                                        borderColor: '#ccc',
                                        backgroundColor: '#F9E79F'
                                    }}
                                    maxRows={10}
                                    minRows={3}
                                    value={userCommentPojo?.comment}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        //snapshot.description = e.target.value
                                        //setValue(e.target.value);

                                        updateComment(e);
                                    }}
                                />

                                {/* <StandardTextField label="url" fullWidth id="filled-basic" variant="filled" size="small" sx={{

                                }}
                                    value={href}
                                    onChange={(e) => {
                                        setHref(e.target.value);
                                        characterBlot.setHref(e.target.value);
                                        //characterBlot?.domNode?.dataset?.href = 
                                    }} /> */}

                            </div>


                        </div>
                    </Paper>
                </Fade>
            )}
        </Popper>

    );
};


export default inject('stores')(observer(CommentPopper));
