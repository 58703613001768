import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import helpers from '../../helpers/helpers';
import Typography from '@mui/material/Typography';
import { TimelineOppositeContent } from '@mui/lab';
import ReactTimeAgo from 'react-time-ago'
import StandardButton from '../StandardButton';
// import StandardOKCancelDialogLaura from '../dialogs/StandardOKCancelDialogLaura';
// import StandardLabel from '../StandardLabel';
import { useAcceptEditRequest } from '../../persistance/persistanceHooks';
import { useUpdateEditRequestStatus } from '../../persistance/persistanceHooks';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import StandardCheckbox from '../StandardCheckbox';
import EditorCompleteEditRequestDialog from '../panels/editing/EditorCompleteEditRequestDialog';
import EditorAcceptEditRequestDialog from '../panels/editing/EditorAcceptEditRequestDialog.';
import StandardOkCancelDialog from '../panels/StandardOkCancelDialog';
import { Grid } from '@mui/material';
import StandardUIDialog from '../UI/StandardUIDialog';
import StandardUITextField from '../UI/StandardUITextField';
import StandardUIButton from '../UI/StandardUIButton';

const EditRequestHistory = (props) => {

	let { editRequest } = props;

	const [showAcceptDialog, setShowAcceptDialog] = React.useState(false);
	const [showCompleteDialog, setShowCompleteDialog] = React.useState(false);
	const [showDeclinedDialog, setShowDeclinedDialog] = React.useState(false);
	const [showRequestorCancelDialog, setShowRequestorCancelDialog] = React.useState(false);
	const [showRequestorCloseDialog, setShowRequestorCloseDialog] = React.useState(false);


	// case 'REQUESTOR_CANCEL':
	// 	return await requestorCancel(input, sub, callback);
	// 	break;

	// case 'REQUESTOR_CLOSE':
	// 	return await requestorClose(input, sub, callback);
	// 	break;

	// case 'REQUESTOR_DELETE':
	// 	return await requestorDelete(input, sub, callback);
	// 	break;

	// case 'EDITOR_CANCEL':
	// 	return await editorCancel(input, sub, callback);
	// 	break;

	// case 'EDITOR_DELETE':
	// 	return await editorDelete(input, sub, callback);
	// 	break;



	const [acceptEditRequest, { data: acceptEditRequestData, loading: acceptEditRequestLoading, error: acceptEditRequestError }] = useAcceptEditRequest();
	const [updateEditRequestStatus, { data: updateEditRequestStatusData, loading: updateEditRequestStatusLoading, error: updateEditRequestStatusError }] = useUpdateEditRequestStatus();


	let user = props.stores.userStore.user;
	let isEditor = false;

	if (user.id == editRequest.editorUserId) {
		isEditor = true;
	}

	//console.log(editRequest);
	let actions = [];

	//if (editRequest.createdAt) {
	let createdAt = editRequest.createdAt != null ? helpers.toLocaleDateTime(editRequest.createdAt) : null;
	actions.push({
		name: 'Author requested',
		timestamp: createdAt,
		stepVisibility: () => { return true }
	});
	//}

	let rejectedAt = '';
	//if (editRequest.rejectedAt) {
	rejectedAt = editRequest.rejectedAt != null ? helpers.toLocaleDateTime(editRequest.rejectedAt) : null;
	actions.push({
		name: 'Editor declined',
		timestamp: rejectedAt,
		button: <StandardButton className='request-history-button' onClick={(e) => {

			setShowDeclinedDialog(true);
		}} buttonAction={'Decline'} >Decline</StandardButton>,
		buttonVisibility: () => { if (isEditor && !editRequest.acceptedAt) return true },
		stepVisibility: () => { if (!editRequest.acceptedAt) return true }
	});
	//}

	let acceptedAt = '';
	//if (editRequest.acceptedAt) {

	acceptedAt = editRequest.acceptedAt != null ? helpers.toLocaleDateTime(editRequest.acceptedAt) : null;
	actions.push({
		name: 'Editor accepted',
		timestamp: acceptedAt,
		button: <StandardButton className='request-history-button' onClick={(e) => {
			e.preventDefault();
			setShowAcceptDialog(true);
		}} buttonAction={'Accept'} >Accept</StandardButton>,
		buttonVisibility: () => { if (isEditor && !editRequest.acceptedAt) return true },
		stepVisibility: () => { return true }
	});
	//}

	let requestorCanceledAt = '';
	//if (editRequest.requestorCanceledAt) {
	requestorCanceledAt = editRequest.requestorCanceledAt != null ? helpers.toLocaleDateTime(editRequest.requestorCanceledAt) : null;
	actions.push({
		name: 'Author canceled',
		timestamp: requestorCanceledAt,
		button: <StandardButton className='request-history-button' onClick={(e) => {

			setShowRequestorCancelDialog(true);
		}} buttonAction={'Cancel'} >Cancel</StandardButton>,
		buttonVisibility: () => { if (!isEditor && !editRequest.requestorCanceledAt && !editRequest.completedAt) return true },
		stepVisibility: () => { if ((!isEditor && !editRequest.completedAt) || editRequest.requestorCanceledAt) return true }
	});
	//}

	let editorCanceledAt = '';
	//if (editRequest.editorCanceledAt) {
	editorCanceledAt = editRequest.editorCanceledAt != null ? helpers.toLocaleDateTime(editRequest.editorCanceledAt) : null;
	actions.push({
		name: 'Editor canceled',
		timestamp: editorCanceledAt,
		button: <StandardButton className='request-history-button' onClick={(e) => {

			//setShowAcceptDialog(true);
		}} buttonAction={'Cancel'} >Cancel</StandardButton>,
		buttonVisibility: () => { if (isEditor && !editRequest.closedAt && !editRequest.editorCanceledAt && editRequest.acceptedAt) return true },
		stepVisibility: () => { if ((isEditor && editRequest.acceptedAt && !editRequest.requestorCanceledAt && !editRequest.closedAt) || editRequest.editorCanceledAt) return true }
	});
	//}


	let completedAt = '';
	//if (editRequest.completedAt) {
	completedAt = editRequest.completedAt != null ? helpers.toLocaleDateTime(editRequest.completedAt) : null;
	actions.push({
		name: 'Editor completed',
		timestamp: completedAt,
		force: true,
		button: <StandardButton className='request-history-button' onClick={(e) => {

			setShowCompleteDialog(true);
		}} buttonAction={'Complete'}  >Complete</StandardButton>,
		buttonVisibility: () => { if (isEditor && !editRequest.requestorCanceledAt && !editRequest.completedAt && !editRequest.editorCanceledAt && editRequest.acceptedAt) return true },
		stepVisibility: () => { if (editRequest.acceptedAt && !editRequest.requestorCanceledAt) return true }
	});
	//}

	let closedAt = '';
	//if (editRequest.completedAt) {
	closedAt = editRequest.closedAt != null ? helpers.toLocaleDateTime(editRequest.closedAt) : null;
	actions.push({
		name: 'Author closed',
		timestamp: closedAt,
		force: true,
		preventConnector: true,
		button: <StandardButton className='request-history-button' onClick={(e) => {

			setShowRequestorCloseDialog(true);
		}} buttonAction={'Close'}  >Close</StandardButton>,
		buttonVisibility: () => { if (!isEditor && !editRequest.closedAt && !editRequest.editorCanceledAt && editRequest.acceptedAt) return true },
		stepVisibility: () => { if (!editRequest.requestorCanceledAt) return true }
	});
	//}



	// let requestorDeletedAt = '';
	// requestorDeletedAt = editRequest.requestorDeletedAt != null ? helpers.toLocaleDateTime(editRequest.requestorDeletedAt) : null;
	// actions.push({
	// 	name: 'Requestor deleted request',
	// 	timestamp: requestorDeletedAt,
	// 	button:!isEditor?<StandardButton className='request-history-button' onClick={(e) => {

	// 		//setShowAcceptDialog(true);
	// 	}} buttonAction={'Delete'} /> : 
	// 	null
	// });




	// actions = actions.sort(function (b, a) {
	// 	if (a.timestamp > b.timestamp) {
	// 		return 1;
	// 	}
	// 	if (a.timestamp < b.timestamp) {
	// 		return -1;
	// 	}
	// 	return 0;
	// });

	let timedActions = actions.filter(f => f.stepVisibility() == true);
	//let timedActions = actions;
	return (
		<Timeline align="right">
			{timedActions.map((m, index) => {
				return <TimelineItem>
					<TimelineOppositeContent>






						<Typography color="textSecondary">
							{m.timestamp &&
								<span className='request-history-button'><ReactTimeAgo date={m.timestamp} locale="en-US" /></span>
							}
							{(!m.timestamp && m.button && m.buttonVisibility && m.buttonVisibility()) &&
								m.button
							}

						</Typography>
					</TimelineOppositeContent>
					<TimelineSeparator>
						{!m.timestamp &&
							<TimelineDot color="grey" />
						}
						{m.timestamp &&
							<TimelineDot color="primary" />
						}
						{(!m.preventConnector && index < (timedActions.length - 1)) &&
							<TimelineConnector />
						}
					</TimelineSeparator>
					<TimelineContent>
						<Typography color="textSecondary">{m.name}</Typography>
					</TimelineContent>
				</TimelineItem>



			})}

			<StandardCheckbox label={'Visible to author'} checked={editRequest.locked} onChange={(e) => {

				// let newValues = buildTypes;
				// buildTypes.epub = e.target.checked;
				updateEditRequestStatus({
					editRequestId: editRequest.id,
					actionType: 'EDITOR_TOGGLE_LOCK',
					statusField: '',
					onCompleted: (data) => {
						//console.log(data);
						// props.stores.bookStore.selectedReceivedEditRequest.editBookDraftId = data.acceptEditRequestBatch.editBookDraftId;
						// props.stores.bookStore.selectedReceivedEditRequest.acceptedAt = data.acceptEditRequestBatch.acceptedAt;

						editRequest.locked = data.updateEditRequestStatus.locked;
						setShowCompleteDialog(false); //setting this state param causes a rerender. Need to actually create a mobx editRequest object instead.

					}
				})
			}} />

			<div>{editRequest?.id}</div>
			<EditorCompleteEditRequestDialog
				open={showCompleteDialog}
				onClose={() => {
					setShowCompleteDialog(false)
				}}
				onOk={() => {

					updateEditRequestStatus({
						editRequestId: editRequest.id,
						actionType: 'EDITOR_COMPLETE',
						statusField: '',
						onCompleted: (data) => {
							//console.log(data);
							// props.stores.bookStore.selectedReceivedEditRequest.editBookDraftId = data.acceptEditRequestBatch.editBookDraftId;
							// props.stores.bookStore.selectedReceivedEditRequest.acceptedAt = data.acceptEditRequestBatch.acceptedAt;

							editRequest.completedAt = data.updateEditRequestStatus.completedAt;
							setShowCompleteDialog(false)

						}
					})
				}}
			/>


			{/* <EditorAcceptEditRequestDialog
				open={showAcceptDialog}
				onClose={() => {
					setShowAcceptDialog(false)
				}}
				onOk={() => {

					acceptEditRequest({
						editRequestId: editRequest.id,
						onCompleted: (data) => {
							//console.log(data);
							props.stores.bookStore.selectedReceivedEditRequest.editBookDraftId = data.acceptEditRequestBatch.editBookDraftId;
							props.stores.bookStore.selectedReceivedEditRequest.acceptedAt = data.acceptEditRequestBatch.acceptedAt;
							setShowAcceptDialog(false);

						}
					})
				}}
			/> */}



			<StandardUIDialog
				title='Accept Edit Request'
				open={showAcceptDialog}
				onClose={() => {
					setShowAcceptDialog(false)
				}}
				dialogContent={
					<div>
						<Grid container direction={'column'}>
							<Grid item>
								<Typography>Accepting this edit request means...</Typography>
							</Grid>
						</Grid>
						{acceptEditRequestLoading &&
							<div>Accepting request

								<Box sx={{ display: 'flex' }}>
									<CircularProgress />
								</Box>

							</div>
						}


					</div>
				}
				cardActions={
					<StandardUIButton
						buttonAction='Accept'
						onClick={() => {

							acceptEditRequest({
								editRequestId: editRequest.id,
								onCompleted: (data) => {
									//console.log(data);
									props.stores.bookStore.selectedReceivedEditRequest.editBookDraftId = data.acceptEditRequestBatch.editBookDraftId;
									props.stores.bookStore.selectedReceivedEditRequest.acceptedAt = data.acceptEditRequestBatch.acceptedAt;
									setShowAcceptDialog(false);
		
								}
							})
						}}
					/>
				}
			/>




			{/* <StandardOkCancelDialog
				open={showAcceptDialog}
				onClose={() => {
					setShowAcceptDialog(false)
				}}
				onOk={() => {

					////console.log('accept it');

					acceptEditRequest({
						editRequestId: editRequest.id,
						onCompleted: (data) => {
							//console.log(data);
							props.stores.bookStore.selectedReceivedEditRequest.editBookDraftId = data.acceptEditRequestBatch.editBookDraftId;
							props.stores.bookStore.selectedReceivedEditRequest.acceptedAt = data.acceptEditRequestBatch.acceptedAt;
							setShowAcceptDialog(false);

						}
					})
				}}
				title={'Accept Edit Request'}
				content={
					<div>
						<Grid container direction={'column'}>
							<Grid item>
								<Typography>Accepting this edit request means...</Typography>
							</Grid>
						</Grid>
						{acceptEditRequestLoading &&
							<div>Accepting request

								<Box sx={{ display: 'flex' }}>
									<CircularProgress />
								</Box>

							</div>
						}


					</div>
				}
			/> */}


			<StandardOkCancelDialog
				PaperProps={{ sx: { minWidth: '50%', height: "100%" } }}
				open={showDeclinedDialog}
				onClose={() => {
					setShowDeclinedDialog(false)
				}}
				onOk={() => {

					updateEditRequestStatus({
						editRequestId: editRequest.id,
						actionType: 'EDITOR_DECLINE',
						statusField: '',
						onCompleted: (data) => {
							//console.log(data);
							// props.stores.bookStore.selectedReceivedEditRequest.editBookDraftId = data.acceptEditRequestBatch.editBookDraftId;
							// props.stores.bookStore.selectedReceivedEditRequest.acceptedAt = data.acceptEditRequestBatch.acceptedAt;

							editRequest.editorDeletedAt = data.updateEditRequestStatus.editorDeletedAt;
							setShowDeclinedDialog(false)

						}
					})
				}}
				title={'Decline Edit Request'}
				content={
					<div>


						{updateEditRequestStatusLoading &&
							<div>Declining request

								<Box sx={{ display: 'flex' }}>
									<CircularProgress />
								</Box>

							</div>
						}


					</div>
				}
			/>









			<StandardOkCancelDialog
				PaperProps={{ sx: { minWidth: '50%', height: "100%" } }}
				open={showRequestorCancelDialog}
				onClose={() => {
					setShowRequestorCancelDialog(false)
				}}
				onOk={() => {

					updateEditRequestStatus({
						editRequestId: editRequest.id,
						actionType: 'REQUESTOR_CANCEL',
						statusField: '',
						onCompleted: (data) => {
							//console.log(data);
							// props.stores.bookStore.selectedReceivedEditRequest.editBookDraftId = data.acceptEditRequestBatch.editBookDraftId;
							// props.stores.bookStore.selectedReceivedEditRequest.acceptedAt = data.acceptEditRequestBatch.acceptedAt;

							editRequest.requestorCanceledAt = data.updateEditRequestStatus.requestorCanceledAt;
							setShowRequestorCancelDialog(false)

						}
					})
				}}
				title={'Cancel Edit Request'}
				content={
					<div>


						{updateEditRequestStatusLoading &&
							<div>Canceling request

								<Box sx={{ display: 'flex' }}>
									<CircularProgress />
								</Box>

							</div>
						}


					</div>
				}
			/>

			<StandardOkCancelDialog
				PaperProps={{ sx: { minWidth: '50%', height: "100%" } }}
				open={showRequestorCloseDialog}
				onClose={() => {
					setShowRequestorCloseDialog(false)
				}}
				onOk={() => {

					updateEditRequestStatus({
						editRequestId: editRequest.id,
						actionType: 'REQUESTOR_CLOSE',
						statusField: '',
						onCompleted: (data) => {
							//console.log(data);
							// props.stores.bookStore.selectedReceivedEditRequest.editBookDraftId = data.acceptEditRequestBatch.editBookDraftId;
							// props.stores.bookStore.selectedReceivedEditRequest.acceptedAt = data.acceptEditRequestBatch.acceptedAt;

							editRequest.closedAt = data.updateEditRequestStatus.closedAt;
							setShowRequestorCloseDialog(false)

						}
					})
				}}
				title={'Close Edit Request'}
				content={
					<div>


						{updateEditRequestStatusLoading &&
							<div>Closing request

								<Box sx={{ display: 'flex' }}>
									<CircularProgress />
								</Box>

							</div>
						}


					</div>
				}
			/>


		</Timeline>
	);
};


export default inject('stores')(observer(EditRequestHistory));