import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import AppWrapper from '../components/AppWrapper';

const PurchaseComplete = (props) => {

  let navigate = useNavigate();

  useEffect(()=>{

    //props.stores.bookStore.isNewPurchase = true;
    //navigate('/books', { replace: true });
    
  });

  return (
    <>
      <div>Thank you for your purchase of BookMerlin!</div>
      <div>Here are all the cool features you get</div>
      <button onClick={()=>{
        navigate('/', { replace: true });
      }}>Click here to begin</button>
    </>


  )
}
//export default PurchaseComplete;
export default inject('stores')(observer(PurchaseComplete));