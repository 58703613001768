import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import StretchBox from '../StretchBox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetBillingPortal } from '../../../managers/PaymentManager';
import { Box } from '@mui/material';

const Billing = (props) => {

  let [billingUrl, setBillingUrl] = useState();

  let user = props.stores.userStore.user;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let {
    loading, error, data, refetch
  } = useGetBillingPortal();


  useEffect(() => {

    if (data != null) {

      let parsedData = JSON.parse(data.getBillingPortal)
      try {
        setBillingUrl(parsedData.billingPortalUrl);
      } catch (err) {
        console.log(err);
      }


      window.open(parsedData.billingPortalUrl, '_blank');
      //window.open(parsedData.billingPortalUrl);


    }
  }, [data]);


  const iframeStyle = {
    width: '100%',
    height: '100vh', // Use 'vh' for full viewport height
    border: '3px', // Remove iframe border if needed
  };


  return (


    <StretchBox flexDirection='column'>

      <StretchBox flexDirection='column'>

      {/* <Box style={{
        height:'100%',
        width:'100%'
      }}>
      {billingUrl &&
          <iframe
            src={billingUrl} // Replace with the URL of the external website
            title="External Website"
            style={iframeStyle}
            allowFullScreen
          />
        }
      </Box> */}
       





      </StretchBox>



    </StretchBox>



  )
}

export default inject('stores')(observer(Billing));