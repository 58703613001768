import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Typography, List, Button } from '@mui/material';
import DashboardCard from '../../dashboard/DashboardCard';
import { useTheme } from '@mui/material/styles';
import PeopleIcon from '@mui/icons-material/People';
import BookIcon from '@mui/icons-material/Book';
import EditIcon from '@mui/icons-material/Edit';
import CommentIcon from '@mui/icons-material/Comment';
import EditOffIcon from '@mui/icons-material/EditOff';
import StandardTree from '../../StandardTree';
import Quill from 'quill';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import StandardTabs from '../../StandardTabs';
import EReader from '../../formatting/EReader';
import EReader3 from '../../formatting/EReader3';
import FormatPropertiesCard from '../../formatting/FormatPropertiesCard';
import StandardStepper, { StandardBaseStepper } from '../../StandardStepper';
import StandardRadioButtons from '../../StandardRadioButtons';
import ScrollingGridContainer from '../../ScrollingGridContainer';
import ScrollingGridItem from '../../ScrollingGridItem';
import MainFormattingPanel from '../../formatting/MainFormattingPanel';
import { useLoadBookDraftForFormat } from '../../../managers/BooksManager';
import BookDraftTree3 from '../../tree/BookDraftTree3';
import BookDraftTree7 from '../../tree/BookDraftTree7';
import FormatToolbar from '../../dev/FormatToolbar';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Feather from 'mdi-material-ui/Feather';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import BookDraftEditor from '../../editor/BookDraftEditor';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ToolsNavigator from '../../../routes/ToolsNavigator';
import { useGetMappedImages } from '../../../managers/StorageManager';
import OwnedBooksList from '../../lists/OwnedBooksList';
import { useOpenBookInFormat } from '../../../managers/BooksManager';
import StandardDialog from '../../StandardDialog';
import ExportsPanel from '../../export/ExportsPanel';
import StretchBox from '../StretchBox';
import ScrollBox from '../ScrollBox';
import AppWrapperDummy from '../../AppWrapperDummy';
import { NestedListComponent } from '../../tree/DNDTree/NestedListComponent';
import { NestedListComponent2 } from '../../tree/DNDTree/NestedListComponent2';



const Format = (props) => {

  const [tabSelection, setTabSelection] = React.useState(0);
  const [selectedBddp, setSelectedBddp] = React.useState(null);
  const [showExport, setShowExport] = React.useState(null);
  const [developmentBookDraftSource, setDevelopmentBookDraftSource] = React.useState('book');

  const { openBookInFormat } = useOpenBookInFormat(props);

  let { user } = props.stores.userStore;

  const {
    bookDraft,
    getBookDraftLoading,
    startersBookDraft,
    inflatableTemplates,
    velocityBookDraft,
    masterRecipe
  } = useLoadBookDraftForFormat(props, props.stores.bookStore.writingBookDraftId);

  const handleTabSelection = (event, newValue) => {
    setTabSelection(newValue);
  };

  const theme = useTheme();

  useEffect(() => {


    if (bookDraft) {

      setSelectedBddp(bookDraft.rootBookDraftDocumentPart);


    }


  }, [bookDraft]);


  const { mappedImages } = useGetMappedImages(props, bookDraft);

  useEffect(() => {


    if (bookDraft) {

      bookDraft.mappedImages = mappedImages;
      bookDraft.book.mappedImages = mappedImages;


    }


  }, [bookDraft, mappedImages]);

  let currentRecipe = selectedBddp?.documentPart.getRecipe();

  if (!props.stores.bookStore.writingBookDraftId) {
    return <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        //border: '1px solid blue',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography variant={'h4'}>Select a book to format</Typography>
      <OwnedBooksList
        filteredBooks={[]}
        showSelectIcon
        onSelected={(book) => {

          if (book) {

            // openBookInFormat({
            //   bookDraftId: book.primaryBookDraftId
            // });

          }

        }}
      />
    </Box>
  }


  return (

    <StretchBox sxProps={{

    }}
      flexDirection='row'>

      <StretchBox
        flexDirection='column'
        sxProps={{
          flex: 2
        }}>

        {props.stores.bookStore.isDevMode &&
          <FormatToolbar developmentBookDraftSource={developmentBookDraftSource} onChangeDevelopmentBookDraftSource={(developmentBookDraftSource) => {

            setDevelopmentBookDraftSource(developmentBookDraftSource);

          }} />
        }
        {/* <NestedListComponent/> */}
        {/* <NestedListComponent2 /> */}
        {(bookDraft && (!developmentBookDraftSource || developmentBookDraftSource == 'book')) &&

          <BookDraftTree7
            bookDraft={bookDraft}
            heading={'Manuscript'}
            hideScenes={true}
            //showAddBookDialog={showAddBookDialog}
            //setShowAddBookDialog={setShowAddBookDialog}
            selectedId={selectedBddp?.partId}
            // selectedIds={[
            //   setSelectedBddp?.partId
            // ]}
            onDocumentPartSelected={(item) => {

              let bddp = bookDraft.getBookDraftDocumentPartByPartId(item.id);
              props.stores.bookStore.writingSelectedBookDraftDocumentPart = bddp;
              //console.log(bddp);

              if (bddp == null) {
                bddp = props.stores.bookStore.startersBookDraft.getBookDraftDocumentPartByPartId(item.id)
              }
              if (bddp == null) {
                bddp = props.stores.bookStore.velocityBookDraft.getBookDraftDocumentPartByPartId(item.id)
              }
              setSelectedBddp(bddp);
              //setEreaderContent(bddp?.documentPart.title);

              // if (showInflatables || showVelocity || showStarters) {
              // 	props.stores.bookStore.addEditTemplateId({
              // 		title: bddp.documentPart.title,
              // 		templateId: item.id,
              // 		templateBddp: bddp
              // 	})
              // }

            }}
            onBookInserted={() => {
              //getBookDraftRefetch();
            }} />

        }
        {(props.stores.bookStore.inflatablesBookDraft && (developmentBookDraftSource == 'inflatables')) &&
          <BookDraftTree7
            bookDraft={props.stores.bookStore.inflatablesBookDraft}
            heading={'Inflatables'}
            hideScenes={true}
            //showAddBookDialog={showAddBookDialog}
            //setShowAddBookDialog={setShowAddBookDialog}
            selectedId={selectedBddp?.partId}
            // selectedIds={[
            //   setSelectedBddp?.partId
            // ]}
            onDocumentPartSelected={(item) => {

              let bddp = props.stores.bookStore.inflatablesBookDraft.getBookDraftDocumentPartByPartId(item.id);
              //props.stores.bookStore.writingSelectedBookDraftDocumentPart = bddp;
              //console.log(bddp);

              // if (bddp == null) {
              // 	bddp = props.stores.bookStore.startersBookDraft.getBookDraftDocumentPartByPartId(item.id)
              // }
              // if (bddp == null) {
              // 	bddp = props.stores.bookStore.velocityBookDraft.getBookDraftDocumentPartByPartId(item.id)
              // }
              // setSelectedBddp(bddp);
              //setEreaderContent(bddp?.documentPart.title);



              props.stores.bookStore.addEditTemplateId({
                title: bddp.documentPart.title,
                templateId: item.id,
                templateBddp: bddp
              })


            }}
            onBookInserted={() => {
              //getBookDraftRefetch();
            }} />
        }
        {(props.stores.bookStore.velocityBookDraft && (developmentBookDraftSource == 'velocity')) &&
          <BookDraftTree7
            bookDraft={props.stores.bookStore.velocityBookDraft}
            heading={'Velocity'}
            hideScenes={true}
            //showAddBookDialog={showAddBookDialog}
            //setShowAddBookDialog={setShowAddBookDialog}
            selectedId={selectedBddp?.partId}
            // selectedIds={[
            //   setSelectedBddp?.partId
            // ]}
            onDocumentPartSelected={(item) => {

              let bddp = velocityBookDraft.getBookDraftDocumentPartByPartId(item.id);
              props.stores.bookStore.addEditTemplateId({
                title: bddp.documentPart.title,
                templateId: item.id,
                templateBddp: bddp
              })


            }}
            onBookInserted={() => {
              //getBookDraftRefetch();
            }} />
        }

      </StretchBox>
      <StretchBox
        flexDirection='column'
        sxProps={{
          flex: 7
        }}>

        {props.stores.bookStore.isDevMode &&
          <div>
            {bookDraft &&
              <span>{bookDraft?.book?.title}</span>
            }
            {bookDraft?.book?.recipe?.json &&
              <span>Book recipe loaded</span>
            }
            {getBookDraftLoading &&
              <span>Loading bookDraft</span>
            }
            {inflatableTemplates &&
              <span>Inflatables loaded</span>
            }
            {startersBookDraft &&
              <span>Starters loaded</span>
            }
            {props.stores.bookStore.velocityBookDraft &&
              <span>Velocity loaded</span>
            }
            {masterRecipe &&
              <span>Master Recipe loaded</span>
            }
          </div>
        }
        {bookDraft &&
          <MainFormattingPanel
            bookDraft={bookDraft}
            documentPart={selectedBddp?.documentPart}
            recipe={currentRecipe?.json}
            user={user}
            stores={props.stores}
          />
        }
      </StretchBox>
      <StretchBox
        flexDirection='column'
        sxProps={{
          flex: 3
        }}>
       
        <Button onClick={(e) => {
          e.stopPropagation();
          setShowExport(true);

        }}>Export</Button>
        <EReader3
          //book={props.stores.bookStore.writingBookDraft}
          documentPart={selectedBddp?.documentPart}
          currentRecipe={currentRecipe?.json}
          currentInflatedTemplates={props.stores.bookStore.currentInflatedTemplates}
        //content={ereaderContent}
        />
         <StandardDialog open={showExport}
          onClose={() => {
            setShowExport(false)
          }
          }
          dialogContent={<ExportsPanel bookDraftId={props.stores.bookStore.writingBookDraftId} />}>

        </StandardDialog>
      </StretchBox>
    </StretchBox>


  )
}

export default inject('stores')(observer(Format));