import React, { useEffect, useState } from 'react';
import { styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import BaseControl from "../BaseControl";
import { useTheme } from '@mui/material/styles';
import { Box, Checkbox } from "@mui/material";
import { v4 as uuid } from "uuid";
// import Switch from '@mui/material/Switch';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import SelfOverrideSwitch from './SelfOverrideSwitch';
import { RecipeUtils } from '../../RecipeUtils';
import { HTML5Backend } from 'react-dnd-html5-backend'
import PropsImageSelector from './PropsImageSelector';

function PropsHeadingDnD(props) {

  const theme = useTheme();
  let baseControl = new BaseControl(props);

  const { classes, element, bookDraft, documentPart } = props;




  let isVisible = baseControl.isVisible();
  if (!isVisible) {
    return <div style={{
      height: '0em'
    }}></div>
  }

  let item = documentPart;
  let properties_book = bookDraft.book;
  let boxSet = bookDraft.book.boxSet;

  let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();
  let hideBookProperties = false;

  let canEdit = true;


  let isOwner = true;


  if (element.paramItem == 'BOOK') {
    item = bookDraft.book;
  }
  if (element.paramItem == 'BOXSET_MAIN_BOOK') {
    item = boxSet.book;
  }
  if (element.paramItem == 'BOXSET_BOOK') {
    item = boxSetBook.book;
  }
  if (element.paramItem == 'BOXSETBOOK') {
    item = boxSetBook;
  }



  let currentRecipe = props.documentPart?.getRecipe().json;
  let recipeParamPath = element.recipeParamPath;
  let checked = new RecipeUtils().getRecipeParamByPartType(null, currentRecipe, documentPart, recipeParamPath);

  let title = baseControl.convertTitle(element.title, documentPart);

  const label = { inputProps: { 'aria-label': title } };




  return (
   <div>
    redo this component
   </div>

  );
}



export default inject('stores')(observer(PropsHeadingDnD));



