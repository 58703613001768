import React, { useState, useEffect } from 'react';
import { TextField, styled } from "@mui/material";
import { Tooltip, Box } from '@mui/material';

export const StyledTextfield = styled(TextField)(() => ({

    //backgroundColor:'red'



}))

export default function StandardTextfield(props) {




    return (
        <StyledTextfield
            fullWidth
            variant="standard"
            {...props} />

    );
}
