import { Box } from '@mui/material';
import React from 'react';





function ScrollBox({ children, sxProps }) {




  return (
    <Box
      sx={{
        flex: '1',
        overflowY: 'auto',
        //border: '4px solid blue',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          borderRadius: '3px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderRadius: '3px',
        },
        ...sxProps
      }}
    >
      {children}
    </Box>
  );
}

export default ScrollBox;
