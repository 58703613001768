import React, { useState, useEffect } from "react";
import { Box, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StandardUIDialog from "../../UI/StandardUIDialog";
import { useManagerAddBoxsetContributor, useManagerAddBoxsetContributedBook, useManagerLoadBoxSetContributors } from '../../../managers/BoxsetsManager';
import ListOwnedBooks from "../lists/ListOwnedBooks";
import CircularProgress from '@mui/material/CircularProgress';


const AddContributedBook = (props) => {


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    let user = props.stores.userStore.user;
    let { boxset } = props;

    let [boxsetBooks, setBoxsetBooks] = useState([]);

    const { addBoxSetContributedBook, loading: addBoxSetContributedBookLoading, error: addBoxSetContributedBookError } = useManagerAddBoxsetContributedBook(props);
    const { addBoxsetContributor, loading: createBoxSetContributorLoading, error: createBoxSetContributorError } = useManagerAddBoxsetContributor(props);

    let {
        boxsetContributors,
        loading: lazyLoadBoxSetContributorsLoading,
        error: lazyLoadBoxSetContributorsError
    } = useManagerLoadBoxSetContributors(props, boxset?.id);


    useEffect(() => {

        try {

            let bookData = [];
            boxsetContributors.forEach((boxsetContributor) => {

                boxsetContributor.contributedBooks.items.forEach((bsb) => {

                    bookData.push({
                        boxsetContributor,
                        boxsetBook: bsb
                    })
                })

            });

            setBoxsetBooks(bookData);

        } catch (err) {
            console.log(err);
        }
    }, [boxsetContributors]);



    return (
        <StandardUIDialog
            open={props.open}
            onClose={props.onClose}
            book={props.book}
            title='Add Book'
            dialogContent={
                    <Box>
                        <ListOwnedBooks
                            filteredBooks={boxsetBooks.map(m => m.boxsetBook.book)}
                            showSelectIcon
                            showWriteIcon={false}
                            showFormatIcon={false}
                            showAddIcon={true}
                            onSelectBook={(book) => {

                                if (book) {
                                    addBoxSetContributedBook({
                                        bookId: book.id,
                                        boxsetId: boxset.id,
                                    })
                                }

                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {addBoxSetContributedBookLoading && <CircularProgress />}
                        </div>
                    </Box>
            }
        />
    )
}

export default inject('stores')(observer(AddContributedBook));