import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import StandardDataTable from '../table/StandardDataTable';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import ReactTimeAgo from 'react-time-ago';
import Feather from 'mdi-material-ui/Feather';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import SnapshotsTable from '../table/SnapshotsTable';
import BookEditsTable from '../table/BookEditsTable';
import { useGetSharedBooks, useOpenBookInWrite, useOpenBookInFormat, useManagerCreateBook } from '../../managers/BooksManager';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import StandardImageViewer from '../StandardImageViewer';
import config from '../../aws-exports';
import AvatarTableCell from '../table/AvatarTableCell'; 
import ImageTitleTableCell from '../table/ImageTitleTableCell';
import { useManagerListConnectedUserBookShares } from '../../managers/ConnectionsManager';
import { useMediaQuery } from 'react-responsive';
import CancelTableCell from '../table/CancelTableCell';
import ShareBookAssembly from '../assemblies/ShareBookAssembly';
import BookShareAssembly from '../assemblies/BookShareAssembly';

const ConnectionSharedToPanel = (props) => {
  let { userConnection } = props;

  const { sharedBooks, loading: listConnectedUserBookSharesLoading, error: listConnectedUserBookSharesError } = useManagerListConnectedUserBookShares(props, userConnection?.connectionUserId);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');



  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();


  const isXs = useMediaQuery({ maxWidth: 599 })
  const isSm = useMediaQuery({ maxWidth: 600 })
  const isMd = useMediaQuery({ maxWidth: 900 })
  const isLg = useMediaQuery({ maxWidth: 1200 })
  const isXl = useMediaQuery({ minWidth: 1536 })






  return (
      <Box sx={{
          display: 'flex',
          flexDirection: 'column'
      }}>

          <StandardDataTable title={'Shared Books'}
              noDataMessage={'Share your book with your connection!'}
              order={order}
              onSetOrder={setOrder}
              orderBy={orderBy}
              onSetOrderBy={setOrderBy}
              headerActions={<BookShareAssembly userConnection={userConnection}/>}

              structure={{
                  dataDef: {
                      data: sharedBooks,
                      rowKeyDataFieldName: 'id'
                  },
                  columns: [

                      {
                          title: 'Book',
                          align: 'left',
                          dataValueFunction: (book) => {
                              return <ImageTitleTableCell
                                  bucketName={config.user_uploads_bucket_name}
                                  title={book?.title}
                                  s3Src={book?.coverUrl}
                              />

                          }
                      },

                    //   {
                    //       title: 'Created',
                    //       dataFieldName: 'createdAt',
                    //       sortable: true,
                    //       dataValueFunction: (book) => { return book.createdAt != null ? <ReactTimeAgo date={book.createdAt} locale="en-US" /> : '' },
                    //       ignore: isXs

                    //   },
                      {
                          title: 'Cancel',
                          align: 'right',
                          dataValueFunction: (book) => {
                              return <CancelTableCell onClick={(e) => {
                                  e.preventDefault();

                                  // if (bookShare.type == 'Group') {
                                  //     deleteBookUserGroupShare({
                                  //         bookId: book.id,
                                  //         userGroupId: bookShare.id
                                  //     });
                                  // }
                                  // if (bookShare.type == 'User') {
                                  //     deleteBookUserShare({
                                  //         bookId: book.id,
                                  //         sharedWithUserId: bookShare.id
                                  //     });
                                  // }



                              }} />
                          }
                      },





                  ]
              }} />



      </Box>

  )
}

export default inject('stores')(observer(ConnectionSharedToPanel));