import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import StandardDataTable from './StandardDataTable';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useGetUserConnections } from '../../managers/ConnectionsManager';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import config from '../../aws-exports';
import ReactTimeAgo from 'react-time-ago';
import AvatarTableCell from './AvatarTableCell';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CommentIcon from '@mui/icons-material/Comment';
import FileCabinet from 'mdi-material-ui/FileCabinet';
import StandardRoundedAction from '../actions/StandardRoundedAction';
import SettingsIcon from '@mui/icons-material/Settings';
import TablePopupMenu from '../menus/TablePopupMenu';
import { useMediaQuery } from 'react-responsive';
import CampaignIcon from '@mui/icons-material/Campaign';
import GppGoodIcon from '@mui/icons-material/GppGood';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ConnectionsList from '../lists/ConnectionsList';
import SendInviteAssembly from '../assemblies/SendInviteAssembly';

const ConnectionsTable = (props) => {

  const { userConnections, error: getUserConnectionsError } = useGetUserConnections(props);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');


  const isXs = useMediaQuery({ maxWidth: 599 })
  const isSm = useMediaQuery({ maxWidth: 600 })
  const isMd = useMediaQuery({ maxWidth: 900 })
  const isLg = useMediaQuery({ maxWidth: 1200 })
  const isXl = useMediaQuery({ minWidth: 1536 })


  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {


    setOrder("desc");
    setOrderBy("createdAt");

  }, [userConnections]);





  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      {!isXs &&
        <StandardDataTable
          // title={'Connections'}
          noDataMessage={'Your connections will appear here!'}
          headerActions={<SendInviteAssembly />}
          structure={{
            dataDef: {
              data: userConnections,
              rowKeyDataFieldName: 'id'
            },
            columns: [

              {
                title: 'User',
                sortable: true,
                sortFieldNameOverride: 'title',
                dataValueFunction: (userConnection) => {
                  return <AvatarTableCell
                    bucketName={config.user_uploads_bucket_name}
                    s3Src={userConnection?.connectedUser?.profilePicUrl}
                    title={userConnection?.connectedUser?.name}
                    onClick={(e) => {
                      e.preventDefault();
                      //console.log(userConnection)
                      props.stores.bookStore.connectionsListConnection = userConnection;
                      navigate("/connections/details");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }}
                  />

                }
              },
              // {
              //   title: 'Name',
              //   dataValueFunction: (userConnection) => {
              //     console.log(userConnection);
              //     return userConnection?.connectedUser?.name
              //   }
              // },
              {
                title: 'Shared With',
                dataValueFunction: (userConnection) => {
                  return <IconButton aria-label="delete">
                    <ShareIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                      e.preventDefault();
                      //console.log(userConnection)
                      props.stores.bookStore.connectionsListConnection = userConnection;
                      navigate("/connections/details/sharedTo");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }} />
                  </IconButton>
                },
                ignore: isXs
              },
              {
                title: 'Shared From',
                dataValueFunction: (userConnection) => {
                  return <IconButton aria-label="delete">
                    <ShareIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                      e.preventDefault();
                      //console.log(userConnection)
                      props.stores.bookStore.connectionsListConnection = userConnection;
                      navigate("/connections/details/sharedFrom");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }} />
                  </IconButton>
                },
                ignore: isXs
              },
              // {
              //   title: 'Boxsets',
              //   dataValueFunction: (userConnection) => {
              //     return <IconButton aria-label="delete">
              //       <ContentCopyIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
              //         e.preventDefault();
              //         console.log(userConnection)
              //         props.stores.bookStore.connectionsListConnection = userConnection;
              //         navigate("/connections/details/boxsets");
              //         // openBookInWrite({
              //         //   bookDraftId: book.primaryBookDraftId
              //         // });
              //       }} />
              //     </IconButton>
              //   },
              //   ignore: isXs
              // },
              // {
              //   title: 'Projects',
              //   dataValueFunction: (userConnection) => {
              //     return <IconButton aria-label="delete">
              //       <FileCabinet sx={{ color: theme.palette.background.main }} onClick={(e) => {
              //         e.preventDefault();
              //         console.log(userConnection)
              //         props.stores.bookStore.connectionsListConnection = userConnection;
              //         navigate("/connections/details/projects");
              //         // openBookInWrite({
              //         //   bookDraftId: book.primaryBookDraftId
              //         // });
              //       }} />
              //     </IconButton>
              //   },
              //   ignore: isXs
              // },
              {
                title: 'Feedback',
                dataValueFunction: (userConnection) => {
                  return <IconButton aria-label="delete">
                    <CommentIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                      e.preventDefault();
                      //console.log(userConnection)
                      props.stores.bookStore.connectionsListConnection = userConnection;
                      navigate("/connections/details/feedback");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }} />
                  </IconButton>
                },
                ignore: isXs
              },
              // {
              //   title: 'Styles',
              //   dataValueFunction: (userConnection) => {
              //     return <IconButton aria-label="delete">
              //       <CommentIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
              //         e.preventDefault();
              //         console.log(userConnection)
              //         props.stores.bookStore.connectionsListConnection = userConnection;
              //         navigate("/connections/details/sharedTo");
              //         // openBookInWrite({
              //         //   bookDraftId: book.primaryBookDraftId
              //         // });
              //       }} />
              //     </IconButton>
              //   },
              //   ignore: isXs
              // },
              {
                title: 'Permissions',
                dataValueFunction: (userConnection) => {
                  return <IconButton aria-label="delete">
                    <HandshakeIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                      e.preventDefault();
                      //console.log(userConnection)
                      props.stores.bookStore.connectionsListConnection = userConnection;
                      navigate("/connections/details/permissions");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }} />
                  </IconButton>
                },
                ignore: isXs
              },
              {
                title: 'Connected',
                dataFieldName: 'createdAt',
                sortable: true,
                dataValueFunction: (userConnection) => { return userConnection.createdAt != null ? <ReactTimeAgo date={userConnection.createdAt} locale="en-US" /> : '' },
                ignore: isXs
              },
              // {
              //     title: 'Delete',
              //     dataValueFunction: (userConnection) => {
              //         return <IconButton aria-label="delete">
              //             <TrashCanOutline sx={{ color: theme.palette.background.main }} onClick={(e) => {
              //                 e.preventDefault();
              //                 console.log(userConnection);
              //                 // setSelectedBook(book);
              //                 // setShowDeleteBookDialog(true);

              //             }} />
              //         </IconButton>
              //     }
              // },


              {
                title: '',
                dataValueFunction: (userConnection) => {
                  return <TablePopupMenu
                    menuItems={[
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(userConnection)
                        props.stores.bookStore.connectionsListConnection = userConnection;
                        navigate("/connections/details/sharedTo");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }} >

                        <ListItemIcon>
                          <ShareIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Shared With" />
                      </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(userConnection)
                        props.stores.bookStore.connectionsListConnection = userConnection;
                        navigate("/connections/details/sharedFrom");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }} >

                        <ListItemIcon>
                          <ShareIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Shared From" />
                      </ListItemButton>,
                      //   <ListItemButton onClick={(e) => {
                      //     e.preventDefault();
                      //     console.log(userConnection)
                      //     props.stores.bookStore.connectionsListConnection = userConnection;
                      //     navigate("/connections/details");
                      //     // openBookInWrite({
                      //     //   bookDraftId: book.primaryBookDraftId
                      //     // });
                      // }}   >

                      //     <ListItemIcon>
                      //       <SettingsIcon sx={{ color: theme.palette.background.main }} />
                      //     </ListItemIcon>
                      //     <ListItemText primary="Details" />
                      //   </ListItemButton>,
                      // <ListItemButton onClick={(e) => {
                      //   e.preventDefault();
                      //   console.log(userConnection)
                      //   props.stores.bookStore.connectionsListConnection = userConnection;
                      //   navigate("/connections/details/boxsets");
                      //   // openBookInWrite({
                      //   //   bookDraftId: book.primaryBookDraftId
                      //   // });
                      // }}   >

                      //   <ListItemIcon>
                      //     <ContentCopyIcon sx={{ color: theme.palette.background.main }} />
                      //   </ListItemIcon>
                      //   <ListItemText primary="Boxsets" />
                      // </ListItemButton>,
                      // <ListItemButton onClick={(e) => {
                      //   e.preventDefault();
                      //   console.log(userConnection)
                      //   props.stores.bookStore.connectionsListConnection = userConnection;
                      //   navigate("/connections/details/projects");
                      //   // openBookInWrite({
                      //   //   bookDraftId: book.primaryBookDraftId
                      //   // });
                      // }} >

                      //   <ListItemIcon>
                      //     <FileCabinet sx={{ color: theme.palette.background.main }} />
                      //   </ListItemIcon>
                      //   <ListItemText primary="Projects" />
                      // </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(userConnection)
                        props.stores.bookStore.connectionsListConnection = userConnection;
                        navigate("/connections/details/feedback");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }} >

                        <ListItemIcon>
                          <CommentIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Feedback" />
                      </ListItemButton>,
                      // <ListItemButton onClick={(e) => {
                      //   e.preventDefault();
                      //   console.log(userConnection)
                      //   props.stores.bookStore.connectionsListConnection = userConnection;
                      //   navigate("/connections/details/sharedTo");
                      //   // openBookInWrite({
                      //   //   bookDraftId: book.primaryBookDraftId
                      //   // });
                      // }} >

                      //   <ListItemIcon>
                      //     <CommentIcon sx={{ color: theme.palette.background.main }} />
                      //   </ListItemIcon>
                      //   <ListItemText primary="Styles" />
                      // </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(userConnection)
                        props.stores.bookStore.connectionsListConnection = userConnection;
                        navigate("/connections/details/permissions");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }}  >

                        <ListItemIcon>
                          <CommentIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Permissions" />
                      </ListItemButton>,
                    ]}
                  />
                },
                ignore: !isXs
              },






            ]
          }} />
      }
      {isXs &&
        <ConnectionsList
          filteredBooks={[]}
          showSelectIcon
          onSelected={(userConnection) => {

            if (userConnection) {

              //console.log(userConnection)
              props.stores.bookStore.connectionsListConnection = userConnection;
              navigate("/connections/details");

            }

          }}
        />
      }
    </Box>

  )
}
export default inject('stores')(observer(ConnectionsTable));