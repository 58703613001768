import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper, Tab } from '@mui/material';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import AppWrapper from '../../AppWrapper';
import Library from './Library';
import OwnedBooksListMenuContent from '../../menus/OwnedBooksListMenuContent';
import AppWrapperDummy from '../../AppWrapperDummy';
import StretchBox from '../StretchBox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StandardAppBar from '../../StandardAppBar';
import MainMenuContent2 from '../../menus/MainMenuContent2';
import BoxsetsTable from '../BoxsetsTable';
import ConnectionsTable from '../ConnectionsTable';
import ConnectionsMobileFooterBar from '../../footers/ConnectionsMobileFooterBar';
import ScrollBox from '../ScrollBox';
import ConnectionsAndGroups from '../../table/ConnectionsAndGroups';
import TrashbinMenuContent from '../../menus/TrashbinMenuContent';
import MainMenuContent from '../../menus/MainMenuContent';
import { StandardPaper } from '../../StandardPaper';
import { useManagerAcceptInvitation } from '../../../managers/ConnectionsManager';
import StandardTabs from '../../StandardTabs';
import MyGroupsTabContent from '../MyGroupsTabContent';
import MyGroupsPage from '../../listPages/MyGroupsPage';
const Connections = (props) => {


  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();
  const [selectedTab, setSelectedTab] = React.useState('books');
  
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const search = useLocation().search;
  const invitationCode = new URLSearchParams(search).get('invitationCode');

 


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (

    // <AppWrapperDummy>
    <StretchBox flexDirection='column'>

      {/* <ConnectionsTable /> */}
      {/* This stretchbox ensures the footer is pushed to the bottom of the page
            before the data is loaded for the table
        */}
      <StretchBox flexDirection='column'>

      
      <StretchBox flexDirection='column'>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <StandardTabs
          value={selectedTab}
          onChange={(event, newValue) => {
            setSelectedTab(newValue);
          }}
          aria-label="basic tabs example">
          <Tab value='books' label="Books" {...a11yProps(0)} />
          <Tab value='snapshots' label="Snapshots" {...a11yProps(1)} />
          <Tab value='boxsets' label="Boxsets" {...a11yProps(2)} />
          <Tab value='templates' label="Templates" {...a11yProps(3)} />
        </StandardTabs>

      </Box>
     

     
     

      {selectedTab && selectedTab == 'books' &&
        <MyGroupsPage />
      }

      {/* {selectedTab && selectedTab == 'sharedGroupsLive' &&
        <SharedGroupsPage />
      } */}



      {/* </Box> */}
      {/* </ScrollBox> */}
    </StretchBox>
       
      </StretchBox>


      {/* <ConnectionsMobileFooterBar /> */}
    </StretchBox>
    // </AppWrapperDummy>


  )
}
export default Connections;
// export default inject('stores')(observer(Books));