import Quill from 'quill';
//import Parchment from 'parchment';
//import Popper from 'popper.js';
import { v4 as uuid }  from 'uuid';
const Parchment = Quill.import('parchment');
let Inline = Quill.import('blots/inline');
var $ = require("jquery");



class NoteBlot extends Inline {

  static create(args) {

    let node = super.create(args);

    node.dataset.uuid = args.uuid;
    node.dataset.type = 'noteBlot';
    node.dataset.accepted = args.accepted;
    node.dataset.rejected = args.rejected;
    node.dataset.editorPinned = args.editorPinned;
    node.dataset.requestorPinned = args.requestorPinned;
    node.dataset.editorComment = args.editorComment;

 

    $(node).click(function () {

      $('#commentPopperTriggerDiv').data('blotNode', node);
      $('#commentPopperTriggerDiv').click();


    });








    //node.setAttribute('target', '_blank');
    return node;
  }

  static formats(domNode) {
    //return domNode.dataset.src
    //return domNode.getAttribute('href');

    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
    uuid:domNode.dataset.uuid,
    type:'noteBlot',
    accepted:domNode.dataset.accepted,
    rejected:domNode.dataset.rejected,
    editorPinned:domNode.dataset.editorPinned,
    requestorPinned:domNode.dataset.requestorPinned,
    editorComment:domNode.dataset.editorComment
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  static value(domNode) {
    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
    uuid:domNode.dataset.uuid,
    type:'noteBlot',
    accepted:domNode.dataset.accepted,
    rejected:domNode.dataset.rejected,
    editorPinned:domNode.dataset.editorPinned,
    requestorPinned:domNode.dataset.requestorPinned,
    editorComment:domNode.dataset.editorComment
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  /*static sanitize(url) {  
    return sanitize(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
  }*/

  format(name, args) {
    if (name !== this.statics.blotName || !args) return super.format(name, args);
    //value = this.constructor.sanitize(value);
    //this.domNode.setAttribute('href', args.href);
    this.domNode.setAttribute('uuid', args.uuid);
    this.domNode.setAttribute('type', 'noteBlot');
    this.domNode.setAttribute('accepted', args.accepted);
    this.domNode.setAttribute('rejected', args.rejected);
    this.domNode.setAttribute('editorPinned', args.editorPinned);
    this.domNode.setAttribute('requestorPinned', args.requestorPinned);
    this.domNode.setAttribute('editorComment', args.editorComment);
  }

  // deleteAt() {
  //   return false
  // }



  







}
NoteBlot.blotName = 'noteBlot';
NoteBlot.tagName = 'span';
NoteBlot.className = 'noteBlot';
//EditingDeleteBlot.SANITIZED_URL = 'about:blank';
//EditingDeleteBlot.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];





export { NoteBlot as default };
