import React from 'react';

const AppContext = React.createContext({
    
});



export const AppContextProvider = AppContext.Provider;

export default AppContext