import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Divider, Grid, Stack, styled, Typography } from "@mui/material";
import { Paper } from "@mui/material";
import StandardDashboardPaper from "../StandardPaper";
import { StandardPaper } from "../StandardPaper";
import { useTheme } from '@mui/material/styles';

const DashboardCard = (props) => {
    //export default function DashboardCard(props) {

    let { title, count } = props;
    const theme = useTheme();

    return (

        <StandardDashboardPaper sx={{
            width: '100%',
            position: 'relative',
            //height: '8em',
            borderRadius: '8px',
            //marginTop: '1.75em',
            paddingBottom: '0.5em',
            marginBottom: '1em',
        }}>
            {!props.hideIcon &&
                <StandardPaper variant='elevation' elevation={1} sx={{
                    width: '3em',
                    height: '3em',
                    position: 'absolute',
                    top: '.5em',
                    right: '1em',
                    backgroundColor: props.color,
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    //border:'1px solid gray'


                }}>
                    {props.icon}
                </StandardPaper>
            }

            <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                marginLeft: '1em',
                marginRight: '1em',
                columns: '100'
            }}>
                <Box sx={{
                    flex: 70
                }}>
                    <Typography variant={'h6'} sx={{ color: theme.palette.secondary.main }}> {title} </Typography>
                    <Typography variant={'h5'} sx={{
                        color: theme.palette.secondary.main,
                        fontWeight: 'bold'
                    }}> {count} </Typography>
                    <Box sx={{
                        marginTop:'3em'
                    }}>

                        {props.children}

                    </Box>
                </Box>
                {/* <Divider sx={{

                }} />
                <Box sx={{
                    flex:30
                }}>
                    A relevant message
                </Box> */}
            </Stack>
        </StandardDashboardPaper>
    );
}

export default inject('stores')(observer(DashboardCard));
