const nunjucks = require('nunjucks');
const mobx = require('mobx');
const RecipeUtils = require('./RecipeUtils').RecipeUtils;
let beautify_js = require('js-beautify'); // also available under "js" export
let beautify_css = require('js-beautify').css;
let beautify_html = require('js-beautify').html;

class PropsHolder{

	props = {
		"param2":"foo2"
	};

	recipeUtils = new RecipeUtils();

	constructor() {

	}

	

	setProp = (name,value) => {
		this.props[name] = value;
	}

	stringifyProps = (name,value) => {
		return JSON.stringify(this.props);
	}
	
	toJson = (s) => {

		try{
			if (typeof s === 'object' && s !== null) {
				return s;
			 } else {
			   return JSON.parse(s);
			 }

		
		}catch(err){
			console.log(err);
		}
		
	}

	addRecipeProp = (recipe, documentPart, paramPath, defaultValue) => {
		let value = this.recipeUtils.getRecipeParamByPartType(null, recipe, documentPart, paramPath, defaultValue);
		
		if(this.props[paramPath] == undefined ){
			this.props[paramPath] = value;
		}
		
	}

	mergeOverrides = (overrides) => {
		try{
			let overridesJson = JSON.parse(overrides);
			Object.keys(overridesJson).forEach((key)=>{
				this.props[key] = overridesJson[key];
			})
		}catch(err){
			console.log(err);
		}
	}

}

export class BuildManager {



	constructor() {

		let env = nunjucks.configure({
			autoescape: false
		});

		//let env = nunjucks.configure();

		env.addFilter('toObject', function (objectString) {
			return JSON.parse(objectString);
		})

		env.addFilter('getRecipeParamByPartType', function (target, recipe, documentPart, paramPath, defaultValue) {

			//let currentRecipe = props.documentPart?.getRecipe().json;
			let value = new RecipeUtils().getRecipeParamByPartType(target, recipe, documentPart, paramPath, defaultValue)
			return value;
		})

		env.addFilter('createPropsHolder', function (target) {

			
			return new PropsHolder();
		})

		env.addFilter('setJsonValue', function (target, name, value) {

			
			target[name] = value;
		})

		



	}

	bookMerlinContext = {



		defaults: {
	
			params: {
				global_showOrnamentalBreaks: {
					value: false,
					title: 'Show Ornamental Break',
					type: 'boolean'
				},
				global_showChapterTitles: {
					value: true,
					title: 'Show Chapter Titles',
					type: 'boolean'
				},
				global_showPartTitles: {
					value: true,
					title: 'Show Part Titles',
					type: 'boolean'
				},
				defaultOrnamentalBreaksTemplate: {
					value: 'template_ornamental_break_001',
					type: 'template',
					title: 'Ornamental Break Template',
					description: '' //provide only if this is not overriding a global param
				},
				defaultBlurbsTemplate: {
					value: 'template_blurb_001',
					type: 'template',
					title: 'Blurb Template',
					description: '' //provide only if this is not overriding a global param
				}
			},
			templates: {
	
				styles: {
					primaryStyle: {
						paramId: 'param_123',
						name: 'templateId',
						value: 'template_primaryStyle_001',
						description: 'A page template for a PART'
					}
				},
				sections: {
	
					part: {
	
						page: {
							id: 'param_123',
							name: 'templateId',
							value: '1234-9876-2222-4444',
							description: 'A page template for a PART'
						},
						body: {
							id: 'param_123',
							name: 'templateId',
							value: 'default_template_part_body_001',
							description: 'A body template for a PART'
						}
	
					},
					chapter: {
						page: {
							id: 'param_123',
							name: 'templateId',
							value: '1234-9876-2222-4444',
							description: 'A page template for a CHAPTER'
						},
						body: {
							id: 'param_123',
							name: 'templateId',
							value: 'default_template_chapter_body_001',
							description: 'A body template for a CHAPTER'
						}
					}
				}
			}
		}
	}

	getRecipeTemplates(recipe) {
		let templates = new Array();
		//////console.log(recipe.templates);
		if (recipe /*&& recipe.templates*/) {
			//////console.log(recipe.templates);
			templates = this._extractTemplates(recipe);
		}

		//////console.log(templates);
		return templates;
	}

	_extractTemplates(a) {

		//////console.log("a:");
		//////console.log(a);
		let templates = new Array();
		let keys = Object.keys(a)
		keys.forEach((key) => {
			//////console.log("key:"+key);
			let element = a[key];
			//////console.log(Object.keys(element));
			//////console.log(typeof element);
			if (typeof element == 'object') {
				//continue;

				if (element.type == 'template') {
					templates.push(element);
					//////console.log('pushing:'+key)
				} else {
					let childKeys = Object.keys(element);
					//////console.log('deeper into:'+Object.keys(element));
					//////console.log(childKeys);
					templates = templates.concat(this._extractTemplates(element));
				}
			}

		})

		return templates;
	}

	createUserBuild({ userId, recipe }) {
		////console.log('createUserBuild()...');
	}

	//Loads the recipe and other things
	inflateUserBuild({ userBuild }) {

	}

	getPartTemplate({ userBuild, partType }) {

	}

	generateMacros(documentPart, subTemplates, inflatedTemplates, context, recipe, buildContext) {

		/*
		subTemplates:[
			{
				macroName:'chapterHeader',
				templateId:'template_chapter_header_001',
				subTemplates:{

				}
			}
		]
		*/
		let that = this;
		let macros = '';

		if (!subTemplates) {
			return macros;
		}

		/*
		////console.log('global macros...')
		////console.log(recipe.macros);
		if(recipe.macros){
			recipe.macros.forEach((macro)=>{
				subTemplates.push(macro);
			})
		}*/

		subTemplates.forEach((subTemplate) => {

			//////console.log(subTemplate);
			let templateId = subTemplate.templateId;

			if (templateId.startsWith('param.')) {
				let paramId = templateId.substring(6, templateId.length);
				//////console.log('paramId:'+paramId);

				//first get the global templateId
				try {
					templateId = context.defaults.params[paramId].value;
					//////console.log(templateId);
				} catch (err) {
					//////console.log(err);
					////console.log('error here. Possibly ignorable');
				}

				//see if the recipe overrides it
				try {
					templateId = recipe.params[paramId].value;
					//////console.log(templateId);
				} catch (err) {
					////console.log(err);
				}

			}

			if (templateId.startsWith('props.')) {
				let propPath = templateId.substring(6, templateId.length);
				let propValue = new RecipeUtils().getRecipeParamByPartType(null, recipe, documentPart, propPath);
				//console.log('propPath:' + propPath);

				templateId = propValue;

			}

			//////console.log(templateId);
			let inflatedTemplate = inflatedTemplates[templateId];
			let macroParams = '(';

			//The inflated template could be missing if the param holds the id of a 
			//template that has been removed. Or it could hold an old test value like
			//template_block_quotes_001
			if (!inflatedTemplate) {

				inflatedTemplate = inflatedTemplates[subTemplate.defaultTemplateId];
			}
			if (inflatedTemplate) {


				if (inflatedTemplate.params) {

					//name="name1", value="value1", type="type1"
					//send the documentPart param down to every template
					//macros+='documentPartx'+(inflatedTemplate.params && inflatedTemplate.params.length>0)?',':''
					inflatedTemplate.params.forEach((param, count) => {

						if (param.type && param.type == 'reference') {
							macroParams += ' ' + param.name;
							if (param.defaultValue) {
								//macroParams+='='+param.defaultValue;
							}
						} else {
							macroParams += ' ' + param.name;
							if (param.defaultValue != null) {
								if (param.type && param.type == 'boolean') {
									//macroParams+='='+param.defaultValue+' ';
								} else {
									//macroParams+='="'+param.defaultValue+'" ';
								}

							}
						}


						if (count < (inflatedTemplate.params.length - 1)) {
							macroParams += ',';
						}

					});
				}
				macroParams += ')';
				let macro = '{% macro ' + subTemplate.macroName + macroParams + ' %}' +
					//'<div>'+
					inflatedTemplate.content +
					//'</div>'+
					'{% endmacro %}';

				macros += macro;

				if (inflatedTemplate.subTemplates) {
					macros += that.generateMacros(documentPart, inflatedTemplate.subTemplates, inflatedTemplates, context, recipe, buildContext);
				}

			}












			//macroParams = null;
		});


		return macros;
	}

	generateStyleContent({  buildType, bookDraftPojo, userPojo, inflatedTemplates, allBookPojos, requiredResources }) {






		let recipePojo = bookDraftPojo.book.recipe;
		//let recipe = JSON.parse(recipePojo.json);
		let recipe = recipePojo.json;
		let styleTemplateConfig = {};
		let templateContent = '';
		let processedContent = '';
		let inflatedTemplate = null;
		let styleContent = '';
		let subTemplates = [];
		//First load the style sheet defined in the recipe
		try {

			//styleTemplateConfig = recipe.templates.styles.primaryStyle;
			styleTemplateConfig = recipe.templates.styles[buildType];
			//console.log(epubType);
			if (!styleTemplateConfig) {
				styleTemplateConfig = this.bookMerlinContext.defaults.templates.styles.primaryStyle;
			}

			let styleInflatedTemplate = inflatedTemplates[styleTemplateConfig.value];
			subTemplates = Array.from(styleInflatedTemplate.subTemplates);
			styleContent = styleInflatedTemplate.content;


		} catch (err) {
			//console.log(err);
		}


		try {


			let macros = this.generateMacros(null,subTemplates, inflatedTemplates, this.bookMerlinContext, recipe);
			//let templateId = bodyTemplateConfig.value;


			//console.log(macros);
			//console.log("\n")

			let params = {
				user: 'James',
				//content:content,
				//documentPart:documentPart,
				book: bookDraftPojo.book,
				requiredResources: requiredResources

			}

			params = this.mergeParams({
				params: params,
				recipe: recipe,
				context: this.bookMerlinContext
			});

			//processedContent = nunjucks.renderString(styleContent+macros+templateContent, params);
			//console.log(macros);
			//console.log(styleContent);
			processedContent = nunjucks.renderString(macros + styleContent, params);

			processedContent = beautify_css(processedContent, { indent_size: 2, space_in_empty_paren: true })




		} catch (err) {
			//do nothing
			console.log(err);
		}


		//console.log(bodyTemplateConfig);

		return processedContent;
	}

	generateBodyContent({ book, epubType, documentPart, content, buildContext, recipe, context, inflatedTemplates, allBooks, user, mappedImages, appendStyleToBody }) {

		if (!documentPart) {
			return '';
		}

		let allBooksNoMobx = allBooks.map(m => { return m });
		//1. Get body template from the recipe
		let partType = documentPart.partType == null ? 'NO_PART_TYPE' : documentPart.partType.toLowerCase();
		//alert(partType);
		let styleTemplateConfig = {};
		let bodyTemplateConfig = {};
		let templateContent = '';
		let processedContent = '';
		let inflatedTemplate = null;
		let styleInflatedTemplate = null;
		let styleContent = '';
		let parseError = null;
		//First load the style sheet defined in the recipe
		try {

			styleTemplateConfig = recipe.templates.styles.primaryStyle;
			if (!styleTemplateConfig) {
				styleTemplateConfig = context.defaults.templates.styles.primaryStyle;
			}

			styleInflatedTemplate = inflatedTemplates[styleTemplateConfig.value];
			styleContent = styleInflatedTemplate.content;

			//////console.log(styleContent);
		} catch (err) {
			////console.log('err');
		}


		/*templates:{

		styles:{
			primaryStyle:{
				paramId:'param_123',
				name:'templateId',
				value:'1234-9876-2222-4444',
				description:'A page template for a PART'
			}
		},*/

		try {

			bodyTemplateConfig = recipe.templates.sections[partType] ? recipe.templates.sections[partType].body : null;
			//alert(bodyTemplateConfig);
			if (!bodyTemplateConfig) {
				bodyTemplateConfig = context.defaults.templates.sections[partType] ? context.defaults.templates.sections[partType].body : null;
			}

			//////console.log(bodyTemplateConfig);
			//////console.log("body template config......\n")
			inflatedTemplate = inflatedTemplates[bodyTemplateConfig.value];

			//Simulates getting the body template by partType. This will later be changed in 
			//the recipe for each partType using: context.defaults.templates.sections[partType].body


			//inflatedTemplate = inflatedTemplates["a2daca1d-f007-4697-a443-a29d0f4cb4e5"];

			let subTemplates = Array.from(inflatedTemplate.subTemplates);
			let styleSubTemplates = Array.from(styleInflatedTemplate.subTemplates);

			subTemplates = subTemplates.concat(styleSubTemplates);
			////console.log('global macros...')
			//////console.log(recipe.macros);
			if (recipe.macros) {
				recipe.macros.forEach((macro) => {
					subTemplates.push(macro);
				})
			}


			//let macros = this.generateMacros(inflatedTemplate.subTemplates,inflatedTemplates,context,recipe);
			let macros = this.generateMacros(documentPart, subTemplates, inflatedTemplates, context, recipe, buildContext);
			//////console.log(macros);
			subTemplates = null;
			let templateId = bodyTemplateConfig.value;
			templateContent = inflatedTemplate.content;


			//////console.log(macros);
			//////console.log("\n")
			try {
				book.bookDraft = documentPart.bookDraftDocumentPart.bookDraft;
			} catch (err) {

			}
			book.epubType = epubType;
			let params = {
				//user: 'James',
				content: content,
				documentPart: documentPart,
				book: book,
				epubType: epubType,
				allBooks: allBooksNoMobx,
				user: user,
				nunjucks: nunjucks,
				macros: macros,
				buildContext: buildContext,
				mappedImages: mappedImages,
				//imagesRoot:config.default.images.RESIZER_GATEWAY
				//imagesRoot: 'https://c22t1rqd15.execute-api.us-east-1.amazonaws.com/dev',
				epubImagesRoot: './images'
			}


			params = this.mergeParams({
				params: params,
				recipe: recipe,
				context: context
			});

			params.recipe = recipe;
			//////console.log(params);
			//processedContent = nunjucks.renderString(macros + styleContent + templateContent, params);
			
			if(appendStyleToBody){
				processedContent = nunjucks.renderString(macros + styleContent + templateContent, params);
			
			}else{
				processedContent = nunjucks.renderString(macros + templateContent, params);
			
			}



		} catch (err) {
			//do nothing
			//console.log(err);
			parseError = err
		}


		//////console.log(bodyTemplateConfig);

		//return processedContent;

		return {
			styleContent: styleContent,
			bodyContent: processedContent,
			parseError
		}


	}



	mergeParams({ params, recipe, context }) {

		let newParams = {};

		if (context?.defaults?.params) {

			let paramIds = Object.keys(context.defaults.params);
			paramIds.forEach((paramId) => {

				newParams[paramId] = context.defaults.params[paramId].value;
			})
		}

		if (recipe) {

			let paramIds = Object.keys(recipe.params);
			paramIds.forEach((paramId) => {

				newParams[paramId] = recipe.params[paramId].value;
			})
		}

		if (params) {

			let paramIds = Object.keys(params);
			paramIds.forEach((paramId) => {

				newParams[paramId] = params[paramId];
			})
		}

		return newParams;


	}

	getParamCaseIndependant({ paramId, recipe }) {

		let mappedParams = {};
		try {

			Object(recipe.params).keys.forEach((key) => {
				let recipeParam = recipe.params[key];
				mappedParams[key.toLowerCase()] = recipeParam;
			})
		} catch (err) {
			//console.log(err);
		}

		let param = mappedParams[paramId.toLowerCase()];

		return param;

	}

	getParam({ element, paramId, paramSource, context, recipe, recipeObject }) {


		////console.log('getParam()...');
		if (!recipe) {
			return;
		}
		//let param = {};

		/*let contextParam = context.defaults.params[paramId];
		
		if(contextParam){
			let keys = Object.keys(contextParam);
			keys.forEach((key)=>{
				param[key] = contextParam[key]
			});
			
		}*/
		if (paramSource && paramSource.toLowerCase() == 'global') {

			let globalParam = context.defaults.params[paramId];
			return globalParam;
		}

		/*
		global_showOrnamentalBreaks:{
				value:true,
				title:'Show Ornamental Break',
				type:'boolean'
			},
		*/
		if (paramSource && paramSource.toLowerCase() == 'recipe') {

			// let foundParamId = null;
			// Object.keys(recipe.params).forEach((key)=>{

			// 	//console.log(key.toLowerCase());
			// 	//console.log(paramId.toLowerCase());
			// 	if(key.toLowerCase()==paramId.toLowerCase()){
			// 		foundParamId = key;
			// 	}

			// });

			let recipeParam = recipeObject.getParamByLowerCase(paramId.toLowerCase());
			//let recipeParam = recipe.params[paramId];
			//let recipeParam = recipe.params[foundParamId];

			let globalParam = {};

			try {
				globalParam = context.defaults.params[paramId];
				if (globalParam) {
					recipeParam.type = globalParam.type;
					recipeParam.title = globalParam.title;
				}

			} catch (err) {
				//////console.log(err);
			}

			try {
				if (recipeParam.newName) {
					recipeParam = recipe.params[recipeParam.newName];
				}
			} catch (err) {
				//console.log(err);
				return this.getParamCaseIndependant({ recipe, paramId });
			}
			return recipeParam;
		}

		//if(paramSource && paramSource.toLowerCase()=='part'){

		/*defaultOrnamentalBreaksTemplate:{
		value:'template_ornamental_break_002',
		type:'template',
		title:'Ornamental Break Template',
		templateTypeTag:'TEMP_ornBreak',
		description:'defaultOrnamentalBreaksTemplate' //provide only if this is not overriding a global param
	}

	paramId,paramSource,context,recipe


	{
		label:'USE_PARAM_LABEL',
		useParamLabel:true,
		paramId:'blurbs',
		source:'BOOK', //BUILD,GLOBAL,RECIPE,BOOK,DRAFT,PART
		title:'Book blurbs',
		type:'blurbs'
	}



	*/

		return {
			//value:'',
			type: element.type,
			title: element.title
			//templateTypeTag:'',
			//description:''
		}
		//}



	}


	getThemeTag({ context, recipe, inflatedTemplates }) {

		let themeTag = null;
		try {

			let defaultThemeTemplateParam = this.getParam({
				paramId: 'defaultThemeTemplate',
				paramSource: 'RECIPE',
				context: context,
				recipe: recipe.json,
				recipeObject: recipe
			});

			if (defaultThemeTemplateParam && inflatedTemplates) {
				let themeTemplate = inflatedTemplates[defaultThemeTemplateParam.value];
				////console.log(themeTemplate);
				if (themeTemplate) {
					themeTag = themeTemplate.themeTag;
				}
			}

		} catch (err) {
			////console.log(err);
		}


		return themeTag;

	}
	getTemplatesByTag({ context, recipe, inflatedTemplates, tag, themeTag }) {

		//////console.log('getTemplatesByTag()...'+tag);
		let templates = {};





		if (inflatedTemplates) {

			let it = mobx.toJS(inflatedTemplates);
			let templateIds = Object.keys(it);

			templateIds.forEach((templateId) => {
				//////console.log(templateId);
				//newParams[paramId] = context.defaults.params[paramId].value;
				let template = inflatedTemplates[templateId];
				//template = mobx.toJS(template);
				//////console.log(template.toJs);
				let tags = template.tags;
				//////console.log(tags);
				if (tags) {
					tags.forEach((t) => {
						if (t == tag && templates[templateId] == null) {

							if (themeTag) {
								//now cycle through all tags again looking for any themeTags
								tags.forEach((t2) => {
									if ((t2 == themeTag || t2 == 'THEME_ALL') && templates[templateId] == null) {
										templates[templateId] = template;
									}
								});
							} else {
								templates[templateId] = template;
							}

						}
					});
				}
			})

		}

		return templates;
	}


	preProcessPropertyPanelConfig({
		propertyPanelConfig,
		context,
		recipe,
		recipeObject
	}) {

		let that = this;
		if (propertyPanelConfig) {
			propertyPanelConfig.elements.forEach((el) => {
				//propertyPanelElements = propertyPanelDefinitions.byPartType[this.props.documentPart.partType.toLowerCase()].elements.map((el)=>{

				/*
			{
					label:'USE_PARAM_LABEL',
					useParamLabel:true,
					paramId:'blurbs',
					source:'BOOK', //BUILD,GLOBAL,RECIPE,BOOK,DRAFT,PART
					title:'Book blurbs',
					type:'blurbs'
				}
				*/
				let param = that.getParam({
					element: el,
					paramId: el.paramId,
					paramSource: el.source,
					context: context,
					recipe: recipe,
					recipeObject: recipeObject
				});
			})
		}

	}


}



//exports.BuildManager=BuildManager;
//exports.recipe1=recipe1;
//exports.bookMerlinContext=bookMerlinContext;
//exports.propertyPanelDefinitions=propertyPanelDefinitions;