import React, { useState, useEffect } from "react";
import { Box, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import config from '../../../aws-exports';
import StandardUITextField from "../../UI/StandardUITextField";
import StandardUISelect from "../../UI/StandardUISelect";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import StretchBox from '../../boilerPlate/StretchBox';
import ScrollBox from '../../boilerPlate/ScrollBox';
import StandardUIDialog from "../../UI/StandardUIDialog";
import StandardUIButton from "../../UI/StandardUIButton";
import { useUpdateItem } from '../../../persistance/persistanceHooks';


const BookDetails = (props) => {

    let { book } = props;

    const [updateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] = useUpdateItem();

    const [openDelete, setOpenDelete] = React.useState(false);
    const handleOpenDelete = () => {
        setOpenDelete(true);
    };

    const handleCloseDelete = (value) => {
        setOpenDelete(false);
    };

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [selection, setSelection] = React.useState('English');

    const handleChange = (event) => {
        setSelection(event.target.value);
    };

    return (
        <StandardUIDialog
            sx={{width: '100%'}}
            open={props.open}
            onClose={props.onClose}
            title={book?.title}
            dialogContent={
                <StretchBox>
                    <ScrollBox>
                        <Typography variant={theme.secondLevelText.variant}>Details</Typography>
                        <StandardUITextField fullWidth sx={{ m: 1 }} label="Title" value={props.title} variant="outlined" />
                        <StandardUITextField fullWidth sx={{ m: 1 }} label="Subitle" variant="outlined" />
                        <StandardUITextField fullWidth sx={{ m: 1 }} label="Author" variant="outlined" />
                        <StandardUITextField fullWidth sx={{ m: 1 }} label="eBook ISBN" variant="outlined" />
                        <StandardUITextField fullWidth sx={{ m: 1 }} label="Print ISBN" variant="outlined" />
                        <StandardUISelect
                            inputLabel={'Language'}
                            content={<Box>
                                <Select
                                    value={selection}
                                    onChange={handleChange}
                                    label={props.selectLabel}
                                >
                                    <MenuItem value={'Catalan'}>Catalan</MenuItem>
                                    <MenuItem value={'Chinese (Simplified)'}>Chinese (Simplified)</MenuItem>
                                    <MenuItem value={'Chinese (Traditional)'}>Chinese (Traditional)</MenuItem>
                                    <MenuItem value={'Czech'}>Czech</MenuItem>
                                    <MenuItem value={'Danish'}>Danish</MenuItem>
                                    <MenuItem value={'Dutch'}>Dutch</MenuItem>
                                    <MenuItem value={'English'}>English</MenuItem>
                                    <MenuItem value={'Finnish'}>Finnish</MenuItem>
                                    <MenuItem value={'French'}>French</MenuItem>
                                    <MenuItem value={'German'}>German</MenuItem>
                                    <MenuItem value={'Hindi'}>Hindi</MenuItem>
                                    <MenuItem value={'Hungarian'}>Hungarian</MenuItem>
                                    <MenuItem value={'Icelandic'}>Icelandic</MenuItem>
                                    <MenuItem value={'Italian'}>Italian</MenuItem>
                                    <MenuItem value={'Japanese'}>Japanese</MenuItem>
                                    <MenuItem value={'Korean'}>Korean</MenuItem>
                                    <MenuItem value={'Norwegian Bokmal'}>Norwegian Bokmal</MenuItem>
                                    <MenuItem value={'Polish'}>Polish</MenuItem>
                                    <MenuItem value={'Portuguese'}>Portuguese</MenuItem>
                                    <MenuItem value={'Romanian'}>Romanian</MenuItem>
                                    <MenuItem value={'Russian'}>Russian</MenuItem>
                                    <MenuItem value={'Spanish'}>Spanish</MenuItem>
                                    <MenuItem value={'Swedish'}>Swedish</MenuItem>
                                    <MenuItem value={'Turkish'}>Turkish</MenuItem>
                                    <MenuItem value={'Vietnamese'}>Vietnamese</MenuItem>
                                </Select>
                            </Box>}
                        />
                        <Divider sx={{ margin: '1em 0' }} />
                        <Typography variant={theme.secondLevelText.variant}>Stores</Typography>
                        <StandardUITextField fullWidth sx={{ m: 1 }} label="Kindle" variant="outlined" />
                        <StandardUITextField fullWidth sx={{ m: 1 }} label="iBooks" variant="outlined" />
                        <StandardUITextField fullWidth sx={{ m: 1 }} label="Google Name" variant="outlined" />
                        <StandardUITextField fullWidth sx={{ m: 1 }} label="Kobo" variant="outlined" />
                        <StandardUITextField fullWidth sx={{ m: 1 }} label="Nook" variant="outlined" />
                        <StandardUITextField fullWidth sx={{ m: 1 }} label="Other" variant="outlined" />
                        <Divider sx={{ margin: '1em 0' }} />
                        <StandardUIButton 
                            buttonAction='Delete'
                            onClick={handleOpenDelete}
                        />
                        <StandardUIDialog 
                            title='Delete Book'
                            open={openDelete}
                            onClose={handleCloseDelete}
                            dialogContent={
                                <div>
                                    <Typography>Deleting will send this book to the Trash page, where it can be permanently deleted.</Typography>
                                    <Typography>Please confirm you want to delete this book below.</Typography>
                                    <StandardUITextField 
                                        label='Delete Book'
                                    />
                                </div>
                            }
                            cardActions={
                                <StandardUIButton 
                                    buttonAction='Delete Book'
                                    onClick={() => {
                    
                                        updateItem({
                                            itemExpectedVersion: 1,
                                            itemKey: book.id,
                                            //itemSortKeyName: 'documentPartId',
                                            //itemSortKeyValue: props.userComment.documentPartId,
                                            itemType: 'Book',
                                            fieldName: 'deletedAt',
                                            fieldValue: 'now',
                                            refetchQueries:['listBooks'],
                                            onCompleted: () => {
                    
                                                //props.userComment.isPinned = !props.userComment.isPinned
                                                props.stores.bookStore.bookListBook = null;
                                            }
                                        })
                    
                                        
                    
                                        setOpenDelete(false);
                                    }}
                                />
                            }
                        />
                    </ScrollBox>
                </StretchBox>}
        />
    )
}

export default inject('stores')(observer(BookDetails));