import React, { useState, useEffect } from "react";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import StandardUIDialog from "../../UI/StandardUIDialog";
import StandardUIButton from "../../UI/StandardUIButton";
import StandardUITextField from "../../UI/StandardUITextField";
import { useManagerCreateUserGroup } from "../../../managers/ConnectionsManager";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CreateGroup = (props) => {

    let user = props.stores.userStore.user;

    let [name, setName] = useState(null);
    let [description, setDescription] = useState(null);

    let { createUserGroup, loading: shareBookWithUserGroupLoading, error: shareBookWithUserGroupError } = useManagerCreateUserGroup(props);


    const handleNameChange = (e) => {
        e.preventDefault();

        setName(e.target.value);
    }

    const handleDescriptionChange = (e) => {
        e.preventDefault();

        setDescription(e.target.value);
    }
    return (
        <StandardUIDialog
            open={props.open}
            onClose={props.onClose}
            title='Create a Group!'
            dialogContent={
                <Box>
                    <StandardUITextField label={'Name'} onChange={handleNameChange} value={props.name} />
                    <StandardUITextField label={'Description'} onChange={handleDescriptionChange} value={props.description} />
                    {shareBookWithUserGroupLoading && <CircularProgress />}
                </Box>
            }
            cardActions={
                <StandardUIButton
                    buttonAction='Create'
                    autoFocus
                    onClick={(e) => {
                        e.preventDefault();
                        createUserGroup({
                            name,
                            description
                        }, () => {

                            //onSuccess
                            if (props.onGroupCreated) {
                                props.onGroupCreated();
                            }

                        }, () => {

                            //onError

                        })
                        props.onClose()
                    }}
                />
            }
        />
    )
}

export default inject('stores')(observer(CreateGroup));