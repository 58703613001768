import React, { useState, useEffect } from "react";
import { inject, observer } from 'mobx-react'; import config from '../../../aws-exports';
import StandardUIDialog from "../../UI/StandardUIDialog";
import StandardUIButton from "../../UI/StandardUIButton";
import StandardUITextField from "../../UI/StandardUITextField";
import { useManagerCreateInvitation, useGetUserConnections, useManagerAcceptInvitation } from '../../../managers/ConnectionsManager';
import InviteConnection from "./InviteConnection";


const InviteConnectionDialog = (props) => {



    return (
        <StandardUIDialog
            open={props.open}
            onClose={props.onClose}
            title='Invite a New Connection!'
            dialogContent={
                <InviteConnection
                    // onSent={props.onSent}
                />
            }
        />
    )
}

export default inject('stores')(observer(InviteConnectionDialog));