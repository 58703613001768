const config = require('../../aws-exports').default;




const layout = {

	boxset: {

		sections: [
			{
				title: 'BoxSet Details',
				elements: [
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_MAIN_BOOK',
						entityFieldName: 'title',
						label: 'Boxset Title',
						placeholder: 'Boxset Title',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_MAIN_BOOK',
						entityFieldName: 'subTitle',
						label: 'Boxset Subtitle',
						placeholder: 'Boxset Subtitle',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_MAIN_BOOK',
						entityFieldName: 'authorName',
						label: 'Author Name',
						placeholder: 'Author Name',
						canEdit: 'ITEM_OWNER'
					}
				]
			},
			{
				title: 'BoxSet Images',
				elements: [
					{
						type: 'SUBHEADING',
						title: 'Cover Image',
						addTopMargin: false
					},
					{
						type: 'IMAGE_SELECTOR',
						title: 'Cover Image',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_MAIN_BOOK',
						entityImageSourceFieldName: 'coverUrl',
						entityImageMediaTypeFieldName: 'coverUrlType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'SUBHEADING',
						title: 'Thumbnail Image',
						addTopMargin: false
					},
					{
						type: 'IMAGE_SELECTOR',
						title: 'Thumbnail Image',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_MAIN_BOOK',
						entityImageSourceFieldName: 'coverThumbnailUrl',
						entityImageMediaTypeFieldName: 'coverThumbnailUrlType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER'
					}
				]
			}

		]

	},
	boxsetBook: {

		sections: [

			// {
			// 	title: 'Contributed Book Theme',
			// 	elements: [
			// 		{
			// 			type: 'SUBHEADING',
			// 			title: 'Theme Source',
			// 			addTopMargin: false
			// 		},
			// 		{
			// 			type: 'TOGGLE_BUTTONS',
			// 			title: 'Select recipe source',
			// 			paramType: 'ENTITY',
			// 			paramItem: 'BOXSETBOOK',
			// 			entityFieldName: 'recipeSource',
			// 			options: [
			// 				{
			// 					label: 'Boxset',
			// 					value: 'boxSetRecipe',
			// 					selectedMessage: 'Use the boxset\'s theme for this contributed book.',

			// 				},
			// 				{
			// 					label: 'Book',
			// 					value: 'originalBookRecipe',
			// 					selectedMessage: 'Use the book\'s theme for this contributed book.',

			// 				},
			// 				{
			// 					label: 'Custom',
			// 					value: 'overrideBookRecipe',
			// 					selectedMessage: 'Use a custom theme for this contributed book.',

			// 				}
			// 			],
			// 		}
			// 	]
			// },
			// {
			// 	title: 'Contributed Book Cover Page Style',
			// 	elements: [
			// 		{
			// 			type: 'TEMPLATE_SELECTOR',
			// 			canEdit: 'ITEM_OWNER',
			// 			title: 'Book Template',
			// 			//configType:'LOOKUP',
			// 			configType: 'STANDARD',
			// 			//configDetails:'HEADER_BY_PART_TYPE',
			// 			recipeParamName: 'defaultBookContentTemplate',
			// 			templateSourceTag: 'TEMP_bookContent',
			// 			hideLabel: true

			// 		}
			// 	],
			// 	sectionVisibilityConditions: [{
			// 		visible: true,
			// 		conditionType: 'IS_INSERTED_BOOK_CONTENT'
			// 	}],
			// 	notVisibleHelperMessage:'This is for inserted books content'
			// },
			{
				title: 'Contributed Book Details',
				elements: [
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_BOOK',
						entityFieldName: 'title',
						label: 'Title',
						placeholder: 'Book Title',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_BOOK',
						entityFieldName: 'subTitle',
						label: 'Subtitle',
						placeholder: 'Boxset Subtitle',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_BOOK',
						entityFieldName: 'authorName',
						label: 'Author Name',
						placeholder: 'Author Name',
						canEdit: 'ITEM_OWNER'
					}
				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'IS_INSERTED_BOOK_CONTENT'
				}],
				notVisibleHelperMessage: 'This is for inserted books content'
			},
			{
				title: 'Contributed Book Images',
				elements: [
					{
						type: 'SUBHEADING',
						title: 'Cover Image',
						addTopMargin: false
					},
					{
						type: 'IMAGE_SELECTOR',
						title: 'Book Cover Image',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_BOOK',
						entityImageSourceFieldName: 'coverUrl',
						entityImageMediaTypeFieldName: 'coverUrlType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'SUBHEADING',
						title: 'Thumbnail Image',
						addTopMargin: false
					},
					{
						type: 'IMAGE_SELECTOR',
						title: 'Thumbnail Image',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_BOOK',
						entityImageSourceFieldName: 'coverThumbnailUrl',
						entityImageMediaTypeFieldName: 'coverThumbnailUrlType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER'
					}
				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'IS_INSERTED_BOOK_CONTENT'
				}],
				notVisibleHelperMessage: 'This is for inserted books content'
			}

		]

	},
	book: {

		sections: [
			{
				title: 'Book Details',
				elements: [
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOOK',
						entityFieldName: 'title',
						label: 'Book Title',
						placeholder: 'Book Title',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Subtitle',
						paramType: 'ENTITY',
						paramItem: 'BOOK',
						entityFieldName: 'subTitle',
						label: 'Book Subtitle',
						placeholder: 'Book Subtitle',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOOK',
						entityFieldName: 'authorName',
						label: 'Author Name',
						placeholder: 'Author Name',
						canEdit: 'ITEM_OWNER'
					}
				]
			},
			{
				title: 'Book Images',
				elements: [
					{
						type: 'SUBHEADING',
						title: 'Cover Image',
						addTopMargin: false
					},
					// {         
					// 	type: 'IMAGE_UPLOAD_SELECTOR',
					// 	title: 'Cover Image',
					// 	paramType: 'ENTITY',
					// 	paramItem: 'BOOK',
					// 	entityFieldName: 'coverUrl',
					// 	bucketName: config.user_uploads_bucket_name,
					// 	// entityImageSourceFieldName: 'coverUrl',
					// 	// entityImageMediaTypeFieldName: 'coverUrlType',
					// 	identitySource: 'ITEM_OWNER',
					// 	canEdit: 'ITEM_OWNER'
					// },
					{
						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Cover Image',
						paramType: 'ENTITY',
						paramItem: 'BOOK',
						entityFieldName: 'coverUrl',
						//bucketName: config.user_uploads_bucket_name,
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// fileSource: 'userImages',
						// level: 'public',
						//This allows us to pick from a gallery and to upload an image in a single control.
						hideGalleryControls: true,
						imageUploaderConfig: {
							type: 'IMAGE_SELECTOR',
							//title: 'Book Common Image',
							paramType: 'RECIPE',
							//paramItem: 'BOOK',   
							bucketName: config.user_uploads_bucket_name,
							//folder: 'folder1',
							fileSource: 'userImages',
							// entityImageSourceFieldName: 'global_common_headingImageSrc',
							// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
							// recipeParamName: 'global_common_headingTitleImageSrc',
							// recipeMediaTypeParamName: 'global_common_headingImageMediaType',
							identitySource: 'ITEM_OWNER',
							canEdit: 'ITEM_OWNER'
						}
					},
					// selectorConfig: {
					// 	type: 'IMAGE_POPUP_SELECTOR',
					// 	title: 'Title Image',
					// 	paramType: 'RECIPE',
					// 	paramItem: 'BOOK',
					// 	bucketName: config.common_content_bucket_name,
					// 	folder: 'public/headerThumbnails',
					// 	fileSource: 'commonImages',
					// 	level: 'public',
					// 	recipeParamName: 'global_common_headingTitleImageSrc',
					// 	recipeMediaTypeParamName: 'global_common_headingTitleImageMediaType',
					// 	identitySource: 'ITEM_OWNER',
					// 	canEdit: 'ITEM_OWNER',

					// 	//This allows us to pick from a gallery and to upload an image in a single control.
					// 	imageUploaderConfig: {
					// 		type: 'IMAGE_SELECTOR',
					// 		title: 'Book Common Image',
					// 		paramType: 'RECIPE',
					// 		//paramItem: 'BOOK',   
					// 		bucketName: config.user_uploads_bucket_name,
					// 		folder: 'folder1',
					// 		fileSource: 'userImages',
					// 		// entityImageSourceFieldName: 'global_common_headingImageSrc',
					// 		// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
					// 		recipeParamName: 'global_common_headingTitleImageSrc',
					// 		recipeMediaTypeParamName: 'global_common_headingImageMediaType',
					// 		identitySource: 'ITEM_OWNER',
					// 		canEdit: 'ITEM_OWNER'
					// 	}
					// }
					{
						type: 'SUBHEADING',
						title: 'Thumbnail Image',
						addTopMargin: false
					},
					// {
					// 	type: 'IMAGE_UPLOAD_SELECTOR',
					// 	title: 'Thumbnail Image',
					// 	paramType: 'ENTITY',
					// 	paramItem: 'BOOK',
					// 	entityFieldName: 'coverThumbnailUrl',
					// 	bucketName: config.user_uploads_bucket_name,
					// 	// entityImageSourceFieldName: 'coverThumbnailUrl',
					// 	// entityImageMediaTypeFieldName: 'coverThumbnailUrlType',
					// 	identitySource: 'ITEM_OWNER',
					// 	canEdit: 'ITEM_OWNER'
					// },
					{
						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Cover Thumbnail',
						paramType: 'ENTITY',
						paramItem: 'BOOK',
						entityFieldName: 'coverThumbnailUrl',
						//bucketName: config.user_uploads_bucket_name,
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// fileSource: 'userImages',
						// level: 'public',
						//This allows us to pick from a gallery and to upload an image in a single control.
						hideGalleryControls: true,
						imageUploaderConfig: {
							type: 'IMAGE_SELECTOR',
							//title: 'Book Common Image',
							paramType: 'RECIPE',
							//paramItem: 'BOOK',   
							bucketName: config.user_uploads_bucket_name,
							//folder: 'folder1',
							fileSource: 'userImages',
							// entityImageSourceFieldName: 'global_common_headingImageSrc',
							// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
							// recipeParamName: 'global_common_headingTitleImageSrc',
							// recipeMediaTypeParamName: 'global_common_headingImageMediaType',
							identitySource: 'ITEM_OWNER',
							canEdit: 'ITEM_OWNER'
						}





					},
				]
			}

		]

	},
	page: {
		sections: [
			{
				title: 'Contributed Book Internal Cover Page Style',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: 'Book Template',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultBookContentTemplate',
						templateSourceTag: 'TEMP_bookContent',
						hideLabel: true

					}
				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'PART_TYPES',
					partTypes: ['BOOK']

				}],
				notVisibleHelperMessage: 'This is for inserted books content'
			},
			{
				title: 'Page Details',
				elements: [
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'title',
						label: 'Title',
						placeholder: 'Title',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Subtitle',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'subtitle',
						label: 'Subtitle',
						placeholder: 'Subtitle',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Author Name',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'authorName',
						label: 'Author Name',
						placeholder: 'Author Name',
						canEdit: 'ITEM_OWNER'
					}
				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'NOT_PART_TYPES',
					partTypes: ['TITLEPAGE', 'HALFTITLE', 'BOOK']

				}],
				notVisibleHelperMessage: 'The title, subtitle and authorname for these page types will come from the book or boxset values.'
			},
			{
				title: 'Page Visibility',
				elements: [
					{
						type: 'CHECKBOX',
						title: 'Exclude from book',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromBook',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Exclude from table of contents',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromToc',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Generic',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromGeneric',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Amazon',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromAmazon',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Apple',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromApple',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Google',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromGoogle',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Kobo',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromKobo',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Nook',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromNook',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Print',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromPrint',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
				]
			},
			{
				title: 'Page Background',
				elements: [
					// {
					// 	type: 'SUBHEADING',
					// 	title: 'Background Image Source',
					// 	addTopMargin: false
					// },
					// {
					// 	type: 'BACKGROUND_IMAGE_SOURCE_TOGGLE_BUTTONS',
					// 	title: 'Select recipe source',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 	options: [
					// 		{
					// 			label: 'Book Common',
					// 			value: 'common',
					// 			selectedMessage: 'Using the book common heading NOT-style for all ---PART_TYPE---s.',

					// 		},
					// 		{
					// 			label: '---PART_TYPE---s Specific',
					// 			value: 'partTypeSpecific',
					// 			selectedMessage: 'Using a separate heading NOT-style for all ---PART_TYPE---s.',

					// 		}
					// 	],
					// },
					{
						type: 'SUBHEADING',
						title: 'Visibility',
						addTopMargin: false
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all heading background images',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_hideAllHeadingBackgroundImages',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}],
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all ---PART_TYPE_LOWER--- heading background images',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_hideAllHeadingBackgroundImages',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}],
					},

					{
						type: 'CHECKBOX',
						title: 'Hide this ---PART_TYPE_LOWER---\'s heading image',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'hideHeadingBackgroundImage',
						checkedValue: "true",
						unCheckedValue: "false",
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},


					{
						type: 'SUBHEADING',
						title: 'Background Image Source',
						addTopMargin: false
					},
					{
						type: 'IMAGE_SOURCE_SELECTOR',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingBackgroundImageSourceType',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
						imageSelectors: [
							{
								label: 'All Pages',
								key: 'book',
								selectedMessage: 'All ---PART_TYPE---s will use the books common heading background image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: 'Book Common Image',
									paramType: 'RECIPE',
									//paramItem: 'BOOK',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									// entityImageSourceFieldName: 'global_common_headingImageSrc',
									// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
									recipeParamName: 'global_common_headingBackgroundImageSrc',
									recipeMediaTypeParamName: 'global_common_headingImageMediaType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'RECIPE',
										//paramItem: 'BOOK',   
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										recipeParamName: 'global_common_headingBackgroundImageSrc',
										recipeMediaTypeParamName: 'global_common_headingImageMediaType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							},
							{
								label: 'All ---PART_TYPE---s',
								key: 'type',
								selectedMessage: 'All ---PART_TYPE---s will use this image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: '---PART_TYPE---s Specific Image',
									paramType: 'RECIPE',
									//paramItem: 'BOOK',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSrc',
									recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'RECIPE',
										//paramItem: 'BOOK',   
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										// entityImageSourceFieldName: 'global_common_headingImageSrc',
										// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
										recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSrc',
										recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							},
							{
								label: 'This Page',
								key: 'self',
								selectedMessage: 'Each ---PART_TYPE--- will provide a unique heading background image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: 'This ---PART_TYPE---\'s Specific Image',
									paramType: 'ENTITY',
									paramItem: 'DOCUMENTPART',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									entityImageSourceFieldName: 'headingBackgroundUrl',
									entityImageMediaTypeFieldName: 'headerImageType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'ENTITY',
										paramItem: 'DOCUMENTPART',
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										entityFieldName: 'headingBackgroundUrl',
										//entityImageMediaTypeFieldName: 'headerImageType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							}
						],
					},
					// {
					// 	type: 'IMAGE_SOURCE_SELECTOR',
					// 	title: '',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_common_headingBackgroundImageSourceType',
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'common'
					// 	}],
					// 	imageSelectors: [
					// 		{
					// 			label: 'All Pages',
					// 			key: 'book',
					// 			selectedMessage: 'All ---PART_TYPE---s will use the books common heading background image.',
					// 			selectorConfig: {
					// 				type: 'IMAGE_SELECTOR',
					// 				title: 'Book Common Image',
					// 				paramType: 'RECIPE',
					// 				//paramItem: 'BOOK',
					// 				bucketName: config.user_uploads_bucket_region,
					// 				folder: 'folder1',
					// 				// entityImageSourceFieldName: 'global_common_headingImageSrc',
					// 				// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
					// 				recipeParamName: 'global_common_headingBackgroundImageSrc',
					// 				recipeMediaTypeParamName: 'global_common_headingImageMediaType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER',
					// 				imageUploaderConfig: {
					// 					type: 'IMAGE_SELECTOR',
					// 					title: 'Book Common Image',
					// 					paramType: 'RECIPE',
					// 					//paramItem: 'BOOK',   
					// 					bucketName: config.user_uploads_bucket_name,
					// 					folder: 'folder1',
					// 					fileSource: 'userImages',
					// 					recipeParamName: 'global_common_headingBackgroundImageSrc',
					// 					recipeMediaTypeParamName: 'global_common_headingImageMediaType',
					// 					identitySource: 'ITEM_OWNER',
					// 					canEdit: 'ITEM_OWNER'
					// 				}
					// 			}
					// 		},
					// 		{
					// 			label: 'This Page',
					// 			key: 'self',
					// 			selectedMessage: 'Each ---PART_TYPE--- will provide a unique heading background image.',
					// 			selectorConfig: {
					// 				type: 'IMAGE_SELECTOR',
					// 				title: 'This ---PART_TYPE---\'s Specific Image',
					// 				paramType: 'ENTITY',
					// 				paramItem: 'DOCUMENTPART',
					// 				bucketName: config.user_uploads_bucket_region,
					// 				folder: 'folder1',
					// 				entityImageSourceFieldName: 'headingBackgroundUrl',
					// 				entityImageMediaTypeFieldName: 'headerImageType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER',
					// 				imageUploaderConfig: {
					// 					type: 'IMAGE_SELECTOR',
					// 					title: 'Book Common Image',
					// 					paramType: 'ENTITY',
					// 					paramItem: 'DOCUMENTPART',
					// 					bucketName: config.user_uploads_bucket_name,
					// 					folder: 'folder1',
					// 					fileSource: 'userImages',
					// 					entityFieldName: 'headingBackgroundUrl',
					// 					//entityImageMediaTypeFieldName: 'headerImageType',
					// 					identitySource: 'ITEM_OWNER',
					// 					canEdit: 'ITEM_OWNER'
					// 				}
					// 			}
					// 		}
					// 	],
					// },
					// {
					// 	type: 'IMAGE_SOURCE_SELECTOR',
					// 	title: '',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSourceType',
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'partTypeSpecific'
					// 	}],
					// 	imageSelectors: [
					// 		{
					// 			label: 'All ---PART_TYPE---s',
					// 			key: 'type',
					// 			selectedMessage: 'All ---PART_TYPE---s will use this image.',
					// 			selectorConfig: {
					// 				type: 'IMAGE_SELECTOR',
					// 				title: '---PART_TYPE---s Specific Image',
					// 				paramType: 'RECIPE',
					// 				//paramItem: 'BOOK',
					// 				bucketName: config.user_uploads_bucket_region,
					// 				folder: 'folder1',
					// 				recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSrc',
					// 				recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER',
					// 				imageUploaderConfig: {
					// 					type: 'IMAGE_SELECTOR',
					// 					title: 'Book Common Image',
					// 					paramType: 'RECIPE',
					// 					//paramItem: 'BOOK',   
					// 					bucketName: config.user_uploads_bucket_name,
					// 					folder: 'folder1',
					// 					fileSource: 'userImages',
					// 					// entityImageSourceFieldName: 'global_common_headingImageSrc',
					// 					// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
					// 					recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSrc',
					// 					recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
					// 					identitySource: 'ITEM_OWNER',
					// 					canEdit: 'ITEM_OWNER'
					// 				}
					// 			}
					// 		},
					// 		{
					// 			label: 'This ---PART_TYPE---',
					// 			key: 'self',
					// 			selectedMessage: 'Each ---PART_TYPE--- will provide a unique heading background image.',
					// 			selectorConfig: {
					// 				type: 'IMAGE_SELECTOR',
					// 				title: 'This ---PART_TYPE---\'s Specific Image',
					// 				paramType: 'ENTITY',
					// 				paramItem: 'DOCUMENTPART',
					// 				bucketName: config.user_uploads_bucket_region,
					// 				folder: 'folder1',
					// 				entityImageSourceFieldName: 'headingBackgroundUrl',
					// 				entityImageMediaTypeFieldName: 'headerImageType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER',
					// 				imageUploaderConfig: {
					// 					type: 'IMAGE_SELECTOR',
					// 					title: 'Book Common Image',
					// 					paramType: 'ENTITY',
					// 					paramItem: 'DOCUMENTPART',
					// 					bucketName: config.user_uploads_bucket_name,
					// 					folder: 'folder1',
					// 					fileSource: 'userImages',
					// 					entityFieldName: 'headingBackgroundUrl',
					// 					//entityImageMediaTypeFieldName: 'headerImageType',
					// 					identitySource: 'ITEM_OWNER',
					// 					canEdit: 'ITEM_OWNER'
					// 				}
					// 			}
					// 		}
					// 	],
					// },
					{
						type: 'SUBHEADING',
						title: 'Image Span ( Vellums title, rename this )',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Single Page', value: 'singlePage' },
							{ label: 'Double Page', value: 'doublePage' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},



					{
						type: 'SUBHEADING',
						title: 'Background Text Color ( probably just white or black )',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'White', value: 'white' },
							{ label: 'Black', value: 'black' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'CHECKBOX',
						title: 'Full Page Bleed ( title ?)',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromBook',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'SUBHEADING',
						title: 'Opacity',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'zero', value: 'zero' },
							{ label: '25%', value: '25' },
							{ label: '50%', value: '50' },
							{ label: '75%', value: '75' },
							{ label: '95%', value: '95' },
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Bottom Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Left Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Right Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},


				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'NOT_PART_TYPES',
					partTypes: ['TITLEPAGE', 'HALFTITLE', 'BOOK']

				}],
				notVisibleHelperMessage: 'The title, subtitle and authorname for these page types will come from the book or boxset values.'
			},

		]
	},
	background: {


		sections: [
			{
				title: 'Page Background',
				elements: [

					{
						type: 'SUBHEADING',
						title: 'Visibility',
						addTopMargin: false
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all heading background images',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_hideAllHeadingBackgroundImages',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all ---PART_TYPE_LOWER--- heading background images',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_hideAllHeadingBackgroundImages',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'partTypeSpecific'
						// }],
					},

					{
						type: 'CHECKBOX',
						title: 'Hide this ---PART_TYPE_LOWER---\'s heading image',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'hideHeadingBackgroundImage',
						checkedValue: "true",
						unCheckedValue: "false",
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},


					{
						type: 'SUBHEADING',
						title: 'Background Image Source',
						addTopMargin: false
					},
					{
						type: 'IMAGE_SOURCE_SELECTOR',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingBackgroundImageSourceType',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
						imageSelectors: [
							{
								label: 'All Pages',
								key: 'book',
								selectedMessage: 'All ---PART_TYPE---s will use the books common heading background image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: 'Book Common Image',
									paramType: 'RECIPE',
									//paramItem: 'BOOK',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									// entityImageSourceFieldName: 'global_common_headingImageSrc',
									// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
									recipeParamName: 'global_common_headingBackgroundImageSrc',
									recipeMediaTypeParamName: 'global_common_headingImageMediaType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'RECIPE',
										//paramItem: 'BOOK',   
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										recipeParamName: 'global_common_headingBackgroundImageSrc',
										recipeMediaTypeParamName: 'global_common_headingImageMediaType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							},
							{
								label: 'All ---PART_TYPE---s',
								key: 'type',
								selectedMessage: 'All ---PART_TYPE---s will use this image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: '---PART_TYPE---s Specific Image',
									paramType: 'RECIPE',
									//paramItem: 'BOOK',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSrc',
									recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'RECIPE',
										//paramItem: 'BOOK',   
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										// entityImageSourceFieldName: 'global_common_headingImageSrc',
										// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
										recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSrc',
										recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							},
							{
								label: 'This Page',
								key: 'self',
								selectedMessage: 'Each ---PART_TYPE--- will provide a unique heading background image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: 'This ---PART_TYPE---\'s Specific Image',
									paramType: 'ENTITY',
									paramItem: 'DOCUMENTPART',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									entityImageSourceFieldName: 'headingBackgroundUrl',
									entityImageMediaTypeFieldName: 'headerImageType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'ENTITY',
										paramItem: 'DOCUMENTPART',
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										entityFieldName: 'headingBackgroundUrl',
										//entityImageMediaTypeFieldName: 'headerImageType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							}
						],
					},
					{
						type: 'SUBHEADING',
						title: 'Image Span ( Vellums title, rename this )',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Single Page', value: 'singlePage' },
							{ label: 'Double Page', value: 'doublePage' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},



					{
						type: 'SUBHEADING',
						title: 'Background Text Color ( probably just white or black )',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'White', value: 'white' },
							{ label: 'Black', value: 'black' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'CHECKBOX',
						title: 'Full Page Bleed ( title ?)',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromBook',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'SUBHEADING',
						title: 'Opacity',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'zero', value: 'zero' },
							{ label: '25%', value: '25' },
							{ label: '50%', value: '50' },
							{ label: '75%', value: '75' },
							{ label: '95%', value: '95' },
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Bottom Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Left Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Right Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},


				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'NOT_PART_TYPES',
					partTypes: ['TITLEPAGE', 'HALFTITLE', 'BOOK']

				}],
				notVisibleHelperMessage: 'The title, subtitle and authorname for these page types will come from the book or boxset values.'
			},

		],
		visibilityConditions: [{
			visible: true,
			conditionType: 'HAS_HEADING'
		}],
		notVisibleHelperMessage: 'This is for content with headings'

	},
	heading: {


		sections: [
			// {
			// 	title: 'Heading Template Source',
			// 	elements: [

			// 		{
			// 			type: 'HEADING_TEMPLATE_SOURCE_TOGGLE_BUTTONS',
			// 			title: 'Select recipe source',
			// 			paramType: 'RECIPE',
			// 			recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
			// 			options: [
			// 				{
			// 					label: 'Book Common',
			// 					value: 'common',
			// 					selectedMessage: 'Using the book common heading NOT-style for all ---PART_TYPE---s.',

			// 				},
			// 				{
			// 					label: '---PART_TYPE---s Specific',
			// 					value: 'partTypeSpecific',
			// 					selectedMessage: 'Using a separate heading NOT-style for all ---PART_TYPE---s.',

			// 				}
			// 			],
			// 		}
			// 	],
			// 	sectionVisibilityConditions: [{
			// 		visible: true,
			// 		conditionType: 'HAS_HEADING'
			// 	}],
			// 	notVisibleHelperMessage: 'This is for content with headings'
			// },
			
			{
				title: 'Heading Visibility',
				elements: [
					{
						type: 'PROPS_CHECKBOX',
						title: 'Heading Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						//invertValue: true,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					// {
					// 	type: 'CHECKBOX',
					// 	title: 'Hide all headings',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_common_hideAllHeadings',
					// 	checkedValue: true,
					// 	unCheckedValue: false,
					// 	invertValue: false,
					// 	canEdit: 'ITEM_OWNER',
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'common'
					// 	}],
					// },
					// {
					// 	type: 'CHECKBOX',
					// 	title: 'Hide all ---PART_TYPE_LOWER--- headings',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_---PART_TYPE_LOWER---_hideAllHeadings',
					// 	checkedValue: true,
					// 	unCheckedValue: false,
					// 	invertValue: false,
					// 	canEdit: 'ITEM_OWNER',
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'partTypeSpecific'
					// 	}],
					// },
					// {
					// 	type: 'CHECKBOX',
					// 	title: 'Hide this ---PART_TYPE_LOWER---\'s heading',
					// 	paramType: 'ENTITY',
					// 	paramItem: 'DOCUMENTPART',
					// 	entityFieldName: 'hideHeading',
					// 	checkedValue: 'true',
					// 	unCheckedValue: 'false',
					// 	invertValue: false,
					// 	canEdit: 'ITEM_OWNER'
					// }
				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			},
			{
				title: 'Heading Template Properties',
				elements: [
					// {
					// 	type: 'PROPS_TOGGLE_BUTTONS',
					// 	title: 'Select recipe source',
					// 	//paramType: 'RECIPE',
					// 	recipeParamPath: 'HEADING.TEMPLATE.ID',
					// 	setValueSoure:true,
					// 	options: [
					// 		{
					// 			label: 'Book Common',
					// 			value: 'common',
					// 			selectedMessage: 'Using the book common heading NOT-style for all ---PART_TYPE---s.',

					// 		},
					// 		{
					// 			label: '---PART_TYPE---s Specific',
					// 			value: 'partTypeSpecific',
					// 			selectedMessage: 'Using a separate heading NOT-style for all ---PART_TYPE---s.',

					// 		},
					// 		{
					// 			label: 'Self',
					// 			value: 'self',
					// 			selectedMessage: 'Using a heading for just this item',

					// 		}
					// 	],
					// },
					// {
					// 	type: 'HEADING_TEMPLATE_SOURCE_TOGGLE_BUTTONS',
					// 	title: 'Select recipe source',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 	options: [
					// 		{
					// 			label: 'Book Common',
					// 			value: 'common',
					// 			selectedMessage: 'Using the book common heading NOT-style for all ---PART_TYPE---s.',

					// 		},
					// 		{
					// 			label: '---PART_TYPE---s Specific',
					// 			value: 'partTypeSpecific',
					// 			selectedMessage: 'Using a separate heading NOT-style for all ---PART_TYPE---s.',

					// 		}
					// 	],
					// },
					{
						type: 'INFOHEADING',
						title: '(For the book\'s common heading)',
						addTopMargin: false,
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'INFOHEADING',
						title: '(For the ---PART_TYPE_LOWER---\'s common heading)',
						addTopMargin: false,
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{
						type: 'PROPS_TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: 'DO_NOT_USE',
						disableSave: false,
						//configType:'LOOKUP',
						configType: 'STANDARD',
						recipeParamPath: 'HEADING.TEMPLATE.ID',
						setValueSoure: false,
						recipeParamName: 'defaultDNUContentTemplate',
						templateSourceTag: 'TEMP_headingWrapper',
						hideLabel: false,
						devModeOnly: true

					},

					// {
					// 	type: 'HEADING_TEMPLATE_SOURCE_TOGGLE_BUTTONS',
					// 	title: 'Select recipe source',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 	options: [
					// 		{
					// 			label: 'Book Common',
					// 			value: 'common',
					// 			selectedMessage: 'Using the book common heading NOT-style for all ---PART_TYPE---s.',

					// 		},
					// 		{
					// 			label: '---PART_TYPE---s Specific',
					// 			value: 'partTypeSpecific',
					// 			selectedMessage: 'Using a separate heading NOT-style for all ---PART_TYPE---s.',

					// 		}
					// 	],
					// },
					// {
					// 	type: 'TEMPLATE_SOURCE_RADIO_BUTTONS',
					// 	title: '',//'Select heading template source for ---PART_TYPE---s',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 	templateSelectors: [
					// 		{
					// 			type: 'TEMPLATE_SELECTOR',
					// 			canEdit: 'ITEM_OWNER',
					// 			key:'common',
					// 			label: 'Book Default Heading',
					// 			subLabel: 'Use the default heading template from this theme',
					// 			disableSave: false,
					// 			//configType:'LOOKUP',
					// 			configType: 'STANDARD',
					// 			//configDetails:'HEADER_BY_PART_TYPE',
					// 			recipeParamName: 'defaultCommonHeaderTemplate',
					// 			templateSourceTag: 'TEMP_commonHeaderWrapper',
					// 			hideLabel: false,


					// 		},
					// 		{
					// 			type: 'TEMPLATE_SELECTOR',
					// 			canEdit: 'ITEM_OWNER',
					// 			key:'partTypeSpecific',
					// 			label: '---PART_TYPE---s Specific Heading',
					// 			subLabel: 'Use the ---PART_TYPE_LOWER--- heading template from this theme',
					// 			disableSave: false,
					// 			//configType:'LOOKUP',
					// 			configType: 'STANDARD',
					// 			//configDetails:'HEADER_BY_PART_TYPE',
					// 			recipeParamName: 'default---PART_TYPE---HeaderTemplate',
					// 			templateSourceTag: 'TEMP_byPartHeaderWrapper',
					// 			hideLabel: false,


					// 		}
					// 	],
					// },



					// {
					// 	type: 'TEMPLATE_SELECTOR',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: 'DO_NOT_USE',
					// 	disableSave: false,
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	recipeParamName: 'defaultDNUContentTemplate',
					// 	templateSourceTag: 'TEMP_commonHeaderDoNotUse',
					// 	hideLabel: false,
					// 	devModeOnly: true

					// },
					// {
					// 	type: 'TEMPLATE_SELECTOR',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: 'Book Common Header Template',
					// 	disableSave: false,
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	recipeParamName: 'defaultCommonHeaderTemplate',
					// 	templateSourceTag: 'TEMP_commonHeaderWrapper',
					// 	hideLabel: false,
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'common'
					// 	}]

					// },
					// {
					// 	type: 'TEMPLATE_SELECTOR',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: '---PART_TYPE---s Specific Header Template',
					// 	disableSave: false,
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	recipeParamName: 'default---PART_TYPE---HeaderTemplate',
					// 	templateSourceTag: 'TEMP_byPartHeaderWrapper',
					// 	hideLabel: false,
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'partTypeSpecific'
					// 	}]

					// }
				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			},
			{
				title: 'Margin',
				elements: [
					{
						type: 'SUBHEADING',
						title: 'Top',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.MARGIN.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.MARGIN.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.MARGIN.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.MARGIN.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
				]
			},
			{
				title: 'Padding',
				elements: [
					{
						type: 'SUBHEADING',
						title: 'Top',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.PADDING.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.PADDING.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.PADDING.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.PADDING.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
				]
			},
			{
				title: 'Border',
				elements: [
					{
						type: 'SUBHEADING',
						title: 'Thickness',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.BORDER.THICKNESS',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Thin', value: 'THIN' },
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Thick', value: 'THICK' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Top',
						addTopMargin: false,
						//showRecipeSourceContext: true
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.BORDER.TOP.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					}, {
						type: 'SUBHEADING',
						title: 'Bottom',
						addTopMargin: false,
						//showRecipeSourceContext: true
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.BORDER.BOTTOM.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},

				]
			},
			{
				title: 'Title Text',
				elements: [


					{
						type: 'INFOHEADING',
						title: '(For the book\'s common heading)',
						addTopMargin: false,
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'INFOHEADING',
						title: '(For the ---PART_TYPE_LOWER--- specific heading)',
						addTopMargin: false,
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Title Visibility',
						addTopMargin: false,
						//showRecipeSourceContext: true
					},


					{
						type: 'PROPS_CHECKBOX',
						title: 'Hide all titles',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.TITLE.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},

					{
						type: 'CHECKBOX',
						title: 'Hide all titles',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						recipeParamName: 'global_common_hideAllHeadingTitles',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all ---PART_TYPE_LOWER--- titles',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAll---PART_TYPE_LOWER---Titles',
						recipeParamName: 'global_---PART_TYPE_LOWER---_hideAllHeadingTitles',

						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{
						type: 'CHECKBOX',
						title: 'Hide this ---PART_TYPE_LOWER---s title',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeTitle',
						checkedValue: "true",
						unCheckedValue: "false",
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'SUBHEADING',
						title: 'Title Text Type',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.TITLE.TEXT_TRANSFORM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'Normal' },
							{ label: 'Uppercase', value: 'Uppercase' },
							{ label: 'Small Caps', value: 'Small Caps' },
							{ label: 'Lowercase', value: 'Lowercase' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'Normal' },
							{ label: 'Uppercase', value: 'Uppercase' },
							{ label: 'Small Caps', value: 'Small Caps' },
							{ label: 'Lowercase', value: 'Lowercase' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'Normal' },
							{ label: 'Uppercase', value: 'Uppercase' },
							{ label: 'Small Caps', value: 'Small Caps' },
							{ label: 'Lowercase', value: 'Lowercase' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},





					{
						type: 'SUBHEADING',
						title: 'Title Text Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.TITLE.TEXT_size',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'Small' },
							{ label: 'Medium', value: 'Medium' },
							{ label: 'Large', value: 'Large' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleFontSize',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'Small' },
							{ label: 'Medium', value: 'Medium' },
							{ label: 'Large', value: 'Large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					// {
					// 	type: 'DISCRETE_SLIDER',
					// 	title: 'Title Text Type',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_common_headingTitleFontSize',
					// 	//paramItem:'BOXSETBOOK',
					// 	//entityFieldName:'recipeSource',
					// 	options: [
					// 		{ label: 'Small', value: 'Small' },
					// 		{ label: 'Medium', value: 'Medium' },
					// 		{ label: 'Large', value: 'Large' }
					// 	],
					// 	canEdit: 'ITEM_OWNER',
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'common'
					// 	}]
					// },
					{
						type: 'RADIO_BUTTONS',
						title: 'Heading Top Margin',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_headingTitleFontSize',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'Small' },
							{ label: 'Medium', value: 'Medium' },
							{ label: 'Large', value: 'Large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Title Text Font',
						addTopMargin: true
					},
					{
						type: 'INFOHEADING',
						title: 'All pages',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					// {

					// 	type: 'IMAGE_POPUP_SELECTOR',
					// 	title: 'Title Font',
					// 	paramType: 'RECIPE',
					// 	paramItem: 'BOOK',
					// 	bucketName: config.common_content_bucket_name,
					// 	folder: 'public/fonts',
					// 	level: 'public',
					// 	isFontParam: true,
					// 	recipeParamName: 'global_common_titleFont',
					// 	recipeMediaTypeParamName: 'global_common_titleFontMediaType',
					// 	identitySource: 'ITEM_OWNER',
					// 	canEdit: 'ITEM_OWNER',
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'common'
					// 	}],
					// },
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Title Font',
						recipeParamPath: 'HEADING.TITLE.FONT',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'INFOHEADING',
						title: 'All ---PART_TYPE---s',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{

						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Title Font',
						paramType: 'RECIPE',
						paramItem: 'BOOK',
						bucketName: config.common_content_bucket_name,
						folder: 'public/fonts',
						level: 'public',
						isFontParam: true,
						recipeParamName: 'global_---PART_TYPE_LOWER---_titleFont',
						recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}],

					},

					// {
					// 	type: 'IMAGE_SOURCE_SELECTOR',
					// 	title: '',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_common_titleFontSourceType',
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'common'
					// 	}],
					// 	imageSelectors: [
					// 		{
					// 			label: 'All Pages',
					// 			key: 'book',
					// 			selectedMessage: 'All ---PART_TYPE---s will use the book\'s common font for the title',
					// 			selectorConfig: {

					// 				type: 'IMAGE_POPUP_SELECTOR',
					// 				title: 'Title Font',
					// 				paramType: 'RECIPE',
					// 				paramItem: 'BOOK',
					// 				bucketName: config.common_content_bucket_name,
					// 				folder: 'public/fonts',
					// 				level: 'public',
					// 				isFontParam: true,
					// 				recipeParamName: 'global_common_titleFont',
					// 				recipeMediaTypeParamName: 'global_common_titleFontMediaType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER'
					// 			}
					// 		},
					// 		{
					// 			label: 'This ---PART_TYPE---',
					// 			key: 'self',
					// 			selectedMessage: 'All ---PART_TYPE---s will use a chapter specific font for the title',
					// 			selectorConfig: {

					// 				type: 'IMAGE_POPUP_SELECTOR',
					// 				title: 'Title Font',
					// 				paramType: 'ENTITY',
					// 				paramItem: 'DOCUMENTPART',
					// 				entityFieldName: 'titleFont',
					// 				// paramType: 'RECIPE',
					// 				// paramItem: 'BOOK',
					// 				bucketName: config.common_content_bucket_name,
					// 				folder: 'public/fonts',
					// 				level: 'public',
					// 				isFontParam: true,
					// 				// recipeParamName: 'global_---PART_TYPE_LOWER---_titleFont',
					// 				// recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_titleFontMediaType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER'

					// 			}
					// 		}
					// 	],
					// },

					// {
					// 	type: 'IMAGE_SOURCE_SELECTOR',
					// 	title: '',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_---PART_TYPE_LOWER---_titleFontSourceType',
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'partTypeSpecific'
					// 	}],
					// 	imageSelectors: [

					// 		{
					// 			label: 'All ---PART_TYPE---s',
					// 			key: 'type',
					// 			selectedMessage: 'All ---PART_TYPE---s will use a chapter specific font for the title',
					// 			selectorConfig: {

					// 				type: 'IMAGE_POPUP_SELECTOR',
					// 				title: 'Title Font',
					// 				paramType: 'RECIPE',
					// 				paramItem: 'BOOK',
					// 				bucketName: config.common_content_bucket_name,
					// 				folder: 'public/fonts',
					// 				level: 'public',
					// 				isFontParam: true,
					// 				recipeParamName: 'global_---PART_TYPE_LOWER---_titleFont',
					// 				recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_titleFontMediaType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER'

					// 			}
					// 		},
					// 		{
					// 			label: 'This ---PART_TYPE---',
					// 			key: 'self',
					// 			selectedMessage: 'All ---PART_TYPE---s will use a chapter specific font for the title',
					// 			selectorConfig: {

					// 				type: 'IMAGE_POPUP_SELECTOR',
					// 				title: 'Title Font',
					// 				paramType: 'ENTITY',
					// 				paramItem: 'DOCUMENTPART',
					// 				entityFieldName: 'titleFont',
					// 				// paramType: 'RECIPE',
					// 				// paramItem: 'BOOK',
					// 				bucketName: config.common_content_bucket_name,
					// 				folder: 'public/fonts',
					// 				level: 'public',
					// 				isFontParam: true,
					// 				// recipeParamName: 'global_---PART_TYPE_LOWER---_titleFont',
					// 				// recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_titleFontMediaType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER'

					// 			}
					// 		}
					// 	],
					// }



				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			},
			{
				title: 'Subtitle Text',
				elements: [

					{
						type: 'SUBHEADING',
						title: 'Subtitle Visibility',
						addTopMargin: false
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all subtitles',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_hideAllHeadingSubtitles',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all ---PART_TYPE--- subtitles',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE---_hideAllHeadingSubtitles',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{
						type: 'CHECKBOX',
						title: 'Hide this ---PART_TYPE---s subtitle',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeSubtitle',
						checkedValue: "true",
						unCheckedValue: "false",
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'SUBHEADING',
						title: 'Subtitle Text Type',
						addTopMargin: false
					},
					{
						type: 'RADIO_BUTTONS',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingSubtitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'Normal' },
							{ label: 'Uppercase', value: 'Uppercase' },
							{ label: 'Small Caps', value: 'Small Caps' },
							{ label: 'Lowercase', value: 'Lowercase' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'RADIO_BUTTONS',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_headingSubtitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'Normal' },
							{ label: 'Uppercase', value: 'Uppercase' },
							{ label: 'Small Caps', value: 'Small Caps' },
							{ label: 'Lowercase', value: 'Lowercase' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},





					{
						type: 'SUBHEADING',
						title: 'Subtitle Text Size',
						addTopMargin: false
					},
					{
						type: 'RADIO_BUTTONS',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingSubtitleFontSize',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'Small' },
							{ label: 'Medium', value: 'Medium' },
							{ label: 'Large', value: 'Large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Heading Top Margin',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_headingSubtitleFontSize',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'Small' },
							{ label: 'Medium', value: 'Medium' },
							{ label: 'Large', value: 'Large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Subtitle Text Font',
						addTopMargin: true
					},
					{
						type: 'INFOHEADING',
						title: 'All pages',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{

						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Title Font',
						paramType: 'RECIPE',
						paramItem: 'BOOK',
						bucketName: config.common_content_bucket_name,
						folder: 'public/fonts',
						level: 'public',
						isFontParam: true,
						recipeParamName: 'global_common_subtitleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}],
					},
					{
						type: 'INFOHEADING',
						title: 'All ---PART_TYPE---s',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{

						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Title Font',
						paramType: 'RECIPE',
						paramItem: 'BOOK',
						bucketName: config.common_content_bucket_name,
						folder: 'public/fonts',
						level: 'public',
						isFontParam: true,
						recipeParamName: 'global_---PART_TYPE_LOWER---_subtitleFont',
						recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}],

					},

				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			},
			{
				title: 'Author Name Text',
				elements: [

					{
						type: 'SUBHEADING',
						title: 'Author Name Visibility',
						addTopMargin: false
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all author names',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_hideAllHeadingAuthorNames',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all ---PART_TYPE--- author names',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE---_hideAllHeadingAuthorNames',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{
						type: 'CHECKBOX',
						title: 'Hide this ---PART_TYPE---s author name',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeAuthor',
						checkedValue: "true",
						unCheckedValue: "false",
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'SUBHEADING',
						title: 'Author Name Text Type',
						addTopMargin: false
					},
					{
						type: 'RADIO_BUTTONS',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingAuthorTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'Normal' },
							{ label: 'Uppercase', value: 'Uppercase' },
							{ label: 'Small Caps', value: 'Small Caps' },
							{ label: 'Lowercase', value: 'Lowercase' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'RADIO_BUTTONS',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_headingAuthorTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'Normal' },
							{ label: 'Uppercase', value: 'Uppercase' },
							{ label: 'Small Caps', value: 'Small Caps' },
							{ label: 'Lowercase', value: 'Lowercase' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Author Name Text Size',
						addTopMargin: false
					},
					{
						type: 'RADIO_BUTTONS',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingAuthorFontSize',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'Small' },
							{ label: 'Medium', value: 'Medium' },
							{ label: 'Large', value: 'Large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Heading Top Margin',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_headingAuthorFontSize',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'Small' },
							{ label: 'Medium', value: 'Medium' },
							{ label: 'Large', value: 'Large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Author Name Text Font',
						addTopMargin: true
					},
					{
						type: 'INFOHEADING',
						title: 'All pages',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},

					{

						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Title Font',
						paramType: 'RECIPE',
						paramItem: 'BOOK',
						bucketName: config.common_content_bucket_name,
						folder: 'public/fonts',
						level: 'public',
						isFontParam: true,
						recipeParamName: 'global_common_authorNameFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}],
					},
					{
						type: 'INFOHEADING',
						title: 'All ---PART_TYPE---s',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{

						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Title Font',
						paramType: 'RECIPE',
						paramItem: 'BOOK',
						bucketName: config.common_content_bucket_name,
						folder: 'public/fonts',
						level: 'public',
						isFontParam: true,
						recipeParamName: 'global_---PART_TYPE_LOWER---_authorNameFont',
						recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}],

					},

				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			},
			{
				title: 'Numbering',
				elements: [
					{
						type: 'SUBHEADING',
						title: 'Numbering Visibility',
						addTopMargin: false
					},
					{
						type: 'CHECKBOX',
						title: 'Turn off all numbering',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_numberingOn',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: true,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'CHECKBOX',
						title: 'Turn off numbering for all ---PART_TYPE---s',
						paramType: 'RECIPE',
						recipeParamName: 'global_numberAll---PART_TYPE---s',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: true,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{
						type: 'CHECKBOX',
						title: 'Turn off numbering for this ---PART_TYPE---',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'isNumbered',
						checkedValue: "true",
						unCheckedValue: "false",
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'SUBHEADING',
						title: 'Numbering Text Type',
						addTopMargin: false
					},
					{
						type: 'RADIO_BUTTONS',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingNumberingTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'Normal' },
							{ label: 'Uppercase', value: 'Uppercase' },
							{ label: 'Small Caps', value: 'Small Caps' },
							{ label: 'Lowercase', value: 'Lowercase' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'RADIO_BUTTONS',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_headingNumberingTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'Normal' },
							{ label: 'Uppercase', value: 'Uppercase' },
							{ label: 'Small Caps', value: 'Small Caps' },
							{ label: 'Lowercase', value: 'Lowercase' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},





					{
						type: 'SUBHEADING',
						title: 'Numbering Text Size',
						addTopMargin: false
					},
					{
						type: 'RADIO_BUTTONS',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingNumberingFontSize',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'Small' },
							{ label: 'Medium', value: 'Medium' },
							{ label: 'Large', value: 'Large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Heading Top Margin',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_headingNumberingFontSize',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'Small' },
							{ label: 'Medium', value: 'Medium' },
							{ label: 'Large', value: 'Large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Numbering Text Font',
						addTopMargin: true
					},
					{
						type: 'INFOHEADING',
						title: 'All pages',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{

						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Title Font',
						paramType: 'RECIPE',
						paramItem: 'BOOK',
						bucketName: config.common_content_bucket_name,
						folder: 'public/fonts',
						level: 'public',
						isFontParam: true,
						recipeParamName: 'global_common_numberingFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}],
					},
					{
						type: 'INFOHEADING',
						title: 'All ---PART_TYPE---s',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{

						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Title Font',
						paramType: 'RECIPE',
						paramItem: 'BOOK',
						bucketName: config.common_content_bucket_name,
						folder: 'public/fonts',
						level: 'public',
						isFontParam: true,
						recipeParamName: 'global_---PART_TYPE_LOWER---_numberingFont',
						recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}],

					},
					{
						type: 'SUBHEADING',
						title: 'Numbering Display Method',
						addTopMargin: false
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Select numbering type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_numberingType',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Numeric', value: 'Numeric' },
							{ label: 'Words', value: 'Words' },
							{ label: 'Roman', value: 'Roman' },
							// { label: 'Image', value: 'Image' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamNamex: 'global_---PART_TYPE_LOWER---_numberingScope',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Select numbering scope',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_numberingType',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Numeric', value: 'Numeric' },
							{ label: 'Words', value: 'Words' },
							{ label: 'Roman', value: 'Roman' },
							// { label: 'Image', value: 'Image' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamNamex: 'global_---PART_TYPE_LOWER---_numberingScope',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Numbering Location',
						addTopMargin: false
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Select recipe source',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingNumberingLocation',
						options: [
							{ label: 'Left', value: 'Left' },
							{ label: 'Right', value: 'Right' },
							{ label: 'Top', value: 'Top' },
							{ label: 'Bottom', value: 'Bottom' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Select recipe source',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE---_headingNumberingLocation',
						options: [
							{ label: 'Left', value: 'Left' },
							{ label: 'Right', value: 'Right' },
							{ label: 'Top', value: 'Top' },
							{ label: 'Bottom', value: 'Bottom' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},







				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			},







			// {
			// 	title: 'Heading Background Image',
			// 	elements: [
			// 		{
			// 			type: 'SUBHEADING',
			// 			title: 'Visibility',
			// 			addTopMargin: false
			// 		},
			// 		{
			// 			type: 'CHECKBOX',
			// 			title: 'Hide all heading background images',
			// 			paramType: 'RECIPE',
			// 			recipeParamName: 'global_common_hideAllHeadingBackgroundImages',
			// 			checkedValue: true,
			// 			unCheckedValue: false,
			// 			invertValue: false,
			// 			canEdit: 'ITEM_OWNER',
			// 			visibilityConditions: [{
			// 				visible: true,
			// 				conditionType: 'RECIPE_PARAM_VALUE',
			// 				operator: 'eq',
			// 				recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
			// 				recipeParamValue: 'common'
			// 			}],
			// 		},
			// 		{
			// 			type: 'CHECKBOX',
			// 			title: 'Hide all ---PART_TYPE_LOWER--- heading background images',
			// 			paramType: 'RECIPE',
			// 			recipeParamName: 'global_---PART_TYPE_LOWER---_hideAllHeadingBackgroundImages',
			// 			checkedValue: true,
			// 			unCheckedValue: false,
			// 			invertValue: false,
			// 			canEdit: 'ITEM_OWNER',
			// 			visibilityConditions: [{
			// 				visible: true,
			// 				conditionType: 'RECIPE_PARAM_VALUE',
			// 				operator: 'eq',
			// 				recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
			// 				recipeParamValue: 'partTypeSpecific'
			// 			}],
			// 		},

			// 		{
			// 			type: 'CHECKBOX',
			// 			title: 'Hide this ---PART_TYPE_LOWER---\'s heading image',
			// 			paramType: 'ENTITY',
			// 			paramItem: 'DOCUMENTPART',
			// 			entityFieldName: 'hideHeadingBackgroundImage',
			// 			checkedValue: "true",
			// 			unCheckedValue: "false",
			// 			invertValue: false,
			// 			canEdit: 'ITEM_OWNER'
			// 		},


			// 		{
			// 			type: 'SUBHEADING',
			// 			title: 'Image Source',
			// 			addTopMargin: false
			// 		},
			// 		{
			// 			type: 'IMAGE_SOURCE_SELECTOR',
			// 			title: '',
			// 			paramType: 'RECIPE',
			// 			recipeParamName: 'global_common_headingBackgroundImageSourceType',
			// 			visibilityConditions: [{
			// 				visible: true,
			// 				conditionType: 'RECIPE_PARAM_VALUE',
			// 				operator: 'eq',
			// 				recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
			// 				recipeParamValue: 'common'
			// 			}],
			// 			imageSelectors: [
			// 				{
			// 					label: 'All Pages',
			// 					key: 'book',
			// 					selectedMessage: 'All ---PART_TYPE---s will use the books common heading background image.',
			// 					selectorConfig: {
			// 						type: 'IMAGE_SELECTOR',
			// 						title: 'Book Common Image',
			// 						paramType: 'RECIPE',
			// 						//paramItem: 'BOOK',
			// 						bucketName: config.user_uploads_bucket_region,
			// 						folder: 'folder1',
			// 						// entityImageSourceFieldName: 'global_common_headingImageSrc',
			// 						// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
			// 						recipeParamName: 'global_common_headingBackgroundImageSrc',
			// 						recipeMediaTypeParamName: 'global_common_headingImageMediaType',
			// 						identitySource: 'ITEM_OWNER',
			// 						canEdit: 'ITEM_OWNER'
			// 					}
			// 				},
			// 				{
			// 					label: 'All ---PART_TYPE---s',
			// 					key: 'type',
			// 					selectedMessage: 'All ---PART_TYPE---s will use this image.',
			// 					selectorConfig: {
			// 						type: 'IMAGE_SELECTOR',
			// 						title: '---PART_TYPE---s Specific Image',
			// 						paramType: 'RECIPE',
			// 						//paramItem: 'BOOK',
			// 						bucketName: config.user_uploads_bucket_region,
			// 						folder: 'folder1',
			// 						recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSrc',
			// 						recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
			// 						identitySource: 'ITEM_OWNER',
			// 						canEdit: 'ITEM_OWNER'
			// 					}
			// 				},
			// 				{
			// 					label: 'This Page',
			// 					key: 'self',
			// 					selectedMessage: 'Each ---PART_TYPE--- will provide a unique heading background image.',
			// 					selectorConfig: {
			// 						type: 'IMAGE_SELECTOR',
			// 						title: 'This ---PART_TYPE---\'s Specific Image',
			// 						paramType: 'ENTITY',
			// 						paramItem: 'DOCUMENTPART',
			// 						bucketName: config.user_uploads_bucket_region,
			// 						folder: 'folder1',
			// 						entityImageSourceFieldName: 'headingBackgroundUrl',
			// 						entityImageMediaTypeFieldName: 'headerImageType',
			// 						identitySource: 'ITEM_OWNER',
			// 						canEdit: 'ITEM_OWNER'
			// 					}
			// 				}
			// 			],
			// 		},
			// 		// {
			// 		// 	type: 'IMAGE_SOURCE_SELECTOR',
			// 		// 	title: '',
			// 		// 	paramType: 'RECIPE',
			// 		// 	recipeParamName: 'global_common_headingBackgroundImageSourceType',
			// 		// 	visibilityConditions: [{
			// 		// 		visible: true,
			// 		// 		conditionType: 'RECIPE_PARAM_VALUE',
			// 		// 		operator: 'eq',
			// 		// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
			// 		// 		recipeParamValue: 'common'
			// 		// 	}],
			// 		// 	imageSelectors: [
			// 		// 		{
			// 		// 			label: 'All Pages',
			// 		// 			key: 'book',
			// 		// 			selectedMessage: 'All ---PART_TYPE---s will use the books common heading background image.',
			// 		// 			selectorConfig: {
			// 		// 				type: 'IMAGE_SELECTOR',
			// 		// 				title: 'Book Common Image',
			// 		// 				paramType: 'RECIPE',
			// 		// 				//paramItem: 'BOOK',
			// 		// 				bucketName: config.user_uploads_bucket_region,
			// 		// 				folder: 'folder1',
			// 		// 				// entityImageSourceFieldName: 'global_common_headingImageSrc',
			// 		// 				// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
			// 		// 				recipeParamName: 'global_common_headingBackgroundImageSrc',
			// 		// 				recipeMediaTypeParamName: 'global_common_headingImageMediaType',
			// 		// 				identitySource: 'ITEM_OWNER',
			// 		// 				canEdit: 'ITEM_OWNER'
			// 		// 			}
			// 		// 		},
			// 		// 		{
			// 		// 			label: 'This Page',
			// 		// 			key: 'self',
			// 		// 			selectedMessage: 'Each ---PART_TYPE--- will provide a unique heading background image.',
			// 		// 			selectorConfig: {
			// 		// 				type: 'IMAGE_SELECTOR',
			// 		// 				title: 'This ---PART_TYPE---\'s Specific Image',
			// 		// 				paramType: 'ENTITY',
			// 		// 				paramItem: 'DOCUMENTPART',
			// 		// 				bucketName: config.user_uploads_bucket_region,
			// 		// 				folder: 'folder1',
			// 		// 				entityImageSourceFieldName: 'headingBackgroundUrl',
			// 		// 				entityImageMediaTypeFieldName: 'headerImageType',
			// 		// 				identitySource: 'ITEM_OWNER',
			// 		// 				canEdit: 'ITEM_OWNER'
			// 		// 			}
			// 		// 		}
			// 		// 	],
			// 		// },
			// 		// {
			// 		// 	type: 'IMAGE_SOURCE_SELECTOR',
			// 		// 	title: '',
			// 		// 	paramType: 'RECIPE',
			// 		// 	recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSourceType',
			// 		// 	visibilityConditions: [{
			// 		// 		visible: true,
			// 		// 		conditionType: 'RECIPE_PARAM_VALUE',
			// 		// 		operator: 'eq',
			// 		// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
			// 		// 		recipeParamValue: 'partTypeSpecific'
			// 		// 	}],
			// 		// 	imageSelectors: [
			// 		// 		{
			// 		// 			label: 'All ---PART_TYPE---s',
			// 		// 			key: 'type',
			// 		// 			selectedMessage: 'All ---PART_TYPE---s will use this image.',
			// 		// 			selectorConfig: {
			// 		// 				type: 'IMAGE_SELECTOR',
			// 		// 				title: '---PART_TYPE---s Specific Image',
			// 		// 				paramType: 'RECIPE',
			// 		// 				//paramItem: 'BOOK',
			// 		// 				bucketName: config.user_uploads_bucket_region,
			// 		// 				folder: 'folder1',
			// 		// 				recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSrc',
			// 		// 				recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
			// 		// 				identitySource: 'ITEM_OWNER',
			// 		// 				canEdit: 'ITEM_OWNER'
			// 		// 			}
			// 		// 		},
			// 		// 		{
			// 		// 			label: 'This ---PART_TYPE---',
			// 		// 			key: 'self',
			// 		// 			selectedMessage: 'Each ---PART_TYPE--- will provide a unique heading background image.',
			// 		// 			selectorConfig: {
			// 		// 				type: 'IMAGE_SELECTOR',
			// 		// 				title: 'This ---PART_TYPE---\'s Specific Image',
			// 		// 				paramType: 'ENTITY',
			// 		// 				paramItem: 'DOCUMENTPART',
			// 		// 				bucketName: config.user_uploads_bucket_region,
			// 		// 				folder: 'folder1',
			// 		// 				entityImageSourceFieldName: 'headingBackgroundUrl',
			// 		// 				entityImageMediaTypeFieldName: 'headerImageType',
			// 		// 				identitySource: 'ITEM_OWNER',
			// 		// 				canEdit: 'ITEM_OWNER'
			// 		// 			}
			// 		// 		}
			// 		// 	],
			// 		// }
			// 	],
			// 	sectionVisibilityConditions: [{
			// 		visible: true,
			// 		conditionType: 'HAS_HEADING'
			// 	}],
			// 	notVisibleHelperMessage: 'This is for content with headings'
			// },
			{
				title: 'Heading Image',
				elements: [
					{
						type: 'SUBHEADING',
						title: 'Heading Image Visibility',
						addTopMargin: false
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all heading images',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_hideAllHeadingImages',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'CHECKBOX',
						title: 'Hide ---PART_TYPE--- heading images',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_hideAllHeadingImages',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},

					{
						type: 'CHECKBOX',
						title: 'Hide this ---PART_TYPE---s heading image',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'hideHeadingImage',
						checkedValue: "true",
						unCheckedValue: "false",
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'SUBHEADING',
						title: 'Heading Image Size',
						addTopMargin: false
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Heading Image Size',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingImageSize',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'Small' },
							{ label: 'Medium', value: 'Medium' },
							{ label: 'Large', value: 'Large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Heading Top Margin',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_headingImageSize',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'Small' },
							{ label: 'Medium', value: 'Medium' },
							{ label: 'Large', value: 'Large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},


























					{
						type: 'SUBHEADING',
						title: 'Heading Image Source',
						addTopMargin: false
					},
					// {
					// 	type: 'IMAGE_SOURCE_SELECTOR',
					// 	title: '',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_common_headingImageSourceType',
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'common'
					// 	}],
					// 	imageSelectors: [
					// 		{
					// 			label: 'All Pages',
					// 			key: 'book',
					// 			selectedMessage: 'All ---PART_TYPE---s will use the books common heading image.',
					// 			selectorConfig: {
					// 				type: 'IMAGE_SELECTOR',
					// 				title: 'Book Common Image',
					// 				paramType: 'RECIPE',
					// 				//paramItem: 'BOOK',
					// 				bucketName: config.user_uploads_bucket_region,
					// 				folder: 'folder1',
					// 				// entityImageSourceFieldName: 'global_common_headingImageSrc',
					// 				// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
					// 				recipeParamName: 'global_common_headingImageSrc',
					// 				recipeMediaTypeParamName: 'global_common_headingImageMediaType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER'
					// 			}
					// 		},
					// 		{
					// 			label: 'All ---PART_TYPE---s',
					// 			key: 'type',
					// 			selectedMessage: 'All ---PART_TYPE---s will use this image.',
					// 			selectorConfig: {
					// 				type: 'IMAGE_SELECTOR',
					// 				title: '---PART_TYPE---s Specific Image',
					// 				paramType: 'RECIPE',
					// 				//paramItem: 'BOOK',
					// 				bucketName: config.user_uploads_bucket_region,
					// 				folder: 'folder1',
					// 				recipeParamName: 'global_---PART_TYPE_LOWER---_headingImageSrc',
					// 				recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER'
					// 			}
					// 		},
					// 		{
					// 			label: 'This Page',
					// 			key: 'self',
					// 			selectedMessage: 'Each ---PART_TYPE--- will provide a unique heading image.',
					// 			selectorConfig: {
					// 				type: 'IMAGE_SELECTOR',
					// 				title: 'This ---PART_TYPE---\'s Specific Image',
					// 				paramType: 'ENTITY',
					// 				paramItem: 'DOCUMENTPART',
					// 				bucketName: config.user_uploads_bucket_region,
					// 				folder: 'folder1',
					// 				entityImageSourceFieldName: 'headingUrl',
					// 				entityImageMediaTypeFieldName: 'headerImageType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER'
					// 			}
					// 		}
					// 	],
					// },
					{
						type: 'IMAGE_SOURCE_SELECTOR',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingImageSourceType',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}],
						imageSelectors: [
							{
								label: 'All Pages',
								key: 'book',
								selectedMessage: 'All ---PART_TYPE---s will use the books common heading image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: 'Book Common Image',
									paramType: 'RECIPE',
									//paramItem: 'BOOK',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									// entityImageSourceFieldName: 'global_common_headingImageSrc',
									// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
									recipeParamName: 'global_common_headingImageSrc',
									recipeMediaTypeParamName: 'global_common_headingImageMediaType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'RECIPE',
										//paramItem: 'BOOK',   
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										// entityImageSourceFieldName: 'global_common_headingImageSrc',
										// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
										recipeParamName: 'global_common_headingImageSrc',
										recipeMediaTypeParamName: 'global_common_headingImageMediaType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							},
							{
								label: 'This Page',
								key: 'self',
								selectedMessage: 'Each ---PART_TYPE--- will provide a unique heading image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: 'This ---PART_TYPE---\'s Specific Image',
									paramType: 'ENTITY',
									paramItem: 'DOCUMENTPART',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									entityFieldName: 'headingUrl',
									entityImageMediaTypeFieldName: 'headerImageType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										//title: 'Book Common Image',
										paramType: 'ENTITY',
										paramItem: 'DOCUMENTPART',
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										entityFieldName: 'headingUrl',
										//entityImageMediaTypeFieldName: 'headerImageType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'



									}
								}
							}
						],
					},
					{
						type: 'IMAGE_SOURCE_SELECTOR',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_headingImageSourceType',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}],
						imageSelectors: [
							{
								label: 'All ---PART_TYPE---s',
								key: 'type',
								selectedMessage: 'All ---PART_TYPE---s will use this image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: '---PART_TYPE---s Specific Image',
									paramType: 'RECIPE',
									//paramItem: 'BOOK',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									recipeParamName: 'global_---PART_TYPE_LOWER---_headingImageSrc',
									recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'RECIPE',
										//paramItem: 'BOOK',   
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										// entityImageSourceFieldName: 'global_common_headingImageSrc',
										// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
										recipeParamName: 'global_---PART_TYPE_LOWER---_headingImageSrc',
										recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							},
							{
								label: 'This ---PART_TYPE---',
								key: 'self',
								selectedMessage: 'Each ---PART_TYPE--- will provide a unique heading image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: 'This ---PART_TYPE---\'s Specific Image',
									paramType: 'ENTITY',
									paramItem: 'DOCUMENTPART',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									entityImageSourceFieldName: 'headingUrl',
									entityImageMediaTypeFieldName: 'headerImageType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										//title: 'Book Common Image',
										paramType: 'ENTITY',
										paramItem: 'DOCUMENTPART',
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										entityFieldName: 'headingUrl',
										//entityImageMediaTypeFieldName: 'headerImageType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							}
						],
					}
				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			},
			{
				title: 'Heading Title Images',
				elements: [
					{
						type: 'SUBHEADING',
						title: 'Title Image Visibility',
						addTopMargin: false
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all title images',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_hideAllTitleImages',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'CHECKBOX',
						title: 'Hide ---PART_TYPE--- title images',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_hideAllTitleImages',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},

					{
						type: 'CHECKBOX',
						title: 'Hide this ---PART_TYPE---s title image',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'hideHeadingTitleImage',
						checkedValue: "true",
						unCheckedValue: "false",
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'SUBHEADING',
						title: 'Title Image Size',
						addTopMargin: false
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Title Image Size',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleImageSize',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'Small' },
							{ label: 'Medium', value: 'Medium' },
							{ label: 'Large', value: 'Large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Heading Top Margin',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_headingTitleImageSize',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'Small' },
							{ label: 'Medium', value: 'Medium' },
							{ label: 'Large', value: 'Large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Title Image Source ( adjust image preview size by image useage--title images smaller then heading images etc )',
						addTopMargin: false
					},
					{
						type: 'IMAGE_SOURCE_SELECTOR',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleImageSourceType',
						width: '5em',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}],
						imageSelectors: [
							{
								label: 'Book',
								key: 'book',
								selectedMessage: 'All ---PART_TYPE---s  WILL USE THE BOOKS COMMON Title IMAGE.',
								selectorConfig: {

									type: 'IMAGE_POPUP_SELECTOR',
									title: 'Title Image',
									paramType: 'RECIPE',
									paramItem: 'BOOK',
									bucketName: config.common_content_bucket_name,
									folder: 'public/headerThumbnails',
									fileSource: 'commonImages',
									level: 'public',
									recipeParamName: 'global_common_headingTitleImageSrc',
									recipeMediaTypeParamName: 'global_common_headingTitleImageMediaType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',

									//This allows us to pick from a gallery and to upload an image in a single control.
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'RECIPE',
										//paramItem: 'BOOK',   
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										// entityImageSourceFieldName: 'global_common_headingImageSrc',
										// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
										recipeParamName: 'global_common_headingTitleImageSrc',
										recipeMediaTypeParamName: 'global_common_headingImageMediaType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							},
							{
								label: 'self',
								key: 'self',
								selectedMessage: 'All ---PART_TYPE---s  WILL USE THE BOOKS COMMON Title IMAGE.',
								selectorConfig: {

									type: 'IMAGE_POPUP_SELECTOR',
									title: 'Title Image',
									paramType: 'ENTITY',
									paramItem: 'DOCUMENTPART',
									bucketName: config.common_content_bucket_name,
									folder: 'public/headerThumbnails',
									fileSource: 'commonImages',
									level: 'public',
									entityFieldName: 'titleImageUrl',
									//entityImageMediaTypeFieldName: 'titleImageType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',

									//This allows us to pick from a gallery and to upload an image in a single control.
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'ENTITY',
										paramItem: 'DOCUMENTPART',
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										entityImageSourceFieldName: 'titleImageUrl',
										entityImageMediaTypeFieldName: 'titleImageType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER',
									}
								}
							},

						],
					},
					{

						type: 'PROPS_IMAGE_POPUP_SELECTOR',
						folder: 'public/headerThumbnails',
						fileSource: 'commonImages',
						level: 'public',
						recipeParamPath: 'HEADING.TITLE.IMAGE.SRC',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						hideUploadControls:false,
						hideGalleryControls:false
						//bucketName: config.common_content_bucket_name,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					
					// {
					// 	type: 'IMAGE_SOURCE_SELECTOR',
					// 	title: '',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_common_headingTitleImageSourceType',
					// 	width: '5em',
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'common'
					// 	}],
					// 	imageSelectors: [
					// 		{
					// 			label: 'Book',
					// 			key: 'book',
					// 			selectedMessage: 'All ---PART_TYPE---s  WILL USE THE BOOKS COMMON Title IMAGE.',
					// 			selectorConfig: {

					// 				type: 'IMAGE_POPUP_SELECTOR',
					// 				title: 'Title Image',
					// 				paramType: 'RECIPE',
					// 				paramItem: 'BOOK',
					// 				bucketName: config.common_content_bucket_name,
					// 				folder: 'public/headerThumbnails',
					// 				fileSource: 'commonImages',
					// 				level: 'public',
					// 				recipeParamName: 'global_common_headingTitleImageSrc',
					// 				recipeMediaTypeParamName: 'global_common_headingTitleImageMediaType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER',

					// 				//This allows us to pick from a gallery and to upload an image in a single control.
					// 				imageUploaderConfig: {
					// 					type: 'IMAGE_SELECTOR',
					// 					title: 'Book Common Image',
					// 					paramType: 'RECIPE',
					// 					//paramItem: 'BOOK',   
					// 					bucketName: config.user_uploads_bucket_name,
					// 					folder: 'folder1',
					// 					fileSource: 'userImages',
					// 					// entityImageSourceFieldName: 'global_common_headingImageSrc',
					// 					// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
					// 					recipeParamName: 'global_common_headingTitleImageSrc',
					// 					recipeMediaTypeParamName: 'global_common_headingImageMediaType',
					// 					identitySource: 'ITEM_OWNER',
					// 					canEdit: 'ITEM_OWNER'
					// 				}
					// 			}
					// 		},
					// 		{
					// 			label: 'self',
					// 			key: 'self',
					// 			selectedMessage: 'All ---PART_TYPE---s  WILL USE THE BOOKS COMMON Title IMAGE.',
					// 			selectorConfig: {

					// 				type: 'IMAGE_POPUP_SELECTOR',
					// 				title: 'Title Image',
					// 				paramType: 'ENTITY',
					// 				paramItem: 'DOCUMENTPART',
					// 				bucketName: config.common_content_bucket_name,
					// 				folder: 'public/headerThumbnails',
					// 				fileSource: 'commonImages',
					// 				level: 'public',
					// 				entityFieldName: 'titleImageUrl',
					// 				//entityImageMediaTypeFieldName: 'titleImageType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER',

					// 				//This allows us to pick from a gallery and to upload an image in a single control.
					// 				imageUploaderConfig: {
					// 					type: 'IMAGE_SELECTOR',
					// 					title: 'Book Common Image',
					// 					paramType: 'ENTITY',
					// 					paramItem: 'DOCUMENTPART',
					// 					bucketName: config.user_uploads_bucket_name,
					// 					folder: 'folder1',
					// 					fileSource: 'userImages',
					// 					entityImageSourceFieldName: 'titleImageUrl',
					// 					entityImageMediaTypeFieldName: 'titleImageType',
					// 					identitySource: 'ITEM_OWNER',
					// 					canEdit: 'ITEM_OWNER',
					// 				}
					// 			}
					// 		},

					// 	],
					// },
					{
						type: 'IMAGE_SOURCE_SELECTOR',
						subType: 'TITLE_IMAGES',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_headingTitleImageSourceType',
						width: '5em',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}],
						imageSelectors: [
							// {
							// 	label: 'Book',
							// 	key: 'book',
							// 	selectedMessage: 'All ---PART_TYPE---s  WILL USE THE BOOKS COMMON Title IMAGE.',
							// 	selectorConfig: {

							// 		type: 'IMAGE_POPUP_SELECTOR',
							// 		title: 'Title Image',
							// 		paramType: 'RECIPE',
							// 		paramItem: 'BOOK',
							// 		bucketName: config.common_content_bucket_name,
							// 		folder: 'public/headerThumbnails',
							// 		fileSource: 'commonImages',
							// 		level: 'public',
							// 		recipeParamName: 'global_common_headingTitleImageSrc',
							// 		recipeMediaTypeParamName: 'global_common_headingTitleImageMediaType',
							// 		identitySource: 'ITEM_OWNER',
							// 		canEdit: 'ITEM_OWNER',

							// 		//This allows us to pick from a gallery and to upload an image in a single control.
							// 		imageUploaderConfig: {
							// 			type: 'IMAGE_SELECTOR',
							// 			title: 'Book Common Image',
							// 			paramType: 'RECIPE',
							// 			//paramItem: 'BOOK',   
							// 			bucketName: config.user_uploads_bucket_name,
							// 			folder: 'folder1',
							// 			fileSource: 'userImages',
							// 			// entityImageSourceFieldName: 'global_common_headingImageSrc',
							// 			// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
							// 			recipeParamName: 'global_common_headingTitleImageSrc',
							// 			recipeMediaTypeParamName: 'global_common_headingImageMediaType',
							// 			identitySource: 'ITEM_OWNER',
							// 			canEdit: 'ITEM_OWNER'
							// 		}
							// 	}
							// },
							{
								label: '---PART_TYPE---s',
								key: 'type',
								selectedMessage: 'All ---PART_TYPE---s  WILL use the same title image.',
								selectorConfig: {

									type: 'IMAGE_POPUP_SELECTOR',
									title: 'Title Image',
									paramType: 'RECIPE',
									paramItem: 'BOOK',
									bucketName: config.common_content_bucket_name,
									folder: 'public/headerThumbnails',
									fileSource: 'commonImages',
									level: 'public',
									recipeParamName: 'global_---PART_TYPE_LOWER---_headingTitleImageSrc',
									recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingTitleImageMediaType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',

									//This allows us to pick from a gallery and to upload an image in a single control.
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: '',
										paramType: 'RECIPE',
										//paramItem: 'BOOK',   
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										// entityImageSourceFieldName: 'global_common_headingImageSrc',
										// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
										recipeParamName: 'global_---PART_TYPE_LOWER---_headingTitleImageSrc',
										recipeMediaTypeParamName: 'global_common_headingImageMediaType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								},
							},
							{
								label: 'self',
								key: 'self',
								selectedMessage: 'All ---PART_TYPE---s  WILL USE THE BOOKS COMMON Title IMAGE.',
								selectorConfig: {

									type: 'IMAGE_POPUP_SELECTOR',
									title: 'Title Image',
									paramType: 'ENTITY',
									paramItem: 'DOCUMENTPART',
									bucketName: config.common_content_bucket_name,
									folder: 'public/headerThumbnails',
									fileSource: 'commonImages',
									level: 'public',
									entityFieldName: 'titleImageUrl',
									//entityImageMediaTypeFieldName: 'titleImageType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',

									//This allows us to pick from a gallery and to upload an image in a single control.
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'ENTITY',
										paramItem: 'DOCUMENTPART',
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										entityImageSourceFieldName: 'titleImageUrl',
										entityImageMediaTypeFieldName: 'titleImageType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER',
									}
								}
							},
							// {
							// 	label: 'Page',
							// 	key: 'self',
							// 	selectedMessage: 'Each ---PART_TYPE---  WILL provide a unique title image.',
							// 	selectorConfig: {
							// 		type: 'IMAGE_SELECTOR',
							// 		title: 'This ---PART_TYPE---\'s Specific Image',
							// 		paramType: 'ENTITY',
							// 		paramItem: 'DOCUMENTPART',
							// 		bucketName: config.user_uploads_bucket_region,
							// 		folder: 'folder1',
							// 		entityImageSourceFieldName: 'titleImageUrl',
							// 		entityImageMediaTypeFieldName: 'titleImageType',
							// 		identitySource: 'ITEM_OWNER',
							// 		canEdit: 'ITEM_OWNER'
							// 	}
							// }
						],
					},
				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			}

		],
		visibilityConditions: [{
			visible: true,
			conditionType: 'HAS_HEADING'
		}],
		notVisibleHelperMessage: 'This is for content with headings'

	},
	content: {

		sections: [
			{
				title: 'Numbering Visibility',
				elements: []
			},
			{
				title: 'Numbering Display Method',
				elements: []
			}

		]

	},
	text: {

		sections: [
			{
				title: 'First Paragraph',
				elements: [

					{
						type: 'INFOHEADING',
						title: 'Vellum has regular/first sentence small-caps/first line small-caps/dropcaps'
					},
					{
						type: 'SUBHEADING',
						title: 'Style',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: 'First paragraph',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultChapterContentTemplate',
						templateSourceTag: 'TEMP_chContent',
						hideLabel: true

					},
				]
			},
			{
				title: 'Paragraph after first',
				elements: [
					// {
					// 	type: 'PARAGRAPH_AFTER_FIRST',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: 'Paragraph after first',
					// 	paramType: 'RECIPE',
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	isParagraphAfterFirstParam: true,
					// 	recipeParamName: 'global_paragraphAfterFirst_firstLineTextTransform',
					// 	recipeMediaTypeParamName: 'global_blockquote_symbolMediaType',
					// 	templateSourceTag: 'TEMP_chContent',
					// 	hideLabel: true
					// },

					{
						type: 'INFOHEADING',
						title: 'Vellum calls this "Paragraph after break" and have the same options of first paragraph ==> regular/first sentence small-caps/first line small-caps/dropcaps',
						visibilityConditionsx: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Style',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{

						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Paragraph After First',
						paramType: 'RECIPE',
						//paramItem: 'BOOK',
						bucketName: config.common_content_bucket_name,
						folder: 'public/paragraphAfterFirst',
						level: 'public',
						isParagraphAfterFirstParam: true,
						recipeParamName: 'global_paragraphAfterFirst_firstLineTextTransform',
						//recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						//hideUploadControls:true
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Drop Cap Lines',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Title Text TypeDropcap Size',
						paramType: 'RECIPE',
						recipeParamName: 'global_paragraphAfterFirst_dropCapSize',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: '2', value: '2' },
							{ label: '3', value: '3' },
							{ label: '4', value: '4' },
							{ label: '5', value: '5' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Drop Cap Font',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{

						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Drop Cap Font',
						paramType: 'RECIPE',
						paramItem: 'BOOK',
						bucketName: config.common_content_bucket_name,
						folder: 'public/fonts',
						level: 'public',
						isFontParam: true,
						recipeParamName: 'global_paragraphAfterFirst_dropCapFont',
						//recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},


				]
			},
			{
				title: 'Blockquote ',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: '',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultBlockQuoteTemplate',
						templateSourceTag: 'TEMP_blockQuote',
						hideLabel: true

					},
					{
						type: 'SUBHEADING',
						title: 'Blockquote Symbol',
						addTopMargin: false
					},
					{
						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Blockquote Symbol',
						paramType: 'RECIPE',
						paramItem: 'BOOK',
						bucketName: config.common_content_bucket_name,
						folder: 'public/blockQuotes',
						level: 'public',
						recipeParamName: 'global_blockquote_symbol',
						recipeMediaTypeParamName: 'global_blockquote_symbolMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER'
					},
				]
			},
			{
				title: 'Attribution',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: '',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultAttributionTemplate',
						templateSourceTag: 'TEMP_attribution',
						hideLabel: true

					},
				]
			},
			{
				title: 'Subhead',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: '',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultSubheadTemplate',
						templateSourceTag: 'TEMP_subhead',
						hideLabel: true

					}
				]
			},
			{
				title: 'Verse',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: '',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultVerseTemplate',
						templateSourceTag: 'TEMP_verse',
						hideLabel: true

					}
				]
			}

		]
	},
	breaks: {

		sections: [
			{
				title: 'Decorated Breaks',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: 'First paragraph',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultOrnamentalBreaksTemplate',
						templateSourceTag: 'TEMP_ornBreak',
						hideLabel: true,
						devModeOnly: true

					},
					{
						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Decorated Break',
						paramType: 'RECIPE',
						paramItem: 'BOOK',
						bucketName: config.common_content_bucket_name,
						folder: 'public/decoratedBreaks',
						level: 'public',
						recipeParamName: 'global_ornamentalBreakImageId',
						recipeMediaTypeParamName: 'global_ornamentalBreakImageMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER'
					},


					{
						type: 'CHECKBOX',
						title: 'Show scene decorated breaks',
						paramType: 'RECIPE',
						recipeParamName: 'global_showOrnamentalBreaks',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Show manual decorated breaks',
						paramType: 'RECIPE',
						recipeParamName: 'global_showOrnamentalBreaks',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					}
				]
			},

		]
	},
	images: {

		sections: [
			{
				title: 'Other Image Stuff Here',
				elements: []
			}

		]
	},
	blots: {
		sections: [
			{
				title: 'Blurbs',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: '',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultBlurbsTemplate',
						templateSourceTag: 'TEMP_blurbs',
						hideLabel: true

					}
				]
			},
			{
				title: 'Also Bys',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: '',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultAlsobysTemplate',
						templateSourceTag: 'TEMP_alsobys',
						hideLabel: true

					}
				]
			},
			{
				title: 'Publisher',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: '',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultPublisherTemplate',
						templateSourceTag: 'TEMP_publisher',
						hideLabel: true
					}
				]
			},
			{
				title: 'Contributors',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: '',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultContributorsTemplate',
						templateSourceTag: 'TEMP_contributors',
						hideLabel: true

					}
				]
			},
			{
				title: 'Contents',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: '',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultTocTemplate',
						templateSourceTag: 'TEMP_toc',
						hideLabel: true

					}
				]
			},
			{
				title: 'Social Media',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: '',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultSocialSheetTemplate',
						templateSourceTag: 'TEMP_socialSheet',
						hideLabel: true

					},
					{
						type: 'CHECKBOX',
						title: 'Include Facebook',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeFacebook',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Twitter',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeTwitter',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Instagram',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeInstagram',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Youtube',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeYoutube',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Snapchat',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeSnapchat',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Pinterest',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includePinterest',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Amazon',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeAmazon',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Bookbub',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeBookbub',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Goodreads',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeGoodreads',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					}

				]
			}

		]
	},
	styles: {

	}
}

export default layout;
//module.exports.layout = layout;