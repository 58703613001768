import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LaurasDetailList from './LaurasDetailList';

const LaurasBooksBoxsets = (props) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
           <LaurasDetailList 
                avatarType={<MenuBookIcon />}
                primary='Boxset title'
                secondary='Since date'
                showWriteIcon={false}
                showFormatIcon={false}
                showReadIcon={true}
                showRestoreButton={false}
           />
           <LaurasDetailList 
                avatarType={<MenuBookIcon />}
                primary='Boxset title 2'
                secondary='Since date'
                showWriteIcon={false}
                showFormatIcon={false}
                showReadIcon={true}
                showRestoreButton={false}
           />
        </div>
    )
}
export default inject('stores')(observer(LaurasBooksBoxsets));