import React from 'react';
// import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

// const Container = styled.div`
//   border: 1px solid lightgrey;
//   border-radius: 2px;
//   padding: 8px;
//   margin-bottom: 8px;
//   background-color: white;
// `;

export default class Task extends React.Component {
  render() {
    return (
      <Draggable 
        draggableId={this.props.task.id} 
        key={this.props.task.id} 
        index={this.props.index}
        type='NODE'>
        {provided => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            innerRef={provided.innerRef}
          >
            {this.props.task.content}
          </div>
        )}
      </Draggable>
    );
  }
}
