import React, { useState, useEffect } from "react";
import { Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import { useManagerCreateSnapshot } from '../../managers/BooksManager';
import StandardTextfield from "../StandardTextfield";

const CreateSnapshotPanel = (props) => {

  let user = props.stores.userStore.user;
  let { book } = props;

  let [title, setTitle] = useState();
  let [description, setDescription] = useState();

  const { createSnapshot, createSnapshotLoading, createSnapshotError } = useManagerCreateSnapshot(props);


  return (
    <Grid container direction={'column'}>
      <Grid item>
        <StandardTextfield label={'Title'} onChange={(e) => {
          setTitle(e.target.value);
        }} />
      </Grid>
      <Grid item>
        <StandardTextfield label={'Description'} onChange={(e) => {
          setDescription(e.target.value);
        }} />
      </Grid>
      <Grid item>
        <Typography variant={'h6'}>{createSnapshotLoading && <div>Creating snapshot...</div>}</Typography>
      </Grid>

      <Divider />
      <Grid container direction={'row'} columnSpacing={2} sx={{
        mt: 3,
        justifyContent: 'flex-end'
      }}>
        <Grid item>
          <StandardButton autoFocus onClick={props?.onClose}>
            Cancel
          </StandardButton>
        </Grid>
        <Grid item>
          <StandardButton autoFocus
            disabled={createSnapshotLoading ? true : false}
            onClick={(e) => {
              e.preventDefault();
              createSnapshot({
                title,
                description,
                bookDraftId: book.primaryBookDraftId
              }, () => {
                props?.onClose();
              })
            }}>
            Ok
          </StandardButton>
        </Grid>
      </Grid>





    </Grid>

  )
}

export default inject('stores')(observer(CreateSnapshotPanel));