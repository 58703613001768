import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { styled, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Switch from '@mui/material/Switch';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDocumentEdit from 'mdi-material-ui/FileDocumentEdit';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import Feather from 'mdi-material-ui/Feather';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import PeopleIcon from '@mui/icons-material/People';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { ApolloConsumer } from '@apollo/client';
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useMediaQuery } from 'react-responsive';
import StandardDashboardPaper from '../StandardPaper';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

const StandardDrawer = styled(Drawer)(() => {


})


function MainMenuContent2(props) {

    let navigate = useNavigate();
    let location = useLocation();

    let [openBooks, setOpenBooks] = useState(false);
    let [openSharedBooks, setOpenSharedBooks] = useState(false);
    let [openConnections, setOpenConnections] = useState(false);
    let [openYourGroups, setOpenYourGroups] = useState(false);
    let [openSharedGroups, setOpenSharedGroups] = useState(false);


    let [openOwnedBoxset, setOpenOwnedBoxset] = useState(false);
    let [openReceivedEditRequest, setOpenReceivedEditRequest] = useState(false);

    const { expanded } = props;

    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    const subItemsIndent = 8;
    const subSubItemsIndent = 0;

    const theme = useTheme();
    const showAllMenus = false;

    const isRoute = (pathname) => {
        //if (pathname && pathname.includes(location.pathname) ) {
        if (location.pathname && location.pathname === pathname) {
            return theme.palette.secondary.main;
        }

        return 'inherit';
    }

    const handleSelection = () => {

    }

    const onLogout = event => {
        event.preventDefault();
        props.stores.bookStore.loggedIn = false;

        Auth.signOut()
            .then(data => {
                //console.log(data);

                props.stores.bookStore.loggedIn = false;
                //props.stores.bookStore.reset();

            })
            .catch(err => {
                console.log(err);

            })
    };

    const selectRoute = (route) => {

        navigate(route);
        //props.onRouteSelected();

    }

    // let menuData = {
    //     items:[
    //         {

    //             title: 'Home',
    //             icon: <HomeIcon color={isRoute('/')} />,
    //             onClick:()=>{}

    //         }
    //     ]
    // }

    const notExpandedWrapper = ({
        title1,
        title2,
        icon,
        route
    }) => {
        return <Box disablePadding
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <ListItemButton onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                selectRoute(route);
                //navigate("/");
            }} sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <ListItemIcon sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>

                    {icon}
                </ListItemIcon>
                <Typography variant={'h7'} style={{ color: props.color }} color={props.color} sx={{
                    color: props.color,
                    paddingBlockEnd: 0
                }}>{title1}</Typography>
                <Typography variant={'h7'} style={{ color: props.color }} color={props.color} sx={{
                    color: props.color,
                    p: 0
                }}>{title2}</Typography>
            </ListItemButton>
        </Box>
    }
    return (
        <>

            <StandardDashboardPaper sx={{
                display: 'flex',
                flexDirection: 'column',
                //width: '100%',
                //position: 'relative',
                //height: '8em',
                borderRadius: '8px',
                //marginTop: '1.75em',
                paddingBottom: '0.5em',
                //border:'1px solid blue'
            }}>


                <Divider />

                <List sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'flex-start',
                    gap: '1em',
                    //marginTop: '5em'
                }}>
                    {expanded &&
                        <Box disablePadding>
                            <ListItemButton onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                selectRoute("/");
                                //navigate("/");
                            }}>
                                <ListItemIcon>

                                    <HomeIcon sx={{
                                        color: isRoute('/')
                                    }}
                                    // color={isRoute('/')} 
                                    />
                                </ListItemIcon>
                                {expanded &&
                                    <ListItemText primary={<Typography variant="h6" style={{ color: isRoute('/') }}>Home</Typography>} />
                                }
                            </ListItemButton>
                        </Box>
                    }


                   
                    {/* {notExpandedWrapper({
                        title1: 'Home',
                        icon: <HomeIcon sx={{
                            color: isRoute('/')
                        }}
                        />,
                        route: '/'
                    })} */}


                    {notExpandedWrapper({
                        title1: 'Library',
                        // title2: 'Books',
                        icon: <MenuBookIcon sx={{
                            color: isRoute('/')
                        }} />,
                        route: '/'
                    })}


                    {/* {notExpandedWrapper({
                        title1: 'Shared',
                        title2: 'Books',
                        icon: <MenuBookIcon sx={{
                            color: isRoute('/sharedBooks/list')
                        }} />,
                        route: '/sharedBooks/list'
                    })}


                    {notExpandedWrapper({
                        title1: 'My',
                        title2: 'Boxsets',
                        icon: <ContentCopyIcon sx={{
                            color: isRoute('/boxsets/list')
                        }} />,
                        route: '/boxsets/list'
                    })}


                    {notExpandedWrapper({
                        title1: 'Shared',
                        title2: 'Boxsets',
                        icon: <ContentCopyIcon sx={{
                            color: isRoute('/sharedBoxsets/list')
                        }} />,
                        route: '/sharedBoxsets/list'
                    })} */}





                    {notExpandedWrapper({
                        title1: 'Connect',
                        icon: <PeopleIcon sx={{
                            color: isRoute('/connections')
                        }} />,
                        route: '/connections'
                    })}

                    {notExpandedWrapper({
                        title1: 'Edit',
                        title2: 'Requests',
                        icon: <AccountEdit sx={{
                            color: isRoute('/editRequests')
                        }} />,
                        route: '/editRequests'
                    })}

                    {/* 
                    {notExpandedWrapper({
                        title1: 'My',
                        title2: 'Groups',
                        icon: <PeopleIcon sx={{
                            color: isRoute('/myGroups/list')
                        }} />,
                        route: '/myGroups/list'
                    })}


                    {notExpandedWrapper({
                        title1: 'Shared',
                        title2: 'Groups',
                        icon: <PeopleIcon sx={{
                            color: isRoute('/sharedGroups/list')
                        }} />,
                        route: '/sharedGroups/list'
                    })} */}



                    <Divider />

                    {notExpandedWrapper({
                        title1: 'Write',
                        icon: <Feather sx={{
                            color: isRoute('/write')
                        }} />,
                        route: '/write'
                    })}

                    {notExpandedWrapper({
                        title1: 'Edit',
                        icon: <AccountEdit sx={{
                            color: isRoute('/edit')
                        }} />,
                        route: '/edit'
                    })}

                    {notExpandedWrapper({
                        title1: 'Format',
                        icon: <FormatPaintIcon sx={{
                            color: isRoute('/format')
                        }} />,
                        route: '/format'
                    })}

                    {notExpandedWrapper({
                        title1: 'Read',
                        icon: <LocalLibraryIcon sx={{
                            color: isRoute('/read')
                        }} />,
                        route: '/read'
                    })}





                    <Divider />



                    {notExpandedWrapper({
                        title1: 'Trash',
                        title2: 'Bin',
                        icon: <TrashCanOutline sx={{
                            color: isRoute('/trashbin')
                        }} />,
                        route: '/trashbin'
                    })}





                    {/* <Box disablePadding>
                    <ListItemButton onClick={(e) => {
                        // setDisplayMode('trashbin')
                        e.preventDefault();
                        e.stopPropagation();
                        selectRoute("/profile");
                    }}>
                        <ListItemIcon>
                            <TrashCanOutline color={isRoute('/profile')} />
                        </ListItemIcon>
                        <ListItemText primary="profile" />
                    </ListItemButton>
                </Box> */}

                    {/* <ApolloConsumer>

                    {(client) => (

                        <Button variant={'contained'} onClick={(e) => {
                            onLogout(e);
                            client.clearStore();
                        }}>Logout</Button>
                    )}
                </ApolloConsumer> */}



                </List >
                {/* <Switch
                checked={props.darkMode}
                onChange={() => {
                    props.toggleDarkMode();
                }}
                inputProps={{ 'aria-label': 'controlled' }}
            /> */}
            </StandardDashboardPaper>
        </>
    );
}

export default inject('stores')(observer(MainMenuContent2));

