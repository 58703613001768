import React, { useState, useEffect } from "react";
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetOwnedBooks, useOpenBookInWrite } from '../../managers/BooksManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import SquareAvatar from "./SquareAvatar";
import DeleteIcon from '@mui/icons-material/Delete';
import StandardRoundedAction from "../actions/StandardRoundedAction";
import Feather from "mdi-material-ui/Feather";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import TablePopupMenu from "../menus/TablePopupMenu";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CommentIcon from '@mui/icons-material/Comment';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import RemoveBoxsetContributedBookPanel from "../panels/RemoveBoxsetContributedBookPanel";
import { useManagerLoadBoxSetContributors } from '../../managers/BoxsetsManager';
import RemoveBoxsetContributorPanel from '../../components/panels/RemoveBoxsetContributorPanel';
import CancelTableCell from "../table/CancelTableCell";
import StandardOkCancelDialog from "../panels/StandardOkCancelDialog";
import { useMediaQuery } from 'react-responsive';
import EmptyContent from "../EmptyContent";
import AddBookAssembly from "../assemblies/AddBookAssembly";
import StandardList from "./StandardList";

let missingBookImage = require('../../assets/images/image-not-found.png')

const OwnedBoxsetBooksList = (props) => {

    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();




    let boxset = props.stores.bookStore.boxSetListBoxSet;

    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    let [boxsetBooks, setBoxsetBooks] = useState([]);
    let [showRemoveBookDialog, setShowRemoveBookDialog] = useState(false);
    let [selectedBook, setSelectedBook] = useState(false);
    let {
        boxsetContributors,
        loading: lazyLoadBoxSetContributorsLoading,
        error: lazyLoadBoxSetContributorsError
    } = useManagerLoadBoxSetContributors(props, boxset?.id);

    //console.log(boxsetContributors);

    useEffect(() => {

        try {

            let bookData = {};
            boxsetContributors.forEach((boxsetContributor) => {

                boxsetContributor.contributedBooks.items.forEach((bsb) => {

                    bookData[bsb.book.id] = ({
                        boxsetContributor,
                        boxsetBook: bsb
                    })
                })

            });

            setBoxsetBooks(Object.values(bookData));

        } catch (err) {
            console.log(err);
        }
    }, [boxsetContributors]);


    const createSecondaryActions = (bsbData) => {

        let secondaryActions = [];

        if (props.showSelectIcon) {
            secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
                //console.log(bsbData)
                props.onSelected(bsbData);
            }}>
                <ShareIcon />
            </IconButton>);
        }

        return secondaryActions;
    }

    return (
        <StandardList
            title='Books'
            headerActions={<AddBookAssembly boxset={boxset}/>}
            component="div"
            disablePadding>
            {boxsetBooks && boxsetBooks.length > 0 ?
                boxsetBooks.map((bsbData) => {

                    return <>

                        <ListItem
                            alignItems="flex-start"

                            secondaryAction={



                                <Box sx={{
                                    display: 'flex'
                                }}>
                                    <CancelTableCell onClick={(e) => {
                                        e.preventDefault();
                                        setShowRemoveBookDialog(true);
                                        setSelectedBook(bsbData.boxsetBook.book);
                                    }} />


                                </Box>







                            }>
                            <ListItemAvatar
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelected(bsbData);
                                }}>
                                <SquareAvatar
                                    //bucketName={config.user_uploads_bucket_name}
                                    s3Src={bsbData.boxsetBook.book.coverUrl}
                                    missingContent={<img src={missingBookImage} />}
                                />
                                {/* <Avatar alt="Remy Sharp" variant="square" src="https://local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm.s3.amazonaws.com/uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/c7cb5750-3627-11ed-914f-5d91b6c4c7eb_planetStrider.png">

              </Avatar> */}
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    noWrap: true
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelected(bsbData);
                                }}
                                primary={bsbData.boxsetBook.book.title}
                                secondary={
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {bsbData.boxsetContributor.contributor.name}
                                        </Typography>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="caption"
                                            color="text.secondary"
                                        >
                                            Added ago
                                        </Typography>
                                        {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                    </Box>
                                }

                            />

                        </ListItem>
                        <Divider variant="inset"/>


                        <StandardOkCancelDialog
                            showOkButton={true}
                            showCancelButton={true}
                            open={showRemoveBookDialog}
                            onClose={() => {
                                setShowRemoveBookDialog(false)
                                setSelectedBook(null)
                            }}
                            onOk={() => {
                                setShowRemoveBookDialog(false)
                                setSelectedBook(null)

                            }}
                            title={'Remove Contributor'}
                            content={
                                <RemoveBoxsetContributedBookPanel boxset={boxset} book={selectedBook} onRemoved={() => {
                                    setShowRemoveBookDialog(false)
                                    setSelectedBook(null)
                                    //lazyLoadBoxSetContributorsRefetch();

                                }} />
                            }
                        />

                    </>
                    // <Divider variant="inset"/>


                    // return <ListItem secondaryAction={createSecondaryActions(book)}>
                    //   <ListItemAvatar>
                    //     <AvatarTableCell
                    //       bucketName={config.user_uploads_bucket_name}
                    //       s3Src={book?.coverUrl}
                    //     //title={userConnection?.connectedUser?.name}
                    //     />
                    //   </ListItemAvatar>
                    //   <ListItemText primary={book?.title} secondary="" />

                    // </ListItem>
                })
                :
                <Box sx={{
                  marginTop: '3em'
                }}>
                  <EmptyContent tagline={'Contributed books will show up here.'} />
                </Box>
                
            }
            {/* {ownedBooks.length}:
      {availableBooks.length} */}
        </StandardList>

    )
}

export default inject('stores')(observer(OwnedBoxsetBooksList));