import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { Button, Paper, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import StandardHelpLabel from '../../StandardHelpLabel';
import Checkbox from '@mui/material/Checkbox';

const EditPopper = (props) => {

    let { open, onClose = () => { }, anchorEl, editBlot, documentPart } = props;

    const [accepted, setAccepted] = useState(editBlot?.domNode?.dataset?.accepted)
    const [rejected, setRejected] = useState(editBlot?.domNode?.dataset?.rejected)
    const [status, setStatus] = useState()

    useEffect(() => {

        //setAccepted(editBlot?.domNode?.dataset?.accepted);
        if(editBlot?.domNode?.dataset?.accepted=='true'){
            setStatus('accept');
        }

    }, [editBlot?.domNode?.dataset?.accepted]);


    useEffect(() => {

        //setRejected(editBlot?.domNode?.dataset?.rejected); 
        if(editBlot?.domNode?.dataset?.rejected=='true'){
            setStatus('reject');
        }

    }, [editBlot?.domNode?.dataset?.rejected]);


    

    return (
        <Popper
            id={'editPopper'}
            open={open}
            onMouseLeave={() => {
                onClose();

            }}

            anchorEl={anchorEl}
            transition
            placement="bottom"
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '1em'
                        }}>
                            <StandardHelpLabel title={'Edit Details'} />
                           

                            <FormControl>
                                {/* <FormLabel id="demo-radio-buttons-group-label">Image Size</FormLabel> */}
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={status}
                                    name="radio-buttons-group"
                                    onChange={(e, value) => {
                                        e.preventDefault();
                                       
                                        switch(e.target.value){

                                            case 'accept':

                                                editBlot.accept();
                                                setAccepted('true');
                                                setRejected('false');
                                                props.stores.bookStore.setBlotsChangedTimeout();

                                            break;

                                            case 'reject':

                                                editBlot.reject();
                                                setAccepted('false');
                                                setRejected('true');
                                                props.stores.bookStore.setBlotsChangedTimeout();


                                            break;

                                           


                                        }
                                        
                                        //documentPart.contentUpdatedTimestamp = new Date().getTime();

                                    }}
                                >
                                    <FormControlLabel  control={<Radio value="accept"/>} label="accept" />
                                    <FormControlLabel  control={<Radio value="reject"/>} label="reject" />
                                    <Button onClick={()=>{
                                        //console.log('clear')
                                        editBlot.clearChoices();
                                        setStatus('');
                                        props.stores.bookStore.setBlotsChangedTimeout();
                                    }}>Clear</Button>
                                    <div>{status}</div>
                                </RadioGroup>
                            </FormControl>

                            


                        </div>
                    </Paper>
                </Fade>
            )}
        </Popper>

    );
};


export default inject('stores')(observer(EditPopper));
