import { Box } from '@mui/material';
import React from 'react';





function StretchBox({flexDirection='row', children, sxProps}) {
 
  


  return (
    <Box
    sx={{
      flex: 1,
      //border: '4px solid white',
      display: 'flex',
      flexDirection: flexDirection,
      overflow: 'hidden',
      ...sxProps
    }}
  >
    {children}
    </Box>
  );
}

export default StretchBox;
