import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const LaurasUserList = (props) => {

    let { showRemoveIcon = true, showReadIcon = false } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const secondaryActions = [];

    if (showRemoveIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <RemoveCircleOutlineIcon />
        </IconButton>)
    }

    if (showReadIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <ManageSearchIcon />
        </IconButton>)
    }

    return (
        <List>
            <ListItem
                sx={{ paddingLeft: ' 0.25em' }}
                secondaryAction={secondaryActions}
            >
                <ListItemAvatar>
                    <Avatar>
                        {props.avatarType}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={props.primary}
                    secondary={props.secondary}
                />
            </ListItem>
        </List>
    )
}
export default inject('stores')(observer(LaurasUserList));