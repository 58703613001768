import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { useLoadBookEditRequests, useOpenEditRequestInEdit } from '../../managers/EditRequestsManager';
import StandardDataTable from './StandardDataTable';
import CreateSnapshotDialog from '../panels/CreateSnapshotDialog';
import { Box, Button } from '@mui/material';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { IconButton } from '@mui/material';
import config from '../../aws-exports';
import AvatarTableCell from './AvatarTableCell';
import ReactTimeAgo from 'react-time-ago';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EditorEditRequestController from '../editing/EditorEditRequestController';
import TimelineIcon from '@mui/icons-material/Timeline';
import StandardRoundedAction from '../actions/StandardRoundedAction';
import StandardDialog from '../StandardDialog';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useGetUserConnections } from '../../managers/ConnectionsManager';
import { useOpenUserInConnections } from '../../managers/ConnectionsManager';
import RequestEditAssembly from '../assemblies/RequestEditAssembly';

const BookEditsTable = (props) => {

    let { book } = props;
    let { editRequest } = props;
    const { userConnections, error: getUserConnectionsError } = useGetUserConnections(props);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');
    const [showCreateSnapshotDialog, setShowCreateSnapshotDialog] = React.useState(false);
    let [showTimelinePopover, setShowTimelinePopover] = useState(false);

    const theme = useTheme();
    let navigate = useNavigate();

    const { openEditRequestInEdit } = useOpenEditRequestInEdit(props);
    const { openUserInConnections } = useOpenUserInConnections(props);

    let { loading: getBookEditRequestsLoading, error: getBookEditRequestsError, data: getBookEditRequestsData } = useLoadBookEditRequests(props, book?.id)

  




    // useEffect(() => {

    //     let { loading: getBookEditRequestsLoading, error: getBookEditRequestsError, data: getBookEditRequestsData } = useGetBookEditRequests(props, book?.id)

    // }, []);

    let tableData = [];
    if (getBookEditRequestsData?.getBook.editRequests) {
        tableData = getBookEditRequestsData?.getBook.editRequests.items;
    }
    return (

        <Box sx={{
            height: '100%'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <StandardDataTable
                    title={'Edit Requests'}
                    noDataMessage={'Send your first edit request!'}
                    // title={'Edits for ' + book?.title}
                    order={order}
                    onSetOrder={setOrder}
                    orderBy={orderBy}
                    onSetOrderBy={setOrderBy}
                    headerActions={<RequestEditAssembly book={book}/>}

                    structure={{
                        dataDef: {
                            data: tableData,//xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx,
                            rowKeyDataFieldName: 'id'
                        },
                        columns: [

                            {
                                title: 'Editor',
                                dataValueFunction: (editRequest) => {
                                    return <AvatarTableCell
                                        bucketName={config.user_uploads_bucket_name}
                                        s3Src={editRequest.editorUser?.profilePicUrl}
                                        title={editRequest.editorUser?.name}
                                        // onClick={(e) => {
                                        //     e.preventDefault();
                                        //     openUserInConnections({
                                        //         userConnection:editRequest.editorUser
                                        //     });
                                        // }}
                                    />

                                }
                            },
                            {
                                title: 'Edit',
                                dataValueFunction: (editRequest) => {
                                    return <StandardRoundedAction icon={<AccountEdit sx={{
                                        color: theme.palette.standardActions.icon.color.main,
                                        //color: theme.palette.standardActions.icon.backgroundColor.main

                                    }} onClick={(e) => {
                                        e.preventDefault();
                                        //console.log(editRequest)
                                        //navigate("/edit");

                                        openEditRequestInEdit({
                                            editRequest
                                        });
                                    }} />} />
                                    // <IconButton aria-label="delete">
                                    //     <AccountEdit sx={{ color: theme.palette.background.main }} onClick={(e) => {
                                    //         e.preventDefault();
                                    //         console.log(editRequest)
                                    //         //navigate("/edit");

                                    //         openEditRequestInEdit({
                                    //             editRequest
                                    //         });
                                    //         // openBookInWrite({
                                    //         //   bookDraftId: book.primaryBookDraftId
                                    //         // });


                                    // }} />
                                    // </IconButton>

                                }
                            },
                            {
                                title: 'Status',
                                dataFieldName: null,
                                sortable: true,
                                dataValueFunction: (editRequest) => { return 'Editor Accepted' }
                            },
                            {
                                title: 'Timeline',
                                dataValueFunction: (editRequest) => {
                                    return <IconButton aria-label="">
                                        <TimelineIcon sx={{ color: theme.palette.background.main }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                //console.log(editRequest)
                                                setShowTimelinePopover(true)
                                            }} />
                                    </IconButton>
                                }
                            },


                            {
                                title: 'Timeline',
                                expandable: true,
                                exoandedRowComponent: (editRequest) => {
                                    return <EditorEditRequestController
                                        editRequest={editRequest}
                                        showOriginal={true}
                                        hideHeading={true} />
                                }

                            },

                            {
                                title: 'Created',
                                dataFieldName: 'createdAt',
                                sortable: true,
                                dataValueFunction: (editRequest) => { return editRequest.createdAt != null ? <ReactTimeAgo date={editRequest.createdAt} locale="en-US" /> : '' },
                                // dataValueFunction: (editRequest) => { return editRequest.createdAt }
                            },
                            // {
                            //     title: 'Write',
                            //     dataValueFunction: (book) => {
                            //         return <IconButton aria-label="delete">
                            //             <Feather sx={{ color: theme.palette.background.main }} onClick={(e) => {
                            //                 e.preventDefault();
                            //                 console.log(book)
                            //                 //navigate("/write");
                            //                 openBookInWrite({
                            //                     bookDraftId: book.primaryBookDraftId
                            //                 });
                            //             }} />
                            //         </IconButton>
                            //     }
                            // },
                            // {
                            //     title: 'Format',
                            //     dataValueFunction: (book) => {
                            //         return <IconButton aria-label="delete">
                            //             <FormatPaintIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                            //                 e.preventDefault();
                            //                 //navigate("/format");
                            //                 openBookInFormat({
                            //                     bookDraftId: book.primaryBookDraftId
                            //                 });
                            //             }} />
                            //         </IconButton>

                            //     }
                            // },
                            // {
                            //     title: 'Snapshots',
                            //     expandable: true,
                            //     exoandedRowComponent: (book) => { return <BookEditsTable book={book} /> }
                            // },
                            // {
                            //     icon: <EditIcon sx={{ color: theme.palette.words?.main }} />,
                            //     expandable: true,
                            //     exoandedRowComponent: (book) => { return <div>its a book 2: {book.title}</div> }
                            // },
                            // {
                            //     title: 'Created',
                            //     dataFieldName: 'createdAt',
                            //     sortable: true,
                            //     dataValueFunction: (book) => { return book.createdAt != null ? <ReactTimeAgo date={book.createdAt} locale="en-US" /> : '' }
                            // },





                        ]
                    }} />
                {/* <Button variant='contained' onClick={(e) => {
                e.preventDefault();

                setShowCreateSnapshotDialog(TrashCanOutline);

            }}>Create Snapshot</Button>
            <CreateSnapshotDialog
                open={showCreateSnapshotDialog}
                bookDraftId={book?.primaryBookDraftId}
                onClose={() => { setShowCreateSnapshotDialog(false) }}
            /> */}
            </Box>
            <StandardDialog
                open={showTimelinePopover}
                onClose={() => {
                    setShowTimelinePopover(false)
                }}
                onOk={() => {


                }}
                title={'Editing Timeline'}
                dialogContent={
                    <EditorEditRequestController
                        editRequest={editRequest}
                        showOriginal={true}
                        hideHeading={true} />
                }
            >
            </StandardDialog>

            {/* <StandardPopover
                open={showTimelinePopover}
                onClose={() => {
                    setShowTimelinePopover(true)
                }}
                content={<EditorEditRequestController
                    editRequest={editRequest}
                    showOriginal={true}
                    hideHeading={true} />}
            /> */}
        </Box>
    )
}
export default inject('stores')(observer(BookEditsTable));