import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Switch from '@mui/material/Switch';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDocumentEdit from 'mdi-material-ui/FileDocumentEdit';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import Feather from 'mdi-material-ui/Feather';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import PeopleIcon from '@mui/icons-material/People';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { ApolloConsumer } from '@apollo/client';
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ShareIcon from '@mui/icons-material/Share';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import SettingsIcon from '@mui/icons-material/Settings';
import RoutesHelper from '../../helpers/RoutesHelper';
import { useGetOwnedBooks, useOpenBookInWrite, useOpenBookInFormat, useManagerCreateBook, useManagerCreateSnapshot } from '../../managers/BooksManager';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { useMediaQuery } from 'react-responsive';
import CommentIcon from '@mui/icons-material/Comment';
import { StandardListButton } from '../StandardButton';
import ShareBookPanel from '../panels/share/ShareBookPanel';
import StandardOkCancelDialog from '../panels/StandardOkCancelDialog';
import CreateSnapshotPanel from '../panels/CreateSnapshotPanel';
import StandardDialog from '../StandardDialog';
import RequestEditPanel from '../panels/editing/RequestEditPanel';
import StandardRoundedAction from '../actions/StandardRoundedAction';
import StandardFooterBar from '../StandardFooterBar';

const StandardDrawer = styled(Drawer)(() => {


})


function TrashbinMenuContent(props) {

    
    let {itemType} = props;

    let navigate = useNavigate();
    let location = useLocation();

    let [openBooks, setOpenBooks] = useState(true);
    let [showShareDialog, setShowShareDialog] = useState(false);
    let [showCreateSnapshotDialog, setShowCreateSnapshotDialog] = useState(false);
    let [showRequestEditDialog, setShowRequestEditDialog] = useState(false);



    const { openBookInWrite } = useOpenBookInWrite(props);
    const { openBookInFormat } = useOpenBookInFormat(props);

    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    const subItemsIndent = 0;
    const subSubItemsIndent = 0;

    const theme = useTheme();


    const isRoute = (pathname) => {
        //if (pathname && pathname.includes(location.pathname) ) {
        if (location.pathname && location.pathname.includes(pathname)) {
            return 'secondary';
        }

        return 'inherit';
    }

    const handleSelection = () => {

    }

    const onLogout = event => {
        event.preventDefault();
        props.stores.bookStore.loggedIn = false;

        Auth.signOut()
            .then(data => {
                //console.log(data);

                props.stores.bookStore.loggedIn = false;
                //props.stores.bookStore.reset();

            })
            .catch(err => {
                console.log(err);

            })
    };

    const selectRoute = (route) => {

        navigate(route);
        props.onRouteSelected();

    }

    return (
        <>
            <List component="div" disablePadding>

                {/* {!isXs &&
                    <ListItemButton sx={{
                        pl: 1
                    }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            selectRoute("/books/list");
                        }}>
                        <ListItemIcon>
                            <ArrowBackSharpIcon color={'inherit'} />
                        </ListItemIcon>
                        <ListItemText primary="Back to Your Books" />
                    </ListItemButton>
                } */}





                <ListItemButton sx={{
                    pl: subItemsIndent + subSubItemsIndent
                }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.setItemType('BOOKS');
                    }}>
                    <ListItemIcon>
                        <MenuBookIcon color={itemType=='BOOKS' ? 'secondary' : 'inherit'} />
                    </ListItemIcon>
                    <ListItemText primary="Books" />
                </ListItemButton>

                <ListItemButton sx={{
                    pl: subItemsIndent + subSubItemsIndent
                }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.setItemType('BOXSETS');
                    }}>
                    <ListItemIcon>
                        <ContentCopyIcon color={itemType=='BOXSETS' ? 'secondary' : 'inherit'} />
                    </ListItemIcon>
                    <ListItemText primary="Boxsets" />
                </ListItemButton>


                <ListItemButton sx={{
                    pl: subItemsIndent + subSubItemsIndent
                }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.setItemType('SNAPSHOTS');
                    }}>
                    <ListItemIcon>
                        <CopyAllIcon color={itemType=='SNAPSHOTS' ? 'secondary' : 'inherit'} />
                    </ListItemIcon>
                    <ListItemText primary="Snapshots" />
                </ListItemButton>

              

               
                <Divider />





                {/* <ListItem disablePadding>
                    <ListItemButton sx={{
                        pl: subItemsIndent + subSubItemsIndent
                    }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            //selectRoute("/write");

                            openBookInWrite({
                                bookDraftId: props.stores.bookStore.bookListBook?.primaryBookDraftId
                            });

                        }}>
                        <ListItemIcon>
                            <StandardRoundedAction icon={<Feather color={'#ffffff'} />} />
                        </ListItemIcon>
                        <ListItemText primary="Write" />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton sx={{
                        pl: subItemsIndent + subSubItemsIndent
                    }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            //selectRoute("/format");
                            openBookInFormat({
                                bookDraftId: props.stores.bookStore.bookListBook?.primaryBookDraftId
                            });
                        }}>
                        <ListItemIcon>
                            <StandardRoundedAction icon={<FormatPaintIcon color={'#ffffff'} />} />
                        </ListItemIcon>
                        <ListItemText primary="Format" />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton sx={{
                        pl: subItemsIndent + subSubItemsIndent
                    }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            //selectRoute("/format");

                        }}>
                        <ListItemIcon>
                            <StandardRoundedAction icon={<TrashCanOutline color={'#ffffff'} />} />
                        </ListItemIcon>
                        <ListItemText primary="Delete" />
                    </ListItemButton>
                </ListItem> */}



                {RoutesHelper.isRoute('/books/details/sharing', location.pathname) &&
                    <ListItem disablePadding>
                        <StandardListButton onClick={() => {
                            setShowShareDialog(true)
                        }}>Share Book</StandardListButton>
                    </ListItem>
                }

                {RoutesHelper.isRoute('/books/details/snapshots', location.pathname) &&
                    <ListItem disablePadding>
                        <StandardListButton onClick={() => {
                            setShowCreateSnapshotDialog(true)
                        }}>Create Snapshot</StandardListButton>
                    </ListItem>
                }

                {RoutesHelper.isRoute('/books/details/editing', location.pathname) &&
                    <ListItem disablePadding>
                        <StandardListButton onClick={() => {
                            setShowRequestEditDialog(true)
                        }}>Request Edit</StandardListButton>
                    </ListItem>
                }


            </List>






        </>


    );
}

export default inject('stores')(observer(TrashbinMenuContent));

