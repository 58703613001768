import React, { useState, useEffect } from "react";
import { Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import StandardTextfield from "../StandardTextfield";
import StandardButton from "../StandardButton";
import { useManagerCreateUserGroup } from "../../managers/ConnectionsManager";

const CreateGroupPanel = (props) => {

  let user = props.stores.userStore.user;

  let [name, setName] = useState(null);
  let [description, setDescription] = useState(null);

  let {createUserGroup,loading: shareBookWithUserGroupLoading,error: shareBookWithUserGroupError} = useManagerCreateUserGroup(props);


  const handleNameChange = (e) => {
    e.preventDefault();

    setName(e.target.value);
  }

  const handleDescriptionChange = (e) => {
    e.preventDefault();

    setDescription(e.target.value);
  }


  return (
    <Grid container direction={'column'}>
      <Grid item>
        <StandardTextfield label={'Name'} onChange={handleNameChange} value={props.name} />
      </Grid>
      <Grid item>
        <StandardTextfield label={'Description'} onChange={handleDescriptionChange} value={props.description} />
      </Grid>



      <Divider />
      <Grid container direction={'row'} columnSpacing={2} sx={{
        mt: 3,
        justifyContent: 'flex-end'
      }}>
        <Grid item>
          <StandardButton autoFocus onClick={props?.onClose}>
            Cancel
          </StandardButton>
        </Grid>
        <Grid item>
          <StandardButton 
            autoFocus 
            //disabled={restoreSnapshotLoading ? true : false} 
            onClick={(e) => {
            e.preventDefault();
            createUserGroup({
              name,
              description
            }, () => {
             
              //onSuccess
              if(props.onGroupCreated){
                props.onGroupCreated();
              }

            }, () => {
             
              //onError

            })
          }}>
            Ok
          </StandardButton>
        </Grid>
      </Grid>





    </Grid>

  )
}

export default inject('stores')(observer(CreateGroupPanel));