import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import AppWrapper from '../components/AppWrapper';
import CheckoutForm from '../components/stripe/CheckoutForm';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import config from '../config';

const stripePromise = loadStripe("pk_test_7yPJSuN6e8juFqqs9nIfbeP300KRqR7dHC");



const Pricing = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  let navigate = useNavigate();
  let location = useLocation();




  return (
    <>

      <Box style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
Pricing
        {/* <script async src="https://js.stripe.com/v3/pricing-table.js"></script> */}
        <stripe-pricing-table pricing-table-id="prctbl_1No9ikFrP83iscO6lnSRjDLS"
          publishable-key="pk_test_7yPJSuN6e8juFqqs9nIfbeP300KRqR7dHC">
        </stripe-pricing-table>


      </Box>

    </>


  )
}
export default inject('stores')(observer(Pricing));
// export default inject('stores')(observer(Books));