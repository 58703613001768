import React, { Component } from 'react';
// import { BuildManager } from '../TemplateManager';
import { BuildManager } from '../BuildManager';
import { RecipeUtils } from '../RecipeUtils';
class BaseControl extends Component {

	constructor(props) {
		super(props)


	}

	partTypeParamMappings = {

		VOLUME: {
			hasHeader: true,
			headerTemplateParam: 'defaultVolumeHeaderTemplate',
			headerTemplateTag: 'TEMP_volHeader',
			subtitleTemplateParam: 'defaultVolumeSubTitleTemplate',
			subtitleTemplateTag: 'TEMP_subtitle'
		},
		PART: {
			hasHeader: true,
			headerTemplateParam: 'defaultPartHeaderTemplate',
			headerTemplateTag: 'TEMP_partHeader',
			subtitleTemplateParam: 'defaultPartSubTitleTemplate',
			subtitleTemplateTag: 'TEMP_subtitle'
		},
		CHAPTER: {
			hasHeader: true,
			headerTemplateParam: 'defaultChapterHeaderTemplate',
			headerTemplateTag: 'TEMP_chHeader',
			subtitleTemplateParam: 'defaultChapterSubTitleTemplate',
			subtitleTemplateTag: 'TEMP_subtitle'
		},
		TITLEPAGE: {
			hasHeader: false,
			headerTemplateParam: 'defaultTitlePageHeaderTemplate',
			headerTemplateTag: 'TEMP_titlePageHeader',
		},
		HALFTITLE: {
			hasHeader: false,
			headerTemplateParam: 'defaultHalfTitleHeaderTemplate',
			headerTemplateTag: 'TEMP_halfTitleHeader',
		},
		COPYRIGHT: {
			hasHeader: true,
			headerTemplateParam: 'defaultCopyrightHeaderTemplate',
			headerTemplateTag: 'TEMP_commonHeader',
		},
		DEDICATION: {
			hasHeader: true,
			headerTemplateParam: 'defaultDedicationHeaderTemplate',
			headerTemplateTag: 'TEMP_commonHeader',
		},
		EPIGRAPH: {
			hasHeader: true,
			headerTemplateParam: 'defaultEpigraphHeaderTemplate',
			headerTemplateTag: 'TEMP_commonHeader',
		},
		TOC: {
			hasHeader: false,
			headerTemplateParam: 'xxxxx',
			headerTemplateTag: 'xxxxx',
		},
		ACKNOWLEDGEMENTS: {
			hasHeader: true,
			headerTemplateParam: 'defaultAcknowledgementsHeaderTemplate',
			headerTemplateTag: 'TEMP_commonHeader',
		},
		FOREWORD: {
			hasHeader: true,
			headerTemplateParam: 'defaultForewordHeaderTemplate',
			headerTemplateTag: 'TEMP_forewordHeader',
		},
		PREFACE: {
			hasHeader: true,
			headerTemplateParam: 'defaultPrefaceHeaderTemplate',
			headerTemplateTag: 'TEMP_commonHeader',
		},
		PROLOGUE: {
			hasHeader: true,
			headerTemplateParam: 'defaultPrologueHeaderTemplate',
			headerTemplateTag: 'TEMP_commonHeader',
		},
		EPILOGUE: {
			hasHeader: true,
			headerTemplateParam: 'defaultEpilogueHeaderTemplate',
			headerTemplateTag: 'TEMP_commonHeader',
		},
		AFTERWORD: {
			hasHeader: true,
			headerTemplateParam: 'defaultAfterwordHeaderTemplate',
			headerTemplateTag: 'TEMP_commonHeader',
		},
		ABOUTAUTHOR: {
			hasHeader: true,
			headerTemplateParam: 'defaultAboutAuthorHeaderTemplate',
			headerTemplateTag: 'TEMP_commonHeader',
		},
		ALSOBY: {
			hasHeader: true,
			headerTemplateParam: 'defaultAlsoByHeaderTemplate',
			headerTemplateTag: 'TEMP_commonHeader',
		},
		FULLPAGEIMAGE: {
			hasHeader: false,
			headerTemplateParam: 'xxxxx',
			headerTemplateTag: 'xxxxx',
		},
		NOTES: {
			hasHeader: false,
			headerTemplateParam: 'xxxxx',
			headerTemplateTag: 'xxxxx',
		},
		BLURBS: {
			hasHeader: true,
			headerTemplateParam: 'defaultBlurbsHeaderTemplate',
			headerTemplateTag: 'TEMP_commonHeader',
		},
		INTRODUCTION: {
			hasHeader: true,
			headerTemplateParam: 'defaultIntroductionHeaderTemplate',
			headerTemplateTag: 'TEMP_commonHeader',
		},
		UNTYPED: {
			hasHeader: true,
			headerTemplateParam: 'defaultUntypedHeaderTemplate',
			headerTemplateTag: 'TEMP_commonHeader',
		}
	}

	isVisible() {
		const { element, documentPart } = this.props;

		//console.log('isVisible...')
		let isVisible = false;

		try{
			if(this.props.stores.bookStore.disabledFormatProps[element.recipeParamPath]!=null){
				return false;
			}
		}
		catch(err){
			console.log(err);
		}
		
		try {

			let conditions = element.visibilityConditions;

			if (!conditions) {
				isVisible = true;
			}

			/*
visibilityConditions: [{
							visible: true,
							conditionType: 'IS_INSERTED_BOOK_CONTENT',
							notVisibleHelperMessage:'This is for inserted books content'
						}]
			*/
			if (conditions) {

				conditions.forEach((condition) => {

					switch (condition.conditionType) {

						case 'RECIPE_PARAM_VALUE':
							let recipeParam = this.getRecipeParamByName(condition.recipeParamName);
							try {
								switch (condition.operator) {
									case 'eq':
										if (recipeParam.value == condition.recipeParamValue) {
											isVisible = true;
										}
										break;

									case 'ne':
										if (recipeParam.value != condition.recipeParamValue) {
											isVisible = true;
										}
										break;
								}



							} catch (err) {

							}

							break;
						case 'PART_TYPE':

							try {
								switch (condition.operator) {
									case 'eq':
										if (documentPart.partType == condition.value) {
											isVisible = true;
										}
										break;

									case 'ne':
										if (documentPart.partType == condition.value) {
											isVisible = true;
										}
										break;
								}



							} catch (err) {

							}

							break;
						case 'IS_INSERTED_BOOK_CONTENT':

							try {

								//notVisibleHelperMessage:'This is for inserted books content'
								//isVisible =  documentPart.isInsertedBookContent();
								isVisible = true;

							} catch (err) {

							}

							break;



					}
				})
			}

		} catch (err) {
			//console.log(err);
		}

		return isVisible;
	}

	getRecipeParamByName(recipeParamName) {

		const { element, documentPart } = this.props;

		//console.log(recipeParamName);

		let recipeParamId = recipeParamName.replace(/---PART_TYPE---/gi, this.toCappedPartType(documentPart));
		recipeParamId = recipeParamId.replace(/---PART_TYPE_LOWER---/gi, documentPart.partType.toLowerCase());



		if (element.configType == 'LOOKUP') {

			if (element.configDetails == 'HEADER_BY_PART_TYPE') {

				let partMapping = this.partTypeParamMappings[documentPart.partType];
				if (partMapping && partMapping.hasHeader) {
					recipeParamId = partMapping.headerTemplateParam;
				}


			}
		}




		let bm = new BuildManager();
		let bookMerlinContext = this.props.stores?.bookStore.currentBookMerlinContext;

		//let currentRecipe = this.props.stores?.bookStore.activeRecipe;
		let currentRecipe = this.props.documentPart?.getRecipe();
		return bm.getParam({
			paramId: recipeParamId,
			paramSource: 'RECIPE',
			context: bookMerlinContext,
			recipe: currentRecipe.json,
			recipeObject: currentRecipe
		})


	}

	getRecipeParam() {

		const { element, documentPart } = this.props;

		try {
			let recipeParamId = element.recipeParamName.replace(/---PART_TYPE---/gi, this.toCappedPartType(documentPart));
			recipeParamId = recipeParamId.replace(/---PART_TYPE_LOWER---/gi, documentPart.partType.toLowerCase());

			//console.log(recipeParamId);
			if (element.configType == 'LOOKUP') {

				if (element.configDetails == 'HEADER_BY_PART_TYPE') {

					let partMapping = this.partTypeParamMappings[documentPart.partType];
					if (partMapping && partMapping.hasHeader) {
						recipeParamId = partMapping.headerTemplateParam;
					}


				}
			}




			let bm = new BuildManager();
			let bookMerlinContext = this.props.stores?.bookStore.currentBookMerlinContext;

			//let currentRecipe = this.props.stores?.bookStore.activeRecipe;
			let currentRecipe = this.props.documentPart?.getRecipe();
			return bm.getParam({
				paramId: recipeParamId,
				paramSource: 'RECIPE',
				context: bookMerlinContext,
				recipe: currentRecipe.json,
				recipeObject: currentRecipe
			})

		} catch (err) {
			//console.log(err);
		}



	}

	getRecipeParamByName(recipeParamName) {

		try {
			const { element, documentPart } = this.props;

			let recipeParamId = recipeParamName.replace(/---PART_TYPE---/gi, this.toCappedPartType(documentPart));
			recipeParamId = recipeParamId.replace(/---PART_TYPE_LOWER---/gi, documentPart.partType.toLowerCase());


			if (element && element.configType == 'LOOKUP') {

				if (element.configDetails == 'HEADER_BY_PART_TYPE') {

					let partMapping = this.partTypeParamMappings[documentPart.partType];
					if (partMapping && partMapping.hasHeader) {
						recipeParamId = partMapping.headerTemplateParam;
					}


				}
			}




			let bm = new BuildManager();
			let bookMerlinContext = this.props.stores?.bookStore.currentBookMerlinContext;

			//let currentRecipe = this.props.stores?.bookStore.activeRecipe;
			let currentRecipe = this.props.documentPart?.getRecipe();
			let param = bm.getParam({
				paramId: recipeParamId,
				paramSource: 'RECIPE',
				context: bookMerlinContext,
				recipe: currentRecipe.json,
				recipeObject: currentRecipe
			})

			return param;
		} catch (err) {
			console.log(err);
		}


	}


	getEntityItem() {

		const { element, documentPart, bookDraft } = this.props;

		let item = documentPart;
		let properties_book = bookDraft?.book;
		let boxSet = bookDraft?.book?.boxSet;

		let boxSetBook = documentPart?.bookDraftDocumentPart?.getBoxSetBook();

		if (element.paramItem == 'BOOK') {
			item = bookDraft.book;

			let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();

			if (boxSetBook) {

				item = boxSetBook.book;

			}


		}

		try {
			if (element.paramItem == 'BOXSET_MAIN_BOOK') {
				item = boxSet.book;
			}
			if (element.paramItem == 'BOXSET_BOOK') {
				item = boxSetBook.book;
			}
			if (element.paramItem == 'BOXSETBOOK') {
				item = boxSetBook;
			}
			if (element.paramItem == 'DOCUMENTPART') {
				item = documentPart;
			}
		} catch (err) {
			console.log(err);
		}


		return item;

	}

	resetRecipeProp({
		recipeParamPath,
		newValue
	}) {

		const { element, documentPart } = this.props;

		//let recipeParamPath = element.recipeParamPath

         

		//console.log(newValue);
		//let currentRecipe = this.props.stores?.bookStore.activeRecipe;
		let currentRecipe = this.props.documentPart?.getRecipe();

		(async () => {
			//console.log('updating recipe with new value:' + newValue);
			

			new RecipeUtils().setRecipeParamByPartType(null, currentRecipe.json, this.props.documentPart, recipeParamPath, newValue);
			let paramUpdateData = new RecipeUtils().getParamUpdateData(currentRecipe.json, this.props.documentPart, recipeParamPath);

			
				this.props.stores.bookStore.recipeTimestamp = new Date().getTime();

				if (currentRecipe && this.props.onUpdateRecipe) {

					this.props.onUpdateRecipe({
						recipe: currentRecipe,
						paramPath: recipeParamPath,
						paramValue: newValue,
						...paramUpdateData
					})


				}


			
		})();








	}

	updateRecipeScope(documentPart, scope) {

		//const { element, documentPart } = this.props;

		let partTypeShortHand = new RecipeUtils().getPartTypeShortHand(documentPart);

		let currentRecipe = documentPart?.getRecipe();

		(async () => {
			//console.log('updating recipe scope:' + scope);
			

			//let paramUpdateData = new RecipeUtils().getParamUpdateData(currentRecipe.json, this.props.documentPart, recipeParamPath);

			
				this.props.stores.bookStore.recipeTimestamp = new Date().getTime();

				if (currentRecipe && this.props.onUpdateRecipe) {

					this.props.onUpdateRecipe({
						recipe: currentRecipe,
						paramAction: "SET_SCOPE",
						scope:scope, 
						partTypeShortHand:partTypeShortHand
						// paramPath: recipeParamPath,
						// paramValue: newValue,
						// ...paramUpdateData
					})


				}


			
		})();








	}

	updateRecipeProp(newValue) {

		const { element, documentPart } = this.props;

		let recipeParamPath = element.recipeParamPath

         

		//console.log(newValue);
		//let currentRecipe = this.props.stores?.bookStore.activeRecipe;
		let currentRecipe = this.props.documentPart?.getRecipe();

		(async () => {
			//console.log('updating recipe with new value:' + newValue);
			

			new RecipeUtils().setRecipeParamByPartType(null, currentRecipe.json, this.props.documentPart, recipeParamPath, newValue);
			let paramUpdateData = new RecipeUtils().getParamUpdateData(currentRecipe.json, this.props.documentPart, recipeParamPath);

			
				this.props.stores.bookStore.recipeTimestamp = new Date().getTime();

				if (currentRecipe && this.props.onUpdateRecipe) {

					this.props.onUpdateRecipe({
						recipe: currentRecipe,
						paramPath: recipeParamPath,
						paramValue: newValue,
						...paramUpdateData
					})


				}


			
		})();








	}

	updateRecipe(newValue) {

		const { element, documentPart } = this.props;

		let recipeParamId = element.recipeParamName.replace(/---PART_TYPE---/gi, this.toCappedPartType(documentPart));
		recipeParamId = recipeParamId.replace(/---PART_TYPE_LOWER---/gi, documentPart.partType.toLowerCase());




		//console.log(newValue);
		//let currentRecipe = this.props.stores?.bookStore.activeRecipe;
		let currentRecipe = this.props.documentPart?.getRecipe();

		(async () => {
			//console.log('updating recipe with new value:' + newValue);
			//console.log(this.props.stores?.bookStore.activeRecipe);

			//let recipeParamName = element.recipeParamName.replace(/---PART_TYPE---/gi, this.toCappedPartType(documentPart));
			let recipeParam = this.getRecipeParam();

			//console.log(recipeParam);

			if (recipeParam) {
				recipeParam.value = newValue;
				this.props.stores.bookStore.recipeTimestamp = new Date().getTime();

				if (currentRecipe) {

					if (this.props.onUpdateRecipe) {
						this.props.onUpdateRecipe({
							recipe: currentRecipe,
							paramName: recipeParamId,
							paramValue: newValue
						})
					}


				}


			}
		})();








	}

	//Fix this later. Bad design.
	updateEntity(newValue, overrideElement) {
		const { element, documentPart } = this.props;

		//console.log('updating entity with new value:' + newValue);

		try {

			let newSaveTime = Date.now() + 1000;

			let item = this.getEntityItem();
			item.updatedTimestamp = newSaveTime;

			//console.log(item);

			//let toBeStoredValue = newValue?element.checkedValue:element.unCheckedValue;
			if(overrideElement){
				item[overrideElement.entityFieldName] = newValue
			}else{
				item[element.entityFieldName] = newValue
			}
			




			this.props.stores.bookStore.recipeTimestamp = new Date().getTime();

			if (this.props.onUpdateEntity) {

				if(overrideElement){
					this.props.onUpdateEntity({
						item,
						fieldName: overrideElement.entityFieldName
					})
				}else{
					this.props.onUpdateEntity({
						item,
						fieldName: element.entityFieldName
					})
				}


				
			}


			// entityManager.updateItem({
			// 	itemKey:item.id,  //currently, everything has the same pk name: "id"
			// 	itemType:item.modelType, 
			// 	fieldName:element.entityFieldName, 
			// 	fieldValue:toBeStoredValue}, ()=>{

			//     //console.log('item updated...');
			// });


		} catch (err) {
			//console.log(err);
		}

	}

	// updateEntity(newValue) {
	// 	let { element, documentPart } = this.props;

	// 	console.log('updating entity with new value:' + newValue);

	// 	try {

	// 		let newSaveTime = Date.now() + 1000;

	// 		let item = this.getEntityItem();
	// 		item.updatedTimestamp = newSaveTime;

	// 		//console.log(item);

	// 		//let toBeStoredValue = newValue?element.checkedValue:element.unCheckedValue;
	// 		item[element.entityFieldName] = newValue




	// 		this.props.stores.bookStore.recipeTimestamp = new Date().getTime();

	// 		if (this.props.onUpdateEntity) {
	// 			this.props.onUpdateEntity({
	// 				item,
	// 				fieldName: element.entityFieldName
	// 			})
	// 		}

			

	// 	} catch (err) {
	// 		console.log(err);
	// 	}

	// }


	toCappedPartType(documentPart) {
		let cappedPartType = '';

		//let documentPart = documentPart;

		if (documentPart) {

			let partType = documentPart.partType
			if (partType && partType.length > 0) {

				cappedPartType = partType.charAt(0).toUpperCase() + partType.slice(1).toLowerCase();

			}
		}

		return cappedPartType;

	}

	convertTitle(title, documentPart) {

		try {
			let newTitle = title.replace(/---PART_TYPE---/gi, this.toCappedPartType(documentPart))
			newTitle = newTitle.replace(/---PART_TYPE_LOWER---/gi, documentPart.partType.toLowerCase());

			return newTitle;
		} catch (err) {

		}

	}

	getHasHeading(documentPart) {


		let hasHeading = true;
		if (!documentPart ||
			documentPart?.partType.toLowerCase() == 'topbook' ||
			//documentPart?.partType.toLowerCase() == 'book' ||
			//documentPart?.partType.toLowerCase() == 'copyright' ||
			//documentPart?.partType.toLowerCase() == 'toc' ||
			documentPart?.partType.toLowerCase() == 'fullpageimage') {
			hasHeading = false;
		}

		return hasHeading;
	}

	getIsInsertedBookDocumentPart(documentPart) {

		try{
			return documentPart.bookDraftDocumentPart.insertedBook || documentPart.partType == 'BOOK';
		}catch(err){

		}
		
	}
	
	getCanEdit() {

		const { element, documentPart } = this.props;

		let canEdit = false;
		let userId = this.props.stores?.bookStore.user.id;

		if (element.paramType == 'RECIPE') {

			//let currentRecipe = this.props.stores?.bookStore.activeRecipe;
			let currentRecipe = this.props.documentPart?.getRecipe();
			if (element.canEdit && element.canEdit == 'ITEM_OWNER') {

				if (currentRecipe?.ownerId == userId) {
					canEdit = true;
				}
			}


		}
		if (element.paramType == 'ENTITY') {

			let item = this.getEntityItem();



			if (element.canEdit && element.canEdit == 'ITEM_OWNER') {

				if (item.owner && item.owner.id == userId) {
					canEdit = true;
				}
			}

		}

		return canEdit;

	}


	getValue() {

		//console.log('getValue()...')
		const { classes, element, bookDraft, documentPart } = this.props;

		if(element.subType && element.subType==='TITLE_IMAGES'){

		}

		if (element.paramType == 'RECIPE') {

			try {


				let recipeParam = this.getRecipeParam();

				return recipeParam.value;

			} catch (err) {
				//console.log(err);
			}
		}

		if (element.paramType == 'ENTITY') {

			//console.log('saving to an entity')
			try {


				let item = this.getEntityItem();
				//console.log(item);
				let entityValue = item[element.entityFieldName];



				return entityValue;

			} catch (err) {
				//console.log(err);
			}

		}


	}

	updateValue({
		value,
		overrideElement
	}) {

		const { classes, element, bookDraft, documentPart } = this.props;

		let that = this;

		//console.log(value);




		if (element.paramType == 'ENTITY') {

			let newSaveTime = Date.now() + 1000;
			let item = this.getEntityItem();
			item.updatedTimestamp = newSaveTime;

			if(overrideElement){
				item[overrideElement.entityFieldName] = value;
				this.updateEntity(value,overrideElement);
			}else{
				item[element.entityFieldName] = value;
				this.updateEntity(value);
			}

			

			

			// setTimeout(() => {
			// 	this.updateEntity(value);
			// }, 10)

		}
		if (element.paramType == 'RECIPE') {

			let recipeParam = this.getRecipeParam();

			if (recipeParam) {
				recipeParam.value = value;
				setTimeout(() => {
					this.updateRecipe(value);
				}, 10)
			}


		}







	}

	getIsChecked() {

		const { classes, element, bookDraft, documentPart } = this.props;

		//console.log('checkbox...');
		if (element.paramType == 'RECIPE') {

			try {


				let recipeParam = this.getRecipeParam();
				let checkedValue = recipeParam.value == element.checkedValue ? true : false;

				try {
					if (recipeParam.value.toLowerCase() == 'true') {
						checkedValue = true;
					}
				} catch (err) {
					//do nothing
				}

				if (element.invertValue) {
					return !checkedValue;
				}

				return checkedValue;

			} catch (err) {
				//console.log(err);
			}
		}
		if (element.paramType == 'ENTITY') {

			////console.log('saving to an entity')
			try {


				let item = this.getEntityItem();
				////console.log(item);
				let entityValue = item[element.entityFieldName];



				let checkedValue = entityValue == element.checkedValue ? true : false;
				if (element.invertValue) {
					return !checkedValue;
				}

				return checkedValue;

			} catch (err) {
				//console.log(err);
			}

		}


	}



}


export default BaseControl;