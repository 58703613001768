export const PART_TYPES = {
    "VOLUME": {
        abbreviation: "VOL",
        allowedParentTypes: [
            "TOPBOOK"
        ]
    },
    "PART": {
        abbreviation: "PRT",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME"
        ]
    },
    "CHAPTER": {
        abbreviation: "CHP",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "SCENE": {
        abbreviation: "SCN",
        allowedParentTypes: [
            "CHAPTER"
        ]
    },
    "COPYRIGHT": {
        abbreviation: "CPY",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "DEDICATION": {
        abbreviation: "DED",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "BLURBS": {
        abbreviation: "BLU",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "TITLEPAGE": {
        abbreviation: "TPA",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "HALFTITLE": {
        abbreviation: "HFT",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "INTRODUCTION": {
        abbreviation: "INT",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "ACKNOWLEDGEMENTS": {
        abbreviation: "ACK",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "FOREWORD": {
        abbreviation: "FOR",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "PREFACE": {
        abbreviation: "PRE",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "PROLOGUE": {
        abbreviation: "PRO",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "TOC": {
        abbreviation: "TOC",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "AFTERWORD": {
        abbreviation: "AFT",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "EPIGRAPH": {
        abbreviation: "EPG",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "EPILOGUE": {
        abbreviation: "EPL",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "ABOUTAUTHOR": {
        abbreviation: "ABA",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "ALSOBY": {
        abbreviation: "ABY",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "FULLPAGEIMAGE": {
        abbreviation: "FPI",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "NOTES": {
        abbreviation: "NTS",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
    "UNTYPED": {
        abbreviation: "UTY",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    }
};