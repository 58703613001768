import React, { useState, useEffect } from "react";
import { Box, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StandardUIDialog from "../../UI/StandardUIDialog";
import StandardUIButton from "../../UI/StandardUIButton";
import { useManagerAddBoxsetContributor, useManagerLoadBoxSetContributors } from '../../../managers/BoxsetsManager';
import ListConnections from "../lists/ListConnections";
import StandardUITabs from "../../UI/StandardUITabs";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../../managers/BooksManager';
import CircularProgress from '@mui/material/CircularProgress';
import InviteConnection from "./InviteConnection";
import { useManagerCreateInvitation, useGetUserConnections, useGetUserGroups } from '../../../managers/ConnectionsManager';


const AddContributor = (props) => {


    let user = props.stores.userStore.user;
    let { book, boxset, selectedUserConnection } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { sharedWithUsers, bookUserSharesLoading, bookUserSharesError, sharedWithUserGroups, bookUserGroupSharesLoading, bookUserGroupSharesError } = useGetBookShares(props, book);
    const { createInvitation, data: createInvitationData, loading: createInvitationLoading, error: createInvitationError } = useManagerCreateInvitation();

    let [selectedTab, setSelectedTab] = useState(0);


    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    const { addBoxsetContributor, loading: createBoxSetContributorLoading, error: createBoxSetContributorError } = useManagerAddBoxsetContributor(props);

    let {
        boxsetContributors,
        loading: lazyLoadBoxSetContributorsLoading,
        error: lazyLoadBoxSetContributorsError
    } = useManagerLoadBoxSetContributors(props, boxset?.id);



    return (
        <StandardUIDialog
            open={props.open}
            onClose={props.onClose}
            book={props.book}
            title='Add Contributor'
            dialogContent={
                    <Box>
                        <StandardUITabs value={selectedTab} onChange={handleChange}>
                            <Tab label="Connections" {...a11yProps(0)} />
                            <Tab label="Invite" {...a11yProps(1)} />
                        </StandardUITabs>
                        {selectedTab == 0 &&
                            <Grid>
                                <ListConnections
                                    selectedUserConnection={selectedUserConnection}
                                    filteredConnections={sharedWithUsers.map(m => m.sharedWithUser)}
                                    showShareIcon
                                    onClick={props.onClick}
                                    onSelectUser={(userConnection) => {
                                        if (userConnection) {
                                            addBoxsetContributor({
                                                contributorId: userConnection.connectedUser.id,
                                                boxsetId: boxset.id,
                                            });
                                        }
                                    }}

                                />
                                <StandardUIButton 
                                    buttonAction='Add Self'
                                    onClick={() => {
                                        addBoxsetContributor({
                                          contributorId: user.id,
                                          boxsetId: boxset.id,
                                        })
                                      }}
                                />
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {createBoxSetContributorLoading && <CircularProgress />}
                                </div>
                            </Grid>
                        }
                        {selectedTab == 1 &&
                        <div>
                            <InviteConnection />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {createInvitationLoading && <CircularProgress />}
                            </div>
                        </div>
                        }
                    </Box>
            }
        />
    )
}

export default inject('stores')(observer(AddContributor));