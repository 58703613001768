import Quill from 'quill';
//import Popper from 'popper.js';
let Inline = Quill.import('blots/inline');
var $ = require("jquery");

class CharacterBlot extends Inline {
  static create(args) {
    //console.log(args);
    let node = super.create(args);
    // node.setAttribute('href', args.href);
    // node.dataset.href = args.href;

    // node.setAttribute('linkType', args.linkType);
    // node.dataset.linkType = args.linkType;
    
    // let propsDiv = $("#characterBlotPopper");

    // let linkType = args.linkType;
    let thisPopper = null;





    $(node).click(function () {
      //console.log('CharacterBlot...');

      let text = $(node).text();
      //console.log(text);
      $('#characterPopperTriggerDiv').data('blotNode', node);
      $('#characterPopperTriggerDiv').click();
      //console.log(this);
      return node;

    });








    $('#characterBlotPopper').mouseleave(function () {
      $('#characterBlotPopper').hide();
      if (thisPopper) {
        thisPopper.destroy();
      }

    });




    //node.setAttribute('target', '_blank');
    return node;
  }

  setHref(href) {
    this.domNode.dataset.href = href;

  }

  setLinkType(linkType) {
    this.domNode.dataset.linkType = linkType;

  }




  static formats(domNode) {
    //return domNode.dataset.src
    //return domNode.getAttribute('href');

    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
      href: domNode.dataset.href,
      linkType: domNode.dataset.linkType
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  static value(domNode) {
    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
      href: domNode.dataset.href,
      linkType: domNode.dataset.linkType
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  /*static sanitize(url) {  
    return sanitize(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
  }*/

  format(name, args) {
    if (name !== this.statics.blotName || !args) return super.format(name, args);
    
    // this.domNode.setAttribute('href', args.href);
    // this.domNode.setAttribute('linkType', args.linkType);
  }
}
CharacterBlot.blotName = 'characterBlot';
CharacterBlot.tagName = 'span';



export { CharacterBlot as default };
