import { styled } from "@mui/material";
import { inject, observer } from 'mobx-react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


export const StandardBaseRadioButtonsGroup = styled(RadioGroup)(()=>({

    // background: 'transparent',
    // boxShadow: 'none'
    marginBottom:'1em',
    
}));


function StandardRadioButtons(props) {
   
    let { title, options} = props;
  
    return (
     
        <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">{title}</FormLabel>
        <StandardBaseRadioButtonsGroup
          row
          onChange={props.onChange}
          value={props.value}
        >
            {options.map((option)=>{
                return <FormControlLabel value={option.value} control={<Radio color={"secondary"}/>} label={option.label} />
            })}
          
          {/* <FormControlLabel
            value="disabled"
            disabled
            control={<Radio />}
            label="other"
          /> */}
        </StandardBaseRadioButtonsGroup>
      </FormControl>
       
    );
  }

  export default inject('stores')(observer(StandardRadioButtons));

