import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LaurasDetailList from './LaurasDetailList';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Popover from '@mui/material/Popover';

const LaurasBooksSnapshot = (props) => {

     const theme = useTheme();
     const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

     const [anchorEl, setAnchorEl] = useState(null);

     const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
     };

     const handleClose = () => {
          setAnchorEl(null);
     };

     const open = Boolean(anchorEl);

     return (
          <div>
               <LaurasDetailList
                    avatarType={<AssignmentIcon />}
                    primary='Snapshot title'
                    secondary='Description'
                    showWriteIcon={false}
                    showFormatIcon={false}
                    showRestoreButton={true}
                    onRestoreClick={handleClick}
               />
               <LaurasDetailList
                    avatarType={<AssignmentIcon />}
                    primary='Snapshot title 2'
                    secondary='Description'
                    showWriteIcon={false}
                    showFormatIcon={false}
                    showRestoreButton={true}
               />
               <LaurasDetailList
                    avatarType={<AssignmentIcon />}
                    primary='Snapshot title 3'
                    secondary='Description'
                    showWriteIcon={false}
                    showFormatIcon={false}
                    showRestoreButton={true}
               />
               <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                    }}
               >
                    <Typography sx={{ p: 2 }}>Open</Typography>
                    <Typography sx={{ p: 2 }}>Restore</Typography>
               </Popover>
          </div>
     )
}
export default inject('stores')(observer(LaurasBooksSnapshot));