import React, { useEffect, useState } from 'react';
import { styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import BaseControl from "./BaseControl";
import { useTheme } from '@mui/material/styles';
import { Box, } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormatImageSelector from './FormatImageSelector';

function FormatImageSourceSelector(props) {

  const theme = useTheme();
  let baseControl = new BaseControl(props);

  const { classes, element, bookDraft, documentPart } = props;

  let isVisible = baseControl.isVisible();
  if (!isVisible) {
    return <div style={{
      height: '0em'
    }}></div>
  }


  let canEdit = true;


  let isOwner = true;

  let title = baseControl.convertTitle(element.title, documentPart);

  const label = { inputProps: { 'aria-label': title } };

  let value = baseControl.getValue();

  let item = baseControl.getEntityItem();

  let options = element.imageSelectors.map((imageSelector) => {

    return {
      value: imageSelector.key,
      label: imageSelector.label,
      selectorConfig: imageSelector.selectorConfig
    }

  })

  return (

    <Box sx={{
      displayx: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      //border:'1px solid blue'
    }}>

      {/* <div><Typography>FormatImageSourceSelector: {title}</Typography></div> */}


      <FormControl sx={{
            width: '100%',
            //border:'3px solid green'
            
          }}> 
        <FormLabel >{title}</FormLabel>
        <RadioGroup
          row
          onChange={(e) => {
            let newValue = e.target.value;
            baseControl.updateValue({
              value: newValue
            });
          }}

          value={value}
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            //border:'3px solid yellow'
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            //border:'2px solid black',
            alignItems: 'center'
          }}>
            {options.map((option) => {
              return <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                //border:'3px solid purple',
                alignItems: 'center'
              }}>
                <FormControlLabel value={option.value} control={<Radio color={'secondary'}/>} label={baseControl.convertTitle( option.label, documentPart) } />
                <FormatImageSelector
                            bookDraft={bookDraft}
                            documentPart={documentPart}
                            element={option.selectorConfig}
                            canEdit={canEdit}
                            onUpdateRecipe={props.onUpdateRecipe}
                            onUpdateEntity={props.onUpdateEntity} />
              </Box>


            })}
          </Box>


          {/* <FormControlLabel
            value="disabled"
            disabled
            control={<Radio />}
            label="other"
          /> */}
        </RadioGroup>
      </FormControl>




      {/* <div><Typography>bucketName: {bucketName}</Typography></div>
      <div><Typography>s3Path: {s3Path}</Typography></div> */}
      {/* <StandardImageViewer
        bucketName={bucketName}
        s3Path={s3Path}
        height={'15em'}
        fit={'contain'}
        sx={{
          minHeight: '15em'
          //display: { sm: 'block', lg: 'block', md: 'block' } 
        }}
        style={{ borderRadius: 2 }}
        className="custom-class"
        onClick={(e) => {
          e.preventDefault();
          setShowGallery(true);

        }}
        showLoading
        errorIcon
      />

      <StandardDialog
        title={"Select a font"}
        open={showGallery}
        onClose={() => { setShowGallery(false) }}






        dialogContent={<S3FilesGallery
          bucketName={element.bucketName}
          folder={element.folder}
          onItemSelected={({s3Key}) => {

            console.log(s3Key);
            processSelection(s3Key);

          }}
        />}>

      </StandardDialog> */}


    </Box>


  );
}


export default inject('stores')(observer(FormatImageSourceSelector));



