import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import SnapshotsTable from '../../../components/table/SnapshotsTable';
import { useMediaQuery } from 'react-responsive';
import BookSnapshotsList2 from '../../../components/lists/BookSnapshotsList2';
import StandardDashboardPaper from '../../../components/StandardPaper';
import AppWrapper from '../../../components/AppWrapper';
import StandardContentWithFooterContainer from '../../../components/StandardContentWithFooterContainer';
import OwnedBooksMobileFooterBar from '../../../components/footers/OwnedBooksMobileFooterBar';

const Snapshots = (props) => {

  const isXs = useMediaQuery({ maxWidth: 599 });
  const isSm = useMediaQuery({ maxWidth: 600 });
  const isMd = useMediaQuery({ maxWidth: 900 });
  const isLg = useMediaQuery({ maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1536 });

  const theme = useTheme();

  return (

    <AppWrapper main={<Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      gap: 2
    }}>

      <Box sx={{
        flex: 12
      }}>


        <StandardContentWithFooterContainer
          smallFooterContent={<OwnedBooksMobileFooterBar />}>
          <>

            <StandardDashboardPaper sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '5em',
              width: '100%',
              //position: 'relative',
              //height: '8em',
              borderRadius: '8px',
              //marginTop: '1.75em',
              paddingBottom: '0.5em',
            }}>

              This is the detail area
            </StandardDashboardPaper>

            <BookSnapshotsList2 book={props.stores.bookStore.bookListBook} />
            {/* <StandardDashboardPaper sx={{
            position: 'relative',
            //height: '15em',
            borderRadius: '8px',
            //marginTop: '2em',
            padding: '.5em 1em 1em 1em'
          }}>
            <SnapshotsTable book={props.stores.bookStore.bookListBook} />
          </StandardDashboardPaper> */}

          </>
        </StandardContentWithFooterContainer>

      </Box>

    </Box>

    } />


  )
}

export default inject('stores')(observer(Snapshots));