import React, { useState, useEffect } from "react";
import { Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import { useManagerRestoreSnapshot } from '../../managers/BooksManager';
import StandardTextfield from "../StandardTextfield";

const RestoreSnapshotPanel = (props) => {

  let user = props.stores.userStore.user;
  let { book, snapshot } = props;

  let [title, setTitle] = useState();
  let [description, setDescription] = useState();

  const { restoreSnapshot, restoreSnapshotLoading, restoreSnapshotError } = useManagerRestoreSnapshot(props,book);


  return (
    <Grid container direction={'column'}>
       <Grid item>
        <Typography variant={'h6'}>
          This snapshot will be restored to the selected book. The existing content will first be copied to a new snapshot. 
          Please provide a title and descriptipn for the to-be-created snapshot below.
          (Probably do not want to ask for a title and description. Lambda function will autoprovide 
          a title and description and we may want to link the snapshot to it's source bookDraft. Then, in this panels
          provide the 'dynamic' name of the source bookDraft.)
        </Typography>
      </Grid>
      <Grid item>
        <StandardTextfield label={'Title'} onChange={(e) => {
          setTitle(e.target.value);
        }} />
      </Grid>
      <Grid item>
        <StandardTextfield label={'Description'} onChange={(e) => {
          setDescription(e.target.value);
        }} />
      </Grid>
      <Grid item>
        <Typography variant={'h6'}>{restoreSnapshotLoading && <div>creating snapshot...</div>}</Typography>
      </Grid>

      <Divider />
      <Grid container direction={'row'} columnSpacing={2} sx={{
        mt: 3,
        justifyContent: 'flex-end'
      }}>
        <Grid item>
          <StandardButton autoFocus onClick={props?.onClose}>
            Cancel
          </StandardButton>
        </Grid>
        <Grid item>
          <StandardButton autoFocus disabled={restoreSnapshotLoading ? true : false} onClick={(e) => {
            e.preventDefault();
            restoreSnapshot({
              snapshotBookDraftId: snapshot.id
            }, () => {
              if(props.onRestored){

                //props.onRestored();
                
              }
             
            })
          }}>
            Ok
          </StandardButton>
        </Grid>
      </Grid>





    </Grid>

  )
}

export default inject('stores')(observer(RestoreSnapshotPanel));