import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormatPropertiesCard from './FormatPropertiesCard';
import { Grid } from '@mui/material';
import BookIcon from '@mui/icons-material/Book';
import SubHeading from './propertyElements/SubHeading';
import FormatRadioButtons from './propertyElements/FormatRadioButtons';
import FormatCheckbox from './propertyElements/FormatCheckbox';
import TemplateSelector from './propertyElements/TemplateSelector';
import FormatTextfield from './propertyElements/FormatTextfield';
import FormatImageSelector from './propertyElements/FormatImageSelector';
import FormatImageSourceSelector from './propertyElements/FormatImageSourceSelector';
import ParagraphAfterFirst from './propertyElements/FormatParagraphAfterFirst';
import FormatImageUploadSelector from './propertyElements/FormatImageUploadSelector';
import EmptyContent from '../EmptyContent';
import FormatHeadingSourceToggleButtons from './propertyElements/FormatHeadingSourceToggleButtons';
import ToggleButtons from './propertyElements/props/ToggleButtons';
import BaseControl from './propertyElements/BaseControl';
import InfoHeading from './propertyElements/InfoHeading';
import FormatBackgroundImageSourceToggleButtons from './propertyElements/FormatBackgroundImageSourceToggleButtons';
import FormatTemplateSourceSelector from './propertyElements/FormatTemplateSourceSelector';
import PropsTemplateSelector from './propertyElements/props/PropsTemplateSelector';
import PropsColorPicker from './propertyElements/props/PropsColorPicker';
import PropsCheckbox from './propertyElements/props/PropsCheckbox';
import PropsRadioButtons from './propertyElements/props/PropsRadioButtons';
import PropsImageSelector from './propertyElements/props/PropsImageSelector';
import PropsFontSelector from './propertyElements/props/PropsFontSelector';
import PropsHeadingDnD from './propertyElements/props/PropsHeadingDnD';

const layout2 = require('./FormattingPropsJSON2').default;
const layout3 = require('./FormattingPropsJSON3').default;

export default class PropertiesFactory {

    constructor() {

    }

    createControl = ({
        element,
        bookDraft,
        documentPart,
        onUpdateRecipe,
        onUpdateEntity,
        canEdit,
        boxSetBook
    }) => {
        switch (element.type) {


            case 'INFOHEADING':
                return <div style={{
                    width: '100%',
                    borderx: '1px solid red',
                    display: 'flex'
                }}>
                    <InfoHeading
                        bookDraft={bookDraft}
                        documentPart={documentPart}
                        element={element} />

                </div>

                break;

            case 'SUBHEADING':
                // return <div style={{
                //     width: '100%',
                //     borderx: '1px solid red',
                //     display: 'flex'
                // }}>
                return <Box sx={{
                    marginTop: '2em'
                }}>
                    <SubHeading
                        bookDraft={bookDraft}
                        documentPart={documentPart}
                        element={element}
                        boxSetBook={boxSetBook} />
                </Box>





                // </div>

                break;

            case 'PROPS_COLOR_PICKER':
                return <PropsColorPicker
                    bookDraft={bookDraft}
                    documentPart={documentPart}
                    element={element}
                    canEdit={canEdit}
                    onUpdateRecipe={onUpdateRecipe} />
                break;
            case 'PROPS_HEADING_PLACEMENT':
                return <PropsHeadingDnD
                    bookDraft={bookDraft}
                    documentPart={documentPart}
                    element={element}
                    canEdit={canEdit}
                    onUpdateRecipe={onUpdateRecipe} />
                break;
            case 'PROPS_TOGGLE_BUTTONS':
                return <ToggleButtons
                    bookDraft={bookDraft}
                    documentPart={documentPart}
                    element={element}
                    canEdit={canEdit}
                    onUpdateRecipe={onUpdateRecipe} />
                break;

            case 'PROPS_RADIO_BUTTONS':
                return <>
                    {/* {showFormatParamInfo &&
                        paramInfo
                    } */}
                    <PropsRadioButtons
                        bookDraft={bookDraft}
                        documentPart={documentPart}
                        element={element}
                        canEdit={canEdit}
                        onUpdateRecipe={onUpdateRecipe}
                        onUpdateEntity={onUpdateEntity}
                    />
                </>
                break;

            case 'PROPS_TEMPLATE_SELECTOR':

                {/* {showFormatParamInfo &&
                        paramInfo
                    } */}

                return <PropsTemplateSelector
                    key={element.recipeParamName}
                    bookDraft={bookDraft}
                    documentPart={documentPart}
                    element={element}
                    canEdit={canEdit}
                    onUpdateRecipe={onUpdateRecipe} />

                break;

            case 'PROPS_CHECKBOX':
                return <>
                    {/* {showFormatParamInfo &&
                            paramInfo
                        } */}
                    <PropsCheckbox
                        bookDraft={bookDraft}
                        documentPart={documentPart}
                        element={element}
                        canEdit={canEdit}
                        onUpdateRecipe={onUpdateRecipe}
                        onUpdateEntity={onUpdateEntity} />
                </>
                break;


            case 'PROPS_FONT_SELECTOR':
                return <PropsFontSelector
                    bookDraft={bookDraft}
                    documentPart={documentPart}
                    element={element}
                    canEdit={canEdit}
                    onUpdateRecipe={onUpdateRecipe}
                    onUpdateEntity={onUpdateEntity} />

                break;
            case 'PROPS_IMAGE_POPUP_SELECTOR':
                return <PropsImageSelector
                    bookDraft={bookDraft}
                    documentPart={documentPart}
                    element={element}
                    canEdit={canEdit}
                    onUpdateRecipe={onUpdateRecipe}
                    onUpdateEntity={onUpdateEntity} />

                break;

            case 'SUBHEADING':
                // return <div style={{
                //     width: '100%',
                //     borderx: '1px solid red',
                //     display: 'flex'
                // }}>
                return <Box sx={{
                    marginTop: '2em'
                }}>
                    <SubHeading
                        bookDraft={bookDraft}
                        documentPart={documentPart}
                        element={element}
                        boxSetBook={boxSetBook} />
                </Box>





                // </div>

                break;


        }
    }
    isSectionVisible({ sectionLayout, documentPart }) {


        //console.log('isVisible...')
        let isVisible = false;
        try {

            let conditions = sectionLayout.sectionVisibilityConditions;

            if (!conditions) {
                isVisible = true;
            }

            /*
visibilityConditions: [{
                            visible: true,
                            conditionType: 'IS_INSERTED_BOOK_CONTENT',
                            notVisibleHelperMessage:'This is for inserted books content'
                        }]
            */
            if (conditions) {

                conditions.forEach((condition) => {

                    switch (condition.conditionType) {

                        case 'RECIPE_PARAM_VALUE':
                            let recipeParam = this.getRecipeParamByName(condition.recipeParamName);
                            try {
                                switch (condition.operator) {
                                    case 'eq':
                                        if (recipeParam.value == condition.recipeParamValue) {
                                            isVisible = true;
                                        }
                                        break;

                                    case 'ne':
                                        if (recipeParam.value != condition.recipeParamValue) {
                                            isVisible = true;
                                        }
                                        break;
                                }



                            } catch (err) {

                            }

                            break;
                        case 'PART_TYPE':

                            try {
                                switch (condition.operator) {
                                    case 'eq':
                                        if (documentPart.partType == condition.value) {
                                            isVisible = true;
                                        }
                                        break;

                                    case 'ne':
                                        if (documentPart.partType == condition.value) {
                                            isVisible = true;
                                        }
                                        break;
                                }



                            } catch (err) {

                            }

                            break;
                        case 'IS_INSERTED_BOOK_CONTENT':

                            try {

                                //notVisibleHelperMessage:'This is for inserted books content'
                                isVisible = documentPart.isInsertedBookContent();
                                //isVisible = true;

                            } catch (err) {

                            }

                            break;
                        case 'HAS_HEADING':

                            try {

                                //notVisibleHelperMessage:'This is for inserted books content'
                                isVisible = new BaseControl().getHasHeading(documentPart);
                                //isVisible = true;

                            } catch (err) {

                            }

                            break;
                        case 'PART_TYPES':

                            let isAllowedPartType = false;
                            try {

                                condition.partTypes.forEach((partType => {
                                    if (documentPart.partType == partType) {
                                        isAllowedPartType = true;
                                    }
                                }))

                                isVisible = isAllowedPartType;

                            } catch (err) {

                            }

                            break;
                        case 'NOT_PART_TYPES':

                            let isNotAllowedPartType = false;
                            try {

                                condition.partTypes.forEach((partType => {
                                    if (documentPart.partType == partType) {
                                        isNotAllowedPartType = true;
                                    }
                                }))

                                isVisible = !isNotAllowedPartType;

                            } catch (err) {

                            }

                            break;





                    }
                })
            }

        } catch (err) {
            //console.log(err);
        }

        return isVisible;

    }

    build({
        sectionType,
        documentPart,
        bookDraft,
        user,
        onUpdateRecipe,
        onUpdateEntity,
        stores,
        theme
    }) {


        let properties_book = bookDraft.book;
        let boxSet = bookDraft.book.boxSet;
        let showFormatParamInfo = false;
        let boxSetBook = documentPart.getBoxSetBook();
        let hideBookProperties = false;
        let canEdit = true;
        let isOwner = true;
        let recipe = documentPart?.getRecipe();

        if (boxSetBook) {

            properties_book = boxSetBook.book;

        }

        let layoutConfig = layout2[sectionType];
        if (stores.bookStore.layout == 'layout3') {
            layoutConfig = layout3[sectionType];
        }


        // new BaseControl().getHasHeading(documentPart);
        // layoutConfig.visibilityConditions
        let propertyCards = layoutConfig.sections.map((sectionLayout) => {


            let sectionVisible = this.isSectionVisible({ sectionLayout, documentPart });


            /*
            sectionVisibilityConditions: [{
            visible: true,
            conditionType: 'IS_INSERTED_BOOK_CONTENT',
            notVisibleHelperMessage:'This is for inserted books content'
        }]
            */

            let showOldElements = true;
            let sectionComponents = sectionLayout.elements.map((element) => {

                return this.createControl({
                    element,
                    bookDraft,
                    documentPart,
                    onUpdateRecipe,
                    onUpdateEntity,
                    canEdit,
                    boxSetBook
                });
                // switch (element.type) {


                //     case 'INFOHEADING':
                //         return <div style={{
                //             width: '100%',
                //             borderx: '1px solid red',
                //             display: 'flex'
                //         }}>
                //             <InfoHeading
                //                 bookDraft={bookDraft}
                //                 documentPart={documentPart}
                //                 element={element} />

                //         </div>

                //         break;

                //     case 'SUBHEADING':
                //         // return <div style={{
                //         //     width: '100%',
                //         //     borderx: '1px solid red',
                //         //     display: 'flex'
                //         // }}>
                //         return <Box sx={{
                //             marginTop: '2em'
                //         }}>
                //             <SubHeading
                //                 bookDraft={bookDraft}
                //                 documentPart={documentPart}
                //                 element={element}
                //                 boxSetBook={boxSetBook} />
                //         </Box>





                //         // </div>

                //         break;

                //     case 'PROPS_TOGGLE_BUTTONS':
                //         return <ToggleButtons
                //             bookDraft={bookDraft}
                //             documentPart={documentPart}
                //             element={element}
                //             canEdit={canEdit}
                //             onUpdateRecipe={onUpdateRecipe} />
                //         break;

                //     case 'PROPS_RADIO_BUTTONS':
                //         return <>
                //             {/* {showFormatParamInfo &&
                //                 paramInfo
                //             } */}
                //             <PropsRadioButtons
                //                 bookDraft={bookDraft}
                //                 documentPart={documentPart}
                //                 element={element}
                //                 canEdit={canEdit}
                //                 onUpdateRecipe={onUpdateRecipe}
                //                 onUpdateEntity={onUpdateEntity}
                //             />
                //         </>
                //         break;

                //     case 'PROPS_TEMPLATE_SELECTOR':

                //         {/* {showFormatParamInfo &&
                //                 paramInfo
                //             } */}

                //         return <PropsTemplateSelector
                //             key={element.recipeParamName}
                //             bookDraft={bookDraft}
                //             documentPart={documentPart}
                //             element={element}
                //             canEdit={canEdit}
                //             onUpdateRecipe={onUpdateRecipe} />

                //         break;

                //     case 'PROPS_CHECKBOX':
                //         return <>
                //             {/* {showFormatParamInfo &&
                //                     paramInfo
                //                 } */}
                //             <PropsCheckbox
                //                 bookDraft={bookDraft}
                //                 documentPart={documentPart}
                //                 element={element}
                //                 canEdit={canEdit}
                //                 onUpdateRecipe={onUpdateRecipe}
                //                 onUpdateEntity={onUpdateEntity} />
                //         </>
                //         break;

                //     case 'PROPS_IMAGE_POPUP_SELECTOR':
                //         return <PropsImageSelector
                //             bookDraft={bookDraft}
                //             documentPart={documentPart}
                //             element={element}
                //             canEdit={canEdit}
                //             onUpdateRecipe={onUpdateRecipe}
                //             onUpdateEntity={onUpdateEntity} />

                //         break;

                //     case 'SUBHEADING':
                //         // return <div style={{
                //         //     width: '100%',
                //         //     borderx: '1px solid red',
                //         //     display: 'flex'
                //         // }}>
                //         return <Box sx={{
                //             marginTop: '2em'
                //         }}>
                //             <SubHeading
                //                 bookDraft={bookDraft}
                //                 documentPart={documentPart}
                //                 element={element}
                //                 boxSetBook={boxSetBook} />
                //         </Box>





                //         // </div>

                //         break;


                // }

                return <div>{element.type}</div>
            });



            let oldSectionComponents = sectionLayout.elements.map((element) => {

                switch (element.type) {

                    case 'TEMPLATE_SELECTOR':

                        {/* {showFormatParamInfo &&
                                paramInfo
                            } */}

                        return <TemplateSelector
                            key={element.recipeParamName}
                            bookDraft={bookDraft}
                            documentPart={documentPart}
                            element={element}
                            canEdit={canEdit}
                            onUpdateRecipe={onUpdateRecipe} />

                        break;

                    case 'PARAGRAPH_AFTER_FIRST':

                        return <ParagraphAfterFirst
                            bookDraft={bookDraft}
                            documentPart={documentPart}
                            element={element}
                            canEdit={canEdit}
                            onUpdateRecipe={onUpdateRecipe} />
                        break;


                    // case 'TEMPLATE_SELECTOR_SELECTOR':
                    //     return <div>
                    //         {showFormatParamInfo &&
                    //             paramInfo
                    //         }
                    //         <div>{'<TemplateSelectorSelector>'}</div>
                    //         <TemplateSelectorSelector
                    //             bookDraft={bookDraft}
                    //             documentPart={documentPart}
                    //             element={element}
                    //             canEdit={canEdit}
                    //             onUpdateRecipe={onUpdateRecipe} />
                    //     </div>
                    //     break;




                    case 'HEADING_TEMPLATE_SOURCE_TOGGLE_BUTTONS':
                        return <FormatHeadingSourceToggleButtons
                            bookDraft={bookDraft}
                            documentPart={documentPart}
                            element={element}
                            canEdit={canEdit}
                            onUpdateRecipe={onUpdateRecipe} />
                        break;

                    case 'TEMPLATE_SOURCE_RADIO_BUTTONS':
                        return <FormatTemplateSourceSelector
                            bookDraft={bookDraft}
                            documentPart={documentPart}
                            element={element}
                            canEdit={canEdit}
                            onUpdateRecipe={onUpdateRecipe} />
                        break;

                    case 'BACKGROUND_IMAGE_SOURCE_TOGGLE_BUTTONS':
                        return <FormatBackgroundImageSourceToggleButtons
                            bookDraft={bookDraft}
                            documentPart={documentPart}
                            element={element}
                            canEdit={canEdit}
                            onUpdateRecipe={onUpdateRecipe} />
                        break;
                    // case 'TOGGLE_BUTTONS':
                    //     return <div>
                    //         {showFormatParamInfo &&
                    //             paramInfo
                    //         }
                    //         <ToggleButtons
                    //             bookDraft={bookDraft}
                    //             documentPart={documentPart}
                    //             element={element}
                    //             canEdit={true}
                    //             onUpdateRecipe={onUpdateRecipe}
                    //             onUpdateEntity={onUpdateEntity}
                    //         />
                    //     </div>
                    //     break;


                    // case 'NUMBERING_METHOD_SELECTOR':
                    //     return <div>
                    //         {showFormatParamInfo &&
                    //             paramInfo
                    //         }
                    //         <div>{'<NumberingMethodSelector>'}</div>
                    //         {/* <NumberingMethodSelector
                    //     bookDraft={bookDraft}
                    //     documentPart={documentPart}
                    //     element={element}
                    //     canEdit={canEdit}
                    //     onUpdateRecipe={onUpdateRecipe} /> */}
                    //     </div>
                    //     break;

                    case 'IMAGE_SOURCE_SELECTOR':
                        return <FormatImageSourceSelector
                            bookDraft={bookDraft}
                            documentPart={documentPart}
                            element={element}
                            canEdit={canEdit}
                            onUpdateRecipe={onUpdateRecipe}
                            onUpdateEntity={onUpdateEntity} />
                        break;

                    case 'TEXTFIELD':
                        return <>


                            <FormatTextfield
                                bookDraft={bookDraft}
                                documentPart={documentPart}
                                element={element}
                                canEdit={canEdit}
                                onUpdateRecipe={onUpdateRecipe}
                                onUpdateEntity={onUpdateEntity} />
                        </>



                        break;

                    case 'CHECKBOX':
                        return <>
                            {/* {showFormatParamInfo &&
                                paramInfo
                            } */}
                            <FormatCheckbox
                                bookDraft={bookDraft}
                                documentPart={documentPart}
                                element={element}
                                canEdit={canEdit}
                                onUpdateRecipe={onUpdateRecipe}
                                onUpdateEntity={onUpdateEntity} />
                        </>
                        break;

                    case 'RADIO_BUTTONS':
                        return <>
                            {/* {showFormatParamInfo &&
                                paramInfo
                            } */}
                            <FormatRadioButtons
                                bookDraft={bookDraft}
                                documentPart={documentPart}
                                element={element}
                                canEdit={canEdit}
                                onUpdateRecipe={onUpdateRecipe}
                                onUpdateEntity={onUpdateEntity}
                            />
                        </>
                        break;

                    // case 'DISCRETE_SLIDER':
                    //     return <div style={{
                    //         display: 'flex',
                    //         justifyContent: 'flex-start',
                    //         width: '75%',
                    //         marginLeft: '1em',
                    //         paddingRight: '4em'
                    //     }}>
                    //         {showFormatParamInfo &&
                    //             paramInfo
                    //         }
                    //         <DiscreteSlider
                    //             bookDraft={bookDraft}
                    //             documentPart={documentPart}
                    //             element={element}
                    //             canEdit={canEdit}
                    //             onUpdateRecipe={onUpdateRecipe}
                    //             onUpdateEntity={onUpdateEntity}
                    //         />
                    //     </div>
                    //     break;


                    case 'IMAGE_POPUP_SELECTOR':
                        return <FormatImageSelector
                            bookDraft={bookDraft}
                            documentPart={documentPart}
                            element={element}
                            canEdit={canEdit}
                            onUpdateRecipe={onUpdateRecipe}
                            onUpdateEntity={onUpdateEntity} />
                        // <StandardPopupImageSelector
                        //     showInformation={false}
                        //     title={element.title}
                        //     documentPart={documentPart}
                        //     bookDraft={bookDraft}
                        //     entity={documentPart}
                        //     element={element}
                        //     bucketName={element.bucketName}
                        //     folder={element.folder}
                        //     imageKeyFieldName={'blah'}
                        //     onImageSelected={(key) => {
                        //         //console.log(key);
                        //     }}
                        //     onChange={() => {
                        //         stores.bookStore.recipeTimestamp = Date.now();
                        //     }}
                        //     onUpdateRecipe={onUpdateRecipe}
                        //     onUpdateEntity={onUpdateEntity}

                        // />

                        break;



                    case 'IMAGE_UPLOAD_SELECTOR':
                        return <FormatImageUploadSelector
                            bookDraft={bookDraft}
                            documentPart={documentPart}
                            element={element}
                            canEdit={canEdit}
                            onUpdateRecipe={onUpdateRecipe}
                            onUpdateEntity={onUpdateEntity} />
                        break;

                    case 'IMAGE_SELECTOR':
                        return <FormatImageSelector
                            bookDraft={bookDraft}
                            documentPart={documentPart}
                            element={element}
                            canEdit={canEdit}
                            onUpdateRecipe={onUpdateRecipe}
                            onUpdateEntity={onUpdateEntity} />
                        break;


                    // case 'IMAGE_SELECTOR':
                    //     return <div>
                    //         {showFormatParamInfo &&
                    //             paramInfo
                    //         }
                    //         <div style={{
                    //             display: 'flex',
                    //             marginLeft: '.5em',
                    //             marginRight: '.5em'
                    //         }}>
                    //             <div sx={{
                    //                 display: 'flex',
                    //                 flexDirection: 'column',
                    //                 flex: 3,
                    //                 justifyContent: 'flex-start',
                    //                 alignItems: 'center',
                    //                 height: '5em'
                    //             }}>
                    //                 {this.generateImageSelector({ element, documentPart, bookDraft, user, onUpdateRecipe, onUpdateEntity, stores })}
                    //             </div>
                    //         </div>



                    //     </div >
                    //     break;
                }

            });

            if (showOldElements) {

                sectionComponents = sectionComponents.concat(oldSectionComponents);

            }

            // return <FormatPropertiesCard title={sectionLayout.title} count={5} color={theme.palette.books?.main} icon={<BookIcon sx={{ color: theme.palette.cardIcon?.main }} />} >
            //     <Grid sx={{
            //         display: 'flex',
            //         flexDirection: 'column'
            //     }}>

            //         {sectionVisible &&
            //             sectionComponents
            //         }
            //         {!sectionVisible &&
            //             <EmptyContent tagline={sectionLayout.notVisibleHelperMessage} />
            //         }


            //     </Grid>
            // </FormatPropertiesCard>


            return <>
                {sectionVisible &&
                    <FormatPropertiesCard title={new BaseControl().convertTitle(sectionLayout.title, documentPart)} count={5} color={theme.palette.books?.main} icon={<BookIcon sx={{ color: theme.palette.cardIcon?.main }} />} >
                        <Grid sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>

                            {sectionVisible &&
                                sectionComponents
                            }



                        </Grid>
                    </FormatPropertiesCard>
                }
            </>



        });

        return propertyCards;

        // return <>
        //      <FormatPropertiesCard title={'Heading Template'} count={5} color={theme.palette.books?.main} icon={<BookIcon sx={{ color: theme.palette.cardIcon?.main }} />} >
        //       <Grid sx={{
        //         display: 'flex',
        //         flexDirection: 'column'
        //       }}>

        //         <StandardRadioButtons title={'Top Margin'} options={[
        //           { label: 'Small', value: 'small' },
        //           { label: 'Medium', value: 'Medium' },
        //           { label: 'Large', value: 'Large' }
        //         ]} />
        //         <StandardRadioButtons title={'Top Margin'} options={[
        //           { label: 'Small', value: 'small' },
        //           { label: 'Medium', value: 'Medium' },
        //           { label: 'Large', value: 'Large' }
        //         ]} />
        //         <StandardRadioButtons title={'Top Margin'} options={[
        //           { label: 'Small', value: 'small' },
        //           { label: 'Medium', value: 'Medium' },
        //           { label: 'Large', value: 'Large' }
        //         ]} />

        //       </Grid>
        //     </FormatPropertiesCard>
        //     <FormatPropertiesCard title={'Heading Visibility'} count={5} color={theme.palette.books?.main} icon={<BookIcon sx={{ color: theme.palette.cardIcon?.main }} />} >
        //       <Grid sx={{
        //         display: 'flex',
        //         flexDirection: 'column'
        //       }}>

        //         <StandardRadioButtons title={'Top Margin'} options={[
        //           { label: 'Small', value: 'small' },
        //           { label: 'Medium', value: 'Medium' },
        //           { label: 'Large', value: 'Large' }
        //         ]} />
        //         <StandardRadioButtons title={'Top Margin'} options={[
        //           { label: 'Small', value: 'small' },
        //           { label: 'Medium', value: 'Medium' },
        //           { label: 'Large', value: 'Large' }
        //         ]} />
        //         <StandardRadioButtons title={'Top Margin'} options={[
        //           { label: 'Small', value: 'small' },
        //           { label: 'Medium', value: 'Medium' },
        //           { label: 'Large', value: 'Large' }
        //         ]} />

        //       </Grid>
        //     </FormatPropertiesCard>
        //     <FormatPropertiesCard title={'Title Text'} count={5} color={theme.palette.books?.main} icon={<BookIcon sx={{ color: theme.palette.cardIcon?.main }} />} >
        //       <Grid sx={{
        //         display: 'flex',
        //         flexDirection: 'column'
        //       }}>

        //         <StandardRadioButtons title={'Top Margin'} options={[
        //           { label: 'Small', value: 'small' },
        //           { label: 'Medium', value: 'Medium' },
        //           { label: 'Large', value: 'Large' }
        //         ]} />
        //         <StandardRadioButtons title={'Top Margin'} options={[
        //           { label: 'Small', value: 'small' },
        //           { label: 'Medium', value: 'Medium' },
        //           { label: 'Large', value: 'Large' }
        //         ]} />
        //         <StandardRadioButtons title={'Top Margin'} options={[
        //           { label: 'Small', value: 'small' },
        //           { label: 'Medium', value: 'Medium' },
        //           { label: 'Large', value: 'Large' }
        //         ]} />

        //       </Grid>
        //     </FormatPropertiesCard>
        //     <FormatPropertiesCard title={'Subtitle Text'} count={5} color={theme.palette.books?.main} icon={<BookIcon sx={{ color: theme.palette.cardIcon?.main }} />} >
        //       <Grid sx={{
        //         display: 'flex',
        //         flexDirection: 'column'
        //       }}>

        //         <StandardRadioButtons title={'Top Margin'} options={[
        //           { label: 'Small', value: 'small' },
        //           { label: 'Medium', value: 'Medium' },
        //           { label: 'Large', value: 'Large' }
        //         ]} />
        //         <StandardRadioButtons title={'Top Margin'} options={[
        //           { label: 'Small', value: 'small' },
        //           { label: 'Medium', value: 'Medium' },
        //           { label: 'Large', value: 'Large' }
        //         ]} />
        //         <StandardRadioButtons title={'Top Margin'} options={[
        //           { label: 'Small', value: 'small' },
        //           { label: 'Medium', value: 'Medium' },
        //           { label: 'Large', value: 'Large' }
        //         ]} />

        //       </Grid>
        //     </FormatPropertiesCard>
        // </>
    }
}