import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton, { StandardListButton } from "../StandardButton";

const UserConnectionsList = (props) => {

  let user = props.stores.userStore.user;

  let { filteredConnections } = props;
  const { userConnections, error: getUserConnectionsError } = useGetUserConnections(props);
  const { userGroups, error: getUserUserGroupsError, loading: getUserUserGroupsLoading } = useGetUserGroups(props);


  let remainingUserConnections = userConnections;

  if (filteredConnections) {

    //remainingUserConnections = userConnections.filter((el) => !filteredConnections.includes(el));

    remainingUserConnections = userConnections.filter(userConnection => !filteredConnections.find(rm => (rm.id === userConnection.connectedUser.id)))

  } 





  const createSecondaryActions = (userConnection) => {

    let secondaryActions = [];

    if (props.showSelectIcon) {
      secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
        //console.log(userConnection)
        props.onSelected(userConnection);
      }}>
        <ShareIcon />
      </IconButton>);
    }

    if (props.showRequestButton) {
      secondaryActions.push(<ListItem disablePadding>
        <StandardListButton onClick={(e) => {
          //console.log(userConnection)
          props.onSelected(userConnection);
        }}>
          Request
        </StandardListButton>
      </ListItem>
        // <IconButton edge="end" aria-label="delete" onClick={(e) => {
        //   console.log(userConnection)
        //   props.onSelected(userConnection);
        // }}>
        //   <ShareIcon />
        // </IconButton>
      );
    }

    return secondaryActions;
  }



return (
  <List component="div" disablePadding>
    {
      remainingUserConnections.map((userConnection) => {

        return <ListItem secondaryAction={createSecondaryActions(userConnection)}>
          <ListItemAvatar>
            <AvatarTableCell
              bucketName={config.user_uploads_bucket_name}
              s3Src={userConnection?.connectedUser?.profilePicUrl}
            //title={userConnection?.connectedUser?.name}
            />
          </ListItemAvatar>
          <ListItemText primary={userConnection?.connectedUser?.name} secondary="" />

        </ListItem>
      })
    }
  </List>

)
}

export default inject('stores')(observer(UserConnectionsList));