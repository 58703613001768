import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import fragments from './persistanceFragments';

export default {
    GET_BOOK_DRAFT: gql`
    query getBookDraft($bookDraftId: ID!) {
        getBookDraft(id: $bookDraftId) {
            ...BookDraftDetails
          book {
              ...BookDetails

                  recipe {
                      ...RecipeDetails
                  }

              boxSet{
                  ...BoxSetDetails
                  book {
                      ...BookDetails
                  }
                  boxSetBooks {
                      items {
                          id
                          boxSetId
                          bookId
                          recipeSource
                          boxset {
                              id
                              ownerId
                          }
                          book {
                              ...BookDetails
                            recipe {
                              ...RecipeDetails
                            }
                          }
                          recipe {
                              ...RecipeDetails
                          }
                      }
          }
              }
              
          }
          bookDraftDocumentParts {
            nextToken
            items {
              ...BookDraftDocumentPartDetails
              documentPart {
                            ...DocumentPartDetails
                          alsoByBooks {
                              items {
                                  id
                                  title
                                  subTitle
                                  authorName
                                  coverUrl
                                  coverThumbnailUrl
                                    appleIdentifier
                                    appleStoreLinkUrl
                                    bookbubStoreLinkUrl
                                    customStoreLinkUrl
                                    googleStoreLinkUrl
                                    kindleStoreLinkUrl
                                    koboStoreLinkUrl
                                    nookStoreLinkUrl      
										appleIdentifier
										bookbubIdentifier
										customIdentifier
										googleIdentifier
										kindleIdentifier
										koboIdentifier
										nookIdentifier

                              }
                          }
                          insertedBook {
                              ...BookDetails
                              primaryBookDraft{
                                  id
                                  bookDraftDocumentParts {
                                      nextToken
                                      items {
                                          ...BookDraftDocumentPartDetails
                                          documentPart {
                                              ...DocumentPartDetails
                                              alsoByBooks {
                                                items {
                                                    id
                                                    title
                                                    subTitle
                                                    authorName
                                                    coverUrl
                                                    coverThumbnailUrl
                                                    appleIdentifier
                                                    appleStoreLinkUrl
                                                    bookbubStoreLinkUrl
                                                    customStoreLinkUrl
                                                    googleStoreLinkUrl
                                                    kindleStoreLinkUrl
                                                    koboStoreLinkUrl
                                                    nookStoreLinkUrl      
                                                    appleIdentifier
                                                    bookbubIdentifier
                                                    customIdentifier
                                                    googleIdentifier
                                                    kindleIdentifier
                                                    koboIdentifier
                                                    nookIdentifier
                                                }
                                            }
                                          }
                                      }
                                      }
                              }
                          }	
              }
            }
          }
        }
      }
      ${fragments.FRAGMENT_BOOK_DETAILS}
      ${fragments.FRAGMENT_BOOKDRAFT_DETAILS}
      ${fragments.FRAGMENT_BOXSET_DETAILS}
      ${fragments.FRAGMENT_DOCUMENTPART_DETAILS}
      ${fragments.FRAGMENT_BOOKDRAFT_DOCUMENTPART_DETAILS}
      ${fragments.FRAGMENT_RECIPE_DETAILS}
      `,

    GET_INFLATABLES_BOOK_DRAFT: gql`
    query getInflatablesBookDraft($bookDraftId: ID!) {
        getBookDraft(id: $bookDraftId) {
            ...BookDraftDetails
          book {
              ...BookDetails

                  recipe {
                      ...RecipeDetails
                  }

              boxSet{
                  ...BoxSetDetails
                  book {
                      ...BookDetails
                  }
                  boxSetBooks {
                      items {
                          id
                          boxSetId
                          bookId
                          recipeSource
                          boxset {
                              id
                              ownerId
                          }
                          book {
                              ...BookDetails
                            recipe {
                              ...RecipeDetails
                            }
                          }
                          recipe {
                              ...RecipeDetails
                          }
                      }
          }
              }
              
          }
          bookDraftDocumentParts {
            nextToken
            items {
              ...BookDraftDocumentPartDetails
              documentPart {
                            ...DocumentPartDetails
                          insertedBook {
                              ...BookDetails
                              primaryBookDraft{
                                  id
                                  bookDraftDocumentParts {
                                      nextToken
                                      items {
                                          ...BookDraftDocumentPartDetails
                                          documentPart {
                                              ...DocumentPartDetails
                                          }
                                      }
                                      }
                              }
                          }	
              }
            }
          }
        }
      }
      ${fragments.FRAGMENT_BOOK_DETAILS}
      ${fragments.FRAGMENT_BOOKDRAFT_DETAILS}
      ${fragments.FRAGMENT_BOXSET_DETAILS}
      ${fragments.FRAGMENT_INFLATABLES_DOCUMENTPART_DETAILS}
      ${fragments.FRAGMENT_INFLATABLES_BOOKDRAFT_DOCUMENTPART_DETAILS}
      ${fragments.FRAGMENT_RECIPE_DETAILS}
      `,

    LIST_BOXSETS: gql`
        query listBoxSets {
        listBoxSets {
            items {
                __typename
                        ...BoxSetDetails
                        book{    
                           ...BookDetails
                        }  
            }
        }
        }
        ${fragments.FRAGMENT_BOOK_DETAILS}
        ${fragments.FRAGMENT_BOXSET_DETAILS}
        `,

GET_VELOCITY_BOOK_DRAFT: gql`
    query getVelocityBookDraft($bookDraftId: ID!) {
        getBookDraft(id: $bookDraftId) {
            id
            bookDraftType
            bookId
            ownerId
            version
            __typename
          book {
            id
            title
            subTitle
            authorName
            __typename

              
          }
          bookDraftDocumentParts {
            nextToken
            items {
                id
            partId
            bookDraftId
            parentPartId
            prevPartId
            originalParentPartId
            originalPrevPartId
            role
            ownerId
            version
            __typename
              documentPart {
                
                id
            ownerId
            cognitoId
            owner {
                id
                name
                cognitoId
            }
            partType
            title
            subtitle
            description
            bookDraftId
            bookId
            deltaOps
            content
            notes
            includeTitle
            includeSubtitle
            version
            __typename
           
              }
            }
          }
        }
      }
    
      `,

GET_USER_INVITED_TO_USER_GROUPS: gql`query getUserInvitedToUserGroups {
  getUser(id:"getUserInvitedToUserGroups") {
    invitedToUserGroupUsers {
      items {
        
        invitedAt
        acceptedAt
        rejectedAt
        leftAt
        removedAt
        deletedAt
        updatedAt
          user {
            id
            name
            profilePicUrl
          }
          userGroup{
            id
            name
            ownerId
            profilePicUrl
            groupOwner {
             id
             name
             profilePicUrl
            }
          }
        }
    }
  }
  }`,








}



