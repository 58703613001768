import { Grid, IconButton, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import React, { useState } from "react";
import StandardDialog from "../StandardDialog";
import StandardTextfield from "../StandardTextfield";
import StandardButton from "../StandardButton";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useManagerCreateBook, useManagerDeleteBook } from '../../managers/BooksManager';

const DeleteBookDialog = (props) => {

  let { book } = props;
  let [title, setTitle] = useState(null);
  let [description, setDescription] = useState(null);

  let { deleteBook } = useManagerDeleteBook();

  let {
    createBook,
    createBookLoading,
    createBookError
  } = useManagerCreateBook();


  const handleTitleChange = (e) => {
    e.preventDefault();

    setTitle(e.target.value);
  }

  const handleDescriptionChange = (e) => {
    e.preventDefault();

    setDescription(e.target.value);
  }


  const handleDeleteBook = () => {

    deleteBook({
      bookId:book.id,
      stores: props.stores
    }, () => {
      props.onClose();
    })

  }

  return (
    <StandardDialog
      open={props.open}
      onClose={props.onClose}
      dialogContent={
        <Grid container direction={'column'}>
          <Grid item>
            <Typography>Deleting this book will send it to the Trash. If you would like to permanently delete this book, please go to the Trash Page.</Typography>
          </Grid>

          {/* <Grid item>
            <StandardTextfield label={'Description'} onChange={handleDescriptionChange} />
          </Grid> */}

          
        </Grid>



      }
      title={'Delete Book'}
      actions={[
        <StandardButton autoFocus onClick={props?.onClose}>
          Cancel
        </StandardButton>,
        <StandardButton autoFocus onClick={(e) => {
          e.preventDefault();
          handleDeleteBook();
        }}>
          Ok
        </StandardButton>
      ]}>

    </StandardDialog>
    
  )
}

export default inject('stores')(observer(DeleteBookDialog));