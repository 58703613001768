import React, { useState, useEffect } from "react";
import { Avatar, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import StandardTabs from "../StandardTabs";
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton, { StandardListButton } from "../StandardButton";
import UserConnectionsList from "../lists/UserConnectionsList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../managers/BooksManager';
import UserGroupsList from "../lists/UserGroupsList";
import {
  useManagerAddBoxsetContributor,
  useManagerAddBoxsetContributedBook,
  useManagerLoadBoxSetContributors,
  useManagerRemoveBoxSetContributedBook
} from '../../managers/BoxsetsManager';
import { User } from "../../domain/bookModel";
import OwnedBooksList from "../lists/OwnedBooksList";
import StandardDetailTitle from "../StandardDetailTitle";
import StandardImageViewer from "../StandardImageViewer";
import StandardErrors from "../StandardErrors";


const RemoveBoxsetContributedBookPanel = (props) => {

  let user = props.stores.userStore.user;
  let { boxset, book } = props;

  let [boxsetBooks, setBoxsetBooks] = useState([]);
  let [errors, setErrors] = useState([]);
  

  const { addBoxSetContributedBook, loading: addBoxSetContributedBookLoading, error: addBoxSetContributedBookError } = useManagerAddBoxsetContributedBook(props);
  const { addBoxsetContributor, loading: createBoxSetContributorLoading, error: createBoxSetContributorError } = useManagerAddBoxsetContributor(props);
  const { removeBoxSetContributedBook, loading: removeBoxSetContributedBookLoading, error: removeBoxSetContributedBookError } = useManagerRemoveBoxSetContributedBook(props);



  let {
    boxsetContributors,
    loading: lazyLoadBoxSetContributorsLoading,
    error: lazyLoadBoxSetContributorsError
  } = useManagerLoadBoxSetContributors(props, boxset?.id);


  useEffect(() => {

    try {

      let bookData = [];
      boxsetContributors.forEach((boxsetContributor) => {

        boxsetContributor.contributedBooks.items.forEach((bsb) => {

          bookData.push({
            boxsetContributor,
            boxsetBook: bsb
          })
        })

      });

      setBoxsetBooks(bookData);

    } catch (err) {
      console.log(err);
    }
  }, [boxsetContributors]);



  //let profilePicSrc = 'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + contributor?.profilePicUrl;



  return (
    <Grid container direction={'column'}>
      

      <Grid item >
          <StandardDetailTitle >{book?.title}</StandardDetailTitle>
        </Grid>

        <Grid item >
          <Grid container >

            <Grid item >

              <StandardImageViewer
                bucketName={config.user_uploads_bucket_name}
                //s3Path={'uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/b6f59120-18fe-11ed-b9d5-076ac854f9a5_planetStrider.png'}
                s3Path={book?.coverUrl}
                height={'10em'}
                fit={'contain'}
                sx={{
                  minHeight: '5em',
                  m:'2em'
                  //display: { sm: 'block', lg: 'block', md: 'block' } 
                }}
                style={{ borderRadius: 2 }}
                className="custom-class"
                onClick={(e) => {
                  // e.preventDefault();
                  // setShowGallery(true);

                }}
                showLoading
                errorIcon

              />

            </Grid>
            <Grid item >
              <StandardImageViewer
                bucketName={config.user_uploads_bucket_name}
                //s3Path={'uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/b6f59120-18fe-11ed-b9d5-076ac854f9a5_planetStrider.png'}
                s3Path={book?.coverThumbnailUrl}
                height={'10em'}
                fit={'contain'}
                sx={{
                  minHeight: '5em',
                  m:'2em'
                  //display: { sm: 'block', lg: 'block', md: 'block' } 
                }}
                style={{ borderRadius: 2 }}
                className="custom-class"
                onClick={(e) => {
                  // e.preventDefault();
                  // setShowGallery(true);

                }}
                showLoading
                errorIcon

              />
            </Grid>
          </Grid>
        </Grid>






      <Grid item>

        {removeBoxSetContributedBookLoading &&
          <div>removing...</div>
        }
        <StandardErrors errors={errors}/>
        {!removeBoxSetContributedBookLoading &&

          <StandardListButton onClick={() => {
            setErrors([])
            removeBoxSetContributedBook({
              bookId: book.id,
              boxsetId: boxset.id,
            }, 
            () => {
              if (props.onRemoved) {
                props.onRemoved()
              }
            }, 
            (errors) => {
              setErrors(errors);
            })
          }}>Remove</StandardListButton>
        }

      </Grid>
    </Grid>
  )
}

export default inject('stores')(observer(RemoveBoxsetContributedBookPanel));