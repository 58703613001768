import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Box from '@mui/material/Box';
import BookMerlinStyle from './BookMerlinStyle';
import { useCreateRecipe, useListBookMerlinRecipes } from '../../persistance/persistanceHooks';
import { Button } from '@mui/material';

const BookMerlinStyles = (props) => {

    const [bookMerlinRecipes, setBookMerlinRecipes] = useState([]);
    const [createRecipe, { data: createRecipeData, loading: createRecipeLoading, error: createRecipeError }] = useCreateRecipe();
    const { loading: listBookMerlinRecipesLoading, error: listBookMerlinRecipesError, data: listBookMerlinRecipesData, refetch: listBookMerlinRecipesRefetch } = useListBookMerlinRecipes();


    let bmStyles = [1, 2, 3, 4, 5, 6]


    useEffect(() => {
        //console.log(listBookMerlinRecipesData);
        try {
            let recipes = listBookMerlinRecipesData.listBookMerlinRecipes.items.map((m) => {

                return <div style={{
                    width: '30%',
                    marginTop: '1em',
                    marginRight: '.5em',
                    marginLeft: '.5em'
                }}>
                    <BookMerlinStyle
                        documentPart={props.documentPart}
                        currentRecipe={props.currentRecipe}
                        recipe={m}
                        lazyGetRecipe={props.lazyGetRecipe}
                    />
                </div>

                // return <div style={{
                //     border: '1px solid blue',
                //     marginBottom: '1em'
                // }}>
                //     <div>{m.title}</div>
                //     <div>{m.id}</div>
                //     <div style={{
                //         display: 'flex'
                //     }}>
                //         <Button onClick={() => {

                //             lazyGetRecipe({
                //                 variables: {
                //                     recipeId: m.id
                //                 },
                //                 //fetchPolicy: 'network-only'
                //             })
                //         }}>Set</Button>


                //     </div>


                // </div>
            })

            setBookMerlinRecipes(recipes);
        } catch (err) {
            //console.log(err);
        }
    }, [listBookMerlinRecipesData, props.documentPart])

    return (

        <div style={{
            //display:displayBig,
            //flexDirection:'column',
            widthx: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'flex-start',
            alignItems: 'top',
            flexWrap: 'wrap',
            borderx: '1px solid green'
        }}>

            {bookMerlinRecipes}




            {props.stores.bookStore.isDevMode &&
                <Button onClick={() => {

                    let recipe = props.currentRecipe;
                    let recipeJson = JSON.stringify(recipe.json);
                    createRecipe({
                        recipeJson,
                        //ownerId: 'BOOKMERLIN',
                        type: 'TOP_STYLE',
                        title: 'New Style'
                    });

                    setTimeout(() => {
                        listBookMerlinRecipesRefetch();
                    }, 3000)

                }}>Create Recipe</Button>
            }




            {/* {bmStyles.map((style) => {
                return <div style={{
                    width: '33%'
                }}>
                    <BookMerlinStyle />
                </div>

            })} */}
        </div>

    );
};


export default inject('stores')(observer(BookMerlinStyles));