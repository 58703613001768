import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import StandardDataTable from '../table/StandardDataTable';
import { useManagerLoadBoxSetContributors } from '../../managers/BoxsetsManager';
import CancelTableCell from '../table/CancelTableCell';
import StandardOkCancelDialog from '../panels/StandardOkCancelDialog';
import RemoveBoxsetContributorPanel from '../panels/RemoveBoxsetContributorPanel';
import AvatarTableCell from '../table/AvatarTableCell';
import config from '../../aws-exports';
import { useMediaQuery } from 'react-responsive';
import AddContributorAssembly from '../assemblies/AddContributorAssembly';
import StandardDashboardPaper from '../StandardPaper';
import OwnedBoxsetContributorsList from '../lists/OwnedBoxsetContributorsList';
import ContributorsList from '../lists/ContributorsList';
import LaurasListItem from './LaurasListItem';
import ShareIcon from '@mui/icons-material/Share';
import StandardList from "../lists/StandardList";
import CircleAvatar from "../lists/CircleAvatar";
import EmptyContent from "../EmptyContent";
import CardPagination from './CardPagination';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';



let missingBookImage = require('../../assets/images/image-not-found.png')

const LaurasBoxsetContributors = (props) => {

  const isXs = useMediaQuery({ maxWidth: 599 });
  const isSm = useMediaQuery({ maxWidth: 600 });
  const isMd = useMediaQuery({ maxWidth: 900 });
  const isLg = useMediaQuery({ maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1536 });

  let boxset = props.boxset;

  let [showRemoveContributorDialog, setShowRemoveContributorDialog] = useState(false);
  let [selectedContributor, setSelectedContributor] = useState(false);

  let {
    boxsetContributors,
    loading: lazyLoadBoxSetContributorsLoading,
    error: lazyLoadBoxSetContributorsError,
    refetch: lazyLoadBoxSetContributorsRefetch
  } = useManagerLoadBoxSetContributors(props, boxset?.id);

  //console.log(boxsetContributors);


  const createSecondaryActions = (boxsetContributor) => {

    let secondaryActions = [];

    if (props.showSelectIcon) {
      secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
        //console.log(boxsetContributor)
        props.onSelected(boxsetContributor);
      }}>
        <ShareIcon />
      </IconButton>);
    }

    return secondaryActions;
  }

  const itemsPerPage = 3;
  const [page, setPage] = useState(1);
  const [noOfPages] = useState(
    // Math.ceil(boxsetContributors.length / itemsPerPage)
    3
  );

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <StandardList
        title='Contributors'
        headerActions={<AddContributorAssembly boxset={boxset} />}
        component="div"
        disablePadding>
        {boxsetContributors && boxsetContributors.length > 0 ?
          boxsetContributors
            .slice((page - 1) * itemsPerPage, page * itemsPerPage)
            .map((boxsetContributor) => {

              return <>

                <ListItem
                  alignItems="flex-start"

                  secondaryAction={



                    <Box sx={{
                      display: 'flex'
                    }}>
                      <CancelTableCell onClick={(e) => {
                        e.preventDefault();
                        setShowRemoveContributorDialog(true)
                        setSelectedContributor(boxsetContributor.contributor)

                      }} />


                    </Box>







                  }>
                  <ListItemAvatar>
                    <CircleAvatar
                      //bucketName={config.user_uploads_bucket_name}
                      s3Src={boxsetContributor?.contributor?.profilePicUrl}
                      missingContent={<img src={missingBookImage} />}
                    />
                    {/* <Avatar alt="Remy Sharp" variant="square" src="https://local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm.s3.amazonaws.com/uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/c7cb5750-3627-11ed-914f-5d91b6c4c7eb_planetStrider.png">

              </Avatar> */}
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      noWrap: true
                    }}
                    primary={boxsetContributor?.contributor?.name}
                    secondary={
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Since date
                        </Typography>
                        {/* <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="caption"
                                            color="text.secondary"
                                        >
                                            Words: 2,432
                                        </Typography> */}
                        {/* {" — I'll be in your neighborhood doing errands this…"} */}
                      </Box>
                    }

                  />

                </ListItem>
                {/* <Divider variant="inset"/> */}

                <StandardOkCancelDialog
                  showOkButton={true}
                  showCancelButton={true}
                  open={showRemoveContributorDialog}
                  onClose={() => {
                    setShowRemoveContributorDialog(false)
                    setSelectedContributor(null)
                  }}
                  onOk={() => {
                    setShowRemoveContributorDialog(false)
                    setSelectedContributor(null)

                  }}
                  title={'Remove Contributor'}
                  content={
                    <RemoveBoxsetContributorPanel boxset={boxset} contributor={selectedContributor} onRemoved={() => {
                      setShowRemoveContributorDialog(false)
                      setSelectedContributor(null)
                      lazyLoadBoxSetContributorsRefetch();

                    }} />
                  }
                />

              </>
            })
          :
          <Box sx={{
            marginTop: '3em'
          }}>
            <EmptyContent tagline={'Contributors will show up here.'} />
          </Box>

        }
        {/* {ownedBooks.length}:
      {availableBooks.length} */}
      </StandardList>
      <Divider />
      <Stack spacing={2}>
        <Pagination
          count={noOfPages}
          page={page}
          onChange={handleChange}
          defaultPage={1}
          color="primary"
          size="large"
          showFirstButton
          showLastButton
          style={{display: 'flex', justifyContent: 'center', marginTop: '0.5em'}}
        />
      </Stack>
    </>
  )
}

export default inject('stores')(observer(LaurasBoxsetContributors));