import config from '../../../aws-exports';
// import { RecipeUtils } from '../RecipeUtils';
// import Storage from '@aws-amplify/storage';
// import config from '../aws-exports';
const RecipeUtils = require('../RecipeUtils').RecipeUtils;
let IS_SERVER_SIDE = false;

export class ResourceExtractor {



  partTypes = [
    'common',
    'copyright',
    'dedication',
    'blurbs',
    'titlepage',
    'halftitle',
    'introduction',
    'acknowledgements',
    'foreword',
    'preface',
    'prologue',
    'volume',
    'part',
    'chapter',
    'toc',
    'afterword',
    'epigraph',
    'epilogue',
    'aboutauthor',
    'alsoby',
    'fullPageImage',
    'notes',
    'untyped'
  ];

  requiredResources = {

    "font1": {
      "fontFiles": [{
        "fileName": "KaushanScript-Regular.otf",
        "mediaType": "application/vnd.ms-opentype"
      }

        //'dummy1.tff',
        //'dummy2.tff'
      ],

      "cssBlocks": [
        "@font-face" +
        "{" +
        "font-family: \"font1\"; " +
        "src: url(\"../fonts/KaushanScript-Regular.otf\"); " +
        "} ",

        //"dummy cssBlock "
      ]
    },



    "font2": {
      "fontFiles": [

        {
          "fileName": "AlexBrush-Regular.ttf",
          "mediaType": "application/vnd.ms-opentype"
        }
      ],

      "cssBlocks": [
        "@font-face" +
        "{" +
        "font-family: \"font2\"; " +
        "src: url(\"../fonts/AlexBrush-Regular.ttf\"); " +
        "} "
      ]
    },



    "font3": {
      "fontFiles": [
        {
          "fileName": "3Dumb.ttf",
          "mediaType": "application/vnd.ms-opentype"
        }

      ],

      "cssBlocks": [
        "@font-face" +
        "{" +
        "font-family: \"font3\"; " +
        "src: url(\"../fonts/3Dumb.ttf\"); " +
        "} "
      ]
    },



    "font4": {
      "fontFiles": [
        {
          "fileName": "fontfile4.tff",
          "mediaType": "application/font-sfnt"
        }

      ],

      "cssBlocks": [
        "@font-face" +
        "{" +
        "font-family: \"font4\"; " +
        "src: url(\"../fonts/fontfile4.tff\"); " +
        "} "
      ]
    },



    "font5": {
      "fontFiles": [
        {
          "fileName": "LeagueScriptNumberOne.otf",
          "mediaType": "application/vnd.ms-opentype"
        }

      ],

      "cssBlocks": [
        "@font-face" +
        "{" +
        "font-family: \"font5\"; " +
        "src: url(\"../fonts/LeagueScriptNumberOne.otf\"); " +
        "} "
      ]
    },



    "font6": {
      "fontFiles": [
        {
          "fileName": "Orbitron Medium.otf",
          "mediaType": "application/vnd.ms-opentype"
        }

      ],

      "cssBlocks": [
        "@font-face" +
        "{" +
        "font-family: \"font6\"; " +
        "src: url(\"../fonts/Orbitron Medium.otf\"); " +
        "} "
      ]
    },

    "pinterestThumbnail": {

      "imageFiles": [
        {
          "fileName": "pinterest.png",
          "s3Key": "public/social/pinterest.png",
          "mediaType": "image/png",
          "source": "commonImages",
          "level": "public",
          "folder": "social"
        }
      ]
    },

    "facebookThumbnail": {

      "imageFiles": [
        {
          "fileName": "facebook.png",
          "s3Key": "public/social/facebook.png",
          "mediaType": "image/png",
          "source": "commonImages",
          "level": "public",
          "folder": "social"
        }
      ]
    },

    "goodreadsThumbnail": {

      "imageFiles": [
        {
          "fileName": "goodreads.png",
          "s3Key": "public/social/goodreads.png",
          "mediaType": "image/png",
          "source": "commonImages",
          "level": "public",
          "folder": "social"
        }
      ]
    },

    "twitterThumbnail": {

      "imageFiles": [
        {
          "fileName": "twitter.png",
          "s3Key": "public/social/twitter.png",
          "mediaType": "image/png",
          "source": "commonImages",
          "level": "public",
          "folder": "social"
        }
      ]
    },

    "snapchatThumbnail": {

      "imageFiles": [
        {
          "fileName": "snapchat.png",
          "s3Key": "public/social/snapchat.png",
          "mediaType": "image/png",
          "source": "commonImages",
          "level": "public",
          "folder": "social"
        }
      ]
    },

    "instagramThumbnail": {

      "imageFiles": [
        {
          "fileName": "instagram.png",
          "s3Key": "public/social/instagram.png",
          "mediaType": "image/png",
          "source": "commonImages",
          "level": "public",
          "folder": "social"
        }
      ]
    },

    "bookbubThumbnail": {

      "imageFiles": [
        {
          "fileName": "bookbub.png",
          "s3Key": "public/social/bookbub.png",
          "mediaType": "image/png",
          "source": "commonImages",
          "level": "public",
          "folder": "social"
        }
      ]
    },

    "amazonThumbnail": {

      "imageFiles": [
        {
          "fileName": "amazon.png",
          "s3Key": "public/social/amazon.png",
          "mediaType": "image/png",
          "source": "commonImages",
          "level": "public",
          "folder": "social"
        }
      ]
    },

    "youtubeThumbnail": {

      "imageFiles": [
        {
          "fileName": "youtube.png",
          "s3Key": "public/social/youtube.png",
          "mediaType": "image/png",
          "source": "commonImages",
          "level": "public",
          "folder": "social"
        }
      ]
    },

    "decoratedBreak3": {

      "imageFiles": [
        {
          "fileName": "fancy-dividers-clipart-1.png",
          "mediaType": "image/png",
          "source": "commonImages"
        }
      ]
    },

    "decoratedBreak2": {

      "imageFiles": [
        {
          "fileName": "ornamental1.png",
          "mediaType": "image/png",
          "source": "commonImages"
        }
      ]
    },

    "decoratedBreak1": {

      "imageFiles": [
        {
          "fileName": "ornamental2.png",
          "mediaType": "image/png",
          "source": "commonImages"
        }
      ]
    }




  }





  cleanMediaType(mediaType) {

    if (!mediaType) {
      return '';
    }
    let cleanedMediaType = mediaType;
    try {
      if (mediaType.contains('jpg')) {
        cleanedMediaType = mediaType.replace('jpg', 'jpeg');
        return cleanedMediaType;
      }
    } catch (err) {
      return mediaType;
    }
  }


  isHeadingVisible(documentPart) {




    let global_common_hideAllHeadingsParamId = 'global_common_hideAllHeadings'
    let global_hideAll_PART_TYPE_HeadingsParamId = 'global_hideAll' + documentPart.partType + 'Headings'

    let global_common_hideAllHeadings = null;
    if (this.getRecipeParam(this.recipe, global_common_hideAllHeadingsParamId)) {
      global_common_hideAllHeadings = this.getRecipeParam(this.recipe, global_common_hideAllHeadingsParamId).value;
    }

    let global_hideAll_PART_TYPE_Headings = null;
    if (this.getRecipeParam(this.recipe, global_hideAll_PART_TYPE_HeadingsParamId)) {
      global_hideAll_PART_TYPE_Headings = this.getRecipeParam(this.recipe, global_hideAll_PART_TYPE_HeadingsParamId).value;
    }



    if (global_common_hideAllHeadings &&
      (global_common_hideAllHeadings == true || global_common_hideAllHeadings.toLowerCase() == 'true')) {
      return false;
    }

    if (global_hideAll_PART_TYPE_Headings &&
      (global_hideAll_PART_TYPE_Headings == true || global_hideAll_PART_TYPE_Headings.toLowerCase() == 'true')) {
      return false;
    }

    if (documentPart.hideHeading &&
      (documentPart.hideHeading == true || documentPart.hideHeading.toLowerCase() == 'true')) {
      return false;
    }


    return true;
    // if (recipe.params) {

    //   let paramNames = Object.keys(recipe.params);
    //   paramNames.forEach((param) => {
    //     //////console.log(param);

    //     let paramConfig = recipe.params[param];
    //     //////console.log(paramConfig);
    //     let paramValue = paramConfig.value;
    //   })
    // }
  }



  lowerCaseMapping = {

  }

  createLowerCaseMapping(recipe) {
    try {
      Object.keys(recipe.params).forEach((key) => {

        this.lowerCaseMapping[key.toLowerCase()] = key

      });
    } catch (err) {
      console.log(err);
    }
  }

  getRecipeParam(recipe, paramId) {
    let actualParamId = this.lowerCaseMapping[paramId.toLowerCase()];

    let recipeParam = recipe.params[actualParamId];

    if (recipeParam && recipeParam.newName) {
      recipeParam = recipe.params[recipeParam.newName];
    }

    return recipeParam;

  }


  createImageFileEntry({ resources, source, s3Key, resourceKey, paramValueHoldsSource }) {

    if (!s3Key || !s3Key.split) {
      return;
    }

    try{

      let fileNames = s3Key.split('/');
      let fileName = fileNames[fileNames.length - 1];
  
  
      resources[s3Key] = {
  
        "imageFiles": [
          {
            "fileName": fileName,
            "s3Key": s3Key,
            "source": source,
            "paramValueHoldsSource": paramValueHoldsSource
          }
        ]
      }
      
    }catch(err){
      console.log(err);
    }
   




  }

  getAllResources({ recipeJson, inflatedTemplates, allBooks, bookDraft }, callback) {

    let requiredResources = this.extractBookDraftRequiredResources(
      bookDraft.book,
      recipeJson,       //bookDraft.book.recipe.json,
      bookDraft.bookDraftDocumentParts.map((bddp) => { return bddp.documentPart }),
      inflatedTemplates,
      allBooks);







    // let documentParts = bookDraft.bookDraftDocumentParts.map(m => { return m.documentPart });
    // let requiredResources = this.extractRecipeRequiredResources(
    //   bookDraft.book,
    //   recipeJson,
    //   documentParts,
    //   inflatedTemplates,
    //   allBooks,
    //   callback);


    let mappedImages = this.mapRequiredResources(requiredResources);
    callback(mappedImages);

    return;





  }    

  extractRecipePropsResources(book, recipe, documentParts){

    let allValues = [];
    let recipeUtils = new RecipeUtils();

    let imageParamPaths = [
      'HEADING.TITLE.IMAGE.SRC',
      'HEADING.IMAGE.SRC',
      'CONTENT.BLOCKQUOTE.SYMBOL.SRC'
    ]


    imageParamPaths.forEach((paramPath)=>{

      let values = recipeUtils.getAllRecipeParamValues(recipe, paramPath);

      allValues = allValues.concat(Object.keys(values));
      //console.log(values);
    })

    let results = [];
    allValues.forEach((value)=>{

      if(value==null || value=='null'){
        return;
      }
      let fileName = value;
      let fileSource = null;
      let paramValueHoldsSource = false;
          try {
            let splitParam = value.split('&&&');
            if (splitParam.length == 2) {
              fileName = splitParam[0];
              fileSource = splitParam[1];
              paramValueHoldsSource = true;
            }
          } catch (err) {
            //console.log(err);
          }

        

        this.createImageFileEntry({
          resources:results,
          //source:"commonImages",
          paramValueHoldsSource,
          source: fileSource,
          resourceKey: value,
          s3Key: fileName
        });

    })
    return results;
    
  }

  extractBookDraftRequiredResources(book, recipe, documentParts, inflatedTemplates, allBooks, callback) {

    let allResources = {};
    this.recipe = recipe;
    this.createLowerCaseMapping(recipe);

    if (!IS_SERVER_SIDE) {

      allBooks.forEach((book) => {

        let bookRequiredResources = this.extractBookRequiredResources(book, documentParts);
        Object.keys(bookRequiredResources).forEach((key) => {

          //allResources[key] = bookRequiredResources[key];
        })

      })

    }


    let recipePropsResources = this.extractRecipePropsResources(book, recipe,documentParts);

    let bookRequiredResources = this.extractBookRequiredResources(book, documentParts);

    let documentPartRequiredResources = this.extractDocumentPartsRequiredResources(book, documentParts);

    let documentPartUserAddedImages = [];
    this.extractDocumentPartUserAddedImages(book, documentPartUserAddedImages, documentParts, recipe, allBooks)

    let recipeRequiredResourceItems = {};
    
    if(recipe){

      recipeRequiredResourceItems = this.extractRecipeRequiredResources(book, recipe, documentParts, inflatedTemplates, allBooks);

    }
   
    
    Object.keys(recipePropsResources).forEach((key) => {

      allResources[key] = recipePropsResources[key];
    })

    Object.keys(bookRequiredResources).forEach((key) => {

      allResources[key] = bookRequiredResources[key];
    })

    Object.keys(documentPartRequiredResources).forEach((key) => {

      allResources[key] = documentPartRequiredResources[key];
    })

    Object.keys(recipeRequiredResourceItems).forEach((key) => {

      allResources[key] = recipeRequiredResourceItems[key];
    })

    documentPartUserAddedImages.forEach((imagesConfig) => {
      //console.log(imagesConfig);
      imagesConfig.imageFiles.forEach((imageConfig) => {

        allResources[imageConfig.s3Key] = {
          imageFiles: [
            imageConfig
          ]
        }

      })

    })


    //console.log(allResources);

    //mergeResources(ar1, ar2)


    //let requiredResources = new ResourceExtractor().mergeResources(bookRequiredResources[0], documentPartRequiredResources[0]);


    let mergedResourceItem = {

    }
    let mergedImageFiles = {};
    let mergedFontFiles = {};
    let mergedCssBlocks = {};


    Object.values(allResources).forEach((resourceItem) => {

      if (resourceItem.imageFiles) {
        resourceItem.imageFiles.forEach((imageFile) => {
          mergedImageFiles[imageFile.fileName] = imageFile;
        });
      }

      if (resourceItem.fontFiles) {
        resourceItem.fontFiles.forEach((fontFile) => {
          mergedFontFiles[fontFile.fileName] = fontFile;
        });
      }

      if (resourceItem.cssBlocks) {
        resourceItem.cssBlocks.forEach((cssBlock) => {
          mergedCssBlocks[cssBlock] = cssBlock;
        });
      }



    });
    mergedResourceItem.imageFiles = Object.values(mergedImageFiles)
    mergedResourceItem.fontFiles = Object.values(mergedFontFiles)
    mergedResourceItem.cssBlocks = Object.values(mergedCssBlocks)

    //console.log(mergedResourceItem);

    // this.getS3Urls([mergedResourceItem], (mappedImages) => {
    //   ////console.log('done...');
    //   mappedImages['missingCoverImage'] = '/epubPreviewer/OEBPS/images/cover-image-not-found.png';
    //   mappedImages['missingImage'] = '/epubPreviewer/OEBPS/images/image-not-found.png';
    //   if (callback) {

    //     callback(mappedImages);
    //   }
    // })


    if (callback) {

      let mappedImages = this.mapRequiredResources([mergedResourceItem]);
      callback(mappedImages);

    }





    return [mergedResourceItem];




  }


  extractFileName(s) {

    let parts = s.split('/');

    //console.log(parts);
    return parts[parts.length - 1];

  }

  extractBookRequiredResources(book, recipe, documentParts, inflatedTemplates, allBooks, callback) {


    if (!recipe) {
      //return [];
    }

    let resources = {};
    let resourceItems = [];

    // if (book.coverUrl) {
    //   resources[book.coverUrl] = {

    //     "imageFiles": [
    //       {
    //         //"fileName":documentPart.headingUrl,
    //         "fileName": this.extractFileName(book.coverUrl),
    //         "s3Key": book.coverUrl,
    //         "mediaType": this.cleanMediaType(book.coverUrlType),
    //         //"cognitoId": book.owner.cognitoId,
    //         //"ownerId": book.ownerId,
    //         "source": "userImages"
    //       }
    //     ]
    //   }
    // }


    if (book.coverUrl) {

      let s3Key = book.coverUrl;
      let paramValueHoldsSource = false;
      let source = '';
      try {
        let split = s3Key.split('&&&');
        if (split.length > 1) {
          s3Key = split[0];
          paramValueHoldsSource = true;
          source = split[1];
        }
      } catch (err) {

      }


      resources[book.coverUrl] = {

        "imageFiles": [
          {
            "s3Key": s3Key,
            "fileName": s3Key,
            "mediaType": this.cleanMediaType(book.coverUrlType),
            //"cognitoId": documentPart.cognitoId,
            //"ownerId": documentPart.ownerId,
            "source": source,
            paramValueHoldsSource: paramValueHoldsSource
          }
        ]
      }
    }
    else {
      resources['MISSING_COVER_URL'] = {

        "imageFiles": [
          {
            "folder": "",
            "s3Key": 'epubPreviewer/OEBPS/images/cover-image-not-found.png',
            "fileName": 'cover-image-not-found.png',
            "mediaType": this.cleanMediaType('image/png'),
            "source": "commonImages"
          }
        ]
      }
    }






    if (book.coverThumbnailUrl) {
      resources[book.coverThumbnailUrl] = {

        "imageFiles": [
          {
            "fileName": this.extractFileName(book.coverThumbnailUrl),
            "s3Key": book.coverThumbnailUrl,
            "mediaType": this.cleanMediaType(book.coverThumbnailUrlType),
            //"cognitoId": book.owner.cognitoId,
            //"ownerId": book.ownerId,
            "source": "userImages"
          }
        ]
      }
    }
    else {
      resources['MISSING_COVER_URL'] = {

        "imageFiles": [
          {
            "folder": "",
            "s3Key": 'epubPreviewer/OEBPS/images/cover-image-not-found.png',
            "fileName": 'cover-image-not-found.png',
            "mediaType": this.cleanMediaType('image/png'),
            "source": "commonImages"
          }
        ]
      }
    }


    //Extract boxSet included books cover and coverThumbnail images. 
    //Note that server-side, these are extracted looping through each book and doing a complete resource extraction
    //( see prepareContentClean.js ). 
    // Since all inserted bddps are already processed with the boxsets content clientside, we just need the inserted books 
    //cover and thumbnails here. 

    if (book.boxSet) {

      book.boxSet.boxSetBooks.forEach((bsb) => {

        if (bsb.book.coverUrl) {
          resources[bsb.book.coverUrl] = {

            "imageFiles": [
              {
                //"fileName":documentPart.headingUrl,
                "fileName": this.extractFileName(bsb.book.coverUrl),
                "s3Key": bsb.book.coverUrl,
                //"mediaType": this.cleanMediaType(book.coverUrlType),
                //"cognitoId": bsb.book.owner.cognitoId,
                //"ownerId": book.ownerId,
                "source": "userImages"
              }
            ]
          }
        }
        else {
          resources['MISSING_COVER_URL'] = {

            "imageFiles": [
              {
                "folder": "",
                "s3Key": 'epubPreviewer/OEBPS/images/cover-image-not-found.png',
                "fileName": 'cover-image-not-found.png',
                "mediaType": this.cleanMediaType('image/png'),
                "source": "commonImages"
              }
            ]
          }
        }






        if (bsb.book.coverThumbnailUrl) {
          resources[bsb.book.coverThumbnailUrl] = {

            "imageFiles": [
              {
                //"fileName":documentPart.headingUrl,
                "fileName": bsb.book.coverThumbnailUrl,
                "s3Key": bsb.book.coverThumbnailUrl,
                //"mediaType": this.cleanMediaType(book.coverThumbnailUrlType),
                //"cognitoId": book.owner.cognitoId,
                //"ownerId": book.ownerId,
                "source": "userImages"
              }
            ]
          }
        }
        else {
          resources['MISSING_COVER_URL'] = {

            "imageFiles": [
              {
                "folder": "",
                "s3Key": 'epubPreviewer/OEBPS/images/cover-image-not-found.png',
                "fileName": 'cover-image-not-found.png',
                "mediaType": this.cleanMediaType('image/png'),
                "source": "commonImages"
              }
            ]
          }
        }






      })

    }

    return resources;

  }

  extractRecipeRequiredResources(book, recipe, documentParts, inflatedTemplates, allBooks, callback) {

    //console.log('extractRecipeRequiredResources()...');
    if (!recipe) {
      //return [];
    }



    let resources = {};
    let resourceItems = [];

    /*resources['MISSING_COVER_URL'] = {

        "imageFiles":[
          {
           //"fileName":documentPart.headingUrl,
           "fileName":'missingCoverImage.jpg',
           "mediaType":cleanMediaType('image/jpeg'),
           "source":"commonImages"
         }
        ]
      }*/















    // partTypes.forEach((partType)=>{

    //   let headingThumbnailImageId = 'global_'+partType+'_headingTitleImageSrc';
    //   let headingThumbnailImageMediaType = 'global_'+partType+'_headingTitleImageMediaType'

    //   let headingThumbnailImageParam = recipe.params[headingThumbnailImageId];
    //   let headingThumbnailImageMediaTypeParam = recipe.params[headingThumbnailImageMediaType];

    //   if (headingThumbnailImageParam) {

    //     //let decoratedBreakImageResource = this.extractDecoratedBreakImageResource();
    //     resources[headingThumbnailImageParam.value] = {

    //       "imageFiles": [
    //         {
    //           //"fileName":documentPart.headingUrl,
    //           "fileName": headingThumbnailImageParam.value,
    //           "mediaType": headingThumbnailImageMediaTypeParam.value,
    //           "source": "commonImages",
    //           "folder": "headerThumbnails",
    //           "level": "public"
    //         }
    //       ]
    //     }
    //   }







    //   let global_partType_headingImageSrc = recipe.params['global_'+partType+'_headingImageSrc'];
    //   let global_partType_headingImageMediaType = recipe.params['global_'+partType+'_headingImageMediaType'];
    //   if (global_partType_headingImageSrc) {

    //     //let decoratedBreakImageResource = this.extractDecoratedBreakImageResource();
    //     resources[global_partType_headingImageSrc.value] = {

    //       "imageFiles": [
    //         {
    //           //"fileName":documentPart.headingUrl,
    //           "fileName": global_partType_headingImageSrc.value,
    //           "mediaType": global_partType_headingImageMediaType.value,
    //           "source": "userImages",     
    //           "folder": "headerThumbnails",
    //           "level": "public"
    //         }
    //       ]
    //     }
    //   }


    // })









    //Need to add a check for all publisher bots to find if even oene of them reference require this image. 
    // if (book.publisherLogoImageSrc) {
    //   resources[book.publisherLogoImageSrc] = {

    //     "imageFiles": [
    //       {
    //         //"fileName":documentPart.headingUrl,
    //         "fileName": book.publisherLogoImageSrc,
    //         "mediaType": this.cleanMediaType(book.publisherLogoImageType),
    //         "cognitoId": book.owner.cognitoId,
    //         "ownerId": book.ownerId,
    //         "source": "userImages"
    //       }
    //     ]
    //   }
    // }
    // if (book.commonAllPartsHeaderUrl) {
    //   resources[book.commonAllPartsHeaderUrl] = {

    //     "imageFiles": [
    //       {
    //         //"fileName":documentPart.headingUrl,
    //         "fileName": book.commonAllPartsHeaderUrl,
    //         "mediaType": this.cleanMediaType(book.commonAllPartsHeaderImageType),
    //         "cognitoId": book.owner.cognitoId,
    //         "ownerId": book.ownerId,
    //         "source": "userImages"
    //       }
    //     ]
    //   }
    // }
    // if (book.commonChapterHeaderUrl) {
    //   resources[book.commonChapterHeaderUrl] = {

    //     "imageFiles": [
    //       {
    //         //"fileName":documentPart.headingUrl,
    //         "fileName": book.commonChapterHeaderUrl,
    //         "mediaType": this.cleanMediaType(book.commonChapterHeaderImageType),
    //         "cognitoId": book.owner.cognitoId,
    //         "ownerId": book.ownerId,
    //         "source": "userImages"
    //       }
    //     ]
    //   }
    // }

    if (recipe && recipe.params && inflatedTemplates) {

      let paramNames = Object.keys(recipe.params);



      //Come back to this later. May be a useful feature to add back in. 
      // paramNames.forEach((param) => {

      //   let paramConfig = recipe.params[param];
      //   let paramValue = paramConfig.value;

      //   let inflatedTemplate = inflatedTemplates[paramValue];
      //   if (inflatedTemplate) {
      //     if (inflatedTemplate.requiredResources) {
      //       //////console.log(inflatedTemplate.requiredResources);

      //       let resourceNames = Object.keys(inflatedTemplate.requiredResources);
      //       resourceNames.forEach((resourceName) => {
      //         resources[resourceName] = inflatedTemplate.requiredResources[resourceName];
      //       });



      //     }

      //   }

      // });



      let global_blockquote_symbolParam = recipe.params['global_blockquote_symbol'];
      //let ornamentalBreakImageId = recipe.params['global_ornamentalBreakImageId'];

      //console.log('global_blockquote_symbolParam:' + global_blockquote_symbolParam);
      ////console.log('ornamentalBreakImageId:'+ornamentalBreakImageId.value);

      if (global_blockquote_symbolParam && global_blockquote_symbolParam.value) {

        //let decoratedBreakImageResource = this.extractDecoratedBreakImageResource();
        let fileNames = global_blockquote_symbolParam.value.split('/');
        let fileName = fileNames[fileNames.length - 1];


        resources[global_blockquote_symbolParam.value] = {

          "imageFiles": [
            {
              "fileName": fileName,
              "s3Key": global_blockquote_symbolParam.value,
              //"mediaType":cleanMediaType(book.commonAllPartsHeaderImageType),
              "source": "commonImages",
              "folder": "blockQuotes",
              "level": "public"
            }
          ]
        }
      }






      let requiresDecoratedBreakImage = recipe.params['global_showOrnamentalBreaks'];
      let ornamentalBreakImageId = recipe.params['global_ornamentalBreakImageId'];

      //console.log('global_showOrnamentalBreaks:' + requiresDecoratedBreakImage);
      ////console.log('ornamentalBreakImageId:'+ornamentalBreakImageId.value);

      if (requiresDecoratedBreakImage && requiresDecoratedBreakImage.value == true && ornamentalBreakImageId) {

        this.createImageFileEntry({
          resources,
          source: "commonImages",
          s3Key: ornamentalBreakImageId.value
        });


      }


      /**************************************************************************
       * Replaced in params processing below...
       * 
            let common_headingThumbnailImageId = recipe.params['global_common_headingTitleImageSrc'];
            let common_headingThumbnailImageMediaType = recipe.params['global_common_headingTitleImageMediaType'];
            if (common_headingThumbnailImageId) {
      
              let fileName = common_headingThumbnailImageId.value;
              let fileSource = "commonImages";
              if (common_headingThumbnailImageId.value) {
      
                try {
                  let splitParam = common_headingThumbnailImageId.value.split('&&&');
                  if (splitParam.length == 2) {
                    fileName = splitParam[0];
                    fileSource = splitParam[1];
                  }
                } catch (err) {
                  console.log(err);
                }
      
              }
      
              this.createImageFileEntry({
                resources,
                //source:"commonImages",
                paramValueHoldsSource: true,
                source: fileSource,
                resourceKey: common_headingThumbnailImageId.value,
                s3Key: fileName
              });
      
      
      
            }
      
      
      
      
      
            let global_common_headingImageSrc = recipe.params['global_common_headingImageSrc'];
            let global_common_headingImageMediaType = recipe.params['global_common_headingImageMediaType'];
            if (global_common_headingImageSrc) {
      
      
              this.createImageFileEntry({
                resources,
                source: "userImages",
                s3Key: global_common_headingImageSrc.value
              });
      
      
      
      
            }
      
      
      */






















      //////console.log(resources);
      //process.exit();

      //process the resources that are declared within the param config.

      const processImage = ({
        paramConfig,
        fileSource,
        paramValueHoldsSource = false
      }) => {
        let fileName = paramConfig.value;
        //let fileSource = "commonImages";
        if (paramConfig.value) {

          try {
            let splitParam = paramConfig.value.split('&&&');
            if (splitParam.length == 2) {
              fileName = splitParam[0];
              fileSource = splitParam[1];
            }
          } catch (err) {
            //console.log(err);
          }

        }

        this.createImageFileEntry({
          resources,
          //source:"commonImages",
          paramValueHoldsSource,
          source: fileSource,
          resourceKey: paramConfig.value,
          s3Key: fileName
        });
      }

      paramNames.forEach((param) => {
        //console.log(param);





        /**************************************************************************************************** */

        if (param.includes('_headingTitleImageSrc')) {

          //console.log('includes:' + param);

          let paramConfig = recipe.params[param];

          //let common_headingThumbnailImageId = recipe.params['global_common_headingTitleImageSrc'];
          if (paramConfig) {

            processImage({
              paramConfig,
              fileSource: "commonImages",
              paramValueHoldsSource: true
            })



          }


        }



        if (param.includes('_headingImageSrc')) {

          //console.log('includes:' + param);

          let paramConfig = recipe.params[param];

          //let common_headingThumbnailImageId = recipe.params['global_common_headingTitleImageSrc'];
          if (paramConfig) {

            processImage({
              paramConfig,
              fileSource: "userImages"
            })



          }


        }

        if (param.includes('_headingBackgroundImageSrc')) {

          //console.log('includes:' + param);

          let paramConfig = recipe.params[param];

          //let common_headingThumbnailImageId = recipe.params['global_common_headingTitleImageSrc'];
          if (paramConfig) {

            processImage({
              paramConfig,
              fileSource: "userImages"
            })



          }


        }




        /**************************************************************************************************** */
        let paramConfig = recipe.params[param];
        //////console.log(paramConfig);
        let paramValue = paramConfig.value;

        let newParamConfig = null;
        if (paramConfig.newName) {
          newParamConfig = recipe.params[paramConfig.newName];
          if (newParamConfig) {
            paramValue = newParamConfig.value;
          }

        }


        if (paramConfig.resourcesMap) {

          if (paramConfig.resourcesMap[paramValue]) {
            //////console.log(param+':'+paramValue);
            //////console.log(paramConfig.resourcesMap[paramValue]);
            //found a required resource
            //////console.log(paramConfig.resourcesMap[paramValue]);


            let resourceNames = paramConfig.resourcesMap[paramValue];
            //////console.log('resourceNames:'+resourceNames);
            resourceNames.forEach((resourceName) => {
              if (this.requiredResources[resourceName]) {

                resources[resourceName] = this.requiredResources[resourceName];

              }

            });
            /*if(paramConfig.resourcesMap[paramValue]){
 
              paramConfig.resourcesMap[paramValue].forEach((resource)=>{
 
                resources[paramValue] = this.requiredResources[paramValue];
              })
            }*/

          }
        }




      })
    }

    //let documentPartRequiredResources = this.extractDocumentPartsRequiredResources(documentParts);

    //merge the documentPart resources
    // Object.keys(documentPartRequiredResources).forEach((key) => {

    //   resources[key] = documentPart.requiredResources[key];
    // })

    // //now convert this object ot an arary.
    // Object.keys(resources).forEach((key) => {
    //   resourceItems.push(resources[key]);
    // });

    this.extractDocumentPartUserAddedImages(book, resourceItems, documentParts, recipe, allBooks)
    //////console.log(resourceItems);
    //////console.log('resources:');

    /*resourceItems.forEach((resource)=>{
      ////console.log(resource);
    })*/

    //************************************************************************* */
    // let mergedResourceItem = {

    // }
    // let mergedImageFiles = {};
    // let mergedFontFiles = {};
    // let mergedCssBlocks = {};
    // resourceItems.forEach((resourceItem) => {

    //   if (resourceItem.imageFiles) {
    //     resourceItem.imageFiles.forEach((imageFile) => {
    //       mergedImageFiles[imageFile.fileName] = imageFile;
    //     });
    //   }

    //   if (resourceItem.fontFiles) {
    //     resourceItem.fontFiles.forEach((fontFile) => {
    //       mergedFontFiles[fontFile.fileName] = fontFile;
    //     });
    //   }

    //   if (resourceItem.cssBlocks) {
    //     resourceItem.cssBlocks.forEach((cssBlock) => {
    //       mergedCssBlocks[cssBlock] = cssBlock;
    //     });
    //   }



    // });
    // mergedResourceItem.imageFiles = Object.values(mergedImageFiles)
    // mergedResourceItem.fontFiles = Object.values(mergedFontFiles)
    // mergedResourceItem.cssBlocks = Object.values(mergedCssBlocks)
    //************************************************************************ */

    ////console.log('check to see if we already have these s3Urls');
    ////console.log(mergedResourceItem);
    ////console.log(book);

    /*if(book.mappedImages){
      let neededImageFiles = [];
      mergedResourceItem.imageFiles.forEach((imageFile)=>{

          if(book.mappedImages[imageFile.fileName]){
            neededImageFiles.push(imageFile);
          }
      });

      mergedResourceItem.imageFiles = neededImageFiles;
    }*/




    // this.getS3Urls([mergedResourceItem], (mappedImages) => {
    //   ////console.log('done...');
    //   mappedImages['missingCoverImage'] = '/epubPreviewer/OEBPS/images/cover-image-not-found.png';
    //   mappedImages['missingImage'] = '/epubPreviewer/OEBPS/images/image-not-found.png';
    //   if (callback) {

    //     callback(mappedImages);
    //   }
    // })


    //return [mergedResourceItem];
    //return resourceItems;

    return resources;
  }


  extractDocumentPartUserAddedImages(book, resourceItems, documentParts, recipe, allBooks) {

    let imageItems = [];


    if (documentParts) {

      documentParts.forEach((documentPart) => {


        //////console.log(documentPart);
        //////console.log('title:'+documentPart.title);
        //////console.log('headingUrl:'+documentPart.headingUrl);
        //////console.log('includeHeadingUrl:'+documentPart.includeHeadingUrl);


        //let headerVisible = this.isHeadingVisible(documentPart,recipe);
        try {
          if (documentPart.includeHeadingUrl &&

            (documentPart.includeHeadingUrl == true ||
              (documentPart.includeHeadingUrl.trim() != '' &&
                documentPart.includeHeadingUrl.toLowerCase() == 'true' &&

                (!documentPart.hideHeading ||
                  documentPart.hideHeading.toLowerCase() != 'true')))

            // && headerVisible
          ) {

            let headingInfo = this.getHeadingInfo(book, documentPart);


          }

        } catch (err) {
          //console.log(err);
        }






        //////console.log(documentPart);
        try {
          //let delta = JSON.parse(documentPart.delta);
          let delta = documentPart.composedDeltaOps;//composedDelta;

          //////console.log('############################################')
          //////console.log(delta);
          delta.ops.forEach((op) => {
            ////console.log(op);



            try {
              let alsobys = JSON.parse(op.insert.alsobys.alsobys);
              ////console.log(alsobys);

              alsobys.records.forEach((alsoby) => {
                ////console.log(alsoby);

                let src = null;
                let mediaType = null;
                let ownerId = null;
                let cognitoId = null;
                if (alsoby.item.bookId == null) {
                  src = alsoby.item.coverUrl;
                  mediaType = alsoby.item.coverUrlType;

                } else {
                  let book = allBooks.filter(f => f.id == alsoby.item.bookId)[0];
                  src = book.coverThumbnailUrl;
                  mediaType = book.coverThumbnailUrlType;
                  // ownerId = book.ownerId;
                  // cognitoId = book.owner.cognitoId;
                }


                // imageItems[src] = {

                //   "imageFiles": [
                //     {
                //       "fileName": src,
                //       "mediaType": mediaType,
                //       "cognitoId": cognitoId,
                //       "ownerId": ownerId,
                //       "source": "userImages"
                //     }
                //   ]
                // }


                this.createImageFileEntry({
                  resources: imageItems,
                  source: "userImages",
                  s3Key: src
                });








              });




            } catch (err) {

            }



            try {
              let src = JSON.parse(op.insert.publisher.publisher).url;
              let mediaType = JSON.parse(op.insert.publisher.publisher).mediaType;
              //let type = op.insert.image4.type;
              //////console.log('src:'+src);

              this.createImageFileEntry({
                resources: imageItems,
                source: "userImages",
                s3Key: src
              });



              // imageItems[src] = {

              //   "imageFiles": [
              //     {
              //       "fileName": src,
              //       "mediaType": mediaType,
              //       "cognitoId": book.owner.cognitoId,
              //       "ownerId": book.ownerId,
              //       "source": "userImages"
              //     }
              //   ]
              // }


            } catch (err) {

            }





            try {
              let src = op.insert.image4.src;
              let type = op.insert.image4.type;
              let fileNames = src.split('/');
              let fileName = fileNames[fileNames.length - 1];
              //////console.log('src:'+src);
              imageItems[src] = {

                "imageFiles": [
                  {
                    "fileName": fileName,
                    "s3Key": src,
                    "mediaType": type,
                    "cognitoId": documentPart.cognitoId,
                    "ownerId": documentPart.ownerId,
                    "source": "userImages"
                  }
                ]
              }


            } catch (err) {

            }

          })
        } catch (err) {
          //////console.log(err);
        }
      })

      Object.keys(imageItems).forEach((key) => {
        resourceItems.push(imageItems[key])
      });

    }

  }




  extractDocumentPartsRequiredResources(book, documentParts) {
    let resources = {};


    if (documentParts) {

      documentParts.forEach((documentPart) => {


        let headingVisible = this.isHeadingVisible(documentPart);

        headingVisible = true;
        if (headingVisible) {
          let headingInfo = this.getHeadingInfo(book, documentPart);
          Object.keys(headingInfo).forEach((key) => {
            resources[key] = headingInfo[key];
          })
          //console.log(headingInfo);
        }



        if (documentPart && documentPart.partType == 'FULLPAGEIMAGE') {



          if (documentPart.headingUrl) {

            resources[documentPart.headingUrl] = {

              "imageFiles": [
                {
                  //"fileName":documentPart.headingUrl,
                  "fileName": this.extractFileName(documentPart.headingUrl),
                  "s3Key": documentPart.headingUrl,
                  //"mediaType": this.cleanMediaType(documentPart.titleImageType),
                  //"cognitoId": documentPart.cognitoId,
                  //"ownerId": documentPart.ownerId,
                  "source": "userImages"
                }
              ]
            }


          } else {
            resources['MISSING_COVER_URL'] = {

              "imageFiles": [
                {
                  "folder": "",
                  "s3Key": 'epubPreviewer/OEBPS/images/cover-image-not-found.png',
                  "fileName": 'cover-image-not-found.png',
                  "mediaType": this.cleanMediaType('image/png'),
                  "source": "commonImages"
                }
              ]
            }
          }



        }

        if (documentPart && documentPart.includePinterest) {
          if (this.requiredResources['pinterestThumbnail']) {
            resources['pinterestThumbnail'] = this.requiredResources['pinterestThumbnail'];
          }

        }
        if (documentPart && documentPart.includeFacebook) {
          if (this.requiredResources['facebookThumbnail']) {
            resources['facebookThumbnail'] = this.requiredResources['facebookThumbnail'];
          }

        }
        if (documentPart && documentPart.includeGoodreads) {
          if (this.requiredResources['goodreadsThumbnail']) {
            resources['goodreadsThumbnail'] = this.requiredResources['goodreadsThumbnail'];
          }

        }
        if (documentPart && documentPart.includeTwitter) {
          if (this.requiredResources['twitterThumbnail']) {
            resources['twitterThumbnail'] = this.requiredResources['twitterThumbnail'];
          }

        }
        if (documentPart && documentPart.includeSnapchat) {
          if (this.requiredResources['snapchatThumbnail']) {
            resources['snapchatThumbnail'] = this.requiredResources['snapchatThumbnail'];
          }

        }
        if (documentPart && documentPart.includeInstagram) {
          if (this.requiredResources['instagramThumbnail']) {
            resources['instagramThumbnail'] = this.requiredResources['instagramThumbnail'];
          }

        }
        if (documentPart && documentPart.includeBookbub) {
          if (this.requiredResources['bookbubThumbnail']) {
            resources['bookbubThumbnail'] = this.requiredResources['bookbubThumbnail'];
          }

        }
        if (documentPart && documentPart.includeAmazon) {
          if (this.requiredResources['amazonThumbnail']) {
            resources['amazonThumbnail'] = this.requiredResources['amazonThumbnail'];
          }

        }
        if (documentPart && documentPart.includeYoutube) {
          if (this.requiredResources['youtubeThumbnail']) {
            resources['youtubeThumbnail'] = this.requiredResources['youtubeThumbnail'];
          }

        }

        /*if(documentPart && documentPart.includePinterest && documentPart.includePinterest.toLowerCase().trim()=='true'){
          if(this.requiredResources['pinterestThumbnail']){
            resources['pinterestThumbnail'] = this.requiredResources['pinterestThumbnail'];
          }

        }
        if(documentPart && documentPart.includeFacebook && documentPart.includeFacebook.toLowerCase().trim()=='true'){
          if(this.requiredResources['facebookThumbnail']){
            resources['facebookThumbnail'] = this.requiredResources['facebookThumbnail'];
          }

        }
        if(documentPart && documentPart.includeGoodreads && documentPart.includeGoodreads.toLowerCase().trim()=='true'){
          if(this.requiredResources['goodreadsThumbnail']){
            resources['goodreadsThumbnail'] = this.requiredResources['goodreadsThumbnail'];
          }

        }
        if(documentPart && documentPart.includeTwitter && documentPart.includeTwitter.toLowerCase().trim()=='true'){
          if(this.requiredResources['twitterThumbnail']){
            resources['twitterThumbnail'] = this.requiredResources['twitterThumbnail'];
          }

        }
        if(documentPart && documentPart.includeSnapchat && documentPart.includeSnapchat.toLowerCase().trim()=='true'){
          if(this.requiredResources['snapchatThumbnail']){
            resources['snapchatThumbnail'] = this.requiredResources['snapchatThumbnail'];
          }

        }
        if(documentPart && documentPart.includeInstagram && documentPart.includeInstagram.toLowerCase().trim()=='true'){
          if(this.requiredResources['instagramThumbnail']){
            resources['instagramThumbnail'] = this.requiredResources['instagramThumbnail'];
          }

        }
        if(documentPart && documentPart.includeBookbub && documentPart.includeBookbub.toLowerCase().trim()=='true'){
          if(this.requiredResources['bookbubThumbnail']){
            resources['bookbubThumbnail'] = this.requiredResources['bookbubThumbnail'];
          }

        }
        if(documentPart && documentPart.includeAmazon && documentPart.includeAmazon.toLowerCase().trim()=='true'){
          if(this.requiredResources['amazonThumbnail']){
            resources['amazonThumbnail'] = this.requiredResources['amazonThumbnail'];
          }

        }
        if(documentPart && documentPart.includeYoutube && documentPart.includeYoutube.toLowerCase().trim()=='true'){
          if(this.requiredResources['youtubeThumbnail']){
            resources['youtubeThumbnail'] = this.requiredResources['youtubeThumbnail'];
          }

        }*/



      })

      //process.exit();
    }

    //////console.log('documentPart required resources:');
    //////console.log(resources);
    return resources;
  }

  getHeadingInfo(book, documentPart, recipe) {

    let resources = {};
    //console.log('getHeadingInfo()...');
    let headingInfo = {};
    if (documentPart) {

      if (documentPart.headingBackgroundUrl) {

        let s3Key = documentPart.headingBackgroundUrl;
        try {
          let split = s3Key.split('&&&');
          if (split.length > 1) {
            s3Key = split[0];
          }
        } catch (err) {

        }

        resources[documentPart.headingBackgroundUrl] = {

          "imageFiles": [
            {
              //"fileName":documentPart.headingUrl,
              "s3Key": s3Key,
              "fileName": documentPart.headingBackgroundUrl,
              "mediaType": 'NA',//this.cleanMediaType(documentPart.headerImageType),
              "cognitoId": documentPart.cognitoId,
              "ownerId": documentPart.ownerId,
              "source": "userImages"
            }
          ]
        }
      }


      if (documentPart.headingUrl) {

        let s3Key = documentPart.headingUrl;
        try {
          let split = s3Key.split('&&&');
          if (split.length > 1) {
            s3Key = split[0];
          }
        } catch (err) {

        }

        resources[documentPart.headingUrl] = {


          "imageFiles": [
            {
              //"fileName":documentPart.headingUrl,
              "s3Key": s3Key,
              "fileName": documentPart.headingUrl,
              "mediaType": this.cleanMediaType(documentPart.headerImageType),
              "cognitoId": documentPart.cognitoId,
              "ownerId": documentPart.ownerId,
              "source": "userImages"
            }
          ]
        }
      }


      if (documentPart.titleImageUrl) {

        let s3Key = documentPart.titleImageUrl;
        let paramValueHoldsSource = false;
        let source = '';
        try {
          let split = s3Key.split('&&&');
          if (split.length > 1) {
            s3Key = split[0];
            paramValueHoldsSource = true;
            source = split[1];
          }
        } catch (err) {

        }


        resources[documentPart.titleImageUrl] = {

          "imageFiles": [
            {
              "s3Key": s3Key,
              "fileName": documentPart.titleImageUrl,
              "mediaType": this.cleanMediaType(documentPart.titleImageType),
              "cognitoId": documentPart.cognitoId,
              "ownerId": documentPart.ownerId,
              "source": source,
              paramValueHoldsSource: paramValueHoldsSource
            }
          ]
        }
      }

    }

    return resources;

  }


  getHeadingInfoOld(book, documentPart) {


    //console.log('getHeadingInfo()...');
    let headingInfo = {};
    if (documentPart) {

      //Make this more conditional later.
      try {
        if (book.commonAllPartsHeaderUrl && book.commonAllPartsHeaderUrl != '') {
          headingInfo.headingUrl = book.commonAllPartsHeaderUrl;
          headingInfo.headerImageType = book.commonAllPartsHeaderImageType;
        }
      } catch (err) {
        ////console.log(err);
      }

      // try {
      //   if (documentPart.titleImageUrl && documentPart.titleImageUrl != '') {
      //     headingInfo.titleImageUrl = documentPart.titleImageUrl;
      //     headingInfo.titleImageType = documentPart.titleImageType;
      //   }
      // } catch (err) {
      //   ////console.log(err);
      // }





      if (documentPart.partType && documentPart.partType.toLowerCase() == 'volume') {

        if (documentPart.overrideCommonVolumeHeadingUrl && documentPart.overrideCommonVolumeHeadingUrl.toLowerCase() == 'true') {

          headingInfo.headingUrl = documentPart.headingUrl;
          headingInfo.headerImageType = documentPart.headerImageType;


        } else {

          //headingUrl =  book.commonVolumeHeaderUrl;
          if (book.commonVolumeHeaderUrl && book.commonVolumeHeaderUrl != '') {
            headingInfo.headingUrl = book.commonVolumeHeaderUrl;
            headingInfo.headerImageType = book.commonVolumeHeaderImageType;
          }




        }

      }

      if (documentPart.partType && documentPart.partType.toLowerCase() == 'part') {

        if (documentPart.overrideCommonPartHeadingUrl && documentPart.overrideCommonPartHeadingUrl.toLowerCase() == 'true') {

          headingInfo.headingUrl = documentPart.headingUrl;
          headingInfo.headerImageType = documentPart.headerImageType;

        } else {

          //headingUrl =  book.commonPartHeaderUrl;
          headingInfo.headingUrl = book.commonPartHeaderUrl;
          headingInfo.headerImageType = book.commonPartHeaderImageType;
        }

      }

      if (documentPart.partType && documentPart.partType.toLowerCase() == 'chapter') {

        if (documentPart.overrideCommonChapterHeadingUrl && documentPart.overrideCommonChapterHeadingUrl.toLowerCase() == 'true') {

          headingInfo.headingUrl = documentPart.headingUrl;
          headingInfo.headerImageType = documentPart.headerImageType;

        } else {

          //headingUrl =  book.commonChapterHeaderUrl;
          headingInfo.headingUrl = book.commonChapterHeaderUrl;
          headingInfo.headerImageType = book.commonChapterHeaderImageType;
        }

      }




    }

    /*if(headingUrl && headingUrl!='' && headingUrl.toLowerCase()!='null'){
      return headingInfo;
    }*/
    return headingInfo;
  }



  mapRequiredResources(requiredResources) {

    //console.log('mapRequiredResources()...');
    let mappedImages = {}
    requiredResources.forEach((m) => {

      m.imageFiles.forEach((imageConfig) => {
        ////console.log(imageConfig.source+":"+imageConfig.fileName);
        ////console.log(imageConfig);
        let mappedKey = imageConfig.s3Key;
        if (imageConfig.paramValueHoldsSource) {
          mappedKey = imageConfig.s3Key + "&&&" + imageConfig.source;
        }
        switch (imageConfig.source) {

          case "userImages":
            mappedImages[mappedKey] = 'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + imageConfig.s3Key;
            mappedImages[imageConfig.fileName] = 'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + imageConfig.s3Key;
            break;

          case "commonImages":

            //mappedImages[imageConfig.fileName] = 'https://'+config.common_content_bucket_name+".s3.amazonaws.com/public/"+imageConfig.folder+"/"+imageConfig.fileName;
            mappedImages[mappedKey] = 'https://' + config.common_content_bucket_name + ".s3.amazonaws.com/" + imageConfig.s3Key;
            mappedImages[imageConfig.fileName] = 'https://' + config.common_content_bucket_name + ".s3.amazonaws.com/" + imageConfig.s3Key;

            break;


            return "missing"

          default:

            break;
        }

      });

    })


    return mappedImages;
    /*
    cognitoId: "us-east-1:0e1e5b7f-dd52-40fd-bfe9-a132b4bd3984"
    fileName: "folder1/41gr3r3FSWL.jpg"
    mediaType: null
    ownerId: undefined
    source: "userImages"
    */

  }






  mergeResources(ar1, ar2) {
    let resourceItems = [{
      imageFiles: ar1.imageFiles.concat(ar2.imageFiles),
      fontFiles: ar1.fontFiles.concat(ar2.fontFiles),
      cssBlocks: ar1.cssBlocks.concat(ar2.cssBlocks)
    }];


    let mergedResourceItem = {

    }
    let mergedImageFiles = {};
    let mergedFontFiles = {};
    let mergedCssBlocks = {};
    resourceItems.forEach((resourceItem) => {

      if (resourceItem.imageFiles) {
        resourceItem.imageFiles.forEach((imageFile) => {
          mergedImageFiles[imageFile.fileName] = imageFile;
        });
      }

      if (resourceItem.fontFiles) {
        resourceItem.fontFiles.forEach((fontFile) => {
          mergedFontFiles[fontFile.fileName] = fontFile;
        });
      }

      if (resourceItem.cssBlocks) {
        resourceItem.cssBlocks.forEach((cssBlock) => {
          mergedCssBlocks[cssBlock] = cssBlock;
        });
      }



    });

    mergedResourceItem.imageFiles = Object.values(mergedImageFiles)
    mergedResourceItem.fontFiles = Object.values(mergedFontFiles)
    mergedResourceItem.cssBlocks = Object.values(mergedCssBlocks)
    return [mergedResourceItem];


  }

  getS3Urls(resources, callback) {

    //console.log('getS3Urls()...');


    //let userImageResources = resources.imageFiles.filter(f=>f.source=='userImages');
    //let imageKeys = resources.map(m=>{return m.imageFiles.map((imageFile)=>{ return imageFile.fileName })})[0];
    let images = resources.map(m => { return m.imageFiles.map((imageFile) => { return imageFile }) })[0];
    let mappedImages = {};

    let s3Promises = images.map((image) => {

      let imageKey = image.fileName;
      let folderName = 'uploads/';
      let level = 'protected';

      if (image && image.level) {
        level = image.level;
      }
      if (image && image.folder) {
        folderName = image.folder + "/";
      }
      return new Promise((resolve, reject) => {
        Storage.get(image.s3Key, { level: level, identityId: image.cognitoId })
          .then(result => {
            ////console.log(result);
            mappedImages[imageKey] = result;
            resolve(result);
          })
          .catch(err => {
            ////console.log(err);
            reject(err);
          });
      })


    });


    Promise.all(s3Promises)
      .then((pres) => {
        ////console.log(mappedImages);
        callback(mappedImages);
      })
      .catch(err => {
        console.log(err);
      });
  }




  getS3UrlsOld(resources, callback) {

    //console.log('getS3Urls()...');


    //let userImageResources = resources.imageFiles.filter(f=>f.source=='userImages');
    //let imageKeys = resources.map(m=>{return m.imageFiles.map((imageFile)=>{ return imageFile.fileName })})[0];
    let images = resources.map(m => { return m.imageFiles.map((imageFile) => { return imageFile }) })[0];
    let mappedImages = {};

    let s3Promises = images.map((image) => {

      let imageKey = image.fileName;
      let folderName = 'uploads/';
      let level = 'protected';

      if (image && image.level) {
        level = image.level;
      }
      if (image && image.folder) {
        folderName = image.folder + "/";
      }
      return new Promise((resolve, reject) => {
        Storage.get(folderName + imageKey, { level: level, identityId: image.cognitoId })
          .then(result => {
            ////console.log(result);
            mappedImages[imageKey] = result;
            resolve(result);
          })
          .catch(err => {
            ////console.log(err);
            reject(err);
          });
      })


    });


    Promise.all(s3Promises)
      .then((pres) => {
        ////console.log(mappedImages);
        callback(mappedImages);
      })
      .catch(err => {
        //console.log(err);
      });
  }

}


