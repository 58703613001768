import { Grid, IconButton, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import React, { useState } from "react";
import StandardDialog from "../StandardDialog";
import StandardTextfield from "../StandardTextfield";
import StandardButton from "../StandardButton";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useManagerCreateBoxset } from '../../managers/BooksManager';

const CreateBoxsetDialog = (props) => {

  let [title, setTitle] = useState(null);

  let {
    createBoxset,
    createBoxsetLoading,
    createBoxsetError
  } = useManagerCreateBoxset();


  const handleTitleChange = (e) => {
    e.preventDefault();

    setTitle(e.target.value);
  }

  const handleCreateBoxset = () => {

    createBoxset({
      title,
      stores:props.stores
    },()=>{
      props.onClose();
    })

  }

  return (
    <StandardDialog
      open={props.open}
      onClose={props.onClose}
      dialogContent={
        <Grid container direction={'column'}>
          <Grid item>
            <StandardTextfield label={'Title'} onChange={handleTitleChange} />
          </Grid>
          {/* <Grid item>
            <Grid container direction={'row'}>
              <Grid item>
                <IconButton onClick={(e) => {
                  e.preventDefault();

                  // openDropZone();

                }}>
                  <UploadFileIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant={'h6'}>Import a word document</Typography>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>



      }
      title={'Create a Boxset'}
      actions={[
        <StandardButton autoFocus onClick={props?.onClose}>
          Cancel
        </StandardButton>,
        <StandardButton autoFocus onClick={(e) => {
          e.preventDefault();
          handleCreateBoxset();
        }}>
          Ok
        </StandardButton>
      ]}>

    </StandardDialog>
  )
}

export default inject('stores')(observer(CreateBoxsetDialog));