import { Grid, IconButton, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import React, { useState } from "react";
import StandardDialog from "../../StandardDialog";
import StandardButton from "../../StandardButton";

const EditorCompleteEditRequestDialog = (props) => {

  let { editRequest } = props;



  return (
    <StandardDialog
      open={props.open}
      onClose={props.onClose}
      dialogContent={
        <Grid container direction={'column'}>
          <Grid item>
            <Typography>Completing this edit request means...</Typography>
          </Grid>

          {/* <Grid item>
            <StandardTextfield label={'Description'} onChange={handleDescriptionChange} />
          </Grid> */}

          
        </Grid>



      }
      title={'Complete Edit Request'}
      actions={[
        <StandardButton autoFocus onClick={props?.onClose}>
          Cancel
        </StandardButton>,
        <StandardButton autoFocus onClick={(e) => {
          e.preventDefault();
          if(props.onOk){
            props.onOk();
          }
        }}>
          Ok
        </StandardButton>
      ]}>

    </StandardDialog>
  )
}

export default inject('stores')(observer(EditorCompleteEditRequestDialog));