import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import { useGetUserConnections, useGetUserInvitedToGroups, useManagerAcceptGroupInvitation, useManagerLeaveGroup } from '../../../managers/ConnectionsManager';
import ShareIcon from '@mui/icons-material/Share';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Avatar from '@mui/material/Avatar';
import helpers from '../../../helpers/helpers';
import ReactTimeAgo from 'react-time-ago';
import EmptyContent from "../../EmptyContent";


const ListSharedGroups = (props) => {

    const { userGroups, loading: getUserInvitedToUserGroupsLoading, error: getUserInvitedToUserGroupsError, getUserInvitedToUserGroupsRefetch } = useGetUserInvitedToGroups(props);
    let { selectedUserGroup, order='desc', orderBy='createdAt' } = props;


    useEffect(() => {


    }, [userGroups]);

    let sortedUserGroups = [];
    if (userGroups != null) {

        sortedUserGroups = userGroups.map((userGroupUser) => {

            let compositeUserGroup = {
                object: userGroupUser,
                name: userGroupUser.userGroup?.name,
                createdAt: userGroupUser.acceptedAt != null ? userGroupUser.acceptedAt : userGroupUser.invitedAt

            }


            return compositeUserGroup;
        });
        sortedUserGroups = helpers.stableSort(sortedUserGroups, order, orderBy, props.stores.bookStore.sortOrder, props.stores.bookStore.sortOrderBy === 'alpha' ? 'name' : props.stores.bookStore.sortOrderBy);

    }




    let secondaryActions = [];

    // if (showShareIcon) {
    //     secondaryActions.push(<IconButton
    //         onClick={props.onSelected}>
    //         <ShareIcon />
    //     </IconButton>)
    // }






    return (<List sx={{ width: '100%' }}>
        {userGroups && userGroups.length > 0 ?
            sortedUserGroups.map((compositeUserGroup, index) => {

                let userGroupUser = compositeUserGroup.object;

                //console.log(index);
                let selectedIndex = null;
                if (compositeUserGroup.id == selectedUserGroup?.id) {
                    selectedIndex = index;
                }

                return <ListItem
                selected={selectedIndex===index}
                    sx={{ padding: ' 0.25em' }}
                    secondaryAction={
                        secondaryActions
                    }
                >
                    <ListItemAvatar>
                        <Avatar
                            bucketName={config.user_uploads_bucket_name}
                        //   s3Src={userConnection?.connectedUser?.profilePicUrl}
                        >
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={userGroupUser.userGroup?.name}
                        secondary={compositeUserGroup?.createdAt != null ? <ReactTimeAgo date={compositeUserGroup?.createdAt} locale="en-US" /> : ''}
                        onClick={(e) => {
                            e.preventDefault();
                            props.onListSelectGroup(compositeUserGroup);
                        }}
                    />

                </ListItem>
            })
            :
            <EmptyContent tagline={'Groups you have been invited to will appear here.'} />
        }
    </List>

    )
}

export default inject('stores')(observer(ListSharedGroups));