import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import StandardDataTable from '../../../components/table/StandardDataTable';
import { useManagerLoadBoxSetContributors } from '../../../managers/BoxsetsManager';
import CancelTableCell from '../../../components/table/CancelTableCell';
import StandardOkCancelDialog from '../../../components/panels/StandardOkCancelDialog';
import RemoveBoxsetContributorPanel from '../../../components/panels/RemoveBoxsetContributorPanel';

const BoxsetContributors = (props) => {


  let { boxset } = props;

  let [showRemoveContributorDialog, setShowRemoveContributorDialog] = useState(false);
  let [selectedContributor, setSelectedContributor] = useState(false);
  
  let {
    boxsetContributors,
    loading: lazyLoadBoxSetContributorsLoading,
    error: lazyLoadBoxSetContributorsError,
    refetch: lazyLoadBoxSetContributorsRefetch
  } = useManagerLoadBoxSetContributors(props, boxset?.id);

  //console.log(boxsetContributors);

  return (
    <>
      {boxset &&
        <StandardDataTable title={'Contributors'}
          structure={{
            dataDef: {
              data: boxsetContributors,
              rowKeyDataFieldName: 'id'
            },
            columns: [
              {
                title: 'Pic',
                dataValueFunction: (boxsetContributor) => { return <Avatar sx={{ width: '1.5em', height: '1.5em' }} variant="square" alt="" src="https://www.adobe.com/express/create/cover/media_178ebed46ae02d6f3284c7886e9b28c5bb9046a02.jpeg?width=400&format=jpeg&optimize=medium" /> }
              },
              {
                title: 'Name',
                dataValueFunction: (boxsetContributor) => { return boxsetContributor.contributor.name }
              },
              {
                title: 'Remove',
                dataValueFunction: (boxsetContributor) => {

                  return <CancelTableCell onClick={(e) => {
                    e.preventDefault();
                    setShowRemoveContributorDialog(true)
                    setSelectedContributor(boxsetContributor.contributor)

                  }} />
                }
              },
            ]
          }}
        />
      }

      <StandardOkCancelDialog
        open={showRemoveContributorDialog}
        showOkButton={true}
        showCancelButton={true}
        onClose={() => {
          setShowRemoveContributorDialog(false)
          setSelectedContributor(null)
        }}
        onOk={() => {
          setShowRemoveContributorDialog(false)
          setSelectedContributor(null)

        }}
        title={'Remove Contributor'}
        content={
          <RemoveBoxsetContributorPanel boxset={boxset} contributor={selectedContributor}  onRemoved={() => {
            setShowRemoveContributorDialog(false)
            setSelectedContributor(null)
            lazyLoadBoxSetContributorsRefetch();
  
          }}/>
        }
      />


    </>

  )
}

export default inject('stores')(observer(BoxsetContributors));