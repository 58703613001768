import React, { useState, useEffect } from "react";
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetOwnedBooks, useOpenBookInWrite } from '../../managers/BooksManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import SquareAvatar from "./SquareAvatar";
import DeleteIcon from '@mui/icons-material/Delete';
import StandardRoundedAction from "../actions/StandardRoundedAction";
import Feather from "mdi-material-ui/Feather";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import TablePopupMenu from "../menus/TablePopupMenu";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CommentIcon from '@mui/icons-material/Comment';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { useGetReceivedEditRequests, useOpenEditRequestInEdit } from '../../managers/EditRequestsManager';
import EmptyContent from "../EmptyContent";
import StandardList from "./StandardList";
import BookImageViewer from "../boilerPlate/BookImageViewer";

let missingBookImage = require('../../assets/images/image-not-found.png')

const ReceivedEditRequestsList = (props) => {

  const { editRequests, error, loading } = useGetReceivedEditRequests(props);

  const { openEditRequestInEdit } = useOpenEditRequestInEdit(props);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');


  const isXs = useMediaQuery({ maxWidth: 599 })
  const isSm = useMediaQuery({ maxWidth: 600 })
  const isMd = useMediaQuery({ maxWidth: 900 })
  const isLg = useMediaQuery({ maxWidth: 1200 })
  const isXl = useMediaQuery({ minWidth: 1536 })


  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {


    setOrder("desc");
    setOrderBy("createdAt");

  }, [editRequests]);




  const createSecondaryActions = (editRequest) => {

    let secondaryActions = [];

    if (props.showSelectIcon) {
      secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
        //console.log(editRequest)
        props.onSelected(editRequest);
      }}>
        <ShareIcon />
      </IconButton>);
    }

    return secondaryActions;
  }

  return (
    <StandardList
      // title={'My Books'}
      hideHeading={true}
      // headerActions={<CreateExportsAssembly book={book} />}
      component="div" disablePadding>
      {editRequests && editRequests.length > 0 ?
        editRequests.map((editRequest) => {

          return <>

            <ListItem
              alignItems="flex-start"

              secondaryAction={



                <Box sx={{
                  display: 'flex'
                }}>
                  <StandardRoundedAction icon={<AccountEdit sx={{
                    color: theme.palette.standardActions.icon.color.main,

                  }} onClick={(e) => {
                    e.preventDefault();
                    //console.log(editRequest)
                    //navigate("/edit");

                    openEditRequestInEdit({
                      editRequest
                    });
                    // openBookInWrite({
                    //   bookDraftId: book.primaryBookDraftId
                    // });


                  }} />} />


                  {/* <TablePopupMenu
                    menuItems={[
                     
                      
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        console.log(book)
                        props.stores.bookStore.bookListBook = book;
                        navigate("/books/details/snapshots");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }} >

                        <ListItemIcon>
                          <CopyAllIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Snapshots" />
                      </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        console.log(book)
                        props.stores.bookStore.bookListBook = book;
                        navigate("/books/details/editing");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }}   >

                        <ListItemIcon>
                          <AccountEdit sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                      </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        console.log(book)
                        props.stores.bookStore.bookListBook = book;
                        navigate("/books/details/sharing");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }}   >

                        <ListItemIcon>
                          <ShareIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Share" />
                      </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        console.log(book)
                        props.stores.bookStore.bookListBook = book;
                        navigate("/books/details/feedback");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }}   >

                        <ListItemIcon>
                          <CommentIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Feedback" />
                      </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        console.log(book)
                        // props.stores.bookStore.bookListBook = book;
                        navigate("/books/details/boxsets");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }}  >

                        <ListItemIcon>
                          <ContentCopyIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Boxsets" />
                      </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        console.log(book)
                        props.stores.bookStore.bookListBook = book;
                        navigate("/books/details/exports");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }} >

                        <ListItemIcon>
                          <FileDownloadIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Export" />
                      </ListItemButton>,
                    ]}
                  /> */}




                </Box>







              }>
              <ListItemAvatar
                onClick={(e) => {
                  e.preventDefault();
                  props.onSelected(editRequest);
                }}>
                {/* <SquareAvatar
                  //bucketName={config.user_uploads_bucket_name}
                  s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + editRequest?.book?.coverUrl}
                  missingContent={<img src={missingBookImage} />}
                /> */}

                <BookImageViewer
                  s3Path={editRequest?.book?.coverUrl}
                  

                />

                {/* <UserAvatar s3Src={userConnection?.connectedUser?.profilePicUrl} /> */}
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  noWrap: true
                }}
                onClick={(e) => {
                  e.preventDefault();
                  props.onSelected(editRequest);
                }}
                primary={editRequest.book?.title}
                secondary={
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {editRequest.requestorUser?.name}
                    </Typography>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="caption"
                      color="text.secondary"
                    >
                      Sent ago
                    </Typography>
                    {/* {" — I'll be in your neighborhood doing errands this…"} */}
                  </Box>
                }

              />

            </ListItem>
            <Divider variant="inset" />
          </>
          // <Divider variant="inset"/>


          // return <ListItem secondaryAction={createSecondaryActions(book)}>
          //   <ListItemAvatar>
          //     <AvatarTableCell
          //       bucketName={config.user_uploads_bucket_name}
          //       s3Src={book?.coverUrl}
          //     //title={userConnection?.connectedUser?.name}
          //     />
          //   </ListItemAvatar>
          //   <ListItemText primary={book?.title} secondary="" />

          // </ListItem>
        })
        :
        <Box sx={{
          marginTop: '3em'
        }}>
          <EmptyContent tagline={'Requests to edit a book will show up here.'} />
        </Box>

      }
      {/* {ownedBooks.length}:
      {availableBooks.length} */}
    </StandardList>

  )
}

export default inject('stores')(observer(ReceivedEditRequestsList));