import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import { useLoadBookEditRequests, useOpenEditRequestInEdit } from '../../managers/EditRequestsManager';
import { useManagerLoadBoxSetContributors } from '../../managers/BoxsetsManager';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useGetBookShares, useManagerDeleteBookUserShare, useManagerDeleteBookUserGroupShare } from '../../managers/BooksManager';
import ImageTitleTableCell from "../table/ImageTitleTableCell";

const ContributedBooksList = (props) => {

  let user = props.stores.userStore.user;

  let { book } = props;

  let boxset = book?.boxSet;
  let [boxsetBooks, setBoxsetBooks] = useState([]);

  let isBoxsetOwner = false;
  if (user && boxset && user.id == boxset.owner?.id) {
    isBoxsetOwner = true;
  }


  let {
    boxsetContributors,
    loading: lazyLoadBoxSetContributorsLoading,
    error: lazyLoadBoxSetContributorsError
  } = useManagerLoadBoxSetContributors(props, boxset?.id);


  useEffect(() => {

    try {

      let bookData = {};
      boxsetContributors.forEach((boxsetContributor) => {

        boxsetContributor.contributedBooks.items.forEach((bsb) => {

          bookData[bsb.book.id] = ({
            boxsetContributor,
            boxsetBook: bsb
          })
        })

      });

      setBoxsetBooks(Object.values(bookData));

    } catch (err) {
      console.log(err);
    }
  }, [boxsetContributors]);


  const createSecondaryActions = (bsbData) => {

    let secondaryActions = [];

    if (props.showSelectIcon) {
      secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
        if(props.onSelected){
          props.onSelected(bsbData.boxsetBook.book);
        }
      }}>
        <AccountEdit />
      </IconButton>);
    }

    return secondaryActions;
  }

  return (
    <List component="div" disablePadding>
      {
        boxsetBooks.map((bsbData) => {

          return <ListItem
            secondaryAction={createSecondaryActions(bsbData)}
          >
            <ImageTitleTableCell
              bucketName={config.user_uploads_bucket_name}
              title={bsbData.boxsetBook.book.title}
              s3Src={bsbData.boxsetBook.book.coverUrl}
              onClick={(e) => {
                e.preventDefault();
                //console.log(boxset)
                // props.stores.bookStore.boxSetListBoxSet = boxset;
                // navigate("/boxsets/details");
              }}
            />
            {/* <ListItemText primary={userConnection?.connectedUser?.name} secondary="" /> */}

          </ListItem>
        })
      }
    </List>

  )
}

export default inject('stores')(observer(ContributedBooksList));