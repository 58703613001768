import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import { useUpdateEntity } from '../../managers/EntityManager';


const StandardAutoUITextField = (props) => {


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const saveDelayTime = 3000;

    const [saveTime, setSaveTime] = useState(-1);
    const [lastKeyTime, setLastKeyTime] = useState(-1);
    const { updateItem, updateItemLoading, updateItemError } = useUpdateEntity(props);
    //Fixing the label/text overlap:
    //https://stackoverflow.com/questions/50955603/react-material-ui-label-overlaps-with-text
    let fieldValue = null;
    
    try{
        fieldValue = props.entity[props.fieldName];
    }catch(err){

    }


    const handleChange = (value) => {


        let now = Date.now();
        setLastKeyTime(now);
        let {entity, fieldName} = props;
        
    
        // if (value.length > maxLength) {
        //   value = value.substring(0, maxLength);
        // }
    
        if (entity[fieldName] != value) {
    
            entity[fieldName] = value;
    
        }
    
        entity.updatedTimestamp = now;
    
        setSaveTime(now);
    
        doSave();
    
    
    
    
      };



      const doSave = () => {
    
        //console.log('doSave()...');
        let {entity, fieldName} = props;
        let now = Date.now();
        let timeSinceLastKeyPress = now - lastKeyTime;
    
        if (saveTime == -1) {
          return;
        }
        else if ((timeSinceLastKeyPress > saveDelayTime)) {
    
          setSaveTime(-1);
    
          //props.stores.bookStore.recipeTimestamp = new Date().getTime();
    
          let newValue = entity[fieldName];

          updateItem({
            itemExpectedVersion: entity.version,
            itemKey: entity.id,
            itemType: entity.modelType,
            fieldName: fieldName,
            fieldValue: entity[fieldName]
          })
    
    
        } else {
          setTimeout(function () {
            doSave()
          }
            , saveDelayTime)
        }
    
    
    
    
      }


    return (
        <TextField
            id={props.id}
            sx={{ m: 1 }}
            onChange={(e)=>{
                // try{
                //     props.entity[props.fieldName] = e.target.value;
                // }
                // catch(err){
                //     //do nothing
                // }

                let newValue = e.target.value;
                handleChange(newValue);

            }}
            fullWidth
            label={props.label}
            variant="outlined"
            // style={{ marginBottom: '1em', marginTop: '0.25em' }}
            value={fieldValue || ''}
            
            
        />
          
    )
}
export default inject('stores')(observer(StandardAutoUITextField));