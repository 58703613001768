import Quill from 'quill';
//import Parchment from 'parchment';
import DeltaToDom  from './DeltaToDom';
import { v4 as uuid }  from 'uuid';
const Parchment = Quill.import('parchment');
var $ = require("jquery");

let BlockEmbed = Quill.import('blots/block/embed');


function createContent(node,args){
  	
  	let converter = new DeltaToDom();
	  /*let html = converter.convertBlurb(args);
  	node.innerHTML = html;*/

  	let domNode = converter.convertAlsobys(args,node);
  	node.appendChild(domNode);

  	/*let addBlurbButton = document.getElementById('addBlurbBtn');
  	addBlurbButton.addEventListener('click', function(ev) {

      ////console.log(this);

  	})*/

  }

export default class AlsobysBlot extends BlockEmbed {
  static create(args) {
    let node = super.create();
    node.dataset.uuid = args.uuid;
    node.dataset.alsobys  = args.alsobys;
    node.setAttribute('id', args.uuid);
    //console.log(node);
    // Allow twitter library to modify our contents
    //twttr.widgets.createTweet(id, node);
    createContent(node,args);
    return node;
  }

  static value(domNode) {
  	let value = {};
  	value.uuid = domNode.dataset.uuid;
    value.alsobys = domNode.dataset.alsobys;//'{contributors:[{name:"bob"}]}'
    return value;
  }

  


  deleteContributor(id){

    
    
    ////console.log(this.domNode.dataset.alsobys);
    
    let alsobys = JSON.parse(this.domNode.dataset.alsobys);

    let alsobysToKeep = alsobys.records.filter(f=>f.item.id!=id);
    
    alsobys.records = alsobysToKeep;

    let count = 0;
     alsobys.records.forEach((alsoby)=>{
      count++;
      alsoby.item.orderNumber = count;

     });



    let alsobysString = JSON.stringify(alsobys);
    ////console.log(alsobysString);
    this.domNode.dataset.alsobys = alsobysString;
    ////console.log('end')

    return alsobysString;

  }

  getContributors(){

    
    let alsobys = JSON.parse(this.domNode.dataset.alsobys);

    
    return alsobys.records;

  }

  addContributor({bookId}){

    ////console.log(this.domNode.dataset.alsobys);
    
    let alsobys = JSON.parse(this.domNode.dataset.alsobys);

    //console.log(alsobys);
    /*let newContributor = {
          orderNumber:1,
          item:{
            id:uuid(),
            name:'name',
            role:'role'
          }
        };*/

    let newContributor =  {
       
      item:{
            orderNumber:alsobys.records.length+1,
            id:uuid(),
            bookId:bookId,
            title:"",
            isStoreLink:'false',
            subtitle:"",
            coverUrl:"",
            coverUrlType:"",
            kindleIdentifier:"1",
            appleIdentifier:"2",
            nookIdentifier:"3",
            koboIdentifier:"4",
            googleIdentifier:"5",
            customIdentifier:"6",
            include:'true'
          }
      
      };


    alsobys.records.push(newContributor)
   

    let alsobysString = JSON.stringify(alsobys);
    ////console.log(alsobysString);
    this.domNode.dataset.alsobys = alsobysString;
    ////console.log('end')

    return newContributor;

  }

  move(old_index, new_index) {

    let alsobys = JSON.parse(this.domNode.dataset.alsobys);

    let arr = alsobys.records;
    //console.log(arr);

    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
    }
     arr.splice(new_index, 0, arr.splice(old_index, 1)[0]); 

     let count = 0;
     arr.forEach((record)=>{
      count++;
      record.item.orderNumber = count;

     });
     alsobys.records = arr;
   return alsobys;
}

  updateFromDom(contributorJson){
    
    let alsobys = JSON.parse(this.domNode.dataset.alsobys);

    let contributor = alsobys.records.filter(f=>f.item.id==contributorJson.id)[0];
    contributor.item.name = contributorJson.name;
    contributor.item.role = contributorJson.role;
    contributor.item.title = contributorJson.title;
    contributor.item.subtitle = contributorJson.subtitle;
    contributor.item.include = contributorJson.include;
    contributor.item.isStoreLink = contributorJson.isStoreLink;

    let alsobysString = JSON.stringify(alsobys);
    this.domNode.dataset.alsobys = alsobysString;



    //this.domNode.dataset.alsobys = JSON.stringify(contributorJson);;
   
  }


  /*deleteAt(index,length) {

    //let textBlot = Parchment.create('text', 'Test');
    //textBlot.insertAt(index, 'ough');
    return false;
  }*/

  deleteAt() {
    return false
  }
  
}
AlsobysBlot.blotName = 'alsobys';
AlsobysBlot.tagName = 'div';
AlsobysBlot.className = 'blot-wrapper-alsoby';