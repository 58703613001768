import Quill from 'quill';
//import Inline from './inline';
//import TextBlot from './text';
let Block = Quill.import('blots/block');
let Inline = Quill.import('blots/inline');
let TextBlot = Quill.import('blots/text');
let Parchment = Quill.import("parchment");
var $ = require("jquery");

class BookMerlinTextBlot extends TextBlot {
    constructor(domNode) {
        super(domNode);
       
        //console.log(domNode);

        let blot = Parchment.find(domNode);
        //console.log(blot);
                        //blot.previewOn();

        let parent = $( domNode ).parent();
        //console.log(parent);
        //console.log(parent.prevObject[0]);

        
        //$( parent.prevObject ).wrap( "<span style='background-color:red'></span>" );




        // domNode.childNodes.forEach((childNode)=>{
        //     childNode.setAttribute('style', 'background-color:blue');
        // })
        // domNode.setAttribute('style', 'background-color:grey');

        //$(domNode).children().css( "background-color", "red" );
      }
 }


export default BookMerlinTextBlot ;
