import { Grid, styled } from "@mui/material";
import { Button } from "@mui/material";
import { Typography } from "antd";
import apiErrorCodes from "../api-error-codes";


export const StandardErrors = (props) => {

    let { errors } = props;
    return (

        <Grid container direction={'column'}>
            {errors.map((error) => {

                let errorMsg = (apiErrorCodes[error] && apiErrorCodes[error].uiShortMessage) ? apiErrorCodes[error].uiShortMessage : error

                return <Grid item>
                    <Typography variant={'h5'}>{errorMsg}</Typography>
                </Grid>
            })
            }
        </Grid>
    )
}





export default StandardErrors;