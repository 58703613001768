import { Box, IconButton, styled } from "@mui/material";
import { Button } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Feather from "mdi-material-ui/Feather";

const StyledButton = styled(Button)(() => {


})

export const StandardRoundedButton = styled(StyledButton)(() => ({

    borderRadius: "8px"

}));

export const StandardRoundedAction = (props) => {

    const theme = useTheme();

    return (


            <IconButton sx={{ }}>
                {props.icon}
            </IconButton>
    )

    /*
    return (

        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            border: '0px solid',
            borderRadius: '3em',
            width: '2.5em',
            height: '2.5em',
            borderColor: theme.palette.standardActions.icon.backgroundColor.main,
            //border: '1px solid red'
            //backgroundColor: theme.palette.standardActions.icon.backgroundColor.main,

        }}>
            <IconButton sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
                //backgroundColor: theme.palette.standardActions.icon.backgroundColor.main,

            }}>
                {props.icon}
            </IconButton>
        </Box>
    )
    */
}



export default StandardRoundedAction;