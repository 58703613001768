import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StretchBox from '../boilerPlate/StretchBox';
import { Box, Button, SwipeableDrawer } from '@mui/material';
import ScrollBox from '../boilerPlate/ScrollBox';
import LaurasBookList from './LaurasBookList';
import LaurasCard from './LaurasCard';
import LaurasBooksSnapshot from './LaurasBooksSnapshot';
import LaurasBooksBoxsets from './LaurasBooksBoxsets';
import LaurasBooksShare from './LaurasBooksShare';
import LaurasBooksFeedback from './LaurasBooksFeedback';
import LaurasBooksEdit from './LaurasBooksEdit';
import LaurasBooksExport from './LaurasBooksExport';
import LaurasButton from './LaurasButton';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LaurasBooksDetails from './LaurasBooksDetails';
import LaurasDialog from './LaurasDialog';
import LaurasCirclePlus from './LaurasCirclePlus';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LaurasMoreDetail from './LaurasMoreDetail';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ShareIcon from '@mui/icons-material/Share';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Link from '@mui/material/Link';

const LaurasBooks = (props) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [showDetailDrawer, setShowDetailDrawer] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showMoreDetail, setShowMoreDetail] = useState(false);
    const [isSelected, setIsSelected] = useState(false);


    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };


    const handleClickOpen = () => {
        setShowDialog(true);
    }

    const handleClose = () => {
        setShowDialog(false);
    }

    const handleMoreDetailOpen = () => {
        setShowMoreDetail(true);
    }

    const handleMoreDetailClose = () => {
        setShowMoreDetail(false);
    }



    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setShowDetailDrawer(open);
            };

    return (

        <StretchBox flexDirection='row' sxProps={{ gap: isSmallScreen ? 0 : 2 }}>

            <StretchBox sxProps={{
                flex: isSmallScreen ? 12 : 4,
                position: 'relative'
            }}>
                {/* <BoxsetsTable onSelectBoxSet={
                    (boxset) => {
                        setSelectedBoxset(boxset)
                        setShowDetailDrawer(true)
                    }} /> */}

                <ScrollBox>
                    <LaurasCard
                        title='Books'
                        children={
                            <div>
                                <LaurasBookList
                                    selected={selectedIndex === 0}
                                    onClick={(event) => {
                                        setShowDetailDrawer(true)
                                        handleListItemClick(event, 0)
                                    }}
                                    avatarType={<MenuBookIcon />}
                                    primary='Book Title 1'
                                    secondary='Subtitle 1'
                                />
                                <LaurasBookList
                                    selected={selectedIndex === 1}
                                    onClick={(event) => {
                                        setShowDetailDrawer(true)
                                        handleListItemClick(event, 1)
                                    }}

                                    avatarType={<MenuBookIcon />}
                                    primary='Book Title 1'
                                    secondary='Subtitle 1'
                                />
                                <LaurasBookList
                                    selected={selectedIndex === 2}
                                    onClick={(event) => {
                                        setShowDetailDrawer(true)
                                        handleListItemClick(event, 2)
                                    }}
                                    avatarType={<MenuBookIcon />}
                                    primary='Book Title 1'
                                    secondary='Subtitle 1'
                                />
                                <LaurasBookList
                                    selected={selectedIndex === 3}
                                    onClick={(event) => {
                                        setShowDetailDrawer(true)
                                        handleListItemClick(event, 3)
                                    }}
                                    avatarType={<MenuBookIcon />}
                                    primary='Book Title 1'
                                    secondary='Subtitle 1'
                                />
                                <LaurasBookList
                                    selected={selectedIndex === 4}
                                    onClick={(event) => {
                                        setShowDetailDrawer(true)
                                        handleListItemClick(event, 4)
                                    }}
                                    avatarType={<MenuBookIcon />}
                                    primary='Book Title 1'
                                    secondary='Subtitle 1'
                                />
                                <LaurasBookList
                                    selected={selectedIndex === 5}
                                    onClick={(event) => {
                                        setShowDetailDrawer(true)
                                        handleListItemClick(event, 5)
                                    }}
                                    avatarType={<MenuBookIcon />}
                                    primary='Book Title 1'
                                    secondary='Subtitle 1'
                                />
                                <LaurasBookList
                                    selected={selectedIndex === 6}
                                    onClick={(event) => {
                                        setShowDetailDrawer(true)
                                        handleListItemClick(event, 6)
                                    }}
                                    avatarType={<MenuBookIcon />}
                                    primary='Book Title 1'
                                    secondary='Subtitle 1'
                                />
                                <LaurasBookList
                                    selected={selectedIndex === 7}
                                    onClick={(event) => {
                                        setShowDetailDrawer(true)
                                        handleListItemClick(event, 7)
                                    }}
                                    avatarType={<MenuBookIcon />}
                                    primary='Book Title 1'
                                    secondary='Subtitle 1'
                                />
                                <LaurasBookList
                                    selected={selectedIndex === 9}
                                    onClick={(event) => {
                                        setShowDetailDrawer(true)
                                        handleListItemClick(event, 9)
                                    }}
                                    avatarType={<MenuBookIcon />}
                                    primary='Book Title 1'
                                    secondary='Subtitle 1'
                                />
                                <LaurasBookList
                                    selected={selectedIndex === 10}
                                    onClick={(event) => {
                                        setShowDetailDrawer(true)
                                        handleListItemClick(event, 10)
                                    }}
                                    avatarType={<MenuBookIcon />}
                                    primary='Book Title 1'
                                    secondary='Subtitle 1'
                                />
                            </div>}
                        showPagination={false}
                        showSearchField={true}
                    />

                </ScrollBox>

                <LaurasCirclePlus
                    onClick={handleClickOpen}
                />
            </StretchBox>

            <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>

                    <LaurasCard
                        title='Details'
                        actions={
                            <Link underline='none' color='blue'
                                onClick={handleMoreDetailOpen}>
                                More
                            </Link>
                        }
                        children={<LaurasBooksDetails />}
                        showPagination={false}
                    />
                    <LaurasCard
                        title='Snapshots'
                        actions={
                            <Fab color="primary" size='small'>
                                <AddIcon />
                            </Fab>
                        }
                        children={<LaurasBooksSnapshot />}
                        showSearchField={true}
                    />
                    <LaurasCard
                        title='Boxsets'
                        children={<LaurasBooksBoxsets />}
                    />
                    <LaurasCard
                        title='Shared With'
                        actions={
                            <Fab color="primary" size='small'>
                                <ShareIcon />
                            </Fab>
                        }
                        children={<LaurasBooksShare />}
                        showSearchField={true}
                    />
                    {/* <LaurasCard
                        title='Feedback'
                        children={<LaurasBooksFeedback />}
                    /> */}
                    <LaurasCard
                        title='Edit'
                        actions={
                            <Fab color="primary" size='small'>
                                <AddIcon />
                            </Fab>
                        }
                        children={<LaurasBooksEdit />}
                    />
                    <LaurasCard
                        title='Export'
                        actions={
                            <Fab color="primary" size='small'>
                                <AddIcon />
                            </Fab>
                            // <FileDownloadIcon />
                        }
                        children={<LaurasBooksExport />}
                    />


                </ScrollBox>
            </StretchBox>



            <LaurasDialog
                title='Upload or Create a Book'
                open={showDialog}
                onClose={handleClose}
            />

            <LaurasMoreDetail
                open={showMoreDetail}
                onClose={handleMoreDetailClose} />


            <SwipeableDrawer
                anchor={'right'}
                open={showDetailDrawer && isSmallScreen}
                onClose={toggleDrawer(false)}
                sx={{ width: '100%' }}
            //variant='persistent'
            //onOpen={setShowDetailDrawer(true)}
            >
                <div style={{ width: '100vw' }}>

                    <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                        <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2, ml: 0, mr: 0 }}>
                            <IconButton
                                style={{ display: 'flex', justifyContent: 'left' }}
                                onClick={() => {
                                    setShowDetailDrawer(false)
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography
                                variant={theme.landmarkText.variant}
                                gutterBottom
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                Book Title
                            </Typography>
                            <LaurasCard
                                title='Details'
                                actions={
                                    <Link underline='none' color='blue'
                                        onClick={handleMoreDetailOpen}>
                                        More
                                    </Link>
                                }
                                children={<LaurasBooksDetails />}
                                showPagination={false}
                            />
                            <LaurasCard
                                title='Snapshots'
                                actions={
                                    <Fab color="primary" size='small'>
                                        <AddIcon />
                                    </Fab>
                                }
                                children={<LaurasBooksSnapshot />}
                            />
                            <LaurasCard
                                title='Boxsets'
                                children={<LaurasBooksBoxsets />}
                            />
                            <LaurasCard
                                title='Shared With'
                                actions={
                                    <Fab color="primary" size='small'>
                                        <ShareIcon />
                                    </Fab>
                                }
                                children={<LaurasBooksShare />}
                            />
                            {/* <LaurasCard
                                title='Feedback'
                                children={<LaurasBooksFeedback />}
                            /> */}
                            <LaurasCard
                                title='Edit'
                                actions={
                                    <Fab color="primary" size='small'>
                                        <AddIcon />
                                    </Fab>
                                }
                                children={<LaurasBooksEdit />}
                            />
                            <LaurasCard
                                title='Export'
                                actions={
                                    <Fab color="primary" size='small'>
                                        <AddIcon />
                                    </Fab>
                                }
                                children={<LaurasBooksExport />}
                            />


                        </ScrollBox>
                    </StretchBox>
                </div>
            </SwipeableDrawer>


        </StretchBox>
    )
}
export default inject('stores')(observer(LaurasBooks));