import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';


function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }


const WriteDetailsGoals = (props) => {

    const theme = useTheme();

    let { bookDraftDocumentPart, bookDraft } = props;


    let userId = props.stores?.bookStore.userId;

    const [progress, setProgress] = React.useState(40);

    React.useEffect(() => {
    //   const timer = setInterval(() => {
    //     setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    //   }, 800);
    //   return () => {
    //     clearInterval(timer);
    //   };
    }, []);


    return (
        <Box container direction={'column'} sx={{
            justifyContent: 'center',
            //marginRight:'1em'
        }}>
            <Box style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center'
            }}>
                <Typography>Words per month:</Typography>
            <Box sx={{ width: '100%' }}>
                <LinearProgressWithLabel value={10} />
            </Box>

            <Typography>Words per day:</Typography>
            <Box sx={{ width: '100%' }}>
                <LinearProgressWithLabel value={progress} />
            </Box>
            <Button variant='contained'>Add Goal</Button>
                
                </Box>
        </Box>
    );
};


export default inject('stores')(observer(WriteDetailsGoals));
