import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import QuillEditor from './QuillEditor';
import Delta from 'quill-delta';
import { Paper } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import StandardEntityImageSelector from '../StandardEntityImageSelector';

const FullpageImageEditor = (props) => {

    let { bookDraftDocumentPart, readOnly = false, userComments = [], disabled } = props;


    let userId = props.stores?.bookStore.userId;





    return (
        <div className='vertical-stretch' style={{

        }}>
            <Paper sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                overFlow: 'hidden',
                marginBottom: '1.5em',
                minHeight: '30em'
            }}>
                <div className='blot-wrapper' style={{
                    height:'100%'
                }}>
                    <Typography variant="h5">{bookDraftDocumentPart.documentPart.derivedTitle}</Typography>
                    {/* <div>{'userComments'+props.userComments.length}</div> */}
                    <StandardEntityImageSelector
                        width={'10em'}
                        title={'Full page Image'}
                        disabled={disabled}
                        entity={bookDraftDocumentPart.documentPart}
                        imageKeyFieldName={'headingUrl'}
                        onChange={props.onChange} />

                    {/* <div className='blot-footer-wrapper'>

                    </div> */}
                </div>

            </Paper>
        </div>
    );
};


export default inject('stores')(observer(FullpageImageEditor));
