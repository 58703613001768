import React, { useState, useEffect } from 'react';
import { Typography, styled } from "@mui/material";
import { Tooltip, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const StyledTypography = styled(Typography)(() => ({

    //backgroundColor:'red'



}))

export default function StandardDetailTitle(props) {


    const theme = useTheme();


    return (
        <StyledTypography
            // color={theme.palette.listPage?.title.color.main}
            // variant={theme.palette.listPage?.title.variant}
            // variant={'h5'}
            style={{
                color: theme.palette.write.bookTitle.color,
                fontSize: '3em',
                fontFamily: 'serif'
            }}
            {...props}  >
            {props.children}
        </StyledTypography>


    );
}
