import * as React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormatPropertiesCard from './FormatPropertiesCard';
import { Grid } from '@mui/material';
import StandardRadioButtons from '../StandardRadioButtons';
import StandardStepper from '../StandardStepper';
import { useTheme } from '@mui/material/styles';
import BookIcon from '@mui/icons-material/Book';
import PropertiesFactory from './PropertiesFactory';
import StandardToggleButtonGroup from '../StandardToggleButtons';
import { useUpdateRecipe } from '../../managers/RecipeManager';
import { useUpdateEntity } from '../../managers/EntityManager';
import StandardDialog from '../StandardDialog';
import StandardTabs from '../StandardTabs';
import TemplatesEditor from './TemplatesEditor';
import { GraphQLDirective } from 'graphql';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ThemeToolbar from './ThemeToolbar';
import BookDraftEditor from '../editor/BookDraftEditor';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ImageIcon from '@mui/icons-material/Image';
import WidgetsIcon from '@mui/icons-material/Widgets';
import BaseControl from './propertyElements/BaseControl';
import EmptyContent from '../EmptyContent';
import BookMerlinStyles from './BookMerlinStyles';
import FormatHeadingSourceToggleButtons from './propertyElements/FormatHeadingSourceToggleButtons';


function TabPanel(props) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const MainFormattingPanel = (props) => {
  const [value, setValue] = React.useState(0);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [showBookmerlinStylesDialog, setShowBookmerlinStylesDialog] = React.useState(false);
  //const [boxsetBookRecipeSource, setBoxsetBookRecipeSource] = React.useState(null);
  const [steps, setSteps] = React.useState([]);

  const { bookDraft, documentPart, recipe, user, stores } = props;
  const theme = useTheme();

  const { updateRecipeParam, updateRecipeParamLoading, updateRecipeParamError } = useUpdateRecipe(props);
  const { updateItem, updateItemLoading, updateItemError } = useUpdateEntity(props);

  const propertiesFactory = new PropertiesFactory();

  let isBoxSet = false;


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const updateRecipe = ({
    recipe,
    paramPath,
    paramName,
    paramValue,
    paramAction,
    overrideActive,
    overridePartId,
    partTypeShortHand,
    scope

  }) => {
    //console.log('updating recipe...');
    //console.log(recipe);
    //console.log(paramName);
    //console.log(paramValue);

    //This is only needed until the transition to new props is completed.
    if(paramPath || scope){

      //console.log('updating recipe prop new...');
      updateRecipeParam({
        recipeId: recipe.id,
        paramPath,
        paramName,
        paramValue,
        paramAction,
        overrideActive,
        overridePartId,
        partTypeShortHand,
        scope
      })

    }else{

      updateRecipeParam({
        recipeId: recipe.id,
        paramName,
        paramValue
      })

    }
   


  }

  const updateEntity = ({
    item,
    fieldName
  }) => {

    let itemKey = item.id;
    if (item.modelType == 'BoxSetBook') {
      itemKey = item.boxSetId + ':::' + item.bookId
    }

    updateItem({
      itemExpectedVersion: item.version,
      itemKey: itemKey,
      itemType: item.modelType,
      fieldName: fieldName,
      fieldValue: item[fieldName]
    })


  }

  React.useEffect(() => {

    let isBoxsetDocumentPart = true;
    let documentPartBoxSetId = documentPart?.bookDraftDocumentPart.bookDraft.book.boxSetId; //Indicated this documentPart is an original page of the boxset
    let boxSetBook = documentPart?.bookDraftDocumentPart.getBoxSetBook();
    let insertedBook = documentPart?.bookDraftDocumentPart.insertedBook;

    let isInsertedBookDocumentPart = documentPart?.bookDraftDocumentPart.insertedBook || documentPart?.partType == 'BOOK';
    // if (documentPartBoxSetId) {
    //   setBoxsetBookRecipeSource('boxSetRecipe');
    // } else if (boxSetBook) {
    //   setBoxsetBookRecipeSource(boxSetBook.recipeSource);
    // }


  }, [documentPart, documentPart?.bookDraftDocumentPart.getBoxSetBook()?.recipeSource]);

  React.useEffect(() => {

    if (!documentPart) {
      return;
    }
    const stepsWithHelp = [
      {
        label: 'BOXSET',
        icon: <ContentCopyIcon />,
        description: 'Adjust boxset properties.',
        boxSetOnly: true,
        properties: propertiesFactory.build({
          theme,
          sectionType: 'boxset',
          documentPart,
          bookDraft,
          user,//:props.stores.bookStore.user,
          onUpdateRecipe: updateRecipe,
          onUpdateEntity: updateEntity,
          stores
        })
      },
      {
        label: 'BOOK',
        icon: <MenuBookIcon />,
        description: 'Adjust boxset book properties.',
        boxSetOnly: true,
        excludedPartTypes: ['TOPBOOK'],
        includedContentOnly: true,
        properties: new BaseControl().getIsInsertedBookDocumentPart(documentPart) ? propertiesFactory.build({
          theme,
          sectionType: 'boxsetBook',
          documentPart,
          bookDraft,
          user,//:props.stores.bookStore.user,
          onUpdateRecipe: updateRecipe,
          onUpdateEntity: updateEntity,
          stores
        }) : <FormatPropertiesCard title={'Contributed Book Properties'} count={5} color={theme.palette.books?.main} >
          <Grid sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>

            <EmptyContent tagline={'Select an inserted book, or page from, for these properties.'} />


          </Grid>
        </FormatPropertiesCard>
      },
      {
        label: 'BOOK',
        icon: <MenuBookIcon />,
        description: 'Adjust book properties. This is the help text. This is the help text. This is the help text. This is the help text.',
        bookOnly: true,
        properties: propertiesFactory.build({
          theme,
          sectionType: 'book',
          documentPart,
          bookDraft,
          user,//:props.stores.bookStore.user,
          onUpdateRecipe: updateRecipe,
          onUpdateEntity: updateEntity,
          stores
        })
      },
      {
        label: 'PAGE',
        icon: <DescriptionIcon />,
        description: 'This is the help text. This is the help text. This is the help text.',
        excludedPartTypes: ['TOPBOOK'],
        properties: isPage(documentPart) ? propertiesFactory.build({
          theme,
          sectionType: 'page',
          documentPart,
          bookDraft,
          user,//:props.stores.bookStore.user,
          onUpdateRecipe: updateRecipe,
          onUpdateEntity: updateEntity,
          stores
        }) : <FormatPropertiesCard title={'Page Properties'} count={5} color={theme.palette.books?.main} >
          <Grid sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>

            <EmptyContent tagline={'There are no page properties for this selection.'} />


          </Grid>
        </FormatPropertiesCard>
      },
      {
        label: 'HEADER/FOOTER',
        icon: <ViewHeadlineIcon />,
        description: 'This is the help text. This is the help text. This is the help text. This is the help text. This is the help text. This is the help text. This is the help text. This is the help text.',
        excludedPartTypes: ['TOPBOOK', 'BOOK', 'FULLPAGEIMAGE'],
        // fixedContent: <Box
        //   sx={{
        //     //marginBottom: '1em'
        //   }}>
        //   <FormatPropertiesCard 
        //     title={new BaseControl().convertTitle('Heading Template Source', documentPart)}
        //     color={theme.palette.books?.main} >
        //     <Grid sx={{
        //       display: 'flex',
        //       flexDirection: 'column'
        //     }}>
        //       <FormatHeadingSourceToggleButtons
        //         bookDraft={bookDraft}
        //         documentPart={documentPart}
        //         element={{
        //           type: 'HEADING_TEMPLATE_SOURCE_TOGGLE_BUTTONS',
        //           title: 'Select recipe source',
        //           paramType: 'RECIPE',
        //           recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
        //           options: [
        //             {
        //               label: 'Book Common',
        //               value: 'common',
        //               selectedMessage: 'Using the book common heading NOT-style for all ---PART_TYPE---s.',

        //             },
        //             {
        //               label: '---PART_TYPE---s Specific',
        //               value: 'partTypeSpecific',
        //               selectedMessage: 'Using a separate heading NOT-style for all ---PART_TYPE---s.',

        //             }
        //           ],
        //         }}
        //         canEdit={true}
        //         onUpdateRecipe={updateRecipe} />
        //     </Grid>
        //   </FormatPropertiesCard>
        // </Box>,

        properties: new BaseControl().getHasHeading(documentPart) ? propertiesFactory.build({
          theme,
          sectionType: 'background',
          documentPart,
          bookDraft,
          user,//:props.stores.bookStore.user,
          onUpdateRecipe: updateRecipe,
          onUpdateEntity: updateEntity,
          stores
        }) : <FormatPropertiesCard title={'Heading Properties'} count={5} color={theme.palette.books?.main} >
          <Grid sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>

            <EmptyContent tagline={'There are no heading properties for this selection.'} />


          </Grid>
        </FormatPropertiesCard>



      },
      {
        label: 'BACKGROUND',
        icon: <ViewHeadlineIcon />,
        description: 'This is the help text. This is the help text. This is the help text. This is the help text. This is the help text. This is the help text. This is the help text. This is the help text.',
        excludedPartTypes: ['TOPBOOK', 'BOOK', 'FULLPAGEIMAGE'],
        // fixedContent: <Box
        //   sx={{
        //     //marginBottom: '1em'
        //   }}>
        //   <FormatPropertiesCard 
        //     title={new BaseControl().convertTitle('Heading Template Source', documentPart)}
        //     color={theme.palette.books?.main} >
        //     <Grid sx={{
        //       display: 'flex',
        //       flexDirection: 'column'
        //     }}>
        //       <FormatHeadingSourceToggleButtons
        //         bookDraft={bookDraft}
        //         documentPart={documentPart}
        //         element={{
        //           type: 'HEADING_TEMPLATE_SOURCE_TOGGLE_BUTTONS',
        //           title: 'Select recipe source',
        //           paramType: 'RECIPE',
        //           recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
        //           options: [
        //             {
        //               label: 'Book Common',
        //               value: 'common',
        //               selectedMessage: 'Using the book common heading NOT-style for all ---PART_TYPE---s.',

        //             },
        //             {
        //               label: '---PART_TYPE---s Specific',
        //               value: 'partTypeSpecific',
        //               selectedMessage: 'Using a separate heading NOT-style for all ---PART_TYPE---s.',

        //             }
        //           ],
        //         }}
        //         canEdit={true}
        //         onUpdateRecipe={updateRecipe} />
        //     </Grid>
        //   </FormatPropertiesCard>
        // </Box>,

        properties: new BaseControl().getHasHeading(documentPart) ? propertiesFactory.build({
          theme,
          sectionType: 'background',
          documentPart,
          bookDraft,
          user,//:props.stores.bookStore.user,
          onUpdateRecipe: updateRecipe,
          onUpdateEntity: updateEntity,
          stores
        }) : <FormatPropertiesCard title={'Heading Properties'} count={5} color={theme.palette.books?.main} >
          <Grid sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>

            <EmptyContent tagline={'There are no heading properties for this selection.'} />


          </Grid>
        </FormatPropertiesCard>



      },
      {
        label: 'HEADING',
        icon: <ViewHeadlineIcon />,
        description: 'This is the help text. This is the help text. This is the help text. This is the help text. This is the help text. This is the help text. This is the help text. This is the help text.',
        excludedPartTypes: ['TOPBOOK', 'FULLPAGEIMAGE']
        // fixedContent: <Box
        //   sx={{
        //     //marginBottom: '1em'
        //   }}>
        //   <FormatPropertiesCard 
        //     title={new BaseControl().convertTitle('Heading Template Source', documentPart)}
        //     color={theme.palette.books?.main} >
        //     <Grid sx={{
        //       display: 'flex',
        //       flexDirection: 'column'
        //     }}>
        //       <FormatHeadingSourceToggleButtons
        //         bookDraft={bookDraft}
        //         documentPart={documentPart}
        //         element={{
        //           type: 'HEADING_TEMPLATE_SOURCE_TOGGLE_BUTTONS',
        //           title: 'Select recipe source',
        //           paramType: 'RECIPE',
        //           recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
        //           options: [
        //             {
        //               label: 'Book Common',
        //               value: 'common',
        //               selectedMessage: 'Using the book common heading NOT-style for all ---PART_TYPE---s.',

        //             },
        //             {
        //               label: '---PART_TYPE---s Specific',
        //               value: 'partTypeSpecific',
        //               selectedMessage: 'Using a separate heading NOT-style for all ---PART_TYPE---s.',

        //             }
        //           ],
        //         }}
        //         canEdit={true}
        //         onUpdateRecipe={updateRecipe} />
        //     </Grid>
        //   </FormatPropertiesCard>
        // </Box>



        ,
        properties: new BaseControl().getHasHeading(documentPart) ? propertiesFactory.build({
          theme,
          sectionType: 'heading',
          documentPart,
          bookDraft,
          user,//:props.stores.bookStore.user,
          onUpdateRecipe: updateRecipe,
          onUpdateEntity: updateEntity,
          stores
        }) : <FormatPropertiesCard title={'Heading Properties'} count={5} color={theme.palette.books?.main} >
          <Grid sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>

            <EmptyContent tagline={'There are no heading properties for this selection.'} />


          </Grid>
        </FormatPropertiesCard>



      },
      {
        label: 'CONTENT',
        icon: <TextFieldsIcon />,
        description: 'This is the help text. This is the help text.',
        properties: propertiesFactory.build({
          theme,
          sectionType: 'text',
          documentPart,
          bookDraft,
          user,//:props.stores.bookStore.user,
          onUpdateRecipe: updateRecipe,
          onUpdateEntity: updateEntity,
          stores
        })
      },
      {
        label: 'BREAKS',
        icon: <TextFieldsIcon />,
        description: 'This is the help text. This is the help text.',
        properties: propertiesFactory.build({
          theme,
          sectionType: 'breaks',
          documentPart,
          bookDraft,
          user,//:props.stores.bookStore.user,
          onUpdateRecipe: updateRecipe,
          onUpdateEntity: updateEntity,
          stores
        })
      },
      {
        label: 'IMAGES',
        icon: <ImageIcon />,
        description: 'This is the help text. This is the help text. This is the help text. This is the help text. This is the help text.',
        properties: propertiesFactory.build({
          theme,
          sectionType: 'images',
          documentPart,
          bookDraft,
          user,//:props.stores.bookStore.user,
          onUpdateRecipe: updateRecipe,
          onUpdateEntity: updateEntity,
          stores
        })
      },
      {
        label: 'WIDGETS',
        icon: <WidgetsIcon />,
        description: 'This is the help text. This is the help text. This is the help text. This is the help text. This is the help text. This is the help text. This is the help text. This is the help text.',
        properties: propertiesFactory.build({
          theme,
          sectionType: 'blots',
          documentPart,
          bookDraft,
          user,//:props.stores.bookStore.user,
          onUpdateRecipe: updateRecipe,
          onUpdateEntity: updateEntity,
          stores
        })
      },
    ]





    let filteredSteps = stepsWithHelp;

    if (isBoxSet) {

      filteredSteps = stepsWithHelp.filter(f => f.bookOnly != true);

    } else {

      filteredSteps = stepsWithHelp.filter(f => f.boxSetOnly != true);

    }

    filteredSteps = filteredSteps.filter((f) => {

      if (f.excludedPartTypes) {

        let excluded = false;

        if (f.excludedPartTypes.filter(excludedPartType => excludedPartType == documentPart.partType).length > 0) {

          excluded = true;

        }

        return !excluded;

      } else {

        return true;

      }
    });

    filteredSteps = filteredSteps.filter((f) => {

      if (f.includedContentOnly) {

        return documentPart.isInsertedBookContent();

      } else {

        return true;

      }
    });


    setSteps(filteredSteps);
    //May have filtered out a step that puts the current step index past the steps length.
    if (currentStep > (filteredSteps.length - 1)) {
      setCurrentStep(0)
    }





  }, [documentPart, documentPart?.bookDraftDocumentPart.getBoxSetBook()?.recipeSource]);

  if (!bookDraft) {
    return <div>No bookdraft</div>
  }

  if (!documentPart) {
    return <div>No documentPart</div>
  }


  let isInsertedBookDocumentPart = documentPart.bookDraftDocumentPart.insertedBook || documentPart.partType === 'BOOK';
  let insertedBook = documentPart?.insertedBook;

  if (!insertedBook) {

    insertedBook = documentPart?.bookDraftDocumentPart.bookDraft?.book; //Indicated this documentPart is an original page of the boxset

  }

  let recipeSource = documentPart.bookDraftDocumentPart.getBoxSetBook()?.recipeSource;
  if (!recipeSource) {

    recipeSource = "boxSetRecipe";

  }

  try {

    if (bookDraft?.book?.boxSet != null) {

      isBoxSet = true;
    }

  } catch (err) {
    console.log(err);
  }

  const isPage = (documentPart) => {

    if (documentPart && documentPart.partType != 'TOPBOOK') {
      return true;
    }
  }





  // 1: <MenuBookIcon />,
  // 2: <DescriptionIcon />,
  // 3: <ViewHeadlineIcon />,
  // 4: <TextFieldsIcon />,
  // 5: <ImageIcon />,
  // 6: <WidgetsIcon />,






  return (
    <Box sx={{
      display: 'flex',
      //border: '2px solid white',
      height: '100%',
      flexDirection: 'column'
    }}>
      <StandardDialog
        title={'Apply Theme'}
        open={showBookmerlinStylesDialog}
        onClose={() => { setShowBookmerlinStylesDialog(false) }}
        dialogContent={<Box>
          <Box>
            Rules for applying themes:
          </Box>
          <Box>
            If you have selected the boxset theme and you are the owner of the boxset, then the theme you select below will be applied to the theme for this boxset.
          </Box>
          <BookMerlinStyles
            documentPart={props.documentPart}
            currentRecipe={props.currentRecipe}
          //lazyGetRecipe={props.lazyGetRecipe}
          />
        </Box>

        }>

      </StandardDialog>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <StandardTabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Format" {...a11yProps(0)} />
          <Tab label="Write" {...a11yProps(1)} />
          <Tab label="Templates" {...a11yProps(2)} />
        </StandardTabs>
      </Box>
      <Box sx={{
        height: '100%',
        flex: 2,
        //border: '2px solid red'
      }}>

        {value == 0 &&
          <Box sx={{
            height: '100%',
            flex: 2,
            //border: '2px solid white'
          }}>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              height: '100%'
            }}>

              <Box sx={{
                width: '20%',
                //border: '5px solid blue'
              }}>
                <StandardStepper steps={steps} step={currentStep} onStepChange={(step) => {
                  setCurrentStep(step);
                  if(step!=currentStep){
                    props.stores.bookStore.disabledFormatProps = {};
                  }
                  
                }} />
              </Box>

              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '80%',
                //border: '5px solid blue'
              }}>

                <ThemeToolbar
                  isBoxSet={isBoxSet}
                  onUpdateEntity={updateEntity}
                  onUpdateRecipe={updateRecipe}
                  bookDraft={bookDraft}
                  documentPart={documentPart}
                  onShowBookmerlinStyles={() => {
                    setShowBookmerlinStylesDialog(true);
                  }} />

                <Box sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end'
                }}>
                  {/* {documentPart.partType} */}
                  {(isInsertedBookDocumentPart && isBoxSet) &&
                    <Typography variant={'h7'} color={'gray'}>Using
                      {recipeSource == 'overrideBookRecipe' ? ' a custom' : ''}
                      {recipeSource == 'originalBookRecipe' ? ' the original' : ''}
                      {recipeSource == "boxSetRecipe" ? " the boxset's" : ""} theme for {insertedBook.title} within this boxset</Typography>

                  }
                  {(!isInsertedBookDocumentPart && isBoxSet) &&
                    <Typography variant={theme.palette.standardTree.heading.variant} color={theme.palette.standardTree.heading.color.main}>Using the boxset's theme for boxset content</Typography>

                  }
                </Box>
                <Box sx={{
                  //height: '100%',
                  flex: 2,
                  //border: '2px solid white'
                }}>
                  {(steps[currentStep] && steps[currentStep].properties) &&
                    steps[currentStep].fixedContent
                  }

                  <Scrollbars
                    autoHide
                    renderThumbHorizontal={() => {
                      return <span />
                    }}
                    style={{ width: '100%', height: '100%', overFlow: 'hidden' }}>
                    {/* <div style={{
                      height: '100%',
                      //paddingBottom:'10em'
                    }}> */}

                    {(steps[currentStep] && steps[currentStep].properties) &&
                      steps[currentStep].properties
                    }
                    {(!steps[currentStep] && steps[0]) &&
                      steps[0].properties
                    }
                    {/* </div> */}

                  </Scrollbars>
                </Box>
              </Box>


            </Box>

          </Box>
        }

        {value == 1 &&
          <Box sx={{
            height: '100%',
            flex: 2,
            //border: '2px solid white' 
          }}>
            <BookDraftEditor
              //subToolbarTitle={'Title bar'}
              //userComments={userComments}
              bookDraft={bookDraft}
              selectedBddp={documentPart?.bookDraftDocumentPart}
              onDocumentPartCreated={(bddp) => {

                //props.stores.bookStore.writingSelectedBookDraftDocumentPart = bddp;

              }} />
          </Box>
        }

        {value == 2 &&
          <Box sx={{
            height: '100%',
            flex: 2,
            //border: '2px solid white'
          }}>
            <TemplatesEditor />
          </Box>
        }
      </Box>

    </Box>
  );
}
export default inject('stores')(observer(MainFormattingPanel));
//export default MainFormattingPanel;
