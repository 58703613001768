import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import StandardDataTable from './StandardDataTable';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import ReactTimeAgo from 'react-time-ago';
import Feather from 'mdi-material-ui/Feather';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import SnapshotsTable from './SnapshotsTable';
import BookEditsTable from './BookEditsTable';
import { useGetOwnedBooks, useOpenBookInWrite, useOpenBookInFormat, useManagerCreateBook } from '../../managers/BooksManager';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import StandardImageViewer from '../StandardImageViewer';
import { useGetOwnedBoxsets } from '../../managers/BoxsetsManager';
import { AvatarGroup } from '@mui/material';
import config from '../../aws-exports';
import ImageTitleTableCell from './ImageTitleTableCell';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CommentIcon from '@mui/icons-material/Comment';
import imageNotFound from '../../assets/images/image-not-found.png'
import StandardRoundedAction from '../actions/StandardRoundedAction';
import SettingsIcon from '@mui/icons-material/Settings';
import TablePopupMenu from '../menus/TablePopupMenu';
import { useMediaQuery } from 'react-responsive';
import PeopleIcon from '@mui/icons-material/People';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import StandardSkeleton from '../../StandardSkeleton';
import OwnedBoxsetsList from '../lists/OwnedBoxsetsList';
import CreateBoxsetAssembly from '../assemblies/CreateBoxsetAssembly';

const OwnedBoxsetsTable = (props) => {

  let { book } = props;
  let { boxset } = props
  const { ownedBoxsets, loading, error } = useGetOwnedBoxsets(props);
  const { openBookInWrite } = useOpenBookInWrite(props);
  const { openBookInFormat } = useOpenBookInFormat(props);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');

  let [showCreateBoxsetDialog, setShowCreateBoxsetDialog] = useState(false);
  let [showDeleteBoxsetDialog, setShowDeleteBoxsetDialog] = useState(false);
  let [selectedBoxset, setSelectedBoxset] = useState(null);

  const isXs = useMediaQuery({ maxWidth: 599 })
  const isSm = useMediaQuery({ maxWidth: 600 })
  const isMd = useMediaQuery({ maxWidth: 900 })
  const isLg = useMediaQuery({ maxWidth: 1200 })
  const isXl = useMediaQuery({ minWidth: 1536 })

  const isRoute = () => {
    return 'primary';
  }


  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {


    setOrder("desc");
    setOrderBy("createdAt");

  }, [ownedBoxsets]);


  const getBoxsetContributedBooks = (boxset) => {
    let allContributedBoxSetBooks = {};
    boxset.boxsetContributors?.map((boxsetContributor) => {

      boxsetContributor.contributedBoxsetBooks.map((bsb) => {
        allContributedBoxSetBooks[bsb.book.id] = bsb;
      });

    });

    return Object.values(allContributedBoxSetBooks);
  }


  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      {!isXs &&
        < StandardDataTable colSpan={8} title={'My Boxsets'}
          noDataMessage={'Create your first boxset!'}
          loading={loading}
          headerActions={<CreateBoxsetAssembly boxset={boxset} />}
          structure={{
            dataDef: {
              data: ownedBoxsets.filter(f => f.deletedAt == null),
              rowKeyDataFieldName: 'id'
            },
            columns: [
              //   {
              //     title: 'Cover',
              //     dataValueFunction: (boxset) => {
              //         return <StandardImageViewer
              //             bucketName={'local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm'}
              //             //s3Path={'uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/b6f59120-18fe-11ed-b9d5-076ac854f9a5_planetStrider.png'}
              //             s3Path={boxset.book?.coverUrl}
              //             height={'5em'}
              //             fit={'contain'}
              //             sx={{
              //                 minHeight: '5em'
              //                 //display: { sm: 'block', lg: 'block', md: 'block' } 
              //             }}
              //             style={{ borderRadius: 2 }}
              //             className="custom-class"
              //             onClick={(e) => {
              //                 // e.preventDefault();
              //                 // setShowGallery(true);

              //             }}
              //             showLoading
              //             errorIcon

              //             /> }
              // },
              //   {
              //     title: 'Title',
              //     dataFieldName: 'title',
              //     dataValueFunction: (boxset) => { return boxset.book.title }
              //   },
              {
                title: 'Title',
                sortable: true,
                sortFieldNameOverride:'title',
                dataValueFunction: (boxset) => {
                  return <ImageTitleTableCell
                    bucketName={config.user_uploads_bucket_name}
                    title={boxset.book.title}
                    s3Src={boxset.book?.coverUrl}
                    onClick={(e) => {
                      e.preventDefault();
                      //console.log(boxset)
                      props.stores.bookStore.boxSetListBoxSet = boxset;
                      navigate("/boxsets/details");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }}
                  />
                }
              },
              {
                title: 'Write',
                dataValueFunction: (boxset) => {
                  return <StandardRoundedAction icon={<Feather sx={{
                    color: theme.palette.standardActions.icon.color.main,

                  }} onClick={(e) => {
                    e.preventDefault();
                    //console.log(boxset)
                    //navigate("/write");
                    openBookInWrite({
                      bookDraftId: boxset.book.primaryBookDraftId
                    });
                  }} />} />
                  {/* <Feather sx={{ color: theme.palette.background.main }} onClick={(e) => {
                    e.preventDefault();
                    console.log(boxset)
                    //navigate("/write");
                    openBookInWrite({
                      bookDraftId: boxset.book.primaryBookDraftId
                    });
                  }} /> */}
                }
              },
              {
                title: 'Format',
                dataValueFunction: (boxset) => {
                  return <StandardRoundedAction icon={<FormatPaintIcon sx={{
                    color: theme.palette.standardActions.icon.color.main,

                  }} onClick={(e) => {
                    e.preventDefault();
                    //navigate("/format");
                    openBookInFormat({
                      bookDraftId: boxset.book.primaryBookDraftId
                    });
                  }} />} />
                 

                },
                ignore: isXs
              },
              {
                title: 'Contributors',
                dataValueFunction: (boxset) => {
                  return <AvatarGroup max={3} sx={{ width: '1.5em', height: '1.5em', cursor: 'pointer', alignItems: 'center', justifyContent: 'center' }}
                    onClick={(e) => {
                      e.preventDefault();
                      //console.log(boxset)
                      props.stores.bookStore.boxSetListBoxSet = boxset;
                      navigate("/boxsets/details/contributors");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }}>
                    {boxset.boxsetContributors?.map((boxsetContributor) => (
                      <Avatar sx={{ width: '1.5em', height: '1.5em' }} alt="" src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + boxsetContributor.contributor.profilePicUrl} />
                    ))}
                  </AvatarGroup>
                },
                ignore: isXs

              },
              {
                title: 'Books',
                dataValueFunction: (boxset) => {

                  // let allContributedBoxSetBooks = [];
                  // boxset.boxsetContributors ?.map((boxsetContributor) => {

                  //   boxsetContributor.contributedBoxsetBooks.map((bsb) => {
                  //     allContributedBoxSetBooks.push(bsb);
                  //   });

                  // });

                  let allContributedBoxSetBooks = getBoxsetContributedBooks(boxset);

                  return <AvatarGroup max={3} sx={{ width: '1.5em', height: '1.5em', cursor: 'pointer', alignItems: 'center', justifyContent: 'center' }} onClick={(e) => {
                    e.preventDefault();
                    //console.log(boxset)
                    props.stores.bookStore.boxSetListBoxSet = boxset;
                    navigate("/boxsets/details/books");
                    // openBookInWrite({
                    //   bookDraftId: book.primaryBookDraftId
                    // });
                  }}>
                    {allContributedBoxSetBooks?.map((contributedBook) => (
                      <Avatar sx={{ width: '1.5em', height: '1.5em' }} variant="square" alt="" src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + contributedBook.book.coverUrl?.split('&&&')[0]} >
                        <img style={{ width: '1.5em', height: '1.5em' }} src={imageNotFound} />
                      </Avatar>
                    ))}
                  </AvatarGroup>
                },
                ignore: isXs
              },
              // {
              //   title: 'Feedback',
              //   dataValueFunction: (boxset) => {
              //     return <IconButton aria-label="delete">
              //       <CommentIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
              //         e.preventDefault();
              //         console.log(boxset)
              //         props.stores.bookStore.boxSetListBoxSet = boxset;
              //         navigate("/boxsets/details/feedback");
              //         // openBookInWrite({
              //         //   bookDraftId: book.primaryBookDraftId
              //         // });
              //       }} />
              //     </IconButton>
              //   },
              //   ignore: isXs
              // },
              {
                title: 'Export',
                dataValueFunction: (boxset) => {
                  return <IconButton aria-label="delete">
                    <FileDownloadIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                      e.preventDefault();
                      //console.log(boxset)
                      props.stores.bookStore.boxSetListBoxSet = boxset;
                      navigate("/boxsets/details/exports");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }} />
                  </IconButton>
                  // <StandardRoundedAction icon={<FileDownloadIcon sx={{
                  //   color: theme.palette.standardActions.icon.color.main,

                  // }} onClick={(e) => {
                  //   e.preventDefault();
                  //   console.log(boxset)
                  //   props.stores.bookStore.boxSetListBoxSet = boxset;
                  //   navigate("/boxsets/details/exports");
                  //   // openBookInWrite({
                  //   //   bookDraftId: book.primaryBookDraftId
                  //   // });
                  // }} />} />

                },
                ignore: isXs
              },
              // {
              //     title: 'Contributors',
              //     dataValueFunction: (boxset) => {
              //         return <IconButton aria-label="">
              //             <CopyAllIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
              //                 e.preventDefault();
              //                 console.log(boxset)
              //                 props.stores.bookStore.boxSetListBoxSet = boxset;
              //                 navigate("/boxsets/details/contributors");
              //                 // openBookInWrite({
              //                 //   bookDraftId: book.primaryBookDraftId
              //                 // });
              //             }} />
              //         </IconButton>
              //     }
              // },
              // {
              //   title: 'Contributor Details',
              //   expandable: true,
              //   exoandedRowComponent: (boxset) => {
              //     return 
              //     <StandardDataTable title={'Contributors'}
              //       structure={{
              //         dataDef: {
              //           data: boxset.boxsetContributors,
              //           rowKeyDataFieldName: 'id'
              //         },
              //         columns: [
              //           {
              //             title: 'Pic',
              //             dataValueFunction: (boxsetContributor) => { return <Avatar sx={{ width: '1.5em', height: '1.5em' }} variant="square" alt="" src="https://www.adobe.com/express/create/cover/media_178ebed46ae02d6f3284c7886e9b28c5bb9046a02.jpeg?width=400&format=jpeg&optimize=medium" /> }
              //           },
              //           {
              //             title: 'Name',
              //             dataValueFunction: (boxsetContributor) => { return boxsetContributor.contributor.name }
              //           },
              //           {
              //             title: 'Foo',
              //             dataValueFunction: (boxsetContributor) => { return boxsetContributor.name }
              //           },
              //         ]
              //       }}
              //     />
              //   }
              // dataDef: {
              //   dataFunction: (boxset) => { return boxset.boxsetContributors },
              //   rowKeyFunction: (boxsetContributor) => { return boxsetContributor.contributor.id },

              // },
              // expandedTitle: <span>Contributors Dude!</span>,
              // columns: [
              //   {
              //     title: 'Pic',
              //     dataValueFunction: (boxsetContributor) => { return <Avatar sx={{ width: '1.5em', height: '1.5em' }} variant="square" alt="" src="https://www.adobe.com/express/create/cover/media_178ebed46ae02d6f3284c7886e9b28c5bb9046a02.jpeg?width=400&format=jpeg&optimize=medium" /> }
              //   },
              //   {
              //     title: 'Name',
              //     dataValueFunction: (boxsetContributor) => { return boxsetContributor.contributor.name }
              //   },
              //   {
              //     title: 'Foo',
              //     dataValueFunction: (boxsetContributor) => { return boxsetContributor.name }
              //   },
              // ]
              // },
              // {
              //   icon: <EditIcon sx={{ color: theme.palette.words?.main }} />,
              //   expandable: false,
              //   expanded: false,
              //   dataDef: {
              //     dataFunction: (boxset) => { return getBoxsetContributedBooks(boxset) },
              //     rowKeyFunction: (boxsetContributor) => { return boxsetContributor.contributor.id },

              //   },
              //   expandedTitle: <span>Books Dude!</span>,
              //   columns: [
              //     {
              //       title: 'Cover',
              //       dataValueFunction: (bsb) => { return <Avatar sx={{ width: '1.5em', height: '1.5em' }} variant="square" alt="" src="https://www.adobe.com/express/create/cover/media_178ebed46ae02d6f3284c7886e9b28c5bb9046a02.jpeg?width=400&format=jpeg&optimize=medium" /> }
              //     },
              //     {
              //       title: 'Title',
              //       dataValueFunction: (bsb) => { return bsb.book.title }
              //     },
              //     {
              //       title: 'Foo',
              //       dataValueFunction: (bsb) => { return bsb.book.id }
              //     },
              //   ]
              // },

              // {
              //   title: 'Write',
              //   dataValueFunction: (boxset) => {
              //     return <Box sx={{
              //       backgroundColor: theme.palette.primary.main,
              //       width: '2.5em',
              //       height: '2.5em',
              //       display: 'flex',
              //       justifyContent: 'center',
              //       borderRadius: '5px',
              //       alignItems: 'center'
              //     }}>
              //       <EditIcon sx={{ color: theme.palette.words?.main }} onClick={(e) => {
              //         e.preventDefault();
              //         console.log(boxset)
              //         //navigate("/write");
              //         openBookInWrite({
              //           bookDraftId: boxset.book.primaryBookDraftId
              //         });
              //       }} />
              //     </Box>
              //   }
              // },
              // {
              //   title: 'Format',
              //   dataValueFunction: (boxset) => {
              //     return <Box sx={{
              //       backgroundColor: theme.palette.primary.main,
              //       width: '2.5em',
              //       height: '2.5em',
              //       display: 'flex',
              //       justifyContent: 'center',
              //       borderRadius: '5px',
              //       alignItems: 'center'
              //     }}>
              //       <FormatPaintIcon sx={{ color: theme.palette.words?.main }} onClick={(e) => {
              //         e.preventDefault();
              //         //navigate("/format");
              //         openBookInFormat({
              //           bookDraftId: boxset.book.primaryBookDraftId
              //         });
              //       }} />
              //     </Box>

              //   }
              // },
              {
                title: 'Created',
                dataFieldName: 'createdAt',
                sortable: true,
                dataValueFunction: (boxset) => { return boxset.createdAt != null ? <ReactTimeAgo date={boxset.createdAt} locale="en-US" /> : '' },
                ignore: isXs
              },
              // {
              //   title: 'Delete',
              //   dataValueFunction: (boxset) => {
              //     return <IconButton aria-label="delete">
              //       <TrashCanOutline sx={{ color: theme.palette.background.main }} onClick={(e) => {
              //         e.preventDefault();
              //         console.log(boxset);
              //         setSelectedBoxset(boxset);
              //         setShowDeleteBoxsetDialog(true);

              //       }} />
              //     </IconButton>
              //   }
              // },
              {
                title: '',
                dataValueFunction: (boxset) => {
                  return <TablePopupMenu
                    menuItems={[
                      // <ListItemButton onClick={(e) => {
                      //   e.preventDefault();
                      //   console.log(boxset)
                      //   props.stores.bookStore.boxSetListBoxSet = boxset;
                      //   navigate("/boxsets/details");
                      //   // openBookInWrite({
                      //   //   bookDraftId: book.primaryBookDraftId
                      //   // });
                      // }}  >

                      //   <ListItemIcon>
                      //     <SettingsIcon sx={{ color: theme.palette.background.main }} />
                      //   </ListItemIcon>
                      //   <ListItemText primary="Details" />
                      // </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(boxset)
                        props.stores.bookStore.boxSetListBoxSet = boxset;
                        navigate("/boxsets/details/contributors");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }} >

                        <ListItemIcon>
                          <PeopleIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Contributors" />
                      </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(boxset)
                        props.stores.bookStore.boxSetListBoxSet = boxset;
                        navigate("/boxsets/details/books");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }} >

                        <ListItemIcon>
                          <MenuBookIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Books" />
                      </ListItemButton>,
                      // <ListItemButton onClick={(e) => {
                      //   e.preventDefault();
                      //   console.log(boxset)
                      //   props.stores.bookStore.boxSetListBoxSet = boxset;
                      //   navigate("/boxsets/details/feedback");
                      //   // openBookInWrite({
                      //   //   bookDraftId: book.primaryBookDraftId
                      //   // });
                      // }} >

                      //   <ListItemIcon>
                      //     <CommentIcon sx={{ color: theme.palette.background.main }} />
                      //   </ListItemIcon>
                      //   <ListItemText primary="Feedback" />
                      // </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(boxset)
                        props.stores.bookStore.boxSetListBoxSet = boxset;
                        navigate("/boxsets/details/exports");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }}  >

                        <ListItemIcon>
                          <FileDownloadIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Export" />
                      </ListItemButton>,
                    ]}
                  />
                },
                ignore: !isXs
              },
            ]
          }} />

      }
      {isXs &&
        <OwnedBoxsetsList
          filteredBooks={[]}
          showSelectIcon
          onSelected={(boxset) => {

            if (boxset) {

              //console.log(boxset)
              props.stores.bookStore.boxSetListBoxSet = boxset;
              navigate("/boxsets/details");

            }

          }}
        />
      }
      {(loading && (!ownedBoxsets || ownedBoxsets.length == 0)) &&
        <StandardSkeleton />
      }
    </Box>

  )
}
export default inject('stores')(observer(OwnedBoxsetsTable));