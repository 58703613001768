import React, { useEffect, useState } from 'react';
import { observable } from 'mobx';
import { useNavigate, useLocation } from "react-router-dom";
import {
  useGetUserReceivedEditRequests,
  useCreateEditRequest
} from '../persistance/persistanceHooks';
import { useGetBookDraft, useLazyGetBookDraft, useGetBookEditRequests } from '../persistance/persistanceHooks';
import { EditRequest, BookDraft } from '../domain/bookModel';


export function useManagerCreateEditRequest(props) {

  const [createEditRequest, { data: createEditRequestData, loading: createEditRequestLoading, error: createEditRequestError }] = useCreateEditRequest();

  const wrapper = ({
    book,
    userId,
    editorUserId
  }, callback) => {
    try {

      createEditRequest({
        bookId: book.id,
        editorUserId: editorUserId,
        originalBookDraftId: book.primaryBookDraftId,
        requestorUserId: userId

      });

    } catch (err) {
      console.log(err);
    }

  }



  return {
    createEditRequest: wrapper,
    loading: createEditRequestLoading, 
    error: createEditRequestError
  }
}


export function useOpenEditRequestInEdit(props) {


  let navigate = useNavigate();
  let location = useLocation();

  const openEditRequestInEdit = ({
    editRequest
  }) => {

    props.stores.bookStore.writeSplitScreen = false;
    props.stores.bookStore.selectedReceivedEditRequest = editRequest;
    props.stores.bookStore.selectedSentEditRequest = editRequest;

    props.stores.bookStore.rightWritingBookDraft = null;
    props.stores.bookStore.rightWritingBookDraftId = null;
    props.stores.bookStore.rightWritingSelectedBookDraftDocumentPart = null;
    props.stores.bookStore.rightWritingBookDraftTitle = null;
    
    navigate('/edit');

  }


  return {
    openEditRequestInEdit
  }


}

export function useOpenEditRequestInWrite(props) {


  let navigate = useNavigate();
  let location = useLocation();

  const openEditRequestInWrite = ({
    editRequest
  }) => {

    props.stores.bookStore.selectedReceivedEditRequest = editRequest;
    props.stores.bookStore.selectedSentEditRequest = editRequest;

    
    //if (resetWritingBookDraft) {
      //props.stores.bookStore.writingBookDraft = null;
    //}
    props.stores.bookStore.writingBookDraftId = editRequest.originalBookDraftId;

    props.stores.bookStore.rightWritingBookDraft = null;
    props.stores.bookStore.rightWritingBookDraftId = editRequest.editBookDraftId;
    props.stores.bookStore.rightWritingSelectedBookDraftDocumentPart = null;
    props.stores.bookStore.rightWritingBookDraftTitle = 'Editor\' Copy';

    props.stores.bookStore.writeSplitScreen = true;
    navigate("/write");




    // props.stores.bookStore.selectedReceivedEditRequest = editRequest;
    // props.stores.bookStore.selectedSentEditRequest = editRequest;
    
    // navigate('/edit');

  }


  return {
    openEditRequestInWrite
  }


}

export function useOpenEditRequestInEdit_6Aug2023(props) {


  let navigate = useNavigate();
  let location = useLocation();

  const openEditRequestInEdit = ({
    editRequest
  }) => {

    
    props.stores.bookStore.selectedReceivedEditRequest = editRequest;
    props.stores.bookStore.selectedSentEditRequest = editRequest;
    
    navigate('/edit');

  }


  return {
    openEditRequestInEdit
  }


}





export function useLoadEditRequestForEdit(props) {


  let [editingBookDraft, setEditingBookDraft] = useState();
  let [originalBookDraft, setOriginalBookDraft] = useState();

  
  let navigate = useNavigate();
  let location = useLocation();

	const [lazyGetEditingBookDraft, { loading: lazyGetEditingBookDraftLoading, error: lazyGetEditingBookDraftError, data: lazyGetEditingBookDraftData, refetch: lazyGetEditingBookDraftRefetch }] = useLazyGetBookDraft({
		//fetchPolicy: 'network-only'
	});


  const [lazyGetOriginalBookDraft, { loading: lazyGetOriginalBookDraftLoading, error: lazyGetOriginalBookDraftError, data: lazyGetOriginalBookDraftData, refetch: lazyGetOriginalBookDraftRefetch }] = useLazyGetBookDraft({
		//fetchPolicy: 'network-only'
	});


  //props.stores.bookStore.writeSplitScreen = true;

	useEffect(() => {

		//console.log(lazyGetOriginalBookDraftData);
		if (lazyGetOriginalBookDraftData) {

			try {

				setOriginalBookDraft(new BookDraft(lazyGetOriginalBookDraftData.getBookDraft));
				// if (!props.stores.bookStore.editingSelectedBookDraftDocumentPart) {
				// 	props.stores.bookStore.editingSelectedBookDraftDocumentPart = editingBookDraft.rootBookDraftDocumentPart;
				// }

			} catch (err) {

				console.log(err);
			}

		}

	}, [lazyGetOriginalBookDraftData]);


  useEffect(() => {

		//console.log(lazyGetEditingBookDraftData);
		if (lazyGetEditingBookDraftData) {

			try {

				setEditingBookDraft(new BookDraft(lazyGetEditingBookDraftData.getBookDraft));
				if (!props.stores.bookStore.editingSelectedBookDraftDocumentPart) {
					props.stores.bookStore.editingSelectedBookDraftDocumentPart = editingBookDraft.rootBookDraftDocumentPart;
				}

			} catch (err) {

				console.log(err);
			}

		}

	}, [lazyGetEditingBookDraftData]);



  const loadEditRequestForEdit = ({
    editRequest
  }) => {

   
    
    if(editRequest && editRequest?.acceptedAt){
			lazyGetEditingBookDraft({
				variables: {
					bookDraftId: editRequest.editBookDraftId
				}
			});

		}

    if(editRequest && editRequest?.acceptedAt){
			lazyGetOriginalBookDraft({
				variables: {
					bookDraftId: editRequest.originalBookDraftId
				}
			});

		}
    


  }


  return {
    loadEditRequestForEdit,
    editingBookDraft,
    originalBookDraft
  }


}





export function useLoadBookEditRequests(props, bookId) {

  const { loading: getBookEditRequestsLoading, error: getBookEditRequestsError, data: getBookEditRequestsData, refetch: getBookEditRequestsRefetch } = useGetBookEditRequests({
    bookId: bookId
  });


  return {
    loading: getBookEditRequestsLoading, error: getBookEditRequestsError, data: getBookEditRequestsData
  }

}







export function useGetReceivedEditRequests(props) {

  const [editRequests, setEditRequests] = useState([]);

  const { loading, error, data, refetch } = useGetUserReceivedEditRequests({
    userId: 'dummyId'
  });

  useEffect(() => {


    try {

      if (data) {


        data.getUser.editRequests.items.forEach((editRequest, index) => {
          let editRequestBook = editRequest.book;
          let selected = false;

          // if (selectedEditRequest && selectedEditRequest.id == editRequest.id) {
          //   props.stores.bookStore.editRequestListReceivedEditRequest = editRequest;
          // }

          // if (selectedEditRequest == null && index == 0) {
          //   props.stores.bookStore.editRequestListReceivedEditRequest = editRequest;
          // }
        });


        setEditRequests(data.getUser.editRequests.items.map((item) => {
          return new EditRequest({ data: item })
        }));
      }

    } catch (err) {
      console.log(err);
    }

  }, [data]);

  return { editRequests, error, loading };
}



