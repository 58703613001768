import React, { useEffect, useState } from 'react';
import { styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";
import { v4 as uuid } from "uuid";
import Image from 'mui-image';
import config from '../aws-exports';

function StandardImageViewer(props) {

  
    let { bucketName, s3Path, url } = props;

    let correctedSrc = s3Path;
    let correctedBucketName = bucketName;
    try {

      correctedSrc = s3Path.split("&&&")[0];

      let fileSource = s3Path.split("&&&")[1];

      if(fileSource && fileSource=='commonImages'){
        correctedBucketName = config.common_content_bucket_name;
      }

      if(fileSource && fileSource=='userImages'){
        correctedBucketName = config.user_uploads_bucket_name;
      }

      
    }
    catch (err) {

    }



    let src ='https://' + correctedBucketName + ".s3.amazonaws.com/" + correctedSrc;

    if(url){
        src = url;
    }


    
  return (

    <Box  sx={{
     
    }}>
     {/* <div>{src}</div> */}
     <Image
            Key={uuid()}
            duration={0}
            {...props}
            src={src}

             />


    </Box>




  );
}


export default inject('stores')(observer(StandardImageViewer));



