import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import config from '../../aws-exports';
import { Typography } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import StretchBox from '../boilerPlate/StretchBox';
import { Box, Button, SwipeableDrawer } from '@mui/material';
import ScrollBox from '../boilerPlate/ScrollBox';
import StandardUICard from '../UI/StandardUICard';
import ListSharedBooks from './lists/ListSharedBooks';
import StandardUIButton from '../UI/StandardUIButton';
import { useGetSharedBooks, useOpenBookInWrite, useOpenBookInRead, useOpenBookInFormat, useManagerCreateBook } from '../../managers/BooksManager';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import StandardUITextField from '../UI/StandardUITextField';
import StandardUIDialog from '../UI/StandardUIDialog';


const SharedBooks = (props) => {

    let { sharedBook } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdAt');


    const [openOptOut, setOpenOptOut] = React.useState(false);
    const handleOpenOptOut = () => {
        setOpenOptOut(true);
    };

    const handleCloseOptOut = (value) => {
        setOpenOptOut(false);
    };


    const { openBookInRead } = useOpenBookInRead(props);


    const [showDetailDrawer, setShowDetailDrawer] = useState(false);
    let [selectedBook, setSelectedBook] = useState(null);

    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setShowDetailDrawer(open);
            };


    return (

        <StretchBox flexDirection='row' sxProps={{ gap: isSmallScreen ? 0 : 2 }}>

            <StretchBox sxProps={{
                flex: isSmallScreen ? 12 : 4,
                position: 'relative'
            }}>
                {/* <BoxsetsTable onSelectBoxSet={
                    (boxset) => {
                        setSelectedBoxset(boxset)
                        setShowDetailDrawer(true)
                    }} /> */}

                <ScrollBox>
                    <StandardUICard
                        title='Shared Books'
                        showPagination={false}
                        showSearchField={true}
                        onSetOrdering={(orderBy, order) => { setOrder(order); setOrderBy(orderBy) }}
                        children={
                            <ListSharedBooks
                                selectedBook={selectedBook}
                                order={order}
                                orderBy={orderBy}
                                onSelectBook={(sharedBook) => {
                                    setSelectedBook(sharedBook)
                                    setShowDetailDrawer(true)
                                }}
                                // onReadClick={(e) => {
                                //     e.preventDefault();
                                //     console.log(book)
                                //     openBookInRead({
                                //         bookDraftId: book.primaryBookDraftId
                                //     });
                                // }}
                                // onClick={(event) => {
                                //     setShowDetailDrawer(true)
                                //     handleListItemClick(event, 0)
                                // }}
                                s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + sharedBook?.coverUrl}
                                showReadIcon={true}
                            />
                        }
                    />




                </ScrollBox>

            </StretchBox>

            <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>

                    <StandardUICard
                        title='Details'
                        showPagination={false}
                        showSearchField={false}
                        // actions={ }
                        children={
                            <div>
                                <StandardUITextField label='Title' value={selectedBook?.title} disabled />
                                <StandardUITextField label='Subtitle' value='Subtitle' disabled />
                                <StandardUITextField label='Author' value='Author' disabled />
                                <Typography>Shared Since</Typography>
                                <StandardUIButton
                                    sx={{ marginTop: '1em' }}
                                    buttonAction='Remove'
                                    onClick={() => (
                                        setOpenOptOut(true)
                                    )}
                                />
                            </div>

                        }
                    />


                </ScrollBox>
            </StretchBox>

            <StandardUIDialog
                title='Opt Out of Shared Book'
                open={openOptOut}
                onClose={handleCloseOptOut}
                dialogContent={
                    <Typography>Opt out of shared book</Typography>
                }
                cardActions={
                    <StandardUIButton
                        buttonAction='Opt Out'
                    />
                }
            />

            <SwipeableDrawer
                anchor={'right'}
                open={showDetailDrawer && isSmallScreen}
                onClose={toggleDrawer(false)}
                sx={{ width: '100%' }}
            //variant='persistent'
            //onOpen={setShowDetailDrawer(true)}
            >
                <div style={{ width: '100vw' }}>

                    <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                        <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2, ml: 0, mr: 0 }}>
                            <IconButton
                                style={{ display: 'flex', justifyContent: 'left' }}
                                onClick={() => {
                                    setShowDetailDrawer(false)
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography
                                variant={theme.landmarkText.variant}
                                gutterBottom
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                {selectedBook?.title}
                            </Typography>
                            <StandardUICard
                                title='Details'
                                showPagination={false}
                                showSearchField={false}
                                // actions={ }
                                children={
                                    <div>
                                        <StandardUITextField label='Title' value={selectedBook?.title} disabled />
                                        <StandardUITextField label='Subtitle' value='Subtitle' disabled />
                                        <StandardUITextField label='Author' value='Author' disabled />
                                        <Typography>Shared Since</Typography>
                                        <StandardUIButton
                                            sx={{ marginTop: '1em' }}
                                            buttonAction='Remove'
                                            onClick={() => (
                                                setOpenOptOut(true)
                                            )}
                                        />
                                    </div>
                                }
                            />


                        </ScrollBox>
                    </StretchBox>
                </div>
            </SwipeableDrawer>


        </StretchBox>
    )
}
export default inject('stores')(observer(SharedBooks));