import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import config from '../../aws-exports';
import useMediaQuery from '@mui/material/useMediaQuery';
import StretchBox from '../boilerPlate/StretchBox';
import { Box, Button, SwipeableDrawer, Typography } from '@mui/material';
import ScrollBox from '../boilerPlate/ScrollBox';
import StandardUICard from '../UI/StandardUICard';
import ListSharedBoxsets from './lists/ListSharedBoxsets';
import StandardUITextField from '../UI/StandardUITextField';
import StandardUIButton from '../UI/StandardUIButton';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListBoxsetContributors from './lists/ListBoxsetContributors';
import ListBoxsetContributedBooks from './lists/ListBoxsetContributedBooks';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import AddContributor from './dialogs/AddContributor';
import AddContributedBook from './dialogs/AddContributedBook';


const SharedBoxsets = (props) => {

    let { boxset } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdAt');
    const [showAddContributor, setShowAddContributor] = useState(false);
    const [showAddContributedBook, setShowAddContributedBook] = useState(false);




    const [showDetailDrawer, setShowDetailDrawer] = useState(false);
    let [selectedBoxset, setSelectedBoxset] = useState(null);

    const handleAddContributorOpen = () => {
        setShowAddContributor(true);
    }

    const handleAddContributorClose = () => {
        setShowAddContributor(false);
    }

    const handleAddContributedBookOpen = () => {
        setShowAddContributedBook(true);
    }

    const handleAddContributedBookClose = () => {
        setShowAddContributedBook(false);
    }

    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setShowDetailDrawer(open);
            };


    return (

        <StretchBox flexDirection='row' sxProps={{ gap: isSmallScreen ? 0 : 2 }}>

            <StretchBox sxProps={{
                flex: isSmallScreen ? 12 : 4,
                position: 'relative'
            }}>
                {/* <BoxsetsTable onSelectBoxSet={
                    (boxset) => {
                        setSelectedBoxset(boxset)
                        setShowDetailDrawer(true)
                    }} /> */}

                <ScrollBox>
                    <StandardUICard
                        title='Shared Boxsets'
                        showPagination={false}
                        showSearchField={true}
                        onSetOrdering={(orderBy, order) => { setOrder(order); setOrderBy(orderBy) }}
                        children={
                            <ListSharedBoxsets
                                selectedBoxset={selectedBoxset}
                                order={order}
                                orderBy={orderBy}
                                onSelectBoxset={(boxset) => {
                                    setSelectedBoxset(boxset)
                                    setShowDetailDrawer(true)
                                }} />
                        }
                    />




                </ScrollBox>

            </StretchBox>

            <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>


                    <StandardUICard
                        title='Details'
                        showPagination={false}
                        showSearchField={false}
                        // actions={ }
                        children={
                            <div>
                                <StandardUITextField label='Title' value={selectedBoxset?.book.title} disabled />
                                <StandardUITextField label='Subtitle' value={selectedBoxset?.book.subtitle} disabled />
                                <StandardUITextField label='Author' value={selectedBoxset?.book.author} disabled />
                                <Typography>Shared Since</Typography>
                                <StandardUIButton
                                    sx={{ marginTop: '1em' }}
                                    buttonAction='Remove'
                                />
                            </div>
                        }
                    />
                    <StandardUICard
                        title='Contributors'
                        showPagination={true}
                        showSearchField={true}
                        // actions={
                        //     <Fab color="primary" size='small'
                        //         onClick={handleAddContributorOpen}
                        //     >
                        //         <AddIcon />
                        //     </Fab>
                        // }
                        children={
                            <ListBoxsetContributors boxset={selectedBoxset} />
                        }
                    />
                    <StandardUICard
                        title='Contributed Books'
                        showPagination={true}
                        showSearchField={true}
                        actions={
                            <Fab color="primary" size='small'
                                onClick={handleAddContributedBookOpen}
                            >
                                <AddIcon />
                            </Fab>
                        }
                        children={
                            <ListBoxsetContributedBooks boxset={selectedBoxset} />
                        }
                    />


                </ScrollBox>
            </StretchBox>

            <SwipeableDrawer
                anchor={'right'}
                open={showDetailDrawer && isSmallScreen}
                onClose={toggleDrawer(false)}
                sx={{ width: '100%' }}
            //variant='persistent'
            //onOpen={setShowDetailDrawer(true)}
            >
                <div style={{ width: '100vw' }}>

                    <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                        <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2, ml: 0, mr: 0 }}>
                            <IconButton
                                style={{ display: 'flex', justifyContent: 'left' }}
                                onClick={() => {
                                    setShowDetailDrawer(false)
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography
                                variant={theme.landmarkText.variant}
                                gutterBottom
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                {selectedBoxset?.book.title}
                            </Typography>
                            <StandardUICard
                                title='Details'
                                showPagination={false}
                                showSearchField={false}
                                // actions={ }
                                children={
                                    <div>
                                        <StandardUITextField label='Title' value={selectedBoxset?.book.title} disabled />
                                        <StandardUITextField label='Subtitle' value={selectedBoxset?.book.subtitle} disabled />
                                        <StandardUITextField label='Author' value={selectedBoxset?.book.author} disabled />
                                        <Typography>Shared Since</Typography>
                                        <StandardUIButton
                                            sx={{ marginTop: '1em' }}
                                            buttonAction='Remove'
                                        />
                                    </div>
                                }
                            />
                            <StandardUICard
                                title='Contributors'
                                showPagination={true}
                                showSearchField={true}
                                // actions={
                                //     <Fab color="primary" size='small'
                                //         onClick={handleAddContributorOpen}
                                //     >
                                //         <AddIcon />
                                //     </Fab>
                                // }
                                children={
                                    <ListBoxsetContributors boxset={selectedBoxset} />
                                }
                            />
                            <StandardUICard
                                title='Contributed Books'
                                showPagination={true}
                                showSearchField={true}
                                actions={
                                    <Fab color="primary" size='small'
                                        onClick={handleAddContributedBookOpen}
                                    >
                                        <AddIcon />
                                    </Fab>
                                }
                                children={
                                    <ListBoxsetContributedBooks boxset={selectedBoxset} />
                                }
                            />


                        </ScrollBox>
                    </StretchBox>
                </div>
            </SwipeableDrawer>

            <AddContributor
                boxset={selectedBoxset}
                open={showAddContributor}
                onClose={handleAddContributorClose}
            />

            {/* AddContributedBook */}
            <AddContributedBook
                boxset={selectedBoxset}
                open={showAddContributedBook}
                onClose={handleAddContributedBookClose}
            />

        </StretchBox>
    )
}
export default inject('stores')(observer(SharedBoxsets));