import React, { useEffect, useState } from 'react';
import { Avatar, styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";
import { v4 as uuid } from "uuid";
import Image from 'mui-image';
import config from '../../aws-exports';
import StandardImageViewer from './StandardImageViewer';


function BookImageViewer(props) {

   

  return (

    <StandardImageViewer
      bucketName={config.user_uploads_bucket_name}
      s3Path={props.s3Path}
      height={'5em'}
      fit={'contain'}
      sx={{
        minHeight: '5em'
        //display: { sm: 'block', lg: 'block', md: 'block' } 
      }}
      style={{ borderRadius: 2 }}
      className="custom-class"
      onClick={(e) => {
        // e.preventDefault();
        // setShowGallery(true);

      }}
    // showLoading
    // errorIcon

    />



  );
}


export default inject('stores')(observer(BookImageViewer));



