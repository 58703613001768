import React, { useEffect, useState } from 'react';
import { Button, FormControlLabel, Radio, RadioGroup, styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import BaseControl from "../BaseControl";
import { useTheme } from '@mui/material/styles';
import { Box, Checkbox } from "@mui/material";
// import { BuildManager } from "../../TemplateManager";
import { BuildManager } from '../../BuildManager';
import Image from 'mui-image';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import config from '../../../../aws-exports';
import StandardDialog from '../../../StandardDialog';
import StandardImageGallery from '../../../image/StandardImageGallery';
import StandardImageViewer from '../../../StandardImageViewer';
import { Checkbox as MuiCheckbox } from '@mui/material';
import FormatImageSelector from '../FormatImageSelector';
import SelfOverrideSwitch from './SelfOverrideSwitch';
import PropsRadioButtons from './PropsRadioButtons';
import SubHeading from '../SubHeading';
import PropertiesFactory from '../../PropertiesFactory';
import { RecipeUtils } from '../../RecipeUtils';
import StandardEntityImageSelector from '../../../StandardEntityImageSelector';

function PropsTemplateSelector(props) {

  const [templates, setTemplates] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState("NO_TEMPLATE");
  const [showGallery, setShowGallery] = useState(false);
  const [galleryItems, setGalleryItems] = useState([]);
  const [paramControls, setParamControls] = useState([]);
  const [propControls, setPropControls] = useState([]);
  const [paramControlChangedTimestamp, setParamControlChangedTimestamp] = useState(Date.now());

  const theme = useTheme();


  let bm = new BuildManager();

  let baseControl = new BaseControl(props);
  let recipeParam = baseControl.getRecipeParam();

  const { classes, element, bookDraft, documentPart } = props;
  let { inflatablesBookDraft, currentInflatedTemplates, bookMerlinContext, isDevMode } = props.stores.bookStore;

  let item = documentPart;
  let properties_book = bookDraft.book;
  let boxSet = bookDraft.book.boxSet;

  let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();
  let hideBookProperties = false;

  let canEdit = true;


  let isOwner = true;




  let editTemplateButton = isDevMode ? <Box sx={{
    backgroundColor: 'yellow',
    color: 'black'
  }} onClick={(e) => {
    e.preventDefault();
    props.stores.bookStore.addEditTemplateId({
      title: selectedTemplate.title,
      templateId: selectedTemplate.id,
      templateBddp: selectedTemplate.bookDraftDocumentPart
    })
  }}>EDIT</Box> : <></>


  const loadTemplates = () => {

    //console.log('loadTemplates...')

    let bookMerlinContext = props.stores.bookStore.currentBookMerlinContext;
    //let currentRecipe = this.props.stores.bookStore.activeRecipe;
    let currentRecipe = documentPart?.getRecipe();


    let bm = new BuildManager();

    let templateSourceTag = element.templateSourceTag;



    if (element.configType == 'LOOKUP') {

      if (element.configDetails == 'HEADER_BY_PART_TYPE') {

        let partMapping = baseControl.partTypeParamMappings[documentPart.partType];
        if (partMapping && partMapping.hasHeader) {
          templateSourceTag = partMapping.headerTemplateTag;
        }


      }
    }

    let templates = bm.getTemplatesByTag({
      recipe: currentRecipe,
      context: bookMerlinContext,
      inflatedTemplates: currentInflatedTemplates,
      tag: templateSourceTag
    })

    //console.log(templates);
    setTemplates(templates);

  }

  const selectTemplate = (templateId) => {

    try {

      //baseControl.updateRecipe(templateId);
      let currentRecipe = props.documentPart?.getRecipe().json;
      let recipeParamPath = element.recipeParamPath;
      //new RecipeUtils().setRecipeParamByPartType(null, currentRecipe, documentPart, "HEADING.TEMPLATE.ID", templateId);
      //new RecipeUtils().setRecipeParamByPartType(null, currentRecipe, documentPart, recipeParamPath, templateId);




      let templateDocument = inflatablesBookDraft.getDocumentPartById(templateId);
      setSelectedTemplate(templateDocument);
      updatePropsUI(templateDocument);

      baseControl.updateRecipeProp(templateId);

      props.stores.bookStore.recipeTimestamp = new Date().getTime();


    } catch (err) {

      console.log(err);
    }




  }

  const updateGalleryItems = () => {




    if (inflatablesBookDraft && templates) {


      let templateItems = [];

      let templateIds = [];
      try {
        templateIds = Object.keys(templates);
      } catch (err) {
        console.log(err);
      }
      templateItems = templateIds.map((templateId, i) => {


        let templateDocument = inflatablesBookDraft.getDocumentPartById(templateId);

        let isSelected = false;
        if (selectedTemplate && selectedTemplate.id == templateId) {
          isSelected = true;
        }
        // if (templateId == currentTemplateId && selectedTemplate?.id != currentTemplateId) {
        //   //setSelectedTemplate(templateDocument);
        // }

        //let backgroundColor = isSelected ? 'red' : 'yellow';
        let selectedBorder = isSelected ? '3px solid black' : '';

        return <Box key={templateDocument?.id} sx={{
          border: selectedBorder,
          //backgroundColor: backgroundColor
        }}>
          {isDevMode &&

            <StandardEntityImageSelector
              showInformation={false}
              title={'Preview Image'}
              entity={templateDocument}
              imageKeyFieldName={'headingUrl'} />

          }

          <Image
            height={'15em'}
            fit={'contain'}

            style={{ borderRadius: 2 }}
            className="custom-class"
            onClick={(e) => {
              e.preventDefault();
              selectTemplate(templateId);

            }}
            src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + templateDocument?.headingUrl}

            showLoading
            errorIcon />
          {isDevMode &&
            <ImageListItemBar
              title={templateDocument?.title}
              subtitle={<span>by: {templateDocument?.title}</span>}
              position="below"
              // actionIcon={
              //   <IconButton
              //     sx={{ color: 'white' }}
              //     aria-label={`star ${templateDocument?.title}`}
              //   >
              //     <StarBorderIcon onClick={(e) => {
              //       e.preventDefault();
              //       props.stores.bookStore.addEditTemplateId({
              //         title: templateDocument.title,
              //         templateId: templateId,
              //         templateBddp: templateDocument.bookDraftDocumentPart
              //       })
              //     }} />
              //   </IconButton>
              // }
              actionPosition="left"
              actionIcon={editTemplateButton ? editTemplateButton : <></>}
            />
          }

        </Box>
      });

      setGalleryItems(templateItems);

    }

  }


  useEffect(() => {
    updateGalleryItems();
  }, [isDevMode]);

  useEffect(() => {
    loadTemplates();
  }, []);

  useEffect(() => {
    loadTemplates();
  }, [currentInflatedTemplates]);

  useEffect(() => {
    updateSelectedTemplate();
  }, [templates, documentPart, documentPart?.bookDraftDocumentPart.getBoxSetBook()?.recipeSource, props.stores.bookStore.recipeTimestamp]);


  useEffect(() => {
    updateGalleryItems();
  }, [props.stores.bookStore.inflatablesBookDraft, templates, currentInflatedTemplates, selectedTemplate]);

  useEffect(() => {
    let currentRecipe = documentPart?.getRecipe();
    let disabled = false;
    let template = templates[selectedTemplate?.id];
    if (!template) {
      return;
    }

    let controls = template.props;
    const propertiesFactory = new PropertiesFactory();

    if (controls) {
      controls = controls.map((element) => {

        return propertiesFactory.createControl({
          element,
          bookDraft,
          documentPart,
          // onUpdateRecipe,
          // onUpdateEntity,
          //canEdit,
          // boxSetBook
        });

      })
    }





    let paramControls = template.params.filter((param) => param.dynamic);


    paramControls = paramControls.map((param) => {

      //return <div>{param.label}:{param.dynamic?.valueType}</div>
      ////console.log(param);

      let paramName = param.dynamic.recipeParamName.replace('$partType$', documentPart.partType.toLowerCase());
      let paramNameCapped = param.dynamic.recipeParamName.replace('$partType$', documentPart.partType.charAt(0).toUpperCase() + documentPart.partType.toLowerCase().slice(1));

      let paramLabel = param.label;

      //console.log(paramName);

      let currentParam = bm.getParam({
        paramId: paramName,
        paramSource: 'RECIPE',
        context: bookMerlinContext,
        recipe: currentRecipe.json,
        recipeObject: currentRecipe
      })







      if (param.dynamic &&
        param.dynamic.valueType != null &&
        param.dynamic.valueType == 'fontChooser') {

        return <Box sx={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography color={'secondary'} variant={'h6'}>{paramLabel ? paramLabel : paramName}</Typography>


          < FormatImageSelector
            bookDraft={bookDraft}
            documentPart={documentPart}
            element={{

              type: 'IMAGE_POPUP_SELECTOR',
              title: param.label,
              paramType: 'RECIPE',
              //paramItem: 'BOOK',
              bucketName: config.common_content_bucket_name,
              folder: 'public/fonts',
              level: 'public',
              isFontParam: true,
              recipeParamName: param.dynamic.recipeParamName,
              //recipeMediaTypeParamName: 'global_common_titleFontMediaType',
              identitySource: 'ITEM_OWNER',
              canEdit: 'ITEM_OWNER'
            }}
            canEdit={canEdit}
            onUpdateRecipe={props.onUpdateRecipe}
            onUpdateEntity={props.onUpdateEntity} />
        </Box>

      }


      if (param.dynamic &&
        param.dynamic.valueType != null &&
        param.dynamic.valueType == 'props_multi') {

        let options = param.dynamic.options;
        // if (param.dynamic.options) {
        //   options = param.dynamic.options.map((option) => {

        //     return { label: 'None', value: 'NONE' }

        //   });

        // }

        return <Box sx={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          <SubHeading
            bookDraft={bookDraft}
            documentPart={documentPart}
            element={{
              type: 'SUBHEADING',
              title: param.label,
              addTopMargin: false,
              // showRecipeSourceContext: true,
              // showHeadingSourceContext: true
            }}
          // boxSetBook={boxSetBook} 
          />

          <PropsRadioButtons
            bookDraft={bookDraft}
            documentPart={documentPart}
            element={{
              type: 'PROPS_RADIO_BUTTONS',
              title: 'Title Text Type',
              paramType: 'RECIPE',
              recipeParamPath: param.dynamic.recipeParamPath,
              recipeParamDefaultValue: param.dynamic.recipeParamDefaultValue,
              //recipeParamName: 'global_common_headingTitleTextTransform',
              //paramItem:'BOXSETBOOK',
              //entityFieldName:'recipeSource',

              options,
              // options: [
              // 	{ label: 'None', value: 'NONE' },
              // 	{ label: 'Small', value: 'SMALL' },
              // 	{ label: 'Medium', value: 'MEDIUM' },
              // 	{ label: 'Large', value: 'LARGE' }
              // ],
              canEdit: 'ITEM_OWNER',
            }}
            canEdit={canEdit}
          //onUpdateRecipe={onUpdateRecipe}
          //onUpdateEntity={onUpdateEntity}
          />
        </Box>
      }



      if (param.dynamic &&
        param.dynamic.valueType != null &&
        param.dynamic.valueType == 'multi') {

        if (!currentParam) {

          paramName = paramNameCapped;

          if (!currentParam) {
            //currentRecipe.json.addParam(paramName);
            return <div>Property not found ( radios ):{paramName}</div>

          }

        }
        let options = [];
        if (param.dynamic.options) {
          options = param.dynamic.options.map((option) => {

            return (
              <FormControlLabel value={option} control={
                <Radio
                  color={'secondary'}
                  disabled={disabled}
                  onChange={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    //console.log(event);

                    currentParam.value = event.target.value;
                    setParamControlChangedTimestamp(Date.now())
                    setTimeout(() => {

                      try {

                        props.stores.bookStore.recipeTimestamp = new Date().getTime();

                        if (currentRecipe) {

                          setTimeout(() => {
                            props.onUpdateRecipe({
                              recipe: currentRecipe
                            })
                          }, 10);

                        }

                      } catch (err) {
                        ////console.log(err);
                      }

                    }, 10);

                  }}

                />} label={option} />

            )
          });
        }

        return (
          <Box className='template-dynamic-param-wrapper' sx={{
            marginBottom: '2em'
          }}>


            {/* <div style={{ fontSize: '2em' }}>{paramName}</div> */}

            {/* <div className='template-dynamic-param-label'>{paramLabel ? paramLabel : paramName}</div> */}
            <Typography color={'secondary'} variant={'h6'}>{paramLabel ? paramLabel : paramName}</Typography>
            <RadioGroup
              aria-label="Font Type"
              name=""
              value={currentParam.value}
              style={{ display: 'flex', 'flex-direction': 'row' }}

            >
              {options}
            </RadioGroup>
            {/*</FormControl> */}
          </Box>
        )

      }
      else if (param.dynamic &&
        param.dynamic.valueType != null &&
        param.dynamic.valueType == 'checkbox2') {



        if (!currentParam) {

          return <div>Property not found:{paramName}</div>

        }

        let currentValue = currentParam.value;
        let checked = false;
        if (currentValue != null && currentValue == true) {
          checked = true;
        }
        return <Box className='template-dynamic-param-wrapper' sx={{
          marginBottom: '2em'
        }}>



          <Box className='property-fieldset-checkbox-flex' sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}>
            <MuiCheckbox
              sx={{
                marginLeft: '0em',
                paddingLeft: '0em'
              }}
              className='formFieldLabel'
              checked={checked}
              disabled={false}
              color={'secondary'}
              onChange={(event) => {
                //console.log(event);


                //currentParam.value = event.target.value;


                let currentValue = currentParam.value;
                currentParam.value = currentValue == true ? false : true;
                setParamControlChangedTimestamp(Date.now())

                setTimeout(() => {

                  try {

                    props.stores.bookStore.recipeTimestamp = new Date().getTime();

                    if (currentRecipe) {

                      setTimeout(() => {
                        props.onUpdateRecipe({
                          recipe: currentRecipe
                        })
                      }, 10);

                    }

                  } catch (err) {
                    ////console.log(err);
                  }

                }, 10);





                // try {



                //   let currentValue = currentParam.value;
                //   currentParam.value = currentValue == true ? false : true;

                //   that.props.stores.bookStore.recipeTimestamp = new Date().getTime();
                //   if (this.props.recipe) {

                //     this.updateRecipe(currentParam.value);

                //   }

                // } catch (err) {
                //   ////console.log(err);
                // }

              }

              }
              value="checked" />


            {/* <div className='template-dynamic-param-label'>{paramLabel ? paramLabel : paramName}</div> */}
            <Typography color={'secondary'} variant={'h6'}>{paramLabel ? paramLabel : paramName}</Typography>
          </Box>

        </Box>
      }
      else {
        return <div>{paramLabel}</div>
      }


    });

    setParamControls(paramControls);
    setPropControls(controls);
  }, [documentPart, selectedTemplate, paramControlChangedTimestamp, props.stores.bookStore.recipeTimestamp]);



  const updatePropsUI = (templateDocument) => {

    try {

      // let cleansed = templateDocument.content.replace(/\n/ig, '').replace(/\t/ig, '');
      // let templateJSON = JSON.parse(cleansed);
      // //let templateJSON = JSON.parse(templateDocument.content);
      // console.log(templateJSON);
      // console.log(templateJSON.disabledProps);


      let template = templates[templateDocument?.id];
      if (!template || !template.managePropsUI) {
        return;
      }

      //props.stores.bookStore.disabledFormatProps = template.disabledProps;
      props.stores.bookStore.setDisabledFormatProps(template.disabledProps)

    } catch (err) {
      console.log(err);
    }



  }
  const updateSelectedTemplate = () => {

    try {


      //let currentTemplateId = recipeParam.value;

      let currentRecipe = props.documentPart?.getRecipe().json;
      let recipeParamPath = element.recipeParamPath;
      let currentTemplateId = new RecipeUtils().getRecipeParamByPartType(null, currentRecipe, documentPart, recipeParamPath);
      let templateIds = Object.keys(templates);
      let templateCount = 0;
      let themeTag = '';
      let inflatablesBookDraft = props.stores.bookStore.inflatablesBookDraft;


      if (inflatablesBookDraft) {


        //setSelectedTemplate('NO_TEMPLATE');

        templateIds.map((templateId, i) => {


          let templateDocument = inflatablesBookDraft.getDocumentPartById(templateId);

          if (templateId == currentTemplateId && selectedTemplate?.id != currentTemplateId) {
            setSelectedTemplate(templateDocument);
            updatePropsUI(templateDocument);
          }
        });

      }
    } catch (err) {
      console.log(err);
    }



  }


  const resetProps = () => {

    try {

      let template = templates[selectedTemplate?.id];
      template.resetProps.forEach((prop) => {

        //console.log(prop);
        // let currentRecipe = documentPart?.getRecipe();
        // new RecipeUtils().setRecipeParamByPartType(null, currentRecipe, documentPart, prop.recipeParamPath, prop.resetValue);

        //baseControl.updateRecipeProp(finalValue);
        baseControl.resetRecipeProp({
          recipeParamPath: prop.recipeParamPath,
          newValue: prop.resetValue
        })
      })
    } catch (err) {
      console.log(err);
    }

  }

  if (element.devModeOnly && !isDevMode) {
    return <></>
  }

  let isVisible = baseControl.isVisible();
  if (!isVisible) {
    return <div style={{
      height: '0em'
    }}></div>
  }

  // let item = documentPart;
  // let properties_book = bookDraft.book;
  // let boxSet = bookDraft.book.boxSet;

  // let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();
  // let hideBookProperties = false;

  // let canEdit = true;


  // let isOwner = true;


  if (element.paramItem == 'BOOK') {
    item = bookDraft.book;
  }
  if (element.paramItem == 'BOXSET_MAIN_BOOK') {
    item = boxSet.book;
  }
  if (element.paramItem == 'BOXSET_BOOK') {
    item = boxSetBook.book;
  }
  if (element.paramItem == 'BOXSETBOOK') {
    item = boxSetBook;
  }

  let checked = baseControl.getIsChecked();

  //The following is necessary to stop the MuiCheckbox from defaulting to uncontrolled mode
  //if it is initilized with a null or undefined value. 
  if (checked == null || checked == undefined) {

    checked = false;
  }





  let title = baseControl.convertTitle(element.title, documentPart);




  return (

    <Box sx={{
      //height: '5em',
      //backgroundColor: 'grey',
      marginTop: '2em'
    }}>



      {selectedTemplate &&
        <Box key={selectedTemplate?.id}
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            flexDirection: 'row'
          }}>
          {/* <Typography>{element.recipeParamName}</Typography> */}
          <Box sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
            flexDirection: 'column'
          }}>

            <StandardImageViewer
              bucketName={config.user_uploads_bucket_name}
              s3Path={selectedTemplate?.headingUrl}
              height={'15em'}
              fit={'contain'}
              sx={{
                minHeight: '15em',
                //border: '1px solid gray'
                //display: { sm: 'block', lg: 'block', md: 'block' } 
              }}
              style={{ borderRadius: 2 }}
              className="custom-class"
              onClick={(e) => {
                e.preventDefault();
                setShowGallery(true);

              }}
              showLoading
              errorIcon
            />
            {element.showResetButton &&
              <Button onClick={() => {
                resetProps();
              }}>reset</Button>
            }
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}>

              {isDevMode &&
                <Typography variant={'h6'}>{selectedTemplate?.title}</Typography>
              }
              <Box>{editTemplateButton ? editTemplateButton : <></>}</Box>
            </Box>
          </Box>
          <SelfOverrideSwitch
            element={element}
            bookDraft={bookDraft}
            documentPart={documentPart}
            onUpdateRecipe={props.onUpdateRecipe} />

        </Box>


      }
      {/* <Typography variant={'h6'} color={'secondary'}>Template specific properties</Typography> */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        //justifyContent:'center',
        //alignItems:'center'
      }}>
        {/* {paramControls} */}
        <Typography variant={'h6'}>Template Specific Properties:</Typography>
        {propControls}
      </Box>

      {!selectedTemplate &&
        <Box key={'missingTemplate'}>
          <Typography variant={'h6'}>Missing recipe value or it does not match the available template ids</Typography>

          <StandardImageViewer
            height={'15em'}
            fit={'contain'}
            sx={{
              minHeight: '15em'
              //display: { sm: 'block', lg: 'block', md: 'block' } 
            }}
            style={{ borderRadius: 2 }}
            className="custom-class"
            onClick={(e) => {
              e.preventDefault();
              setShowGallery(true);

            }}

            showLoading
            errorIcon

            url={"https://planetary.s3.amazonaws.com/web/assets/pictures/_1200x630_crop_center-center_82_none/jwst-carina-nebula.jpg?mtime=1657639468"} />
        </Box>


      }



      <StandardDialog
        title={"Select a style"}
        open={showGallery}
        onClose={() => { setShowGallery(false) }}
        dialogContent={<StandardImageGallery items={galleryItems} />}>

      </StandardDialog>
    </Box>

  );
}


export default inject('stores')(observer(PropsTemplateSelector));



