import React, { useState, useEffect } from "react";
import { inject, observer } from 'mobx-react';
import { useManagerCreateBook } from '../../../managers/BooksManager';
import { useDropzone } from 'react-dropzone';
import { useImportBookDraftDocument } from '../../../persistance/persistanceHooks';
import StorageManager, { useUploadBucketItem } from '../../../managers/StorageManager';
import config from '../../../aws-exports';
import StandardUIDialog from "../../UI/StandardUIDialog";
import StandardUIButton from "../../UI/StandardUIButton";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import StandardUITextField from "../../UI/StandardUITextField";
import { Box, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";

const CreateBook = (props) => {

    let user = props.stores.userStore.user;

    let [title, setTitle] = useState(null);
    let [subtitle, setSubtitle] = useState(null);
    let [uploadedFileS3Key, setUploadedFileS3Key] = useState(null);
    let [selectedFile, setSelectedFile] = useState(null);
    //let [uploadedFileName, setUploadedFileName] = useState(null);


    const [importBookDraftDocument, { data: importBookDraftDocumentData, loading: importBookDraftDocumentLoading, error: importBookDraftDocumentError }] = useImportBookDraftDocument();



    let {
        createBook,
        createBookLoading,
        createBookError
    } = useManagerCreateBook();


    const { getRootProps, getInputProps, open: openDropZone, acceptedFiles } = useDropzone({
        noClick: true,
        noKeyboard: true,
        maxFiles: 1,
        maxSize: 5000000
    });

    let { uploadBucketItem } = useUploadBucketItem();

    useEffect(() => {

        //console.log(acceptedFiles);
        if (acceptedFiles) {
            // const files = acceptedFiles.map(file => (
            //   <li key={file.path}>
            //     {file.path} - {file.size} bytes
            //   </li>
            // ));

            if (acceptedFiles.length > 0) {
                //uploadFile(acceptedFiles[0]);

                let file = acceptedFiles[0];
                // let bucketName = config.user_uploads_bucket_name;
                // uploadBucketItem({
                //   bucketName: bucketName,
                //   file,
                //   userId: user.id,
                //   storageType: StorageManager.STORAGE_TYPE_WORD_IMPORT
                // }, (results, err) => {
                //   if (err == null) {

                //     //processUpload(results.s3Key, element.imageUploaderConfig);
                //     setUploadedFileName(file.name);
                //     setUploadedFileS3Key(results.s3Key);

                //   }
                // });


                setSelectedFile(file);



            }

        }


    }, [acceptedFiles]);




    useEffect(() => {

        if (uploadedFileS3Key) {
            importBookDraftDocument({
                title,
                s3Key: uploadedFileS3Key
            }, () => {
                props.onClose();
            });
        }
    }, [uploadedFileS3Key]);

    useEffect(() => {

        if (importBookDraftDocumentError) {

            //console.log(importBookDraftDocumentError);
        }
    }, [importBookDraftDocumentError]);






    const handleTitleChange = (e) => {
        e.preventDefault();

        setTitle(e.target.value);
    }

    const handleSubtitleChange = (e) => {
        e.preventDefault();

        setSubtitle(e.target.value);
    }

    const handleCreateBook = () => {

        if (selectedFile) {

            let file = selectedFile;
            let bucketName = config.user_uploads_bucket_name;
            uploadBucketItem({
                bucketName: bucketName,
                file,
                userId: user.id,
                storageType: StorageManager.STORAGE_TYPE_WORD_IMPORT
            }, (results, err) => {
                if (err == null) {

                    //processUpload(results.s3Key, element.imageUploaderConfig);
                    //setUploadedFileName(file.name);
                    setUploadedFileS3Key(results.s3Key);

                }
            });

        } else {
            createBook({
                title,
                stores: props.stores
            }, (book) => {
                props.onBookCreated(book);

                //alert('created...');





            })
        }


        // if(uploadedFileS3Key!=null){

        //   importBookDraftDocument({
        //     title,
        //     s3Key: uploadedFileS3Key
        // });

        // }else{
        //   createBook({
        //     title,
        //     stores: props.stores
        //   }, () => {
        //     props.onClose();
        //   })
        // }


    }

    return (
        <StandardUIDialog
            open={props.open}
            onClose={props.onClose}
            title='Upload or Create Book'
            dialogContent={
                        <Box >
                            <Card
                                variant="outlined"
                                style={{ marginBottom: '3em' }}
                            >
                                <CardContent
                                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <UploadFileIcon fontSize='large' />
                                    <Typography variant="body2">
                                        Choose a file or drag and drop here
                                    </Typography>
                                    {importBookDraftDocumentLoading && <CircularProgress />}
                                </CardContent>
                            </Card>
                            <StandardUITextField label='Title' onChange={handleTitleChange} />
                            <StandardUITextField label='Subtitle' onChange={handleSubtitleChange} />

                        </Box>
            }
            cardActions={
                <StandardUIButton
                    buttonAction='Create'
                    autoFocus
                    disabled={((title == null || title.trim().length == 0) || importBookDraftDocumentLoading) ? true : false} onClick={(e) => {
                        e.preventDefault();
                        handleCreateBook();
                    }}
                />
            }
        />
    )
}

export default inject('stores')(observer(CreateBook));