import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import PushPinIcon from '@mui/icons-material/PushPin';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import Avatar from '@mui/material/Avatar';
import { useUpdateItem } from '../../persistance/persistanceHooks';
import ReactTimeAgo from 'react-time-ago';
import { ApolloConsumer } from '@apollo/client';
import { UserComment as UserCommentPojo } from '../../domain/bookModel';
import IconButton from '@mui/material/IconButton';
import config from '../../aws-exports';
import { Box, TextareaAutosize, Typography } from '@mui/material';
import StandardRoundedAction from '../actions/StandardRoundedAction';
import Feather from 'mdi-material-ui/Feather';
import { useTheme } from '@mui/material/styles';
import { showAllComments, showDocumentPartComment, showDocumentPartComments, useOpenCommentInWrite } from '../../managers/BooksManager';
import ImageTitleTableCell from '../table/ImageTitleTableCell';
import StandardImageViewer from '../StandardImageViewer';
import EditIcon from '@mui/icons-material/Edit';
import { useCreateSharedBookUserComment } from '../../persistance/persistanceHooks';
import EditingContext from './EditingContext';
import { observable } from 'mobx';
import { StandardListButton } from '../StandardButton';
var $ = require("jquery");

const UserComment = (props) => {

    let user = props.stores.userStore.user;
    let { userComment: userCommentPojo } = props;
    const editingContext = React.useContext(EditingContext);

    const [value, setValue] = useState(null);
    const [editMode, setEditMode] = useState(null);
    //const [userCommentPojo, setUserCommentPojo] = useState(null);

    const [updateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] = useUpdateItem();
    const { openCommentInWrite } = useOpenCommentInWrite(props);
    const [createSharedBookUserComment, { data: createSharedBookUserCommentData, loading: createSharedBookUserCommentLoading, error: createSharedBookUserCommentError }] = useCreateSharedBookUserComment();



    const userId = props.stores.userStore.user?.id;
    const documentPart = props.documentPart;
    const bookDraft = documentPart?.bookDraftDocumentPart.bookDraft;
    const book = bookDraft?.book;




    const theme = useTheme();

    let completionIcon = <CheckCircleOutlineIcon sx={{
        color: theme.palette.secondary.main
    }} />

    if (!userCommentPojo?.completedAt) {
        // completionIcon = <CheckCircleOutlineIcon sx={{
        //     color: theme.palette.smallIcons.color.main
        // }} />
        completionIcon = <PendingOutlinedIcon sx={{
            color: theme.palette.smallIcons.color.main
        }} />
    }


    const scrollToComment = ({
        blotId
    }) => {
        try {

            let node = $("div").find("[data-uuid='" + blotId + "']")[0];
            node.scrollIntoView({
                behavior: "smooth", // or "auto" or "instant"
                block: "end" // or "start" --> dont know why, but using "start" caused a horizontal scrollbar to appear ( which cause the toolbar to be clipped at the top )
            });
        } catch (err) {
            console.log(err);
        }
    };

    let profilePicUrl = null;
    let commentUserName = null;

    if (userCommentPojo != null) {

        profilePicUrl = userCommentPojo?.commentorUser.profilePicUrl;
        commentUserName = userCommentPojo?.commentorUser.name;

    } else {

        profilePicUrl = user.profilePicUrl;
        commentUserName = user.name;
    }

    return (
        <ApolloConsumer>
            {(client) => (
                <div style={{
                    width: '100%',
                    backgroundColor: theme.palette.write.controls.backgroundColor,
                    border: 'thin solid',
                    borderColor: 'gray',
                    borderRadius: '8px',
                    padding: '0.5em',
                    marginBottom: '0.5em'
                }}
                    onMouseLeave={() => {
                        setEditMode(false);
                    }}>

                    <div style={{
                        positionx: 'unset',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            textAlign: 'left',
                            //border:'1px solid red',
                            width: '100%'
                        }}>
                            {/* <div style={{
                                color:'black'
                            }}>commentId:{userCommentPojo?.id}</div> */}

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                textAlign: 'left',
                                //border: '1px solid red',
                            }} onClick={() => {

                                scrollToComment({
                                    blotId: userCommentPojo?.id
                                })

                            }}>

                                <Avatar
                                    alt=''
                                    src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + profilePicUrl}
                                    sx={{ width: '1.5em', height: '1.5em' }} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    textAlign: 'left',
                                    width: '100%'
                                }}>
                                    <Box sx={{
                                        display:'flex',
                                        flexDirection:'column',
                                        alignItems:'flex-start',
                                        justifyContent:'flex-start',
                                        marginLeft:'.5em'
                                    }}>
                                    <Typography
                                        style={{ fontWeight: 'bold', color: theme.palette.write.controls.color }}
                                    >
                                        {commentUserName}
                                    </Typography>
                                    <div style={{
                                        marginBottom: '.5em'
                                    }}>
                                        <Typography
                                            variant={'h9'}
                                            style={{
                                                color: theme.palette.write.controls.color,
                                                fontSize: '.75em'
                                            }}>{userCommentPojo && <ReactTimeAgo date={userCommentPojo?.createdAt} locale="en-US" />}</Typography>

                                    </div>

                                    </Box>

                                </div>

                            </div>

                            {props.showBookInfo == true &&


                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    textAlign: 'right',
                                    //border: '1px solid blue',
                                }}>
                                    {/* <ImageTitleTableCell
                                    bucketName={config.user_uploads_bucket_name}
                                    title={userCommentPojo?.sharedBook?.title}
                                    s3Src={userCommentPojo?.sharedBook?.coverUrl}
                                    //details={'Opened'}
                                    showReadIcon={false}
                                /> */}

                                    <StandardImageViewer
                                        bucketName={config.user_uploads_bucket_name}
                                        //s3Path={'uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/b6f59120-18fe-11ed-b9d5-076ac854f9a5_planetStrider.png'}
                                        s3Path={userCommentPojo?.sharedBook?.coverUrl}
                                        height={'3em'}
                                        fit={'contain'}
                                        sx={{
                                            height: '2em'
                                            //display: { sm: 'block', lg: 'block', md: 'block' } 
                                        }}
                                        style={{ borderRadius: 2 }}
                                        className="custom-class"
                                        onClick={(e) => {
                                            // e.preventDefault();
                                            // setShowGallery(true);

                                        }}
                                        showLoading
                                        errorIcon

                                    />
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        textAlign: 'left',
                                        width: '100%'
                                    }}>

                                        <Typography style={{ color: theme.palette.write.controls.color }}>{userCommentPojo?.sharedBook?.title}</Typography>


                                    </div>

                                </div>
                            }
                            {props.showWriteIcon &&
                                <StandardRoundedAction icon={<Feather sx={{
                                    color: theme.palette.standardActions.icon.color.main,

                                }} onClick={(e) => {
                                    e.preventDefault();
                                    openCommentInWrite({
                                        bookDraftId: userCommentPojo?.sharedBookDraftId,
                                        userCommentId: userCommentPojo?.id
                                    });
                                }} />} />
                            }

                            <Box>
                                {(userCommentPojo && !editMode && userCommentPojo.commentorUserId == user?.id) &&
                                    <IconButton>
                                        <EditIcon sx={{
                                            color: theme.palette.smallIcons.color.main
                                        }}
                                            onClick={(e) => {
                                                e.preventDefault();

                                                setEditMode(!editMode);
                                                setValue(userCommentPojo?.comment);
                                                console.log(userCommentPojo?.isPinned);



                                            }} />
                                    </IconButton>
                                }
                                {userCommentPojo &&
                                    <IconButton onClick={(e) => {
                                        e.preventDefault();
                                        //console.log(userCommentPojo?.isFavorite);
                                        updateItem({
                                            itemExpectedVersion: 1,
                                            itemKey: userCommentPojo?.id,
                                            //itemSortKeyName: 'documentPartId',
                                            //itemSortKeyValue: userCommentPojo?.documentPartId,
                                            itemType: 'UserComment',
                                            fieldName: 'deletedAt',
                                            fieldValue: 'now',

                                        }, () => {

                                            props.stores.bookStore.userComments.replace(props.stores.bookStore.userComments.filter(f => f.id != userCommentPojo?.id))
                                            props.stores.bookStore.userCommentsTimestamp = Date.now();
                                            client.cache.modify({
                                                //broadcast: false,
                                                id: client.cache.identify(userCommentPojo),
                                                fields: {
                                                    deletedAt(value) {
                                                        return userCommentPojo?.deletedAt == null ? Date.now() : null;
                                                    },
                                                },
                                                /* broadcast: false // Include this to prevent automatic query refresh */
                                            });

                                        })


                                    }}>

                                        <HighlightOffIcon sx={{
                                            color: theme.palette.smallIcons.color.main
                                        }} />
                                    </IconButton>
                                }
                            </Box>
                        </div>
                        {/* <div style={{
                            marginBottom:'.5em'
                        }}>
                            <Typography
                                variant={'h9'}
                                style={{
                                    color: theme.palette.write.controls.color,
                                    fontSize:'1em'
                                }}>{userCommentPojo && <ReactTimeAgo date={userCommentPojo?.createdAt} locale="en-US" />}</Typography>

                        </div> */}
                        <div style={{
                            textAlign: 'left',
                            //paddingLeft: '2.25em',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            width: '100%',
                            alignItems: 'center'
                        }}
                        >
                            {userCommentPojo && !editMode &&
                                <Typography variant="h7" noWrap={true}
                                    sx={{
                                        // 'white-space': 'nowrap',
                                        // //'border': '1px solid red',
                                        // 'width': '100%',
                                        // //'width': theme.components.standardTree.nodes.width,
                                        // 'display': 'block',
                                        // 'overflow': 'hidden',
                                        // 'text-overflow': 'ellipsis',
                                        backgroundColor: theme.palette.background.paper,
                                        'color': '#E5E4E2',
                                        'text-overflow': 'ellipsis',
                                        'overflow': 'hidden',
                                        // Addition lines for 2 line or multiline ellipsis
                                        'display': '-webkit-box !important',
                                        '-webkit-line-clamp': '4',
                                        '-webkit-box-orient': 'vertical',
                                        'white-space': 'normal',
                                        border: '1px solid gray',
                                        borderRadius: '.5em',
                                        width: '100%',
                                        paddingLeft:'.5em',
                                        paddingRight:'.5em'
                                        //padding: '.5em'
                                    }}>
                                    {userCommentPojo?.comment}
                                </Typography>

                            }
                            {(userCommentPojo == null || editMode) &&

                                <TextareaAutosize
                                    className={'tooltip-comment-textarea'}
                                    aria-label=""
                                    placeholder="Add Comment"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: theme.palette.write.controls.backgroundColor,
                                        color: theme.palette.write.controls.color
                                    }}
                                    maxRows={5}
                                    minRows={7}
                                    value={value}
                                    onFocus={() => {
                                        //alert('');
                                        // if(props.quill && props.selection){
                                        //     props.quill.setSelection(props.selection);
                                        // }
                                    }}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setValue(e.target.value);

                                    }}
                                />

                            }

                        </div>
                        {!editMode &&

                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end'

                            }}>
                                {props.ownerMode &&
                                    <IconButton>
                                        <PushPinIcon sx={{
                                            color: userCommentPojo?.isPinned ? theme.palette.secondary.main : theme.palette.smallIcons.color.main
                                        }}
                                            onClick={(e) => {
                                                e.preventDefault();

                                                //console.log(userCommentPojo?.isPinned);
                                                updateItem({
                                                    itemExpectedVersion: 1,
                                                    itemKey: userCommentPojo?.id,
                                                    //itemSortKeyName: 'documentPartId',
                                                    //itemSortKeyValue: userCommentPojo?.documentPartId,
                                                    itemType: 'UserComment',
                                                    fieldName: 'isPinned',
                                                    fieldValue: !userCommentPojo?.isPinned
                                                }
                                                    , () => {
                                                        client.cache.modify({
                                                            //broadcast: false,
                                                            id: client.cache.identify(userCommentPojo),
                                                            fields: {
                                                                isPinned(value) {
                                                                    return !userCommentPojo?.isPinned;
                                                                },
                                                            },
                                                            /* broadcast: false // Include this to prevent automatic query refresh */
                                                        });


                                                        //userCommentPojo?.isFavorite = !userCommentPojo?.isFavorite
                                                    }


                                                )


                                            }} />
                                    </IconButton>
                                }
                                {props.ownerMode &&
                                    <IconButton>
                                        <StarIcon sx={{
                                            color: userCommentPojo?.isFavorite ? theme.palette.secondary.main : theme.palette.smallIcons.color.main
                                        }} onClick={(e) => {
                                            e.preventDefault();

                                            //console.log(userCommentPojo?.isFavorite);
                                            updateItem({
                                                itemExpectedVersion: 1,
                                                itemKey: userCommentPojo?.id,
                                                //itemSortKeyName: 'documentPartId',
                                                //itemSortKeyValue: userCommentPojo?.documentPartId,
                                                itemType: 'UserComment',
                                                fieldName: 'isFavorite',
                                                fieldValue: !userCommentPojo?.isFavorite,
                                                // optimisticResponse: {
                                                //     userComment: userCommentPojo
                                                // },

                                                // update(cache, { data: { userComment } }) {
                                                //     cache.modify({
                                                //         broadcast: false,
                                                //         id: cache.identify(userCommentPojo),
                                                //         fields: {
                                                //             isFavorite(value) {
                                                //                 return !userCommentPojo?.isFavorite;
                                                //             },
                                                //         },
                                                //         /* broadcast: false // Include this to prevent automatic query refresh */
                                                //     });
                                                // },

                                                //refetchQueries:['listAllBookDraftUserComments'],
                                                // onCompleted:()=>{

                                                //         //console.log(client);
                                                //         //console.log(client.cache.identify(userCommentPojo));
                                                //         client.cache.modify({
                                                //         //broadcast: false,
                                                //         id: client.cache.identify(userCommentPojo),
                                                //         fields: {
                                                //             isFavorite(value) {
                                                //                 return !userCommentPojo?.isFavorite;
                                                //             },
                                                //         },
                                                //         /* broadcast: false // Include this to prevent automatic query refresh */
                                                //     });

                                                // }
                                            }, () => {


                                                client.cache.modify({
                                                    //broadcast: false,
                                                    id: client.cache.identify(userCommentPojo),
                                                    fields: {
                                                        isFavorite(value) {
                                                            return !userCommentPojo?.isFavorite;
                                                        },
                                                    },
                                                    /* broadcast: false // Include this to prevent automatic query refresh */
                                                });


                                                //userCommentPojo?.isFavorite = !userCommentPojo?.isFavorite
                                            })


                                        }} />
                                    </IconButton>
                                }
                                {props.ownerMode &&
                                    <IconButton onClick={(e) => {
                                        e.preventDefault();

                                        //console.log(userCommentPojo?.isFavorite);
                                        updateItem({
                                            itemExpectedVersion: 1,
                                            itemKey: userCommentPojo?.id,
                                            //itemSortKeyName: 'documentPartId',
                                            //itemSortKeyValue: userCommentPojo?.documentPartId,
                                            itemType: 'UserComment',
                                            fieldName: 'completedAt',
                                            fieldValue: userCommentPojo?.completedAt != null ? 'null' : 'now',

                                        }, () => {


                                            client.cache.modify({
                                                //broadcast: false,
                                                id: client.cache.identify(userCommentPojo),
                                                fields: {
                                                    completedAt(value) {
                                                        return userCommentPojo?.completedAt == null ? Date.now() : null;
                                                    },
                                                },
                                                /* broadcast: false // Include this to prevent automatic query refresh */
                                            });


                                            //userCommentPojo?.isFavorite = !userCommentPojo?.isFavorite
                                        })


                                    }}>



                                        {completionIcon}
                                    </IconButton>
                                }


                                {/* {userCommentPojo &&
                                    <IconButton onClick={(e) => {
                                        e.preventDefault();
                                        //console.log(userCommentPojo?.isFavorite);
                                        updateItem({
                                            itemExpectedVersion: 1,
                                            itemKey: userCommentPojo?.id,
                                            //itemSortKeyName: 'documentPartId',
                                            //itemSortKeyValue: userCommentPojo?.documentPartId,
                                            itemType: 'UserComment',
                                            fieldName: 'deletedAt',
                                            fieldValue: 'now',

                                        }, () => {

                                            props.stores.bookStore.userComments.replace(props.stores.bookStore.userComments.filter(f => f.id != userCommentPojo?.id))
                                            props.stores.bookStore.userCommentsTimestamp = Date.now();
                                            client.cache.modify({
                                                //broadcast: false,
                                                id: client.cache.identify(userCommentPojo),
                                                fields: {
                                                    deletedAt(value) {
                                                        return userCommentPojo?.deletedAt == null ? Date.now() : null;
                                                    },
                                                },
                                              
                                            });

                                        })


                                    }}>

                                        <HighlightOffIcon sx={{
                                            color: theme.palette.smallIcons.color.main
                                        }} />
                                    </IconButton>
                                } */}
                            </Box>
                        }
                        {(userCommentPojo == null || editMode) &&
                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end'
                            }}>
                                <StandardListButton variant={'outlined'} onClick={() => {
                                    setEditMode(false);

                                    if (userCommentPojo) {
                                        // userCommentPojo.comment = value;

                                        // setValue(userCommentPojo.comment)

                                        try {

                                            let existingCommentPojo = props.stores.bookStore.userComments.filter(f => f.id == userCommentPojo.id)[0];
                                            existingCommentPojo.comment = value
                                            userCommentPojo.comment = value
                                            props.stores.bookStore.userCommentsTimestamp = Date.now();

                                        } catch (err) {
                                            //do nothing
                                            console.log(err);
                                        }

                                        updateItem({
                                            itemExpectedVersion: 1,
                                            itemKey: userCommentPojo?.id,
                                            //itemSortKeyName: 'documentPartId',
                                            //itemSortKeyValue: userCommentPojo?.documentPartId,
                                            itemType: 'UserComment',
                                            fieldName: 'comment',
                                            fieldValue: value,
                                            refetchQueries: ['listAllBookDraftUserComments', 'listBookDraftUserComments']
                                        }
                                            , () => {

                                                client.cache.modify({
                                                    //broadcast: false,
                                                    id: client.cache.identify(userCommentPojo),
                                                    fields: {
                                                        comment(x) {
                                                            return value;
                                                        },
                                                    },
                                                    /* broadcast: false // Include this to prevent automatic query refresh */
                                                });


                                                //userCommentPojo?.isFavorite = !userCommentPojo?.isFavorite
                                            }


                                        )




                                    } else {
                                        //create a new comment here.

                                        createSharedBookUserComment({
                                            user: user,
                                            documentPartId: documentPart.id,
                                            sharedBookId: book.id,
                                            sharedBookDraftId: bookDraft.id,
                                            commentorUserId: userId,
                                            comment: value,
                                            itemOwnerId: book.owner?.id,
                                            partIndex: props.selection?.index,
                                            partLength: props.selection?.length
                                            //highlightedText:highlightedText
                                        }, (userComment) => {


                                            editingContext.setUserCommentId(userComment.id)
                                            props.onNewComment(new UserCommentPojo({ data: userComment }));
                                            props.stores.bookStore.userComments.push(observable(new UserCommentPojo({ data: userComment })))
                                            props.stores.bookStore.userCommentsTimestamp = Date.now();
                                            // showDocumentPartComment(
                                            //     props,
                                            //     {
                                            //         quill: props.quill,
                                            //         partId: documentPart?.id,
                                            //         selection:props.selection,
                                            //         userComment:userComment
                                            //     });
                                        });


                                    }
                                }}>Save</StandardListButton>
                                {userCommentPojo &&
                                    <StandardListButton variant={'outlined'} onClick={() => {
                                        setEditMode(false);
                                    }}>Cancel</StandardListButton>
                                }
                            </Box>
                        }
                        {/* <Typography style={{ color: theme.palette.write.controls.color }}>{userCommentPojo && <ReactTimeAgo date={userCommentPojo?.createdAt} locale="en-US" />}</Typography> */}

                    </div>
                </div>
            )}
        </ApolloConsumer>
    )


};


export default inject('stores')(observer(UserComment));