import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Paper, styled, Typography } from "@mui/material";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Tooltip, Box } from '@mui/material';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BaseControl from "./propertyElements/BaseControl";
import { useTheme } from '@mui/material/styles';
import ToolbarItem from '../editor/toolbar/ToolbarItem';
// import { RecipeUtils } from './TemplateManager';
import { RecipeUtils } from './RecipeUtils';
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({

    //backgroundColor:'red'
    "&.Mui-selected": {
        "backgroundColor": "#f2784b"
    }
}))

function ThemeToolbar(props) {

    let { documentPart, isBoxSet } = props;
    const [boxsetBookRecipeSource, setBoxsetBookRecipeSource] = React.useState(null);
    const [headingSourceType, setHeadingSourceType] = React.useState(null);

    let baseControl = new BaseControl(props);
    const theme = useTheme();


    let isInsertedBookDocumentPart = documentPart.bookDraftDocumentPart.insertedBook || documentPart.partType == 'BOOK';

    const getHeadingSource = (documentPart) => {

        try {

            let recipeParamName = 'global_headerTemplateSourceFor---PART_TYPE---s';
            recipeParamName = baseControl.convertTitle(recipeParamName, documentPart);

            let recipeParam = baseControl.getRecipeParamByName(recipeParamName);

            return recipeParam.value;

        } catch (err) {
            console.log(err);
        }

    };

    const handleChangeBoxsetBookRecipeSource = (event, newRecipeSource) => {
        //setSelection(event.target.value);

        try {
            setBoxsetBookRecipeSource(newRecipeSource);
            let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();

            boxSetBook.recipeSource = newRecipeSource;

            setTimeout(() => {

                props.stores.bookStore.recipeTimestamp = new Date().getTime();

                if (props.onUpdateEntity) {
                    props.onUpdateEntity({
                        item: boxSetBook,
                        fieldName: 'recipeSource'
                    })
                }

            }, 10);


        } catch (err) {
            console.log(err);
        }

    };


    const handleChangeLayout = (event, layout) => {
        props.stores.bookStore.layout = layout;
        props.stores.bookStore.recipeTimestamp = new Date().getTime();
    }

    const handleChangeRecipeScope = (event, scope) => {

        new RecipeUtils().setScope(documentPart, scope);
        baseControl.updateRecipeScope(documentPart, scope);

        //props.stores.bookStore.recipeTimestamp = new Date().getTime();

        //new RecipeUtils().setRecipeParamByPartType(null, currentRecipe, documentPart, recipeParamPath, newValue);
        //baseControl.updateRecipeProp(newValue);


    }

    const handleChangeHeadingSource = (event, value) => {
        setHeadingSourceType(value);

        let currentRecipe = props.documentPart?.getRecipe();

        let recipeParamName = 'global_headerTemplateSourceFor---PART_TYPE---s';
        recipeParamName = baseControl.convertTitle(recipeParamName, props.documentPart);

        let recipeParam = baseControl.getRecipeParamByName(recipeParamName);

        recipeParam.value = value;

        setTimeout(() => {

            props.stores.bookStore.recipeTimestamp = new Date().getTime();

            if (props.onUpdateRecipe) {
                props.onUpdateRecipe({
                    recipe: currentRecipe,
                    paramName: recipeParamName,
                    paramValue: value
                })
            }

        }, 10);




    };


    useEffect(() => {

        try {

            let recipeParamName = 'global_headerTemplateSourceFor---PART_TYPE---s';
            let headerTemplateSourceParam = baseControl.getRecipeParamByName(recipeParamName);

            setHeadingSourceType(headerTemplateSourceParam.value);

            let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();
            setBoxsetBookRecipeSource(boxSetBook.recipeSource);

        } catch (err) {
            console.log(err);
        }
    }, [])


    useEffect(() => {

        let isBoxsetDocumentPart = true;
        let documentPartBoxSetId = documentPart.bookDraftDocumentPart.bookDraft.book.boxSetId; //Indicated this documentPart is an original page of the boxset
        let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();
        let insertedBook = documentPart.bookDraftDocumentPart.insertedBook;

        let isInsertedBookDocumentPart = documentPart.bookDraftDocumentPart.insertedBook || documentPart.partType == 'BOOK';
        if (documentPartBoxSetId) {
            setBoxsetBookRecipeSource('boxSetRecipe');
        } else if (boxSetBook) {
            setBoxsetBookRecipeSource(boxSetBook.recipeSource);
        }

        if (documentPart) {

            let value = getHeadingSource(documentPart);
            setHeadingSourceType(value);

        }


    }, [documentPart, documentPart.bookDraftDocumentPart.getBoxSetBook()?.recipeSource]);


    let recipeSource = documentPart.bookDraftDocumentPart.getBoxSetBook()?.recipeSource;
    if (!recipeSource) {
        recipeSource = "boxSetRecipe";
    }

    let hasHeadingSourceType = true;
    if (!documentPart ||
        documentPart?.partType.toLowerCase() == 'topbook' ||
        documentPart?.partType.toLowerCase() == 'book' ||
        //documentPart?.partType.toLowerCase() == 'copyright' || 
        //documentPart?.partType.toLowerCase() == 'toc' || 
        documentPart?.partType.toLowerCase() == 'fullpageimage') {
        hasHeadingSourceType = false;
    }

    let recipeScope = new RecipeUtils().getScope(documentPart);
    let numDisabledFormatProps = 0;

    try {
        numDisabledFormatProps = Object.keys(props.stores.bookStore.disabledFormatProps).length
    } catch (err) {
        console.log(err);
    }


    return (
        <Paper sx={{
            display: 'flex',
            backgroundColor: theme.palette.write?.background?.color,
            justifyContent: 'space-between',
            border: '1px solid gray'

        }} >
            <Button onClick={() => {
                props.stores.bookStore.isDevMode = !props.stores.bookStore.isDevMode
            }}>EDIT</Button>
            <Button onClick={() => {

            }}>NUM Disabled:{numDisabledFormatProps}</Button>
            <Button onClick={() => {
                let currentRecipe = documentPart?.getRecipe().json;
                currentRecipe = JSON.parse(JSON.stringify(currentRecipe));
                //console.log(currentRecipe);
            }}>JSON</Button>
            <Box focusable='false' tabIndex={-1} sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                'flex-direction': 'row',
                'flex-wrap': 'wrap',
                'alignItems': 'center',
                width: '100%',
                padding: '0em 0em 0em 0.5em',
                'height': '100%',
                //flex: 4,
                gap: '1.5em'
                //'width': '100%'  
            }}>
                {/* <span>{documentPart.bookDraftDocumentPart.getBoxSetBook()?.recipeSource}</span>
                <span>:{boxsetBookRecipeSource}</span> */}

                {/* 
                <span>{boxsetBookRecipeSource}</span> */}
                {/* <span>isBoxSet:{isBoxSet?'true':'false'}</span> */}
                {/* {<span>{documentPart.bookDraftDocumentPart.bookDraft.book.boxSetId}</span>} */}

                {/* <span>isInsertedBookDocumentPart:{isInsertedBookDocumentPart?'isInserted':'notInserted'}</span> */}

                <ToolbarItem icon={<FormatPaintIcon fontSize={'small'} color={'disabled'} onClick={() => { props.onShowBookmerlinStyles(); }} />} />
                <ToolbarItem icon={<SaveIcon fontSize={'small'} color={'disabled'} onClick={() => { props.onShowBookmerlinStyles(); }} />} />
                <ToolbarItem icon={<ShareIcon fontSize={'small'} color={'disabled'} onClick={() => { props.onShowBookmerlinStyles(); }} />} />
                <ToolbarItem icon={<InfoOutlinedIcon fontSize={'small'} color={'disabled'} onClick={() => { props.onShowBookmerlinStyles(); }} />} />

            </Box>

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                //border: '1px solid red',
                //flex: 6
            }}>
                {isBoxSet &&
                    <ToggleButtonGroup
                        size="small"
                        disabled={!(documentPart.bookDraftDocumentPart.insertedBook || documentPart.partType == 'BOOK')}
                        //value={boxsetBookRecipeSource}
                        value={recipeSource}
                        exclusive
                        onChange={handleChangeBoxsetBookRecipeSource}
                        aria-label=""
                    >
                        <ToggleButton value="boxSetRecipe" aria-label="">
                            <Box sx={{
                                display: 'flex',
                                gap: '.5em'
                            }}>
                                <Box sx={{
                                    color: recipeSource == 'boxSetRecipe' ? 'white' : 'gray'
                                }}>BOXSET</Box>
                                <FormatPaintIcon fontSize={'small'} sx={{
                                    color: recipeSource == 'boxSetRecipe' ? 'white' : 'gray'
                                }} />
                            </Box>

                        </ToggleButton>
                        <ToggleButton value="originalBookRecipe" aria-label="">
                            <Box sx={{
                                display: 'flex',
                                gap: '.5em'
                            }}>
                                <Box sx={{
                                    color: recipeSource == 'originalBookRecipe' ? 'white' : 'gray'
                                }}>BOOK</Box>
                                <FormatPaintIcon fontSize={'small'} sx={{
                                    color: recipeSource == 'originalBookRecipe' ? 'white' : 'gray'
                                }} />
                            </Box>

                        </ToggleButton>
                        <ToggleButton value="overrideBookRecipe" aria-label="">
                            <Box sx={{
                                display: 'flex',
                                gap: '.5em'
                            }}>
                                <Box sx={{
                                    color: recipeSource == 'overrideBookRecipe' ? 'white' : 'gray'
                                }}>CUSTOM</Box>
                                <FormatPaintIcon fontSize={'small'} sx={{
                                    color: recipeSource == 'overrideBookRecipe' ? 'white' : 'gray'
                                }} />
                            </Box>

                        </ToggleButton>
                    </ToggleButtonGroup>



                }
{/* 
                <Box sx={{
                    marginLeft: '1em'
                }}>
                    <ToggleButtonGroup
                        size="small"
                        //disabled={!(documentPart.bookDraftDocumentPart.insertedBook || documentPart.partType == 'BOOK')}
                        //value={boxsetBookRecipeSource}
                        value={props.stores.bookStore.layout}
                        exclusive
                        onChange={handleChangeLayout}
                        aria-label=""
                    >
                        <ToggleButton value="layout2" aria-label="">
                            <Box sx={{
                                display: 'flex',
                                gap: '.5em'
                            }}>
                                <Box sx={{
                                    color: props.stores.bookStore.layout == 'layout2' ? 'white' : 'gray'
                                }}>V2</Box>

                            </Box>

                        </ToggleButton>
                        <ToggleButton value="layout3" aria-label="">
                            <Box sx={{
                                display: 'flex',
                                gap: '.5em'
                            }}>
                                <Box sx={{
                                    color: props.stores.bookStore.layout == 'layout3' ? 'white' : 'gray'
                                }}>V3</Box>

                            </Box>

                        </ToggleButton>
                       
                       
                    </ToggleButtonGroup>

                </Box> */}


                <Box sx={{
                    marginLeft: '1em',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                  
                    <ToggleButtonGroup
                        size="small"
                        //disabled={!(documentPart.bookDraftDocumentPart.insertedBook || documentPart.partType == 'BOOK')}
                        //value={boxsetBookRecipeSource}
                        value={recipeScope}
                        exclusive
                        onChange={handleChangeRecipeScope}
                        aria-label=""
                    >
                        <ToggleButton value="common" aria-label="">
                            <Box sx={{
                                display: 'flex',
                                gap: '.5em'
                            }}>
                                <Box sx={{
                                    color: recipeScope == 'common' ? 'white' : 'gray'
                                }}>Book</Box>

                            </Box>

                        </ToggleButton>
                        <ToggleButton value="partType" aria-label="">
                            <Box sx={{
                                display: 'flex',
                                gap: '.5em'
                            }}>
                                <Box sx={{
                                    color: recipeScope == 'partType' ? 'white' : 'gray'
                                }}>Type</Box>

                            </Box>

                        </ToggleButton>
                        {/* <ToggleButton value="self" aria-label="">
                        <Box sx={{
                            display: 'flex',
                            gap: '.5em'
                        }}>
                            <Box sx={{
                                color: recipeScope == 'self' ? 'white' : 'gray'
                            }}>Self</Box>
                           
                        </Box>

                    </ToggleButton> */}
                    </ToggleButtonGroup>
                  

                </Box>
            </Box>


            {/* <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flex: 4
            }}>
                {hasHeadingSourceType &&
                    <ToggleButtonGroup
                        size="small"
                        value={headingSourceType}
                        exclusive
                        onChange={handleChangeHeadingSource}
                        aria-label="text alignment"
                    >
                        <ToggleButton value="common" aria-label="">

                            COMMON
                        </ToggleButton>
                        <ToggleButton value="partTypeSpecific" aria-label="">

                            SPECIFIC
                        </ToggleButton>
                    </ToggleButtonGroup>
                }

            </Box> */}

        </Paper>
    )

    return (
        <Paper sx={{
            display: 'flex',
            backgroundColor: theme.palette.write?.background?.color,

        }} >
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                'flex-direction': 'row',
                'flex-wrap': 'wrap',
                'alignItems': 'center',
                width: '100%',
                border: '1px solid gray',
                //padding: '0em 0em .5em 0em'
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    border: '1px solid red'
                }}>
                    <span style={{
                        display: 'flex',
                        gap: '1em',
                        alignItems: 'flex-start',
                        alignItems: 'center',
                    }}>
                        <ToolbarItem icon={<FormatPaintIcon fontSize={'small'} color={'disabled'} onClick={() => { props.onShowBookmerlinStyles(); }} />} />
                        <ToolbarItem icon={<SaveIcon fontSize={'small'} color={'disabled'} onClick={() => { props.onShowBookmerlinStyles(); }} />} />
                        <ToolbarItem icon={<ShareIcon fontSize={'small'} color={'disabled'} onClick={() => { props.onShowBookmerlinStyles(); }} />} />
                        <ToolbarItem icon={<InfoOutlinedIcon fontSize={'small'} color={'disabled'} onClick={() => { props.onShowBookmerlinStyles(); }} />} />


                    </span>
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid red'
                }}>
                    {!isBoxSet &&
                        <StyledToggleButtonGroup
                            size="small"
                            value={boxsetBookRecipeSource}
                            exclusive
                            onChange={handleChangeBoxsetBookRecipeSource}
                            aria-label=""
                        >
                            <ToggleButton value="boxSetRecipe" aria-label="">
                                {/* <FormatAlignLeftIcon /> */}
                                BOXSET
                            </ToggleButton>
                            <ToggleButton value="originalBookRecipe" aria-label="">
                                {/* <FormatAlignCenterIcon /> */}
                                BOOK
                            </ToggleButton>
                            <ToggleButton value="overrideBookRecipe" aria-label="">
                                {/* <FormatAlignRightIcon /> */}
                                CUSTOM
                            </ToggleButton>
                        </StyledToggleButtonGroup>
                    }
                </Box>


                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <StyledToggleButtonGroup
                        size="small"
                        value={headingSourceType}
                        exclusive
                        onChange={handleChangeHeadingSource}
                        aria-label="text alignment"
                    >
                        <ToggleButton value="common" aria-label="">

                            COMMON
                        </ToggleButton>
                        <ToggleButton value="partTypeSpecific" aria-label="">

                            SPECIFIC
                        </ToggleButton>
                    </StyledToggleButtonGroup>

                </Box>

            </Box>
        </Paper>

    );
}

export default inject('stores')(observer(ThemeToolbar));
