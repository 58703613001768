import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { useGetSnapshots } from '../../managers/BooksManager';
import StandardDataTable from './StandardDataTable';
import { Box, Button } from '@mui/material';
import { Avatar } from "@mui/material";
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useGetBookDraftBuildRequests } from "../../managers/ExportsManager";
import ReactTimeAgo from 'react-time-ago';
import config from '../../aws-exports';


const GeneratedDocsTable = (props) => {

    let { buildRequest, bookDraftId } = props;

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');

    const GET_PRESIGNED_URL = gql`
    query getPresignedUrl($key: String!) {
      getPresignedUrl(key: $key)
              }
    `;

const { buildRequests, loading, error, client } = useGetBookDraftBuildRequests(props, bookDraftId);

    // const [showCreateSnapshotDialog, setShowCreateSnapshotDialog] = React.useState(false);


    // let { listSnapshots, snapshots, error: listSnapshotsError, loading: listSnapshotsLoading } = useGetSnapshots();


    // useEffect(() => {

    //     if (book != null) {
    //         listSnapshots({
    //             bookId: book.id,
    //             onCompleted: () => {

    //             }
    //         })
    //     }
    // }, [book]);


    // useEffect(() => {

    //     if (snapshots != null) {

    //         console.log(snapshots);
    //     }
    // }, [snapshots]);



    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column'
        }}>
            <StandardDataTable title={'Generated Docs '}
                order={order}
                onSetOrder={setOrder}
                orderBy={orderBy}
                onSetOrderBy={setOrderBy}

                structure={{
                    dataDef: {
                        data: buildRequest.buildRequestGeneratedDocs.items,
                        rowKeyDataFieldName: 'id'
                    },
                    columns: [
                        {
                            title: 'id',
                            dataValueFunction: (generatedDoc) => { return <Avatar sx={{ width: '1.5em', height: '1.5em' }} variant="square" alt="" src="https://www.adobe.com/express/create/cover/media_178ebed46ae02d6f3284c7886e9b28c5bb9046a02.jpeg?width=400&format=jpeg&optimize=medium" /> }
                        },
                        {
                            title: 'Filename',
                            dataValueFunction: (generatedDoc) => {
                                return <span onClick={async () => {

                                    let results = await client.query({
                                        query: GET_PRESIGNED_URL,
                                        variables: { key: generatedDoc.generatedDoc.fileName },
                                        fetchPolicy: 'network-only',

                                        //data: data
                                    });

                                    //console.log(results);
                                    //console.log(JSON.parse(results.data.getPresignedUrl).url)
                                    let finalUrl = null;

                                    try {
                                        finalUrl = JSON.parse(results.data.getPresignedUrl).url;
                                    } catch (err) {
                                        //console.log(err);
                                    }
                                    window.open(finalUrl, '_blank');
                                }} className='generated-doc-link'>{generatedDoc.generatedDoc.fileName}</span>
                            }
                        }
                    ]
                }} />
            
        </Box>

    )
}
export default inject('stores')(observer(GeneratedDocsTable));