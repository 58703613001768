import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import StandardTabs from "../../StandardTabs";
import { useManagerCreateInvitation, useGetUserConnections, useGetUserGroups } from '../../../managers/ConnectionsManager';
import AvatarTableCell from "../../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../../StandardButton";
import UserConnectionsList from "../../lists/UserConnectionsList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../../managers/BooksManager';
import UserGroupsList from "../../lists/UserGroupsList";
import { useManagerCreateEditRequest } from '../../../managers/EditRequestsManager';
import SendInvitationPanel from "../connections/SendInvitationPanel";


const RequestEditPanel = (props) => {

  let user = props.stores.userStore.user;
  let { book } = props;

  const { userConnections, error: getUserConnectionsError } = useGetUserConnections(props);
  const { userGroups, error: getUserUserGroupsError, loading: getUserUserGroupsLoading } = useGetUserGroups(props);
  const { sharedWithUsers, bookUserSharesLoading, bookUserSharesError, sharedWithUserGroups, bookUserGroupSharesLoading, bookUserGroupSharesError } = useGetBookShares(props, book);
  const { shareBookWithUser, loading: shareBookLoading, error: sshareBookError } = useShareBookWithUser(props);
  const { shareBookWithGroup, loading: shareBookWithUserGroupLoading, error: shareBookWithUserGroupError } = useShareBookWithGroup(props);

  const { createEditRequest, loading: createEditRequestLoading, error: createEditRequestError } = useManagerCreateEditRequest(props);
  let [name, setName] = useState('Bob');
  let [email, setEmail] = useState('jshamel+test0001@gmail.com');

  const { createInvitation, data: createInvitationData, loading: createInvitationLoading, error: createInvitationError } = useManagerCreateInvitation();

  const handleNameChange = (e) => {
    e.preventDefault();

    setName(e.target.value);
  }

  const handleEmailChange = (e) => {
    e.preventDefault();

    setEmail(e.target.value);
  }
  let [selectedTab, setSelectedTab] = useState(0);


  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Grid container direction={'column'}>
      <Grid item>
        <StandardTabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Request Edit" {...a11yProps(0)} />
          <Tab label="Invite Editor" {...a11yProps(1)} />
        </StandardTabs>
        {selectedTab == 0 &&
          <UserConnectionsList
            filteredConnections={[]}
            showRequestButton
            onSelected={(userConnection) => {

              if (userConnection) {
                createEditRequest({
                  book,
                  userId: user.id,
                  editorUserId: userConnection.connectedUser.id
                })
              }

            }}
          />
        }
        {selectedTab == 1 &&

          <SendInvitationPanel
            name={name}
            email={email}
            onNameChange={handleNameChange}
            onEmailChange={handleEmailChange} />

        }
      </Grid>
    </Grid>
  )
}

export default inject('stores')(observer(RequestEditPanel));