import React, { useState } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { Auth } from "aws-amplify";
import { inject, observer } from 'mobx-react';
import ItemTypeDetail from '../items/ItemTypeDetail';

const PrecipitantDetail = (props) => {

	let { uiState } = props;
	const { universe, precipitant } = props;



	//console.log(precipitant);

	



	return (
		<div>
			Precipitant: {precipitant?.title}
			<ItemTypeDetail itemType={precipitant?.itemType} />
		</div>
	);
};


export default inject('stores')(observer(PrecipitantDetail));