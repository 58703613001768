import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import config from '../aws-exports';
import ReactTimeAgo from 'react-time-ago';
import StandardImageViewer from './StandardImageViewer';

const StandardBookCoverTitle = (props) => {

  
  let { book } = props;
  

  

  return (
   
    <Grid container sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      //border: '1px solid red'
    }}>
      <Grid item xs={3} sx={{
        paddingRight:'1em'
      }}>
        <StandardImageViewer
          bucketName={config.user_uploads_bucket_name}
          //s3Path={'uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/b6f59120-18fe-11ed-b9d5-076ac854f9a5_planetStrider.png'}
          s3Path={book?.coverUrl}
          height={'5em'}
          fit={'contain'}
          sx={{
            minHeight: '5em'
            //display: { sm: 'block', lg: 'block', md: 'block' } 
          }}
          style={{ borderRadius: 2 }}
          className="custom-class"
          onClick={(e) => {
            // e.preventDefault();
            // setShowGallery(true);

          }}
          showLoading
          errorIcon

        />

      </Grid>
      <Grid item xs={9}>
        <span>{book?.title}</span>
      </Grid>

    </Grid>
    

  )
}
export default inject('stores')(observer(StandardBookCoverTitle));