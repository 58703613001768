import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Typography, List, Button } from '@mui/material';
import DashboardCard from '../../dashboard/DashboardCard';
import { useTheme } from '@mui/material/styles';
import BookDraftEditor from '../../editor/BookDraftEditor';
import StretchBox from '../StretchBox';
import ScrollBox from '../ScrollBox';
import AppWrapperDummy from '../../AppWrapperDummy';
import { useMediaQuery } from '@mui/material';
import BookDraftTree3 from '../../tree/BookDraftTree3';
import BookDraftTree7 from '../../tree/BookDraftTree7';
import { useLoadEditRequestForEdit } from '../../../managers/EditRequestsManager';
import SplitScreenController from '../../SplitScreenController';
import EditDetails from '../../editing/EditDetails';
const Edit = (props) => {

  let user = props.stores.userStore.user;

  const theme = useTheme();

  let [splitPaneMode, setSplitPaneMode] = useState(false);
  let [selectedBddp, setSelectedBddp] = useState(null);
  let [showBothPanes, setShowBothPanes] = useState(false);



  let { selectedReceivedEditRequest: editRequest } = props.stores.bookStore;

  //let { writingSelectedBookDraftDocumentPart: selectedBddp } = props.stores.bookStore;

  const {
    loadEditRequestForEdit,
    editingBookDraft,
    originalBookDraft
  } = useLoadEditRequestForEdit(props);

  useEffect(() => {

    if (editRequest && editRequest.acceptedAt) {

      loadEditRequestForEdit({
        editRequest
      });

    }

  }, [editRequest?.acceptedAt]);


  let showOriginal = false;
  let isEditor = false;
  let isAccepted = false;

  if (user && editRequest && user.id == editRequest.editorUserId) {
    isEditor = true;
  }

  if (editRequest?.acceptedAt) {

    showOriginal = false;
    isAccepted = true;
  }


  useEffect(() => {


    if (editingBookDraft) {

      setSelectedBddp(editingBookDraft.rootBookDraftDocumentPart);


    }


  }, [editingBookDraft]);



  let tree = <BookDraftTree7
    bookDraft={editingBookDraft}
    heading={"Editor's Copy"}
    hideScenes={true}
    //showAddBookDialog={showAddBookDialog}
    //setShowAddBookDialog={setShowAddBookDialog}
    selectedId={selectedBddp?.partId}
    selectedIds={[
      selectedBddp?.partId
    ]}


    onDocumentPartSelected={(item) => {

      let bddp = editingBookDraft.getBookDraftDocumentPartByPartId(item.id);
      props.stores.bookStore.editingSelectedBookDraftDocumentPart = bddp;
      //console.log(bddp);

      setSelectedBddp(bddp);


    }}
    onBookInserted={() => {
      //getBookDraftRefetch();
    }} />



  let leftEditor = <BookDraftEditor
    subToolbarTitle={'Title bar'}
    //userComments={userComments}
    bookDraft={editingBookDraft}
    selectedBddp={selectedBddp}
    editMode={true}
    onDocumentPartCreated={(bddp) => {

      //props.stores.bookStore.writingSelectedBookDraftDocumentPart = bddp;

    }} />

  let rightEditor = <BookDraftEditor
    subToolbarTitle={'Title bar'}
    //userComments={userComments}
    bookDraft={originalBookDraft}
    selectedBddp={originalBookDraft?.rootBookDraftDocumentPart}
    editMode={false}
    onDocumentPartCreated={(bddp) => {

      //props.stores.bookStore.writingSelectedBookDraftDocumentPart = bddp;

    }} />


  return (
    // <AppWrapperDummy >
      <StretchBox sxProps={{

      }}
        flexDirection='row'>

        <StretchBox
          flexDirection='column'
          sxProps={{
            flex: 2
          }}>

          <ScrollBox>
            {tree}
          </ScrollBox>


        </StretchBox>
        <StretchBox
          flexDirection='column'
          sxProps={{
            flex: 7
          }}>

          <SplitScreenController
            showBothPanes={props.stores.bookStore.writeSplitScreen}
            leftItem={leftEditor}
            rightItem={rightEditor}

          />
        </StretchBox>
        <StretchBox
          flexDirection='column'
          sxProps={{
            flex: 3
          }}>
          <EditDetails
            documentPart={props.stores.bookStore.editingSelectedBookDraftDocumentPart?.documentPart}
            originalBookDraft={originalBookDraft}
            editBookDraft={editingBookDraft}
            editRequest={editRequest}
            showOriginal={showOriginal}
            onToggleLoadOriginal={() => {

              //console.log(editRequest);

              if (editRequest) {
                // lazyGetBookDraft({
                //   variables: {
                //     bookDraftId: editRequest.originalBookDraftId
                //   }
                // });

              }
            }} />
        </StretchBox>
      </StretchBox>

    // </AppWrapperDummy>

  )
}

export default inject('stores')(observer(Edit));