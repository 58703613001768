import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import config from '../../aws-exports';
import useMediaQuery from '@mui/material/useMediaQuery';
import StretchBox from '../boilerPlate/StretchBox';
import { Box, Button, SwipeableDrawer, Typography } from '@mui/material';
import ScrollBox from '../boilerPlate/ScrollBox';
import StandardUICard from '../UI/StandardUICard';
import StandardUICirclePlus from '../UI/StandardUICirclePlus';
import ListOwnedGroups from './lists/ListOwnedGroups';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import GroupShareBook from './dialogs/GroupShareBook';
import GroupsSharedToCard from './listCards/GroupsSharedToCard';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import CreateGroup from './dialogs/CreateGroup';
import AddMember from './dialogs/AddMember';
import ListGroupMembers from './lists/ListGroupMembers';
import StandardUITextField from '../UI/StandardUITextField';
import StandardUIButton from '../UI/StandardUIButton';
import StandardAutoUITextField from '../UI/StandardAutoUITextField';

const MyGroupsPage = (props) => {

    let { book, userGroup } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdAt');


    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [showAddMember, setShowAddMember] = useState(false);
    const [showShareBook, setShowShareBook] = useState(false);

    const handleOpenCreateGroup = () => {
        setShowCreateGroup(true);
    }

    const handleCloseCreateGroup = () => {
        setShowCreateGroup(false);
    }

    const handleOpenAddMember = () => {
        setShowAddMember(true);
    }

    const handleCloseAddMember = () => {
        setShowAddMember(false);
    }

    const handleShareBookOpen = () => {
        setShowShareBook(true);
    }

    const handleShareBookClose = () => {
        setShowShareBook(false);
    }





    const [showDetailDrawer, setShowDetailDrawer] = useState(false);
    let [selectedUserGroup, setSelectedUserGroup] = useState(null);

    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setShowDetailDrawer(open);
            };


    return (

        <StretchBox flexDirection='row' sxProps={{ gap: isSmallScreen ? 0 : 2 }}>

            <StretchBox sxProps={{
                flex: isSmallScreen ? 12 : 4,
                position: 'relative'
            }}>
                {/* <BoxsetsTable onSelectBoxSet={
                    (boxset) => {
                        setSelectedBoxset(boxset)
                        setShowDetailDrawer(true)
                    }} /> */}

                <ScrollBox>
                    <StandardUICard
                        title='My Groups'
                        showPagination={false}
                        showSearchField={true}
                        onSetOrdering={(orderBy, order)=>{ setOrder(order); setOrderBy(orderBy)}}
                        children={
                            <ListOwnedGroups
                                selectedUserGroup={selectedUserGroup}
                                order={order}
                                orderBy={orderBy}
                                secondary={'Time ago'}
                                showShareIcon={false}
                                onListSelectGroup={(userGroup) => {
                                    setSelectedUserGroup(userGroup);
                                    setShowDetailDrawer(true)
                                }}

                            />
                        }
                    />






                </ScrollBox>
                <StandardUICirclePlus
                    onClick={handleOpenCreateGroup}
                />


            </StretchBox>

            <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>


                    <StandardUICard
                        title='Details'
                        showPagination={false}
                        showSearchField={false}
                        // actions={
                        //     <Link underline='none' color='blue'
                        //         onClick={handleMoreDetailOpen}>
                        //         More
                        //     </Link>
                        // }
                        children={
                            <div>
                                <StandardAutoUITextField label='Title' value={selectedUserGroup?.name} entity={selectedUserGroup} fieldName={'name'} />
                                <StandardAutoUITextField label='Details' value='Details Here' />
                                <Typography>Created</Typography>
                                <StandardUIButton
                                    sx={{ marginTop: '1em' }}
                                    buttonAction='Delete'
                                    // onClick={() => (
                                    //     setOpenOptOut(true)
                                    // )}
                                />
                            </div>
                        }
                    />
                    <StandardUICard
                        title='Members'
                        showPagination={true}
                        showSearchField={true}
                        actions={
                            <Fab color="primary" size='small'
                                onClick={handleOpenAddMember}
                            >
                                <AddIcon />
                            </Fab>
                        }
                        children={
                            <ListGroupMembers userGroup={selectedUserGroup} />
                        }
                    />
                    <StandardUICard
                        title='Shared Books'
                        showPagination={true}
                        showSearchField={true}
                        actions={
                            <Fab color="primary" size='small'
                                onClick={handleShareBookOpen}
                            >
                                <AddIcon />
                            </Fab>
                        }
                        children={
                            <GroupsSharedToCard userGroup={selectedUserGroup} />
                        }
                    />


                </ScrollBox>
            </StretchBox>

            <CreateGroup
                open={showCreateGroup}
                onClose={handleCloseCreateGroup}
            />

            <AddMember
                userGroup={selectedUserGroup}
                open={showAddMember}
                onClose={handleCloseAddMember} />

            <GroupShareBook
                userGroup={selectedUserGroup}
                open={showShareBook}
                onClose={handleShareBookClose}
            />

            <SwipeableDrawer
                anchor={'right'}
                open={showDetailDrawer && isSmallScreen}
                onClose={toggleDrawer(false)}
                sx={{ width: '100%' }}
            //variant='persistent'
            //onOpen={setShowDetailDrawer(true)}
            >
                <div style={{ width: '100vw' }}>

                    <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                        <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2, ml: 0, mr: 0 }}>
                            <IconButton
                                style={{ display: 'flex', justifyContent: 'left' }}
                                onClick={() => {
                                    setShowDetailDrawer(false)
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography
                                variant={theme.landmarkText.variant}
                                gutterBottom
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                {selectedUserGroup?.name}
                            </Typography>


                            <StandardUICard
                                title='Details'
                                showPagination={false}
                                showSearchField={false}
                                // actions={
                                //     <Link underline='none' color='blue'
                                //         onClick={handleMoreDetailOpen}>
                                //         More
                                //     </Link>
                                // }
                                children={
                                    <div>
                                        <StandardUITextField label='Title' value={selectedUserGroup?.name} />
                                        <StandardUITextField label='Title' value='Details' />
                                        <Typography>Created</Typography>
                                        <StandardUIButton
                                            sx={{ marginTop: '1em' }}
                                            buttonAction='Delete'
                                            // onClick={() => (
                                            //     setOpenOptOut(true)
                                            // )}
                                        />
                                    </div>
                                }
                            />
                            <StandardUICard
                                title='Members'
                                showPagination={true}
                                showSearchField={true}
                                actions={
                                    <Fab color="primary" size='small'
                                        onClick={handleOpenAddMember}
                                    >
                                        <AddIcon />
                                    </Fab>
                                }
                                children={
                                    <ListGroupMembers userGroup={selectedUserGroup} />
                                }
                            />
                            <StandardUICard
                                title='Shared Books'
                                showPagination={true}
                                showSearchField={true}
                                actions={
                                    <Fab color="primary" size='small'
                                        onClick={handleShareBookOpen}
                                    >
                                        <AddIcon />
                                    </Fab>
                                }
                                children={
                                    <GroupsSharedToCard userGroup={selectedUserGroup} />
                                }
                            />


                        </ScrollBox>
                    </StretchBox>
                </div>
            </SwipeableDrawer>


        </StretchBox>
    )
}
export default inject('stores')(observer(MyGroupsPage));