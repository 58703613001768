import React, { useEffect, useState } from 'react';
import { styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import BaseControl from "./BaseControl";
import { useTheme } from '@mui/material/styles';
import { Box, } from "@mui/material";
import { v4 as uuid } from "uuid";
import config from '../../../aws-exports';
// import StandardDialog from '../../StandardDialog';
// import S3FilesGallery from './S3FilesGallery';
import StandardImageViewer from '../../StandardImageViewer';
import { useDropzone } from 'react-dropzone';
import helpers from '../../../helpers/helpers';
import StorageManager, { useUploadBucketItem } from '../../../managers/StorageManager';

let AWS = require("aws-sdk");

function FormatImageUploadSelector(props) {

  let user = props.stores.userStore.user;

  let {uploadBucketItem} = useUploadBucketItem();

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    maxSize: 5000000
  });

  const [showGallery, setShowGallery] = useState(false);

  useEffect(() => {

    if (acceptedFiles) {
      const files = acceptedFiles.map(file => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      ));

      if (acceptedFiles.length > 0) {
        uploadFile(acceptedFiles[0]);
      }

    }


  }, [acceptedFiles]);

  const theme = useTheme();
  let baseControl = new BaseControl(props);

  const { classes, element, bookDraft, documentPart } = props;

  let isVisible = baseControl.isVisible();
  if (!isVisible) {
    return <div style={{
      height: '0em'
    }}></div>
  }


  let canEdit = true;


  let isOwner = true;


  let bucketName = null;
  let s3Path = null;

  let title = baseControl.convertTitle(element.title, documentPart);

  const label = { inputProps: { 'aria-label': title } };

  let item = baseControl.getEntityItem();


  



  const calculateS3Path = (value) => {

    if (element.isFontParam) {

      return 'public/fonts/' + value + '.png';


    }
    if (element.isParagraphAfterFirstParam) {

      return 'public/paragraphAfterFirst/' + value + '.png';


    }


    return value;
  }

  const calculateBucketName = (fileSource) => {

    let workingBucketName = config.common_content_bucket_name;

    if (element.bucketName) {

      workingBucketName = element.bucketName;

    } else {

      if (fileSource && fileSource == 'userImages') {
        workingBucketName = config.user_uploads_bucket_name;
      }

    }



    return workingBucketName;


  }

  let src = null;

  if (element.paramType == 'ENTITY') {


    let fileName = item[element.entityFieldName];
    let fileSource = null;
    if (fileName) {

      try {
        let splitParam = fileName.split('&&&');
        if (splitParam.length == 2) {
          fileName = splitParam[0];
          fileSource = splitParam[1];
        }
      } catch (err) {
        //console.log(err);
      }

    }

    //src = convertFontValueToKey(fileName, fileSource);
    s3Path = calculateS3Path(fileName);
    bucketName = calculateBucketName(fileSource);


  }

  if (element.paramType == 'RECIPE') {


    let recipeSrcParam = baseControl.getRecipeParamByName(element.recipeParamName);

    if (recipeSrcParam) {

      let fileName = recipeSrcParam.value;
      let fileSource = null;
      if (fileName) {

        try {
          let splitParam = fileName.split('&&&');
          if (splitParam.length == 2) {
            fileName = splitParam[0];
            fileSource = splitParam[1];
          }
        } catch (err) {
          //console.log(err);
        }

      }

      s3Path = calculateS3Path(fileName);
      bucketName = calculateBucketName(fileSource);
    }


  }

  try {
    if (s3Path && s3Path.trim() == '') {
      s3Path = null;
    }
  } catch (err) {
    //console.log(err);
  }



  const processSelection = (s3Key) => {

    let finalValue = s3Key;
    let parts = s3Key.split('/');
    let finalPart = parts[parts.length - 1].split('.')[0];

    if (element.isFontParam || element.isParagraphAfterFirstParam) {

      // baseControl.updateValue({
      //   value: finalPart
      // });
      finalValue = finalPart;


    }

    if (element.fileSource != null && element.fileSource.trim() != '') {
      finalValue += '&&&' + element.fileSource;
    }
    baseControl.updateValue({
      value: finalValue
    });


  }


  const uploadFile = (file) => {


    let { element } = props;


    let entity = item;

    let fileName = file.name;

    //console.log(fileName);

    let s3Key = helpers.createUserImageUploadS3Key(user.id, fileName);

    //console.log(s3Key);


    uploadBucketItem({
      bucketName, 
      file,
      userId:user.id,
      storageType:StorageManager.STORAGE_TYPE_USER_IMAGE
  },(results,err)=>{
    if(err==null){
      //handleUpdateImage(results);
    }
  });
    // // Use S3 ManagedUpload class as it supports multipart uploads
    // let upload = new AWS.S3.ManagedUpload({
    //   params: {
    //     Bucket: bucketName,
    //     Key: s3Key,
    //     Body: file,
    //     ACL: 'public-read'
    //   }
    // });

    // let promise = upload.promise();

    // console.log('updatingItem here...')
    // promise.then(
    //   function (data) {
    //     //console.log("Successfully uploaded photo.");
    //     let modifiedValue = s3Key;
    //     if (element && element.fileSource && element.fileSource != '') {
    //       modifiedValue = modifiedValue + '&&&' + element.fileSource
    //     }


    //     entity[convertedImageKeyFieldName] = modifiedValue;

    //     //console.log(entity);
    //     updateItem({
    //       itemKey: entity.id,
    //       itemType: entity.modelType ? entity.modelType : entity.__typename,
    //       fieldName: convertedImageKeyFieldName,
    //       fieldValue: modifiedValue
    //     })

    //     if (props.onChange) {
    //       props.stores.bookStore.recipeTimestamp = Date.now();
    //       props.onChange();
    //     }


    //     //viewAlbum(albumName);
    //   },
    //   function (err) {
    //     console.log(err);
    //     //return alert("There was an error uploading your photo: ", err.message);
    //   }
    // );
  }




  return (

    <Box sx={{
      displayx: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}>

      {/* <div><Typography>FormatImageUploadSelector: {title}</Typography></div>
      <div><Typography>bucketName: {bucketName}</Typography></div>
      <div><Typography>s3Path: {s3Path}</Typography></div> */}
      <StandardImageViewer
        bucketName={bucketName}
        s3Path={s3Path}
        height={'5em'}
        fit={'contain'}
        sx={{
          minHeight: '5em'
          //display: { sm: 'block', lg: 'block', md: 'block' } 
        }}
        style={{ borderRadius: 2 }}
        className="custom-class"
        onClick={(e) => {
          e.preventDefault();
          //setShowGallery(true);
          open();

        }}
        showLoading
        errorIcon
      />

      {/* <StandardDialog
        title={"Select a font"}
        open={showGallery}
        onClose={() => { setShowGallery(false) }}






        dialogContent={<S3FilesGallery
          bucketName={element.bucketName}
          folder={element.folder}
          onItemSelected={({ s3Key }) => {

            console.log(s3Key);
            processSelection(s3Key);

          }}
        />}>

      </StandardDialog> */}


    </Box>


  );
}


export default inject('stores')(observer(FormatImageUploadSelector));



