import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { Box, Paper, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import StandardHelpLabel from '../../StandardHelpLabel';
import StandardTextfield from '../../StandardTextfield';

const LinkPopper = (props) => {

    let { open, onClose = () => { }, anchorEl, linkBlot } = props;

    const [href, setHref] = useState(linkBlot?.domNode?.dataset?.href)
    const [linkType, setLinkType] = useState(linkBlot?.domNode?.dataset?.linkType)

    useEffect(() => {

        setHref(linkBlot?.domNode?.dataset?.href);
        setLinkType(linkBlot?.domNode?.dataset?.linkType);

    }, [linkBlot?.domNode?.dataset?.href]);

    return (
        <Popper
            id={'linkPopper'}
            open={open}
            onMouseLeave={() => {
                onClose();

            }}

            anchorEl={anchorEl}
            transition
            placement="bottom-start"
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper elevation={6}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '1em'
                        }}>
                            <StandardHelpLabel title={'Link URL'} />


                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Link Type</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={linkType}
                                    name="radio-buttons-group"
                                    onChange={(e, value) => {
                                        setLinkType(e.target.value);
                                        linkBlot.setLinkType(e.target.value);
                                    }}
                                >
                                    <FormControlLabel value="web" control={<Radio />} label="Web" />
                                    <FormControlLabel value="store" control={<Radio />} label="Store" />
                                    <FormControlLabel value="internal" control={<Radio />} label="Internal" />
                                </RadioGroup>
                            </FormControl>

                            {linkType == 'web' &&
                                <Box>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                        <StandardTextfield label="url" fullWidth id="filled-basic" variant="filled" size="small" sx={{

                                        }}
                                            value={href}
                                            onChange={(e) => {
                                                setHref(e.target.value);
                                                linkBlot.setHref(e.target.value);
                                                //linkBlot?.domNode?.dataset?.href = 
                                            }} />
                                        <OpenInNewIcon color='primary' onClick={(e) => {

                                            e.preventDefault();
                                            window.open(href);
                                        }} />
                                    </div>
                                </Box>
                            }
                            {linkType == 'store' &&
                                <Box>store</Box>
                            }
                            {linkType == 'internal' &&
                                <Box>internal</Box>
                            }
                        </div>
                    </Paper>
                </Fade>
            )}
        </Popper>

    );
};


export default inject('stores')(observer(LinkPopper));
