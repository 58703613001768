import { createTheme } from "@mui/material";
import { amber, deepOrange } from "@mui/material/colors";

const common = {

    light: {
        primary: "#34495E",
        secondary: "#009688",
        text: {
            primary: "#009688",
            secondary: "gray"
        }
    },
    dark: {
        primary: "#34495E",
        secondary: "#009688",
        text: {
            primary: "white",
            secondary: "gray"
        }
    },
    books: "#009688", //"#e74c3c" more red
    words: "#2980b9",
    feedback: "#34495e",
    connections: "#16a085",
    editing: "#009688",
    cardIcon: "#ffffff",
    bookmerlinGreen: "#009688",
    listPage: {
        title: {
            variant: 'h4',
            color: {
                main: "white"
            }
        },
        description: {
            variant: 'h6',
            color: {
                main: "gray"
            }
        }
    },
    standardTable: {
        title: {
            variant: 'h5',
            color: {
                main: "white"
            }
        },
        columnTitle: {
            variant: 'h6',
            color: {
                main: "white"
            }
        },
    },
    actions: {
        backgroundColor: {

        }
    }
};



const createStandardTheme = (isDarkMode) => {

    const mode = isDarkMode ? 'dark' : 'light';

    const standardTheme = createTheme({

        palette: {
            mode,
            ...(mode === 'light'
                ? {
                    //************* Start MUI Standard values
                    primary: {
                        main: '#009688',
                    },
                    secondary: {
                        main: '#009688',
                    },
                    background: {
                        default: '#F5F5F5',
                        paper: '#FFFFFF',
                    },
                    text: {
                        heading1: {
                            color: 'darkgray',
                            variant: 'h5',
                            font: 'sans serif'
                        },
                        heading2: {
                            color: 'darkgray',
                            variant: 'h7',
                            font: 'sans serif'
                        },
                        content1: {
                            color: 'darkgray',
                            variant: 'h7',
                            font: 'sans serif'
                        },
                        content2: {
                            color: 'darkgray',
                            variant: 'h8',
                            font: 'sans serif'
                        }
                    },



                    //************* End MUI Standard values

                    //************* Lauras theming
                    components: {
                        MuiListItemButton: {
                            styleOverrides: {
                                root: {
                                    "&.MuiListItemButton-root": {
                                        "&.Mui-selected": {
                                            backgroundColor: "grey"
                                        },
                                    },

                                }

                            }
                        }
                    },



                    //************* Start BookMerlin custom values
                    cardIcon: {
                        main: common.cardIcon,
                    },
                    write: {
                        background: {
                            color: '#FFFFFF'
                        },
                        bookTitle: {
                            color: '#787878'
                        },
                        controls: {
                            backgroundColor: '#34495E',
                            color: '#009688'
                        }
                    },
                    standardTable: {
                        title: {
                            variant: 'h5',
                            // color: {                             
                            //     main: "white"
                            // }
                        },
                        columnTitle: {
                            variant: 'h6',
                            // color: {
                            //     main: "white"
                            // }
                        },
                    },
                    standardTree: {
                        heading: {
                            variant: 'h6',
                            color: {
                                main: common.dark.secondary,
                            }
                        },
                        title: {
                            variant: 'h5',
                            color: {
                                main: "darkgray",
                            }
                        },
                        columnTitle: {
                            variant: 'h6',
                            // color: {
                            //     main: "white"
                            // }
                        },
                        nodes: {
                            //variant: 'h6',
                            color: {
                                main: "black"
                            },
                            selected: {
                                backgroundColor: {
                                    main: "gray"
                                },
                                color: {
                                    main: "black"
                                }
                            },
                            hover: {
                                backgroundColor: {
                                    main: "green"
                                },
                                color: {
                                    main: "blue"
                                }
                            }
                        },
                    },
                    standardActions: {
                        icon: {
                            backgroundColor: {
                                main: '#009688'
                            },
                            color: {
                                main: '#009688'
                            }
                        },
                        label: {
                            variant: 'h5',
                            color: {
                                main: '#009688'
                            }
                        }
                    },
                    smallIcons: {
                        color: {
                            main: '#009688'
                        }

                    }
                }





                : {
                    //************* Start MUI Standard values
                    primary: {
                        main: '#009688',
                    },
                    secondary: {
                        main: '#009688',
                    },
                    background: {
                        default: '#303030',
                        paper: '#424242',
                    },
                    //************* End MUI Standard values

                    //************* Lauras themeing
                    components: {
                        MuiListItemButton: {
                            styleOverrides: {
                                root: {
                                    "&.MuiListItemButton-root": {
                                        "&.Mui-selected": {
                                            backgroundColor: "#009688"
                                        },
                                    },

                                }


                            }
                        }
                    },

                    text: {
                        heading1: {
                            color: 'white',
                            variant: 'h5',
                            font: 'sans serif'
                        },
                        heading2: {
                            color: 'white',
                            variant: 'h7',
                            font: 'sans serif'
                        },
                        content1: {
                            color: 'white',
                            variant: 'h7',
                            font: 'sans serif'
                        },
                        content2: {
                            color: 'white',
                            variant: 'h8',
                            font: 'sans serif'
                        }
                    },
                    //************* Start BookMerlin custom values
                    write: {
                        // background: {
                        //     color:'rgb(211, 222, 230)',
                        // },
                        text: {
                            color: 'white',
                        },
                        bookTitle: {
                            color: 'darkGray'
                        },
                        controls: {
                            backgroundColor: '#303030',
                            color: 'darkGray'
                        }
                    },
                    cardIcon: {
                        main: common.cardIcon,
                    },
                    standardTable: {
                        title: {
                            variant: 'h6',
                            // color: {
                            //     main: "white"
                            // }
                        },
                        columnTitle: {
                            variant: 'h6',
                            // color: {
                            //     main: "white"
                            // }
                        },
                    },
                    standardTree: {
                        heading: {
                            variant: 'h6',
                            color: {
                                main: common.dark.secondary,
                            }
                        },
                        title: {
                            variant: 'h6',
                            color: {
                                main: "white",
                            }
                        },
                        nodes: {
                            //variant: 'h6',
                            color: {
                                main: "white"
                            },
                            selected: {
                                backgroundColor: {
                                    main: "gray"
                                },
                                color: {
                                    main: "white"
                                }
                            },
                            hover: {
                                backgroundColor: {
                                    main: "gray"
                                },
                                color: {
                                    main: "white"
                                }
                            }
                        },
                    },
                    standardActions: {
                        icon: {
                            backgroundColor: {
                                main: common.dark.secondary
                            },
                            color: {
                                main: common.dark.secondary
                            }
                        },
                        label: {
                            variant: 'h5',
                            color: {
                                main: common.dark.secondary
                            }
                        }
                    },
                    smallIcons: {
                        color: {
                            main: 'rgba(255, 255, 255, 0.3)'
                        }
                    }
                }),
        },

        //************* Fonts ( independant of light/dark mode)
        //************* reference: https://mui.com/material-ui/customization/typography/
        typography: {
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },

        //laura added
        landmarkText: {
            variant: 'h4',
        },
        secondLevelText: {
            variant: 'h5',
        },


        transitions: {
            duration: {
                enteringScreen: 100,
                leavingScreen: 100
            }
        },
        // text: {
        //     primary: common.primary,
        // },
        components: {
            MuiButton: {
                defaultProps: {
                    disableRipple: true
                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        color: common[mode].text.primary,
                        // '&:hover': {
                        //     color: 'black'
                        //  }
                    },
                    treeNodeTitle: {
                        // '&:hover': {
                        //     color: 'red'
                        //  }
                    }

                }
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        "&.Mui-selected": {
                            "color": "white",
                            backgroundColor: '#009688'
                        },
                        // '&:hover': {
                        //     "color": "white",
                        //     backgroundColor:'#009688'
                        //  }
                    },
                    treeNodeTitle: {
                        // '&:hover': {
                        //     color: 'red'
                        //  }
                    }

                }
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        //backgroundColor: common[mode].text.primary
                    }

                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        color: '#009688',
                        "&.Mui-selected": {
                            "color": "#009688"
                        }
                    },
                    indicator: {
                        backgroundColor: "#009688"
                    },
                    selected: {
                        color: "#009688"
                    }

                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: 'white', //laura changed
                        borderColor: 'darkGray'
                    }


                }
            },
            //lauras additional theming
            MuiDialog: {
                styleOverrides: {
                    root: {
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                minWidth: "50%",  // Set your width here
                            },
                        },
                    }


                }
            },
            // MuiListItemButton: {
            //     styleOverrides: {
            //         root: {
            //             "&.MuiListItemButton-root":{
            //                 "&.Mui-selected": {
            //                     backgroundColor: "grey"
            //                 },
            //             },

            //         }


            //     }
            // },

            
            //BookMerlin Components
            standardTree: {
                title: {
                    variant: 'h7',
                },
                nodes: {
                    variant: 'h7',
                    width: '20em'
                },
            },
            BookDraftEditor: {
                paddingLeft: '5%', //Large screens only
                paddingRight: '5%' //Large screens only
            }


        }
    });

    return standardTheme;

}

export default createStandardTheme;