import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import StandardTabs from "../StandardTabs";
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton, { StandardListButton } from "../StandardButton";
import UserConnectionsList from "../lists/UserConnectionsList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../managers/BooksManager';
import UserGroupsList from "../lists/UserGroupsList";
import { useManagerAddBoxsetContributor, useManagerAddBoxsetContributedBook, useManagerLoadBoxSetContributors } from '../../managers/BoxsetsManager';
import { User } from "../../domain/bookModel";
import OwnedBooksList from "../lists/OwnedBooksList";


const AddBoxsetBookPanel = (props) => {

  let user = props.stores.userStore.user;
  let { boxset } = props;

  let [boxsetBooks, setBoxsetBooks] = useState([]);

  const { addBoxSetContributedBook, loading: addBoxSetContributedBookLoading, error: addBoxSetContributedBookError } = useManagerAddBoxsetContributedBook(props);
  const { addBoxsetContributor, loading: createBoxSetContributorLoading, error: createBoxSetContributorError } = useManagerAddBoxsetContributor(props);

  let {
    boxsetContributors,
    loading: lazyLoadBoxSetContributorsLoading,
    error: lazyLoadBoxSetContributorsError
  } = useManagerLoadBoxSetContributors(props, boxset?.id);


  useEffect(() => {

    try {

      let bookData = [];
      boxsetContributors.forEach((boxsetContributor)=>{

        boxsetContributor.contributedBooks.items.forEach((bsb)=>{

          bookData.push({
            boxsetContributor,
            boxsetBook:bsb
          }) 
        })

      });

      setBoxsetBooks(bookData);

    } catch (err) {
      console.log(err);
    }
  }, [boxsetContributors]);

  return (
    <Grid container direction={'column'}>
      <Grid item>
        <OwnedBooksList
          filteredBooks={boxsetBooks.map(m=>m.boxsetBook.book)}
          showSelectIcon
          onSelected={(book) => {

            if (book) {
              addBoxSetContributedBook({
                bookId: book.id,
                boxsetId: boxset.id,
              })
            }

          }}
        />
      </Grid>
      <Grid item>
        {(boxsetContributors?.filter(f=>f.id!=user?.id).length==0) &&
          <StandardListButton onClick={() => {
            addBoxsetContributor({
              contributorId: user.id,
              boxsetId: boxset.id,
            })
          }}>Add Self</StandardListButton>

        }
      </Grid>
    </Grid>
  )
}

export default inject('stores')(observer(AddBoxsetBookPanel));