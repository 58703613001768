const nunjucks = require('nunjucks');
const mobx = require('mobx');
const RecipeUtils = require('./RecipeUtils').RecipeUtils;
// let recipe1 = {
// 	id: '1234-9876xxxxxx',
// 	title: 'Title1',

// 	/*addParam: (paramName)=>{
// 		this.params[paramName] = {
// 			value:''
// 		}	
// 	},	*/
// 	//First all of the global params will be added.
// 	//Then the recipe params will be added. Can override the global params
// 	//Then the partType params will be added. 
// 	//Then the template specific params will be added.  
// 	params: {
// 		global_showOrnamentalBreaks: {
// 			value: true
// 		},
// 		global_showChapterTitles: {
// 			value: true
// 		},
// 		global_hideAllBlurbsHeadings: {
// 			value: true
// 		},
// 		global_hideAllVolumeHeadings: {
// 			value: false
// 		},
// 		global_hideAllPartHeadings: {
// 			value: false
// 		},
// 		global_hideAllChapterHeadings: {
// 			value: false
// 		},
// 		global_hideAllBlurbsSubtitles: {
// 			value: true
// 		},
// 		global_hideAllVolumeSubtitles: {
// 			value: true
// 		},
// 		global_hideAllPartSubtitles: {
// 			value: true
// 		},
// 		global_hideAllChapterSubtitles: {
// 			value: false
// 		},
// 		global_includeAllBlurbHeadingUrls: {
// 			value: true
// 		},
// 		global_includeAllVolumeHeadingUrls: {
// 			value: true
// 		},
// 		global_includeAllPartHeadingUrls: {
// 			value: true
// 		},
// 		global_includeAllChapterHeadingUrls: {
// 			value: true
// 		},
// 		global_includeAllHeadingUrls: {
// 			value: true
// 		},
// 		global_numberAllVolumes: {
// 			value: true
// 		},
// 		global_numberAllParts: {
// 			value: true
// 		},
// 		global_numberAllChapters: {
// 			value: true
// 		},
// 		global_firstParagraphDropCapSize: {
// 			value: '3'
// 		},
// 		global_firstParagraphDropCapFont: {
// 			value: 'font1'
// 		},
// 		global_hideVolumeAuthorNames: {
// 			value: false
// 		},
// 		recipe1CustomParam1: {
// 			value: 'test title',
// 			type: 'boolean',
// 			title: 'Recipe1',
// 			description: '' //provide only if this is not overriding a global param
// 		},
// 		defaultVolumeHeaderTemplate: {
// 			valuex: 'template_volume_header_002',
// 			value: 'b92cc400-2db5-11e9-ab6b-d1ff7d5d757e',
// 			type: 'template',
// 			title: 'Volume Heading Template',
// 			templateTypeTag: 'TEMP_volHeader',
// 			description: 'default volume header template' //provide only if this is not overriding a global param
// 		},
// 		defaultPartHeaderTemplate: {
// 			value: 'template_part_header_001',
// 			type: 'template',
// 			title: 'Volume Heading Template',
// 			templateTypeTag: 'TEMP_partHeader',
// 			description: 'default part header template' //provide only if this is not overriding a global param
// 		},
// 		defaultChapterHeaderTemplate: {
// 			value: 'template_chapter_header_001',
// 			type: 'template',
// 			title: 'Chapter Heading Template',
// 			templateTypeTag: 'TEMP_chHeader',
// 			description: 'default chapter header template' //provide only if this is not overriding a global param
// 		},
// 		defaultVolumeContentTemplate: {
// 			valuex: 'template_volume_content_001',
// 			value: 'bd8e4960-2db5-11e9-ab6b-d1ff7d5d757e',
// 			type: 'template',
// 			title: 'Volume Content Template',
// 			templateTypeTag: 'TEMP_volContent',
// 			description: 'default volume header template' //provide only if this is not overriding a global param
// 		},
// 		defaultPartContentTemplate: {
// 			value: 'template_part_content_001',
// 			type: 'template',
// 			title: 'Part Content Template',
// 			templateTypeTag: 'TEMP_partContent',
// 			description: 'default part header template' //provide only if this is not overriding a global param
// 		},
// 		defaultChapterContentTemplate: {
// 			value: 'template_chapter_content_001',
// 			type: 'template',
// 			title: 'Chapter Content Template',
// 			templateTypeTag: 'TEMP_chContent',
// 			description: 'default chapter header template' //provide only if this is not overriding a global param
// 		},
// 		defaultOrnamentalBreaksTemplate: {
// 			value: 'template_ornamental_break_002',
// 			type: 'template',
// 			title: 'Ornamental Break Template',
// 			templateTypeTag: 'TEMP_ornBreak',
// 			description: 'defaultOrnamentalBreaksTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultTitlePageContentTemplate: {
// 			value: 'template_titlepage_content_001',
// 			type: 'template',
// 			title: 'Title Page Template',
// 			templateTypeTag: 'TEMP_titlePageContent',
// 			description: 'defaultHalfTitleContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultHalfTitleContentTemplate: {
// 			value: 'template_halftitle_content_001',
// 			type: 'template',
// 			title: 'Half Title Template',
// 			templateTypeTag: 'TEMP_halfTitleContent',
// 			description: 'defaultHalfTitleContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultFullPageImageContentTemplate: {
// 			value: 'template_fullpageimage_content_001',
// 			type: 'template',
// 			title: 'FullPageImage Template',
// 			templateTypeTag: 'TEMP_fullPageImageContent',
// 			description: 'defaultFullPageImageContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultAlsoByContentTemplate: {
// 			value: 'template_alsoby_content_001',
// 			type: 'template',
// 			title: 'Also By Template',
// 			templateTypeTag: 'TEMP_alsoByContent',
// 			description: 'defaultAlsoByContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultAboutAuthorContentTemplate: {
// 			value: 'template_aboutauthor_content_001',
// 			type: 'template',
// 			title: 'About Author Template',
// 			templateTypeTag: 'TEMP_aboutAuthorContent',
// 			description: 'defaultAboutAuthorContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultAcknowledgementsContentTemplate: {
// 			value: 'template_acknowledgements_content_001',
// 			type: 'template',
// 			title: 'Acknowledgements Template',
// 			templateTypeTag: 'TEMP_acknowledgementsContent',
// 			description: 'defaultacknowledgementsContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultAfterwordContentTemplate: {
// 			value: 'template_afterword_content_001',
// 			type: 'template',
// 			title: 'After Template',
// 			templateTypeTag: 'TEMP_afterwordContent',
// 			description: 'defaultAfterwordContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultEpilogueContentTemplate: {
// 			value: 'template_epilogue_content_001',
// 			type: 'template',
// 			title: 'Epilogue Template',
// 			templateTypeTag: 'TEMP_epilogueContent',
// 			description: 'defaultEpilogueContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultPrologueContentTemplate: {
// 			value: 'template_prologue_content_001',
// 			type: 'template',
// 			title: 'Prologue Template',
// 			templateTypeTag: 'TEMP_prologueContent',
// 			description: 'defaultPrologueContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultEpigraphContentTemplate: {
// 			value: 'template_epigraph_content_001',
// 			type: 'template',
// 			title: 'Epigraph Template',
// 			templateTypeTag: 'TEMP_epigraphContent',
// 			description: 'defaultEpigraphContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultTocContentTemplate: {
// 			value: 'template_toc_content_001',
// 			type: 'template',
// 			title: 'TOC Template',
// 			templateTypeTag: 'TEMP_tocContent',
// 			description: 'defaultTocContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultForewardContentTemplate: {
// 			value: 'template_foreward_content_001',
// 			type: 'template',
// 			title: 'Foreward Template',
// 			templateTypeTag: 'TEMP_forewardContent',
// 			description: 'defaultForewardContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultBlurbsContentTemplate: {
// 			valuex: 'template_blurbs_content_001',
// 			value: '8794f090-2eee-11e9-9f90-d933a92ce124',
// 			type: 'template',
// 			title: 'Blurbs Template',
// 			templateTypeTag: 'TEMP_blurbsContent',
// 			description: 'defaultBlurbsContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultIntroductionContentTemplate: {
// 			value: 'template_introduction_content_001',
// 			type: 'template',
// 			title: 'Introduction Template',
// 			templateTypeTag: 'TEMP_introductionContent',
// 			description: 'defaultIntroductionContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultPrefaceContentTemplate: {
// 			value: 'template_preface_content_001',
// 			type: 'template',
// 			title: 'Preface Template',
// 			templateTypeTag: 'TEMP_prefaceContent',
// 			description: 'defaultPrefaceContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultCopyrightContentTemplate: {
// 			value: 'template_copyright_content_001',
// 			type: 'template',
// 			title: 'Copyright Template',
// 			templateTypeTag: 'TEMP_copyrightContent',
// 			description: 'defaultCopyrightContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultDedicationContentTemplate: {
// 			value: 'template_dedication_content_001',
// 			type: 'template',
// 			title: 'Dedication Template',
// 			templateTypeTag: 'TEMP_dedicationContent',
// 			description: 'defaultDedicationContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultCoverContentTemplate: {
// 			value: 'template_cover_content_001',
// 			type: 'template',
// 			title: 'Cover Template',
// 			templateTypeTag: 'TEMP_coverContent',
// 			description: 'defaultCoverContentTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultFirstParagraphTemplate: {
// 			value: 'template_first_paragraph_002',
// 			type: 'template',
// 			title: 'First Paragraph Template',
// 			templateTypeTag: 'TEMP_firstParagraph',
// 			description: 'defaultFirstParagraphTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultBlockQuoteTemplate: {
// 			value: 'template_block_quotes_001',
// 			type: 'template',
// 			title: 'Block Quote Template',
// 			templateTypeTag: 'TEMP_blockQuote',
// 			description: 'defaultBlockQuoteTemplate' //provide only if this is not overriding a global param
// 		},
// 		defaultBlurbsTemplate: {
// 			valuexxx: 'template_blurb_003',
// 			value: 'dca00d60-2ef6-11e9-a1b1-bd079886ef98',
// 			type: 'template',
// 			title: 'Blurb Template',
// 			templateTypeTag: 'TEMP_blurb',
// 			description: 'defaultBlurbsTemplate' //provide only if this is not overriding a global param
// 		}
// 	},

// 	//Every leaf is a template
// 	templates: {

// 		styles: {
// 			primaryStyle: {
// 				type: 'template',
// 				paramId: 'param_123',
// 				name: 'templateId',
// 				value: 'template_primaryStyle_001',
// 				description: 'A style template'
// 			}
// 		},
// 		sections: {

// 			volume: {

// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for a VOLUME'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					valuex: 'template_volume_body_001',
// 					value: '08f597d0-2d4f-11e9-a77c-ddff9e31bcb3',
// 					description: 'A body template for a VOLUME'
// 				}

// 			},
// 			part: {

// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for a PART'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'template_part_body_001',
// 					description: 'A body template for a PART'
// 				}

// 			},
// 			chapter: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for a CHAPTER'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_chapter_body_001',
// 					description: 'A body template for a CHAPTER'
// 				}
// 			},
// 			titlepage: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for a BLURBS'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_titlepage_body_001',
// 					description: 'A body template for a TITLEPAGE'
// 				}
// 			},
// 			blurbs: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for a BLURBS'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					valuex: 'default_template_blurbs_body_001',
// 					value: 'e929ff90-2d53-11e9-9c93-b90237bc6487',
// 					description: 'A body template for a BLURBS'
// 				}
// 			},
// 			halftitle: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_halftitle_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			fullpageimage: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_fullpageimage_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			alsoby: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_alsoby_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			aboutauthor: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_aboutauthor_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			acknowledgements: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_acknowledgements_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			afterword: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_afterword_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			epilogue: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_epilogue_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			prologue: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_prologue_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			epigraph: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_epigraph_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			toc: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_toc_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			foreward: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_foreward_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			introduction: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_introduction_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			preface: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_preface_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			copyright: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_copyright_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			dedication: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_dedication_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			},
// 			cover: {
// 				page: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: '1234-9876-2222-4444',
// 					description: 'A page template for xxxxxxxxxx'
// 				},
// 				body: {
// 					type: 'template',
// 					paramId: 'param_123',
// 					name: 'templateId',
// 					value: 'default_template_cover_body_001',
// 					description: 'A body template for xxxxxxxxx'
// 				}
// 			}

// 		},

// 	}

// }

export class BuildContext {

	constructor({ epubVersion, epubPlatform }) {
		//this.epubVersion = epubVersion;
		this.epubPlatform = epubPlatform;

		this.epubVersion = 2;

		if (epubPlatform && (epubPlatform == 'EPUB_PREVIEWER' || epubPlatform == 'iBooks' || epubPlatform == 'kobo' || epubPlatform == 'kindle' || epubPlatform == 'googlePlay')) {
			this.epubVersion = 3;
		}
	}

	//http://apex.infogridpacific.com/df/epub-type-epubpackaging8.html
	partTypeToEpubTypeMappings = {
		VOLUME: 'volume',
		PART: 'part',
		CHAPTER: 'chapter',
		BLURBS: 'blurbs',
		COPYRIGHT: 'copyright-page',
		HALFTITLE: 'halftitlepage',
		TITLEPAGE: 'titlepage',
		DEDICATION: 'dedication',
		EPIGRAPH: 'epigraph',
		TOC: 'toc',
		FOREWARD: 'foreword',
		INTRODUCTION: 'introduction',
		PREFACE: 'preface',
		PROLOGUE: 'prologue',
		EPILOGUE: 'epilogue',
		AFTERWORD: 'afterword',
		ACKNOWLEDGEMENTS: 'acknowledgements',
		ABOUTAUTHOR: 'z3998:biographical-note',
		ALSOBY: 'z3998:published-works',
		FULLPAGEIMAGE: '',
		NOTES: 'rearnotes',
		CONCLUSION: 'conclusion', //---->Not implemented
		INDEX: 'index', //---->Not implemented
		APPENDIX: 'appendix', //---->Not implemented
		GLOSSARY: 'glossary', //---->Not implemented
		BIBLIOGRAPHY: 'bibliography', //---->Not implemented
		CONTRIBUTORS: 'contributors'

	}

	getEpubTypeAttribute = (documentPart) => {
		let epubType = '';
		return epubType;
		if (this.epubVersion && this.epubVersion == 3 && documentPart && this.partTypeToEpubTypeMappings[documentPart.partType]) {
			epubType = 'epub:type="' + this.partTypeToEpubTypeMappings[documentPart.partType] + '"';
		}
		return epubType;
	}

}








let userBuild = {

	recipe: {
		recipeId: '1234-9876',

	},
	overrides: {
		param_123: {
			value: 'newValue'
		}
	}





}





// export class RecipeUtils {

// 	setOverrideParam = (documentPart, paramPath, override) => {



// 		try {

// 			let recipe = documentPart?.getRecipe().json;
// 			let partTypeShorthand = PART_MAPPING[documentPart.partType];

// 			let currentObject = this.getParamObject(recipe, paramPath);
// 			if (currentObject.overrideIds == null) {
// 				currentObject.overrideIds = {};
// 			}
// 			let overrideConfig = currentObject.overrideIds[documentPart.id];
// 			if (overrideConfig == null) {


// 				overrideConfig = {

// 				}
// 			}

// 			//seed the override with the current value ( from the current scope )
// 			let currentScopedValue = this.getRecipeParamByPartType(null, recipe, documentPart, paramPath);

// 			overrideConfig.override = override;
// 			overrideConfig.value = currentScopedValue;

// 			currentObject.overrideIds[documentPart.id] = overrideConfig;

// 		} catch (err) {
// 			console.log(err);
// 		}


// 	}

// 	getOverrideParam = (documentPart, paramPath) => {



// 		try {

// 			let recipe = documentPart?.getRecipe().json;
// 			let partTypeShorthand = PART_MAPPING[documentPart.partType];

// 			let currentObject = this.getParamObject(recipe, paramPath);
// 			let overrideConfig = currentObject.overrideIds[documentPart.id];

// 			return overrideConfig;

// 		} catch (err) {
// 			console.log(err);
// 		}


// 	}

// 	getScope = (documentPart) => {

// 		let scope = "common";

// 		try {

// 			let recipe = documentPart?.getRecipe().json;
// 			let partTypeShorthand = PART_MAPPING[documentPart.partType];

// 			if (recipe.scope.partType[partTypeShorthand] != null) {

// 				scope = recipe.scope.partType[partTypeShorthand];

// 			}

// 		} catch (err) {
// 			console.log(err);
// 		}

// 		return scope;
// 	}

// 	setScope = (documentPart, scope) => {




// 		try {

// 			let recipe = documentPart?.getRecipe().json;
// 			let partTypeShorthand = PART_MAPPING[documentPart.partType];

// 			if (scope && (scope == 'common' || scope == 'partType')) {

// 				recipe.scope.partType[partTypeShorthand] = scope;


// 			}



// 		} catch (err) {
// 			console.log(err);
// 		}

// 		return scope;
// 	}

// 	getParamObject = (recipe, paramPath) => {

// 		let currentObject = recipe.props;
// 		try {
// 			let paths = paramPath.split('.');
// 			paths.forEach((pathPart) => {

// 				//If this part of the param path does not exist, create it.
// 				if(!currentObject[pathPart]){
// 					currentObject[pathPart] = {

// 					}
// 				}

// 				currentObject = currentObject[pathPart];
				
// 			})

// 			if(!currentObject.part){
// 				currentObject.part = {};
// 			}

// 			if(!currentObject.overrideIds){
// 				currentObject.overrideIds = {};
// 			}

// 			return currentObject;
// 		} catch (err) {
// 			console.log(err);
// 		}
// 	}

// 	// getRecipePropValueSource = (target, recipe, documentPart, paramPath)=>{

// 	// 	let currentObject = this.getParamObject(recipe,paramPath);

// 	// 	let partTypeShorthand = PART_MAPPING[documentPart.partType];

// 	// 	if(currentObject.VALUE_SOURCE.part[partTypeShorthand]){
// 	// 		return currentObject.VALUE_SOURCE.part[partTypeShorthand];
// 	// 	}


// 	// 	return currentObject.VALUE_SOURCE.def;
// 	// };

// 	// setRecipePropValueSource = (target, recipe, documentPart, paramPath, newValue)=>{

// 	// 	let currentObject = this.getParamObject(recipe,paramPath);

// 	// 	let partTypeShorthand = PART_MAPPING[documentPart.partType];

// 	// 	currentObject.VALUE_SOURCE.part[partTypeShorthand] = newValue;

// 	// 	// if(currentObject.VALUE_SOURCE.part[partTypeShorthand]){
// 	// 	// 	currentObject.VALUE_SOURCE.part[partTypeShorthand] = newValue;
// 	// 	// }


// 	// 	return currentObject.VALUE_SOURCE.def;
// 	// };


// 	getAllRecipeParamValues = (recipe, paramPath) => {

// 		let values = {};
// 		let _calculatedValue = null;
// 		//let currentObject = recipe.props;
// 		let currentObject = this.getParamObject(recipe, paramPath)
// 		try {

// 			values[currentObject.def] = currentObject.def;
// 			values[currentObject.com] = currentObject.com;
// 			if (currentObject.part) {

// 				let keys = Object.keys(currentObject.part);
// 				try {
// 					keys.forEach((key) => {

// 						values[currentObject.part[key]] = currentObject.part[key];

// 					})
// 				} catch (err) {
// 					console.log(err);
// 				}

// 			}
// 			if (currentObject.overrideIds) {
// 				let partIds = Object.keys(currentObject.overrideIds);
// 				try {
// 					partIds.forEach((partId) => {

// 						let partOverride = currentObject.overrideIds[partId];
// 						if (partOverride.value) {
// 							values[partOverride.value] = partOverride.value;
// 						}

// 					})
// 				} catch (err) {
// 					console.log(err);
// 				}



			
// 			}

// 		} catch (err) {
// 			console.log(err);
// 		}

// 		return values;
// 		//return 'partType:' + documentPart.partType;
// 	}
// 	//target is the nunjucks variable being set
// 	getRecipeParamByPartType = (target, recipe, documentPart, paramPath, defaultValue) => {

// 		let _calculatedValue = null;
// 		//let currentObject = recipe.props;
// 		let currentObject = this.getParamObject(recipe, paramPath)
// 		try {
// 			// let paths = paramPath.split('.');
// 			// paths.forEach((pathPart) => {
// 			// 	currentObject = currentObject[pathPart];
// 			// })

// 			let partTypeShorthand = PART_MAPPING[documentPart.partType];

// 			//check to see if currentObject has a VALUE_SOURCE. If not, then 
// 			//currentObject IS the value.
// 			//if(currentObject.VALUE_SOURCE!=null){

// 			//let sourceByType = currentObject.VALUE_SOURCE.part[partTypeShorthand];
// 			let sourceByType = recipe.scope.partType[partTypeShorthand];
// 			sourceByType = sourceByType ? sourceByType : 'common';
// 			if (sourceByType != null) {
// 				//If there is a value for this partType return that.
// 				switch (sourceByType) {

// 					case "common":
// 						_calculatedValue = currentObject.com;
// 						break;

// 					case "partType":
// 						_calculatedValue = currentObject.part[partTypeShorthand];
// 						break;

// 					// case "self":
// 					// 	_calculatedValue = documentPart[currentObject.entityFieldName];
// 					// break;
// 				}

// 				if (_calculatedValue == null) {
// 					//If the recipe is calling for a byPart value and 
// 					//this partType does not have a value, then return the
// 					//default ( def ) value.
// 					_calculatedValue = currentObject.def;
// 				}
// 			} else {
// 				//Otherwise, return the detault value.	
// 				_calculatedValue = currentObject.com;
// 			}


// 			//}

// 			if (currentObject.overrideIds) {

// 				let overrideConfig = currentObject.overrideIds[documentPart.id];

// 				//If the param is overridden but has no value, default to the _calculatedValue
// 				//from this parts current scope. 
// 				if (overrideConfig && overrideConfig.override == true && overrideConfig.value != null) {

// 					_calculatedValue = overrideConfig.value;

// 				}

// 			}

// 			if(!_calculatedValue){

// 				_calculatedValue = defaultValue;

// 			}
// 			return _calculatedValue;
// 		} catch (err) {
// 			console.log(err);
// 		}
// 		//return 'partType:' + documentPart.partType;
// 	}

// 	setRecipeParamByPartType = (target, recipe, documentPart, paramPath, newValue) => {


// 		let currentObject = this.getParamObject(recipe, paramPath)
// 		try {

// 			let partTypeShorthand = PART_MAPPING[documentPart.partType];

// 			let overrideConfig = currentObject.overrideIds != null ? currentObject.overrideIds[documentPart.id] : null;
// 			if (overrideConfig && overrideConfig.override == true) {

// 				overrideConfig.value = newValue;

// 			} else { //if(currentObject.VALUE_SOURCE!=null){

// 				//let sourceByType = currentObject.VALUE_SOURCE.part[partTypeShorthand];
// 				let sourceByType = recipe.scope.partType[partTypeShorthand];
// 				sourceByType = sourceByType ? sourceByType : 'common';

// 				if (sourceByType != null) {
// 					//If there is a value for this partType return that.
// 					switch (sourceByType) {

// 						case "common":
// 							currentObject.com = newValue;
// 							break;

// 						case "partType":
// 							currentObject.part[partTypeShorthand] = newValue;
// 							break;

// 						// case "self":
// 						// 	documentPart[currentObject.entityFieldName] = newValue;
// 						// break;
// 					}

// 				} else {
// 					//Otherwise, set the common value to the newValue. 
// 					//If VALUE_SOURCE==null set it to 'common' since we cannot change
// 					//the default value and this object may have been using to the 
// 					//default value. 	
// 					currentObject.com = newValue;
// 				}


// 			}

// 		} catch (err) {
// 			console.log(err);
// 		}

// 	}
// }

// export class BuildManager {



// 	constructor() {

// 		let env = nunjucks.configure({
// 			autoescape: false
// 		});

// 		//let env = nunjucks.configure();

// 		env.addFilter('toObject', function (objectString) {
// 			return JSON.parse(objectString);
// 		})

// 		env.addFilter('getRecipeParamByPartType', function (target, recipe, documentPart, paramPath, defaultValue) {

// 			//let currentRecipe = props.documentPart?.getRecipe().json;
// 			let value = new RecipeUtils().getRecipeParamByPartType(target, recipe, documentPart, paramPath, defaultValue)
// 			return value;
// 		})



// 	}

// 	getRecipeTemplates(recipe) {
// 		let templates = new Array();
// 		//////console.log(recipe.templates);
// 		if (recipe /*&& recipe.templates*/) {
// 			//////console.log(recipe.templates);
// 			templates = this._extractTemplates(recipe);
// 		}

// 		//////console.log(templates);
// 		return templates;
// 	}

// 	_extractTemplates(a) {

// 		//////console.log("a:");
// 		//////console.log(a);
// 		let templates = new Array();
// 		let keys = Object.keys(a)
// 		keys.forEach((key) => {
// 			//////console.log("key:"+key);
// 			let element = a[key];
// 			//////console.log(Object.keys(element));
// 			//////console.log(typeof element);
// 			if (typeof element == 'object') {
// 				//continue;

// 				if (element.type == 'template') {
// 					templates.push(element);
// 					//////console.log('pushing:'+key)
// 				} else {
// 					let childKeys = Object.keys(element);
// 					//////console.log('deeper into:'+Object.keys(element));
// 					//////console.log(childKeys);
// 					templates = templates.concat(this._extractTemplates(element));
// 				}
// 			}

// 		})

// 		return templates;
// 	}

// 	createUserBuild({ userId, recipe }) {
// 		////console.log('createUserBuild()...');
// 	}

// 	//Loads the recipe and other things
// 	inflateUserBuild({ userBuild }) {

// 	}

// 	getPartTemplate({ userBuild, partType }) {

// 	}

// 	generateMacros(documentPart, subTemplates, inflatedTemplates, context, recipe, buildContext) {

// 		/*
// 		subTemplates:[
// 			{
// 				macroName:'chapterHeader',
// 				templateId:'template_chapter_header_001',
// 				subTemplates:{

// 				}
// 			}
// 		]
// 		*/
// 		let that = this;
// 		let macros = '';

// 		if (!subTemplates) {
// 			return macros;
// 		}

// 		/*
// 		////console.log('global macros...')
// 		////console.log(recipe.macros);
// 		if(recipe.macros){
// 			recipe.macros.forEach((macro)=>{
// 				subTemplates.push(macro);
// 			})
// 		}*/

// 		subTemplates.forEach((subTemplate) => {

// 			//////console.log(subTemplate);
// 			let templateId = subTemplate.templateId;

// 			if (templateId.startsWith('param.')) {
// 				let paramId = templateId.substring(6, templateId.length);
// 				//////console.log('paramId:'+paramId);

// 				//first get the global templateId
// 				try {
// 					templateId = context.defaults.params[paramId].value;
// 					//////console.log(templateId);
// 				} catch (err) {
// 					//////console.log(err);
// 					////console.log('error here. Possibly ignorable');
// 				}

// 				//see if the recipe overrides it
// 				try {
// 					templateId = recipe.params[paramId].value;
// 					//////console.log(templateId);
// 				} catch (err) {
// 					////console.log(err);
// 				}

// 			}

// 			if (templateId.startsWith('props.')) {
// 				let propPath = templateId.substring(6, templateId.length);
// 				let propValue = new RecipeUtils().getRecipeParamByPartType(null, recipe, documentPart, propPath);
// 				console.log('propPath:' + propPath);

// 				templateId = propValue;

// 			}

// 			//////console.log(templateId);
// 			let inflatedTemplate = inflatedTemplates[templateId];
// 			let macroParams = '(';

// 			//The inflated template could be missing if the param holds the id of a 
// 			//template that has been removed. Or it could hold an old test value like
// 			//template_block_quotes_001
// 			if (!inflatedTemplate) {

// 				inflatedTemplate = inflatedTemplates[subTemplate.defaultTemplateId];
// 			}
// 			if (inflatedTemplate) {


// 				if (inflatedTemplate.params) {

// 					//name="name1", value="value1", type="type1"
// 					//send the documentPart param down to every template
// 					//macros+='documentPartx'+(inflatedTemplate.params && inflatedTemplate.params.length>0)?',':''
// 					inflatedTemplate.params.forEach((param, count) => {

// 						if (param.type && param.type == 'reference') {
// 							macroParams += ' ' + param.name;
// 							if (param.defaultValue) {
// 								//macroParams+='='+param.defaultValue;
// 							}
// 						} else {
// 							macroParams += ' ' + param.name;
// 							if (param.defaultValue != null) {
// 								if (param.type && param.type == 'boolean') {
// 									//macroParams+='='+param.defaultValue+' ';
// 								} else {
// 									//macroParams+='="'+param.defaultValue+'" ';
// 								}

// 							}
// 						}


// 						if (count < (inflatedTemplate.params.length - 1)) {
// 							macroParams += ',';
// 						}

// 					});
// 				}
// 				macroParams += ')';
// 				let macro = '{% macro ' + subTemplate.macroName + macroParams + ' %}' +
// 					//'<div>'+
// 					inflatedTemplate.content +
// 					//'</div>'+
// 					'{% endmacro %}';

// 				macros += macro;

// 				if (inflatedTemplate.subTemplates) {
// 					macros += that.generateMacros(documentPart, inflatedTemplate.subTemplates, inflatedTemplates, context, recipe, buildContext);
// 				}

// 			}












// 			//macroParams = null;
// 		});


// 		return macros;
// 	}

// 	generateBodyContent({ book, epubType, documentPart, content, buildContext, recipe, context, inflatedTemplates, allBooks, user, mappedImages }) {

// 		if (!documentPart) {
// 			return '';
// 		}

// 		let allBooksNoMobx = allBooks.map(m => { return m });
// 		//1. Get body template from the recipe
// 		let partType = documentPart.partType == null ? 'NO_PART_TYPE' : documentPart.partType.toLowerCase();
// 		//alert(partType);
// 		let styleTemplateConfig = {};
// 		let bodyTemplateConfig = {};
// 		let templateContent = '';
// 		let processedContent = '';
// 		let inflatedTemplate = null;
// 		let styleInflatedTemplate = null;
// 		let styleContent = '';
// 		let parseError = null;
// 		//First load the style sheet defined in the recipe
// 		try {

// 			styleTemplateConfig = recipe.templates.styles.primaryStyle;
// 			if (!styleTemplateConfig) {
// 				styleTemplateConfig = context.defaults.templates.styles.primaryStyle;
// 			}

// 			styleInflatedTemplate = inflatedTemplates[styleTemplateConfig.value];
// 			styleContent = styleInflatedTemplate.content;

// 			//////console.log(styleContent);
// 		} catch (err) {
// 			////console.log('err');
// 		}


// 		/*templates:{

// 		styles:{
// 			primaryStyle:{
// 				paramId:'param_123',
// 				name:'templateId',
// 				value:'1234-9876-2222-4444',
// 				description:'A page template for a PART'
// 			}
// 		},*/

// 		try {

// 			bodyTemplateConfig = recipe.templates.sections[partType] ? recipe.templates.sections[partType].body : null;
// 			//alert(bodyTemplateConfig);
// 			if (!bodyTemplateConfig) {
// 				bodyTemplateConfig = context.defaults.templates.sections[partType] ? context.defaults.templates.sections[partType].body : null;
// 			}

// 			//////console.log(bodyTemplateConfig);
// 			//////console.log("body template config......\n")
// 			inflatedTemplate = inflatedTemplates[bodyTemplateConfig.value];

// 			//Simulates getting the body template by partType. This will later be changed in 
// 			//the recipe for each partType using: context.defaults.templates.sections[partType].body


// 			//inflatedTemplate = inflatedTemplates["a2daca1d-f007-4697-a443-a29d0f4cb4e5"];

// 			let subTemplates = Array.from(inflatedTemplate.subTemplates);
// 			let styleSubTemplates = Array.from(styleInflatedTemplate.subTemplates);

// 			subTemplates = subTemplates.concat(styleSubTemplates);
// 			////console.log('global macros...')
// 			//////console.log(recipe.macros);
// 			if (recipe.macros) {
// 				recipe.macros.forEach((macro) => {
// 					subTemplates.push(macro);
// 				})
// 			}


// 			//let macros = this.generateMacros(inflatedTemplate.subTemplates,inflatedTemplates,context,recipe);
// 			let macros = this.generateMacros(documentPart, subTemplates, inflatedTemplates, context, recipe, buildContext);
// 			//////console.log(macros);
// 			subTemplates = null;
// 			let templateId = bodyTemplateConfig.value;
// 			templateContent = inflatedTemplate.content;


// 			//////console.log(macros);
// 			//////console.log("\n")
// 			try {
// 				book.bookDraft = documentPart.bookDraftDocumentPart.bookDraft;
// 			} catch (err) {

// 			}
// 			book.epubType = epubType;
// 			let params = {
// 				//user: 'James',
// 				content: content,
// 				documentPart: documentPart,
// 				book: book,
// 				epubType: epubType,
// 				allBooks: allBooksNoMobx,
// 				user: user,
// 				nunjucks: nunjucks,
// 				macros: macros,
// 				buildContext: buildContext,
// 				mappedImages: mappedImages,
// 				//imagesRoot:config.default.images.RESIZER_GATEWAY
// 				//imagesRoot: 'https://c22t1rqd15.execute-api.us-east-1.amazonaws.com/dev',
// 				epubImagesRoot: './images'
// 			}


// 			params = this.mergeParams({
// 				params: params,
// 				recipe: recipe,
// 				context: context
// 			});

// 			params.recipe = recipe;
// 			//////console.log(params);
// 			processedContent = nunjucks.renderString(macros + styleContent + templateContent, params);
// 			//////console.log(processedContent);



// 		} catch (err) {
// 			//do nothing
// 			//console.log(err);
// 			parseError = err
// 		}


// 		//////console.log(bodyTemplateConfig);

// 		//return processedContent;

// 		return {
// 			styleContent: styleContent,
// 			bodyContent: processedContent,
// 			parseError
// 		}


// 	}

// 	generateBodyContent_30Dec2022({ book, epubType, documentPart, content, buildContext, recipe, context, inflatedTemplates, allBooks, user, mappedImages }) {

// 		if (!documentPart) {
// 			return '';
// 		}

// 		let allBooksNoMobx = allBooks.map(m => { return m });
// 		//1. Get body template from the recipe
// 		let partType = documentPart.partType == null ? 'NO_PART_TYPE' : documentPart.partType.toLowerCase();
// 		//alert(partType);
// 		let styleTemplateConfig = {};
// 		let bodyTemplateConfig = {};
// 		let templateContent = '';
// 		let processedContent = '';
// 		let inflatedTemplate = null;
// 		let styleInflatedTemplate = null;
// 		let styleContent = '';
// 		let parseError = null;
// 		//First load the style sheet defined in the recipe
// 		try {

// 			styleTemplateConfig = recipe.templates.styles.primaryStyle;
// 			if (!styleTemplateConfig) {
// 				styleTemplateConfig = context.defaults.templates.styles.primaryStyle;
// 			}

// 			styleInflatedTemplate = inflatedTemplates[styleTemplateConfig.value];
// 			styleContent = styleInflatedTemplate.content;

// 			//////console.log(styleContent);
// 		} catch (err) {
// 			////console.log('err');
// 		}


// 		/*templates:{

// 		styles:{
// 			primaryStyle:{
// 				paramId:'param_123',
// 				name:'templateId',
// 				value:'1234-9876-2222-4444',
// 				description:'A page template for a PART'
// 			}
// 		},*/

// 		try {

// 			bodyTemplateConfig = recipe.templates.sections[partType] ? recipe.templates.sections[partType].body : null;
// 			//alert(bodyTemplateConfig);
// 			if (!bodyTemplateConfig) {
// 				bodyTemplateConfig = context.defaults.templates.sections[partType] ? context.defaults.templates.sections[partType].body : null;
// 			}

// 			//////console.log(bodyTemplateConfig);
// 			//////console.log("body template config......\n")
// 			inflatedTemplate = inflatedTemplates[bodyTemplateConfig.value];
// 			let subTemplates = Array.from(inflatedTemplate.subTemplates);
// 			let styleSubTemplates = Array.from(styleInflatedTemplate.subTemplates);

// 			subTemplates = subTemplates.concat(styleSubTemplates);
// 			////console.log('global macros...')
// 			//////console.log(recipe.macros);
// 			if (recipe.macros) {
// 				recipe.macros.forEach((macro) => {
// 					subTemplates.push(macro);
// 				})
// 			}


// 			//let macros = this.generateMacros(inflatedTemplate.subTemplates,inflatedTemplates,context,recipe);
// 			let macros = this.generateMacros(documentPart, subTemplates, inflatedTemplates, context, recipe, buildContext);
// 			//////console.log(macros);
// 			subTemplates = null;
// 			let templateId = bodyTemplateConfig.value;
// 			templateContent = inflatedTemplate.content;


// 			//////console.log(macros);
// 			//////console.log("\n")
// 			try {
// 				book.bookDraft = documentPart.bookDraftDocumentPart.bookDraft;
// 			} catch (err) {

// 			}
// 			book.epubType = epubType;
// 			let params = {
// 				user: 'James',
// 				content: content,
// 				documentPart: documentPart,
// 				book: book,
// 				epubType: epubType,
// 				allBooks: allBooksNoMobx,
// 				user: user,
// 				nunjucks: nunjucks,
// 				macros: macros,
// 				buildContext: buildContext,
// 				mappedImages: mappedImages,
// 				//imagesRoot:config.default.images.RESIZER_GATEWAY
// 				imagesRoot: 'https://c22t1rqd15.execute-api.us-east-1.amazonaws.com/dev',
// 				epubImagesRoot: './images'
// 			}


// 			params = this.mergeParams({
// 				params: params,
// 				recipe: recipe,
// 				context: context
// 			});

// 			params.recipe = recipe;
// 			//////console.log(params);
// 			processedContent = nunjucks.renderString(macros + styleContent + templateContent, params);
// 			//////console.log(processedContent);



// 		} catch (err) {
// 			//do nothing
// 			//console.log(err);
// 			parseError = err
// 		}


// 		//////console.log(bodyTemplateConfig);

// 		//return processedContent;

// 		return {
// 			styleContent: styleContent,
// 			bodyContent: processedContent,
// 			parseError
// 		}


// 	}

// 	mergeParams({ params, recipe, context }) {

// 		let newParams = {};

// 		if (context?.defaults?.params) {

// 			let paramIds = Object.keys(context.defaults.params);
// 			paramIds.forEach((paramId) => {

// 				newParams[paramId] = context.defaults.params[paramId].value;
// 			})
// 		}

// 		if (recipe) {

// 			let paramIds = Object.keys(recipe.params);
// 			paramIds.forEach((paramId) => {

// 				newParams[paramId] = recipe.params[paramId].value;
// 			})
// 		}

// 		if (params) {

// 			let paramIds = Object.keys(params);
// 			paramIds.forEach((paramId) => {

// 				newParams[paramId] = params[paramId];
// 			})
// 		}

// 		return newParams;


// 	}

// 	getParamCaseIndependant({ paramId, recipe }) {

// 		let mappedParams = {};
// 		try {

// 			Object(recipe.params).keys.forEach((key) => {
// 				let recipeParam = recipe.params[key];
// 				mappedParams[key.toLowerCase()] = recipeParam;
// 			})
// 		} catch (err) {
// 			//console.log(err);
// 		}

// 		let param = mappedParams[paramId.toLowerCase()];

// 		return param;

// 	}

// 	getParam({ element, paramId, paramSource, context, recipe, recipeObject }) {


// 		////console.log('getParam()...');
// 		if (!recipe) {
// 			return;
// 		}
// 		//let param = {};

// 		/*let contextParam = context.defaults.params[paramId];
		
// 		if(contextParam){
// 			let keys = Object.keys(contextParam);
// 			keys.forEach((key)=>{
// 				param[key] = contextParam[key]
// 			});
			
// 		}*/
// 		if (paramSource && paramSource.toLowerCase() == 'global') {

// 			let globalParam = context.defaults.params[paramId];
// 			return globalParam;
// 		}

// 		/*
// 		global_showOrnamentalBreaks:{
// 				value:true,
// 				title:'Show Ornamental Break',
// 				type:'boolean'
// 			},
// 		*/
// 		if (paramSource && paramSource.toLowerCase() == 'recipe') {

// 			// let foundParamId = null;
// 			// Object.keys(recipe.params).forEach((key)=>{

// 			// 	//console.log(key.toLowerCase());
// 			// 	//console.log(paramId.toLowerCase());
// 			// 	if(key.toLowerCase()==paramId.toLowerCase()){
// 			// 		foundParamId = key;
// 			// 	}

// 			// });

// 			let recipeParam = recipeObject.getParamByLowerCase(paramId.toLowerCase());
// 			//let recipeParam = recipe.params[paramId];
// 			//let recipeParam = recipe.params[foundParamId];

// 			let globalParam = {};

// 			try {
// 				globalParam = context.defaults.params[paramId];
// 				if (globalParam) {
// 					recipeParam.type = globalParam.type;
// 					recipeParam.title = globalParam.title;
// 				}

// 			} catch (err) {
// 				//////console.log(err);
// 			}

// 			try {
// 				if (recipeParam.newName) {
// 					recipeParam = recipe.params[recipeParam.newName];
// 				}
// 			} catch (err) {
// 				//console.log(err);
// 				return this.getParamCaseIndependant({ recipe, paramId });
// 			}
// 			return recipeParam;
// 		}

// 		//if(paramSource && paramSource.toLowerCase()=='part'){

// 		/*defaultOrnamentalBreaksTemplate:{
// 		value:'template_ornamental_break_002',
// 		type:'template',
// 		title:'Ornamental Break Template',
// 		templateTypeTag:'TEMP_ornBreak',
// 		description:'defaultOrnamentalBreaksTemplate' //provide only if this is not overriding a global param
// 	}

// 	paramId,paramSource,context,recipe


// 	{
// 		label:'USE_PARAM_LABEL',
// 		useParamLabel:true,
// 		paramId:'blurbs',
// 		source:'BOOK', //BUILD,GLOBAL,RECIPE,BOOK,DRAFT,PART
// 		title:'Book blurbs',
// 		type:'blurbs'
// 	}



// 	*/

// 		return {
// 			//value:'',
// 			type: element.type,
// 			title: element.title
// 			//templateTypeTag:'',
// 			//description:''
// 		}
// 		//}



// 	}


// 	getThemeTag({ context, recipe, inflatedTemplates }) {

// 		let themeTag = null;
// 		try {

// 			let defaultThemeTemplateParam = this.getParam({
// 				paramId: 'defaultThemeTemplate',
// 				paramSource: 'RECIPE',
// 				context: context,
// 				recipe: recipe.json,
// 				recipeObject: recipe
// 			});

// 			if (defaultThemeTemplateParam && inflatedTemplates) {
// 				let themeTemplate = inflatedTemplates[defaultThemeTemplateParam.value];
// 				////console.log(themeTemplate);
// 				if (themeTemplate) {
// 					themeTag = themeTemplate.themeTag;
// 				}
// 			}

// 		} catch (err) {
// 			////console.log(err);
// 		}


// 		return themeTag;

// 	}
// 	getTemplatesByTag({ context, recipe, inflatedTemplates, tag, themeTag }) {

// 		//////console.log('getTemplatesByTag()...'+tag);
// 		let templates = {};





// 		if (inflatedTemplates) {

// 			let it = mobx.toJS(inflatedTemplates);
// 			let templateIds = Object.keys(it);

// 			templateIds.forEach((templateId) => {
// 				//////console.log(templateId);
// 				//newParams[paramId] = context.defaults.params[paramId].value;
// 				let template = inflatedTemplates[templateId];
// 				//template = mobx.toJS(template);
// 				//////console.log(template.toJs);
// 				let tags = template.tags;
// 				//////console.log(tags);
// 				if (tags) {
// 					tags.forEach((t) => {
// 						if (t == tag && templates[templateId] == null) {

// 							if (themeTag) {
// 								//now cycle through all tags again looking for any themeTags
// 								tags.forEach((t2) => {
// 									if ((t2 == themeTag || t2 == 'THEME_ALL') && templates[templateId] == null) {
// 										templates[templateId] = template;
// 									}
// 								});
// 							} else {
// 								templates[templateId] = template;
// 							}

// 						}
// 					});
// 				}
// 			})

// 		}

// 		return templates;
// 	}


// 	preProcessPropertyPanelConfig({
// 		propertyPanelConfig,
// 		context,
// 		recipe,
// 		recipeObject
// 	}) {

// 		let that = this;
// 		if (propertyPanelConfig) {
// 			propertyPanelConfig.elements.forEach((el) => {
// 				//propertyPanelElements = propertyPanelDefinitions.byPartType[this.props.documentPart.partType.toLowerCase()].elements.map((el)=>{

// 				/*
// 			{
// 					label:'USE_PARAM_LABEL',
// 					useParamLabel:true,
// 					paramId:'blurbs',
// 					source:'BOOK', //BUILD,GLOBAL,RECIPE,BOOK,DRAFT,PART
// 					title:'Book blurbs',
// 					type:'blurbs'
// 				}
// 				*/
// 				let param = that.getParam({
// 					element: el,
// 					paramId: el.paramId,
// 					paramSource: el.source,
// 					context: context,
// 					recipe: recipe,
// 					recipeObject: recipeObject
// 				});
// 			})
// 		}

// 	}


// }



let propertyPanelDefinitions = {

	byPartType: {

		blurbs: {

			elements: [
				{
					label: 'USE_PARAM_LABEL',
					useParamLabel: true,
					paramId: 'blurbs',
					source: 'BOOK', //BUILD,GLOBAL,RECIPE,BOOK,DRAFT,PART
					title: 'Book blurbs',
					type: 'blurbs'
				},
				{
					label: 'USE_PARAM_LABEL',
					useParamLabel: true,
					paramId: 'defaultBlurbsTemplate',
					source: 'RECIPE' //BUILD,GLOBAL,RECIPE,PART
				},
			]
		},
		volume: {

			elements: [
				{
					label: 'USE_PARAM_LABEL',
					useParamLabel: true,
					paramId: 'global_showChapterTitles',
					source: 'RECIPE' //BUILD,GLOBAL,RECIPE,PART
				}
			]
		},

		chapter: {
			elements: [
				{
					label: 'USE_PARAM_LABEL',
					useParamLabel: true,
					paramId: 'title',
					source: 'DOCUMENT_PART' //BUILD,GLOBAL,RECIPE,PART
				},
				{
					label: 'USE_PARAM_LABEL',
					useParamLabel: true,
					paramId: 'global_showChapterTitles',
					source: 'RECIPE' //BUILD,GLOBAL,RECIPE,PART
				},
				{
					label: 'USE_PARAM_LABEL',
					useParamLabel: true,
					paramId: 'recipe1CustomParam1',
					source: 'RECIPE' //BUILD,GLOBAL,RECIPE,PART
				},
				{
					label: 'USE_PARAM_LABEL',
					useParamLabel: true,
					paramId: 'global_showOrnamentalBreaks',
					source: 'RECIPE' //BUILD,GLOBAL,RECIPE,PART
				},
				{
					label: 'USE_PARAM_LABEL',
					useParamLabel: true,
					paramId: 'defaultOrnamentalBreaksTemplate',
					source: 'RECIPE' //BUILD,GLOBAL,RECIPE,PART
				},


				/*{
					//type:'BY_PARAM_TYPE',
					label:'USE_PARAM_LABEL',
					useParamLabel:true,
					paramId:'global_showChapterTitles',
					source:'GLOBAL' //BUILD,GLOBAL,RECIPE,PART
				},
				{
					type:'DIVIDER'
				}*/

			]
		}
	}
}


//exports.BuildManager=BuildManager;
//exports.recipe1=recipe1;
//exports.bookMerlinContext=bookMerlinContext;
//exports.propertyPanelDefinitions=propertyPanelDefinitions;