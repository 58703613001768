import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from "@mui/material";
import { List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import { useNavigate, useLocation } from "react-router-dom";
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Feather from 'mdi-material-ui/Feather';
import Home from '@mui/icons-material/Home';
import StandardRoundedAction from '../components/actions/StandardRoundedAction';


function ToolsNavigator(props) {

  let navigate = useNavigate();
  let location = useLocation();

  const theme = useTheme();
  let {bookDraft} = props;



  const selectRoute = (route) => {

    navigate(route);
    props.onRouteSelected();

  }

  return (


    <List>
      <Divider />
      {!props.hideWriteAction &&
        <ListItem disablePadding>
          <ListItemButton sx={{
            pl: 2
          }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              selectRoute('/write')

            }}>

            <ListItemIcon>
              <StandardRoundedAction icon={<Feather sx={{
                color: theme.palette.standardActions.icon.color.main,

              }} />} />

            </ListItemIcon>

            <ListItemText primary="Write" primaryTypographyProps={{
              style: {
                color: theme.palette.standardActions.label.color.main
              }
            }} />
          </ListItemButton>
        </ListItem>
      }

      {!props.hideFormatAction &&
        <ListItem disablePadding>
          <ListItemButton sx={{
            pl: 2
          }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              selectRoute('/format')

            }}>

            <ListItemIcon>
              <StandardRoundedAction icon={<FormatPaintIcon sx={{
                color: theme.palette.standardActions.icon.color.main,

              }} />} />

            </ListItemIcon>
            <ListItemText primary="Format" primaryTypographyProps={{
              style: {
                color: theme.palette.standardActions.label.color.main
              }
            }} />
          </ListItemButton>
        </ListItem>
      } 
      {!props.hideBookHomeAction &&
        <ListItem disablePadding>
          <ListItemButton sx={{
            pl: 2
          }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              
              if(bookDraft?.book.isBoxset){
                props.stores.bookStore.boxSetListBoxSet = bookDraft?.book.boxSet;
                navigate("/boxsets/details");
              }else{
                props.stores.bookStore.bookListBook = bookDraft?.book;
                navigate("/books/details");
              }
              

            }}>

            <ListItemIcon>
              <StandardRoundedAction icon={<Home sx={{
                color: theme.palette.standardActions.icon.color.main,

              }} />} />

            </ListItemIcon>

            <ListItemText primary="Book Home" primaryTypographyProps={{
              style: {
                color: theme.palette.standardActions.label.color.main
              }
            }} />
          </ListItemButton>
        </ListItem>
      }



    </List>
  );
}

export default inject('stores')(observer(ToolsNavigator));

