import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import AppWrapper from '../../AppWrapper';
import Library from './Library';
import OwnedBooksListMenuContent from '../../menus/OwnedBooksListMenuContent';
import AppWrapperDummy from '../../AppWrapperDummy';
import StretchBox from '../StretchBox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StandardAppBar from '../../StandardAppBar';
import MainMenuContent2 from '../../menus/MainMenuContent2';
import BoxsetsTable from '../BoxsetsTable';
import ConnectionsTable from '../ConnectionsTable';
import ConnectionsMobileFooterBar from '../../footers/ConnectionsMobileFooterBar';
import ScrollBox from '../ScrollBox';
import ConnectionsAndGroups from '../../table/ConnectionsAndGroups';
import TrashbinMenuContent from '../../menus/TrashbinMenuContent';
import MainMenuContent from '../../menus/MainMenuContent';
import { StandardPaper } from '../../StandardPaper';
import { useManagerAcceptInvitation } from '../../../managers/ConnectionsManager';
const Connections = (props) => {


  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();
  const { acceptInvitation, userConnection, loading, error } = useManagerAcceptInvitation(props);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const search = useLocation().search;
  const invitationCode = new URLSearchParams(search).get('invitationCode');

  useEffect(() => {

    //The following code should only run one time, immediately after the user logs in after clicking the link 
    //in an email invitation.

    //Check to see if this user registered with a pending invitation 
    if (invitationCode) {



      acceptInvitation({
        invitationCode: invitationCode,
        onCompleted: () => {
          //console.log('completed...')
          //setProcessedInvitationCode(invitationCode)
        }
      });


    }

  }, [invitationCode]);

  return (

    // <AppWrapperDummy>
    <StretchBox flexDirection='column'>

      {/* <ConnectionsTable /> */}
      {/* This stretchbox ensures the footer is pushed to the bottom of the page
            before the data is loaded for the table
        */}
      <StretchBox flexDirection='column'>

      
          <ConnectionsAndGroups />
       
      </StretchBox>


      {/* <ConnectionsMobileFooterBar /> */}
    </StretchBox>
    // </AppWrapperDummy>


  )
}
export default Connections;
// export default inject('stores')(observer(Books));