// RequireAuth.js
import { useLocation, Navigate, useSearchParams } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

export function RequireAuth({ children }) {

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();


  const { route } = useAuthenticator((context) => [context.route]);

  console.log(searchParams);
  if (route !== 'authenticated' && route !== 'idle') {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}