import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minWidth: '20em',
    minHeightx: '10em'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  const theme = useTheme();

  return (
    <DialogTitle sx={{ 
      m: 0, 
      p: 2, 
      // backgroundColor:theme.palette.books?.main
      
      }} 
      
      style={{
        borderBottom: 'solid',
        borderColor:theme.palette.secondary.main,
        margin: '0 0.5em 0.5em 0.5em'
      }}
      
      {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size='small'
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function StandardDialog(props) {
  
    let { open } = props;

    const theme = useTheme();


  return (    
      <BootstrapDialog
        onClose={props?.onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={props?.onClose}>
          <Typography variant={'h5'} color={theme.palette.text.writing}> {props.title} </Typography>
        </BootstrapDialogTitle>
        <DialogContent >
         {props.dialogContent}
        </DialogContent>
        <DialogActions>
          {props.actions}
        </DialogActions>
      </BootstrapDialog>
   
  );
}
