import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import StandardDataTable from './StandardDataTable';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import ReactTimeAgo from 'react-time-ago';
import Feather from 'mdi-material-ui/Feather';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import SnapshotsTable from '../../components/table/SnapshotsTable';
import BookEditsTable from '../../components/table/BookEditsTable';
import { useGetOwnedBooks, useOpenBookInWrite, useOpenBookInFormat, useManagerCreateBook } from '../../managers/BooksManager';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import StandardImageViewer from '../StandardImageViewer';
import ImageTitleTableCell from './ImageTitleTableCell';
import config from '../../aws-exports';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ShareIcon from '@mui/icons-material/Share';
import CommentIcon from '@mui/icons-material/Comment';
import DeleteBookDialog from '../panels/DeleteBookDialog';
import { useMediaQuery } from 'react-responsive';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableListPopOver from './TableListPopOver';
import MenuItem from '@mui/material/MenuItem';
import StandardRoundedAction from '../actions/StandardRoundedAction';
import SettingsIcon from '@mui/icons-material/Settings';
import TablePopupMenu from '../menus/TablePopupMenu';
import StandardFloatingPlus from '../StandardFloatingPlus';
import OwnedBooksList2 from '../lists/OwnedBooksList2';
import CreateExportsAssembly from '../assemblies/CreateExportsAssembly';
import StandardSkeleton from '../../StandardSkeleton';
import CreateBookAssembly from '../assemblies/CreateBookAssembly';
import ScrollingGridContainer from '../ScrollingGridContainer';
import ScrollingGridItem from '../ScrollingGridItem';

const OwnedBooksTable = (props) => {

    let { book } = props;

    const { ownedBooks, loading, error } = useGetOwnedBooks(props);
    const { openBookInWrite } = useOpenBookInWrite(props);
    const { openBookInFormat } = useOpenBookInFormat(props);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');

    let [showCreateBookDialog, setShowCreateBookDialog] = useState(false);
    let [showDeleteBookDialog, setShowDeleteBookDialog] = useState(false);
    let [selectedBook, setSelectedBook] = useState(null);


    const isXs = useMediaQuery({ maxWidth: 599 })
    const isSm = useMediaQuery({ maxWidth: 600 })
    const isMd = useMediaQuery({ maxWidth: 900 })
    const isLg = useMediaQuery({ maxWidth: 1200 })
    const isXl = useMediaQuery({ minWidth: 1536 })

    const isRoute = () => {
        return 'primary';
    }


    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        //console.log(ownedBooks);
        setOrder("desc");
        setOrderBy("createdAt");

    }, [ownedBooks]);



    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            height: '100%'
        }}>

            <ScrollingGridContainer container columns={100} direction={'row'}>

                <ScrollingGridItem xs={100} >


                    {!isXs &&
                        <StandardDataTable
                            title={'My Books'}
                            noDataMessage={'Create your first book!'}
                            order={order}
                            onSetOrder={setOrder}
                            orderBy={orderBy}
                            onSetOrderBy={setOrderBy}
                            //headerActions={<CreateExportsAssembly/>}
                            loading={loading}
                            headerActions={<CreateBookAssembly book={book} />}
                            structure={{
                                dataDef: {
                                    data: ownedBooks.filter(f => f.deletedAt == null),
                                    rowKeyDataFieldName: 'id'
                                },
                                columns: [
                                    {
                                        title: 'Title',
                                        sortable: true,
                                        sortFieldNameOverride: 'title',
                                        dataValueFunction: (book) => {
                                            return <ImageTitleTableCell
                                                bucketName={config.user_uploads_bucket_name}
                                                title={book?.title}
                                                s3Src={book?.coverUrl}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    //console.log(book)
                                                    props.stores.bookStore.bookListBook = book;
                                                    navigate("/books/details");
                                                    // openBookInWrite({
                                                    //   bookDraftId: book.primaryBookDraftId
                                                    // });
                                                }}
                                            />

                                        }
                                    },
                                    {
                                        title: 'Write',
                                        dataValueFunction: (book) => {
                                            return <StandardRoundedAction sx={{

                                            }} icon={<Feather sx={{
                                                color: theme.palette.standardActions.icon.color.main,
                                                //color: theme.palette.standardActions.icon.backgroundColor.main

                                            }} onClick={(e) => {
                                                e.preventDefault();
                                                //console.log(book)
                                                //navigate("/write");
                                                openBookInWrite({
                                                    bookDraftId: book.primaryBookDraftId
                                                });
                                            }} />} />

                                        }
                                    },
                                    {
                                        title: 'Format',
                                        dataValueFunction: (book) => {
                                            return <StandardRoundedAction icon={<FormatPaintIcon sx={{
                                                color: theme.palette.standardActions.icon.color.main,

                                            }} onClick={(e) => {
                                                e.preventDefault();
                                                //navigate("/format");
                                                openBookInFormat({
                                                    bookDraftId: book.primaryBookDraftId
                                                });
                                            }} />} />


                                        },
                                        ignore: isXs
                                    },
                                    {
                                        title: 'Snapshots',
                                        dataValueFunction: (book) => {
                                            return <IconButton aria-label="delete">
                                                <CopyAllIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                                                    e.preventDefault();
                                                    //console.log(book)
                                                    props.stores.bookStore.bookListBook = book;
                                                    navigate("/books/details/snapshots");

                                                }} />
                                            </IconButton>
                                        },
                                        ignore: isXs
                                    },
                                    {
                                        title: 'Edit',
                                        dataValueFunction: (book) => {
                                            return <IconButton aria-label="delete">
                                                <AccountEdit sx={{ color: theme.palette.background.main }} onClick={(e) => {
                                                    e.preventDefault();
                                                    //console.log(book)
                                                    props.stores.bookStore.bookListBook = book;
                                                    navigate("/books/details/editing");
                                                    // openBookInWrite({
                                                    //   bookDraftId: book.primaryBookDraftId
                                                    // });
                                                }} />
                                            </IconButton>
                                        },
                                        ignore: isXs
                                    },
                                    {
                                        title: 'Share',
                                        dataValueFunction: (book) => {
                                            return <IconButton aria-label="delete">
                                                <ShareIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                                                    e.preventDefault();
                                                    //console.log(book)
                                                    props.stores.bookStore.bookListBook = book;
                                                    navigate("/books/details/sharing");
                                                    // openBookInWrite({
                                                    //   bookDraftId: book.primaryBookDraftId
                                                    // });
                                                }} />
                                            </IconButton>
                                        },
                                        ignore: isXs
                                    },
                                    {
                                        title: 'Feedback',
                                        dataValueFunction: (book) => {
                                            return <IconButton aria-label="delete">
                                                <CommentIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                                                    e.preventDefault();
                                                    //console.log(book)
                                                    props.stores.bookStore.bookListBook = book;
                                                    navigate("/books/details/feedback");
                                                    // openBookInWrite({
                                                    //   bookDraftId: book.primaryBookDraftId
                                                    // });
                                                }} />
                                            </IconButton>
                                        },
                                        ignore: isXs
                                    },
                                    {
                                        title: 'Boxsets',
                                        dataValueFunction: (book) => {
                                            return <IconButton aria-label="delete">
                                                <ContentCopyIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                                                    e.preventDefault();
                                                    //console.log(book)
                                                    props.stores.bookStore.bookListBook = book;
                                                    navigate("/books/details/boxsets");
                                                    // openBookInWrite({
                                                    //   bookDraftId: book.primaryBookDraftId
                                                    // });
                                                }} />
                                            </IconButton>
                                        },
                                        ignore: isXs
                                    },
                                    {
                                        title: 'Export',
                                        dataValueFunction: (book) => {
                                            return <IconButton aria-label="delete">
                                                <FileDownloadIcon sx={{
                                                    color: theme.palette.background.main
                                                }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        //console.log(book)
                                                        props.stores.bookStore.bookListBook = book;
                                                        navigate("/books/details/exports");
                                                        // openBookInWrite({
                                                        //   bookDraftId: book.primaryBookDraftId
                                                        // });
                                                    }} />
                                            </IconButton>

                                            // <StandardRoundedAction icon={<FileDownloadIcon sx={{
                                            //     color: theme.palette.standardActions.icon.color.main,
                                            //     //color: theme.palette.standardActions.icon.backgroundColor.main

                                            // }} onClick={(e) => {
                                            //     e.preventDefault();
                                            //     console.log(book)
                                            //     props.stores.bookStore.bookListBook = book;
                                            //     navigate("/books/details/exports");
                                            // }} />} />

                                        },
                                        ignore: isXs
                                    },
                                    // {
                                    //     title: 'Cover',
                                    //     dataValueFunction: (book) => {
                                    //         return <StandardImageViewer
                                    //             bucketName={'local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm'}
                                    //             //s3Path={'uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/b6f59120-18fe-11ed-b9d5-076ac854f9a5_planetStrider.png'}
                                    //             s3Path={book?.coverUrl}
                                    //             height={'5em'}
                                    //             fit={'contain'}
                                    //             sx={{
                                    //                 minHeight: '5em'
                                    //                 //display: { sm: 'block', lg: 'block', md: 'block' } 
                                    //             }}
                                    //             style={{ borderRadius: 2 }}
                                    //             className="custom-class"
                                    //             onClick={(e) => {
                                    //                 // e.preventDefault();
                                    //                 // setShowGallery(true);

                                    //             }}
                                    //             showLoading
                                    //             errorIcon

                                    //             /> }
                                    // },
                                    // {
                                    //     title: 'Title',
                                    //     dataFieldName: 'title',
                                    //     sortable: true,
                                    //     dataValueFunction: (book) => { return book.title }
                                    // },

                                    // {
                                    //     title: 'Snapshots',
                                    //     expandable: true,
                                    //     exoandedRowComponent: (book) => { return <SnapshotsTable book={book} /> }
                                    // },
                                    // {
                                    //     title: 'Editing',
                                    //     expandable: true,
                                    //     exoandedRowComponent: (book) => { return <BookEditsTable book={book} /> }
                                    // }, 

                                    // {
                                    //     icon: <EditIcon sx={{ color: theme.palette.words?.main }} />,
                                    //     expandable: true,
                                    //     exoandedRowComponent: (book) => { return <div>its a book 2: {book.title}</div> }
                                    // },
                                    {
                                        title: 'Created',
                                        dataFieldName: 'createdAt',
                                        sortable: true,
                                        dataValueFunction: (book) => { return book.createdAt != null ? <ReactTimeAgo date={book.createdAt} locale="en-US" /> : '' },
                                        ignore: isXs

                                    },
                                    // {
                                    //     title: 'Delete',
                                    //     dataValueFunction: (book) => {
                                    //         return <IconButton aria-label="delete">
                                    //             <TrashCanOutline sx={{ color: theme.palette.background.main }} onClick={(e) => {
                                    //                 e.preventDefault();
                                    //                 console.log(book);
                                    //                 setSelectedBook(book);
                                    //                 setShowDeleteBookDialog(true);

                                    //             }} />
                                    //         </IconButton>
                                    //     }
                                    // },


                                    {
                                        title: '',
                                        dataValueFunction: (book) => {
                                            return <TablePopupMenu
                                                menuItems={[
                                                    // <ListItemButton onClick={(e) => {
                                                    //     e.preventDefault();
                                                    //     console.log(book)
                                                    //     props.stores.bookStore.bookListBook = book;
                                                    //     navigate("/books/details");
                                                    //     // openBookInWrite({
                                                    //     //   bookDraftId: book.primaryBookDraftId
                                                    //     // });
                                                    // }} >

                                                    //     <ListItemIcon>
                                                    //         <SettingsIcon sx={{ color: theme.palette.background.main }} />
                                                    //     </ListItemIcon>
                                                    //     <ListItemText primary="Details" />
                                                    // </ListItemButton>,
                                                    <ListItemButton onClick={(e) => {
                                                        e.preventDefault();
                                                        //console.log(book)
                                                        props.stores.bookStore.bookListBook = book;
                                                        navigate("/books/details/snapshots");
                                                        // openBookInWrite({
                                                        //   bookDraftId: book.primaryBookDraftId
                                                        // });
                                                    }} >

                                                        <ListItemIcon>
                                                            <CopyAllIcon sx={{ color: theme.palette.background.main }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Snapshots" />
                                                    </ListItemButton>,
                                                    <ListItemButton onClick={(e) => {
                                                        e.preventDefault();
                                                        //console.log(book)
                                                        props.stores.bookStore.bookListBook = book;
                                                        navigate("/books/details/editing");
                                                        // openBookInWrite({
                                                        //   bookDraftId: book.primaryBookDraftId
                                                        // });
                                                    }}   >

                                                        <ListItemIcon>
                                                            <AccountEdit sx={{ color: theme.palette.background.main }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Edit" />
                                                    </ListItemButton>,
                                                    <ListItemButton onClick={(e) => {
                                                        e.preventDefault();
                                                        //console.log(book)
                                                        props.stores.bookStore.bookListBook = book;
                                                        navigate("/books/details/sharing");
                                                        // openBookInWrite({
                                                        //   bookDraftId: book.primaryBookDraftId
                                                        // });
                                                    }}   >

                                                        <ListItemIcon>
                                                            <ShareIcon sx={{ color: theme.palette.background.main }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Share" />
                                                    </ListItemButton>,
                                                    <ListItemButton onClick={(e) => {
                                                        e.preventDefault();
                                                        //console.log(book)
                                                        props.stores.bookStore.bookListBook = book;
                                                        navigate("/books/details/feedback");
                                                        // openBookInWrite({
                                                        //   bookDraftId: book.primaryBookDraftId
                                                        // });
                                                    }}   >

                                                        <ListItemIcon>
                                                            <CommentIcon sx={{ color: theme.palette.background.main }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Feedback" />
                                                    </ListItemButton>,
                                                    <ListItemButton onClick={(e) => {
                                                        e.preventDefault();
                                                        //console.log(book)
                                                        // props.stores.bookStore.bookListBook = book;
                                                        navigate("/books/details/boxsets");
                                                        // openBookInWrite({
                                                        //   bookDraftId: book.primaryBookDraftId
                                                        // });
                                                    }}  >

                                                        <ListItemIcon>
                                                            <ContentCopyIcon sx={{ color: theme.palette.background.main }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Boxsets" />
                                                    </ListItemButton>,
                                                    <ListItemButton onClick={(e) => {
                                                        e.preventDefault();
                                                        //console.log(book)
                                                        props.stores.bookStore.bookListBook = book;
                                                        navigate("/books/details/exports");
                                                        // openBookInWrite({
                                                        //   bookDraftId: book.primaryBookDraftId
                                                        // });
                                                    }} >

                                                        <ListItemIcon>
                                                            <FileDownloadIcon sx={{ color: theme.palette.background.main }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Export" />
                                                    </ListItemButton>,
                                                ]}
                                            />
                                        },
                                        ignore: !isXs
                                    },




                                ]
                            }} />
                    }
                    {isXs &&
                        <OwnedBooksList2
                            filteredBooks={[]}
                            showSelectIcon
                            onSelected={(book) => {

                                if (book) {

                                    //console.log(book)
                                    props.stores.bookStore.bookListBook = book;
                                    navigate("/books/details");

                                }

                            }}
                        />
                    }

                    {/* <StandardFloatingPlus onClick={(e) => {
                e.preventDefault();
                //setShowCreateBookDialog(true);
            }} /> */}

                    < DeleteBookDialog
                        open={showDeleteBookDialog}
                        book={selectedBook}
                        onClose={() => {
                            setShowDeleteBookDialog(false)
                            setSelectedBook(null);
                        }}
                    />
                    {(loading && (!ownedBooks || ownedBooks.length == 0)) &&
                        <StandardSkeleton />
                    }

                </ScrollingGridItem>
            </ScrollingGridContainer>
        </Box >
    )
}
export default inject('stores')(observer(OwnedBooksTable));