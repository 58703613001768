import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import StandardTabs from "../StandardTabs";
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton, { StandardListButton } from "../StandardButton";
import UserConnectionsList from "../lists/UserConnectionsList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../managers/BooksManager';
import UserGroupsList from "../lists/UserGroupsList";
import { useManagerInsertBoxsetBook, useManagerAddBoxsetContributor, useManagerAddBoxsetContributedBook, useManagerLoadBoxSetContributors } from '../../managers/BoxsetsManager';
import { User } from "../../domain/bookModel";
import OwnedBooksList from "../lists/OwnedBooksList";
import OwnedBoxsetDetailMenuContent from "../menus/OwnedBoxsetDetailMenuContent";
import InsertBoxsetBookMenuContent from "../menus/InsertBoxsetBookMenuContent";
import BoxsetContributors from "../../routes/subRoutes/ownedBoxsets/BoxsetContributors";
import BoxsetBooks from "../../routes/subRoutes/ownedBoxsets/BoxsetBooks";
import ContributedBooksList from "../lists/ContributedBooksList";


const InsertBoxsetBookPanel = (props) => {

  let user = props.stores.userStore.user;
  let { book, parentBddp } = props;

  let boxset = book?.boxSet;

  let { insertBoxsetBook, loading: insertingBookLoading, error: insertingBookError } = useManagerInsertBoxsetBook(props);

  return (
    <>
      {!insertingBookLoading &&
        <Grid container direction={'row'}>
          {/* <Grid item sx={{
            width: '40%'
          }}>

            <InsertBoxsetBookMenuContent boxset={boxset} />
          </Grid> */}
          <Grid item sx={{
            //width: '60%'
          }}>
            {/* <BoxsetContributors boxset={boxset} /> */}
            {/* <BoxsetBooks
              boxset={boxset}
              onInsertBook={(toInsertBook) => {


                try {

                  insertBoxsetBook({
                    userId: user.id,
                    book: toInsertBook,
                    boxset: boxset,
                    parentBddp
                  }, () => {
                    //alert('called back');
                  });

                } catch (err) {
                  console.log(err);
                }
              }} /> */}
            

            <ContributedBooksList
              book={book}
              boxset={boxset}
              showSelectIcon
              onSelected={(selectedBook) => {

                try {

                  insertBoxsetBook({
                    userId: user.id,
                    book: selectedBook,
                    boxset: boxset,
                    parentBddp
                  }, () => {
                    //alert('called back');
                  });

                } catch (err) {
                  console.log(err);
                }

              }}
            />



          </Grid>
        </Grid>
      }
      {insertingBookLoading &&
        <div>inserting book</div>
      }
    </>
  )
}

export default inject('stores')(observer(InsertBoxsetBookPanel));