import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import OwnedBoxsetContributors from '../panels/OwnedBoxsetContributors';
import OwnedBoxsetBooks from '../panels/OwnedBoxsetBooks';
import useMediaQuery from '@mui/material/useMediaQuery';
import BoxsetsTable from '../boilerPlate/BoxsetsTable';
import StretchBox from '../boilerPlate/StretchBox';
import { Box, Button, SwipeableDrawer } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import ScrollBox from '../boilerPlate/ScrollBox';
import OwnedBoxsetsList from '../lists/OwnedBoxsetsList';
import { useGetOwnedBoxsets } from '../../managers/BoxsetsManager';
import { useGetOwnedBooks } from '../../managers/BooksManager';
import StandardFloatingPlus from '../StandardFloatingPlus';
import CreateBoxsetDialog from '../panels/CreateBoxsetDialog';
import ExportsPanel from '../export/ExportsPanel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import Feather from "mdi-material-ui/Feather";
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import LaurasListItem from './LaurasListItem';
import LaurasCard from './LaurasCard';
import OwnedBooksList from '../lists/OwnedBooksList';
import LaurasBoxsetContributors from './LaurasBoxsetContributors';

const LaurasTabOneContent = (props) => {


    const [selectedBoxset, setSelectedBoxset] = useState(false);
    const [showDetailDrawer, setShowDetailDrawer] = useState(false);
    const [showCreateBoxsetDialog, setShowCreateBoxsetDialog] = useState(false);

    const { ownedBoxsets, loading: loadingOwnedBoxsets } = useGetOwnedBoxsets(props);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    let user = props.stores.userStore.user;

    let { filteredBooks, filteredBookIds } = props;

    const { ownedBooks, error, loading } = useGetOwnedBooks(props);
    const [availableBooks, setAvailableBooks] = useState([])

    let remainingBooks = ownedBooks;

    useEffect(() => {


        if (ownedBoxsets) {
            //setSelectedBoxset(ownedBoxsets[0])
        }

    }, [ownedBoxsets]);


    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setShowDetailDrawer(open);
            };

    return (

        <StretchBox flexDirection='row' sxProps={{ gap: isSmallScreen ? 0 : 2 }}>

            <StretchBox sxProps={{
                flex: isSmallScreen ? 12 : 4,
                position: 'relative'
            }}>
                {/* <BoxsetsTable onSelectBoxSet={
                    (boxset) => {
                        setSelectedBoxset(boxset)
                        setShowDetailDrawer(true)
                    }} /> */}

                <ScrollBox>
                    <OwnedBoxsetsList
                        selectedBoxset={!isSmallScreen ? selectedBoxset : null}
                        boxsets={ownedBoxsets}
                        loading={loadingOwnedBoxsets}
                        filteredBooks={[]}
                        showSelectIcon
                        onSelected={(boxset) => {

                            if (boxset) {

                                //console.log(boxset)
                                setSelectedBoxset(boxset)
                                setShowDetailDrawer(true)

                            }

                        }}
                    />
                </ScrollBox>
                <StandardFloatingPlus onClick={(e) => {
                    e.preventDefault();
                    setShowCreateBoxsetDialog(true);
                }} />

            </StretchBox>

            <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>

                    {selectedBoxset &&
                        <LaurasCard
                            // heading={'Contributors'}
                            children={<LaurasBoxsetContributors boxset={selectedBoxset} />}
                        />
                    }


                </ScrollBox>
            </StretchBox>

            <CreateBoxsetDialog
                open={showCreateBoxsetDialog}
                onClose={(boxset) => {
                    setShowCreateBoxsetDialog(false);

                }}

                onBookCreated={(boxset) => {
                    setShowCreateBoxsetDialog(false);
                    setSelectedBoxset(boxset);


                }}


            />

            <SwipeableDrawer
                anchor={'right'}
                open={showDetailDrawer && isSmallScreen}
                onClose={toggleDrawer(false)}
                sx={{ width: '100%' }}
            //variant='persistent'
            //onOpen={setShowDetailDrawer(true)}
            >
                <Button variant='contained' onClick={(e) => {
                    e.preventDefault();
                    setShowDetailDrawer(false);
                }}>Close</Button>
                <div style={{ width: '100vw' }}>

                    <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                        <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>
                            {selectedBoxset &&
                                <div> {selectedBoxset.book.title} </div>
                            }

                            {selectedBoxset &&
                                <LaurasCard
                                    children={<OwnedBoxsetContributors boxset={selectedBoxset} />}
                                />
                            }


                        </ScrollBox>
                    </StretchBox>
                </div>
            </SwipeableDrawer>


        </StretchBox>
    )
}
export default inject('stores')(observer(LaurasTabOneContent));