import { Grid, IconButton, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import React, { useState } from "react";
import StandardDialog from "../StandardDialog";
import StandardTextfield from "../StandardTextfield";
import StandardButton from "../StandardButton";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useManagerCreateBook } from '../../managers/BooksManager';

const CreateSnapshotDialog = (props) => {

  let [title, setTitle] = useState(null);
  let [description, setDescription] = useState(null);

  let {
    createBook,
    createBookLoading,
    createBookError
  } = useManagerCreateBook();


  const handleTitleChange = (e) => {
    e.preventDefault();

    setTitle(e.target.value);
  }

  const handleDescriptionChange = (e) => {
    e.preventDefault();

    setDescription(e.target.value);
  }


  const handleCreateBook = () => {

    createBook({
      title,
      stores: props.stores
    }, () => {
      props.onClose();
    })

  }

  return (
    <StandardDialog
      open={props.open}
      onClose={props.onClose}
      dialogContent={
        <Grid container direction={'column'}>
          <Grid item>
            <StandardTextfield label={'Title'} onChange={handleTitleChange} />
          </Grid>

          <Grid item>
            <StandardTextfield label={'Description'} onChange={handleDescriptionChange} />
          </Grid>

          
        </Grid>



      }
      title={'Create snapshot'}
      actions={[
        <StandardButton autoFocus onClick={props?.onClose}>
          Cancel
        </StandardButton>,
        <StandardButton autoFocus onClick={(e) => {
          e.preventDefault();
          //handleCreateBook();
        }}>
          Ok
        </StandardButton>
      ]}>

    </StandardDialog>
  )
}

export default inject('stores')(observer(CreateSnapshotDialog));