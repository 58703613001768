import React, { useState, useEffect } from "react";
import { inject, observer } from 'mobx-react';
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import config from '../../../aws-exports';
import StandardUIDialog from "../../UI/StandardUIDialog";
import ListOwnedBooks from "../lists/ListOwnedBooks";
import { useManagerShareBookWithUserGroup } from "../../../managers/ConnectionsManager";
import CircularProgress from '@mui/material/CircularProgress';


const GroupShareBook = (props) => {

  let user = props.stores.userStore.user;
  let { userConnection, userGroup } = props;

  let { shareBookWithUserGroup, loading: shareBookWithUserGroupLoading, error: shareBookWithUserGroupError } = useManagerShareBookWithUserGroup(props);



  return (
    <StandardUIDialog
      open={props.open}
      onClose={props.onClose}
      title='Share Book'
      dialogContent={
        <Grid>
          <ListOwnedBooks
            showWriteIcon={false}
            showFormatIcon={false}
            showAddIcon={true}
            filteredBooks={[]}
            showSelectIcon
            onSelectBook={(book) => {

              if (book) {
                shareBookWithUserGroup({
                  bookId: book.id,
                  userGroupId: userGroup.id,
                }, () => {

                  //onSuccess
                  if (props.onGroupShareCreated) {
                    props.onGroupShareCreated();
                  }

                }, () => {

                  //onError

                })
              }

            }}
          />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {shareBookWithUserGroupLoading && <CircularProgress />}
          </div>
        </Grid>

      }
    // cardActions={

    // }
    />
  )
}

export default inject('stores')(observer(GroupShareBook));