import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Box } from '@mui/material';
import Quill from 'quill';
import ToolbarItem from './toolbar/ToolbarItem';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import ChatIcon from '@mui/icons-material/Chat';
import EditingContext from './EditingContext';
import UserCommentInput from './UserCommentInput';
import { v4 as uuid } from 'uuid';
import UserComment from './UserComment';
import { useEffect } from 'react';

var $ = require("jquery");

function EditorToolTip(props) {

  let {
    quill,
    visible,
    editorId,
    documentPart,
    selectedText,
    selection,
    widthElementClassName,
    readOnly
  } = props;


  const editingContext = React.useContext(EditingContext);
  let [showAddComment, setShowAddComment] = React.useState(false);
  let [userComment, setUserComment] = React.useState(null);

  let id = 'editor-tooltip' + uuid();
  let buttonBarId = 'editor-tooltip-buttonBar' + uuid();


  useEffect(()=>{

    return ()=>{
      //quill.formatText(selection.index,selection.length,'currentSelectionBlot',false,Quill.sources.SILENT);
    }
  },[]);

  React.useEffect(() => {

    try {

      //let userComment = props.stores.bookStore.userComments.filter(f=>f.id==editingContext.userCommentId)[0];
      setUserComment(props.stores.bookStore.userComments.filter(f => f.id == editingContext.userCommentId)[0]);
      //console.log(userComment);
    } catch (err) {
      console.log(err);
    }

  },[editingContext.userCommentId]);

  React.useEffect(() => {

    try {


      //let toolTipId = 'tooltip-controls' + editorId;
      //quill.format('currentSelectionBlot',true,Quill.sources.SILENT);
      let rangeBounds = quill.getBounds(selection);

      let bookDraftEditorTopScrollDivTop = $('.bookDraftEditorTopScrollDiv').scrollTop();
      //let documentPartEditorOffset = $('#' + editorId).position();
      let documentPartEditorOffset = $('#' + editingContext.selectedEditorId).position();


      //let selection = window.getSelection();
      let toolTipHeight = $('#' + id).height();
      // let buttonBarHeight = 0;
      
      // if(!readOnly){
      //   //buttonBarHeight = $('#' + buttonBarId).height();
      // }
      let toolTipWidth = $('#' + id).width();
      let editorWidth = $('.' + widthElementClassName).width();

      let leftPosition = rangeBounds.left + rangeBounds.width / 2 - $('#' + id).outerWidth() / 2;
      if (leftPosition < 0) leftPosition = 0;
      if ((leftPosition + toolTipWidth) > editorWidth) {
        $('#' + id).show().css({
          left: '',
          right: 0,
          top: rangeBounds.top + bookDraftEditorTopScrollDivTop + documentPartEditorOffset.top - toolTipHeight
          //top: rangeBounds.top + bookDraftEditorTopScrollDivTop - toolTipHeight - 20
        });
      } else {
        $('#' + id).show().css({
          right: '',
          left: leftPosition,
          top: rangeBounds.top + bookDraftEditorTopScrollDivTop + documentPartEditorOffset.top - toolTipHeight
          //top: rangeBounds.top + bookDraftEditorTopScrollDivTop  - toolTipHeight - 20
        });

      }


      // if(visible){
      //   $('#' + id).show();
      // }else{
      //   $('#' + id).hide();
      // }

    } catch (err) {
      console.log(err);
    }

    return () => {
      //console.log('closing');
      if (selection) {

        //quill.setSelection(selection.index, selection.length, Quill.sources.SILENT);
        //quill.formatText(selection.index, selection.length, 'currentSelectionBlot',false,Quill.sources.SILENT);

      }
    }

  });

  React.useEffect(() => {
    return () => {
      //console.log('unmount');
      //quill.formatText(selection.index, selection.length, 'currentSelectionBlot', false, Quill.sources.SILENT);
    };
  }, []);

  let disabled = false;
  const fontSize = 'small';
  const boldIcon = <div className='tooltip-button'><ToolbarItem icon={<FormatBoldIcon fontSize={fontSize} color={editingContext.formatting?.bold ? 'secondary' : 'disabled'} onClick={() => { if (disabled) return; /*toggleFormat('bold')*/ }} />} /></div>;
  const underlineIcon = <div className='tooltip-button'><ToolbarItem icon={<FormatUnderlinedIcon fontSize={fontSize} color={editingContext.formatting?.underline ? 'secondary' : 'disabled'} onClick={() => { if (disabled) return;  /*toggleFormat('underline')*/ }} />} /></div>
  const italicIcon = <div className='tooltip-button'><ToolbarItem icon={<FormatItalicIcon fontSize={fontSize} color={editingContext.formatting?.italic ? 'secondary' : 'disabled'} onClick={() => { if (disabled) return;  /*toggleFormat('italic')*/ }} />} /></div>
  const chatIcon = <div className='tooltip-button'><ToolbarItem icon={<ChatIcon fontSize={fontSize} color={'disabled'} onClick={() => {
    setShowAddComment(true);


    // quill.setSelection(selection.index, selection.length, Quill.sources.SILENT);
    // quill.format('currentSelectionBlot',true,Quill.sources.SILENT);
    quill.formatText(selection.index, selection.length, 'currentSelectionBlot', true, Quill.sources.SILENT);
    //quill.setSelection(43, 6 ,Quill.sources.SILENT);
    //quill.setSelection(1,35 ,Quill.sources.SILENT);


    // setCurrentSelection(quillInstance.getSelection());

    // createSharedBookUserComment({
    //     documentPartId: documentPart.id,
    //     sharedBookId: book.id,
    //     sharedBookDraftId: bookDraft.id,
    //     commentorUserId: userId,
    //     comment: selectedText,
    //     partIndex: selection.index,
    //     partLength: selection.length,
    //     itemOwnerId: book.owner.id
    // }, () => {
    //     //props.stores.bookStore.userCommentsTimestamp = Date.now();
    // });




  }} />} /></div>;

  const resetFocus = () => {
    //console.log(selection);
    try {
      let index = selection.index;
      let length = selection.length;
      //console.log(index);
      //console.log(length);
      //quill.setSelection(index, length, Quill.sources.SILENT);
      //quill.setSelection(43, 6 ,Quill.sources.SILENT);
      //quill.setSelection(1,35 ,Quill.sources.SILENT);
    } catch (err) {
      console.log(err);
    }
  }
  return (





    <Box
      id={id}
      className={'editor-tooltip'}
      style={{
        position: 'absolute',
        maxWidth:' 30em'
      }}
      onFocus={() => {
        //console.log('focus');
        //console.log(selection);
        //resetFocus();
      }}
      onBlur={() => {
        //console.log('blur');
        //resetFocus();
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        'flex-wrap': 'wrap',
        'alignItems': 'center',
        //width: '100%',
        //border: '1px solid gray',
        //padding: '0em 0em .5em 0em',
        zIndex: 9999,
        //border: '1px solid white',
        //backgroundColor: 'black',
        minWidth: '20em'

      }}>

      {/* <div>{editingContext.userCommentId}</div>
      <div>{editingContext.selectedEditorId}</div>
      <div>visible:{visible ? 'true' : 'false'}</div> */}
      {visible &&
        <Box sx={{
          width:'100%'
        }}>
          {/* {showAddComment &&
            <UserCommentInput
              bookDraft={documentPart?.bookDraftDocumentPart.bookDraft}
              documentPart={documentPart}
              hideAddButton={false}
              selection={selection}
              quill={quill}
            />
  
          } */}    

          {/* {userComment && */}
            <UserComment
              ownerMode={false}
              showBookInfo={false}
              userComment={userComment}
              bookDraft={documentPart?.bookDraftDocumentPart.bookDraft}
              documentPart={documentPart}
              hideAddButton={false}
              selection={selection}
              quill={quill}
              onNewComment={(userCommentPojo) => {

                setUserComment(userCommentPojo);

               }}
              onUpdated={() => {

                //setUserComment = 
               }}
            />
          {/* } */}

        </Box>
      }
       {/* <div> {readOnly?'readOnly':'notReadOnly'}</div> */}
      {(visible && !readOnly) &&
        <Box 
          id={buttonBarId} 
          sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          'alignItems': 'center',
          width: '100%',
          backgroundColor: 'black',
          border: '1px solid white',
          height: '3em'
        }}>
          {boldIcon}
          {underlineIcon}
          {italicIcon}
          {chatIcon}
        </Box>
      }
    </Box>


  );
}
export default inject('stores')(observer(EditorToolTip));