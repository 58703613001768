import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import InformationOutline from 'mdi-material-ui/InformationOutline';
import logo from '../assets/images/logo001_grey.png';
//const logo = require('../assets/images/logo001_grey.svg');

const EmptyContent = (props) => {

    let { showLearnMoreButton=false, message, loading } = props;

    if(loading){
        return(
            <div>loading...</div>
        )
    }
    return (
        <div style={{
            'display':'flex',
            'flexDirection':'column', 
            'widthx':'100%' ,
            'height':'100%', 
            'alignItems':'center',
            'justifyContent':'space-between',
            //border:'1px solid red'
            }}>
            
            <div>
            {/*Header if needed */}
            </div>

            <div style={{
                display:'flex',
                flexDirection:'column', 
                alignItems:'center',
                justifyContent:'center'

            }}>

                <img 
                      className='popupLogo'
                      style={{
                        width:'10em'
                      }}
                      src={logo}/>

                {!props.white && 
                    <div className='empty-content-text'>
                        {props.tagline}

                    </div>
                }
                {props.white && 
                    <div className='empty-content-text-white'>
                        {props.tagline}

                    </div>
                }
                <a 	className={'helpLink'}>
                    <div style={{
                        display:'flex',
                        flexDirection:'row', 
                        alignItems:'center',
                        justifyContent:'center',
                        marginTop:'.1em'

                    }}
                    onClick={(e)=>{
                        e.preventDefault();
                        ////console.log(props.helpTextContentId);
                        props.stores.bookStore.showHelp = true;
                        if(props.helpTextContentId && props.helpTextContentId instanceof String){
                            props.stores.bookStore.helpTextContentId = props.helpTextContentId;
                        }
                        
                        ////console.log(props.stores.bookStore.showHelp);
                    }}>
                        
                        {showLearnMoreButton && 
                                <div style={{
                                    marginRight:'.2em'
                                }}>Learn more</div>}

                                    {showLearnMoreButton && 
                                        <InformationOutline/>
                                    }
                                </div>

                    </a>

            </div>
            <div>
                {/*Footer if needed */}
            </div>
        </div>
    );
};


export default inject('stores')(observer(EmptyContent));