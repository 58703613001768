import Quill from 'quill';
import Delta from 'quill-delta';
import { v4 as uuid }  from 'uuid';
import EntityManager from '../../domain/EntityManager.js';

export default class DeltaManager {


	constructor() {


	}

	findBlot = ({
		delta,
		uuid,
		documentPart
	}) => {

		let beforeDelta = new Delta();
		let foundDelta = new Delta();
		let foundUuid = null;


		// let finalDeltaOps = new Delta("{\"ops\":[]}");

		// try {

		// 	documentPart.deltaOps.forEach((delta) => {

		// 		let parsedDelta = delta;//JSON.parse(delta);
		// 		if (!finalDeltaOps) {

		// 			finalDeltaOps = new Delta(parsedDelta);

		// 		} else {

		// 			finalDeltaOps = finalDeltaOps.compose(new Delta(parsedDelta));

		// 		}
		// 	});


		// 	//console.log(finalDeltaOps);

		// } catch (err) {
		// 	//console.log(err);
		// }


		let finalDeltaOps = documentPart.composedDeltaOps;

		finalDeltaOps.forEach((op) => {

			let thisOpHasUuid = false;

			if (op.hasOwnProperty("attributes")) {

				Object.keys(op.attributes).forEach((key) => {

					if (key == 'uuid') {

						if (op.attributes[key] == uuid) {
							foundUuid = op.attributes[key];
							foundDelta.ops.push(op);
						}


					} else {

						try {

							Object.keys(op.attributes[key]).forEach((subKey) => {

								if (subKey == 'uuid') {

									if (op.attributes[key][subKey] == uuid) {
										foundUuid = op.attributes[key][subKey];
										thisOpHasUuid = true;
										foundDelta.ops.push(op);
									}



								}

							})

						} catch (err) {
							//console.log(err);
						}





					}

				});


			}

			if (op.hasOwnProperty("insert") &&
				(op.insert.hasOwnProperty("editingInsertNewLineBlot2") || op.insert.hasOwnProperty("editingDeleteNewLineBlot"))) {

				let blotData = op.insert.editingInsertNewLineBlot2;
				if (op.insert.editingDeleteNewLineBlot) {
					blotData = op.insert.editingDeleteNewLineBlot;
				}
				Object.keys(blotData).forEach((key) => {

					if (key == 'uuid') {

						if (blotData[key] == uuid) {
							foundUuid = blotData[key];
							foundDelta.ops.push(op);
						}


					} else {

						try {

							Object.keys(blotData[key]).forEach((subKey) => {

								if (subKey == 'uuid') {

									if (blotData[key][subKey] == uuid) {
										foundUuid = blotData[key][subKey];
										thisOpHasUuid = true;
										foundDelta.ops.push(op);
									}



								}

							})

						} catch (err) {
							//console.log(err);
						}





					}

				});

			}

			if (!thisOpHasUuid && !foundUuid) {
				beforeDelta.ops.push(op);
			}

			//console.log(op);

		});

		//console.log(beforeDelta.length())

		return {
			beforeDelta,
			foundDelta
		}

	}





	findBookDraftBlot = ({
		uuid,
		bookDraft
	}) => {

		let results = null;

		try {

			bookDraft.bookDraftDocumentParts.forEach((bddp) => {

				//if(results==null){
				let {
					beforeDelta,
					foundDelta,
				} = new DeltaManager().findBlot({
					//delta:finalDeltaOps,
					documentPart: bddp.documentPart,
					uuid: uuid
				});

				//console.log(foundDelta);
				if (foundDelta.ops.length > 0) {
					results = {
						beforeDelta,
						foundDelta,
						documentPart: bddp.documentPart
					}
				}
				//}


			})

		} catch (err) {
			console.log(err);
		}

		if (results == null) {

			return {}

		} else {
			return results;
		}


	}

	copySource = ({
		range,
		quill,
		bookStore,
		documentPart
	}) => {

		//let range = quill.getSelection(false, 'silent');
		let selectedContent = quill.getContents(range);
		//bookStore.inTransitMovedEditIsDestination = false;


		bookStore.inTransitMovedEdit = selectedContent;
		bookStore.inTransitSourceRange = range;
		bookStore.inTransitSourceQuill = quill;
		bookStore.inTransitSourceDocumentPart = documentPart;



	}

	prepareForMove = ({
		range,
		quill,
		bookStore,
		documentPart
	}) => {

		let { inTransitMovedEdit, inTransitSourceRange } = bookStore;

		if (!inTransitMovedEdit) {
			alert('Nothing copied');
			return;
		}




		// let {
		// 	hasEditingMovedTombstoneBlot,
		// 	hasEditingMovedDestinationBlot,
		// 	existingTombstoneUuid,
		// 	existingDestinationUuid,
		// 	existingTombstoneFormat,
		// 	existingDestinationFormat
		// } = this.getMoveFormatInfo({
		// 	quill
		// });

		// if (hasEditingMovedTombstoneBlot) {
		// 	props.stores.bookStore.showSnackMessage('Cannot move here');
		// 	return;
		// }
		// if (hasEditingMovedDestinationBlot) {
		// 	props.stores.bookStore.showSnackMessage('Cannot move here');
		// 	return;
		// }

		if (bookStore.inTransitMovedEditIsDestination) {

			// this.doMoveDestinationBlot({
			// 	quill,
			// 	documentPart,
			// 	existingTombstoneFormat,
			// 	existingDestinationFormat
			// });
		} else {
			this.doMove({
				quill
			});
		}

		//}



	}


	getFormatBehind({
		format,
		index,
		quill,
		existingDestinationUuid
	}) {

		let count = 0;
		let complete = false;

		while (!complete && (index - count) > 0) {

			quill.setSelection(index - count, 1, 'silent');

			let formats = quill.getFormat();
			let hasFormat = false;
			Object.keys(formats).forEach((key) => {

				let scannedFormat = formats[key];
				if (key == format && existingDestinationUuid == scannedFormat.uuid) {

					count++;
					hasFormat = true;

				}

			})

			if (Object.keys(formats).length == 0 || !hasFormat) {
				complete = true;
			}

		}

		return count;



	}

	getFormatAhead({
		format,
		index,
		quill,
		existingDestinationUuid
	}) {

		let count = 0;
		let complete = false;

		while (!complete && (index + count < quill.getLength())) {

			quill.setSelection(index + count, 1, 'silent');

			let formats = quill.getFormat();
			let hasFormat = false;
			Object.keys(formats).forEach((key) => {

				let scannedFormat = formats[key];
				if (key == format && existingDestinationUuid == scannedFormat.uuid) {

					count++;
					hasFormat = true;

				}

			})

			try{
				
				if (Object.keys(formats).length == 0 || !hasFormat) {
					complete = true;
				}

			}catch(err){
				console.log(err);
			}

		}



		return count;



	}

	getMoveFormatInfo({
		quill
	}) {

		let formats = quill.getFormat();
		let hasEditingMovedTombstoneBlot = false;
		let hasEditingMovedDestinationBlot = false;

		let existingTombstoneUuid = null;
		let existingDestinationUuid = null;

		let existingTombstoneFormat = null;
		let existingDestinationFormat = null;

		Object.keys(formats).forEach((key) => {
			if (key == 'editingMovedTombstoneBlot') {

				hasEditingMovedTombstoneBlot = true;
				existingTombstoneUuid = formats[key].uuid;
				existingTombstoneFormat = formats[key];
			}
			if (key == 'editingMovedDestinationBlot') {

				hasEditingMovedDestinationBlot = true;
				existingDestinationUuid = formats[key].uuid;
				existingDestinationFormat = formats[key];

			}
		})

		return {
			hasEditingMovedTombstoneBlot,
			hasEditingMovedDestinationBlot,
			existingTombstoneUuid,
			existingDestinationUuid,
			existingTombstoneFormat,
			existingDestinationFormat
		}

	}

	doMoveDestinationBlot({
		quill,
		props,
		documentPart,
		existingTombstoneFormat,
		existingDestinationFormat
	}) {
		let range = quill.getSelection(false, 'silent');
		let allChangeDeltas = [];
		let selectedContent = props.stores.bookStore.inTransitMovedEdit;
		let sourceRange = props.stores.bookStore.inTransitSourceRange;
		let sourceQuill = props.stores.bookStore.inTransitSourceQuill;

		let inTransitSourceDocumentPart = props.stores.bookStore.inTransitSourceDocumentPart;


		let deltaManager = new DeltaManager();
		let { inTransitMovedEdit, inTransitSourceRange } = props.stores.bookStore;


		let finalDelta = new Delta();

		let updateQuillLive = true;

		//1: Delete the old
		let deleteDelta = new Delta();
		if (inTransitSourceRange.index > 0) {
			deleteDelta.retain(sourceRange.index);
		} else {
			deleteDelta.retain(sourceRange.index);
		}

		deleteDelta.delete(sourceRange.length);


		/////////////////////////////////////////////////////////////////////////////////////
		if (props.documentPart.id == inTransitSourceDocumentPart.id) {

			let finalDestinationDelta = new Delta();
			let insertOriginalContentDelta = new Delta();

			let droppedIndex = range.index;
			if (props.documentPart.id == inTransitSourceDocumentPart.id) {
				droppedIndex = range.index;
			}

			if (sourceRange.index < droppedIndex) {
				droppedIndex -= sourceRange.length;
			}
			insertOriginalContentDelta.ops = [{ retain: droppedIndex }].concat(inTransitMovedEdit.ops);
			finalDestinationDelta = finalDestinationDelta.compose(deleteDelta);
			finalDestinationDelta = finalDestinationDelta.compose(insertOriginalContentDelta);
			if (updateQuillLive) quill.updateContents(finalDestinationDelta, 'silent');

			this.appendDelta({
				documentPart: props.documentPart,
				delta: finalDestinationDelta
			})


			//props.documentPart.appendDelta(finalDestinationDelta);

			// Update the tombstone blot to point to the new documentPart ( if it changed ).
			// The entire moved delta should be formatted as a destination blot. So, can 
			// extract source partId and uuid from it. 

			let sourceUuid = null;
			let sourcePartId = null;

			while (sourceUuid == null) {


				inTransitMovedEdit.ops.forEach((op) => {


					Object.keys(op.attributes).forEach((key) => {

						if (key == 'editingMovedDestinationBlot') {
							sourceUuid = op.attributes[key].oppositeUuid;
							sourcePartId = op.attributes[key].sourcePartId;

						}
					})
				})

			}


			let bookDraft = documentPart.bookDraftDocumentPart.bookDraft;
			let sourceDocumentPart = bookDraft.getDocumentPartById(sourcePartId).documentPart;

			//Now cycle through the source deltaOps , looking for all deltas with this source formatting
			let priorOps = [];
			let sourceOps = [];
			let tombstoneFormatProperties = null;
			let composedDelta = this.composeDeltas(sourceDocumentPart.deltaOps);


			let foundSourceOps = false;

			composedDelta.ops.forEach((op) => {

				let isSourceOp = false;

				if (op.attributes) {
					Object.keys(op.attributes).forEach((key) => {

						if (key == 'editingMovedTombstoneBlot') {

							tombstoneFormatProperties = op.attributes[key];
							let uuid = op.attributes[key].uuid;
							let partId = op.attributes[key].sourcePartId;

							if (uuid == sourceUuid && sourcePartId == partId) {

								foundSourceOps = true;
								isSourceOp = true;

							}

						}
					})
				}

				if (isSourceOp) {
					sourceOps.push(op);
				}

				if (!foundSourceOps && sourceOps.length == 0) {
					priorOps.push(op);
				}


			})



			let priorDelta = new Delta(priorOps);
			let sourceDelta = new Delta(sourceOps);

			let sourceIndex = priorDelta.length();
			let sourceLength = sourceDelta.length();

			let updateSourceDelta = new Delta([
				{
					"retain": sourceIndex
				},
				{
					"retain": sourceLength,
					"attributes": {
						"editingMovedTombstoneBlot": {
							"uuid": tombstoneFormatProperties.uuid,
							"oppositeUuid": tombstoneFormatProperties.oppositeUuid,
							"accepted": tombstoneFormatProperties.accepted,
							"rejected": tombstoneFormatProperties.rejected,
							"editorPinned": tombstoneFormatProperties.editorPinned,
							"requestorPinned": tombstoneFormatProperties.requestorPinned,
							"editorComment": tombstoneFormatProperties.editorComment,
							"sourcePartId": tombstoneFormatProperties.sourcePartId,
							"destinationPartId": props.documentPart.id
						}
					}
				}
			]);

			finalDestinationDelta = finalDestinationDelta.compose(updateSourceDelta);

			this.appendDelta({
				documentPart: sourceDocumentPart,
				delta: updateSourceDelta
			})
			//sourceDocumentPart.appendDelta(updateSourceDelta);
			quill.setSelection(sourceIndex, sourceLength);


			props.stores.bookStore.inTransitMovedEdit = null;
			props.stores.bookStore.inTransitSourceRange = null;
			props.stores.bookStore.inTransitSourceDocumentPart = null;

			props.documentPart.realTimeUpdateTimestamp = Date.now();
			props.stores.bookStore.editingChangeNotesTimestamp = Date.now();
		}
		else {

			//delete source

			let finalDestinationDelta = new Delta();
			let insertOriginalContentDelta = new Delta();

			let droppedIndex = range.index;
			if (props.documentPart.id == inTransitSourceDocumentPart.id) {
				droppedIndex = range.index;
			}

			if (sourceRange.index < droppedIndex) {
				//droppedIndex -= sourceRange.length;
			}
			insertOriginalContentDelta.ops = [{ retain: droppedIndex }].concat(inTransitMovedEdit.ops);
			//finalDestinationDelta = finalDestinationDelta.compose(deleteDelta);
			finalDestinationDelta = finalDestinationDelta.compose(insertOriginalContentDelta);
			//if (updateQuillLive) quill.updateContents(finalDestinationDelta, 'silent');

			this.appendDelta({
				documentPart: inTransitSourceDocumentPart,
				delta: deleteDelta
			})

			this.appendDelta({
				documentPart: props.documentPart,
				delta: finalDestinationDelta
			})
			//props.documentPart.appendDelta(finalDestinationDelta);

			// Update the tombstone blot to point to the new documentPart ( if it changed ).
			// The entire moved delta should be formatted as a destination blot. So, can 
			// extract source partId and uuid from it. 

			let sourceUuid = null;
			let sourcePartId = null;

			while (sourceUuid == null) {


				inTransitMovedEdit.ops.forEach((op) => {


					Object.keys(op.attributes).forEach((key) => {

						if (key == 'editingMovedDestinationBlot') {
							sourceUuid = op.attributes[key].oppositeUuid;
							sourcePartId = op.attributes[key].sourcePartId;

						}
					})
				})

			}


			let bookDraft = documentPart.bookDraftDocumentPart.bookDraft;
			let sourceDocumentPart = bookDraft.getDocumentPartById(sourcePartId).documentPart;

			//Now cycle through the source deltaOps , looking for all deltas with this source formatting
			let priorOps = [];
			let sourceOps = [];
			let tombstoneFormatProperties = null;
			let composedDelta = this.composeDeltas(sourceDocumentPart.deltaOps);


			let foundSourceOps = false;

			composedDelta.ops.forEach((op) => {

				let isSourceOp = false;

				if (op.attributes) {
					Object.keys(op.attributes).forEach((key) => {

						if (key == 'editingMovedTombstoneBlot') {

							tombstoneFormatProperties = op.attributes[key];
							let uuid = op.attributes[key].uuid;
							let partId = op.attributes[key].sourcePartId;

							if (uuid == sourceUuid && sourcePartId == partId) {

								foundSourceOps = true;
								isSourceOp = true;

							}

						}
					})
				}

				if (isSourceOp) {
					sourceOps.push(op);
				}

				if (!foundSourceOps && sourceOps.length == 0) {
					priorOps.push(op);
				}


			})



			let priorDelta = new Delta(priorOps);
			let sourceDelta = new Delta(sourceOps);

			let sourceIndex = priorDelta.length();
			let sourceLength = sourceDelta.length();

			let updateSourceDelta = new Delta([
				{
					"retain": sourceIndex
				},
				{
					"retain": sourceLength,
					"attributes": {
						"editingMovedTombstoneBlot": {
							"uuid": tombstoneFormatProperties.uuid,
							"oppositeUuid": tombstoneFormatProperties.oppositeUuid,
							"accepted": tombstoneFormatProperties.accepted,
							"rejected": tombstoneFormatProperties.rejected,
							"editorPinned": tombstoneFormatProperties.editorPinned,
							"requestorPinned": tombstoneFormatProperties.requestorPinned,
							"editorComment": tombstoneFormatProperties.editorComment,
							"sourcePartId": tombstoneFormatProperties.sourcePartId,
							"destinationPartId": props.documentPart.id
						}
					}
				}
			]);

			finalDestinationDelta = finalDestinationDelta.compose(updateSourceDelta);

			this.appendDelta({
				documentPart: sourceDocumentPart,
				delta: updateSourceDelta
			})
			//sourceDocumentPart.appendDelta(updateSourceDelta);
			quill.setSelection(sourceIndex, sourceLength);


			props.stores.bookStore.inTransitMovedEdit = null;
			props.stores.bookStore.inTransitSourceRange = null;
			props.stores.bookStore.inTransitSourceDocumentPart = null;

			props.documentPart.realTimeUpdateTimestamp = Date.now();
			props.stores.bookStore.editingChangeNotesTimestamp = Date.now();

			//paste destination

		}



	}

	appendDelta({
		documentPart,
		delta
	}) {


		documentPart.appendDelta(delta);

		// entityManager.updateDocumentPart({
		// 	documentPart: documentPart,
		// 	itemKey: documentPart.id,
		// 	itemType: documentPart.modelType,
		// 	fieldName: 'title',
		// 	fieldValue: delta
		// }, () => {

		// 	//console.log('item updated...');
		// 	//that.props.stores.bookStore.editingChangeNotesTimestamp = Date.now();
		// });


	}


	doMove({
		quill,
		props
	}) {
		let sourceUuid = uuid();
		let destinationUuid = uuid();
		let allChangeDeltas = [];
		let range = quill.getSelection(false, 'silent');
		let selectedContent = props.stores.bookStore.inTransitMovedEdit;
		let sourceRange = props.stores.bookStore.inTransitSourceRange;
		let sourceQuill = props.stores.bookStore.inTransitSourceQuill;
		let inTransitSourceDocumentPart = props.stores.bookStore.inTransitSourceDocumentPart;

		let retainDelta = new Delta().retain(range.index);

		let deltaManager = new DeltaManager();
		let { inTransitMovedEdit, inTransitSourceRange } = props.stores.bookStore;

		let { convertedDelta, lengthRemoved } = deltaManager.convertEditedDeltasForMoving([inTransitMovedEdit]);

		let finalDelta = new Delta();

		let updateQuillLive = true;
		//1: Delete the old
		let deleteDelta = new Delta();
		if (inTransitSourceRange.index > 0) {
			deleteDelta.retain(sourceRange.index);
		} else {
			deleteDelta.retain(sourceRange.index);
		}

		deleteDelta.delete(sourceRange.length);


		finalDelta = finalDelta.compose(deleteDelta);
		//if (updateQuillLive) quill.updateContents(deleteDelta, 'silent');



		//2: Insert the converted
		let insertConvertedDelta = new Delta();
		if (inTransitSourceRange.index > 0) {
			insertConvertedDelta.ops = [{ retain: inTransitSourceRange.index }].concat(convertedDelta.ops);
			finalDelta = finalDelta.compose(insertConvertedDelta);
		} else {
			finalDelta = finalDelta.compose(convertedDelta);
		}

		if (updateQuillLive) sourceQuill.updateContents(convertedDelta, 'silent');

		//3: Apply tombstone blot.
		let applyTombstoneDelta = new Delta();
		applyTombstoneDelta.ops = [];
		if (inTransitSourceRange.index != 0) {
			applyTombstoneDelta.ops.push({
				"retain": inTransitSourceRange.index
			});
		}





		applyTombstoneDelta.ops.push({
			"retain": convertedDelta.length(),
			"attributes": {
				"editingMovedTombstoneBlot": {
					"uuid": sourceUuid,
					"oppositeUuid": destinationUuid,
					"accepted": "false",
					"rejected": "false",
					"editorPinned": "false",
					"requestorPinned": "false",
					"editorComment": "",
					"sourcePartId": inTransitSourceDocumentPart.id,
					"destinationPartId": props.documentPart.id
				}
			}
		});

		finalDelta = finalDelta.compose(applyTombstoneDelta);
		try {
			if (updateQuillLive) sourceQuill.updateContents(applyTombstoneDelta, 'silent');
		} catch (err) {
			//console.log('quill not live...');
		}



		this.appendDelta({
			documentPart: inTransitSourceDocumentPart,
			delta: finalDelta
		})
		//inTransitSourceDocumentPart.appendDelta(finalDelta);
		inTransitSourceDocumentPart.realTimeUpdateTimestamp = Date.now();




		/////////////////////////////////////////////////////////////////////////////////////
		{
			let { inTransitMovedEdit, inTransitSourceRange } = props.stores.bookStore;
			let finalDelta = new Delta();
			let insertOriginalContentDelta = new Delta();

			let droppedIndex = range.index;
			if (props.documentPart.id == inTransitSourceDocumentPart.id) {
				droppedIndex = range.index - lengthRemoved;
			}

			if (droppedIndex > 0) {
				insertOriginalContentDelta.ops = [{ retain: droppedIndex }].concat(inTransitMovedEdit.ops);
			} else {
				insertOriginalContentDelta.ops = inTransitMovedEdit.ops;
			}

			finalDelta = finalDelta.compose(insertOriginalContentDelta);
			if (updateQuillLive) quill.updateContents(insertOriginalContentDelta, 'silent');



			let applyMovedDelta = new Delta();



			if (droppedIndex > 0) {
				applyMovedDelta.ops = [
					{
						"retain": droppedIndex
					},
					{
						"retain": inTransitMovedEdit.length(),
						"attributes": {
							"editingMovedDestinationBlot": {
								"uuid": destinationUuid,
								"oppositeUuid": sourceUuid,
								"accepted": "false",
								"rejected": "false",
								"editorPinned": "false",
								"requestorPinned": "false",
								"editorComment": "",
								"sourcePartId": inTransitSourceDocumentPart.id,
								"destinationPartId": props.documentPart.id
							}
						}
					}
				];
			} else {
				applyMovedDelta.ops = [
					{
						"retain": inTransitMovedEdit.length(),
						"attributes": {
							"editingMovedDestinationBlot": {
								"uuid": destinationUuid,
								"oppositeUuid": sourceUuid,
								"accepted": "false",
								"rejected": "false",
								"editorPinned": "false",
								"requestorPinned": "false",
								"editorComment": "",
								"sourcePartId": inTransitSourceDocumentPart.id,
								"destinationPartId": props.documentPart.id
							}
						}
					}
				];
			}





			finalDelta = finalDelta.compose(applyMovedDelta);
			if (updateQuillLive) quill.updateContents(applyMovedDelta, 'silent');


			props.stores.bookStore.inTransitMovedEdit = null;
			props.stores.bookStore.inTransitSourceRange = null;
			props.stores.bookStore.inTransitSourceDocumentPart = null;

			this.appendDelta({
				documentPart: props.documentPart,
				delta: finalDelta
			})
			//props.documentPart.appendDelta(finalDelta);
			props.documentPart.realTimeUpdateTimestamp = Date.now();
			props.stores.bookStore.editingChangeNotesTimestamp = Date.now();

		}
	}





	convertEditedDeltasForMoving = (deltas) => {

		let edits = [];

		if (!deltas) {
			return edits;
		}

		let composedDelta = this.composeDeltas(deltas);



		let convertedDelta = new Delta("{\"ops\":[]}");
		let lengthRemoved = 0;
		composedDelta.ops.forEach((op) => {

			//console.log(op);
			let discardOp = false;
			if (op && op.attributes) {
				Object.keys(op.attributes).forEach((attributeKey) => {

					if (attributeKey == 'editingDeleteBlot' ||
						attributeKey == 'editingInsertBlot' ||
						attributeKey == 'editingInsertNewLineBlot2') {


						if (attributeKey == 'editingDeleteBlot') {
							let editingDeleteBlot = op.attributes[attributeKey];

							delete op.attributes.editingDeleteBlot;
							if (Object.keys(op.attributes).length == 0) delete op.attributes;

							//convertedDelta.ops.push(op);

						}

						if (attributeKey == 'editingInsertBlot') {
							discardOp = true;
							let editingInsertBlot = op.attributes[attributeKey];

							delete op.attributes.editingInsertBlot;
							if (Object.keys(op.attributes).length == 0) delete op.attributes;
							if (op.insert) {
								lengthRemoved += op.insert.length;
							}
							//convertedDelta.ops.push(op);

						}

						if (attributeKey == 'editingInsertNewLineBlot2') {
							discardOp = true;
							let editingInsertNewLineBlot2 = op.attributes[attributeKey];

							delete op.attributes.editingInsertNewLineBlot2;
							if (Object.keys(op.attributes).length == 0) delete op.attributes;
							if (op.insert) {
								lengthRemoved += op.insert.length;
							}
							//convertedDelta.ops.push(op);

						}


					} else {

						//convertedDelta.ops.push(op);

					}

				});
			} else {
				//convertedDelta.ops.push(op);
			}

			if (!discardOp) {
				convertedDelta.ops.push(op);
			}

		})


		//console.log(convertedDelta);
		//console.log(lengthRemoved);
		return {
			convertedDelta,
			lengthRemoved
		}

	}

	convertEditedDeltas = (deltas) => {

		let edits = [];

		if (!deltas) {
			return edits;
		}

		let composedDelta = this.composeDeltas(deltas);

		let incompleteDeltas = this.getIncompleteEdits([composedDelta]);

		if (incompleteDeltas.length > 0) {

			throw {
				error: 'HAS_INCOMPLETE_DELTAS'
			}

		}

		let convertedDelta = new Delta("{\"ops\":[]}");

		composedDelta.ops.forEach((op) => {

			//console.log(op);
			if (op && op.attributes) {
				Object.keys(op.attributes).forEach((attributeKey) => {

					if (
						attributeKey == 'editingDeleteBlot' ||
						attributeKey == 'editingInsertBlot') {


						if (attributeKey == 'editingDeleteBlot') {
							let editingDeleteBlot = op.attributes[attributeKey];
							if (editingDeleteBlot.accepted && editingDeleteBlot.accepted == 'true') {

								//Do not keep this one. 

							} else {

								delete op.attributes.editingDeleteBlot;
								if (Object.keys(op.attributes).length == 0) delete op.attributes;
								convertedDelta.ops.push(op);

							}




						}

						if (attributeKey == 'editingInsertBlot') {
							let editingInsertBlot = op.attributes[attributeKey];
							if (editingInsertBlot.accepted && editingInsertBlot.accepted == 'true') {

								delete op.attributes.editingInsertBlot;
								if (Object.keys(op.attributes).length == 0) delete op.attributes;
								convertedDelta.ops.push(op);

							} else {

								//Do not keep this one. 

							}



						}


					} else {

						convertedDelta.ops.push(op);

					}

				});
			} else {
				convertedDelta.ops.push(op);
			}


		})


		//console.log(convertedDelta);
		return convertedDelta;

	}


	composeDeltas = (deltas) => {

		let finalDelta = new Delta("{\"ops\":[]}");
		if (deltas) {

			deltas.forEach((delta) => {

				//let parsedDelta = delta;//JSON.parse(delta);
				if (!finalDelta) {

					finalDelta = new Delta(delta);

				} else {

					finalDelta = finalDelta.compose(new Delta(delta));

				}
			});
		}

		return finalDelta;


	}

	getEdits = (delta) => {

		let edits = [];
		if (!delta || !delta.ops) {

			return edits;

		}

		delta.ops.forEach((op) => {
			try {


				if (op && op.attributes) {
					Object.keys(op.attributes).forEach((attributeKey) => {
						if (attributeKey == 'editingDeleteBlot' ||
							attributeKey == 'editingInsertBlot' ||
							attributeKey == 'editingMovedTombstoneBlot' ||
							attributeKey == 'editingMovedDestinationBlot' ||
							attributeKey == 'editingInsertNewLineBlot2' ||
							attributeKey == 'editingDeleteNewLineBlot') {
							let change = op.attributes[attributeKey];
							change.type = attributeKey
							//editingBlots2.push(change);

							edits.push(change)


						}
					});
				}
				if (op && op.insert && op.insert.editingDeleteNewLineBlot) {

					let change = op.insert.editingDeleteNewLineBlot;
					//change.type = attributeKey
					//editingBlots2.push(change);

					edits.push(change)

				}
				if (op && op.insert && op.insert.editingInsertNewLineBlot2) {

					let change = op.insert.editingInsertNewLineBlot2;
					//change.type = attributeKey
					//editingBlots2.push(change);

					edits.push(change)

				}

			} catch (err) {
				//console.log(err);
			}
		});

		return edits;
	}

	getIncompleteEdits = (composedDelta) => {

		let edits = [];

		if (!composedDelta) {
			return edits;
		}

		//let composedDelta = this.composeDeltas(deltas);

		edits = this.getEdits(composedDelta);

		let incompleteEdits = [];
		edits.forEach((m) => {

			let rejected = false;
			let accepted = false;

			if (m.rejected && m.rejected == 'true') {
				rejected = true;
			}

			if (m.accepted && m.accepted == 'true') {
				accepted = true;
			}

			if (!accepted && !rejected) {
				incompleteEdits.push(m);
			}
		});

		return incompleteEdits;

	}


}