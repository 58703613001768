import React from "react";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useDragOver } from "@minoru/react-dnd-treeview";
// import { TypeIcon } from "./TypeIcon";
import styles from "./CustomNode.module.css";
import { useTheme } from '@mui/material/styles';
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const TypeIcon = (props) => {
    if (props.droppable) {
        return <FolderIcon />;
    }

    switch (props.fileType) {
        case "image":
            return <ImageIcon />;
        case "csv":
            return <ListAltIcon />;
        case "text":
            return <DescriptionIcon />;
        default:
            return null;
    }
};

export const CustomNode = (props) => {
    const { id, droppable, data, icon } = props.node;
    const indent = props.depth * 24;

    const theme = useTheme();

    const handleToggle = (e) => {
        e.preventDefault();
        e.stopPropagation();
        props.onToggle(props.node.id);
    };

    const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

    return (
        <div
            className={`tree-node ${styles.root} ${props.isSelected ? styles.isSelected : ""
                }`}
            style={{ paddingInlineStart: indent }}
            {...dragOverProps}
        >
            <div
                className={`${styles.expandIconWrapper} ${props.isOpen ? styles.isOpen : ""
                    }`}
            >
                {props.node.droppable && (
                    <div onClick={handleToggle}
                    >
                        <ArrowRightIcon />
                    </div>
                )}
            </div>
            <div>
                {/* <TypeIcon droppable={droppable} fileType={data?.fileType} /> */}
                {icon}
            </div>
            <div className={styles.labelGridItem} >
                <Typography variant="body2" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.onDocumentPartSelected({
                        id: id
                    });
                }}><div style={{
                    backgroundColor: props.isSelected?'lightgray':'inherit',
                    color:props.isSelected?'black':'inherit',
                    fontWeight:props.isSelected?'bold':'inherit',
                }}>
                        {props.node.text}
                    </div></Typography>
            </div>
            <MoreVertIcon onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();


                if (props.onContextMenu) {
                    props.onContextMenu(e, id);
                }
            }}
                sx={{
                    color: theme.palette.standardTree.title.color.main
                }} />
        </div>
    );
};
