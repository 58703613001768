import React, { useEffect, useState } from 'react';
import { styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useMediaQuery } from 'react-responsive';

function StandardFloatingPlus(props) {

  const theme = useTheme();
  

  const { label, checked } = props;


  const isXs = useMediaQuery({ maxWidth: 599 });
  const isSm = useMediaQuery({ maxWidth: 600 });
  const isMd = useMediaQuery({ maxWidth: 900 });
  const isLg = useMediaQuery({ maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1536 });

  return (

    <Box sx={{
      //height: '100%',
      position: 'relative'
    }}>
      {/* {isXs && */}
        <Fab  {...props}
          color="primary" aria-label="add" sx={{
            position: 'absolute',
            bottom: '1em',
            right: '1em',
            backgroundColor: theme.palette.secondary.main,
            //width:'2.5em',
            //height:'2.5em'

          }}>
          <AddIcon />
        </Fab>
      {/* } */}
    </Box>


  );
}


export default inject('stores')(observer(StandardFloatingPlus));



