import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import config from '../../aws-exports';
import useMediaQuery from '@mui/material/useMediaQuery';
import StretchBox from '../boilerPlate/StretchBox';
import { Box, Button, SwipeableDrawer, Typography } from '@mui/material';
import ScrollBox from '../boilerPlate/ScrollBox';
import StandardUICard from '../UI/StandardUICard';
import StandardUICirclePlus from '../UI/StandardUICirclePlus';
import ListConnections from './lists/ListConnections';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import ConnectionSharedToCard from './listCards/ConnectionSharedToCard';
import ConnectionSharedFromCard from './listCards/ConnectionSharedFromCard';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import InviteConnection from './dialogs/InviteConnection';
import ConnectionShareBook from './dialogs/ConnectionShareBook';
import InviteConnectionDialog from './dialogs/InviteConnectionDialog';
import StandardUITextField from '../UI/StandardUITextField';
import StandardUIButton from '../UI/StandardUIButton';


const ConnectionsPage = (props) => {


    let { user, book, userConnection, data } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdAt');


    const [showInviteConnection, setShowInviteConnection] = useState(false);
    const [showShareBook, setShowShareBook] = useState(false);

    const handleOpenInvitation = () => {
        setShowInviteConnection(true);
    }

    const handleCloseInvitation = () => {
        setShowInviteConnection(false);
    }

    const handleShareBookOpen = () => {
        setShowShareBook(true);
    }

    const handleShareBookClose = () => {
        setShowShareBook(false);
    }



    const [showDetailDrawer, setShowDetailDrawer] = useState(false);
    let [selectedUserConnection, setSelectedUserConnection] = useState(null);

    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setShowDetailDrawer(open);
            };


    return (

        <StretchBox flexDirection='row' sxProps={{ gap: isSmallScreen ? 0 : 2 }}>

            <StretchBox sxProps={{
                flex: isSmallScreen ? 12 : 4,
                position: 'relative'
            }}>
                {/* <BoxsetsTable onSelectBoxSet={
                    (boxset) => {
                        setSelectedBoxset(boxset)
                        setShowDetailDrawer(true)
                    }} /> */}

                <ScrollBox>
                    <StandardUICard
                        title='Connections'
                        showPagination={false}
                        showSearchField={true}
                        onSetOrdering={(orderBy, order) => { setOrder(order); setOrderBy(orderBy) }}
                        children={
                            // <ListConnections 
                            //     secondary={'Time ago'}
                            //     showShareIcon={false}
                            // />
                            <ListConnections
                                selectedUserConnection={selectedUserConnection}
                                order={order}
                                orderBy={orderBy}
                                onListSelectUser={(userConnection) => {
                                    setSelectedUserConnection(userConnection)
                                    setShowDetailDrawer(true)
                                }}
                                secondary={'Time ago'}
                                showShareIcon={false}
                            />
                        }
                    />






                </ScrollBox>
                <StandardUICirclePlus
                    onClick={handleOpenInvitation}
                />


            </StretchBox>

            <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>



                    <StandardUICard
                        title='Details'
                        showPagination={false}
                        showSearchField={false}
                        // actions={
                        //     <Link underline='none' color='blue'
                        //         onClick={handleMoreDetailOpen}>
                        //         More
                        //     </Link>
                        // }
                        children={
                            <div>
                                <StandardUITextField label='Name' value={selectedUserConnection?.connectedUser?.name} disabled />
                                <Typography>Connected Since</Typography>
                                <StandardUIButton
                                    sx={{ marginTop: '1em' }}
                                    buttonAction='Remove'
                                />
                            </div>
                        }
                    />
                    <StandardUICard
                        title='Books You Have Shared'
                        showPagination={true}
                        showSearchField={true}
                        actions={
                            <Fab color="primary" size='small'
                                onClick={handleShareBookOpen}
                            >
                                <AddIcon />
                            </Fab>
                        }
                        children={
                            <ConnectionSharedToCard userConnection={selectedUserConnection} />
                        }
                    />
                    <StandardUICard
                        title='Books Shared With You'
                        showPagination={true}
                        showSearchField={true}
                        // actions={
                        //     <Link underline='none' color='blue'
                        //         onClick={handleMoreDetailOpen}>
                        //         More
                        //     </Link>
                        // }
                        children={
                            <ConnectionSharedFromCard userConnection={selectedUserConnection} />
                        }
                    />


                </ScrollBox>
            </StretchBox>




            <InviteConnectionDialog
                open={showInviteConnection}
                onClose={handleCloseInvitation}
                onSent={handleCloseInvitation}
            />

            <ConnectionShareBook
                userConnection={selectedUserConnection}
                open={showShareBook}
                onClose={handleShareBookClose}
            />

            <SwipeableDrawer
                anchor={'right'}
                open={showDetailDrawer && isSmallScreen}
                onClose={toggleDrawer(false)}
                sx={{ width: '100%' }}
            //variant='persistent'
            //onOpen={setShowDetailDrawer(true)}
            >
                <div style={{ width: '100vw' }}>

                    <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                        <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2, ml: 0, mr: 0 }}>
                            <IconButton
                                style={{ display: 'flex', justifyContent: 'left' }}
                                onClick={() => {
                                    setShowDetailDrawer(false)
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography
                                variant={theme.landmarkText.variant}
                                gutterBottom
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                {selectedUserConnection?.connectedUser?.name}
                            </Typography>


                            <StandardUICard
                                title='Details'
                                showPagination={false}
                                showSearchField={false}
                                // actions={
                                //     <Link underline='none' color='blue'
                                //         onClick={handleMoreDetailOpen}>
                                //         More
                                //     </Link>
                                // }
                                children={
                                    <div>
                                        <StandardUITextField label='Name' value={selectedUserConnection?.connectedUser?.name} disabled />
                                        <Typography>Connected Since</Typography>
                                        <StandardUIButton
                                            sx={{ marginTop: '1em' }}
                                            buttonAction='Remove'
                                        />
                                    </div>
                                }
                            />
                            <StandardUICard
                                title='Books You Have Shared'
                                showPagination={true}
                                showSearchField={true}
                                actions={
                                    <Fab color="primary" size='small'
                                        onClick={handleShareBookOpen}
                                    >
                                        <AddIcon />
                                    </Fab>
                                }
                                children={
                                    <ConnectionSharedToCard userConnection={selectedUserConnection} />
                                }
                            />
                            <StandardUICard
                                title='Books Shared With You'
                                showPagination={true}
                                showSearchField={true}
                                // actions={
                                //     <Link underline='none' color='blue'
                                //         onClick={handleMoreDetailOpen}>
                                //         More
                                //     </Link>
                                // }
                                children={
                                    <ConnectionSharedFromCard userConnection={selectedUserConnection} />
                                }
                            />


                        </ScrollBox>
                    </StretchBox>
                </div>
            </SwipeableDrawer>


        </StretchBox>
    )
}
export default inject('stores')(observer(ConnectionsPage));