import React, { useState, useEffect } from "react";
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography, Box } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import StandardRoundedAction from "../actions/StandardRoundedAction";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const ListExpandIcon = (props) => {

    let { expanded, actionIcon } = props;

    return (
        
        <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: '100%',
            gap:'1em'
            //border: '1px solid red'
        }}>
            {actionIcon}


            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                //border: '1px solid red'
            }}
                onClick={(e) => {

                    e.preventDefault();
                    props.onToggleExpand();
                   
                }}>

                {expanded === true ? <ExpandLess /> : <ExpandMore />}

            </Box>

        </Box>

    )
}

export default inject('stores')(observer(ListExpandIcon));