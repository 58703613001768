import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';


const LaurasTextField = (props) => {


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <Box sx={{
            //height: '100%',
            position: 'relative'
          }}>
            {/* {isXs && */}
              <Fab  {...props}
                color="primary" aria-label="add" sx={{
                  position: 'absolute',
                  bottom: '2em',
                  right: '0.25em',
                  backgroundColor: theme.palette.secondary.main,
                  //width:'2.5em',
                  //height:'2.5em'
      
                }}>
                <AddIcon />
              </Fab>
            {/* } */}
          </Box>
    )
}
export default inject('stores')(observer(LaurasTextField));