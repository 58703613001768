import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import StandardTabs from "../StandardTabs";
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton, { StandardListButton } from "../StandardButton";
import BookSharedWithList from "../lists/BookSharedWithList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../managers/BooksManager';
import UserGroupsList from "../lists/UserGroupsList";
import { useManagerCreateEditRequest } from '../../managers/EditRequestsManager';
import StandardOkCancelDialog from "./StandardOkCancelDialog";
import ShareBookPanel from "./share/ShareBookPanel";
import ContributorsList from "../lists/ContributorsList";
import AddBoxsetContributorPanel from "./AddBoxsetContributorPanel";
import FeedbackList from "../lists/FeedbackList";
import DocumentPartUserComments from "../editor/DocumentPartUserComments";



const FeedbackPanel = (props) => {

  let user = props.stores.userStore.user;
  let { book, bookDraftDocumentPart, ownerMode } = props;

  let [showAddContributorDialog, setShowAddContributorDialog] = useState(false);

  const { createEditRequest, loading: createEditRequestLoading, error: createEditRequestError } = useManagerCreateEditRequest(props);

  let [selectedTab, setSelectedTab] = useState(0);


  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Grid container direction={'column'}>
      {/* <Grid item>
        <StandardListButton
          variant="outlined"
          onClick={() => {
            setShowAddContributorDialog(true)
          }}>Feedback</StandardListButton>

      </Grid> */}
      <Grid item>
        <FeedbackList book={book} ownerMode={true} />
        <DocumentPartUserComments
          book={book}
          showBookInfo={false}
          documentPart={bookDraftDocumentPart?.documentPart}
          ownerMode={ownerMode} />

      </Grid>
    </Grid>
  )
}

export default inject('stores')(observer(FeedbackPanel));