import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { Paper, Typography } from '@mui/material';
import StandardHelpLabel from '../../StandardHelpLabel';
import PrecipitantDetail from '../../universe/PrecipitantDetail';

const LinkPopper = (props) => {

    let { open, onClose = () => { }, anchorEl, characterBlot } = props;

    const [href, setHref] = useState(characterBlot?.domNode?.dataset?.href)
    const [linkType, setLinkType] = useState(characterBlot?.domNode?.dataset?.linkType)

    let { currentPrecipitantTitleForPopper } = props.stores.bookStore;

    useEffect(() => {

        setHref(characterBlot?.domNode?.dataset?.href);
        setLinkType(characterBlot?.domNode?.dataset?.linkType);

    }, [characterBlot?.domNode?.dataset?.href]);

    let precipitant = null;

    try{
        precipitant = props.stores.bookStore.universeListUniverse.getPrecipitantByTitle(currentPrecipitantTitleForPopper);
    }catch(err){
        //console.log(err);
    }
    return (
        <Popper
            id={'linkPopper'}
            open={open}
            onMouseLeave={() => {
                onClose();

            }}

            anchorEl={anchorEl}
            transition
            placement="bottom-start"
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper elevation={6}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '1em'
                        }}>
                            <StandardHelpLabel title={'Character Card'} />
                            {currentPrecipitantTitleForPopper}
                            <PrecipitantDetail precipitant={precipitant}/>
                            {/* <div style={{ 
                                display: 'flex',
                                alignItems:'center',
                                justifyContent:'space-between'
                            }}>
                                <StandardTextField label="url" fullWidth id="filled-basic" variant="filled" size="small" sx={{

                                }}
                                    value={href}
                                    onChange={(e) => {
                                        setHref(e.target.value);
                                        characterBlot.setHref(e.target.value);
                                        //characterBlot?.domNode?.dataset?.href = 
                                    }} />
                                <OpenInNewIcon color='primary' onClick={(e)=>{

                                    e.preventDefault();
                                    window.open(href);
                                }}/>
                            </div> */}

                            
                        </div>
                    </Paper>
                </Fade>
            )}
        </Popper>

    );
};


export default inject('stores')(observer(LinkPopper));
