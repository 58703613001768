import { Amplify } from 'aws-amplify';
import { createAuthLink } from 'aws-appsync-auth-link';
// import { persistCache, CachePersistor } from "apollo-cache-persist";
import { persistCache, LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import localforage from 'localforage';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  useQuery,
  HttpLink,
  createHttpLink,
  gql
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import SerializingLink from 'apollo-link-serialize';
import QueueLink from 'apollo-link-queue';
import config from './aws-exports';
import { Auth } from 'aws-amplify'
// import trackerLink2 from './components/apollo/trackerLink2';
import mutationPersister from './components/apollo/mutationPersister';
import { SnackbarProvider, enqueueSnackbar } from 'notistack'

const url = config.graphqlEndpoint;
const region = config.aws_project_region;

const auth = {
  type: "AMAZON_COGNITO_USER_POOLS",
  jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>{

      console.log(message);
     
      let shortenedMsg = message;
      if(message.msg){
        shortenedMsg = message.msg;
      }

      try{
        
        enqueueSnackbar('LINK:'+shortenedMsg+':'+message.fieldName,{variant:'info'})
      }catch(err){
        console.log(err);
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )

  }
      
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const retryLink = new RetryLink({
  attemptsx: { max: Infinity },
  attempts: (count, operation, error) => {
    return !!error && operation.operationName != 'specialCase';
  },
  delay: (count, operation, error) => {
    return count * 1000 * Math.random();
  },
});


const queueLink = new QueueLink();


const serializingLink = new SerializingLink();

const cache = new InMemoryCache({});

localforage.config({
  //driver      : localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
  name: 'bookmerlin-indexeddb',
  version: 1.0,
  //size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: 'bookmerlin-indexeddb', // Should be alphanumeric, with underscores.
  description: 'bookmerlin app data'
});


let link = null;

let persistor = new CachePersistor({
  storage: new LocalStorageWrapper(window.localStorage),
  cache,
  maxSize:false
});


const httpLink = new HttpLink({
  uri: url
});

//Article regarding, among other things, forcing mutations to be sequential.
//https://codeburst.io/highly-functional-offline-applications-using-apollo-client-12885bd5f335
link = ApolloLink.from([

  errorLink,
  //mutationPersister,
  queueLink,
  serializingLink,
  retryLink,
  createAuthLink({ url, region, auth }),
  //createHttpLink({ uri: url })
  httpLink

]);


let client = new ApolloClient({
  link,
  connectToDevTools: true,
  cache: cache,
  onError: ({ graphQLErrors, networkError, operation, forward }) => {
    //console.log(graphQLErrors);
    //console.log(networkError);
    //console.log(operation);
    //console.log(forward);
    if (graphQLErrors) {

    }
  }
});



export default { client, persistor,cache };