import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import { useGetAllBookShares, useGetBookShares, useManagerDeleteBookUserShare, useManagerDeleteBookUserGroupShare } from '../../../managers/BooksManager';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Avatar from '@mui/material/Avatar';
import EmptyContent from "../../EmptyContent";
import StandardUIDialog from "../../UI/StandardUIDialog";
import StandardUIButton from "../../UI/StandardUIButton";
import StandardUITextField from "../../UI/StandardUITextField";
import UserListItem from "../lists/UserListItem";

const OwnedBookSharedCard = (props) => {


    let user = props.stores.userStore.user;

    let { book, showRemoveIcon = true } = props;

    //const { sharedWithUsers, bookUserSharesLoading, bookUserSharesError, sharedWithUserGroups, bookUserGroupSharesLoading, bookUserGroupSharesError } = useGetBookShares(props, book);
    const { sharedWithItems, sharedWithLoading, sharedWithError } = useGetAllBookShares(props, book);



    const [openUnshare, setOpenUnshare] = React.useState(false);
    const [sharedItems, setSharedItems] = React.useState(false);

    const handleOpenUnshare = () => {
        setOpenUnshare(true);
    };

    const handleCloseUnshare = (value) => {
        setOpenUnshare(false);
    };

    

    useEffect(() => {

        let tableData = [];

        if (sharedWithItems) {

            try {

                sharedWithItems.forEach((sharedItem) => {

                    if (sharedItem.userGroupId != null) {

                        tableData.push({
                            name: sharedItem?.userGroup.name,
                            profilePicUrl: sharedItem?.userGroup.profilePicUrl,
                            type: 'Group',
                            createdAt: sharedItem?.createdAt,
                            id: sharedItem?.userGroup.id
                        });
                    }
                    if (sharedItem.sharedWithUserId != null) {

                        tableData.push({
                            name: sharedItem.sharedWithUser?.name,
                            profilePicUrl: sharedItem.sharedWithUser?.profilePicUrl,
                            type: 'User',
                            createdAt: sharedItem?.createdAt,
                            id: sharedItem.sharedWithUser.id
                        })
                    }


                });

                setSharedItems(tableData);
            } catch (err) {
                console.log(err)
            }
            //console.log(sharedWithItems);
        }
    }, [sharedWithItems]);



    // if (sharedWithUsers) {
    //     tableData = tableData.concat(sharedWithUsers.map((bookUserShare) => {

    //         return {
    //             name: bookUserShare.sharedWithUser?.name,
    //             profilePicUrl: bookUserShare.sharedWithUser?.profilePicUrl,
    //             type: 'User',
    //             createdAt: bookUserShare?.createdAt,
    //             id: bookUserShare.sharedWithUser.id
    //         }
    //     }))
    // }


    // if (sharedWithUserGroups) {

    //     tableData = tableData.concat(sharedWithUserGroups.map((bookUserGroupShare) => {

    //         return {
    //             name: bookUserGroupShare?.userGroup.name,
    //             profilePicUrl: bookUserGroupShare?.userGroup.profilePicUrl,
    //             type: 'Group',
    //             createdAt: bookUserGroupShare?.createdAt,
    //             id: bookUserGroupShare?.userGroup.id
    //         }
    //     }))
    // }

    const secondaryActions = [];

    if (showRemoveIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
                setOpenUnshare(true)
            }}>
            <RemoveCircleOutlineIcon />
        </IconButton>)
    }



    return (
        <List>
            {sharedItems && sharedItems.length > 0 ?
                sharedItems.map((bookShare) => {
                    return <UserListItem
                        //selected={selectedIndex}
                        user={bookShare}
                        secondaryActions={
                            secondaryActions
                        }
                    //secondaryText={userConnection.createdAt != null ? <>Connected: <ReactTimeAgo date={userConnection.createdAt} locale="en-US" /> </> : ''}
                    />


                    return <ListItem
                        sx={{ padding: ' 0.25em' }}
                        secondaryAction={
                            secondaryActions
                        }
                    >
                        <ListItemAvatar>
                            <Avatar
                                bucketName={config.user_uploads_bucket_name}
                                s3Src={bookShare?.profilePicUrl}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={bookShare?.name}
                            secondary='shared since'
                        />
                    </ListItem>
                })
                :
                <EmptyContent tagline={'Share your book!'} />
            }
            <StandardUIDialog
                open={openUnshare}
                onClose={handleCloseUnshare}
                title='Unshare this book'
                dialogContent={
                    <div>
                        <Typography>Confirm you want to unshare this book below.</Typography>
                        <StandardUITextField
                            label='Confirm Unshare'
                        />
                    </div>
                }
                cardActions={
                    <StandardUIButton
                        buttonAction='Unshare'
                        onClick={() => {
                            alert('Unshared');
                            setOpenUnshare(false);
                        }}
                    />
                }
            />
        </List>
    )
}

export default inject('stores')(observer(OwnedBookSharedCard));