import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Switch from '@mui/material/Switch';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDocumentEdit from 'mdi-material-ui/FileDocumentEdit';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import Feather from 'mdi-material-ui/Feather';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import PeopleIcon from '@mui/icons-material/People';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { ApolloConsumer } from '@apollo/client';
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ShareIcon from '@mui/icons-material/Share';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import SettingsIcon from '@mui/icons-material/Settings';
import RoutesHelper from '../../helpers/RoutesHelper';
import { useGetOwnedBooks, useOpenBookInWrite, useOpenBookInFormat, useManagerCreateBook } from '../../managers/BooksManager';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { useMediaQuery } from 'react-responsive';
import CommentIcon from '@mui/icons-material/Comment';
import { StandardListButton } from '../StandardButton';
import StandardOkCancelDialog from '../panels/StandardOkCancelDialog';
import AddBoxsetContributorPanel from '../panels/AddBoxsetContributorPanel';
import AddBoxsetBookPanel from '../panels/AddBoxsetBookPanel';
import StandardRoundedAction from '../actions/StandardRoundedAction';
import ArticleIcon from '@mui/icons-material/Article';
import ExportsPanel from '../export/ExportsPanel';
import ExportDialog from '../panels/ExportDialog';
import { useGetBookDraftBuildRequests } from "../../managers/ExportsManager";
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useUpdateItem } from '../../persistance/persistanceHooks';
import DashboardCard from '../dashboard/DashboardCard';

const StandardDrawer = styled(Drawer)(() => {


})


function OwnedBoxsetDetailMenuContent(props) {

    let book = props.stores.bookStore.boxSetListBoxSet?.book;
    let boxset = props.stores.bookStore.boxSetListBoxSet;

    let navigate = useNavigate();
    let location = useLocation();
    let [openBooks, setOpenBooks] = useState(true);
    let [showAddContributorDialog, setShowAddContributorDialog] = useState(false);
    let [showAddBookDialog, setShowAddBookDialog] = useState(false);
    let [showExportDialog, setShowExportDialog] = useState(false);
    const [updateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] = useUpdateItem();

    let [buildTypes, setBuildTypes] = useState({});
    let { bookDraftId } = props;

    const { buildRequests, loading, error, client } = useGetBookDraftBuildRequests(props, bookDraftId);

    const CREATE_BOOK = gql` mutation RequestbuildBatch(
        $bookDraftId: String!,
        $buildTypes: [String]!
    ) {
        requestbuildBatch(input: {
            bookDraftId: $bookDraftId, 
            buildTypes: $buildTypes
        }) {
            id
            ownerId
            buildTypes
            completedAt
            createdAt

        }
    }`;


    const [
        requestbuildBatch,
        { data: mutationData, loading: mutationLoading, error: mutationError }] =
        useMutation(CREATE_BOOK);



    const GET_PRESIGNED_URL = gql`
    query getPresignedUrl($key: String!) {
      getPresignedUrl(key: $key)
              }
    `;


  


    const { openBookInWrite } = useOpenBookInWrite(props);
    const { openBookInFormat } = useOpenBookInFormat(props);

    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    const subItemsIndent = 0;
    const subSubItemsIndent = 0;

    const theme = useTheme();

    const isRoute = (pathname) => {
        //if (pathname && pathname.includes(location.pathname) ) {
        if (location.pathname && location.pathname.includes(pathname)) {
            return 'secondary';
        }

        return 'inherit';
    }

    const handleSelection = () => {

    }

    const onLogout = event => {
        event.preventDefault();
        props.stores.bookStore.loggedIn = false;

        Auth.signOut()
            .then(data => {
                //console.log(data);

                props.stores.bookStore.loggedIn = false;
                //props.stores.bookStore.reset();

            })
            .catch(err => {
                console.log(err);

            })
    };

    const selectRoute = (route) => {

        navigate(route);
        props.onRouteSelected();

    }

    return (
        <>
            <DashboardCard
                title={'My Boxset Menu'}
                color={theme.palette.books?.main}
                hideIcon={true}
                //icon={<MenuBookIcon 
                sx={{
                    color: theme.palette.cardIcon?.main,
                    width: '100%'
                }}>
                <List component="div" disablePadding>

                    {!isXs &&
                        <ListItemButton sx={{
                            pl: 1
                        }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                selectRoute("/boxsets/list");
                            }}>
                            <ListItemIcon>
                                <ArrowBackSharpIcon color={'inherit'} />
                            </ListItemIcon>
                            <ListItemText primary="Back to Your Boxsets" />
                        </ListItemButton>
                    }



                    {/* <ListItemButton sx={{
                    pl: subItemsIndent + subSubItemsIndent,
                    backgroundColor: theme.palette.books?.main
                }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //selectRoute("/books");
                    }}> */}
                    {/* <ListItemIcon color={theme.palette.books?.main}>
                                            <MenuBookIcon color={theme.palette.books?.main} />
                                        </ListItemIcon> */}
                    {/* <ListItemText
                        primary={book?.title} />
                </ListItemButton> */}



                    <ListItemButton sx={{
                        pl: subItemsIndent + subSubItemsIndent
                    }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            selectRoute("/boxsets/details");
                        }}>
                        <ListItemIcon>
                            <SettingsIcon color={RoutesHelper.isRoute('/boxsets/details', location.pathname) ? 'secondary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText primary="Details" />
                    </ListItemButton>

                    <ListItemButton sx={{
                        pl: subItemsIndent + subSubItemsIndent
                    }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            selectRoute("/boxsets/details/contributors");
                        }}>
                        <ListItemIcon>
                            <PeopleIcon color={isRoute('/boxsets/details/contributors')} />
                        </ListItemIcon>
                        <ListItemText primary="Contributors" />
                    </ListItemButton>

                    <ListItemButton sx={{
                        pl: subItemsIndent + subSubItemsIndent
                    }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            selectRoute("/boxsets/details/books");
                        }}>
                        <ListItemIcon>
                            <MenuBookIcon color={isRoute('/boxsets/details/books')} />
                        </ListItemIcon>
                        <ListItemText primary="Books" />
                    </ListItemButton>

                    {/* <ListItemButton sx={{
                    pl: subItemsIndent + subSubItemsIndent
                }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        selectRoute("/boxsets/details/feedback");
                    }}>
                    <ListItemIcon>
                        <CommentIcon color={isRoute('/boxsets/details/feedback')} />
                    </ListItemIcon>
                    <ListItemText primary="Feedback" />
                </ListItemButton> */}

                    <ListItemButton sx={{
                        pl: subItemsIndent + subSubItemsIndent
                    }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            selectRoute("/boxsets/details/exports");
                        }}>
                        <ListItemIcon>
                            <FileDownloadIcon color={isRoute('/boxsets/details/exports')} />
                        </ListItemIcon>
                        <ListItemText primary="Exports" />
                    </ListItemButton>

                </List>

            </DashboardCard>
            {/* <Divider /> */}
            <DashboardCard
                title={'Apps'}
                color={theme.palette.books?.main}
                hideIcon={true}
                //icon={<MenuBookIcon 
                sx={{
                    color: theme.palette.cardIcon?.main,
                    width: '100%'
                }}>
                <ListItem disablePadding>
                    <ListItemButton sx={{
                        pl: subItemsIndent + subSubItemsIndent
                    }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            //selectRoute("/write");

                            openBookInWrite({
                                bookDraftId: book?.primaryBookDraftId
                            });

                        }}>
                        <ListItemIcon>
                            {/* <Feather color={isRoute('/write')} /> */}
                            <StandardRoundedAction icon={<Feather color={isRoute('/write')} />} />
                        </ListItemIcon>
                        <ListItemText primary="Write" />
                    </ListItemButton>
                </ListItem>


                <ListItem disablePadding>
                    <ListItemButton sx={{
                        pl: subItemsIndent + subSubItemsIndent
                    }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            //selectRoute("/format");
                            openBookInFormat({
                                bookDraftId: book?.primaryBookDraftId
                            });
                        }}>
                        <ListItemIcon>
                            {/* <CellphoneSettings /> */}
                            {/* <FormatPaintIcon color={isRoute('/format')} /> */}
                            <StandardRoundedAction icon={<FormatPaintIcon color={isRoute('/format')} />} />
                        </ListItemIcon>
                        <ListItemText primary="Format" />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton sx={{
                        pl: subItemsIndent + subSubItemsIndent
                    }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            updateItem({
                                itemExpectedVersion: 1,
                                itemKey: boxset.id,
                                //itemSortKeyName: 'documentPartId',
                                //itemSortKeyValue: props.userComment.documentPartId,
                                itemType: 'BoxSet',
                                fieldName: 'deletedAt',
                                fieldValue: 'now',
                                refetchQueries: ['listBoxSets'],
                                onCompleted: () => {

                                    //props.userComment.isPinned = !props.userComment.isPinned
                                    //props.stores.bookStore.bookListBook = null;
                                    selectRoute("/boxsets/list");
                                }


                            })

                            //props.stores.bookStore.bookListBook = null;
                            selectRoute("/boxsets/list");

                        }}>
                        <ListItemIcon>
                            {/* <CellphoneSettings /> */}
                            {/* <TrashCanOutline color={'secondary'} /> */}
                            <StandardRoundedAction icon={<TrashCanOutline color={'#ffffff'} />} />
                        </ListItemIcon>
                        <ListItemText primary="Delete" />
                    </ListItemButton>
                </ListItem>



                {RoutesHelper.isRoute('/boxsets/details/contributors', location.pathname) &&
                    <ListItem disablePadding>
                        <StandardListButton
                            sx={{
                                //backgroundColor:theme.palette.write.controls.backgroundColor,
                                color: theme.palette.write.controls.color,
                                borderColor: theme.palette.write.controls.color
                            }}
                            disableElevation={true}
                            variant="outlined"
                            onClick={() => {
                                setShowAddContributorDialog(true)
                            }}>Add Contributor</StandardListButton>
                    </ListItem>
                }

                {RoutesHelper.isRoute('/boxsets/details/books', location.pathname) &&
                    <ListItem disablePadding>
                        <StandardListButton
                            sx={{
                                //backgroundColor:theme.palette.write.controls.backgroundColor,
                                color: theme.palette.write.controls.color,
                                borderColor: theme.palette.write.controls.color
                            }}
                            disableElevation={true}
                            variant="outlined"
                            onClick={() => {
                                setShowAddBookDialog(true)
                            }}>Contribute Book</StandardListButton>
                    </ListItem>
                }

                {RoutesHelper.isRoute('/boxsets/details/exports', location.pathname) &&
                    <ListItem disablePadding>
                        <StandardListButton
                            sx={{
                                //backgroundColor:theme.palette.write.controls.backgroundColor,
                                color: theme.palette.write.controls.color,
                                borderColor: theme.palette.write.controls.color
                            }}
                            disableElevation={true}
                            variant="outlined"
                            onClick={() => {
                                setShowExportDialog(true)
                            }}>Export</StandardListButton>
                    </ListItem>
                }


                <StandardOkCancelDialog
                    open={showAddContributorDialog}
                    onClose={() => {
                        setShowAddContributorDialog(false)
                    }}
                    onOk={() => {
                        setShowAddContributorDialog(false)

                    }}
                    title={'Add Contributor'}
                    content={
                        <AddBoxsetContributorPanel boxset={boxset} />
                    }
                />

                <StandardOkCancelDialog
                    open={showAddBookDialog}
                    onClose={() => {
                        setShowAddBookDialog(false)
                    }}
                    onOk={() => {
                        setShowAddBookDialog(false)

                    }}
                    title={'Contribute Book'}
                    content={
                        <AddBoxsetBookPanel boxset={boxset} />
                    }
                />

                <StandardOkCancelDialog
                    open={showExportDialog}
                    onClose={() => {
                        setShowExportDialog(false)
                    }}
                    onOk={(e) => {
                        e.preventDefault();

                        let actualBuildTypes = [];
                        Object.keys(buildTypes).forEach((key) => {

                            if (buildTypes[key] == true) {
                                actualBuildTypes.push(key);
                            }
                        });

                        //console.log(actualBuildTypes);
                        requestbuildBatch({
                            variables: {
                                bookDraftId: bookDraftId,
                                //buildTypes: ["epub","nook"],
                                buildTypes: actualBuildTypes
                            },
                            refetchQueries: ['listUserBuildRequests']

                        });
                        // setShowExportDialog(false);
                    }}
                    title={'Export'}
                    content={
                        <ExportDialog bookDraftId={book?.primaryBookDraftId} />
                    }
                />

            </DashboardCard>
        </>


    );
}

export default inject('stores')(observer(OwnedBoxsetDetailMenuContent));

