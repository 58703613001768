import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import PeopleIcon from '@mui/icons-material/People';
import BookIcon from '@mui/icons-material/Book';
import EditIcon from '@mui/icons-material/Edit';
import CommentIcon from '@mui/icons-material/Comment';
import EditOffIcon from '@mui/icons-material/EditOff';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { Collapse, Box, Typography, Button, Stack } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar } from '@mui/material';
import { AvatarGroup } from '@mui/material';
import { StandardPaper } from '../StandardPaper';
import StandardButton from '../StandardButton';
import { visuallyHidden } from '@mui/utils';
import config from '../../aws-exports';
import ReactTimeAgo from 'react-time-ago';
import EmptyContent from '../EmptyContent';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StandardTypographyHeading2 from '../StandardTypographyHeading2';
import StandardTypographyHeading1 from '../StandardTypographyHeading1';




function Row(props) {
    const { row, columns } = props;
    const [showSnapshots, setShowSnapshots] = React.useState(false);
    const [showSharedWith, setShowSharedWith] = React.useState(false);
    const [columnVisibility, setColumnVisibility] = React.useState(null);
    const [open2, setOpen2] = React.useState(false);
    const [detailedRows, setDetailedRows] = React.useState([]);
    const [expandedRows, setExpandedRows] = React.useState([]);


    const theme = useTheme();

    let allContributedBoxSetBooks = [];

    useEffect(() => {

        let columnVisibility = {

        }
        columns.forEach((column, index) => {
            columnVisibility['column:' + index] = false;
        })

        setColumnVisibility(columnVisibility);
    }, []);

    // const getColumnVisibility = (index) => {
    //     return columnVisibility['column:' + index];
    // }

    // const toggleColumnVisibility = (index) => {
    //     columnVisibility['column:' + index] = !columnVisibility['column:' + index];
    //     setColumnVisibility(columnVisibility);
    // }

    //let detailedRows = [];

    // columns.map((column) => {

    //     return {
    //         visible:true
    //     }

    // });


    useEffect(() => {

        let detailedRows = columns.filter(f => !f.ignore).map((column) => {

            return false;

        });

        setDetailedRows(detailedRows);

    }, []);


    useEffect(() => {

        //console.log('updating...')
        let expandedRows = columns.filter(f => !f.ignore).map((column, innerIndex) => {
            if (column.expandable) {
                return <Box sx={{
                    marginLeft: '5em',
                    //width:'100%'
                }}>
                    {/* <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}> */}
                    <Collapse in={detailedRows[innerIndex]} timeoutx="auto" unmountOnExit>
                        <Box sx={{ marginx: 1 }}>
                            <Box sx={{
                                display: 'flex',
                                widthx: '100%',
                                justifyContent: 'space-between'
                            }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    {column.expandedTitle}
                                </Typography>


                                <StandardButton onClick={(e) => {
                                    e.preventDefault();
                                    detailedRows[innerIndex] = false;
                                    //console.log(detailedRows[innerIndex]);
                                    let newDetails = [].concat(detailedRows);
                                    setDetailedRows(newDetails);
                                }}>Close</StandardButton>

                            </Box>
                            {column.exoandedRowComponent(row)}

                        </Box>
                    </Collapse>
                    {/* </TableCell>
                    </TableRow> */}
                </Box>
            } else {
                return <div></div>;
            }

        })

        setExpandedRows(expandedRows);
    }, [detailedRows]);


    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                {columns.filter(f => !f.ignore).map((column, index) => {

                    if (column.expandable) {

                        return <TableCell
                            align={column.align}
                            onClick={(e) => {
                                //alert(index + ":" + getColumnVisibility(index));
                                e.preventDefault();
                                //toggleColumnVisibility(index);
                                detailedRows[index] = !detailedRows[index];
                                //console.log(detailedRows[index]);
                                let newDetails = [].concat(detailedRows);
                                setDetailedRows(newDetails);
                            }} >

                            {detailedRows[index] ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}

                        </TableCell>

                    }

                    else {

                        if (column.dataValueFunction) {
                            return <TableCell
                                align={column.align}
                                sx={{
                                    //border:'2px solid white',
                                    //border: '1px solid red',
                                    //width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'center'
                                }}><span style={{
                                    //width: '100%',
                                    //border: '1px solid blue'
                                }}><Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    //width: '100%',
                                    //border: '1px solid blue'
                                }}> {column.dataValueFunction(row)}</Box>
                                </span></TableCell>
                        } else {
                            return <TableCell align={column.align}><Avatar sxx={{ width: '1.5em', height: '1.5em' }} variant="square" alt="" src="https://www.adobe.com/express/create/cover/media_178ebed46ae02d6f3284c7886e9b28c5bb9046a02.jpeg?width=400&format=jpeg&optimize=medium" /></TableCell>
                        }

                    }

                })}

            </TableRow>
            {expandedRows}



        </React.Fragment>
    );
}

const StandardDataTable = (props) => {

    let { structure, order, onSetOrder, orderBy, onSetOrderBy, hideTitleBlock } = props;


    // const [order, setOrder] = React.useState('asc');
    // const [orderBy, setOrderBy] = React.useState('calories');

    const theme = useTheme();


    let dataDef = structure.dataDef ? structure.dataDef : [];
    let data = dataDef.data ? dataDef.data : [];


    let ownedBoxsets = [];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        onSetOrder(isAsc ? 'desc' : 'asc');
        onSetOrderBy(property);
    };

    function descendingComparator(a, b, orderBy) {
        try {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }

        } catch (err) {
            //do nothing
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const createSortHandler = (property, sortFieldNameOverride) => (event) => {
        let sortFieldName = property != null ? property : sortFieldNameOverride;
        handleRequestSort(event, sortFieldName);
    };

    return (
        <Box sx={{
            //marginTop: '1.75em',
            position: 'relative'
        }}>






            {/* {!hideTitleBlock &&
                <StandardPaper variant='elevation' elevation={3} sx={{
                    width: '15em',
                    height: '2em',
                    position: 'absolute',
                    top: '-1.5em',
                    left: '1em',
                    backgroundColor: theme.palette.feedback?.main,
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 999,
                    borderColor: theme.palette.feedback?.main,
                    color: theme.palette.text.main,

                }}>
                    <Typography
                        variant={theme.palette.standardTable.title.variant}
                        //color={theme.palette.text.main}

                        sx={{ color: theme.palette.secondary.main, }}
                    >{props.title}
                    </Typography>

                    <InfoOutlinedIcon sx={{
                        marginLeft: '1em',
                        cursor: 'pointer'
                    }} onClick={(e) => {
                        e.preventDefault();
                        props.stores.bookStore.showHelpDrawer = true;

                    }} />
                </StandardPaper>
            } */}
            <TableContainer component={Box}
                sx={{
                    borderRadius: '8px'
                }}>
                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    height: '100%',
                    marginTop: '1em',
                    marginLeft: '1em',
                    marginRight: '1em',
                    columns: '100',
                    //border:'1px solid red'
                }}>
                    <Box sx={{
                        //flex: 70
                    }}>
                        {/* <Typography
                            variant={theme.palette.text.heading1.variant}
                            sx={{ color: theme.palette.text.heading1.color }}>
                            {props.title}
                        </Typography> */}
                        <StandardTypographyHeading1>
                        {props.title}
                        </StandardTypographyHeading1>

                    </Box>
                    {props.headerActions}
                </Stack>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            {structure.columns.filter(f => !f.ignore).map((column) => {
                                // if(column.heading){
                                //     return <TableCell align="center">{column.heading}</TableCell>
                                // }
                                if (column.icon) {
                                    return <TableCell align={column.align}>
                                        <Box sx={{
                                            // backgroundColor: theme.palette.primary.main,
                                            widthx: '2.5em',
                                            heightx: '2.5em',
                                            display: 'flex',
                                            //justifyContent: 'center',
                                            borderRadius: '5px',
                                            alignItems: 'center'
                                        }}>{column.icon}</Box></TableCell>
                                } else if (column.sortable) {
                                    return <TableCell align={column.align}>
                                        {/* <Typography variant={'h6'}>{column.title}</Typography> */}
                                        <TableSortLabel
                                            active={orderBy === column.dataFieldName}
                                            direction={orderBy === column.dataFieldName ? order : 'asc'}
                                            onClick={createSortHandler(column.dataFieldName, column.sortFieldNameOverride)}>
                                            <Typography
                                                variant={theme.palette.text.heading2.variant}
                                                sx={{ color: theme.palette.text.heading2.color }}>
                                                {column.title}
                                            </Typography>
                                            {orderBy === column.dataFieldName ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                }
                                else {
                                    return <TableCell align={column.align}>
                                        <Box sx={{
                                            // backgroundColor: theme.palette.primary.main,
                                            widthx: '2.5em',
                                            heightx: '2.5em',
                                            display: 'flex',
                                            //justifyContent: 'center',
                                            borderRadius: '5px',
                                            //alignItems: 'center',
                                            //border: '1px solid red'
                                        }}>
                                            <StandardTypographyHeading2
                                                // variant={theme.palette.text.heading2.variant}
                                                // sx={{
                                                //     color: theme.palette.text.heading2.color,
                                                //     textOverflow: 'ellipsis',
                                                //     overflow: 'hidden'
                                                // }}
                                            >
                                                {column.title}
                                            </StandardTypographyHeading2>
                                        </Box>
                                    </TableCell>

                                }

                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody 
                    align='center'
                    >
                        {stableSort(dataDef.data, getComparator(order, orderBy))
                            //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                // const isItemSelected = isSelected(row.name);
                                // const labelId = `enhanced-table-checkbox-${index}`;

                                return <Row

                                    key={row[dataDef.rowKeyDataFieldName]}
                                    row={row}
                                    columns={structure.columns} />
                            })
                        }
                    </TableBody>
                    {/* // {dataDef.data.map((row) => (
                        //     <Row key={data[dataDef.rowKeyDataFieldName]} row={row} columns={structure.columns} />
                        // ))}
                    */}

                </Table>
            </TableContainer>
            {(!props.loading && (dataDef.data == null || dataDef.data.length == 0)) &&
                <Box sx={{
                    marginTop: '3em'
                }}>

                    <EmptyContent tagline={props.noDataMessage} />
                </Box>
            }

        </Box>
    )
}
export default inject('stores')(observer(StandardDataTable));