import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import config from '../../aws-exports';
import useMediaQuery from '@mui/material/useMediaQuery';
import StretchBox from '../boilerPlate/StretchBox';
import { Box, Button, SwipeableDrawer, Typography } from '@mui/material';
import ScrollBox from '../boilerPlate/ScrollBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import ListSentRequests from './lists/ListSentRequests';
import StandardUICard from '../UI/StandardUICard';
import EditorEditRequestController from '../editing/EditorEditRequestController';


const SentEditRequests = (props) => {

    let { book, boxset, onSelectBoxset } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdAt');


    let [selectedEditRequest, setSelectedEditRequest] = useState(null);
    const [showDetailDrawer, setShowDetailDrawer] = useState(false);

    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setShowDetailDrawer(open);
            };


    return (

        <StretchBox flexDirection='row' sxProps={{ gap: isSmallScreen ? 0 : 2 }}>

            <StretchBox sxProps={{
                flex: isSmallScreen ? 12 : 4,
                position: 'relative'
            }}>
                {/* <BoxsetsTable onSelectBoxSet={
                    (boxset) => {
                        setSelectedBoxset(boxset)
                        setShowDetailDrawer(true)
                    }} /> */}

                <ScrollBox>
                    {/* NOT COMPLETED */}
                    <StandardUICard
                        title='Sent Requests'
                        showPagination={false}
                        showSearchField={true}
                        children={
                            <ListSentRequests
                                selectedEditRequest={selectedEditRequest}
                                order={order}
                                orderBy={orderBy}
                                onSelectRequest={(editRequest) => {
                                    setSelectedEditRequest(editRequest)
                                    setShowDetailDrawer(true)
                                }}
                            />
                        }
                    />


                </ScrollBox>



            </StretchBox>

            <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>


                    <StandardUICard
                        title='Details'
                        showPagination={false}
                        showSearchField={false}
                        // actions={
                        //     <Link underline='none' color='blue'
                        //         onClick={handleMoreDetailOpen}>
                        //         More
                        //     </Link>
                        // }
                        children={
                            <div>Details</div>
                        }
                    />
                    <StandardUICard
                        title='Timeline'
                        showPagination={false}
                        showSearchField={false}
                        // actions={
                        //     <Link underline='none' color='blue'
                        //         onClick={handleMoreDetailOpen}>
                        //         More
                        //     </Link>
                        // }
                        children={
                            selectedEditRequest && <EditorEditRequestController
                                editRequest={selectedEditRequest}
                            //showOriginal={showOriginal} 
                            />
                        }
                    />


                </ScrollBox>
            </StretchBox>




            <SwipeableDrawer
                anchor={'right'}
                open={showDetailDrawer && isSmallScreen}
                onClose={toggleDrawer(false)}
                sx={{ width: '100%' }}
            //variant='persistent'
            //onOpen={setShowDetailDrawer(true)}
            >
                <div style={{ width: '100vw' }}>

                    <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                        <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2, ml: 0, mr: 0 }}>
                            <IconButton
                                style={{ display: 'flex', justifyContent: 'left' }}
                                onClick={() => {
                                    setShowDetailDrawer(false)
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography
                                variant={theme.landmarkText.variant}
                                gutterBottom
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                Book Title
                            </Typography>

                            <div>Right mobile side</div>

                        </ScrollBox>
                    </StretchBox>
                </div>
            </SwipeableDrawer>


        </StretchBox>
    )
}
export default inject('stores')(observer(SentEditRequests));