import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Divider, Grid, Stack, styled, Typography } from "@mui/material";
import { Paper } from "@mui/material";
import StandardDashboardPaper from "../StandardPaper";
import { StandardPaper } from "../StandardPaper";
import { useTheme } from '@mui/material/styles';
import { useGetOwnedBooks } from '../../managers/BooksManager';
import StandardBookCoverTitle from "../StandardBookCoverTitle";
import BookListItem from "../lists/BookListItem";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

const FeedbackCardContent = (props) => {
//export default function FeedbackCardContent(props) {

    const { ownedBooks, error } = useGetOwnedBooks(props);


    const theme = useTheme();

    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
    );
    
     const options = {
      indexAxis: 'y' ,
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      scales: {
        xAxes: {
          gridLines: {
            display: false ,
            color: "#FFFFFF"
          },
        }
      },
      plugins: {
        legend: {
          position: 'right' ,
          labels: {
            font: {
              color: '#ffffff'
            }
          }
        },
        title: {
          display: true,
          text: 'Comments by Book',
        },
      },
    };
      
      const labels = ['Book 1', 'Book 2', 'Book 3', 'Book 4'];
      
    const data = {
        labels,
        datasets: [
          {
            label: 'Book 1',
            data:  [500,1200,601,300,450,200,250],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Book 2',
            data:  [600,1300,401,500,650,300,150],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };

    return (

        <Box>
          <Bar options={options} data={data} />
        </Box>
    );
}

export default inject('stores')(observer(FeedbackCardContent));
