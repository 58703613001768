import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography, Popover } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import ReactTimeAgo from 'react-time-ago';
import EmptyContent from "../../EmptyContent";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useManagerListConnectedUserBookShares } from '../../../managers/ConnectionsManager';
import { useTheme } from '@mui/material/styles';
import ShareIcon from '@mui/icons-material/Share';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';



const ConnectionSharedToCard = (props) => {


    let { userConnection, showRemoveIcon = true } = props;

    const { sharedBooks, loading: listConnectedUserBookSharesLoading, error: listConnectedUserBookSharesError } = useManagerListConnectedUserBookShares(props, userConnection?.connectionUserId);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');



    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();




    const generateSecondaryActions = (book) => {

        let secondaryActions = [];

        if (props.showSelectIcon) {
            secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
                //console.log(book)
                props.onSelected(book);
            }}>
                <ShareIcon />
            </IconButton>);
        }

        if (showRemoveIcon) {
            secondaryActions.push(<IconButton
                onClick={(e) => {
                    e.stopPropagation();
                }}>
                <RemoveCircleOutlineIcon />
            </IconButton>)
        }

        return secondaryActions;
    }


    return (
        <List>
            {sharedBooks && sharedBooks.length > 0 ?
                sharedBooks.map((book) => {
                    return <ListItem
                        sx={{ padding: ' 0.25em' }}
                        secondaryAction={
                            generateSecondaryActions(book)
                        }
                    >
                        <ListItemAvatar>
                            <Avatar variant='rounded'
                                bucketName={config.user_uploads_bucket_name}
                                s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + book?.coverUrl}
                                >
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={book?.title}
                            secondary='shared since'
                        />
                    </ListItem>
                })
                :
                <EmptyContent tagline={'Share your book!'} />
            }
        </List>
    )
}

export default inject('stores')(observer(ConnectionSharedToCard));