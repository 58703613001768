import Quill from 'quill';
//import Parchment from 'parchment';
import DeltaToDom from './DeltaToDom';
import { v4 as uuid } from 'uuid';
const Parchment = Quill.import('parchment');
var $ = require("jquery");

let BlockEmbed = Quill.import('blots/block/embed');


function createContent(node, args) {

  let converter = new DeltaToDom();
  /*let html = converter.convertBlurb(args);
  node.innerHTML = html;*/

  let domNode = converter.convertBlurbs(args, node);
  node.appendChild(domNode);


  /*let addBlurbButton = document.getElementById('addBlurbBtn');
  addBlurbButton.addEventListener('click', function(ev) {

    ////console.log(this);

  })*/

}

export default class BlurbsBlot extends BlockEmbed {
  static create(args) {
    let node = super.create();
    node.dataset.uuid = args.uuid;
    node.dataset.blurbs = args.blurbs;
    ////console.log(node);
    // Allow twitter library to modify our contents
    //twttr.widgets.createTweet(id, node);
    createContent(node, args);
    return node;
  }

  static value(domNode) {
    let value = {};
    value.uuid = domNode.dataset.uuid;
    value.blurbs = domNode.dataset.blurbs;//'{blurbs:[{name:"bob"}]}'
    return value;
  }


  getContributors() {


    let blurbs = JSON.parse(this.domNode.dataset.blurbs);


    return blurbs.records;

  }

  deleteContributor(id) {



    //console.log(id);

    let blurbs = JSON.parse(this.domNode.dataset.blurbs);

    let blurbsToKeep = blurbs.records.filter(f => f.item.id != id);

    blurbs.records = blurbsToKeep;

    let count = 0;
    blurbs.records.forEach((contributor) => {
      count++;
      contributor.item.orderNumber = count;

    });


    let blurbsString = JSON.stringify(blurbs);
    ////console.log(blurbsString);
    this.domNode.dataset.blurbs = blurbsString;
    ////console.log('end')

    return blurbs;

  }

  addContributor() {

    //console.log(this.domNode.dataset.blurbs);

    let blurbs = JSON.parse(this.domNode.dataset.blurbs);

    let newContributor = {
      item: {
        id: uuid(),
        orderNumber: blurbs.records.length + 1,
        comment: 'A great book!',
        source: 'Top Fan'
      }
    };

    blurbs.records.push(newContributor)


    let blurbsString = JSON.stringify(blurbs);
    ////console.log(blurbsString);
    this.domNode.dataset.blurbs = blurbsString;
    ////console.log('end')

    return newContributor;

  }

  updateContributor(contributorJson) {
    ////console.log(contributorJson);
    ////console.log(this.domNode.dataset.blurbs);

    let blurbs = JSON.parse(this.domNode.dataset.blurbs);

    let contributor = blurbs.records.filter(f => f.item.id == contributorJson.id)[0];
    contributor.item.comment = contributorJson.comment;
    contributor.item.source = contributorJson.source;

    let blurbsString = JSON.stringify(blurbs);
    ////console.log(blurbsString);
    this.domNode.dataset.blurbs = blurbsString;
    ////console.log('end')
    /*if(this.domNode.dataset.editorPinned && this.domNode.dataset.editorPinned=='true'){
      this.domNode.dataset.editorPinned='false'
    }else{
      this.domNode.dataset.editorPinned='true'
    }
    ////console.log(this.domNode.dataset.editorPinned);*/
  }


  /*deleteAt(index,length) {

    //let textBlot = Parchment.create('text', 'Test');
    //textBlot.insertAt(index, 'ough');
    return false;
  }*/

  deleteAt() {
    return false
  }


  move(old_index, new_index) {

    let blurbs = JSON.parse(this.domNode.dataset.blurbs);

    let arr = blurbs.records;
    //console.log(arr);

    if (new_index >= arr.length) {
      return blurbs;
    }

    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length;
      while ((k--) + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    let count = 0;
    arr.forEach((contributor) => {
      count++;
      contributor.item.orderNumber = count;

    });

    blurbs.records = arr;
    return blurbs;
  }


}
BlurbsBlot.blotName = 'blurbs';
BlurbsBlot.tagName = 'div';
BlurbsBlot.className = 'blurbs';