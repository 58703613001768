import {
    observable,
    action,
    computed,
    runInAction,
    extendObservable,
    makeObservable,
} from 'mobx';

import { User } from '../domain/bookModel';

class UserStore {
	
    loggedIn = false;
    user = null;
    constructor() {
        makeObservable(this, {
            user: observable

        });
    }

    setUser = (user) => {
        this.user = observable(user);
    }

    reset = () => {
        this.user = null;
        this.loggedIn = false;
    }

    
}

export default UserStore;