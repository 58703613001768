import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import { useGetUserConnections, useGetUserGroups } from '../../../managers/ConnectionsManager';
import ShareIcon from '@mui/icons-material/Share';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Avatar from '@mui/material/Avatar';
import helpers from "../../../helpers/helpers";
import EmptyContent from "../../EmptyContent";


const ListOwnedGroups = (props) => {

    let user = props.stores.userStore.user;

    let { filteredUserGroups, showShareIcon = true, selectedUserGroup, order='desc', orderBy='createdAt' } = props;
    //const { userConnections, error: getUserConnectionsError } = useGetUserConnections(props);
    const { userGroups, error: getUserUserGroupsError, loading: getUserUserGroupsLoading } = useGetUserGroups(props);


    let remainingUserGroups = userGroups;

    if (filteredUserGroups) {

        remainingUserGroups = userGroups.filter(userGroup => !filteredUserGroups.find(rm => (rm.userGroup.id === userGroup.id)))

    }


    remainingUserGroups = helpers.stableSort(remainingUserGroups, order, orderBy);





    const generateSecondaryActions = (userGroup) => {

        let secondaryActions = [];

        if (showShareIcon) {
            secondaryActions.push(<IconButton
                onClick={(e) => {
                    e.preventDefault();
                    props.onSelectGroup(userGroup);
                }}>
                <ShareIcon />
            </IconButton>)
        }
        return secondaryActions;
    }






    return (<List sx={{ width: '100%' }}>
        {remainingUserGroups && remainingUserGroups.length > 0 ?
            remainingUserGroups.map((userGroup, index) => {


                //console.log(index);
                let selectedIndex = null;
                if (userGroup.id == selectedUserGroup?.id) {
                    selectedIndex = index;
                }


                return <ListItem
                selected={selectedIndex===index}
                    sx={{ padding: ' 0.25em' }}
                    secondaryAction={
                        generateSecondaryActions(userGroup)
                    }
                >
                    <ListItemAvatar>
                        <Avatar
                            bucketName={config.user_uploads_bucket_name}
                            s3Src={userGroup?.profilePicUrl}
                        >
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={userGroup?.name}
                        secondary={props.secondary}
                        onClick={(e) => {
                            e.preventDefault();
                            props.onListSelectGroup(userGroup);
                        }}
                    />

                </ListItem>
            })
            :
            <EmptyContent tagline={'Create your first group!'} />
       
        }
    </List>

    )
}

export default inject('stores')(observer(ListOwnedGroups));