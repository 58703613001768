import { styled } from "@mui/material";
import {Typography} from "@mui/material";
import { useTheme } from '@mui/material/styles';


export const StandardStyledTypography = styled(Typography)(({theme}) => ({

    // background: 'transparent',
    // boxShadow: 'none',
    // //color: "#d75b51",
    // "& button.Mui-selected": {
        
    //     color: theme.palette.books?.main
    //   },

    color: theme.palette.text.content1.color,
    textOverflow: 'ellipsis',
    overflow: 'hidden'

}));


export default function StandardTypographyHeading1(props) {

    const theme = useTheme();

    return (

        <StandardStyledTypography
            variant={theme.palette.text.content1.variant}
            //textColor={theme.palette.books?.main} 
            {...props}>
        </StandardStyledTypography>

    );
}

