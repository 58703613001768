import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import StandardTabs from "../../StandardTabs";
import { useManagerCreateInvitation, useGetUserConnections, useGetUserGroups } from '../../../managers/ConnectionsManager';
import ShareIcon from '@mui/icons-material/Share';
import UserConnectionsList from "../../lists/UserConnectionsList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../../managers/BooksManager';
import UserGroupsList from "../../lists/UserGroupsList";
import StandardUITextField from "../../UI/StandardUITextField";
import StandardUIButton from "../../UI/StandardUIButton";
import StandardUIDialog from "../../UI/StandardUIDialog";
import ListConnections from "../lists/ListConnections";
import ListOwnedGroups from "../lists/ListOwnedGroups";
import StandardUITabs from "../../UI/StandardUITabs";
import StretchBox from '../../boilerPlate/StretchBox';
import ScrollBox from '../../boilerPlate/ScrollBox';
import InviteConnection from "./InviteConnection";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


const OwnedBookShareBook = (props) => {

    let user = props.stores.userStore.user;
    let { book, selectedUserConnection, selectedUserGroup } = props;

    const { userConnections, error: getUserConnectionsError } = useGetUserConnections(props);
    const { userGroups, error: getUserUserGroupsError, loading: getUserUserGroupsLoading } = useGetUserGroups(props);
    const { sharedWithUsers, bookUserSharesLoading, bookUserSharesError, sharedWithUserGroups, bookUserGroupSharesLoading, bookUserGroupSharesError } = useGetBookShares(props, book);
    const { shareBookWithUser, loading: shareBookLoading, error: sshareBookError } = useShareBookWithUser(props);
    const { shareBookWithGroup, loading: shareBookWithUserGroupLoading, error: shareBookWithUserGroupError } = useShareBookWithGroup(props);
    let [showInviteDialog, setShowInviteDialog] = useState(false);
    let [name, setName] = useState(null);
    let [email, setEmail] = useState(null);

    const { createInvitation, data: createInvitationData, loading: createInvitationLoading, error: createInvitationError } = useManagerCreateInvitation();

    const handleNameChange = (e) => {
        e.preventDefault();

        setName(e.target.value);
    }

    const handleEmailChange = (e) => {
        e.preventDefault();

        setEmail(e.target.value);
    }

    let [selectedTab, setSelectedTab] = useState(0);


    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    return (

        <StandardUIDialog
            open={props.open}
            onClose={props.onClose}
            book={props.book}
            title='Share Book'
            dialogContent={
                    <Box>
                        <StandardUITabs value={selectedTab} onChange={handleChange}>
                            <Tab label="Connections" {...a11yProps(0)} />
                            <Tab label="Groups" {...a11yProps(1)} />
                            <Tab label="Invite" {...a11yProps(2)} />
                        </StandardUITabs>
                        {selectedTab == 0 &&
                            <div>
                                <ListConnections
                                    selectedUserConnection={selectedUserConnection}
                                    filteredConnections={sharedWithUsers.map(m => m.sharedWithUser)}
                                    showShareIcon
                                    onSelectUser={(userConnection) => {
                                        if (userConnection) {
                                            shareBookWithUser({
                                                bookId: book.id,
                                                userId: userConnection.connectedUser.id
                                            });
                                        }
                                    }}

                                />
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {shareBookLoading && <CircularProgress />}
                                </div>
                            </div>
                        }
                        {selectedTab == 1 &&
                            <div>
                                <ListOwnedGroups
                                    selectedUserGroup={selectedUserGroup}
                                    filteredUserGroups={sharedWithUserGroups}
                                    showShareIcon
                                    onSelectGroup={(userGroup) => {
                                        if (userGroup) {
                                            shareBookWithGroup({
                                                bookId: book.id,
                                                userGroupId: userGroup.id
                                            });
                                        }
                                    }}

                                />
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {shareBookWithUserGroupLoading && <CircularProgress />}
                                </div>

                            </div>
                        }
                        {selectedTab == 2 &&
                            <div>
                                <InviteConnection />
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {createInvitationLoading && <CircularProgress />}
                                </div>

                            </div>
                        }
                    </Box>
            }
        />

    )
}

export default inject('stores')(observer(OwnedBookShareBook));