import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import PushPinIcon from '@mui/icons-material/PushPin';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import Avatar from '@mui/material/Avatar';
import { useUpdateItem } from '../../persistance/persistanceHooks';
import ReactTimeAgo from 'react-time-ago';
import { ApolloConsumer } from '@apollo/client';
import { UserComment as UserCommentPojo } from '../../domain/bookModel';
import IconButton from '@mui/material/IconButton';
import config from '../../aws-exports';
import { Box, Typography } from '@mui/material';
import StandardRoundedAction from '../actions/StandardRoundedAction';
import Feather from 'mdi-material-ui/Feather';
import { useTheme } from '@mui/material/styles';
import { useOpenCommentInWrite } from '../../managers/BooksManager';
import ImageTitleTableCell from '../table/ImageTitleTableCell';
import StandardImageViewer from '../StandardImageViewer';

var $ = require("jquery");

const UserNote = (props) => {

    const [updateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] = useUpdateItem();
    const { openCommentInWrite } = useOpenCommentInWrite(props);

    let userCommentPojo = null;

    try {

        userCommentPojo = new UserCommentPojo({ data: props.userComment });

    } catch (err) {
        console.log(err);
    }

    const theme = useTheme();

    let completionIcon = <CheckCircleOutlineIcon sx={{
        color: theme.palette.secondary.main
    }} />

    if (!userCommentPojo?.completedAt) {
        // completionIcon = <CheckCircleOutlineIcon sx={{
        //     color: 'lightgray'
        // }} />
        completionIcon = <PendingOutlinedIcon sx={{
            color: 'lightgray'
        }} />
    }


    const scrollToComment = ({
        blotId
    }) => {
        try {

            let node = $("div").find("[data-uuid='" + blotId + "']")[0];
            node.scrollIntoView({
                behavior: "smooth", // or "auto" or "instant"
                block: "end" // or "start" --> dont know why, but using "start" caused a horizontal scrollbar to appear ( which cause the toolbar to be clipped at the top )
            });
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <ApolloConsumer>
            {(client) => (
                <div style={{
                    width: '100%',
                    backgroundColor: 'tan', 
                    border: 'thin white solid',
                    // borderRadius: '8px',
                    padding: '0.5em',
                    marginBottom: '0.5em'
                }}>

                    <div style={{
                        positionx: 'unset',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            textAlign: 'left',
                            //border:'1px solid red',
                            width: '100%'
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                textAlign: 'left',
                                //border: '1px solid red',
                            }} onClick={() => {

                                scrollToComment({
                                    blotId: userCommentPojo?.id
                                })

                            }}>
                               
                               

                            </div>

                           
                           
                           
                           


                        </div>
                        <div style={{
                            textAlign: 'left',
                            paddingLeft: '2.25em',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            width: '100%',
                            alignItems: 'center'
                        }}
                        >
                            <Typography variant="body2" noWrap={true}
                                sx={{
                                    // 'white-space': 'nowrap',
                                    // //'border': '1px solid red',
                                    // 'width': '100%',
                                    // //'width': theme.components.standardTree.nodes.width,
                                    // 'display': 'block',
                                    // 'overflow': 'hidden',
                                    // 'text-overflow': 'ellipsis',
                                    'color': 'black',
                                    'text-overflow': 'ellipsis',
                                    'overflow': 'hidden',
                                    // Addition lines for 2 line or multiline ellipsis
                                    'display': '-webkit-box !important',
                                    '-webkit-line-clamp': '2',
                                    '-webkit-box-orient': 'vertical',
                                    'white-space': 'normal',
                                }}>
                                {userCommentPojo?.comment}
                            </Typography>

                        </div>
                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between'

                        }}>
                           
                                <IconButton>
                                    <PushPinIcon sx={{
                                        color: userCommentPojo?.isPinned ? theme.palette.secondary.main : 'lightgray'
                                    }}
                                        onClick={(e) => {
                                            e.preventDefault();

                                            //console.log(userCommentPojo?.isPinned);
                                            updateItem({
                                                itemExpectedVersion: 1,
                                                itemKey: userCommentPojo?.id,
                                                //itemSortKeyName: 'documentPartId',
                                                //itemSortKeyValue: userCommentPojo?.documentPartId,
                                                itemType: 'UserComment',
                                                fieldName: 'isPinned',
                                                fieldValue: !userCommentPojo?.isPinned
                                            }
                                                , () => {
                                                    client.cache.modify({
                                                        //broadcast: false,
                                                        id: client.cache.identify(userCommentPojo),
                                                        fields: {
                                                            isPinned(value) {
                                                                return !userCommentPojo?.isPinned;
                                                            },
                                                        },
                                                        /* broadcast: false // Include this to prevent automatic query refresh */
                                                    });


                                                    //userCommentPojo?.isFavorite = !userCommentPojo?.isFavorite
                                                }


                                            )


                                        }} />
                                </IconButton>
                            
                            
                            {props.ownerMode &&
                                <IconButton onClick={(e) => {
                                    e.preventDefault();

                                    //console.log(userCommentPojo?.isFavorite);
                                    updateItem({
                                        itemExpectedVersion: 1,
                                        itemKey: userCommentPojo?.id,
                                        //itemSortKeyName: 'documentPartId',
                                        //itemSortKeyValue: userCommentPojo?.documentPartId,
                                        itemType: 'UserComment',
                                        fieldName: 'completedAt',
                                        fieldValue: userCommentPojo?.completedAt != null ? 'null' : 'now',

                                    }, () => {


                                        client.cache.modify({
                                            //broadcast: false,
                                            id: client.cache.identify(userCommentPojo),
                                            fields: {
                                                completedAt(value) {
                                                    return userCommentPojo?.completedAt == null ? Date.now() : null;
                                                },
                                            },
                                            /* broadcast: false // Include this to prevent automatic query refresh */
                                        });


                                        //userCommentPojo?.isFavorite = !userCommentPojo?.isFavorite
                                    })


                                }}>



                                    {completionIcon}
                                </IconButton>
                            }
                            <IconButton onClick={(e) => {
                                e.preventDefault();
                                //console.log(userCommentPojo?.isFavorite);

                                
                                updateItem({
                                    itemExpectedVersion: 1,
                                    itemKey: userCommentPojo?.id,
                                    //itemSortKeyName: 'documentPartId',
                                    //itemSortKeyValue: userCommentPojo?.documentPartId,
                                    itemType: 'UserComment',
                                    fieldName: 'deletedAt',
                                    fieldValue: 'now',
                                    refetchQueries: ['listAllBookDraftNotes']

                                }, () => {

                                    //props.stores.bookStore.userComments.replace(props.stores.bookStore.userComments.filter(f => f.id != userCommentPojo?.id))
                                    //props.stores.bookStore.userCommentsTimestamp = Date.now();
                                    client.cache.modify({
                                        //broadcast: false,
                                        id: client.cache.identify(userCommentPojo),
                                        fields: {
                                            deletedAt(value) {
                                                return userCommentPojo?.deletedAt == null ? Date.now() : null;
                                            },
                                        },
                                        /* broadcast: false // Include this to prevent automatic query refresh */
                                    });

                                })


                            }}>
                                <HighlightOffIcon sx={{
                                    color: 'lightgray'
                                }} />
                            </IconButton>
                        </Box>
                        <Typography style={{ color: 'black' }}>{userCommentPojo && <ReactTimeAgo date={userCommentPojo?.createdAt} locale="en-US" />}</Typography>

                    </div>
                </div>
            )}
        </ApolloConsumer>
    )


};


export default inject('stores')(observer(UserNote));