import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import EditRequestsPanel from '../../panels/editing/EditRequestsPanel';

const WriteDetailsEditRequests = (props) => {

    const theme = useTheme();

    let { bookDraftDocumentPart,bookDraft} = props;

    let userId = props.stores?.bookStore.userId;

 


    return (    
        <Box container direction={'column'} sx={{
            justifyContent: 'center',
            //marginRight:'1em'
        }}>

         
          {/* originalBookDraft={originalBookDraft}
          editBookDraft={editingBookDraft}
          showOriginal={showOriginal}
          onToggleLoadOriginal */}


          <EditRequestsPanel
            book={bookDraft?.book}
            editingBookDraft={props.editingBookDraft}
            documentPart={bookDraftDocumentPart?.documentPart}
            editRequest={props.stores.bookStore.selectedSentEditRequest}
        />
           
        </Box>
    );
};


export default inject('stores')(observer(WriteDetailsEditRequests));
