import React, { useEffect, useState } from 'react';
import { styled, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import BaseControl from "./BaseControl";
import { useTheme } from '@mui/material/styles';
import { Box, Checkbox } from "@mui/material";
import { v4 as uuid } from "uuid";

function FormatHeadingSourceToggleButtons(props) {

  const theme = useTheme();
  let baseControl = new BaseControl(props);

  const { classes, element, bookDraft, documentPart } = props;
  const [headingSourceType, setHeadingSourceType] = React.useState(null);

  let headerTemplateSourceValue = null;

  try {
    headerTemplateSourceValue = baseControl.getRecipeParamByName('global_headerTemplateSourceFor---PART_TYPE---s').value
  } catch (err) {
    console.log(err);
  }


  useEffect(() => {

    try {

      let recipeParamName = 'global_headerTemplateSourceFor---PART_TYPE---s';
      let headerTemplateSourceParam = baseControl.getRecipeParamByName(recipeParamName);

      setHeadingSourceType(headerTemplateSourceParam.value);


    } catch (err) {
      console.log(err);
    }
  }, [documentPart, headerTemplateSourceValue])


  let isVisible = baseControl.isVisible();
  if (!isVisible) {
    return <div style={{
      height: '0em'
    }}></div>
  }

  let item = documentPart;
  let properties_book = bookDraft.book;
  let boxSet = bookDraft.book.boxSet;

  let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();
  let hideBookProperties = false;

  let canEdit = true;


  let isOwner = true;


  if (element.paramItem == 'BOOK') {
    item = bookDraft.book;
  }
  if (element.paramItem == 'BOXSET_MAIN_BOOK') {
    item = boxSet.book;
  }
  if (element.paramItem == 'BOXSET_BOOK') {
    item = boxSetBook.book;
  }
  if (element.paramItem == 'BOXSETBOOK') {
    item = boxSetBook;
  }

  //console.log('gonna call it...')

  let checked = baseControl.getIsChecked();

  //The following is necessary to stop the MuiCheckbox from defaulting to uncontrolled mode
  //if it is initilized with a null or undefined value. 
  if (checked == null || checked == undefined) {

    checked = false;
  }


  let title = baseControl.convertTitle(element.title, documentPart);

  const label = { inputProps: { 'aria-label': title } };

  const handleChangeHeadingSource = (event, value) => {
    setHeadingSourceType(value);

    let currentRecipe = props.documentPart?.getRecipe();

    let recipeParamName = 'global_headerTemplateSourceFor---PART_TYPE---s';
    recipeParamName = baseControl.convertTitle(recipeParamName, props.documentPart);

    let recipeParam = baseControl.getRecipeParamByName(recipeParamName);

    recipeParam.value = value;

    setTimeout(() => {

      props.stores.bookStore.recipeTimestamp = new Date().getTime();

      if (props.onUpdateRecipe) {
        props.onUpdateRecipe({
          recipe: currentRecipe,
          paramName: recipeParamName,
          paramValue: value
        })
      }

    }, 10);




  };



  let hasHeadingSourceType = true;
  if (!documentPart ||
    documentPart?.partType.toLowerCase() == 'topbook' ||
    documentPart?.partType.toLowerCase() == 'book' ||
    documentPart?.partType.toLowerCase() == 'copyright' ||
    documentPart?.partType.toLowerCase() == 'toc' ||
    documentPart?.partType.toLowerCase() == 'fullpageimage') {
    hasHeadingSourceType = false;
  }

  return (

    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flex: 12
    }}>
      {hasHeadingSourceType &&
        <>
          <ToggleButtonGroup
            size="small"
            value={headingSourceType}
            exclusive
            onChange={handleChangeHeadingSource}
            aria-label="text alignment"
          >
            <ToggleButton value="common" aria-label="">

            Book Default
            </ToggleButton>
            <ToggleButton value="partTypeSpecific" aria-label="">

            {baseControl.convertTitle(" ---PART_TYPE--- SPECIFIC",documentPart)}
            </ToggleButton>
          </ToggleButtonGroup>

          {/* convertTitle(title, documentPart){
		
		try{
			let newTitle =  title.replace(/---PART_TYPE---/gi, this.toCappedPartType(documentPart))
			newTitle = newTitle.replace(/---PART_TYPE_LOWER---/gi, documentPart.partType.toLowerCase());
			
			return newTitle;
		}catch(err){

		}
		
	} */}



          {(headerTemplateSourceValue && headerTemplateSourceValue === 'common') &&
            <Typography variant={'h7'} color={'gray'}>
              {baseControl.convertTitle("Using the book\'s default heading template for all ---PART_TYPE_LOWER---s.",documentPart)}</Typography>
          }
          {(headerTemplateSourceValue && headerTemplateSourceValue === 'partTypeSpecific') &&
            <Typography variant={'h7'} color={'gray'}>{baseControl.convertTitle("Using a unique heading template for all ---PART_TYPE_LOWER--- 's",documentPart)}</Typography>
          }
        </>
      }

    </Box>


  );
}


export default inject('stores')(observer(FormatHeadingSourceToggleButtons));



