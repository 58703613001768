import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditRequestHistory from './EditRequestHistory';
import StandardButton from '../StandardButton';
// import StandardOKCancelDialogLaura from '../dialogs/StandardOKCancelDialogLaura';
// import StandardLabel from '../StandardLabel';
import { useAcceptEditRequest } from '../../persistance/persistanceHooks';
import { useUpdateEditRequestStatus } from '../../persistance/persistanceHooks';
//import ConnectionsListItem from '../ConnectionsListItem';
import Avatar from '@mui/material/Avatar';
import config from '../../aws-exports';
import Image from 'mui-image'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const EditorEditRequestController = (props) => {

	let { editRequest = {}, showOriginal, hideHeading=false } = props;
	//console.log(editRequest);

	let user = props.stores.userStore.user;
	let isEditor = false;

	if (user.id == editRequest.editorUserId) {
		isEditor = true;
	}

	const [expanded, setExpanded] = React.useState(false);
	const [showAcceptDialog, setShowAcceptDialog] = React.useState(false);

	const [showCompleteDialog, setShowCompleteDialog] = React.useState(false);



	const [acceptEditRequest, { data: acceptEditRequestData, loading: acceptEditRequestLoading, error: acceptEditRequestError }] = useAcceptEditRequest();
	const [updateEditRequestStatus, { data: updateEditRequestStatusData, loading: updateEditRequestStatusLoading, error: updateEditRequestStatusError }] = useUpdateEditRequestStatus();

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};


	//console.log(editRequest);

	let profilePicSrc = 'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + editRequest?.editorUser?.profilePicUrl;
	let profileTitle = 'Editor: ' + editRequest?.editorUser?.name;

	if (editRequest?.editorUser?.id == user.id) { //profilePicUrl
		profilePicSrc = 'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + editRequest?.requestorUser?.profilePicUrl;
		profileTitle = 'Author: ' + editRequest?.requestorUser?.name;
	}

	return (


		<div expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{
			width: '100%'
		}}>
			{/* <div>isEditor:{isEditor ? 'true' : 'false'}</div> */}

			{!hideHeading &&
				<div
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
				>
					<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
						<Image
							width={'3em'}
							className="custom-class"
							src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + editRequest?.book?.coverUrl}

							showLoading
							errorIcon />

						<Avatar
							sx={{
								marginLeft: '0.5em',
								marginRight: '0.5em',
								width: '3em',
								height: '3em'
							}}
							alt=''
							src={profilePicSrc} />


						<div style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-start',
							alignItems: 'flex-start'
						}}>
							<Typography sx={{ flexShrink: 0 }} variant='h6'>
								{editRequest?.book?.title}:{showOriginal ? 'ORIGINAL COPY' : 'EDITING COPY'}
							</Typography>
							<Typography sx={{ flexShrink: 0 }} variant='subtitle1'>
								{profileTitle}
							</Typography>

						</div>

					</div>
				</div>
			}
			<div>
				<EditRequestHistory editRequest={editRequest} />
				{/* <div style={{
					display: 'flex'
				}}>
					<div style={{
						display: 'flex'
					}}>
						{!editRequest?.acceptedAt && !editRequest?.editorDeletedAt &&
							<StandardButton onClick={() => {
								
								
								setShowAcceptDialog(true);
							}} buttonAction={'Accept'} >Accept</StandardButton>

						}
						{!editRequest.acceptedAt &&
							!editRequest.rejectedAt &&
							!editRequest.editorDeletedAt &&
							!editRequest.completedAt &&
							<StandardButton color='primary' onClick={(e) => {
								e.stopPropagation();
								
								


							}} buttonAction={'Decline'}>Decline</StandardButton>
						}
						{editRequest.acceptedAt &&
							!editRequest.rejectedAt &&
							!editRequest.editorDeletedAt &&
							!editRequest.completedAt &&
							<StandardButton
								color='primary' onClick={(e) => {
									e.stopPropagation();


									
									setShowCompleteDialog(true);
								}} buttonAction={'Complete'}>Complete</StandardButton>
						}
						{editRequest.acceptedAt &&
							!editRequest.rejectedAt &&
							!editRequest.editorDeletedAt &&
							!editRequest.completedAt &&
							<StandardButton
								color='primary' onClick={(e) => {
									e.stopPropagation();

								
									

								}} buttonAction={'Cancel'}>Cancel</StandardButton>
						}
						{
							
							(editRequest.acceptedAt &&
								!editRequest.editorDeletedAt &&
								(!editRequest.editorCanceledAt || !editRequest.completedAt))

						
							|| !editRequest.acceptedAt &&

							<StandardButton
								color='primary' onClick={(e) => {
									e.stopPropagation();

									

								}} buttonAction={'Delete'}>Delete</StandardButton>
						}





					</div>
				</div> */}
			</div>


		</div>
	);
};


export default inject('stores')(observer(EditorEditRequestController));