import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Divider, Grid, Stack, styled, Typography } from "@mui/material";
import { Paper } from "@mui/material";
import StandardDashboardPaper from "../StandardPaper";
import { StandardPaper } from "../StandardPaper";
import { useTheme } from '@mui/material/styles';
import { useGetOwnedBooks, useOpenBookInWrite } from '../../managers/BooksManager';
import StandardBookCoverTitle from "../StandardBookCoverTitle";
import BookListItem from "../lists/BookListItem";
import { StandardListButton } from "../StandardButton";
import CreateBookDialog from '../panels/CreateBookDialog';


//function MostRecentCardContent(props) {
const MostRecentCardContent = (props) => {

    let [showCreateBookDialog, setShowCreateBookDialog] = useState(false);

    const { ownedBooks, error } = useGetOwnedBooks(props);
    const { openBookInWrite } = useOpenBookInWrite(props);


    const theme = useTheme();
    let mostRecentBooks = [];
    if (ownedBooks && ownedBooks.length > 0) {

        mostRecentBooks = ownedBooks.slice(0, 2).map((book) => {
        //mostRecentBooks = ownedBooks.map((book) => {
            return <BookListItem book={book} />


        })
    }
    return (

        <Box>
            {(ownedBooks && ownedBooks.length === 0) &&

            <StandardListButton
                sx={{
                    //color: theme.palette.write.controls.color,
                    //borderColor: theme.palette.write.controls.color
                }}
                disableElevation={true}
                variant="outlined"
                onClick={() => {


                    setShowCreateBookDialog(true);

                }}>Create First Book</StandardListButton>
            }
            {mostRecentBooks}


            {showCreateBookDialog &&
                <CreateBookDialog
                    open={showCreateBookDialog}
                    onClose={(book) => {
                        setShowCreateBookDialog(false);

                    }}

                    onBookCreated={(book) => {
                        setShowCreateBookDialog(false);

                        openBookInWrite({
                            bookDraftId: book.primaryBookDraftId
                        });

                    }}


                />
            }
        </Box>
    );
}

export default inject('stores')(observer(MostRecentCardContent));
