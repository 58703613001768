let PART_MAPPING = {
	"VOLUME": "VOL",
	"PART": "PRT",
	"CHAPTER": "CHP",
	"COPYRIGHT": "CPY",
	"DEDICATION": "DED",
	"BLURBS": "BLU",
	"TITLEPAGE": "TPA",
	"HALFTITLE": "HFT",
	"INTRODUCTION": "INT",
	"ACKNOWLEDGEMENTS": "ACK",
	"FOREWORD": "FOR",
	"PREFACE": "PRE",
	"PROLOGUE": "PRO",
	"TOC": "TOC",
	"AFTERWORD": "AFT",
	"EPIGRAPH": "EPG",
	"EPILOGUE": "EPL",
	"ABOUTAUTHOR": "ABA",
	"ALSOBY": "ABY",
	"FULLPAGEIMAGE": "FPI",
	"NOTES": "NTS",
	"UNTYPED": "UTY"
};

export class RecipeUtils {


	setOverrideParam = (documentPart, paramPath, override) => {



		try {

			let recipe = documentPart?.getRecipe().json;
			let partTypeShorthand = PART_MAPPING[documentPart.partType];

			let currentObject = this.getParamObject(recipe, paramPath);
			if (currentObject.overrideIds == null) {
				currentObject.overrideIds = {};
			}
			let overrideConfig = currentObject.overrideIds[documentPart.id];
			if (overrideConfig == null) {


				overrideConfig = {

				}
			}

			//seed the override with the current value ( from the current scope )
			let currentScopedValue = this.getRecipeParamByPartType(null, recipe, documentPart, paramPath);

			overrideConfig.override = override;
			overrideConfig.value = currentScopedValue;

			currentObject.overrideIds[documentPart.id] = overrideConfig;

		} catch (err) {
			console.log(err);
		}


	}

	getOverrideParam = (documentPart, paramPath) => {



		try {

			let recipe = documentPart?.getRecipe().json;
			let partTypeShorthand = PART_MAPPING[documentPart.partType];

			let currentObject = this.getParamObject(recipe, paramPath);
			let overrideConfig = currentObject.overrideIds[documentPart.id];

			return overrideConfig;

		} catch (err) {
			console.log(err);
		}


	}

	getScope = (documentPart) => {

		let scope = "common";

		try {

			let recipe = documentPart?.getRecipe().json;
			let partTypeShorthand = PART_MAPPING[documentPart.partType];

			if (recipe.scope.partType[partTypeShorthand] != null) {

				scope = recipe.scope.partType[partTypeShorthand];

			}

		} catch (err) {
			console.log(err);
		}

		return scope;
	}

	getPartTypeShortHand = (documentPart) => {

		
		try {

			let partTypeShorthand = PART_MAPPING[documentPart.partType];

			
			return partTypeShorthand;



		} catch (err) {
			console.log(err);
		}

	}

	setScope = (documentPart, scope) => {




		try {

			let recipe = documentPart?.getRecipe().json;
			let partTypeShorthand = PART_MAPPING[documentPart.partType];

			if (scope && (scope == 'common' || scope == 'partType')) {

				recipe.scope.partType[partTypeShorthand] = scope;


			}



		} catch (err) {
			console.log(err);
		}

		return scope;
	}

	getParamObject = (recipe, paramPath) => {

		let currentObject = recipe.props;
		if (!currentObject) {
			recipe.props = {};
		}
		try {
			let paths = paramPath.split('.');
			paths.forEach((pathPart) => {

				//If this part of the param path does not exist, create it.
				if (!currentObject[pathPart]) {
					currentObject[pathPart] = {

					}
				}

				currentObject = currentObject[pathPart];

			})

			if (!currentObject.part) {
				currentObject.part = {};
			}

			if (!currentObject.overrideIds) {
				currentObject.overrideIds = {};
			}

			return currentObject;
		} catch (err) {
			console.log(err);
		}
	}

	// getRecipePropValueSource = (target, recipe, documentPart, paramPath)=>{

	// 	let currentObject = this.getParamObject(recipe,paramPath);

	// 	let partTypeShorthand = PART_MAPPING[documentPart.partType];

	// 	if(currentObject.VALUE_SOURCE.part[partTypeShorthand]){
	// 		return currentObject.VALUE_SOURCE.part[partTypeShorthand];
	// 	}


	// 	return currentObject.VALUE_SOURCE.def;
	// };

	// setRecipePropValueSource = (target, recipe, documentPart, paramPath, newValue)=>{

	// 	let currentObject = this.getParamObject(recipe,paramPath);

	// 	let partTypeShorthand = PART_MAPPING[documentPart.partType];

	// 	currentObject.VALUE_SOURCE.part[partTypeShorthand] = newValue;

	// 	// if(currentObject.VALUE_SOURCE.part[partTypeShorthand]){
	// 	// 	currentObject.VALUE_SOURCE.part[partTypeShorthand] = newValue;
	// 	// }


	// 	return currentObject.VALUE_SOURCE.def;
	// };


	getAllRecipeParamValues = (recipe, paramPath) => {

		let values = {};
		let _calculatedValue = null;
		//let currentObject = recipe.props;
		let currentObject = this.getParamObject(recipe, paramPath)
		try {

			values[currentObject.def] = currentObject.def;
			values[currentObject.com] = currentObject.com;
			if (currentObject.part) {

				let keys = Object.keys(currentObject.part);
				try {
					keys.forEach((key) => {

						values[currentObject.part[key]] = currentObject.part[key];

					})
				} catch (err) {
					console.log(err);
				}

			}
			if (currentObject.overrideIds) {
				let partIds = Object.keys(currentObject.overrideIds);
				try {
					partIds.forEach((partId) => {

						let partOverride = currentObject.overrideIds[partId];
						if (partOverride.value) {
							values[partOverride.value] = partOverride.value;
						}

					})
				} catch (err) {
					console.log(err);
				}




			}

		} catch (err) {
			console.log(err);
		}

		return values;
		//return 'partType:' + documentPart.partType;
	}
	//target is the nunjucks variable being set
	getRecipeParamByPartType = (target, recipe, documentPart, paramPath, defaultValue) => {

		let _calculatedValue = null;
		//let currentObject = recipe.props;
		let currentObject = this.getParamObject(recipe, paramPath)
		try {
			// let paths = paramPath.split('.');
			// paths.forEach((pathPart) => {
			// 	currentObject = currentObject[pathPart];
			// })

			let partTypeShorthand = PART_MAPPING[documentPart.partType=='SCENE'?'CHAPTER':documentPart.partType];

			//check to see if currentObject has a VALUE_SOURCE. If not, then 
			//currentObject IS the value.
			//if(currentObject.VALUE_SOURCE!=null){

			//let sourceByType = currentObject.VALUE_SOURCE.part[partTypeShorthand];
			let sourceByType = recipe.scope.partType[partTypeShorthand];
			sourceByType = sourceByType ? sourceByType : 'common';
			if (sourceByType != null) {
				//If there is a value for this partType return that.
				switch (sourceByType) {

					case "common":
						_calculatedValue = currentObject.com;
						break;

					case "partType":
						_calculatedValue = currentObject.part[partTypeShorthand];
						break;

					// case "self":
					// 	_calculatedValue = documentPart[currentObject.entityFieldName];
					// break;
				}

				if (_calculatedValue == null) {
					//If the recipe is calling for a byPart value and 
					//this partType does not have a value, then return the
					//default ( def ) value.
					_calculatedValue = currentObject.def;
				}
			} else {
				//Otherwise, return the detault value.	
				_calculatedValue = currentObject.com;
			}


			//}

			if (currentObject.overrideIds) {

				let overrideConfig = currentObject.overrideIds[documentPart.id];

				//If the param is overridden but has no value, default to the _calculatedValue
				//from this parts current scope. 
				//if (overrideConfig && overrideConfig.override == true && overrideConfig.value != null) {
				if (overrideConfig && overrideConfig.override == true) {

					_calculatedValue = overrideConfig.value;

				}

			}

			if (!_calculatedValue) {

				_calculatedValue = defaultValue;

			}
			return _calculatedValue;
		} catch (err) {
			console.log(err);
		}
		//return 'partType:' + documentPart.partType;
	}

	setRecipeParamByPartType = (target, recipe, documentPart, paramPath, newValue) => {


		let currentObject = this.getParamObject(recipe, paramPath)
		try {

			let partTypeShorthand = PART_MAPPING[documentPart.partType];

			let overrideConfig = currentObject.overrideIds != null ? currentObject.overrideIds[documentPart.id] : null;
			if (overrideConfig && overrideConfig.override == true) {

				overrideConfig.value = newValue;

			} else { //if(currentObject.VALUE_SOURCE!=null){

				//let sourceByType = currentObject.VALUE_SOURCE.part[partTypeShorthand];
				let sourceByType = recipe.scope?.partType[partTypeShorthand];
				sourceByType = sourceByType ? sourceByType : 'common';

				if (sourceByType != null) {
					//If there is a value for this partType return that.
					switch (sourceByType) {

						case "common":
							currentObject.com = newValue;
							break;

						case "partType":
							currentObject.part[partTypeShorthand] = newValue;
							break;

						// case "self":
						// 	documentPart[currentObject.entityFieldName] = newValue;
						// break;
					}

				} else {
					//Otherwise, set the common value to the newValue. 
					//If VALUE_SOURCE==null set it to 'common' since we cannot change
					//the default value and this object may have been using to the 
					//default value. 	
					currentObject.com = newValue;
				}


			}

		} catch (err) {
			console.log(err);
		}

	}

	getParamUpdateData = (recipe, documentPart, paramPath) => {
		// let recipeId = input.recipeId;
		// let paramAction = input.paramAction;
		// let paramName = input.paramName;
		// let paramValue = input.paramValue;
		// let paramPath = input.paramPath;
		// let overrideActive = input.overrideActive;
		// let overridePartId = input.overridePartId;
		// let partTypeShortHand = input.partTypeShortHand;
		// let scope = input.scope;


		let paramUpdateData = {
			paramAction: "SET_PARAM_VALUE",
			paramPath: paramPath
		}

		let _calculatedValue = null;
		//let currentObject = recipe.props;
		let currentObject = this.getParamObject(recipe, paramPath)
		try {

			let partTypeShorthand = PART_MAPPING[documentPart.partType];
			paramUpdateData.partTypeShortHand = partTypeShorthand;

			let sourceByType = recipe.scope.partType[partTypeShorthand];
			sourceByType = sourceByType ? sourceByType : 'common';
			if (sourceByType != null) {
				//If there is a value for this partType return that.
				switch (sourceByType) {

					case "common":
						paramUpdateData.paramValue = currentObject.com;
						paramUpdateData.scope = "common";
						break;

					case "partType":
						paramUpdateData.paramValue = currentObject.part[partTypeShorthand];
						paramUpdateData.scope = "partType";
						break;


				}

				// if (_calculatedValue == null) {
				// 	//If the recipe is calling for a byPart value and 
				// 	//this partType does not have a value, then return the
				// 	//default ( def ) value.
				// 	_calculatedValue = currentObject.def;
				// }
			} else {
				//Otherwise, return the detault value.	
				paramUpdateData.paramValue = currentObject.com;
			}


			//}

			if (currentObject.overrideIds) {

				let overrideConfig = currentObject.overrideIds[documentPart.id];

				//If the param is overridden but has no value, default to the _calculatedValue
				//from this parts current scope. 
				if (overrideConfig && overrideConfig.override == true && overrideConfig.value != null) {

					paramUpdateData.paramValue = overrideConfig.value;
					paramUpdateData.overrideActive = true;
					paramUpdateData.overridePartId = documentPart.id;
					paramUpdateData.partTypeShortHand = null;
					paramUpdateData.scope = null;

				}

			}

			//Always set this so we can remove an override if necessary server side.
			paramUpdateData.overridePartId = documentPart.id;

			return paramUpdateData;
		} catch (err) {
			console.log(err);
		}
	}


}


//exports.BuildManager=BuildManager;
//exports.recipe1=recipe1;
//exports.bookMerlinContext=bookMerlinContext;
//exports.propertyPanelDefinitions=propertyPanelDefinitions;