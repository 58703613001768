import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography, Popover } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import StandardUISquareList from "../../UI/StandardUISquareList";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useGetSnapshots, useOpenBookInWrite, useOpenSnapshotInWrite } from '../../../managers/BooksManager';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import ReactTimeAgo from 'react-time-ago';
import EmptyContent from "../../EmptyContent";
import StandardDialog from "../../StandardDialog";
import RestoreSnapshotPanel from "../../panels/RestoreSnapshotPanel";
import StandardUIButton from "../../UI/StandardUIButton";
import StandardUITextField from "../../UI/StandardUITextField";
import StandardUIDialog from "../../UI/StandardUIDialog";






const OwnedBookSnapshotCard = (props) => {

    let user = props.stores.userStore.user;

    let { book, showRestoreIcon = true } = props;



    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event, snapshot) => {
        setAnchorEl(event.currentTarget);
        setSelectedSnapshot(snapshot);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const { openBookInWrite } = useOpenBookInWrite(props);
    const { openSnapshotInWrite } = useOpenSnapshotInWrite(props, false);
    const [showCreateSnapshotDialog, setShowCreateSnapshotDialog] = React.useState(false);
    const [showRestoreSnapshotDialog, setShowRestoreSnapshotDialog] = React.useState(false);
    const [showDeleteSnapshotDialog, setShowDeleteSnapshotDialog] = React.useState(false);
    const [selectedSnapshot, setSelectedSnapshot] = React.useState(null);

    let { listSnapshots, snapshots, error: listSnapshotsError, loading: listSnapshotsLoading } = useGetSnapshots();

    useEffect(() => {

        if (book != null) {
            listSnapshots({
                bookId: book.id,
                onCompleted: () => {

                }
            })
        }
    }, [book]);


    useEffect(() => {

        if (snapshots != null) {

            //console.log(snapshots);
        }
    }, [snapshots]);




    const getSecondaryActions = (snapshot) => {

        const secondaryActions = [];

        if (showRestoreIcon) {
            secondaryActions.push(<IconButton
                onClick={(e) => {
                    handleClick(e, snapshot)
                }}>
                <MoreVertIcon />
            </IconButton>)
        }

        return secondaryActions

    }



    return (
        <List>
            {snapshots && snapshots.length > 0 ?
                snapshots.map((snapshot) => {
                    return <ListItem
                        sx={{ padding: ' 0.25em' }}
                        secondaryAction={
                            getSecondaryActions(snapshot)
                        }
                    >
                        <ListItemAvatar>
                            <Avatar variant="rounded">
                                {props.avatarType}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={snapshot.name}
                            secondary={snapshot.description}
                        />

                    </ListItem>
                })
                :
                <EmptyContent tagline={'Create your first snapshot!'} />
            }


            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }} onClick={() => {

                    openSnapshotInWrite({
                        bookDraftId: book.primaryBookDraftId,
                        snapshotId: selectedSnapshot.id
                    });

                }}>Open</Typography>
                <Typography sx={{ p: 2 }} onClick={() => {

                    setSelectedSnapshot(selectedSnapshot);
                    setShowRestoreSnapshotDialog(true);

                }}>Restore</Typography>
                <Typography sx={{ p: 2 }} onClick={() => {

                    setSelectedSnapshot(selectedSnapshot);
                    setShowDeleteSnapshotDialog(true);

                }}>Delete</Typography>
            </Popover>
            <StandardDialog
                open={showRestoreSnapshotDialog}
                onClose={() => {
                    setShowRestoreSnapshotDialog(false)
                }}
                onOk={() => {


                }}
                title={'Restore Snapshot'}
                dialogContent={
                    <RestoreSnapshotPanel
                        book={book}
                        snapshot={selectedSnapshot}
                        onClose={() => {
                            setShowRestoreSnapshotDialog(false)
                        }}
                        onRestored={() => {
                            setShowRestoreSnapshotDialog(false)
                            setSelectedSnapshot(null);
                            openBookInWrite({
                                bookDraftId: book.primaryBookDraftId
                            });
                        }} />
                }
            >
            </StandardDialog>
            <StandardUIDialog
                open={showDeleteSnapshotDialog}
                onClose={() => {
                    setShowDeleteSnapshotDialog(false)
                }}
                onOk={() => {


                }}
                title={'Delete Snapshot'}
                dialogContent={
                    <Typography>Delete Snapshot</Typography>
                }
                cardActions={
                    <StandardUIButton
                        buttonAction='Delete Snapshot'
                    />
                }
            >
            </StandardUIDialog>


        </List>
    )
}

export default inject('stores')(observer(OwnedBookSnapshotCard));