import React, { useEffect, useState } from 'react';
import { observable } from 'mobx';
import { useUpdateRecipeParam } from '../persistance/persistanceHooks';
import { BoxSet } from '../domain/bookModel';
import { Auth } from "aws-amplify";

class SecurityManager {

    constructor() {

    }

    logout = ({
        client,
        appContext,
        stores
    }) => {



        stores.bookStore.loggedIn = false;

        Auth.signOut()
            .then(data => {
                
                stores.bookStore.loggedIn = false;
               

            })
            .catch(err => {
                console.log(err);

            })

        // stores.resetStores();
        stores.bookStore.reset();
        stores.userStore.reset();
        // client.resetStore();
        client.clearStore();
        client.cache.gc();
        let persistor = appContext.persistor;
        persistor.purge().then(()=>{

            persistor.getSize().then((data)=>{

                console.log(data);
            })

        });

    }

}

export default new SecurityManager();