import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import StandardDataTable from './StandardDataTable';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import ReactTimeAgo from 'react-time-ago';
import Feather from 'mdi-material-ui/Feather';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import SnapshotsTable from './SnapshotsTable';
import BookEditsTable from './BookEditsTable';
import { useOpenBookInWrite, useOpenBookInRead, useOpenBookInFormat, useManagerCreateBook } from '../../managers/BooksManager';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import StandardImageViewer from '../StandardImageViewer';
import { useGetOwnedBoxsets } from '../../managers/BoxsetsManager';
import { AvatarGroup } from '@mui/material';
import config from '../../aws-exports';
import ImageTitleTableCell from './ImageTitleTableCell';
import CommentIcon from '@mui/icons-material/Comment';
import { useGetInvitedToBoxsets } from '../../managers/BoxsetsManager';
import AvatarTableCell from './AvatarTableCell';
import imageNotFound from '../../assets/images/image-not-found.png'
import { useMediaQuery } from 'react-responsive';
import StandardRoundedAction from '../actions/StandardRoundedAction';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import TablePopupMenu from '../menus/TablePopupMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SharedBoxsetsList from '../lists/SharedBoxsetsList';
import StandardSkeleton from '../../StandardSkeleton';

const SharedBoxsetsTable = (props) => {

  let user = props.stores.userStore.user;

  let { book } = props;
  //const { ownedBoxsets, error } = useGetOwnedBoxsets(props);

  const { invitedToBoxsets, error, loading } = useGetInvitedToBoxsets(props, user?.id)


  const { openBookInWrite } = useOpenBookInWrite(props);
  const { openBookInFormat } = useOpenBookInFormat(props);
  const { openBookInRead } = useOpenBookInRead(props);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');

  let [showCreateBoxsetDialog, setShowCreateBoxsetDialog] = useState(false);
  let [showDeleteBoxsetDialog, setShowDeleteBoxsetDialog] = useState(false);
  let [selectedBoxset, setSelectedBoxset] = useState(null);

  const isXs = useMediaQuery({ maxWidth: 599 });
  const isSm = useMediaQuery({ maxWidth: 600 });
  const isMd = useMediaQuery({ maxWidth: 900 });
  const isLg = useMediaQuery({ maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1536 });

  const isRoute = () => {
    return 'primary';
  }


  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {


    setOrder("desc");
    setOrderBy("createdAt");

  }, [invitedToBoxsets]);


  const getBoxsetContributedBooks = (boxset) => {
    let allContributedBoxSetBooks = {};
    boxset.boxsetContributors?.map((boxsetContributor) => {

      boxsetContributor.contributedBoxsetBooks.map((bsb) => {
        allContributedBoxSetBooks[bsb.book.id] = bsb;
      });

    });

    return Object.values(allContributedBoxSetBooks);
  }


  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      {!isXs &&
        <StandardDataTable title={'Shared Boxsets'}
          noDataMessage={'Boxsets you have been invited to will appear here!'}
          loading={loading}
          structure={{
            dataDef: {
              data: invitedToBoxsets,
              rowKeyDataFieldName: 'id'
            },
            columns: [
              {
                title: 'Title',
                sortable: true,
                sortFieldNameOverride:'title',
                dataValueFunction: (boxset) => {
                  return <ImageTitleTableCell
                    bucketName={config.user_uploads_bucket_name}
                    title={boxset.book.title}
                    s3Src={boxset.book?.coverUrl}
                    onClick={(e) => {
                      e.preventDefault();
                      //console.log(boxset)
                      props.stores.bookStore.invitedToBoxSetListBoxSet = boxset;
                      navigate("/sharedBoxsets/details");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }}
                  />
                }
              },
              {
                title: 'Owner',
                dataValueFunction: (boxset) => {
                  return <AvatarTableCell
                    bucketName={config.user_uploads_bucket_name}
                    s3Src={boxset?.owner?.profilePicUrl}
                    title={boxset?.owner?.name}
                    onClick={(e) => {
                      e.preventDefault();
                      //console.log(boxset)
                      props.stores.bookStore.invitedToBoxSetListBoxSet = boxset;
                      navigate("/sharedBoxsets/details");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }}
                  />

                },
                ignore: isXs
              },

              {
                title: 'Read',
                dataValueFunction: (boxset) => {
                  return <StandardRoundedAction
                    icon={<LocalLibraryIcon sx={{ color: theme.palette.standardActions.icon.color.main }}
                      onClick={(e) => {
                        e.preventDefault();
                        //console.log(boxset)
                        openBookInRead({
                          bookDraftId: boxset.book.primaryBookDraftId
                        });
                      }} />} />
                }
              },

              {
                title: 'Contributors',
                dataValueFunction: (boxset) => {
                  return <AvatarGroup max={3} sx={{ width: '1.5em', height: '1.5em', cursor: 'pointer', alignItems: 'center', justifyContent: 'center' }} onClick={(e) => {
                    e.preventDefault();
                    //console.log(boxset)
                    props.stores.bookStore.invitedToBoxSetListBoxSet = boxset;
                    navigate("/sharedBoxsets/details/contributors");
                    // openBookInWrite({
                    //   bookDraftId: book.primaryBookDraftId
                    // });
                  }}>
                    {boxset.boxsetContributors?.map((boxsetContributor) => (
                      <Avatar sx={{ width: '1.5em', height: '1.5em' }} alt="" src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + boxsetContributor.contributor.profilePicUrl} />
                    ))}
                  </AvatarGroup>
                },
                ignore: isXs

              },
              {
                title: 'Books',
                dataValueFunction: (boxset) => {

                  // let allContributedBoxSetBooks = [];
                  // boxset.boxsetContributors ?.map((boxsetContributor) => {

                  //   boxsetContributor.contributedBoxsetBooks.map((bsb) => {
                  //     allContributedBoxSetBooks.push(bsb);
                  //   });

                  // });

                  let allContributedBoxSetBooks = getBoxsetContributedBooks(boxset);

                  return <AvatarGroup max={3} sx={{ width: '1.5em', height: '1.5em', cursor: 'pointer', alignItems: 'center', justifyContent: 'center' }} onClick={(e) => {
                    e.preventDefault();
                    //console.log(boxset)
                    props.stores.bookStore.invitedToBoxSetListBoxSet = boxset;
                    navigate("/sharedBoxsets/details/books");
                    // openBookInWrite({
                    //   bookDraftId: book.primaryBookDraftId
                    // });
                  }}>
                    {allContributedBoxSetBooks?.map((contributedBook) => (
                      <Avatar sx={{ width: '1.5em', height: '1.5em' }} variant="square" alt="" src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + contributedBook.book.coverUrl?.split('&&&')[0]} >
                        <img style={{ width: '1.5em', height: '1.5em' }} src={imageNotFound} />
                      </Avatar>
                    ))}
                  </AvatarGroup>
                },
                ignore: isXs
              },

              // {
              //   title: 'Feedback',
              //   dataValueFunction: (boxset) => {
              //     return <IconButton aria-label="delete">
              //       <CommentIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
              //         e.preventDefault();
              //         console.log(boxset)
              //         props.stores.bookStore.invitedToBoxSetListBoxSet = boxset;
              //         navigate("/sharedBoxsets/details/books");
              //         // openBookInWrite({
              //         //   bookDraftId: book.primaryBookDraftId
              //         // });
              //       }} />
              //     </IconButton>
              //   },
              //   ignore: isXs
              // },
              // {
              //   title: 'Write',
              //   dataValueFunction: (boxset) => {
              //     return <IconButton aria-label="delete">
              //       <Feather sx={{ color: theme.palette.background.main }} onClick={(e) => {
              //         e.preventDefault();
              //         console.log(boxset)
              //         //navigate("/write");
              //         openBookInWrite({
              //           bookDraftId: boxset.book.primaryBookDraftId
              //         });
              //       }} />
              //     </IconButton>
              //   }
              // },
              // {
              //   title: 'Format',
              //   dataValueFunction: (boxset) => {
              //     return <IconButton aria-label="delete">
              //       <FormatPaintIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
              //         e.preventDefault();
              //         //navigate("/format");
              //         openBookInFormat({
              //           bookDraftId: boxset.book.primaryBookDraftId
              //         });
              //       }} />
              //     </IconButton>

              //   }
              // },
              {
                title: 'Shared',
                dataFieldName: 'createdAt',
                sortable: true,
                dataValueFunction: (boxset) => { return boxset.sharedAt != null ? <ReactTimeAgo date={boxset.sharedAt} locale="en-US" /> : '' },
                ignore: isXs
              },
              // {
              //   title: 'Delete',
              //   dataValueFunction: (boxset) => {
              //     return <IconButton aria-label="delete">
              //       <TrashCanOutline sx={{ color: theme.palette.background.main }} onClick={(e) => {
              //         e.preventDefault();
              //         console.log(boxset);
              //         setSelectedBoxset(boxset);
              //         setShowDeleteBoxsetDialog(true);

              //       }} />
              //     </IconButton>
              //   }
              // },
              {
                title: '',
                dataValueFunction: (boxset) => {
                  return <TablePopupMenu
                    menuItems={[
                      // <ListItemButton onClick={(e) => {
                      //   e.preventDefault();
                      //   console.log(boxset)
                      //   props.stores.bookStore.invitedToBoxSetListBoxSet = boxset;
                      //   navigate("/sharedBoxsets/details");
                      //   // openBookInWrite({
                      //   //   bookDraftId: book.primaryBookDraftId
                      //   // });
                      // }}  >

                      //   <ListItemIcon>
                      //     <SettingsIcon sx={{ color: theme.palette.background.main }} />
                      //   </ListItemIcon>
                      //   <ListItemText primary="Details" />
                      // </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(boxset)
                        props.stores.bookStore.invitedToBoxSetListBoxSet = boxset;
                        navigate("/sharedBoxsets/details/contributors");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }} >

                        <ListItemIcon>
                          <PeopleIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Contributors" />
                      </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(boxset)
                        props.stores.bookStore.invitedToBoxSetListBoxSet = boxset;
                        navigate("/sharedBoxsets/details/books");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }} >

                        <ListItemIcon>
                          <MenuBookIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Books" />
                      </ListItemButton>,
                      // <ListItemButton onClick={(e) => {
                      //   e.preventDefault();
                      //   console.log(boxset)
                      //   props.stores.bookStore.invitedToBoxSetListBoxSet = boxset;
                      //   navigate("/sharedBoxsets/details/feedback");
                      //   // openBookInWrite({
                      //   //   bookDraftId: book.primaryBookDraftId
                      //   // });
                      // }} >

                      //   <ListItemIcon>
                      //     <CommentIcon sx={{ color: theme.palette.background.main }} />
                      //   </ListItemIcon>
                      //   <ListItemText primary="Feedback" />
                      // </ListItemButton>,
                    ]}
                  />
                },
                ignore: !isXs
              },
            ]
          }} />
      }
      {isXs &&
        <SharedBoxsetsList
          filteredBooks={[]}
          showSelectIcon
          onSelected={(boxset) => {

            if (boxset) {

              //console.log(boxset)
              props.stores.bookStore.invitedToBoxSetListBoxSet = boxset;
              navigate("/sharedBoxsets/details");

            }

          }}
        />
      }
      {(loading && (!invitedToBoxsets || invitedToBoxsets.length == 0)) &&
        <StandardSkeleton />
      }
    </Box>

  )
}
export default inject('stores')(observer(SharedBoxsetsTable));