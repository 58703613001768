import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LaurasDetailList from './LaurasDetailList';
import GoogleIcon from '@mui/icons-material/Google';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LaurasButton from './LaurasButton';

const LaurasBooksExport = (props) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [showMoreDetail, setShowMoreDetail] = useState(false);
    const handleMoreDetailOpen = () => {
        setShowMoreDetail(true);
    }

    const handleMoreDetailClose = () => {
        setShowMoreDetail(false);
    }

    return (
        <div>
            <LaurasDetailList
                avatarType={<MenuBookIcon />}
                primary='Export Title 1'
                secondary='5/29/2023'
                showWriteIcon={false}
                showFormatIcon={false}
                showExpandIcon={true}
                onExpandClick={handleMoreDetailOpen}
            />
            <LaurasDetailList
                avatarType={<MenuBookIcon />}
                primary='Export Title 2'
                secondary='6/26/2023'
                showWriteIcon={false}
                showFormatIcon={false}
                showExpandIcon={true}
                onExpandClick={handleMoreDetailOpen}
            />

            <Dialog
                // sx={{
                //     "& .MuiDialog-container": {
                //         "& .MuiPaper-root": {
                //             width: "100%",
                //             maxWidth: "500px",  // Set your width here
                //         },
                //     },
                // }}
                open={showMoreDetail}
                onClose={handleMoreDetailClose}>
                <div
                    style={{ display: 'flex', justifyContent: 'right' }}>
                    <IconButton
                        onClick={handleMoreDetailClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogTitle
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1em' }}
                    variant={theme.landmarkText.variant}
                >
                    Title
                </DialogTitle>
                <DialogContent >
                    <LaurasDetailList
                        avatarType={<MenuBookIcon />}
                        primary='Kindle'
                        secondary='Clickable File'
                        showWriteIcon={false}
                        showFormatIcon={false}
                    />
                    <LaurasDetailList
                        avatarType={<GoogleIcon />}
                        primary='Google Play'
                        secondary='Clickable File'
                        showWriteIcon={false}
                        showFormatIcon={false}
                    />
                    <LaurasDetailList
                        avatarType={<MenuBookIcon />}
                        primary='iBooks'
                        secondary='Clickable File'
                        showWriteIcon={false}
                        showFormatIcon={false}
                    />
                    <LaurasDetailList
                        avatarType={<MenuBookIcon />}
                        primary='Kobo'
                        secondary='Clickable File'
                        showWriteIcon={false}
                        showFormatIcon={false}
                    />
                    <LaurasDetailList
                        avatarType={<MenuBookIcon />}
                        primary='Nook'
                        secondary='Clickable File'
                        showWriteIcon={false}
                        showFormatIcon={false}
                    />
                    <LaurasDetailList
                        avatarType={<MenuBookIcon />}
                        primary='Generic'
                        secondary='Clickable File'
                        showWriteIcon={false}
                        showFormatIcon={false}
                    />
                </DialogContent>
                <CardActions
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
                >
                    <LaurasButton
                        buttonAction='Delete'
                    />
                </CardActions>
            </Dialog>
        </div>
    )
}
export default inject('stores')(observer(LaurasBooksExport));