import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import StandardDataTable from '../../../components/table/StandardDataTable';
import { useManagerLoadBoxSetContributors } from '../../../managers/BoxsetsManager';
import CancelTableCell from '../../../components/table/CancelTableCell';
import RemoveBoxsetContributedBookPanel from '../../../components/panels/RemoveBoxsetContributedBookPanel';
import StandardOkCancelDialog from '../../../components/panels/StandardOkCancelDialog';

const BoxsetBooks = (props) => {


  let { boxset } = props

  let [boxsetBooks, setBoxsetBooks] = useState([]);
  let [showRemoveBookDialog, setShowRemoveBookDialog] = useState(false);
  let [selectedBook, setSelectedBook] = useState(false);
  let {
    boxsetContributors,
    loading: lazyLoadBoxSetContributorsLoading,
    error: lazyLoadBoxSetContributorsError
  } = useManagerLoadBoxSetContributors(props, boxset?.id);

  //console.log(boxsetContributors);

  useEffect(() => {

    try {

      let bookData = {};
      boxsetContributors.forEach((boxsetContributor) => {

        boxsetContributor.contributedBooks.items.forEach((bsb) => {

          bookData[bsb.book.id] = ({
            boxsetContributor,
            boxsetBook: bsb
          })
        })

      });

      setBoxsetBooks(Object.values(bookData));

    } catch (err) {
      console.log(err);
    }
  }, [boxsetContributors]);


  return (
    <>
      {boxset &&
        <StandardDataTable title={'Contributed Books'}
          structure={{
            dataDef: {
              data: boxsetBooks,
              rowKeyDataFieldName: 'id'
            },
            columns: [
              {
                title: 'Pic',
                dataValueFunction: (bsbData) => { return <Avatar sx={{ width: '1.5em', height: '1.5em' }} variant="square" alt="" src="https://www.adobe.com/express/create/cover/media_178ebed46ae02d6f3284c7886e9b28c5bb9046a02.jpeg?width=400&format=jpeg&optimize=medium" /> }
              },
              {
                title: 'Name',
                dataValueFunction: (bsbData) => { return bsbData.boxsetContributor.contributor.name }
              },
              {
                title: 'Title',
                dataValueFunction: (bsbData) => { return bsbData.boxsetBook.book.title }
              },
              {
                title: 'Remove',
                dataValueFunction: (bsbData) => {

                  return <CancelTableCell onClick={(e) => {
                    e.preventDefault();
                    setShowRemoveBookDialog(true);
                    setSelectedBook(bsbData.boxsetBook.book);
                  }} />
                }
              },
              {
                title: 'Insert',
                dataValueFunction: (bsbData) => {

                  return <CancelTableCell onClick={(e) => {
                    e.preventDefault();
                    if(props.onInsertBook){
                      props.onInsertBook(bsbData.boxsetBook.book);
                    }
                  }} />
                }
              },
            ]
          }}
        />
      }


      <StandardOkCancelDialog
        open={showRemoveBookDialog}
        showOkButton={true}
        showCancelButton={true}
        onClose={() => {
          setShowRemoveBookDialog(false)
          setSelectedBook(null)
        }}
        onOk={() => {
          setShowRemoveBookDialog(false)
          setSelectedBook(null)

        }}
        title={'Remove Contributor'}
        content={
          <RemoveBoxsetContributedBookPanel boxset={boxset} book={selectedBook}  onRemoved={() => {
            setShowRemoveBookDialog(false)
            setSelectedBook(null)
            //lazyLoadBoxSetContributorsRefetch();
  
          }}/>
        }
      />
    </>

  )
}

export default inject('stores')(observer(BoxsetBooks));