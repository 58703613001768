


export default {

    isRoute: function (route, location) {

        try {

            if (route && route == location) {

                return true;
            }



        } catch (e) {
            return false;
        }


        return false;

    },




}
