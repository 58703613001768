import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import ToolbarItem from '../editor/toolbar/ToolbarItem';
import SettingsIcon from '@mui/icons-material/Settings';
import ChatIcon from '@mui/icons-material/Chat';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDocumentEdit from 'mdi-material-ui/FileDocumentEdit';
import NoteIcon from '@mui/icons-material/Note';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ShareIcon from '@mui/icons-material/Share';
import { BottomNavigationAction, Box, Paper } from '@mui/material';
import ScrollingGridContainer from '../ScrollingGridContainer';
import ScrollingGridItem from '../ScrollingGridItem';
import { useTheme } from '@mui/material/styles';
import WriteDetailsNotes from './detailPanels/WriteDetailsNotes';
import WriteDetailsOutline from './detailPanels/WriteDetailsOutline';
import WriteDetailsGoals from './detailPanels/WriteDetailsGoals';
import WriteDetailsFeedback from './detailPanels/WriteDetailsFeedback';
import WriteDetailsSharing from './detailPanels/WriteDetailsSharing';
import ReadDetailsSettings from './detailPanels/ReadDetailsSettings';
import WriteDetailsSnapshots from './detailPanels/WriteDetailsSnapshots';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import WriteDetailsEditRequests from './detailPanels/WriteDetailsEditRequests';
import PeopleIcon from '@mui/icons-material/People';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WriteDetailsContributors from './detailPanels/WriteDetailsContributors';
import WriteDetailsContributedBooks from './detailPanels/WriteDetailsContributedBooks';
import ReadDetailsFeedback from './detailPanels/ReadDetailsFeedback';
import UserCommentInput from './UserCommentInput';

const ReadDetails = (props) => {

    const [finalView, setFinalView] = useState(false);
    const [selectedTab, setSelectedTab] = useState('SETTINGS');

    const theme = useTheme();

    let { bookDraftDocumentPart, sharedBook, bookDraft } = props;

    
    const fontSize = 'small';


    const noteIcon = <ToolbarItem icon={<NoteIcon fontSize={fontSize} color={selectedTab == 'NOTES' ? 'secondary' : 'inherit'} onClick={() => { setSelectedTab('NOTES') }} />} />;
    const outlineIcon = <ToolbarItem icon={<CalendarViewWeekIcon fontSize={fontSize} color={selectedTab == 'OUTLINE' ? 'secondary' : 'inherit'} onClick={() => { setSelectedTab('OUTLINE') }} />} />;
    const goalsIcon = <ToolbarItem icon={<TrendingUpIcon fontSize={fontSize} color={selectedTab == 'GOALS' ? 'secondary' : 'inherit'} onClick={() => { setSelectedTab('GOALS') }} />} />;
    const chatIcon = <ToolbarItem icon={<ChatIcon fontSize={fontSize} color={selectedTab == 'FEEDBACK' ? 'secondary' : 'inherit'} onClick={() => { setSelectedTab('FEEDBACK') }} />} />;
    const shareIcon = <ToolbarItem icon={<ShareIcon fontSize={fontSize} color={selectedTab == 'SHARE' ? 'secondary' : 'inherit'} onClick={() => { setSelectedTab('SHARE') }} />} />;
    const snapshotsIcon = <ToolbarItem icon={<CopyAllIcon fontSize={fontSize} color={selectedTab == 'SNAPSHOTS' ? 'secondary' : 'inherit'} onClick={() => { setSelectedTab('SNAPSHOTS') }} />} />;
    const editRequestsIcon = <ToolbarItem icon={<AccountEdit fontSize={fontSize} color={selectedTab == 'EDITS' ? 'secondary' : 'inherit'} onClick={() => { setSelectedTab('EDITS') }} />} />;
    const settingsIcon = <ToolbarItem icon={<SettingsIcon fontSize={fontSize} color={selectedTab == 'SETTINGS' ? 'secondary' : 'inherit'} onClick={() => { setSelectedTab('SETTINGS') }} />} />;

    const contributorsIcon = <ToolbarItem icon={<PeopleIcon fontSize={fontSize} color={selectedTab == 'CONTRIBUTORS' ? 'secondary' : 'inherit'} onClick={() => { setSelectedTab('CONTRIBUTORS') }} />} />;
    const contributedBooksIcon = <ToolbarItem icon={<MenuBookIcon fontSize={fontSize} color={selectedTab == 'CONTRIBUTED_BOOKS' ? 'secondary' : 'inherit'} onClick={() => { setSelectedTab('CONTRIBUTED_BOOKS') }} />} />;





    const textSnippetIcon = <ToolbarItem icon={<FileDocumentEdit fontSize={fontSize} color={selectedTab == 'NOTES' ? 'secondary' : 'inherit'} onClick={() => { setSelectedTab('NOTES') }} />} />;
    const visibilityIcon = <ToolbarItem icon={<VisibilityOutlinedIcon fontSize={fontSize} color={finalView ? 'secondary' : 'inherit'} onClick={() => { setFinalView(!finalView); }} />} />;
    const transferIcon = <ToolbarItem icon={<FileCopyIcon fontSize={fontSize} color={finalView ? 'secondary' : 'inherit'} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        //initTransferHandler();
    }} />} />;







    return (

        <Paper sx={{
            display: 'flex',
            backgroundColor: theme.palette.write?.background?.color,
            height: '100%',

        }} >
            <Box focusable='false' tabIndex={-1} id='master-toolbar' style={{
                'width': '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>

                {sharedBook &&
                    <Box focusable='false' tabIndex={-1} className='toolbar-wrapper' sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        'flex-direction': 'row',
                        'flex-wrap': 'wrap',
                        'alignItems': 'center',
                        width: '100%',
                        border: '1px solid gray',
                        padding: '0em 0em .5em 0em'
                    }}>

                        {/* {noteIcon} */}
                        {bookDraft && bookDraft?.book.isBoxset === false && chatIcon}
                        {bookDraft && bookDraft?.book.isBoxset === false && shareIcon}
                        

                        {/* Boxsets only */}
                        {bookDraft && bookDraft?.book.isBoxset === true && contributorsIcon}
                        {bookDraft && bookDraft?.book.isBoxset === true && contributedBooksIcon}
                        {settingsIcon}




                    </Box>
                }
                {/* {selectedTab == 'FEEDBACK' &&

                <UserCommentInput bookDraft={bookDraft} documentPart={bookDraftDocumentPart?.documentPart} />

            } */}
                <ScrollingGridContainer container columns={100} direction={'row'}>

                    <ScrollingGridItem xs={100} >


                        {selectedTab == 'FEEDBACK' &&

                            <ReadDetailsFeedback 
                                bookDraft={bookDraft} 
                                sharedBook={sharedBook}
                                bookDraftDocumentPart={bookDraftDocumentPart} />

                        }
                        {selectedTab == 'SETTINGS' &&

                            <ReadDetailsSettings
                                bookDraft={bookDraft}
                                sharedBook={sharedBook}
                                bookDraftDocumentPart={bookDraftDocumentPart}
                            />

                        }
                        {selectedTab == 'CONTRIBUTORS' &&

                            <WriteDetailsContributors bookDraft={bookDraft} bookDraftDocumentPart={bookDraftDocumentPart} hideAddContributor={true}/>

                        }
                        {selectedTab == 'CONTRIBUTED_BOOKS' &&

                            <WriteDetailsContributedBooks bookDraft={bookDraft} bookDraftDocumentPart={bookDraftDocumentPart}/>

                        }













                    </ScrollingGridItem>
                </ScrollingGridContainer>

            </Box>
        </Paper>
    );
};


export default inject('stores')(observer(ReadDetails));
