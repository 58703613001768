import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import StandardTextfield from "../../StandardTextfield";
import StandardButton from "../../StandardButton";

const SendInvitationPanel = (props) => {

  let user = props.stores.userStore.user;

  // let [name, setName] = useState(null);
  // let [email, setEmail] = useState(null);

  // const handleNameChange = (e) => {
  //   e.preventDefault();

  //   setName(e.target.value);
  // }

  // const handleEmailChange = (e) => {
  //   e.preventDefault();

  //   setEmail(e.target.value);
  // }
  

  return (
    <Grid container direction={'column'}>
      <Grid item>
        <StandardTextfield label={'Name'} onChange={props.onNameChange} value={props.name}/>
      </Grid>
      <Grid item>
        <StandardTextfield label={'email'} onChange={props.onEmailChange} value={props.email}/>
      </Grid>
    </Grid>

  )
}

export default inject('stores')(observer(SendInvitationPanel));