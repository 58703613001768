import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import OwnedBoxsetContributors from '../panels/OwnedBoxsetContributors';
import { useManagerLoadBoxSetContributors } from '../../managers/BoxsetsManager';
import OwnedBoxsetBooks from '../panels/OwnedBoxsetBooks';
import useMediaQuery from '@mui/material/useMediaQuery';
import BoxsetsTable from '../boilerPlate/BoxsetsTable';
import StretchBox from '../boilerPlate/StretchBox';
import { Box, Button, SwipeableDrawer, Typography, Divider } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import ScrollBox from '../boilerPlate/ScrollBox';
import OwnedBoxsetsList from '../lists/OwnedBoxsetsList';
import { useGetOwnedBoxsets } from '../../managers/BoxsetsManager';
import StandardFloatingPlus from '../StandardFloatingPlus';
import CreateBoxsetDialog from '../panels/CreateBoxsetDialog';
import ExportsPanel from '../export/ExportsPanel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import Feather from "mdi-material-ui/Feather";
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import AvatarTableCell from '../table/AvatarTableCell';
import config from '../../aws-exports';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import StandardList from '../lists/StandardList';
import ShareIcon from '@mui/icons-material/Share';
import AddContributorAssembly from "../assemblies/AddContributorAssembly";
import CancelTableCell from "../table/CancelTableCell";
import CircleAvatar from "../lists/CircleAvatar";
import StandardOkCancelDialog from "../panels/StandardOkCancelDialog";
import RemoveBoxsetContributorPanel from '../../components/panels/RemoveBoxsetContributorPanel';
import EmptyContent from "../EmptyContent";



let missingBookImage = require('../../assets/images/image-not-found.png')

const LaurasListItem = (props) => {

    let boxset = props.stores.bookStore.boxSetListBoxSet;

    let [showRemoveContributorDialog, setShowRemoveContributorDialog] = useState(false);
    let [selectedContributor, setSelectedContributor] = useState(false);

    let {
        boxsetContributors,
        loading: lazyLoadBoxSetContributorsLoading,
        error: lazyLoadBoxSetContributorsError,
        refetch: lazyLoadBoxSetContributorsRefetch
    } = useManagerLoadBoxSetContributors(props, boxset?.id);

    //console.log(boxsetContributors);


    const createSecondaryActions = (boxsetContributor) => {

        let secondaryActions = [];

        if (props.showSelectIcon) {
            secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
                //console.log(boxsetContributor)
                props.onSelected(boxsetContributor);
            }}>
                <ShareIcon />
            </IconButton>);
        }

        return secondaryActions;
    }

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <StandardList
            // title='Contributors'
            // headerActions={<AddContributorAssembly boxset={boxset}/>}
            component="div"
            disablePadding>
            {boxsetContributors && boxsetContributors.length > 0 ?
                boxsetContributors.map((boxsetContributor) => {

                    return <>

                        <ListItem
                            alignItems="flex-start"

                            secondaryAction={



                                <Box sx={{
                                    display: 'flex'
                                }}>
                                    <CancelTableCell onClick={(e) => {
                                        e.preventDefault();
                                        setShowRemoveContributorDialog(true)
                                        setSelectedContributor(boxsetContributor.contributor)

                                    }} />


                                </Box>







                            }>
                            <ListItemAvatar
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelected(boxsetContributor);
                                }}>
                                <CircleAvatar
                                    //bucketName={config.user_uploads_bucket_name}
                                    s3Src={boxsetContributor?.contributor?.profilePicUrl}
                                    missingContent={<img src={missingBookImage} />}
                                />
                                {/* <Avatar alt="Remy Sharp" variant="square" src="https://local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm.s3.amazonaws.com/uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/c7cb5750-3627-11ed-914f-5d91b6c4c7eb_planetStrider.png">

              </Avatar> */}
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    noWrap: true
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelected(boxsetContributor);
                                }}
                                primary={boxsetContributor?.contributor?.name}
                                secondary={
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            Since date
                                        </Typography>
                                        {/* <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="caption"
                                            color="text.secondary"
                                        >
                                            Words: 2,432
                                        </Typography> */}
                                        {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                    </Box>
                                }

                            />

                        </ListItem>
                        <Divider variant="inset"/>

                        <StandardOkCancelDialog
                            showOkButton={true}
                            showCancelButton={true}
                            open={showRemoveContributorDialog}
                            onClose={() => {
                                setShowRemoveContributorDialog(false)
                                setSelectedContributor(null)
                            }}
                            onOk={() => {
                                setShowRemoveContributorDialog(false)
                                setSelectedContributor(null)

                            }}
                            title={'Remove Contributor'}
                            content={
                                <RemoveBoxsetContributorPanel boxset={boxset} contributor={selectedContributor} onRemoved={() => {
                                    setShowRemoveContributorDialog(false)
                                    setSelectedContributor(null)
                                    lazyLoadBoxSetContributorsRefetch();

                                }} />
                            }
                        />

                    </>
                    // <Divider variant="inset"/>


                    // return <ListItem secondaryAction={createSecondaryActions(book)}>
                    //   <ListItemAvatar>
                    //     <AvatarTableCell
                    //       bucketName={config.user_uploads_bucket_name}
                    //       s3Src={book?.coverUrl}
                    //     //title={userConnection?.connectedUser?.name}
                    //     />
                    //   </ListItemAvatar>
                    //   <ListItemText primary={book?.title} secondary="" />

                    // </ListItem>
                })
                :
                <Box sx={{
                  marginTop: '3em'
                }}>
                  <EmptyContent tagline={'Contributors will show up here.'} />
                </Box>
                
            }
            {/* {ownedBooks.length}:
      {availableBooks.length} */}
        </StandardList>

    )
}
export default inject('stores')(observer(LaurasListItem));