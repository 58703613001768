import React, { useEffect, useState } from 'react';
import helpers from '../helpers/helpers';
import config from '../aws-exports';
import {
    useGetBooks
} from '../persistance/persistanceHooks';
import { ResourceExtractor } from '../components/formatting/propertyElements/RequiredResources';
import { Book } from '../domain/bookModel';
import { observable } from 'mobx';

const AWS = require("aws-sdk");

class StorageManager {

    constructor() {

    }



}

export function useGetBucketItems(props) {

    const [bucketItems, setBucketItems] = useState([]);

    const getBucketItems = ({
        bucketName,
        folder
    }) => {


        let s3 = new AWS.S3({
            apiVersion: "2006-03-01",
            params: { Bucket: bucketName }
        });

        try {

            s3.listObjects({
                Bucket: bucketName,
                Prefix: folder + '/',
                Delimiter: "/"
            }, function (err, data) {
                if (err) {
                    //console.log("There was an error listing your albums: " + err.message);
                } else {

                    //console.log(data);

                    let files = data.Contents.filter(f => f.Key != folder + '/');

                    setBucketItems(files);
                    // let files = data.Contents.filter(f => f.Key != folder + '/');
                    // setFiles(files);
                    // let fileItems = files.map((file) => {
                    //     return <ImageListItem key={file.Key} cols={file.cols || 1} rows={file.rows || 1}>
                    //         <Image
                    //             width={'3em'}
                    //             height={'3em'}
                    //             onClick={(e) => {

                    //                 handleImageSelected(file.Key);



                    //             }}
                    //             {...srcset(convertUrl(file.Key), 121, file.rows, file.cols)}
                    //             showLoading
                    //             errorIcon />

                    //     </ImageListItem>


                    // })



                }
            });

        } catch (err) {
            console.log(err);
        }
    }

    return { getBucketItems, bucketItems };
}


export function useUploadBucketItem(props) {

    let AWS = require("aws-sdk");

    const uploadBucketItem = ({
        bucketName,
        file,
        userId,
        storageType
    }, callback) => {

        let s3 = new AWS.S3({
            apiVersion: "2006-03-01",
            params: { Bucket: bucketName }
        });

        let folder = 'folder1';
        let fileName = file.name;
        let folderKey = encodeURIComponent(folder) + "/";

        let fileKey = folderKey + fileName;

        if (storageType && storageType == StorageManager.STORAGE_TYPE_USER_IMAGE) {

            bucketName = config.user_uploads_bucket_name;
            fileKey = helpers.createUserImageUploadS3Key(userId, fileName);

        }

        if (storageType && storageType == StorageManager.STORAGE_TYPE_WORD_IMPORT) {

            bucketName = config.user_uploads_bucket_name;
            fileKey = helpers.createUserBookImportUploadS3Key(userId, fileName);

        }



        // Use S3 ManagedUpload class as it supports multipart uploads
        let upload = new AWS.S3.ManagedUpload({
            params: {
                Bucket: bucketName,
                Key: fileKey,
                Body: file,
                ACL: 'public-read'
            }
        });

        let promise = upload.promise();

        promise.then(
            function (data) {
                //console.log(data);

                if (callback) {
                    callback({
                        bucketName: data.Bucket,
                        s3Key: data.Key
                    });
                }
                // entity[imageKeyFieldName] = fileKey;

                // //console.log(entity);
                // updateItem({
                //     itemKey: entity.id,
                //     itemType: entity.modelType ? entity.modelType : entity.__typename,
                //     fieldName: imageKeyFieldName,
                //     fieldValue: fileKey
                // })

                // if (props.onChange) {
                //     props.onChange();
                // }


            },
            function (err) {
                console.log(err);
                if (callback) {
                    callback({}, err);
                }
            }
        );

    };

    return {
        uploadBucketItem
    }

}

export function useGetMappedImages(props, bookDraft) {

    const [books, setBooks] = useState([]);
    const [mappedImages, setMappedImages] = useState([]);

    const { loading, error, data, refetch, networkStatus } = useGetBooks();


    useEffect(() => {


        try {
            //console.log(data);
            if (data) {

                let books = [];

                data.listBooks.items.filter(f => f.deletedAt == null).forEach((bookData) => {

                    let book = new observable(new Book({
                        data: bookData
                    }))
                    //console.log(book);
                    books.push(book);

                });

                setBooks(books);

                
                

            }
        } catch (err) {
            console.log(err);
        }

    }, [data]);





    useEffect(() => {


        if (books != null && bookDraft != null) {


            //let calculatedRecipe = documentPart?.getRecipe().json;

            let mergedBooks = props.stores.bookStore.authoredBooks

            try {
                mergedBooks = [].concat(mergedBooks);
                if (bookDraft.book.boxSet) {

                    bookDraft.book.boxSet.boxSetBooks.forEach((bsb) => {
                        mergedBooks.push(bsb.book);
                    });
                }



            } catch (err) {
                console.log(err);
            }




            try {

                bookDraft.bookDraftDocumentParts.forEach((bddp) => {

                    if (bddp.documentPart.alsoByBooks.items.length > 0) {

                        mergedBooks = mergedBooks.concat(bddp.documentPart.alsoByBooks.items);

                    }

                });


            } catch (err) {
                console.log(err);
            }

          
            
                let currentInflatedTemplates = props.stores.bookStore.currentInflatedTemplates;

                new ResourceExtractor().getAllResources({
                    recipeJson: bookDraft.book.recipe.json,//calculatedRecipe,
                    inflatedTemplates: currentInflatedTemplates,
                    //allBooks:stores.bookStore.authoredBooks,
                    allBooks: mergedBooks,
                    bookDraft: bookDraft
                }, (mappedImages) => {

                    //console.log(mappedImages);
                    setMappedImages(mappedImages);

                    bookDraft.mappedImages = mappedImages;
                    bookDraft.book.mappedImages = mappedImages;
                });

            

        }

    }, [books]);





    return { mappedImages};




}

StorageManager.STORAGE_TYPE_USER_IMAGE = 'USER_IMAGE';
StorageManager.STORAGE_TYPE_WORD_IMPORT = 'WORD_IMPORT';

export default StorageManager;