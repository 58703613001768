import Quill from 'quill';
//import ReactQuill, { Quill } from 'react-quill';
let Block = Quill.import('blots/block');

let Parchment = Quill.import("parchment");

class AttributionBlot extends Block { }
AttributionBlot.blotName = 'attribution';
AttributionBlot.tagName = 'div';
AttributionBlot.className = 'attribution';

export default AttributionBlot ;
