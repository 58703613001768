import React, { useEffect, useState } from 'react';
import { styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useMediaQuery } from 'react-responsive';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

function StandardSelect(props) {

    const theme = useTheme();


    const { label, checked } = props;


    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    return (

        <Box sx={{
            height: '100%',
            position: 'relative'
        }}>
            <FormControl >
                <InputLabel >{props.inputLabel}</InputLabel>
                {props.content}

            </FormControl>
        </Box>


    );
}


export default inject('stores')(observer(StandardSelect));



