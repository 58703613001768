import React, { useState, useEffect } from 'react';
import { observable } from 'mobx';
import { useLazyGetBookDraft, useLazyLoadInflatables, useLazyGetVelocityBookDraft } from './persistanceHooks';
import { BookDraft, Book } from '../domain/bookModel';



export function useLoadInflatables({
    bookStore,
    loadIntoStore=false
}) {


    const [inflatablesLazyGetBookDraft, { loading:inflatablesLazyGetBookDraftLoading, error:inflatablesLazyGetBookDraftError, data:inflatablesLazyGetBookDraftData }] = useLazyLoadInflatables({
		
	});

    const wrappedMutation = () => {
		//console.log('loading inflatables...')
        inflatablesLazyGetBookDraft({
            variables:{
                bookDraftId: 'e70149b0-2c83-11e9-9f0b-bd30087e3184'
            }
        })
    }

	useEffect(() => {

		
		if (inflatablesLazyGetBookDraftData) {

			try {

				let templatesObject = {

				}

				bookStore.setInflatablesBookDraft(new BookDraft(inflatablesLazyGetBookDraftData.getBookDraft))

				bookStore.inflatablesBookDraft.bookDraftDocumentParts.forEach((bddp) => {

					try {
						if (bddp.documentPart.partType == 'TEMPLATE') {

							bddp.documentPart.content.replace(/\n/ig, '')
							let contentJson = JSON.parse(bddp.documentPart.content.replace(/\n/ig, '').replace(/\t/ig, ''));
							templatesObject[bddp.documentPart.id] = observable(contentJson);
						}
					} catch (err) {
						//This is a big deal error. Should not happen. 
						//console.log('error parsing inflatables content');
						//console.log(err);
					}

				});

				//setCurrentInflatedTemplates(templatesObject);
				bookStore.currentInflatedTemplates = observable(templatesObject);




			} catch (err) {

				console.log(err);
			}

		}
		//console.log(inflatablesLazyGetBookDraftData);

	}, [inflatablesLazyGetBookDraftData]);

    return [wrappedMutation]


}


export function useLoadStarters({
    bookStore,
    loadIntoStore=false
}) {


    const [startersLazyGetBookDraft, { loading:startersLazyGetBookDraftLoading, error:startersLazyGetBookDraftError, data:startersLazyGetBookDraftData }] = useLazyGetBookDraft({
		
	});

    const wrappedMutation = () => {
		//console.log('loading starters...')
        startersLazyGetBookDraft({
            variables:{
                bookDraftId: 'dadcc561-8843-11eb-bfb6-9f3c5403dc43'
            }
        })
    }

    useEffect(()=>{
        
		if (startersLazyGetBookDraftData && loadIntoStore) {

			try {

				bookStore.setStartersBookDraft(new BookDraft(startersLazyGetBookDraftData.getBookDraft))

				bookStore.startersBookDraft.bookDraftDocumentParts.forEach((bddp) => {

					////console.log(bddp.documentPart.title);
					////console.log(bddp.documentPart);
					try {
						if (bddp.documentPart.partType == 'TEMPLATE') {

							bddp.documentPart.content.replace(/\n/ig, '')
							let contentJson = JSON.parse(bddp.documentPart.content.replace(/\n/ig, '').replace(/\t/ig, ''));
							bddp.documentPart.contentJson = contentJson;

							//console.log(contentJson);

						}
					} catch (err) {
						//This is a big deal error. Should not happen. 
						//console.log(err);
					}

				});





			} catch (err) {

				//console.log(err);
			}

		}
		//console.log(startersLazyGetBookDraftData);
    },[startersLazyGetBookDraftData])

    return [wrappedMutation]


}



export function useLoadVelocity({
    bookStore,
    loadIntoStore=false
}) {


    const [velocityLazyGetBookDraft, { loading:velocityLazyGetBookDraftLoading, error:velocityLazyGetBookDraftError, data:velocityLazyGetBookDraftData }] = useLazyGetVelocityBookDraft({
		fetchPolicy:'network-only'
	});

    const wrappedMutation = () => {
        velocityLazyGetBookDraft({
            variables:{
                bookDraftId: '7693e4e0-1770-11e8-8a4f-797a71458945'
            }
        })
    }

	useEffect(()=>{

		//console.log(velocityLazyGetBookDraftError);

	},[velocityLazyGetBookDraftError]);

    useEffect(()=>{
        
		if (velocityLazyGetBookDraftData && loadIntoStore) {

			try {

				bookStore.setVelocityBookDraft(new BookDraft(velocityLazyGetBookDraftData.getBookDraft))

				bookStore.velocityBookDraft.bookDraftDocumentParts.forEach((bddp) => {

					////console.log(bddp.documentPart.title);
					////console.log(bddp.documentPart);
					try {
						if (bddp.documentPart.partType == 'TEMPLATE') {

							bddp.documentPart.content.replace(/\n/ig, '')
							let contentJson = JSON.parse(bddp.documentPart.content.replace(/\n/ig, '').replace(/\t/ig, ''));
							bddp.documentPart.contentJson = contentJson;

							//console.log(contentJson);

						}
					} catch (err) {
						//This is a big deal error. Should not happen. 
						//console.log(err);
					}

				});





			} catch (err) {

				//console.log(err);
			}

		}
		//console.log(velocityLazyGetBookDraftData);
    },[velocityLazyGetBookDraftData])

    return [wrappedMutation]


}