import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { styled, Box } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({

    //backgroundColor:'red'

}))

function FormatToolbar(props) {

    let { documentPart } = props;
    

    useEffect(() => {

        try {

          
            

        } catch (err) {
            console.log(err);
        }
    }, [])


    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start'
            }}>
                <StyledToggleButtonGroup
                    size="small"
                    value={props.developmentBookDraftSource}
                    exclusive
                    onChange={(e)=>{
                    
                        props.onChangeDevelopmentBookDraftSource(e.target.value);

                    }}
                    aria-label=""
                >
                    <ToggleButton value="book" aria-label="">
                        {/* <FormatAlignLeftIcon /> */}
                        BOOK
                    </ToggleButton>
                    <ToggleButton value="inflatables" aria-label="">
                        {/* <FormatAlignCenterIcon /> */}
                        INFLATABLES
                    </ToggleButton>
                    <ToggleButton value="velocity" aria-label="">
                        {/* <FormatAlignRightIcon /> */}
                        VELOCITY
                    </ToggleButton>
                    <ToggleButton value="starters" aria-label="">
                        {/* <FormatAlignRightIcon /> */}
                        STARTERS
                    </ToggleButton>
                </StyledToggleButtonGroup>
                
                
            </Box>




        </Box>

    );
}

export default inject('stores')(observer(FormatToolbar));
