import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from "@mui/material";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import MainMenuContent from './menus/MainMenuContent';


const StandardDrawer = styled(Drawer)(() => {


})


function StandardSideDrawer(props) {

    let navigate = useNavigate();
    let location = useLocation();

    let [openBooks, setOpenBooks] = useState(true);

    const subItemsIndent = 10;

    const isRoute = () => {
        return 'primary';
    }

    const handleSelection = () => {

    }

    const onLogout = event => {
        event.preventDefault();
        props.stores.bookStore.loggedIn = false;

        Auth.signOut()
            .then(data => {
                //console.log(data);

                props.stores.bookStore.loggedIn = false;
                //props.stores.bookStore.reset();

            })
            .catch(err => {
                console.log(err);

            })
    };

    const selectRoute = (route) => {

        navigate(route);
        props.onRouteSelected();

    }

    return (


        <Drawer
            //variant="permanent"
            open={props.open}
            //open={true}
            onClose={props.onClose}
            {...props}
        >

            <MainMenuContent onRouteSelected={props.onRouteSelected} toggleDarkMode={props.toggleDarkMode} />
        </Drawer>
    );
}

export default inject('stores')(observer(StandardSideDrawer));

