import clone from 'clone';
import equal from 'deep-equal';
import extend from 'extend';
import Delta from 'quill-delta';
import DeltaOp from 'quill-delta/lib/op';
import Parchment from 'parchment';
import DeltaManager from '../DeltaManager';
//import logger from '../core/logger';
import Quill from 'quill';
import Module from './module';
import editingUtils from '../../../helpers/editingUtils';
//let debug = logger('quill:keyboard');

const SHORTKEY = /Mac/i.test(navigator.platform) ? 'metaKey' : 'ctrlKey';
const ASCII = /^[ -~]*$/;

class Keyboard extends Module {
  static match(evt, binding) {
    binding = normalize(binding);
    if (['altKey', 'ctrlKey', 'metaKey', 'shiftKey'].some(function (key) {
      return (!!binding[key] !== evt[key] && binding[key] !== null);
    })) {
      return false;
    }
    return binding.key === (evt.which || evt.keyCode);
  }

  constructor(quill, options) {
    super(quill, options);
    this.bindings = {};
    Object.keys(this.options.bindings).forEach((name) => {
      if (name === 'list autofill' &&
        quill.scroll.whitelist != null &&
        !quill.scroll.whitelist['list']) {
        return;
      }
      if (this.options.bindings[name]) {
        this.addBinding(this.options.bindings[name]);
      }
    });
    this.addBinding({ key: Keyboard.keys.ENTER, shiftKey: null }, handleEnter);
    this.addBinding({ key: Keyboard.keys.ENTER, metaKey: null, ctrlKey: null, altKey: null }, function () { });
    if (/Firefox/i.test(navigator.userAgent)) {
      // Need to handle delete and backspace for Firefox in the general case #1171
      this.addBinding({ key: Keyboard.keys.BACKSPACE }, { collapsed: true }, handleBackspace);
      this.addBinding({ key: Keyboard.keys.DELETE }, { collapsed: true }, handleDelete);
    } else {
      this.addBinding({ key: Keyboard.keys.BACKSPACE }, { collapsed: true, prefix: /^.?$/ }, handleBackspace);
      this.addBinding({ key: Keyboard.keys.DELETE }, { collapsed: true, suffix: /^.?$/ }, handleDelete);
    }
    this.addBinding({ key: Keyboard.keys.BACKSPACE }, { collapsed: false }, handleDeleteRange);
    this.addBinding({ key: Keyboard.keys.DELETE }, { collapsed: false }, handleDeleteRange);
    this.addBinding({ key: Keyboard.keys.BACKSPACE, altKey: null, ctrlKey: null, metaKey: null, shiftKey: null },
      { collapsed: true, offset: 0 },
      handleBackspace);
    this.listen();
  }

  addBinding(key, context = {}, handler = {}) {
    let binding = normalize(key);
    if (binding == null || binding.key == null) {
      //return debug.warn('Attempted to add invalid keyboard binding', binding);
    }
    if (typeof context === 'function') {
      context = { handler: context };
    }
    if (typeof handler === 'function') {
      handler = { handler: handler };
    }
    binding = extend(binding, context, handler);
    this.bindings[binding.key] = this.bindings[binding.key] || [];
    this.bindings[binding.key].push(binding);
  }

  listen() {

    this.quill.root.addEventListener('keydown', (evt) => {

      //console.log('keyboard.listen()...');
      let editMode = this.options.editMode;
      let disabled = this.options.disabled;

      /********************************************************************
      If disabled ignore all keyboard inputs. 
      *********************************************************************/
      if (disabled) {
        
        evt.preventDefault();
        return;

      }


      //let bookStore = this.options.stores.bookStore;
      let range = this.quill.getSelection();


      if (evt.defaultPrevented) return;

      /********************************************************************
      If there are bindings, defer to them and abandon editing. 
      *********************************************************************/
      let which = evt.which || evt.keyCode;
      let bindings = (this.bindings[which] || []).filter(function (binding) {
        return Keyboard.match(evt, binding);
      });
      //if (bindings.length === 0) return;


      /********************************************************************
      If the cursor does not exist or the editor does not have focus return. 
      *********************************************************************/
      if (range == null || !this.quill.hasFocus()) return;

      /********************************************************************
      If in edit mode, handle editing. Else, standard processing will be used. 
      *********************************************************************/
      if (editMode) {
        this.editProcessing(evt);
        //evt.preventDefault();
        return;

      } else {

        this.standardProcessing(evt);
      }


    });
  }

  editProcessing(evt) {

    let bookStore = this.options.stores.bookStore;
    let { documentPart } = this.options;
    let range = this.quill.getSelection();
    let selectedFormat = this.quill.getFormat();
    let selectedContentsDelta = this.quill.getContents(range);
    let finalPartialDelta = null;
    let finalPartialDocumentPart = null;
    let finalPartialSourceDelta = null;
    let finalPartialSourceDocumentPart = null;

    let finalTombstoneDocumentPart = null;
    let finalTombstoneDeleteDelta = null;
    let finalTombstoneInsertDelta = null;



    let finalSelectionRange = range;

    /********************************************************************
      TODO:
      1: Highlight text then drag it with the cursor. Handle this situation.
      *********************************************************************/



    /********************************************************************
    Is this just a cursor adjustment ( avoiding cursor to immediate right 
    of EditingInsertNewLineBlot2? )
    *********************************************************************/
    // if (this.iscursorAdjustment(evt)) {
    //   //this.standardProcessing(evt);
    //   return;
    // }

    /********************************************************************
      Is this a key that we ignore for editing purposes? 
      TODO: Make these checks cross-browser gauranteed.
      *********************************************************************/
    if (this.isNonEditKeystroke(evt)) {
      this.standardProcessing(evt);
      return;
    }

    /********************************************************************
    Since we are gonna handle this keypress here, prevent its event
    from propagating.
    *********************************************************************/
    evt.preventDefault();

    /********************************************************************
    Extract all content editing parts. 
    *********************************************************************/
    let parts = editingUtils.extractParts({
      quill: this.quill,
      range: this.quill.getSelection()
    });

    this.quill.setSelection(range, Quill.sources.SILENT);

    /********************************************************************
    Is the cursor sitting within a tombstone. 
    *********************************************************************/
    let cursorFormats = this.quill.getFormat();
    //Cannot count on this since it gives the format of content behind the cursor. 
    //For deletes need the content ahead of the cursor. 
    if (cursorFormats && cursorFormats.editingMovedTombstoneBlot) {
      //return;
    }

    /********************************************************************
    Determine if trying to edit an uneditable area ( blots ). 
    *********************************************************************/
    if (editingUtils.hasBannedFormats({
      delta: selectedContentsDelta
    })) {

      //this.standardProcessing(evt);
      return;

    }

    let editAction = this.getEditAction({
      evt,
      quill: this.quill,
      range,
      parts
    });
    //console.log(parts);
    let deltaManager = new DeltaManager();

    switch (editAction) {

      case Keyboard.EDIT_ACTION_DELETE_EXISTING_INSERT:
        //console.log('will perform a delete existing insert');

        finalPartialDelta = editingUtils.deleteExistingInsert({
          quill: this.quill,
          range: {
            index: range.index,
            length: 1
          },
          bookStore,
          insertedText: evt.key
          //documentPart
        });


        break;


      case Keyboard.EDIT_ACTION_DELETE_EXISTING_DELETE:
        //console.log('will perform a delete existing delete');
        //simply advance the cursor one spot forward. 
        finalSelectionRange = {
          index: range.index + 1,
          length: 0
        }


        break;




      case Keyboard.EDIT_ACTION_DELETE:

        //console.log('will perform a delete');
        //check if we are trying to delete tombstone content.
        cursorFormats = this.quill.getFormat({
          index: range.index,
          length: 1
        });
        //Cannot count on this since it gives the format of content behind the cursor. 
        //For deletes need the content ahead of the cursor. 
        if (cursorFormats && cursorFormats.editingMovedTombstoneBlot) {
          return;
        }




        if (range && range.length == 0) {

          finalPartialDelta = editingUtils.deleteAhead({
            quill: this.quill,
            range,
            bookStore,
            insertedText: evt.key
            //documentPart
          });

          //If we actually made a change, then update the finalSelectionRange.
          //Otherwise, leave it alone so that the initial range will be applied.
          //This will ensure that the cursor is back where it started when no change
          //is made. 
          if (finalPartialDelta) {
            finalSelectionRange = {
              index: range.index + 1,
              length: 0
            }
          }



        } else if (parts.length > 0) {

          finalPartialDelta = editingUtils.keyboardModule_handleDeletePressed({
            quill: this.quill,
            range,
            bookStore,
            insertedText: evt.key,
            parts
            //documentPart
          });

          finalSelectionRange = {
            index: range.index + range.length,
            length: 0
          }


        }



        break;


      case Keyboard.EDIT_ACTION_BACKSPACE_EXISTING_INSERT:

        finalPartialDelta = editingUtils.deleteExistingInsert({
          quill: this.quill,
          range: {
            index: range.index - 1,
            length: 1
          },
          bookStore,
          insertedText: evt.key
          //documentPart
        });

        if (finalPartialDelta) {
          finalSelectionRange = {
            index: range.index - 1,
            length: 0
          }
        }



        break;

      case Keyboard.EDIT_ACTION_BACKSPACE:

        //console.log('will perform a backspace');

        cursorFormats = this.quill.getFormat({
          index: range.index - 1,
          length: 1
        });
        //Cannot count on this since it gives the format of content behind the cursor. 
        //For deletes need the content ahead of the cursor. 
        if (cursorFormats && cursorFormats.editingMovedTombstoneBlot) {
          return;
        }


        if (range && range.length > 0) {

          finalPartialDelta = editingUtils.keyboardModule_handleDeletePressed({
            quill: this.quill,
            range,
            bookStore,
            insertedText: evt.key
            //documentPart
          });

          finalSelectionRange = {
            index: range.index,
            length: 0
          }

        }
        else if (range.index == 0 && range.length == 0) {
          //do nothing
        } else {

          finalPartialDelta = editingUtils.keyboardModule_backspaceDeletePressed({
            quill: this.quill,
            range,
            bookStore,
            insertedText: evt.key
            //documentPart
          });

          finalSelectionRange = {
            index: range.index - 1,
            length: 0
          }

        }
        break;

      case Keyboard.EDIT_ACTION_INSERT:

        //console.log('will perform an insert');



        let { formatBehind, formatAhead } = editingUtils.getNearbyFormats({
          range,
          //formatType: 'editingInsertBlot',
          quill: this.quill
        });
        if (formatAhead.editingMovedTombstoneBlot != null && formatBehind.editingMovedTombstoneBlot != null) {
          return null;
        }
        // else if (formatAhead.editingDeleteBlot != null) {
        //   return Keyboard.EDIT_ACTION_DELETE_EXISTING_DELETE;
        // }




        



        finalPartialDelta = editingUtils.keyboardModule_handleKeyPressed({
          cursorFormats,
          quill: this.quill,
          range,
          bookStore,
          insertedText: evt.key
          //documentPart
        });

        finalSelectionRange = {
          index: range.index + 1,
          length: 0
        }

        break;

      case Keyboard.EDIT_ACTION_INSERT_NEWLINE:

        //console.log('will perform insert newline');

        finalPartialDelta = editingUtils.keyboardModule_handleInsertNewline({
          quill: this.quill,
          range,
          bookStore,
          insertedText: evt.key,
          cursorFormats
        });

        finalSelectionRange = {
          index: range.index + 1,
          length: 0
        }

        break;



      case Keyboard.EDIT_ACTION_BACKSPACE_NEWLINE:

        //console.log('will perform backspace newline');

        finalPartialDelta = editingUtils.keyboardModule_handleBackspaceNewline({
          quill: this.quill,
          range,
          bookStore,
          insertedText: evt.key
          //documentPart
        });

        break;

      case Keyboard.EDIT_ACTION_DELETE_NEWLINE:

        //console.log('will perform delete newline');

        finalPartialDelta = editingUtils.keyboardModule_handleDeleteNewline({
          quill: this.quill,
          range,
          bookStore,
          insertedText: evt.key
          //documentPart
        });

        break;


      case Keyboard.EDIT_ACTION_DELETE_REMOVE_INSERT_NEWLINE:

        //console.log('will perform remove inserted newline');

        finalPartialDelta = editingUtils.keyboardModule_handleDeleteRemoveInsertedNewline({
          quill: this.quill,
          range,
          bookStore,
          insertedText: evt.key
          //documentPart
        });

        break;

      case Keyboard.EDIT_ACTION_BACKSPACE_REMOVE_INSERT_NEWLINE:

        //console.log('will perform remove inserted newline');

        finalPartialDelta = editingUtils.keyboardModule_handleBackspaceRemoveInsertedNewline({
          quill: this.quill,
          range,
          bookStore,
          insertedText: evt.key
          //documentPart
        });

        if (finalPartialDelta) {
          finalSelectionRange = {
            index: range.index - 1,
            length: 0
          }
        }
        break;








      case Keyboard.EDIT_ACTION_COPY:

        //console.log('will perform a copy');

        break;

      case Keyboard.EDIT_ACTION_PASTE:

        //console.log('will perform a paste');

        break;

      case Keyboard.EDIT_ACTION_PREPARE_MOVE:

        //console.log('will perform a pre-move');
        if (parts.length > 0) {

          let hasDestinationParts = false;
          let hasNonDestinationParts = false;
          parts.forEach((part) => {

            if (part.blot.editingMovedDestinationBlot) hasDestinationParts = true;
            if (!part.blot.editingMovedDestinationBlot) hasNonDestinationParts = true;
          })

          if (hasDestinationParts && hasNonDestinationParts) {
            alert('cannot move part of a destination blot')
          }
        }

        let { updatedRange } = editingUtils.copySource({
          range,
          quill: this.quill,
          bookStore,
          documentPart,
          parts
        });

        finalSelectionRange = updatedRange;




        break;

      case Keyboard.EDIT_ACTION_MOVE:

        //console.log('will perform a move');

        let {
          sourceChangeDelta,
          destinationChangeDelta,
          sourceChangeDocumentPart,
          destinationChangeDocumentPart,
          postMoveSelection,
          tombstoneDocumentPart,
          tombstoneDeleteDelta,
          tombstoneInsertDelta } = editingUtils.moveSource({
            range,
            quill: this.quill,
            bookStore,
            documentPart
          });

        //console.log(sourceChangeDelta);
        //console.log(destinationChangeDelta);

        finalPartialSourceDelta = sourceChangeDelta;
        finalPartialSourceDocumentPart = sourceChangeDocumentPart;
        finalPartialDelta = destinationChangeDelta;


        finalTombstoneDocumentPart = tombstoneDocumentPart;
        finalTombstoneDeleteDelta = tombstoneDeleteDelta;
        finalTombstoneInsertDelta = tombstoneInsertDelta;

        break;

    }



    //If there are two different document parts for source and destination, must prevent undo. 
    //Because an undo at the source or the destination will not automatically undo the opposite side. 
    //TODO: Add code to undo both if possible. 
    if (finalPartialSourceDelta) {

      //This null check should only be needed while developing. Get rid of this!
      if (finalPartialDelta) {
        bookStore.postDocumentPartEvent({
          partId: documentPart.id,
          delta: finalPartialDelta,
          quillSourceType: 'api'
        })
        documentPart.appendDelta(finalPartialDelta);
      }


      //=========> Update the contents
      if (finalPartialSourceDocumentPart && finalPartialSourceDocumentPart.id == documentPart.id) {

        //this.quill.updateContents(finalPartialSourceDelta, "user");

        //If the source and destination are in the same documentPart allow an undo ( i.e. quillSourceType:'user' )
        bookStore.postDocumentPartEvent({
          partId: finalPartialSourceDocumentPart.id,
          delta: finalPartialSourceDelta,
          quillSourceType: 'user'
        })
        finalPartialSourceDocumentPart.appendDelta(finalPartialSourceDelta);

      } else {


        // let quills = bookStore.quillInstanceByPartId[finalPartialSourceDocumentPart.id];
        // quills.forEach((quill) => {
        //   console.log(quill);
        //   quill.history.clear();
        //   quill.history.cutoff();
        //   quill.updateContents(finalPartialSourceDelta, "api");
        // })

        //If the source and destination are NOT in the same documentPart disallow an undo ( i.e. quillSourceType:'api' )
        bookStore.postDocumentPartEvent({
          partId: finalPartialSourceDocumentPart.id,
          delta: finalPartialSourceDelta,
          quillSourceType: 'api'
        })
        finalPartialSourceDocumentPart.appendDelta(finalPartialSourceDelta);

        // this.options.saveDeltaCallback({
        //   documentPart: finalPartialSourceDocumentPart,
        //   delta: finalPartialSourceDelta
        // });

      }


      //=========> Update blots timestamp
      //bookStore.setBlotsChangedTimestamp();

      //=========> Set the new cursor location

    }

    else if (finalPartialDelta) {
      //=========> Update the contents
      //this.quill.updateContents(finalPartialDelta, "user");

      bookStore.postDocumentPartEvent({
        partId: documentPart.id,
        delta: finalPartialDelta,
        quillSourceType: 'user'
      })

      documentPart.appendDelta(finalPartialDelta);
      //=========> Update blots timestamp
      //bookStore.setBlotsChangedTimestamp();

      //=========> Set the new cursor location

    }


    if (finalTombstoneDeleteDelta) {

      bookStore.postDocumentPartEvent({
        partId: finalTombstoneDocumentPart.id,
        delta: finalTombstoneDeleteDelta,
        quillSourceType: 'api'
      })

      finalTombstoneDocumentPart.appendDelta(finalTombstoneDeleteDelta);

      bookStore.postDocumentPartEvent({
        partId: finalTombstoneDocumentPart.id,
        delta: finalTombstoneInsertDelta,
        quillSourceType: 'api'
      })

      finalTombstoneDocumentPart.appendDelta(finalTombstoneInsertDelta);



    }


    bookStore.setBlotsChangedTimeout();




    //If the finalSelectionRange was not altered, the original range should be applie. 
    this.quill.setSelection(finalSelectionRange);

  }



  getEditAction({
    evt,
    quill,
    range,
    parts
  }) {

    if (evt && evt.key == 'Delete') {

      let atEOF = false;
      let totalLength = quill.getLength();
      if (range.index == (totalLength - 1)) {
        atEOF = true;
        return;
      }
      //Check to see if we are trying to delete an existing insert
      if (range && range.length == 0) {
        let { formatBehind, formatAhead } = editingUtils.getNearbyFormats({
          range,
          //formatType: 'editingInsertBlot',
          quill: this.quill
        });
        if (formatAhead.editingInsertBlot != null) {
          return Keyboard.EDIT_ACTION_DELETE_EXISTING_INSERT;
        } else if (formatAhead.editingDeleteBlot != null) {
          return Keyboard.EDIT_ACTION_DELETE_EXISTING_DELETE;
        }
        //console.log(formatAhead);

        if (editingUtils.aheadIsNewLine({
          quill: this.quill,
          range
        })) {
          return Keyboard.EDIT_ACTION_DELETE_NEWLINE;
        }



      }



      if (editingUtils.rangeAheadIsEditingInsertNewLine({
        quill: this.quill,
        range
      })) {
        return Keyboard.EDIT_ACTION_DELETE_REMOVE_INSERT_NEWLINE;
      }



      return Keyboard.EDIT_ACTION_DELETE;
    }

    if (evt && evt.key == 'Backspace') {
      //Check to see if we are trying to delete an existing insert
      if (range.index == 0 && range.length == 0) {
        //cannot backspace when at the beginning of the document
        return null;
      }
      else if (range && range.length == 0) {
        let { formatBehind, formatAhead } = editingUtils.getNearbyFormats({
          range,
          //formatType: 'editingInsertBlot',
          quill: this.quill
        });
        if (formatBehind.editingInsertBlot != null) {
          return Keyboard.EDIT_ACTION_BACKSPACE_EXISTING_INSERT;
        }
        //console.log(formatBehind);
      }

      if (editingUtils.rangeIsEditingInsertNewLine({
        quill: this.quill,
        range
      })) {
        return Keyboard.EDIT_ACTION_BACKSPACE_REMOVE_INSERT_NEWLINE;
      }

      if (editingUtils.behindIsNewLine({
        quill: this.quill,
        range
      })) {
        return Keyboard.EDIT_ACTION_BACKSPACE_NEWLINE;
      }


      // if(range.index>0){
      //   let characterBehindDelta = this.quill.getContents({
      //     index:range.index-1,
      //     length:1
      //   });
      //   console.log(characterBehindDelta);
      // }
      return Keyboard.EDIT_ACTION_BACKSPACE;
    }


    if (evt.code == 'Enter' || evt.code == 'NumpadEnter') {
      return Keyboard.EDIT_ACTION_INSERT_NEWLINE;
    }

    if (evt.code == 'KeyV' && evt.ctrlKey) {
      return Keyboard.EDIT_ACTION_PASTE;
    }

    if (evt.code == 'KeyC' && evt.ctrlKey) {
      return Keyboard.EDIT_ACTION_COPY;
    }

    if (evt.code == 'KeyQ' && evt.ctrlKey) {
      return Keyboard.EDIT_ACTION_PREPARE_MOVE;
    }

    if (evt.code == 'KeyM' && evt.ctrlKey) {
      return Keyboard.EDIT_ACTION_MOVE;
    }

    return Keyboard.EDIT_ACTION_INSERT;





    //     Keyboard.EDIT_ACTION_DELETE = 'EDIT_ACTION_DELETE';
    // Keyboard.EDIT_ACTION_BACKSPACE = 'EDIT_ACTION_BACKSPACE';
    // Keyboard.EDIT_ACTION_INSERT = 'EDIT_ACTION_INSERT';
    // Keyboard.EDIT_ACTION_COPY = 'EDIT_ACTION_COPY';
    // Keyboard.EDIT_ACTION_PASTE = 'EDIT_ACTION_PASTE';
    // Keyboard.EDIT_ACTION_PREPARE_MOVE = 'EDIT_ACTION_PREPARE_MOVE';
    // Keyboard.EDIT_ACTION_MOVE = 'EDIT_ACTION_MOVE';





  }

  iscursorAdjustment(evt) {


    //The current range from BEFORE the cursor is moved in the direction indicated by the arrow keys
    let range = this.quill.getSelection();

    //console.log('iscursorAdjustment:');
    //console.log(range);
    if (evt.code != 'ArrowLeft' && evt.code != 'ArrowRight' && evt.code == 'ArrowUp' && evt.code == 'ArrowDown') {

      return false;

    }

    let isLeftAdjacent = false;
    let isRightAdjacent = false;

    let adjustedRange = range;







    try {
      let selectedDelta = this.quill.getContents({
        index: range.index - 1,
        length: 1
      });
      //console.log(selectedDelta);

      selectedDelta.ops.forEach((op) => {

        if (op.insert && op.insert.editingInsertNewLineBlot2) {

          isRightAdjacent = true;
          //console.log('isRightAdjacent')


          return;
        }
      });



      selectedDelta = this.quill.getContents({
        index: range.index,
        length: 1
      });
      //console.log(selectedDelta);

      selectedDelta.ops.forEach((op) => {

        if (op.insert && op.insert.editingInsertNewLineBlot2) {

          isLeftAdjacent = true;
          //console.log('isLeftAdjacent')


          return;
        }
      });



    } catch (err) {
      console.log(err);
    }

    if (isRightAdjacent && evt.code == 'ArrowLeft') {

      this.quill.setSelection({
        index: range.index,
        length: 0
      }, "silent");

      return true;

    }



    if (isRightAdjacent && evt.code == 'ArrowRight') {

      this.quill.setSelection({
        index: range.index + 1,
        length: 0
      }, "silent");

      return true;

    }


    if (isLeftAdjacent && evt.code == 'ArrowRight') {

      this.quill.setSelection({
        index: range.index,
        length: 0
      }, "silent");

      return true;

    }




    return false;

  }


  isNonEditKeystroke(evt) {

    if (evt.key == 'Control' || evt.key == 'Alt') {
      return true;
    }

    if (evt.code == 'KeyZ' && evt.ctrlKey) {
      return true;
    }

    if (evt.code == 'KeyY' && evt.ctrlKey) {
      return true;
    }

    if (evt.code == 'KeyA' && evt.ctrlKey) {
      return true;
    }

    // if (evt.code == 'KeyC' && evt.ctrlKey) {
    //   return true;
    // }

    // if (evt.code == 'KeyV' && evt.ctrlKey) {
    //   return true;
    // }

    if (evt.code == 'ShiftLeft' || evt.code == 'ShiftRight') {
      return true;
    }

    if (evt.code == 'ArrowLeft' || evt.code == 'ArrowRight' || evt.code == 'ArrowUp' || evt.code == 'ArrowDown') {
      return true;
    }

    return false;

  }

  listen_11May2022() {
    //console.log('keyboard.listen()...')
    this.quill.root.addEventListener('keydown', (evt) => {

      let bookStore = this.options.stores.bookStore;
      let editMode = this.options.editMode;
      let range = this.quill.getSelection();

      if (evt.defaultPrevented) return;
      let which = evt.which || evt.keyCode;
      let bindings = (this.bindings[which] || []).filter(function (binding) {
        return Keyboard.match(evt, binding);
      });
      //console.log(bindings);


      //console.log(evt);
      if (range == null || !this.quill.hasFocus()) return;


      //Probably not going to use bindings
      //if (bindings.length === 0) {
      if (editMode) {
        if (evt.key == 'Control' || evt.key == 'Alt') {
          //evt.preventDefault();
          this.standardProcessing(evt);
          return;
        }

        if (evt.code == 'KeyZ' && evt.ctrlKey) {
          this.standardProcessing(evt);
          return;
        }

        if (evt.code == 'KeyY' && evt.ctrlKey) {
          this.standardProcessing(evt);
          return;
        }

        if (evt.code == 'KeyA' && evt.ctrlKey) {
          this.standardProcessing(evt);
          return;
        }

        if (evt.code == 'KeyC' && evt.ctrlKey) {
          this.standardProcessing(evt);
          return;
        }

        if (evt.code == 'KeyV' && evt.ctrlKey) {
          this.standardProcessing(evt);
          return;
        }

        if (evt.code == 'ShiftLeft' || evt.code == 'ShiftRight') {
          this.standardProcessing(evt);
          return;
        }

        if (evt.code == 'ArrowLeft' || evt.code == 'ArrowRight' || evt.code == 'ArrowUp' || evt.code == 'ArrowDown') {
          this.standardProcessing(evt);
          return;
        }

        //console.log(evt.key.match([ -~]));
        //console.log(/[ -~]/.test(evt.key));
        let extended = false;

        // let isAscii = (extended ? /^[\x00-\xFF]*$/ : /^[\x00-\x7F]*$/).test(evt.keyCode);
        // console.log('isAscii:'+isAscii);
        //console.log('evt.keyCode:' + evt.keyCode);



        // if (evt.keyCode >= 32 && evt.keyCode <= 255) {
        let finalPartialDelta = null;
        let finalSelectionRange = null;

        if (evt.key == 'Delete') {

          let parts = editingUtils.extractParts({
            quill: this.quill,
            range: this.quill.getSelection()
          });


          parts.forEach((part) => {

            //console.log(part);

          });


          if (parts.length > 0) {

            finalPartialDelta = editingUtils.keyboardModule_handleDeletePressed_withParts({
              quill: this.quill,
              parts,
              bookStore,
              insertedText: evt.key
              //documentPart
            });

            //console.log(finalPartialDelta);

            finalSelectionRange = {
              index: range.index + finalPartialDelta.length() + 1,
              length: 0
            }
          }

          else {

            if (range.length == 0) {


              finalPartialDelta = editingUtils.keyboardModule_handleDeletePressed({
                quill: this.quill,
                range: {
                  index: range.index,
                  length: 0
                },
                bookStore,
                insertedText: evt.key
                //documentPart
              });

              finalSelectionRange = {
                index: range.index + 1,
                length: 0
              }


            } else {


              finalPartialDelta = editingUtils.keyboardModule_handleDeletePressed({
                quill: this.quill,
                range,
                bookStore,
                insertedText: evt.key
                //documentPart
              });

              finalSelectionRange = {
                index: range.index,
                length: 0
              }


            }

          }









        } else if (evt.key == 'Backspace') {

          if (range.length > 0) {

            finalPartialDelta = editingUtils.keyboardModule_handleDeletePressed({
              quill: this.quill,
              range,
              bookStore,
              insertedText: evt.key
              //documentPart
            });

          } else if (range.index == 0 && range.length == 0) {
            //do nothing
          } else {

            finalPartialDelta = editingUtils.keyboardModule_backspaceDeletePressed({
              quill: this.quill,
              range,
              bookStore,
              insertedText: evt.key
              //documentPart
            });

          }

          finalSelectionRange = {
            index: range.index - 1,
            length: 0
          }


        } else if (evt.code == 'KeyV' && evt.ctrlKey) {


          //console.log('pasted conent...');
          //bookStore.mostRecentQuillClipboardDelta;
          //bookStore.mostRecentQuillClipboardSelection = this.quill.getSelection();



        }
        else {
          finalPartialDelta = editingUtils.keyboardModule_handleKeyPressed({
            quill: this.quill,
            range,
            bookStore,
            insertedText: evt.key
            //documentPart
          });

          finalSelectionRange = {
            index: range.index + 1,
            length: 0
          }
        }




        if (finalPartialDelta) {
          //=========> Update the contents
          this.quill.updateContents(finalPartialDelta, "user");

          //=========> Update blots timestamp
          //bookStore.setBlotsChangedTimestamp();

          //=========> Set the new cursor location
          this.quill.setSelection(finalSelectionRange)
        }









        evt.preventDefault();
        return;
        // }

      }



      this.standardProcessing(evt);





    });
  }

  standardProcessing(evt) {

    let range = this.quill.getSelection();

    if (evt.defaultPrevented) return;
    let which = evt.which || evt.keyCode;
    let bindings = (this.bindings[which] || []).filter(function (binding) {
      return Keyboard.match(evt, binding);
    });

    let [line, offset] = this.quill.getLine(range.index);
    let [leafStart, offsetStart] = this.quill.getLeaf(range.index);
    let [leafEnd, offsetEnd] = range.length === 0 ? [leafStart, offsetStart] : this.quill.getLeaf(range.index + range.length);
    let prefixText = leafStart instanceof Parchment.Text ? leafStart.value().slice(0, offsetStart) : '';
    let suffixText = leafEnd instanceof Parchment.Text ? leafEnd.value().slice(offsetEnd) : '';
    let curContext = {
      collapsed: range.length === 0,
      empty: range.length === 0 && line.length() <= 1,
      format: this.quill.getFormat(range),
      offset: offset,
      prefix: prefixText,
      suffix: suffixText
    };
    let prevented = bindings.some((binding) => {
      if (binding.collapsed != null && binding.collapsed !== curContext.collapsed) return false;
      if (binding.empty != null && binding.empty !== curContext.empty) return false;
      if (binding.offset != null && binding.offset !== curContext.offset) return false;
      if (Array.isArray(binding.format)) {
        // any format is present
        if (binding.format.every(function (name) {
          return curContext.format[name] == null;
        })) {
          return false;
        }
      } else if (typeof binding.format === 'object') {
        // all formats must match
        if (!Object.keys(binding.format).every(function (name) {
          if (binding.format[name] === true) return curContext.format[name] != null;
          if (binding.format[name] === false) return curContext.format[name] == null;
          return equal(binding.format[name], curContext.format[name]);
        })) {
          return false;
        }
      }
      if (binding.prefix != null && !binding.prefix.test(curContext.prefix)) return false;
      if (binding.suffix != null && !binding.suffix.test(curContext.suffix)) return false;
      return binding.handler.call(this, range, curContext) !== true;
    });

    if (prevented) {
      evt.preventDefault();
    }
  }
}

Keyboard.keys = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  DELETE: 46
};

Keyboard.EDIT_ACTION_DELETE = 'EDIT_ACTION_DELETE';
Keyboard.EDIT_ACTION_DELETE_EXISTING_INSERT = 'EDIT_ACTION_DELETE_EXISTING_INSERT';
Keyboard.EDIT_ACTION_DELETE_EXISTING_DELETE = 'EDIT_ACTION_DELETE_EXISTING_DELETE';
Keyboard.EDIT_ACTION_BACKSPACE_EXISTING_INSERT = 'EDIT_ACTION_BACKSPACE_EXISTING_INSERT';
Keyboard.EDIT_ACTION_BACKSPACE = 'EDIT_ACTION_BACKSPACE';
Keyboard.EDIT_ACTION_INSERT = 'EDIT_ACTION_INSERT';
Keyboard.EDIT_ACTION_COPY = 'EDIT_ACTION_COPY';
Keyboard.EDIT_ACTION_PASTE = 'EDIT_ACTION_PASTE';
Keyboard.EDIT_ACTION_PREPARE_MOVE = 'EDIT_ACTION_PREPARE_MOVE';
Keyboard.EDIT_ACTION_MOVE = 'EDIT_ACTION_MOVE';
Keyboard.EDIT_ACTION_INSERT_NEWLINE = 'EDIT_ACTION_INSERT_NEWLINE';
Keyboard.EDIT_ACTION_BACKSPACE_REMOVE_INSERT_NEWLINE = 'EDIT_ACTION_BACKSPACE_REMOVE_INSERT_NEWLINE';
Keyboard.EDIT_ACTION_DELETE_REMOVE_INSERT_NEWLINE = 'EDIT_ACTION_DELETE_REMOVE_INSERT_NEWLINE';
Keyboard.EDIT_ACTION_DELETE_NEWLINE = 'EDIT_ACTION_DELETE_NEWLINE';
Keyboard.EDIT_ACTION_BACKSPACE_NEWLINE = 'EDIT_ACTION_BACKSPACE_NEWLINE';

Keyboard.blotsTimeoutId = null;

Keyboard.DEFAULTS = {
  bindings: {
    'bold': makeFormatHandler('bold'),
    'italic': makeFormatHandler('italic'),
    'underline': makeFormatHandler('underline'),
    'indent': {
      // highlight tab or tab at beginning of list, indent or blockquote
      key: Keyboard.keys.TAB,
      format: ['blockquote', 'indent', 'list'],
      handler: function (range, context) {
        if (context.collapsed && context.offset !== 0) return true;
        this.quill.format('indent', '+1', Quill.sources.USER);
      }
    },
    'outdent': {
      key: Keyboard.keys.TAB,
      shiftKey: true,
      format: ['blockquote', 'indent', 'list'],
      // highlight tab or tab at beginning of list, indent or blockquote
      handler: function (range, context) {
        if (context.collapsed && context.offset !== 0) return true;
        this.quill.format('indent', '-1', Quill.sources.USER);
      }
    },
    'outdent backspace': {
      key: Keyboard.keys.BACKSPACE,
      collapsed: true,
      shiftKey: null,
      metaKey: null,
      ctrlKey: null,
      altKey: null,
      format: ['indent', 'list'],
      offset: 0,
      handler: function (range, context) {
        if (context.format.indent != null) {
          this.quill.format('indent', '-1', Quill.sources.USER);
        } else if (context.format.list != null) {
          this.quill.format('list', false, Quill.sources.USER);
        }
      }
    },
    'indent code-block': makeCodeBlockHandler(true),
    'outdent code-block': makeCodeBlockHandler(false),
    'remove tab': {
      key: Keyboard.keys.TAB,
      shiftKey: true,
      collapsed: true,
      prefix: /\t$/,
      handler: function (range) {
        this.quill.deleteText(range.index - 1, 1, Quill.sources.USER);
      }
    },
    'tab': {
      key: Keyboard.keys.TAB,
      handler: function (range) {
        this.quill.history.cutoff();
        let delta = new Delta().retain(range.index)
          .delete(range.length)
          .insert('\t');
        this.quill.updateContents(delta, Quill.sources.USER);
        this.quill.history.cutoff();
        this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
      }
    },
    'list empty enter': {
      key: Keyboard.keys.ENTER,
      collapsed: true,
      format: ['list'],
      empty: true,
      handler: function (range, context) {
        this.quill.format('list', false, Quill.sources.USER);
        if (context.format.indent) {
          this.quill.format('indent', false, Quill.sources.USER);
        }
      }
    },
    'checklist enter': {
      key: Keyboard.keys.ENTER,
      collapsed: true,
      format: { list: 'checked' },
      handler: function (range) {
        let [line, offset] = this.quill.getLine(range.index);
        let formats = extend({}, line.formats(), { list: 'checked' });
        let delta = new Delta().retain(range.index)
          .insert('\n', formats)
          .retain(line.length() - offset - 1)
          .retain(1, { list: 'unchecked' });
        this.quill.updateContents(delta, Quill.sources.USER);
        this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
        this.quill.scrollIntoView();
      }
    },
    'header enter': {
      key: Keyboard.keys.ENTER,
      collapsed: true,
      format: ['header'],
      suffix: /^$/,
      handler: function (range, context) {
        let [line, offset] = this.quill.getLine(range.index);
        let delta = new Delta().retain(range.index)
          .insert('\n', context.format)
          .retain(line.length() - offset - 1)
          .retain(1, { header: null });
        this.quill.updateContents(delta, Quill.sources.USER);
        this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
        this.quill.scrollIntoView();
      }
    },
    'list autofill': {
      key: ' ',
      collapsed: true,
      format: { list: false },
      prefix: /^\s*?(\d+\.|-|\*|\[ ?\]|\[x\])$/,
      handler: function (range, context) {
        let length = context.prefix.length;
        let [line, offset] = this.quill.getLine(range.index);
        if (offset > length) return true;
        let value;
        switch (context.prefix.trim()) {
          case '[]': case '[ ]':
            value = 'unchecked';
            break;
          case '[x]':
            value = 'checked';
            break;
          case '-': case '*':
            value = 'bullet';
            break;
          default:
            value = 'ordered';
        }
        this.quill.insertText(range.index, ' ', Quill.sources.USER);
        this.quill.history.cutoff();
        let delta = new Delta().retain(range.index - offset)
          .delete(length + 1)
          .retain(line.length() - 2 - offset)
          .retain(1, { list: value });
        this.quill.updateContents(delta, Quill.sources.USER);
        this.quill.history.cutoff();
        this.quill.setSelection(range.index - length, Quill.sources.SILENT);
      }
    },
    'code exit': {
      key: Keyboard.keys.ENTER,
      collapsed: true,
      format: ['code-block'],
      prefix: /\n\n$/,
      suffix: /^\s+$/,
      handler: function (range) {
        const [line, offset] = this.quill.getLine(range.index);
        const delta = new Delta()
          .retain(range.index + line.length() - offset - 2)
          .retain(1, { 'code-block': null })
          .delete(1);
        this.quill.updateContents(delta, Quill.sources.USER);
      }
    },
    'embed left': makeEmbedArrowHandler(Keyboard.keys.LEFT, false),
    'embed left shift': makeEmbedArrowHandler(Keyboard.keys.LEFT, true),
    'embed right': makeEmbedArrowHandler(Keyboard.keys.RIGHT, false),
    'embed right shift': makeEmbedArrowHandler(Keyboard.keys.RIGHT, true)
  }
};

function makeEmbedArrowHandler(key, shiftKey) {
  const where = key === Keyboard.keys.LEFT ? 'prefix' : 'suffix';
  return {
    key,
    shiftKey,
    altKey: null,
    [where]: /^$/,
    handler: function (range) {
      let index = range.index;
      if (key === Keyboard.keys.RIGHT) {
        index += (range.length + 1);
      }
      const [leaf,] = this.quill.getLeaf(index);
      if (!(leaf instanceof Parchment.Embed)) return true;
      if (key === Keyboard.keys.LEFT) {
        if (shiftKey) {
          this.quill.setSelection(range.index - 1, range.length + 1, Quill.sources.USER);
        } else {
          this.quill.setSelection(range.index - 1, Quill.sources.USER);
        }
      } else {
        if (shiftKey) {
          this.quill.setSelection(range.index, range.length + 1, Quill.sources.USER);
        } else {
          this.quill.setSelection(range.index + range.length + 1, Quill.sources.USER);
        }
      }
      return false;
    }
  };
}


function handleBackspace(range, context) {
  if (range.index === 0 || this.quill.getLength() <= 1) return;
  let [line,] = this.quill.getLine(range.index);
  let formats = {};
  if (context.offset === 0) {
    let [prev,] = this.quill.getLine(range.index - 1);
    if (prev != null && prev.length() > 1) {
      let curFormats = line.formats();
      let prevFormats = this.quill.getFormat(range.index - 1, 1);
      formats = DeltaOp.attributes.diff(curFormats, prevFormats) || {};
    }
  }
  // Check for astral symbols
  let length = /[\uD800-\uDBFF][\uDC00-\uDFFF]$/.test(context.prefix) ? 2 : 1;
  this.quill.deleteText(range.index - length, length, Quill.sources.USER);
  if (Object.keys(formats).length > 0) {
    this.quill.formatLine(range.index - length, length, formats, Quill.sources.USER);
  }
  this.quill.focus();
}

function handleDelete(range, context) {
  // Check for astral symbols
  let length = /^[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(context.suffix) ? 2 : 1;
  if (range.index >= this.quill.getLength() - length) return;
  let formats = {}, nextLength = 0;
  let [line,] = this.quill.getLine(range.index);
  if (context.offset >= line.length() - 1) {
    let [next,] = this.quill.getLine(range.index + 1);
    if (next) {
      let curFormats = line.formats();
      let nextFormats = this.quill.getFormat(range.index, 1);
      formats = DeltaOp.attributes.diff(curFormats, nextFormats) || {};
      nextLength = next.length();
    }
  }
  this.quill.deleteText(range.index, length, Quill.sources.USER);
  if (Object.keys(formats).length > 0) {
    this.quill.formatLine(range.index + nextLength - 1, length, formats, Quill.sources.USER);
  }
}

function handleDeleteRange(range) {
  let lines = this.quill.getLines(range);
  let formats = {};
  if (lines.length > 1) {
    let firstFormats = lines[0].formats();
    let lastFormats = lines[lines.length - 1].formats();
    formats = DeltaOp.attributes.diff(lastFormats, firstFormats) || {};
  }
  this.quill.deleteText(range, Quill.sources.USER);
  if (Object.keys(formats).length > 0) {
    this.quill.formatLine(range.index, 1, formats, Quill.sources.USER);
  }
  this.quill.setSelection(range.index, Quill.sources.SILENT);
  this.quill.focus();
}

function handleEnter(range, context) {
  if (range.length > 0) {
    this.quill.scroll.deleteAt(range.index, range.length);  // So we do not trigger text-change
  }
  let lineFormats = Object.keys(context.format).reduce(function (lineFormats, format) {
    if (Parchment.query(format, Parchment.Scope.BLOCK) && !Array.isArray(context.format[format])) {
      lineFormats[format] = context.format[format];
    }
    return lineFormats;
  }, {});
  this.quill.insertText(range.index, '\n', lineFormats, Quill.sources.USER);
  // Earlier scroll.deleteAt might have messed up our selection,
  // so insertText's built in selection preservation is not reliable
  this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
  this.quill.focus();
  Object.keys(context.format).forEach((name) => {
    if (lineFormats[name] != null) return;
    if (Array.isArray(context.format[name])) return;
    if (name === 'link') return;
    this.quill.format(name, context.format[name], Quill.sources.USER);
  });
}

function makeCodeBlockHandler(indent) {
  return {
    key: Keyboard.keys.TAB,
    shiftKey: !indent,
    format: { 'code-block': true },
    handler: function (range) {
      let CodeBlock = Parchment.query('code-block');
      let index = range.index, length = range.length;
      let [block, offset] = this.quill.scroll.descendant(CodeBlock, index);
      if (block == null) return;
      let scrollIndex = this.quill.getIndex(block);
      let start = block.newlineIndex(offset, true) + 1;
      let end = block.newlineIndex(scrollIndex + offset + length);
      let lines = block.domNode.textContent.slice(start, end).split('\n');
      offset = 0;
      lines.forEach((line, i) => {
        if (indent) {
          block.insertAt(start + offset, CodeBlock.TAB);
          offset += CodeBlock.TAB.length;
          if (i === 0) {
            index += CodeBlock.TAB.length;
          } else {
            length += CodeBlock.TAB.length;
          }
        } else if (line.startsWith(CodeBlock.TAB)) {
          block.deleteAt(start + offset, CodeBlock.TAB.length);
          offset -= CodeBlock.TAB.length;
          if (i === 0) {
            index -= CodeBlock.TAB.length;
          } else {
            length -= CodeBlock.TAB.length;
          }
        }
        offset += line.length + 1;
      });
      this.quill.update(Quill.sources.USER);
      this.quill.setSelection(index, length, Quill.sources.SILENT);
    }
  };
}

function makeFormatHandler(format) {
  return {
    key: format[0].toUpperCase(),
    shortKey: true,
    handler: function (range, context) {
      this.quill.format(format, !context.format[format], Quill.sources.USER);
    }
  };
}

function normalize(binding) {
  if (typeof binding === 'string' || typeof binding === 'number') {
    return normalize({ key: binding });
  }
  if (typeof binding === 'object') {
    binding = clone(binding, false);
  }
  if (typeof binding.key === 'string') {
    if (Keyboard.keys[binding.key.toUpperCase()] != null) {
      binding.key = Keyboard.keys[binding.key.toUpperCase()];
    } else if (binding.key.length === 1) {
      binding.key = binding.key.toUpperCase().charCodeAt(0);
    } else {
      return null;
    }
  }
  if (binding.shortKey) {
    binding[SHORTKEY] = binding.shortKey;
    delete binding.shortKey;
  }
  return binding;
}


export { Keyboard as default, SHORTKEY };
