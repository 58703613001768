import React, { useState, useEffect } from "react";
import { Grid, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import config from '../../../aws-exports';
import ReactTimeAgo from 'react-time-ago';
import EmptyContent from "../../EmptyContent";
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import StandardUIDialog from "../../UI/StandardUIDialog";
import StandardUIButton from "../../UI/StandardUIButton";
import {
    useManagerAddBoxsetContributor,
    useManagerAddBoxsetContributedBook,
    useManagerLoadBoxSetContributors,
    useManagerRemoveBoxsetContributor
  } from '../../../managers/BoxsetsManager';
import UserListItem from "./UserListItem";

const ListBoxsetContributors = (props) => {

    
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { removeBoxSetContributor, loading: removeBoxSetContributorLoading, error: removeBoxSetContributorError } = useManagerRemoveBoxsetContributor(props);

    const [showRemoveContributor, setShowRemoveContributor] = useState(false);

    const handleOpenRemoveContributor = () => {
        setShowRemoveContributor(true);
    }

    const handleCloseRemoveContributor = () => {
        setShowRemoveContributor(false);
    }


    let { boxset, showRemoveIcon = true, contributor } = props;

    
    let {
        boxsetContributors,
        loading: lazyLoadBoxSetContributorsLoading,
        error: lazyLoadBoxSetContributorsError,
        refetch: lazyLoadBoxSetContributorsRefetch
    } = useManagerLoadBoxSetContributors(props, boxset?.id);

    //console.log(boxsetContributors);


    
    const generateSecondaryActions = (boxsetContributor) => {

    const secondaryActions = [];

    if (showRemoveIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
                setShowRemoveContributor(true)
            }}>
            <RemoveCircleOutlineIcon />
        </IconButton>)
    }

    return secondaryActions;
}



    return (
        <div>
            <List>
                {boxsetContributors && boxsetContributors.length > 0 ?
                    boxsetContributors.map((boxsetContributor) => {


                        return <UserListItem
                    //selected={selectedIndex}
                    user={boxsetContributor?.contributor}
                    secondaryActions={
                        generateSecondaryActions(boxsetContributor)
                    }
                    //secondaryText={userConnection.createdAt != null ? <>Connected: <ReactTimeAgo date={userConnection.createdAt} locale="en-US" /> </> : ''}
                />

                        // return <ListItem
                        //     secondaryAction={
                        //         generateSecondaryActions(boxsetContributor)
                        //     }
                        // > <ListItemAvatar>
                        //         <Avatar variant="rounded"
                        //             // bucketName={config.user_uploads_bucket_name}
                        //             s3Src={boxsetContributor?.contributor?.profilePicUrl}
                        //         >
                        //         </Avatar>
                        //     </ListItemAvatar>
                        //     <ListItemText
                        //         primary={boxsetContributor?.contributor?.name}
                        //         secondary='Since Date'
                        //     />
                        // </ListItem>


                    }

                    )
                    :
                    <EmptyContent tagline={'Add yourself or a new contributor!'} />
                }
                <StandardUIDialog 
                    title='Remove Contributor'
                    open={showRemoveContributor}
                    onClose={handleCloseRemoveContributor}
                    dialogContent={
                        <Typography>
                            Are you sure you want to remove this contributor?
                            Removing this contributor will remove any and all of their contributed books.
                        </Typography>
                    }
                    cardActions={
                        <StandardUIButton 
                            buttonAction='Remove'
                            onClick={() => {
                                removeBoxSetContributor({
                                  contributorId: contributor.id,
                                  boxsetId: boxset.id,
                                },()=>{
                                  if(props.onRemoved){
                                    props.onRemoved()
                                  }
                                })
                              }}
                        />
                    }
                />
            </List>
        </div>
    )
}

export default inject('stores')(observer(ListBoxsetContributors));