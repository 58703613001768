import React, { useEffect, useState } from 'react';
import { styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import BaseControl from "./BaseControl";
import { useTheme } from '@mui/material/styles';
import { Box, Checkbox } from "@mui/material";
import { v4 as uuid } from "uuid";

function FormatCheckbox(props) {

  const theme = useTheme();
  let baseControl = new BaseControl(props);

  const { classes, element, bookDraft, documentPart } = props;




  let isVisible = baseControl.isVisible();
  if (!isVisible) {
    return <div style={{
      height: '0em'
    }}></div>
  }

  let item = documentPart;
  let properties_book = bookDraft.book;
  let boxSet = bookDraft.book.boxSet;

  let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();
  let hideBookProperties = false;

  let canEdit = true;


  let isOwner = true;


  if (element.paramItem == 'BOOK') {
    item = bookDraft.book;
  }
  if (element.paramItem == 'BOXSET_MAIN_BOOK') {
    item = boxSet.book;
  }
  if (element.paramItem == 'BOXSET_BOOK') {
    item = boxSetBook.book;
  }
  if (element.paramItem == 'BOXSETBOOK') {
    item = boxSetBook;
  }

  //console.log('gonna call it...')

  let checked = baseControl.getIsChecked();

  //The following is necessary to stop the MuiCheckbox from defaulting to uncontrolled mode
  //if it is initilized with a null or undefined value. 
  if (checked == null || checked == undefined) {

    checked = false;
  }


  let title = baseControl.convertTitle(element.title, documentPart);

  const label = { inputProps: { 'aria-label': title } };

  return (

    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}>
      {/* <Box sx={{
      display: 'flex',
      flexDirection:'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginBottom:'2em'
    }}>
      <div>paramType:{documentPart && element.paramType}</div>
      <div>recipeParamName:{element.recipeParamName}</div>
      <div>entityFieldName:{element.entityFieldName}</div>
      <div>docPartValue:{documentPart && documentPart[element.entityFieldName]}</div>
      <div>recipeParamValue:{baseControl.getRecipeParam()?.value}</div>

    </Box> */}
     
      <Checkbox color={'secondary'} key={uuid()} {...label} checked={checked} onChange={(e) => {
        let newValue = e.target.value;
        //console.log(newValue);

        let newIsChecked = e.target.checked;
        if(element.invertValue){
          newIsChecked = !newIsChecked;
        }

        if(!newIsChecked){ 

          newValue = element.unCheckedValue;

        }else{

          newValue = element.checkedValue;

        }

       

 










        // if(baseControl.getIsChecked()){ 

        //   newValue = element.unCheckedValue;

        // }else{

        //   newValue = element.checkedValue;

        // }

        //console.log(newValue);

        baseControl.updateValue({
          value: newValue
        });


        // if (newValue && newValue.toLowerCase() == 'on') {
        //   newValue = 'true';
        // }
        // baseControl.updateValue({
        //   value: newValue
        // });


      }} /><Typography>{title}</Typography>
    </Box>


  );
}


export default inject('stores')(observer(FormatCheckbox));



