import React, { useState, useEffect } from "react";
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetOwnedBooks, useOpenBookInWrite } from '../../managers/BooksManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import CircleAvatar from "./CircleAvatar";
import DeleteIcon from '@mui/icons-material/Delete';
import StandardRoundedAction from "../actions/StandardRoundedAction";
import Feather from "mdi-material-ui/Feather";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import TablePopupMenu from "../menus/TablePopupMenu";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CommentIcon from '@mui/icons-material/Comment';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useGetUserConnections } from '../../managers/ConnectionsManager';
import { useMediaQuery } from 'react-responsive';
import EmptyContent from "../EmptyContent";
import helpers from '../../helpers/helpers';
import StandardList from "./StandardList";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';


let missingBookImage = require('../../assets/images/image-not-found.png')

const ConnectionsList = (props) => {

  // const { userConnections, error: getUserConnectionsError } = useGetUserConnections(props);
  const { userConnections, loading, error } = props;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');


  const isXs = useMediaQuery({ maxWidth: 599 })
  const isSm = useMediaQuery({ maxWidth: 600 })
  const isMd = useMediaQuery({ maxWidth: 900 })
  const isLg = useMediaQuery({ maxWidth: 1200 })
  const isXl = useMediaQuery({ minWidth: 1536 })


  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {


    setOrder("desc");
    setOrderBy("createdAt");

  }, [userConnections]);




  const createSecondaryActions = (userConnection) => {

    let secondaryActions = [];

    if (props.showSelectIcon) {
      secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
        //console.log(userConnection)
        props.onSelected(userConnection);
      }}>
        <ShareIcon />
      </IconButton>);
    }

    return secondaryActions;
  }

  let sortedConnections = [];
  if (userConnections != null) {

    sortedConnections = userConnections.map((userConnection) => {

      let compositeUserConnection = {
        object: userConnection,
        name: userConnection.connectedUser.name,
        createdAt: userConnection.createdAt

      }


      return compositeUserConnection;
    });
    sortedConnections = helpers.stableSort(sortedConnections, props.stores.bookStore.sortOrder, props.stores.bookStore.sortOrderBy === 'alpha' ? 'name' : props.stores.bookStore.sortOrderBy);

  }

  //console.log(sortedConnections);


  return (
    <StandardList
      // title={'My Books'}
      hideHeading={true}
      // headerActions={<CreateExportsAssembly book={book} />}
      component="div" disablePadding>
      {userConnections && userConnections.length > 0 ?
        sortedConnections.map((compositeUserConnection) => {

          let userConnection = compositeUserConnection.object;
          return <>

            <ListItem
              alignItems="flex-start"

              secondaryAction={



                <Box sx={{
                  display: 'flex'
                }}>
                  <StandardRoundedAction icon={<StarBorderIcon sx={{
                    color: theme.palette.standardActions.icon.color.main,

                  }} onClick={(e) => {
                    e.preventDefault();
                    //navigate("/write");
                   
                    
                  }} />} />


                  <StandardRoundedAction icon={<RemoveCircleOutlineIcon sx={{
                    color: theme.palette.standardActions.icon.color.main,

                  }} onClick={(e) => {
                    e.preventDefault();
                    //navigate("/format");
                   
                  }} />} />




                </Box>







              }>
              <ListItemAvatar
                onClick={(e) => {
                  e.preventDefault();
                  props.onSelected(userConnection);
                }}>
                <CircleAvatar
                  //bucketName={config.user_uploads_bucket_name}
                  s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + userConnection?.connectedUser?.profilePicUrl}
                  missingContent={<img src={missingBookImage} />}
                />
                {/* <Avatar alt="Remy Sharp" variant="square" src="https://local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm.s3.amazonaws.com/uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/c7cb5750-3627-11ed-914f-5d91b6c4c7eb_planetStrider.png">

              </Avatar> */}
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  noWrap: true
                }}
                onClick={(e) => {
                  e.preventDefault();
                  props.onSelected(userConnection);
                }}
                primary={userConnection?.connectedUser?.name}
                secondary={
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      Connected Since
                    </Typography>
                    {/* <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="caption"
                      color="text.secondary"
                    >
                      Words: 2,432
                    </Typography> */}
                    {/* {" — I'll be in your neighborhood doing errands this…"} */}
                  </Box>
                }

              />

            </ListItem>
            <Divider variant="inset" />
          </>
          // <Divider variant="inset"/>


          // return <ListItem secondaryAction={createSecondaryActions(book)}>
          //   <ListItemAvatar>
          //     <AvatarTableCell
          //       bucketName={config.user_uploads_bucket_name}
          //       s3Src={book?.coverUrl}
          //     //title={userConnection?.connectedUser?.name}
          //     />
          //   </ListItemAvatar>
          //   <ListItemText primary={book?.title} secondary="" />

          // </ListItem>
        })
        :
        <Box sx={{
          marginTop: '3em'
        }}>
          <EmptyContent tagline={'Make your first connection!'} />
        </Box>

      }
      {/* {ownedBooks.length}:
      {availableBooks.length} */}
    </StandardList>

  )
}

export default inject('stores')(observer(ConnectionsList));