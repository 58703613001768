import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Grid, styled } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';
import config from '../config.js'
import UserAvatar from './lists/UserAvatar.jsx';
import { ApolloConsumer } from '@apollo/client';
import helpers from '../helpers/helpers.js';
import { Popover } from "@mui/material";
import SecurityManager from '../managers/SecurityManager.js';
import AppContext from '../AppContext.js';
import { useGetBillingPortal } from '../managers/PaymentManager.js';

export const StandardTransparentAppBar = styled(AppBar)(() => ({

  background: 'transparent',
  boxShadow: 'none'

}));



function StandardAppBar(props) {

  const theme = useTheme();
  const appContext = React.useContext(AppContext);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [updateWaiting, setUpdateWaiting] = React.useState(false);
  const [registration, setRegistration] = React.useState(null);
  const [swListener, setSwListener] = React.useState({});
  const [auth, setAuth] = React.useState(true);

  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  let navigate = useNavigate();
  let location = useLocation();

  let [billingUrl, setBillingUrl] = React.useState();



  let {
    lazyGetStripeBillingPortal, loading, error, data
  } = useGetBillingPortal();


  React.useEffect(() => {

    if (data != null) {

      let parsedData = JSON.parse(data.getBillingPortal)
      try {
        setBillingUrl(parsedData.billingPortalUrl);

        window.location.replace(parsedData.billingPortalUrl);

      } catch (err) {
        console.log(err);
      }


      //window.open(parsedData.billingPortalUrl, '_blank');
      //window.open(parsedData.billingPortalUrl);


    }
  }, [data]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const openUserMenu = Boolean(anchorEl);

  const handleUpdate = () => {
    //console.log('update app...')
    swListener.skipWaiting(registration.waiting);
  }



  function logOut() {
    signOut();
    navigate('/login');
  }

  //console.log(props.stores.userStore.user)

  let subscriptions = '';
  if(props.stores.userStore.user){
    props.stores.userStore.user.userSubscriptions.forEach((userSubscription) => {
      subscriptions += userSubscription.productId + ":";
    })
  }
 
  return (

    <StandardTransparentAppBar
      position={'static'}  //static ensures that following content appears below ( vertically ) and not under ( z direction )
      darkMode={props.darkMode}
      toggleDarkMode={props.toggleDarkMode}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        //border: '1px solid red'
      }}>
      <Toolbar sx={{
        //border: '2px solid green',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>

        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}>
          <IconButton
            size="large"
            edge="start"
            //color={theme.palette.secondary.main}
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              props.onOpenMainDrawer();
            }}
          >
            <MenuIcon sx={{
              color: theme.palette.secondary.main
            }} />
          </IconButton>
          {/* <Typography variant="caption" component="div" sx={{ flexGrow: 1 }}>
            Home / Books
          </Typography> */}
        </Box>


        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>

        </Box>






        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}>


          {/* <UpdateWaiting title={<Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          Update-Checker
        </Typography>} updateWaiting={updateWaiting} handleUpdate={handleUpdate} /> */}


          <Typography sx={{ p: 2 }} onClick={() => {



          }}>{subscriptions}</Typography>




          <IconButton
            size="large"
            edge="start"
            //color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              props.stores.bookStore.showHelpDrawer = true;
            }}
          >
            <HelpOutlineIcon sx={{
              color: theme.palette.secondary.main
            }} />
          </IconButton>

          <IconButton
            size="large"
            edge="start"
            //color="inherit"
            aria-label="menu"
            //sx={{ mr: 2 }}
            onClick={() => {
              navigate("/profile");
            }}
            sx={{
              color: theme.palette.secondary.main
            }}
          >
            {/* <StandardAvatar
              bucketName={config.user_uploads_bucket_name}
              s3Src={props.stores.userStore.user?.profilePicUrl}
            /> */}
          </IconButton>

          {/* {!isSmallScreen && */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 1
          }}>

            {/* <span>{subscriptions}</span> */}
            {/* <Button onClick={() => logOut()}>Logout</Button> */}


            {!isSmallScreen &&
              <ApolloConsumer>
                {(client) => (

                  <Button onClick={async () => {


                    let processNextMutation = () => {

                      let pendingMutations = JSON.parse(localStorage.getItem('pendingMutations')) || [];
                      pendingMutations = helpers.mutationSort(pendingMutations, 'ASC',);

                      if (pendingMutations.length > 0) {

                        let mutationData = pendingMutations[0];
                        client.mutate({
                          mutation: JSON.parse(mutationData.query),
                          variables: JSON.parse(mutationData.variables),
                          context: {
                            isPersistRetry: true
                          }

                        })
                          .then((obj) => {

                            console.log(obj);

                            //Mutation executed successfully, remove from pending list
                            const updatedPendingMutations = pendingMutations.filter(
                              (item) => item !== mutationData
                            );
                            localStorage.setItem('pendingMutations', JSON.stringify(updatedPendingMutations));
                            processNextMutation();
                          })
                          .catch((err) => {

                            console.log(err);

                          });

                      }

                    };

                    processNextMutation();

                    // pendingMutations.forEach(async (mutationData) => {


                    //   await client.mutate({
                    //     mutation: JSON.parse(mutationData.query),
                    //     variables: JSON.parse(mutationData.variables),
                    //     context: {
                    //       isPersistRetry: true
                    //     }

                    //   })
                    //     .then((obj) => {

                    //       console.log(obj);

                    //       //Mutation executed successfully, remove from pending list
                    //       const updatedPendingMutations = pendingMutations.filter(
                    //         (item) => item !== mutationData
                    //       );
                    //       localStorage.setItem('pendingMutations', JSON.stringify(updatedPendingMutations));

                    //     })
                    //     .catch((err) => {

                    //       console.log(err);

                    //     });
                    // });
                  }}>RUN</Button>
                )}
              </ApolloConsumer>
            }
            <span>{props.stores.userStore.user?.name}</span>
            <UserAvatar
              s3Src={props.stores.userStore.user?.profilePicUrl}
              onClick={(e) => {


                //e.preventDefault();
                handleClick(e)



              }}
            />

            <Popover
              open={openUserMenu}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography sx={{ p: 2 }} onClick={() => {

                navigate('/profile');

              }}>Profile</Typography>
              <Typography sx={{ p: 2 }} onClick={() => {

                lazyGetStripeBillingPortal();
                // window.location.replace(billingUrl);
                //window.open(billingUrl, '_blank');

              }}>Billing</Typography>

              <Typography sx={{ p: 2 }} onClick={() => {

                navigate('/pricing');

              }}>Pricing(stripe)</Typography>

              <Typography sx={{ p: 2 }} onClick={() => {

                navigate('/pricing2');

              }}>Pricing(ours)</Typography>

              <Typography sx={{ p: 2 }} onClick={() => {

                // setSelectedSnapshot(selectedSnapshot);
                // setShowRestoreSnapshotDialog(true);

              }}>Preferences</Typography>
              <ApolloConsumer>

                {(client) => (

                  <Button variant={'contained'} onClick={(e) => {

                    //onLogout(e);


                    SecurityManager.logout({
                      client,
                      appContext,
                      stores: props.stores
                    });
                  }}>Logout</Button>
                )}
              </ApolloConsumer>
              <Typography sx={{ p: 2 }}>
                {config.version}
              </Typography>
              <Typography sx={{ p: 2 }}>
                userId:{props.stores.userStore.user?.id}
              </Typography>
            </Popover>


          </Box>
          {/* } */}
        </Box>






        {/* {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                //color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu>
            </div>
          )} */}
      </Toolbar>
    </StandardTransparentAppBar>
  );
}



const UpdateWaiting = ({ title, updateWaiting, handleUpdate }) => {
  if (!updateWaiting) return title
  return (
    <Button color="neutral" variant="outlined" onClick={handleUpdate} >Update Version</Button>
  )
}
//export default StandardAppBar
export default inject('stores')(observer(StandardAppBar))
