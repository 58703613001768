import React, { useState, useEffect } from "react";
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTheme } from '@mui/material/styles';
import { Box, Paper, Typography } from "@mui/material";
import { TreeItem } from "./drag-and-drop-2/TreeItem";
import Column from "./Column";
import ChildItems from './ChildItems';
import TreeNode from "../TreeNode";
import CameraOutline from 'mdi-material-ui/CameraOutline';
import AnimationOutline from 'mdi-material-ui/AnimationOutline';
import BookOutline from 'mdi-material-ui/BookOutline';
import BookOpenVariant from 'mdi-material-ui/BookOpenVariant';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import FileDocumentEdit from 'mdi-material-ui/FileDocumentEdit';
import FolderOutline from 'mdi-material-ui/FolderOutline';
import VideoOutline from 'mdi-material-ui/VideoOutline';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContextMenu from "../ContextMenu";
import config from "../../../config";
import { useAddBookDraftDocumentPart } from "../../../persistance/persistanceHooks";
import Item from "./Item";

//https://github.com/atlassian/react-beautiful-dnd/issues/1548
//Includes this fix to prevent RBD error.
window.ResizeObserver = undefined;

window.addEventListener('error', (e) => {
    //console.log(e);
    // if (e.message === 'ResizeObserver loop completed with undelivered notifications.' || e.message === 'ResizeObserver loop limit exceeded') {
    //   e.stopImmediatePropagation()
    // }
})

const wrapIcon = (icon, theme) => {

    return <div style={{
    }}>
        <span style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '.5em',
            color: theme.palette.standardTree.nodes.color.main
        }}>{icon}</span>
    </div>


}


const NestedListComponent2 = (props) => {


    let [items, setItems] = useState([]);
    let [contextSelectedBddp, setContextSelectedBddp] = useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [createDocumentPartBatch, { data: mutationData, loading: mutationLoading, error: mutationError }] = useAddBookDraftDocumentPart();
    const [showAddBookDialog, setShowAddBookDialog] = React.useState(null);

    let { propItems } = props;
    let writingBookDraft = props.bookDraft;
    let writingBookDraft2 = props.bookDraft2;
    let { bookDraft, selectedId } = props;

    const open = Boolean(anchorEl);

    let userId = props.stores.userStore?.user?.id;


    const theme = useTheme();
    // fake data generator
    const getItems = count =>
        Array.from({ length: count }, (v, k) => k).map(k => ({
            id: `item-${k}`,
            content: `item ${k}`
        }));

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };


    const partTypeIcons = {
        BOXSETS: wrapIcon(<AnimationOutline />, theme),
        BOXSET: wrapIcon(<AnimationOutline sx={{
            color: theme.palette.standardTree.heading.color.main
        }} />, theme),
        TOPBOOK: wrapIcon(<BookOutline color={theme.palette.text.main} />, theme),
        BOOKS: wrapIcon(<BookOutline />, theme),
        BOOK: wrapIcon(<BookOpenVariant sx={{
            color: theme.palette.standardTree.heading.color.main
        }} />, theme),
        VOLUME: wrapIcon(<FolderOutline />, theme),
        PART: wrapIcon(<FolderOutline />, theme),
        CHAPTER: wrapIcon(<FolderOutline />, theme),
        SCENE: wrapIcon(<VideoOutline />, theme),
        SNAPSHOTS: wrapIcon(<CameraOutline />, theme),
        SNAPSHOT: wrapIcon(<CameraOutline />, theme),
        TRASH: wrapIcon(<TrashCanOutline />, theme),
        RESEARCH: wrapIcon(<FolderOutline />, theme),
        FOLDER: wrapIcon(<FolderOutline />, theme),
        EDIT: wrapIcon(<AccountEdit />, theme),
        SNAPSHOT: wrapIcon(<CopyAllIcon />, theme),
        //PDF:wrapIcon(<FilePdf />), theme,
        //WEBPAGE:wrapIcon(<Web />, theme),
        DEFAULT: wrapIcon(<FileDocumentEdit />, theme),


        //DEFAULT:<span></span>

    }

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        //display:'flex',
        userSelect: "none",
        //padding: grid * 2,
        margin: `0 0 0 0`,

        // change background colour if dragging
        //background: isDragging ? "lightgreen" : "grey",
        //border: '2px solid blue',

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        // background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: grid,
        width: '100%'
    });


    const onDragEnd = function (result) {
        //console.log(result);
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const ar = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(ar);
    }


    useEffect(() => {

        setItems(getItems(10));
    }, []);



    const onContextMenuSelected = (menuId) => {
        //console.log(menuId)
        if (contextSelectedBddp) {

            let partType = menuId;
            // let bookDraftId = writingBookDraft.id;
            // let bookId = writingBookDraft.book.id;

            let bookDraftId = contextSelectedBddp.bookDraft.id;
            let bookId = contextSelectedBddp.bookDraft.book.id;


            let parentPartId = contextSelectedBddp?.partId;
            let previousBddp = contextSelectedBddp.lastPart;
            let starterDeltaOps = [];
            let deltaOpsJson = [];
            //let starterEscapedDeltaOps = [];


            try {

                let starterTemplatesBookDraft = props.stores.bookStore.startersBookDraft;
                let starterTemplateDocumentPart = starterTemplatesBookDraft.getBookDraftDocumentPartByPartId(config.STARTER_TEMPLATES[partType]);
                //console.log(starterTemplateDocumentPart);
                deltaOpsJson = starterTemplateDocumentPart.documentPart.deltaOps;
                starterDeltaOps = starterTemplateDocumentPart.documentPart.deltaOps.map((m) => {
                    return JSON.stringify(m);
                })
                //console.log(starterDeltaOps);
                // starterEscapedDeltaOps = starterTemplateDocumentPart.documentPart.deltaOps.map((m) => {
                // 	let s = JSON.stringify(m);
                // 	//s = s.replace(/""/g,'\"');
                // 	//s = s.replace(/\"/g,'\\"');
                // 	return s;
                // })

                // deltaOpsJson = JSON.stringify(deltaOpsJson).replace('"', '\"');

            } catch (err) {
                //console.log(err);
            }

            if (partType == 'ADD_BOOK') {

                setShowAddBookDialog(true);


            }

            else if (menuId && menuId == 'DELETE') {
                alert('Delete this item');
            }
            else {
                createDocumentPartBatch({
                    userId,
                    partType,
                    bookDraftId,
                    bookId,
                    parentPartId,
                    starterDeltaOps,
                    prevPartId: previousBddp?.partId

                }, (newBddp) => {

                    writingBookDraft.addBookDraftDocumentPart({
                        data: newBddp
                    });
                    props.stores.bookStore.recipeTimestamp = Date.now();


                });
            }


        }
    }

    const onContextSelected = (e, id) => {
        setAnchorEl(e.currentTarget);
        let bddp = writingBookDraft.getBookDraftDocumentPartByPartId(id);
        if (!bddp) {
            bddp = writingBookDraft2.getBookDraftDocumentPartByPartId(id);
        }
        setContextSelectedBddp(bddp)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {propItems.map((item, index) => (
                            // <Item item={item} index={index} onContextSelected={props.onContextSelected}/>

                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (

                                    <Paper
                                        //elevation={0}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        // {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            item.onDocumentPartSelected()
                                        }}
                                    >

                                        <div style={{
                                            display: 'flex'
                                        }}>
                                            <span
                                                {...provided.dragHandleProps}
                                                style={{
                                                    display: "inline-block",
                                                    margin: "0 0px",
                                                    //border: "1px solid #000"
                                                }}
                                            >
                                                {partTypeIcons[item.bddp.documentPart.partType] != null ? partTypeIcons[item.bddp.documentPart.partType] : partTypeIcons['DEFAULT']}
                                            </span>


                                            <Typography
                                                //className={'treeNodeTitle'}
                                                noWrap={true}
                                                variant={theme.components.standardTree.nodes.variant}
                                                //color={titleColor}
                                                sx={{

                                                    'white-space': 'nowrap',
                                                    //'font-weight':'bold',
                                                    //'border': '1px solid red',
                                                    //'width': '100%',
                                                    'min-width': 0,
                                                    'display': 'block',
                                                    'overflow': 'hidden',
                                                    'text-overflow': 'ellipsis',
                                                }}
                                            >{item.content}</Typography>

                                            <MoreVertIcon onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                // setAnchorEl(e.currentTarget);
                                                onContextSelected(e,item.id)
                                                // if (props.onContextMenu) {
                                                //     props.onContextMenu(e);
                                                // }
                                            }}
                                                sx={{
                                                    color: theme.palette.standardTree.title.color.main
                                                }} />
                                        </div>


                                        {/* <Item item={item} index={index} onContextSelected={props.onContextSelected} /> */}
                                        <ChildItems subItems={item.items} type={item.id} onContextSelected={onContextSelected} />

                                    </Paper>
                                    
                                )}
                            </Draggable>





                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>

            {open &&
                <ContextMenu
                    anchorEl={anchorEl}
                    open={open}
                    bookDraft={bookDraft}
                    contextSelectedType={contextSelectedBddp.documentPart.partType}
                    onSelected={({
                        menuId,
                    }) => {

                        //console.log('menuId:'+menuId);
                        onContextMenuSelected(menuId);
                    }}
                    handleClose={() => {
                        setAnchorEl(null)
                    }} />
            }

        </DragDropContext>
    );

};

export default inject('stores')(observer(NestedListComponent2));
