import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';

const AttrType = (props) => {

	let { uiState } = props;
	const { universe, attrType } = props;





	return (
		<div>
			attrType Details: {attrType.title}
		</div>
	);
};


export default inject('stores')(observer(AttrType));