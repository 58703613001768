import Quill from 'quill';
//import Parchment from 'parchment';
import { v4 as uuid }  from 'uuid';
import Delta from 'quill-delta';
import DeltaManager from '../DeltaManager';

let $ = require("jquery");
const Parchment = Quill.import('parchment');
let ACTION_TYPES = {
	UNKNOWN: 'unknown',
	DELETING: 'deleting',
	INSERTING: 'inserting'
}

let FORMAT_TYPES = {
	NONE: 'none',
	DELETING: 'editingDeleteBlot',
	INSERTING: 'editingInsertBlot'
}
class ChangeTracker {

	constructor(quill, options) {
		this.active = false;
		this.quill = quill;
		this.options = options;
		this.container = document.querySelector(options.container);
		quill.on('text-change', this.update.bind(this));
		this.update();  // Account for initial contents
	}



	toggle() {
		this.active = !this.active;
	}

	setActive(b) {
		this.active = b;
	}


	extractParts({
		toBeInserted,
		toBeDeleted,
		retain,
		totalLength
	}) {

		var range = this.quill.getSelection();


		let parts = [];
		let currentPart = null;
		/*let currentPart = {
			format:FORMAT_TYPES.NONE,
			length:0
		};
		parts.push(currentPart);*/

		if (!range) {
			return parts;
		}
		for (let i = 0; i < range.length; i++) {

			this.quill.setSelection(range.index + i, 1, 'silent');

			let format = this.quill.getFormat();

			if (Object.keys(format).length == 0) {

				if (currentPart && currentPart.format == FORMAT_TYPES.NONE) {
					currentPart.length++;
				} else {

					currentPart = {
						format: FORMAT_TYPES.NONE,
						length: 1,
						index: range.index + i,
						blot: format
					}
					parts.push(currentPart);

				}

			}

			try {

				Object.keys(format).forEach((key) => {

					//console.log(key);

					if (key == FORMAT_TYPES.INSERTING || key == FORMAT_TYPES.DELETING) {

						if (currentPart && key == currentPart.format) {
							currentPart.length++;
						} else {

							currentPart = {
								format: key,
								length: 1,
								index: range.index + i,
								blot: format
							}
							parts.push(currentPart);
						}

					} else {

						if (currentPart && currentPart.format == FORMAT_TYPES.NONE) {
							currentPart.length++;
						} else {

							currentPart = {
								format: FORMAT_TYPES.NONE,
								length: 1,
								index: range.index + i,
								blot: format
							}
							parts.push(currentPart);

						}
					}



				});

				//console.log();
			} catch (err) {
				////console.log(err);
			}







		}


		parts.forEach((part) => {

			this.quill.setSelection(part.index, part.length, 'silent');

		})

		return parts;



	}

	extractPartsOriginal({
		toBeInserted,
		toBeDeleted,
		retain,
		totalLength
	}) {

		//console.log('toBeInserted:' + toBeInserted);
		//console.log('toBeDeleted:' + toBeDeleted);
		//console.log('retain:' + retain);
		//console.log('totalLength:' + totalLength);

		let parts = [];
		let currentPart = null;
		/*let currentPart = {
			format:FORMAT_TYPES.NONE,
			length:0
		};
		parts.push(currentPart);*/

		for (let i = 0; i < totalLength; i++) {

			this.quill.setSelection(retain + i, 1);

			let format = this.quill.getFormat();

			if (Object.keys(format).length == 0) {

				if (currentPart && currentPart.format == FORMAT_TYPES.NONE) {
					currentPart.length++;
				} else {

					currentPart = {
						format: FORMAT_TYPES.NONE,
						length: 1
					}
					parts.push(currentPart);

				}

			}

			try {

				Object.keys(format).forEach((key) => {

					//console.log(key);

					if (key == FORMAT_TYPES.INSERTING || key == FORMAT_TYPES.DELETING) {

						if (currentPart && key == currentPart.format) {
							currentPart.length++;
						} else {

							currentPart = {
								format: key,
								length: 1
							}
							parts.push(currentPart);
						}

					} else {

						if (currentPart && currentPart.format == FORMAT_TYPES.NONE) {
							currentPart.length++;
						} else {

							currentPart = {
								format: FORMAT_TYPES.NONE,
								length: 1
							}
							parts.push(currentPart);

						}
					}



				});

				//console.log();
			} catch (err) {
				////console.log(err);
			}







		}




		return parts;



	}

	getLengthOfNextFormat = ({
		formatType,
		startIndex,
		quill
	}) => {
		let length = 0;
		let currentIndex = startIndex;
		let hasWrongFormat = false;

		while (currentIndex >= 0 && !hasWrongFormat) {
			quill.setSelection(currentIndex, 1, 'silent');
			let format = quill.getFormat({ index: currentIndex, length: 1 });
			let hasDesiredFormat = false;
			Object.keys(format).forEach((key) => {
				if (key == formatType) {
					hasDesiredFormat = true;

				}
			});

			if (hasDesiredFormat) {
				length++;
			} else {
				hasWrongFormat = true;
			}
			currentIndex++;
		}

		return length;

	}


	getLengthOfPreviousFormat = ({
		formatType,
		startIndex,
		quill
	}) => {
		let length = 0;
		let currentIndex = startIndex;
		let hasWrongFormat = false;

		while (currentIndex >= 0 && !hasWrongFormat) {
			quill.setSelection(currentIndex, 1, 'silent');
			let format = quill.getFormat({ index: currentIndex, length: 1 });
			let hasDesiredFormat = false;
			Object.keys(format).forEach((key) => {
				if (key == formatType) {
					hasDesiredFormat = true;

				}
			});

			if (hasDesiredFormat) {
				length++;
			} else {
				hasWrongFormat = true;
			}
			currentIndex--;
		}

		return length;

	}


	applyDeleteEdit = ({
		retain,
		toBeInserted,
		toBeDeleted,
		totalLength,
		formatAhead,
		formatBehind,
		quill,
		delta,
		allChangeDeltas,
		parts
	}) => {

		let addedNewDeleteBlot = false;
		let aheadDeleteUUID = null;
		let behindDeleteUUID = null;

		/*let undoDelta = quill.history.undo(Quill.sources.SILENT);
		allChangeDeltas.push({ undo: true });

		quill.history.cutoff();
		quill.setSelection(retain, toBeDeleted, 'silent');


		let parts = this.extractParts({
			toBeInserted,
			toBeDeleted,
			retain,
			totalLength
		});*/


		/*
if (key == FORMAT_TYPES.INSERTING || key == FORMAT_TYPES.DELETING) {

						if (currentPart && key == currentPart.format) {
							currentPart.length++;
						} else {

							currentPart = {
								format: key,
		*/
		//remove all of the insertedBlot parts. 
		let partsIndexModifier = 0;
		parts.forEach((part) => {

			if (part.format == FORMAT_TYPES.INSERTING) {
				quill.setSelection(part.index - partsIndexModifier, part.length, 'silent');
				let deleteInsertDelta = quill.deleteText(part.index - partsIndexModifier, part.length, 'silent');
				allChangeDeltas.push(deleteInsertDelta);
				toBeDeleted = toBeDeleted - part.length;
				partsIndexModifier += part.length;
			}


		})
		try {

			if (formatAhead) {
				Object.keys(formatAhead).forEach((key) => {
					if (key == 'editingDeleteBlot') {
						//isEditingInsertFormat = true;
						aheadDeleteUUID = formatAhead.editingDeleteBlot.uuid;
					}
				});

			}

		} catch (err) {
			////console.log(err);
		}

		try {
			if (formatBehind) {
				Object.keys(formatBehind).forEach((key) => {
					if (key == 'editingDeleteBlot') {
						//isEditingInsertFormat = true;
						behindDeleteUUID = formatBehind.editingDeleteBlot.uuid;
					}
				});

			}

		} catch (err) {
			////console.log(err);
		}

		//console.log(aheadDeleteUUID);
		//console.log(behindDeleteUUID);

		let previousDeleteLength = this.getLengthOfPreviousFormat({
			formatType: 'editingDeleteBlot',
			startIndex: retain - 1,
			quill
		});

		let nextDeleteLength = this.getLengthOfNextFormat({
			formatType: 'editingDeleteBlot',
			startIndex: retain + totalLength,
			quill
		});

		let referenceBlot = null;
		if (previousDeleteLength > 0) {
			referenceBlot = formatBehind;
		} else if (nextDeleteLength > 0) {
			referenceBlot = formatAhead;

		}

		quill.setSelection(retain - previousDeleteLength, toBeDeleted + previousDeleteLength, 'silent');

		let clearDeleteFrmattingDelta = quill.format('editingDeleteBlot', false, 'silent');
		allChangeDeltas.push(clearDeleteFrmattingDelta);

		let insertBlotDelta = quill.format('editingDeleteBlot', {
			editorComment: '',
			uuid: uuid()
		}, 'silent');
		allChangeDeltas.push(insertBlotDelta);
		quill.setSelection(retain + toBeDeleted, 0, 'silent');

		if (toBeInserted && toBeInserted.length > 0) {

			//////////////////////////////////////////////////////
			let aheadInsertUUID = null;
			let behindInsertUUID = null;

			let insertFormatAhead = this.quill.getFormat({ index: retain + totalLength - 1, length: 1 });
			quill.setSelection(retain + totalLength - 1, 1, 'silent');

			try {

				if (insertFormatAhead) {
					Object.keys(insertFormatAhead).forEach((key) => {
						if (key == 'editingInsertBlot') {
							//isEditingInsertFormat = true;
							aheadInsertUUID = insertFormatAhead.editingInsertBlot.uuid;
						}
					});

				}

			} catch (err) {
				////console.log(err);
			}



			//console.log(aheadInsertUUID);
			//console.log(behindInsertUUID);

			let previousInsertLength = this.getLengthOfPreviousFormat({
				formatType: 'editingInsertBlot',
				startIndex: retain - 1,
				quill
			});

			let nextInsertLength = this.getLengthOfNextFormat({
				formatType: 'editingInsertBlot',
				startIndex: retain + totalLength - 1,
				quill
			});

			let referenceBlot = null;
			if (nextInsertLength > 0) {
				referenceBlot = insertFormatAhead;

			}
			//remove the editing from the before content.


			let insertTextDelta = quill.insertText(retain + toBeDeleted, toBeInserted, 'silent');
			allChangeDeltas.push(insertTextDelta);

			if (referenceBlot) {
				quill.setSelection(retain - previousInsertLength + toBeDeleted, toBeInserted.length + previousInsertLength + nextInsertLength, 'silent');
				let clearFrmattingDelta = quill.format('editingInsertBlot', false, 'silent');
				allChangeDeltas.push(clearFrmattingDelta);

				let clearDeleteFrmattingDelta = quill.format('editingDeleteBlot', false, 'silent');
				allChangeDeltas.push(clearDeleteFrmattingDelta);



				//console.log(previousInsertLength);
				//quill.setSelection(retain - previousInsertLength+ toBeDeleted, totalLength + previousInsertLength + nextInsertLength);
				let insertEditingBlotDelta = quill.format('editingInsertBlot', {
					editorComment: referenceBlot.editingInsertBlot.editorComment,
					uuid: referenceBlot.editingInsertBlot.uuid
				}, 'silent');
				allChangeDeltas.push(insertEditingBlotDelta);
				quill.setSelection(retain - previousInsertLength + toBeDeleted + toBeInserted.length, 'silent');

			} else {
				quill.setSelection(retain + toBeDeleted, toBeInserted.length);
				let insertEditingBlotDelta = quill.format('editingInsertBlot', {
					editorComment: '',
					uuid: uuid()
				}, 'silent');
				allChangeDeltas.push(insertEditingBlotDelta);
				quill.setSelection(retain + toBeDeleted + toBeInserted.length, 'silent');


			}
			/////////////////////////////////////////////////////
			//

		}

		if (referenceBlot == null) {
			//this.options.stores.bookStore.editingChangeNotesTimestamp = Date.now();
			//let documentPart = this.options.documentPart;
			//this.options.documentPart.updateEdits();
			addedNewDeleteBlot = true;
		}

		return {
			addedNewDeleteBlot
		}

	}

	applyInsertEdit = ({
		retain,
		toBeInserted,
		totalLength,
		formatAhead,
		formatBehind,
		quill,
		delta,
		allChangeDeltas
	}) => {

		let addedNewInsertBlot = false;
		let aheadInsertUUID = null;
		let behindInsertUUID = null;

		try {

			if (formatAhead) {
				Object.keys(formatAhead).forEach((key) => {
					if (key == 'editingInsertBlot') {
						//isEditingInsertFormat = true;
						aheadInsertUUID = formatAhead.editingInsertBlot.uuid;
					}
					if (key == 'editingDeleteBlot') {

					}
				});

			}

		} catch (err) {
			////console.log(err);
		}

		try {
			if (formatBehind) {
				Object.keys(formatBehind).forEach((key) => {
					if (key == 'editingInsertBlot') {
						//isEditingInsertFormat = true;
						behindInsertUUID = formatBehind.editingInsertBlot.uuid;
					}
					if (key == 'editingDeleteBlot') {

					}
				});

			}

		} catch (err) {
			////console.log(err);
		}

		//console.log(aheadInsertUUID);
		//console.log(behindInsertUUID);

		let previousInsertLength = this.getLengthOfPreviousFormat({
			formatType: 'editingInsertBlot',
			startIndex: retain - 1,
			quill
		});

		let nextInsertLength = this.getLengthOfNextFormat({
			formatType: 'editingInsertBlot',
			startIndex: retain + totalLength,
			quill
		});


		//The following code is here because of some very annoying shit to do with quilljs. 
		if (previousInsertLength == 0 && behindInsertUUID != null) {
			previousInsertLength = 1;
		}

		let referenceBlot = null;
		if (previousInsertLength > 0) {
			referenceBlot = formatBehind;
		} else if (nextInsertLength > 0) {
			referenceBlot = formatAhead;

		}
		//remove the editing from the before content.


		let insertTextDelta = quill.insertText(retain, toBeInserted, 'silent');
		allChangeDeltas.push(insertTextDelta);

		if (referenceBlot) {
			quill.setSelection(retain - previousInsertLength, totalLength + previousInsertLength + nextInsertLength, 'silent');
			let clearFrmattingDelta = quill.format('editingInsertBlot', false, 'silent');
			allChangeDeltas.push(clearFrmattingDelta);

			let clearDeleteFrmattingDelta = quill.format('editingDeleteBlot', false, 'silent');
			allChangeDeltas.push(clearDeleteFrmattingDelta);



			//console.log(previousInsertLength);
			quill.setSelection(retain - previousInsertLength, totalLength + previousInsertLength + nextInsertLength, 'silent');
			let insertEditingBlotDelta = quill.format('editingInsertBlot', {
				editorComment: referenceBlot.editingInsertBlot.editorComment,
				uuid: referenceBlot.editingInsertBlot.uuid
			}, 'silent');

			allChangeDeltas.push(insertEditingBlotDelta);
			quill.setSelection(retain + totalLength, 'silent');
		} else {

			//console.log(Quill.imports);
			quill.setSelection(retain, totalLength, 'silent');
			let clearFrmattingDelta = this.quill.format('editingDeleteBlot', false, Quill.sources.SILENT);
			allChangeDeltas.push(clearFrmattingDelta);

			let insertEditingBlotDelta = quill.format('editingInsertBlot', {
				editorComment: '',
				uuid: uuid()
			}, 'silent');
			allChangeDeltas.push(insertEditingBlotDelta);


			/*insertEditingBlotDelta = quill.format('editingMovedTombstoneBlot', {
				editorComment: '',
				uuid: uuid()
			}, 'silent');*/




			quill.setSelection(retain + totalLength, 'silent');
		}


		if (referenceBlot == null) {
			addedNewInsertBlot = true;
		}
		return {
			addedNewInsertBlot
		}

	}

	processComplexPaste({
		retain,
		toBeInserted,
		totalLength,
		formatAhead,
		formatBehind,
		quill,
		delta,
		allChangeDeltas
	}) {

		let deltaManager = new DeltaManager();

		let convertedDelta = deltaManager.convertEditedDeltasForPasting([delta]);

		//console.log(convertedDelta);
	}

	extractOldToBeDeletedDelta = ({
		retain,
		toBeDeleted,
		delta,
		oldDelta
	}) => {

		let deleteDelta = new Delta().compose(oldDelta);
		if (retain) {
			deleteDelta.retain(retain);
		}

	}

	getNearbyFormatsNew({
		range,
		formatType
	}) {

		let formatAhead = null;
		let formatBehind = null;
		let indexAheadLength = 0;
		if (formatType && formatType == 'editingInsertBlot') {
			indexAheadLength = range.length;
		}
		try {
			if (range) {
				//let indexAhead = range.index + range.length;
				let indexAhead = range.index + indexAheadLength;
				let indexBehind = range.index - 1;
				this.quill.setSelection(indexAhead, 1, 'silent');
				formatAhead = this.quill.getFormat({ index: indexAhead, length: 1 });
				if (indexBehind >= 0) {
					this.quill.setSelection(indexBehind, 1, 'silent');
					formatBehind = this.quill.getFormat({ index: indexBehind, length: 1 });
				}
			}

		} catch (err) {
			//console.log(err);
		}



		return {
			formatAhead,
			formatBehind
		}

	}

	getNearbyFormats({
		workingRetain,
		workingInsert,
		workingDelete
	}) {

		let formatAhead = null;
		let formatBehind = null;

		let hasDeleteFormat = false;
		let hasInsertFormat = false;

		let totalLength = 0;
		if (workingDelete) {

			totalLength = workingDelete;

		}
		let indexAhead = workingRetain + totalLength;
		let indexBehind = workingRetain - 1;
		this.quill.setSelection(indexAhead, 1, 'silent');
		//formatAhead = this.quill.getFormat();
		formatAhead = this.quill.getFormat({ index: indexAhead, length: 1 });
		/*if (toBeDeleted > 0) {
			formatAhead = this.quill.getFormat({ index: indexAhead + toBeDeleted, length: 1 });
		}*/
		//No need to check format behind if cursor is at the beginning of the doc.
		//if (indexBehind != -1) {
		if (indexBehind >= 0) {
			this.quill.setSelection(indexBehind, 1, 'silent');
			formatBehind = this.quill.getFormat({ index: indexBehind, length: 1 });
		}


		return {
			formatAhead,
			formatBehind
		}

	}

	//Check to see if this original delta is attempting to delete a sequence that is already deleted.
	isDeletingDeleted({ delta }) {

		let retain = null;
		let totalLength = null;
		let toBeInserted = null;
		let toBeDeleted = null;

		try {
			delta.ops.forEach((op) => {
				Object.keys(op).forEach((key) => {
					if (key == 'retain') {
						retain = op[key];
					}
					else if (key == 'insert') {
						totalLength += op[key].length;
						toBeInserted = op[key];
					}
					else if (key == 'delete') {
						totalLength += op[key];
						toBeDeleted = op[key];
					}
				});
			})
		} catch (err) {
			////console.log(err);
		}



		let nextDeleteLength = this.getLengthOfNextFormat({
			formatType: 'editingDeleteBlot',
			//startIndex: workingRetain + totalLength,
			startIndex: retain,
			quill: this.quill
		});

		if (nextDeleteLength == toBeDeleted) {
			return true;
		}





	}

	isDeletingNewLine({ delta }) {

		if (!delta) {
			return false;
		}

		let retain = null;
		let toDelete = null;
		try {
			delta.ops.forEach((op) => {
				Object.keys(op).forEach((key) => {


					if (key == 'retain') {
						retain = op[key];
					}
					if (key == 'delete') {
						toDelete = op[key];
					}
				});
			})
		} catch (err) {
			////console.log(err);
		}

		if (retain && toDelete) {

			let selected = this.quill.setSelection(retain, toDelete, 'silent');
			let selectedDelta = this.quill.getContents(retain, toDelete);

			let newLineInserted = null;
			try {
				selectedDelta.ops.forEach((op) => {
					Object.keys(op).forEach((key) => {


						if (key == 'insert') {
							newLineInserted = op[key];
						}
					});
				})
			} catch (err) {
				////console.log(err);
			}

			if (newLineInserted && newLineInserted == '\n') {
				return true;
			}

			//console.log(selectedDelta);
		}

		return false;

	}

	extractInsertedIndex({ delta, quill }) {

		let insertedIndex = 0;

		delta.ops.forEach((op, index) => {

			if (op.hasOwnProperty('retain')) {
				insertedIndex += op.retain;
			}

			if (op.hasOwnProperty('delete')) {
				insertedIndex += op.delete;
			}



		})

		return insertedIndex;

	}



	replaceNewLinesWithBlots({ delta }) {

		if (!delta) {
			return null;
		}

		let newOps = [];

		delta.ops.forEach((op) => {

			if (op.hasOwnProperty('insert') && op.insert) {

				let sa = [];

				try {
					sa = op.insert.split("\n");
				} catch (err) {

				}


				sa.forEach((s, index) => {
					if (s.length > 0) {
						newOps.push({
							"insert": s,
							"attributes": op.attributes
						});
					}


					if (index < (sa.length - 1)) {
						newOps.push({
							"insert": {
								"editingNewLineBlot": {
									uuid: uuid()
								}
							}
						})

						// newOps.push({
						// 	"insert": {
						// 		"editingInsertNewLineBlot": {}
						// 	}
						// })
					}

				})



			}

		})

		delta = new Delta(newOps);

		return delta;
	}

	// [
	// 	{
	// 		"insert": {
	// 			"editingInsertNewLineBlot": {}
	// 		}
	// 	}
	// ]

	isEditingDeletedNewLine({ delta }) {

		if (delta && delta.ops.length == 1 && delta.ops[0].insert && delta.ops[0].insert.editingNewLineBlot) {
			return true;
		}

		return false;

	}

	isEditingInsertNewLine({ delta }) {

		if (delta && delta.ops.length == 1 && delta.ops[0].insert && delta.ops[0].insert.editingInsertNewLineBlot) {
			return true;
		}

		return false;

	}

	extractDeleteDelta({ delta, quill, latestSelectionRange, documentPart }) {

		let that = this.options.sceneEditor;
		let deleteHit = that.props.stores.bookStore.deleteHit;
		let backspaceHit = that.props.stores.bookStore.backspaceHit;
		//let deleteRange = that.props.stores.bookStore.deleteRange;
		let deleteRange = latestSelectionRange;
		let selectedDelta = null;
		//If del hit but nothing was selected do nothing.
		if (deleteHit && !deleteRange) {
			return {};
		}

		if ((deleteRange && deleteRange.length == 0) && !backspaceHit) {
			//return {};
		}


		//If there is not delete op and no del or backspace hit, then nothing is being delete.
		//Likely this is a format change ( like to bold or italic ) 
		let hasDeleteOp = false;

		if (delta) {

			delta.ops.forEach((op) => {

				if (op.hasOwnProperty('delete')) {

					hasDeleteOp = true;

				}
			})
		}
		//quill.history.undo(Quill.sources.SILENT);


		if (deleteHit && deleteRange) {
			if (deleteRange.length == 0) {
				deleteRange.length = 1;
			}
			selectedDelta = documentPart.composedDelta.slice(deleteRange.index, deleteRange.length + deleteRange.index);

			if (this.isEditingInsertNewLine({ delta: selectedDelta })) {

				//deleteRange.length += 1; //add one more to account for the \n that needs deleting.
				return { selectedDelta, deleteRange, isDeletingInsertedNewLine: true };

			}
			if (this.isEditingDeletedNewLine({ delta: selectedDelta })) {

				//deleteRange.length += 1; //add one more to account for the \n that needs deleting.
				return { selectedDelta, deleteRange, isDeletingDeletedNewLine: true };

			}

			selectedDelta = this.replaceNewLinesWithBlots({ delta: selectedDelta });
			//this.quill.setSelection(deleteRange);
			//selectedDelta = this.quill.getContents(deleteRange);
			//quill.history.redo(Quill.sources.SILENT);
			return { selectedDelta, deleteRange };
		}

		if (backspaceHit && deleteRange) {
			deleteRange.index = deleteRange.index - 1;
			if (deleteRange.length == 0) {
				deleteRange.length = 1;
			}
			//this.quill.setSelection(deleteRange);
			//selectedDelta = this.quill.getContents(deleteRange);
			//quill.history.redo(Quill.sources.SILENT);
			selectedDelta = documentPart.composedDelta.slice(deleteRange.index, deleteRange.length + deleteRange.index);
			//this.quill.setSelection(deleteRange.index - 1, 1, 'silent');
			let selectedMinusOneDelta = documentPart.composedDelta.slice(deleteRange.index - 1, deleteRange.index);
			if (this.isEditingInsertNewLine({ delta: selectedMinusOneDelta })) {
				this.quill.history.undo(Quill.sources.SILENT);
				this.setCursor({ index: deleteRange.index })
				return {
					knockItOff: true
				}
			}
			if (this.isEditingInsertNewLine({ delta: selectedDelta })) {

				//deleteRange.length += 1; //add one more to account for the \n that needs deleting.
				return { selectedDelta, deleteRange, isDeletingInsertedNewLine: true };

			}
			if (this.isEditingDeletedNewLine({ delta: selectedDelta })) {

				//deleteRange.length += 1; //add one more to account for the \n that needs deleting.
				return { selectedDelta, deleteRange, isDeletingDeletedNewLine: true };

			}
			selectedDelta = this.replaceNewLinesWithBlots({ delta: selectedDelta });
			return { selectedDelta, deleteRange };
		}

		if (!hasDeleteOp) {

			return {

			}
		}

		//***************** Use quill editor to get the to be deleted content  ************************/
		//let latestSelection = this.options.stores.bookStore.quillMostRecentSelectionRange;
		//this.quill.setSelection(latestSelectionRange);


		try {

			if (latestSelectionRange) {
				//selectedDelta = this.quill.getContents(latestSelectionRange);
				selectedDelta = documentPart.composedDelta.slice(latestSelectionRange.index, latestSelectionRange.length + latestSelectionRange.index);
			}

		} catch (err) {
			//console.log(err);
		}

		//quill.history.redo(Quill.sources.SILENT);
		return { selectedDelta, deleteRange };

	}

	extractDeleteOld({ delta, quill, latestSelectionRange }) {

		let that = this.options.sceneEditor;
		let deleteHit = that.props.stores.bookStore.deleteHit;
		//let deleteRange = that.props.stores.bookStore.deleteRange;
		let deleteRange = latestSelectionRange;
		let selectedDelta = null;
		//If del hit but nothing was selected do nothing.
		if (deleteHit && !deleteRange) {
			return;
		}

		if (deleteHit && deleteRange) {
			if (deleteRange.length == 0) {
				deleteRange.length = 1;
			}
			this.quill.setSelection(deleteRange);
			selectedDelta = this.quill.getContents(deleteRange);
			return { selectedDelta, deleteRange };
		}

		//***************** Use quill editor to get the to be deleted content  ************************/
		//let latestSelection = this.options.stores.bookStore.quillMostRecentSelectionRange;
		//this.quill.setSelection(latestSelectionRange);


		try {

			if (latestSelectionRange) {
				selectedDelta = this.quill.getContents(latestSelectionRange);
			}

		} catch (err) {
			//console.log(err);
		}


		return { selectedDelta, deleteRange };

	}

	removeNewLineFormatting({ delta }) {

		if (!delta) {
			return delta;
		}

		delta.ops.forEach((op) => {

			if (op && op.attributes && op.attributes.hasOwnProperty('editingInsertNewLineBlot')) {

				delete op.attributes.editingInsertNewLineBlot;

			}

		})

		return delta;

	}
	extractInsertedDelta({ delta, quill, latestSelectionRange }) {

		let insertedOps = [];

		//Check for a retain and a delete op. If both are present, indicates we may need to 'extract' the inserted op. 
		let hasDelete = false;
		let hasRetain = false;
		let preDeleteOpRetainLength = null;

		let clipBoardDelta = null;
		let clipBoardDeltaLength = null;
		try {
			clipBoardDelta = JSON.parse(JSON.stringify(this.quill.clipBoardDelta));
			clipBoardDeltaLength = new Delta(clipBoardDelta).length();

			let tabPurgedOps = [];

			clipBoardDelta.ops.forEach((op) => {

				if (op.hasOwnProperty('insert') && op.insert == "\t") {
					//Do nothing
				} else {
					tabPurgedOps.push(op);
				}

			})

			clipBoardDelta.ops = tabPurgedOps;
		} catch (err) {
			//console.log(err);
		}

		this.quill.clipBoardDelta = null;

		let deltaCopy = JSON.parse(JSON.stringify(delta));
		deltaCopy = this.removeNewLineFormatting({ delta: deltaCopy });

		let pastedOps = deltaCopy.ops;

		//Extract pasted content ops
		if (deltaCopy.ops.length >= 2) {

			if (deltaCopy.ops[0].hasOwnProperty('retain') && deltaCopy.ops[1].hasOwnProperty('delete')) {

				pastedOps = deltaCopy.ops.splice(2);

			}

			if (deltaCopy.ops[0].hasOwnProperty('retain') && !deltaCopy.ops[1].hasOwnProperty('delete')) {

				pastedOps = deltaCopy.ops.splice(1);

			}




		}

		//Note that deleted '\n' entries maintain their deletedBlot attributes even after the 
		//edit is 'deleted'	
		let tempDelta = new Delta(this.removeDeleteOps(pastedOps));
		let isInsertingNewLine = this.isInsertingNewLine({ delta: tempDelta });

		/*
		[
	{
		"retain": 5
	},
	{
		"insert": {
			"editingNewLineBlot": {}
		}
	}
]
		*/
		if (isInsertingNewLine) {
			//let insertNewlineDelta = this.quill.insertEmbed(5, 'editingInsertNewLineBlot', 'sssss', Quill.sources.SILENT);
			////console.log(insertNewlineDelta);



			let newLineDelta = new Delta([{
				"retain": latestSelectionRange.index
			},
			{
				"insert": {
					"editingInsertNewLineBlot": {
						uuid: uuid()
					}
				}
			}]);

			return {
				toBeInsertedDelta: newLineDelta,
				isNewLine: true
			}


		}

		if (!clipBoardDelta) {
			return {
				toBeInsertedDelta: new Delta(this.removeDeleteOps(pastedOps))
			}

			//return new Delta(this.removeDeleteOps(pastedOps));
		}


		let mergedOps = [];
		try {

			clipBoardDelta.ops.forEach((cbOp, index) => {

				let pastedOp = pastedOps[index];

				let mergedOp = {
					insert: cbOp.insert
				};

				if (pastedOp.hasOwnProperty('attributes')) {
					mergedOp.attributes = pastedOp.attributes;
				}
				mergedOps.push(mergedOp)
			})

		} catch (err) {
			//console.log(err);
		}


		let insertedDelta = new Delta(this.removeDeleteOps(mergedOps));



		//Split all inserts that contain a newline character. 
		let splitOps = [];

		insertedDelta.ops.forEach((op) => {

			if (op.hasOwnProperty('insert') && op.insert.includes("\n")) {

				let segments = op.insert.split('\n');
				segments.forEach((segment, index) => {
					//console.log(segment);

					let newOp = JSON.parse(JSON.stringify(op))
					newOp.insert = segment;
					splitOps.push(newOp);

					if (index < (segments.length - 1)) {
						splitOps.push({
							"insert": "\n"
						});
					}
				})
			} else {
				splitOps.push(op);
			}


		});




		insertedDelta.ops = splitOps;

		return {
			toBeInsertedDelta: insertedDelta
		}


		//return insertedDelta;

	}





	removeDeleteOps(ops) {

		if (!ops) {
			return;
		}

		let purgedOps = [];
		ops.forEach((op) => {

			if (!op.hasOwnProperty('delete') && !op.hasOwnProperty('retain')) {

				purgedOps.push(op);
			}
		})

		return purgedOps;
	}





	processInsert({
		toBeInsertedDelta,
		isNewLine,
		insertedIndex,
		allChangeDeltas,
		formatBehind,
		formatAhead
	}) {

		let blotUuid = uuid();
		let blotComment = '';
		let updateDelta = new Delta();

		/*
[
	{
		"retain": 34
	},
	{
		"insert": {
			"editingInsertNewLineBlot": {}
		}
	}
]
		*/
		//If inserting a newline, do not need any other processing.
		if (isNewLine) {
			return toBeInsertedDelta;
		}




		if (formatBehind && formatBehind.hasOwnProperty("editingInsertNewLineBlot")) {

			//delete the inserted content.
			let deleteDelta = new Delta().retain(insertedIndex).delete(toBeInsertedDelta.length());
			let reinsertDelta = new Delta().retain(1);

			let finalOps = deleteDelta.ops.concat(reinsertDelta.ops).concat(toBeInsertedDelta.ops)
			let finalDelta = new Delta(finalOps);
			//let finalDelta = deleteDelta.compose(reinsertDelta).compose(toBeInsertedDelta);
			//console.log(finalDelta);

			insertedIndex += 1;
			updateDelta = finalDelta;
			//return finalDelta;
		}




		if (formatBehind && formatBehind.hasOwnProperty("editingInsertBlot")) {

			blotUuid = formatBehind.editingInsertBlot.uuid;
			blotComment = formatBehind.editingInsertBlot.editorComment;
		}
		else if (formatAhead && formatAhead.hasOwnProperty("editingInsertBlot")) {

			blotUuid = formatAhead.editingInsertBlot.uuid;
			blotComment = formatAhead.editingInsertBlot.editorComment;
		}


		let previousInsertLength = this.getLengthOfPreviousFormat({
			formatType: 'editingInsertBlot',
			startIndex: insertedIndex - 1,
			quill: this.quill
		});

		let nextInsertLength = this.getLengthOfNextFormat({
			formatType: 'editingInsertBlot',
			//startIndex: workingRetain + totalLength,
			startIndex: insertedIndex,
			quill: this.quill
		});





		// let insertBlotDelta = null;
		let length = toBeInsertedDelta.length();
		// if (insertedIndex > 0) {
		// 	updateDelta = new Delta().retain(insertedIndex);
		// }
		// updateDelta.ops = updateDelta.ops.concat(toBeInsertedDelta.ops)






		//Remove the adjacent formatting so that we do not have multiple blots for the same 
		//edit on screen. The documentPart delta will be correct but the screen will not update
		//properly if we do not complete this step.
		let clearFormattingDelta = null;
		// if(previousInsertLength>0){


		// 	clearFormattingDelta = new Delta([
		// 		{
		// 			"retain": insertedIndex
		// 		},
		// 		{
		// 			"retain": previousInsertLength,
		// 			"attributes": {
		// 				"editingInsertBlot":null
		// 			}
		// 		}
		// 	]);



		// }




		//Set the range for the deleted and adjust it for adjacent same type formatting
		let formattingIndex = insertedIndex;
		let formattingLength = 0;

		if (previousInsertLength > 0) {

			formattingIndex = formattingIndex - previousInsertLength;
			formattingLength = previousInsertLength;

			this.quill.setSelection(formattingIndex, formattingLength, 'silent');
			clearFormattingDelta = this.quill.format('editingInsertBlot', false, Quill.sources.SILENT);
			//console.log(clearFormattingDelta);
		}

		else if (nextInsertLength > 0) {

			formattingIndex = formattingIndex;
			formattingLength = nextInsertLength + length;

			this.quill.setSelection(formattingIndex, formattingLength, 'silent');
			clearFormattingDelta = this.quill.format('editingInsertBlot', false, Quill.sources.SILENT);
			//console.log(clearFormattingDelta);
		}









		let insertBlotDelta = null;

		if (formattingIndex > 0) {

			insertBlotDelta = new Delta([
				{
					"retain": formattingIndex
				},
				{
					//"retain": formattingLength + length,
					"retain": 1,
					"attributes": {
						"editingInsertBlot": {
							"uuid": blotUuid,
							"accepted": "undefined",
							"rejected": "undefined",
							"editorPinned": "undefined",
							"requestorPinned": "undefined",
							"editorComment": ""
						}
					}
				}
			]);

		} else {
			insertBlotDelta = new Delta([

				{
					"retain": formattingLength + length,
					"attributes": {
						"editingInsertBlot": {
							"uuid": blotUuid,
							"accepted": "undefined",
							"rejected": "undefined",
							"editorPinned": "undefined",
							"requestorPinned": "undefined",
							"editorComment": ""
						}
					}
				}
			]);
		}


		updateDelta = updateDelta.compose(insertBlotDelta);


		if (clearFormattingDelta) {

			try {
				updateDelta = clearFormattingDelta.compose(updateDelta);
			} catch (err) {
				//console.log(err);
			}

		}

		return updateDelta;

	}




	processDelete({
		toBeDeletedDelta,
		toBeDeletedRange,
		isDeletingInsertedNewLine,
		isDeletingDeletedNewLine,
		quill,
		editingNewLineDetails,
		allChangeDeltas,
		formatBehind,
		formatAhead
	}) {

		let blotUuid = uuid();
		let blotComment = '';

		//************* process deleting newLineBlots *********************/

		//If the following conditions are met, need to delete the blot and insert "\n"
		if (editingNewLineDetails &&
			!editingNewLineDetails.hasNonInsertNewLineBlots &&
			editingNewLineDetails.length == 1) {




		}


		if (isDeletingInsertedNewLine) {

			let removeSlashNDelta = new Delta().retain(toBeDeletedRange.index).delete(1);
			//console.log(removeSlashNDelta);
			return removeSlashNDelta;
		}
		if (isDeletingDeletedNewLine) {

			let insertSlashNDelta = new Delta().retain(toBeDeletedRange.index).insert('\n');
			//console.log(insertSlashNDelta);
			return insertSlashNDelta;
		}






		if (formatBehind && formatBehind.hasOwnProperty("editingDeleteBlot")) {

			blotUuid = formatBehind.editingDeleteBlot.uuid;
			blotComment = formatBehind.editingDeleteBlot.editorComment;
		}
		else if (formatAhead && formatAhead.hasOwnProperty("editingDeleteBlot")) {

			blotUuid = formatAhead.editingDeleteBlot.uuid;
			blotComment = formatAhead.editingDeleteBlot.editorComment;
		}


		let previousDeleteLength = this.getLengthOfPreviousFormat({
			formatType: 'editingDeleteBlot',
			startIndex: toBeDeletedRange.index - 1,
			quill
		});

		let nextDeleteLength = this.getLengthOfNextFormat({
			formatType: 'editingDeleteBlot',
			startIndex: toBeDeletedRange.index + toBeDeletedRange.length,
			startIndex: toBeDeletedRange.index,
			quill
		});


		//Reinsert the deleted text since the delete is no longer undone. 
		let reinsertDeletedDelta = new Delta();
		// if(toBeDeletedRange && toBeDeletedRange.index>0){

		// 	reinsertDeletedDelta = new Delta([
		// 		{
		// 			"retain": toBeDeletedRange.index
		// 		}
		// 	])
		// }
		// reinsertDeletedDelta = reinsertDeletedDelta.compose(toBeDeletedDelta);

		if (toBeDeletedRange.index > 0) {

			reinsertDeletedDelta.ops = [{ "retain": toBeDeletedRange.index }].concat(toBeDeletedDelta.ops);

		} else {

			reinsertDeletedDelta.ops = toBeDeletedDelta.ops;

		}


		//Remove the adjacent formatting so that we do not have multiple blots for the same 
		//edit on screen. The documentPart delta will be correct but the screen will not update
		//properly if we do not complete this step.
		//let clearFormattingDelta = reinsertDeletedDelta;
		//let clearFormattingDelta = new Delta();
		if (previousDeleteLength > 0) {


			let tempLength = (toBeDeletedRange.index - toBeDeletedRange.length);

			let clearFormattingDelta = new Delta([
				{
					"retain": (toBeDeletedRange.index - toBeDeletedRange.length)
				},
				{
					"retain": previousDeleteLength,
					"attributes": {
						"editingDeleteBlot": null
					}
				}
			]);

			if (tempLength == 0) {
				clearFormattingDelta = new Delta([

					{
						"retain": previousDeleteLength,
						"attributes": {
							"editingDeleteBlot": null
						}
					}
				]);
			}

			reinsertDeletedDelta = reinsertDeletedDelta.compose(clearFormattingDelta);



		}

		//Set the range for the deleted and adjust it for adjacent same type formatting
		let formattingIndex = toBeDeletedRange.index;
		let formattingLength = toBeDeletedRange.length;

		//Clearing the formatting in real time quill is different from clearing it 
		//in the delta applied to the document part. This is because at this point the editor
		//has actually completed a delete ( it has not been undone ).
		if (previousDeleteLength > 0) {

			formattingIndex = formattingIndex - previousDeleteLength;
			//formattingLength = previousDeleteLength;
			formattingLength = toBeDeletedRange.length + previousDeleteLength;

			// this.quill.setSelection(formattingIndex, previousDeleteLength, 'silent');
			// let clearFrmattingDelta = this.quill.format('editingDeleteBlot', false, Quill.sources.SILENT);
			// ////console.log(clearFrmattingDelta);
		}

		else if (nextDeleteLength > 0) {

			formattingIndex = formattingIndex;
			formattingLength = toBeDeletedRange.length + nextDeleteLength;

			// this.quill.setSelection(formattingIndex, formattingLength, 'silent');
			// let clearFrmattingDelta = this.quill.format('editingDeleteBlot', false, Quill.sources.SILENT);
			// ////console.log(clearFrmattingDelta);
		}


		let deletetBlotDelta = this.createDeletedBlotDelta({
			toBeDeletedDelta,
			startingIndex: formattingIndex,
			previousBlotUuid: blotUuid,
			previousBlotComment: blotComment
		})


		let deletetBlotDeltax = new Delta([

			{
				"retain": formattingLength,
				"attributes": {
					"editingDeleteBlot": {
						"uuid": blotUuid,
						"type": "delete",
						"accepted": "undefined",
						"rejected": "undefined",
						"editorPinned": "undefined",
						"requestorPinned": "undefined",
						"editorComment": blotComment
					}
				}
			}
		]);

		//if (toBeDeletedRange.index > 0) {
		if (formattingIndex > 0) {

			deletetBlotDeltax = new Delta([
				{
					"retain": formattingIndex
				},
				{
					"retain": formattingLength,
					"attributes": {
						"editingDeleteBlot": {
							"uuid": blotUuid,
							"type": "delete",
							"accepted": "undefined",
							"rejected": "undefined",
							"editorPinned": "undefined",
							"requestorPinned": "undefined",
							"editorComment": ""
						}
					}
				}
			]);

		}

		// this.quill.setSelection(toBeDeletedRange, 'silent');
		// let deletetBlotDelta = this.quill.format('editingDeleteBlot', {
		// 	editorComment: '',
		// 	uuid: blotUuid
		// }, 'silent');
		//allChangeDeltas.push(deletetBlotDelta);

		//if (clearFormattingDelta) {

		reinsertDeletedDelta = reinsertDeletedDelta.compose(deletetBlotDelta);

		//next remove the delete formatting from each editingNewLineBlot
		let runningLength = 0;
		let removeFormattingDeltaOps = [];
		reinsertDeletedDelta.ops.forEach((op) => {

			if (op.hasOwnProperty('retain')) {

				runningLength += op.retain;
			}

			else if (op.hasOwnProperty('insert') && !op.insert.editingNewLineBlot) {

				runningLength += op.insert.length;
			}

			else if (op.hasOwnProperty('insert') && op.insert.editingNewLineBlot) {

				//add a remove formatting here.
				removeFormattingDeltaOps.push({
					"retain": runningLength
				});

				removeFormattingDeltaOps.push({
					"retain": 1,
					"attributes": {
						"editingDeleteBlot": null
					}
				});



			}


		})

		//console.log(runningLength)

		reinsertDeletedDelta = reinsertDeletedDelta.compose(new Delta(removeFormattingDeltaOps));


		// [
		// 	{
		// 		"retain": 94
		// 	},
		// 	{
		// 		"insert": "CDK."
		// 	},
		// 	{
		// 		"insert": {
		// 			"editingNewLineBlot": {}
		// 		}
		// 	},
		// 	{
		// 		"insert": "To"
		// 	}
		// ]
		//}
		return reinsertDeletedDelta;

	}


	createDeletedBlotDelta({
		toBeDeletedDelta,
		startingIndex,
		previousBlotUuid = uuid(),
		previousBlotComment = ''
	}) {

		/*
		[
	{
		"insert": "123456",
		"attributes": {
			"editingInsertBlot": {
				"editorPinned": "undefined",
				"accepted": "undefined",
				"editorComment": "",
				"requestorPinned": "undefined",
				"uuid": "dd4e0ca0-2f7d-11ec-92b4-cb728ce22116",
				"rejected": "undefined"
			}
		}
	},
	{
		"insert": "fo"
	},
	{
		"insert": "llow",
		"attributes": {
			"editingDeleteBlot": {
				"editorPinned": "undefined",
				"requestorPinned": "undefined",
				"rejected": "undefined",
				"accepted": "undefined",
				"editorComment": "",
				"type": "delete",
				"uuid": "ecc9d330-2f7d-11ec-92b4-cb728ce22116"
			}
		}
	},
	{
		"insert": "ing"
	},
	{
		"insert": "QWERTY",
		"attributes": {
			"editingInsertBlot": {
				"editorPinned": "undefined",
				"accepted": "undefined",
				"editorComment": "",
				"requestorPinned": "undefined",
				"uuid": "e4798770-2f7d-11ec-92b4-cb728ce22116",
				"rejected": "undefined"
			}
		}
	}
]
		*/
		/*
		let deletetBlotDelta = new Delta([

			{
				"retain": formattingLength,
				"attributes": {
					"editingDeleteBlot": {
						"uuid": blotUuid,
						"type": "delete",
						"accepted": "undefined",
						"rejected": "undefined",
						"editorPinned": "undefined",
						"requestorPinned": "undefined",
						"editorComment": blotComment
					}
				}
			}
		]);

		//if (toBeDeletedRange.index > 0) {
		if (formattingIndex > 0) {

			deletetBlotDelta = new Delta([
				{
					"retain": formattingIndex
				},
				{
					"retain": formattingLength,
					"attributes": {
						"editingDeleteBlot": {
							"uuid": blotUuid,
							"type": "delete",
							"accepted": "undefined",
							"rejected": "undefined",
							"editorPinned": "undefined",
							"requestorPinned": "undefined",
							"editorComment": ""
						}
					}
				}
			]);

		}
		*/

		let finalDelta = new Delta();
		let currentIndex = startingIndex;


		if (currentIndex > 0) {
			finalDelta.ops.push({
				"retain": currentIndex
			})
		}
		toBeDeletedDelta.ops.forEach((op, index) => {


			if (this.isInsertedOp(op) && op.insert && op.insert.length > 0) {
				//delete this op
				finalDelta.ops.push({
					"delete": op.insert.length
				})

				currentIndex += op.insert.length;
			}
			else if (this.isDeletedOp(op)) {
				//remove its existing deleted blot and add a new one.
				finalDelta.ops.push({
					"retain": op.insert.length,
					"attributes": {
						"editingDeleteBlot": {
							"uuid": previousBlotUuid,
							"type": "delete",
							"accepted": "undefined",
							"rejected": "undefined",
							"editorPinned": "undefined",
							"requestorPinned": "undefined",
							"editorComment": previousBlotComment
						}
					}

				})
				currentIndex += op.insert.length;
			}
			else {
				//create a new deleted blot
				finalDelta.ops.push({
					"retain": op.insert.length,
					"attributes": {
						"editingDeleteBlot": {
							"uuid": previousBlotUuid,
							"type": "delete",
							"accepted": "undefined",
							"rejected": "undefined",
							"editorPinned": "undefined",
							"requestorPinned": "undefined",
							"editorComment": previousBlotComment
						}
					}
				})
			}



		})

		return finalDelta;


	}






	processSelection({
		latestSelectionRange,
		quill,
		allChangeDeltas
	}) {


		this.quill.setSelection(latestSelectionRange, 'silent');
		let insertBlotDelta = this.quill.format('editingInsertBlot', {
			editorComment: 'This is the toolbar supplied editor comment',
			uuid: uuid()
		}, 'silent');
		allChangeDeltas.push(insertBlotDelta);

	}

	isInsertingNewLine({ delta }) {

		let foundNewLine = false;
		if (!delta) return false;

		delta.ops.forEach((op) => {

			if (op.hasOwnProperty('insert') && op.insert == "\n") {

				foundNewLine = true;

			}
		})

		return foundNewLine;
	}

	extractEditingNewLineDetails({
		delta
	}) {

		if (!delta) {
			return {};
		}

		/*
		[
	{
		"insert": {
			"editingNewLineBlot": {}
		}
	}
]
		*/

		let insertNewLineBlots = [];
		let hasNonInsertNewLineBlots = false;
		delta.ops.forEach((op) => {

			if (op.hasOwnProperty('insert') && op.insert.editingNewLineBlot) {

				insertNewLineBlots.push(op);

			} else {

				hasNonInsertNewLineBlots = true;

			}

		})


		return {
			insertNewLineBlots,
			hasNonInsertNewLineBlots
		}
	}

	extractFormattedDelta({ delta, quill, latestSelectionRange, documentPart }) {

		if (!delta) {
			return {

			}
		}

		let hasInserts = false;
		let hasDeletes = false;
		let hasAttributes = false;
		let formatAttributes = null;
		let selectedDelta = new Delta();
		if (latestSelectionRange) {

			//selectedDelta = quill.getContents(latestSelectionRange);
			selectedDelta = documentPart.composedDelta.slice(latestSelectionRange.index, latestSelectionRange.length + latestSelectionRange.index);
			//console.log(selectedDelta);
		}

		//Check the original delta to verify this is a pure format change.
		if (delta) {

			delta.ops.forEach((op) => {

				if (op.hasOwnProperty('delete')) {

					hasDeletes = true;

				}

				if (op.hasOwnProperty('insert')) {

					hasInserts = true;

				}

				if (op.hasOwnProperty('attributes') && !op.attributes.hasOwnProperty('editingDeleteBlot')) {

					hasAttributes = true;
					formatAttributes = op.attributes;
				}



			})
		}

		if (hasInserts || hasDeletes) {
			return {

			}
		}


		//If we have actual attributes, in the original delta, then process the selectionDelta
		if (hasAttributes) {

			let clearAllAttributes = {
				"bold": null,
				"underline": null,
				"italic": null,
				"strike": null,
				"script": null,
				"link2": null,
				"fontVariant": null,
				"fontSizeBM": null
			}
			selectedDelta.ops.forEach((op) => {

				let newAttributes = JSON.parse(JSON.stringify(clearAllAttributes));
				/****************** CHANGE IN PLANS **********************/
				// Deleted text should be formatted too, since the author can reject a deletion
				// but wouldn't want to have to go back and manually add formatting back in for the text
				//that was deleted before the surrounding text was formatted. 


				// if(this.isDeletedOp(op)){
				//  **** The following is the old strategy ****
				// 	//Need to reverse the changes already made to the deleted text since
				// 	//quill has already been updated. To do so, keep the formatting that exists 
				// 	//in this op since it came from the documentPart that has yet to be updated. 
				// 	//If this each format attribute not present 
				// 	if(op.attributes){

				// 		Object.keys(op.attributes).forEach((keepAttribute)=>{

				// 			newAttributes[keepAttribute] = op.attributes[keepAttribute];

				// 		})

				// 		op.attributes = newAttributes;
				// 	}

				// 	op.retain = op.insert.length;
				// 	delete op.insert;
				// }

				// if(this.isDeletedOp(op)){
				// 	Object.keys(formatAttributes).forEach((key)=>{
				// 		op.attributes[key] = formatAttributes[key];
				// 	})

				// 	op.retain = op.insert.length;
				// 	delete op.insert;
				// }

				if (this.isInsertedOp(op)) {
					Object.keys(formatAttributes).forEach((key) => {
						op.attributes[key] = formatAttributes[key];
					})

					op.retain = op.insert.length;
					delete op.insert;
				}
				// else if(this.isFormatOp(op)){
				// 	Object.keys(formatAttributes).forEach((key)=>{
				// 		op.attributes[key] = formatAttributes[key];
				// 	})

				// 	op.retain = op.insert.length;
				// 	delete op.insert;
				// }
				//else this is original text
				else {

					if (!op.attributes) {
						op.attributes = {};
					}

					op.retain = op.insert.length;
					delete op.insert;

					//Add the formatting
					Object.keys(formatAttributes).forEach((key) => {
						op.attributes[key] = formatAttributes[key];
					})
					//Then add a editingFormatBlot
					op.attributes.editingFormatBlot = {
						"uuid": uuid(),
						"type": "format",
						"accepted": "undefined",
						"rejected": "undefined",
						"editorPinned": "undefined",
						"requestorPinned": "undefined",
						"editorComment": ''
					}
				}

			})

			if (latestSelectionRange && latestSelectionRange.index > 0) {

				selectedDelta.ops = [{ retain: latestSelectionRange.index }].concat(selectedDelta.ops);

			}
			return {

				toBeFormattedDelta: selectedDelta

			}
		}


		return {

		}

	}

	isDeletedOp(op) {

		if (!op) {
			return false;
		}

		if (op.attributes && op.attributes.editingDeleteBlot != null) {
			return true;
		}
	}


	isInsertedOp(op) {

		if (!op) {
			return false;
		}

		if (op.attributes && op.attributes.editingInsertBlot != null) {
			return true;
		}
	}


	isFormatOp(op) {

		if (!op) {
			return false;
		}

		if (op.attributes && op.attributes.editingFormatBlot != null) {
			return true;
		}
	}
	processFormat({
		toBeFormattedDelta,
		quill,
		allChangeDeltas,
		formatBehind,
		formatAhead
	}) {

		// {
		// 	"retain": formattingLength,
		// 	"attributes": {
		// 		"editingDeleteBlot": {
		// 			"uuid": blotUuid,
		// 			"type": "delete",
		// 			"accepted": "undefined",
		// 			"rejected": "undefined",
		// 			"editorPinned": "undefined",
		// 			"requestorPinned": "undefined",
		// 			"editorComment": blotComment
		// 		}
		// 	}
		// }

		// [
		// 	{
		// 		"retain": 3
		// 	},
		// 	{
		// 		"retain": 4,
		// 		"attributes": {
		// 			"bold": true
		// 		}
		// 	}
		// ]

		let blotUuid = uuid();
		toBeFormattedDelta.ops.forEach((op) => {

			if (op.hasOwnProperty('attributes')) {

				op.attributes.editingFormatBlot = {
					"uuid": blotUuid,
					"type": "format",
					"accepted": "undefined",
					"rejected": "undefined",
					"editorPinned": "undefined",
					"requestorPinned": "undefined",
					"editorComment": ''
				}
			}

		})

		return {
			formatBlotDelta: toBeFormattedDelta
		}


	}


	update(delta, oldDelta, source) {

		//console.log(delta);

		return;
		if (!delta) {
			return;
		}

		let allChangeDeltas = [];
		let updateDelta = new Delta();

		let insertBlotDelta = this.processInsert({
			toBeInsertedDelta: delta,
			isNewLine: false,
			insertedIndex: 5,
			quill: this.quill,
			allChangeDeltas: [],
			formatBehind: null,//insertedNearbyFormats.formatBehind,
			formatAhead: null//insertedNearbyFormats.formatAhead
		});


		updateDelta = updateDelta.compose(insertBlotDelta);

		allChangeDeltas.push(updateDelta);

		this.options.stores.bookStore.changeTrackerWorking = false;

		this.options.callback({
			allChangeDeltas,
			editsAdded: true
		});
		return;



	}

	update_original(delta, oldDelta, source) {

		//console.log(delta);
		if (!this.active || !delta) {
			try {
				this.options.stores.bookStore.changeTrackerWorking = false;
			} catch (err) {
				//console.log(err);
			}

			return;
		}

		if (this.options.stores.bookStore.changeTrackerWorking) {

			return;

		}

		if (delta == null) {
			return;
		}

		if (!this.cycles) {
			this.cycles = 0;
		}
		this.cycles++;
		//***************** Setup vars  ************************/
		let allChangeDeltas = [];
		let editsAdded = true;
		let that = this.options.sceneEditor;
		let deleteHit = that.props.stores.bookStore.deleteHit;
		let backspaceHit = that.props.stores.bookStore.backspaceHit;
		let documentPart = this.options.documentPart;

		//***************** Extract current selection ( before any undos since selection data is lost)  ************************/
		let latestSelectionRange = this.options.stores.bookStore.quillMostRecentSelectionRange;
		this.options.stores.bookStore.quillMostRecentSelectionRange = null;

		///////////////////////////////////////////////////////////////////////////////
		// let that = this.options.sceneEditor;

		// that.props.stores.bookStore.backspaceHit = false;
		// that.props.stores.bookStore.backspaceRange = null;

		// that.props.stores.bookStore.deleteHit = false;
		// that.props.stores.bookStore.deleteRange = null;

		// this.options.stores.bookStore.quillMostRecentSelectionRange = null;
		////////////////////////////////////////////////////////////////////////////////





		//DO NOT: select anything yet since an undo is required for the latestSelectionRange to make sense.
		//let selectedDelta = this.quill.getContents(latestSelectionRange);


		//Must undo before extracting insert delta so quill contents can be used
		//to infer inserted text from combined delete/insert operations.
		//this.quill.history.undo(Quill.sources.SILENT);
		//allChangeDeltas.push({ undo: true });

		//this.quill.setSelection(latestSelectionRange);
		// this.quill.updateContents(delta, Quill.sources.SILENT);
		// allChangeDeltas.push(delta);
		//return;



		//***************** Extract values  ************************/
		let { toBeFormattedDelta } = this.extractFormattedDelta({ delta, quill: this.quill, latestSelectionRange, documentPart });




		let { toBeInsertedDelta, isNewLine } = this.extractInsertedDelta({ delta, quill: this.quill, latestSelectionRange });
		let insertedIndex = this.extractInsertedIndex({ delta, quill: this.quill, latestSelectionRange });
		let toBeInsertedLength = 0;
		if (toBeInsertedDelta) {
			toBeInsertedLength = toBeInsertedDelta.length();
		}
		let extractDeleteDetails = {};
		let toBeDeletedDelta = null;
		let toBeDeletedRange = null;
		let isDeletingInsertedNewLine = null;
		let isDeletingDeletedNewLine = null;
		let knockItOff = false;

		//if (latestSelectionRange && latestSelectionRange.length > 0) {

		extractDeleteDetails = this.extractDeleteDelta({
			delta,
			quill: this.quill,
			latestSelectionRange,
			documentPart
		});
		toBeDeletedDelta = extractDeleteDetails.selectedDelta;
		toBeDeletedRange = extractDeleteDetails.deleteRange;
		isDeletingInsertedNewLine = extractDeleteDetails.isDeletingInsertedNewLine;
		isDeletingDeletedNewLine = extractDeleteDetails.isDeletingDeletedNewLine;
		knockItOff = extractDeleteDetails.knockItOff;
		if (knockItOff == true) {
			this.options.stores.bookStore.changeTrackerWorking = false;
			return;
		}


		let editingNewLineDetails = this.extractEditingNewLineDetails({
			delta: toBeDeletedDelta
		});



		//}


		//let toBeDeletedRange = that.props.stores.bookStore.deleteRange;
		//let toBeDeletedRange = latestSelectionRange;

		//let latestSelectionRange = this.options.stores.bookStore.quillMostRecentSelectionRange;
		//let selectedDelta = this.quill.getContents(latestSelectionRange);
		//this.quill.setSelection(latestSelection);

		//**************** Temp ****************************/
		// if(toBeDeletedRange){
		// 	this.quill.setSelection(toBeDeletedRange);
		// }

		// this.resetValues();
		// return;


		//***************** Get adjacent formats ************************/
		let { formatBehind, formatAhead } = this.getNearbyFormatsNew({
			range: extractDeleteDetails.deleteRange,
			formatType: 'editingDeleteBlot'
		});

		let insertedNearbyFormats = this.getNearbyFormatsNew({
			range: { index: insertedIndex, length: toBeInsertedLength },
			formatType: 'editingInsertBlot'
		});


		//***************** Create deltas  ************************/
		let updateDelta = new Delta();
		// let updateDelta = new Delta().retain(insertedIndex);
		// updateDelta.ops = updateDelta.ops.concat(toBeInsertedDelta.ops)
		// allChangeDeltas.push(updateDelta);

		//***************** Create format delta  ************************/
		if (toBeFormattedDelta && toBeFormattedDelta.ops.length > 0) {

			// let {formatBlotDelta} = this.processFormat({
			// 	toBeFormattedDelta,
			// 	quill: this.quill,
			// 	allChangeDeltas,
			// 	formatBehind,
			// 	formatAhead
			// });

			//updateDelta = updateDelta.compose(formatBlotDelta);
			updateDelta = updateDelta.compose(toBeFormattedDelta);


		}




		//***************** Create delete delta  ************************/
		if (toBeDeletedRange && toBeDeletedRange.length > 0) {

			let deletetBlotDelta = this.processDelete({
				toBeDeletedDelta,
				toBeDeletedRange,
				isDeletingInsertedNewLine,
				isDeletingDeletedNewLine,
				quill: this.quill,
				allChangeDeltas,
				editingNewLineDetails,
				formatBehind,
				formatAhead
			});
			updateDelta = updateDelta.compose(deletetBlotDelta);
		}

		if (toBeInsertedDelta.ops.length > 0) {

			//let insertDelta = new Delta().retain(insertedIndex);
			//updateDelta.ops = insertDelta.ops.concat(toBeInsertedDelta.ops)

			let insertBlotDelta = this.processInsert({
				toBeInsertedDelta,
				isNewLine,
				insertedIndex,
				quill: this.quill,
				allChangeDeltas,
				formatBehind: insertedNearbyFormats.formatBehind,
				formatAhead: insertedNearbyFormats.formatAhead
			});


			updateDelta = updateDelta.compose(insertBlotDelta);

		}




		//The quill editor and the documentPart.deltaOps require two different updates. 
		//Since the editor already has the inserted text, since we are no longer using an undo, 
		//it does not require the inbound 'delta' to be applied. However, the documentPart does require 
		//the inbound 'delta.

		let documentPartUpdateDelta = null;
		if (toBeFormattedDelta && toBeFormattedDelta.ops.length > 0) {

			documentPartUpdateDelta = toBeFormattedDelta;

		} else {
			documentPartUpdateDelta = delta.compose(updateDelta);
		}

		// let documentPartUpdateDelta = toBeInsertedDelta.compose(updateDelta);

		// let splitOps = [];

		// documentPartUpdateDelta.ops.forEach((op) => {

		// 	if (op.hasOwnProperty('insert') && op.insert.includes("\n")) {

		// 		let segments = op.insert.split('\n');
		// 		segments.forEach((segment, index) => {
		// 			//console.log(segment);

		// 			let newOp = JSON.parse(JSON.stringify(op))
		// 			newOp.insert = segment;
		// 			splitOps.push(newOp);

		// 			if (index < (segments.length - 1)) {
		// 				splitOps.push({
		// 					"insert": "\n"
		// 				});
		// 			}
		// 		})
		// 	} else {
		// 		splitOps.push(op);
		// 	}


		// });

		// documentPartUpdateDelta.ops = splitOps;


		//allChangeDeltas.push(updateDelta);
		allChangeDeltas.push(documentPartUpdateDelta);

		let allChangesDeltasComposed = new Delta();
		allChangeDeltas.forEach((delta) => {

			allChangesDeltasComposed = allChangesDeltasComposed.compose(delta);

		});




		//***************** Apply deltas to live quill(s)  ************************/
		let sceneEditors = that.props.stores.bookStore.sceneEditorsByPart[documentPart.id];
		if (sceneEditors) {

			sceneEditors.forEach((sceneEditor) => {

				let sceneQuill = sceneEditor.state.quill;
				//console.log(sceneQuill);

				if (this.quill === sceneQuill) {
					sceneQuill.updateContents(updateDelta, Quill.sources.SILENT);
				}
				else {

					sceneQuill.updateContents(allChangesDeltasComposed, Quill.sources.SILENT);
					//console.log('...')
				}


			})
		}

		//this.quill.updateContents(updateDelta, Quill.sources.SILENT);

		//***************** Reset values  ************************/
		//Reset them before setting the cursor since it nulls this.options.stores.bookStore.quillMostRecentSelectionRange
		//Setting the cursor sets this value. So do not reset values after setting the cursor.
		this.resetValues();

		//***************** Set cursor  ************************/
		if (toBeInsertedDelta.length() > 0) {

			if (isNewLine) {
				let newCursorIndex = insertedIndex + 1;
				//let selection = this.quill.setSelection( { index:newCursorRange, length:0 }, 'silent');
				this.setCursor({ index: newCursorIndex });
			} else {
				let newCursorIndex = insertedIndex + toBeInsertedDelta.length();
				//let selection = this.quill.setSelection( { index:newCursorRange, length:0 }, 'silent');
				this.setCursor({ index: newCursorIndex });
			}

		} else {
			if (backspaceHit) {

				if (isDeletingInsertedNewLine) {
					let newCursorIndex = insertedIndex - 1;
					this.setCursor({ index: newCursorIndex });

				} else {
					if (toBeInsertedDelta) {
						try {

							let newCursorIndex = insertedIndex + toBeInsertedDelta.length() + toBeDeletedRange.length;
							//this.quill.setSelection( { index:newCursorRange, length:0 }, 'silent');
							this.setCursor({ index: newCursorIndex });

						} catch (err) {
							//console.log(err);
						}

					}


				}
			} else if (deleteHit) {

				if (isDeletingInsertedNewLine) {

					let newCursorIndex = insertedIndex - 1;
					this.setCursor({ index: newCursorIndex });

				} else {
					let newCursorIndex = latestSelectionRange.index + toBeDeletedRange.length;
					//this.quill.setSelection( { index:newCursorRange, length:0 }, 'silent');
					this.setCursor({ index: newCursorIndex });

				}





			}

		}


		//that.props.stores.bookStore.quillMostRecentSelectionRange = null;

		//***************** Return results  ************************/
		this.options.stores.bookStore.changeTrackerWorking = false;

		this.options.callback({
			allChangeDeltas,
			editsAdded
		});
		return;
	}

	setCursor({ index }) {

		let range = { index, length: 0 };
		//console.log(range);
		this.quill.setSelection(index, 1, 'silent');
		this.quill.setSelection(range, 'silent');
		this.options.stores.bookStore.quillMostRecentSelectionRange = range;

	}
	resetValues() {

		let that = this.options.sceneEditor;

		that.props.stores.bookStore.backspaceHit = false;
		that.props.stores.bookStore.backspaceRange = null;

		that.props.stores.bookStore.deleteHit = false;
		that.props.stores.bookStore.deleteRange = null;

		this.options.stores.bookStore.quillMostRecentSelectionRange = null;

	}




}

let findRangeByFormat = function (quill, index, formatType) {

	let counter = 0;
	let forwardCount = 0;
	let reverseCount = 0;
	let hasFormat = true;
	let contentsLength = quill.getLength();

	while (hasFormat && counter < (contentsLength - index)) {
		counter++;
		let format = quill.getFormat(index + counter, 1);
		if (!format[formatType]) {
			hasFormat = false;
			forwardCount = counter;
		}
		////console.log(format);
	}

	hasFormat = true;
	counter = 0;
	while (counter < index && hasFormat) {
		counter++;
		let format = quill.getFormat(index - counter, 1);
		if (!format[formatType]) {
			hasFormat = false;
			reverseCount = counter;
			counter--;
		}
	}

	return {
		index: index - counter,
		length: forwardCount + counter
	}

}
export default ChangeTracker;