import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const WriteDetailsOutline = (props) => {

    const theme = useTheme();

    let { bookDraftDocumentPart,bookDraft} = props;


    let userId = props.stores?.bookStore.userId;

 


    return (    
        <Box container direction={'column'} sx={{
            justifyContent: 'center',
            //marginRight:'1em'
        }}>
          WriteDetailsOutline...
           
        </Box>
    );
};


export default inject('stores')(observer(WriteDetailsOutline));
