import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import UserAvatar from '../../lists/UserAvatar';
import ReactTimeAgo from 'react-time-ago';
import UserListItem from '../../listPages/lists/UserListItem';

const ReadDetailsSettings = (props) => {

    const theme = useTheme();

    let { bookDraftDocumentPart, sharedBook, bookDraft } = props;


    let userId = props.stores?.bookStore.userId;
    let isBoxSet = bookDraft?.book.boxSetId?true:false;

    let shareType = isBoxSet?'boxset':'book';
    //console.log(sharedBook);


    return (
        <Box container direction={'column'} sx={{
            justifyContent: 'center',
            //marginRight:'1em'
        }}>


            {/* <div>{bookDraft?.book.boxSetId}</div> */}
            {/* <div>{<>This {shareType} shared: <ReactTimeAgo date={sharedBook?.sharedAt} locale="en-US" /> </>}</div> */}
            <Typography>This {shareType} is shared with you by:</Typography>
            <UserListItem 
                user={sharedBook?.owner}
                secondaryText={sharedBook?.sharedAt != null ? <>This {shareType} shared: <ReactTimeAgo date={sharedBook.sharedAt} locale="en-US" /> </>: ''}/>
           

        </Box>
    );
};


export default inject('stores')(observer(ReadDetailsSettings));
