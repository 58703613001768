import { styled } from "@mui/material";
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DescriptionIcon from '@mui/icons-material/Description';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ImageIcon from '@mui/icons-material/Image';
import WidgetsIcon from '@mui/icons-material/Widgets';

export const StandardBaseStepper = styled(Stepper)(() => ({

  background: 'transparent',
  boxShadow: 'none'

}));


export default function StandardStepper(props) {

  //const [activeStep, setActiveStep] = React.useState(2);

  const { steps, step, onStepChange } = props;

  const theme = useTheme();

  // const handleNext = (index) => {
  //   setActiveStep(index);
  // };


  const ColorlibStepIconRoot = styled('div')
    (({ theme, ownerState }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
      zIndex: 1,
      color: '#fff',
      width: '2em',
      height: '2em',
      display: 'flex',
      borderRadius: '1em',
      justifyContent: 'center',
      alignItems: 'center',
      ...(ownerState.active && {
        backgroundColor: theme.palette.secondary.main,
        color: 'white'
        // backgroundImage:
        //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      }),
      ...(!ownerState.active && {
        //backgroundColor: theme.palette.smallIcons.color.main,
        color: 'darkgray'
        // backgroundImage:
        //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      }),
    }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;


    // const icons = {
    //   1: <MenuBookIcon />,
    //   2: <DescriptionIcon />,
    //   3: <ViewHeadlineIcon />,
    //   4: <TextFieldsIcon />,
    //   5: <ImageIcon />,
    //   6: <WidgetsIcon />,
    // };

    // const labels = {
    //   1: 'Book',
    //   2: 'Page',
    //   3: 'Heading',
    //   4: 'Text',
    //   5: 'Images',
    //   6: 'Widgets',
    // };

    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems:'center'
      }}>
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
          {steps[props.icon-1].icon}
        </ColorlibStepIconRoot>

        <Typography sx={{
          fontWeight:active?'700':'100',
          marginLeft:'.5em',
          color: active?theme.palette.secondary.main:theme.palette.standardTree.nodes.color.main
        }}>{steps[props.icon-1].label}</Typography>

      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 400 }}>
      <StandardBaseStepper nonLinear activeStep={step} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label} onClick={() => { onStepChange(index) }}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
            //   optional={
            //     index === 2 ? (
            //       <Typography variant="body1">Last step</Typography>
            //     ) : null
            //   }
            >
              {/* <Typography sx={{
                color: theme.palette.smallIcons.color.main
              }}>{step.label}</Typography> */}
            </StepLabel>
            <StepContent >
              {/* <Typography variant='body2' sx={{ color:theme.palette.text.secondary}}>{step.description}</Typography> */}


            </StepContent>
          </Step>
        ))}
      </StandardBaseStepper>
      {/* {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
    </Box>
  );
}

