import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import SplitPane, { Pane } from 'react-split-pane';
import BookDraftEditor from './editor/BookDraftEditor';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from 'react-responsive'

const SplitScreenController = (props) => {


    let { showBothPanes } = props;
    const theme = useTheme();


    const isXs = useMediaQuery({ maxWidth: 599 })
    const isSm = useMediaQuery({ maxWidth: 600 })
    const isMd = useMediaQuery({ maxWidth: 900 })
    const isLg = useMediaQuery({ maxWidth: 1200 })
    const isXl = useMediaQuery({ minWidth: 1536 })


    return (
        <Box  sx={{
            //border: '3px solid orange',
            //boxSizing: 'border-box',
            height:'100%',
            overflowX:'hidden',
            paddingLeft:!isXs?theme.components.BookDraftEditor.paddingLeft:'0em',
            paddingRight:!isXs?theme.components.BookDraftEditor.paddingRight:'0em'
          }}>

            {/* {props.leftItem} */}
            {!showBothPanes &&
                props.leftItem
            }

            {/* {showBothPanes &&
                <Box
                    style={{
                        position: 'relative',
                        height: '100%',
                    }}
                    split="vertical" minSizex={50} defaultSize={'50%'}>
                    <Box style={{
                        width: '100%',
                        height: '100%'
                    }}>
                        {props.leftItem}
                    </Box>
                    <Box style={{
                        width: '100%',
                        height: '100%'
                    }}>
                        {props.rightItem}
                    </Box>
                </Box>

            } */}

            {showBothPanes &&
                <SplitPane
                pane1Style={{
                    marginRight:'.5em'
                }}
                    style={{
                        position: 'relative',
                        height: '100%',
                    }}
                    split="vertical" minSizex={50} defaultSize={'50%'}>
                    <Pane style={{
                        width: '100%',
                        height: '100%'
                    }}>
                        {props.leftItem}
                    </Pane>
                    <Pane style={{
                        width: '100%',
                        height: '100%'
                    }}>
                        {props.rightItem}
                    </Pane>
                </SplitPane>

            }
        </Box>





    );
};


export default inject('stores')(observer(SplitScreenController));
