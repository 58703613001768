import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import FeedbackPanel from '../../panels/FeedbackPanel';
import UserCommentInput from '../UserCommentInput';
//import { useLazyListAllBookDraftUserComments, useListAllBookDraftUserComments, useLazyListBookDraftUserComments } from '../../../persistance/persistanceHooks';
import DocumentPartReaderComments from '../DocumentPartReaderComments';

const ReadDetailsFeedback = (props) => {

    const theme = useTheme();

    let { bookDraftDocumentPart, bookDraft } = props;

    let userId = props.stores?.bookStore.userId;

    //let [userCommentItems, setUserCommentItems] = useState([]);

    // const [listBookDraftUserComments, { loading: listBookDraftUserCommentsLoading, error: listBookDraftUserCommentsError, data: listBookDraftUserCommentsData, refetch: listBookDraftUserCommentsRefetch }] = useLazyListAllBookDraftUserComments();


    // const [listUserBookDraftUserComments, { loading: listUserBookDraftUserCommentsLoading, error: listUserBookDraftUserCommentsError, data: listUserBookDraftUserCommentsData, refetch: listUserBookDraftUserCommentsRefetch }] = useLazyListBookDraftUserComments();



    return (
        <Box container direction={'column'} sx={{
            justifyContent: 'center',
            //marginRight: '1em'
        }}>
            
            {/* <UserCommentInput documentPart={bookDraftDocumentPart?.documentPart} /> */}
            <DocumentPartReaderComments 
            book={bookDraft?.book} 
            documentPart={bookDraftDocumentPart?.documentPart} 
            ownerMode={false}
            />
        </Box>
    );
};


export default inject('stores')(observer(ReadDetailsFeedback));
