import Quill from 'quill';

//import Inline from './inline';
//import TextBlot from './text';
let Block = Quill.import('blots/block');
let Inline = Quill.import('blots/inline');
let TextBlot = Quill.import('blots/text');
let Parchment = Quill.import("parchment");
var $ = require("jquery");

class BookMerlinBlot extends Block {
    constructor(domNode) {
        super(domNode);
       
        // let children = $( domNode ).children();
        // console.log(children);
        // $( domNode ).children().wrap( "<div style='background-color:blue'></div>" );


        $(domNode).each(function(index) {
          //console.log($(this));
      });

      // $(domNode).contents()
      //   //.filter(function(){return this.nodeType === 3})
      //   .wrap('<b />');


        //$('.editor p').contents()
        // .filter(function(){ 
        //     return this.nodeType === 3  && this.data.trim().length > 0
        //  })
         //.wrap('<A class="link2" />');


        // $(domNode).each(function(i, v) {
        //     $(v).contents().eq(2).wrap('<span class="new"/>')
        // });​




        // domNode.childNodes.forEach((childNode)=>{
        //     childNode.setAttribute('style', 'background-color:blue');
        // })
        //domNode.setAttribute('style', 'background-color:grey');

        //$(domNode).children().css( "background-color", "red" );
      }
 }
BookMerlinBlot.blotName = 'block';
BookMerlinBlot.tagName = 'P';
BookMerlinBlot.defaultChild = 'break';
BookMerlinBlot.allowedChildren = [Inline, Parchment.Embed, TextBlot];

export default BookMerlinBlot ;
