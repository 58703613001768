import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import usePagination from '@mui/material/usePagination';
import Pagination from '@mui/material/Pagination';
import { Box, Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography } from "@mui/material";





const CardPagination = (props) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const pageSize = 3;

    return (
        <Box>
            <Pagination 
                count ={props.page}
                onChange={props.onPageChange}
            />
        </Box>
    )
}
export default inject('stores')(observer(CardPagination));