import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LaurasButton from './LaurasButton';
import TextField from '@mui/material/TextField';
import LaurasTextField from './LaurasTextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const LaurasMoreDetail = (props) => {


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));



    return (
        <Dialog
            // sx={{
            //     "& .MuiDialog-container": {
            //         "& .MuiPaper-root": {
            //             width: "100%",
            //             minWidth: "50%",  // Set your width here
            //         },
            //     },
            // }}
            open={props.open}
            onClose={props.onClose}>
            <div
                style={{ display: 'flex', justifyContent: 'right' }}>
                <IconButton
                    onClick={props.onClose}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogTitle
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1em' }}
                variant={theme.landmarkText.variant}
            >
                Book Title
            </DialogTitle>
            <DialogContent
                style={{ display: 'flex', flexDirection: 'column' }}
            >
                <Typography variant={theme.secondLevelText.variant}>Details</Typography>
                <TextField fullWidth sx={{ m: 1 }} label="Title" variant="outlined" />
                <TextField fullWidth sx={{ m: 1 }} label="Subtitle" variant="outlined" />
                <TextField fullWidth sx={{ m: 1 }} label="Author Name" variant="outlined" />
                <Divider />
                <Typography variant={theme.secondLevelText.variant}>Stores</Typography>
                <TextField fullWidth sx={{ m: 1 }} label="Kindle" variant="outlined" />
                <TextField fullWidth sx={{ m: 1 }} label="iBooks" variant="outlined" />
                <TextField fullWidth sx={{ m: 1 }} label="Google Name" variant="outlined" />
                <TextField fullWidth sx={{ m: 1 }} label="Kobo" variant="outlined" />
                <TextField fullWidth sx={{ m: 1 }} label="Nook" variant="outlined" />
                <TextField fullWidth sx={{ m: 1 }} label="Other" variant="outlined" />
            </DialogContent>
            <CardActions
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
            >
            </CardActions>
        </Dialog>
    )
}
export default inject('stores')(observer(LaurasMoreDetail));