import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Skeleton, List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ScrollingGridContainer from '../../../components/ScrollingGridContainer';
import ScrollingGridItem from '../../../components/ScrollingGridItem';
import BookDraftEditor from '../../../components/editor/BookDraftEditor';
import SplitScreenController from '../../../components/SplitScreenController';
import { useLoadBookDraftForWrite, useLoadSnapshotForWrite, useLoadBookDraftForRead } from '../../../managers/BooksManager';
import BookDraftTree3 from '../../../components/tree/BookDraftTree3';
import BookDraftTree7 from '../../../components/tree/BookDraftTree7';
import { useMediaQuery } from 'react-responsive'
import { Scrollbars } from 'react-custom-scrollbars-2';
import ReadDetails from '../../../components/editor/ReadDetails';
import ReadFooterBar from '../../../components/editor/ReadFooterBar';
import { useLoadBookReaderComments } from '../../../managers/BooksManager';
import AppWrapperDummy from '../../AppWrapperDummy';

const Read2 = (props) => {

  const theme = useTheme();
  let [splitPaneMode, setSplitPaneMode] = useState(false);
  const [selectedBddp, setSelectedBddp] = React.useState(null);
  const [showBothPanes, setShowBothPanes] = React.useState(true);

  let sharedBook = props.stores.bookStore.sharedBook;

  let {
    loading: listUserBookDraftUserCommentsLoading,
    error: listUserBookDraftUserCommentsError,
    refetch: listUserBookDraftUserCommentsRefetch,
    userComments } = useLoadBookReaderComments(props, { 
      // bookDraftId: props.stores.bookStore.sharedBookDraftId 
      bookDraftId: sharedBook?.primaryBookDraftId
    })


  //console.log(userComments);
  let {
    // writingRightBookDraftId: rightContentBookDraftId,
    // writingRightBookDraft: rightContentBookDraft,
    writingRightSelectedBookDraftDocumentPart
  } = props.stores.bookStore;


  const isXs = useMediaQuery({ maxWidth: 599 })
  const isSm = useMediaQuery({ maxWidth: 600 })
  const isMd = useMediaQuery({ maxWidth: 900 })
  const isLg = useMediaQuery({ maxWidth: 1200 })
  const isXl = useMediaQuery({ minWidth: 1536 })


  let rightContentTitle = 'Snapshot copy';
  const {
    bookDraft,
    getBookDraftLoading
  } = useLoadBookDraftForRead(props, 
    // props.stores.bookStore.sharedBookDraftId,
    sharedBook?.primaryBookDraftId
    );




  useEffect(() => {


    if (bookDraft) {

      setSelectedBddp(bookDraft.rootBookDraftDocumentPart);


    }


  }, [bookDraft]);





  let tree = <BookDraftTree7
    bookDraft={bookDraft}
    heading={'Shared Book'}
    //bookDraft2={rightContentBookDraft}
    hideScenes={true}
    //showAddBookDialog={showAddBookDialog}
    //setShowAddBookDialog={setShowAddBookDialog}
    selectedId={selectedBddp?.partId}
    // selectedIds={[
    //   setSelectedBddp?.partId
    // ]}
    onDocumentPartSelected={(item) => {

      let bddp = bookDraft.getBookDraftDocumentPartByPartId(item.id);
      props.stores.bookStore.writingSelectedBookDraftDocumentPart = bddp;
      //console.log(bddp);

      // if (bddp == null) {
      // 	bddp = props.stores.bookStore.startersBookDraft.getBookDraftDocumentPartByPartId(item.id)
      // }
      // if (bddp == null) {
      // 	bddp = props.stores.bookStore.velocityBookDraft.getBookDraftDocumentPartByPartId(item.id)
      // }
      setSelectedBddp(bddp);
      //setEreaderContent(bddp?.documentPart.title);

      // if (showInflatables || showVelocity || showStarters) {
      // 	props.stores.bookStore.addEditTemplateId({
      // 		title: bddp.documentPart.title,
      // 		templateId: item.id,
      // 		templateBddp: bddp
      // 	})
      // }

    }}
    onBookInserted={() => {
      //getBookDraftRefetch();
    }} />




  let leftEditor = <BookDraftEditor
    readOnly={true}
    //subToolbarTitle={'Title bar'}
    //userComments={userComments}
    bookDraft={bookDraft}
    selectedBddp={selectedBddp}
    onDocumentPartCreated={(bddp) => {

      //props.stores.bookStore.writingSelectedBookDraftDocumentPart = bddp;

    }} />





  // if (getBookDraftLoading) {


  //   return <Fade in={true}><Box sx={{
  //     width: '100%',
  //     height: '100%'
  //   }}>
  //     <EmptyContent tagline={'Loading...'} />

  //   </Box>
  //   </Fade>
  // }
  if (isSm) {

    return (
      <Box sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}>



        <ScrollingGridContainer container columns={100} direction={'row'} >


          <ScrollingGridItem xs={100}>
            <Box sx={{
              backgroundColor: theme.palette.background.paper,
              height: '100%'
            }}>

              {/* <div>title:{bookDraft?.book.title}</div> */}
              <SplitScreenController
                leftItem={leftEditor}
              //rightItem={rightEditor}

              />
            </Box>

          </ScrollingGridItem>



        </ScrollingGridContainer>
        <ReadFooterBar tree={tree} setSelectedBddp={setSelectedBddp} bookDraft={bookDraft} bookDraftDocumentPart={selectedBddp} />
      </Box>
    )

  } else {

    return (

      // <AppWrapperDummy >
        <Box sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          marginLeft: '1em',
          marginRight: '1em'
          //border:'1px solid red',
        }}>

          <Box

            //xs={15} 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: theme.components.standardTree.nodes.width,
              height: '100%',
              //border: '1px solid white'
            }}
          >
            <Box

              //xs={15} 
              sx={{
                flex: 1,
                width: theme.components.standardTree.nodes.width,
                height: '100%',
                //border: '1px solid white'
              }}
            >

              <Scrollbars
                autoHide
                renderThumbHorizontal={() => {
                  return <span />
                }}
                style={{
                  width: '100%', height: '100%', overFlow: 'hidden',
                  overflowX: 'hidden'
                }}>


                {tree}





              </Scrollbars>
            </Box>

            {/* <ToolsNavigator /> */}


          </Box>

          <Box sx={{
            flex: 2,
            backgroundColor: theme.palette.write?.background?.color,
            backgroundColorx: theme.palette.background.paper,
            height: '100%',
            width: '100%',
            //paddingLeft: '1em',
            //paddingRight: '1em',
            //padding: '1em'
            //border: '2px solid blue'
          }}>

            <SplitScreenController
              showBothPanes={props.stores.bookStore.writeSplitScreen}
              leftItem={leftEditor}
            //rightItem={rightEditor}

            />

          </Box>

          <Box sx={{
            width: theme.components.standardTree.nodes.width
          }}>

            {bookDraft &&
              <ReadDetails 
                bookDraft={bookDraft} 
                sharedBook={sharedBook} 
                bookDraftDocumentPart={selectedBddp} 
                />
            }
          </Box>

        </Box>
      // </AppWrapperDummy>
    )

  }


}

export default inject('stores')(observer(Read2));