import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import config from '../../../aws-exports';
import { useGetBookContributedToBoxsets, useOpenBookInFormat, useOpenBookInRead, useGetSnapshots, useOpenBookInWrite, useOpenSnapshotInWrite } from '../../../managers/BooksManager';
import { useOpenBoxsetInBoxsetDetails } from '../../../managers/BoxsetsManager';
import Avatar from '@mui/material/Avatar';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import EmptyContent from "../../EmptyContent";


let missingBookImage = require('../../../assets/images/image-not-found.png')



const OwnedBookBoxsetsCard = (props) => {

    let { book, showReadIcon = true } = props;
    const theme = useTheme();

    const { openBookInWrite } = useOpenBookInWrite(props);
    const { openBookInFormat } = useOpenBookInFormat(props);
    const { openBookInRead } = useOpenBookInRead(props);
    const { openBoxsetInBoxsetDetails } = useOpenBoxsetInBoxsetDetails(props);
    let { boxsets, loading: listBookContributedToBoxSetsLoading, error: listBookContributedToBoxSetsError } = useGetBookContributedToBoxsets(props, book)

    useEffect(() => {

        //console.log(boxsets);
    }, [boxsets]);

    const generateSecondaryActions = (boxset) => {

        const secondaryActions = [];

        if (showReadIcon) {
            secondaryActions.push(<IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    openBookInWrite({
                        bookDraftId: boxset.book.primaryBookDraftId
                    });
                }}>
                <ManageSearchIcon />
            </IconButton>)
        }
        return secondaryActions;

    }

    return (
        <List>
            {boxsets && boxsets.length > 0 ?
                boxsets.map((boxset) => {
                    return <ListItem
                        sx={{ padding: ' 0.25em' }}
                        secondaryAction={
                            generateSecondaryActions(boxset)
                        }
                    >
                        <ListItemAvatar>
                            <Avatar variant="rounded"
                                s3Src={boxset.book?.coverUrl}
                                missingContent={<img src={missingBookImage} />}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={boxset.book.title}
                            secondary={boxset.book.owner?.name}
                        />
                    </ListItem>
                })
                :
                <EmptyContent tagline={'Your book is not part of a boxet yet.'} />
            }
        </List>
    )
}

export default inject('stores')(observer(OwnedBookBoxsetsCard));