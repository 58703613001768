import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import StandardTabs from "../../StandardTabs";
import { useGetUserConnections, useGetUserGroups } from '../../../managers/ConnectionsManager';
import AvatarTableCell from "../../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton, { StandardListButton } from "../../StandardButton";
import BookSharedWithList from "../../lists/BookSharedWithList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../../managers/BooksManager';
import UserGroupsList from "../../lists/UserGroupsList";
import { useManagerCreateEditRequest } from '../../../managers/EditRequestsManager';
import StandardOkCancelDialog from "../StandardOkCancelDialog";
import ShareBookPanel from "../share/ShareBookPanel";



const SharedWithPanel = (props) => {

  let user = props.stores.userStore.user;
  let { book } = props;

  let [showShareBookDialog, setShowShareBookDialog] = useState(false);

  const { createEditRequest, loading: createEditRequestLoading, error: createEditRequestError } = useManagerCreateEditRequest(props);

  let [selectedTab, setSelectedTab] = useState(0);


  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Grid container direction={'column'}>
      <Grid item>
        <StandardListButton
          variant="outlined"
          onClick={() => {
            setShowShareBookDialog(true)
          }}>Share Book</StandardListButton>

      </Grid>
      <Grid item style={{
        display:'grid',
        justifyContent:'flex-start'
      }}>
        <BookSharedWithList
          book={book}
          showSelectIcon
          onSelected={(userConnection) => {

            if (userConnection) {
              createEditRequest({
                book,
                userId: user.id,
                editorUserId: userConnection.connectedUser.id
              })
            }

          }}
        />

        <StandardOkCancelDialog
          open={showShareBookDialog}
          onClose={() => {
            setShowShareBookDialog(false)
          }}
          onOk={() => {
            setShowShareBookDialog(false)

          }}
          title={'Request Edit'}
          content={
            <ShareBookPanel book={book} />
          }
        />
      </Grid>
    </Grid>
  )
}

export default inject('stores')(observer(SharedWithPanel));