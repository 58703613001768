import Quill from 'quill';
//import Popper from 'popper.js';
let Inline = Quill.import('blots/inline');
var $ = require("jquery");

class Link2 extends Inline {
  static create(args) {
    ////console.log(args);
    let node = super.create(args);
    //value = this.sanitize(value);
    //node.setAttribute('id', args.href);
    //console.log('Link2...');
    node.setAttribute('href', args.href);
    node.dataset.href = args.href;

    node.setAttribute('linkType', args.linkType);
    node.setAttribute('linkData', args.linkData);
    node.dataset.linkType = args.linkType;
    node.dataset.linkData = args.linkData;
    //let propsDiv = $("#popupProps");
    let propsDiv = $("#link2Popper");

    let linkType = args.linkType;
    let thisPopper = null;

    



    $(node).click(function () {
      //console.log('Link2...');

      $('#linkPopperTriggerDiv').data('blotNode', node);
      $('#linkPopperTriggerDiv').click();
      //console.log(this);
      return node;
      
      // let editors = $('ql-editor');
      // let max = 0;
      // editors.each(function () {
      //   var z = parseInt($(this).css("z-index"), 10);
      //   max = Math.max(max, z);
      // })

      // $('#link2Popper').css("z-index", max + 1);
      // $('.popperProps').hide();
      // $('#link2Popper').show();

      


      // thisPopper = new Popper(node, propsDiv, {
      //   placement: 'bottom'
      // });

      // $('#link2Popper').data('linkNode', node);

      // let linkType = node.getAttribute('linkType')
      // ////console.log(node);
      // $('#link2PopperHrefField').val(node.getAttribute('href'));

      // $('#link2PopperLinkTypeStoreField').prop("checked", false);
      // $('#link2PopperLinkTypeWebField').prop('checked', false);
      // if (linkType && linkType == 'store') {
      //   $('#link2PopperLinkTypeStoreField').prop('checked', true);
      // } else {
      //   $('#link2PopperLinkTypeWebField').prop('checked', true);
      // }


      // let storeTypeRadio = $('#link2PopperLinkTypeStoreField');





      // //$('#link2Popper').css("z-index", max + 1 );
      // //$('.popperProps').hide();
      // //$('#link2Popper').show();


    });








    // $('#link2Popper').mouseleave(function () {
    //   $('#link2Popper').hide();
    //   if (thisPopper) {
    //     thisPopper.destroy();
    //   }

    // });




    //node.setAttribute('target', '_blank');
    return node;
  }

  setHref(href) {
    this.domNode.dataset.href = href;

  }

  setLinkType(linkType) {
    this.domNode.dataset.linkType = linkType;

  }




  static formats(domNode) {
    //return domNode.dataset.src
    //return domNode.getAttribute('href');

    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
      href: domNode.dataset.href,
      linkType: domNode.dataset.linkType,
      linkData: domNode.dataset.linkData

      
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  static value(domNode) {
    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
      href: domNode.dataset.href,
      linkType: domNode.dataset.linkType,
      linkData: domNode.dataset.linkData
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  /*static sanitize(url) {  
    return sanitize(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
  }*/

  format(name, args) {
    if (name !== this.statics.blotName || !args) return super.format(name, args);
    //value = this.constructor.sanitize(value);
    this.domNode.setAttribute('href', args.href);
    this.domNode.setAttribute('linkType', args.linkType);
    this.domNode.setAttribute('linkData', args.linkData);

    
  }
}
Link2.blotName = 'link2';
Link2.tagName = 'A';
Link2.SANITIZED_URL = 'about:blank';
Link2.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];


function sanitize(url, protocols) {
  let anchor = document.createElement('a');
  anchor.href = url;
  let protocol = anchor.href.slice(0, anchor.href.indexOf(':'));
  return protocols.indexOf(protocol) > -1;
}


export { Link2 as default, sanitize };
