import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { v4 as uuid } from 'uuid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';


const TableListPopOver = (props) => {

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton aria-label="delete">
                <MoreVertIcon sx={{ color: theme.palette.background.main }}
                    // onClick={(e) => {
                    //     e.preventDefault();
                    onClick={handleClick}
                // console.log(book);
                // setSelectedBook(book);
                // setShowDeleteBookDialog(true);

                />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {props.menuItems}
            </Menu>
        </div>
    );
};


export default inject('stores')(observer(TableListPopOver));
