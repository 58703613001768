import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Paper, Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from 'react-responsive';





function FooterTitleIcon(props) {


  const theme = useTheme();

  const ref = React.useRef(null);


  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Box sx={{
        color: props.color
      }}>


        {props.children}
      </Box>

      <Typography variant={'caption'} style={{ color: props.color }} color={props.color} sx={{
        color: props.color
      }}>{props.title}</Typography>
    </Box>
  );
}
export default inject('stores')(observer(FooterTitleIcon));