import Quill from 'quill';
//import Parchment from 'parchment';
import DeltaToDom from './DeltaToDom';
//import Popper from 'popper.js';
import config from '../../../aws-exports';

const Parchment = Quill.import('parchment');
var $ = require("jquery");

let BlockEmbed = Quill.import('blots/block/embed');
let Base = Quill.import('blots/embed');
let Inline = Quill.import('blots/inline');

let generateClassNames = function ({ accepted, rejected, preview, editorPinned, requestorPinned, editorMode = false }) {

  editorPinned = (editorPinned && editorPinned == 'true') ? true : false;
  if (!editorMode) {
    editorPinned = (requestorPinned && requestorPinned == 'true') ? true : false;
  }
  let acceptedClass = '';
  if (accepted && accepted == 'true') {
    if (preview && !editorPinned) {
      acceptedClass = 'editingInsertNewLineBlot2FinalViewAccepted';
    } else {
      acceptedClass = 'editingInsertNewLineBlot2Accepted editingBlotHidden';
    }
  }

  else if (rejected && rejected == 'true') {


    if (preview && !editorPinned) {
      acceptedClass = 'editingInsertNewLineBlot2FinalViewRejected';
    } else {
      acceptedClass = 'editingInsertNewLineBlot2Rejected';
    }


  }
  else {



    if (preview && !editorPinned) {
      //act as if it was accepted since we have nothing else from the author.
      acceptedClass = 'editingInsertNewLineBlot2FinalViewAccepted';
    } else {
      acceptedClass = 'editingInsertNewLineBlot2Pending';
    }



  }



  return 'editingBlot editingInsertBlot editingInsertNewLineBlot2 ' + acceptedClass;


}

function createImageContent(node, args, blot, blotImageDivId) {

  //console.log('createImageContent()...');
  let converter = new DeltaToDom();
  /*let html = converter.convertBlurb(args);
    node.innerHTML = html;*/

  let domNode = converter.convertInsertNewLineContent(args, node, blot, blotImageDivId);
  //node.appendChild(domNode);
}

export default class EditingInsertNewLineBlot2 extends Base {
  static create(args) {
    //console.log(args);
    let node = super.create();
    let blotImageDivId = 'blotImageDiv' + Date.now();
    node.setAttribute('id', blotImageDivId);
    //node.setAttribute('style', 'border:2px solid red');
    node.dataset.uuid = args.uuid;
    node.dataset.type = 'editingInsertNewLineBlot2';
    node.dataset.accepted = args.accepted;
    node.dataset.rejected = args.rejected;
    node.dataset.editorPinned = args.editorPinned;
    node.dataset.requestorPinned = args.requestorPinned;
    node.dataset.editorComment = args.editorComment;

    // Allow twitter library to modify our contents
    //twttr.widgets.createTweet(id, node);
    node.setAttribute('type', 'editingInsertNewLineBlot2');
    createImageContent(node, args, this, blotImageDivId);
    //node.setAttribute('style', 'margin-left:1.25em');
    //node.setAttribute('contenteditable', 'false');

    $(node).addClass('editingBlot');
    $(node).addClass('editingInsertBlot');

    return node;
  }

  static formats(domNode) {
    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
      uuid: domNode.dataset.uuid,
      type: 'editingInsertNewLineBlot2',
      accepted: domNode.dataset.accepted,
      rejected: domNode.dataset.rejected,
      editorPinned: domNode.dataset.editorPinned,
      requestorPinned: domNode.dataset.requestorPinned,
      editorComment: domNode.dataset.editorComment
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  static value(domNode) {
    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
      uuid: domNode.dataset.uuid,
      type: 'editingInsertNewLineBlot2',
      accepted: domNode.dataset.accepted,
      rejected: domNode.dataset.rejected,
      editorPinned: domNode.dataset.editorPinned,
      requestorPinned: domNode.dataset.requestorPinned,
      editorComment: domNode.dataset.editorComment
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  format(name, args) {
    if (name !== this.statics.blotName || !args) return super.format(name, args);
    //value = this.constructor.sanitize(value);
    //this.domNode.setAttribute('href', args.href);
    this.domNode.setAttribute('uuid', args.uuid);
    this.domNode.setAttribute('type', 'editingInsertNewLineBlot2');
    this.domNode.setAttribute('accepted', args.accepted);
    this.domNode.setAttribute('rejected', args.rejected);
    this.domNode.setAttribute('editorPinned', args.editorPinned);
    this.domNode.setAttribute('requestorPinned', args.requestorPinned);
    this.domNode.setAttribute('editorComment', args.editorComment);
  }

  accept(preview) {
    this.domNode.dataset.accepted = 'true';
    this.domNode.dataset.rejected = 'false';

    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();
  }

  reject(preview) {
    this.domNode.dataset.accepted = 'false';
    this.domNode.dataset.rejected = 'true';

    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();
  }

  toggleAccept(preview) {
    if (!this.domNode.dataset.accepted || this.domNode.dataset.accepted == 'false' || this.domNode.dataset.accepted == 'undefined') {
      this.domNode.dataset.accepted = 'true';
      this.domNode.dataset.rejected = 'false';
      //this.domNode.setAttribute('style', 'display:none');

    } else {
      this.domNode.dataset.accepted = 'false';
      //this.domNode.setAttribute('style', 'color:red;border:1px solid red;text-decoration:line-through;background-colorx:pink');
      //let classNames = this.generateClassNames();
      //this.domNode.setAttribute('class', classNames);
    }
    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();
  }

  toggleReject(preview) {
    if (!this.domNode.dataset.rejected || this.domNode.dataset.rejected == 'false' || this.domNode.dataset.rejected == 'undefined') {
      this.domNode.dataset.rejected = 'true';
      this.domNode.dataset.accepted = 'false';
      //this.domNode.setAttribute('style', 'display:none');

    } else {
      this.domNode.dataset.rejected = 'false';
      //this.domNode.setAttribute('style', 'color:red;border:1px solid red;text-decoration:line-through;background-colorx:pink');
      //let classNames = this.generateClassNames();
      //this.domNode.setAttribute('class', classNames);
    }
    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();
  }

  clearChoices(preview) {
    this.domNode.dataset.rejected = 'false';
    this.domNode.dataset.accepted = 'false';

    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();
  }

  previewOn() {
    $(this.domNode).removeClass('editingBlotHidden');
    $(this.domNode).removeClass('editingInsertNewLineBlot2Preview');
    $(this.domNode).addClass('editingInsertNewLineBlot2Preview');
  }

  previewOff() {
    $(this.domNode).removeClass();
    $(this.domNode).addClass(generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: false,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    }));
    /*$(this.domNode).removeClass('editingBlot');
    $(this.domNode).removeClass('editingInsertNewLineBlot2');
    $(this.domNode).removeClass('editingInsertNewLineBlot2Preview');*/
    //$(this.domNode).removeClass('editingInsertNewLineBlot2Preview');

  }
  finalView(preview, editorMode) {

    $(this.domNode).removeClass();
    $(this.domNode).addClass(generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
      editorMode: editorMode

    }));

    let rejected = this.domNode.dataset.rejected;
    let accepted = this.domNode.dataset.accepted;
    $(this.domNode).show();

    if (preview) {
      if (rejected && rejected == 'true') {

        $(this.domNode).hide();

      }
      if (accepted && accepted == 'true') {

        $(this.domNode).show();

      }
    }



  }


}
EditingInsertNewLineBlot2.blotName = 'editingInsertNewLineBlot2';
EditingInsertNewLineBlot2.tagName = 'span';
EditingInsertNewLineBlot2.className = 'editingInsertNewLineBlot2';