import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import { toJS } from 'mobx';
//import persistanceFragments from './persistanceFragments';
import { FRAGMENT_BOOK_DETAILS, FRAGMENT_BOOKDRAFT_DOCUMENTPART_DETAILS } from './persistanceFragments';
import fragments from './persistanceFragments';
import queries from './persistanceQueries';
import apiErrorCodes from '../api-error-codes';
import config from '../config';

function preProcessGraphqlErrors(e, props) {

	let errorMessages = e.graphQLErrors.map((gqlError) => {
		return gqlError.message;
	})

	let nonSystemErrors = errorMessages.filter(f => f != apiErrorCodes.ERROR_SYSTEM_ERROR);
	let systemErrors = errorMessages.filter(f => f == apiErrorCodes.ERROR_SYSTEM_ERROR);

	if (systemErrors.length > 0) {
		props.stores.bookStore.showSnackMessage('An error', 'success');
	}

	return nonSystemErrors;


}

export function useRemoveBoxSetContributedBook(props) {




	const mutation = gql`
	mutation removeBoxSetContributedBook($input: RemoveBoxSetContributedBookInput!) {
		removeBoxSetContributedBook(input: $input) {
			id
			boxSetId
			bookId
		}
	}`;

	const [
		removeBoxSetContributedBook,
		{ data, loading, error }] = useMutation(mutation, {


		});


	const wrappedMutation = ({
		boxSetId,
		bookId },
		callback,
		errorCallback) => {



		const variables = {
			input: {
				bookId,
				boxSetId
			}

		}

		try {

			removeBoxSetContributedBook({
				variables,
				fetchPolicy: 'no-cache'
				//refetchQueries: ['listRecipes']
				// optimisticResponse: {
				// 	createDocumentPartBatch: newBddp
				// }
			})
			.then(({ data }) => {
				if (callback) {
					callback();
				}
			})
			.catch(e => {
				////console.log(e);
				// let errorMessages = e.graphQLErrors.map((gqlError) => {
				// 	return gqlError.message;
				// })
				if (errorCallback) {

					let errorMessages = preProcessGraphqlErrors(e, props)

					errorCallback(errorMessages);

				}
			});


		} catch (err) {
			//console.log(err);
		}

	}
	return [wrappedMutation, { data, loading, error }]

}


export function useRemoveBoxSetContributor() {




	const mutation = gql`
	mutation removeBoxSetContributor($input: RemoveBoxSetContributorInput!) {
		removeBoxSetContributor(input: $input) {
			id
			boxSetId
			contributorId
		}
	}`;

	const [
		removeBoxSetContributor,
		{ data, loading, error }] = useMutation(mutation, {


		});
		

	const wrappedMutation = ({
		boxSetId,
		contributorId }, callback, errorCallback) => {



		const variables = {
			input: {
				boxSetId,
				contributorId
			}

		}

		try {

			removeBoxSetContributor({
				variables,
				fetchPolicy: 'no-cache'
				//refetchQueries: ['listRecipes']
				// optimisticResponse: {
				// 	createDocumentPartBatch: newBddp
				// }
			}).then(({ data }) => {
				if (callback) {
					callback();
				}
			})
				.catch(e => {
					//console.log(e);
					let errorMessages = e.graphQLErrors.map((gqlError) => {
						return gqlError.message;
					})
					if (errorCallback) {
						errorCallback(errorMessages);
					}
				});


		} catch (err) {
			//console.log(err);
		}

	}
	return [wrappedMutation, { data, loading, error }]

}




export function useInitializeTransferEditBookDraftToOriginalBatch() {




	const mutation = gql`
	mutation initializeTransferEditBookDraftToOriginalBatch($input: TransferEditBookDraftToOriginalBatch!) {
		transferEditBookDraftToOriginalBatch(input: $input) 
	}`;

	const [
		initializeTransferEditBookDraftToOriginalBatch,
		{ data, loading, error }] = useMutation(mutation, {


		});

	//let actionType = 'TRANSFER_EDIT_BOOKDRAFT_INITIALIZE';

	const wrappedMutation = ({
		editRequestId,
		bookDraftExpectedVersion,
		actionType,
		newSnapshotName }, callback, errorCallback) => {



		const variables = {
			input: {
				editRequestId,
				bookDraftExpectedVersion,
				actionType: 'TRANSFER_EDIT_BOOKDRAFT_INITIALIZE',
				newSnapshotName
			}

		}

		try {

			initializeTransferEditBookDraftToOriginalBatch({
				variables,
				fetchPolicy: 'no-cache'
				//refetchQueries: ['listRecipes']
				// optimisticResponse: {
				// 	createDocumentPartBatch: newBddp
				// }
			}).then(({ data }) => {
				if (callback) {
					callback();
				}
			})
				.catch(e => {
					//console.log(e);
					let errorMessages = e.graphQLErrors.map((gqlError) => {
						return gqlError.message;
					})
					if (errorCallback) {
						errorCallback(errorMessages);
					}
				});


		} catch (err) {
			//console.log(err);
		}

	}
	return [wrappedMutation, { data, loading, error }]

}

export function useTransferEditBookDraftToOriginalBatch() {




	const mutation = gql`
	mutation transferEditBookDraftToOriginalBatch($input: TransferEditBookDraftToOriginalBatch!) {
		transferEditBookDraftToOriginalBatch(input: $input) 
	}`;

	const [
		transferEditBookDraftToOriginalBatch,
		{ data, loading, error }] = useMutation(mutation, {


		});

	//let actionType = 'TRANSFER_EDIT_BOOKDRAFT_COMPLETE';
	const wrappedMutation = ({
		editRequestId,
		bookDraftExpectedVersion,
		actionType,
		newSnapshotName }, callback, errorCallback) => {



		const variables = {
			input: {
				editRequestId,
				bookDraftExpectedVersion,
				actionType: 'TRANSFER_EDIT_BOOKDRAFT_COMPLETE',
				newSnapshotName
			}

		}

		try {

			transferEditBookDraftToOriginalBatch({
				variables,
				fetchPolicy: 'no-cache'
				//refetchQueries: ['listRecipes']
				// optimisticResponse: {
				// 	createDocumentPartBatch: newBddp
				// }
			}).then(({ data }) => {
				if (callback) {
					callback();
				}
			})
				.catch(e => {
					//console.log(e);
					let errorMessages = e.graphQLErrors.map((gqlError) => {
						return gqlError.message;
					})
					if (errorCallback) {
						errorCallback(errorMessages);
					}
				});


		} catch (err) {
			//console.log(err);
		}

	}
	return [wrappedMutation, { data, loading, error }]

}

export function useTransferEditsToOriginalBatch() {




	const mutation = gql`
	mutation transferEditsToOriginalBatch($input: TransferEditsToOriginalBatchInput!) {
		transferEditsToOriginalBatch(input: $input) {
			id
			version
			deltaOps
		}
	}`;

	const [
		transferEditsToOriginalBatch,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		editRequestId,
		editPartId,
		editPartExpectedVersion,
		actionType }, callback, errorCallback) => {



		const variables = {
			input: {
				editRequestId,
				editPartId,
				editPartExpectedVersion,
				actionType
			}

		}

		try {

			transferEditsToOriginalBatch({
				variables,
				//refetchQueries: ['listRecipes']
				// optimisticResponse: {
				// 	createDocumentPartBatch: newBddp
				// }
			}).then(({ data }) => {
				//console.log(data);
				if (callback) {
					callback(data.transferEditsToOriginalBatch);
				}
			})
				.catch(e => {
					////console.log(e);
					let errorMessages = e.graphQLErrors.map((gqlError) => {
						return gqlError.message;
					})
					if (errorCallback) {
						errorCallback(errorMessages);
					}
				});


		} catch (err) {
			////console.log(err);
		}

	}
	return [wrappedMutation, { data, loading, error }]

}


export function useDeleteBookUserGroupShare() {

	const mutation = gql`
	mutation deleteBookUserGroupShare($input: DeleteBookUserGroupShareInput!) {
		deleteBookUserGroupShare(input: $input) {
		bookId
		userGroupId
		}
	}`;

	const [
		deleteBookUserShare,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		bookId,
		userGroupId

	}, callback,errorCallback) => {

		////console.log(id);



		const variables = {
			input: {
				bookId,
				userGroupId
			}
		}

		deleteBookUserShare({
			variables,
			refetchQueries: ['listUserGroupBookShares', {
				variables: { bookId },
				query: gql`query listBookUserGroupShares($bookId: ID!) {
				listBookUserGroupShares(bookId: $bookId) {
					items {
						bookId
						userGroupId
						createdAt
						userGroup {
							id
							name
							profilePicUrl
							groupOwner {
								id
								name
								displayName
								profilePicUrl
							}
						}
						sharedBook {
							id
							title
							coverUrl
							coverUrlType
							coverThumbnailUrl
							coverThumbnailUrlType
							commonAllTitlepagesHeaderUrl
				commonAllHalftitlesHeaderUrl
				commonAllPartsHeaderUrl
				commonVolumeHeaderUrl
				commonPartHeaderUrl
				commonChapterHeaderUrl
				publisherLogoImageSrc
				publisherLogoImageType
				publisherLogoUrl
							owner {
									id
									cognitoId
									name
									profilePicUrl
								}
						}
					}
				}
			}` }],
			//refetchQueries: ['listUserGroupUsers']
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}









export function useDeleteBookUserShare() {

	const mutation = gql`
	mutation deleteBookUserShare($input: DeleteBookUserShareInput!) {
		deleteBookUserShare(input: $input) {
		bookId
		sharedWithUserId
		}
	}`;

	const [
		deleteBookUserShare,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		bookId,
		sharedWithUserId

	}, callback,errorCallback) => {

		////console.log(id);



		const variables = {
			input: {
				bookId,
				sharedWithUserId
			}
		}

		deleteBookUserShare({
			variables,
			refetchQueries: ['listBookUserShares']
			//refetchQueries: ['listUserGroupUsers']
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}


export function useDeleteRecipe() {

	const mutation = gql`
	mutation deleteRecipe($input: DeleteRecipeInput!) {
		deleteRecipe(input: $input) {
		id
		}
	}
		`;

	const [
		deleteRecipe,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		id,
		expectedVersion

	}, callback,errorCallback) => {

		////console.log(id);



		const variables = {
			input: {
				id,
				expectedVersion
			}
		}

		deleteRecipe({
			variables,
			refetchQueries: ['listRecipes']
			//refetchQueries: ['listUserGroupUsers']
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}


export function useDeleteBookMerlinRecipe() {

	const mutation = gql`
	mutation deleteBookMerlinRecipe($input: DeleteRecipeInput!) {
		deleteBookMerlinRecipe(input: $input) {
		id
		}
	}
		`;

	const [
		deleteBookMerlinRecipe,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		id,
		expectedVersion

	}, callback,errorCallback) => {

		////console.log(id);



		const variables = {
			input: {
				id,
				expectedVersion
			}
		}

		deleteBookMerlinRecipe({
			variables,
			refetchQueries: ['listBookMerlinRecipes']
			//refetchQueries: ['listUserGroupUsers']
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}


















export function useCreateSharedBookUserComment() {

	// const mutation = gql`
	// mutation acceptEditRequestBatch($input: AcceptEditRequestBatchInput!) {
	//   acceptEditRequestBatch(input: $input) {



	const mutation = gql`
	mutation createSharedBookUserComment($input: SharedBookUserCommentInput!) {
		createSharedBookUserComment(input: $input) {
		  __typename
		  comment
		  id
		  partId
		partIndex
		partLength
		partSelectedText
		}
	  }
`;

	const [
		createSharedBookUserComment,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		user,
		commentId,
		documentPartId,
		sharedBookId,
		sharedBookDraftId,
		commentorUserId,
		comment,
		partId,
		partIndex,
		partLength,
		partSelectedText,
		itemOwnerId




	}, callback,errorCallback) => {

		//console.log('useCreateSharedBookUserComment...')

		let id = uuid();

		const variables = {
			input: {
				id: id,
				documentPartId,
				sharedBookId,
				sharedBookDraftId,
				commentorUserId,
				comment,
				partId,
				partIndex,
				partLength,
				partSelectedText,
				itemOwnerId
			}

		}

		createSharedBookUserComment({
			variables,
			refetchQueries: ['listBookDraftUserComments', 'listAllBookDraftUserComments', 'listAllBookDraftNotes'],
			onCompleted: () => {
				if (callback) {
					variables.input.createdAt = Date.now();
					variables.input.commentorUser = user;
					callback(variables.input);
				}
			},
			fetchPolicy: 'network-only',
			update(cache, { data: { createSharedBookUserComment } }) {

				//console.log(createDocumentPartBatch);
				//https://github.com/apollographql/apollo-client/issues/5903

				//console.log(createSharedBookUserComment);

				cache.modify({
					fields: {
						listBookDraftUserComments(existingUserComments = []) {
							let cacheKey = `SharedBookUserComment:${createSharedBookUserComment.id}`;
							const newCommentRef = cache.writeFragment({
								id: cacheKey,

								data: {
									...createSharedBookUserComment
								},
								fragment: gql`
										fragment NewSharedBookUserComment on SharedBookUserComment{
										__typename
										comment
										id
										documentPartId
										createdAt
										sharedBookId
										sharedBookDraftId
										deletedAt
										readAt
										isFavorite
										isPinned
										partIndex
										partLength
										
										}
									`
							});

							//check if this item is already in the list
							if (existingUserComments && existingUserComments.items.filter(f => f.__ref == cacheKey).length > 0) {
								return {
									items: [...existingUserComments.items]
								}
							} else {
								return {
									items: [...existingUserComments.items, {
										__ref: `SharedBookUserComment:${createSharedBookUserComment.id}`
									}]
								}
							}

						}
					}
				});







			}
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		})
		.then(({ data }) => {
			if (callback) {
				callback();
			}
		})
		.catch(e => {

			if (errorCallback) {
				//let errorMessages = preProcessGraphqlErrors(e, props)
				//errorCallback(errorMessages);
				errorCallback()
			}

		});;

	}
	return [wrappedMutation, { data, loading, error }]

}





export function useUpdateEditRequestStatus() {

	const mutation = gql`
	mutation updateEditRequestStatus($input: UpdateEditRequestStatusBatchInput!) {
		updateEditRequestStatus(input: $input) {
			__typename
			acceptedAt
			completedAt
			closedAt
			bookId
			book {
				owner {
					id
					cognitoId
					name
					profilePicUrl
				}
				coverUrl
				coverUrlType
				coverThumbnailUrl
				coverThumbnailUrlType
		commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl



				createdAt
				id
				primaryBookDraftId
				ownerId
				title
				subTitle
				version
			}
			createdAt
			deletedAt
			editBookDraftId
			editorCanceledAt
			editorComments
			editorDeletedAt
			editorUserId
			editorUser {
			  id
			  cognitoId
			  name
			  profilePicUrl
			}
			id
			isFavorite
			locked
			originalBookDraftId
			ownerId
			rejectedAt
			requestedAt
			requestorCanceledAt
			requestorComments
			requestorDeletedAt
			requestorUserId
			requestorUser {
			  id
			  cognitoId
			  name
			  profilePicUrl
			}
			updatedAt
			version
		
	  }
	}
	`;



	const [
		updateEditRequestStatus,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({

		editRequestId,
		statusField,
		actionType,
		onCompleted






	}, callback,errorCallback) => {

		////console.log(editRequestId);

		let id = uuid();

		const variables = {
			input: {
				editRequestId,
				statusField,
				actionType
			}

		}

		updateEditRequestStatus({
			variables,
			refetchQueries: ['getEditRequest'],
			onCompleted: onCompleted
			//fetchPolicy: 'network-only'
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}


export function useAcceptEditRequest() {

	const mutation = gql`
	mutation acceptEditRequestBatch($input: AcceptEditRequestBatchInput!) {
	  acceptEditRequestBatch(input: $input) {
			__typename
			acceptedAt
			completedAt
			closedAt
			bookId
			book {
				owner {
					id
					cognitoId
					name
					profilePicUrl
				}
				coverUrl
				coverUrlType
				coverThumbnailUrl
				coverThumbnailUrlType
		commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl



				createdAt
				id
				primaryBookDraftId
				ownerId
				title
				subTitle
				version
			}
			createdAt
			deletedAt
			editBookDraftId
			editorCanceledAt
			editorComments
			editorDeletedAt
			editorUserId
			editorUser {
			  id
			  cognitoId
			  name
			  profilePicUrl
			}
			id
			isFavorite
			locked
			originalBookDraftId
			ownerId
			rejectedAt
			requestedAt
			requestorCanceledAt
			requestorComments
			requestorDeletedAt
			requestorUserId
			requestorUser {
			  id
			  cognitoId
			  name
			  profilePicUrl
			}
			updatedAt
			version
		
	  }
	}
	`;



	const [
		acceptEditRequest,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({

		editRequestId,
		onCompleted




	}, callback,errorCallback) => {

		////console.log(editRequestId);

		let id = uuid();

		const variables = {
			input: {
				editRequestId
			}

		}

		acceptEditRequest({
			variables,
			refetchQueries: ['getEditRequest'],
			onCompleted: onCompleted
			//fetchPolicy: 'network-only'
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}

export function useRollbackDocumentPartBatch() {


	const mutation = gql`
	mutation RollbackDocumentPartBatch($input: RollbackDocumentPartBatchInput!) {
		rollbackDocumentPartBatch(input: $input) {
		id
		bookDraftId
		ownerId
		deltaOps
		version
		mostRecentDelta
		realTimeAccess
	  }
	}
	`;



	const [
		theMutation,
		{ data, loading, error }] = useMutation(mutation, {

			onQueryUpdated: (args) => {
				//////console.log(args);
				if (args && args.queryName == 'getBookDraft') {

					//return false here to prevent refetching while typing which causes the cursor to keep moving to index 0
					return false;
				}
			}

		});

	const wrappedMutation = ({

		expectedVersion,
		documentPartId,
		rollbackVersion
		//itemType: "DocumentPart",
		//parentExpectedVersion: 10,
		//parentId: "1111",
		//fieldName: "title",
		//deltaOps




	}, callback,errorCallback) => {




		const variables = {
			input: {
				itemExpectedVersion: expectedVersion,
				itemId: documentPartId,
				rollbackVersion: rollbackVersion
				//itemType: "DocumentPart",
				//parentExpectedVersion: 10,
				//parentId: "1111",
				//fieldName: "title",
				//fieldValue: JSON.stringify(deltaOps)
			}

		}

		try {
			theMutation({
				variables,
				////fetchPolicy: 'network-only',
				context: {
					//https://codeburst.io/highly-functional-offline-applications-using-apollo-client-12885bd5f335
					serializationKey: 'MUTATION'

				}
				//refetchQueries: ['getBookEditRequests', 'getUserSentEditRequests']
				// optimisticResponse: {
				// 	createDocumentPartBatch: newBddp
				// }
			}).then(({ data }) => {
				// you can do something with the response here
				////console.log(data);
				if (callback) {
					//callback(data.updateDocumentPartBatch);
				}
			})
				.catch(e => {
					// you can do something with the error here
					////console.log(e);
				});

			if (callback) {
				callback();
			}

		} catch (err) {
			////console.log(err);
		}


	}
	return [wrappedMutation, { data, loading, error }]

}

export function useUpdateDocumentPartBatch() {


	// const mutation = gql`
	// mutation UpdateDocumentPartBatch($input: UpdateDocumentPartBatchInput!) {
	//   updateDocumentPartBatch(input: $input) {
	// 	id
	// 	bookDraftId
	// 	ownerId
	// 	deltaOps
	// 	version
	// 	mostRecentDelta
	// 	realTimeAccess
	//   }
	// }
	// `;

	const mutation = gql`
	mutation UpdateDocumentPartBatch($input: UpdateDocumentPartBatchInput!) {
	updateDocumentPartBatch(input: $input) {
		id
		bookDraftId
		ownerId
		version
		mostRecentDelta
		realTimeAccess
		
	}
	}
	`;




	const [
		theMutation,
		{ data, loading, error }] = useMutation(mutation, {

			onQueryUpdated: (args) => {
				//////console.log(args);
				if (args && args.queryName == 'getBookDraft') {

					//return false here to prevent refetching while typing which causes the cursor to keep moving to index 0
					return false;
				}
			}

		});

	const wrappedMutation = ({

		expectedVersion,
		documentPartId,
		//itemType: "DocumentPart",
		//parentExpectedVersion: 10,
		//parentId: "1111",
		//fieldName: "title",
		deltaOps,
		appContext




	}, callback, errorCallback) => {


		deltaOps.version = expectedVersion + 1;
		deltaOps.createdAt = new Date().toISOString();

		let optimisticResponse = {
			updateDocumentPartBatch: {
				mutationId: uuid(),
				id: documentPartId,
				__typename: 'DocumentPart',
				version: expectedVersion + 1,
				mostRecentDelta: JSON.stringify(deltaOps)
				//deltaOps,
				//mostRecentDelta
				// realTimeAccess
			}
		}



		const variables = {
			input: {
				itemExpectedVersion: expectedVersion,
				itemId: documentPartId,
				itemType: "DocumentPart",
				//parentExpectedVersion: 10,
				//parentId: "1111",
				fieldName: "title",
				fieldValue: JSON.stringify(deltaOps)
			}

		}

		try {
			theMutation({
				variables,
				////fetchPolicy: 'network-only',
				context: {
					//https://codeburst.io/highly-functional-offline-applications-using-apollo-client-12885bd5f335
					serializationKey: 'MUTATION'
				},
				optimisticResponse: optimisticResponse,
				//ignoreResults:true,
				update(cache, { data: { updateDocumentPartBatch }, optimisticResponse }) {
					try {
						cache.modify({
							id: cache.identify(updateDocumentPartBatch),
							optimistic: true,
							broadcast: false,
							fields: {
								deltaOps(existingDeltaOps, { readField }) {
									// deltaOps.version = updateDocumentPartBatch.version;
									// deltaOps.createdAt = new Date().toISOString();
									let mostRecentDelta = updateDocumentPartBatch.mostRecentDelta;
									return [...existingDeltaOps, mostRecentDelta];
									//return [...existingDeltaOps, JSON.stringify(deltaOps)];

								},

							},
						});
						//After modifying the cache, trigger the persist operation using the persistor
						// appContext.persistor.persist()
						// 	.then((obj) => {
						// 		console.log(obj)
						// 	})
						// 	.catch(error => {
						// 		console.error("Error persisting cache:", error);
						// 	});
						// console.log('got here')
					} catch (err) {
						console.log(err);
					}
					//console.log(updateDocumentPartBatch);
				},
			}).then((obj) => {

				const pendingMutations = JSON.parse(localStorage.getItem('pendingMutations')) || [];
				pendingMutations.forEach(async (mutationData) => {
					let context = JSON.parse(mutationData.context);
					if (context.optimisticResponse.updateDocumentPartBatch.mutationId === optimisticResponse.updateDocumentPartBatch.mutationId) {
						const updatedPendingMutations = pendingMutations.filter(
							(item) => item !== mutationData
						);
						localStorage.setItem('pendingMutations', JSON.stringify(updatedPendingMutations));
					}

				})
				if (callback) {
					//callback(data.updateDocumentPartBatch);
					callback()
				}
			})
				.catch(e => {
					// you can do something with the error here
					console.log('A mutation error')
					console.log(e);
					if (errorCallback) {
						errorCallback(JSON.parse(e.message))
					}
				});

			if (callback) {
				//callback();
			}

		} catch (err) {
			////console.log(err);
		}


	}
	return [wrappedMutation, { data, loading, error }]

}



export function useCreateEditRequest() {

	// const mutation = gql`
	// mutation createBookUserGroupShare($input: CreateBookUserGroupShareInput!) {
	// 	createBookUserGroupShare(input: $input) {




	const mutation = gql`
	mutation createEditRequest($input: CreateEditRequestInput!){
			createEditRequest(input: $input) {
					__typename
					acceptedAt
					completedAt
					closedAt
					bookId
					book {     
					  
						coverUrl
						  coverUrlType
						  coverThumbnailUrl
						  coverThumbnailUrlType
						  commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
					  createdAt
					  id
					  primaryBookDraftId
					  ownerId
					  title
					  subTitle
					  version
					  owner {
						id
						cognitoId
						name
						profilePicUrl
					  }
					}
					createdAt
					deletedAt
					editBookDraftId
					editorCanceledAt
					editorComments
					editorDeletedAt
					editorUserId
					editorUser {
					  id
					  cognitoId
					  name
					  profilePicUrl
					}
					id
					isFavorite
					locked
					originalBookDraftId
					ownerId
					rejectedAt
					requestedAt
					requestorCanceledAt
					requestorComments
					requestorDeletedAt
					requestorUserId
					requestorUser {
					  id
					  cognitoId
					  name
					  profilePicUrl
					}
					updatedAt
					version
				  
		  }
		
	}`;



	const [
		createEditRequest,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({

		bookId,
		originalBookDraftId,
		requestorUserId,
		editorUserId,




	}, callback,errorCallback) => {

		////console.log(bookId);
		////console.log(originalBookDraftId);
		////console.log(requestorUserId);
		////console.log(editorUserId);

		let id = uuid();

		const variables = {
			input: {
				id,
				bookId,
				originalBookDraftId,
				requestorUserId,
				editorUserId,
			}

		}

		createEditRequest({
			variables,
			refetchQueries: ['getBookEditRequests', 'getUserSentEditRequests'],
			////fetchPolicy: 'network-only'
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}



export function useShareBookWithUserGroup() {

	const mutation = gql`
	mutation createBookUserGroupShare($input: CreateBookUserGroupShareInput!) {
		createBookUserGroupShare(input: $input) {
		    sharedBook {
		      id
						  coverUrl
						  coverUrlType
						  coverThumbnailUrl
						  coverThumbnailUrlType
						  commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
		     
				owner {
					id
					cognitoId
					name
					profilePicUrl
				}
		    }
		    bookId
		    permissions
		    version
		    userGroupId
		    # sharedWithUser {
		    #   id
		    #   name
		    #   profilePicUrl
		    #   version
		    # }
		  }
		}
	`;

	const [
		createBookUserGroupShare,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({

		bookId,
		userGroupId



	}, callback,errorCallback) => {

		////console.log(bookId);
		////console.log(userGroupId);

		let id = uuid();

		const variables = {
			input: {
				bookId,
				userGroupId: userGroupId
			}

		}

		createBookUserGroupShare({
			variables,
			//refetchQueries: ['listUserGroupBookShares'],
			refetchQueries: [
				'listBookShares'
				// 'listUserGroupBookShares',
				// 	{
				// 		variables: { bookId },
				// 		query: gql`query listBookUserGroupShares($bookId: ID!) {
				// 	listBookUserGroupShares(bookId: $bookId) {
				// 		items {
				// 			bookId
				// 			userGroupId
				// 			createdAt
				// 			userGroup {
				// 				id
				// 				name
				// 				profilePicUrl
				// 				groupOwner {
				// 					id
				// 					name
				// 					displayName
				// 					profilePicUrl
				// 				}
				// 			}
				// 			sharedBook {
				// 				id
				// 				title
				// 				coverUrl
				// 				coverUrlType
				// 				coverThumbnailUrl
				// 				coverThumbnailUrlType
				// 				commonAllTitlepagesHeaderUrl
				// 	commonAllHalftitlesHeaderUrl
				// 	commonAllPartsHeaderUrl
				// 	commonVolumeHeaderUrl
				// 	commonPartHeaderUrl
				// 	commonChapterHeaderUrl
				// 	publisherLogoImageSrc
				// 	publisherLogoImageType
				// 	publisherLogoUrl
				// 				owner {
				// 						id
				// 						cognitoId
				// 						name
				// 						profilePicUrl
				// 					}
				// 			}
				// 		}
				// 	}
				// }` }

			],



			onCompleted: () => {

				//This is only needed because, for some reason, the refetchQueries are not being called.
				// if(onCompleted){
				// 	onCompleted();
				// }

			}
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}

export function useCreateUserGroupUser() {

	const mutation = gql`
	mutation createUserGroupUser($input: CreateUserGroupUserInput!) {
		createUserGroupUser(input: $input) {
		userGroupId
		userId
		invitedAt
		acceptedAt
		rejectedAt
		leftAt
		removedAt
		deletedAt
		updatedAt
		}
	}
		`;

	const [
		createUserGroupUser,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		userGroupId,
		userId

	}, callback,errorCallback) => {

		////console.log(userGroupId);
		////console.log(userId);



		const variables = {
			input: {
				userGroupId,
				userId
			}
		}

		createUserGroupUser({
			variables,
			refetchQueries: ['listUserGroupUsers']
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}


export function useCreateUserGroup() {

	const mutation = gql`
	mutation createUserGroup($input: CreateUserGroupInput!) {
		createUserGroup(input: $input) {
		id
		name
		}
	}
		`;

	const [
		createUserGroup,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		name



	},
		callback,
		errorCallback
	) => {

		////console.log(name);

		let id = uuid();




		const variables = {
			input: {
				id: id,
				name: name
			}
		}

		createUserGroup({
			variables,
			refetchQueries: ['getUserUserGroups']
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}






export function useAddBoxSetContributedBook() {

	const mutation = gql`
	mutation addBoxSetContributedBookBatch($input: CreateBoxSetBookBatchInput!) {
	  addBoxSetContributedBookBatch(input: $input) {
		boxSetId
		bookId
		id
		ownerId
		recipeId
		book {
			id
			title
			coverUrl
			coverUrlType
			coverThumbnailUrl
			coverThumbnailUrlType
			commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
			owner {
					id
					cognitoId
					name
					profilePicUrl
				}
		  }
		recipe {
				id
			json
			title
		  }
		boxset {
		  id
		  contributors {
				items {
					contributor {
						id
						name
						profilePicUrl
					}
					contributedBooks {
						items {
							version
							book {
								id 
								title
						  coverUrl
						  coverUrlType
						  coverThumbnailUrl
						  coverThumbnailUrlType
						  commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
								owner {
								  id
								  cognitoId
								  name
								  profilePicUrl
								}
							}
						}
						
					}
					
				}
				
			}
		}
	  }
	}
	`;

	const [
		addBoxSetContributedBook,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({

		boxSetId,
		bookId



	}, callback,errorCallback) => {

		////console.log(boxSetId);
		////console.log(bookId);

		let recipeId = uuid();




		const variables = {
			input: {
				//id: id,
				bookId: bookId,
				boxSetId: boxSetId,
				recipeId
			}
		}

		addBoxSetContributedBook({
			variables,
			refetchQueries: ['getBoxSetContributors']
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}


export function useCreateBoxSetContributor() {

	const mutation = gql`mutation createBoxSetContributor($input: CreateBoxSetContributorInput!) {
		createBoxSetContributor(input: $input) {
		  contributorId
		  id
		  ownerId
		  boxSetId
		  userPermissions
		  version
		}
	  }`;

	const [
		createBoxSetContributor,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({

		boxSetId,
		contributorId



	}, callback,errorCallback) => {

		////console.log(boxSetId);
		////console.log(contributorId);

		let id = uuid();




		const variables = {
			input: {
				boxSetId,
				contributorId,
				//id,
				//ownerId: "${userId}",
				userPermissions: "{}"
			}

		}

		createBoxSetContributor({
			variables,
			refetchQueries: ['getBoxSetContributors', 'listBoxSets']
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}





export function useShareBook(props) {

	const mutation = gql`
	mutation createBookUserShare($input: CreateBookUserShareInput!) {
		createBookUserShare(input: $input) {
		    book {
		      id
						  coverUrl
						  coverUrlType
						  coverThumbnailUrl
						  coverThumbnailUrlType
						  commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
		      owner {
		        name
		        id
		        profilePicUrl
		        version
		      }
		    }
		    bookId
		    permissions
		    version
		    sharedWithUserId
		    sharedWithUser {
		      id
		      name
		      profilePicUrl
		      version
		    }
		  }
		}
	`;

	const [
		createBookUserShare,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({

		bookId,
		sharedWithUserId



	},
		callback,
		errorCallback) => {

		////console.log(bookId);
		////console.log(sharedWithUserId);

		//let id = uuid();

		const variables = {
			input: {
				//id,
				bookId,
				sharedWithUserId
			}

		}



		try {

			createBookUserShare({
				variables,
				refetchQueries: [
					'listBookShares'
					// 'listBookUserShares', 
					// 'listConnectedUserBookShares'
				]
				// optimisticResponse: {
				// 	createDocumentPartBatch: newBddp
				// }
			}).then(({ data }) => {
				if (callback) {
					callback();
				}
			})
				.catch(e => {
					////console.log(e);
					// let errorMessages = e.graphQLErrors.map((gqlError) => {
					// 	return gqlError.message;
					// })
					if (errorCallback) {

						let errorMessages = preProcessGraphqlErrors(e, props)

						errorCallback(errorMessages);

					}
				});


		} catch (err) {
			//console.log(err);
		}













		// createBookUserShare({
		// 	variables,
		// 	refetchQueries: ['listBookUserShares']
		// 	// optimisticResponse: {
		// 	// 	createDocumentPartBatch: newBddp
		// 	// }
		// });
		// if (callback) {
		// 	callback();
		// }
	}
	return [wrappedMutation, { data, loading, error }]

}



export function useRestoreSnapshot() {

	const mutation = gql`
	mutation restoreSnapshotBatch($bookDraftId: ID) {
		restoreSnapshotBatch(bookDraftId: $bookDraftId) {
		id
		newPrimaryBookDraftId
		bookId
		name
		description
		bookDraftType
		ownerId
		createdAt
		updatedAt
		deletedAt

		
	  }
	}
	`;

	const [
		restoreSnapshot,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		snapshotBookDraftId

	}, callback,errorCallback) => {

		////console.log(snapshotBookDraftId);

		let id = uuid();

		const variables = {

			bookDraftId: snapshotBookDraftId

		}

		restoreSnapshot({
			variables,
			refetchQueries: ['listSnapshots'],
			onCompleted: () => {
				if (callback) {
					callback();
				}
			}
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});

	}
	return [wrappedMutation, { data, loading, error }]

}


export function useCreateSnapshot() {

	const mutation = gql`mutation createSnapshotBatch($input: CreateSnapshotBatchInput) {
		createSnapshotBatch(input: $input) {
		  id
		  bookId
		  name
		  description
		  bookDraftType
		  ownerId
		  createdAt
		  updatedAt
		  deletedAt
		  
		}
	  }`;

	const [
		createSnapshot,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		copiedBookDraftId,
		title,
		description

	}, callback,errorCallback) => {

		////console.log(copiedBookDraftId);

		let id = uuid();

		const variables = {

			copiedBookDraftId,
			title,
			description

		}

		createSnapshot({
			variables: { input: variables },
			refetchQueries: ['listSnapshots'],
			onCompleted: () => {
				if (callback) {
					callback();
				}
			}
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});

	}
	return [wrappedMutation, { data, loading, error }]

}


export function useShareRecipe() {

	const mutation = gql`
	mutation createRecipeUserShare($input : CreateRecipeUserShareInput!) {
		createRecipeUserShare(input: $input) {
			id
		    ownerId
		    sharedWithUserId
		  }
		}`;

	const [
		shareRecipe,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		recipeId,
		sharedWithUserId

	}, callback,errorCallback) => {

		////console.log(recipeId);
		////console.log(sharedWithUserId);

		let id = uuid();

		const variables = {
			id,
			recipeId,
			sharedWithUserId
		}

		shareRecipe({
			variables: { input: variables },
			//refetchQueries: ['listUserConnections']
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}




export function useAcceptInvitation() {



	const mutation = gql`
	mutation acceptInvitationBatch($input : AcceptInvitationBatchInput) {
		  acceptInvitationBatch(input: $input) {
			connectionType
			connectedUser {
				id
				name
				ownerId
				version
			}
			connectionUserId
			id
			oppositeId
			initiatorId
			invitationCode
			isFavorite
			ownerId
			updatedAt
			version
			deletedAt
			createdAt
		  }
		}`;


	const [
		acceptInvitation,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		invitationCode,
		onCompleted = () => { }

	}, callback,errorCallback) => {

		////console.log(invitationCode);



		const variables = {
			invitationCode
		}





		acceptInvitation({
			variables: { input: variables },
			refetchQueries: ['listUserConnections'],
			onCompleted: onCompleted
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}





export function useCreateInvitation() {

	const mutation = gql`
			mutation createConnectionInvitation($input: CreateConnectionInvitationInput!) {
				createConnectionInvitation(input: $input) {
					acceptedAt
					createdAt
					deletedAt
					id
					emailAddress
					label
					ownerId
					status
					updatedAt
					version}
				}`;


	const [
		createInvitationMutation,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		label,
		emailAddress

	}, callback,errorCallback) => {

		////console.log(label);

		let id = uuid();
		let invitationCode = uuid();

		const variables = {
			id: id,
			status: "PENDING",
			label: label,
			emailAddress
			//invitationCode: invitationCode
		}





		createInvitationMutation({
			variables: { input: variables },
			refetchQueries: ['listConnectionInvitations']
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}


export function useCreateRecipe() {

	const mutation = gql`
			mutation createRecipe($input: CreateRecipeInput!) {
			  createRecipe(input: $input) {
				id
				title
				type
				json
				ownerId
				title
			  }
			}
			`;

	const [
		createRecipeMutation,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		recipeJson,
		ownerId,
		title,
		type

	}, callback,errorCallback) => {


		////console.log(recipeJson);

		let recipeId = uuid();

		const variables = {
			id: recipeId,
			type, //: 'custom',
			json: recipeJson,
			ownerId: ownerId,
			title: title
		}

		// let optimisticRecipe = {

		// 	__typename: 'Recipe',
		// 	id: recipeId,
		// 	json: recipeJson,
		// 	title: title,
		// 	type: 'custom',
		// 	description: '',
		// 	sharedByUserId: null

		// }


		createRecipeMutation({
			variables: { input: variables },
			refetchQueries: ['listRecipes']
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp


			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}


export function useUpdateItem() {

	const mutation = gql`
	mutation UpdateItemBatch($updateItemInput: UpdateItemBatchInput!) {
	  updateItemBatch(input: $updateItemInput) {
		itemId
		itemType
		fieldName
		fieldValue
		actionType
		realTimeAccess
	  }
	}
	`;

	const [
		updateItem,
		{ data, loading, error }] = useMutation(mutation, {

			// 	update(cache, { data: { createBookBatch } }) {

			// 	if (!mutationLoading || mutationLoading) {
			// 	  cache.modify({
			// 		fields: {
			// 		  listBooks(existingBooks = []) {
			// 			const newBookRef = cache.writeFragment({
			// 			  id: `Book:${createBookBatch.id}`,
			// 			  data: {
			// 				__typename: 'Book',
			// 				title: createBookBatch.title
			// 			  },
			// 			  fragment: gql`
			// 				fragment NewBook on Book{
			// 				  __typename
			// 				  title
			// 				}
			// 			  `
			// 			});
			// 			return {
			// 			  items: [...existingBooks.items, {
			// 				__ref: `Book:${createBookBatch.id}`
			// 			  }]
			// 			}
			// 		  }
			// 		}
			// 	  })
			// 	}

			//   }
		})

	const wrappedUpdateItem = ({
		itemKey,
		itemSortKeyName,
		itemSortKeyValue,
		itemType,
		fieldName,
		fieldValue,
		onCompleted,
		refetchQueries,
		update

	}, callback,errorCallback) => {


		const variables = {
			itemExpectedVersion: 10,
			itemId: itemKey,
			itemSortKeyName: itemSortKeyName,
			itemSortKeyValue: itemSortKeyValue,
			itemType: itemType,
			parentExpectedVersion: 10,
			parentId: "1111",
			fieldName: fieldName,
			fieldValue: JSON.stringify(fieldValue),
			//fieldValue: fieldValue
		}


		updateItem({
			variables: { updateItemInput: variables },
			onCompleted,
			refetchQueries,
			update,
			onCompleted: () => {
				if (callback) {
					callback();
				}
			},



		}).then((obj) => {

			if (callback) {
				//callback(data.updateDocumentPartBatch);
				callback()
			}
		})
			.catch(e => {

				if (errorCallback) {
					errorCallback(JSON.parse(e.message))
				}
			})

	}
	return [wrappedUpdateItem, { data, loading, error }]

}







export function useUpdateRecipeParam() {

	const mutation = gql`
	mutation UpdateRecipeParam($updateRecipeParamInput: UpdateRecipeParamInput!) {
	  updateRecipeParam(input: $updateRecipeParamInput) {
		fieldValue
	  }
	}
	`;

	const [
		updateRecipeParam,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedUpdateItem = ({
		recipeId,
		paramPath,
		paramName,
		paramValue,
		paramAction,
		overrideActive,
		overridePartId,
		partTypeShortHand,
		scope

	}, callback,errorCallback) => {


		const variables = {
			recipeId,
			paramName,
			paramPath,
			//paramValue: JSON.stringify(paramValue),
			paramValue: paramValue,
			paramAction,
			overrideActive,
			overridePartId,
			partTypeShortHand,
			scope
		}


		updateRecipeParam({
			variables: { updateRecipeParamInput: variables },
			// onCompleted,
			// refetchQueries,
			// update

		});
		if (callback) {
			callback();
		}
	}
	return [wrappedUpdateItem, { data, loading, error }]

}



export function useLazyGetRecipe({
	//fetchPolicy
}) {


	let QUERY = gql`query getRecipe($recipeId: ID!) {
		getRecipe(id: $recipeId) {
		  updatedAt
		  type
		  title
		  ownerId
		  json
		  id
		  description
		  createdAt
		  deletedAt
		  version
		}
	  }`;

	const [lazyGetRecipe, { loading, error, data }] = useLazyQuery(QUERY, {
		variables: {
			recipeId: 'MASTER_RECIPE'
		},
		//fetchPolicy: 'cache-and-network'
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			//console.log(data)
		},
		onError: (error) => {
			//console.log(error)
		}
	});





	return [lazyGetRecipe, { loading, error, data }];

}

export function useGetRecipe({
	recipeId
}) {

	////console.log('useGetRecipe()...');
	let QUERY = gql`query getRecipe($recipeId: ID!) {
		getRecipe(id: $recipeId) {
		  updatedAt
		  type
		  title
		  ownerId
		  json
		  id
		  description
		  createdAt
		  deletedAt
		  version
		}
	  }`;





	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			recipeId: recipeId
		},
		//fetchPolicy: 'cache-and-network',
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			//console.log(data)
		},
		onError: (error) => {
			//console.log(error)
		}
	});

	return { loading, error, data, refetch }

}





export function useListBookMerlinRecipes() {

	////console.log('useListBookMerlinRecipes()...');
	let QUERY = gql`query listBookMerlinRecipes {
		listBookMerlinRecipes {
			items{
				updatedAt
				type
				title
				ownerId
				json
				id
				description
				createdAt
				deletedAt
				version
				previewUrl
				previewUrlType
			}
		 
		}
	  }`;





	const { loading, error, data, refetch } = useQuery(QUERY, {
		// variables: {
		// 	recipeId: recipeId
		// },
		fetchPolicy: 'network-only'
	});

	return { loading, error, data, refetch }

}

export function useListRecipes() {

	////console.log('useListRecipes()...');
	let QUERY = gql`query listRecipes {
		listRecipes {
			items{
				updatedAt
				type
				title
				ownerId
				json
				id
				description
				createdAt
				deletedAt
				version
			}
		 
		}
	  }`;





	const { loading, error, data, refetch } = useQuery(QUERY, {
		// variables: {
		// 	recipeId: recipeId
		// },
		//fetchPolicy: 'network-only'
	});

	return { loading, error, data, refetch }

}

export function useLazyGetUser() {

	////console.log('useGetUser()...');

	let QUERY = gql`query getUser($userId: ID!) {
		getUser(id: $userId) {
		  allowConnectionRequests
		  allowEditRequests
		  amazonId
		  availableTags
		  bookbubId
		  createdAt
		  deletedAt
		  emailAddress
		  facebookId
		  goodreadsId
		  id
		  cognitoId
		  invitationCode
		  invitationCodeDate
		  invitationCodeUsed
		  instagramId
		  name
		  lastName
		businessName
			displayName
			country
			state
			zipCode
		  ownerId
		  pinterestId
		  profilePicUrl
			facebookUrl 
			twitterUrl
			instagramUrl
			pinterestUrl
			amazonUrl
			bookbubUrl
			goodreadsUrl
			snapchatUrl
			youtubeUrl
		  snapchatId
		  twitterId
		  updatedAt
		  version
		  youtubeId   
		  userSubscriptions {
			items {
				userId
				productId
				stripeCustomerId
				period
				trialStartDate
				trialEndDate
				stripeData
			}
		  }
		}
	  }`;





	// const { loading, error, data, refetch } = useQuery(QUERY, {
	// 	variables: {
	// 		userId: userId
	// 	},
	// 	////fetchPolicy: 'network-only'
	// });


	const [lazyGetUser, { loading, error, data }] = useLazyQuery(QUERY, {
		fetchPolicy: 'cache-and-network'
	});



	return [lazyGetUser, { loading, error, data }];

}




/*
const queryListConnectionInvitations = gql(`
		query listConnectionInvitations {
			  listConnectionInvitations(filter: {
				  status: {eq: "PENDING"}
				}) {
				items {
				  createdAt
				  deletedAt
				  id
				  invitationCode
				  label
				  ownerId
				  updatedAt
				  version
				}
			  }
			}
			`);
*/



export function useListConnectionInvitations() {

	////console.log('useListConnectionInvitations()...');
	let QUERY = gql`query listConnectionInvitations {
		  listConnectionInvitations(filter: {
			  status: {eq: "PENDING"}
			}) {
			items {
			  createdAt
			  deletedAt
			  id
			  invitationCode
			  emailAddress
			  label
			  ownerId
			  updatedAt
			  version
			}
		  }
		}
		`;





	const { loading, error, data, refetch } = useQuery(QUERY, {
		// variables: {
		// 	recipeId: recipeId
		// },
		//fetchPolicy: 'cache-and-network'
	});

	return { loading, error, data, refetch }

}




export function useListDeletedSnapshots() {

	////console.log('useLazyListSnapshots()...');

	let QUERY = gql`query listDeletedSnapshots {
		listDeletedSnapshots {
			items {
			          id
			          name
					  description
					  bookDraftType
					  createdAt
					  deletedAt
					  bookId 
					  book {
						  id
						  title
						  coverUrl
					  }
			        }
			
		  }
		}`;

	// const [lazyListSnapshots, { loading, error, data, refetch }] = useLazyQuery(QUERY, {
	// 	//fetchPolicy: 'network-only'
	// });

	// return [lazyListSnapshots, { loading, error, data, refetch  }]



	const { loading, error, data, refetch } = useQuery(QUERY, {
		// variables: {
		// 	recipeId: recipeId
		// },
		fetchPolicy: 'cache-and-network'
	});

	return { loading, error, data, refetch }





}

export function useListUserConnections() {

	////console.log('useListUserConnections()...');
	let QUERY = gql`query listUserConnections {
		listUserConnections {
		  items {
			connectedUser {
			  id
			  name
			  version
			  updatedAt
			  profilePicUrl
			  ownerId
			  createdAt
			  deletedAt
			}
			id
			oppositeId
			connectionUserId
			connectionType
			createdAt
			deletedAt
			initiatorId
			invitationCode
			isFavorite
			ownerId
			updatedAt
			version
		  }
		}
	  }`;





	const { loading, error, data, refetch } = useQuery(QUERY, {
		// variables: {
		// 	recipeId: recipeId
		// },
		fetchPolicy: 'cache-and-network',
		pollInterval: config.graphQl.DEFAULT_POLL_INTERVAL,
		notifyOnNetworkStatusChange: true
	});

	return { loading, error, data, refetch }

}


export function useLazyListRecipeUserShares() {

	////console.log('useLazyListRecipeUserShares()...');
	let QUERY = gql`query listRecipeUserShares {
		listRecipeUserShares {
		  items {
			  ownerUser {
				id
				cognitoId
				name
				profilePicUrl
			  }
			  recipe {
				id
				type
				title
				deletedAt
				createdAt
			  }
			}
		}
	  }`;





	const [lazyListRecipeUserShares, { loading, error, data }] = useLazyQuery(QUERY, {
		////fetchPolicy: 'network-only'
	});



	return [lazyListRecipeUserShares, { loading, error, data }];

}








export function useLazyListSnapshots() {

	////console.log('useLazyListSnapshots()...');

	let QUERY = gql`query listSnapshots($bookId : ID!) {
		listSnapshots(bookId: $bookId) {
			items {
			          id
			          name
					  description
					  bookDraftType
					  createdAt
					  deletedAt
			        }
			
		  }
		}`;

	const [lazyListSnapshots, { loading, error, data, refetch, onCompleted }] = useLazyQuery(QUERY, {
		fetchPolicy: 'cache-and-network'
	});

	return [lazyListSnapshots, { loading, error, data, refetch, onCompleted }]

}







export function useLazyListBookUserShares() {

	////console.log('useLazyListBookUserShares()...');

	let QUERY = gql`query listBookUserShares($bookId : ID!) {
		listBookUserShares(bookId: $bookId) {
			items {
					bookId
					createdAt
					deletedAt
					permissions
					sharedWithUser {
						id
						name
						displayName
						profilePicUrl
					}
					sharedWithUserId
			    }
			
		  }
		}`;

	const [lazyBookUserShares, { loading, error, data }] = useLazyQuery(QUERY, {
		fetchPolicy: 'cache-and-network'
	});

	return [lazyBookUserShares, { loading, error, data }]

}




export function useLazyListUserBookShares() {

	////console.log('useLazyListBookUserShares()...');

	let QUERY = gql`query listUserBookShares {
		listUserBookShares {
			items {
					bookId
					createdAt
					deletedAt
					#id
					permissions
					book {
						id
						title
						coverUrl
						coverUrlType
						coverThumbnailUrl
						coverThumbnailUrlType
						commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
						owner {
								id
								cognitoId
								name
								profilePicUrl
							}
					}
					sharedWithUser {
						id
						name
						displayName
						profilePicUrl
						userGroups {
							items {
								userGroupId
								userGroup {
									id
									name
									profilePicUrl
									groupOwner {
										id
										name
										displayName
										profilePicUrl
									}
								}
							}
						}
					}
					sharedWithUserId
			    }
			
		  }
		}`;

	const [lazyListUserBookShares, { loading, error, data }] = useLazyQuery(QUERY, {
		//fetchPolicy: 'network-only'
	});

	return [lazyListUserBookShares, { loading, error, data }]

}

export function useLazyLoadBoxSetContributors() {

	////console.log('useLazyLoadBoxSetContributors()...');

	let QUERY = gql`query getBoxSetContributors($boxSetId : ID!) {
		getBoxSet(id: $boxSetId) {
			        id
			        title
					contributors {
						items {
							contributor {
								id
								name
								ownerId
								version
								profilePicUrl
							}
							contributedBooks {
								items {
									id
									ownerId
									version
									createdAt
									bookId
									boxSetId
									book {
										id
										title
										coverUrl
										coverUrlType
										coverThumbnailUrl
										coverThumbnailUrlType
										commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
										owner {
											createdAt
											id
											name
											profilePicUrl
											version
											cognitoId
										}
									}
								}
							}
						}

		            }
		          }
		        }`;

	const [lazyLoadBoxSetContributors, { loading, error, data }] = useLazyQuery(QUERY, {
		fetchPolicy: 'cache-and-network'
	});

	return [lazyLoadBoxSetContributors, { loading, error, data }]

}



export function useListContributingToBoxSets({
	id
}) {

	////console.log('useListContributingToBoxSets()...');
	let QUERY = gql`query getUser($id: ID!) {
		getUser(id: $id) {
		  id
		  name
		contributingToBoxSets {
		items {
		  contributorId
		  boxSetId
		  createdAt
		  boxset {
			id
			owner {
				name
				profilePicUrl
				version
				cognitoId
				id
			}
			book {
			  	id
			  	title
				coverUrl
				coverUrlType
				coverThumbnailUrl
				coverThumbnailUrlType
				commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
			owner {
					id
					cognitoId
					name
					profilePicUrl
				}
			  subTitle
			  version
			  userPermissions
			  recipeId
			  primaryBookDraftId
			}
			contributors {
						items {
							contributor {
								id
								name
								ownerId
								version
								profilePicUrl
							}
							contributedBooks {
								items {
									id
									ownerId
									version
									createdAt
									bookId
									boxSetId
									book {
										id
										title
										coverUrl
										coverUrlType
										coverThumbnailUrl
										coverThumbnailUrlType
										commonAllTitlepagesHeaderUrl
										commonAllHalftitlesHeaderUrl
										commonAllPartsHeaderUrl
										commonVolumeHeaderUrl
										commonPartHeaderUrl
										commonChapterHeaderUrl
										publisherLogoImageSrc
										publisherLogoImageType
										publisherLogoUrl
										owner {
											createdAt
											id
											name
											profilePicUrl
											version
											cognitoId
										}
									}
								}
							}
						}

		            }
			
		  }
		}
	  }
	}
	}`





	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			id: id
		},
		fetchPolicy: 'cache-and-network',
		pollInterval: config.graphQl.DEFAULT_POLL_INTERVAL
	});

	return { loading, error, data, refetch }

}

export function useListBookContributedToBoxSets({
	id
}) {

	////console.log('useListBookContributedToBoxSets()...');

	let QUERY = gql`query listBookContributedToBoxSets($id: ID!) {
  getBook(id: $id) {
    contributedToBoxSets {
      items {
        boxset {
		  id
		  book {
			  	id
			  	title
				coverUrl
				coverUrlType
				coverThumbnailUrl
				coverThumbnailUrlType
				primaryBookDraftId
				commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
			owner {
					id
					cognitoId
					name
					profilePicUrl
				}
		  }
          boxSetBooks {
            items {
              book {
                title
                id
				coverUrl
				coverUrlType
				coverThumbnailUrl
				coverThumbnailUrlType
				commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
				owner {
						id
						cognitoId
						name
						profilePicUrl
					}
              }
            }
          }
          contributors {
            items {
              contributor {
                id
                name
				profilePicUrl
              }
              contributedBooks {
                items {
                  book {
                    id
                    title
					coverUrl
					coverUrlType
					coverThumbnailUrl
					coverThumbnailUrlType
					commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
					owner {
							id
							cognitoId
							name
							profilePicUrl
						}
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`





	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			id: id
		},
		fetchPolicy: 'cache-and-network'
	});

	return { loading, error, data, refetch }

}



export function useGetUserUserGroups() {

	//NOTE: the id:"getUserUserGroups" is used so that there is no conflict between
	// useGetUserUserGroups() and  useGetUserInvitedToUserGroups(). Previously, 
	// both used id:'ignored'. This caused the results to be cached to the same 
	// saved query since their signatures were identical. 
	let QUERY = gql`query getUserUserGroups {
  getUser(id:"getUserUserGroups") { 
    userGroups {
      items {
        id
        name
        ownerId
		name
		profilePicUrl
		createdAt
		deletedAt
		updatedAt
		groupOwner {
			id
			name
			displayName
			profilePicUrl
		}
      }
    }
  }
}`





	const { loading, error, data, refetch } = useQuery(QUERY, {
		fetchPolicy: 'cache-and-network',
		pollInterval: config.graphQl.DEFAULT_POLL_INTERVAL,
		notifyOnNetworkStatusChange: true
	});

	return { loading, error, data, refetch }

}



export function useGetUserInvitedToUserGroups() {

	//NOTE: the id:"getUserInvitedToUserGroups" is used so that there is no conflict between
	// useGetUserUserGroups() and  useGetUserInvitedToUserGroups(). Previously, 
	// both used id:'ignored'. This caused the results to be cached to the same 
	// saved query since their signatures were identical. 
	let QUERY = gql`query getUserInvitedToUserGroups {
  getUser(id:"getUserInvitedToUserGroups") {
    invitedToUserGroupUsers {
		items {
			
			invitedAt
			acceptedAt
			rejectedAt
			leftAt
			removedAt
			deletedAt
			updatedAt
				user {
					id
					name
					profilePicUrl
				}
				userGroup{
					id
                    name
                    ownerId
					profilePicUrl
					groupOwner {
                       id
                       name
                       profilePicUrl
                    }
				}
			}
    }
  }
}`





	const { loading, error, data, refetch } = useQuery(QUERY, {
		fetchPolicy: 'cache-and-network',
		pollInterval: config.graphQl.DEFAULT_POLL_INTERVAL
	});

	return { loading, error, data, refetch }

}



export function useListUserGroupUsers({
	id
}) {

	////console.log('useListUserGroupUsers()...');

	let QUERY = gql`query listUserGroupUsers($id: ID!) {
		listUserGroupUsers(id: $id) {
			items {
				user {
					id
					name
					profilePicUrl
					
				}
			}
		}
	}`





	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			id: id
		},
		fetchPolicy: 'cache-and-network'
	});

	return { loading, error, data, refetch }

}

export function useListBookShares({
	bookId
}) {

	////console.log('useListBookUserGroupShares()...');

	let QUERY = gql`query listBookShares($bookId: ID!) {
		listBookShares(bookId: $bookId) {
			items {
				bookId
				userGroupId
				userGroup {
					id
					name
					profilePicUrl
					groupOwner {
						id
						name
						displayName
						profilePicUrl
					}
				}
				sharedWithUserId
				sharedWithUser {
					id
					name
					displayName
					profilePicUrl
				}
				createdAt
				
			}
		}
	}`





	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			bookId: bookId
		},
		fetchPolicy: 'cache-and-network'

	});

	return { loading, error, data, refetch }

}



export function useListBookUserGroupShares({
	bookId
}) {

	////console.log('useListBookUserGroupShares()...');

	let QUERY = gql`query listBookUserGroupShares($bookId: ID!) {
		listBookUserGroupShares(bookId: $bookId) {
			items {
				bookId
				userGroupId
				createdAt
				userGroup {
					id
					name
					profilePicUrl
					groupOwner {
						id
						name
						displayName
						profilePicUrl
					}
				}
				sharedBook {
					id
					title
					coverUrl
					coverUrlType
					coverThumbnailUrl
					coverThumbnailUrlType
					commonAllTitlepagesHeaderUrl
					commonAllHalftitlesHeaderUrl
					commonAllPartsHeaderUrl
					commonVolumeHeaderUrl
					commonPartHeaderUrl
					commonChapterHeaderUrl
					publisherLogoImageSrc
					publisherLogoImageType
					publisherLogoUrl
					owner {
							id
							cognitoId
							name
							profilePicUrl
						}
				}
			}
		}
	}`





	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			bookId: bookId
		},
		fetchPolicy: 'cache-and-network'
	});

	return { loading, error, data, refetch }

}


export function useListUserGroupBookShares({
	userGroupId
}) {

	////console.log('useListUserGroupBookShares()...');

	let QUERY = gql`query listUserGroupBookShares($userGroupId: ID!) {
		listUserGroupBookShares(userGroupId: $userGroupId) {
			items {
				bookId
				userGroupId
				userGroup {
					id
					name
					profilePicUrl
				}
				sharedBook {
					id
					title
					coverUrl
					coverUrlType
					coverThumbnailUrl
					coverThumbnailUrlType
					commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
					owner {
							id
							cognitoId
							name
							profilePicUrl
						}
				}
			}
		}
	}`





	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			userGroupId: userGroupId
		},
		fetchPolicy: 'cache-and-network'
	});

	return { loading, error, data, refetch }

}



export function useGetInflatablesBookDraft({
	bookDraftId
}) {

	const { loading, error, data, refetch } = useQuery(queries.GET_INFLATABLES_BOOK_DRAFT, {
		variables: {
			bookDraftId: bookDraftId
		},
		//fetchPolicy: 'cache-and-network',
		//fetchPolicy: 'network-only',
		returnPartialData: true
	});

	return { loading, error, data, refetch }

}

export function useLazyGetVelocityBookDraft({
	//fetchPolicy
}) {

	const [lazyGetBookDraft, { loading, error, data, refetch }] = useLazyQuery(queries.GET_VELOCITY_BOOK_DRAFT, {
		fetchPolicy: 'cache-and-network'
	});

	return [lazyGetBookDraft, { loading, error, data, refetch }];

}




export function useGetBookDraft({
	bookDraftId,
	fetchPolicy = 'cache-first'
}) {

	//console.log('useGetBookDraft()...');
	const { loading, error, data, refetch } = useQuery(queries.GET_BOOK_DRAFT, {
		variables: {
			bookDraftId: bookDraftId
		},
		context: {
			//https://codeburst.io/highly-functional-offline-applications-using-apollo-client-12885bd5f335
			serializationKey: 'MUTATION'

		},
		fetchPolicy: 'cache-and-network',
		//fetchPolicy: 'cache-first',
		//fetchPolicy: 'network-only',
		//fetchPolicy:fetchPolicy,
		//nextFetchPolicy: 'cache-first',
		returnPartialData: true
	});

	return { loading, error, data, refetch }

}

export function useLazyGetBookDraft({
	//fetchPolicy
}) {



	const [lazyGetBookDraft, { loading, error, data, refetch }] = useLazyQuery(queries.GET_BOOK_DRAFT, {
		fetchPolicy: 'cache-and-network'
	});

	return [lazyGetBookDraft, { loading, error, data, refetch }];

}



export function useLazyGetBookDraftOld({
	//fetchPolicy
}) {

	//console.log('useLazyGetBookDraft()...');

	const GET_BOOK_DRAFT = gql`
			  query getBookDraft($bookDraftId: ID!) {
				  getBookDraft(id: $bookDraftId) {
					id
					bookDraftType
				  	bookId
				  	ownerId
				  	version
					  book {
						id
				      	title
						subTitle
						authorName
						appleStoreLinkUrl
						bookbubStoreLinkUrl
						customStoreLinkUrl
						googleStoreLinkUrl
						kindleStoreLinkUrl
						koboStoreLinkUrl
						nookStoreLinkUrl       
						appleIdentifier
						bookbubIdentifier
						customIdentifier
						googleIdentifier
						kindleIdentifier
						koboIdentifier
						nookIdentifier
						coverUrl
						coverUrlType
						coverThumbnailUrl
						coverThumbnailUrlType
						commonAllTitlepagesHeaderUrl
						commonAllHalftitlesHeaderUrl
						commonAllPartsHeaderUrl
						commonVolumeHeaderUrl
						commonPartHeaderUrl
						commonChapterHeaderUrl
						publisherLogoImageSrc
						publisherLogoImageType
						publisherLogoUrl
						owner {
								id
								cognitoId
								name
								profilePicUrl
							}
				      	cognitoId
					    boxSetId
						recipe {
							id
							ownerId
							type
							json
							version
						}
						boxSet{
							id
							boxSetBooks {
								items {
									id
									bookId
									recipeSource
									boxset {
										id
										ownerId
									}
									book {
										recipeId
										coverUrl
										coverUrlType
										coverThumbnailUrl
										coverThumbnailUrlType
										commonAllTitlepagesHeaderUrl
										commonAllHalftitlesHeaderUrl
										commonAllPartsHeaderUrl
										commonVolumeHeaderUrl
										commonPartHeaderUrl
										commonChapterHeaderUrl
										publisherLogoImageSrc
										publisherLogoImageType
										publisherLogoUrl
										id
										title
										subTitle
										authorName
										appleStoreLinkUrl
										bookbubStoreLinkUrl
										customStoreLinkUrl
										googleStoreLinkUrl
										kindleStoreLinkUrl
										koboStoreLinkUrl
										nookStoreLinkUrl       
										appleIdentifier
										bookbubIdentifier
										customIdentifier
										googleIdentifier
										kindleIdentifier
										koboIdentifier
										nookIdentifier
										ownerId
									owner {
											id
											cognitoId
											name
											profilePicUrl
										}
									  recipe {
									    version
									    updatedAt
									    type
									    title
									    sharedByUserId
									    ownerId
									    json
									    id
									    description
									    deletedAt
									    createdAt
									  }
									}
									recipe {
										id
										ownerId
										type
										json
										version
									}
								}
				    }
				
						}
					  }




				bookDraftDocumentParts {
				      nextToken
				      items {
				      	id
				      	partId
				        bookDraftId
				        parentPartId
				        prevPartId
				        originalParentPartId
		              	originalPrevPartId
			            role
				        ownerId
			            deletedAt
						documentPart {
					              	id
									ownerId
									cognitoId
									owner {
										id
										name
										cognitoId
									}
									partType
									title
									subtitle
									description
									authorName
									excludeFromBook
									webPageUrl
									bookDraftId
									version
									bookId
									insertedBookId
									insertedBook {
										id
										title
										coverUrl
										coverUrlType
										coverThumbnailUrl
										coverThumbnailUrlType
										commonAllTitlepagesHeaderUrl
										commonAllHalftitlesHeaderUrl
										commonAllPartsHeaderUrl
										commonVolumeHeaderUrl
										commonPartHeaderUrl
										commonChapterHeaderUrl
										publisherLogoImageSrc
										publisherLogoImageType
										publisherLogoUrl
										appleStoreLinkUrl
										bookbubStoreLinkUrl
										customStoreLinkUrl
										googleStoreLinkUrl
										kindleStoreLinkUrl
										koboStoreLinkUrl
										nookStoreLinkUrl       
										appleIdentifier
										bookbubIdentifier
										customIdentifier
										googleIdentifier
										kindleIdentifier
										koboIdentifier
										nookIdentifier
										owner {
												id
												cognitoId
												name
												profilePicUrl
											}
										primaryBookDraft{
											id
											bookDraftDocumentParts {
												nextToken
												items {
													id
													partId
													bookDraftId
													parentPartId
													prevPartId
													originalParentPartId
													originalPrevPartId
													role
													ownerId
													deletedAt
													documentPart {
																id
																ownerId
																cognitoId
																owner {
																	id
																	name
																	cognitoId
																}
																partType
																title
																subtitle
																description
																authorName
																excludeFromBook
																webPageUrl
																bookDraftId
																version
																bookId
																deltaOps
																content
																editorDelta
																editCompleted
																originalPartId
																notes
																synopsis
																receivedEditChangesAt
																editingNotes
																updatedAt
																completed
																includeTitle
																includeSubtitle
																excludeFromToc
																headingUrl
																includeHeadingUrl
																headerImageType
																titleImageType
																titleImageUrl
																overrideCommonVolumeHeadingUrl
																overrideCommonPartHeadingUrl
																overrideCommonChapterHeadingUrl
																hideHeading
																hideTitle
																hideSubtitle
																isNumbered
																includeAuthor		
																numberAll
																hideHeadingBackgroundImage
																headingBackgroundUrl
																hideAllHeadings
																hideAllSubtitles
																hideAllHeaderImages
																restartPartNumbering
																restartChapterNumbering
																hideAllAuthors
																boxSetOverrideRecipeId
																includeFacebook
																includeTwitter
																includeInstagram
																includePinterest
																includeAmazon
																includeBookbub
																includeGoodreads
																includeSnapchat
																includeYoutube

													}
												}
												}
										}
									}
									deltaOps
									content
									editorDelta
									editCompleted
									originalPartId
									notes
									synopsis
									receivedEditChangesAt
									editingNotes
									updatedAt
									completed
									includeTitle
									includeSubtitle
									excludeFromToc
									headingUrl
									includeHeadingUrl
									headerImageType
																titleImageType
																titleImageUrl
									overrideCommonVolumeHeadingUrl
									overrideCommonPartHeadingUrl
									overrideCommonChapterHeadingUrl
									hideHeading
									hideTitle
		                			hideSubtitle
									isNumbered
									includeAuthor		
									numberAll
																hideHeadingBackgroundImage
																headingBackgroundUrl
									hideAllHeadings
									hideAllSubtitles
									hideAllHeaderImages
									restartPartNumbering
									restartChapterNumbering
									hideAllAuthors
									boxSetOverrideRecipeId
									includeFacebook
									includeTwitter
									includeInstagram
									includePinterest
									includeAmazon
									includeBookbub
									includeGoodreads
									includeSnapchat
									includeYoutube
				        }
				        
				      }
				    }








					  
				  }
				}`;





	const GET_BOOK_DRAFT_FULL = gql`
			  query getBookDraft($bookDraftId: ID!) {
				  getBookDraft(id: $bookDraftId) {
				  	id
				  	bookId
				  	ownerId
				  	version
					  book {
						id
				      	title
						coverUrl
						coverUrlType
						coverThumbnailUrl
						coverThumbnailUrlType
						commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
				      	cognitoId
					    boxSetId
						recipe {
							id
							ownerId
							type
							json
							version
						}
						boxSet{
							id
							boxSetBooks {
								items {
									id
									bookId
									recipeSource
									boxset {
										id
										ownerId
									}
									book {
										recipeId
									    
									  
										coverUrl
										coverUrlType
										coverThumbnailUrl
										coverThumbnailUrlType
										commonAllTitlepagesHeaderUrl
										commonAllHalftitlesHeaderUrl
										commonAllPartsHeaderUrl
										commonVolumeHeaderUrl
										commonPartHeaderUrl
										commonChapterHeaderUrl
										publisherLogoImageSrc
										publisherLogoImageType
										publisherLogoUrl
										appleStoreLinkUrl
										bookbubStoreLinkUrl
										customStoreLinkUrl
										googleStoreLinkUrl
										kindleStoreLinkUrl
										koboStoreLinkUrl
										nookStoreLinkUrl      
										appleIdentifier
										bookbubIdentifier
										customIdentifier
										googleIdentifier
										kindleIdentifier
										koboIdentifier
										nookIdentifier
										owner {
												id
												cognitoId
												name
												profilePicUrl
											}
									  id
									  title
									  subTitle
									  authorName
									appleStoreLinkUrl
									bookbubStoreLinkUrl
									customStoreLinkUrl
									googleStoreLinkUrl
									kindleStoreLinkUrl
									koboStoreLinkUrl
									nookStoreLinkUrl      
										appleIdentifier
										bookbubIdentifier
										customIdentifier
										googleIdentifier
										kindleIdentifier
										koboIdentifier
										nookIdentifier
									  ownerId
									  owner {
									  	id
									  	cognitoId
									  	name
									  }
									  recipe {
									    version
									    updatedAt
									    type
									    title
									    sharedByUserId
									    ownerId
									    json
									    id
									    description
									    deletedAt
									    createdAt
									  }
									}
									recipe {
										id
										ownerId
										type
										json
										version
									}
								}
				    }
						}
						
				    }
				    bookDraftDocumentParts {
				      nextToken
				      items {
				      	id
				      	partId
				        bookDraftId
				        parentPartId
				        prevPartId
				        originalParentPartId
		              	originalPrevPartId
			            role
				        ownerId
			            deletedAt
				        documentPart {
					              	id
									ownerId
									cognitoId
									owner {
										id
										name
										cognitoId
									}
									partType
									title
									subtitle
									description
									authorName
									excludeFromBook
									webPageUrl
									bookDraftId
									version
									bookId
									insertedBookId
									insertedBook {
										id
										title
										coverUrl
										coverUrlType
										coverThumbnailUrl
										coverThumbnailUrlType
										commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
										owner {
												id
												cognitoId
												name
												profilePicUrl
											}
										primaryBookDraft{
											id
											bookDraftDocumentParts {
												nextToken
												items {
													id
													partId
													bookDraftId
													parentPartId
													prevPartId
													originalParentPartId
													originalPrevPartId
													role
													ownerId
													deletedAt
													documentPart {
																id
																ownerId
																cognitoId
																owner {
																	id
																	name
																	cognitoId
																}
																partType
																title
																subtitle
																description
																authorName
																excludeFromBook
																webPageUrl
																bookDraftId
																version
																bookId
																deltaOps
																content
																editorDelta
																editCompleted
																originalPartId
																notes
																synopsis
																receivedEditChangesAt
																editingNotes
																updatedAt
																completed
																includeTitle
																includeSubtitle
																excludeFromToc
																headingUrl
																includeHeadingUrl
																headerImageType
																titleImageType
																titleImageUrl
																overrideCommonVolumeHeadingUrl
																overrideCommonPartHeadingUrl
																overrideCommonChapterHeadingUrl
																hideHeading
																hideTitle
																hideSubtitle
																isNumbered
																includeAuthor		
																numberAll
																hideHeadingBackgroundImage
																headingBackgroundUrl
																hideAllHeadings
																hideAllSubtitles
																hideAllHeaderImages
																restartPartNumbering
																restartChapterNumbering
																hideAllAuthors
																boxSetOverrideRecipeId
																includeFacebook
																includeTwitter
																includeInstagram
																includePinterest
																includeAmazon
																includeBookbub
																includeGoodreads
																includeSnapchat
																includeYoutube
													}
												}
												}
										}
									}
									deltaOps
									content
									editorDelta
									editCompleted
									originalPartId
									notes
									synopsis
									receivedEditChangesAt
									editingNotes
									updatedAt
									completed
									includeTitle
									includeSubtitle
									excludeFromToc
									headingUrl
									includeHeadingUrl
									headerImageType
																titleImageType
																titleImageUrl
									overrideCommonVolumeHeadingUrl
									overrideCommonPartHeadingUrl
									overrideCommonChapterHeadingUrl
									hideHeading
									hideTitle
		                			hideSubtitle
									isNumbered
									includeAuthor		
									numberAll
																hideHeadingBackgroundImage
																headingBackgroundUrl
									hideAllHeadings
									hideAllSubtitles
									hideAllHeaderImages
									restartPartNumbering
									restartChapterNumbering
									hideAllAuthors
									boxSetOverrideRecipeId
									includeFacebook
									includeTwitter
									includeInstagram
									includePinterest
									includeAmazon
									includeBookbub
									includeGoodreads
									includeSnapchat
									includeYoutube
				        }
				      }
				    }
				  }
				}`;


	const [lazyGetBookDraft, { loading, error, data, refetch }] = useLazyQuery(GET_BOOK_DRAFT, {
		fetchPolicy: 'cache-and-network'
	});

	return [lazyGetBookDraft, { loading, error, data, refetch }];

}



export function useLazyLoadInflatables({
	//fetchPolicy
}) {

	//console.log('useLazyGetBookDraft()...');

	const GET_BOOK_DRAFT = gql`
			  query getBookDraft($bookDraftId: ID!) {
				  getBookDraft(id: $bookDraftId) {
					id
					bookDraftType
				  	bookId
				  	ownerId
				  	version
					  book {
						id
				      	title
						subTitle
						authorName
						appleStoreLinkUrl
						bookbubStoreLinkUrl
						customStoreLinkUrl
						googleStoreLinkUrl
						kindleStoreLinkUrl
						koboStoreLinkUrl
						nookStoreLinkUrl       
						appleIdentifier
						bookbubIdentifier
						customIdentifier
						googleIdentifier
						kindleIdentifier
						koboIdentifier
						nookIdentifier
						coverUrl
						coverUrlType
						coverThumbnailUrl
						coverThumbnailUrlType
						commonAllTitlepagesHeaderUrl
						commonAllHalftitlesHeaderUrl
						commonAllPartsHeaderUrl
						commonVolumeHeaderUrl
						commonPartHeaderUrl
						commonChapterHeaderUrl
						publisherLogoImageSrc
						publisherLogoImageType
						publisherLogoUrl
						owner {
								id
								cognitoId
								name
								profilePicUrl
							}
				      	cognitoId
					    boxSetId
						recipe {
							id
							ownerId
							type
							json
							version
						}
						boxSet{
							id
							boxSetBooks {
								items {
									id
									bookId
									recipeSource
									boxset {
										id
										ownerId
									}
									book {
										recipeId
										coverUrl
										coverUrlType
										coverThumbnailUrl
										coverThumbnailUrlType
										commonAllTitlepagesHeaderUrl
										commonAllHalftitlesHeaderUrl
										commonAllPartsHeaderUrl
										commonVolumeHeaderUrl
										commonPartHeaderUrl
										commonChapterHeaderUrl
										publisherLogoImageSrc
										publisherLogoImageType
										publisherLogoUrl
										id
										title
										subTitle
										authorName
										appleStoreLinkUrl
										bookbubStoreLinkUrl
										customStoreLinkUrl
										googleStoreLinkUrl
										kindleStoreLinkUrl
										koboStoreLinkUrl
										nookStoreLinkUrl       
										appleIdentifier
										bookbubIdentifier
										customIdentifier
										googleIdentifier
										kindleIdentifier
										koboIdentifier
										nookIdentifier
										ownerId
									owner {
											id
											cognitoId
											name
											profilePicUrl
										}
									  recipe {
									    version
									    updatedAt
									    type
									    title
									    sharedByUserId
									    ownerId
									    json
									    id
									    description
									    deletedAt
									    createdAt
									  }
									}
									recipe {
										id
										ownerId
										type
										json
										version
									}
								}
				    }
				
						}
					  }




				bookDraftDocumentParts {
				      nextToken
				      items {
				      	id
				      	partId
				        bookDraftId
				        parentPartId
				        prevPartId
				        originalParentPartId
		              	originalPrevPartId
			            role
				        ownerId
			            deletedAt
						documentPart {
					              	id
									ownerId
									cognitoId
									owner {
										id
										name
										cognitoId
									}
									partType
									title
									subtitle
									description
									authorName
									excludeFromBook
									webPageUrl
									bookDraftId
									version
									bookId
									insertedBookId
									insertedBook {
										id
										title
										coverUrl
										coverUrlType
										coverThumbnailUrl
										coverThumbnailUrlType
										commonAllTitlepagesHeaderUrl
										commonAllHalftitlesHeaderUrl
										commonAllPartsHeaderUrl
										commonVolumeHeaderUrl
										commonPartHeaderUrl
										commonChapterHeaderUrl
										publisherLogoImageSrc
										publisherLogoImageType
										publisherLogoUrl
										appleStoreLinkUrl
										bookbubStoreLinkUrl
										customStoreLinkUrl
										googleStoreLinkUrl
										kindleStoreLinkUrl
										koboStoreLinkUrl
										nookStoreLinkUrl       
										appleIdentifier
										bookbubIdentifier
										customIdentifier
										googleIdentifier
										kindleIdentifier
										koboIdentifier
										nookIdentifier
										owner {
												id
												cognitoId
												name
												profilePicUrl
											}
										primaryBookDraft{
											id
											bookDraftDocumentParts {
												nextToken
												items {
													id
													partId
													bookDraftId
													parentPartId
													prevPartId
													originalParentPartId
													originalPrevPartId
													role
													ownerId
													deletedAt
													documentPart {
																id
																ownerId
																cognitoId
																owner {
																	id
																	name
																	cognitoId
																}
																partType
																title
																subtitle
																description
																authorName
																excludeFromBook
																webPageUrl
																bookDraftId
																version
																bookId
																deltaOps
																content
																editorDelta
																editCompleted
																originalPartId
																notes
																synopsis
																receivedEditChangesAt
																editingNotes
																updatedAt
																completed
																includeTitle
																includeSubtitle
																excludeFromToc
																headingUrl
																includeHeadingUrl
																headerImageType
																titleImageType
																titleImageUrl
																overrideCommonVolumeHeadingUrl
																overrideCommonPartHeadingUrl
																overrideCommonChapterHeadingUrl
																hideHeading
																hideTitle
																hideSubtitle
																isNumbered
																includeAuthor		
																numberAll
																hideHeadingBackgroundImage
																headingBackgroundUrl
																hideAllHeadings
																hideAllSubtitles
																hideAllHeaderImages
																restartPartNumbering
																restartChapterNumbering
																hideAllAuthors
																boxSetOverrideRecipeId
																includeFacebook
																includeTwitter
																includeInstagram
																includePinterest
																includeAmazon
																includeBookbub
																includeGoodreads
																includeSnapchat
																includeYoutube

													}
												}
												}
										}
									}
									deltaOps
									content
									editorDelta
									editCompleted
									originalPartId
									notes
									synopsis
									receivedEditChangesAt
									editingNotes
									updatedAt
									completed
									includeTitle
									includeSubtitle
									excludeFromToc
									headingUrl
									includeHeadingUrl
									headerImageType
																titleImageType
																titleImageUrl
									overrideCommonVolumeHeadingUrl
									overrideCommonPartHeadingUrl
									overrideCommonChapterHeadingUrl
									hideHeading
									hideTitle
		                			hideSubtitle
									isNumbered
									includeAuthor		
									numberAll
																hideHeadingBackgroundImage
																headingBackgroundUrl
									hideAllHeadings
									hideAllSubtitles
									hideAllHeaderImages
									restartPartNumbering
									restartChapterNumbering
									hideAllAuthors
									boxSetOverrideRecipeId
									includeFacebook
									includeTwitter
									includeInstagram
									includePinterest
									includeAmazon
									includeBookbub
									includeGoodreads
									includeSnapchat
									includeYoutube
				        }
				        
				      }
				    }








					  
				  }
				}`;





	const GET_BOOK_DRAFT_FULL = gql`
			  query getBookDraft($bookDraftId: ID!) {
				  getBookDraft(id: $bookDraftId) {
				  	id
				  	bookId
				  	ownerId
				  	version
					  book {
						id
				      	title
						coverUrl
						coverUrlType
						coverThumbnailUrl
						coverThumbnailUrlType
						commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
				      	cognitoId
					    boxSetId
						recipe {
							id
							ownerId
							type
							json
							version
						}
						boxSet{
							id
							boxSetBooks {
								items {
									id
									bookId
									recipeSource
									boxset {
										id
										ownerId
									}
									book {
										recipeId
									    
									  
										coverUrl
										coverUrlType
										coverThumbnailUrl
										coverThumbnailUrlType
										commonAllTitlepagesHeaderUrl
										commonAllHalftitlesHeaderUrl
										commonAllPartsHeaderUrl
										commonVolumeHeaderUrl
										commonPartHeaderUrl
										commonChapterHeaderUrl
										publisherLogoImageSrc
										publisherLogoImageType
										publisherLogoUrl
										appleStoreLinkUrl
										bookbubStoreLinkUrl
										customStoreLinkUrl
										googleStoreLinkUrl
										kindleStoreLinkUrl
										koboStoreLinkUrl
										nookStoreLinkUrl      
										appleIdentifier
										bookbubIdentifier
										customIdentifier
										googleIdentifier
										kindleIdentifier
										koboIdentifier
										nookIdentifier
										owner {
												id
												cognitoId
												name
												profilePicUrl
											}
									  id
									  title
									  subTitle
									  authorName
									appleStoreLinkUrl
									bookbubStoreLinkUrl
									customStoreLinkUrl
									googleStoreLinkUrl
									kindleStoreLinkUrl
									koboStoreLinkUrl
									nookStoreLinkUrl      
										appleIdentifier
										bookbubIdentifier
										customIdentifier
										googleIdentifier
										kindleIdentifier
										koboIdentifier
										nookIdentifier
									  ownerId
									  owner {
									  	id
									  	cognitoId
									  	name
									  }
									  recipe {
									    version
									    updatedAt
									    type
									    title
									    sharedByUserId
									    ownerId
									    json
									    id
									    description
									    deletedAt
									    createdAt
									  }
									}
									recipe {
										id
										ownerId
										type
										json
										version
									}
								}
				    }
						}
						
				    }
				    bookDraftDocumentParts {
				      nextToken
				      items {
				      	id
				      	partId
				        bookDraftId
				        parentPartId
				        prevPartId
				        originalParentPartId
		              	originalPrevPartId
			            role
				        ownerId
			            deletedAt
				        documentPart {
					              	id
									ownerId
									cognitoId
									owner {
										id
										name
										cognitoId
									}
									partType
									title
									subtitle
									description
									authorName
									excludeFromBook
									webPageUrl
									bookDraftId
									version
									bookId
									insertedBookId
									insertedBook {
										id
										title
										coverUrl
										coverUrlType
										coverThumbnailUrl
										coverThumbnailUrlType
										commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
										owner {
												id
												cognitoId
												name
												profilePicUrl
											}
										primaryBookDraft{
											id
											bookDraftDocumentParts {
												nextToken
												items {
													id
													partId
													bookDraftId
													parentPartId
													prevPartId
													originalParentPartId
													originalPrevPartId
													role
													ownerId
													deletedAt
													documentPart {
																id
																ownerId
																cognitoId
																owner {
																	id
																	name
																	cognitoId
																}
																partType
																title
																subtitle
																description
																authorName
																excludeFromBook
																webPageUrl
																bookDraftId
																version
																bookId
																deltaOps
																content
																editorDelta
																editCompleted
																originalPartId
																notes
																synopsis
																receivedEditChangesAt
																editingNotes
																updatedAt
																completed
																includeTitle
																includeSubtitle
																excludeFromToc
																headingUrl
																includeHeadingUrl
																headerImageType
																titleImageType
																titleImageUrl
																overrideCommonVolumeHeadingUrl
																overrideCommonPartHeadingUrl
																overrideCommonChapterHeadingUrl
																hideHeading
																hideTitle
																hideSubtitle
																isNumbered
																includeAuthor		
																numberAll
																hideHeadingBackgroundImage
																headingBackgroundUrl
																hideAllHeadings
																hideAllSubtitles
																hideAllHeaderImages
																restartPartNumbering
																restartChapterNumbering
																hideAllAuthors
																boxSetOverrideRecipeId
																includeFacebook
																includeTwitter
																includeInstagram
																includePinterest
																includeAmazon
																includeBookbub
																includeGoodreads
																includeSnapchat
																includeYoutube
													}
												}
												}
										}
									}
									deltaOps
									content
									editorDelta
									editCompleted
									originalPartId
									notes
									synopsis
									receivedEditChangesAt
									editingNotes
									updatedAt
									completed
									includeTitle
									includeSubtitle
									excludeFromToc
									headingUrl
									includeHeadingUrl
									headerImageType
																titleImageType
																titleImageUrl
									overrideCommonVolumeHeadingUrl
									overrideCommonPartHeadingUrl
									overrideCommonChapterHeadingUrl
									hideHeading
									hideTitle
		                			hideSubtitle
									isNumbered
									includeAuthor		
									numberAll
																hideHeadingBackgroundImage
																headingBackgroundUrl
									hideAllHeadings
									hideAllSubtitles
									hideAllHeaderImages
									restartPartNumbering
									restartChapterNumbering
									hideAllAuthors
									boxSetOverrideRecipeId
									includeFacebook
									includeTwitter
									includeInstagram
									includePinterest
									includeAmazon
									includeBookbub
									includeGoodreads
									includeSnapchat
									includeYoutube
				        }
				      }
				    }
				  }
				}`;


	const [lazyGetBookDraft, { loading, error, data, refetch }] = useLazyQuery(GET_BOOK_DRAFT, {
		fetchPolicy: 'cache-and-network'
	});

	return [lazyGetBookDraft, { loading, error, data, refetch }];

}



export function useGetBookEditRequests({
	bookId
}) {

	////console.log('useGetBookEditRequests()...');
	const QUERY = gql`
			  query getBookEditRequests($bookId: ID!) {
				  getBook(id: $bookId) {
				  		id
				      	title
				      	cognitoId
						editRequests {
							items {
							acceptedAt
							completedAt
							closedAt
							bookId
							book {
								coverUrl
								coverUrlType
								coverThumbnailUrl
								coverThumbnailUrlType
								commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
								owner {
										id
										cognitoId
										name
										profilePicUrl
									}
								createdAt
								id
								primaryBookDraftId
								ownerId
								title
								subTitle
								version
							}
							createdAt
							deletedAt
							editBookDraftId
							editorCanceledAt
							editorComments
							editorDeletedAt
							editorUserId
							editorUser {
								id
								cognitoId
								name
								profilePicUrl
							}
							id
							isFavorite
							locked
							originalBookDraftId
							ownerId
							rejectedAt
							requestedAt
							requestorCanceledAt
							requestorComments
							requestorDeletedAt
							requestorUserId
							requestorUser {
								id
								cognitoId
								name
								profilePicUrl
							}
							updatedAt
							version
							}
			      }
				  }	
				}`;


	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			bookId: bookId
		},
		fetchPolicy: 'cache-and-network'
	});

	return { loading, error, data, refetch }

}











export function useGetUserSentEditRequests({
	//userId
}) {

	////console.log('useGetUserSentEditRequests()...');
	const QUERY = gql`
			  query getUserSentEditRequests($userId: ID!) {
				  getUser(id: $userId) {
					  id
					  name
					sentEditRequests {
						items {
							acceptedAt
							book {
								id
								title
								coverUrl
								coverUrlType
								coverThumbnailUrl
								coverThumbnailUrlType
								commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
								coverUrl
								owner {
										id
										cognitoId
										name
										profilePicUrl
									}
							}
							bookId
							createdAt
							closedAt
							completedAt
							deletedAt
							editBookDraftId
							editorCanceledAt
							editorComments
							editorDeletedAt
							editorUser {
								id
								cognitoId
								name
								profilePicUrl
							}
							editorUserId
							id
							isFavorite
							locked
							originalBookDraftId
							ownerId
							rejectedAt
							requestedAt
							requestorCanceledAt
							requestorComments
							requestorDeletedAt
							requestorUserId
							updatedAt
							version
						}
						}
				  }
				}`;


	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			userId: 'getUserSentEditRequests'
		},
		fetchPolicy: 'cache-and-network',
		pollInterval: config.graphQl.DEFAULT_POLL_INTERVAL
	});

	return { loading, error, data, refetch }

}









export function useLazyGetDocumentPart() {

	//////console.log('useLazyGetDocumentPart()...');

	// const QUERY = gql`
	// 		  query getUserReceivedEditRequests($userId: ID!) {
	// 			  getUser(id: $userId) {



	const QUERY = gql`
			  query getDocumentPart($id: ID!) {
				getDocumentPart(id: $id) {
					version
					deltaOps
					mostRecentDelta
				}
			  }`;




	const [lazyGetDocumentPart, { loading, error, data, refetch }] = useLazyQuery(QUERY, {
		//////fetchPolicy: 'network-only'
	});



	return [lazyGetDocumentPart, { loading, error, data, refetch }];

}









export function useGetBooks() {

	const LIST_BOOKS = gql`
	query listBooks {
			listBooks {
			  items {
				...BookDetails
				# primaryBookDraft{
				# 	...BookDraftDetails
				# 	book {
				# 		id
				# 		title
				# 		__typename
				# 		recipe {
				# 			id
				# 			ownerId
				# 			type
				# 			json
				# 			version
				# 			__typename
				# 		}
				# 	}
				# 	bookDraftDocumentParts {
				# 	__typename
				# 	items
				# 		{
				# 			id
				# 			__typename
				# 			partId
				# 			bookDraftId
				# 			parentPartId
				# 			prevPartId
				# 			originalParentPartId
				# 			originalPrevPartId
				# 			role
				# 			ownerId
				# 			deletedAt
				# 			documentPart {
				# 				id
				# 				__typename
				# 				partType
				# 				ownerId
				# 				cognitoId
				# 				owner
				# 				title
				# 				owner {
				# 					id
				# 					__typename
				# 					cognitoId
				# 					name
				# 					profilePicUrl
				# 				}

				# 			}
				# 		}
					
				# }
				#}
					}
			}
	  }
	${fragments.FRAGMENT_BOOK_DETAILS}`;


	const { loading, error, data, refetch, networkStatus, client } = useQuery(LIST_BOOKS, {
		fetchPolicy: "cache-and-network",
		pollInterval: config.graphQl.DEFAULT_POLL_INTERVAL,
		notifyOnNetworkStatusChange: true,
	});

	return { loading, error, data, refetch, networkStatus, client }

}






export function useGetUserReceivedEditRequests({
	userId
}) {

	////console.log('useGetUserReceivedEditRequests()...');
	const QUERY = gql`
			  query getUserReceivedEditRequests($userId: ID!) {
				  getUser(id: $userId) {
					  id
					  name
					  editRequests {
						items {
							id
							bookId
							editorUser {
								id
								cognitoId
								name
								profilePicUrl
							}
							requestorUser {
								id
								cognitoId
								name
								profilePicUrl
							}
							book {
								coverUrl
						  coverUrlType
						  coverThumbnailUrl
						  coverThumbnailUrlType
						  commonAllTitlepagesHeaderUrl
							commonAllHalftitlesHeaderUrl
							commonAllPartsHeaderUrl
							commonVolumeHeaderUrl
							commonPartHeaderUrl
							commonChapterHeaderUrl
							publisherLogoImageSrc
							publisherLogoImageType
							publisherLogoUrl
								id
								title
								subTitle
								owner {
										id
										cognitoId
										name
										profilePicUrl
									}
							}
							completedAt
							acceptedAt
							createdAt
							deletedAt
							editBookDraftId
							editorCanceledAt
							editorComments
							editorDeletedAt
							editorUserId
							isFavorite
							locked
							originalBookDraftId
							ownerId
							rejectedAt
							requestedAt
							requestorCanceledAt
							requestorComments
							requestorDeletedAt
							requestorUserId
							updatedAt
							version
						}
						}
				  }
				}`;


	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			userId: userId
		},
		fetchPolicy: 'cache-and-network',
		pollInterval: config.graphQl.DEFAULT_POLL_INTERVAL
	});

	return { loading, error, data, refetch }

}



export function useGetEditRequest({
	editRequestId
}) {

	////console.log('useGetEditRequest()...');
	const QUERY = gql`
			  query getEditRequest($editRequestId: ID!) {
				getEditRequest(id: $editRequestId) {
					acceptedAt
						book {
								id
								title
								
								coverUrl
						  coverUrlType
						  coverThumbnailUrl
						  coverThumbnailUrlType
						  commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
								owner {
									id
									cognitoId
									name
									profilePicUrl
								}
							}
							bookId
							completedAt
							createdAt
							closedAt
							deletedAt
							editBookDraftId
							editorCanceledAt
							editorComments
							editorDeletedAt
							editorUser {
								id
								cognitoId
								name
								profilePicUrl
							}
							editorUserId
							enableBookDraftTransfer
							enablePartTransfer
							id
							isFavorite
							locked
							originalBookDraftId
							ownerId
							rejectedAt
							requestedAt
							requestorCanceledAt
							requestorComments
							requestorDeletedAt
							requestorDestroyedAt
							requestorUserId
							updatedAt
							version
				}
				}`;


	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			editRequestId
		},
		fetchPolicy: 'network-only'
	});

	return { loading, error, data, refetch }

}


export function useListConnectedUserBookShares({
	userId
}) {
	////console.log('useGetConnectedUserSharedWithBooks()...');
	const QUERY = gql`
			  query listConnectedUserBookShares($userId: ID!) {
				listConnectedUserBookShares(userId: $userId) {
						items {
							book {
								id
								title
								coverUrl
								coverUrlType
								coverThumbnailUrl
								coverThumbnailUrlType
								owner {
									id
									name
									displayName
									profilePicUrl
								}
								ownerId
							}
						}
					
					}
				}`;


	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			userId
		},
		fetchPolicy: 'cache-and-network'
	});

	return { loading, error, data, refetch }

}

export function useGetSharedWithBooks() {
	////console.log('useGetSharedWithBooks()...');
	const QUERY = gql`
			  query useGetSharedWithBooks {
				getUser(id: "useGetSharedWithBooks") {
					userGroupUsers {
						items {
						userGroupId
						userGroup {
							id
							name
							profilePicUrl
							ownerId
							sharedBooks {
							items {
							createdAt
								sharedBook {
								id
								title
						  coverUrl
						  coverUrlType
						  coverThumbnailUrl
						  coverThumbnailUrlType
						  commonAllTitlepagesHeaderUrl
							commonAllHalftitlesHeaderUrl
							commonAllPartsHeaderUrl
							commonVolumeHeaderUrl
							commonPartHeaderUrl
							commonChapterHeaderUrl
							publisherLogoImageSrc
							publisherLogoImageType
							publisherLogoUrl
								primaryBookDraftId
								owner {
									displayName
									id
									name
									profilePicUrl
								}
								ownerId
								}
							}
							}
						}
						}
					}
					sharedBooks {
						items {
						createdAt
						book {
							id
							title
							
							coverUrl
						  coverUrlType
						  coverThumbnailUrl
						  coverThumbnailUrlType
						  commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
							primaryBookDraftId
							owner {
								id
								name
								displayName
								profilePicUrl
							}
							ownerId
						}
						}
					}
					}
				}`;


	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			userId: "useGetSharedWithBooks"
		},
		fetchPolicy: "cache-and-network",
		pollInterval: config.graphQl.DEFAULT_POLL_INTERVAL,
		notifyOnNetworkStatusChange: true,
		onCompleted: () => {
			//alert('completed');
		},
		onError: () => {
			//alert('error');

		}
	});

	return { loading, error, data, refetch }

}




export function useListBookDraftUserComments({
	bookDraftId
}) {
	////console.log('useListBookDraftUserComments()...');

	const QUERY = gql`
			  query listBookDraftUserComments($bookDraftId: ID!) {
				listBookDraftUserComments(bookDraftId: $bookDraftId) {
					items {
						comment
						id
						documentPartId
						createdAt
						sharedBookId
						sharedBookDraftId
						deletedAt
						readAt
						isFavorite
						isPinned
						partIndex
						partLength
						sharedBook {
							id
							title
							coverUrl
							coverUrlType
							coverThumbnailUrl
							coverThumbnailUrlType
						}
						commentorUser {
							displayName
							id
							name
							profilePicUrl
						}
						}
					}
				}`;


	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			bookDraftId
		},
		fetchPolicy: 'cache-and-network'
	});

	return { loading, error, data, refetch }

}


export function useListMostRecentUserComments() {
	////console.log('useListBookDraftUserComments()...');

	const QUERY = gql`
			  query listMostRecentUserComments {
				listMostRecentUserComments {
					items {
						comment
						id
						documentPartId
						createdAt
						sharedBookId
						sharedBookDraftId
						deletedAt
						readAt
						isFavorite
						isPinned
						partIndex
						partLength
						sharedBook {
							id
							title
							coverUrl
							coverUrlType
							coverThumbnailUrl
							coverThumbnailUrlType
						}
						commentorUser {
							displayName
							id
							name
							profilePicUrl
						}
						}
					}
				}`;


	const { loading, error, data, refetch } = useQuery(QUERY, {
		// variables: {
		// 	bookDraftId
		// },
		fetchPolicy: 'cache-and-network'
	});

	return { loading, error, data, refetch }

}



export function useListAllBookDraftUserComments({
	bookDraftId
}) {
	////console.log('useListAllBookDraftUserComments()...');

	const QUERY = gql`
			  query listAllBookDraftUserComments($bookDraftId: ID!) {
				listAllBookDraftUserComments(bookDraftId: $bookDraftId) {
					items {
						comment
						id
						documentPartId
						createdAt
						sharedBookId
						sharedBookDraftId
						deletedAt
						readAt
						isFavorite
						isPinned
						completedAt
						commentorUserId
						partIndex
						partLength
						sharedBook {
							id
							title
							coverUrl
							coverUrlType
							coverThumbnailUrl
							coverThumbnailUrlType
						}
						commentorUser {
							displayName
							id
							name
							profilePicUrl
						}
						}
					}
				}`;


	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			bookDraftId
		},
		fetchPolicy: 'network-only'
		//fetchPolicy: 'cache-and-network'
	});

	return { loading, error, data, refetch }

}


export function useListBookDraftNotes({
	bookDraftId
}) {
	////console.log('useListAllBookDraftUserComments()...');

	const QUERY = gql`
			  query listAllBookDraftNotes($bookDraftId: ID!) {
				listAllBookDraftNotes(bookDraftId: $bookDraftId) {
					items {
						comment
						id
						documentPartId
						createdAt
						sharedBookId
						sharedBookDraftId
						deletedAt
						readAt
						isFavorite
						isPinned
						completedAt
						commentorUserId
						partIndex
						partLength
						sharedBook {
							id
							title
							coverUrl
							coverUrlType
							coverThumbnailUrl
							coverThumbnailUrlType
						}
						commentorUser {
							displayName
							id
							name
							profilePicUrl
						}
						}
					}
				}`;


	const { loading, error, data, refetch } = useQuery(QUERY, {
		variables: {
			bookDraftId
		},
		//fetchPolicy: 'network-only'
		fetchPolicy: 'cache-and-network'
	});

	return { loading, error, data, refetch }

}


export function useLazyListBookDraftUserComments() {
	////console.log('useListBookDraftUserComments()...');

	const QUERY = gql`
			  query listBookDraftUserComments($bookDraftId: ID!) {
				listBookDraftUserComments(bookDraftId: $bookDraftId) {
					items {
						comment
						id
						documentPartId
						createdAt
						sharedBookId
						sharedBookDraftId
						deletedAt
						readAt
						isFavorite
						isPinned
						completedAt
						commentorUserId
						partIndex
						partLength
						sharedBook {
							id
							title
							coverUrl
							coverUrlType
							coverThumbnailUrl
							coverThumbnailUrlType
						}
						commentorUser {
							displayName
							id
							name
							profilePicUrl
						}
						}
					}
				}`;


	// const [lazyListAllBookDraftUserComments, { loading, error, data, refetch }] = useLazyQuery(QUERY, {
	// 	// variables: {
	// 	// 	bookDraftId
	// 	// },
	// 	fetchPolicy: 'network-only'
	// 	//fetchPolicy: 'cache-and-network'
	// });



	// return [lazyListAllBookDraftUserComments, { loading, error, data, refetch }];





	const [lazyListBookDraftUserComments, { loading, error, data, refetch }] = useLazyQuery(QUERY, {
		// variables: {
		// 	bookDraftId
		// },
		//fetchPolicy: 'network-only'
		fetchPolicy: 'cache-and-network'
	});

	return [lazyListBookDraftUserComments, { loading, error, data, refetch }];

}

export function useLazyListAllBookDraftUserComments() {

	////console.log('useLazyListAllBookDraftUserComments()...');
	const QUERY = gql`
			  query listAllBookDraftUserComments($bookDraftId: ID!) {
				listAllBookDraftUserComments(bookDraftId: $bookDraftId) {
					items {
						comment
						id
						documentPartId
						createdAt
						sharedBookId
						sharedBookDraftId
						deletedAt
						readAt
						isFavorite
						isPinned
						completedAt
						commentorUserId
						partIndex
						partLength
						sharedBook {
							id
							title
							coverUrl
							coverUrlType
							coverThumbnailUrl
							coverThumbnailUrlType
						}
						commentorUser {
							displayName
							id
							name
							profilePicUrl
						}
						}
					}
				}`;





	const [lazyListAllBookDraftUserComments, { loading, error, data, refetch }] = useLazyQuery(QUERY, {
		// variables: {
		// 	bookDraftId
		// },
		//fetchPolicy: 'network-only'
		fetchPolicy: 'cache-and-network'
	});



	return [lazyListAllBookDraftUserComments, { loading, error, data, refetch }];

}




export function useGetUserInitiatedActionHistory() {

	////console.log('useGetUserInitiatedActionHistory()...');
	const QUERY = gql`
			  query getUserInitiatedActionHistory {
				  getUser {
					  id
					  name
					  targetUserActionHistory {
						  items {
							  id
							  actionType
							  sharedBookUserCommentId
							  actionSharedBookUserComment {
								id
								documentPartId
								sharedBook {
									id
									title
									coverUrl
									coverUrlType
									coverThumbnailUrl
									coverThumbnailUrlType
									commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
									owner {
											id
											cognitoId
											name
											profilePicUrl
										}
								}
								comment
						id
						documentPartId
						createdAt
						sharedBookId
						sharedBookDraftId
						deletedAt
						readAt
						isFavorite
						isPinned
						completedAt
						commentorUserId
								commentorUser{
									id
									name
									displayName
								}
								comment
								createdAt
								deletedAt
								readAt
								isFavorite
								isPinned
							  }
						  }
					  }
					  initiatorUserActionHistory {
						  items {
							  id
							  actionType
							  targetUser {
								  id
								  name
								  displayName
							  }
							  boxSetId
							  actionBoxSet{
								  id
								  bookId
								  book {
									  id
									  title
										coverUrl
										coverUrlType
										coverThumbnailUrl
										coverThumbnailUrlType
										commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
										owner {
												id
												cognitoId
												name
												profilePicUrl
											}
								  }
							  }
							  bookId
							  actionBook{
								  id
								  title
								  primaryBookDraftId
							  }
							  actionEditRequest {
								id
								bookId
								editorUser {
									id
									cognitoId
									name
									profilePicUrl
								}
								requestorUser {
									id
									cognitoId
									name
									profilePicUrl
								}
								book {
									
									coverUrl
									coverUrlType
									coverThumbnailUrl
									coverThumbnailUrlType
									commonAllTitlepagesHeaderUrl
        commonAllHalftitlesHeaderUrl
        commonAllPartsHeaderUrl
        commonVolumeHeaderUrl
        commonPartHeaderUrl
        commonChapterHeaderUrl
        publisherLogoImageSrc
        publisherLogoImageType
        publisherLogoUrl
									id
									title
									subTitle
									owner {
										id
										cognitoId
										name
										profilePicUrl
									}
								}
								completedAt
								acceptedAt
								createdAt
								deletedAt
								editBookDraftId
								editorCanceledAt
								editorComments
								editorDeletedAt
								editorUserId
								isFavorite
							locked
								originalBookDraftId
								ownerId
								rejectedAt
								requestedAt
								requestorCanceledAt
								requestorComments
								requestorDeletedAt
								requestorUserId
								updatedAt
								version
							
							}
						  }
					  }
					  
				  }
				}`;


	const { loading, error, data, refetch } = useQuery(QUERY, {
		// variables: {
		// 	userId: userId
		// },
		fetchPolicy: 'cache-and-network'
	});

	return { loading, error, data, refetch }

}




export function useGetBoxSets() {


	//console.log('useGetBoxSets()...');

	const LIST_BOXSETS = gql`
query listBoxSets {
  listBoxSets {
	items {
		...BoxSetDetails
				book{   
					...BookDetails
				}
				contributors {
						items {
							contributor {
								id
								name
								ownerId
								version
								profilePicUrl
							}
							contributedBooks {
								items {
									id
									ownerId
									version
									createdAt
									bookId
									boxSetId
									book {
										id
										title
										coverUrl
										coverUrlType
										coverThumbnailUrl
										coverThumbnailUrlType
										commonAllTitlepagesHeaderUrl
										commonAllHalftitlesHeaderUrl
										commonAllPartsHeaderUrl
										commonVolumeHeaderUrl
										commonPartHeaderUrl
										commonChapterHeaderUrl
										publisherLogoImageSrc
										publisherLogoImageType
										publisherLogoUrl
										owner {
											createdAt
											id
											name
											profilePicUrl
											version
											cognitoId
										}
									}
								}
							}
						}

		            }
		
	}
  }
}
${fragments.FRAGMENT_BOOK_DETAILS}
${fragments.FRAGMENT_BOXSET_DETAILS}`;


	const { loading, error, data, refetch, networkStatus } = useQuery(LIST_BOXSETS, {
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-and-network',
		pollInterval: config.graphQl.DEFAULT_POLL_INTERVAL,
		//nextFetchPolicy: 'cache-first',
		// context: {
		// 	//https://codeburst.io/highly-functional-offline-applications-using-apollo-client-12885bd5f335
		// 	serializationKey: 'a query'

		// },
		returnPartialData: true
	});

	return { loading, error, data, refetch, networkStatus }

}




export function useAddBookDraftDocumentPart() {

	const createDocumentPartBatchMutation = gql`
	mutation createDocumentPartBatch($createDocumentPartInput: CreateDocumentPartBatchInput!) {
	  createDocumentPartBatch(
		input: $createDocumentPartInput) {
			...BookDraftDocumentPartDetails
				        documentPart {
					           ...DocumentPartDetails   
									
				        }
		}
	}
	${fragments.FRAGMENT_BOOKDRAFT_DOCUMENTPART_DETAILS}
	${fragments.FRAGMENT_DOCUMENTPART_DETAILS}`;

	const [
		createDocumentPartBatch,
		{ data, loading, error }] = useMutation(createDocumentPartBatchMutation, {

			context: {
				//https://codeburst.io/highly-functional-offline-applications-using-apollo-client-12885bd5f335
				serializationKey: 'MUTATION'

			},
			onQueryUpdated: (args) => {
				//console.log(args);
				if (args && args.queryName == 'getBookDraft') {
					//return false;
				}
			},
			update(cache, { data: { createDocumentPartBatch } }) {

				//console.log(createDocumentPartBatch);
				//https://github.com/apollographql/apollo-client/issues/5903

				//console.log(createDocumentPartBatch);

				cache.modify({
					id: 'BookDraft:' + createDocumentPartBatch.bookDraftId,
					fields: {
						bookDraftDocumentParts: (previous, { toReference }) => (

							{
								items: [...previous.items, toReference(createDocumentPartBatch)]
							}
						)
					}
				});







			}
		});

	const wrappedCreateDocumentPartBatch = ({
		userId,
		partType,
		bookDraftId,
		bookId,
		insertedBookId,
		role,
		parentPartId,
		prevPartId,
		starterDeltaOps

	}, callback,errorCallback) => {
		//const partType = menuId;
		//let bookDraftId = writingBookDraft.id;
		//let bookId = writingBookDraft.book.id;
		let newPartId = uuid();
		//let parentPartId = contextSelectedBddp?.partId;
		//let starterDeltaOps = [];


		let newBddp = {

			bookDraftId,
			createdAt: "2022-01-09T03:02:52.146Z",
			id: bookDraftId + newPartId,
			ownerId: userId,
			parentPartId,
			prevPartId,
			partId: newPartId,
			prevPartId: prevPartId,
			role: " ",
			version: 1,
			documentPart: {
				createdAt: "2022-01-09T03:02:52.146Z",
				deltaOps: starterDeltaOps,
				role,
				id: newPartId,
				ownerId: userId,
				partType: partType,
				title: "" + partType,
				version: 1,
				bookId,
				insertedBookId,
				includeTitle: 'true',
				includeSubtitle: 'true',
				includeAuthor: 'true',
				isNumbered: 'true',
				__typename: "DocumentPart"
			},
			__typename: "BookDraftDocumentPart"
		};

		const variables = {
			bookDraftId: bookDraftId,
			expectedVersion: 1,
			parentPartId: parentPartId,
			prevPartId: prevPartId,
			partId: newPartId,
			partType: partType,
			//prevPartId: prevPartId,
			title: "" + partType,
			deltaOps: JSON.stringify(starterDeltaOps),
			bookId: bookId,
			cognitoId: 'user.cognitoId'
		}

		if (insertedBookId) {
			variables.insertedBookId = insertedBookId;
		}
		createDocumentPartBatch({
			variables: { createDocumentPartInput: variables },
			//refetchQueries: ['getBookDraft'],
			optimisticResponse: {
				createDocumentPartBatch: newBddp


			},
			// refetchQueries: [{
			// 	query: queries.GET_BOOK_DRAFT,
			// 	variables: {
			// 		bookDraftId: bookDraftId
			// 	},
			//   }]
		});
		if (callback) {
			callback(newBddp);
		}
	}
	return [wrappedCreateDocumentPartBatch, { data, loading, error }]

}

export function useCreateBoxSet() {

	const mutation = gql`
		mutation createBoxsetBatch($createBoxSetInput: CreateBoxsetBatchInput!) {
		  createBoxsetBatch(input: $createBoxSetInput) {
				...BoxSetDetails
				book{    
					...BookDetails
				}
		  }
		}
		${fragments.FRAGMENT_BOOK_DETAILS}
		${fragments.FRAGMENT_BOXSET_DETAILS}`;


	const [
		createBoxSet,
		{ data, loading, error }] = useMutation(mutation, {

			update(
				cache,
				{
					data: { createBoxsetBatch }
				}
			) {

				//console.log('update...');


				try {
					cache.modify({
						fields: {
							listBoxSets(existingBoxSets = []) {
								let cacheKey = `BoxSet:${createBoxsetBatch.id}`;
								const newBoxSetRef = cache.writeFragment({
									id: cacheKey,

									data: {
										...createBoxsetBatch
									},
									fragment: gql`
											fragment NewBoxSet on BoxSet{
											__typename
											bookId
											id
											book {
												__typename
													id
													title
												}
											}
										`
								});

								//check if this item is already in the list
								if (existingBoxSets && existingBoxSets.items.filter(f => f.__ref == cacheKey).length > 0) {
									return {
										items: [...existingBoxSets.items]
									}
								} else {
									return {
										items: [...existingBoxSets.items, {
											__ref: `BoxSet:${createBoxsetBatch.id}`
										}]
									}
								}

							}
						}
					});


					//console.log(queries);

					let q = gql`
					query getBookDraft($bookDraftId: ID!) {
						getBookDraft(id: $bookDraftId) {
							__typename
							id
							bookId
							version 
							book {
								__typename
								id
								title
								recipe {
									id
									__typename
									ownerId
									type
									json
									version
								}
							}
							bookDraftDocumentParts {
								items
									{
										id
										__typename
										partId
										bookDraftId
										parentPartId
										prevPartId
										originalParentPartId
										originalPrevPartId
										role
										ownerId
										deletedAt
										documentPart {
											id
											__typename
											partType
											ownerId
											cognitoId
											owner
											title
											version
			
										}
									}
								
							}
						}
					}`;

					cache.writeQuery({
						query: q, //queries.GET_BOOK_DRAFT,
						data: { // Contains the data to write
							getBookDraft: createBoxsetBatch.book.primaryBookDraft
						},
						variables: {
							bookDraftId: createBoxsetBatch.book.primaryBookDraftId
						}
					});


					const results = cache.readQuery({
						query: q, //queries.GET_BOOK_DRAFT,
						variables: { // Provide any required variables here
							bookDraftId: createBoxsetBatch.book.primaryBookDraftId
						},
					});


					//console.log(results);


				} catch (err) {
					console.log(err);
				}




			}
		});




	const wrappedCreateBoxSet = ({
		user,
		title,
		masterRecipe

	}, callback,errorCallback) => {

		const now = Date.now();
		const bookDraftId = uuid();
		const bookId = uuid();
		const boxsetId = uuid();
		const rootPartId = uuid();
		const recipeId = uuid();

		const cognitoId = user.cognitoId;


		const variables = {
			bookDraftId,
			bookId,
			bookTitle: title,
			boxsetId,
			rootPartId,
			recipeId
		}


		let masterRecipeStringified = JSON.stringify(toJS(masterRecipe).json);
		let optimisticCreateBoxsetBatch = {
			id: boxsetId,
			__typename: 'BoxSet',
			bookId: bookId,
			book: {
				id: bookId,
				__typename: 'Book',
				title: title + ' optimistic',
				owner: {
					id: user.id,
					__typename: 'User',
					cognitoId: user.cognitoId,
					name: user.name,
					profilePicUrl: user.profilePicUrl
				},
				recipe: {
					id: recipeId,
					__typename: "Recipe",
					ownerId: user.id,
					type: '???',
					json: masterRecipeStringified,
					version: 1
				},
				primaryBookDraftId: bookDraftId,
				primaryBookDraft: {
					__typename: "BookDraft",
					id: bookDraftId,
					bookId: bookId,
					version: 1,
					book: {
						id: bookId,
						__typename: 'Book',
						title: title + ' optimistic',
						recipe: {
							id: recipeId,
							__typename: "Recipe",
							ownerId: user.id,
							type: '???',
							json: masterRecipeStringified,
							version: 1
						},
					},
					bookDraftDocumentParts: {
						__typename: "ModelBookDraftDocumentPartConnection",
						items: [
							{
								id: bookDraftId + rootPartId,
								__typename: 'BookDraftDocumentPart',
								partId: rootPartId,
								bookDraftId: bookDraftId,
								parentPartId: null,
								prevPartId: null,
								originalParentPartId: null,
								originalPrevPartId: null,
								role: null,
								ownerId: user.id,
								deletedAt: null,
								documentPart: {
									id: rootPartId,
									__typename: 'DocumentPart',
									partType: 'ROOT',
									ownerId: user.id,
									cognitoId: cognitoId,
									owner: null,
									title: "Root123",
									version: 1

								}
							}
						]
					}
				}
			}

		}

		createBoxSet({
			variables: { createBoxSetInput: variables },
			context: {
				//https://codeburst.io/highly-functional-offline-applications-using-apollo-client-12885bd5f335
				serializationKey: 'MUTATION'

			},
			optimisticResponse: {
				createBoxsetBatch: optimisticCreateBoxsetBatch
			},
			//refetchQueries: ['listBoxSets']
			//refetchQueries: ['listBoxSets'],
			onQueryUpdated(observableQuery) {
				// If ReallyImportantQuery is active, it will be passed to onQueryUpdated.
				// If no query with that name is active, a warning will be logged.
				//console.log(observableQuery);
				//return observableQuery.refetch()
			},
		});
		if (callback) {
			callback(optimisticCreateBoxsetBatch);
		}
	}
	return [wrappedCreateBoxSet, { data, loading, error }]

}



export function useCreateBook() {

	const mutation = gql`
		mutation createBookBatch($createBookInput: CreateBookBatchInput!) {
			createBookBatch(input: $createBookInput) {
				...BookDetails
			# 	primaryBookDraft{
			# 		...BookDraftDetails
			# 		book {
			# 			id
			# 			title
			# 			__typename
			# 			recipe {
			# 				id
			# 				ownerId
			# 				type
			# 				json
			# 				version
			# 				__typename
			# 			}
			# 		}
			# 		bookDraftDocumentParts {
			# 		__typename
			# 		items
			# 			{
			# 				id
			# 				__typename
			# 				partId
			# 				bookDraftId
			# 				parentPartId
			# 				prevPartId
			# 				originalParentPartId
			# 				originalPrevPartId
			# 				role
			# 				ownerId
			# 				deletedAt
			# 				documentPart {
			# 					id
			# 					__typename
			# 					partType
			# 					ownerId
			# 					cognitoId
			# 					owner
			# 					title
			# 					owner {
			# 						id
			# 						__typename
			# 						cognitoId
			# 						name
			# 						profilePicUrl
			# 					}

			# 				}
			# 			}
					
			# 	}
			# }
		  }
		}
		${fragments.FRAGMENT_BOOK_DETAILS}`;


	const [
		createBook,
		{ data, loading, error }] = useMutation(mutation, {

			update(
				cache,
				{
					data: { createBookBatch }
				}
			) {

				//console.log('update...');


				try {
					cache.modify({
						fields: {
							listBooks(existingItems = []) {
								let cacheKey = `Book:${createBookBatch.id}`;
								const newRef = cache.writeFragment({
									id: cacheKey,

									data: {
										...createBookBatch
									},
									fragment: gql`
											fragment NewFragment on Book{
											
												__typename
												id
												title
												createdAt
												
											}
										`
								});

								//check if this item is already in the list
								if (existingItems && existingItems.items.filter(f => f.__ref == cacheKey).length > 0) {
									return {
										items: [...existingItems.items]
									}
								} else {
									return {
										items: [...existingItems.items, {
											__ref: `Book:${createBookBatch.id}`
										}]
									}
								}

							}
						}
					});


					//console.log(queries);

					let q = gql`
					query getBookDraft($bookDraftId: ID!) {
						getBookDraft(id: $bookDraftId) {
							__typename
							id
							bookId
							version 
							book {
								__typename
								id
								title
								createdAt
								recipe {
									id
									__typename
									ownerId
									type
									json
									version
								}
							}
							bookDraftDocumentParts {
								items
									{
										id
										__typename
										partId
										bookDraftId
										parentPartId
										prevPartId
										originalParentPartId
										originalPrevPartId
										role
										ownerId
										deletedAt
										documentPart {
											id
											__typename
											partType
											ownerId
											cognitoId
											owner
											title
								
			
										}
									}
								
							}
						}
					}`;

					cache.writeQuery({
						query: q, //queries.GET_BOOK_DRAFT,
						data: { // Contains the data to write
							getBookDraft: createBookBatch.primaryBookDraft
						},
						variables: {
							bookDraftId: createBookBatch.primaryBookDraftId
						}
					});


					const results = cache.readQuery({
						query: q, //queries.GET_BOOK_DRAFT,
						variables: { // Provide any required variables here
							bookDraftId: createBookBatch.primaryBookDraftId
						},
					});


					//console.log(results);


				} catch (err) {
					console.log(err);
				}




			}
		});




	const wrappedCreateBook = ({
		user,
		title,
		masterRecipe

	}, callback,errorCallback) => {

		const now = new Date().toISOString();
		const bookDraftId = uuid();
		const bookId = uuid();
		const rootPartId = uuid();
		const recipeId = uuid();

		const cognitoId = user.cognitoId;

		const variables = {
			bookDraftId,
			bookId,
			bookTitle: title,
			rootPartId,
			recipeId,
			cognitoId
		}


		let masterRecipeStringified = JSON.stringify(toJS(masterRecipe).json);
		let optimisticCreateBookBatch = {
			id: bookId,
			__typename: 'Book',
			title: title + ' optimistic',
			primaryBookDraftId: bookDraftId,

			subTitle: null,
			authorName: null,
			coverUrl: null,
			coverUrlType: null,
			coverThumbnailUrl: null,
			coverThumbnailUrlType: null,
			commonAllTitlepagesHeaderUrl: null,
			commonAllHalftitlesHeaderUrl: null,
			commonAllPartsHeaderUrl: null,
			commonVolumeHeaderUrl: null,
			commonPartHeaderUrl: null,
			commonChapterHeaderUrl: null,
			publisherLogoImageSrc: null,
			publisherLogoImageType: null,
			publisherLogoUrl: null,
			appleStoreLinkUrl: null,
			bookbubStoreLinkUrl: null,
			customStoreLinkUrl: null,
			googleStoreLinkUrl: null,
			kindleStoreLinkUrl: null,
			koboStoreLinkUrl: null,
			nookStoreLinkUrl: null,
			appleIdentifier: null,
			bookbubIdentifier: null,
			customIdentifier: null,
			googleIdentifier: null,
			kindleIdentifier: null,
			koboIdentifier: null,
			nookIdentifier: null,
			cognitoId: null,
			boxSetId: null,
			ownerId: null,
			version: null,
			createdAt: now,
			updatedAt: null,
			deletedAt: null,


			owner: {
				id: user.id,
				__typename: 'User',
				cognitoId: user.cognitoId,
				name: user.name,
				profilePicUrl: user.profilePicUrl
			},
			recipe: {
				id: recipeId,
				__typename: "Recipe",
				ownerId: user.id,
				type: '???',
				json: masterRecipeStringified,
				version: 1
			},
			primaryBookDraft: {
				id: bookDraftId,
				bookId: bookId,
				ownerId: user.id,
				version: 1,
				createdAt: null,
				updatedAt: null,
				deletedAt: null,
				__typename: "BookDraft",
				book: {
					id: bookId,
					__typename: 'Book',
					title: title + ' optimistic',
					createdAt: new Date().toISOString(),
					recipe: {
						id: recipeId,
						__typename: "Recipe",
						ownerId: user.id,
						type: '???',
						json: masterRecipeStringified,
						version: 1
					},
				},
				bookDraftDocumentParts: {
					__typename: "ModelBookDraftDocumentPartConnection",
					items: [
						{
							id: bookDraftId + rootPartId,
							__typename: 'BookDraftDocumentPart',
							partId: rootPartId,
							bookDraftId: bookDraftId,
							parentPartId: null,
							prevPartId: null,
							originalParentPartId: null,
							originalPrevPartId: null,
							role: null,
							ownerId: user.id,
							deletedAt: null,
							documentPart: {
								id: rootPartId,
								__typename: 'DocumentPart',
								partType: 'ROOT',
								ownerId: user.id,
								cognitoId: cognitoId,
								owner: null,
								title: "Root123",
								owner: {
									id: user.id,
									__typename: 'User',
									cognitoId: user.cognitoId,
									name: user.name,
									profilePicUrl: user.profilePicUrl
								}

							}
						}
					]
				}
			}


		}

		createBook({
			variables: { createBookInput: variables },
			refetchQueries: ['listRecipes', 'listBooks'],
			// context: {
			// 	//https://codeburst.io/highly-functional-offline-applications-using-apollo-client-12885bd5f335
			// 	serializationKey: 'MUTATION'

			// },
			optimisticResponse: {
				createBookBatch: optimisticCreateBookBatch
			},
			//refetchQueries: ['listBoxSets']
			//refetchQueries: ['listBoxSets'],
			onQueryUpdated(observableQuery) {
				// If ReallyImportantQuery is active, it will be passed to onQueryUpdated.
				// If no query with that name is active, a warning will be logged.
				//console.log(observableQuery);
				//return observableQuery.refetch()
			},
		});
		if (callback) {
			callback(optimisticCreateBookBatch);
		}
	}
	return [wrappedCreateBook, { data, loading, error }]

}


export function useMoveBookDraftDocumentPart() {

	// const moveDocumentPartBatchMutation = gql`
	// mutation moveDocumentPartBatch($moveDocumentPartBatchInput: MoveDocumentPartBatchInput!) {
	// 	moveDocumentPartBatch(
	// 	input: $moveDocumentPartBatchInput) {
	// 		...BookDraftDocumentPartDetails
	// 			        documentPart {
	// 				           ...DocumentPartDetails   

	// 			        }
	// 	}
	// }
	// ${fragments.FRAGMENT_BOOKDRAFT_DOCUMENTPART_DETAILS}
	// ${fragments.FRAGMENT_DOCUMENTPART_DETAILS}`;

	const moveDocumentPartBatchMutation = gql`
	mutation moveDocumentPartBatch($moveDocumentPartBatchInput: MoveDocumentPartBatchInput!) {
		moveDocumentPartBatch(
		input: $moveDocumentPartBatchInput) {
			...BookDraftDocumentPartDetails
				        
		}
	}
	${fragments.FRAGMENT_BOOKDRAFT_DOCUMENTPART_DETAILS}`;








	const [
		moveDocumentPartBatch,
		{ data, loading, error }] = useMutation(moveDocumentPartBatchMutation, {

			context: {
				//https://codeburst.io/highly-functional-offline-applications-using-apollo-client-12885bd5f335
				serializationKey: 'MUTATION'

			},
			// onQueryUpdated: (args) => {
			// 	console.log(args);
			// 	if (args && args.queryName == 'getBookDraft') {
			// 		//return false;
			// 	}
			// },
			// update(cache, { data: { moveDocumentPartBatch } }) {
			// 	//console.log(createDocumentPartBatch);
			// 	//https://github.com/apollographql/apollo-client/issues/5903
			// 	console.log(moveDocumentPartBatch);
			// }
		});

	const wrappedMoveDocumentPartBatch = ({
		userId,
		bookDraftId,
		bookDraftExpectedVersion,
		movedPartId,
		movedPartExpectedVersion,
		parentPartId,
		prevPartId,
		optimisticResponse

	}, callback,errorCallback) => {
		let newPartId = uuid();


		const variables = {
			bookDraftId,
			bookDraftExpectedVersion,
			movedPartId,
			movedPartExpectedVersion,
			parentPartId,
			prevPartId
		}


		moveDocumentPartBatch({
			variables: { moveDocumentPartBatchInput: variables },
			//refetchQueries: ['getBookDraft'],
			optimisticResponse: optimisticResponse,
			update(cache, { data: { moveDocumentPartBatch }, optimisticResponse }) {
				try {
					
					// updateDocumentPartBatch
					// cache.modify({
					// 	id: cache.identify(updateDocumentPartBatch),
					// 	optimistic: true,
					// 	broadcast: false,
					// 	fields: {
					// 		deltaOps(existingDeltaOps, { readField }) {
					// 			let mostRecentDelta = updateDocumentPartBatch.mostRecentDelta;
					// 			return [...existingDeltaOps, mostRecentDelta];

					// 		},

					// 	},
					// });
					
				} catch (err) {
					console.log(err);
				}
				
			},
			// refetchQueries: [{
			// 	query: queries.GET_BOOK_DRAFT,
			// 	variables: {
			// 		bookDraftId: bookDraftId
			// 	},
			//   }]
		})
		.then(()=>{
			if (callback) {
				callback();
			}
		})
		.catch((e)=>{
			if (errorCallback) {
				errorCallback(e);
			}
		});
		
	}
	return [wrappedMoveDocumentPartBatch, { data, loading, error }]

}


export function useImportBookDraftDocument() {

	const importBookDraftDocumentMutation = gql`
	mutation importBookDraftDocument($importBookDraftDocumentInput: ImportBookDraftDocumentInput!) {
		importBookDraftDocument(input: $importBookDraftDocumentInput) {
			id
		}
	}`;

	const [
		importBookDraftDocument,
		{ data, loading, error }] = useMutation(importBookDraftDocumentMutation, {

			refetchQueries: ['listBooks'],
			// context: {
			// 	//https://codeburst.io/highly-functional-offline-applications-using-apollo-client-12885bd5f335
			// 	serializationKey: 'MUTATION'

			// },
			// onQueryUpdated: (args) => {
			// 	console.log(args);

			// },
			// update(cache, { data: { moveDocumentPartBatch } }) {

			// }
		});

	const wrappedImportBookDraftDocumentPart = ({
		userId,
		s3Key,
		title

	}, callback,errorCallback) => {



		const variables = {
			s3Key,
			title
		}


		importBookDraftDocument({
			variables: { importBookDraftDocumentInput: variables },
			onCompleted: () => {
				if (callback) {
					callback();
				}
			}
		});

	}
	return [wrappedImportBookDraftDocumentPart, { data, loading, error }]

}


export function useLanguageToolEval() {

	const languageToolEvalMutation = gql`
	mutation languageToolEval($languageToolEvalInput: LanguageToolEvalInput!) {
		languageToolEval(input: $languageToolEvalInput) 
	}`;

	const [
		languageToolEval,
		{ data, loading, error }] = useMutation(languageToolEvalMutation, {

			//refetchQueries: ['listBooks'],
			// context: {
			// 	//https://codeburst.io/highly-functional-offline-applications-using-apollo-client-12885bd5f335
			// 	serializationKey: 'MUTATION'

			// },
			// onQueryUpdated: (args) => {
			// 	console.log(args);

			// },
			// update(cache, { data: { moveDocumentPartBatch } }) {

			// }
		});

	const wrappedlanguageToolEval = ({
		sourceText,
		partId

	}, callback,errorCallback) => {



		const variables = {
			sourceText,
			partId
		}


		languageToolEval({
			variables: { languageToolEvalInput: variables }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedlanguageToolEval, { data, loading, error }]

}


export function useGetBuildRequests({ bookDraftId }) {

	const LIST_USER_BUILD_REQUESTS = gql`
query listUserBuildRequests($bookDraftId: ID!) {
  listUserBuildRequests(bookDraftId: $bookDraftId) {
					    items {
					      bookDraftId
					      buildTypes
					      createdAt
					      deletedAt
					      id
					      ownerId
					      updatedAt
					      completedAt
					      buildRequestGeneratedDocs {
					        items {
					          generatedDoc {
					            fileName
					            id
					            epubCheckResults
					            draftId
					            ownerId
					            subType
					            type
					          }
					          generatedDocId
					        }
					      }
					    }
					  }
					}
`;


	const { loading, error, data, refetch, startPolling, stopPolling, client } = useQuery(LIST_USER_BUILD_REQUESTS, {
		variables: {
			bookDraftId: bookDraftId
		},
		//pollInterval: 3000,
		fetchPolicy: 'cache-and-network'
	});

	return { loading, error, data, refetch, client }

}


export function useAcceptGroupInvitation() {



	const mutation = gql`
	mutation acceptGroupInvitationBatch($input : AcceptGroupInvitationBatchInput) {
		  acceptGroupInvitationBatch(input: $input) {
			userId
			userGroupId
			acceptedAt
			invitedAt
			leftAt
		  }
		}`;


	const [
		acceptGroupInvitation,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		userGroupId,
		onCompleted = () => { }

	}, callback,errorCallback) => {

		////console.log(invitationCode);



		const variables = {
			userGroupId
		}





		acceptGroupInvitation({
			variables: { input: variables },
			refetchQueries: [{
				//variables: { bookId },
				query: queries.GET_USER_INVITED_TO_USER_GROUPS
			}],
			onCompleted: onCompleted
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}

export function useLeaveGroup() {



	const mutation = gql`
	mutation leaveGroupBatch($input : LeaveGroupBatchInput) {
		leaveGroupBatch(input: $input) {
			userId
			userGroupId
			acceptedAt
			invitedAt
			leftAt
		  }
		}`;


	const [
		leaveGroup,
		{ data, loading, error }] = useMutation(mutation, {


		});

	const wrappedMutation = ({
		userGroupId,
		onCompleted = () => { }

	}, callback,errorCallback) => {

		////console.log(invitationCode);



		const variables = {
			userGroupId
		}





		leaveGroup({
			variables: { input: variables },
			refetchQueries: [{
				//variables: { bookId },
				query: queries.GET_USER_INVITED_TO_USER_GROUPS
			}],
			onCompleted: onCompleted
			// optimisticResponse: {
			// 	createDocumentPartBatch: newBddp
			// }
		});
		if (callback) {
			callback();
		}
	}
	return [wrappedMutation, { data, loading, error }]

}

export function useGetStripeBillingPortal() {


	let QUERY = gql`query getBillingPortal {
		getBillingPortal 
	  }`;



const [lazyGetStripeBillingPortal, { loading, error, data }] = useLazyQuery(QUERY, {
	//const { loading, error, data, refetch } = useQuery(QUERY, {
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			//console.log(data)
		},
		onError: (error) => {
			//console.log(error)
		}
	});

	return { lazyGetStripeBillingPortal,loading, error, data }


}

export function useGetStripePurchaseLink() {



	let QUERY = gql`query getPurchaseLink($productId: String!, $planId: String!) {
		getPurchaseLink(productId: $productId, planId: $planId) 
	  }`;



const [lazyGetPurchaseLink, { loading, error, data }] = useLazyQuery(QUERY, {
	//const { loading, error, data, refetch } = useQuery(QUERY, {
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			//console.log(data)
		},
		onError: (error) => {
			//console.log(error)
		}
	});

	return { lazyGetPurchaseLink,loading, error, data }


}


