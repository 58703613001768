import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import OwnedBoxsetContributors from '../panels/OwnedBoxsetContributors';
import OwnedBoxsetBooks from '../panels/OwnedBoxsetBooks';
import useMediaQuery from '@mui/material/useMediaQuery';
import BoxsetsTable from './BoxsetsTable';
import StretchBox from './StretchBox';
import { Box, Button, SwipeableDrawer } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import ScrollBox from './ScrollBox';
import OwnedBoxsetsList from '../lists/OwnedBoxsetsList';
import { useGetUserConnections } from '../../managers/ConnectionsManager';
import StandardFloatingPlus from '../StandardFloatingPlus';
import CreateBoxsetDialog from '../panels/CreateBoxsetDialog';
import ExportsPanel from '../export/ExportsPanel';
import ConnectionsList from '../lists/ConnectionsList';
import ConnectionSharedToPanel from '../panels/ConnectionSharedToPanel';
import ConnectionSharedFromPanel from '../panels/ConnectionSharedFromPanel';
import ConnectionSharedFromList from '../lists/ConnectionSharedFromList';
import SharedGroupsList from '../lists/SharedGroupsList';

const SharedGroupsTabContent = (props) => {


    const [selectedBoxset, setSelectedBoxset] = useState(false);
    const [selectedUserConnection, setSelectedUserConnection] = useState(false);

    const [showDetailDrawer, setShowDetailDrawer] = useState(false);
    const [showCreateBoxsetDialog, setShowCreateBoxsetDialog] = useState(false);

    const { userConnections, error: getUserConnectionsError } = useGetUserConnections(props);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {


        if (userConnections) {
            setSelectedUserConnection(userConnections[0])
        }

    }, [userConnections]);


    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setShowDetailDrawer(open);
            };

    return (

        <StretchBox flexDirection='row' sxProps={{ gap: isSmallScreen ? 0 : 2 }}>

            <StretchBox sxProps={{
                flex: isSmallScreen ? 12 : 4,
                border: '1px solid gray',
                borderRadius: '1em',
                position: 'relative'
            }}>
                {/* <BoxsetsTable onSelectBoxSet={
                    (boxset) => {
                        setSelectedBoxset(boxset)
                        setShowDetailDrawer(true)
                    }} /> */}

                <ScrollBox>
                    <SharedGroupsList
                        filteredBooks={[]}
                        showSelectIcon
                        onSelected={(userGroupUser) => {

                            if (userGroupUser) {

                                //console.log(userGroupUser)
                                //props.stores.bookStore.sharedGroupListGroup = userGroupUser.userGroup;
                                //navigate("/sharedGroups/details");

                            }

                        }}
                    />
                </ScrollBox>
                <StandardFloatingPlus onClick={(e) => {
                    e.preventDefault();
                    setShowCreateBoxsetDialog(true);
                }} />

            </StretchBox>

            <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>


                    {selectedUserConnection &&
                        <Box sx={{ border: '1px solid gray', borderRadius: '1em' }}>
                            <ConnectionSharedToPanel userConnection={selectedUserConnection} />
                        </Box>
                    }


                    {selectedUserConnection &&
                        <Box sx={{ border: '1px solid gray', borderRadius: '1em' }}>
                            <ConnectionSharedFromList userConnection={selectedUserConnection} />
                        </Box>
                    }



                </ScrollBox>
            </StretchBox>

            <CreateBoxsetDialog
                open={showCreateBoxsetDialog}
                onClose={(boxset) => {
                    setShowCreateBoxsetDialog(false);

                }}

                onBookCreated={(boxset) => {
                    setShowCreateBoxsetDialog(false);
                    setSelectedBoxset(boxset);


                }}


            />

            <SwipeableDrawer
                anchor={'right'}
                open={showDetailDrawer && isSmallScreen}
                onClose={toggleDrawer(false)}
                sx={{ width: '100%' }}
            //variant='persistent'
            //onOpen={setShowDetailDrawer(true)}
            >
                <Button variant='contained' onClick={(e) => {
                    e.preventDefault();
                    setShowDetailDrawer(false);
                }}>Close</Button>
                <div style={{ width: '100vw' }}>

                    <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                        <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>
                            {selectedBoxset &&
                                <div> {selectedBoxset.book.title} </div>
                            }
                            {selectedBoxset &&
                                <Box sx={{ border: '1px solid gray', borderRadius: '1em' }}>
                                    <OwnedBoxsetContributors boxset={selectedBoxset} />
                                </Box>
                            }
                            {selectedBoxset &&
                                <Box sx={{ border: '1px solid gray', borderRadius: '1em' }}>
                                    <OwnedBoxsetBooks boxset={selectedBoxset} />
                                </Box>
                            }


                        </ScrollBox>
                    </StretchBox>
                </div>
            </SwipeableDrawer>


        </StretchBox>
    )
}
export default inject('stores')(observer(SharedGroupsTabContent));