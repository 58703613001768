import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import StandardDataTable from './StandardDataTable';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import ReactTimeAgo from 'react-time-ago';
import Feather from 'mdi-material-ui/Feather';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import SnapshotsTable from './SnapshotsTable';
import BookEditsTable from './BookEditsTable';
import { useGetSharedBooks, useOpenBookInWrite, useOpenBookInRead, useOpenBookInFormat, useManagerCreateBook } from '../../managers/BooksManager';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import StandardImageViewer from '../StandardImageViewer';
import config from '../../aws-exports';
import ImageTitleTableCell from './ImageTitleTableCell';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CommentIcon from '@mui/icons-material/Comment';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AvatarTableCell from './AvatarTableCell';
import { useMediaQuery } from 'react-responsive';
import StandardRoundedAction from '../actions/StandardRoundedAction';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TablePopupMenu from '../menus/TablePopupMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import SharedBooksList from '../lists/SharedBooksList';
import StandardSkeleton from '../../StandardSkeleton';

const SharedBooksTable = (props) => {

    let { book } = props;

    const { sharedBooks, loading, error: getSharedWithBooksError, loading: getSharedWithBooksLoading } = useGetSharedBooks(props);
    const { openBookInWrite } = useOpenBookInWrite(props);
    const { openBookInFormat } = useOpenBookInFormat(props);
    const { openBookInRead } = useOpenBookInRead(props);



    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');

    let [showCreateBookDialog, setShowCreateBookDialog] = useState(false);
    let [showDeleteBookDialog, setShowDeleteBookDialog] = useState(false);
    let [selectedBook, setSelectedBook] = useState(null);

    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    const isRoute = () => {
        return 'primary';
    }


    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        //console.log(sharedBooks);
        setOrder("desc");
        setOrderBy("createdAt");

    }, [sharedBooks]);




    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            {!isXs &&
                <StandardDataTable title={'Shared Books'}
                    noDataMessage={'Books shared with you will appear here!'}
                    order={order}
                    onSetOrder={setOrder}
                    orderBy={orderBy}
                    onSetOrderBy={setOrderBy}
                    loading={loading}

                    structure={{
                        dataDef: {
                            data: sharedBooks,
                            rowKeyDataFieldName: 'id'
                        },
                        columns: [
                            {
                                title: 'Title',
                                sortable: true,
                                sortFieldNameOverride:'title',
                                dataValueFunction: (book) => {
                                    return <ImageTitleTableCell
                                        bucketName={config.user_uploads_bucket_name}
                                        title={book?.title}
                                        s3Src={book?.coverUrl}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            //console.log(book)
                                            props.stores.bookStore.bookListBook = book;
                                            navigate("/sharedBooks/details");
                                            // openBookInWrite({
                                            //   bookDraftId: book.primaryBookDraftId
                                            // });
                                        }}
                                    />
                                }
                            },
                            {
                                title: 'Author',
                                dataValueFunction: (book) => {
                                    return <div style={{ cursor: 'pointer' }}>
                                        <AvatarTableCell
                                            bucketName={config.user_uploads_bucket_name}
                                            s3Src={book?.owner?.profilePicUrl}
                                            title={book?.owner?.name}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                //console.log(book)
                                                props.stores.bookStore.bookListBook = book;
                                                navigate("/sharedBooks/details");
                                                // openBookInWrite({
                                                //   bookDraftId: book.primaryBookDraftId
                                                // });
                                            }}
                                        />
                                    </div>
                                },
                                ignore: isXs
                            },

                            {
                                title: 'Read',
                                dataValueFunction: (book) => {
                                    return <StandardRoundedAction
                                        icon={<LocalLibraryIcon sx={{
                                            color: theme.palette.standardActions.icon.color.main,

                                        }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                //console.log(book)
                                                openBookInRead({
                                                    bookDraftId: book.primaryBookDraftId
                                                });
                                            }} />} />
                                }
                            },
                            // {
                            //     title: 'Boxsets',
                            //     dataValueFunction: (book) => {
                            //         return <IconButton aria-label="delete">
                            //             <ContentCopyIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                            //                 e.preventDefault();
                            //                 console.log(book)
                            //                 props.stores.bookStore.bookListBook = book;
                            //                 navigate("/sharedBooks/details/boxsets");
                            //                 // openBookInWrite({
                            //                 //   bookDraftId: book.primaryBookDraftId
                            //                 // });
                            //             }} />
                            //         </IconButton>
                            //     },
                            //     ignore: isXs
                            // },
                            // {
                            //     title: 'Feedback',
                            //     dataValueFunction: (book) => {
                            //         return <IconButton aria-label="delete">
                            //             <CommentIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                            //                 e.preventDefault();
                            //                 console.log(book)
                            //                 props.stores.bookStore.bookListBook = book;
                            //                 navigate("/sharedBooks/details/feedback");
                            //                 // openBookInWrite({
                            //                 //   bookDraftId: book.primaryBookDraftId
                            //                 // });
                            //             }} />
                            //         </IconButton>
                            //     },
                            //     ignore: isXs
                            // },

                            {
                                title: 'Shared',
                                dataFieldName: 'sharedAt',
                                sortable: true,
                                dataValueFunction: (book) => { return book.sharedAt != null ? <ReactTimeAgo date={book.sharedAt} locale="en-US" /> : '' },
                                ignore: isXs
                            },
                            // {
                            //     title: '',
                            //     dataValueFunction: (book) => {
                            //         return <TablePopupMenu menuItems={[
                            // <ListItemButton onClick={(e) => {
                            //     e.preventDefault();
                            //     console.log(book)
                            //     props.stores.bookStore.bookListBook = book;
                            //     navigate("/sharedBooks/details");
                            //     // openBookInWrite({
                            //     //   bookDraftId: book.primaryBookDraftId
                            //     // });
                            // }} >

                            //     <ListItemIcon>
                            //         <SettingsIcon sx={{ color: theme.palette.background.main }} />
                            //     </ListItemIcon>
                            //     <ListItemText primary="Details" />
                            // </ListItemButton>,
                            // <ListItemButton sx={{

                            // }}
                            //     onClick={(e) => {
                            //         e.preventDefault();
                            //         console.log(book)
                            //         props.stores.bookStore.bookListBook = book;
                            //         navigate("/sharedBooks/details/boxsets");

                            //     }}>
                            //     <ListItemIcon>
                            //         <ContentCopyIcon sx={{ color: theme.palette.background.main }} />
                            //     </ListItemIcon>
                            //     <ListItemText primary="Boxsets" />
                            // </ListItemButton>,

                            // <ListItemButton sx={{

                            // }}
                            //     onClick={(e) => {
                            //         console.log(book)
                            //         props.stores.bookStore.bookListBook = book;
                            //         navigate("/sharedBooks/details/feedback");

                            //     }}>
                            //     <ListItemIcon>
                            //         <CommentIcon sx={{ color: theme.palette.background.main }} />
                            //     </ListItemIcon>
                            //     <ListItemText primary="Feedback" />
                            // </ListItemButton>,

                            // <ListItemButton sx={{

                            // }}
                            //     onClick={(e) => {
                            //         e.preventDefault();
                            //         console.log(book);
                            //         setSelectedBook(book);
                            //         setShowDeleteBookDialog(true);

                            //     }} >
                            //     <ListItemIcon>
                            //         <TrashCanOutline sx={{ color: theme.palette.background.main }} />
                            //     </ListItemIcon>
                            //     <ListItemText primary="Delete" />
                            // </ListItemButton>,



                            // <IconButton aria-label="delete">
                            //     <TrashCanOutline sx={{ color: theme.palette.background.main }} onClick={(e) => {
                            //         e.preventDefault();
                            //         console.log(book);
                            //         setSelectedBook(book);
                            //         setShowDeleteBookDialog(true);

                            //     }} />
                            // </IconButton>



                            //     ]} />
                            // },
                            // ignore: !isXs
                            // },







                        ]
                    }} />
            }
            {isXs &&
                <SharedBooksList
                    filteredBooks={[]}
                    showSelectIcon
                    onSelected={(book) => {

                        if (book) {

                            //console.log(book)
                            props.stores.bookStore.bookListBook = book;
                            navigate("/sharedBooks/details");

                        }

                    }}
                />
            }

            {/* <Button variant='contained' onClick={(e) => {
                e.preventDefault();

                setShowCreateSnapshotDialog(TrashCanOutline);

            }}>Create Snapshot</Button>
            <CreateSnapshotDialog
                open={showCreateSnapshotDialog}
                bookDraftId={book?.primaryBookDraftId}
                onClose={() => { setShowCreateSnapshotDialog(false) }}
            /> */}
            {(loading && (!sharedBooks || sharedBooks.length == 0)) &&
                <StandardSkeleton />
            }
        </Box>

    )
}
export default inject('stores')(observer(SharedBooksTable));