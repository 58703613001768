import React, { useState, useEffect } from "react";
import { Avatar, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import StandardTabs from "../StandardTabs";
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton, { StandardListButton } from "../StandardButton";
import UserConnectionsList from "../lists/UserConnectionsList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../managers/BooksManager';
import UserGroupsList from "../lists/UserGroupsList";
import {
  useManagerAddBoxsetContributor,
  useManagerAddBoxsetContributedBook,
  useManagerLoadBoxSetContributors,
  useManagerRemoveBoxsetContributor
} from '../../managers/BoxsetsManager';
import { User } from "../../domain/bookModel";
import OwnedBooksList from "../lists/OwnedBooksList";
import StandardDetailTitle from "../StandardDetailTitle";


const RemoveBoxsetContributorPanel = (props) => {

  let user = props.stores.userStore.user;
  let { boxset, contributor } = props;

  let [boxsetBooks, setBoxsetBooks] = useState([]);

  const { addBoxSetContributedBook, loading: addBoxSetContributedBookLoading, error: addBoxSetContributedBookError } = useManagerAddBoxsetContributedBook(props);
  const { addBoxsetContributor, loading: createBoxSetContributorLoading, error: createBoxSetContributorError } = useManagerAddBoxsetContributor(props);
  const { removeBoxSetContributor, loading: removeBoxSetContributorLoading, error: removeBoxSetContributorError } = useManagerRemoveBoxsetContributor(props);



  let {
    boxsetContributors,
    loading: lazyLoadBoxSetContributorsLoading,
    error: lazyLoadBoxSetContributorsError
  } = useManagerLoadBoxSetContributors(props, boxset?.id);


  useEffect(() => {

    try {

      let bookData = [];
      boxsetContributors.forEach((boxsetContributor) => {

        boxsetContributor.contributedBooks.items.forEach((bsb) => {

          bookData.push({
            boxsetContributor,
            boxsetBook: bsb
          })
        })

      });

      setBoxsetBooks(bookData);

    } catch (err) {
      console.log(err);
    }
  }, [boxsetContributors]);



  let profilePicSrc = 'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + contributor?.profilePicUrl;



  return (
    <Grid container direction={'column'}>
      <Grid item>
        <Grid container direction={'column'}>

          <Grid item >
            <StandardDetailTitle>{contributor?.name}</StandardDetailTitle>
          </Grid>

          <Grid item >
            <Grid container >
              <Grid item >
                <Avatar
                  sx={{
                    marginLeft: '0.5em',
                    marginRight: '0.5em',
                    width: '3em',
                    height: '3em'
                  }}
                  alt=''
                  src={profilePicSrc} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>

        {removeBoxSetContributorLoading &&
          <div>removing...</div>
        }
        {!removeBoxSetContributorLoading &&

          <StandardListButton onClick={() => {
            removeBoxSetContributor({
              contributorId: contributor.id,
              boxsetId: boxset.id,
            },()=>{
              if(props.onRemoved){
                props.onRemoved()
              }
            })
          }}>Remove</StandardListButton>
        }

      </Grid>
    </Grid>
  )
}

export default inject('stores')(observer(RemoveBoxsetContributorPanel));