import { styled } from "@mui/material";
import { Button } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const StyledButton = styled(Button)(()=>{


})

export const StandardRoundedButton = styled(StyledButton)(()=>({

    borderRadius: "8px"
    
}));

export const StandardButton = (props) => {

    const theme = useTheme();

    return(

        <StyledButton size={'small'} {...props} variant={'outlined'}>

        </StyledButton>
    )
}

export const StandardListButton = styled(StyledButton)(({theme})=>({

            //width: '75%',
            margin: '1em 1em 1em 1em',
            //height: '4em',
            borderRadius: "1em",
            color: theme.palette.write.controls.color,
            borderColor: theme.palette.write.controls.color
    
}));




export default StandardButton;