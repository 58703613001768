import React, { useState, useEffect } from 'react';
import { Typography, styled, Grid } from "@mui/material";
import { Tooltip, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export const StyledTypography = styled(Typography)(() => ({

    //backgroundColor:'red'
    'font-variant': 'small-caps'


}))

export default function StandardDetailDescription(props) {


    const theme = useTheme();


    return (

        <Grid item >
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '1em'
            }}>

                <StyledTypography color={theme.palette.listPage?.description.color.main} variant={theme.palette.listPage?.description.variant} {...props}  >{props.children}</StyledTypography>
                <HelpOutlineIcon color={'disabled'}/>
                
            </Box>
        </Grid>




    );
}
