import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import StandardButton from '../StandardButton';
import StandardDialog from '../StandardDialog';
import CreateSnapshotPanel from '../panels/CreateSnapshotPanel';
import AddConnectionSharePanel from '../panels/AddConnectionSharePanel';
import StandardOkCancelDialog from '../panels/StandardOkCancelDialog';
import AddGroupSharePanel from '../panels/AddGroupSharePanel';
import RoutesHelper from '../../helpers/RoutesHelper';

const BookShareAssembly = (props) => {

    let [showShareBookDialog, setShowShareBookDialog] = useState(false);
    let navigate = useNavigate();
    let location = useLocation();

    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    const theme = useTheme();

    return (
        <>
            <Box sx={{
                display: 'flex',
                //justifyContent: 'flex-end',
                //width: '100%',
                //border:'1px solid yellow'
            }}>
                <Button variant="contained" sx={{
                    color: '#ffffff',
                    backgroundColor: theme.palette.secondary.main
                }}
                    onClick={(e) => {
                        e.preventDefault();
                        setShowShareBookDialog(true);
                    }}>Share</Button>
                {/* <StandardButton variant="contained" sx={{
                    color: '#ffffff',
                    backgroundColor: theme.palette.secondary.main
                }}
                >Create Snapshot</StandardButton> */}

            </Box>

            {RoutesHelper.isRoute('/connections/details/sharedTo', location.pathname) &&
                <StandardOkCancelDialog
                    open={showShareBookDialog}
                    onClose={() => {
                        setShowShareBookDialog(false)
                    }}
                    onOk={() => {
                        setShowShareBookDialog(false)

                    }}
                    title={'Share Bookx'}
                    content={
                        <AddConnectionSharePanel userConnection={props.userConnection} onShareCreated={() => {
                            setShowShareBookDialog(false)
                        }} />
                        // <AddGroupSharePanel userGroup={userGroup} onGroupShareCreated={()=>{
                        //     setShowShareBookDialog(false)
                        // }}/>
                    }
                />
            }

            {RoutesHelper.isRoute('/myGroups/details/shared', location.pathname) &&
                <StandardOkCancelDialog
                open={showShareBookDialog}
                onClose={() => {
                    setShowShareBookDialog(false)
                }}
                onOk={() => {
                    setShowShareBookDialog(false)

                }}
                title={'Share Book'}
                content={
                    <AddGroupSharePanel userGroup={props.userGroup} onGroupShareCreated={() => {
                        setShowShareBookDialog(false)
                    }} />
                }
            />
            }
        </>


    )
}

export default inject('stores')(observer(BookShareAssembly));