import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
// import StandardLabel from '../StandardLabel';
// import StandardTextField from '../formFields/StandardTextField';
import StandardButton from '../StandardButton';
import { DocumentPart } from '../../domain/bookModel';

const ApplyRecipeDialog = (props) => {

  let userId = props.stores.userStore.user.id;

  //const [open, setOpen] = React.useState(false);
  const { open,
    onClose,
    cancelLabel,
    confirmLabel,
    forceConfirmation,
    confirmationText,
    useValidation,
    documentPart } = props;

  const [userConfirmText, setUserConfirmText] = React.useState('');

  const [fieldsValid, setFieldsValid] = React.useState(false);


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  let boxSetBook = null;
  let targetRecipeText = '';
  let targetItemText = '';
  let isBoxSet = false;
  let isBookOwner = false;
  let isBoxSetOwner = false;
  let isAuthorized = false;

  try{
    if(props.documentPart.bookDraftDocumentPart.bookDraft.book.boxSet!=null){

      isBoxSet = true;

    }
  }catch(err){

  }

  try{
   
    if(userId==props.documentPart.bookDraftDocumentPart.bookDraft.book.boxSet.book.owner.id){

      isBoxSetOwner = true;

    }
  }catch(err){

  }


  try{

    boxSetBook = props.documentPart?.getBoxSetBook();

    targetItemText = 'You have selected a page from the contributed book '+boxSetBook.book.title;
    if(userId==boxSetBook.book.owner.id){

      isBookOwner = true;

    }

    if(userId==boxSetBook.book.bookDraft.book.boxSet.book.owner.id){

      isBoxSetOwner = true;

    }

    switch(boxSetBook.recipeSource){

      case 'boxSetRecipe':

        targetRecipeText = 'You are attempting to modify the style for the boxset';

      break;

      case 'overrideBookRecipe':

        targetRecipeText = 'You are attempting to modify the style applied to this book within this boxset';

      break;

      case 'originalBookRecipe':

        targetRecipeText = 'You are attempting to modify the original style for this book.';
        if(isBookOwner){
          isAuthorized = true;
        }

      break;


    }
  }catch(err){
    console.log(err);
  }
  
  
  return (
    <div stylex={{
      minWidth: '25%',
      minHeight:'25%'
    }}>
      <Dialog
        //https://medium.com/the-clever-dev/how-to-size-and-position-the-material-ui-mui-dialog-component-a5601cedc1c9
        PaperProps={{ sx: { minWidth: '25%', height: "50%" } }}
        open={open}
        onClose={onClose}
        //fullScreen={fullScreen}
        //fullWidth={fullScreen}
        //maxWidth={'90%'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent sx={{
          position: 'relative',
          overflow: 'hidden'
          //height:'100%'
        }}>
          {/* {forceConfirmation &&
            <div>
              <StandardLabel title={props.deleteMessage} />
              <StandardTextField
                label={'Confirm Delete'}
                placeholder={props.confirmationText}
                value={userConfirmText}
                onChange={(e) => {
                  setUserConfirmText(e.target.value);
                
                  if (e.target.value == props.confirmationText){
                    setFieldsValid(true);
                  } else {
                    setFieldsValid(false);
                  }
                  // confirmDelete();
                  
                  
                  
                }}
              //disabled={false}
              />
            </div>
          } */}
          {props.content}
          <div>
            {documentPart.title}
          </div>
          <div>
            {boxSetBook && 
              <div>
                <div>This page is part of a contributed book.</div>
                <div>Recipe Source:{boxSetBook.recipeSource}</div>
              </div>
            }
            {

              <div>{targetRecipeText}</div>
              
            }
            {isBookOwner &&
              <div>You are the owner of this book</div>
            }
            {!isBookOwner &&
              <div>You are not the owner of this book</div>
            }
            {isBoxSetOwner &&
              <div>You are the owner of this boxSet</div>
            }
            {!isBoxSetOwner &&
              <div>You are not the owner of this boxSet</div>
            }


          </div>

          
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => { onClose() }}>Cancel</Button>
            <Button 
              disabled={{forceConfirmation}}
              onClick={()=>{props.onOK()}} 
              autoFocus>Ok</Button> */}
          <StandardButton onClick={() => { onClose() }} buttonAction='Cancel' />
          <StandardButton
            disabled={(useValidation && !fieldsValid) || (forceConfirmation && !userConfirmText)}
            onClick={() => { props.onOK() }}
            autoFocus
            buttonAction='Okay' />
        </DialogActions>
      </Dialog>
    </div>
  );
};


export default inject('stores')(observer(ApplyRecipeDialog));
