import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";

const UserGroupsList = (props) => {

  let user = props.stores.userStore.user;

  let { filteredUserGroups } = props;
  //const { userConnections, error: getUserConnectionsError } = useGetUserConnections(props);
  const { userGroups, error: getUserUserGroupsError, loading: getUserUserGroupsLoading } = useGetUserGroups(props);


  let remainingUserGroups = userGroups;

  if (filteredUserGroups) {

    remainingUserGroups = userGroups.filter(userGroup => !filteredUserGroups.find(rm => (rm.userGroup.id === userGroup.id) ))

  }





  const createSecondaryActions = (userGroup) => {

    let secondaryActions = [];

    if (props.showSelectIcon) {
      secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
        //console.log(userGroup)
        props.onSelected(userGroup);
      }}>
        <ShareIcon />
      </IconButton>);
    }

    return secondaryActions;
  }

  return (
    <List component="div" disablePadding>
      {
        remainingUserGroups.map((userGroup) => {

          return <ListItem secondaryAction={createSecondaryActions(userGroup)}>
            <ListItemAvatar>
              <AvatarTableCell
                bucketName={config.user_uploads_bucket_name}
                s3Src={userGroup?.profilePicUrl}
              //title={userConnection?.connectedUser?.name}
              />
            </ListItemAvatar>
            <ListItemText primary={userGroup?.name} secondary="" />

          </ListItem>
        })
      }
    </List>

  )
}

export default inject('stores')(observer(UserGroupsList));