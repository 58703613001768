import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import FeedbackPanel from '../../panels/FeedbackPanel';
import NotesPanel from '../../panels/NotesPanel';

const WriteDetailsNotes = (props) => {

    const theme = useTheme();

    let { bookDraftDocumentPart,bookDraft} = props;


    let userId = props.stores?.bookStore.userId;

 


    return (    
        <Box container direction={'column'} sx={{
            justifyContent: 'center',
            //marginRight:'1em'
        }}>
          <NotesPanel book={bookDraft?.book} bookDraftDocumentPart={bookDraftDocumentPart} ownerMode={true}/>
           
        </Box>
    );
};


export default inject('stores')(observer(WriteDetailsNotes));
