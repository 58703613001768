import React, { useState, useEffect } from "react";
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography, AvatarGroup } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetOwnedBooks, useOpenBookInWrite, useOpenBookInFormat, useManagerCreateBook } from '../../managers/BooksManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import SquareAvatar from "./SquareAvatar";
import DeleteIcon from '@mui/icons-material/Delete';
import StandardRoundedAction from "../actions/StandardRoundedAction";
import Feather from "mdi-material-ui/Feather";
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import TablePopupMenu from "../menus/TablePopupMenu";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CommentIcon from '@mui/icons-material/Comment';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { useGetOwnedBoxsets } from '../../managers/BoxsetsManager';
import EmptyContent from "../EmptyContent";
import helpers from '../../helpers/helpers';
import ReactTimeAgo from 'react-time-ago';
import StandardList from "./StandardList";
import imageNotFound from '../../assets/images/image-not-found.png'
import StandardFloatingPlus from "../StandardFloatingPlus";

let missingBookImage = require('../../assets/images/image-not-found.png')

const OwnedBoxsetsList = (props) => {

  let { book, boxsets: ownedBoxsets, loading, selectedBoxset } = props;
  //const { ownedBoxsets, loading, error } = useGetOwnedBoxsets(props);
  const { openBookInWrite } = useOpenBookInWrite(props);
  const { openBookInFormat } = useOpenBookInFormat(props);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');

  let [showCreateBoxsetDialog, setShowCreateBoxsetDialog] = useState(false);
  let [showDeleteBoxsetDialog, setShowDeleteBoxsetDialog] = useState(false);
  //let [selectedBoxset, setSelectedBoxset] = useState(null);

  const isRoute = () => {
    return 'primary';
  }


  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();

  // useEffect(() => {


  //   setOrder("desc");
  //   setOrderBy("createdAt");

  // }, [ownedBoxsets]);


  const getBoxsetContributedBooks = (boxset) => {
    let allContributedBoxSetBooks = {};
    boxset.boxsetContributors?.map((boxsetContributor) => {

      boxsetContributor.contributedBoxsetBooks.map((bsb) => {
        allContributedBoxSetBooks[bsb.book.id] = bsb;
      });

    });

    return Object.values(allContributedBoxSetBooks);
  }




  const createSecondaryActions = (boxset) => {

    let secondaryActions = [];

    if (props.showSelectIcon) {
      secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
        //console.log(boxset)
        props.onSelected(boxset);
      }}>
        <ShareIcon />
      </IconButton>);
    }

    return secondaryActions;
  }


  // ownedBoxsets = helpers.stableSort(ownedBoxsets, props.stores.bookStore.sortOrder, props.stores.bookStore.sortOrderBy==='alpha'?'title':props.stores.bookStore.sortOrderBy);


  let sortedBoxsets = [];
  if (ownedBoxsets != null) {

    sortedBoxsets = ownedBoxsets.map((boxset) => {

      let compositeOwnedBoxset = {
        object: boxset,
        title: boxset.book.title,
        createdAt: boxset.createdAt

      }


      return compositeOwnedBoxset;
    });
    sortedBoxsets = helpers.stableSort(sortedBoxsets, props.stores.bookStore.sortOrder, props.stores.bookStore.sortOrderBy === 'alpha' ? 'title' : props.stores.bookStore.sortOrderBy);

  }



  return (
    <StandardList
      // title={'My Books'}
      hideHeading={true}
      // headerActions={<CreateExportsAssembly book={book} />}
      component="div" disablePadding>
      {ownedBoxsets && ownedBoxsets.length > 0 ?
        sortedBoxsets.map((compositeOwnedBoxset) => {

          let boxset = compositeOwnedBoxset.object;


          let allContributedBoxSetBooks = {};
          boxset.boxsetContributors?.map((boxsetContributor) => {

            boxsetContributor.contributedBoxsetBooks.map((bsb) => {
              allContributedBoxSetBooks[bsb.book.id] = bsb;
            });

          });

          allContributedBoxSetBooks = Object.values(allContributedBoxSetBooks);

          let isSelected = false;
          if (selectedBoxset && selectedBoxset.id == compositeOwnedBoxset.object.id) {
            isSelected = true;
          }


          return <>

            <ListItem
              sx={{
                backgroundColor: isSelected ? 'darkgray' : 'inherit'
              }}
              alignItems="flex-start"

              secondaryAction={

                <Box sx={{
                  display: 'flex'
                }}>
                  <StandardRoundedAction icon={<Feather sx={{
                    color: theme.palette.standardActions.icon.color.main,

                  }} onClick={(e) => {
                    e.preventDefault();
                    //console.log(boxset)
                    //navigate("/write");
                    openBookInWrite({
                      bookDraftId: boxset.book.primaryBookDraftId
                    });
                  }} />} />


                  <StandardRoundedAction icon={<FormatPaintIcon sx={{
                    color: theme.palette.standardActions.icon.color.main,

                  }} onClick={(e) => {
                    e.preventDefault();
                    //navigate("/format");
                    openBookInFormat({
                      bookDraftId: boxset.book.primaryBookDraftId
                    });
                  }} />} />


                </Box>







              }>
              <ListItemAvatar
                onClick={(e) => {
                  e.preventDefault();
                  props.onSelected(boxset);
                }}>
                <SquareAvatar
                  //bucketName={config.user_uploads_bucket_name}
                  s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + boxset.book?.coverUrl}
                  missingContent={<img src={missingBookImage} />}
                />
                {/* <Avatar alt="Remy Sharp" variant="square" src="https://local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm.s3.amazonaws.com/uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/c7cb5750-3627-11ed-914f-5d91b6c4c7eb_planetStrider.png">

              </Avatar> */}
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  noWrap: true
                }}
                onClick={(e) => {
                  e.preventDefault();
                  props.onSelected(boxset);
                }}
                primary={boxset.book.title}
                secondary={
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left'
                  }}>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      The Book's Subtitle
                    </Typography>

                    {/* <Box sx={{
                      display: 'flex'
                    }}>


                      <AvatarGroup
                        max={3}
                        sx={{
                          display:'flex',
                          width: '1em',
                          height: '1em',

                        }} >
                      {boxset.boxsetContributors?.map((boxsetContributor) => (
                        <Avatar sx={{
                          width: '1em', height: '1em'
                        }} alt="" src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + boxsetContributor.contributor.profilePicUrl} />
                      ))}
                      </AvatarGroup>
                    </Box> */}



                    {/* <Box sx={{
                      display: 'flex'
                    }}>


                      <AvatarGroup
                        max={3}
                        sx={{
                          display:'flex',
                          width: '1em',
                          height: '1em',

                        }} >
                      {allContributedBoxSetBooks?.map((contributedBook) => (
                        <Avatar sx={{ width: '1.5em', height: '1.5em' }} variant="square" alt="" src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + contributedBook.book.coverUrl?.split('&&&')[0]} >
                          <img style={{ width: '1.5em', height: '1.5em' }} src={imageNotFound} />
                        </Avatar>
                      ))}
                      </AvatarGroup>
                    </Box> */}

                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="caption"
                      color="text.secondary"
                    >
                      {boxset.createdAt != null ? <ReactTimeAgo date={boxset.createdAt} locale="en-US" /> : ''}
                    </Typography>
                    {/* {" — I'll be in your neighborhood doing errands this…"} */}
                  </Box>
                }

              />

            </ListItem>
            <Divider variant="inset" />
          </>
          // <Divider variant="inset"/>


          // return <ListItem secondaryAction={createSecondaryActions(book)}>
          //   <ListItemAvatar>
          //     <AvatarTableCell
          //       bucketName={config.user_uploads_bucket_name}
          //       s3Src={book?.coverUrl}
          //     //title={userConnection?.connectedUser?.name}
          //     />
          //   </ListItemAvatar>
          //   <ListItemText primary={book?.title} secondary="" />

          // </ListItem>
        })
        :
        <Box sx={{
          marginTop: '3em'
        }}>
          <EmptyContent tagline={'Create your first boxset!'} />
        </Box>

      }
      {/* {ownedBooks.length}:
      {availableBooks.length} */}

     

    </StandardList>

  )
}

export default inject('stores')(observer(OwnedBoxsetsList));