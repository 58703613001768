import Quill from 'quill';
//import Parchment from 'parchment';
import DeltaToDom from './DeltaToDom';
import { v4 as uuid } from 'uuid';
const Parchment = Quill.import('parchment');
var $ = require("jquery");

let BlockEmbed = Quill.import('blots/block/embed');


function createContent(node, args) {

  let converter = new DeltaToDom();
  /*let html = converter.convertBlurb(args);
  node.innerHTML = html;*/

  let domNode = converter.convertContributors(args, node);
  node.appendChild(domNode);

  /*let addBlurbButton = document.getElementById('addBlurbBtn');
  addBlurbButton.addEventListener('click', function(ev) {

    ////console.log(this);

  })*/

}

export default class ContributorsBlot extends BlockEmbed {
  static create(args) {
    let node = super.create();
    node.dataset.uuid = args.uuid;
    node.dataset.contributors = args.contributors;
    ////console.log(node);
    // Allow twitter library to modify our contents
    //twttr.widgets.createTweet(id, node);
    createContent(node, args);
    return node;
  }

  static value(domNode) {
    let value = {};
    value.uuid = domNode.dataset.uuid;
    value.contributors = domNode.dataset.contributors;//'{contributors:[{name:"bob"}]}'
    return value;
  }

  getContributors() {


    let contributors = JSON.parse(this.domNode.dataset.contributors);


    return contributors.records;

  }

  deleteContributor(id) {



    //console.log(id);

    let contributors = JSON.parse(this.domNode.dataset.contributors);

    let contributorsToKeep = contributors.records.filter(f => f.item.id != id);

    contributors.records = contributorsToKeep;

    let count = 0;
    contributors.records.forEach((contributor) => {
      count++;
      contributor.item.orderNumber = count;

    });


    let contributorsString = JSON.stringify(contributors);
    ////console.log(contributorsString);
    this.domNode.dataset.contributors = contributorsString;
    ////console.log('end')

    return contributors;

  }

  addContributor() {

    ////console.log(this.domNode.dataset.contributors);

    let contributors = JSON.parse(this.domNode.dataset.contributors);

    let newContributor = {
      item: {
        id: uuid(),
        orderNumber: contributors.records.length + 1,
        name: 'Contributor Name',
        role: 'Contributor Role'
      }
    };

    contributors.records.push(newContributor)


    let contributorsString = JSON.stringify(contributors);
    ////console.log(contributorsString);
    this.domNode.dataset.contributors = contributorsString;
    ////console.log('end')

    return newContributor;

  }

  updateContributor(contributorJson) {
    ////console.log(contributorJson);
    ////console.log(this.domNode.dataset.contributors);

    let contributors = JSON.parse(this.domNode.dataset.contributors);

    let contributor = contributors.records.filter(f => f.item.id == contributorJson.id)[0];
    contributor.item.name = contributorJson.name;
    contributor.item.role = contributorJson.role;

    let contributorsString = JSON.stringify(contributors);
    ////console.log(contributorsString);
    this.domNode.dataset.contributors = contributorsString;
    ////console.log('end')
    /*if(this.domNode.dataset.editorPinned && this.domNode.dataset.editorPinned=='true'){
      this.domNode.dataset.editorPinned='false'
    }else{
      this.domNode.dataset.editorPinned='true'
    }
    ////console.log(this.domNode.dataset.editorPinned);*/
  }


  /*deleteAt(index,length) {

    //let textBlot = Parchment.create('text', 'Test');
    //textBlot.insertAt(index, 'ough');
    return false;
  }*/

  deleteAt() {
    return false
  }

  move(old_index, new_index) {

    let contributors = JSON.parse(this.domNode.dataset.contributors);

    let arr = contributors.records;
    //console.log(arr);

    if (new_index >= arr.length) {
      return contributors;
    }
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length;
      while ((k--) + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    let count = 0;
    arr.forEach((contributor) => {
      count++;
      contributor.item.orderNumber = count;

    });

    contributors.records = arr;
    return contributors;
  }


}
ContributorsBlot.blotName = 'contributors';
ContributorsBlot.tagName = 'div';
ContributorsBlot.className = 'blot-wrapper-contributors';