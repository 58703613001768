let uuid = require('uuid');
let Delta = require('quill-delta');
let DeltaManager = require('../components/editor/DeltaManager').default;

export default {

    CANNOT_EDIT_FORMATS : {
        editingDeleteBlot: 'editingDeleteBlot',
        editingInsertBlot: 'editingInsertBlot',
        editingMovedDestinationBlot: 'editingMovedDestinationBlot',
        editingMovedTombstoneBlot: 'editingMovedTombstoneBlot',
        editingInsertNewLineBlot2: 'editingInsertNewLineBlot2',
        editingDeleteNewLineBlot: 'editingDeleteNewLineBlot'
    },

    hasAnyEditFormat: function (delta) {

       

        let hasFormat = false;
        try {
            delta.ops.forEach((op) => {
                Object.keys(this.CANNOT_EDIT_FORMATS).forEach((formatType)=>{
                    if (op.attributes && op.attributes[formatType]) {

                        hasFormat = true;
    
                    }
                })
                
            })
        } catch (err) {
            //console.log(err);
        }

        return hasFormat;

    },
    hasFormat: function (delta, formatType) {

       

        let hasFormat = false;
        try {
            delta.ops.forEach((op) => {
                if (op.attributes && op.attributes[formatType]) {

                    hasFormat = true;

                }
            })
        } catch (err) {
            //console.log(err);
        }

        return hasFormat;

    },
    isAllFormat: function ({
        quill,
        range,
        formatType
    }) {

        let formats = quill.getFormat(range);
        if (formats && formats[formatType]) {
            return true;
        }

        return false;
    },
    getNearbyFormats: function ({
        range,
        formatType,
        quill
    }) {

        let formatAhead = null;
        let formatBehind = null;
        let indexAheadLength = range.length;
        if (formatType && formatType == 'editingInsertBlot') {
            indexAheadLength = range.length == 0 ? 1 : range.length + 1;
        }
        try {
            if (range) {
                //let indexAhead = range.index + range.length;
                let indexAhead = range.index + indexAheadLength;
                let indexBehind = range.index - 1;
                quill.setSelection(indexAhead, 1, 'silent');
                formatAhead = quill.getFormat({ index: indexAhead, length: 1 });
                if (indexBehind >= 0) {
                    quill.setSelection(indexBehind, 1, 'silent');
                    formatBehind = quill.getFormat({ index: indexBehind, length: 1 });
                }
            }

        } catch (err) {
            //console.log(err);
        }



        return {
            formatAhead,
            formatBehind
        }

    },


    /*
{
    "attributes": {
        "editingInsertBlot": {
            "uuid": "864098b0-9fda-11ec-a919-6b3d5a8a190f",
            "accepted": "true",
            "rejected": "false",
            "editorPinned": "",
            "requestorPinned": "",
            "editorComment": ""
        }
    },
    "insert": "Edit change notes"
}
    */
    applyEditingDeleteBlot: function ({
        index,
        deletedDelta,
        quill
    }) {

        let deletedBlotDelta = null;
        let deletedLength = deletedDelta.length();

        if (index > 0) {


            let addDeletedOps = [{
                "retain": index
            }];

            addDeletedOps = addDeletedOps.concat(deletedDelta.ops);

            deletedBlotDelta = new Delta(addDeletedOps);

            // deletedBlotDelta = deletedBlotDelta.compose(new Delta([
            //     {
            //         "retain": index
            //     },
            //     {
            //         "insert":" "
            //     }
            // ]));

            deletedBlotDelta = deletedBlotDelta.compose(new Delta([
                {
                    "retain": index
                },
                {
                    //"retain": formattingLength + length,
                    "retain": deletedLength,
                    "attributes": {
                        "editingDeleteBlot": {
                            "uuid": uuid.v1(),
                            "accepted": "",
                            "rejected": "",
                            "editorPinned": "",
                            "requestorPinned": "",
                            "editorComment": ""
                        }
                    }
                }
            ]))
            // addDeletedOps.push({
            //     "retain": 0
            // });
            // addDeletedOps.push({
            //     //"retain": formattingLength + length,
            //     "retain": deletedLength,
            //     "attributes": {
            //         "editingDeleteBlot": {
            //             "uuid": uuid.v1(),
            //             "accepted": "",
            //             "rejected": "",
            //             "editorPinned": "",
            //             "requestorPinned": "",
            //             "editorComment": ""
            //         }
            //     }
            // });
            //deletedBlotDelta = new Delta(addDeletedOps);
            //  deletedBlotDelta = new Delta([
            //     {
            //         "retain": index
            //     },
            //     {
            //         //"retain": formattingLength + length,
            //         "retain": length,
            //         "attributes": {
            //             "editingInsertBlot": {
            //                 "uuid": uuid.v1(),
            //                 "accepted": "",
            //                 "rejected": "",
            //                 "editorPinned": "",
            //                 "requestorPinned": "",
            //                 "editorComment": ""
            //             }
            //         }
            //     }
            // ]);

        } else {

            let addDeletedOps = [];

            addDeletedOps = addDeletedOps.concat(deletedDelta.ops);

            deletedBlotDelta = new Delta(addDeletedOps);



            // deletedBlotDelta = new Delta([

            //     {
            //         "retain": length,
            //         "attributes": {
            //             "editingInsertBlot": {
            //                 "uuid": uuid.v1(),
            //                 "accepted": "",
            //                 "rejected": "",
            //                 "editorPinned": "",
            //                 "requestorPinned": "",
            //                 "editorComment": ""
            //             }
            //         }
            //     }
            // ]);
        }

        // let deletedLength = deletedDelta.length();
        // quill.setSelection(index,deletedDelta.length());
        return deletedBlotDelta;

    },

    applyEditingInsertBlot: function ({
        index,
        length
    }) {

        let insertBlotDelta = null;

        if (index > 0) {

            insertBlotDelta = new Delta([
                {
                    "retain": index
                },
                {
                    //"retain": formattingLength + length,
                    "retain": length,
                    "attributes": {
                        "editingInsertBlot": {
                            "uuid": uuid.v1(),
                            "accepted": "",
                            "rejected": "",
                            "editorPinned": "",
                            "requestorPinned": "",
                            "editorComment": ""
                        }
                    }
                }
            ]);

        } else {
            insertBlotDelta = new Delta([

                {
                    "retain": length,
                    "attributes": {
                        "editingInsertBlot": {
                            "uuid": uuid.v1(),
                            "accepted": "",
                            "rejected": "",
                            "editorPinned": "",
                            "requestorPinned": "",
                            "editorComment": ""
                        }
                    }
                }
            ]);
        }

        return insertBlotDelta;

    },
    keyboardModule_backspaceDeletePressed: function ({
        quill,
        bookStore,
        delta,
        range,
        insertedText,
        documentPart
    }) {
        try {

            let selectedDelta = quill.getContents(range);

            let { formatBehind, formatAhead } = this.getNearbyFormats({
                range,
                formatType: 'editingDeleteBlot',
                quill
            });

            let appliedUuid = formatAhead.editingDeleteBlot ? formatAhead.editingDeleteBlot.uuid : uuid.v1();

            let deleteDeltaOps = [];
            if (range.index > 1) {
                deleteDeltaOps.push({
                    "retain": range.index - 1
                })
            }
            deleteDeltaOps.push(
                {
                    //"retain": formattingLength + length,
                    "retain": 1,
                    "attributes": {
                        "editingDeleteBlot": {
                            "uuid": appliedUuid,
                            "accepted": "",
                            "rejected": "",
                            "editorPinned": "",
                            "requestorPinned": "",
                            "editorComment": ""
                        }
                    }
                });

            return new Delta(deleteDeltaOps);

        } catch (err) {
            console.log(err);
        }

    },

    keyboardModule_handleDeletePressed_withParts: function ({
        quill,
        bookStore,
        delta,
        parts,
        insertedText,
        documentPart
    }) {

        try {

            let deleteDeltaOps = [];

            parts.forEach((part, index) => {


                //console.log(part);
                let offset = 0;

                if (index == 0 && part.index > 0 && part.length > 0) {
                    deleteDeltaOps.push({
                        "retain": part.index
                    })
                }


                switch (part.format) {


                    case 'none':



                        deleteDeltaOps.push(
                            {
                                //"retain": formattingLength + length,
                                "retain": part.length,
                                "attributes": {
                                    "editingDeleteBlot": {
                                        "uuid": uuid.v1(),
                                        "accepted": "",
                                        "rejected": "",
                                        "editorPinned": "",
                                        "requestorPinned": "",
                                        "editorComment": ""
                                    }
                                }
                            });

                        break;

                    case 'editingInsertBlot':

                        deleteDeltaOps.push(
                            {
                                //"retain": formattingLength + length,
                                "delete": part.length
                            });

                        break;

                    case 'editingDeleteBlot':

                        deleteDeltaOps.push(
                            {
                                //"retain": formattingLength + length,
                                "retain": part.length,
                                "attributes": {
                                    "editingDeleteBlot": {
                                        "uuid": part.blot.editingDeleteBlot.uuid,
                                        "accepted": "",
                                        "rejected": "",
                                        "editorPinned": "",
                                        "requestorPinned": "",
                                        "editorComment": ""
                                    }
                                }
                            });

                        break;

                }

            })


            return new Delta(deleteDeltaOps);

        } catch (err) {
            console.log(err);
        }

    },

    hasBannedFormats: function ({
        delta
    }) {

        const CAN_EDIT_FORMATS = {
            editingDeleteBlot: 'editingDeleteBlot',
            editingInsertBlot: 'editingInsertBlot',
            editingMovedDestinationBlot: 'editingMovedDestinationBlot',
            editingInsertNewLineBlot2: 'editingInsertNewLineBlot2',
            image: 'image',
            //editingMovedTombstoneBlot: 'editingMovedTombstoneBlot'
        };

        let hasBannedFormats = false;

        try {


            delta.ops.forEach((op) => {
                //console.log(op);

                if (op.insert && typeof op.insert === 'object') {

                    Object.keys(op.insert).forEach((formatType) => {
                        if (CAN_EDIT_FORMATS[formatType] == null) {
                            hasBannedFormats = true;
                        }
                    })
                    //console.log('cannot edit this');
                    //hasBannedFormats = true;
                }

                if (op.attributes && typeof op.attributes === 'object') {

                    Object.keys(op.attributes).forEach((formatType) => {
                        if (CAN_EDIT_FORMATS[formatType] == null) {
                            hasBannedFormats = true;
                        }
                    })
                    //console.log('cannot edit this');
                    //hasBannedFormats = true;
                }



            })

        } catch (err) {
            console.log(err);
        }

        return hasBannedFormats;
    },

    deleteExistingInsert: function ({
        quill,
        bookStore,
        delta,
        range,
        insertedText,
        documentPart
    }) {
        try {


            let deleteDeltaOps = [];

            //If the cursor is not at the zero index location add a retain op. 
            if (range.index > 0) {
                deleteDeltaOps.push({
                    "retain": range.index
                })
            }
            deleteDeltaOps.push(
                {
                    "delete": range.length
                });

            return new Delta(deleteDeltaOps);

        } catch (err) {
            console.log(err);
        }

    },

    deleteAhead: function ({
        quill,
        bookStore,
        delta,
        range,
        insertedText,
        documentPart
    }) {
        try {

            //Only delete ahead if the cursor exists and nothing is selected
            if (!range || range.length > 0) {
                return null;
            }

            //Only delete ahead if there are actual characters ahead of the cursor
            if (quill.getLength() <= range.index + 1) {
                return null;
            }

            let selectedDelta = quill.getContents({
                index: range.index,
                length: 1
            });



            let { formatBehind, formatAhead } = this.getNearbyFormats({
                range,
                formatType: 'editingDeleteBlot',
                quill
            });

            let appliedUuid = uuid.v1();

            //If the character ahead is already deleted then do nothing. 
            if (formatAhead && formatAhead.editingDeleteBlot) {
                //appliedUuid = formatAhead.editingDeleteBlot ? formatAhead.editingDeleteBlot.uuid : uuid.v1();
                return null;
            }
            else if (formatBehind && formatBehind.editingDeleteBlot) {
                appliedUuid = formatBehind.editingDeleteBlot ? formatBehind.editingDeleteBlot.uuid : uuid.v1();
            }


            let deleteDeltaOps = [];

            //If the cursor is not at the zero index location add a retain op. 
            if (range.index > 0) {
                deleteDeltaOps.push({
                    "retain": range.index
                })
            }
            deleteDeltaOps.push(
                {
                    "retain": 1,
                    "attributes": {
                        "editingDeleteBlot": {
                            "uuid": appliedUuid,
                            "accepted": "",
                            "rejected": "",
                            "editorPinned": "",
                            "requestorPinned": "",
                            "editorComment": ""
                        }
                    }
                });

            return new Delta(deleteDeltaOps);

        } catch (err) {
            console.log(err);
        }

    },
    deleteParts: function ({
        quill,
        bookStore,
        delta,
        range,
        insertedText,
        documentPart
    }) {
        try {

            if (!range || range.length == 0) {
                //return null;
            }

            //if range has a length > 0 text has been selected that needs to be deleted. 
            let selectedDelta = quill.getContents(range);

            //if range.length==0 nothing has been selected so we will auto select the next character. 
            if (range.length == 0) {

                selectedDelta = quill.getContents({
                    index: range.index,
                    length: 1
                });

            }

            let { formatBehind, formatAhead } = this.getNearbyFormats({
                range,
                formatType: 'editingDeleteBlot',
                quill
            });

            let appliedUuid = uuid.v1();

            if (formatAhead && formatAhead.editingDeleteBlot) {
                appliedUuid = formatAhead.editingDeleteBlot ? formatAhead.editingDeleteBlot.uuid : uuid.v1();
            }
            else if (formatBehind && formatBehind.editingDeleteBlot) {
                appliedUuid = formatBehind.editingDeleteBlot ? formatBehind.editingDeleteBlot.uuid : uuid.v1();
            }


            let deleteDeltaOps = [];
            if (range.index > 0 && range.length > 0) {
                deleteDeltaOps.push({
                    "retain": range.index - 1
                })
            }
            else if (range.index > 0 && range.length == 0) {
                deleteDeltaOps.push({
                    "retain": range.index
                })
            }
            deleteDeltaOps.push(
                {
                    //"retain": formattingLength + length,
                    "retain": selectedDelta.length(),
                    "attributes": {
                        "editingDeleteBlot": {
                            "uuid": appliedUuid,
                            "accepted": "",
                            "rejected": "",
                            "editorPinned": "",
                            "requestorPinned": "",
                            "editorComment": ""
                        }
                    }
                });

            return new Delta(deleteDeltaOps);

        } catch (err) {
            console.log(err);
        }

    },

    keyboardModule_handleDeletePressed: function ({
        quill,
        bookStore,
        delta,
        range,
        insertedText,
        documentPart,
        parts
    }) {
        try {

            if (!range || range.length == 0) {
                //return null;
            }

            
            
            //if range has a length > 0 text has been selected that needs to be deleted. 
            let selectedDelta = quill.getContents(range);

            


            if(this.hasAnyEditFormat(selectedDelta)){
                bookStore.showSnackMessage('Cannot delete already edited content');
                return null;
            }

            //if range.length==0 nothing has been selected so we will auto select the next character. 
            if (range.length == 0) {

                selectedDelta = quill.getContents({
                    index: range.index,
                    length: 1
                });

            }

            let { formatBehind, formatAhead } = this.getNearbyFormats({
                range,
                formatType: 'editingDeleteBlot',
                quill
            });

            let appliedUuid = uuid.v1();

            if (formatAhead && formatAhead.editingDeleteBlot) {
                appliedUuid = formatAhead.editingDeleteBlot ? formatAhead.editingDeleteBlot.uuid : uuid.v1();
            }
            else if (formatBehind && formatBehind.editingDeleteBlot) {
                appliedUuid = formatBehind.editingDeleteBlot ? formatBehind.editingDeleteBlot.uuid : uuid.v1();
            }


            let deleteDeltaOps = [];
            if (range.index > 0 && range.length > 0) {
                deleteDeltaOps.push({
                    "retain": range.index
                })
            }
            else if (range.index > 0 && range.length == 0) {
                deleteDeltaOps.push({
                    "retain": range.index
                })
            }
            deleteDeltaOps.push(
                {
                    //"retain": formattingLength + length,
                    "retain": selectedDelta.length(),
                    "attributes": {
                        "editingDeleteBlot": {
                            "uuid": appliedUuid,
                            "accepted": "",
                            "rejected": "",
                            "editorPinned": "",
                            "requestorPinned": "",
                            "editorComment": ""
                        }
                    }
                });

            return new Delta(deleteDeltaOps);

        } catch (err) {
            console.log(err);
        }

    },



    keyboardModule_handleDeleteRemoveInsertedNewline: function ({
        quill,
        bookStore,
        delta,
        range,
        insertedText,
        documentPart
    }) {
        try {


            let appliedUuid = uuid.v1();

            let insertDeltaOps = [
                {
                    "retain": range.index
                },
                {
                    "delete": 2
                },
            ];

            let insertBlotDelta = new Delta(insertDeltaOps);
            return insertBlotDelta;




        } catch (err) {
            console.log(err);
        }
    },

    keyboardModule_handleBackspaceRemoveInsertedNewline: function ({
        quill,
        bookStore,
        delta,
        range,
        insertedText,
        documentPart
    }) {
        try {


            let appliedUuid = uuid.v1();

            let insertDeltaOps = [
                {
                    "retain": range.index - 1
                },
                {
                    "delete": 1
                },
            ];

            let insertBlotDelta = new Delta(insertDeltaOps);
            return insertBlotDelta;




        } catch (err) {
            console.log(err);
        }
    },
    keyboardModule_handleBackspaceNewline: function ({
        quill,
        bookStore,
        delta,
        range,
        insertedText,
        documentPart
    }) {
        try {


            let appliedUuid = uuid.v1();

            let insertDeltaOps = [
                {
                    "retain": range.index - 1
                },
                {
                    "delete": 1
                },
                {
                    "insert": {
                        "editingDeleteNewLineBlot": {
                            uuid: uuid.v1()
                        }
                    }
                },

            ];

            let insertBlotDelta = new Delta(insertDeltaOps);
            return insertBlotDelta;




        } catch (err) {
            console.log(err);
        }
    },

    keyboardModule_handleDeleteNewline: function ({
        quill,
        bookStore,
        delta,
        range,
        insertedText,
        documentPart
    }) {
        try {


            let appliedUuid = uuid.v1();

            let insertDeltaOps = [
                {
                    "retain": range.index
                },
                {
                    "delete": 1
                },
                {
                    "insert": {
                        "editingDeleteNewLineBlot": {
                            uuid: uuid.v1()
                        }
                    }
                },

            ];

            let insertBlotDelta = new Delta(insertDeltaOps);
            return insertBlotDelta;




        } catch (err) {
            console.log(err);
        }
    },




    keyboardModule_handleInsertNewline: function ({
        quill,
        bookStore,
        delta,
        range,
        insertedText,
        documentPart,
        cursorFormats,
          
    }) {
        try {

            let selectedDelta = quill.getContents(range);
            let appliedUuid = uuid.v1();

            let insertOp =  {
                "insert": {
                    "editingInsertNewLineBlot2": {
                        uuid: uuid.v1(),
                        "accepted": "",
                        "rejected": "",
                        "editorPinned": "",
                        "requestorPinned": "",
                        "editorComment": ""
                    }
                },
                "attributes": {
                    editingDeleteBlot: null,
                    editingMovedDestinationBlot: null,
                    editingMovedTombstoneBlot: null
                }
            };
            let insertDeltaOps = [
                {
                    "retain": range.index
                },
                insertOp
                // {
                //     "insert": {
                //         "editingInsertNewLineBlot2": {
                //             uuid: uuid.v1(),
                //             "accepted": "",
                //             "rejected": "",
                //             "editorPinned": "",
                //             "requestorPinned": "",
                //             "editorComment": ""
                //         }
                //     },
                //     "attributes": {
                //         editingDeleteBlot: null,
                //         editingMovedDestinationBlot: null,
                //         editingMovedTombstoneBlot: null
                //     }
                // },
                // {
                //     "insert": "\n"
                // }


            ];

            if (cursorFormats && cursorFormats.editingMovedDestinationBlot) {
                insertOp.attributes.editingMovedDestinationBlot = cursorFormats.editingMovedDestinationBlot;
            }

            if (range.index == 0) {

                insertDeltaOps = [

                    {
                        "insert": {
                            "editingInsertNewLineBlot2": {
                                uuid: uuid.v1(),
                                "accepted": "",
                                "rejected": "",
                                "editorPinned": "",
                                "requestorPinned": "",
                                "editorComment": ""
                            }
                        }
                    },


                ];

            }


            if (selectedDelta.length() > 0) {


                let deleteDeltaOps = [
                    // {
                    //     "retain": range.index + insertedText.length
                    // },
                    {
                        "retain": selectedDelta.length(),
                        "attributes": {
                            "editingDeleteBlot": {
                                "uuid": uuid.v1(),
                                "accepted": "",
                                "rejected": "",
                                "editorPinned": "",
                                "requestorPinned": "",
                                "editorComment": ""
                            }
                        }
                    }]



                insertDeltaOps = insertDeltaOps.concat(deleteDeltaOps);
            }

            let insertBlotDelta = new Delta(insertDeltaOps);
            return insertBlotDelta;




        } catch (err) {
            console.log(err);
        }
    },

    keyboardModule_handleKeyPressed: function ({
        quill,
        bookStore,
        delta,
        range,
        cursorFormats,
        insertedText,
        documentPart
    }) {
        try {


            let selectedDelta = quill.getContents(range);

            if (this.hasAnyEditFormat(selectedDelta)) {
                bookStore.showSnackMessage('Cannot insert over already edited content');
                return null;
              }


            if (!this.hasFormat(delta, 'editingInsertBlot')) {

                let { formatBehind, formatAhead } = this.getNearbyFormats({
                    range,
                    formatType: 'editingInsertBlot',
                    quill
                });

                let appliedUuid = (formatBehind && formatBehind.editingInsertBlot) ? formatBehind.editingInsertBlot.uuid : uuid.v1();

                let insertOp = {
                    "insert": insertedText,
                    "attributes": {
                        "editingInsertBlot": {
                            "uuid": appliedUuid,
                            "accepted": "",
                            "rejected": "",
                            "editorPinned": "",
                            "requestorPinned": "",
                            "editorComment": ""
                        }
                    }
                };

                if (cursorFormats && cursorFormats.editingMovedDestinationBlot) {
                    insertOp.attributes.editingMovedDestinationBlot = cursorFormats.editingMovedDestinationBlot;
                }

                let insertDeltaOps = [
                    {
                        "retain": range.index
                    },
                    insertOp
                    // {
                    //     "insert": insertedText,
                    //     "attributes": {
                    //         "editingInsertBlot": {
                    //             "uuid": appliedUuid,
                    //             "accepted": "",
                    //             "rejected": "",
                    //             "editorPinned": "",
                    //             "requestorPinned": "",
                    //             "editorComment": ""
                    //         }
                    //     }
                    // },
                ];




                if (range.index == 0) {
                    insertDeltaOps = [
                        insertOp
                        // {
                        //     "insert": insertedText,
                        //     //"retain": 1,
                        //     "attributes": {
                        //         "editingInsertBlot": {
                        //             "uuid": appliedUuid,
                        //             "accepted": "",
                        //             "rejected": "",
                        //             "editorPinned": "",
                        //             "requestorPinned": "",
                        //             "editorComment": ""
                        //         }
                        //     }
                        // }
                    ]

                }


                if (selectedDelta.length() > 0) {


                    let deleteDeltaOps = [
                        // {
                        //     "retain": range.index + insertedText.length
                        // },
                        {
                            "retain": selectedDelta.length(),
                            "attributes": {
                                "editingDeleteBlot": {
                                    "uuid": uuid.v1(),
                                    "accepted": "",
                                    "rejected": "",
                                    "editorPinned": "",
                                    "requestorPinned": "",
                                    "editorComment": ""
                                }
                            }
                        }]



                    insertDeltaOps = insertDeltaOps.concat(deleteDeltaOps);
                }





                // try{
                //     deleteDelta = this.keyboardModule_deleteSelected({
                //         quill,
                //         index:quill.mostRecentSelection.index+1,
                //         documentPart
                //     })
                // }catch(err){
                //     console.log(err);
                // }




                // quill.updateContents(insertBlotDelta, 'user');
                // bookStore.setBlotsChangedTimestamp();
                // quill.setSelection({
                //     index: bookStore.mostRecentQuillKeyPressSelection.index + 1,
                //     length: 0
                // })

                // if(deleteDelta){
                //     insertBlotDelta = deleteDelta.compose(insertBlotDelta);
                // }
                let insertBlotDelta = new Delta(insertDeltaOps);
                return insertBlotDelta;


            }

        } catch (err) {
            console.log(err);
        }
    },


    extractParts: function ({
        quill,
        range
    }) {

        let FORMAT_TYPES = {
            NONE: 'none',
            DELETING: 'editingDeleteBlot',
            INSERTING: 'editingInsertBlot'
        }


        let parts = [];
        let currentPart = null;
        /*let currentPart = {
            format:FORMAT_TYPES.NONE,
            length:0
        };
        parts.push(currentPart);*/

        if (!range) {
            return parts;
        }
        for (let i = 0; i < range.length; i++) {

            quill.setSelection(range.index + i, 1, 'silent');

            let format = quill.getFormat();

            if (Object.keys(format).length == 0) {

                if (currentPart && currentPart.format == FORMAT_TYPES.NONE) {
                    currentPart.length++;
                } else {

                    currentPart = {
                        format: FORMAT_TYPES.NONE,
                        length: 1,
                        index: range.index + i,
                        blot: format
                    }
                    parts.push(currentPart);

                }

            }

            try {

                Object.keys(format).forEach((key) => {

                    console.log(key);

                    if (key == FORMAT_TYPES.INSERTING || key == FORMAT_TYPES.DELETING) {

                        if (currentPart && key == currentPart.format) {
                            currentPart.length++;
                        } else {

                            currentPart = {
                                format: key,
                                length: 1,
                                index: range.index + i,
                                blot: format
                            }
                            parts.push(currentPart);
                        }

                    } else {

                        if (currentPart && currentPart.format == FORMAT_TYPES.NONE) {
                            currentPart.length++;
                        } else {

                            currentPart = {
                                format: FORMAT_TYPES.NONE,
                                length: 1,
                                index: range.index + i,
                                blot: format
                            }
                            parts.push(currentPart);

                        }
                    }



                });

                //console.log();
            } catch (err) {
                console.log(err);
            }







        }


        // parts.forEach((part) => {

        // 	this.quill.setSelection(part.index, part.length, 'silent');

        // })

        return parts;



    },

    handleKeyPressed: function ({
        quill,
        bookStore,
        delta,
        documentPart
    }) {
        try {

            if (!this.hasFormat(delta, 'editingInsertBlot')) {


                let insertBlotDelta = new Delta([
                    {
                        "retain": bookStore.mostRecentQuillKeyPressSelection.index
                    },
                    {
                        //"retain": formattingLength + length,
                        "retain": 1,
                        "attributes": {
                            "editingInsertBlot": {
                                "uuid": uuid.v1(),
                                "accepted": "",
                                "rejected": "",
                                "editorPinned": "",
                                "requestorPinned": "",
                                "editorComment": ""
                            }
                        }
                    }
                ]);

                if (bookStore.mostRecentQuillKeyPressSelection.index == 0) {

                    insertBlotDelta = new Delta([

                        {
                            //"retain": formattingLength + length,
                            "retain": 1,
                            "attributes": {
                                "editingInsertBlot": {
                                    "uuid": uuid.v1(),
                                    "accepted": "",
                                    "rejected": "",
                                    "editorPinned": "",
                                    "requestorPinned": "",
                                    "editorComment": ""
                                }
                            }
                        }
                    ]);

                }
                let { formatBehind, formatAhead } = this.getNearbyFormats({
                    range: bookStore.mostRecentQuillKeyPressSelection,
                    formatType: 'editingInsertBlot',
                    quill
                });

                let deleteDelta = null;

                try {
                    deleteDelta = this.deleteSelected({
                        quill,
                        index: quill.mostRecentSelection.index + 1,
                        documentPart
                    })
                } catch (err) {
                    console.log(err);
                }


                //console.log(formatBehind);
                //console.log(formatAhead);


                //documentPart.appendDelta(insertBlotDelta);

                quill.updateContents(insertBlotDelta, 'user');
                bookStore.setBlotsChangedTimestamp();
                quill.setSelection({
                    index: bookStore.mostRecentQuillKeyPressSelection.index + 1,
                    length: 0
                })

                if (deleteDelta) {
                    insertBlotDelta = deleteDelta.compose(insertBlotDelta);
                }
                return insertBlotDelta;


            }

        } catch (err) {
            console.log(err);
        }
    },

    isPasted: function ({
        quill,
        bookStore,
        delta,
        documentPart
    }) {
        if (bookStore.mostRecentQuillClipboardDelta != null) {
            return true;
        }
        return false;
    },

    isKeyPressed: function ({
        quill,
        bookStore,
        delta,
        documentPart
    }) {

        return true;
    },

    isBackspace: function ({
        quill,
        bookStore,
        delta,
        documentPart
    }) {
        if (bookStore.backspaceHit == true) {

            return true;
        }

        return false;
    },
    isDelete: function ({
        quill,
        bookStore,
        delta,
        documentPart
    }) {

        if (bookStore.deleteHit == true) {

            return true;
        }

        return false;
    },

    handlePasted: function ({
        quill,
        bookStore,
        delta,
        documentPart
    }) {

        //console.log(delta);
        //console.log(bookStore.mostRecentQuillClipboardDelta);
        //console.log(bookStore.mostRecentQuillClipboardDelta.length());
        //console.log(bookStore.mostRecentQuillKeyPressSelection);
        //console.log(quill.mostRecentSelection);
        //console.log(quill.mostRecentSelectionDelta);

        //Step 1. Create insert insert blot
        let length = bookStore.mostRecentQuillClipboardDelta.length();
        quill.setSelection(quill.mostRecentSelection.index, length);

        let editInsertBlot = this.applyEditingInsertBlot({
            index: quill.mostRecentSelection.index,
            length
        });

        quill.updateContents(editInsertBlot, 'user');
        //documentPart.appendDelta(editInsertBlot);


        //Only apply a delete blot if something was selected with this paste.
        let deletedDelta = this.deleteSelected({
            quill,
            index: quill.mostRecentSelection.index + length,
            documentPart
        })

        //let finalDelta = bookStore.mostRecentQuillClipboardDelta.compose(editInsertBlot);
        //let finalDelta = editInsertBlot.compose(bookStore.mostRecentQuillClipboardDelta);
        //let finalDelta = new Delta().retain(quill.mostRecentSelection.index).compose(bookStore.mostRecentQuillClipboardDelta).compose(editInsertBlot);

        let finalDelta = delta.compose(editInsertBlot);
        if (deletedDelta) {

            finalDelta = finalDelta.compose(deletedDelta);

        }


        return finalDelta;

    },

    deleteSelected: function ({
        quill,
        index,
        documentPart
    }) {

        if (quill.mostRecentSelectionDelta && quill.mostRecentSelectionDelta.ops?.length > 0) {

            let editDeleteBlot = this.applyEditingDeleteBlot({
                index: index,
                deletedDelta: quill.mostRecentSelectionDelta,
                quill
            })

            quill.updateContents(editDeleteBlot, 'user');
            //documentPart.appendDelta(editDeleteBlot);

            return editDeleteBlot;

        }

    },
    behindIsNewLine: function ({
        quill,
        range
    }) {
        if (range.index == 0) return false;

        let characterBehindDelta = quill.getContents({
            index: range.index - 1,
            length: 1
        });
        //console.log(characterBehindDelta);

        if (characterBehindDelta &&
            characterBehindDelta.ops &&
            characterBehindDelta.ops.length == 1 &&
            characterBehindDelta.ops[0].insert &&
            characterBehindDelta.ops[0].insert == '\n'
        ) {
            return true;
        }

        return false;

    },
    aheadIsNewLine: function ({
        quill,
        range
    }) {
        if (range.index == 0) return false;

        let characterAheadDelta = quill.getContents({
            index: range.index,
            length: 1
        });
        //console.log(characterAheadDelta);

        if (characterAheadDelta &&
            characterAheadDelta.ops &&
            characterAheadDelta.ops.length == 1 &&
            characterAheadDelta.ops[0].insert &&
            characterAheadDelta.ops[0].insert == '\n'
        ) {
            return true;
        }

        return false;

    },


    rangeAheadIsEditingInsertNewLine: function ({
        quill,
        range
    }) {
        if (range.index == 0) return false;

        let characterAheadDelta = quill.getContents({
            index: range.index,
            length: 1
        });
        //console.log(characterAheadDelta);

        if (characterAheadDelta &&
            characterAheadDelta.ops &&
            characterAheadDelta.ops.length == 1 &&
            characterAheadDelta.ops[0].insert &&
            characterAheadDelta.ops[0].insert.editingInsertNewLineBlot2
        ) {
            return true;
        }
        return false;

    },
    rangeIsEditingInsertNewLine: function ({
        quill,
        range
    }) {
        if (range.index == 0) return false;

        let characterBehindDelta = quill.getContents({
            index: range.index - 1,
            length: 1
        });
        //console.log(characterBehindDelta);

        if (characterBehindDelta &&
            characterBehindDelta.ops &&
            characterBehindDelta.ops.length == 1 &&
            characterBehindDelta.ops[0].insert &&
            characterBehindDelta.ops[0].insert.editingInsertNewLineBlot2
        ) {
            return true;
        }
        return false;

    },
    copySource: function ({
        range,
        quill,
        bookStore,
        documentPart,
        parts,
        cursorFormats
    }) {

        bookStore.inTransitMovedEditIsDestination = false;
        let selectedContent = quill.getContents(range);

        let {
            hasEditingMovedTombstoneBlot,
            hasEditingMovedDestinationBlot,
            existingTombstoneUuid,
            existingDestinationUuid
        } = this.getMoveFormatInfo({
            quill,
            parts,
            range
        });

        if (hasEditingMovedTombstoneBlot) {
            bookStore.showSnackMessage('Text already moved');
            return {};
        }

        if (cursorFormats && !cursorFormats.editingMovedDestinationBlot) {
            bookStore.showSnackMessage('Cannot move part of already moved content');
            return {};
        }



        if (hasEditingMovedDestinationBlot) {



            let destinationFormatBehind = this.getFormatBehind({
                format: 'editingMovedDestinationBlot',
                index: range.index,
                quill,
                existingDestinationUuid
            });

            let destinationFormatAhead = this.getFormatAhead({
                format: 'editingMovedDestinationBlot',
                index: range.index,
                quill,
                existingDestinationUuid
            })

            quill.setSelection(range.index - destinationFormatBehind, destinationFormatBehind + destinationFormatAhead);


            range = quill.getSelection(false, 'silent');
            selectedContent = quill.getContents(range);

            bookStore.inTransitMovedEditIsDestination = true;




        }


        if (!range || range.length == 0) {
            bookStore.inTransitMovedEdit = null;
            bookStore.inTransitSourceRange = null;
            bookStore.inTransitSourceQuill = null;
            bookStore.inTransitSourceDocumentPart = null;

            bookStore.showSnackMessage('Must select something.');

        } else {
            bookStore.inTransitMovedEdit = selectedContent;
            bookStore.inTransitSourceRange = range;
            bookStore.inTransitSourceQuill = quill;
            bookStore.inTransitSourceDocumentPart = documentPart;
            //console.log(bookStore.inTransitMovedEdit)
            //console.log('copied source');
        }

        return {
            updatedRange: bookStore.inTransitSourceRange
        }


    },
    moveSource: function ({
        quill,
        bookStore,
        documentPart,
        range
    }) {

        let { inTransitMovedEdit, inTransitSourceRange } = bookStore;

        //console.log('moving source');
        //console.log(bookStore.inTransitMovedEdit)

        if (!inTransitMovedEdit) {
            //alert('Nothing copied');
            return {};
        }




        let {
        	hasEditingMovedTombstoneBlot,
        	hasEditingMovedDestinationBlot,
        	existingTombstoneUuid,
        	existingDestinationUuid,
        	existingTombstoneFormat,
        	existingDestinationFormat,
            nextIndexIsTombstone,
            nextIndexIsDestination
        } = this.getMoveFormatInfo({
        	quill,
            range
        });

        if (range && range.length>0) {
        	bookStore.showSnackMessage('Cannot move content over selected text.');
        	return {};
        }

        if (hasEditingMovedTombstoneBlot && nextIndexIsTombstone) {
        	bookStore.showSnackMessage('Cannot move here');
        	return {};
        }
        if (hasEditingMovedDestinationBlot && nextIndexIsDestination && range.index>0) {
        	bookStore.showSnackMessage('Cannot move here');
        	return {};
        }

        
        if (bookStore.inTransitMovedEditIsDestination) {

            let results = this.doMoveDestinationBlot({
                range,
                quill,
                bookStore,
                documentPart
            });

            return results;
            //return { sourceChangeDelta, destinationChangeDelta, sourceChangeDocumentPart };
        } else {

            let results = this.doMove({
                range,
                quill,
                bookStore,
                documentPart
            });

            return results;
            //return { sourceChangeDelta, destinationChangeDelta, sourceChangeDocumentPart };
        }




    },

    doMove: function ({
        range,
        quill,
        bookStore,
        documentPart
    }) {
        let sourceChangeDelta = null;
        let sourceChangeDocumentPart = null;
        let destinationChangeDelta = null;
        let sourceUuid = uuid.v1();
        let destinationUuid = uuid.v1();
        let sourceRange = bookStore.inTransitSourceRange;
        let sourceQuill = bookStore.inTransitSourceQuill;
        let inTransitSourceDocumentPart = bookStore.inTransitSourceDocumentPart;


        let deltaManager = new DeltaManager();
        let { inTransitMovedEdit, inTransitSourceRange } = bookStore;

        //Convert the content to remove any existing edits. This is so that edits do not appear in more than one
        //location. Even if the source edits are disabled. 
        let { convertedDelta, lengthRemoved } = deltaManager.convertEditedDeltasForMoving([inTransitMovedEdit]);

        let finalDelta = new Delta();

        let updateQuillLive = true;




        bookStore.inTransitMovedEditIsDestination = false;





        //Ensure they have not clicked inside a tombstone.
        let {
            hasEditingMovedTombstoneBlot,
            hasEditingMovedDestinationBlot,
            existingTombstoneUuid,
            existingDestinationUuid
        } = this.getMoveFormatInfo({
            quill
        });

        //console.log('hasEditingMovedTombstoneBlot:' + hasEditingMovedTombstoneBlot);
        //console.log('hasEditingMovedDestinationBlot:' + hasEditingMovedDestinationBlot);















        //  1: ************************************** 
        //             Delete the old
        //     **************************************
        let deleteDelta = new Delta();
        let netTombstoneLengthChange = 0;
        if (inTransitSourceRange.index > 0) {
            deleteDelta.retain(sourceRange.index);
        } else {
            deleteDelta.retain(sourceRange.index);
        }

        deleteDelta.delete(sourceRange.length);


        finalDelta = finalDelta.compose(deleteDelta);



        //  2: ************************************** 
        //      Insert the converted source content
        //     **************************************
        let insertConvertedDelta = new Delta();
        if (inTransitSourceRange.index > 0) {
            insertConvertedDelta.ops = [{ retain: inTransitSourceRange.index }].concat(convertedDelta.ops);
            finalDelta = finalDelta.compose(insertConvertedDelta);
        } else {
            finalDelta = finalDelta.compose(convertedDelta);
        }

        //if (updateQuillLive) sourceQuill.updateContents(finalDelta, 'silent');


        //  3: ************************************** 
        //      Apply tombstone blot to source
        //     **************************************
        let applyTombstoneDelta = new Delta();
        applyTombstoneDelta.ops = [];
        if (inTransitSourceRange.index != 0) {
            applyTombstoneDelta.ops.push({
                "retain": inTransitSourceRange.index
            });
        }

        applyTombstoneDelta.ops.push({
            "retain": convertedDelta.length(),
            "attributes": {
                "editingMovedTombstoneBlot": {
                    "uuid": sourceUuid,
                    "oppositeUuid": destinationUuid,
                    "accepted": "false",
                    "rejected": "false",
                    "editorPinned": "false",
                    "requestorPinned": "false",
                    "editorComment": "",
                    "sourcePartId": inTransitSourceDocumentPart.id,
                    "destinationPartId": documentPart.id
                }
            }
        });

        finalDelta = finalDelta.compose(applyTombstoneDelta);
        try {
            //if (updateQuillLive) sourceQuill.updateContents(applyTombstoneDelta, 'silent');
        } catch (err) {
            //console.log('quill not live...');
        }


        //This is the final change to what will become the tombstone.
        sourceChangeDelta = finalDelta;
        sourceChangeDocumentPart = inTransitSourceDocumentPart;

        //  4: ************************************** 
        //      Apply destination blot
        //     **************************************

        /////////////////////////////////////////////////////////////////////////////////////
        {
            let { inTransitMovedEdit, inTransitSourceRange } = bookStore;
            let finalDelta = new Delta();
            let insertOriginalContentDelta = new Delta();

            let droppedIndex = range.index;
            if (documentPart.id == inTransitSourceDocumentPart.id) {

                //If the source comes after the destination the dropped index is set to the range.index - the number
                //of characters removed when the source was converted to a tombstone.
                if (sourceRange.index < range.index) {

                    droppedIndex = range.index - lengthRemoved;

                } else if (sourceRange.index >= range.index) {
                    droppedIndex = range.index;
                }

            }

            if (droppedIndex > 0) {
                insertOriginalContentDelta.ops = [{ retain: droppedIndex }].concat(inTransitMovedEdit.ops);
            } else {
                insertOriginalContentDelta.ops = inTransitMovedEdit.ops;
            }

            finalDelta = finalDelta.compose(insertOriginalContentDelta);
            //if (updateQuillLive) quill.updateContents(insertOriginalContentDelta, 'silent');



            let applyMovedDelta = new Delta();



            if (droppedIndex > 0) {
                applyMovedDelta.ops = [
                    {
                        "retain": droppedIndex
                    },
                    {
                        "retain": inTransitMovedEdit.length(),
                        "attributes": {
                            "editingMovedDestinationBlot": {
                                "uuid": destinationUuid,
                                "oppositeUuid": sourceUuid,
                                "accepted": "false",
                                "rejected": "false",
                                "editorPinned": "false",
                                "requestorPinned": "false",
                                "editorComment": "",
                                "sourcePartId": inTransitSourceDocumentPart.id,
                                "destinationPartId": documentPart.id
                            }
                        }
                    }
                ];
            } else {
                applyMovedDelta.ops = [
                    {
                        "retain": inTransitMovedEdit.length(),
                        "attributes": {
                            "editingMovedDestinationBlot": {
                                "uuid": destinationUuid,
                                "oppositeUuid": sourceUuid,
                                "accepted": "false",
                                "rejected": "false",
                                "editorPinned": "false",
                                "requestorPinned": "false",
                                "editorComment": "",
                                "sourcePartId": inTransitSourceDocumentPart.id,
                                "destinationPartId": documentPart.id
                            }
                        }
                    }
                ];
            }





            finalDelta = finalDelta.compose(applyMovedDelta);
            //finalDelta = new Delta(finalDelta.ops.concat(applyMovedDelta.ops));
            //if (updateQuillLive) quill.updateContents(applyMovedDelta, 'silent');

            let combinedDelta = sourceChangeDelta.compose(finalDelta);

            bookStore.inTransitMovedEdit = null;
            bookStore.inTransitSourceRange = null;
            bookStore.inTransitSourceDocumentPart = null;

            //This is the final change to what will become the tombstone.
            destinationChangeDelta = finalDelta;

            if (inTransitSourceDocumentPart.id == documentPart.id) {

                return {
                    sourceChangeDelta: null,
                    destinationChangeDelta: combinedDelta,
                    sourceChangeDocumentPart: inTransitSourceDocumentPart
                };

            } else {

                return {
                    sourceChangeDelta,
                    destinationChangeDelta: finalDelta,
                    sourceChangeDocumentPart: inTransitSourceDocumentPart
                };
            }



        }
    },
    getMoveFormatInfo: function ({
        quill,
        parts,
        range
    }) {

        let formats = quill.getFormat();
        let hasEditingMovedTombstoneBlot = false;
        let hasEditingMovedDestinationBlot = false;

        let existingTombstoneUuid = null;
        let existingDestinationUuid = null;

        let existingTombstoneFormat = null;
        let existingDestinationFormat = null;

        let nextIndexIsTombstone = null;
        try{
            let contents = quill.getContents(range.index,1);
            nextIndexIsTombstone = this.hasFormat(contents, 'editingMovedTombstoneBlot');
        }catch(err){

        }

        let nextIndexIsDestination = null;
        try{
            let contents = quill.getContents(range.index,1);
            nextIndexIsDestination = this.hasFormat(contents, 'editingMovedDestinationBlot');
        }catch(err){

        }
        




        Object.keys(formats).forEach((key) => {

            if (key == 'editingMovedTombstoneBlot') {

                hasEditingMovedTombstoneBlot = true;
                existingTombstoneUuid = formats[key].uuid;
                existingTombstoneFormat = formats[key];
            }
            if (key == 'editingMovedDestinationBlot') {

                hasEditingMovedDestinationBlot = true;
                existingDestinationUuid = formats[key].uuid;
                if(Array.isArray(formats[key])){
                    existingDestinationUuid = formats[key][0].uuid;
                }
                existingDestinationFormat = formats[key];

            }
        })
        // Object.keys(formats).forEach((key) => {

        //     if (key == 'editingMovedTombstoneBlot') {

        //         hasEditingMovedTombstoneBlot = true;
        //         existingTombstoneUuid = formats[key].uuid;
        //         existingTombstoneFormat = formats[key];
        //     }
        //     if (key == 'editingMovedDestinationBlot') {

        //         hasEditingMovedDestinationBlot = true;
        //         existingDestinationUuid = formats[key].uuid;
        //         existingDestinationFormat = formats[key];

        //     }
        // })

        return {
            hasEditingMovedTombstoneBlot,
            hasEditingMovedDestinationBlot,
            existingTombstoneUuid,
            existingDestinationUuid,
            existingTombstoneFormat,
            existingDestinationFormat,
            nextIndexIsTombstone,
            nextIndexIsDestination
        }

    },




    getFormatBehind: function ({
        format,
        index,
        quill,
        existingDestinationUuid
    }) {

        if (index == 0) {
            return 0;
        }
        let count = 0;
        let complete = false;

        let workingIndex = index - 1;
        while (!complete && (workingIndex - count) >= 0) {

            quill.setSelection(workingIndex - count, 1, 'silent');

            let formats = quill.getFormat(workingIndex - count, 1);
            let hasFormat = false;
            Object.keys(formats).forEach((key) => {

                let scannedFormat = formats[key];
                if (key == format && existingDestinationUuid == scannedFormat.uuid) {

                    count++;
                    hasFormat = true;

                }

            })

            if (Object.keys(formats).length == 0 || !hasFormat) {
                complete = true;
            }

        }

        return count;



    },

    getFormatAhead: function ({
        format,
        index,
        quill,
        existingDestinationUuid
    }) {

        let count = 0;
        let complete = false;

        while (!complete && (index + count < quill.getLength())) {

            quill.setSelection(index + count, 1, 'silent');
            let selectedContents = quill.getContents(index + count, 1, 'silent');

            let formats = quill.getFormat(index + count, 1);
            let hasFormat = false;
            Object.keys(formats).forEach((key) => {

                let scannedFormat = formats[key];
                if (key == format && existingDestinationUuid == scannedFormat.uuid) {

                    count++;
                    hasFormat = true;

                }

            })

            if (Object.keys(formats).length == 0 || !hasFormat) {
                complete = true;
            }

        }



        return count;



    },


    doMoveDestinationBlot: function ({
        quill,
        props,
        bookStore,
        documentPart,
        existingTombstoneFormat,
        existingDestinationFormat
    }) {
        let range = quill.getSelection(false, 'silent');
        let allChangeDeltas = [];
        let selectedContent = bookStore.inTransitMovedEdit;
        let sourceRange = bookStore.inTransitSourceRange;
        let inTransitSourceDocumentPart = bookStore.inTransitSourceDocumentPart;
        let bookDraft = documentPart.bookDraftDocumentPart.bookDraft;
        let sourceChangeDocumentPart = null;
        let sourceChangeDelta = null;
        let destinationChangeDelta = null;

        let tombstoneDocumentPart = null;
        let tombstoneDeleteDelta = null;
        let tombstoneInsertDelta = null;


        let deltaManager = new DeltaManager();
        let { inTransitMovedEdit, inTransitSourceRange } = bookStore;


        let finalDelta = new Delta();

        let updateQuillLive = true;

        //1: Delete the old
        let deleteDelta = new Delta();
        if (inTransitSourceRange.index > 0) {
            deleteDelta.retain(sourceRange.index);
        } else {
            deleteDelta.retain(sourceRange.index);
        }

        deleteDelta.delete(sourceRange.length);


        /////////////////////////////////////////////////////////////////////////////////////
        if (documentPart.id == inTransitSourceDocumentPart.id) {


            let finalDestinationDelta = new Delta();
            let insertOriginalContentDelta = new Delta();

            let droppedIndex = range.index;
            // if (documentPart.id == inTransitSourceDocumentPart.id) {
            //     droppedIndex = range.index;
            // }

            if (sourceRange.index < droppedIndex) {
                droppedIndex -= sourceRange.length;
            }

            if (droppedIndex == 0) {

                insertOriginalContentDelta.ops = inTransitMovedEdit.ops;

            } else {

                insertOriginalContentDelta.ops = [{ retain: droppedIndex }].concat(inTransitMovedEdit.ops);
            }


            finalDestinationDelta = finalDestinationDelta.compose(deleteDelta);
            finalDestinationDelta = finalDestinationDelta.compose(insertOriginalContentDelta);
            //if (updateQuillLive) quill.updateContents(finalDestinationDelta, 'silent');



            //sourceChangeDocumentPart = inTransitSourceDocumentPart;
            //sourceChangeDelta = finalDestinationDelta;

            destinationChangeDelta = finalDestinationDelta;











            /*


            
            // Update the tombstone blot to point to the new documentPart ( if it changed ).
            // The entire moved delta should be formatted as a destination blot. So, can 
            // extract source partId and uuid from it. 

            let sourceUuid = null;
            let sourcePartId = null;

            while (sourceUuid == null) {


                inTransitMovedEdit.ops.forEach((op) => {


                    Object.keys(op.attributes).forEach((key) => {

                        if (key == 'editingMovedDestinationBlot') {
                            sourceUuid = op.attributes[key].oppositeUuid;
                            sourcePartId = op.attributes[key].sourcePartId;

                        }
                    })
                })

            }


            let bookDraft = documentPart.bookDraftDocumentPart.bookDraft;
            let sourceDocumentPart = bookDraft.getDocumentPartById(sourcePartId).documentPart;

            //Now cycle through the source deltaOps , looking for all deltas with this source formatting
            let priorOps = [];
            let sourceOps = [];
            let tombstoneFormatProperties = null;
            let composedDelta = deltaManager.composeDeltas(sourceDocumentPart.deltaOps);
    	

                let foundSourceOps = false;
            	
                composedDelta.ops.forEach((op)=>{

                    let isSourceOp = false;

                    if(op.attributes){
                        Object.keys(op.attributes).forEach((key) => {

                            if (key == 'editingMovedTombstoneBlot') {
                            	
                                tombstoneFormatProperties = op.attributes[key];
                                let uuid = op.attributes[key].uuid;
                                let partId = op.attributes[key].sourcePartId;
	
                                if(uuid==sourceUuid && sourcePartId==partId){
	
                                    foundSourceOps = true;
                                    isSourceOp = true;
	
                                }
	
                            }
                        })
                    }

                    if(isSourceOp){
                        sourceOps.push(op);
                    }
	
                    if(!foundSourceOps && sourceOps.length==0){
                        priorOps.push(op);
                    }


                })

            	

            let priorDelta = new Delta(priorOps);
            let sourceDelta = new Delta(sourceOps);	
	
            let sourceIndex = priorDelta.length();
            let sourceLength = sourceDelta.length();

            let updateSourceDelta = new Delta([
                {
                    "retain": sourceIndex
                },
                {
                    "retain": sourceLength,
                    "attributes": {
                        "editingMovedTombstoneBlot":  {
                            "uuid": tombstoneFormatProperties.uuid,
                            "oppositeUuid": tombstoneFormatProperties.oppositeUuid,
                            "accepted": tombstoneFormatProperties.accepted,
                            "rejected": tombstoneFormatProperties.rejected,
                            "editorPinned": tombstoneFormatProperties.editorPinned,
                            "requestorPinned": tombstoneFormatProperties.requestorPinned,
                            "editorComment": tombstoneFormatProperties.editorComment,
                            "sourcePartId": tombstoneFormatProperties.sourcePartId,
                            "destinationPartId": documentPart.id
                        }
                    }
                }
            ]);	
	
            finalDestinationDelta = finalDestinationDelta.compose(updateSourceDelta);

            
            //sourceDocumentPart.appendDelta(updateSourceDelta);
            quill.setSelection(sourceIndex, sourceLength);
            	

            bookStore.inTransitMovedEdit = null;
            bookStore.inTransitSourceRange = null;
            bookStore.inTransitSourceDocumentPart = null;

            documentPart.realTimeUpdateTimestamp = Date.now();
            bookStore.editingChangeNotesTimestamp = Date.now();

            */

        }





        else {



            //***********************************************************************
            // Moving a destination could involve up to three documentParts changed.
            // 1: Destination source documentPart
            // 2: Destination new-destination documentPart
            // 3: Tombstone
            // First step is to get all of the document parts for each.
            // We know that the source and new-destination are not the same from the
            // else statement we are working within ( this helps us to know which
            // deltas need to be combinged into a single delta )
            //***********************************************************************


            //***********************************************************************
            // Build the delta that deletes the current destination
            //***********************************************************************
            let currentDestinationDocumentPart = inTransitSourceDocumentPart;
            let currentDestinationRange = inTransitSourceRange;
            let deleteCurrentDestinationDelta = new Delta().retain(currentDestinationRange.index).delete(inTransitMovedEdit.length());

            sourceChangeDelta = deleteCurrentDestinationDelta;
            sourceChangeDocumentPart = currentDestinationDocumentPart;

            //***********************************************************************
            // Build the delta that inserts the destination delta at its new location
            //***********************************************************************
            let finalDestinationDocumentPart = documentPart;
            let finalDestinationRange = range;
            let insertFinalDestinationDelta = new Delta();
            if (finalDestinationRange.index > 0) {

                insertFinalDestinationDelta.retain(finalDestinationRange.index);

            }

            insertFinalDestinationDelta = new Delta(insertFinalDestinationDelta.ops.concat(inTransitMovedEdit.ops));

            insertFinalDestinationDelta = this.setBlotDestinationPartId({
                delta: insertFinalDestinationDelta,
                partId: finalDestinationDocumentPart.id
            });
            destinationChangeDelta = insertFinalDestinationDelta;




            //***********************************************************************
            // Build the delta that modifies the tombstone's destination partId
            //***********************************************************************
            let {
                sourcePartId,
                destinationPartId,
                uuid: destinationUuid,
                oppositeUuid: tombstoneUuid

            } = this.getBlotData({
                delta: destinationChangeDelta
            });

            tombstoneDocumentPart = bookDraft.getBookDraftDocumentPartByPartId(sourcePartId).documentPart;
            let {
                beforeDelta: tombstoneBeforeDelta,
                foundDelta: tombstonefoundDelta,
            } = new DeltaManager().findBlot({
                //delta:finalDeltaOps,
                documentPart: tombstoneDocumentPart,
                uuid: tombstoneUuid
            });

            let tombstoneIndex = tombstoneBeforeDelta.length();

            //If the new location is before the tombstone in the same documentPart...
            if (tombstoneDocumentPart.id == documentPart.id && tombstoneBeforeDelta.length() >= range.index) {
                tombstoneIndex = tombstoneBeforeDelta.length() + selectedContent.length()
            }
            
            //else If the source content is being moved away from in front of the tombstone in the same documentPart...
            else if (tombstoneDocumentPart.id != documentPart.id && tombstoneBeforeDelta.length() >= sourceRange.index) {
                tombstoneIndex = tombstoneBeforeDelta.length() - inTransitMovedEdit.length()
            }



            tombstoneDeleteDelta = new Delta().retain(tombstoneIndex).delete(tombstonefoundDelta.length());

            let modifiedTombstoneDelta = this.setBlotDestinationPartId({
                delta: tombstonefoundDelta,
                partId: finalDestinationDocumentPart.id
            });

            tombstoneInsertDelta = new Delta().retain(tombstoneIndex);

            tombstoneInsertDelta = new Delta(tombstoneInsertDelta.ops.concat(modifiedTombstoneDelta.ops));

            //console.log(tombstoneInsertDelta);




        }


        bookStore.inTransitMovedEdit = null;
        bookStore.inTransitSourceRange = null;
        bookStore.inTransitSourceDocumentPart = null;

        return {
            sourceChangeDelta,
            sourceChangeDocumentPart,
            destinationChangeDelta,
            tombstoneDocumentPart,
            tombstoneDeleteDelta,
            tombstoneInsertDelta
        };

    },



    getBlotData: function ({
        delta
    }) {

        let results = {

        };

        delta.ops.forEach((op) => {

            let blotData = null;
            if (op.hasOwnProperty("attributes") && op.attributes.hasOwnProperty("editingMovedDestinationBlot")) {

                results = op.attributes.editingMovedDestinationBlot;


            }
            if (op.hasOwnProperty("attributes") && op.attributes.hasOwnProperty("editingMovedTombstoneBlot")) {

                results = op.attributes.editingMovedTombstoneBlot;

            }



        })

        return results;

    },

    setBlotDestinationPartId: function ({
        delta,
        partId
    }) {

        delta.ops.forEach((op) => {

            let blotData = null;
            if (op.hasOwnProperty("attributes") && op.attributes.hasOwnProperty("editingMovedDestinationBlot")) {

                blotData = op.attributes.editingMovedDestinationBlot;


            }
            if (op.hasOwnProperty("attributes") && op.attributes.hasOwnProperty("editingMovedTombstoneBlot")) {

                blotData = op.attributes.editingMovedTombstoneBlot;

            }

            if (blotData) {
                blotData.destinationPartId = partId;
            }

        })

        return delta;

    }











}