import React, { useState, useEffect } from "react";
import { Link, Avatar, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import StandardUIDialog from "../../UI/StandardUIDialog";
import StandardUIButton from "../../UI/StandardUIButton";
import ReactTimeAgo from "react-time-ago";
import { useGetBookDraftBuildRequests } from "../../../managers/ExportsManager";
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import EmptyContent from "../../EmptyContent";
import ListItemButton from '@mui/material/ListItemButton';
import GoogleIcon from '@mui/icons-material/Google';


let missingBookImage = require('../../../assets/images/image-not-found.png')



const OwnedBookExportCard = (props) => {


    let [buildTypes, setBuildTypes] = useState({});
    let { book, showListIcon = true, selectedExport } = props;
    //let book = props.stores.bookStore.bookListBook;


    const { buildRequests, loading, error, client } = useGetBookDraftBuildRequests(props, book?.primaryBookDraftId);

    const CREATE_BOOK = gql` mutation RequestbuildBatch(
        $bookDraftId: String!,
        $buildTypes: [String]!
    ) {
        requestbuildBatch(input: {
            bookDraftId: $bookDraftId, 
            buildTypes: $buildTypes
        }) {
            id
            ownerId
            buildTypes
            completedAt
            createdAt

        }
    }`;


    const [
        requestbuildBatch,
        { data: mutationData, loading: mutationLoading, error: mutationError }] =
        useMutation(CREATE_BOOK);



    const GET_PRESIGNED_URL = gql`
    query getPresignedUrl($key: String!) {
      getPresignedUrl(key: $key)
              }
    `;


    useEffect(() => {

        //console.log(buildRequests)

    }, [buildRequests]);



    const generateSecondaryActions = (buildRequests) => {

        const secondaryActions = [];

        if (showListIcon) {
            secondaryActions.push(<IconButton
                onClick={props.onListClick}>
                <FormatListBulletedIcon />
            </IconButton>)
        }
        return secondaryActions;
    }

    const [showExportList, setShowExportList] = useState(false);
    const handleExportListOpen = () => {
        setShowExportList(true);
    }

    const handleExportListClose = () => {
        setShowExportList(false);
    }





    return (
        <div>
            <List >
                {buildRequests && buildRequests.length > 0 ?
                    <div>hi</div>
                    :
                    <EmptyContent tagline={'Export your book!'} />
                }
            </List>
        </div>
    )
}

export default inject('stores')(observer(OwnedBookExportCard));