import Quill from 'quill';
//import ReactQuill, { Quill } from 'react-quill';
let Block = Quill.import('blots/block');

let Parchment = Quill.import("parchment");

class SubheadBlot extends Block { }
SubheadBlot.blotName = 'subhead';
SubheadBlot.tagName = 'div';
SubheadBlot.className = 'subhead';

export default SubheadBlot ;
