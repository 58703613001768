import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Image from 'mui-image'
import { Typography } from '@mui/material';
import StandardHelpLabel from './StandardHelpLabel';
import Button from '@mui/material/Button';
import { useDropzone } from 'react-dropzone';
import { useUpdateItem } from '../persistance/persistanceHooks';
import config from '../aws-exports';
import BaseControl from './formatting/propertyElements/BaseControl';
import ImageIcon from '@mui/icons-material/Image';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import helpers from '../helpers/helpers';

let AWS = require("aws-sdk");

const StandardBlotImageSelector = (props) => {

    let userId = props.stores.userStore.user.id;
    const [updateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] = useUpdateItem();

    let { entity, imageKeyFieldName, showInformation = true, documentPart } = props;

    let baseControl = new BaseControl(props);

    let convertedImageKeyFieldName = imageKeyFieldName;
    if (documentPart) {

        convertedImageKeyFieldName = baseControl.convertTitle(imageKeyFieldName, documentPart);

    }

    // const item = {
    //     img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    //     title: 'Breakfast',
    //     rows: 2,
    //     cols: 2,
    // }

    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        maxFiles: 1,
        maxSize: 5000000
    });


    //let bucketName = "local-bookmerlincicd8-useruploadedcontents3bucket-1l2gynpa7rn0k";
    let bucketName = config.user_uploads_bucket_name;
    let bucketRegion = config.user_uploads_bucket_region;//"us-east-1";
    let IdentityPoolId = config.identity_pool_id
    //let IdentityPoolId = "us-east-1:df19ecd0-c12a-4c26-bcec-be786f62f103";

    AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: IdentityPoolId
        })
    });

    let s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: bucketName }
    });


    const uploadFile = (file) => {
        
		
		
        let fileName = file.name;
        let s3Key = helpers.createUserImageUploadS3Key(userId, fileName);
        //console.log(s3Key);
        // Use S3 ManagedUpload class as it supports multipart uploads
        let upload = new AWS.S3.ManagedUpload({
            params: {
                Bucket: bucketName,
                Key: s3Key,
                Body: file,
                ACL: 'public-read'
            }
        });

        let promise = upload.promise();

        promise.then(
            function (data) {
                //console.log("Successfully uploaded photo.");
                // entity[convertedImageKeyFieldName] = fileKey;

                // //console.log(entity);
                // updateItem({
                //     itemKey: entity.id,
                //     itemType: entity.modelType ? entity.modelType : entity.__typename,
                //     fieldName: convertedImageKeyFieldName,
                //     fieldValue: fileKey
                // })

                if (props.onImageUploaded) {
                    props.onImageUploaded(s3Key);
                }


                //viewAlbum(albumName);
            },
            function (err) {
                //console.log(err);
                //return alert("There was an error uploading your photo: ", err.message);
            }
        );
    }



    const getFolderContents = (folder) => {
        s3.listObjects({
            Bucket: bucketName,
            Prefix: folder + '/',
            Delimiter: "/"
        }, function (err, data) {
            if (err) {
                return alert("There was an error listing your albums: " + err.message);
            } else {
                // let albums = data.CommonPrefixes.map(function (commonPrefix) {
                //     let prefix = commonPrefix.Prefix;
                //     let albumName = decodeURIComponent(prefix.replace("/", ""));
                //     return <div>{albumName}</div>
                // });

                let files = data.Contents.filter(f => f.Key != folder + '/');
                //console.log(data);
                //console.log(files);
                // let message = albums.length
                //   ? getHtml([
                //       "<p>Click on an album name to view it.</p>",
                //       "<p>Click on the X to delete the album.</p>"
                //     ])
                //   : "<p>You do not have any albums. Please Create album.";
                // let htmlTemplate = [
                //   "<h2>Albums</h2>",
                //   message,
                //   "<ul>",
                //   getHtml(albums),
                //   "</ul>",
                //   "<button onclick=\"createAlbum(prompt('Enter Album Name:'))\">",
                //   "Create New Album",
                //   "</button>"
                // ];
                // document.getElementById("app").innerHTML = getHtml(htmlTemplate);
            }
        });
    }




    useEffect(() => {

        if (acceptedFiles) {
            const files = acceptedFiles.map(file => (
                <li key={file.path}>
                    {file.path} - {file.size} bytes
                </li>
            ));

            if (acceptedFiles.length > 0) {
                uploadFile(acceptedFiles[0]);
            }

        }


    }, [acceptedFiles]);


    // let src={entity?`https://local-bookmerlincicd8-useruploadedcontents3bucket-1l2gynpa7rn0k.s3.amazonaws.com/${entity[imageKeyFieldName]}`:null}
    //             //src={entity?`${config.user_uploads_bucket_name}/${entity[imageKeyFieldName]}`:null}

    // let fieldName = imageKeyFieldName;
    // if(documentPart){

    //     fieldName = imageKeyFieldName.replace(/---PART_TYPE---/gi, baseControl.toCappedPartType(documentPart));
    //     fieldName = fieldName.replace(/---PART_TYPE_LOWER---/gi, documentPart.partType.toLowerCase());

    // }


    let src = null;
    if (entity) {
        src = 'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + entity[convertedImageKeyFieldName];
    }


    return (
        <div sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', height: '100%' }}>
            {/*https://www.npmjs.com/package/mui-image*/}


            {showInformation &&
                <StandardHelpLabel title={convertedImageKeyFieldName} />
            }
            {/* <Button onClick={() => {
                getFolderContents('folder1');
            }}>
                list
            </Button> */}
            {/* <Button type="button" onClick={open}>
                Open File Dialog
            </Button> */}
            <input {...getInputProps()} />
            {/* <Image
                sx={{ display: { sm: 'block', lg: 'block', md: 'block' } }}
                style={{ borderRadius: 2 }}
                className="custom-class"
                onClick={open}
                //src={entity?`https://local-bookmerlincicd8-useruploadedcontents3bucket-1l2gynpa7rn0k.s3.amazonaws.com/${entity[imageKeyFieldName]}`:null}
                //src={entity?`https://${config.user_uploads_bucket_name}/${entity[imageKeyFieldName]}`:null}
                src={src}
                
                showLoading
                errorIcon /> */}


            {/* <img
                onClick={open}
                src={src}
                width='50px'
                height='50px'

            /> */}


            <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Upload Image</FormLabel>
                <ImageIcon color='primary'  onClick={open}/>
            </FormControl>




            {/*<ImageListItem key={item.img}>
                <img
                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                />
    </ImageListItem>*/}

        </div>
    );
};


export default inject('stores')(observer(StandardBlotImageSelector));
