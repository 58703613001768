import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import AppWrapper from '../components/AppWrapper';
import CheckoutForm from '../components/stripe/CheckoutForm';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import config from '../config';
import { useGetPurchaseLink } from '../managers/PaymentManager';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_7yPJSuN6e8juFqqs9nIfbeP300KRqR7dHC");



const Purchase = (props) => {
  const [clientSecret, setClientSecret] = useState("");
  const [product, setProduct] = useState();
  const [plan, setPlan] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  let {
    lazyGetPurchaseLink,loading, error, data
  } = useGetPurchaseLink();

  let navigate = useNavigate();
  let location = useLocation();


  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };


  const isBasicPlan = () =>{

    let s = product + '_' + plan;
    if( s && ( s=='BASIC_MONTHLY') ||  ( s=='BASIC_YEARLY')){

      return true;
    }

    return false;

  }

  useEffect(()=>{

    if(data){
      console.log(data);

      try{

        document.location = JSON.parse(data.getPurchaseLink).checkoutUrl;
      }catch(err){
        console.log(err);
      }
    }
  },[data])


  useEffect(() => {

    
    try {

      if(product && plan){
        lazyGetPurchaseLink({variables: {
          productId: product,
          planId: plan
        }}         
        );
      }
      

      if(isBasicPlan()){
        navigate("/");
      }
      let purchaseLink = config.stripe.purchaseLinks[product + '_' + plan];
      if (purchaseLink) {

        //document.location = purchaseLink;
      }

    } catch (err) {
      console.log(err);
    }

  },[product,plan]);

  useEffect(() => {

    console.log(searchParams);
    setProduct(searchParams.get("product"))
    setPlan(searchParams.get("plan"))

    

  }, [searchParams])
  return (
    <>
      Here are the details...
      <Box style={{
        display: 'flex',
        flexDirection: 'column'
      }}>

        <Typography>product:{product}</Typography>
        <Typography>plan:{plan}</Typography>
      </Box>
      {/* {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )} */}
    </>


  )
}
export default inject('stores')(observer(Purchase));
// export default inject('stores')(observer(Books));