import React, { useEffect, useState } from 'react';
import { styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import BaseControl from "./BaseControl";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";

function FormatParagraphAfterFirst(props) {


  const theme = useTheme();

  let baseControl = new BaseControl(props);
  let recipeParam = baseControl.getRecipeParam();

  const { classes, element, bookDraft, documentPart } = props;

  let isVisible = baseControl.isVisible();
  if (!isVisible) {
    return <div style={{
      height: '0em'
    }}></div>
  }

  let item = baseControl.getEntityItem();
  let properties_book = bookDraft.book;
  let boxSet = bookDraft.book.boxSet;

  
  let hideBookProperties = false;

  let canEdit = true;


  let isOwner = true;






  let title = baseControl.convertTitle(element.title, documentPart);


  



  return (

    <Box sx={{
     
    }}>
    
    paf
    </Box>

  );
}


export default inject('stores')(observer(FormatParagraphAfterFirst));



