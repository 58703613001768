import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import { useGetUserConnections } from '../../../managers/ConnectionsManager';
import ShareIcon from '@mui/icons-material/Share';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Avatar from '@mui/material/Avatar';
import CircleAvatar from "../../lists/CircleAvatar";
import UserAvatar from "../../lists/UserAvatar";
import helpers from '../../../helpers/helpers';
import ReactTimeAgo from 'react-time-ago';
import EmptyContent from "../../EmptyContent";
import UserListItem from "./UserListItem";


const ListConnections = (props) => {

    let user = props.stores.userStore.user;

    let { filteredConnections, showShareIcon = true, onListSelectUser, selectedUserConnection, order = 'desc', orderBy = 'createdAt' } = props;
    const { userConnections, error: getUserConnectionsError } = useGetUserConnections(props);
    // const { userGroups, error: getUserUserGroupsError, loading: getUserUserGroupsLoading } = useGetUserGroups(props);


    let remainingUserConnections = userConnections;

    if (filteredConnections) {

        //remainingUserConnections = userConnections.filter((el) => !filteredConnections.includes(el));

        remainingUserConnections = userConnections.filter(userConnection => !filteredConnections.find(rm => (rm.id === userConnection.connectedUser.id)))

    }


    remainingUserConnections = helpers.stableSort(remainingUserConnections, order, orderBy);




    const generateSecondaryActions = (userConnection) => {

        let secondaryActions = [];

        if (showShareIcon) {
            secondaryActions.push(<IconButton
                onClick={(e) => {
                    e.preventDefault();
                    props.onSelectUser(userConnection);
                }}>
                <ShareIcon />
            </IconButton>)
        }
        return secondaryActions;
    }






    return (<List sx={{ width: '100%' }}>
        {remainingUserConnections && remainingUserConnections.length > 0 ?
            remainingUserConnections.map((userConnection, index) => {


                //console.log(index);
                let selected = false;
                if (userConnection.id == selectedUserConnection?.id) {
                    selected = true;
                }


                // return <ListItem
                //     selected={selectedIndex}
                //     sx={{ padding: ' 0.25em' }}
                //     secondaryAction={
                //         generateSecondaryActions(userConnection)
                //     }
                // >
                //     <ListItemAvatar>
                //         <UserAvatar
                //             s3Src={userConnection?.connectedUser?.profilePicUrl} 
                //         />
                //     </ListItemAvatar>
                //     <ListItemText
                //         primary={userConnection?.connectedUser?.name}
                //         secondary={userConnection.createdAt != null ? <ReactTimeAgo date={userConnection.createdAt} locale="en-US" /> : ''}
                //         onClick={(e) => {
                //             e.preventDefault();
                //             props.onListSelectUser(userConnection);
                //         }}
                //     />

                // </ListItem>
                return <UserListItem
                    onSelected={()=>{
                        props.onListSelectUser(userConnection);
                    }}
                    selected={selected}
                    user={userConnection?.connectedUser}
                    secondaryActions={
                        generateSecondaryActions(userConnection)
                    }
                    secondaryText={userConnection.createdAt != null ? <>Connected: <ReactTimeAgo date={userConnection.createdAt} locale="en-US" /> </> : ''}
                />
            })
            :
            <EmptyContent tagline={'Make your first connection!'} />

        }
    </List>

    )
}

export default inject('stores')(observer(ListConnections));