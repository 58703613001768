import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import { useLoadBookEditRequests, useOpenEditRequestInEdit } from '../../managers/EditRequestsManager';
import { useManagerLoadBoxSetContributors } from '../../managers/BoxsetsManager';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useGetBookShares, useManagerDeleteBookUserShare, useManagerDeleteBookUserGroupShare } from '../../managers/BooksManager';

const ContributorsList = (props) => {

  let user = props.stores.userStore.user;

  let { book } = props;

  let [showRemoveContributorDialog, setShowRemoveContributorDialog] = useState(false);
  let [selectedContributor, setSelectedContributor] = useState(false);
  
  let {
    boxsetContributors,
    loading: lazyLoadBoxSetContributorsLoading,
    error: lazyLoadBoxSetContributorsError,
    refetch: lazyLoadBoxSetContributorsRefetch
  } = useManagerLoadBoxSetContributors(props, book?.boxSet.id);

  const createSecondaryActions = (editRequest) => {

    let secondaryActions = [];

    if (props.showSelectIcon) {
      secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
        // openEditRequestInEdit({
        //   editRequest
        // });
      }}>
        <AccountEdit />
      </IconButton>);
    }

    return secondaryActions;
  }

  return (
    <List component="div" disablePadding>
      {
        boxsetContributors.map((boxsetContributor) => {

          return <ListItem
            secondaryAction={createSecondaryActions(boxsetContributor)}
          >
            <ListItemAvatar>
              <AvatarTableCell
                bucketName={config.user_uploads_bucket_name}
                s3Src={boxsetContributor.contributor.profilePicUrl}
                title={boxsetContributor.contributor.name}
              />
            </ListItemAvatar>
            {/* <ListItemText primary={userConnection?.connectedUser?.name} secondary="" /> */}

          </ListItem>
        })
      }
    </List>

  )
}

export default inject('stores')(observer(ContributorsList));