import React, { useState, useEffect } from "react";
import { inject, observer } from 'mobx-react';
import { useManagerCreateBook } from '../../../managers/BooksManager';
import { useDropzone } from 'react-dropzone';
import { useImportBookDraftDocument } from '../../../persistance/persistanceHooks';
import StorageManager, { useUploadBucketItem } from '../../../managers/StorageManager';
import config from '../../../aws-exports';
import StandardUIDialog from "../../UI/StandardUIDialog";
import StandardUIButton from "../../UI/StandardUIButton";
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Typography from '@mui/material/Typography';
import StandardUITextField from "../../UI/StandardUITextField";
import { useManagerCreateBoxset } from '../../../managers/BooksManager';

const CreateBoxset = (props) => {

    let [title, setTitle] = useState(null);

    let {
        createBoxset,
        createBoxsetLoading,
        createBoxsetError
    } = useManagerCreateBoxset();


    const handleTitleChange = (e) => {
        e.preventDefault();

        setTitle(e.target.value);
    }

    const handleCreateBoxset = () => {

        createBoxset({
            title,
            stores: props.stores
        }, () => {
            props.onClose();
        })

    }

    return (
        <StandardUIDialog
            open={props.open}
            onClose={props.onClose}
            title='Create a Boxset'
            dialogContent={
                <Box>
                    <StandardUITextField label='Title' onChange={handleTitleChange} />
                </Box>
            }
            cardActions={
                <StandardUIButton
                    buttonAction='Create'
                    autoFocus
                    onClick={(e) => {
                        e.preventDefault();
                        handleCreateBoxset();
                    }}
                />
            }
        />
    )
}

export default inject('stores')(observer(CreateBoxset));