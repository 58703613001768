import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import StandardButton from '../StandardButton';
import StandardDialog from '../StandardDialog';
import CreateSnapshotPanel from '../panels/CreateSnapshotPanel';
import CreateBoxsetDialog from '../panels/CreateBoxsetDialog';
import StandardOkCancelDialog from '../panels/StandardOkCancelDialog';

const CreateBoxsetAssembly = (props) => {

    let [showCreateBoxsetDialog, setShowCreateBoxsetDialog] = useState(false);
    let { boxset } = props

    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    const theme = useTheme();

    return (
        <>
            <Box sx={{
                display: 'flex',
                //justifyContent: 'flex-end',
                //width: '100%',
                //border:'1px solid yellow'
            }}>
                <Button variant="contained" sx={{
                    color: '#ffffff',
                    backgroundColor: theme.palette.secondary.main
                }}
                    onClick={(e) => {
                        e.preventDefault();
                        setShowCreateBoxsetDialog(true);
                    }}>Create Boxset</Button>
                {/* <StandardButton variant="contained" sx={{
                    color: '#ffffff',
                    backgroundColor: theme.palette.secondary.main
                }}
                >Create Snapshot</StandardButton> */}

            </Box>

            <StandardOkCancelDialog
                open={showCreateBoxsetDialog}
                onClose={() => {
                    setShowCreateBoxsetDialog(false)
                }}
                onOk={() => {
                    setShowCreateBoxsetDialog(false)

                }}
                title={'Create a Boxset'}
                content={
                    <CreateBoxsetDialog
                        open={showCreateBoxsetDialog}
                        onClose={(boxset) => {
                            setShowCreateBoxsetDialog(false);

                        }}

                        onBookCreated={(boxset) => {
                            setShowCreateBoxsetDialog(false);

                            // openBookInWrite({
                            //     bookDraftId: book.primaryBookDraftId
                            // });

                        }}


                    />
                }
            />
        </>


    )
}

export default inject('stores')(observer(CreateBoxsetAssembly));