import React, { useState, useEffect } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useAddBookDraftDocumentPart, useMoveBookDraftDocumentPart } from '../../persistance/persistanceHooks';
//import StandardTree from './StandardTree';
import { bookDraftTree2 } from './bookDraftTreeBuilder2';
import config from '../../config';
//import BoxSetContributedBookSelectorDialog from '../dialogs/BoxSetContributedBookSelectorDialog';
//import './antd-dark.css';
//import 'antd/dist/antd.variable.min.css';
import { Tree } from 'antd';
import { Menu, Dropdown } from 'antd';
import ContextMenu from './ContextMenu';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useUpdateItem } from '../../persistance/persistanceHooks';
import EntityManager from '../../domain/EntityManager';
import { Box, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import StandardDialog from '../StandardDialog';
import InsertBoxsetBookPanel from '../panels/InsertBoxsetBookPanel';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TreeNode from './TreeNode';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { useMediaQuery } from 'react-responsive'
import CameraOutline from 'mdi-material-ui/CameraOutline';
import AnimationOutline from 'mdi-material-ui/AnimationOutline';
import Database from 'mdi-material-ui/Database';
import Book from 'mdi-material-ui/Book';
import BookOutline from 'mdi-material-ui/BookOutline';
import BookOpenVariant from 'mdi-material-ui/BookOpenVariant';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import FileDocumentEdit from 'mdi-material-ui/FileDocumentEdit';
import FolderOutline from 'mdi-material-ui/FolderOutline';
import VideoOutline from 'mdi-material-ui/VideoOutline';
import StandardSkeleton from '../../StandardSkeleton';
import { StandardPaper } from '../StandardPaper';
import ScrollingGridContainer from '../ScrollingGridContainer';
import ScrollingGridItem from '../ScrollingGridItem';
import StretchBox from '../boilerPlate/StretchBox';
import ScrollBox from '../boilerPlate/ScrollBox';
import TreeView from './reactDndTreeView/TreeView';
import { useMoveDocumentPart } from "../../managers/BooksManager";
import { PART_TYPES } from '../../helpers/documentPartTypes';

const ItemTypes = {
    KNIGHT: 'knight'
}

const wrapIcon = (icon, theme) => {

    return <div style={{
    }}>
        <span style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '.5em',
            color: theme.palette.standardTree.nodes.color.main
        }}>{icon}</span>
    </div>


}

const BookDraftTree7 = (props) => {


    let { bookDraft, selectedId } = props;

    let userId = props.stores.userStore?.user?.id;

    let writingBookDraft = props.bookDraft;
    let writingBookDraft2 = props.bookDraft2;

    let [nodes, setNodes] = useState([]);
    let [items, setItems] = useState([]);
    let [treeViewItems, setTreeViewItems] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    let [contextSelectedBddp, setContextSelectedBddp] = useState();
    const [createDocumentPartBatch, { data: mutationData, loading: mutationLoading, error: mutationError }] = useAddBookDraftDocumentPart();
    const [showAddBookDialog, setShowAddBookDialog] = React.useState(null);
    const { moveDocumentPart,
        loading: moveBookDraftDocumentPartLoading,
        error: moveBookDraftDocumentPartError } = useMoveDocumentPart(props);

    const theme = useTheme();
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        //console.log(event);
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => setAnchorEl(null);



    const isXs = useMediaQuery({ maxWidth: 599 })
    const isSm = useMediaQuery({ maxWidth: 600 })
    const isMd = useMediaQuery({ maxWidth: 900 })
    const isLg = useMediaQuery({ maxWidth: 1200 })
    const isXl = useMediaQuery({ minWidth: 1536 })


    const partTypeIcons = {
        BOXSETS: wrapIcon(<AnimationOutline />, theme),
        BOXSET: wrapIcon(<AnimationOutline sx={{
            color: theme.palette.standardTree.heading.color.main
        }} />, theme),
        TOPBOOK: wrapIcon(<BookOutline color={theme.palette.text.main} />, theme),
        BOOKS: wrapIcon(<BookOutline />, theme),
        BOOK: wrapIcon(<BookOpenVariant sx={{
            color: theme.palette.standardTree.heading.color.main
        }} />, theme),
        VOLUME: wrapIcon(<FolderOutline />, theme),
        PART: wrapIcon(<FolderOutline />, theme),
        CHAPTER: wrapIcon(<FolderOutline />, theme),
        SCENE: wrapIcon(<VideoOutline />, theme),
        SNAPSHOTS: wrapIcon(<CameraOutline />, theme),
        SNAPSHOT: wrapIcon(<CameraOutline />, theme),
        TRASH: wrapIcon(<TrashCanOutline />, theme),
        RESEARCH: wrapIcon(<FolderOutline />, theme),
        FOLDER: wrapIcon(<FolderOutline />, theme),
        EDIT: wrapIcon(<AccountEdit />, theme),
        SNAPSHOT: wrapIcon(<CopyAllIcon />, theme),
        //PDF:wrapIcon(<FilePdf />), theme,
        //WEBPAGE:wrapIcon(<Web />, theme),
        DEFAULT: wrapIcon(<FileDocumentEdit />, theme),


        //DEFAULT:<span></span>

    }










    const onContextMenuSelected = (menuId) => {
        //console.log(menuId)
        if (contextSelectedBddp) {

            let partType = menuId;
            // let bookDraftId = writingBookDraft.id;
            // let bookId = writingBookDraft.book.id;

            let bookDraftId = contextSelectedBddp.bookDraft.id;
            let bookId = contextSelectedBddp.bookDraft.book.id;


            let parentPartId = contextSelectedBddp?.partId;
            let previousBddp = contextSelectedBddp.lastPart;
            let starterDeltaOps = [];
            let deltaOpsJson = [];
            //let starterEscapedDeltaOps = [];


            try {

                let starterTemplatesBookDraft = props.stores.bookStore.startersBookDraft;
                let starterTemplateDocumentPart = starterTemplatesBookDraft.getBookDraftDocumentPartByPartId(config.STARTER_TEMPLATES[partType]);
                //console.log(starterTemplateDocumentPart);
                deltaOpsJson = starterTemplateDocumentPart.documentPart.deltaOps;
                starterDeltaOps = starterTemplateDocumentPart.documentPart.deltaOps.map((m) => {
                    return JSON.stringify(m);
                })
                //console.log(starterDeltaOps);
                // starterEscapedDeltaOps = starterTemplateDocumentPart.documentPart.deltaOps.map((m) => {
                // 	let s = JSON.stringify(m);
                // 	//s = s.replace(/""/g,'\"');
                // 	//s = s.replace(/\"/g,'\\"');
                // 	return s;
                // })

                // deltaOpsJson = JSON.stringify(deltaOpsJson).replace('"', '\"');

            } catch (err) {
                //console.log(err);
            }

            if (partType == 'ADD_BOOK') {

                setShowAddBookDialog(true);


            }

            else if (menuId && menuId == 'DELETE') {
                alert('Delete this item');
            }
            else {
                createDocumentPartBatch({
                    userId,
                    partType,
                    bookDraftId,
                    bookId,
                    parentPartId,
                    starterDeltaOps,
                    prevPartId: previousBddp?.partId

                }, (newBddp) => {

                    writingBookDraft.addBookDraftDocumentPart({
                        data: newBddp
                    });
                    props.stores.bookStore.recipeTimestamp = Date.now();


                });
            }


        }
    }






    const onContextSelected = (id) => {
        let bddp = writingBookDraft.getBookDraftDocumentPartByPartId(id);
        if (!bddp) {
            bddp = writingBookDraft2.getBookDraftDocumentPartByPartId(id);
        }
        setContextSelectedBddp(bddp)
    }




    const createChildItems = ({
        parentBddp
    }) => {

        //let rootChildBddps = bookDraft.bookDraftDocumentParts.filter((bddp) => bddp.parentPartId == parentBddp.partId);

        let childNodes = parentBddp.getSortedChildren().map((bddp) => {

            let grandChildNodes = createChildItems({
                parentBddp: bddp,
            })

            // return <TreeNode title={bddp.documentPart.title} childNodes={grandChildNodes} />
            let icon = partTypeIcons[bddp.documentPart.partType] != null ? partTypeIcons[bddp.documentPart.partType] : partTypeIcons['DEFAULT'];

            let observableDocumentPart = new observable(bddp.documentPart)

            let documentPart = bookDraft.getDocumentPartById(bddp.partId);

            return {

                id: bddp.documentPart.id,
                type: bddp.documentPart.partType,
                name: documentPart.derivedTitle,
                bddp: bddp,
                items:
                    createChildItems({
                        parentBddp: bddp,
                    }),
                content: documentPart.derivedTitle,
                onDocumentPartSelected: (e) => {

                    //props.onDocumentPartSelected(bddp);

                    props.onDocumentPartSelected({
                        id: bddp.partId
                    });
                }


            }

            // return <DragBox
            //     pojo={bddp}
            //     key={'dragged' + bddp.documentPart.id}
            //     name={bookDraft?.book.title}
            //     component={
            //         <TreeNode
            //             pojo={bddp}
            //             icon={icon}
            //             selected={(selectedId && selectedId == bddp.documentPart.id) ? true : false}
            //             //expanded={bddp?.expanded}
            //             item={bddp}
            //             toggleExpanded={() => {
            //                 bddp.expanded = !bddp.expanded
            //             }}
            //             //key={'treeNode'+bddp.documentPart.id} 
            //             title={documentPart.derivedTitle}
            //             documentPart={bddp.documentPart}
            //             onClick={(e) => {

            //                 //props.onDocumentPartSelected(bddp);

            //                 props.onDocumentPartSelected({
            //                     id: bddp.partId
            //                 });
            //             }}
            //             childNodes={grandChildNodes}
            //             onMoved={({
            //                 dragged,
            //                 dropped,
            //                 position }) => {

            //                 bookDraft.moveBookDraftDocumentPart({
            //                     movedBddp: dragged,
            //                     targetBddp: dropped,
            //                     position
            //                 })

            //             }}
            //             onContextMenu={(e) => {
            //                 e.preventDefault();
            //                 e.stopPropagation();
            //                 //console.log(e);
            //                 handleClick(e);
            //                 onContextSelected(bddp.partId)



            //             }}
            //         />} />




        });

        return childNodes;

    }


    const createChildNodes = ({
        parentBddp
    }) => {

        //let rootChildBddps = bookDraft.bookDraftDocumentParts.filter((bddp) => bddp.parentPartId == parentBddp.partId);

        let childNodes = parentBddp.getSortedChildren().map((bddp) => {

            let grandChildNodes = createChildNodes({
                parentBddp: bddp,
            })

            // return <TreeNode title={bddp.documentPart.title} childNodes={grandChildNodes} />
            let icon = partTypeIcons[bddp.documentPart.partType] != null ? partTypeIcons[bddp.documentPart.partType] : partTypeIcons['DEFAULT'];

            let observableDocumentPart = new observable(bddp.documentPart)

            let documentPart = bookDraft.getDocumentPartById(bddp.partId);

            return <Box
                pojo={bddp}
                key={'dragged' + bddp.documentPart.id}
                name={bookDraft?.book.title}
                component={
                    <TreeNode
                        pojo={bddp}
                        icon={icon}
                        selected={(selectedId && selectedId == bddp.documentPart.id) ? true : false}
                        //expanded={bddp?.expanded}
                        item={bddp}
                        toggleExpanded={() => {
                            bddp.expanded = !bddp.expanded
                        }}
                        //key={'treeNode'+bddp.documentPart.id} 
                        title={documentPart.derivedTitle}
                        documentPart={bddp.documentPart}
                        onClick={(e) => {

                            //props.onDocumentPartSelected(bddp);

                            props.onDocumentPartSelected({
                                id: bddp.partId
                            });
                        }}
                        childNodes={grandChildNodes}
                        onMoved={({
                            dragged,
                            dropped,
                            position }) => {

                            bookDraft.moveBookDraftDocumentPart({
                                movedBddp: dragged,
                                targetBddp: dropped,
                                position
                            })

                        }}
                        onContextMenu={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            //console.log(e);
                            handleClick(e);
                            onContextSelected(bddp.partId)



                        }}
                    />} />




        });

        return childNodes;

    }




    const createChildViewItems = (parentBddp, treeViewItems) => {

        let childItems = parentBddp.getSortedChildren().map((bddp) => {

            // if (bddp.documentPart.id != rootBddp.documentPart.id) {
            let prevBddp = bookDraft.getBookDraftDocumentPartByPartId(bddp.prevPartId);
            let prevTitle = '';
            if (prevBddp != null) {
                prevTitle = prevBddp.documentPart.derivedTitle;
            }
            treeViewItems.push({
                id: bddp.documentPart.id,
                parent: bddp.parentPartId,
                droppable: isDroppable(bddp),
                partType:bddp.documentPart.partType,
                // icon: <TypeIcon droppable={isDroppable(bddp)} bddp={bddp}/>,
                icon: partTypeIcons[bddp.documentPart.partType] != null ? partTypeIcons[bddp.documentPart.partType] : partTypeIcons['DEFAULT'],
                text: bddp.documentPart.derivedTitle,// + '-prev:' + prevTitle,
                data: {
                    onDocumentPartSelected: (e) => {

                        //props.onDocumentPartSelected(bddp);

                        props.onDocumentPartSelected({
                            id: bddp.partId
                        });
                    }
                }
            });

            createChildViewItems(bddp, treeViewItems);
            // }

        });

        return childItems;
    }

    useEffect(() => {



        if (bookDraft) {

            let rootBddp = bookDraft?.rootBookDraftDocumentPart;


            let isBoxSet = bookDraft?.book.boxSet != null ? true : false;
            let icon = isBoxSet ? partTypeIcons['BOXSET'] : partTypeIcons['BOOK'];



            let rootTreeViewItem = {
                id: rootBddp.documentPart.id,
                parent: 0,
                droppable: true,
                partType:rootBddp.documentPart.partType,
                text: bookDraft?.book.title,
                icon: partTypeIcons[rootBddp.documentPart.partType] != null ? partTypeIcons[rootBddp.documentPart.partType] : partTypeIcons['DEFAULT'],
                data: {
                    onDocumentPartSelected: (e) => {

                        //props.onDocumentPartSelected(bddp);

                        props.onDocumentPartSelected({
                            id: rootBddp.partId
                        });
                    }
                }
            }

            treeViewItems = [rootTreeViewItem];
            createChildViewItems(rootBddp, treeViewItems)
            //treeViewItems = treeViewItems.concat(createChildViewItems(rootBddp));

            // rootBddp.getSortedChildren().forEach((bddp) => {

            //     // if (bddp.documentPart.id != rootBddp.documentPart.id) {

            //         treeViewItems.push({
            //             id: bddp.documentPart.id,
            //             parent: bddp.parentPartId,
            //             droppable: isDroppable(bddp),
            //             text: bddp.documentPart.derivedTitle,
            //             data: {
            //                 onDocumentPartSelected: (e) => {

            //                     //props.onDocumentPartSelected(bddp);

            //                     props.onDocumentPartSelected({
            //                         id: bddp.partId
            //                     });
            //                 }
            //             }
            //         });
            //     // }

            // })


            // bookDraft.bookDraftDocumentParts.forEach((bddp) => {

            //     if (bddp.documentPart.id != rootBddp.documentPart.id) {

            //         treeViewItems.push({
            //             id: bddp.documentPart.id,
            //             parent: bddp.parentPartId,
            //             droppable: isDroppable(bddp),
            //             text: bddp.documentPart.derivedTitle,
            //             data:{
            //                 onDocumentPartSelected: (e) => {

            //                     //props.onDocumentPartSelected(bddp);

            //                     props.onDocumentPartSelected({
            //                         id: bddp.partId
            //                     });
            //                 }
            //             }
            //         });
            //     }

            // })

            setTreeViewItems(treeViewItems);

        }

    }, [bookDraft, bookDraft?.version, selectedId]);




    const isDroppable = (bddp) => {

        if (bddp?.documentPart.partType == 'ROOT' ||
            bddp?.documentPart.partType == 'VOLUME' ||
            bddp?.documentPart.partType == 'PART' ||
            bddp?.documentPart.partType == 'CHAPTER' ||
            bddp?.documentPart.partType == 'FOLDER' ||
            bddp?.documentPart.partType == 'BOOK' ||
            bddp?.documentPart.partType == 'TOPBOOK') {
            return true;
        }

        return false;
    }



    let backend = HTML5Backend;

    if (isXs) {

        backend = TouchBackend;

    }

    if (!bookDraft) {

        return <StandardSkeleton />
    }


    const onDragEnd = (result) => {
        // Check if the drag operation was successful
        if (!result.destination) {
            return; // No valid destination, no action needed
        }

        // Perform the necessary update based on the drag result
        // For example, update the state or reorder the tasks/columns

        // Example: Update the state using useState hook
        // const [initialData, setInitialData] = useState({ ... });

        // Example: Reorder tasks within a column
        // const column = initialData.columns[result.source.droppableId];
        // const newTaskIds = Array.from(column.taskIds);
        // newTaskIds.splice(result.source.index, 1);
        // newTaskIds.splice(result.destination.index, 0, result.draggableId);
        // const newColumn = {
        //   ...column,
        //   taskIds: newTaskIds,
        // };
        // const newState = {
        //   ...initialData,
        //   columns: {
        //     ...initialData.columns,
        //     [newColumn.id]: newColumn,
        //   },
        // };

        // Example: Update the state with the new column order
        // newState.columnOrder = Array.from(initialData.columnOrder);
        // newState.columnOrder.splice(result.source.index, 1);
        // newState.columnOrder.splice(result.destination.index, 0, result.draggableId);

        // Update the state with the new data
        // setInitialData(newState);
    };

    const initialData = {
        tasks: {
            'task-1': { id: 'task-1', content: 'Take out the garbage' },
            'task-2': { id: 'task-2', content: 'Watch my favorite show' },
            'task-3': { id: 'task-3', content: 'Charge my phone' },
            'task-4': { id: 'task-4', content: 'Cook dinner' },
        },
        columns: {
            'column-1': {
                id: 'column-1',
                title: 'To do',
                taskIds: ['task-1', 'task-2', 'task-3', 'task-4'],
            },
        },
        // Facilitate reordering of the columns
        columnOrder: ['column-1'],
    };

    const getAllMoveNodeInfo = (bookDraft, moveInfo) => {

        // let allMoveNodeInfo = [];

        // let movedBddp = bookDraft.getBookDraftDocumentPartByPartId(moveInfo.dragSourceId);
        // let newParentBddp = bookDraft.getBookDraftDocumentPartByPartId(moveInfo.dropTargetId);
        // let newPrevBddp = null;
        // if (moveInfo.relativeIndex > 0) newPrevBddp = newParentBddp.getSortedChildren()[moveInfo.relativeIndex - 1]

        // let movedNodeMoveInfo = generateMovedNodeMoveInfo({ bookDraft, movedBddp, newPrevBddp, moveInfo })
        // let oldNextNodeMoveInfo = generateOldNextNodeMoveInfo({ bookDraft, movedBddp, newPrevBddp, moveInfo })
        // let newNextNodeNodeInfo = getNewNextNodeNodeInfo({ bookDraft, movedBddp, newParentBddp, newPrevBddp, moveInfo })

        // allMoveNodeInfo.push(movedNodeMoveInfo);
        // if (oldNextNodeMoveInfo) allMoveNodeInfo.push(oldNextNodeMoveInfo);
        // if (newNextNodeNodeInfo) allMoveNodeInfo.push(newNextNodeNodeInfo);

        // console.log(allMoveNodeInfo);
        // processNodeMoveInfo(allMoveNodeInfo);

        // let originalVersion = bookDraft?.version;
        // bookDraft.version = bookDraft?.version + 1;

        try{
            moveDocumentPart({
                userId,
                bookDraft,
                moveInfo,
                // bookDraftExpectedVersion:originalVersion,
                // movedPartId:movedBddp.partId,
                // movedPartExpectedVersion:movedBddp.documentPart.version,
                // newParentId:newParentBddp.partId,
                // newPrevId:newPrevBddp!=null?newPrevBddp.partId:null
              });
        }catch(err){
            console.log(err);
        }
        




    }

    // const processNodeMoveInfo = (allMoveNodeInfo) => {

    //     allMoveNodeInfo.forEach((nodeInfo) => {

    //         let bddp = bookDraft.getBookDraftDocumentPartByPartId(nodeInfo.id);

    //         if (nodeInfo.newParentId != 'UNCHANGED') {

    //             bddp.parentPartId = nodeInfo.newParentId;
    //         }
    //         if (nodeInfo.newPrevId != 'UNCHANGED') {

    //             bddp.prevPartId = nodeInfo.newPrevId;
    //         }

    //     });

       
    // }

    // const generateMovedNodeMoveInfo = ({ bookDraft, movedBddp, newPrevBddp, moveInfo }) => {


    //     console.log('generateMovedNodeMoveInfo:...');
    //     return {
    //         id: moveInfo.dragSourceId,
    //         newParentId: moveInfo.dropTargetId,
    //         newPrevId: newPrevBddp ? newPrevBddp.partId : null,
    //         oldParentId: movedBddp.parentPartId,
    //         oldPrevId: movedBddp.prevPartId,
    //         title: movedBddp.documentPart.derivedTitle,
    //         description: 'MOVED_BDDP'
    //     }
    // }

    // const generateOldNextNodeMoveInfo = ({ bookDraft, movedBddp, newPrevBddp, moveInfo }) => {

    //     //Find any bddp that is the old next for the movedBddp
    //     let oldNextBddp = bookDraft.getBookDraftDocumentPartByPrevPartId(movedBddp.parentPartId, movedBddp.partId);
    //     if (oldNextBddp) {
    //         return {
    //             id: oldNextBddp.partId,
    //             newParentId: 'UNCHANGED',
    //             newPrevId: movedBddp.prevPartId,
    //             oldParentId: oldNextBddp.parentPartId,
    //             oldPrevId: oldNextBddp.prevPartId,
    //             title: oldNextBddp.documentPart.derivedTitle,
    //             description: 'OLD_NEXT_BDDP'
    //         }
    //     }


    // }

    // const getNewNextNodeNodeInfo = ({ bookDraft, movedBddp, newParentBddp, newPrevBddp, moveInfo }) => {

    //     let newNextBddp = null;

    //     if (newPrevBddp) {
    //         newNextBddp = bookDraft.getBookDraftDocumentPartByPrevPartId(newPrevBddp.parentPartId, newPrevBddp.partId);
    //     } else {
    //         newNextBddp = bookDraft.getBookDraftDocumentPartByPrevPartId(newParentBddp.partId, null);
    //     }

    //     if (newNextBddp) {
    //         return {
    //             id: newNextBddp.partId,
    //             newParentId: 'UNCHANGED',
    //             newPrevId: movedBddp.partId,
    //             oldParentId: newNextBddp.parentPartId,
    //             oldPrevId: newNextBddp.prevPartId,
    //             title: newNextBddp.documentPart.derivedTitle,
    //             description: 'NEW_NEXT_BDDP'
    //         }
    //     }

    // }

    const handleCanDrop = ({ dragSourceId, dropTargetId, dragSource, dropTarget }) => {

        try {

            let movedBddp = bookDraft.getBookDraftDocumentPartByPartId(dragSourceId);
            let targetBddp = bookDraft.getBookDraftDocumentPartByPartId(dropTargetId);

            let movedPartType = movedBddp.documentPart.partType;
            let targetPartType = targetBddp.documentPart.partType;

            let canDrop = false;

            //console.log(movedPartType);
            //console.log(targetPartType);

            /*

    "CHAPTER": {
        abbreviation: "CHP",
        allowedParentTypes: [
            "TOPBOOK", "VOLUME", "PART"
        ]
    },
            */

        let partTypeConfig = PART_TYPES[movedPartType]
        //console.log(partTypeConfig);
        //console.log(partTypeConfig.allowedParentTypes.filter((f) => f==targetPartType));
        if(partTypeConfig.allowedParentTypes.filter((f) => f==targetPartType).length>0){
            canDrop = true;
        }
            // switch (movedPartType) {
            //     case 'VOLUME':
            //         if (targetPartType == 'TOPBOOK') {
            //             canDrop = true;
            //         }

            //         break;

            //     case 'PART':
            //         if (targetPartType == 'TOPBOOK') {
            //             canDrop = true;
            //         }
            //         if (targetPartType == 'VOLUME') {
            //             canDrop = true;
            //         }

            //         break;

            //     case 'CHAPTER':
            //         if (targetPartType == 'TOPBOOK') {
            //             canDrop = true;
            //         }
            //         if (targetPartType == 'VOLUME') {
            //             canDrop = true;
            //         }
            //         if (targetPartType == 'PART') {
            //             canDrop = true;
            //         }

            //         break;
            //     case 'SCENE':
            //         if (targetPartType == 'CHAPTER') {
            //             canDrop = true;
            //         }

            //         break;


            //     default:
            //         break;
            // }

            return canDrop;

        } catch (err) {
            console.log(err);
        }



    }

    return (
        <StretchBox flexDirection='column' sxProps={{

        }}>
            <Paper focusable='false' tabIndex={-1} className='toolbar-wrapper' sx={{
                display: 'flex',
                justifyContent: 'space-around',
                'flex-direction': 'row',
                'flex-wrap': 'wrap',
                'alignItems': 'center',
                width: '100%',
                border: '1px solid gray',
                padding: '0em 0em .5em 0em'
            }}>
                <Typography variant={theme.palette.standardTree.heading.variant} color={theme.palette.standardTree.heading.color.main}>{props.heading}</Typography>
                <Typography variant={theme.palette.standardTree.heading.variant} color={theme.palette.standardTree.heading.color.main}>{props.bookDraft.version}</Typography>


            </Paper>

            <div>moveLoading:{moveBookDraftDocumentPartLoading===true?'loading...':'not loading'}</div>
            {/* <div>moveError:{moveBookDraftDocumentPartError!=null?moveBookDraftDocumentPartError:'no error'}</div> */}
            <ScrollBox>

                <TreeView
                    //rootId={bookDraft?.rootBookDraftDocumentPart.partId}
                    rootId={0}
                    data={treeViewItems}
                    bookDraft={props.bookDraft}
                    bookDraft2={props.bookDraft2}
                    selectedId={props.selectedId}
                    onDocumentPartSelected={props.onDocumentPartSelected}
                    onContextMenu={(e, partId) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //console.log(e);
                        handleClick(e);
                        onContextSelected(partId)
                    }}
                    onCanDrop={({ dragSourceId, dropTargetId, dragSource, dropTarget }) => {
                        return handleCanDrop({ dragSourceId, dropTargetId, dragSource, dropTarget });
                        //return true;
                    }}
                    onMoveNode={(moveInfo) => {

                        //console.log(moveInfo);


                        if (handleCanDrop({
                            dragSourceId: moveInfo.dragSourceId,
                            dropTargetId: moveInfo.dropTargetId
                        })) {
                            let moveJson = getAllMoveNodeInfo(props.bookDraft, moveInfo);
                        }

                        // moveDocumentPart({
                        //     userId,
                        //     bookDraft,
                        //     bookDraftExpectedVersion:-1,
                        //     movedPartId:dragSourceId,
                        //     movedPartExpectedVersion:-1,
                        //     newParentId:dropTargetId,
                        //     newPrevId:null
                        //   });
                    }}
                />



            </ScrollBox>






            {
                open &&
                <ContextMenu
                    anchorEl={anchorEl}
                    open={open}
                    bookDraft={bookDraft}
                    contextSelectedType={contextSelectedBddp.documentPart.partType}
                    onSelected={({
                        menuId,
                    }) => {

                        //console.log('menuId:'+menuId);
                        onContextMenuSelected(menuId);
                    }}
                    handleClose={() => {
                        setAnchorEl(null)
                    }} />
            }


            <StandardDialog
                title={"Add a Contributed Book"}
                open={showAddBookDialog}
                onClose={() => {

                    setShowAddBookDialog(false);

                }}

                dialogContent={<InsertBoxsetBookPanel book={writingBookDraft?.book} parentBddp={contextSelectedBddp} />}>

            </StandardDialog>
        </StretchBox >

    )
};


export default inject('stores')(observer(BookDraftTree7));