import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const StandardUISelect = (props) => {



    const [selection, setSelection] = React.useState('English');

    const handleChange = (event) => {
        setSelection(event.target.value);
    };

    return (
        <FormControl fullWidth>
            <InputLabel >{props.inputLabel}</InputLabel>
            {props.content}

        </FormControl>
    );
};


export default inject('stores')(observer(StandardUISelect));