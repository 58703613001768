import React, { useEffect, useState } from 'react';
import { styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

function UniverseGeneralPanel(props) {

  const theme = useTheme();
  

  const { label, checked } = props;


  return (

    <Box sx={{
      height:'100%',
      position: 'relative'
    }}>
      Universe info
    </Box>


  );
}


export default inject('stores')(observer(UniverseGeneralPanel));



