import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import BaseControl from "./BaseControl";

export const StyledTypography = styled(Typography)(() => ({

    // background: 'transparent',
    // boxShadow: 'none',
    fontWeight:'bold'



}));





export default function SubHeading(props) {

    const theme = useTheme();

    let baseControl = new BaseControl(props);
    const { classes, element, bookDraft, documentPart, boxSetBook, showProps = true, fieldName, s3FieldName, fileTypeFieldName, label } = props;

    let isVisible = baseControl.isVisible();
    if (!isVisible) {
        return <div></div>
    }

    let title = baseControl.convertTitle(element.title, documentPart);
    let headingSourceParam = baseControl.getRecipeParamByName('global_headerTemplateSourceFor---PART_TYPE---s');

    let partTypeText = baseControl.convertTitle('---PART_TYPE---', documentPart);


    let activeThemeText = 'book\'s theme'
    if (boxSetBook) {

        switch (boxSetBook.recipeSource) {

            case 'boxSetRecipe':
                activeThemeText = 'boxset\'s theme'
                break;

            case 'originalBookRecipe':
                activeThemeText = 'book\'s theme'
                break;

            case 'overrideBookRecipe':
                activeThemeText = 'a custom theme'
                break;
        }
    }

    let headingSourceText = '';
    if (headingSourceParam && headingSourceParam.value == 'common') {
        headingSourceText = ' and the book\'s common heading.';
    }
    if (headingSourceParam && headingSourceParam.value == 'partTypeSpecific') {
        headingSourceText = ' and ' + partTypeText + ' specific headings.';
    }


    let contextString = '';
    if (element.showRecipeSourceContext && element.showRecipeSourceContext == true) {
        contextString = 'Values for the ' + activeThemeText
    }
    if (element.showHeadingSourceContext && element.showHeadingSourceContext == true) {
        contextString += headingSourceText
    }


    return (

        <StyledTypography variant={'h6'}  color={'secondary'} 
            //textColor={theme.palette.books?.main} 
            {...props}>
            {title}{contextString}
        </StyledTypography>

    );
}

