import React, { useEffect, useState } from 'react';
import { styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box, Checkbox } from "@mui/material";
import { v4 as uuid } from "uuid";

function StandardCheckbox(props) {

  const theme = useTheme();
  

  const { label, checked } = props;

 
  return (

    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}>
     
     
     
      <Checkbox key={uuid()} checked={checked} onChange={(e) => {
        if(props.onChange){
          props.onChange(e);
        }
      }} /><Typography>{label}</Typography>
    </Box>


  );
}


export default inject('stores')(observer(StandardCheckbox));



