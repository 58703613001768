import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { inject, observer } from 'mobx-react';
import Quill from 'quill';
//import Delta from 'quill-delta';
import { v4 as uuid }  from 'uuid';
import EditingContext from './EditingContext';
import BlurbsBlot from './blots/BlurbsBlot';
import AlsobysBlot from './blots/AlsobysBlot';
import VerseBlot from './blots/VerseBlot';
import AttributionBlot from './blots/AttributionBlot';
import BlockQuoteBlot from './blots/BlockQuoteBlot';
import SubheadBlot from './blots/SubheadBlot';
import PublisherBlot from './blots/PublisherBlot';
import ContributorsBlot from './blots/ContributorsBlot';
import DecoratedBreakBlot2 from './blots/DecoratedBreakBlot2';
import EditingInsertBlot from './blots/EditingInsertBlot';
import EditingDeleteBlot from './blots/EditingDeleteBlot';
import EditingMovedTombstoneBlot from './blots/EditingMovedTombstoneBlot';
import EditingMovedDestinationBlot from './blots/EditingMovedDestinationBlot';
import EditingInsertNewLineBlot from './blots/EditingInsertNewLineBlot';
import EditingInsertNewLineBlot2 from './blots/EditingInsertNewLineBlot2';
import EditingDeleteNewLineBlot from './blots/EditingDeleteNewLineBlot';
import Link2 from './blots/Link2';
import ImageBlot4 from './blots/ImageBlot4';
import FullPageImageBlot from './blots/FullPageImageBlot';
import TOCBlot from './blots/TOCBlot';
import { useUpdateDocumentPartBatch, useLazyGetDocumentPart } from '../../persistance/persistanceHooks';
import Counter from './modules/Counter';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import EntityManager from '../../domain/EntityManager';
import ChangeTracker from './modules/ChangeTracker';
import ChangeTracker2 from './modules/ChangeTracker2';
//import History from './modules/history';
import History2 from './modules/history2';
//import Keyboard from './modules/keyboard';
import DeltaManager from './DeltaManager';
import UserCommentInput from './UserCommentInput';
import UserComment from './UserComment';
import { UserComment as UserCommentPojo } from '../../domain/bookModel';
import editingUtils from '../../helpers/editingUtils';
import EditingProcessor from '../../helpers/EditingProcessor';
let Delta = Quill.import('delta');


const editingProcessor = new EditingProcessor();
let editingInProcess = false;
let Inline = Quill.import('blots/inline');

var $ = require("jquery");
require('quill/dist/quill.snow.css')

// const Clipboard = Quill.import('modules/clipboard');

// class TabMitigatingClipboard extends Clipboard {
//     convert(html = null) {
//         var delta = super.convert(html);
//         try {

//             if (
//                 delta.ops.length > 0 &&
//                 delta.ops[0].hasOwnProperty("insert") &&
//                 delta.ops[0].insert.charAt(0) === "\t"
//             ) {
//                 delta = delta.compose(new Delta().delete(1));
//             }

//         } catch (err) {
//             //console.log(err);
//         }

//         return delta;
//     }
// }

// class PlainClipboard extends Clipboard {
//     convert(html = null) {
//         /*if (typeof html === 'string') {
//           this.container.innerHTML = html;
//         }
//         let text = this.container.innerText;
//         this.container.innerHTML = '';
//         let newDelta = new Delta().insert(text);
//         //console.log(newDelta);
//         //return newDelta;*/
//         if (!this.options.stores.bookStore.isQuillInitialLoad) {
//             this.options.stores.bookStore.mostRecentQuillClipboardSelection = this.quill.getSelection();
//         }


//         let delta = super.convert(html);
//         ////console.log(delta);

//         //props.stores.bookStore.mostRecentQuillClipboardDelta = delta;
//         if (delta.ops.length == 1 &&
//             Object.keys(delta.ops[0]).length == 0 &&
//             delta.ops[0].hasOwnProperty('insert') &&
//             delta.ops[0].insert.hasOwnProperty('break') &&
//             delta.ops[0].insert.break == ''
//         ) {

//             this.quill.clipBoardDelta = null;
//             return delta;

//         }

//         delta.ops.forEach((op) => {

//             if (op.hasOwnProperty("insert") &&
//                 op.insert.length > 0
//             ) {
//                 op.insert = op.insert.replace('\t', '');
//             }

//         })

//         let newOps = [];
//         delta.ops.forEach((op) => {

//             if (op.hasOwnProperty("insert") && op.insert == '') {
//                 //do nothing
//             } else {
//                 newOps.push(op);
//             }

//         })

//         delta.ops = newOps;


//         if (!this.options.stores.bookStore.isQuillInitialLoad) {
//             this.options.stores.bookStore.mostRecentQuillClipboardDelta = delta;
//             this.options.stores.bookStore.mostRecentQuillClipboardSelection = this.quill.getSelection();
//         }

//         return delta;
//     }
// }

//Quill.register('modules/clipboard', PlainClipboard, true);


//Quill.register('modules/clipboard', TabMitigatingClipboard, true);
Quill.register(BlurbsBlot, true);
Quill.register(AlsobysBlot, true);
Quill.register(TOCBlot, true);
Quill.register(PublisherBlot, true);
Quill.register(ContributorsBlot, true);
Quill.register(DecoratedBreakBlot2, true);
Quill.register(EditingInsertBlot, true);
Quill.register(EditingDeleteBlot, true);
Quill.register(EditingMovedTombstoneBlot, true);
Quill.register(EditingMovedDestinationBlot, true);
Quill.register(EditingInsertNewLineBlot, true);
Quill.register(EditingInsertNewLineBlot2, true);
Quill.register(EditingDeleteNewLineBlot, true);



// Quill.register('modules/counter', Counter);
// Quill.register('modules/history', History2, true);
// Quill.register('modules/keyboard', Keyboard);


//let Inline = Quill.import('blots/inline');
const Parchment = Quill.import('parchment');

let FontSizeStyle = new Parchment.Attributor.Style('fontSizeBM', 'font-size', {
    scope: Parchment.Scope.INLINE,
    whitelist: [null, '0.75em', '1.5em', '2em']
});
Quill.register(FontSizeStyle, true);

let FontVariantStyle = new Parchment.Attributor.Style('fontVariant', 'font-variant', {
    scope: Parchment.Scope.INLINE,
    whitelist: ['small-caps']
});
Quill.register(FontVariantStyle, true);

let FontFamilyStyle = new Parchment.Attributor.Style('fontFamily', 'font-family', {
    scope: Parchment.Scope.INLINE,
    whitelist: ['serif', 'sans-serif', 'monospace']
});
Quill.register(FontFamilyStyle, true);
//Quill.register('modules/cursors', QuillCursors);


Quill.register(VerseBlot, true);
Quill.register(AttributionBlot, true);
Quill.register(BlockQuoteBlot, true);
Quill.register(SubheadBlot, true);
Quill.register(Link2, true);
Quill.register(ImageBlot4, true);
Quill.register(FullPageImageBlot, true);



//https://github.com/quilljs/quill/issues/2312
Inline.order.push('editingInsertBlot');
Inline.order.push('editingDeleteBlot');
Inline.order.push('editingFormatBlot');
Inline.order.push('editingMovedTombstoneBlot');
Inline.order.push('editingMovedDestinationBlot');
Inline.order.push('editingNewLineBlot');
Inline.order.push('editingInsertNewLineBlot');
Inline.order.push('link2');


const PreviewQuillEditor = (props) => {

    const [updateDocumentPartBatch, { data: updateDocumentPartBatchData, loading: updateDocumentPartBatchLoading, error: updateDocumentPartBatchError }] = useUpdateDocumentPartBatch();
    const [lazyGetDocumentPartToRestore, { loading: lazyGetDocumentPartToRestoreLoading, error: lazyGetDocumentPartToRestoreError, data: lazyGetDocumentPartToRestoreData, refetch: lazyGetDocumentPartToRestoreRefetch }] = useLazyGetDocumentPart();

    const [editorId, setEditorId] = React.useState('previewEditor');
    const [previewEditorId, setPreviewEditorId] = React.useState('previewEditorId' + uuid());

    const [quillInstance, setQuillInstance] = React.useState(null);
    const [changeTrackerDeltas, setChangeTrackerDeltas] = React.useState(null);
    const [editsAdded, setEditsAdded] = React.useState(null);
    const [userCommentItems, setUserCommentItems] = React.useState([]);


    const [parsedErrorMessage, setParsedErrorMessage] = useState();


    const [scrollingContainerId, setScrollingContainerId] = React.useState('scrollingContainer' + uuid());

    const editingContext = useContext(EditingContext);

    const { documentPart, readOnly = false } = props;

    const { userComments } = props.stores.bookStore;





    useEffect(() => {

        //console.log('setting up quill')
        props.stores.bookStore.isQuillInitialLoad = true;

        let quill = new Quill('#' + editorId, {
            readOnly: readOnly,
            theme: 'snow',
            modules: {
                history: {
                    stores: props.stores,
                    documentPart: documentPart,
                    editMode: props.editMode,
                    userStore: props.stores.userStore,
                    userOnly: true //https://stackoverflow.com/questions/59653962/stop-undo-from-clearing-everything-in-the-editor-quill-quilljs
                },
                // counter: {
                //     //container: '#' + that.state.numWordsDivId,
                //     unit: 'word',
                //     callback: (words) => {
                //         ////console.log(words);
                //         //props.documentPart.numWords = words;
                //     }
                // },
                clipboard: {
                    stores: props.stores
                },
                toolbar: false,

            },
            formats: [
                'bold',
                'underline',
                'italic',
                'strike',
                'blockquote',
                'attribution',
                'verse',
                'subhead',
                'fontSizeBM',
                'fontVariant',
                'fontFamily',
                'publisher',
                'contributors',
                'blurbs',
                'alsobys',
                //'link',
                'link2',
                //'list',
                'align',
                //'image',
                'image4',
                'fullPageImage',
                'tocs',
                'script',
                'editingDeleteBlot',
                'editingInsertBlot',
                'editingFormatBlot',
                'editingMovedTombstoneBlot',
                'editingMovedDestinationBlot',
                'editingNewLineBlot',
                //'editingInsertNewLineBlot',
                'editingInsertNewLineBlot2',
                'editingDeleteNewLineBlot',
                'decoratedBreak',
                //'break'
                //'indent'
            ],
            placeholder: readOnly ? '' : 'Begin writing here...',
            //scrollingContainer: '#' + scrollingContainerId
        });


        // quill.on('text-change', function (delta, oldDelta, source) {



        //     console.log(delta);



        // });


        // quill.on('selection-change', function (range, oldRange, source) {
        //     console.log('quill.selection-change...')
        //     console.log(range);

        // });





        setQuillInstance(quill);
        props.stores.bookStore.previewQuill = quill;




    }, [

    ]);










    return (
        <div

            autoHide
            renderThumbHorizontal={() => {
                return <span />
            }}
            style={{ width: '100%', height: '100%', overFlow: 'hidden', display:'none' }}>



            <div id={editorId}
                className={`editor scene-editor-scrolling-container scene-wrapper`}
                style={{
                    overflow: 'hidden',
                    border: '0em'
                }}
                onKeyDown={(e) => {



                    // if (!e.ctrlKey &&
                    //     e.key != 'Alt' &&
                    //     e.key != 'Delete' &&
                    //     e.key != 'Backspace' &&
                    //     e.key != 'Tab' &&
                    //     e.key != 'Escape') {

                    //     props.stores.bookStore.mostRecentQuillKeyPress = e.key;
                    //     props.stores.bookStore.mostRecentQuillKeyPressSelection = quillInstance.getSelection();

                    // }

                }}
                onKeyPressedx={(e) => {
                    //console.log(e.key)

                }}>


            </div>


        </div>
    );
};


export default inject('stores')(observer(PreviewQuillEditor));
