import { Box, Grid, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import StandardListHeader from "./StandardListHeader";

const StyledGrid = styled(Grid)({
  //height: '100%',
  //overflow: 'hidden',
  //border: '5px solid purple',
  boxSizing: 'border-box',
  // alignItems: 'stretch',
  // alignContent:'stretch',
  //flex:2
  //'
});

const ScrollingGridContainer = (props) => {

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      flexDirection: 'column',
      height:'100%',
      //border:'1px solid red'

    }}>
      
      {props.headerComponent}
     
      <StyledGrid {...props} container sx={{
        //border: '3px solid orange',
        boxSizing: 'border-box',
        display: 'flex',
        flex: 1,
        minHeight: '0px'
        //alignItems: 'stretch'

      }}>

        {props.children}

      </StyledGrid>

    </Box>

  );
};

export default ScrollingGridContainer;