import Quill from 'quill';
//import ReactQuill, { Quill } from 'react-quill';
let Block = Quill.import('blots/block');

let Parchment = Quill.import("parchment");

class VerseBlot extends Block { }
VerseBlot.blotName = 'verse';
VerseBlot.tagName = 'div';
VerseBlot.className = 'verse';

export default VerseBlot ;
