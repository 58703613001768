import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import EditChangeNotes from './EditChangeNotes';
import ToolbarItem from '../editor/toolbar/ToolbarItem';
import SettingsIcon from '@mui/icons-material/Settings';
import ChatIcon from '@mui/icons-material/Chat';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDocumentEdit from 'mdi-material-ui/FileDocumentEdit';
import { Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ScrollingGridContainer from '../ScrollingGridContainer';
import ScrollingGridItem from '../ScrollingGridItem';
import EditorEditRequestController from './EditorEditRequestController';

const EditDetails = (props) => {

    const [finalView, setFinalView] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const theme = useTheme();  

    const fontSize = 'small';
    const textSnippetIcon = <ToolbarItem icon={<FileDocumentEdit fontSize={fontSize} color={selectedTab == 0 ? 'secondary' : 'disabled'} onClick={() => { setSelectedTab(0) }} />} />;
    const snapshotsIcon = <ToolbarItem icon={<CopyAllIcon fontSize={fontSize} color={selectedTab == 2 ? 'secondary' : 'disabled'} onClick={() => { setSelectedTab(2) }} />} />;
    const chatIcon = <ToolbarItem icon={<ChatIcon fontSize={fontSize} color={selectedTab == 3 ? 'secondary' : 'disabled'} onClick={() => { setSelectedTab(3) }} />} />;
    const settingsIcon = <ToolbarItem icon={<SettingsIcon fontSize={fontSize} color={selectedTab == 1 ? 'secondary' : 'disabled'} onClick={() => { setSelectedTab(1) }} />} />;
    const visibilityIcon = <ToolbarItem icon={<VisibilityOutlinedIcon fontSize={fontSize} color={finalView ? 'secondary' : 'disabled'} onClick={() => { setFinalView(!finalView); }} />} />;
    const transferIcon = <ToolbarItem icon={<FileCopyIcon fontSize={fontSize} color={finalView ? 'secondary' : 'disabled'} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        //initTransferHandler();
    }} />} />;



    return (
        <Paper sx={{
            display: 'flex',
            backgroundColor: theme.palette.write?.background?.color,
            height: '100%',

        }} >
            <Box focusable='false' tabIndex={-1} id='master-toolbar' style={{
                'width': '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>

                <Box focusable='false' tabIndex={-1} className='toolbar-wrapper' sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    'flex-direction': 'row',
                    'flex-wrap': 'wrap',
                    'alignItems': 'center',
                    width: '100%',
                    border: '1px solid gray',
                    padding: '0em 0em .5em 0em'
                }}>
                    {visibilityIcon}
                    {textSnippetIcon}
                    {transferIcon}
                    {/* {snapshotsIcon} */}
                    {chatIcon}
                    {settingsIcon}

                </Box>

                <ScrollingGridContainer container columns={100} direction={'row'}>

                    <ScrollingGridItem xs={100} >
                        {selectedTab == 0 &&

                            <EditChangeNotes
                                //allBlots={allBlots}
                                documentPart={props.documentPart}
                                editRequest={props.editRequest}
                                editBookDraft={props.editBookDraft}
                                originalBookDraft={props.originalBookDraft}
                                //onInitTransfer={initTransferHandler}
                                finalView={finalView}
                            // onToggleFinalView={() => {
                            //     setFinalView(!finalView)
                            // }}
                            />

                        }

                        {(selectedTab == 1 && props.editRequest) &&
                            <EditorEditRequestController
                                editRequest={props.editRequest}
                            //showOriginal={showOriginal} 
                            />
                        }

                    </ScrollingGridItem>
                </ScrollingGridContainer>

            </Box>
        </Paper>
    );
};


export default inject('stores')(observer(EditDetails));
