import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';


export const StandardSkeleton = (props) => {

    const theme = useTheme();

    return (

        <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            
            <Skeleton variant="rectangular" width={210} height={60} />

            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            
            <Skeleton variant="rectangular" width={210} height={60} />

            {/* For other variants, adjust the size with `width` and `height` */}
            {/* <Skeleton variant="circular" width={40} height={40} /> */}
            <Skeleton variant="rectangular" width={210} height={60} />
            {/* <Skeleton variant="rounded" width={210} height={60} /> */}
        </Stack>
    )
}

export default StandardSkeleton;