import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import SharedWithPanel from '../../panels/connections/SharedWithPanel';
import ContributedBooksPanel from '../../panels/ContributedBooksPanel';

const WriteDetailsContributedBooks = (props) => {

    const theme = useTheme();

    let { bookDraftDocumentPart,bookDraft} = props;


    let userId = props.stores?.bookStore.userId;

 


    return (    
        <Box container direction={'column'} sx={{
            justifyContent: 'center',
            //marginRight:'1em'
        }}>
          <ContributedBooksPanel book={bookDraft?.book}/>
        </Box>
    );
};


export default inject('stores')(observer(WriteDetailsContributedBooks));
