import React, { useState, useEffect } from "react";
import { inject, observer } from 'mobx-react';
import { Box, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { useManagerCreateInvitation, useGetUserConnections, useGetUserGroups, useManagerCreateUserGroupUser } from '../../../managers/ConnectionsManager';
import config from '../../../aws-exports';
import StandardUIDialog from "../../UI/StandardUIDialog";
import InviteConnection from "./InviteConnection";
import StretchBox from '../../boilerPlate/StretchBox';
import ScrollBox from '../../boilerPlate/ScrollBox';
import StandardUITabs from "../../UI/StandardUITabs";
import ListConnections from "../lists/ListConnections";
import CircularProgress from '@mui/material/CircularProgress';

const AddMember = (props) => {

    let user = props.stores.userStore.user;
    let { userGroup } = props;

    const { createUserGroupUser, loading: createUserGroupUserLoading, error: createUserGroupUserError } = useManagerCreateUserGroupUser(props);
    const { createInvitation, data: createInvitationData, loading: createInvitationLoading, error: createInvitationError } = useManagerCreateInvitation();



    let [selectedTab, setSelectedTab] = useState(0);


    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <StandardUIDialog
            open={props.open}
            onClose={props.onClose}
            title='Add Members'
            dialogContent={
                <Box >
                    <StandardUITabs value={selectedTab} onChange={handleChange}>
                        <Tab label="Connections" {...a11yProps(0)} />
                        <Tab label="Invite" {...a11yProps(1)} />
                    </StandardUITabs>
                    {selectedTab == 0 &&
                        <div>
                            <ListConnections
                                filteredConnections={[]}
                                showSelectIcon
                                onSelectUser={(userConnection) => {
                                    if (userConnection) {
                                        createUserGroupUser({
                                            userGroupId: userGroup?.id,
                                            userId: userConnection.connectedUser.id
                                        });
                                    }
                                }}

                            />
                            {createUserGroupUserLoading && <CircularProgress />}

                        </div>

                    }

                    {selectedTab == 1 &&
                        <div>
                            <InviteConnection />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {createInvitationLoading && <CircularProgress />}
                            </div>
                        </div>
                    }
                </Box>
            }
        />
    )
}

export default inject('stores')(observer(AddMember));