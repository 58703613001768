import Quill from 'quill';
//import Parchment from 'parchment';
import DeltaToDom from './DeltaToDom';
//import Popper from 'popper.js';
import config from '../../../aws-exports';

const Parchment = Quill.import('parchment');
var $ = require("jquery");

let BlockEmbed = Quill.import('blots/block/embed');
let Base = Quill.import('blots/embed');
let Inline = Quill.import('blots/inline');

function createImageContent(node, args, blot, blotImageDivId) {

  //console.log('createImageContent()...');
  let converter = new DeltaToDom();
  /*let html = converter.convertBlurb(args);
    node.innerHTML = html;*/

  let domNode = converter.convertFullPageImage(args, node, blot, blotImageDivId);
  node.appendChild(domNode);






}

export default class FullPageImageBlot extends Base {
  static create(args) {
    //console.log(args);
    let node = super.create();
    let blotImageDivId = 'blotImageDiv' + Date.now();
    node.setAttribute('id', blotImageDivId);
    //node.setAttribute('style', 'border:2px solid red');


    $(node).click(function () {

      $('#imagePopperTriggerDiv').data('blotNode', node);
      $('#imagePopperTriggerDiv').click();


    });

    let nodeParent = $(node);

    createImageContent(node, args, this, blotImageDivId);

    let propsDiv = $("#image4Popper");
    let thisPopper = null;


    return node;
  }

  static value(domNode) {
    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
      src: domNode.dataset.src,
      type: domNode.dataset.type,
      alt: 'alternate text',
      
    };
    return value;
  }


  deleteAt(index,length) {

    //let textBlot = Parchment.create('text', 'Test');
    //textBlot.insertAt(index, 'ough');
    return false;
  }

  
  setSrc(src) {
    //console.log(src);
    this.domNode.dataset.src = src;
    //let blotImageDivId = $(this.domNode).data('blotImageDivId');
    //$(this.domNode).find("img").attr('src','https://local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm.s3.amazonaws.com/folder1/2-27610_swirl-clipart-scroll-line-vines-clip-art.png');

    $(this.domNode).find("img").attr('src','https://'+config.user_uploads_bucket_name+".s3.amazonaws.com/"+src);

    
  }



}
FullPageImageBlot.blotName = 'fullPageImage';
FullPageImageBlot.tagName = 'div';
FullPageImageBlot.className = 'fullPageImage';