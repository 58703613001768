/* eslint-disable */
		

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_wAKW8k3gQ",
    "aws_user_pools_web_client_id": "73t8bjrc4l4t752fgec88hd6a6",
    "graphqlEndpoint":"https://lzd4zxut2rc2rnhsjepc5eksru.appsync-api.us-east-1.amazonaws.com/graphql",
    "user_uploads_bucket_name":"dev-bookmerlincicd8-useruploadedcontents3bucket2d-dde9t9u47khj",
    "identity_pool_id" :"us-east-1:92b6d94f-9abf-4b19-9b11-40cd599c14ee",
    "user_uploads_bucket_region":"us-east-1",
    "common_content_bucket_name":"dev-bookmerlincicd8-commoncontents3bucket03b3cbc8-10mv79rw1n2ey"
   
    




    




};
export default awsmobile;
