import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';


const StandardUITextField = (props) => {


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    //Fixing the label/text overlap:
    //https://stackoverflow.com/questions/50955603/react-material-ui-label-overlaps-with-text
    //let fieldValue = props?.entity[props.fieldName];
    return (
        <TextField
            id={props.id}
            sx={{ mb: 1 }}
            onChange={props.onChange}
            fullWidth
            label={props.label}
            variant="outlined"
            // style={{ marginBottom: '1em', marginTop: '0.25em' }}
            //value={props.value}
            
            
        >
            {props.children}
            </TextField>
          
    )
}
export default inject('stores')(observer(StandardUITextField));