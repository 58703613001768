import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { useGetSnapshots, useOpenBookInWrite, useOpenSnapshotInWrite } from '../../managers/BooksManager';
import StandardDataTable from './StandardDataTable';
import CreateSnapshotDialog from '../panels/CreateSnapshotDialog';
import { Box, Button, IconButton, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import config from '../../aws-exports';
import StandardButton from '../StandardButton';
import ButtonTableCell from './ButtonTableCell';
import ReactTimeAgo from 'react-time-ago';
import StandardDialog from '../StandardDialog';
import RestoreSnapshotPanel from '../panels/RestoreSnapshotPanel';
import DeleteSnapshotPanel from '../panels/DeleteSnapshotPanel';
import { useMediaQuery } from 'react-responsive';
import StandardFirstColumnTitle from './StandardFirstColumnTitle';
import TablePopupMenu from '../menus/TablePopupMenu';
import CreateSnapshotAssembly from '../assemblies/CreateSnapshotAssembly';

const SnapshotsTable = (props) => {

    let { book } = props;

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');
    const { openBookInWrite } = useOpenBookInWrite(props);
    const { openSnapshotInWrite } = useOpenSnapshotInWrite(props, true);

    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    const [showCreateSnapshotDialog, setShowCreateSnapshotDialog] = React.useState(false);
    const [showRestoreSnapshotDialog, setShowRestoreSnapshotDialog] = React.useState(false);
    const [showDeleteSnapshotDialog, setShowDeleteSnapshotDialog] = React.useState(false);
    const [selectedSnapshot, setSelectedSnapshot] = React.useState(null);

    let { listSnapshots, snapshots, error: listSnapshotsError, loading: listSnapshotsLoading } = useGetSnapshots();

    const theme = useTheme();

    useEffect(() => {

        if (book != null) {
            listSnapshots({
                bookId: book.id,
                onCompleted: () => {

                }
            })
        }
    }, [book]);


    



    return (

        <Box sx={{
            height: '100%'
        }}>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <StandardDataTable
                    title={'Snapshots'}
                    noDataMessage={'Snapshots will appear here.'}
                    order={order}
                    onSetOrder={setOrder}
                    orderBy={orderBy}
                    onSetOrderBy={setOrderBy}
                    headerActionsxxx={<Box sx={{
                        display: 'flex',
                        //justifyContent: 'flex-end',
                        //width: '100%',
                        //border:'1px solid yellow'
                    }}>
                        <Button variant="contained" sx={{
                            color: '#ffffff',
                            backgroundColor: theme.palette.secondary.main
                        }}
                        >Create Snapshot</Button>
                        <StandardButton variant="contained" sx={{
                            color: '#ffffff',
                            backgroundColor: theme.palette.secondary.main
                        }}
                        >Create Snapshot</StandardButton>

                    </Box>}
                    headerActions={<CreateSnapshotAssembly book={book}/>}
                    structure={{
                        dataDef: {
                            data: snapshots,
                            rowKeyDataFieldName: 'id'
                        },
                        columns: [


                            {
                                title: 'Name',
                                dataFieldName: 'name',
                                sortable: true,
                                dataValueFunction: (snapshot) => {
                                    return <StandardFirstColumnTitle
                                        title={snapshot.name}
                                        details={snapshot.description}
                                    />


                                    // snapshot.name 
                                }
                            },
                            // {
                            //     title: 'id',
                            //     dataFieldName: 'id',
                            //     sortable: true,
                            //     dataValueFunction: (snapshot) => { return snapshot.id }
                            // },
                            // {
                            //     title: 'Description',
                            //     dataFieldName: 'description',
                            //     sortable: true,
                            //     dataValueFunction: (snapshot) => { return snapshot.description }
                            // },
                            {
                                title: 'Restore',
                                dataFieldName: null,
                                sortable: true,
                                dataValueFunction: (snapshot) => {
                                    return <ButtonTableCell onClick={() => {
                                        setSelectedSnapshot(snapshot);
                                        setShowRestoreSnapshotDialog(true)
                                    }}>Restore</ButtonTableCell>
                                }
                            },
                            {
                                title: 'Load',
                                dataFieldName: null,
                                sortable: true,
                                dataValueFunction: (snapshot) => {
                                    return <ButtonTableCell onClick={() => {
                                        openSnapshotInWrite({
                                            bookDraftId: book.primaryBookDraftId,
                                            snapshotId: snapshot.id
                                        });
                                    }}>Load</ButtonTableCell>
                                },
                                ignore: isXs
                            },
                            {
                                title: 'Created',
                                dataFieldName: 'createdAt',
                                sortable: true,
                                dataValueFunction: (snapshot) => { return snapshot.createdAt != null ? <ReactTimeAgo date={snapshot.createdAt} locale="en-US" /> : '' },
                                ignore: isXs
                            },
                            {
                                title: 'Delete',
                                dataValueFunction: (snapshot) => {
                                    return <IconButton aria-label="delete">
                                        <TrashCanOutline sx={{ color: theme.palette.background.main }} onClick={(e) => {
                                            e.preventDefault();
                                            //console.log(snapshot);
                                            setSelectedSnapshot(snapshot);
                                            setShowDeleteSnapshotDialog(true);

                                        }} />
                                    </IconButton>
                                },
                                ignore: isXs
                            },

                            // {
                            //     title: 'Write',
                            //     dataValueFunction: (book) => {
                            //         return <IconButton aria-label="delete">
                            //             <Feather sx={{ color: theme.palette.background.main }} onClick={(e) => {
                            //                 e.preventDefault();
                            //                 console.log(book)
                            //                 //navigate("/write");
                            //                 openBookInWrite({
                            //                     bookDraftId: book.primaryBookDraftId
                            //                 });
                            //             }} />
                            //         </IconButton>
                            //     }
                            // },
                            // {
                            //     title: 'Format',
                            //     dataValueFunction: (book) => {
                            //         return <IconButton aria-label="delete">
                            //             <FormatPaintIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                            //                 e.preventDefault();
                            //                 //navigate("/format");
                            //                 openBookInFormat({
                            //                     bookDraftId: book.primaryBookDraftId
                            //                 });
                            //             }} />
                            //         </IconButton>

                            //     }
                            // },
                            // {
                            //     title: 'Snapshots',
                            //     expandable: true,
                            //     exoandedRowComponent: (book) => { return <SnapshotsTable book={book} /> }
                            // },
                            // {
                            //     icon: <EditIcon sx={{ color: theme.palette.words?.main }} />,
                            //     expandable: true,
                            //     exoandedRowComponent: (book) => { return <div>its a book 2: {book.title}</div> }
                            // },
                            // {
                            //     title: 'Created',
                            //     dataFieldName: 'createdAt',
                            //     sortable: true,
                            //     dataValueFunction: (book) => { return book.createdAt != null ? <ReactTimeAgo date={book.createdAt} locale="en-US" /> : '' }
                            // },


                            {
                                title: '',
                                dataValueFunction: (snapshot) => {
                                    return <TablePopupMenu
                                        menuItems={[
                                            <div>
                                                <ButtonTableCell onClick={() => {
                                                    openSnapshotInWrite({
                                                        bookDraftId: book.primaryBookDraftId,
                                                        snapshotId: snapshot.id
                                                    });
                                                }}>Load</ButtonTableCell>
                                            </div>,
                                            <div>
                                                <ListItemText primary="Created:" />
                                                {snapshot.createdAt != null ? <ReactTimeAgo date={snapshot.createdAt} locale="en-US" /> : ''}
                                            </div>,
                                            <ListItemButton onClick={(e) => {
                                                e.preventDefault();
                                                //console.log(snapshot);
                                                setSelectedSnapshot(snapshot);
                                                setShowDeleteSnapshotDialog(true);

                                            }} >

                                                <ListItemIcon>
                                                    <TrashCanOutline sx={{ color: theme.palette.background.main }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Delete" />
                                            </ListItemButton>,

                                        ]}
                                    />
                                },
                                ignore: !isXs
                            },


                        ]
                    }} />

                <StandardDialog
                    open={showRestoreSnapshotDialog}
                    onClose={() => {
                        setShowRestoreSnapshotDialog(false)
                    }}
                    onOk={() => {


                    }}
                    title={'Restore Snapshot'}
                    dialogContent={
                        <RestoreSnapshotPanel
                            book={book}
                            snapshot={selectedSnapshot}
                            onClose={() => {
                                setShowRestoreSnapshotDialog(false)
                            }}
                            onRestored={() => {
                                setShowRestoreSnapshotDialog(false)
                                setSelectedSnapshot(null);
                                openBookInWrite({
                                    bookDraftId: book.primaryBookDraftId
                                });
                            }} />
                    }
                >
                </StandardDialog>

                <StandardDialog
                    open={showDeleteSnapshotDialog}
                    onClose={() => {
                        setShowDeleteSnapshotDialog(false)
                    }}
                    onOk={() => {


                    }}
                    title={'Delete Snapshot'}
                    dialogContent={
                        <DeleteSnapshotPanel book={book} snapshot={selectedSnapshot}

                            onClose={() => {
                                setShowDeleteSnapshotDialog(false)
                            }}
                            onDeleted={() => {
                                setShowDeleteSnapshotDialog(false)
                                setSelectedSnapshot(null);

                            }} />
                    }
                >
                </StandardDialog>

            </Box>
        </Box >
    )
}
export default inject('stores')(observer(SnapshotsTable));