import React, { useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { ThemeProvider, CssBaseline } from "@mui/material";
import {
  Tree,
  MultiBackend,
  getBackendOptions
} from "@minoru/react-dnd-treeview";
import { CustomNode } from "./CustomNode";
import styles from "./App.module.css";
// import SampleData from "./sample_data.json";
import { CustomDragPreview } from "./CustomDragPreview";
import { Placeholder } from "./Placeholder";

var diff = require('deep-diff').diff;

function TreeView(props) {

  const [treeData, setTreeData] = useState([]);
  const [openNodes, setOpenNodes] = useState([props.rootId, 0]);



  useEffect(() => {

    if (props.data != null) {
      let opened = [];
      props.data.map((node) => {

        if (node.partType != "CHAPTER") {
          opened.push(node.id)
        }
      })

      setOpenNodes(opened);
    }


  }, [props.data]);

  const handleOnChangeOpen = (newOpenNodes) => {
    //console.log(newOpenNodes);
    setOpenNodes(newOpenNodes);
  }

  const getNodeDifferences = ({
    oldData,
    newData,
    nodeId
  }) => {

    let oldNodeData = oldData.filter((f) => {
      if (f.id == nodeId) return f;
    })[0];

    let newNodeData = newData.filter((f) => {
      if (f.id == nodeId) return f;
    })[0];


    //console.log(oldNodeData);
    //console.log(newNodeData);

    let nodeDifferences = diff(oldNodeData, newNodeData);

    return nodeDifferences;

  }


  return (

    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <div className={styles.app}>
        <Tree
          // tree={treeData}
          tree={props.data}
          rootId={props.rootId}
          listComponent={'div'}
          listItemComponent={'div'}
          placeholderComponent={'div'}
          sort={false}
          enableAnimateExpand={true}
          //initialOpen={[props.rootId]}
          initialOpen={openNodes}
          onChangeOpen={handleOnChangeOpen}
          dropTargetOffset={0}
          insertDroppableFirst={false}
          render={(node, { depth, isOpen, onToggle }) => (
            <CustomNode
              node={node}
              depth={depth}
              isOpen={isOpen}
              isSelected={node.id === props.selectedId}
              onToggle={onToggle}
              onContextMenu={props.onContextMenu}
              onDocumentPartSelected={props.onDocumentPartSelected}
            />
          )}
          // dragPreviewRender={(monitorProps) => (
          //   <CustomDragPreview monitorProps={monitorProps} />
          // )}
          //onDrop={handleDrop}
          onDrop={(
            newTree,
            { dragSourceId, dropTargetId, dragSource, dropTarget, destinationIndex, relativeIndex }
          ) => {
            //console.log('dragSourceId:' + dragSourceId);
            //console.log('dropTargetId:' + dropTargetId);
            //console.log('dragSource:' + dragSource);
            //console.log('dropTarget:' + dropTarget);
            //console.log('destinationIndex:' + destinationIndex);
            //console.log('relativeIndex:' + relativeIndex);

            //console.log(props.data);
            //console.log(newTree);

            // let oldChapter3Data = props.data.filter((f)=>{
            //   if(f.id=='f9b4ccc0-47e0-43af-ac91-fc2bd24d2fbf') return f;
            // })[0];

            // let newChapter3Data = newTree.filter((f)=>{
            //   if(f.id=='f9b4ccc0-47e0-43af-ac91-fc2bd24d2fbf') return f;
            // })[0];

            // // let newChapter3Data = newTree.filter((f)=>{
            // //   if(f.id=='bae093ee-d37b-4fd2-be90-4dd0545ebbed') return f;
            // // })[0];

            // console.log(oldChapter3Data);
            // console.log(newChapter3Data);

            // //var differences = diff({data:props.data}, {data:newTree});
            // var movedNodeDifferences = diff(oldChapter3Data, newChapter3Data);
            // var movedNodeDifferences = diff(oldChapter3Data, newChapter3Data);

            // //console.log(differences);


            // props.data.forEach((oldNodeData)=>{

            //   let newNodeData = newTree.filter((f)=>{
            //     if(oldNodeData.id==f.id) return f;
            //   })[0];

            //   let differences = diff(oldNodeData, newNodeData)
            //   console.log(differences);
            // });

            // // let dragNodeDifferences = getNodeDifferences({
            // //   oldData:props.data,
            // //   newData:newTree,
            // //   nodeId:dragSourceId
            // // })

            // // console.log(dragNodeDifferences);

            props.onMoveNode(
              {
                newTree,
                dragSourceId,
                dropTargetId,
                relativeIndex,
                dragSource,
                dropTarget
              }
            );
            //setTreeData(newTree)
            // moveDocumentPart({
            //   userId,
            //   bookDraft,
            //   bookDraftExpectedVersion:-1,
            //   movedPartId:dragSourceId,
            //   movedPartExpectedVersion:-1,
            //   newParentId:dropTargetId,
            //   newPrevId:null
            // });

          }}
          canDrop={(
            currentTree,
            { dragSourceId, dropTargetId, dragSource, dropTarget }
          ) => {
            // console.log('dragSourceId'+dragSourceId);
            // console.log('dropTargetId'+dropTargetId);
            // console.log('dragSource'+dragSource);
            // console.log('dropTarget'+dropTarget);
            return props.onCanDrop({ dragSourceId, dropTargetId, dragSource, dropTarget });
            //return true;

            // or

            return false;

            // or

            return;

            // or

            return undefined;
          }}
          classes={{
            root: styles.treeRoot,
            draggingSource: styles.draggingSource,
            dropTarget: styles.dropTarget,
            placeholder: styles.placeholderContainer
          }}
          placeholderRender={(node, { depth }) => (
            <Placeholder node={node} depth={depth} />
          )}
        />
      </div>
    </DndProvider>

  );
}

export default TreeView;
