import Quill from 'quill';
//import Parchment from 'parchment';
import DeltaToDom  from './DeltaToDom';
const Parchment = Quill.import('parchment');
var $ = require("jquery");

let BlockEmbed = Quill.import('blots/block/embed');


function createContent(node,args){
  	
    ////console.log('TOCBlot.createContent()...')
  	let converter = new DeltaToDom();
	/*let html = converter.convertBlurb(args);
  	node.innerHTML = html;*/

  	let domNode = converter.convertTOC(args,node);
  	node.appendChild(domNode);

  	/*let addBlurbButton = document.getElementById('addBlurbBtn');
  	addBlurbButton.addEventListener('click', function(ev) {

      ////console.log(this);

  	})*/

  }

export default class TOCBlot extends BlockEmbed {
  static create(args) {
    let node = super.create();
    node.dataset.uuid = args.uuid;
    node.dataset.includeVolumes = args.includeVolumes;
    node.dataset.includeParts = args.includeParts;
    node.dataset.includeChapters = args.includeChapters;
    node.dataset.includeFrontMatter = args.includeFrontMatter;
    node.dataset.includeBackMatter = args.includeBackMatter;

   


    ////console.log(node);
    // Allow twitter library to modify our contents
    //twttr.widgets.createTweet(id, node);
    createContent(node,args);
    return node;
  }

  static value(domNode) {
  	let value = {};
    value.uuid = domNode.dataset.uuid;
  	value.includeVolumes = domNode.dataset.includeVolumes;
    value.includeParts = domNode.dataset.includeParts;
    value.includeChapters = domNode.dataset.includeChapters;
    value.includeFrontMatter = domNode.dataset.includeFrontMatter;
    value.includeBackMatter = domNode.dataset.includeBackMatter;
    return value;
  }


  deleteAt() {
    return false
  }

  
}
TOCBlot.blotName = 'tocs';
TOCBlot.tagName = 'div';
TOCBlot.className = 'tocs';