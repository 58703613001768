import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Feather from 'mdi-material-ui/Feather';
import IconButton from '@mui/material/IconButton';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import LaurasButton from './LaurasButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ListItemButton from '@mui/material/ListItemButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const LaurasDetailList = (props) => {

    let { showWriteIcon = true, showFormatIcon = true, showRestoreButton = false, showReadIcon = false, showExpandIcon = false } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const secondaryActions = [];

    if (showWriteIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <Feather />
        </IconButton>)
    }

    if (showFormatIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <FormatPaintIcon />
        </IconButton>)
    }

    if (showReadIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <ManageSearchIcon />
        </IconButton>)
    }

    if (showRestoreButton) {
        secondaryActions.push(<IconButton
            onClick={props.onRestoreClick}>
            <MoreVertIcon />
        </IconButton>)
    }

    if (showExpandIcon) {
        secondaryActions.push(<IconButton
            onClick={props.onExpandClick}>
            <FormatListBulletedIcon/>
        </IconButton>)
    }


    return (
        <List>
            <ListItem
                sx={{ padding: ' 0.25em' }}
                secondaryAction={
                    secondaryActions
                }
            >
                <ListItemAvatar>
                    <Avatar variant="rounded">
                        {props.avatarType}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={props.primary}
                    secondary={props.secondary}
                />
            </ListItem>

        </List>
    )
}
export default inject('stores')(observer(LaurasDetailList));