import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetOwnedBooks } from '../../managers/BooksManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";

const OwnedBooksList = (props) => {

  let user = props.stores.userStore.user;

  let { filteredBooks, filteredBookIds } = props;

  const { ownedBooks, error, loading } = useGetOwnedBooks(props);
  const [availableBooks, setAvailableBooks] = useState([])

  let remainingBooks = ownedBooks;

  //useState(() => {

    

    if (filteredBooks) {

      remainingBooks = ownedBooks.filter(ownedBook => !filteredBooks.find(rm => (rm.id === ownedBook.id)))

    }

    if (filteredBookIds) {

      remainingBooks = remainingBooks.filter(ownedBook => !filteredBookIds.find(bookId => (bookId === ownedBook.id)))

    }

    //setAvailableBooks(remainingBooks);

  //}, [filteredBooks, filteredBookIds, ownedBooks, loading])




  const createSecondaryActions = (book) => {

    let secondaryActions = [];

    if (props.showSelectIcon) {
      secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
        //console.log(book)
        props.onSelected(book);
      }}>
        <ShareIcon />
      </IconButton>);
    }

    return secondaryActions;
  }

  return (
    <List component="div" disablePadding>
      {
        ownedBooks.map((book) => {

          return <ListItem secondaryAction={createSecondaryActions(book)}>
            <ListItemAvatar>
              <AvatarTableCell
                bucketName={config.user_uploads_bucket_name}
                s3Src={book?.coverUrl}
              //title={userConnection?.connectedUser?.name}
              />
            </ListItemAvatar>
            <ListItemText primary={book?.title} secondary="" />

          </ListItem>
        })
      }
      {ownedBooks.length}:
      {availableBooks.length}
    </List>

  )
}

export default inject('stores')(observer(OwnedBooksList));