import Quill from 'quill';
//import Parchment from 'parchment';
import { v4 as uuid }  from 'uuid';
import Delta from 'quill-delta';
import DeltaManager from '../DeltaManager';

let $ = require("jquery");
const Parchment = Quill.import('parchment');
let ACTION_TYPES = {
	UNKNOWN: 'unknown',
	DELETING: 'deleting',
	INSERTING: 'inserting'
}

let FORMAT_TYPES = {
	NONE: 'none',
	DELETING: 'editingDeleteBlot',
	INSERTING: 'editingInsertBlot'
}
class ChangeTracker2 {

	constructor(quill, options) {
		this.active = false;
		this.quill = quill;
		this.options = options;
		this.container = document.querySelector(options.container);
		quill.on('text-change', this.update.bind(this));
		this.update();  // Account for initial contents
	}


	update(delta, oldDelta, source){
		//console.log(delta);
	}

	

}


export default ChangeTracker2;