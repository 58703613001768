import React, { useState, useEffect } from "react";
import { Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import { useManagerDeleteSnapshot } from '../../managers/BooksManager';
import StandardTextfield from "../StandardTextfield";

const DeleteSnapshotPanel = (props) => {

  let user = props.stores.userStore.user;
  let { book, snapshot } = props;

  let [title, setTitle] = useState();
  let [description, setDescription] = useState();

  const { deleteSnapshot, loading: updateItemLoading, error: updateItemError } = useManagerDeleteSnapshot(props);

  

  return (
    <Grid container direction={'column'}>
       <Grid item>
        <Typography variant={'h6'}>
          This snapshot will be deleted and moved to the trashbin. To permanently delete it go there...
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={'h6'}>{updateItemLoading && <div>deleting snapshot...</div>}</Typography>
      </Grid>

      <Divider />
      <Grid container direction={'row'} columnSpacing={2} sx={{
        mt: 3,
        justifyContent: 'flex-end'
      }}>
        <Grid item>
          <StandardButton autoFocus onClick={props?.onClose}>
            Cancel
          </StandardButton>
        </Grid>
        <Grid item>
          <StandardButton autoFocus disabled={updateItemLoading ? true : false} onClick={(e) => {
            e.preventDefault();

            deleteSnapshot({
              book,
              snapshotId:snapshot.id
            }, () => {
              if(props.onDeleted){
                props.onDeleted();
              }
             
            })
          }}>
            Ok
          </StandardButton>
        </Grid>
      </Grid>





    </Grid>

  )
}

export default inject('stores')(observer(DeleteSnapshotPanel));