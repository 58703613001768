import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Tab, Grid, Box, Avatar, Typography, Button, IconButton, Paper, SwipeableDrawer } from '@mui/material';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import AppWrapper from '../../AppWrapper';
import Library from './Library';
import OwnedBooksListMenuContent from '../../menus/OwnedBooksListMenuContent';
import AppWrapperDummy from '../../AppWrapperDummy';
import StretchBox from '../StretchBox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StandardAppBar from '../../StandardAppBar';
import MainMenuContent2 from '../../menus/MainMenuContent2';
import BoxsetsTable from '../BoxsetsTable';
import ConnectionsTable from '../ConnectionsTable';
import ConnectionsMobileFooterBar from '../../footers/ConnectionsMobileFooterBar';
import ScrollBox from '../ScrollBox';
import ConnectionsAndGroups from '../../table/ConnectionsAndGroups';
import ConnectionsList from '../../lists/ConnectionsList';
import ReceivedEditRequestsList from '../../lists/ReceivedEditRequestsList';
import StandardFloatingPlus from '../../StandardFloatingPlus';
import StandardTabs from '../../StandardTabs';
import ReceivedEditRequests from '../../listPages/ReceivedEditRequests';
import SentEditRequests from '../../listPages/SentEditRequests';

const EditRequests = (props) => {



  const [selectedTab, setSelectedTab] = React.useState('receivedEditRequests');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (

    // <AppWrapperDummy>
    <StretchBox flexDirection='column'>



      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <StandardTabs
          value={selectedTab}
          onChange={(event, newValue) => {
            setSelectedTab(newValue);
          }}
          aria-label="basic tabs example">
          {/* <Tab value='editOld' label="Edit Old" {...a11yProps(0)} /> */}
          <Tab value='receivedEditRequests' label="Received Requests" {...a11yProps(1)} />
          <Tab value='sentEditRequests' label="Sent Requests" {...a11yProps(2)} />
          {/* <Tab value='bpBoxsets' label="Boxsets BP" {...a11yProps(3)} />
            <Tab value='bpBoxsets2' label="Boxsets BP2" {...a11yProps(3)} /> */}
        </StandardTabs>

      </Box>


      {/* {selectedTab && selectedTab == 'editOld' &&

        <ReceivedEditRequestsList />

      } */}

      {selectedTab && selectedTab == 'receivedEditRequests' &&
        <ReceivedEditRequests />
      }

      {selectedTab && selectedTab == 'sentEditRequests' &&
        <SentEditRequests />
      }

      <ConnectionsMobileFooterBar />
    </StretchBox>

    // </AppWrapperDummy>


  )
}
export default EditRequests;
// export default inject('stores')(observer(Books));