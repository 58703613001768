import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from 'react-responsive';
import ScrollingGridContainer from './ScrollingGridContainer';
import ScrollingGridItem from './ScrollingGridItem';


const StandardContentWithFooterContainer = (props) => {


  const theme = useTheme();

  let { smallFooterContent, largeFooterContent} = props;

  const isXs = useMediaQuery({ maxWidth: 599 });
  const isSm = useMediaQuery({ maxWidth: 600 });
  const isMd = useMediaQuery({ maxWidth: 900 });
  const isLg = useMediaQuery({ maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1536 });





  return (

    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      //border:'1px solid red'
    }}>



      <ScrollingGridContainer container columns={100} direction={'row'} >


        <ScrollingGridItem xs={100}>
          <Box sx={{
            //backgroundColor: theme.palette.write?.background?.color,
            //backgroundColor: theme.palette.background.paper,
            height: '100%'
          }}>

           {props.children}
          </Box>

        </ScrollingGridItem>



      </ScrollingGridContainer>
      {(isSm && smallFooterContent ) &&
        smallFooterContent
      }
      {(!isSm && largeFooterContent ) &&
        largeFooterContent
      }
    </Box>

  );
};


export default inject('stores')(observer(StandardContentWithFooterContainer));
