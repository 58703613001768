import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTheme } from '@mui/material/styles';


const StandardHelpLabel = (props) => {

    // let helpUrlSuffix = '';

    // if(props.helpContent){
    //     helpUrlSuffix = props.helpContent;
    // }

    const theme = useTheme();


    return (
        <div style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            marginx: '.5em 0',

        }}>
            <Typography
                variant="h7"
                component="div"
                sx={{
                    // textTransform: "capitalize",
                    // fontWeight: 'bold',
                }}>{props.title}</Typography>
            <InfoOutlinedIcon sx={{
                marginLeft: '1em'
            }} onClick={(e) => {
                e.preventDefault();
                // props.stores.bookStore.showHelpDrawer = true;

            }} />
        </div>
    );
}

export default inject('stores')(observer(StandardHelpLabel));