import { Grid, IconButton, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import React, { useState } from "react";
import StandardDialog from "../StandardDialog";
import StandardButton from "../StandardButton";
import { action } from "mobx";

const StandardOkCancelDialog = (props) => {

  let { editRequest, showOkButton, showCancelButton } = props;

  let actionButtons = [];

  if (showOkButton) {
    actionButtons.push(
      <StandardButton autoFocus onClick={(e) => {
        e.preventDefault();
        if (props.onOk) {
          props.onOk();
        }
      }}>
        Ok
      </StandardButton>
    )
  }

  if (showCancelButton) {
    actionButtons.push(
    <StandardButton autoFocus onClick={props?.onClose}>
      Cancel
    </StandardButton>
    );
  }

  return (
    <StandardDialog
      open={props.open}
      onClose={props.onClose}
      dialogContent={props.content}
      title={props.title}
      actions={actionButtons}
      // actions={[
      //   <StandardButton autoFocus onClick={props?.onClose}>
      //     Cancel
      //   </StandardButton>,
      //   <StandardButton autoFocus onClick={(e) => {
      //     e.preventDefault();
      //     if (props.onOk) {
      //       props.onOk();
      //     }
      //   }}>
      //     Ok
      //   </StandardButton>
      // ]}
      >

    </StandardDialog>
  )
}

export default inject('stores')(observer(StandardOkCancelDialog));