import React, { useEffect, useState } from 'react';
import { Button, styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import BaseControl from "../BaseControl";
import { useTheme } from '@mui/material/styles';
import { Box, Checkbox } from "@mui/material";
import { v4 as uuid } from "uuid";
// import Switch from '@mui/material/Switch';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import SelfOverrideSwitch from './SelfOverrideSwitch';
import { RecipeUtils } from '../../RecipeUtils';
import { ChromePicker } from 'react-color'

function PropsColorPicker(props) {

  const theme = useTheme();
  let baseControl = new BaseControl(props);

  const { classes, element, bookDraft, documentPart } = props;

  let [displayColorPicker, setDisplayColorPicker] = useState(false);
  let [color, setColor] = useState({});


  useEffect(()=>{

    let colorInfo = {};
    try{
  
      let paramValue = new RecipeUtils().getRecipeParamByPartType(null, currentRecipe, documentPart, recipeParamPath);
      if (typeof paramValue === 'object' && paramValue !== null) {
         colorInfo = paramValue;
      } else {
        colorInfo = JSON.parse(paramValue);
      }
     
     
      if(colorInfo!=null){
        setColor(colorInfo);
      }
      

    }catch(err){
      console.log(err);

      // let paramValue = new RecipeUtils().getRecipeParamByPartType(null, currentRecipe, documentPart, recipeParamPath);
      // //colorInfo = JSON.parse(paramValue);
      // colorInfo = paramValue;
  
      // setColor(colorInfo);
    }

    
  },[])


  let isVisible = baseControl.isVisible();
  if (!isVisible) {
    return <div style={{
      height: '0em'
    }}></div>
  }

  let item = documentPart;
  let properties_book = bookDraft.book;
  let boxSet = bookDraft.book.boxSet;

  let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();
  let hideBookProperties = false;

  let canEdit = true;


  let isOwner = true;


  if (element.paramItem == 'BOOK') {
    item = bookDraft.book;
  }
  if (element.paramItem == 'BOXSET_MAIN_BOOK') {
    item = boxSet.book;
  }
  if (element.paramItem == 'BOXSET_BOOK') {
    item = boxSetBook.book;
  }
  if (element.paramItem == 'BOXSETBOOK') {
    item = boxSetBook;
  }

  //console.log('gonna call it...')

  // let checked = baseControl.getIsChecked();

  // //The following is necessary to stop the MuiCheckbox from defaulting to uncontrolled mode
  // //if it is initilized with a null or undefined value. 
  // if (checked == null || checked == undefined) {

  //   checked = false;
  // }




  let currentRecipe = props.documentPart?.getRecipe().json;
  let recipeParamPath = element.recipeParamPath;



 
  

  let title = baseControl.convertTitle(element.title, documentPart);

  const label = { inputProps: { 'aria-label': title } };


  const popover = {
    position: 'absolute',
    zIndex: '2',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '100px',
    bottom: '0px',
    left: '0px',
  }

  const handleClick = () => {

    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {

    setDisplayColorPicker(false);
  };


  const handleChange = (color) => {
    setColor(color.rgb);

    try{

      let newValue = JSON.stringify(color.rgb);
      //let newValue = color.rgb;
      new RecipeUtils().setRecipeParamByPartType(null, currentRecipe, documentPart, recipeParamPath, newValue);
      baseControl.updateRecipeProp(newValue);

    }catch(err){

      console.log(err);

    }

  };

  // const styles = {
  //   'default': {
  //     color: {
  //       width: '36px',
  //       height: '14px',
  //       borderRadius: '2px',
  //       background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
  //     },
  //     swatch: {
  //       padding: '5px',
  //       background: '#fff',
  //       borderRadius: '1px',
  //       boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
  //       display: 'inline-block',
  //       cursor: 'pointer',
  //     },
  //     popover: {
  //       position: 'absolute',
  //       zIndex: '2',
  //     },
  //     cover: {
  //       position: 'fixed',
  //       top: '0px',
  //       right: '0px',
  //       bottom: '0px',
  //       left: '0px',
  //     },
  //   },
  // };

  return (

    <Box sx={{
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}>


      <Box sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}>



        {/* <Checkbox color={'secondary'} key={uuid()} {...label} checked={checked} onChange={(e) => {
          let newValue = e.target.value;
          console.log(newValue);

          let newIsChecked = e.target.checked;
          if (element.invertValue) {
            newIsChecked = !newIsChecked;
          }

          if (!newIsChecked) {

            newValue = element.unCheckedValue;

          } else {

            newValue = element.checkedValue;

          }

   
          new RecipeUtils().setRecipeParamByPartType(null, currentRecipe, documentPart, recipeParamPath, newValue);
          baseControl.updateRecipeProp(newValue);
          //props.stores.bookStore.recipeTimestamp = new Date().getTime();
          // baseControl.updateValue({
          //   value: newValue
          // });


          // if (newValue && newValue.toLowerCase() == 'on') {
          //   newValue = 'true';
          // }
          // baseControl.updateValue({
          //   value: newValue
          // });


        }} /> */}

        <div style={{
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        }} onClick={handleClick}>
          <div style={{
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: color!=null?`rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`:null
          }} />
        </div>
          <Button onClick={(e)=>{
            e.stopPropagation();
            handleChange({});
          }}>Clear</Button>
        {/* <div>{JSON.stringify(color)}</div> */}
        {displayColorPicker ? <div style={popover}>
          <div style={cover} onClick={handleClose} />
          <ChromePicker color={ color} onChange={ handleChange } />
          
        </div> : null}

        {/* <Typography>{title}</Typography> */}
      </Box>

      <SelfOverrideSwitch
        element={element}
        bookDraft={bookDraft}
        documentPart={documentPart}
        onUpdateRecipe={props.onUpdateRecipe} />


    </Box>


  );
}


export default inject('stores')(observer(PropsColorPicker));



