import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material";
import { inject, observer } from 'mobx-react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import BaseControl from "./BaseControl";
import { useTheme } from '@mui/material/styles';
import StandardRadioButtons from "../../StandardRadioButtons";

function FormatRadioButtons(props) {

  const theme = useTheme();
  let baseControl = new BaseControl(props);

  const { classes, element, bookDraft, documentPart } = props;




  let isVisible = baseControl.isVisible();
  if (!isVisible) {
    return <div style={{
      height: '0em'
    }}></div>
  }

  let item = documentPart;
  let properties_book = bookDraft.book;
  let boxSet = bookDraft.book.boxSet;

  let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();
  let hideBookProperties = false;

  let canEdit = true;


  let isOwner = true;


  if (element.paramItem == 'BOOK') {
    item = bookDraft.book;
  }
  if (element.paramItem == 'BOXSET_MAIN_BOOK') {
    item = boxSet.book;
  }
  if (element.paramItem == 'BOXSET_BOOK') {
    item = boxSetBook.book;
  }
  if (element.paramItem == 'BOXSETBOOK') {
    item = boxSetBook;
  }

  //console.log('gonna call it...')
  let value = baseControl.getValue();


  let options = element.options.map((option) => {

    let label = option.label.replace(/---PART_TYPE---/gi, baseControl.toCappedPartType(documentPart));
    label = label.replace(/---PART_TYPE_LOWER---/gi, documentPart.partType.toLowerCase());

    return (

      {
        label: label,
        value: option.value
      }


      // <StandardRadioButtons title={'Top Margin'} options={[
      //   { label: 'Small', value: 'small' },
      //   { label: 'Medium', value: 'Medium' },
      //   { label: 'Large', value: 'Large' }
      // ]} />




      // <div className='radio-button-wrapper' >



      //   <Radio
      //     checked={value === option.value}
      //     onChange={(e) => {
      //       e.stopPropagation();
      //       baseControl.handleChange({
      //         value: option.value
      //       });
      //     }}
      //     value={option.value}
      //     name="radio-button-demo"
      //     color={'primary'}
      //     inputProps={{ 'aria-label': 'Image' }}
      //     disabled={false} />
      //   <div className='property-element-item-label'>{label}</div>


      // </div>
    )
  })


  return (

    <>
      {/* <div>value:{value}</div> */}
      <StandardRadioButtons {...props}
        options={options}
        value={value}
        onChange={(e) => {
          let newValue = e.target.value;
          baseControl.updateValue({
            value: newValue
          });
        }} />
    </>


  );
}


export default inject('stores')(observer(FormatRadioButtons));



