import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import StandardDataTable from './StandardDataTable';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useGetUserConnections, useGetUserInvitedToGroups, useManagerAcceptGroupInvitation, useManagerLeaveGroup } from '../../managers/ConnectionsManager';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import config from '../../aws-exports';
import ReactTimeAgo from 'react-time-ago';
import AvatarTableCell from './AvatarTableCell';
import ShareIcon from '@mui/icons-material/Share';
import CommentIcon from '@mui/icons-material/Comment';
import PeopleIcon from '@mui/icons-material/People';
import StandardDialog from '../StandardDialog';
import LeaveGroupPanel from '../panels/LeaveGroupPanel';
import SettingsIcon from '@mui/icons-material/Settings';
import TablePopupMenu from '../menus/TablePopupMenu';
import { useMediaQuery } from 'react-responsive';
import SharedGroupsList from '../lists/SharedGroupsList';

const SharedGroupsTable = (props) => {

  const { userGroups, loading: getUserInvitedToUserGroupsLoading, error: getUserInvitedToUserGroupsError, getUserInvitedToUserGroupsRefetch } = useGetUserInvitedToGroups(props);
  const { acceptGroupInvitation, loading: acceptGroupInvitationLoading, error: acceptGroupInvitationError } = useManagerAcceptGroupInvitation(props);
  const { leaveGroup, loading: leaveGroupLoading, error: leaveGroupError } = useManagerLeaveGroup(props);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [showLeaveGroup, setShowLeaveGroup] = React.useState(false);
  const [selectedUserGroupUser, setSelectedUserGroupUser] = React.useState(null);




  const isXs = useMediaQuery({ maxWidth: 599 })
  const isSm = useMediaQuery({ maxWidth: 600 })
  const isMd = useMediaQuery({ maxWidth: 900 })
  const isLg = useMediaQuery({ maxWidth: 1200 })
  const isXl = useMediaQuery({ minWidth: 1536 })


  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {


    setOrder("desc");
    setOrderBy("createdAt");

  }, [userGroups]);






  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      {!isXs &&
        <StandardDataTable 
        // title={'Shared Groups'}
          noDataMessage={'Groups you have been invited to will appear here!'}
          structure={{
            dataDef: {
              data: userGroups,
              rowKeyDataFieldName: 'id'
            },
            columns: [
              {
                title: 'Group',
                sortable: true,
                sortFieldNameOverride:'title',
                dataValueFunction: (userGroupUser) => {
                  return <AvatarTableCell
                    bucketName={config.user_uploads_bucket_name}
                    s3Src={userGroupUser.userGroup?.profilePicUrl}
                    title={userGroupUser.userGroup?.name}
                    details={userGroupUser.userGroup?.description}
                    onClick={(e) => {
                      e.preventDefault();
                      //console.log(userGroupUser)
                      props.stores.bookStore.sharedGroupListGroup = userGroupUser.userGroup;
                      navigate("/sharedGroups/details");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }}
                  />
                }
              },


              {
                title: 'Owner',
                dataValueFunction: (userGroupUser) => {
                  return <AvatarTableCell
                    bucketName={config.user_uploads_bucket_name}
                    s3Src={userGroupUser.userGroup?.groupOwner?.profilePicUrl}
                    title={userGroupUser.userGroup?.groupOwner?.name}
                  //   onClick={(e) => {
                  //     e.preventDefault();
                  //     console.log(userGroupUser)
                  //     props.stores.bookStore.sharedGroupListGroup = userGroupUser.userGroup;
                  //     navigate("/sharedGroups/details");
                  //     // openBookInWrite({
                  //     //   bookDraftId: book.primaryBookDraftId
                  //     // });
                  // }}
                  />

                },
                ignore: isXs
              },
              // {
              //   title: 'Invited',
              //   dataFieldName: 'invitedAt',
              //   sortable: true,
              //   dataValueFunction: (userGroupUser) => { return userGroupUser.invitedAt != null ? <ReactTimeAgo date={userGroupUser.invitedAt} locale="en-US" /> : '' }
              // },
              // {
              //   title: 'Accepted',
              //   dataFieldName: 'acceptedAt',
              //   sortable: true,
              //   dataValueFunction: (userGroupUser) => { return userGroupUser.acceptedAt != null ? <ReactTimeAgo date={userGroupUser.acceptedAt} locale="en-US" /> : '' }
              // },
              // {
              //   title: 'Left',
              //   dataFieldName: 'leftAt',
              //   sortable: true,
              //   dataValueFunction: (userGroupUser) => { return userGroupUser.leftAt != null ? <ReactTimeAgo date={userGroupUser.leftAt} locale="en-US" /> : '' }
              // },



              // {
              //   title: 'Profile Pic',
              //   dataValueFunction: (userGroup) => { return <EditIcon sx={{ color: theme.palette.words?.main }} /> }
              // },
              // {
              //   title: 'Name',
              //   dataValueFunction: (userGroup) => {
              //     console.log(userGroup);
              //     return userGroupUser.userGroup?.name
              //   }
              // },
              {
                title: 'Shared',
                dataValueFunction: (userGroupUser) => {
                  return <IconButton aria-label="delete">
                    <ShareIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                      e.preventDefault();
                      //console.log(userGroupUser)
                      props.stores.bookStore.sharedGroupListGroup = userGroupUser.userGroup;
                      navigate("/sharedGroups/details/shared");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }} />
                  </IconButton>
                },
                ignore: isXs
              },
              // {
              //   title: 'Members',
              //   dataValueFunction: (userGroupUser) => {
              //     return <IconButton aria-label="delete">
              //       <PeopleIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
              //         e.preventDefault();
              //         console.log(userGroupUser)
              //         props.stores.bookStore.sharedGroupListGroup = userGroupUser.userGroup;
              //         navigate("/sharedGroups/details/members");
              //         // openBookInWrite({
              //         //   bookDraftId: book.primaryBookDraftId
              //         // });
              //       }} />
              //     </IconButton>
              //   },
              //   ignore: isXs
              // },
              {
                title: 'Feedback',
                dataValueFunction: (userGroupUser) => {
                  return <IconButton aria-label="delete">
                    <CommentIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                      e.preventDefault();
                      //console.log(userGroupUser)
                      props.stores.bookStore.sharedGroupListGroup = userGroupUser.userGroup;
                      navigate("/sharedGroups/details/feedback");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }} />
                  </IconButton>
                },
                ignore: isXs
              },
              {
                title: 'Permissions',
                dataValueFunction: (userGroupUser) => {
                  return <IconButton aria-label="delete">
                    <CommentIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                      e.preventDefault();
                      //console.log(userGroupUser)
                      props.stores.bookStore.sharedGroupListGroup = userGroupUser.userGroup;
                      navigate("/sharedGroups/details/permissions");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }} />
                  </IconButton>
                },
                ignore: isXs
              },
              {
                title: 'Connected',
                dataFieldName: 'createdAt',
                sortable: true,
                dataValueFunction: (userGroupUser) => {

                  let actionButton = <Button variant={'outlined'}
                    sx={{ color: theme.palette.background.main }}
                    onClick={(e) => {
                      e.preventDefault();
                      //console.log(userGroupUser);
                      // setSelectedBook(book);
                      // setShowDeleteBookDialog(true);
                      acceptGroupInvitation({
                        userGroupId: userGroupUser.userGroup.id
                      });

                    }} >Accept</Button>

                  if (userGroupUser.acceptedAt != null) {
                    actionButton = userGroupUser.acceptedAt != null ? <ReactTimeAgo date={userGroupUser.acceptedAt} locale="en-US" /> : ''
                  }


                  return actionButton;


                  // return userGroupUser.userGroup.createdAt != null ? <ReactTimeAgo date={userGroupUser.userGroup.createdAt} locale="en-US" /> : '' 




                },
                ignore: isXs
              },
              // {
              //   title: '',
              //   dataValueFunction: (userGroupUser) => {

              //     let actionButton = <Button variant={'outlined'}
              //       sx={{ color: theme.palette.background.main }}
              //       onClick={(e) => {
              //         e.preventDefault();
              //         console.log(userGroupUser);
              //         // setSelectedBook(book);
              //         // setShowDeleteBookDialog(true);
              //         acceptGroupInvitation({
              //           userGroupId: userGroupUser.userGroup.id
              //         });

              //       }} >Accept</Button>

              //     if (userGroupUser.acceptedAt != null) {
              //       actionButton = <Button variant={'outlined'}
              //         sx={{ color: theme.palette.background.main }}
              //         onClick={(e) => {
              //           e.preventDefault();
              //           console.log(userGroupUser);
              //           setSelectedUserGroupUser(userGroupUser);
              //           setShowLeaveGroup(true);


              //         }} >Leave</Button>
              //     }


              //     return actionButton;

              //   }
              // },
              // {
              //     title: 'Delete',
              //     dataValueFunction: (userGroup) => {
              //         return <IconButton aria-label="delete">
              //             <TrashCanOutline sx={{ color: theme.palette.background.main }} onClick={(e) => {
              //                 e.preventDefault();
              //                 console.log(userGroup);
              //                 // setSelectedBook(book);
              //                 // setShowDeleteBookDialog(true);

              //             }} />
              //         </IconButton>
              //     }
              // },

              {
                title: '',
                dataValueFunction: (userGroupUser) => {
                  return <TablePopupMenu
                    menuItems={[
                      //   <ListItemButton onClick={(e) => {
                      //     e.preventDefault();
                      //     console.log(userGroupUser)
                      //     props.stores.bookStore.connectionsListConnection = userGroupUser.userGroup;
                      //     navigate("/sharedGroups/details");
                      //     // openBookInWrite({
                      //     //   bookDraftId: book.primaryBookDraftId
                      //     // });
                      // }}  >

                      //     <ListItemIcon>
                      //       <SettingsIcon sx={{ color: theme.palette.background.main }} />
                      //     </ListItemIcon>
                      //     <ListItemText primary="Details" />
                      //   </ListItemButton>,
                      // <ListItemButton onClick={(e) => {
                      //   e.preventDefault();
                      //   console.log(userGroupUser)
                      //   props.stores.bookStore.userGroupListGroup = userGroupUser.userGroup;
                      //   navigate("/sharedGroups/details/members");
                      //   // openBookInWrite({
                      //   //   bookDraftId: book.primaryBookDraftId
                      //   // });
                      // }}   >

                      //   <ListItemIcon>
                      //     <PeopleIcon sx={{ color: theme.palette.background.main }} />
                      //   </ListItemIcon>
                      //   <ListItemText primary="Members" />
                      // </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(userGroupUser)
                        props.stores.bookStore.connectionsListConnection = userGroupUser.userGroup;
                        navigate("/sharedGroups/details/shared");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }} >

                        <ListItemIcon>
                          <ShareIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Shared" />
                      </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(userGroupUser)
                        props.stores.bookStore.connectionsListConnection = userGroupUser.userGroup;
                        navigate("/sharedGroups/details/feedback");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }} >

                        <ListItemIcon>
                          <CommentIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Feedback" />
                      </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(userGroupUser)
                        props.stores.bookStore.connectionsListConnection = userGroupUser.userGroup;
                        navigate("/sharedGroups/details/permissions");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }} >

                        <ListItemIcon>
                          <CommentIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Permissions" />
                      </ListItemButton>,
                    ]}
                  />
                },
                ignore: !isXs
              },









            ]
          }} />
      }
      {isXs &&
        <SharedGroupsList
          filteredBooks={[]}
          showSelectIcon
          onSelected={(userGroupUser) => {

            if (userGroupUser) {

              //console.log(userGroupUser)
              props.stores.bookStore.sharedGroupListGroup = userGroupUser.userGroup;
              navigate("/sharedGroups/details");

            }

          }}
        />
      }

      <StandardDialog
        open={showLeaveGroup}
        onClose={() => {
          setShowLeaveGroup(false)
        }}
        onOk={() => {
          setShowLeaveGroup(false)

        }}
        title={'Leave Group'}
        dialogContent={
          <LeaveGroupPanel
            userGroupUser={selectedUserGroupUser}
            onGroupCreated={() => {
              setShowLeaveGroup(false)
            }} />
        }
      />

    </Box>

  )
}
export default inject('stores')(observer(SharedGroupsTable));