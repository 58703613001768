import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import StandardDataTable from '../table/StandardDataTable';
import { useManagerLoadBoxSetContributors } from '../../managers/BoxsetsManager';
import CancelTableCell from '../table/CancelTableCell';
import RemoveBoxsetContributedBookPanel from '../panels/RemoveBoxsetContributedBookPanel';
import StandardOkCancelDialog from '../panels/StandardOkCancelDialog';
import AvatarTableCell from '../table/AvatarTableCell';
import config from '../../aws-exports';
import ImageTitleTableCell from '../table/ImageTitleTableCell';
import { useMediaQuery } from 'react-responsive';
import OwnedBoxsetBooksList from '../lists/OwnedBoxsetBooksList';
import AddBookAssembly from '../assemblies/AddBookAssembly';
import StandardDashboardPaper from '../StandardPaper';

const OwnedBoxsetBooks = (props) => {


  //let boxset = props.stores.bookStore.boxSetListBoxSet;
  let boxset = props.boxset;

  const isXs = useMediaQuery({ maxWidth: 599 });
  const isSm = useMediaQuery({ maxWidth: 600 });
  const isMd = useMediaQuery({ maxWidth: 900 });
  const isLg = useMediaQuery({ maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1536 });

  let [boxsetBooks, setBoxsetBooks] = useState([]);
  let [showRemoveBookDialog, setShowRemoveBookDialog] = useState(false);
  let [selectedBook, setSelectedBook] = useState(false);
  
  let {
    boxsetContributors,
    loading: lazyLoadBoxSetContributorsLoading,
    error: lazyLoadBoxSetContributorsError
  } = useManagerLoadBoxSetContributors(props, boxset?.id);

  //console.log(boxsetContributors);

  useEffect(() => {

    try {

      let bookData = {};
      boxsetContributors.forEach((boxsetContributor) => {

        boxsetContributor.contributedBooks.items.forEach((bsb) => {

          bookData[bsb.book.id] = ({
            boxsetContributor,
            boxsetBook: bsb
          })
        })

      });

      setBoxsetBooks(Object.values(bookData));

    } catch (err) {
      console.log(err);
    }
  }, [boxsetContributors]);


  return (
    <>
      {/* <StandardDashboardPaper sx={{
        position: 'relative',
        //height: '15em',
        borderRadius: '8px',
        //marginTop: '2em',
        padding: '.5em 1em 1em 1em'
      }}> */}
        {boxset &&
          <StandardDataTable
            title={'Contributed Books'}
            noDataMessage={'Contributed books will show up here.'}
            headerActions={<AddBookAssembly boxset={boxset} />}
            structure={{
              dataDef: {
                data: boxsetBooks,
                rowKeyDataFieldName: 'id'
              },
              columns: [
                {
                  title: 'Title',
                  dataValueFunction: (bsbData) => {
                    return <ImageTitleTableCell
                      bucketName={config.user_uploads_bucket_name}
                      title={bsbData.boxsetBook.book.title}
                      s3Src={bsbData.boxsetBook.book.coverUrl}
                      onClick={(e) => {
                        e.preventDefault();
                        //console.log(boxset)
                        // props.stores.bookStore.boxSetListBoxSet = boxset;
                        // navigate("/boxsets/details");
                      }}
                    />

                  }
                },
                {
                  title: 'Author',
                  dataValueFunction: (bsbData) => {
                    return <AvatarTableCell
                      bucketName={config.user_uploads_bucket_name}
                      s3Src={bsbData.boxsetContributor.contributor.profilePicUrl}
                      title={bsbData.boxsetContributor.contributor.name}
                      onClick={(e) => {
                        e.preventDefault();
                        // console.log(boxsetContributor)
                        // props.stores.bookStore.connectionsListConnection = userConnection;
                        // navigate("/connections/details");
                      }}
                    />

                  }
                },
                // {
                //   title: 'Pic',
                //   dataValueFunction: (bsbData) => { return <Avatar sx={{ width: '1.5em', height: '1.5em' }} variant="square" alt="" src="https://www.adobe.com/express/create/cover/media_178ebed46ae02d6f3284c7886e9b28c5bb9046a02.jpeg?width=400&format=jpeg&optimize=medium" /> }
                // },
                // {
                //   title: 'Name',
                //   dataValueFunction: (bsbData) => { return bsbData.boxsetContributor.contributor.name }
                // },
                // {
                //   title: 'Title',
                //   dataValueFunction: (bsbData) => { return bsbData.boxsetBook.book.title }
                // },
                {
                  title: 'Remove',
                  dataValueFunction: (bsbData) => {

                    return <CancelTableCell onClick={(e) => {
                      e.preventDefault();
                      setShowRemoveBookDialog(true);
                      setSelectedBook(bsbData.boxsetBook.book);
                    }} />
                  }
                },
              ]
            }}
          />
        }
        {/* {isXs && boxset &&
          <OwnedBoxsetBooksList
            filteredBooks={[]}
            showSelectIcon
            onSelected={(bsbData) => {

              if (bsbData) {

                console.log(bsbData)
                // props.stores.bookStore.bookListBook = book;

              }

            }}
          />
        } */}


        <StandardOkCancelDialog
          showOkButton={true}
          showCancelButton={true}
          open={showRemoveBookDialog}
          onClose={() => {
            setShowRemoveBookDialog(false)
            setSelectedBook(null)
          }}
          onOk={() => {
            setShowRemoveBookDialog(false)
            setSelectedBook(null)

          }}
          title={'Remove Contributor'}
          content={
            <RemoveBoxsetContributedBookPanel boxset={boxset} book={selectedBook} onRemoved={() => {
              setShowRemoveBookDialog(false)
              setSelectedBook(null)
              //lazyLoadBoxSetContributorsRefetch();

            }} />
          }
        />
      {/* </StandardDashboardPaper> */}
    </>

  )
}

export default inject('stores')(observer(OwnedBoxsetBooks));