import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Skeleton, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StandardTree from '../../StandardTree';
import ScrollingGridContainer from '../../ScrollingGridContainer';
import ScrollingGridItem from '../../ScrollingGridItem';
import BookDraftEditor from '../../editor/BookDraftEditor';
import SplitScreenController from '../../SplitScreenController';
import { useLoadBookDraftForWrite, useLoadSnapshotForWrite } from '../../../managers/BooksManager';
// import BookDraftTree3 from '../../tree/BookDraftTree3';
import BookDraftTree7 from '../../tree/BookDraftTree7';
import { useMediaQuery } from 'react-responsive'
import WriteFooterBar from '../../editor/WriteFooterBar';
import StandardDetailTitle from '../../StandardDetailTitle';
import EmptyContent from '../../EmptyContent';
import { Fade } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Feather from 'mdi-material-ui/Feather';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import ToolsNavigator from '../../../routes/ToolsNavigator';
import WriteDetails from '../../editor/WriteDetails';
import StandardSkeleton from '../../../StandardSkeleton';
import { useGetMappedImages } from '../../../managers/StorageManager';
import OwnedBooksList from '../../lists/OwnedBooksList';
import { Typography } from 'antd';
import { useOpenBookInWrite, useCloseSnapshotInWrite } from '../../../managers/BooksManager';
import { StandardListButton } from '../../StandardButton';
import { useAddBookDraftDocumentPart, useMoveBookDraftDocumentPart } from '../../../persistance/persistanceHooks';
import { useLoadBookWriteComments, useLoadBookWriteNotes } from '../../../managers/BooksManager';
import config from '../../../config';
import AppWrapperDummy from '../../AppWrapperDummy';



const Write = (props) => {

  let user = props.stores.userStore.user;
  const theme = useTheme();

  //Load ownedBooks for blots
  //const { ownedBooks, error } = useGetOwnedBooks(props);
  const { openBookInWrite } = useOpenBookInWrite(props);
  const { closeSnapshotInWrite } = useCloseSnapshotInWrite(props);



  let [splitPaneMode, setSplitPaneMode] = useState(false);
  const [selectedBddp, setSelectedBddp] = React.useState(null);
  const [showBothPanes, setShowBothPanes] = React.useState(true);

  const [tree, setTree] = React.useState(null);
  const [tree2, setTree2] = React.useState(null);
  const [createDocumentPartBatch, { data: mutationData, loading: mutationLoading, error: mutationError }] = useAddBookDraftDocumentPart();


  let {
    loading: listUserBookDraftUserCommentsLoading,
    error: listUserBookDraftUserCommentsError,
    refetch: listUserBookDraftUserCommentsRefetch,
    userComments } = useLoadBookWriteComments(props, { bookDraftId: props.stores.bookStore.writingBookDraftId })


  let {
    loading: listBookNotesLoading,
    error: listBookNotesError,
    refetch: listBookNotesRefetch,
    userNotes } = useLoadBookWriteNotes(props, { bookDraftId: props.stores.bookStore.writingBookDraftId })

    
  
  let {
    // writingRightBookDraftId: rightContentBookDraftId,
    // writingRightBookDraft: rightContentBookDraft,
    writingRightSelectedBookDraftDocumentPart
  } = props.stores.bookStore;


  const isXs = useMediaQuery({ maxWidth: 599 })
  const isSm = useMediaQuery({ maxWidth: 600 })
  const isMd = useMediaQuery({ maxWidth: 900 })
  const isLg = useMediaQuery({ maxWidth: 1200 })
  const isXl = useMediaQuery({ minWidth: 1536 })


  // let rightContentTitle = 'Snapshot';
  let rightContentTitle = props.stores.bookStore.rightWritingBookDraftTitle;
  
  const {
    bookDraft,
    getBookDraftLoading,
    refetch
  } = useLoadBookDraftForWrite(props, props.stores.bookStore.writingBookDraftId);


  const {
    snapshotBookDraft: rightContentBookDraft,
    snapshotGetBookDraftLoading: rightContentGetBookDraftLoading
  } = useLoadSnapshotForWrite(props, props.stores.bookStore.rightWritingBookDraftId);



  // const { mappedImages } = useGetMappedImages(props,bookDraft);

  // useEffect(() => {


  //   if (bookDraft) {

  //     bookDraft.mappedImages = mappedImages;
  //     bookDraft.book.mappedImages = mappedImages;


  //   }


  // }, [bookDraft,mappedImages]);

  useEffect(() => {


    if (bookDraft && selectedBddp == null) {

      setSelectedBddp(bookDraft.rootBookDraftDocumentPart);


    }

    if (bookDraft ) {

      bookDraft.calculateWords();

    }

    

  }, [bookDraft]);


  useEffect(() => {

    setTree(<BookDraftTree7
      bookDraft={bookDraft}
      heading={'Manuscript'}
      //bookDraft2={rightContentBookDraft}
      hideScenes={true}
      //showAddBookDialog={showAddBookDialog}
      //setShowAddBookDialog={setShowAddBookDialog}
      selectedId={selectedBddp?.partId}
      selectedIds={[
        selectedBddp?.partId
      ]}
      onDocumentPartSelected={(item) => {

        let bddp = bookDraft.getBookDraftDocumentPartByPartId(item.id);
        props.stores.bookStore.writingSelectedBookDraftDocumentPart = bddp;
        //console.log(bddp);


        setSelectedBddp(bddp);



      }}
      onBookInserted={() => {
        //getBookDraftRefetch();
      }} />
    )

  }, [bookDraft, bookDraft?.version, selectedBddp]);



  useEffect(() => {


    if (rightContentBookDraft) {

      props.stores.bookStore.writingRightSelectedBookDraftDocumentPart = rightContentBookDraft.rootBookDraftDocumentPart;


    }


  }, [rightContentBookDraft]);



  useEffect(() => {

    setTree2(<BookDraftTree7
      bookDraft={rightContentBookDraft}
      heading={'Snapshot'}
      hideTrashbin={true}
      //bookDraft2={rightContentBookDraft}
      hideScenes={true}
      //showAddBookDialog={showAddBookDialog}
      //setShowAddBookDialog={setShowAddBookDialog}
      selectedId={writingRightSelectedBookDraftDocumentPart?.partId}
      // selectedIds={[
      //   setSelectedBddp?.partId
      // ]}
      onDocumentPartSelected={(item) => {

        let bddp = rightContentBookDraft.getBookDraftDocumentPartByPartId(item.id);
        props.stores.bookStore.writingRightSelectedBookDraftDocumentPart = bddp;
        //setRightContentSelectedBookDraftDocumentPart(bddp);
        //console.log(bddp);


        //For now, locking is out since the original and the snapshot may be radically different from each other. 

        // if(props.stores.bookStore.writeLockScreen){


        //   try {
        //     let matchingBddp = bookDraft.bookDraftDocumentParts.filter((f) => {

        //       if (bddp.documentPart.originalPartId == f.partId) {
        //         return f;
        //       }

        //     })[0];

        //     if (matchingBddp) {
        //       props.stores.bookStore.writingSelectedBookDraftDocumentPart = matchingBddp;
        //       setSelectedBddp(matchingBddp);
        //     }
        //   } catch (err) {
        //     console.log(err);
        //   }




        // }

      }}
      onBookInserted={() => {
        //getBookDraftRefetch();
      }} />
    )

  }, [rightContentBookDraft, rightContentGetBookDraftLoading, props.stores.bookStore.writingRightSelectedBookDraftDocumentPart]);





  let leftEditor = <BookDraftEditor
    subToolbarTitle={rightContentBookDraft != null ? 'Manuscript' : null}
    //userComments={userComments}
    bookDraft={bookDraft}
    selectedBddp={selectedBddp}
    onDocumentPartCreated={(bddp) => {

      //props.stores.bookStore.writingSelectedBookDraftDocumentPart = bddp;

    }} />


  let rightEditor = <BookDraftEditor
    readOnly={true}
    disabled={true}
    editMode={rightContentBookDraft?.bookDraftType == 'EDITORS_COPY'}
    subToolbarTitle={<div>{rightContentTitle}</div>}
    showCloseIcon={true}
    onClose={() => {
      closeSnapshotInWrite();
      setTree2(null);
    }}
    userComments={[]}
    bookDraft={rightContentBookDraft}
    selectedBddp={writingRightSelectedBookDraftDocumentPart}
    onDocumentPartCreated={(bddp) => {

      //props.stores.bookStore.writingSelectedBookDraftDocumentPart = bddp;

    }} />



  let leftLoading = getBookDraftLoading;
  let rightLoading = rightContentGetBookDraftLoading;
  // if (getBookDraftLoading) {


  //   return <Fade in={true}><Box sx={{
  //     width: '100%',
  //     height: '100%'
  //   }}>
  //     <EmptyContent tagline={'Loading...'} />

  //   </Box>
  //   </Fade>
  // }
  if (!props.stores.bookStore.writingBookDraftId) {
    return <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        //border: '1px solid blue',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography variant={'h4'}>Select a book to write</Typography>
      <OwnedBooksList
        filteredBooks={[]}
        showSelectIcon
        onSelected={(book) => {

          if (book) {

            openBookInWrite({
              bookDraftId: book.primaryBookDraftId
            });

          }

        }}
      />
    </Box>
  }


  if (isSm) {

    return (
      <Box sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}>



        <ScrollingGridContainer container columns={100} direction={'row'} >


          <ScrollingGridItem xs={100}>
            <Box sx={{
              //backgroundColor: theme.palette.write?.background?.color,
              //backgroundColor: theme.palette.background.paper,
              height: '100%'
            }}>

              {/* <div>title:{bookDraft?.book.title}</div> */}
              <SplitScreenController
                leftItem={leftEditor}
                rightItem={rightEditor}

              />
            </Box>

          </ScrollingGridItem>



        </ScrollingGridContainer>
        <WriteFooterBar tree={tree} setSelectedBddp={setSelectedBddp} bookDraft={bookDraft} bookDraftDocumentPart={selectedBddp}/>
      </Box>
    )

  } else {

    return (
      // <AppWrapperDummy >
      <Box sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        marginLeft: '1em',
        marginRight: '1em'
        //border:'1px solid red',
      }}>

        <Box

          //xs={15} 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: theme.components.standardTree.nodes.width,
            height: '100%',
            //border:'1px solid white'
          }}
        >

          <Box

            //xs={15} 
            sx={{
              flex: 1,
              width: theme.components.standardTree.nodes.width,
              height: '100%',
              //border: '1px solid white'
            }}
          >

            {rightContentBookDraft &&
              <Box sx={{
                height: '100%',
                //border: '1px solid red',
                display: 'flex',
                flexDirection: 'column'
              }}>

                <Box sx={{
                  flex: 6,
                  //border: '1px solid blue',
                }}>
                  <Scrollbars
                    autoHide
                    renderThumbHorizontal={() => {
                      return <span />
                    }}
                    style={{
                      width: '100%', height: '100%', overFlow: 'hidden',
                      overflowX: 'hidden'
                    }}>


                    {tree}






                  </Scrollbars>
                </Box>

                <Box sx={{
                  flex: 6,
                  //border: '1px solid blue',
                }}>
                  <Scrollbars
                    autoHide
                    renderThumbHorizontal={() => {
                      return <span />
                    }}
                    style={{
                      width: '100%', height: '100%', overFlow: 'hidden',
                      overflowX: 'hidden'
                    }}>


                    {tree2}






                  </Scrollbars>
                </Box>

              </Box>
            }

            {!rightContentBookDraft &&
              <Scrollbars
                autoHide
                renderThumbHorizontal={() => {
                  return <span />
                }}
                style={{
                  width: '100%', height: '100%', overFlow: 'hidden',
                  overflowX: 'hidden'
                }}>

                {/* {leftLoading &&
                <StandardSkeleton />
              }
              {!leftLoading &&
                tree
              } */}

                {tree}

                <Box sx={{
                  height: '1em'
                }}>

                </Box>

                {(rightContentBookDraft) &&

                  tree2


                }





              </Scrollbars>
            }

          </Box>

          {/* <Box sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center'
          }}>
            <StandardListButton
              sx={{
                //color: theme.palette.write.controls.color,
                //borderColor: theme.palette.write.controls.color
              }}
              disableElevation={true}
              variant="outlined"
              onClick={() => {

                let partType = 'CHAPTER';
                //Move the following into a manager function. Do the same for similar code in BookDraftTree3
                let starterTemplatesBookDraft = props.stores.bookStore.startersBookDraft;
                let starterTemplateDocumentPart = starterTemplatesBookDraft.getBookDraftDocumentPartByPartId(config.STARTER_TEMPLATES[partType]);
                //console.log(starterTemplateDocumentPart);
                //deltaOpsJson = starterTemplateDocumentPart.documentPart.deltaOps;
                let starterDeltaOps = starterTemplateDocumentPart.documentPart.deltaOps.map((m) => {
                  return JSON.stringify(m);
                })

                //props.stores.bookStore.writingSelectedBookDraftDocumentPart
                createDocumentPartBatch({
                  userId: user.id,
                  partType,
                  bookDraftId: bookDraft.id,
                  bookId: bookDraft.book.id,
                  parentPartId: bookDraft.rootBookDraftDocumentPart.partId,
                  starterDeltaOps,
                  prevPartId: bookDraft.lastChapterBddp?.partId

                }, (newBddp) => {

                  bookDraft.addBookDraftDocumentPart({
                    data: newBddp
                  });
                  props.stores.bookStore.recipeTimestamp = Date.now();


                });


              }}>Add Chapter</StandardListButton>

          </Box>

          <ToolsNavigator hideWriteAction={true} bookDraft={bookDraft} /> */}



        </Box>

        <Box sx={{
          flex: 2,
          //backgroundColor: theme.palette.write?.background?.color,
          height: '100%',
          width: '100%',
          //paddingLeft: '5%',
          //paddingRight: '5%',
          //border: '2px solid blue'
        }}>

          {/* {getBookDraftLoading &&
              <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
            }
            
            {!getBookDraftLoading &&
              <SplitScreenController

                leftItem={leftEditor}


                rightItem={'rightEditor'}

              />
            } */}



          <SplitScreenController
            showBothPanes={props.stores.bookStore.writeSplitScreen}
            leftItem={leftEditor}
            rightItem={rightEditor}

          />

        </Box>

        <Box sx={{
          width: theme.components.standardTree.nodes.width
        }}>

          {bookDraft &&
            <WriteDetails 
              bookDraft={bookDraft} 
              bookDraftDocumentPart={selectedBddp} 
              editingBookDraft={rightContentBookDraft}/>
          }
        </Box>

      </Box >
      // </AppWrapperDummy>
    )

  }


}

export default inject('stores')(observer(Write));