import React, { useState, useEffect } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Provider } from 'mobx-react';
import stores from './stores';
import { RequireAuth } from './RequireAuth';
import { Login } from './components/Login';
import { ProtectedSecond } from './components/ProtectSecond';
import { Home } from './components/Home';
import { Layout } from './components/Layout';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { Box, Button, CssBaseline } from '@mui/material';
import './App.css';
import './old_styles_reduced.css';
import Books from './routes/Books';
import BookDetails from './routes/BookDetails';
import Snapshots from './routes/subRoutes/ownedBooks/Snapshots';
import AppWrapper from './components/AppWrapper';
import createStandardTheme from './themes/theme';
import { red } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'
import { Auth } from 'aws-amplify'
import { User, Recipe } from './domain/bookModel';
import { useAuthenticator } from '@aws-amplify/ui-react';
import config from './aws-exports';
import Purchase from './routes/Purchase';
import PurchaseComplete from './routes/PurchaseComplete';
import { useLazyGetUser, useGetRecipe, useLazyGetRecipe } from './persistance/persistanceHooks';
import PageBoilerPlate from './components/boilerPlate/PageBoilerPlate';
import Connections from './components/boilerPlate/routes/Connections';
import Library from './components/boilerPlate/routes/Library';
import EditRequests from './components/boilerPlate/routes/EditRequests';
import Format from './components/boilerPlate/routes/Format';
import Write from './components/boilerPlate/routes/Write';
import Read from './components/boilerPlate/routes/Read';
import Edit from './components/boilerPlate/routes/Edit';
import Trashbin from './components/boilerPlate/routes/Trashbin';
import AppWrapperDummy from './components/AppWrapperDummy';
import Profile from './components/boilerPlate/routes/Profile';
import AppContext, { AppContextProvider } from './AppContext';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import Billing from './components/boilerPlate/routes/Billing';
import Pricing from './routes/Pricing';
import Pricing2 from './routes/Pricing2';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)





const theme = createTheme({
  palette: {
    primary: {
      main: red[500],
    },
  },
});

let standardTheme = createStandardTheme(stores.bookStore.isDarkTheme);


// const AWS = require("aws-sdk");

// let bucketRegion = config.user_uploads_bucket_region;//"us-east-1";
// let IdentityPoolId = config.identity_pool_id

// const url = config.graphqlEndpoint;
// const region = config.aws_project_region;


// AWS.config.update({
//   region: bucketRegion,
//   credentials: new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: IdentityPoolId
//   })
// });

function MyRoutes(props) {

  // <Route path="/" element={<AppWrapper />}>
  let [client, setClient] = useState();
  const [sub, setSub] = useState();
  const [lazyGetUser, { loading: lazyGetUserLoading, error: lazyGetUserError, data: lazyGetUserData }] = useLazyGetUser();
  const { route } = useAuthenticator((context) => [context.route]);
  //const { route } = useAuthenticator((context) => [context.route]);
  const { authData } = useAuthenticator();
  const { persistor } = props;

  const appContext = React.useContext(AppContext);
  const { product, plan } = useParams();


  useEffect(() => {
    if (route === 'signOut') {
      setSub(null);
    }

    if (route === 'authenticated') {

      Auth.currentAuthenticatedUser({
        bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then(data => {
          //console.log(data);
          setSub(data.username);
          // stores.bookStore.loggedIn = true;
          // stores.bookStore.setCognitoUser({
          //   attributes: {
          //     sub: data.username
          //   }
          // })


        });

    }
  }, [route]);

  useEffect(() => {

    if (sub) {
      //TODO:************************* turn this back on
      lazyGetUser({
        variables: {
          userId: sub
        }
      })

    }

  }, [sub]);

  useEffect(() => {

    //console.log(lazyGetUserData);
    if (lazyGetUserData?.getUser) {

      stores.bookStore.setUser(new User({
        data: lazyGetUserData.getUser
      }))

      stores.userStore.setUser(new User({
        data: lazyGetUserData.getUser
      }))

      //lazyGetRecipe();


    }

  }, [lazyGetUserData]);

  // if (!stores.userStore.user) {
  //   return <div>No User</div>
  // }

  if (!stores.userStore.user) {
    //return <div>No user yet! App.js</div>

    //return <Login />
  }


  return (
    <>
      {/* <ThemeProvider theme={standardTheme}> */}

      <CssBaseline />
      {/* <ApolloProvider client={client}> */}
      <Provider stores={stores} userStore={stores.userStore}>


        {/* Page describing how to secure routes:
      https://ui.docs.amplify.aws/react/guides/auth-protected */}
        {/* {stores.userStore.user && */}
        <BrowserRouter>
          {/* <AppWrapperDummy> */}
            <Routes>
              <Route path="/" element={<><Outlet /></>}>
                {/* <Route index element={<RequireAuth> <Home /></RequireAuth>} /> */}
                <Route index element={<RequireAuth><AppWrapperDummy><Library /></AppWrapperDummy></RequireAuth>} />
                <Route path="/connections" element={<RequireAuth>  <AppWrapperDummy><Connections /></AppWrapperDummy></RequireAuth>} ></Route>
                <Route path="/editRequests" element={<RequireAuth><AppWrapperDummy><EditRequests /></AppWrapperDummy>     </RequireAuth>} ></Route>
                <Route path="/write" element={<RequireAuth><AppWrapperDummy><Write /></AppWrapperDummy>    </RequireAuth>} ></Route>
                <Route path="/read" element={<RequireAuth><AppWrapperDummy><Read /></AppWrapperDummy>    </RequireAuth>} ></Route>
                <Route path="/format" element={<RequireAuth><AppWrapperDummy><Format /></AppWrapperDummy>    </RequireAuth>} ></Route>
                <Route path="/edit" element={<RequireAuth><AppWrapperDummy><Edit /></AppWrapperDummy>   </RequireAuth>} ></Route>
                <Route path="/purchase" element={<RequireAuth> <Purchase /></RequireAuth>} />
                <Route path="/purchaseComplete" element={<PurchaseComplete />} />
                <Route path="/pricing" element={<AppWrapperDummy><Pricing /></AppWrapperDummy> } />
                <Route path="/pricing2" element={<AppWrapperDummy><Pricing2 /></AppWrapperDummy>} />
                <Route path="/login" element={<Login />} />
                <Route path="/profile" element={<RequireAuth><AppWrapperDummy><Profile /></AppWrapperDummy>   </RequireAuth>} ></Route>
                <Route path="/billing" element={<RequireAuth><AppWrapperDummy><Billing /></AppWrapperDummy>   </RequireAuth>} ></Route>
                <Route path="/pageBoilerPlate" element={<RequireAuth><AppWrapperDummy><PageBoilerPlate /></AppWrapperDummy>   </RequireAuth>} ></Route>
                <Route path="/trashbin" element={<RequireAuth><AppWrapperDummy><Trashbin /></AppWrapperDummy>    </RequireAuth>} ></Route>
              </Route>
            </Routes>
          {/* </AppWrapperDummy> */}
        </BrowserRouter>
        {/* } */}
      </Provider>
      {/* </ApolloProvider> */}
      {/* </ThemeProvider> */}

    </>

  );
}

function App() {

  const [open, setOpen] = React.useState(true);

  const appContext = React.useContext(AppContext);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  // const addToast = ()=>{
  //   enqueueSnackbar('That was easy!', { variant: 'error' })
  // }

  return (
    <Authenticator.Provider>

      <MyRoutes />
      {/* <div>
        <button onClick={()=>{
          appContext.addToast('A toast.')
          }}>Show snackbar</button>
      </div> */}
    </Authenticator.Provider>
  );
}

export default App;
