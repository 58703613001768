import React, { useEffect, useState } from 'react';
import { styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import BaseControl from "./BaseControl";
import { useTheme } from '@mui/material/styles';
import { Box, Checkbox } from "@mui/material";
import { v4 as uuid } from "uuid";
import { useGetBucketItems } from '../../../managers/StorageManager';
import StandardImageGallery from '../../image/StandardImageGallery';
import StandardImageViewer from '../../StandardImageViewer';

function S3FilesGallery(props) {

  const [images, setImages] = useState([]);
  const { bucketName, folder} = props;
  const theme = useTheme();
 
  const {getBucketItems, bucketItems } = useGetBucketItems();

  useEffect(()=>{

    
    getBucketItems({
      bucketName,
      folder
    });

  },[]);



  const itemSelected = (s3File) => {

    if(props.onItemSelected){

      props.onItemSelected({s3Key:s3File.Key});

    }
    
  }


  useEffect(()=>{

    let images = [];
    if(bucketItems){
      //console.log(bucketItems);

      images = bucketItems.map((s3File)=>{

       return  <StandardImageViewer
        bucketName={bucketName}
        s3Path={s3File.Key}
        height={'15em'}
        fit={'contain'}
        sx={{
          minHeight: '15em'
          //display: { sm: 'block', lg: 'block', md: 'block' } 
        }}
        style={{ borderRadius: 2 }}
        className="custom-class"
        onClick={(e) => {
          e.preventDefault();
          itemSelected(s3File);

        }}
        showLoading
        errorIcon
      />

      });

      setImages(images);

    }
  },[bucketItems]);

  return (

    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}>
     
     
     <StandardImageGallery items={images} />
    </Box>


  );
}


export default inject('stores')(observer(S3FilesGallery));



