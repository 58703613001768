export default {
   
    ERROR_SYSTEM_ERROR:{
        uiShortMessage: 'A system error has occurred. BookMerlin has been notified.',
        uiLongMessage: '',
        helpId:123,
        recommendedActions:[ 
            'OPEN_BOXSET_IN_WRITE'
        ]
    },

    ERROR_BOXSET_CANNOT_REMOVE_CONTRIBUTED_BOOK_WHEN_INSERTED:{
        uiShortMessage: 'Remove this book from the boxset before removing it as a contributed book.',
        uiLongMessage: 'Cannot remove a contributed book if it has been inserted into the boxset.'
    },

    ERROR_BOXSET_CANNOT_REMOVE_CONTRIBUTED_BOOK_NOT_BOOK_OR_BOXSET_OWNER:{
        uiShortMessage: 'Remove this book from the boxset before removing it as a contributed book.',
        uiLongMessage: 'Cannot remove a contributed book if it has been inserted into the boxset.'
    },


    //Can use recommended actions to place controls right next to the error msg that will help resolve the problem. 
    //For example, a link to open a boxset in write when trying to remove a contributed book that has been included
    //in the boxset.
    ACTIONS:{
        OPEN_BOXSET_IN_WRITE: {

        }
    }
}