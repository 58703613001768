import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { styled, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import Feather from 'mdi-material-ui/Feather';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import PeopleIcon from '@mui/icons-material/People';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { ApolloConsumer } from '@apollo/client';
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useMediaQuery } from 'react-responsive';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { useAuthenticator } from '@aws-amplify/ui-react';
import AppContext, { AppContextProvider } from '../../AppContext';
import SecurityManager from '../../managers/SecurityManager';

const StandardDrawer = styled(Drawer)(() => {


})


function MainMenuContent(props) {

    let navigate = useNavigate();
    let location = useLocation();

    let [openBooks, setOpenBooks] = useState(false);
    let [openSharedBooks, setOpenSharedBooks] = useState(false);
    let [openConnections, setOpenConnections] = useState(false);
    let [openYourGroups, setOpenYourGroups] = useState(false);
    let [openSharedGroups, setOpenSharedGroups] = useState(false);


    let [openOwnedBoxset, setOpenOwnedBoxset] = useState(false);
    let [openReceivedEditRequest, setOpenReceivedEditRequest] = useState(false);

    const appContext = React.useContext(AppContext);

    const { route, signOut } = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);

    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    const subItemsIndent = 8;
    const subSubItemsIndent = 0;

    const theme = useTheme();
    const showAllMenus = false;

    const isRoute = (pathname) => {
        //if (pathname && pathname.includes(location.pathname) ) {
        if (location.pathname && location.pathname === pathname) {
            return theme.palette.secondary.main;
        }

        return 'inherit';
    }

    const handleSelection = () => {

    }

    const onLogout = event => {
        // event.preventDefault();
        // props.stores.bookStore.loggedIn = false;

        // Auth.signOut()
        //     .then(data => {
        //         //console.log(data);

        //         props.stores.bookStore.loggedIn = false;
        //         //props.stores.bookStore.reset();

        //     })
        //     .catch(err => {
        //         console.log(err);

        //     })
    };

    const selectRoute = (route) => {

        navigate(route);
        props.onRouteSelected();

    }

    // let menuData = {
    //     items:[
    //         {

    //             title: 'Home',
    //             icon: <HomeIcon color={isRoute('/')} />,
    //             onClick:()=>{}

    //         }
    //     ]
    // }
    return (
        <>


            <Divider />

            <List sx={{
                display: 'flex',
                flexDirection: 'column'
                //marginTop: '5em'
            }}>
                <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        selectRoute("/");
                        //navigate("/");
                    }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                        <ListItemIcon>

                            <HomeIcon sx={{
                                color: isRoute('/')
                            }}
                            // color={isRoute('/')} 
                            />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/') }}>Library</Typography>} />
                    </ListItemButton>
                </ListItem>
                {/* <Divider /> */}

                {/* <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //setOpenBooks(!openBooks)
                        selectRoute("/books/list");
                    }}>
                        <ListItemIcon>
                            <MenuBookIcon sx={{
                                color: isRoute('/books/list')
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/books/list') }}>My Books</Typography>} />

                    </ListItemButton>
                </ListItem> */}


                {/* <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //setOpenBooks(!openBooks)
                        selectRoute("/sharedBooks/list");
                    }}>
                        <ListItemIcon>
                            <MenuBookIcon sx={{
                                color: isRoute('/sharedBooks/list')
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/sharedBooks/list') }}>Shared Books</Typography>} />

                    </ListItemButton>
                </ListItem> */}


                {/* <ListItem disablePadding sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        <ListItemButton onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            //setOpenBooks(!openBooks)
                            selectRoute("/books/list");
                        }}>
                           
                            <ListItemText primary="Your Books" color={isRoute('/books/list')} />
                            {(showAllMenus || isXs) &&
                                <span onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setOpenBooks(!openBooks)
                                    //selectRoute("/books");
                                }}>{openBooks ? <ExpandLess /> : <ExpandMore />}</span>
                            }
                        </ListItemButton> 
                    </Box>
                </ListItem>*/}

                {/* <Collapse in={openBooks} timeout="auto" unmountOnExit>
                    <>
                        {props.stores.bookStore.bookListBook &&
                            <>
                                <Divider />
                                {(showAllMenus || isXs) &&

                                    <BookDetailMenuContent />

                                }
                               
                            </>
                        }
                    </>
                </Collapse> */}

                {/* <ListItem disablePadding sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <ListItemButton onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            selectRoute("/sharedBooks/list");
                        }} sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                           
                            <ListItemText primary="Shared Books" />
                            {(showAllMenus || isXs) &&
                                <span onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setOpenSharedBooks(!openSharedBooks)
                                    //selectRoute("/books");
                                }}>{openSharedBooks ? <ExpandLess /> : <ExpandMore />}</span>
                            }
                        </ListItemButton>

                    </Box>

                </ListItem> */}

                {/* <Collapse in={openSharedBooks} timeout="auto" unmountOnExit>
                    <>
                        {props.stores.bookStore.bookListBook &&
                            <>
                                <Divider />
                                {(showAllMenus || isXs) &&

                                    <SharedBookDetailMenuContent />

                                }


                            </>
                        }
                    </>
                </Collapse> */}


                {/* <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //setOpenBooks(!openBooks)
                        selectRoute("/boxsets/list");
                    }}>
                        <ListItemIcon>
                            <ContentCopyIcon sx={{
                                color: isRoute('/boxsets/list')
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/boxsets/list') }}>My Boxsets</Typography>} />
                       
                    </ListItemButton>
                </ListItem> */}


                {/* <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //setOpenBooks(!openBooks)
                        selectRoute("/sharedBoxsets/list");
                    }}>
                        <ListItemIcon>
                            <ContentCopyIcon sx={{
                                color: isRoute('/sharedBoxsets/list')
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/sharedBoxsets/list') }}>Shared Boxsets</Typography>} />
                       
                    </ListItemButton>
                </ListItem> */}

                <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //setOpenBooks(!openBooks)
                        selectRoute("/connections");
                    }}>
                        <ListItemIcon>
                            <PeopleIcon sx={{
                                color: isRoute('/connections')
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/connections') }}>Connect</Typography>} />

                    </ListItemButton>
                </ListItem>


                <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //setOpenBooks(!openBooks)
                        selectRoute("/editRequests");
                    }}>
                        <ListItemIcon>
                            <AccountEdit sx={{
                                color: isRoute('/editRequests')
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/editRequests') }}>Edit Requests</Typography>} />

                    </ListItemButton>
                </ListItem>







                {/* <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //setOpenBooks(!openBooks)
                        selectRoute("/myGroups/list");
                    }}>
                        <ListItemIcon>
                            <PeopleIcon sx={{
                                color: isRoute('/myGroups/list')
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/myGroups/list') }}>My Groups</Typography>} />
                       
                    </ListItemButton>
                </ListItem> */}



                {/* <ListItemButton sx={{
                    //pl: subItemsIndent + subSubItemsIndent
                }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        selectRoute("/myGroups/list");
                    }}>
                    <ListItemIcon>
                        <PeopleIcon color={isRoute('/myGroups/list')} />
                    </ListItemIcon>
                    <ListItemText primary="Your Groups" />
                    {(showAllMenus || isXs) &&
                        <span onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpenYourGroups(!openYourGroups)
                            //selectRoute("/books");
                        }}>{openYourGroups ? <ExpandLess /> : <ExpandMore />}</span>
                    }
                </ListItemButton> */}
                {/* <Collapse in={openYourGroups} timeout="auto" unmountOnExit>
                    <>
                        {props.stores.bookStore.userGroupListGroup &&
                            <>
                                <Divider />
                                {(showAllMenus || isXs) &&

                                    <YourGroupDetailMenuContent />

                                }
                            </>
                        }
                    </>
                </Collapse> */}



                {/* 


                <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //setOpenBooks(!openBooks)
                        selectRoute("/sharedGroups/list");
                    }}>
                        <ListItemIcon>
                            <PeopleIcon sx={{
                                color: isRoute('/sharedGroups/list')
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/sharedGroups/list') }}>Shared Groups</Typography>} />
                      
                    </ListItemButton>
                </ListItem> */}





                {/* <ListItemButton sx={{
                    //pl: subItemsIndent + subSubItemsIndent
                }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        selectRoute("/sharedGroups/list");
                    }}>
                    <ListItemIcon>
                        <PeopleIcon color={isRoute('/sharedGroups/list')} />
                    </ListItemIcon>
                    <ListItemText primary="Shared Groups" />
                    {(showAllMenus || isXs) &&
                        <span onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpenSharedGroups(!openSharedGroups)
                            //selectRoute("/books");
                        }}>{openSharedGroups ? <ExpandLess /> : <ExpandMore />}</span>
                    }
                </ListItemButton> */}
                {/* <Collapse in={openSharedGroups} timeout="auto" unmountOnExit>
                    <>
                        {props.stores.bookStore.sharedGroupListGroup &&
                            <>
                                <Divider />
                                {(showAllMenus || isXs) &&

                                    <SharedGroupDetailMenuContent />

                                }
                            </>
                        }
                    </>
                </Collapse> */}

                <Divider />

                <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //setOpenBooks(!openBooks)
                        selectRoute("/write");
                    }}>
                        <ListItemIcon>
                            <Feather sx={{
                                color: isRoute('/write')
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/write') }}>Write</Typography>} />


                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //setOpenBooks(!openBooks)
                        selectRoute("/edit");
                    }}>
                        <ListItemIcon>
                            <AccountEdit sx={{
                                color: isRoute('/edit')
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/edit') }}>Edit</Typography>} />


                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //setOpenBooks(!openBooks)
                        selectRoute("/format");
                    }}>
                        <ListItemIcon>
                            <FormatPaintIcon sx={{
                                color: isRoute('/format')
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/format') }}>Format</Typography>} />


                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //setOpenBooks(!openBooks)
                        selectRoute("/read");
                    }}>
                        <ListItemIcon>
                            <LocalLibraryIcon sx={{
                                color: isRoute('/read')
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/read') }}>Read</Typography>} />


                    </ListItemButton>
                </ListItem>



                <Divider />




                <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //setOpenBooks(!openBooks)
                        selectRoute("/trashbin");
                    }}>
                        <ListItemIcon>
                            <TrashCanOutline sx={{
                                color: isRoute('/trashbin')
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/trashbin') }}>Trash Bin</Typography>} />


                    </ListItemButton>
                </ListItem>









                {/* <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        // setDisplayMode('trashbin')
                        e.preventDefault();
                        e.stopPropagation();
                        selectRoute("/profile");
                    }}>
                        <ListItemIcon>
                            <TrashCanOutline color={isRoute('/profile')} />
                        </ListItemIcon>
                        <ListItemText primary="profile" />
                    </ListItemButton>
                </ListItem> */}

                <ApolloConsumer>

                    {(client) => (

                        <Button variant={'contained'} onClick={(e) => {
                            //console.log('logging out...');
                            //onLogout(e);
                            // client.clearStore();
                            // client.cache.gc();
                            // let persistor = appContext.persistor;
                            // persistor.purge();

                            SecurityManager.logout({
                                client,
                                appContext,
                                stores:props.stores
                            });
                        }}>Logout</Button>
                    )}
                </ApolloConsumer>
                {/* <Divider /> */}

                <ListItem disablePadding>
                    <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //setOpenBooks(!openBooks)
                        selectRoute("/account/settings");
                    }}>
                        <ListItemIcon>
                            <MenuBookIcon sx={{
                                color: isRoute('/account/settings')
                            }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h7" style={{ color: isRoute('/account/settings') }}>Account</Typography>} />



                        {/* {(showAllMenus || isXs) &&
                            <span onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setOpenBooks(!openBooks)
                                //selectRoute("/books");
                            }}>{openBooks ? <ExpandLess /> : <ExpandMore />}</span>
                        } */}
                    </ListItemButton>
                </ListItem>


                






            </List >
            <Switch
                checked={props.darkMode}
                onChange={() => {
                    //props.toggleDarkMode();
                    props.stores.bookStore.isDarkTheme = !props.stores.bookStore.isDarkTheme
                }}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </>
    );
}

export default inject('stores')(observer(MainMenuContent));

