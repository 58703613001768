import React, { useState, useEffect } from "react";
import { Box, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import StandardUITextField from "../../UI/StandardUITextField";
import StandardAutoUITextField from "../../UI/StandardAutoUITextField";
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StandardUISelect from "../../UI/StandardUISelect";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import StandardUIDialog from "../../UI/StandardUIDialog";
import StandardUIButton from "../../UI/StandardUIButton";
import { useUpdateItem } from '../../../persistance/persistanceHooks';

const OwnedBoxsetDetailCard = (props) => {

    let { book, boxset, selectedBoxset } = props;

    const [updateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] = useUpdateItem();

    const [openDelete, setOpenDelete] = React.useState(false);
    const handleOpenDelete = () => {
        setOpenDelete(true);
    };

    const handleCloseDelete = (value) => {
        setOpenDelete(false);
    };

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [selection, setSelection] = React.useState('English');

    const handleChange = (event) => {
        setSelection(event.target.value);
    };

    const [showMoreDetail, setShowMoreDetail] = useState(false);


    return (
        <Box>
            {/* <div>Book id:{book?.id}</div> */}
            {showMoreDetail ?
                <Box>
                    <StandardAutoUITextField fullWidth sx={{ m: 1 }} label="Title" value={book?.title} variant="outlined" entity={book} fieldName={'title'} />
                    <StandardAutoUITextField fullWidth sx={{ m: 1 }} label="Subtitle" value={book?.subTitle} variant="outlined" entity={book} fieldName={'subTitle'} />
                    <StandardAutoUITextField fullWidth sx={{ m: 1 }} label="Author" value={book?.authorName} variant="outlined" entity={book} fieldName={'authorName'} />
                    <StandardAutoUITextField fullWidth sx={{ m: 1 }} label="eBook ISBN" variant="outlined" />
                    <StandardAutoUITextField fullWidth sx={{ m: 1 }} label="Print ISBN" variant="outlined" />
                    <StandardUISelect
                        inputLabel={'Language'}
                        content={<Box>
                            <Select
                                value={selection}
                                onChange={handleChange}
                                label={props.selectLabel}
                            >
                                <MenuItem value={'Catalan'}>Catalan</MenuItem>
                                <MenuItem value={'Chinese (Simplified)'}>Chinese (Simplified)</MenuItem>
                                <MenuItem value={'Chinese (Traditional)'}>Chinese (Traditional)</MenuItem>
                                <MenuItem value={'Czech'}>Czech</MenuItem>
                                <MenuItem value={'Danish'}>Danish</MenuItem>
                                <MenuItem value={'Dutch'}>Dutch</MenuItem>
                                <MenuItem value={'English'}>English</MenuItem>
                                <MenuItem value={'Finnish'}>Finnish</MenuItem>
                                <MenuItem value={'French'}>French</MenuItem>
                                <MenuItem value={'German'}>German</MenuItem>
                                <MenuItem value={'Hindi'}>Hindi</MenuItem>
                                <MenuItem value={'Hungarian'}>Hungarian</MenuItem>
                                <MenuItem value={'Icelandic'}>Icelandic</MenuItem>
                                <MenuItem value={'Italian'}>Italian</MenuItem>
                                <MenuItem value={'Japanese'}>Japanese</MenuItem>
                                <MenuItem value={'Korean'}>Korean</MenuItem>
                                <MenuItem value={'Norwegian Bokmal'}>Norwegian Bokmal</MenuItem>
                                <MenuItem value={'Polish'}>Polish</MenuItem>
                                <MenuItem value={'Portuguese'}>Portuguese</MenuItem>
                                <MenuItem value={'Romanian'}>Romanian</MenuItem>
                                <MenuItem value={'Russian'}>Russian</MenuItem>
                                <MenuItem value={'Spanish'}>Spanish</MenuItem>
                                <MenuItem value={'Swedish'}>Swedish</MenuItem>
                                <MenuItem value={'Turkish'}>Turkish</MenuItem>
                                <MenuItem value={'Vietnamese'}>Vietnamese</MenuItem>
                            </Select>
                        </Box>}
                    />
                    <Divider sx={{ margin: '1em 0' }} />
                    <Typography variant={theme.secondLevelText.variant}>Stores</Typography>
                    <StandardAutoUITextField fullWidth sx={{ m: 1 }} label="Kindle" variant="outlined" />
                    <StandardAutoUITextField fullWidth sx={{ m: 1 }} label="iBooks" variant="outlined" />
                    <StandardAutoUITextField fullWidth sx={{ m: 1 }} label="Google Name" variant="outlined" />
                    <StandardAutoUITextField fullWidth sx={{ m: 1 }} label="Kobo" variant="outlined" />
                    <StandardAutoUITextField fullWidth sx={{ m: 1 }} label="Nook" variant="outlined" />
                    <StandardAutoUITextField fullWidth sx={{ m: 1 }} label="Other" variant="outlined" />
                    <Divider sx={{ margin: '1em 0' }} />
                    <StandardUIButton
                        buttonAction='Delete'
                        onClick={handleOpenDelete}
                    />
                    <StandardUIDialog
                        title='Delete Book'
                        open={openDelete}
                        onClose={handleCloseDelete}
                        dialogContent={
                            <div>
                                <Typography>Deleting will send this book to the Trash page, where it can be permanently deleted.</Typography>
                                <Typography>Please confirm you want to delete this book below.</Typography>
                                <StandardUITextField
                                    label='Delete Book'
                                />
                            </div>
                        }
                        cardActions={
                            <StandardUIButton
                                buttonAction='Delete Book'
                                onClick={() => {

                                    updateItem({
                                        itemExpectedVersion: 1,
                                        itemKey: book.id,
                                        //itemSortKeyName: 'documentPartId',
                                        //itemSortKeyValue: props.userComment.documentPartId,
                                        itemType: 'Book',
                                        fieldName: 'deletedAt',
                                        fieldValue: 'now',
                                        refetchQueries: ['listBooks'],
                                        onCompleted: () => {

                                            //props.userComment.isPinned = !props.userComment.isPinned
                                            props.stores.bookStore.bookListBook = null;
                                        }
                                    })



                                    setOpenDelete(false);
                                }}
                            />
                        }
                    />
                    <Link
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        underline='none' color='blue'
                        onClick={() => {
                            setShowMoreDetail(false)
                        }}
                    >
                        Less
                    </Link>
                </Box>
                :
                <Box>
                    <StandardAutoUITextField fullWidth sx={{ m: 1 }} label="Title" value={book?.title} variant="outlined" entity={book} fieldName={'title'} />
                    <StandardAutoUITextField fullWidth sx={{ m: 1 }} label="Subtitle" value={book?.subTitle} variant="outlined" entity={book} fieldName={'subTitle'} />
                    <StandardAutoUITextField fullWidth sx={{ m: 1 }} label="Author" value={book?.authorName} variant="outlined" entity={book} fieldName={'authorName'} />
                    <Link
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        underline='none' color='blue'
                        onClick={() => {
                            setShowMoreDetail(true)
                        }}>
                        More
                    </Link>
                </Box>
            }
        </Box>
    )
}

export default inject('stores')(observer(OwnedBoxsetDetailCard));