import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import StandardDataTable from './StandardDataTable';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import config from '../../aws-exports';
import ReactTimeAgo from 'react-time-ago';
import AvatarTableCell from './AvatarTableCell';
import ShareIcon from '@mui/icons-material/Share';
import CommentIcon from '@mui/icons-material/Comment';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import TablePopupMenu from '../menus/TablePopupMenu';
import { useMediaQuery } from 'react-responsive';
import YourGroupsList from '../lists/YourGroupsList';
import CreateGroupAssembly from '../assemblies/CreateGroupAssembly';

const YourGroupsTable = (props) => {

  const { userGroups, error: getUserUserGroupsError, loading: getUserUserGroupsLoading } = useGetUserGroups(props);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');


  const isXs = useMediaQuery({ maxWidth: 599 })
  const isSm = useMediaQuery({ maxWidth: 600 })
  const isMd = useMediaQuery({ maxWidth: 900 })
  const isLg = useMediaQuery({ maxWidth: 1200 })
  const isXl = useMediaQuery({ minWidth: 1536 })


  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {


    setOrder("desc");
    setOrderBy("createdAt");

  }, [userGroups]);





  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      {!isXs &&
        <StandardDataTable 
        // title={'My Groups'}
          noDataMessage={'Create your first group!'}
          headerActions={<CreateGroupAssembly />}
          structure={{
            dataDef: {
              data: userGroups,
              rowKeyDataFieldName: 'id'
            },
            columns: [
              {
                title: 'Group',
                sortable: true,
                sortFieldNameOverride:'title',
                dataValueFunction: (userGroup) => {
                  return <AvatarTableCell
                    bucketName={config.user_uploads_bucket_name}
                    s3Src={userGroup?.profilePicUrl}
                    title={userGroup?.name}
                    details={userGroup?.description}
                    onClick={(e) => {
                      e.preventDefault();
                      //console.log(userGroup)
                      props.stores.bookStore.userGroupListGroup = userGroup;
                      navigate("/myGroups/details");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }}
                  />
                }
              },
              // {
              //   title: 'Profile Pic',
              //   dataValueFunction: (userGroup) => { return <EditIcon sx={{ color: theme.palette.words?.main }} /> }
              // },
              // {
              //   title: 'Name',
              //   dataValueFunction: (userGroup) => {
              //     console.log(userGroup);
              //     return userGroup?.name
              //   }
              // },
              {
                title: 'Shared',
                dataValueFunction: (userGroup) => {
                  return <IconButton aria-label="delete">
                    <ShareIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                      e.preventDefault();
                      //console.log(userGroup)
                      props.stores.bookStore.userGroupListGroup = userGroup;
                      navigate("/myGroups/details/shared");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }} />
                  </IconButton>
                },
                ignore: isXs
              },
              {
                title: 'Members',
                dataValueFunction: (userGroup) => {
                  return <IconButton aria-label="delete">
                    <PeopleIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                      e.preventDefault();
                      //console.log(userGroup)
                      props.stores.bookStore.userGroupListGroup = userGroup;
                      navigate("/myGroups/details/members");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }} />
                  </IconButton>
                },
                ignore: isXs
              },
              {
                title: 'Feedback',
                dataValueFunction: (userGroup) => {
                  return <IconButton aria-label="delete">
                    <CommentIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                      e.preventDefault();
                      //console.log(userGroup)
                      props.stores.bookStore.connectionsListConnection = userGroup;
                      navigate("/myGroups/details/feedback");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }} />
                  </IconButton>
                },
                ignore: isXs
              },
              {
                title: 'Permissions',
                dataValueFunction: (userGroup) => {
                  return <IconButton aria-label="delete">
                    <CommentIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                      e.preventDefault();
                      //console.log(userGroup)
                      props.stores.bookStore.connectionsListConnection = userGroup;
                      navigate("/myGroups/details/permissions");
                      // openBookInWrite({
                      //   bookDraftId: book.primaryBookDraftId
                      // });
                    }} />
                  </IconButton>
                },
                ignore: isXs
              },
              {
                title: 'Connected',
                dataFieldName: 'createdAt',
                sortable: true,
                dataValueFunction: (userGroup) => { return userGroup.createdAt != null ? <ReactTimeAgo date={userGroup.createdAt} locale="en-US" /> : '' },
                ignore: isXs
              },
              // {
              //     title: 'Delete',
              //     dataValueFunction: (userGroup) => {
              //         return <IconButton aria-label="delete">
              //             <TrashCanOutline sx={{ color: theme.palette.background.main }} onClick={(e) => {
              //                 e.preventDefault();
              //                 console.log(userGroup);
              //                 // setSelectedBook(book);
              //                 // setShowDeleteBookDialog(true);

              //             }} />
              //         </IconButton>
              //     }
              // },

              {
                title: '',
                dataValueFunction: (userGroup) => {
                  return <TablePopupMenu
                    menuItems={[
                      //   <ListItemButton onClick={(e) => {
                      //     e.preventDefault();
                      //     console.log(userGroup)
                      //     props.stores.bookStore.connectionsListConnection = userGroup;
                      //     navigate("/myGroups/details");
                      //     // openBookInWrite({
                      //     //   bookDraftId: book.primaryBookDraftId
                      //     // });
                      // }}  >

                      //     <ListItemIcon>
                      //       <SettingsIcon sx={{ color: theme.palette.background.main }} />
                      //     </ListItemIcon>
                      //     <ListItemText primary="Details" />
                      //   </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(userGroup)
                        props.stores.bookStore.userGroupListGroup = userGroup;
                        navigate("/myGroups/details/shared");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }}   >

                        <ListItemIcon>
                          <ShareIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Shared" />
                      </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(userGroup)
                        props.stores.bookStore.userGroupListGroup = userGroup;
                        navigate("/myGroups/details/members");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }}   >

                        <ListItemIcon>
                          <PeopleIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Members" />
                      </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(userGroup)
                        props.stores.bookStore.connectionsListConnection = userGroup;
                        navigate("/myGroups/details/feedback");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }} >

                        <ListItemIcon>
                          <CommentIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Feedback" />
                      </ListItemButton>,
                      <ListItemButton onClick={(e) => {
                        e.preventDefault();
                        //console.log(userGroup)
                        props.stores.bookStore.connectionsListConnection = userGroup;
                        navigate("/myGroups/details/permissions");
                        // openBookInWrite({
                        //   bookDraftId: book.primaryBookDraftId
                        // });
                      }} >

                        <ListItemIcon>
                          <CommentIcon sx={{ color: theme.palette.background.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Permissions" />
                      </ListItemButton>,
                    ]}
                  />
                },
                ignore: !isXs
              },







            ]
          }} />
      }
      {isXs &&
        <YourGroupsList
          filteredBooks={[]}
          showSelectIcon
          onSelected={(userGroup) => {

            if (userGroup) {

              //console.log(userGroup)
              props.stores.bookStore.connectionsListConnection = userGroup;
              navigate("/myGroups/details");

            }

          }}
        />
      }
    </Box>

  )
}
export default inject('stores')(observer(YourGroupsTable));