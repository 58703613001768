import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import SharedWithPanel from '../../panels/connections/SharedWithPanel';
import BookSharesList from '../../lists/BookSharesList';
import FeedbackPanel from '../../panels/FeedbackPanel';

const WriteDetailsSharing = (props) => {

    const theme = useTheme();

    let { bookDraftDocumentPart,bookDraft} = props;


    let userId = props.stores?.bookStore.userId;

 


    return (    
        <Box container direction={'column'} sx={{
            justifyContent: 'center',
            //marginRight:'1em'
        }}>
          <SharedWithPanel book={bookDraft?.book}/>
          {/* <BookSharesList book={bookDraft?.book} /> */}
          <FeedbackPanel book={bookDraft?.book} bookDraftDocumentPart={bookDraftDocumentPart} ownerMode={true}/>
        </Box>
    );
};


export default inject('stores')(observer(WriteDetailsSharing));
