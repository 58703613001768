import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import StandardButton from '../StandardButton';
import StandardDialog from '../StandardDialog';
import CreateSnapshotPanel from '../panels/CreateSnapshotPanel';
import RequestEditPanel from '../panels/editing/RequestEditPanel';
import StandardOkCancelDialog from '../panels/StandardOkCancelDialog';

const RequestEditAssembly = (props) => {
    
    let [showRequestEditDialog, setShowRequestEditDialog] = useState(false);
    
    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    const theme = useTheme();

    return (
        <>
            <Box sx={{
                display: 'flex',
                //justifyContent: 'flex-end',
                //width: '100%',
                //border:'1px solid yellow'
            }}>
                <Button variant="contained" sx={{
                    color: '#ffffff',
                    backgroundColor: theme.palette.secondary.main
                }}
                onClick={(e)=>{
                    e.preventDefault();
                    setShowRequestEditDialog(true);
                }}>Request</Button>
                {/* <StandardButton variant="contained" sx={{
                    color: '#ffffff',
                    backgroundColor: theme.palette.secondary.main
                }}
                >Create Snapshot</StandardButton> */}

            </Box>

            <StandardOkCancelDialog
                    open={showRequestEditDialog}
                    onClose={() => {
                        setShowRequestEditDialog(false)
                    }}
                    // onOk={() => {
                    //     setShowRequestEditDialog(false)

                    // }}
                    title={'Request Edit'}
                    content={
                        <RequestEditPanel book={props.book} />
                    }
                />
        </>


    )
}

export default inject('stores')(observer(RequestEditAssembly));