import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  useQuery,
  HttpLink,
  createHttpLink,
  gql
} from "@apollo/client";
let uuid = require('uuid');

const mutationPersister = new ApolloLink((operation, forward) => {

  operation.setContext({ start: new Date() });

  if (isMutation(operation)) {

    let context = operation.getContext();

    if (!context.isPersistRetry) {
      let savedContext = {
        forceFetch: context.forceFetch,
        optimisticResponse: context.optimisticResponse,
        serializationKey: context.serializationKey,
        start: context.start
      }
      let offlineMutation = {

        name: operation.operationName,
        query: JSON.stringify(operation.query),
        variables: JSON.stringify(operation.variables),
        context: JSON.stringify(savedContext),
        id: uuid.v1()

      }


      const pendingMutations = JSON.parse(localStorage.getItem('pendingMutations')) || [];
      pendingMutations.push(offlineMutation);
      localStorage.setItem('pendingMutations', JSON.stringify(pendingMutations));
    }


  }
  return forward(operation);
});

const isMutation = (operation) => {

  let isOne = false;
  try {
    operation.query.definitions.forEach((def) => {
      if (def.operation == 'mutation') {
        isOne = true;
      }
    })
  } catch (err) {
    console.log(err);
  }

  return isOne;
}
export default mutationPersister;

