import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import { useGetUserSentEditRequests } from '../../../persistance/persistanceHooks';
import EmptyContent from "../../EmptyContent";
import { useGetUserConnections } from '../../../managers/ConnectionsManager';
import { useOpenUserInConnections } from '../../../managers/ConnectionsManager';
import { useLoadBookEditRequests, useOpenEditRequestInEdit } from '../../../managers/EditRequestsManager';
import EditorEditRequestController from '../../editing/EditorEditRequestController';
import Avatar from '@mui/material/Avatar';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import UserListItem from "../lists/UserListItem";


let missingBookImage = require('../../../assets/images/image-not-found.png')


const OwnedBookEditCard = (props) => {

    let { showReadIcon = true } = props;

    let { book } = props;
    let { editRequest } = props;
    const { openEditRequestInEdit } = useOpenEditRequestInEdit(props);

    let { loading: getBookEditRequestsLoading, error: getBookEditRequestsError, data: getBookEditRequestsData } = useLoadBookEditRequests(props, book?.id)

    useEffect(() => {

        //console.log(getBookEditRequestsData);
    }, [getBookEditRequestsData]);



    const generateSecondaryActions = (editRequest) => {

    const secondaryActions = [];

    if (showReadIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();

                openEditRequestInEdit({
                    editRequest
                });
            }}>
            <ManageSearchIcon />
        </IconButton>)
    }
        return secondaryActions;

    }

    return (
        <List>
            {getBookEditRequestsData?.getBook.editRequests.items && getBookEditRequestsData?.getBook.editRequests.items.length > 0 ?
                getBookEditRequestsData?.getBook.editRequests.items.map((editRequest) => {

                   return  <UserListItem
                    //selected={selectedIndex}
                    user={editRequest.editorUser}
                    secondaryActions={
                        generateSecondaryActions(editRequest)
                    }
                    //secondaryText={userConnection.createdAt != null ? <>Connected: <ReactTimeAgo date={userConnection.createdAt} locale="en-US" /> </> : ''}
                />


                    // return <ListItem
                    //     sx={{ padding: ' 0.25em' }}
                    //     secondaryAction={
                    //         generateSecondaryActions(editRequest)
                    //     }
                    // >
                    //     <ListItemAvatar>
                    //         <Avatar variant="rounded"
                    //             s3Src={editRequest.editorUser?.profilePicUrl}
                    //             missingContent={<img src={missingBookImage}/>}
                    //         >
                    //         </Avatar>
                    //     </ListItemAvatar>
                    //     <ListItemText
                    //         primary={editRequest.editorUser?.name}
                    //         secondary='Edit Timeline'
                    //     />
                    // </ListItem>
                })
                :
                <EmptyContent tagline={'Send your first edit request!'} />
            }
        </List>
    )
}

export default inject('stores')(observer(OwnedBookEditCard));