import {
    observable,
    action,
    computed,
    runInAction,
    extendObservable,
    makeObservable,
    makeAutoObservable,
} from 'mobx';

import { BookDraft, Book, Universe, UniversePrecipitant, Precipitant, ItemType, AttrType, ItemTypeAttrType, Recipe } from '../domain/bookModel';
import Delta from 'quill-delta';
import { configure } from "mobx";
import { v4 as uuid }  from 'uuid';
//import OutlineManager from '../components/outline/OutlineManager';

let masterRecipeJson = require('../assets/json/recipes.json');

configure({
    enforceActions: "never",
})

class BookStore {


    isNewPurchase = false;
    isDarkTheme = true;
    isDevMode = false;
    layout = "layout3";
    preventSaves = false;
    forceEditMode = false;
    isQuillInitialLoad = false;
    distractionFree = false;
    disabledFormatProps = {};
    sortOrder = 'desc';
    sortOrderBy = 'createdAt';

    showFooterDetail = false;
    footerDetailContent = null;

    extendedSideMenuOff = false;
    
    writeSplitScreen = false;
    writeLockScreen = false;

    theme = null;
    setTheme = (theme) => {
        // setIsDarkTheme(!isDarkTheme);
        this.theme = theme;
    };


    uiState = {

        books: {

            leftContent: {
                activeTab: 0
            },

            yourBookDetails: {
                activeTab: 0
            },

            sharedBookDetails: {
                activeTab: 0
            }

        },
        boxSets: {

            leftContent: {
                activeTab: 0
            },

            yourBoxSetDetails: {
                activeTab: 0
            },

            sharedBoxSetDetails: {
                activeTab: 0
            }

        },
        editRequests: {

            leftContent: {
                activeTab: 0
            },

            sentEditRequestDetails: {
                activeTab: 0
            },

            receivedEditRequestDetails: {
                activeTab: 0
            }

        },
        connections: {

            leftContent: {
                activeTab: 0
            },

            connectionDetails: {
                activeTab: 0
            },

            yourGroupDetails: {
                activeTab: 0
            },

            theirGroupDetails: {
                activeTab: 0
            }

        },
        write: {

        },
        format: {
            mainTabsSelectedIndex: 0,
            stepperSelectedIndex: 0,
            recipeTabsSelectedIndex: 0,
            templateEditorSelectedIndex: 0
        }

    };
    loggedIn = false;
    //userName = '';
    user = null;
    cognitoUser = {};
    hasActiveMutations = false;
    authoredBooks = [];
    authoredBoxSets = [];
    invitedToBoxSets = [];
    sharedBooks = [];

    

    outlineDetailSelectedItem = null;
    bookMerlinItemTypes = [
        
    ]
    ownedUniverses = [
        new Universe({
            data: {
                id: 'universe001',
                title: 'Universe 1',
                profilePicUrl: 'uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/e51ee420-0792-11ed-98bc-eb12f332f880_TheUniverse.PNG'
            }
        }),
        new Universe({
            data: {
                id: 'universe002',
                title: 'Universe 2',
                profilePicUrl: null
            }
        }),
        new Universe({
            data: {
                id: 'universe003',
                title: 'Universe 3',
                profilePicUrl: null
            }
        }),
    ];



    setDisabledFormatProps = (disabledFormatProps)=>{

        this.disabledFormatProps = {};
        disabledFormatProps.forEach((config)=>{

            this.disabledFormatProps[config.recipeParamPath] = config;

        })
    }

    preloadUniverse = (universe, Earth, Abe, Cyd) => {

        

        universe.universePrecipitants = [
            new UniversePrecipitant({
                data: {
                    id: 'universe001_precipitant1',
                    universeId: 'universe001',
                    precipitantId: 'precipitant1',
                    universeId: 'universe001',
                    parentId: null,
                    prevId: null,
                    universe:universe,
                    precipitant: Earth
                }
            }),
            new UniversePrecipitant({
                data: {
                    id: 'universe001_precipitant2',
                    universeId: 'universe001',
                    precipitantId: 'precipitant2',
                    universeId: 'universe001',
                    parentId: 'universe001_precipitant1',
                    prevId: null,
                    universe:universe,
                    precipitant: Abe
                }
            }),
            new UniversePrecipitant({
                data: {
                    id: 'universe001_precipitant3',
                    universeId: 'universe001',
                    precipitantId: 'precipitant3',
                    universeId: 'universe001',
                    parentId: 'universe001_precipitant1',
                    prevId: 'universe001_precipitant2',
                    universe:universe,
                    precipitant: Cyd
                }
            })

        ]
    }

    ownedOutlines = [];//new OutlineManager().createTestOutlines();
    universeListUniverse = null;
    outlineListOutline = null;
    activeSceneQuill = null;
    writingBookDraftId = '';
    writingBookDraft = null;
    writingSelectedBookDraftDocumentPart = null;


    writingRightBookDraftId = '';
    writingRightBookDraft = null;
    writingRightSelectedBookDraftDocumentPart = null;



    editingBookDraft = null;
    editingSelectedBookDraftDocumentPart = null;

    sharedBook = null;
    sharedBookDraftId = null;
    sharedBookDraft = null;
    sharedSelectedBookDraftDocumentPart = null;

    trashbinSelectedItem = null;
    // trashbinSelectedBook = null;
    // trashbinSelectedBoxSet = null;



    pendingDeltaOpSaves = {};
    velocityBookDraft = null;
    inflatablesBookDraft = null;
    currentInflatedTemplates = null;
    startersBookDraft = null;
    bookListBook = null;
    sharedBookListBook = null;
    boxSetListBoxSet = null;
    invitedToBoxSetListBoxSet = null;
    universeListUniverse = null;
    selectedItemType = null;
    universeDetailSelectedItem = null;
    currentPrecipitantTitleForPopper = null;
    editRequestListSentEditRequest = null;
    editRequestListReceivedEditRequest = null;
    invitedToUserGroupListGroup = null;
    userGroupListGroup = null;
    sharedGroupListGroup = null;
    connectionsListConnection = null;
    masterRecipe = observable(new Recipe({ data: masterRecipeJson })); //Initialize it with the backup.
    sharedRecipes = [];
    customRecipes = [];
    activeRecipe = null;
    recipeTimestamp = null;
    editingTemplateIds = [
        {
            templateId: '7fd2b1c0-856f-11ea-85f2-87128ebeb879',
            title: 'styles'
        }];
    mainMobileMenuDisplayMode = 'root';
    showHelpDrawer = false;
    showMainDrawer = false;
    helpUrl = 'https://help.bookmerlin.com/articles/features/snapshots/';
    selectedReceivedEditRequest = null;
    selectedSentEditRequest = null;
    backspaceHit = null;
    backspaceRange = null;
    deleteHit = null;
    deleteRange = null;
    deleteDelta = null; //The delta of what is about to be deleted. 
    mostRecentQuillKeyPress = null;
    mostRecentQuillKeyPressSelection = null;
    mostRecentQuillClipboardDelta = null;
    mostRecentQuillClipboardSelection = null;
    mostRecentQuillSelection = null;
    blotIsUpdating = false;

    showCommentsInEditor = false;
    showNotesInEditor = false;
    viewFavoritedComments = false;
    viewPinnedComments = false;
    viewCompletedComments = false;
    viewPendingComments = false;
    showComments = false;
    userComments = [];
    userNotes = [];
    blotsChangedTimestamp = null;
    userCommentsTimestamp = null;
    snackbarMessage = null;
    showSnackbar = false;
    snackbarMessageSeverity = 'info';
    scrollToUserCommentId = null;
    snackbarMessages = [1,2,3];
    
    quillInstanceByPartId = {

    }

    getQuillInstancesByPartId = (partId) => {

        try{

            return this.quillInstanceByPartId[partId];

        }catch(err){
            //do nothing
        }
    }

    setQuillInstanceByPartId = (quill, partId) => {


        //console.log(partId);

        //this.quillInstanceByPartId[partId] = quill;
        if (this.quillInstanceByPartId[partId] == null) {
            this.quillInstanceByPartId[partId] = [];
        }

        if (this.quillInstanceByPartId[partId].filter(f => f === quill).length > 0) {
            //do not add it to the list
        } else {
            this.quillInstanceByPartId[partId].push(quill);
        }


        //console.log(this.quillInstanceByPartId);
    }


    removeQuillInstanceByPartId = (quill, partId) => {


        //this.quillInstanceByPartId[partId] = quill;
        if (this.quillInstanceByPartId[partId] == null) {
            this.quillInstanceByPartId[partId] = [];
        }

        if (this.quillInstanceByPartId[partId]) {
            let foundQuills = this.quillInstanceByPartId[partId].filter(f => f === quill);

        }


    }


    documentPartSubscriptions = {};
    documentPartCommentsSubscriptions = {};

    addDocumentPartSubscription = ({
        partId,
        listenerId,
        callback,
        commentsCallback
    }) => {
        if (this.documentPartSubscriptions[partId] == null) {

            this.documentPartSubscriptions[partId] = {};

        }
        this.documentPartSubscriptions[partId][listenerId] = {
            listenerId,
            callback
        }

        if(commentsCallback){
            if (this.documentPartCommentsSubscriptions[partId] == null) {

                this.documentPartCommentsSubscriptions[partId] = {};
    
            }
            this.documentPartCommentsSubscriptions[partId][listenerId] = {
                listenerId,
                callback:commentsCallback
            }
        }
    };

    removeDocumentPartSubscription = ({
        partId,
        listenerId
    }) => {

        try {

            let subscriptions = this.documentPartSubscriptions[partId];
            //console.log(subscriptions);
            delete subscriptions[listenerId];
            //console.log(subscriptions);

        } catch (err) {
            console.log(err);
        }

    };

    postDocumentPartEvent = ({
        partId,
        delta,
        sourceId,
        quillSourceType
    }) => {

        if (this.documentPartSubscriptions[partId]) {

            let listeners = this.documentPartSubscriptions[partId];
            Object.keys(listeners).forEach((listenerId) => {

                let subscription = this.documentPartSubscriptions[partId][listenerId];
                subscription.callback({
                    partId,
                    delta,
                    sourceId,
                    quillSourceType
                })
            })
        }

    };

    postDocumentPartCommentsEvent = ({
        partId,
        userComment,
        sourceId,
        quillSourceType
    }) => {

        if (this.documentPartCommentsSubscriptions[partId]) {

            let listeners = this.documentPartCommentsSubscriptions[partId];
            Object.keys(listeners).forEach((listenerId) => {

                let commentsCallback = this.documentPartCommentsSubscriptions[partId][listenerId];
                commentsCallback.callback({
                    partId,
                    sourceId,
                    quillSourceType,
                    userComment
                })
            })
        }

    };

    

    clearDocumentPartSubscriptions = () => {
        this.documentPartSubscriptions = {};
        this.documentPartCommentsSubscriptions = {};
    };

    showSnackMessage = (msg, severity) => {

        this.snackbarMessage = msg;
        this.snackbarMessageSeverity = severity;
        this.showSnackbar = true;
    }

    reset = () => {

        this.loggedIn = false;
        //userName = '';
        this.user = null;
        this.cognitoUser = {};
        this.hasActiveMutations = false;
        this.authoredBooks = []; //used by DeltaToDom 
        this.authoredBoxSets = [];
        this.invitedToBoxSets = [];
        this.sharedBooks = [];
        this.writingBookDraftId = '';
        this.sharedBookDraftId = null;
        //this.writingBook = null;
        this.writingBookDraft = null;
        this.editingBookDraft = null;
        this.sharedBookDraft = null;
        //inflatablesBookDraft = null;
        //currentInflatedTemplates = null;
        //startersBookDraft = null;
        this.bookListBook = null;
        this.sharedBookListBook = null;
        this.boxSetListBoxSet = null;
        this.invitedToBoxSetListBoxSet = null;
        this.universeListUniverse = null;
        this.sharedRecipes = [];
        this.customRecipes = [];
        this.activeRecipe = null;
        this.recipeTimestamp = null;
        this.editingTemplateIds = []
        this.mainMobileMenuDisplayMode = 'root';
        this.writingSelectedBookDraftDocumentPart = null;
        this.editingSelectedBookDraftDocumentPart = null;
        this.sharedSelectedBookDraftDocumentPart = null;
        this.selectedReceivedEditRequestId = null;
        this.backspaceHit = null;
        this.backspaceRange = null;
        this.deleteHit = null;
        this.deleteRange = null;
        this.mostRecentQuillKeyPress = null;
        this.mostRecentQuillKeyPressSelection = null;
        this.mostRecentQuillClipboardDelta = null;
        this.mostRecentQuillClipboardSelection = null;
        this.mostRecentQuillSelection = null;
        this.editRequestListSentEditRequest = null;
        this.editRequestListReceivedEditRequest = null;
        this.invitedToUserGroupListGroup = null;
        this.userGroupListGroup = null;

        this.viewFavoritedComments = false;
        // this.trashbinSelectedBook = null;
        // this.trashbinSelectedBoxSet = null;
        this.trashbinSelectedItem = null;
        this.showComments = false;
        this.userComments = [];
        this.userNotes = [];
        this.blotsChangedTimestamp = null;
    }

    constructor() {
        makeAutoObservable(this, {
            mostRecentQuillSelection: false
        }, { autoBind: true });
        // makeObservable(this, {
        //     userName:observable,
        //     cognitoUser:observable,
        //     loggedIn: observable,
        //     //writingBook: observable,
        //     //writingBookDraft: observable,
        //     writingBookDraftId: observable,
        //     authoredBooks: observable,
        //     hasActiveMutations: observable,
        //     setWritingBookDraft: action,
        //     userId: computed

        // });

        let Universe1 = this.ownedUniverses[0];


 















 
    let attrType_profilePic = new AttrType({data:{
        id:'attrType_profilePic',
        title:'profilePic',
        controlType:AttrType.USER_IMAGE
    }});


    let attrType_name = new AttrType({data:{
        id:'attrType_name',
        title:'name',
        controlType:AttrType.TEXTFIELD
    }});

    let attrType_hobbies = new AttrType({data:{
        id:'attrType_name',
        title:'hobbies',
        controlType:AttrType.TEXTAREA
    }});



        let itemType1 = new ItemType({data:{
            id:'itemType1',
            title:'Character'
        }});
        itemType1.appendAttrType(attrType_profilePic);
        itemType1.appendAttrType(attrType_name);
        itemType1.appendAttrType(attrType_hobbies);
    
        let itemType2 = new ItemType({data:{
            id:'itemType2',
            title:'Place'
        }});
    
        let itemType3 = new ItemType({data:{
            id:'itemType3',
            title:'Thing'
        }});

        this.bookMerlinItemTypes = [
            itemType1,
            itemType2,
            itemType3
        ]


        let Earth = new Precipitant({
            data: {
                id: 'precipitant1',
                title: 'Earth',
                itemTypeId: 'itemType_location',
                itemType:itemType2,
                universes: [],
                universe:Universe1
            }
        });

        let Abe = new Precipitant({
            data: {
                id: 'precipitant2',
                title: 'Abe',
                itemTypeId: 'itemType_character',
                itemType:itemType1,
                universes: [],
                universe:Universe1
            }
        });

        let Cyd = new Precipitant({
            data: {
                id: 'precipitant3',
                title: 'Cyd',
                itemTypeId: 'itemType_character',
                itemType:itemType1,
                universes: [],
                universe:Universe1
            }
        });


        this.preloadUniverse(Universe1, Earth, Abe, Cyd);
    }


    setShowComments = action(show => {
        this.showComments = show;
    })

    setUserComments = action(comments => {
        this.userComments.replace(comments);
    })

    setBookListBook = action(bookListBook => {
        this.bookListBook = bookListBook;
    })


    setAuthoredBooks = (listBooksData) => {
        //console.log(listBooksData);

        let booksArray = [];
        try {
            listBooksData.listBooks.items.map((bookData) => {
                booksArray.push(new Book({ bookDraft: null, data: bookData }))
            })

            this.authoredBooks.replace(observable(booksArray));
        } catch (err) {
            //console.log(err);
        }
    }

    setAuthoredBookPojos = (bookPojos) => {
        //console.log(listBooksData);

        let booksArray = [];
        try {
            this.authoredBooks.replace(bookPojos);
        } catch (err) {
            console.log(err);
        }
    }

    setUser = (user) => {
        this.user = observable(user);
    }

    setCognitoUser = (cognitoUser) => {
        this.cognitoUser = observable(cognitoUser);
    }


    setWritingBookDraftId = (bookDraftId) => {

        this.writingSelectedBookDraftDocumentPart = null;
        this.writingBookDraftId = bookDraftId;
        this.writingBookDraft = null; //so that write and format pages do not start loading with an old bookdraft before it loads the new one. 
    }
    /*
    This is the 'loaded' bookDraft that is inflated within one of the working pages. 
    */
    setWritingBookDraft = (bookDraft) => {

        //console.log(bookDraft);
        //Only set the bddp to null if we are loading a different bookDraft
        if (this.writingBookDraft && bookDraft && this.writingBookDraft.id != bookDraft.id) {
            this.writingSelectedBookDraftDocumentPart = null;
        }

        this.writingBookDraft = observable(bookDraft);

        try {

            //Since we are creating a new bookDraft, any bddps from the old one can no longer trigger rerenders. 
            //Therefore we need to replace the old references with the new. 
            //This is true because the apollo cache is not necessarily in sync with the mobx cache. 
            //We are using both. So in this case, we are synchronizing mobx and apollo cache to ensure rerenders
            //take place when they should. This became apparent when the documentPart editors would only add new child editors
            //once. After that, they were frozen. 
            let bddp = this.writingBookDraft.getBookDraftDocumentPartByPartId(this.writingSelectedBookDraftDocumentPart.partId);
            if (bddp != null) {

                this.writingSelectedBookDraftDocumentPart = bddp;

            }

        } catch (err) {
            //console.log(err);
        }


    }




    setEditingBookDraft = (bookDraft) => {

        //console.log(bookDraft);
        //Only set the bddp to null if we are loading a different bookDraft
        if (this.editingBookDraft && bookDraft && this.editingBookDraft.id != bookDraft.id) {
            this.editingSelectedBookDraftDocumentPart = null;
        }

        this.editingBookDraft = observable(bookDraft);

        try {

            //Since we are creating a new bookDraft, any bddps from the old one can no longer trigger rerenders. 
            //Therefore we need to replace the old references with the new. 
            //This is true because the apollo cache is not necessarily in sync with the mobx cache. 
            //We are using both. So in this case, we are synchronizing mobx and apollo cache to ensure rerenders
            //take place when they should. This became apparent when the documentPart editors would only add new child editors
            //once. After that, they were frozen. 
            let bddp = this.editingBookDraft.getBookDraftDocumentPartByPartId(this.editingSelectedBookDraftDocumentPart.partId);
            if (bddp != null) {

                this.editingSelectedBookDraftDocumentPart = bddp;

            }

        } catch (err) {
            //console.log(err);
        }


    }

    setSharedBookDraftId = (bookDraftId) => {

        this.sharedSelectedBookDraftDocumentPart = null;
        this.sharedBookDraftId = bookDraftId;
        this.sharedBookDraft = null; //so that write and format pages do not start loading with an old bookdraft before it loads the new one. 
    }



    setSharedBookDraft = (bookDraft) => {

        //console.log(bookDraft);
        //Only set the bddp to null if we are loading a different bookDraft
        if (this.sharedBookDraft && bookDraft && this.sharedBookDraft.id != bookDraft.id) {
            this.sharedSelectedBookDraftDocumentPart = null;
        }

        this.sharedBookDraft = observable(bookDraft);

        try {

            //Since we are creating a new bookDraft, any bddps from the old one can no longer trigger rerenders. 
            //Therefore we need to replace the old references with the new. 
            //This is true because the apollo cache is not necessarily in sync with the mobx cache. 
            //We are using both. So in this case, we are synchronizing mobx and apollo cache to ensure rerenders
            //take place when they should. This became apparent when the documentPart editors would only add new child editors
            //once. After that, they were frozen. 
            let bddp = this.sharedBookDraft.getBookDraftDocumentPartByPartId(this.sharedSelectedBookDraftDocumentPart.partId);
            if (bddp != null) {

                this.sharedSelectedBookDraftDocumentPart = bddp;

            }

        } catch (err) {
            //console.log(err);
        }


    }

    setInflatablesBookDraft = (bookDraft) => {
        this.inflatablesBookDraft = observable(bookDraft);
    }

    setVelocityBookDraft = (bookDraft) => {
        this.velocityBookDraft = observable(bookDraft);
    }

    setStartersBookDraft = (bookDraft) => {
        this.startersBookDraft = observable(bookDraft);
    }

    setMasterRecipe = (recipe) => {
        this.masterRecipe = observable(recipe);
    }

    setActiveRecipe = (recipe) => {
        this.activeRecipe = observable(recipe);
    }

    addEditTemplateId(template) {

        if (this.editingTemplateIds.filter(f => f.templateId == template.templateId).length == 0) {

            this.editingTemplateIds.push(template);
        }


    }

    removeEditTemplateId(templateInfo) {

        //'7fd2b1c0-856f-11ea-85f2-87128ebeb879'
        //console.log(templateInfo);
        let newArray = [];

        this.editingTemplateIds.forEach((m) => {
            if (templateInfo.templateId != m.templateId || m.templateId == '7fd2b1c0-856f-11ea-85f2-87128ebeb879') {
                newArray.push(m);
            }
        });

        this.editingTemplateIds.replace(newArray);

    }


    // get userId() {

    //     if (this.cognitoUser) {
    //         return this.cognitoUser.attributes.sub;
    //     }
    // }

    setBlotsChangedTimestamp = action(() => {
        //console.log('setBlotsChangedTimestamp()...');
        this.blotsChangedTimestamp = Date.now();
    })

    setBlotsChangedTimeout = action(() => {

        if (this.blotsTimeoutId == null) {

            this.blotsTimeoutId = setTimeout(() => {

                this.blotsChangedTimestamp = Date.now();

            }, 250);



        } else {

            clearTimeout(this.blotsTimeoutId);

            this.blotsTimeoutId = setTimeout(() => {

                this.blotsChangedTimestamp = Date.now();

            }, 250);

        }

    })

    addPendingDeltaOps({
        userId,
        partId,
        expectedVersion,
        deltaOps,
        allDeltaOps,
        documentPart,
        mutation
    }, callback) {


        let that = this;
        //return;

        if (documentPart.wrongVersion) {
            return;
        }


        const composeDelta = (deltaOps) => {
            let finalDeltaOps = null;

            deltaOps.forEach((delta) => {

                let parsedDelta = delta;//JSON.parse(delta);
                if (!finalDeltaOps) {

                    finalDeltaOps = new Delta(parsedDelta);

                } else {

                    finalDeltaOps = finalDeltaOps.compose(new Delta(parsedDelta));

                }
            });

            return finalDeltaOps;

        }



        if (!that.pendingDeltaOpSaves[partId]) {
            that.pendingDeltaOpSaves[partId] = observable({
                deltaOps: [deltaOps]
            })
            that.startedWriteDelay = true;

            setTimeout(function () {
                if (that.preventSaves) {
                    //console.log('do not save...')
                    return;
                }
                //that.startedWriteDelay = false;
                let toBeSaved = that.pendingDeltaOpSaves[partId].deltaOps;
                delete that.pendingDeltaOpSaves[partId];

                let finalDeltas = composeDelta(toBeSaved);
                ////console.log(finalDeltas);

                that.recipeTimestamp = Date.now(); //this triggers the ereader
                callback({
                    finalDeltas
                });
                // persistanceManager.updateDocumentPartBatch({
                // 	userId,
                // 	itemKey: partId,
                // 	expectedVersion: expectedVersion,
                // 	//itemType, 
                // 	//values, 
                // 	//fieldName, 
                // 	fieldValue: finalDeltas,
                // 	deltaOps: allDeltaOps
                // }, () => {
                // 	documentPart.version += 1;
                // 	//console.log('back from update');
                // 	if (Object.keys(that.pendingDeltaOpSaves).length > 0) {
                // 		that.startedWriteDelay = true;
                // 	} else {
                // 		that.startedWriteDelay = false;
                // 	}
                // },
                // 	(errorArgs) => {
                // 		handleGraphQlError.bind(that)
                // 		handleGraphQlError(errorArgs, documentPart)
                // 	}
                // )


            }, 1000);

        } else {
            that.pendingDeltaOpSaves[partId].deltaOps.push(deltaOps);
        }
    }





    addPendingFormatChange() {


        let that = this;
        
        
            if(that.pendingFormatChangeSettimeoutId!=null){

                clearTimeout(that.pendingFormatChangeSettimeoutId);
            }


            
            
            that.pendingFormatChangeSettimeoutId = setTimeout(function () {
                
                

                that.recipeTimestamp = Date.now(); //this triggers the ereader
                that.pendingFormatChangeSettimeoutId = null;
                

            }, 1000);

        
        
    }


}

export default BookStore;