
class EntityManager {

  moveDocumentPart = (args) => {

    let {
      userId,
      bookDraftId,
      bookDraftExpectedVersion,
      movedPartId,
      movedPartExpectedVersion,
      targetParentPartId,
      targetPrevPartId,
      targetParentBddp,
      targetPrevBddp,
      movedBddp,
      stores
  } = args;
  
    //console.log('moveDocumentPart..');
    let sourceBookDraft = movedBddp.bookDraft;
    let targetBookDraft = targetParentBddp.bookDraft;

   

    if(this.documentPartMoveIsAllowed(args)){
      let moveBddpData = this.getMoveBddpData(args);

      //1. Update the mobx entities. ( or dont and let Apollo cache do it ??)
      this.updateMobxEntities(moveBddpData);
  
      //2. Increment the bookDraft version(s)
      sourceBookDraft.version++;

      if(sourceBookDraft.id != targetParentBddp.bookDraft.id){
        targetBookDraft.version++;
      }
      args.stores.bookStore.recipeTimestamp = Date.now();
    }
    

    

  }

  documentPartMoveIsAllowed = (args) => {

    let {
      userId,
      bookDraftId,
      bookDraftExpectedVersion,
      movedPartId,
      movedPartExpectedVersion,
      targetParentPartId,
      targetPrevPartId,
      targetParentBddp,
      targetPrevBddp,
      movedBddp,
      stores
  } = args;

  //console.log(movedBddp);
  //console.log(targetParentBddp);

  //**************** Add all of the "dont allow" cases here  *******************************/
  //Nothing inside an included book boxset can be moved. Period!
  if(movedBddp.getBoxSetBook() && movedBddp.documentPart.partType.toLowerCase()!='book'){
    return false;
  }


   //**************** Add all of the "allow" cases here  *******************************/
  if(movedBddp && movedBddp.documentPart.partType.toLowerCase()=='book'){

    switch(targetParentBddp.documentPart.partType.toLowerCase()){

      case 'topbook':
        return true;
      break;

      default:
        return false;
      break;
    }
  }


  if(movedBddp && movedBddp.documentPart.partType.toLowerCase()=='volume'){

    switch(targetParentBddp.documentPart.partType.toLowerCase()){

      case 'topbook':
        return true;
      break;

      default:
        return false;
      break;
    }
  }

  if(movedBddp && movedBddp.documentPart.partType.toLowerCase()=='part'){

    switch(targetParentBddp.documentPart.partType.toLowerCase()){

      case 'topbook':
        return true;
      break;
      
      case 'volume':
        return true;
      break;


      default:
        return false;
      break;
    }
  }

  if(movedBddp && movedBddp.documentPart.partType.toLowerCase()=='chapter'){

    switch(targetParentBddp.documentPart.partType.toLowerCase()){

      case 'topbook':
        return true;
      break;

      case 'volume':
        return true;
      break;

      case 'part':
        return true;
      break;


      default:
        return false;
      break;
    }
  }

    if(movedBddp && movedBddp.documentPart.partType.toLowerCase()=='scene'){

      switch(targetParentBddp.documentPart.partType.toLowerCase()){

        case 'chapter':
          return true;
        break;

        default:
          return false;
        break;
      }
    }

    //Allow everything that is not a special case partType to be moved inside of topbook, volumes and part. 
    if( movedBddp && 
        movedBddp.documentPart.partType.toLowerCase()!='topbook' &&
        movedBddp.documentPart.partType.toLowerCase()!='volume' &&
        movedBddp.documentPart.partType.toLowerCase()!='part' &&
        movedBddp.documentPart.partType.toLowerCase()!='chapter' &&
        movedBddp.documentPart.partType.toLowerCase()!='scene' &&
        movedBddp.documentPart.partType.toLowerCase()!='book'){

      switch(targetParentBddp.documentPart.partType.toLowerCase()){

        case 'topbook':
          return true;
        break;
  
        case 'volume':
          return true;
        break;
  
        case 'part':
          return true;
        break;
  
  
        default:
          return false;
        break;
      }
    

  }

  }

  
  getMoveBddpData = (args) => {

    let {
        userId,
        bookDraftId,
        bookDraftExpectedVersion,
        movedPartId,
        movedPartExpectedVersion,
        targetParentPartId,
        targetPrevPartId,
        targetParentBddp,
        targetPrevBddp,
        movedBddp,
        stores
    } = args;


    //First find all the players. 
    let movedBddpOriginalBookDraft = movedBddp.bookDraft;
    let movedBddpTargetBookDraft = movedBddp.bookDraft;

    //Find the original bddp that comes after the moved one ( if any )
    let movedBddpOriginalNextBddp = movedBddpOriginalBookDraft.getBookDraftDocumentPartByPrevPartId(movedBddp.parentPartId,movedPartId);

    //Find the target bddp that comes after the moved one ( if any )
    let movedBddpTargetNextBddp = movedBddpTargetBookDraft.getBookDraftDocumentPartByPrevPartId(targetParentPartId,targetPrevPartId);

    //console.log('movedBddp:'+movedBddp.documentPart.title);
    //console.log('movedBddpOriginalNextBddp:'+movedBddpOriginalNextBddp?.documentPart.title);
    //console.log('movedBddpTargetNextBddp:'+movedBddpTargetNextBddp?.documentPart.title);
    //console.log('targetParentBddp:'+targetParentBddp.documentPart.title);
    //console.log('targetPrevBddp:'+targetPrevBddp?.documentPart.title);

    return {
        movedBddpOriginalBookDraft,
        movedBddpTargetBookDraft,
        movedBddpOriginalNextBddp,
        movedBddpTargetNextBddp,
        movedBddp,
        targetParentBddp,
        targetPrevBddp
    }

}


updateMobxEntities = (args) => {

  let {
      movedBddpOriginalBookDraft,
      movedBddpTargetBookDraft,
      movedBddpOriginalNextBddp,
      movedBddpTargetNextBddp,
      movedBddp,
      targetParentBddp,
      targetPrevBddp
  } = args;


  //Handle the original changes before modifying the movedBddp since
  //we will use its prevPartId value if needed. 
  if(movedBddpOriginalNextBddp){
      movedBddpOriginalNextBddp.prevPartId = movedBddp.prevPartId;
  }

  //Handle the target changes 
  movedBddp.parentPartId = targetParentBddp.partId;
  if(targetPrevBddp){
      movedBddp.prevPartId = targetPrevBddp.partId;
  }else{
      movedBddp.prevPartId = null;
  }
  
  if(movedBddpTargetNextBddp){
      movedBddpTargetNextBddp.prevPartId = movedBddp.partId;
  }

  
  



 


}


  addDocumentPart = () => {

    //console.log('adding document part...')
  }

  loadDocumentParts = (documentPart, delta, props) => {

    /*let props = {
      sceneDocumentPart:this.options.stores.bookStore.currentSceneDocumentPart.documentPart,
      stores:this.options.stores,
      userStore:this.options.userStore
    }*/

    //let documentPart = props.sceneDocumentPart;
    try {

      let userStore = props.stores.userStore;
      delta.ops = delta.ops.map((op) => {

        try {
          if (op.insert && op.insert.alsobys) {
            op.insert.alsobys.documentPart = documentPart;
            op.insert.alsobys.stores = props.stores;
            op.insert.alsobys.userStore = userStore;
          }
          if (op.insert && op.insert.contributors) {
            op.insert.contributors.documentPart = documentPart;
            op.insert.contributors.stores = props.stores;
            op.insert.contributors.userStore = userStore;
          }
          if (op.insert && op.insert.publisher) {
            op.insert.publisher.documentPart = documentPart;
            op.insert.publisher.stores = props.stores;
            op.insert.publisher.userStore = userStore;
          }
          if (op.insert && op.insert.blurbs) {
            op.insert.blurbs.documentPart = documentPart;
            op.insert.blurbs.stores = props.stores;
            op.insert.blurbs.userStore = userStore;
          }
          if (op.insert && op.insert.notes) {
            op.insert.notes.documentPart = documentPart;
            op.insert.notes.stores = props.stores;
            op.insert.notes.userStore = userStore;
          }
          if (op.insert && op.insert.footnote) {
            op.insert.footnote.documentPart = documentPart;
            op.insert.footnote.stores = props.stores;
            op.insert.footnote.userStore = userStore;


          }
          if (op.insert && op.insert.image4) {
            op.insert.image4.documentPart = documentPart;
            op.insert.image4.stores = props.stores;
            op.insert.image4.userStore = userStore;


          }
          if (op.insert && op.insert.fullPageImage) {
            op.insert.fullPageImage.documentPart = documentPart;
            op.insert.fullPageImage.stores = props.stores;
            op.insert.fullPageImage.userStore = userStore;


          }
          if (op.insert && op.insert.tocs) {
            op.insert.tocs.documentPart = documentPart;
            op.insert.tocs.stores = props.stores;
            op.insert.tocs.userStore = userStore;


          }
        } catch (err) {
          //console.log(err);
        }

        return op;
      });


      //delta = await props.stores.bookStore.convertImageUrls(delta, documentPart);




      return delta;

    } catch (err) {
      //console.log(err);
    }




  }



}


export default new EntityManager();