import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { IconButton, styled } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';

function TablePopupMenu(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();
    

    return (
        <div>
            <IconButton aria-label="delete">
                <MoreVertIcon sx={{ color: theme.palette.secondary.main }} onClick={(e) => {
                    e.preventDefault();
                    //console.log(book);
                    handleClick(e);

                }} />
            </IconButton>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {props.menuItems.map((m)=>{
                        return <MenuItem onClickx={handleClose}>{m}</MenuItem>
                    })
                }
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem> */}
            </Menu>
        </div>
    );
}

export default inject('stores')(observer(TablePopupMenu));

