import React, { useEffect, useState } from 'react';
import { observable } from 'mobx';
import { useUpdateRecipeParam } from '../persistance/persistanceHooks';
import { BoxSet } from '../domain/bookModel';
import { Auth } from "aws-amplify";
import { useGetStripeBillingPortal, useGetStripePurchaseLink } from '../persistance/persistanceHooks';

class PaymentManager {

    constructor() {

    }

}


export function useGetBillingPortal(props) {




    const  { lazyGetStripeBillingPortal,loading, error, data } = useGetStripeBillingPortal();


   
    

    return {
        lazyGetStripeBillingPortal,loading, error, data
    };
}

export function useGetPurchaseLink(props) {




    const  { lazyGetPurchaseLink,loading, error, data } = useGetStripePurchaseLink();


   
    

    return {
        lazyGetPurchaseLink,loading, error, data
    };
}

export default new PaymentManager();