import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';


const LaurasBooksDetails = (props) => {


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <div>
            <Box>
                <TextField fullWidth sx={{ m: 1 }} label="Title" variant="outlined" />
                <TextField fullWidth sx={{ m: 1 }} label="Subtitle" variant="outlined" />
                <TextField fullWidth sx={{ m: 1 }} label="Author Name" variant="outlined" />
                <Divider />
                {/* <TextField fullWidth sx={{ m: 1 }} label="Kindle" variant="outlined" />
                <TextField fullWidth sx={{ m: 1 }} label="iBooks" variant="outlined" />
                <TextField fullWidth sx={{ m: 1 }} label="Google Name" variant="outlined" />
                <TextField fullWidth sx={{ m: 1 }} label="Kobo" variant="outlined" />
                <TextField fullWidth sx={{ m: 1 }} label="Nook" variant="outlined" />
                <TextField fullWidth sx={{ m: 1 }} label="Other" variant="outlined" /> */}
            </Box>
        </div>
    )
}
export default inject('stores')(observer(LaurasBooksDetails));