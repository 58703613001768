import React, { useState, useEffect } from 'react';
import { styled } from "@mui/material";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Tooltip, Box } from '@mui/material';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({

    //backgroundColor:'red'



}))

export default function StandardToggleButtonGroup(props) {

    const [alignment, setAlignment] = React.useState('left');

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };


    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start'
            }}>
                <StyledToggleButtonGroup
                    size="small"
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                >
                    <ToggleButton value="left" aria-label="left aligned">
                        {/* <FormatAlignLeftIcon /> */}
                        BOXSET
                    </ToggleButton>
                    <ToggleButton value="center" aria-label="centered">
                        {/* <FormatAlignCenterIcon /> */}
                        BOOK
                    </ToggleButton>
                    <ToggleButton value="right" aria-label="right aligned">
                        {/* <FormatAlignRightIcon /> */}
                        CUSTOM
                    </ToggleButton>
                </StyledToggleButtonGroup>
                <span style={{
                    display: 'flex',
                    gap: '.75em',
                    alignItems: 'center'
                }}>
                    <Tooltip title='Set Theme'>
                        <FormatPaintIcon fontSize={'small'} color={'disabled'} onClick={()=>{ 
                            props.onShowBookmerlinStyles();
                        }}/>
                    </Tooltip>

                    <Tooltip title='Save Theme'>
                        <SaveIcon fontSize={'small'} color={'disabled'} onClick={() => { }} />
                    </Tooltip>
                    <Tooltip title='Share Theme'>
                        <ShareIcon fontSize={'small'} color={'disabled'} onClick={() => { }} />
                    </Tooltip>

                    <InfoOutlinedIcon fontSize={'small'} color={'disabled'} onClick={() => { }} />
                </span>
            </Box>



            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                <StyledToggleButtonGroup
                    size="small"
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                >
                    <ToggleButton value="left" aria-label="left aligned">
                        {/* <FormatAlignLeftIcon /> */}
                        COMMON
                    </ToggleButton>
                    <ToggleButton value="center" aria-label="centered">
                        {/* <FormatAlignCenterIcon /> */}
                        SPECIFIC
                    </ToggleButton>
                </StyledToggleButtonGroup>
               
            </Box>

        </Box>

    );
}
