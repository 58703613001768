import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import createStandardTheme from '../themes/theme';
import { Box, Button, SwipeableDrawer } from '@mui/material';
import StandardAppBar from './StandardAppBar';
import MainMenuContent2 from './menus/MainMenuContent2';
import Library from './boilerPlate/routes/Library';
import StretchBox from './boilerPlate/StretchBox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MainMenuContent from './menus/MainMenuContent';
import ScrollBox from './boilerPlate/ScrollBox';
import { StandardPaper } from './StandardPaper';
import { NestedListComponent2 } from './tree/DNDTree/NestedListComponent2';
import { enqueueSnackbar } from 'notistack'
import AppContext, { AppContextProvider } from '../AppContext';

const AppWrapperDummy = (props) => {


  const theme = useTheme();
  let standardTheme = createStandardTheme(props.stores.bookStore.isDarkTheme);
  const appContext = React.useContext(AppContext);

  //appContext.setToaster(enqueueSnackbar);

  useEffect(() => {

    standardTheme = createStandardTheme(props.stores.bookStore.isDarkTheme);

  }, [props.stores.bookStore.isDarkTheme]);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [showMenuDrawer, setShowMenuDrawer] = useState(false);

  if (!props.stores.userStore.user) {
    //return <div>No user yet!111</div>
  }

  const toggleDrawer =
    (open) =>
      (event) => {
        if (event.type === 'keydown' && ((event).key === 'Tab' || (event).key === 'Shift')) {
          return;
        }

        setShowMenuDrawer(open);
      };



  return (
    <ThemeProvider theme={standardTheme}>
      {/* <SnackbarProvider />  */}
      <Box
        sx={{
          height: '100vh',
          //border: '4px solid red',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <StandardPaper sx={{
          flex: 1,
          //border: '4px solid white',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
          <Box
            id='header'
            sx={{
              //border: '1px solid red',
              //p: 2,
            }}
          >
            <StandardAppBar
              onOpenMainDrawer={() => {
                // toggleDrawer(true)
                setShowMenuDrawer(true);
              }}
            />
            {/* <div>{props.stores.bookStore.isDarkTheme ? 'dark' : 'light'}</div> */}
          </Box>



          <StretchBox sxProps={{

          }}>


            {!isSmallScreen &&
              <>
               
                  {/* <button onClick={() => {

                    //enqueueSnackbar('That was easy!')
                    try{
                     
                      enqueueSnackbar('That was easy!')
                    }catch(err){
                      console.log(err);
                    }
                    
                  }}
                    >Show snackbar</button> */}
                <MainMenuContent2 />
              </>

            }



            <StretchBox sxProps={{
              marginLeft: '1em',
              marginRight: '1em'
            }}>
              {props.children}
            </StretchBox>

          </StretchBox>


          {/* {isSmallScreen && */}
          <SwipeableDrawer
            anchor={'left'}
            open={showMenuDrawer}
            onClose={() => {
              setShowMenuDrawer(false);
            }}
            sx={{ width: '50%' }}
          //variant='persistent'
          //onOpen={setShowDetailDrawer(true)}
          >
            {/* <Button variant='contained' onClick={(e) => {
          e.preventDefault();
          setShowDetailDrawer(false);
        }}>Close</Button> */}
            <div style={{
              // width: '100vw'
            }}>

              <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>
                  <MainMenuContent />
                </ScrollBox>
              </StretchBox>
            </div>
          </SwipeableDrawer>
          {/* } */}
        </StandardPaper>
      </Box>
    </ThemeProvider>
  );
}

export default inject('stores')(observer(AppWrapperDummy));
