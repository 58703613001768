import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const DocumentPartHistoryLineChart = (props) => {

    let {bookDraftDocumentPart} = props;

    const [labels, setLabels] = useState([]);
    const [values, setValues] = useState([]);

    useEffect(()=>{

        if(bookDraftDocumentPart){

            let labels = [];
            let values = [];
            bookDraftDocumentPart.documentPart.flattenedOps.forEach((fo)=>{
                
                labels.push('.');
                values.push(fo.numWords);

            })

            setLabels(labels);
            setValues(values);

        }

    },[bookDraftDocumentPart])

    const options = {
        responsive: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
            },
        },
    };

    //const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: values,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            // {
            //     label: 'Dataset 2',
            //     data: [61, 30, 24, 76, 73, 13, 25],
            //     borderColor: 'rgb(53, 162, 235)',
            //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
            // },
        ],
    };


    return (
        <div >

            <Line options={options} data={data} style={{
            height: '10em'
        }}/>
        </div>
    )
}

export default inject('stores')(observer(DocumentPartHistoryLineChart));



