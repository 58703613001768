import React, { useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import config from '../../aws-exports';
import { useGetOwnedBoxsets } from '../../managers/BoxsetsManager';
import StandardImageViewer from './StandardImageViewer';
import StandardRoundedAction from '../actions/StandardRoundedAction';
import Feather from 'mdi-material-ui/Feather';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import { useOpenBookInWrite, useOpenBookInFormat } from '../../managers/BooksManager';
import ReactTimeAgo from 'react-time-ago';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Button } from '@mui/material';
import FilterWrapper from './FilterWrapper';
import StandardButton from '../StandardButton';

function TableHeader({ isSmallScreen, onSortChange, columns }) {
  const theme = useTheme();
  const [sorting, setSorting] = useState({ column: 'createdAt', order: 'asc' });


  const handleSortClick = (column) => {
    const { order } = sorting;

    if (sorting.column === column) {
      setSorting({ column, order: order === 'asc' ? 'desc' : 'asc' });
      onSortChange(column, order === 'asc' ? 'desc' : 'asc');
    } else {
      setSorting({ column, order: 'asc' });
      onSortChange(column, 'asc');
    }
  };

  return (
    <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'grey' }}>
      <TableRow sx={{  }}>
        {columns.map((column) => {

          const sortClickHandler = () => {

            if (column.sortable) {
              handleSortClick(column.title)
            }
          }

          return <TableCell
            onClick={sortClickHandler}
            align={column.align}
          >
            {column.title}
            {sorting.column === column.title && (sorting.order === 'asc' ? '▲' : '▼')}
          </TableCell>
        })}

        {/* <TableCell align="left">Cover</TableCell>
        <TableCell onClick={() => handleSortClick('title')} style={{ cursor: 'pointer' }}>
          Title {sorting.column === 'title' && (sorting.order === 'asc' ? '▲' : '▼')}
        </TableCell>
        <TableCell align="center">Write</TableCell>
        {!isSmallScreen && (
          <TableCell align="center">Format</TableCell>
        )}
        <TableCell align="center" onClick={() => handleSortClick('createdAt')} style={{ cursor: 'pointer' }}>
          Created {sorting.column === 'createdAt' && (sorting.order === 'asc' ? '▲' : '▼')}
        </TableCell> */}
      </TableRow>
    </TableHead>
  );
}

function TableBodyContent({ props, sortedData, sortConfig, isSmallScreen, requestSort, columns }) {
  const theme = useTheme();
  const { openBookInWrite } = useOpenBookInWrite(props);
  const { openBookInFormat } = useOpenBookInFormat(props);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }


  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }


  return (
    <TableBody sx={{ position: 'sticky', top: 0, zIndex: 0 }}>
      {stableSort(sortedData, getComparator(sortConfig)).map((boxset) => (
        <TableRow key={boxset.id} sx={{ zIndex: 1 }}>
          {columns.map((column) => {

            return <TableCell>

              {column.data(boxset)}
            </TableCell>

          })}
          {/* <TableCell>
            <StandardImageViewer
              s3Path={boxset.book.coverUrl}
              bucketName={config.user_uploads_bucket_name}
            />
          </TableCell>
          <TableCell
            align="left"
            onClick={() => requestSort('title')}
            className={getClassNamesFor('title')}
          >
            {boxset.book.title}
          </TableCell>
          <TableCell align="center">
            <StandardRoundedAction
              icon={
                <Feather
                  sx={{
                    color: theme.palette.standardActions.icon.color.main,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    openBookInWrite({
                      bookDraftId: boxset.book.primaryBookDraftId,
                    });
                  }}
                />
              }
            />
          </TableCell>
          {!isSmallScreen && (
            <TableCell align="center">
              <StandardRoundedAction
                icon={
                  <FormatPaintIcon
                    sx={{
                      color: theme.palette.standardActions.icon.color.main,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      openBookInFormat({
                        bookDraftId: boxset.book.primaryBookDraftId,
                      });
                    }}
                  />
                }
              />
            </TableCell>
          )}
          <TableCell
            align="center"
            onClick={() => requestSort('createdAt')}
            className={getClassNamesFor('createdAt')}
          >
            {boxset.createdAt != null ? (
              <ReactTimeAgo date={boxset.createdAt} locale="en-US" />
            ) : (
              ''
            )}
          </TableCell> */}
        </TableRow>
      ))}
    </TableBody>
  );
}

function TableBoilerPlate(props) {
  const [orderBy, setOrderBy] = useState('createdAt');
  const [order, setOrder] = useState('asc');
  const { data, error, loading, columns } = props;

  const handleSortChange = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = useMemo(() => {
    if (!data) return [];

    const comparator = (a, b) => {
      const orderValue = order === 'asc' ? 1 : -1;
      let result = 0;

      columns.forEach((column) => {

        if (orderBy === column.title) {
          result = column.sortData(a).localeCompare(column.sortData(b));
        }
      });

      // switch (orderBy) {
      //   case 'itle':
      //     result = a.book.title.localeCompare(b.book.title);
      //     break;
      //   case 'createdAt':
      //     //result = a.createdAt - b.createdAt;
      //     result = a.createdAt.localeCompare(b.createdAt);
      //     break;
      //   default:
      //     break;
      // }

      return result * orderValue;
    };

    return data.slice().sort(comparator);
  }, [data, orderBy, order, error, loading]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :</p>;

  return (
    <>
      {/* <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>

        <FilterWrapper />
        <StandardButton variant="contained">Create</StandardButton>
      </Box> */}
      <TableContainer
        component={Box}
        sx={{
          height: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': { width: '3px' },
        }}
      >
        <Table aria-label="simple table">
          <TableHeader columns={columns} isSmallScreen={isSmallScreen} orderBy={orderBy} order={order} onSortChange={handleSortChange} />
          <TableBodyContent columns={columns} props={props} sortedData={sortedData} isSmallScreen={isSmallScreen} />
        </Table>
      </TableContainer>
    </>
  );
}

export default inject('stores')(observer(TableBoilerPlate));
