const uuid = require('uuid');


export default {

	// This method is created for cross-browser compatibility, if you don't
	// need to support IE11, you can use Array.prototype.sort() directly
	stableSort: function (array, order, orderBy) {

		let comparator = this.getComparator(order, orderBy)
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	},
	mutationSort: function (array, order, orderBy) {

		let comparator = this.getComparator(order, orderBy)
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {

			let variables_a = JSON.parse(a[0].variables);
			let variables_b = JSON.parse(b[0].variables);

			const order = comparator(variables_a.input.itemExpectedVersion, variables_b.input.itemExpectedVersion);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	},

	descendingComparator: function (a, b, orderBy) {
		try {
			if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
				return -1;
			}
			if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
				return 1;
			}

		} catch (err) {
			//do nothing
		}
		return 0;
	},

	getComparator: function (order, orderBy) {
		return order === 'desc'
			? (a, b) => this.descendingComparator(a, b, orderBy)
			: (a, b) => -this.descendingComparator(a, b, orderBy);
	},




	toLocaleDateTime: function (timestamp) {

		try {
			let d = new Date(timestamp);

			if (d instanceof Date && !isNaN(d)) {
				return d.toLocaleString();
			} else {
				return '';
			}
		} catch (e) {
			return '';
		}

	},

	createUserImageUploadS3Key: function (userId, fileName) {

		let imageId = uuid.v1() + '_' + fileName;
		let s3Key = 'uploads/' + userId + '/images/' + imageId;
		//console.log(s3Key);

		return s3Key;

	},

	createUserBookImportUploadS3Key: function (userId, fileName) {

		let fileId = uuid.v1() + '_' + fileName;
		let s3Key = 'uploads/' + userId + '/importBookDraftFiles/' + fileId;
		//console.log(s3Key);

		return s3Key;

	},

	countWords: function (text) {


		let trimmedText = text.trim();
		// Splitting empty text returns a non-empty array
		return trimmedText.length > 0 ? trimmedText.split(/\s+/).length : 0;

	},
	getFlattenedOps: function (deltas) {



		let flattenedOps = deltas.filter(f=>f.ops.length>0).map((delta) => {

			let text = '';
			delta.ops.forEach((op) => {
				if (op.insert && !(op.insert instanceof Object)) {

					text += op.insert;
				}
			})

			//let cleanedText = text.replace(/[.!?,;:]/g, "");
			let cleanedText = text.replace(/[^\w\s]/g, '')
			
			return {
				createdAt: delta.createdAt,
				text: cleanedText,
				words:cleanedText.split(/\s+/)
				//words:cleanedText.split(/\s+/).filter(f=>f!='')
			}

		})

		let flattenedAndMergedOps = [];
		for (let i = 0; i < flattenedOps.length; i++) {

			let flattenedOp = flattenedOps[i];
			//let words = flattenedOp.text.split(/\s+/);
			let words = flattenedOp.words;
			let lastWord = words[words.length - 1];
			//console.log(lastWord);
			let isLastFlattenedOp = (i==flattenedOps.length-1)?true:false;
			if (lastWord != '' && !isLastFlattenedOp) {
				//means that the last word was not a space. Could mean we have part of a word. 
				//If the first char of the next word is not a space this is a partial word.

				let nextFlattenedOp = flattenedOps[i + 1];
				//let nextWords = nextFlattenedOp.text.split(/\s+/);
				let nextWords = nextFlattenedOp.words;
				let nextWord = nextWords[0];
				//console.log(nextWord);

				if (nextWord != '') {
					//means that we have two parts of a split word.
					let combinedWord = lastWord+nextWord;
					//console.log(combinedWord);

					//Update the two flattenedOps. Remove the last word from the current flattenedOp
					//and update the first word of the next flattenedOp
					words = words.filter(f=>f!='');
					flattenedAndMergedOps.push({
						createdAt:flattenedOp.createdAt,
						numWords:words.length-1,
						words:words.splice(0,words.length-1)
					});

					//Now update the nextWords and convert them back into a string 
					nextFlattenedOp.words[0] = combinedWord;
				}else{
					flattenedAndMergedOps.push({
						createdAt:flattenedOp.createdAt,
						numWords:words.length,
						words:words
					});
				}
			}else{
				//The last 'word' is a space. So keep all but the space. 
				words = words.filter(f=>f!='');
				flattenedAndMergedOps.push({
					createdAt:flattenedOp.createdAt,
					numWords:words.length,
					words:words
				});

				// flattenedAndMergedOps.push({
				// 	createdAt:flattenedOp.createdAt,
				// 	numWords:words.length-1,
				// 	words:words.splice(0,words.length-1)
				// });
			}
			// if(i<flattenedOps.length-1){

			// 	let nextFlattenedOp = flattenedOps[i+1];
			// 	let nextWords = nextFlattenedOp.text.split(/\s+/);
			// 	let nextWord = nextWords[0];
			// 	console.log(nextWord);
			// 	let lastWordLastChar = lastWord.charAt(lastWord.length-1);
			// 	let nextWordFirstChar = nextWord.charAt(0);
			// 	if(lastWordLastChar!=' ' && nextWordFirstChar!=' '){
			// 		//combine these words
			// 		let combinedWord = lastWord+nextWord;
			// 		console.log(combinedWord);

			// 		//remove the last word of 'words'
			// 		flattenedAndMergedOps.push({
			// 			createdAt:flattenedOp.createdAt,
			// 			numWords:words.length-1,
			// 			words:words.slice(0, words.length-1)
			// 		})

			// 		//replace the text of the first of the 'next' words
			// 		//nextWords[0].text = combinedWord;
			// 		flattenedAndMergedOps.push({
			// 			createdAt:nextFlattenedOp.createdAt,
			// 			numWords:nextWords.length,
			// 			words:nextWords.slice(0, nextWords.length-1)
			// 		})

			// 		i++;

			// 	}else{
			// 		flattenedAndMergedOps.push({
			// 			createdAt:flattenedOp.createdAt,
			// 			numWords:words.length,
			// 			words:words
			// 		})
			// 	}

			// }

			//console.log(words);


		}

		//console.log(flattenedAndMergedOps);
		flattenedAndMergedOps = flattenedAndMergedOps.filter(f=>f.numWords>0);
		return flattenedAndMergedOps;
	}



}

