import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import StandardTabs from "../StandardTabs";
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton, { StandardListButton } from "../StandardButton";
import BookSharedWithList from "../lists/BookSharedWithList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../managers/BooksManager';
import UserGroupsList from "../lists/UserGroupsList";
import { useManagerCreateEditRequest } from '../../managers/EditRequestsManager';
import StandardOkCancelDialog from "./StandardOkCancelDialog";
import ShareBookPanel from "./share/ShareBookPanel";
import ContributorsList from "../lists/ContributorsList";
import AddBoxsetContributorPanel from "./AddBoxsetContributorPanel";
import ContributedBooksList from "../lists/ContributedBooksList";
import AddBoxsetBookPanel from "./AddBoxsetBookPanel";



const ContributedBooksPanel = (props) => {

  let user = props.stores.userStore.user;
  let { book } = props;

  let [showAddBookDialog, setShowAddBookDialog] = useState(false);
    

  
  

  return (
    <Grid container direction={'column'}>
      <Grid item>
        <StandardListButton
          variant="outlined"
          onClick={() => {
            setShowAddBookDialog(true)
          }}>Contribute Book</StandardListButton>

      </Grid>
      <Grid item>
        <ContributedBooksList
          book={book}
          showSelectIcon
          onSelected={(userConnection) => {

            // if (userConnection) {
            //   createEditRequest({
            //     book,
            //     userId: user.id,
            //     editorUserId: userConnection.connectedUser.id
            //   })
            // }

          }}
        />

        <StandardOkCancelDialog
          open={showAddBookDialog}
          onClose={() => {
            setShowAddBookDialog(false)
          }}
          onOk={() => {
            setShowAddBookDialog(false)

          }}
          title={'Contribute Book'}
          content={
            <AddBoxsetBookPanel boxset={book?.boxSet} />
          }
        />
      </Grid>
    </Grid>
  )
}

export default inject('stores')(observer(ContributedBooksPanel));