import React, { useEffect, useState } from 'react';
import { observable } from 'mobx';
import config from '../config';

import {
  useListUserConnections,
  useGetUserUserGroups,
  useCreateInvitation,
  useAcceptInvitation,
  useAcceptGroupInvitation,
  useListUserGroupUsers,
  useListUserGroupBookShares,
  useGetUserInvitedToUserGroups,
  useCreateUserGroupUser,
  useCreateUserGroup,
  useShareBookWithUserGroup,
  useListConnectedUserBookShares,
  useLeaveGroup
} from '../persistance/persistanceHooks';

import { Book, BookDraft, Recipe, User } from '../domain/bookModel';
import { useNavigate, useLocation } from "react-router-dom";

export function useManagerListConnectedUserBookShares(props, connectedUserId) {

  const [sharedBooks, setSharedBooks] = useState([]);

  const { loading: listConnectedUserBookSharesLoading, error: listConnectedUserBookSharesError, data } = useListConnectedUserBookShares({
    userId: connectedUserId
});




  useEffect(() => {

    try {

      if (data?.listConnectedUserBookShares) {

        let sharedBookPojos = data?.listConnectedUserBookShares.items.map((bookUserGroupShare) => {

          let { book } = bookUserGroupShare;

          return book;

        });

        setSharedBooks(sharedBookPojos);


      }


    } catch (err) {
      console.log(err);
    }

  }, [data]);

  return { sharedBooks, loading: listConnectedUserBookSharesLoading, error: listConnectedUserBookSharesError };
}
























export function useManagerGetUserGroupBookShares(props, groupId) {

  const [sharedBooks, setSharedBooks] = useState([]);

  const { loading: listUserGroupBookSharesLoading, error: listUserGroupBookSharesError, data: listUserGroupBookSharesData, refetch: listUserGroupBookSharesRefetch } = useListUserGroupBookShares({
    userGroupId: groupId
  });




  useEffect(() => {

    try {

      if (listUserGroupBookSharesData && listUserGroupBookSharesData.listUserGroupBookShares) {

        let sharedBookPojos = listUserGroupBookSharesData.listUserGroupBookShares.items.map((bookUserGroupShare) => {

          let { sharedBook } = bookUserGroupShare;

          return sharedBook;

        });

        setSharedBooks(sharedBookPojos);


      }


    } catch (err) {
      console.log(err);
    }

  }, [listUserGroupBookSharesData]);

  return { sharedBooks, loading: listUserGroupBookSharesLoading, error: listUserGroupBookSharesError, refetch: listUserGroupBookSharesRefetch };
}


export function useManagerGetUserGroupUsers(props, groupId) {

  const [users, setUsers] = useState([]);

  const { loading: listUserGroupUsersLoading, error: listUserGroupUsersError, data: listUserGroupUsersData, refetch: listUserGroupUsersRefetch } = useListUserGroupUsers({
    id: groupId
  });


  useEffect(() => {


    try {

      if (listUserGroupUsersData && listUserGroupUsersData.listUserGroupUsers) {

        let userPojos = listUserGroupUsersData.listUserGroupUsers.items.map((userGroupUser) => {

          let { user } = userGroupUser;

          return new User({ data: user })
        });

        setUsers(userPojos);


      }


    } catch (err) {
      console.log(err);
    }

  }, [listUserGroupUsersData]);

  return { users, loading: listUserGroupUsersLoading, error: listUserGroupUsersError };
}


export function useGetUserConnections(props) {

  const [userConnections, setUserConnections] = useState([]);

  const { loading: listUserConnectionsLoading, error: listUserConnectionsError, data: listUserConnectionsData, refetch: listUserConnectionsRefetch } = useListUserConnections();


  useEffect(() => {


    try {

      if (listUserConnectionsData?.listUserConnections?.items) {

        let userConnectionPojos = listUserConnectionsData?.listUserConnections?.items.map((userConnection) => {

          //console.log(userConnection);
          return userConnection;
        })

        setUserConnections(userConnectionPojos);

        //let filteredConnectionItems = listUserConnectionsData?.listUserConnections?.items.filter(f => f.connectedUser.name.includes(searchString) || searchString == null);

        // let connectionItems = filteredConnectionItems.map((m, index) => {

        //   if (props.autoSelect) {

        //     if (props.selectedUser == null && index == 0) {
        //       props.onConnectionSelected(m.connectedUser);
        //     }

        //   }

        // });

      }


    } catch (err) {
      console.log(err);
    }

  }, [listUserConnectionsData]);

  return { userConnections, error: listUserConnectionsError };
}





export function useGetUserInvitedToGroups(props) {

  const [userGroups, setUserGroups] = useState([])

  const { loading: getUserInvitedToUserGroupsLoading, error: getUserInvitedToUserGroupsError, data: getUserInvitedToUserGroupsData, refetch: getUserInvitedToUserGroupsRefetch } = useGetUserInvitedToUserGroups();


  useEffect(() => {


    try {

      if (getUserInvitedToUserGroupsData) {

        setUserGroups(getUserInvitedToUserGroupsData.getUser.invitedToUserGroupUsers.items.map((userGroupUser) => {

          //return userGroupUser.userGroup;
          return userGroupUser;
        }));




      }


    } catch (err) {
      console.log(err);
    }

  }, [getUserInvitedToUserGroupsData]);

  return { userGroups, loading: getUserInvitedToUserGroupsLoading, error: getUserInvitedToUserGroupsError, refetch: getUserInvitedToUserGroupsRefetch};
}


export function useGetUserGroups(props) {

  const [userGroups, setUserGroups] = useState([])

  const { loading: getUserUserGroupsLoading, error: getUserUserGroupsError, data: getUserUserGroupsData, refetch: getUserUserGroupsRefetch } = useGetUserUserGroups();


  useEffect(() => {


    try {

      if (getUserUserGroupsData) {

        setUserGroups(getUserUserGroupsData.getUser.userGroups.items.map((userGroup) => {

          return userGroup;
        }));




      }


    } catch (err) {
      console.log(err);
    }

  }, [getUserUserGroupsData]);

  return { userGroups, error: getUserUserGroupsError, loading: getUserUserGroupsLoading };
}






export function useManagerCreateInvitation(props) {


  const [createInvitation, { data: createInvitationData, loading: createInvitationLoading, error: createInvitationError }] = useCreateInvitation();


  const wrapper = ({
    label,
    emailAddress
  }, callback) => {
    try {
      createInvitation({
        label: label,
        emailAddress: emailAddress
      });

    } catch (err) {
      console.log(err);
    }

  }

  return {
    createInvitation: wrapper,
    data: createInvitationData,
    loading: createInvitationLoading,
    error: createInvitationError
  };
}


export function useManagerAcceptInvitation(props) {


  let [userConnection, setUserConnection] = useState();
  const [acceptInvitation, { data: acceptInvitationData, loading: acceptInvitationLoading, error: acceptInvitationError }] = useAcceptInvitation();

  let navigate = useNavigate();
  let location = useLocation();


  useEffect(() => {

    //The following code should only run one time, immediately after the user logs in after clicking the link 
    //in an email invitation.

    //Check to see if this user registered with a pending invitation 
    if (acceptInvitationData) {


      //console.log(acceptInvitationData);

      if (acceptInvitationData.acceptInvitationBatch) {



        try {
          //console.log(acceptInvitationError);

          //let connectedUserPojo = new User({data:acceptInvitationData.acceptInvitationBatch.connectedUser});
          let newUserConnection = acceptInvitationData.acceptInvitationBatch;
          setUserConnection(newUserConnection);


          props.stores.bookStore.connectionsListConnection = newUserConnection;
          navigate("/connections/details");
          // props.stores.bookStore.snackbarMessage = 'Now connected with ' + newUserConnection?.connectedUser.name;
          // props.stores.bookStore.snackbarMessageSeverity = 'success';
          // props.stores.bookStore.showSnackbar = true;


        } catch (err) {
          //do nothing
        }




      }


      //http://localhost:3000/connections/details/sharedTo






    }

  }, [acceptInvitationData]);


  useEffect(() => {

    //The following code should only run one time, immediately after the user logs in after clicking the link 
    //in an email invitation.

    //Check to see if this user registered with a pending invitation 
    if (acceptInvitationError) {

      let mappedErrorMessage = config.errorMessagesMappings[acceptInvitationError.message];

      try {
        //console.log(acceptInvitationError);
        // props.stores.bookStore.snackbarMessage = mappedErrorMessage ? mappedErrorMessage : acceptInvitationError.message;
        // props.stores.bookStore.snackbarMessageSeverity = 'error';
        // props.stores.bookStore.showSnackbar = true;
        navigate("/connections/list");
      } catch (err) {
        //do nothing
      }


    }

  }, [acceptInvitationError]);





  const wrapper = ({
    invitationCode
  }, callback) => {
    try {
      acceptInvitation({
        invitationCode,
        onCompleted: () => {
          //console.log('completed...')

        }
      });

    } catch (err) {
      console.log(err);
    }

  }

  return {
    acceptInvitation: wrapper,
    userConnection,
    loading: acceptInvitationLoading,
    error: acceptInvitationError
  };
}




export function useManagerCreateUserGroupUser(props) {


  const [createUserGroupUser, { data: createUserGroupUserData, loading: createUserGroupUserLoading, error: createUserGroupUserError }] = useCreateUserGroupUser();
    

  const wrapper = ({
    userGroupId,
    userId
  }, callback) => {
    try {
      createUserGroupUser({
        userGroupId:userGroupId,
        userId:userId
      });

    } catch (err) {
      console.log(err);
    }

  }

  return {
    createUserGroupUser: wrapper,
    loading: createUserGroupUserLoading, 
    error: createUserGroupUserError
  };
}

export function useManagerCreateUserGroup(props) {


  const [createUserGroup, { data: createUserGroupData, loading: createUserGroupLoading, error: createUserGroupError }] = useCreateUserGroup();
      

  const wrapper = ({
    name,
    description
  }, callback) => {
    try {
      createUserGroup({
        name: name,
        description: description
    },
    ()=>{

      //onSuccess
      if(callback){
        callback();
      }
    },
    ()=>{

      //onError
      
    });

    } catch (err) {
      console.log(err);
    }

  }

  return {
    createUserGroup: wrapper,
    loading: createUserGroupLoading, 
    error: createUserGroupError
  };
}




export function useManagerShareBookWithUserGroup(props) {


  const [shareBookWithUserGroup, { data: shareBookWithUserGroupData, loading: shareBookWithUserGroupLoading, error: shareBookWithUserGroupError }] = useShareBookWithUserGroup();
    

  const wrapper = ({
    bookId,
    userGroupId
  }, callback) => {
    try {
      shareBookWithUserGroup({
        bookId: bookId,
        userGroupId: userGroupId
    },
    ()=>{

      //onSuccess
      if(callback){
        callback();
      }
    },
    ()=>{

      //onError
      
    });

    } catch (err) {
      console.log(err);
    }

  }

  return {
    shareBookWithUserGroup: wrapper,
    loading: shareBookWithUserGroupLoading, 
    error: shareBookWithUserGroupError
  };
}





export function useManagerAcceptGroupInvitation(props) {


  const [acceptGroupInvitation, { data: acceptGroupInvitationData, loading: acceptGroupInvitationLoading, error: acceptGroupInvitationError }] = useAcceptGroupInvitation();



  const wrapper = ({
    userGroupId
  }, callback) => {
    try {
      acceptGroupInvitation({
        userGroupId,
        onCompleted: () => {
          //console.log('completed...')

        }
      });

    } catch (err) {
      console.log(err);
    }

  }

  return {
    acceptGroupInvitation: wrapper,
    loading: acceptGroupInvitationLoading,
    error: acceptGroupInvitationError
  };
}

export function useManagerLeaveGroup(props) {

  const [leaveGroup, { data: leaveGroupData, loading: leaveGroupLoading, error: leaveGroupError }] = useLeaveGroup();

  const wrapper = ({
    userGroupId
  }, callback) => {
    try {
      leaveGroup({
        userGroupId,
        onCompleted: () => {
          //console.log('completed...')
          if(callback){
            callback();
          }
        }
      });

    } catch (err) {
      console.log(err);
    }

  }

  return {
    leaveGroup: wrapper,
    loading: leaveGroupLoading,
    error: leaveGroupError
  };
}


export function useOpenUserInConnections(props) {


  let navigate = useNavigate();
  let location = useLocation();

  const openUserInConnections = ({
    user
  }) => {

    let userConnection = {
      connectedUser:user
    };

    props.stores.bookStore.connectionsListConnection = userConnection;
    navigate("/connections/details");

  }


  return {
    openUserInConnections
  }


}
