import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import StandardTabs from '../StandardTabs';
import { Tab, Grid } from '@mui/material';
import { TabPanel } from '@mui/lab';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TabContext from '@mui/lab/TabContext';
import TemplateEditor from './TemplateEditor';
function TemplatesEditor(props) {

    const [value, setValue] = React.useState(0);

    let { editingTemplateIds } = props.stores.bookStore;
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    return (
        <TabContext>


            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <StandardTabs variant={'scrollable'} value={value} onChange={handleChange} aria-label="basic tabs example">
                        {editingTemplateIds.map((m) => {
                            return <Tab
                                icon={<HighlightOffIcon
                                    onClick={() => {
                                        props.stores.bookStore.removeEditTemplateId(m);
                                    }} />}
                                iconPosition={'end'}
                                label={m.title}

                            />



                            // <span style={{
                            //     display: 'flex',
                            //     justifyContent: 'center',
                            //     alignItems: 'top'
                            // }}
                            //     onClick={() => {
                            //         props.stores.bookStore.removeEditTemplateId(m);
                            //     }} > {m.title}</span>

                        })
                        }

                    </StandardTabs>

                </Box>



            </Box>

            {editingTemplateIds.map((m, index) => {
               
               //console.log(m);
                    if(value == index){
                        return <TemplateEditor templateId={m.templateId} templateBddp={m.templateBddp} />
                    }
                   
              

                // return <TabPanel value={index}  >
                //     <div>{value}:{index}</div>
                //     {/* <TemplateEditor templateBddp={m.templateBddp} /> */}
                // </TabPanel>


            })
            }
        </TabContext>


    );
}

export default inject('stores')(observer(TemplatesEditor));
