import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import Quill from 'quill';
import StarIcon from '@mui/icons-material/Star';
import { useLazyListAllBookDraftUserComments, useListAllBookDraftUserComments, useLazyListBookDraftUserComments } from '../../persistance/persistanceHooks';
import { UserComment as UserCommentPojo } from '../../domain/bookModel';
import { Box, Paper } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import UserCommentInput from './UserCommentInput';
import UserComment from './UserComment';
import ToolbarItem from './toolbar/ToolbarItem';
import Scrollbars from 'react-custom-scrollbars-2';
import { showAllComments } from '../../managers/BooksManager';
import { useTheme } from '@mui/material/styles';

var $ = require("jquery");

const DocumentPartUserComments = (props) => {

  let { showConnectionIcon = false, showBookInfo, onShowComments = () => { }, onHideComments = () => { } } = props;
  let { showComments } = props.stores.bookStore;
  let [userCommentItems, setUserCommentItems] = useState([]);

  const theme = useTheme();



  const [listBookDraftUserComments, { loading: listBookDraftUserCommentsLoading, error: listBookDraftUserCommentsError, data: listBookDraftUserCommentsData, refetch: listBookDraftUserCommentsRefetch }] = useLazyListAllBookDraftUserComments();


  const [listUserBookDraftUserComments, { loading: listUserBookDraftUserCommentsLoading, error: listUserBookDraftUserCommentsError, data: listUserBookDraftUserCommentsData, refetch: listUserBookDraftUserCommentsRefetch }] = useLazyListBookDraftUserComments();
  // const { loading: listBookDraftUserCommentsLoading, error: listBookDraftUserCommentsError, data: listBookDraftUserCommentsData, refetch: listBookDraftUserCommentsRefetch } = useListAllBookDraftUserComments({
  //   bookDraftId: props.book.bookDraft.id
  // });



  const createUserCommentItem = (data, documentPart) => {

    let { viewFavoritedComments, viewPinnedComments, viewCompletedComments, viewPendingComments } = props.stores.bookStore;


    let filteredComments = data.items;

    filteredComments = filteredComments.filter((f) => {

      if (viewFavoritedComments || viewPinnedComments || viewCompletedComments || viewPendingComments) {

        if (viewFavoritedComments && f.isFavorite) return f;
        if (viewPinnedComments && f.isPinned) return f;
        if (viewCompletedComments && f.completedAt) return f;
        if (viewPendingComments && !f.completedAt) return f;

      }
      else {
        return f;
      }


    });




    let userCommentItems = filteredComments.filter(f => f.deletedAt == null).filter(f => f.documentPartId == documentPart.id).map((userComment) => {


      // return <UserComment ownerMode={props.ownerMode} userComment={userComment} onUpdated={() => {}} />
      return userComment;

    });


    documentPart.bookDraftDocumentPart.getSortedChildren().forEach((bddp) => {
      userCommentItems = userCommentItems.concat(createUserCommentItem(data, bddp.documentPart));
    })


    return userCommentItems;

  }

  useEffect(() => {

    if (props.ownerMode) {

      listBookDraftUserComments({
        variables: {
          bookDraftId: props.book.bookDraft.id
        }

      })
    } else {

      listUserBookDraftUserComments({
        variables: {
          bookDraftId: props.book.bookDraft.id
        }

      })


    }

  }, []);

  useEffect(() => {


    //console.log(listBookDraftUserCommentsData);
    if (listBookDraftUserCommentsData && props.documentPart) {

      try {
        props.stores.bookStore.userComments.replace(listBookDraftUserCommentsData.listAllBookDraftUserComments.items.map((commentData) => {

          let partQuillInstance = props.stores.bookStore.getQuillInstanceByPartId(commentData.documentPartId);
          return new UserCommentPojo({ data: commentData })
        }));

      } catch (err) {
        console.log(err);
      }

      let commentItems = createUserCommentItem(listBookDraftUserCommentsData.listAllBookDraftUserComments, props.documentPart);



      let filteredPinnedOnly = commentItems.filter(f => f.isPinned);
      let filteredNoPinned = commentItems.filter(f => !f.isPinned);

      commentItems = filteredPinnedOnly.concat(filteredNoPinned);
      setUserCommentItems(commentItems.map((userComment) => {






        return <UserComment ownerMode={props.ownerMode} showBookInfo={props.showBookInfo} userComment={userComment} onUpdated={() => { }} />
      }))

      //showAllComments(props, commentItems);
      // commentItems.forEach((userComment)=>{

      //   let partQuillInstances = props.stores.bookStore.getQuillInstancesByPartId(userComment.documentPartId);

      //   partQuillInstances.forEach((quill)=>{
      //     quill.setSelection(userComment.partIndex , userComment.partLength , Quill.sources.SILENT);
      //     let formatDelta1 = quill.format('commentBlot', { uuid: userComment.id }, Quill.sources.SILENT);
      //     quill.setSelection(0 , 0, Quill.sources.SILENT);
      //   })

      //   $('.tooltip-controls').hide();
      //   $('#sidebar-controls').hide();
      //   $('#sidebar-controls').removeClass('active'); 


      // })



    }
  }, [
    listBookDraftUserCommentsData,
    showComments,
    props.documentPart,
    props.stores.bookStore.viewFavoritedComments,
    props.stores.bookStore.viewPinnedComments,
    props.stores.bookStore.viewCompletedComments,
    props.stores.bookStore.viewPendingComments,
  ]);



  useEffect(() => {

    //userComments={userComments.filter(f => f.documentPartId == bookDraftDocumentPart.documentPart.id)}


    //console.log(listUserBookDraftUserCommentsData);
    if (listUserBookDraftUserCommentsData && props.documentPart) {

      //setUserComments(listBookDraftUserCommentsData.listAllBookDraftUserComments.items);
      //props.stores.bookStore.userComments = listBookDraftUserCommentsData.listAllBookDraftUserComments.items
      //props.stores.bookStore.setUserComments(listBookDraftUserCommentsData.listAllBookDraftUserComments.items);


      //setUserCommentItems(createUserCommentItem(listUserBookDraftUserCommentsData.listBookDraftUserComments,props.documentPart))
      let commentItems = createUserCommentItem(listUserBookDraftUserCommentsData.listBookDraftUserComments, props.documentPart);


      let filteredPinnedOnly = commentItems.filter(f => f.isPinned);
      let filteredNoPinned = commentItems.filter(f => !f.isPinned);

      commentItems = filteredPinnedOnly.concat(filteredNoPinned);

      showAllComments(props, commentItems);
      setUserCommentItems(commentItems.map((userComment) => {


        return <UserComment showBookInfo={props.showBookInfo} ownerMode={props.ownerMode} userComment={userComment} onUpdated={() => { }} />
      }))









    }
  }, [
    listUserBookDraftUserCommentsData,
    props.documentPart]);

  const fontSize = 'small';


  

  const showCommentsIcon = <ToolbarItem
  icon={<VisibilityOutlinedIcon
    fontSize={fontSize}
    sx={{
      color: props.stores.bookStore.showCommentsInEditor ? theme.palette.secondary.main : 'disabled'
    }}
    //color={props.stores.bookStore.viewPinnedComments ? theme.palette.secondary.main : 'disabled'} 
    onClick={(e) => {
      e.preventDefault();
      props.stores.bookStore.showCommentsInEditor = !props.stores.bookStore.showCommentsInEditor
    }} />} />;

  const pushPinIcon = <ToolbarItem
    icon={<PushPinIcon
      fontSize={fontSize}
      sx={{
        color: props.stores.bookStore.viewPinnedComments ? theme.palette.secondary.main : 'disabled'
      }}
      //color={props.stores.bookStore.viewPinnedComments ? theme.palette.secondary.main : 'disabled'} 
      onClick={(e) => {
        e.preventDefault();
        props.stores.bookStore.viewPinnedComments = !props.stores.bookStore.viewPinnedComments
      }} />} />;

  const starIcon = <ToolbarItem
    icon={<StarIcon
      fontSize={fontSize}
      sx={{
        color: props.stores.bookStore.viewFavoritedComments ? theme.palette.secondary.main : 'disabled'
      }}
      //color={props.stores.bookStore.viewFavoritedComments ? theme.palette.secondary.main : 'disabled'} 
      onClick={(e) => {
        e.preventDefault();
        props.stores.bookStore.viewFavoritedComments = !props.stores.bookStore.viewFavoritedComments
      }} />} />;

  const completedIcon = <ToolbarItem
    icon={<CheckCircleOutlineIcon
      fontSize={fontSize}
      sx={{
        color: props.stores.bookStore.viewCompletedComments ? theme.palette.secondary.main : 'disabled'
      }}
      //color={props.stores.bookStore.viewCompletedComments ? theme.palette.secondary.main : 'disabled'}
      onClick={(e) => {
        e.preventDefault();

        if (props.stores.bookStore.viewCompletedComments) {
          props.stores.bookStore.viewCompletedComments = false;
        }

        else if (!props.stores.bookStore.viewCompletedComments) {
          props.stores.bookStore.viewCompletedComments = true;
          props.stores.bookStore.viewPendingComments = false;
        }


      }} />} />;

  const pendingIcon = <ToolbarItem 
  icon={<PendingOutlinedIcon 
    fontSize={fontSize} 
    
    sx={{
      color: props.stores.bookStore.viewPendingComments ? theme.palette.secondary.main : 'disabled'
    }}
    //color={props.stores.bookStore.viewPendingComments ? theme.palette.secondary.main : 'disabled'} 
    onClick={(e) => {
    e.preventDefault();
    if (props.stores.bookStore.viewPendingComments) {
      props.stores.bookStore.viewPendingComments = false;
    }

    else if (!props.stores.bookStore.viewPendingComments) {
      props.stores.bookStore.viewPendingComments = true;
      props.stores.bookStore.viewCompletedComments = false;
    }
  }} />} />;


  return (
    <div style={{
      height: '100%'
    }}>

      {/* <button onClick={() => {
        //showAllComments(props, commentItems);
      }}>
        RENDER
      </button> */}

      {props.ownerMode &&
        <div focusable='false' tabIndex={-1} id='master-toolbar' style={{
          'width': '100%',
          marginBottom: '0em'
        }}>

          <Box focusable='false' tabIndex={-1} className='toolbar-wrapper' sx={{
            display: 'flex',
            justifyContent: 'space-around',
            'flex-direction': 'row',
            'flex-wrap': 'wrap',
            'alignItems': 'center',
            width: '100%',
            borderx: '1px solid gray',
            padding: '0em 0em .5em 0em'
          }}>
            {showCommentsIcon}
            {pushPinIcon}
            {starIcon}
            {completedIcon}
            {pendingIcon}

          </Box>


        </div>
      }



      {userCommentItems}





      {/* <UserCommentInput documentPart={props.documentPart} /> */}

    </div>
  );
}

export default inject('stores')(observer(DocumentPartUserComments));
