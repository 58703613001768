import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import config from '../../aws-exports';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const CancelTableCell = (props) => {

  let { bucketName, s3Src, title } = props;
  const theme = useTheme();

  let profilePicSrc = 'https://' + bucketName + ".s3.amazonaws.com/" + s3Src;


  return (

    <IconButton aria-label="delete" {...props} >
      <RemoveCircleOutlineIcon sx={{ color: theme.palette.background.main }} />
    </IconButton>

  )
}
export default inject('stores')(observer(CancelTableCell));