import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import AppWrapper from '../AppWrapper';
import ListBoilerPlate from './ListBoilerPlate';
import TableBoilerPlate from './TableBoilerPlate';
import FilterWrapper from './FilterWrapper';
import StandardButton from '../StandardButton';

const PageBoilerPlate = (props) => {

  const isSmallScreen = useMediaQuery('(max-width:600px)');


  return (
    <>
      <AppWrapper main={<Box sx={{
        display: 'flex',
        width: '100%',
        height: '100%'
        // flexDirection: 'row',
        // gap:2
      }}>
       
        {isSmallScreen ? <ListBoilerPlate /> : <TableBoilerPlate />}



      </Box>

      } />
    </>


  )
}
export default PageBoilerPlate;
// export default inject('stores')(observer(Books));