import React, { useEffect, useState } from 'react';
import { Box, styled } from "@mui/material";
import { inject, observer } from 'mobx-react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import BaseControl from "../BaseControl";
import { useTheme } from '@mui/material/styles';
import StandardRadioButtons from "../../../StandardRadioButtons";
import SelfOverrideSwitch from './SelfOverrideSwitch';
import { RecipeUtils } from '../../RecipeUtils';

function PropsRadioButtons(props) {

  const theme = useTheme();
  let baseControl = new BaseControl(props);

  const { classes, element, bookDraft, documentPart } = props;




  let isVisible = baseControl.isVisible();
  // if(this.props.stores.bookStore.disabledFormatProps[element.recipeParamPath]!=null){
  //   isVisible =  false;
  // }
  if (!isVisible) {
    return <div style={{
      height: '0em'
    }}></div>
  }

  let item = documentPart;
  let properties_book = bookDraft.book;
  let boxSet = bookDraft.book.boxSet;

  let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();
  let hideBookProperties = false;

  let canEdit = true;


  let isOwner = true;


  if (element.paramItem == 'BOOK') {
    item = bookDraft.book;
  }
  if (element.paramItem == 'BOXSET_MAIN_BOOK') {
    item = boxSet.book;
  }
  if (element.paramItem == 'BOXSET_BOOK') {
    item = boxSetBook.book;
  }
  if (element.paramItem == 'BOXSETBOOK') {
    item = boxSetBook;
  }

  //console.log('gonna call it...')
  //let value = baseControl.getValue();
  let currentRecipe = props.documentPart?.getRecipe().json;
  let recipeParamPath = element.recipeParamPath;
  let recipeParamDefaultValue = element.recipeParamDefaultValue;
  
  let value = new RecipeUtils().getRecipeParamByPartType(null, currentRecipe, documentPart, recipeParamPath, recipeParamDefaultValue);


  let options = element.options.map((option) => {

    let label = option.label.replace(/---PART_TYPE---/gi, baseControl.toCappedPartType(documentPart));
    label = label.replace(/---PART_TYPE_LOWER---/gi, documentPart.partType.toLowerCase());

    return (

      {
        label: label,
        value: option.value
      }


      // <StandardRadioButtons title={'Top Margin'} options={[
      //   { label: 'Small', value: 'small' },
      //   { label: 'Medium', value: 'Medium' },
      //   { label: 'Large', value: 'Large' }
      // ]} />




      // <div className='radio-button-wrapper' >



      //   <Radio
      //     checked={value === option.value}
      //     onChange={(e) => {
      //       e.stopPropagation();
      //       baseControl.handleChange({
      //         value: option.value
      //       });
      //     }}
      //     value={option.value}
      //     name="radio-button-demo"
      //     color={'primary'}
      //     inputProps={{ 'aria-label': 'Image' }}
      //     disabled={false} />
      //   <div className='property-element-item-label'>{label}</div>


      // </div>
    )
  })


  return (

    <>
      {/* <div>value:{value}</div> */}
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      }}>
        <StandardRadioButtons {...props}
          options={options}
          value={value}
          onChange={(e) => {
            let newValue = e.target.value;

            new RecipeUtils().setRecipeParamByPartType(null, currentRecipe, documentPart, recipeParamPath, newValue);
            baseControl.updateRecipeProp(newValue);
            //props.stores.bookStore.recipeTimestamp = new Date().getTime()

            // baseControl.updateValue({
            //   value: newValue
            // });
          }} />


        <SelfOverrideSwitch
          element={element}
          bookDraft={bookDraft}
          documentPart={documentPart}
          onUpdateRecipe={props.onUpdateRecipe} />

      </Box>
    </>


  );
}


export default inject('stores')(observer(PropsRadioButtons));



