import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import StandardTabs from "../StandardTabs";
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton, { StandardListButton } from "../StandardButton";
import BookSharedWithList from "../lists/BookSharedWithList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../managers/BooksManager';
import UserGroupsList from "../lists/UserGroupsList";
import { useManagerCreateEditRequest } from '../../managers/EditRequestsManager';
import StandardOkCancelDialog from "./StandardOkCancelDialog";
import ShareBookPanel from "./share/ShareBookPanel";
import ContributorsList from "../lists/ContributorsList";
import AddBoxsetContributorPanel from "./AddBoxsetContributorPanel";



const ContributorsPanel = (props) => {

  let user = props.stores.userStore.user;
  let { book, hideAddContributor } = props;

  let [showAddContributorDialog, setShowAddContributorDialog] = useState(false);

  const { createEditRequest, loading: createEditRequestLoading, error: createEditRequestError } = useManagerCreateEditRequest(props);

  let [selectedTab, setSelectedTab] = useState(0);


  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Grid container direction={'column'}>
      <Grid item>
        {!hideAddContributor &&
          <StandardListButton
            variant="outlined"
            onClick={() => {
              setShowAddContributorDialog(true)
            }}>Add Contributor</StandardListButton>
        }

      </Grid>
      <Grid item>
        <ContributorsList
          book={book}
          showSelectIcon
          onSelected={(userConnection) => {

            // if (userConnection) {
            //   createEditRequest({
            //     book,
            //     userId: user.id,
            //     editorUserId: userConnection.connectedUser.id
            //   })
            // }

          }}
        />

        <StandardOkCancelDialog
          open={showAddContributorDialog}
          onClose={() => {
            setShowAddContributorDialog(false)
          }}
          onOk={() => {
            setShowAddContributorDialog(false)

          }}
          title={'Add Contributor'}
          content={
            <AddBoxsetContributorPanel boxset={book?.boxSet} />
          }
        />
      </Grid>
    </Grid>
  )
}

export default inject('stores')(observer(ContributorsPanel));