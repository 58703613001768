import React, { useState, useEffect } from "react";
import { Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import config from '../../../aws-exports';
import { useGetOwnedBoxsets } from '../../../managers/BoxsetsManager';
import { useGetOwnedBooks, useOpenBookInWrite, useOpenBookInFormat, useManagerCreateBook } from '../../../managers/BooksManager';
import ReactTimeAgo from 'react-time-ago';
import EmptyContent from "../../EmptyContent";
import Avatar from '@mui/material/Avatar';
import Feather from 'mdi-material-ui/Feather';
import IconButton from '@mui/material/IconButton';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import helpers from '../../../helpers/helpers';


const ListOwnedBoxsets = (props) => {

    let { book, boxset, selectedBoxset, onSelectBoxset, showWriteIcon = true, showFormatIcon = true, s3Src, order='desc', orderBy='createdAt' } = props;
    const { ownedBoxsets, loading, error } = useGetOwnedBoxsets(props);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { openBookInWrite } = useOpenBookInWrite(props);
    const { openBookInFormat } = useOpenBookInFormat(props);


    useEffect(() => {


        // setOrder("desc");
        // setOrderBy("createdAt");

    }, [ownedBoxsets]);





    const getBoxsetContributedBooks = (boxset) => {
        let allContributedBoxSetBooks = {};
        boxset.boxsetContributors?.map((boxsetContributor) => {

            boxsetContributor.contributedBoxsetBooks.map((bsb) => {
                allContributedBoxSetBooks[bsb.book.id] = bsb;
            });

        });

        return Object.values(allContributedBoxSetBooks);
    }

    const generateSecondaryActions = (boxset) => {

        const secondaryActions = [];

        if (showWriteIcon) {
            secondaryActions.push(<IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    //console.log(boxset)
                    
                    openBookInWrite({
                      bookDraftId: boxset.book.primaryBookDraftId
                    });
                }}>
                <Feather />
            </IconButton>)
        }

        if (showFormatIcon) {
            secondaryActions.push(<IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    openBookInFormat({
                      bookDraftId: boxset.book.primaryBookDraftId
                    });
                }}>
                <FormatPaintIcon />
            </IconButton>)
        }

        return secondaryActions;
    }


    let sortedBoxsets = [];
    if (ownedBoxsets != null) {

        sortedBoxsets = ownedBoxsets.map((boxset) => {

            let compositeOwnedBoxset = {
                object: boxset,
                title: boxset.book.title,
                createdAt: boxset.createdAt

            }


            return compositeOwnedBoxset;
        });
        sortedBoxsets = helpers.stableSort(sortedBoxsets, order, orderBy, props.stores.bookStore.sortOrder, props.stores.bookStore.sortOrderBy === 'alpha' ? 'title' : props.stores.bookStore.sortOrderBy);

    }


    return (
        <div>
            <List>
                {ownedBoxsets && ownedBoxsets.length > 0 ?
                    sortedBoxsets.map((compositeOwnedBoxset, index) => {


                        let boxset = compositeOwnedBoxset.object;



                        //console.log(index);
                        let selectedIndex = null;
                        if (boxset.id == selectedBoxset?.id) {
                            selectedIndex = index;
                        }
                        return <ListItem
                        selected={selectedIndex===index}
                            secondaryAction={
                                generateSecondaryActions(boxset)
                            }
                        > <ListItemAvatar>
                                <Avatar variant="rounded"
                                    bucketName={config.user_uploads_bucket_name}
                                    s3Src={boxset.book?.coverUrl}
                                >
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={boxset.book?.title}
                                secondary={boxset.createdAt != null ? <ReactTimeAgo date={boxset.createdAt} locale="en-US" /> : ''}
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelectBoxset(boxset);
                                }}
                            />
                        </ListItem>


                    }

                    )
                    :
                    <EmptyContent tagline={'Create your first boxset!'} />
                }
            </List>
        </div>
    )
}

export default inject('stores')(observer(ListOwnedBoxsets));