import React, { useState, useEffect } from "react";
import { Grid, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import config from '../../../aws-exports';
import ReactTimeAgo from 'react-time-ago';
import EmptyContent from "../../EmptyContent";
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import StandardUIDialog from "../../UI/StandardUIDialog";
import StandardUIButton from "../../UI/StandardUIButton";
import {
    useManagerAddBoxsetContributor,
    useManagerAddBoxsetContributedBook,
    useManagerLoadBoxSetContributors,
    useManagerRemoveBoxSetContributedBook
  } from '../../../managers/BoxsetsManager';


const ListBoxsetContributedBooks = (props) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let [boxsetBooks, setBoxsetBooks] = useState([]);
    let [errors, setErrors] = useState([]);

    let { book, boxset, showRemoveIcon = true } = props;

    const { removeBoxSetContributedBook, loading: removeBoxSetContributedBookLoading, error: removeBoxSetContributedBookError } = useManagerRemoveBoxSetContributedBook(props);

    
    const [showRemoveContributedBook, setShowRemoveContributedBook] = useState(false);

    const handleOpenRemoveContributedBook = () => {
        setShowRemoveContributedBook(true);
    }

    const handleCloseRemoveContributedBook = () => {
        setShowRemoveContributedBook(false);
    }

    let {
        boxsetContributors,
        loading: lazyLoadBoxSetContributorsLoading,
        error: lazyLoadBoxSetContributorsError,
        refetch: lazyLoadBoxSetContributorsRefetch
    } = useManagerLoadBoxSetContributors(props, boxset?.id);

    //console.log(boxsetContributors);


    useEffect(() => {

        try {

            let bookData = {};
            boxsetContributors.forEach((boxsetContributor) => {

                boxsetContributor.contributedBooks.items.forEach((bsb) => {

                    bookData[bsb.book.id] = ({
                        boxsetContributor,
                        boxsetBook: bsb
                    })
                })

            });

            setBoxsetBooks(Object.values(bookData));

        } catch (err) {
            console.log(err);
        }
    }, [boxsetContributors]);



    const generateSecondaryActions = (bsbData) => {

        const secondaryActions = [];

        if (showRemoveIcon) {
            secondaryActions.push(<IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    setShowRemoveContributedBook(true);
                }}>
                <RemoveCircleOutlineIcon />
            </IconButton>)
        }

        return secondaryActions;
    }



    return (
        <div>
            <List>
                {boxsetBooks && boxsetBooks.length > 0 ?
                    boxsetBooks.map((bsbData) => {


                        return <ListItem
                            secondaryAction={
                                generateSecondaryActions(bsbData)
                            }
                        > <ListItemAvatar>
                                <Avatar variant="rounded"
                                    // bucketName={config.user_uploads_bucket_name}
                                    s3Src={bsbData.boxsetBook.book.coverUrl}
                                >
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={bsbData.boxsetBook.book.title}
                                secondary={bsbData.boxsetContributor.contributor.name}
                            />
                        </ListItem>


                    }

                    )
                    :
                    <EmptyContent tagline={'Contributed books will appear here!'} />
                }
                <StandardUIDialog
                    title='Remove Contributed Book'
                    open={showRemoveContributedBook}
                    onClose={handleCloseRemoveContributedBook}
                    dialogContent={
                        <Typography>
                            Are you sure you want to remove this contributed book?
                        </Typography>
                    }
                    cardActions={
                        <StandardUIButton
                            buttonAction='Remove'
                            onClick={() => {
                                setErrors([])
                                removeBoxSetContributedBook({
                                  bookId: book.id,
                                  boxsetId: boxset.id,
                                }, 
                                () => {
                                  if (props.onRemoved) {
                                    props.onRemoved()
                                  }
                                }, 
                                (errors) => {
                                  setErrors(errors);
                                })
                              }}
                        />
                    }
                />
            </List>
        </div>
    )
}

export default inject('stores')(observer(ListBoxsetContributedBooks));