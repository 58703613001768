import React, { useState, useEffect } from "react";
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetOwnedBooks, useOpenBookInWrite } from '../../managers/BooksManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import SquareAvatar from "./SquareAvatar";
import DeleteIcon from '@mui/icons-material/Delete';
import StandardRoundedAction from "../actions/StandardRoundedAction";
import Feather from "mdi-material-ui/Feather";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import TablePopupMenu from "../menus/TablePopupMenu";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CommentIcon from '@mui/icons-material/Comment';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import CancelTableCell from "../table/CancelTableCell";
import { useManagerListConnectedUserBookShares } from '../../managers/ConnectionsManager';
import StandardList from "./StandardList";

let missingBookImage = require('../../assets/images/image-not-found.png')

const ConnectionSharedFromList = (props) => {

    let { userConnection } = props;

    const { sharedBooks, loading: listConnectedUserBookSharesLoading, error: listConnectedUserBookSharesError } = useManagerListConnectedUserBookShares(props, userConnection?.connectionUserId);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');



    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();




    const createSecondaryActions = (sharedBooks) => {

        let secondaryActions = [];

        if (props.showSelectIcon) {
            secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
                //console.log(sharedBooks)
                props.onSelected(sharedBooks);
            }}>
                <ShareIcon />
            </IconButton>);
        }

        return secondaryActions;
    }

    return (
        <StandardList
            title='Shared From'
            component="div"
            disablePadding>
            {
                [].map((book) => {

                    return <>

                        <ListItem
                            alignItems="flex-start"

                            secondaryAction={



                                <Box sx={{
                                    display: 'flex'
                                }}>
                                    <CancelTableCell onClick={(e) => {
                                        e.preventDefault();

                                        // if (bookShare.type == 'Group') {
                                        //     deleteBookUserGroupShare({
                                        //         bookId: book.id,
                                        //         userGroupId: bookShare.id
                                        //     });
                                        // }
                                        // if (bookShare.type == 'User') {
                                        //     deleteBookUserShare({
                                        //         bookId: book.id,
                                        //         sharedWithUserId: bookShare.id
                                        //     });
                                        // }



                                    }} />
                                </Box>







                            }>
                            <ListItemAvatar
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelected(book);
                                }}>
                                <SquareAvatar
                                    //bucketName={config.user_uploads_bucket_name}
                                    s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + book?.coverUrl}
                                    missingContent={<img src={missingBookImage} />}
                                />
                                {/* <Avatar alt="Remy Sharp" variant="square" src="https://local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm.s3.amazonaws.com/uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/c7cb5750-3627-11ed-914f-5d91b6c4c7eb_planetStrider.png">

              </Avatar> */}
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    noWrap: true
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelected(book);
                                }}
                                primary={book?.title}
                                secondary={
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            The Book's Subtitle
                                        </Typography>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="caption"
                                            color="text.secondary"
                                        >
                                            Shared Ago
                                        </Typography>
                                        {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                    </Box>
                                }

                            />

                        </ListItem>
                        <Divider variant="inset"/>
                    </>
                    // <Divider variant="inset"/>


                    // return <ListItem secondaryAction={createSecondaryActions(book)}>
                    //   <ListItemAvatar>
                    //     <AvatarTableCell
                    //       bucketName={config.user_uploads_bucket_name}
                    //       s3Src={book?.coverUrl}
                    //     //title={userConnection?.connectedUser?.name}
                    //     />
                    //   </ListItemAvatar>
                    //   <ListItemText primary={book?.title} secondary="" />

                    // </ListItem>
                })
            }
            {/* {ownedBooks.length}:
      {availableBooks.length} */}
        </StandardList>

    )
}

export default inject('stores')(observer(ConnectionSharedFromList));