import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import StretchBox from '../StretchBox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StandardEntityProfileImageSelector from '../../image/StandardEntityProfileImageSelector';

const Profile = (props) => {

  let user = props.stores.userStore.user;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


  return (

  
    <StretchBox flexDirection='column'>

      <StretchBox flexDirection='column'>

      
          Profile pagexxx
          <StandardEntityProfileImageSelector
			title={'Profile Picture'} 
			entity={user} 
			imageKeyFieldName={'profilePicUrl'}/>
       
       Banned user list here somewhere
      </StretchBox>


    
    </StretchBox>
 


  )
}

export default inject('stores')(observer(Profile));