import React, { useState, useEffect } from "react";
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetOwnedBooks, useOpenBookInWrite } from '../../managers/BooksManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import CircleAvatar from "./CircleAvatar";
import DeleteIcon from '@mui/icons-material/Delete';
import StandardRoundedAction from "../actions/StandardRoundedAction";
import Feather from "mdi-material-ui/Feather";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import TablePopupMenu from "../menus/TablePopupMenu";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CommentIcon from '@mui/icons-material/Comment';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { useGetUserConnections, useGetUserInvitedToGroups, useManagerAcceptGroupInvitation, useManagerLeaveGroup } from '../../managers/ConnectionsManager';
import EmptyContent from "../EmptyContent";
import helpers from '../../helpers/helpers';
import ReactTimeAgo from 'react-time-ago';
import StandardList from "./StandardList";

let missingBookImage = require('../../assets/images/image-not-found.png')

const SharedGroupsList = (props) => {

    const { userGroups, loading: getUserInvitedToUserGroupsLoading, error: getUserInvitedToUserGroupsError, getUserInvitedToUserGroupsRefetch } = useGetUserInvitedToGroups(props);
    const { acceptGroupInvitation, loading: acceptGroupInvitationLoading, error: acceptGroupInvitationError } = useManagerAcceptGroupInvitation(props);
    const { leaveGroup, loading: leaveGroupLoading, error: leaveGroupError } = useManagerLeaveGroup(props);
  
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');
    const [showLeaveGroup, setShowLeaveGroup] = React.useState(false);
    const [selectedUserGroupUser, setSelectedUserGroupUser] = React.useState(null);
  
  
  
  
    const isXs = useMediaQuery({ maxWidth: 599 })
    const isSm = useMediaQuery({ maxWidth: 600 })
    const isMd = useMediaQuery({ maxWidth: 900 })
    const isLg = useMediaQuery({ maxWidth: 1200 })
    const isXl = useMediaQuery({ minWidth: 1536 })
  
  
    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();
  
    useEffect(() => {
  
  
      setOrder("desc");
      setOrderBy("createdAt");
  
    }, [userGroups]);




  const createSecondaryActions = (userGroupUser) => {

    let secondaryActions = [];

    if (props.showSelectIcon) {
      secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
        //console.log(userGroupUser)
        props.onSelected(userGroupUser);
      }}>
        <ShareIcon />
      </IconButton>);
    }

    return secondaryActions;
  }

  // userGroups = helpers.stableSort(userGroups, props.stores.bookStore.sortOrder, props.stores.bookStore.sortOrderBy==='alpha'?'name':props.stores.bookStore.sortOrderBy);


  let sortedUserGroups = [];
  if(userGroups!=null){

    sortedUserGroups = userGroups.map((userGroupUser)=>{

      let compositeUserGroup = {
        object:userGroupUser,
        name:userGroupUser.userGroup?.name,
        createdAt:userGroupUser.acceptedAt!=null?userGroupUser.acceptedAt:userGroupUser.invitedAt
        
      }
      

      return compositeUserGroup;
    });
    sortedUserGroups = helpers.stableSort(sortedUserGroups, props.stores.bookStore.sortOrder, props.stores.bookStore.sortOrderBy==='alpha'?'name':props.stores.bookStore.sortOrderBy);

  }

  return (
    <StandardList
      // title={'My Books'}
      hideHeading={true}
      // headerActions={<CreateExportsAssembly book={book} />}
      component="div" disablePadding>
      {userGroups && userGroups.length > 0 ?
        sortedUserGroups.map((compositeUserGroup) => {

          let userGroupUser = compositeUserGroup.object;

          return <>

            <ListItem
              alignItems="flex-start"

              secondaryAction={



                <Box sx={{
                  display: 'flex'
                }}>
                  


                </Box>







              }>
              <ListItemAvatar
                onClick={(e) => {
                  e.preventDefault();
                  props.onSelected(userGroupUser);
                }}>
                <CircleAvatar
                  //bucketName={config.user_uploads_bucket_name}
                  s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + userGroupUser.userGroup?.profilePicUrl}
                  missingContent={<img src={missingBookImage} />}
                />
                {/* <Avatar alt="Remy Sharp" variant="square" src="https://local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm.s3.amazonaws.com/uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/c7cb5750-3627-11ed-914f-5d91b6c4c7eb_planetStrider.png">

              </Avatar> */}
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  noWrap:true
                }}
                onClick={(e) => {
                  e.preventDefault();
                  props.onSelected(userGroupUser);
                }}
                primary={userGroupUser.userGroup?.name}
                secondary={
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {userGroupUser.userGroup?.description}
                    </Typography>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="caption"
                      color="text.secondary"
                    >
                      {compositeUserGroup?.createdAt != null ? <ReactTimeAgo date={compositeUserGroup?.createdAt} locale="en-US" /> : ''}
                    </Typography>
                    {/* {" — I'll be in your neighborhood doing errands this…"} */}
                  </Box>
                }

              />

            </ListItem>
            <Divider variant="inset"/>
          </>
          // <Divider variant="inset"/>


          // return <ListItem secondaryAction={createSecondaryActions(book)}>
          //   <ListItemAvatar>
          //     <AvatarTableCell
          //       bucketName={config.user_uploads_bucket_name}
          //       s3Src={book?.coverUrl}
          //     //title={userConnection?.connectedUser?.name}
          //     />
          //   </ListItemAvatar>
          //   <ListItemText primary={book?.title} secondary="" />

          // </ListItem>
        })
        :
        <Box sx={{
          marginTop: '3em'
        }}>
          <EmptyContent tagline={'Groups you have been invited to will appear here!'} />
        </Box>
        
      }
      {/* {ownedBooks.length}:
      {availableBooks.length} */}
    </StandardList>

  )
}

export default inject('stores')(observer(SharedGroupsList));