import Quill from 'quill';
//import Parchment from 'parchment';
import DeltaToDom  from './DeltaToDom';
//import Popper from 'popper.js';
const Parchment = Quill.import('parchment');
var $ = require("jquery");

let BlockEmbed = Quill.import('blots/block/embed');
let Base = Quill.import('blots/embed');
let Inline = Quill.import('blots/inline');

function createContent(node){
  	
  	let converter = new DeltaToDom();
	/*let html = converter.convertBlurb(args);
  	node.innerHTML = html;*/

  	let args = {};
  	let domNode = converter.convertDecoratedBreak(args,node);
  	node.appendChild(domNode);
    
    
    



  }

export default class DecoratedBreakBlot2 extends BlockEmbed {
  static create(args) {
    let node = super.create();
    
		node.setAttribute('contenteditable', 'false');
    // Allow twitter library to modify our contents
    //twttr.widgets.createTweet(id, node);
    createContent(node);

    return node;
  }

  static value(domNode) {
  	let value = {
  		
  	};
  	//value.id = domNode.dataset.id;
  	return value;
  }


  /*deleteAt(index,length) {

    //let textBlot = Parchment.create('text', 'Test');
    //textBlot.insertAt(index, 'ough');
    return false;
  }*/

  
}
DecoratedBreakBlot2.blotName = 'decoratedBreak';
DecoratedBreakBlot2.tagName = 'div';
DecoratedBreakBlot2.className = 'decoratedBreakWrapper';