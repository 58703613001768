import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import config from '../../aws-exports';
import useMediaQuery from '@mui/material/useMediaQuery';
import StretchBox from '../boilerPlate/StretchBox';
import { Box, Button, SwipeableDrawer, Typography } from '@mui/material';
import ScrollBox from '../boilerPlate/ScrollBox';
import StandardUICard from '../UI/StandardUICard';
import ListOwnedBooks from './lists/ListOwnedBooks';
import StandardUICirclePlus from '../UI/StandardUICirclePlus';
import CreateBook from './dialogs/CreateBook';
import OwnedBookDetailCard from './listCards/OwnedBookDetailCard';
import OwnedBookSnapshotCard from './listCards/OwnedBookSnapshotCard';
import OwnedBookBoxsetsCard from './listCards/OwnedBookBoxsetsCard';
import OwnedBookSharedCard from './listCards/OwnedBookSharedCard';
import OwnedBookEditCard from './listCards/OwnedBookEditCard';
import OwnedBookExportCard from './listCards/OwnedBookExportCard';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import ShareIcon from '@mui/icons-material/Share';
import Link from '@mui/material/Link';
import BookDetails from './dialogs/BookDetails';
import CreateSnapshot from './dialogs/CreateSnapshot';
import OwnedBookShareBook from './dialogs/OwnedBookShareBook';
import SendEditRequest from './dialogs/SendEditRequest';
import ExportBook from './dialogs/ExportBook';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { useGetOwnedBooks, useOpenBookInWrite, useOpenBookInFormat, useManagerCreateBook } from '../../managers/BooksManager';



const OwnedBooks = (props) => {

    let { book, bookDraftId } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { openBookInWrite } = useOpenBookInWrite(props);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdAt');

    const [showCreateBook, setShowCreateBook] = useState(false);
    const [showMoreDetail, setShowMoreDetail] = useState(false);
    const [showCreateSnapshot, setShowCreateSnapshot] = useState(false);
    const [showShareBook, setShowShareBook] = useState(false);
    const [showSendEditRequest, setShowSendEditRequest] = useState(false);
    const [showExportBook, setShowExportBook] = useState(false);

    const handleOpenCreateBook = () => {
        setShowCreateBook(true);
    }

    const handleCloseCreateBook = () => {
        setShowCreateBook(false);
    }

    const handleMoreDetailOpen = () => {
        setShowMoreDetail(true);
    }

    const handleMoreDetailClose = () => {
        setShowMoreDetail(false);
    }

    const handleCreateSnapshotOpen = () => {
        setShowCreateSnapshot(true);
    }

    const handleCreateSnapshotClose = () => {
        setShowCreateSnapshot(false);
    }

    const handleShareBookOpen = () => {
        setShowShareBook(true);
    }

    const handleShareBookClose = () => {
        setShowShareBook(false);
    }

    const handleSendEditRequestOpen = () => {
        setShowSendEditRequest(true);
    }

    const handleSendEditRequestClose = () => {
        setShowSendEditRequest(false);
    }

    const handleExportBookOpen = () => {
        setShowExportBook(true);
    }

    const handleExportBookClose = () => {
        setShowExportBook(false);
    }



    //const [selectedIndex, setSelectedIndex] = useState(0);
    const [showDetailDrawer, setShowDetailDrawer] = useState(false);
    let [selectedBook, setSelectedBook] = useState(null);

    // const handleListItemClick = (event, index) => {
    //     setSelectedIndex(index);
    // };
    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setShowDetailDrawer(open);
            };


    return (

        <StretchBox flexDirection='row' sxProps={{ gap: isSmallScreen ? 0 : 2 }}>

            <StretchBox sxProps={{
                flex: isSmallScreen ? 12 : 4,
                position: 'relative'
            }}>
                <ScrollBox>
                    {isSmallScreen ?
                        <StandardUICard
                            sx={{ minHeight: '100vh' }}
                            title='My Books'
                            showPagination={false}
                            showSearchField={true}
                            onSetOrdering={(orderBy, order) => { setOrder(order); setOrderBy(orderBy) }}
                            children={
                                <ListOwnedBooks
                                    selectedBook={selectedBook}
                                    order={order}
                                    orderBy={orderBy}
                                    onListSelectBook={(book) => {
                                        setSelectedBook(book)
                                        setShowDetailDrawer(true)
                                    }}
                                    s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + book?.coverUrl}
                                    showFormatIcon={false}
                                />
                            }
                        />
                        :
                        <StandardUICard
                            title='My Books'
                            showPagination={false}
                            showSearchField={true}
                            onSetOrdering={(orderBy, order) => { setOrder(order); setOrderBy(orderBy) }}
                            children={
                                <ListOwnedBooks
                                    selectedBook={selectedBook}
                                    order={order}
                                    orderBy={orderBy}
                                    onListSelectBook={(book) => {
                                        setSelectedBook(book)
                                        setShowDetailDrawer(true)
                                    }}
                                    s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + book?.coverUrl}

                                />
                            }
                        />
                    }


                </ScrollBox>
                <StandardUICirclePlus
                    onClick={handleOpenCreateBook}
                />

            </StretchBox>


            
            {/* {book && book.length == 0 ? */}
                <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                    <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>


                        <StandardUICard
                            title='Details'
                            showPagination={false}
                            showSearchField={false}
                            // actions={
                            //     <Link underline='none' color='blue'
                            //         onClick={handleMoreDetailOpen}>
                            //         More
                            //     </Link>
                            // }
                            children={
                                <OwnedBookDetailCard
                                    book={selectedBook}
                                />
                            }
                        />
                        <StandardUICard
                            title='Snapshots'
                            showPagination={true}
                            showSearchField={true}
                            actions={
                                <Fab color="primary" size='small'
                                    onClick={handleCreateSnapshotOpen}>
                                    <AddIcon />
                                </Fab>
                            }
                            children={
                                <OwnedBookSnapshotCard book={selectedBook} />
                            }
                        />
                        <StandardUICard
                            title='Boxsets'
                            showPagination={true}
                            showSearchField={false}
                            children={
                                <OwnedBookBoxsetsCard book={selectedBook} />
                            }
                        />
                        <StandardUICard
                            title='Shared With'
                            showPagination={true}
                            showSearchField={true}
                            actions={
                                <Fab color="primary" size='small'
                                    onClick={handleShareBookOpen}>
                                    <ShareIcon />
                                </Fab>
                            }
                            children={
                                <OwnedBookSharedCard book={selectedBook} />
                            }
                        />
                        <StandardUICard
                            title='Edits'
                            showPagination={true}
                            showSearchField={false}
                            actions={
                                <Fab color="primary" size='small'
                                    onClick={handleSendEditRequestOpen}>
                                    <AddIcon />
                                </Fab>
                            }
                            children={
                                <OwnedBookEditCard book={selectedBook} />
                            }
                        />
                        <StandardUICard
                            title='Export'
                            showPagination={true}
                            showSearchField={false}
                            actions={
                                <Fab color="primary" size='small'
                                    onClick={handleExportBookOpen}>
                                    <AddIcon />
                                </Fab>
                            }
                            children={
                                <OwnedBookExportCard book={selectedBook} />
                            }
                        />




                    </ScrollBox>
                </StretchBox>


                {/* :
                <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                    <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>

                        <div>No books in list</div>
                    </ScrollBox>
                </StretchBox>
            } */}





            <CreateBook
                open={showCreateBook}
                onClose={handleCloseCreateBook}

                onBookCreated={(book) => {
                    setShowCreateBook(false);

                    openBookInWrite({
                        bookDraftId: book.primaryBookDraftId
                    });

                }}
            />

            <BookDetails
                book={selectedBook}
                title={selectedBook?.title}
                open={showMoreDetail}
                onClose={handleMoreDetailClose}

            />

            <CreateSnapshot
                sx={{ width: '100%' }}
                book={selectedBook}
                open={showCreateSnapshot}
                onClose={handleCreateSnapshotClose} />

            <OwnedBookShareBook
                book={selectedBook}
                open={showShareBook}
                onClose={handleShareBookClose}
            />

            <SendEditRequest
                book={selectedBook}
                open={showSendEditRequest}
                onClose={handleSendEditRequestClose}
            />

            <ExportBook
                bookDraftId={selectedBook}
                open={showExportBook}
                onClose={handleExportBookClose}
            />


            <SwipeableDrawer
                anchor={'right'}
                open={showDetailDrawer && isSmallScreen}
                onClose={toggleDrawer(false)}
                sx={{ width: '100%' }}
            //variant='persistent'
            //onOpen={setShowDetailDrawer(true)}
            >
                <div style={{ width: '100vw' }}>

                    <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                        <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2, ml: 0, mr: 0 }}>
                            <IconButton
                                style={{ display: 'flex', justifyContent: 'left' }}
                                onClick={() => {
                                    setShowDetailDrawer(false)
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography
                                variant={theme.landmarkText.variant}
                                gutterBottom
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                {selectedBook?.title}
                            </Typography>

                            <StandardUICard
                                title='Details'
                                showPagination={false}
                                showSearchField={false}
                                // actions={
                                //     <Link underline='none' color='blue'
                                //         onClick={handleMoreDetailOpen}>
                                //         More
                                //     </Link>
                                // }
                                children={
                                    <OwnedBookDetailCard
                                        book={selectedBook}
                                        title={selectedBook?.title}
                                    />
                                }
                            />
                            <StandardUICard
                                title='Snapshots'
                                showPagination={true}
                                showSearchField={true}
                                actions={
                                    <Fab color="primary" size='small'
                                        onClick={handleCreateSnapshotOpen}>
                                        <AddIcon />
                                    </Fab>
                                }
                                children={
                                    <OwnedBookSnapshotCard book={selectedBook} />
                                }
                            />
                            <StandardUICard
                                title='Boxsets'
                                showPagination={true}
                                showSearchField={false}
                                children={
                                    <OwnedBookBoxsetsCard book={selectedBook} />
                                }
                            />
                            <StandardUICard
                                title='Shared With'
                                showPagination={true}
                                showSearchField={true}
                                actions={
                                    <Fab color="primary" size='small'
                                        onClick={handleShareBookOpen}>
                                        <ShareIcon />
                                    </Fab>
                                }
                                children={
                                    <OwnedBookSharedCard book={selectedBook} />
                                }
                            />
                            <StandardUICard
                                title='Edits'
                                showPagination={true}
                                showSearchField={false}
                                actions={
                                    <Fab color="primary" size='small'
                                        onClick={handleSendEditRequestOpen}>
                                        <AddIcon />
                                    </Fab>
                                }
                                children={
                                    <OwnedBookEditCard book={selectedBook} />
                                }
                            />
                            <StandardUICard
                                title='Export'
                                showPagination={true}
                                showSearchField={false}
                                actions={
                                    <Fab color="primary" size='small'
                                        onClick={handleExportBookOpen}>
                                        <AddIcon />
                                    </Fab>
                                }
                                children={
                                    <OwnedBookExportCard book={selectedBook} />
                                }
                            />


                        </ScrollBox>
                    </StretchBox>
                </div>
            </SwipeableDrawer>


        </StretchBox>
    )
}
export default inject('stores')(observer(OwnedBooks));