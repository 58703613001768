import React, { useState, useEffect } from "react";
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import SquareAvatar from "./SquareAvatar";
import DeleteIcon from '@mui/icons-material/Delete';
import StandardRoundedAction from "../actions/StandardRoundedAction";
import Feather from "mdi-material-ui/Feather";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import TablePopupMenu from "../menus/TablePopupMenu";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CommentIcon from '@mui/icons-material/Comment';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useGetSharedBooks, useOpenBookInWrite, useOpenBookInRead, useOpenBookInFormat, useManagerCreateBook } from '../../managers/BooksManager';
import { useMediaQuery } from 'react-responsive';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import EmptyContent from "../EmptyContent";
import helpers from '../../helpers/helpers';
import ReactTimeAgo from 'react-time-ago';
import StandardList from "./StandardList";

let missingBookImage = require('../../assets/images/image-not-found.png')

const SharedBooksList = (props) => {

    let { book } = props;

    const { sharedBooks, error: getSharedWithBooksError, loading: getSharedWithBooksLoading } = useGetSharedBooks(props);
    const { openBookInWrite } = useOpenBookInWrite(props);
    const { openBookInFormat } = useOpenBookInFormat(props);
    const { openBookInRead } = useOpenBookInRead(props);


    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');

    let [showCreateBookDialog, setShowCreateBookDialog] = useState(false);
    let [showDeleteBookDialog, setShowDeleteBookDialog] = useState(false);
    let [selectedBook, setSelectedBook] = useState(null);

    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    const isRoute = () => {
        return 'primary';
    }


    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        //console.log(sharedBooks);
        setOrder("desc");
        setOrderBy("createdAt");

    }, [sharedBooks]);



    // sharedBooks = helpers.stableSort(sharedBooks, props.stores.bookStore.sortOrder, props.stores.bookStore.sortOrderBy==='alpha'?'title':props.stores.bookStore.sortOrderBy);

    const createSecondaryActions = (sharedBook) => {

        let secondaryActions = [];

        if (props.showSelectIcon) {
            secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
                //console.log(sharedBook)
                props.onSelected(sharedBook);
            }}>
                <ShareIcon />
            </IconButton>);
        }

        return secondaryActions;
    }

    let sortedSharedBook = [];
    if(sharedBooks!=null){
  
      sortedSharedBook = sharedBooks.map((sharedBook)=>{
  
        let compositeSharedBook = {
          object:sharedBook,
          title:sharedBook.title,
          createdAt:sharedBook.sharedAt
          
        }
        
  
        return compositeSharedBook;
      });
      sortedSharedBook = helpers.stableSort(sortedSharedBook, props.stores.bookStore.sortOrder, props.stores.bookStore.sortOrderBy==='alpha'?'title':props.stores.bookStore.sortOrderBy);
  
    }
  

    return (
        <StandardList
          // title={'My Books'}
          hideHeading={true}
          // headerActions={<CreateExportsAssembly book={book} />}
          component="div" disablePadding>
            {sharedBooks && sharedBooks.length > 0 ?
                sortedSharedBook.map((compositeSharedBook) => {

                  let sharedBook = compositeSharedBook.object;

                    return <>

                        <ListItem
                            alignItems="flex-start"

                            secondaryAction={



                                <Box sx={{
                                    display: 'flex'
                                }}>
                                    <StandardRoundedAction
                                        icon={<LocalLibraryIcon sx={{
                                            color: theme.palette.standardActions.icon.color.main,

                                        }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                //console.log(sharedBook)
                                                openBookInRead({
                                                    bookDraftId: sharedBook.primaryBookDraftId
                                                });
                                            }} />} />

                                </Box>



                            }>
                            <ListItemAvatar
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelected(sharedBook);
                                }}>
                                <SquareAvatar
                                    //bucketName={config.user_uploads_bucket_name}
                                    s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + book?.coverUrl}
                                    missingContent={<img src={missingBookImage} />}
                                />
                                {/* <Avatar alt="Remy Sharp" variant="square" src="https://local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm.s3.amazonaws.com/uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/c7cb5750-3627-11ed-914f-5d91b6c4c7eb_planetStrider.png">

              </Avatar> */}
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    noWrap: true
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelected(book);
                                }}
                                primary={sharedBook?.title}
                                secondary={
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {sharedBook?.owner?.name}
                                        </Typography>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="caption"
                                            color="text.secondary"
                                        >
                                            {sharedBook?.sharedAt != null ? <ReactTimeAgo date={sharedBook?.sharedAt} locale="en-US" /> : ''}
                                        </Typography>
                                        {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                    </Box>
                                }

                            />

                        </ListItem>
                        <Divider variant="inset"/>
                    </>
                    // <Divider variant="inset"/>


                    // return <ListItem secondaryAction={createSecondaryActions(book)}>
                    //   <ListItemAvatar>
                    //     <AvatarTableCell
                    //       bucketName={config.user_uploads_bucket_name}
                    //       s3Src={book?.coverUrl}
                    //     //title={userConnection?.connectedUser?.name}
                    //     />
                    //   </ListItemAvatar>
                    //   <ListItemText primary={book?.title} secondary="" />

                    // </ListItem>
                })
                :
                <Box sx={{
                  marginTop: '3em'
                }}>
                  <EmptyContent tagline={'Books shared with you will appear here!'} />
                </Box>
              
            }
            {/* {ownedBooks.length}:
      {availableBooks.length} */}
        </StandardList>

    )
}

export default inject('stores')(observer(SharedBooksList));