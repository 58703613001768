import { styled } from "@mui/material";
import { Paper } from "@mui/material";

export const StandardPaper = styled(Paper)(()=>({

    //backgroundColor:'red'
    


}))

export default function StandardDashboardPaper(props) {
   
  
    return (
     
        <StandardPaper elevation={2} variant='elevation' {...props}>
            {props.children}
        </StandardPaper>
    );
  }
