import { useEffect, useState } from "react";
import { inject, observer } from 'mobx-react';
import { Box, styled } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { useGetBookDraftBuildRequests } from "../../managers/ExportsManager";
import StandardDataTable from "../table/StandardDataTable";
import ScrollingGridContainer from "../ScrollingGridContainer";
import ScrollingGridItem from "../ScrollingGridItem";
import { Avatar } from "@mui/material";
import ReactTimeAgo from 'react-time-ago'
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import StandardCheckbox from "../StandardCheckbox";
import StandardButton from "../StandardButton";
import GeneratedDocsTable from "../table/GeneratedDocsTable";
import ImageTitleTableCell from "../table/ImageTitleTableCell";
import config from '../../aws-exports';
import ExportDialog from "../panels/ExportDialog";
import StandardOkCancelDialog from "../panels/StandardOkCancelDialog";
import { useMediaQuery } from 'react-responsive';
import CreateExportsAssembly from "../assemblies/CreateExportsAssembly";


function ExportsPanel(props) {

    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    let [buildTypes, setBuildTypes] = useState({});
    let { bookDraftId } = props;
    let book = props.stores.bookStore.bookListBook;

    const { buildRequests, loading, error, client } = useGetBookDraftBuildRequests(props, bookDraftId);
    let [showExportDialog, setShowExportDialog] = useState(false);

    const CREATE_BOOK = gql` mutation RequestbuildBatch(
        $bookDraftId: String!,
        $buildTypes: [String]!
    ) {
        requestbuildBatch(input: {
            bookDraftId: $bookDraftId, 
            buildTypes: $buildTypes
        }) {
            id
            ownerId
            buildTypes
            completedAt
            createdAt

        }
    }`;


    const [
        requestbuildBatch,
        { data: mutationData, loading: mutationLoading, error: mutationError }] =
        useMutation(CREATE_BOOK);



    const GET_PRESIGNED_URL = gql`
    query getPresignedUrl($key: String!) {
      getPresignedUrl(key: $key)
              }
    `;


    useEffect(() => {

        //console.log(buildRequests)

    }, [buildRequests]);

    const theme = useTheme();

    return (

        <Box sx={{
            height: '100%'
        }}>


            <Box sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                {/* <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-around'
                }}>


                    <StandardCheckbox label={'Generic'} checked={buildTypes.epub} onChange={(e) => {

                        let newValues = buildTypes;
                        buildTypes.epub = e.target.checked;
                        setBuildTypes(buildTypes);
                        console.log(buildTypes);
                    }} />
                    <StandardCheckbox label={'Kindle'} checked={buildTypes.kindle} onChange={(e) => {

                        let newValues = buildTypes;
                        buildTypes.kindle = e.target.checked;
                        setBuildTypes(buildTypes);
                        console.log(buildTypes);
                    }} />
                    <StandardCheckbox label={'Google'} checked={buildTypes.googlePlay} onChange={(e) => {

                        let newValues = buildTypes;
                        buildTypes.googlePlay = e.target.checked;
                        setBuildTypes(buildTypes);
                        console.log(buildTypes);
                    }} />
                    <StandardCheckbox label={'iBooks'} checked={buildTypes.iBooks} onChange={(e) => {

                        let newValues = buildTypes;
                        buildTypes.iBooks = e.target.checked;
                        setBuildTypes(buildTypes);
                        console.log(buildTypes);
                    }} />
                </Box>


                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-around'
                }}>



                    <StandardCheckbox label={'Kobo'} checked={buildTypes.kobo} onChange={(e) => {

                        let newValues = buildTypes;
                        buildTypes.kobo = e.target.checked;
                        setBuildTypes(buildTypes);
                        console.log(buildTypes);
                    }} />
                    <StandardCheckbox label={'Nook'} checked={buildTypes.nook} onChange={(e) => {

                        let newValues = buildTypes;
                        buildTypes.nook = e.target.checked;
                        setBuildTypes(buildTypes);
                        console.log(buildTypes);
                    }} />
                    <StandardCheckbox disabled label={'Print'} checked={buildTypes.print} onChange={(e) => {

                        let newValues = buildTypes;
                        buildTypes.print = e.target.checked;
                        setBuildTypes(buildTypes);
                        console.log(buildTypes);
                    }} />
                    <StandardCheckbox disabled label={'Docx'} checked={buildTypes.docx} onChange={(e) => {

                        let newValues = buildTypes;
                        buildTypes.docx = e.target.checked;
                        setBuildTypes(buildTypes);
                        console.log(buildTypes);
                    }} />
                </Box> */}


                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    {isXs &&
                        <StandardButton onClick={(e) => {


                            e.preventDefault();
                            setShowExportDialog(true)

                            // let actualBuildTypes = [];
                            // Object.keys(buildTypes).forEach((key) => {

                            //     if (buildTypes[key] == true) {
                            //         actualBuildTypes.push(key);
                            //     }
                            // });

                            // console.log(actualBuildTypes);
                            // requestbuildBatch({
                            //     variables: {
                            //         bookDraftId: bookDraftId,
                            //         //buildTypes: ["epub","nook"],
                            //         buildTypes: actualBuildTypes
                            //     },
                            //     refetchQueries: ['listUserBuildRequests']

                            // });


                        }}>Export</StandardButton>
                    }
                </Box>
            </Box>





            {/* <ScrollingGridContainer container direction={'columns'}

        >
    
          <ScrollingGridItem item xs={12}> */}

            <StandardDataTable 
                title={'Exports'}
                noDataMessage={'Export your book!'}
                headerActions={<CreateExportsAssembly 
                    //book={book} 
                    bookDraftId={bookDraftId}/>
                }
                structure={{
                    dataDef: {
                        data: buildRequests,
                        rowKeyDataFieldName: 'id'
                    },
                    columns: [
                        {
                            title: '',
                            dataValueFunction: (buildRequest) => {
                                return <ImageTitleTableCell
                                    bucketName={config.user_uploads_bucket_name}
                                    title={buildRequest?.title}
                                    s3Src={buildRequest?.coverUrl}
                                // onClick={(e) => {
                                //     e.preventDefault();
                                //     console.log(buildRequest)
                                //     props.stores.bookStore.bookListBook = book;
                                //     navigate("/books/details");
                                //     // openBookInWrite({
                                //     //   bookDraftId: book.primaryBookDraftId
                                //     // });
                                // }}
                                />

                            }
                        },
                        {
                            title: 'Created',
                            dataFieldName: 'title',
                            dataValueFunction: (buildRequest) => {

                                let dateResponse = buildRequest.completedAt ? <ReactTimeAgo date={buildRequest.completedAt} locale="en-US" /> : '';
                                return dateResponse;

                            }
                        },
                        {
                            title: 'Files',
                            expandable: true,
                            exoandedRowComponent: (buildRequest) => { return <GeneratedDocsTable bookDraftId={bookDraftId} buildRequest={buildRequest} /> }
                            // dataDef: {
                            //     dataFunction: (buildRequest) => { return buildRequest.buildRequestGeneratedDocs.items },
                            //     rowKeyFunction: (generatedDoc) => { return generatedDoc.id },

                            // },
                            // expandedTitle: <span>Generated Docs</span>,
                            // columns: [
                            //     {
                            //         title: 'id',
                            //         dataValueFunction: (generatedDoc) => { return <Avatar sx={{ width: '1.5em', height: '1.5em' }} variant="square" alt="" src="https://www.adobe.com/express/create/cover/media_178ebed46ae02d6f3284c7886e9b28c5bb9046a02.jpeg?width=400&format=jpeg&optimize=medium" /> }
                            //     },
                            //     {
                            //         title: 'Filename',
                            //         dataValueFunction: (generatedDoc) => {
                            //             return <span onClick={async () => {

                            //                 let results = await client.query({
                            //                     query: GET_PRESIGNED_URL,
                            //                     variables: { key: generatedDoc.generatedDoc.fileName },
                            //                     fetchPolicy: 'network-only',

                            //                     //data: data
                            //                 });

                            //                 //console.log(results);
                            //                 //console.log(JSON.parse(results.data.getPresignedUrl).url)
                            //                 let finalUrl = null;

                            //                 try {
                            //                     finalUrl = JSON.parse(results.data.getPresignedUrl).url;
                            //                 } catch (err) {
                            //                     //console.log(err);
                            //                 }
                            //                 window.open(finalUrl, '_blank');
                            //             }} className='generated-doc-link'>{generatedDoc.generatedDoc.fileName}</span>
                            //         }
                            //     }
                            // ]
                        },


                    ]
                }} />

            <StandardOkCancelDialog
                open={showExportDialog}
                onClose={() => {
                    setShowExportDialog(false)
                }}
                onOk={(e) => {
                    e.preventDefault();

                    let actualBuildTypes = [];
                    Object.keys(buildTypes).forEach((key) => {

                        if (buildTypes[key] == true) {
                            actualBuildTypes.push(key);
                        }
                    });

                    //console.log(actualBuildTypes);
                    requestbuildBatch({
                        variables: {
                            bookDraftId: bookDraftId,
                            //buildTypes: ["epub","nook"],
                            buildTypes: actualBuildTypes
                        },
                        refetchQueries: ['listUserBuildRequests']

                    });
                    setShowExportDialog(false);
                }}
                title={'Export'}
                content={
                    <ExportDialog bookDraftId={book?.primaryBookDraftId} />

                }
            />
            {/* </ScrollingGridItem>
        </ScrollingGridContainer> */}

        </Box>
    );
}

export default inject('stores')(observer(ExportsPanel));