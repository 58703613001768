import React, { useEffect, useState } from 'react';
import { styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import BaseControl from "./BaseControl";
import { useTheme } from '@mui/material/styles';
import { Box, TextField } from "@mui/material";
import { v4 as uuid } from "uuid";


function FormatTextfield(props) {

  const [saveTime, setSaveTime] = useState(-1);
  const [lastKeyTime, setLastKeyTime] = useState(-1);

  const theme = useTheme();

  const saveDelayTime = 1000;
  let baseControl = new BaseControl(props);

  const { classes, element, bookDraft, documentPart } = props;




  let isVisible = baseControl.isVisible();
  if (!isVisible) {
    return <div style={{
      height: '0em'
    }}></div>
  }

  let item = documentPart;
  const fieldName = element.entityFieldName;

  let properties_book = bookDraft?.book;
  let boxSet = bookDraft?.book?.boxSet;

  let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();
  let hideBookProperties = false;

  let canEdit = true;


  let isOwner = true;

  try {
    if (element.paramItem == 'BOOK') {
      item = bookDraft.book;
    }
    if (element.paramItem == 'BOXSET_MAIN_BOOK') {
      item = boxSet.book;
    }
    if (element.paramItem == 'BOXSET_BOOK') {
      item = boxSetBook.book;
    }
    if (element.paramItem == 'BOXSETBOOK') {
      item = boxSetBook;
    }
  } catch (err) {
    console.log(err);
  }


  //console.log('gonna call it...')



  let title = baseControl.convertTitle(element.title, documentPart);

  const label = { inputProps: { 'aria-label': title } };

  let value = item ? item[element.entityFieldName] : null




  const handleChange = (value) => {


    let now = Date.now();
    setLastKeyTime(now);
    const fieldName = element.entityFieldName;

    let { maxLength = 200 } = props;

    if (value.length > maxLength) {
      value = value.substring(0, maxLength);
    }

    if (item[fieldName] != value) {

      item[fieldName] = value;

    }

    item.updatedTimestamp = now;

    setSaveTime(now);

    doSave();




  };


  const doSave = () => {


    // props.stores.bookStore.recipeTimestamp = new Date().getTime();

    // let newValue = item[fieldName];
    // baseControl.updateValue({
    //   value: newValue
    // });

    // return;









    


    let now = Date.now();
    let timeSinceLastKeyPress = now - lastKeyTime;

    if (saveTime == -1) {
      return;
    }
    else if ((timeSinceLastKeyPress > saveDelayTime)) {

      setSaveTime(-1);

      props.stores.bookStore.recipeTimestamp = new Date().getTime();

      let newValue = item[fieldName];
      baseControl.updateValue({
        value: newValue
      });


    } else {
      setTimeout(function () {
        doSave()
      }
        , saveDelayTime)
    }




  }


  return (

    <Box sx={{
      marginTop: '1em',
      width: '100%'
    }}>
      <TextField
        key={'textField' + item?.id + element.entityFieldName}
        item={item}
        fieldName={element.entityFieldName}
        label={element.label}
        variant="standard"
        size="small"
        defaultValue={value}
        fullWidth={true}
        onChange={(e) => {

          let newValue = e.target.value;
          handleChange(newValue);
        }} />
    </Box>




  );
}


export default inject('stores')(observer(FormatTextfield));



