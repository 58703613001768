import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Divider, Grid, Stack, styled, Typography } from "@mui/material";
import { Paper } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import StandardDashboardPaper, { StandardPaper } from '../StandardPaper';

const StandardList = (props) => {
    //export default function StandardList(props) {

    let { title, count } = props;
    const theme = useTheme();

    return (
        <>
        {/* // <StandardDashboardPaper sx={{
        //     display: 'flex',
        //     flexDirection: 'column',
        //     width: '100%',
        //     position: 'relative',
        //     //height: '8em',
        //     borderRadius: '8px',
        //     //marginTop: '1.75em',
        //     paddingBottom: '0.5em',
        //     //border:'1px solid blue'
        // }}> */}
            {!props.hideHeading &&
                <Stack sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    height: '100%',
                    paddingTop: '1em',
                    marginLeft: '1em',
                    marginRight: '1em',
                    //columns: '100',
                    //border:'1px solid red'
                }}>
                    <Box sx={{
                        //flex: 70
                    }}>
                        <Typography variant={'h6'} sx={{ color: theme.palette.secondary.main }}> {props.title} </Typography>
                    </Box>
                    {props.headerActions}
                </Stack>
                }
                {props.children}
                {/* </StandardDashboardPaper> */}
        </>
    );
}

export default inject('stores')(observer(StandardList));
