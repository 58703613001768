import Quill from 'quill';
//import Popper from 'popper.js';
import { v4 as uuid } from 'uuid';

const Parchment = Quill.import('parchment');

let $ = require("jquery");
let config = require('./config.js');

export default class DeltaToDom {

	convert(delta) {
		////console.log(delta);
		let ops = delta.ops;

		let domNode;

		ops.forEach((m, index) => {
			if (typeof m.insert === 'string') {

				//html+='<p>'+m.insert+'</p>  ';

			} else if (m.insert.blurbs) {

				domNode = this.convertBlurb(m.insert.blurbs);

			} else {
				let keys = Object.keys(m.insert);
				let key = keys[0];
				//html+=m.insert[key].url;
				//html+='<div><img src="'+config.default.images.RESIZER_GATEWAY+'/'+m.insert[key].url+'?h=75"><div>Caption</div></div>'
				//////console.log(config.default);
				//html+='<div><img src="'+m.insert[key].url+'"><div>Caption</div></div>'

			}

		});
		return domNode;
	}

	convertDecoratedBreak(args, node) {
		//console.log('convertDecoratedBreak()...')
		let wrapperNode = document.createElement("DIV");
		//wrapperNode.setAttribute('style', 'width:100%;text-align:center;font-size:2em;font-weigth:bold;text-indent:0px');
		wrapperNode.setAttribute('class', 'decoratedBreak');

		//wrapperNode.appendChild(document.createTextNode("***"));

		for (let i = 0; i < 3; i++) {
			let asterikSpan = document.createElement("SPAN");
			asterikSpan.setAttribute('class', 'decoratedBreakAsterik')
			asterikSpan.appendChild(document.createTextNode("*"))
			wrapperNode.appendChild(asterikSpan);
		}



		return wrapperNode;


	}


	convertPublisher(args, node) {
		//console.log('convertPublisher()...')

		let blurbsNode = document.createElement("DIV");



		try {


			let blotElementId = node.dataset.uuid;
			let documentPart = args.documentPart;

			let bookDraft = args.documentPart.bookDraftDocumentPart.bookDraft;
			let mappedImages = bookDraft.mappedImages;
			let mappedSrc = mappedImages[args.src];


			//console.log(mappedImages);
			let userId = '';

			let publisher = {};
			try {

				publisher = JSON.parse(args.publisher);

			} catch (err) {
				////console.log(err);
			}


			try {

				userId = args.userStore.user.id;

			} catch (err) {
				////console.log(err);
			}



			////console.log(documentPart);


			//Create wrapper div

			//blurbsNode.setAttribute('style', 'display:flex; flex-direction:column');
			blurbsNode.setAttribute('class', 'blot-wrapper blot-publisher-wrapper');

			let deleteIcon = this.createDeleteIcon({ uuid: args.uuid });

			blurbsNode.appendChild(deleteIcon);

			let contributorDivsNode = document.createElement("DIV");
			blurbsNode.appendChild(contributorDivsNode);

			/*let publisherNameDiv = this.createTextField({
						label:'publisher name:',
						args:args,
						entity:publisher,
						fieldName:'name',
						value:publisher.name,
						blurHandler:(blot,entity)=>{
							////console.log('handling this blur...');
							blot.updateFromDom(entity);
						}, 
						changeHandler:null});
					
					blurbsNode.appendChild(publisherNameDiv);
	
					let publisherUrlDiv = this.createTextField({
						label:'publisher url:',
						args:args,
						entity:publisher,
						fieldName:'url',
						value:publisher.url,
						blurHandler:(blot,entity)=>{
							////console.log('handling this blur...');
							blot.updateFromDom(entity);
						}, 
						changeHandler:null});
					
					blurbsNode.appendChild(publisherUrlDiv);
					*/





			let publisherNameTextFieldResults = this.createAutoTextField({
				label: 'Name:',
				args: args,
				entity: publisher,
				fieldName: 'name',
				placeholder: '[Publisher name]',
				value: publisher.name,
				blurHandler: (blot, entity) => {
					blot.updateFromDom(entity);
				},
				changeHandler: null
			});
			blurbsNode.appendChild(publisherNameTextFieldResults.wrapperSpan);

			let publisherUrlTextFieldResults = this.createAutoTextField({
				label: 'publisher url:',
				args: args,
				entity: publisher,
				fieldName: 'url',
				placeholder: '[Publisher url]',
				value: publisher.url,
				blurHandler: (blot, entity) => {
					blot.updateFromDom(entity);
				},
				changeHandler: null
			});
			blurbsNode.appendChild(publisherUrlTextFieldResults.wrapperSpan);


			let publisherImageUrl = './img/' + config.default.images.MISSING_IMAGE_NAME;
			if (mappedImages[publisher.url] != null) {
				publisherImageUrl = mappedImages[publisher.url];
			}


			let imageWrapperSpan = document.createElement("div");
			imageWrapperSpan.setAttribute('class', 'blot-blurb-content-italic');
			imageWrapperSpan.setAttribute('contenteditable', 'false');

			let publisherDivId = 'publisherDiv';
			let img = document.createElement("img");
			img.setAttribute('id', publisherDivId + 'Image');
			img.setAttribute('width', '100px');
			img.setAttribute('src', publisherImageUrl);

			// img.addEventListener('click', function (e) {
			// 	e.stopPropagation();
			// 	e.preventDefault();
			// 	////console.log(e);
			// 	$("#master_quillImageProcessor").attr("blotImageDivId", blotElementId);
			// 	$("#master_quillImageProcessor").click()

			// })

			//For now publisher data comes from the book object itself, not these blots. 
			imageWrapperSpan.appendChild(img);
			blurbsNode.appendChild(imageWrapperSpan);



			//console.log(bookDraft);


			let bookPublisherImageUrl = null;
			if (mappedImages[bookDraft.book.publisherLogoImageSrc] != null) {
				bookPublisherImageUrl = mappedImages[bookDraft.book.publisherLogoImageSrc];
			}


			let bookPublisherImage = document.createElement("img");
			bookPublisherImage.setAttribute('id', publisherDivId + 'BookPublisherImage');
			bookPublisherImage.setAttribute('width', '50px');
			bookPublisherImage.setAttribute('src', bookPublisherImageUrl);
			//bookPublisherImage.setAttribute('src', bookDraft.book.publisherLogoImageSrc);


			// bookPublisherImage.addEventListener('click', function (e) {
			// 	e.stopPropagation();
			// 	e.preventDefault();
			// 	////console.log(e);
			// 	$("#master_quillImageProcessor").attr("blotImageDivId", blotElementId);
			// 	$("#master_quillImageProcessor").click()

			// })
			//blurbsNode.appendChild(bookPublisherImage);

			//createCheckbox({args,entity, checked, label='', canEdit=true,changeHandler});
			let checked = false;
			if (publisher.useBookPublisherImage && publisher.useBookPublisherImage == 'true') {
				checked = true;
			}

			// let publisherCheckbox = this.createCheckbox({
			// 			label:'Use book image',
			// 			args:args,
			// 			entity:publisher,
			// 			fieldName:'useBookPublisherImage',
			// 			checked:checked,
			// 			blurHandler:null, 
			// 			changeHandler:(blot,entity)=>{
			// 				//console.log('handling this blur...');
			// 				blot.updateFromDom(entity);
			// 			}});

			// blurbsNode.appendChild(publisherCheckbox);



			blurbsNode.appendChild(this.createBlotFooter({
				args
			}));

		} catch (err) {
			console.log(err);
		}
		return blurbsNode;

	}

	//convertFullPageImage


	convertFullPageImage(args, node, imageBlot, blotImageDivId) {
		let propsDiv = $("#image4Popper");
		let thisPopper = null;

		let bookDraft = args.documentPart.bookDraftDocumentPart.bookDraft;
		let mappedImages = bookDraft.mappedImages;
		let mappedSrc = mappedImages[args.src];

		// if(mappedSrc==null){
		// 	mappedSrc = 'https://m.media-amazon.com/images/I/71cWEHLM9TL._AC_SL1500_.jpg';
		// }
		let wrapperNode = document.createElement("DIV");


		let propertiesClickerNode = document.createElement("DIV");
		propertiesClickerNode.setAttribute('style', 'opacity:1.0;display:none;width:25%;height:25%;background-color:red;position:absolute;right:0px;top:0px');

		wrapperNode.appendChild(propertiesClickerNode);




		node.dataset.src = args.src;
		node.dataset.type = args.type;
		////console.log(imageSize);
		wrapperNode.setAttribute('style', 'position:relative;borderx:1px solid green;display:inline-block;flex-direction:column');
		wrapperNode.setAttribute('id', 'popupProps');
		wrapperNode.setAttribute('name', 'popupProps');


		let innerDiv = document.createElement("div");
		innerDiv.setAttribute('style', 'display:flex; flex-direction:column');
		wrapperNode.appendChild(innerDiv);

		let topDiv = document.createElement("div");
		topDiv.setAttribute('style', 'display:flex; flex-direction:row');
		innerDiv.appendChild(topDiv);


		let img = document.createElement("img");
		img.setAttribute('id', blotImageDivId + 'Image');
		//img.src = config.default.images.RESIZER_GATEWAY+'/'+args.src;     
		//img.src = args.s3Src;     
		img.src = mappedSrc;
		img.setAttribute('style', 'display:block; margin-left:.5em; margin-right:.5em;');
		//img.setAttribute('width', imageSize);


		// img.addEventListener('click', function (e) {
		// 	e.stopPropagation();
		// 	e.preventDefault();
		// 	////console.log(e);
		// 	$("#master_quillImageProcessor").attr("blotImageDivId", blotImageDivId);
		// 	$("#master_quillImageProcessor").click()

		// })
		topDiv.appendChild(img);

		/*let settingsDiv = document.createElement("div");
		settingsDiv.setAttribute('stylex', 'display:block; margin-leftx:auto; margin-rightx:auto; widthx:100');
		topDiv.appendChild(settingsDiv);*/

		let controlsDiv = document.createElement("div");
		controlsDiv.setAttribute('style', 'display:flex; flex-direction:column');

		let trashSvg = $('<svg class="MuiSvgIcon-root-102 MuiSvgIcon-colorPrimary-103" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19C6,20.1 6.9,21 8,21H16C17.1,21 18,20.1 18,19V7H6V19Z"></path></svg>');
		// $(trashSvg).click(function (e) {
		// 	e.stopPropagation();
		// 	e.preventDefault();
		// 	////console.log(e);
		// 	$("#master_quillImageDeleter").attr("blotImageDivId", blotImageDivId); $("#master_quillImageDeleter").click()




		// })
		$(controlsDiv).append(trashSvg);

		let cameraSvg = $('<svg class="MuiSvgIcon-root-102 MuiSvgIcon-colorPrimary-103" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M4,4H7L9,2H15L17,4H20C21.1,4 22,4.9 22,6V18C22,19.1 21.1,20 20,20H4C2.9,20 2,19.1 2,18V6C2,4.9 2.9,4 4,4M12,7C9.24,7 7,9.24 7,12C7,14.76 9.24,17 12,17C14.76,17 17,14.76 17,12C17,9.24 14.76,7 12,7M12,9C13.66,9 15,10.34 15,12C15,13.66 13.66,15 12,15C10.34,15 9,13.66 9,12C9,10.34 10.34,9 12,9Z"></path></svg>');
		$(controlsDiv).append(cameraSvg);

		let checkSvg = $('<svg class="MuiSvgIcon-root-102 MuiSvgIcon-colorPrimary-103" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12,2C17.52,2 22,6.48 22,12C22,17.52 17.52,22 12,22C6.48,22 2,17.52 2,12C2,6.48 6.48,2 12,2M11,16.5L18,9.5L16.59,8.09L11,13.67L7.91,10.59L6.5,12L11,16.5Z"></path></svg>');
		$(controlsDiv).append(checkSvg);

		let auxControlsDiv = document.createElement("div");
		auxControlsDiv.setAttribute('style', 'display:flex; flex-direction:row');

		let left = $('<div>LT</div>');
		$(left).attr('style', 'margin:3px 3px 3px 3px');
		$(left).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'left';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			////console.log(node.dataset);


		});
		$(auxControlsDiv).append(left);

		let center = $('<div>CR</div>');
		$(center).attr('style', 'margin:3px 3px 3px 3px');
		$(center).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'center';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			$(nodeParent).addClass('ql-align-center');
			////console.log(node.dataset);


		})
		$(auxControlsDiv).append(center);

		let right = $('<div>RT</div>');
		$(right).attr('style', 'margin:3px 3px 3px 3px');
		$(right).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'right';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			$(nodeParent).addClass('ql-align-right');
			////console.log(node.dataset);


		})
		$(auxControlsDiv).append(right);

		let floatLeft = $('<div>FL</div>');
		$(floatLeft).attr('style', 'margin:3px 3px 3px 3px');
		$(floatLeft).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'floatLeft';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			$(nodeParent).addClass('floatLeft');
			////console.log(node.dataset);


		})
		$(auxControlsDiv).append(floatLeft);

		let floatRight = $('<div>FR</div>');
		$(floatRight).attr('style', 'margin:3px 3px 3px 3px');
		$(floatRight).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'floatRight';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			$(nodeParent).addClass('floatRight');
			////console.log(node.dataset);


		})
		$(auxControlsDiv).append(floatRight);


		let sizeControlsDiv = document.createElement("div");
		sizeControlsDiv.setAttribute('style', 'display:flex; flex-direction:row');

		let small = $('<div>SM</div>');
		$(small).attr('style', 'margin:3px 3px 3px 3px');
		$(small).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.size = 'small';
			let nodeParent = $(node);
			////console.log(node.dataset);
			img.setAttribute('style', 'display:block; margin-left:auto; margin-right:auto;width:25px');



		});
		$(sizeControlsDiv).append(small);

		let medium = $('<div>MD</div>');
		$(medium).attr('style', 'margin:3px 3px 3px 3px');
		$(medium).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.size = 'medium';
			let nodeParent = $(node);
			////console.log(node.dataset);
			img.setAttribute('style', 'display:block; margin-left:auto; margin-right:auto;width:50px');



		});
		$(sizeControlsDiv).append(medium);




		let large = $('<div>LG</div>');
		$(large).attr('style', 'margin:3px 3px 3px 3px');
		$(large).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.size = 'large';
			let nodeParent = $(node);
			////console.log(node.dataset);
			img.setAttribute('style', 'display:block; margin-left:auto; margin-right:auto;width:75px');



		});
		$(sizeControlsDiv).append(large);



		let xlarge = $('<div>XL</div>');
		$(xlarge).attr('style', 'margin:3px 3px 3px 3px');
		$(xlarge).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.size = 'xlarge';
			let nodeParent = $(node);
			////console.log(node.dataset);
			img.setAttribute('style', 'display:block; margin-left:auto; margin-right:auto;width:100px');



		});
		$(sizeControlsDiv).append(xlarge);






		//topDiv.appendChild(controlsDiv);



		let display = 'none';
		if (node.dataset.includeCaption && node.dataset.includeCaption == 'true') {
			display = 'inline-block';
		}
		let captionInput = document.createElement("input");
		captionInput.value = args.caption;
		captionInput.setAttribute('type', 'text');
		captionInput.setAttribute('id', blotImageDivId + 'Caption');
		captionInput.setAttribute('style', 'display:' + display + ';border:none;text-align:center;color:#888888');
		captionInput.addEventListener('change', function (e) {
			e.stopPropagation();
			e.preventDefault();
			////console.log(e);
			////console.log(node);
			node.dataset.caption = e.target.value;

		})
		innerDiv.appendChild(captionInput);

		let popperAnchor = document.createElement("div");
		popperAnchor.setAttribute('id', blotImageDivId + 'PopperAnchor');
		//popperAnchor.setAttribute('style', 'display:none;');
		innerDiv.appendChild(popperAnchor);

		//innerDiv.appendChild(auxControlsDiv);
		//innerDiv.appendChild(sizeControlsDiv);




		/*let deleteButton = document.createElement("button");
		deleteButton.appendChild(document.createTextNode("delete"));
		deleteButton.addEventListener('click', function(e) {
			e.stopPropagation();
			e.preventDefault();
			////console.log(e);
			$("#master_quillImageDeleter").attr("blotImageDivId",blotImageDivId);$("#master_quillImageDeleter").click()
		
			
		    
		    
			})  
		wrapperNode.appendChild(deleteButton);*/

		let buildPopper = function () {
			////console.log('focus');
			let popperDiv = document.createElement("div");
			popperDiv.setAttribute('style', 'display:none;background-color:red;height:100px');
			popperDiv.setAttribute('id', blotImageDivId + 'popper');
			node.appendChild(popperDiv);
			$('#' + blotImageDivId + 'popper').html('<div>The popper</div>');


			popperDiv.appendChild(auxControlsDiv);
			popperDiv.appendChild(sizeControlsDiv);
			popperDiv.appendChild(controlsDiv);

			const refEl = $('#' + blotImageDivId + 'PopperAnchor');
			const popEl = $('#' + blotImageDivId + 'popper');

			let thisPopper = null;
			/*let thisPopper = new Popper(refEl, popEl, {
				 placement: 'bottom'
			});*/

			/*$( '#'+blotImageDivId ).mouseover(function(){
				 thisPopper = new Popper(refEl, popEl, {
					 placement: 'bottom'
				});
				$('#'+blotImageDivId+'popper').show();
			});

			$( '#'+blotImageDivId ).mouseleave(function(){
				$('#'+blotImageDivId+'popper').hide();
				thisPopper.destroy();
			});*/
		}
		setTimeout(() => {

			//$(wrapperNode).focus(function() {
			buildPopper();

			//});

		}, 500);

		wrapperNode.appendChild(this.createBlotFooter({
			args,
			centerControls: <span></span>
		}));

		return wrapperNode;
	}


	convertDeleteNewLineContent(args, node, imageBlot, blotImageDivId) {

		let wrapperNode = document.createElement("SPAN");

		let img = document.createElement("IMG");
		img.src = '/img/line-break.png';
		//img.src = '/img/remove-line-break.png';
		img.setAttribute('style', 'height:1em; width:1em;position:relative');

		// let strikethroughSpan = document.createElement("SPAN");
		// strikethroughSpan.setAttribute('style', 'height:1em; width:1em;position:absolute;top:0;bottom:0;text-decoration:line-through;text-decoration-color: red;');
		// strikethroughSpan.appendChild(document.createTextNode(" "));
		// img.appendChild(strikethroughSpan);

		wrapperNode.appendChild(img);

		let newLine1 = document.createElement("BR");
		newLine1.setAttribute('style', 'display:none');
		wrapperNode.appendChild(newLine1);

		let newLine2 = document.createElement("BR");
		newLine2.setAttribute('style', 'display:none');
		wrapperNode.appendChild(newLine2);

		let indentSpan = document.createElement("SPAN");
		indentSpan.setAttribute('style', 'margin-left:1.25em;display:none');
		$(indentSpan).addClass('indent-span');
		wrapperNode.appendChild(indentSpan);



		return wrapperNode;


	}

	convertInsertNewLineContent(args, node, imageBlot, blotImageDivId) {

		// let wrapperNode = document.createElement("SPAN");
		// wrapperNode.setAttribute('contenteditable', 'false');

		let img = document.createElement("img");
		img.src = '/img/line-break.png';
		img.setAttribute('style', 'height:1em; width:1em');
		//img.setAttribute('contenteditable', 'false');
		node.appendChild(img);

		// Must add two new lines here, instead of using ops in keyboard2.js, 
		// so that previews work. Otherwise, the newlines are not turned off. 
		// let newLine1 = document.createElement("BR");
		// newLine1.setAttribute('contenteditable', 'false');
		// node.appendChild(newLine1);

		// let newLine2 = document.createElement("BR");
		// newLine2.setAttribute('contenteditable', 'false');
		// node.appendChild(newLine2);

		// let indentSpan = document.createElement("SPAN");
		// indentSpan.setAttribute('style', 'margin-left:1.25em');
		// indentSpan.setAttribute('contenteditable', 'false');
		// node.appendChild(indentSpan);


		//return wrapperNode;


	}

	convertImage4(args, node, imageBlot, blotImageDivId) {

		try{

			let propsDiv = $("#image4Popper");
			let thisPopper = null;
	
			let bookDraft = args.documentPart.bookDraftDocumentPart.bookDraft;
			let mappedImages = bookDraft.mappedImages;
			let mappedSrc = mappedImages[args.src];
	
			if (mappedSrc == null || args.src == 'null' || args.src == 'undefined') {
	
				mappedSrc = './img/' + config.default.images.MISSING_IMAGE_NAME;
				//mappedSrc = mappedImages[imageSrc];
			}
			//console.log(args.src);
			// if(mappedSrc==null){
			// 	mappedSrc = 'https://m.media-amazon.com/images/I/71cWEHLM9TL._AC_SL1500_.jpg';
			// }
			let wrapperNode = document.createElement("img");
			wrapperNode.setAttribute('class', 'image4Wrapper');
			wrapperNode.setAttribute('src', 'https://m.media-amazon.com/images/I/71cWEHLM9TL._AC_SL1500_.jpg');
	
	
			//node.setAttribute('class', 'image4 image4-centered');
			//node.setAttribute('class', 'image4 image4-float-left');
			node.setAttribute('class', 'image4 floatRight');

			return wrapperNode;
			
		}catch(err){
			console.log(err);
			let wrapperNode = document.createElement("img");
			wrapperNode.setAttribute('class', 'image4Wrapper');
			wrapperNode.setAttribute('src', 'https://m.media-amazon.com/images/I/71cWEHLM9TL._AC_SL1500_.jpg');
			return wrapperNode;
		}



		

	}


	convertImage4_current(args, node, imageBlot, blotImageDivId) {
		let propsDiv = $("#image4Popper");
		let thisPopper = null;

		let bookDraft = args.documentPart.bookDraftDocumentPart.bookDraft;
		let mappedImages = bookDraft.mappedImages;
		let mappedSrc = mappedImages[args.src];

		if (mappedSrc == null || args.src == 'null' || args.src == 'undefined') {

			mappedSrc = './img/' + config.default.images.MISSING_IMAGE_NAME;
			//mappedSrc = mappedImages[imageSrc];
		}
		//console.log(args.src);
		// if(mappedSrc==null){
		// 	mappedSrc = 'https://m.media-amazon.com/images/I/71cWEHLM9TL._AC_SL1500_.jpg';
		// }
		let wrapperNode = document.createElement("DIV");

		node.dataset.src = args.src;
		//node.dataset.src = mappedSrc;
		node.dataset.s3Src = args.s3Src;
		//node.dataset.s3Src = mappedSrc;
		node.dataset.type = args.type;
		node.dataset.size = args.size;
		node.dataset.alignment = args.alignment;
		let imageSize = '10em';
		switch (node.dataset.size) {

			case 'small':
				imageSize = '5em';
				break;

			case 'medium':
				imageSize = '10em';
				break;

			case 'large':
				imageSize = '15em';
				break;

			case 'xlarge':
				imageSize = '20em';
				break;
		}
		////console.log(imageSize);
		wrapperNode.setAttribute('style', 'position:relative;borderx:1px solid green;display:inline-block;flex-direction:column;width:' + imageSize);
		wrapperNode.setAttribute('id', 'popupProps');
		wrapperNode.setAttribute('name', 'popupProps');


		let innerDiv = document.createElement("div");
		innerDiv.setAttribute('style', 'display:flex; flex-direction:column');
		wrapperNode.appendChild(innerDiv);

		let topDiv = document.createElement("div");
		topDiv.setAttribute('style', 'display:flex; flex-direction:row');
		innerDiv.appendChild(topDiv);


		let imgDiv = document.createElement("div");
		imgDiv.setAttribute('style', 'position:relative');
		let img = document.createElement("img");
		img.setAttribute('id', blotImageDivId + 'Image');
		//img.src = config.default.images.RESIZER_GATEWAY+'/'+args.src;     
		//img.src = args.s3Src;     
		img.src = mappedSrc;
		//img.setAttribute('style', 'display:block; margin-left:.5em; margin-right:.5em;width:' + imageSize + '');
		img.setAttribute('style', 'display:block; width:' + imageSize + '');
		//img.setAttribute('width', imageSize);

		imgDiv.appendChild(img);

		// img.addEventListener('click', function (e) {
		// 	e.stopPropagation();
		// 	e.preventDefault();
		// 	////console.log(e);
		// 	$("#master_quillImageProcessor").attr("blotImageDivId", blotImageDivId);
		// 	$("#master_quillImageProcessor").click()

		// })


		let propertiesClickerNode = document.createElement("DIV");
		propertiesClickerNode.setAttribute('style', 'right:0px;top:0px');
		propertiesClickerNode.setAttribute('class', 'blot-control-background');
		$(propertiesClickerNode).click(function () {
			// thisPopper = new Popper(node, propsDiv, {
			// 	placement: 'top'
			// });
			// $('#image4Popper').data('node', node);
			// let editors = $('ql-editor');
			// let max = 0;
			// editors.each(function () {
			// 	var z = parseInt($(this).css("z-index"), 10);
			// 	max = Math.max(max, z);
			// })
			// $('#image4Popper').trigger('click');
			// $('#image4Popper').css("z-index", max + 1);
			// $('.popperProps').hide();
			// $('#image4Popper').show();

		});

		let propertiesClickerDiv = document.createElement("DIV");
		propertiesClickerDiv.innerHTML = '<svg class="MuiSvgIcon-root MuiSvgIcon-colorDisabled MuiSvgIcon-fontSizeSmall css-me1rrz-MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="ArrowUpwardIcon"><path d="m4 12 1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"></path></svg>';
		propertiesClickerNode.appendChild(propertiesClickerDiv);

		imgDiv.appendChild(propertiesClickerNode);

		let deleteClickerNode = document.createElement("DIV");
		deleteClickerNode.setAttribute('style', 'right:0px;bottom:0px');
		deleteClickerNode.setAttribute('class', 'blot-control-background');
		$(deleteClickerNode).click(function () {
			// thisPopper = new Popper(node, propsDiv, {
			// 	placement: 'top'
			// });
			// $('#image4Popper').data('node', node);
			// let editors = $('ql-editor');
			// let max = 0;
			// editors.each(function () {
			// 	var z = parseInt($(this).css("z-index"), 10);
			// 	max = Math.max(max, z);
			// })
			// $('#image4Popper').trigger('click');
			// $('#image4Popper').css("z-index", max + 1);
			// $('.popperProps').hide();
			// $('#image4Popper').show();

		});
		imgDiv.appendChild(deleteClickerNode);








		topDiv.appendChild(imgDiv);

		let captionDiv = this.createCaptionField({
			label: 'caption:',
			args: args,
			entity: args,
			imageSize,
			fieldName: 'caption',
			placeholder: '[caption]',
			value: args.caption,
			blurHandler: (blot, entity) => {
				node.dataset.caption = entity.caption;
			},
			changeHandler: null
		});

		captionDiv.setAttribute('style', 'width:' + imageSize + '');
		innerDiv.appendChild(captionDiv);

		/*let settingsDiv = document.createElement("div");
		settingsDiv.setAttribute('stylex', 'display:block; margin-leftx:auto; margin-rightx:auto; widthx:100');
		topDiv.appendChild(settingsDiv);*/

		let controlsDiv = document.createElement("div");
		controlsDiv.setAttribute('style', 'display:flex; flex-direction:column');

		let trashSvg = $('<svg class="MuiSvgIcon-root-102 MuiSvgIcon-colorPrimary-103" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19C6,20.1 6.9,21 8,21H16C17.1,21 18,20.1 18,19V7H6V19Z"></path></svg>');
		// $(trashSvg).click(function (e) {
		// 	e.stopPropagation();
		// 	e.preventDefault();
		// 	////console.log(e);
		// 	$("#master_quillImageDeleter").attr("blotImageDivId", blotImageDivId); $("#master_quillImageDeleter").click()




		// })
		$(controlsDiv).append(trashSvg);

		let cameraSvg = $('<svg class="MuiSvgIcon-root-102 MuiSvgIcon-colorPrimary-103" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M4,4H7L9,2H15L17,4H20C21.1,4 22,4.9 22,6V18C22,19.1 21.1,20 20,20H4C2.9,20 2,19.1 2,18V6C2,4.9 2.9,4 4,4M12,7C9.24,7 7,9.24 7,12C7,14.76 9.24,17 12,17C14.76,17 17,14.76 17,12C17,9.24 14.76,7 12,7M12,9C13.66,9 15,10.34 15,12C15,13.66 13.66,15 12,15C10.34,15 9,13.66 9,12C9,10.34 10.34,9 12,9Z"></path></svg>');
		$(controlsDiv).append(cameraSvg);

		let checkSvg = $('<svg class="MuiSvgIcon-root-102 MuiSvgIcon-colorPrimary-103" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12,2C17.52,2 22,6.48 22,12C22,17.52 17.52,22 12,22C6.48,22 2,17.52 2,12C2,6.48 6.48,2 12,2M11,16.5L18,9.5L16.59,8.09L11,13.67L7.91,10.59L6.5,12L11,16.5Z"></path></svg>');
		$(controlsDiv).append(checkSvg);

		let auxControlsDiv = document.createElement("div");
		auxControlsDiv.setAttribute('style', 'display:flex; flex-direction:row');

		let left = $('<div>LT</div>');
		$(left).attr('style', 'margin:3px 3px 3px 3px');
		$(left).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'left';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			////console.log(node.dataset);


		});
		$(auxControlsDiv).append(left);

		let center = $('<div>CR</div>');
		$(center).attr('style', 'margin:3px 3px 3px 3px');
		$(center).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'center';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			$(nodeParent).addClass('ql-align-center');
			////console.log(node.dataset);


		})
		$(auxControlsDiv).append(center);

		let right = $('<div>RT</div>');
		$(right).attr('style', 'margin:3px 3px 3px 3px');
		$(right).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'right';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			$(nodeParent).addClass('ql-align-right');
			////console.log(node.dataset);


		})
		$(auxControlsDiv).append(right);

		let floatLeft = $('<div>FL</div>');
		$(floatLeft).attr('style', 'margin:3px 3px 3px 3px');
		$(floatLeft).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'floatLeft';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			$(nodeParent).addClass('floatLeft');
			////console.log(node.dataset);


		})
		$(auxControlsDiv).append(floatLeft);

		let floatRight = $('<div>FR</div>');
		$(floatRight).attr('style', 'margin:3px 3px 3px 3px');
		$(floatRight).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'floatRight';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			$(nodeParent).addClass('floatRight');
			////console.log(node.dataset);


		})
		$(auxControlsDiv).append(floatRight);


		let sizeControlsDiv = document.createElement("div");
		sizeControlsDiv.setAttribute('style', 'display:flex; flex-direction:row');

		let small = $('<div>SM</div>');
		$(small).attr('style', 'margin:3px 3px 3px 3px');
		$(small).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.size = 'small';
			let nodeParent = $(node);
			////console.log(node.dataset);
			img.setAttribute('style', 'display:block; margin-left:auto; margin-right:auto;width:25px');



		});
		$(sizeControlsDiv).append(small);

		let medium = $('<div>MD</div>');
		$(medium).attr('style', 'margin:3px 3px 3px 3px');
		$(medium).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.size = 'medium';
			let nodeParent = $(node);
			////console.log(node.dataset);
			img.setAttribute('style', 'display:block; margin-left:auto; margin-right:auto;width:50px');



		});
		$(sizeControlsDiv).append(medium);




		let large = $('<div>LG</div>');
		$(large).attr('style', 'margin:3px 3px 3px 3px');
		$(large).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.size = 'large';
			let nodeParent = $(node);
			////console.log(node.dataset);
			img.setAttribute('style', 'display:block; margin-left:auto; margin-right:auto;width:75px');



		});
		$(sizeControlsDiv).append(large);



		let xlarge = $('<div>XL</div>');
		$(xlarge).attr('style', 'margin:3px 3px 3px 3px');
		$(xlarge).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.size = 'xlarge';
			let nodeParent = $(node);
			////console.log(node.dataset);
			img.setAttribute('style', 'display:block; margin-left:auto; margin-right:auto;width:100px');



		});
		$(sizeControlsDiv).append(xlarge);






		//topDiv.appendChild(controlsDiv);



		let display = 'none';
		if (node.dataset.includeCaption && node.dataset.includeCaption == 'true') {
			display = 'inline-block';
		}



		// let captionDiv = this.createCaptionField({
		// 	label: 'caption:',
		// 	args: args,
		// 	entity: args,
		// 	imageSize,
		// 	fieldName: 'caption',
		// 	placeholder:'[caption]',
		// 	value: args.caption,
		// 	blurHandler: (blot, entity) => {
		// 		node.dataset.caption = entity.caption;
		// 	},
		// 	changeHandler: null
		// });

		// innerDiv.appendChild(captionDiv);




		let popperAnchor = document.createElement("div");
		popperAnchor.setAttribute('id', blotImageDivId + 'PopperAnchor');
		//popperAnchor.setAttribute('style', 'display:none;');
		innerDiv.appendChild(popperAnchor);




		//innerDiv.appendChild(auxControlsDiv);
		//innerDiv.appendChild(sizeControlsDiv);




		/*let deleteButton = document.createElement("button");
		deleteButton.appendChild(document.createTextNode("delete"));
		deleteButton.addEventListener('click', function(e) {
			e.stopPropagation();
			e.preventDefault();
			////console.log(e);
			$("#master_quillImageDeleter").attr("blotImageDivId",blotImageDivId);$("#master_quillImageDeleter").click()
		
			
		    
		    
			})  
		wrapperNode.appendChild(deleteButton);*/

		let buildPopper = function () {
			////console.log('focus');
			let popperDiv = document.createElement("div");
			popperDiv.setAttribute('style', 'display:none;background-color:red;height:100px');
			popperDiv.setAttribute('id', blotImageDivId + 'popper');
			node.appendChild(popperDiv);
			$('#' + blotImageDivId + 'popper').html('<div>The popper</div>');


			popperDiv.appendChild(auxControlsDiv);
			popperDiv.appendChild(sizeControlsDiv);
			popperDiv.appendChild(controlsDiv);

			const refEl = $('#' + blotImageDivId + 'PopperAnchor');
			const popEl = $('#' + blotImageDivId + 'popper');

			let thisPopper = null;
			/*let thisPopper = new Popper(refEl, popEl, {
				 placement: 'bottom'
			});*/

			/*$( '#'+blotImageDivId ).mouseover(function(){
				 thisPopper = new Popper(refEl, popEl, {
					 placement: 'bottom'
				});
				$('#'+blotImageDivId+'popper').show();
			});

			$( '#'+blotImageDivId ).mouseleave(function(){
				$('#'+blotImageDivId+'popper').hide();
				thisPopper.destroy();
			});*/
		}
		setTimeout(() => {

			//$(wrapperNode).focus(function() {
			buildPopper();

			//});

		}, 500);


		return wrapperNode;
	}

	convertImage4_20Oct2022(args, node, imageBlot, blotImageDivId) {
		let propsDiv = $("#image4Popper");
		let thisPopper = null;

		let bookDraft = args.documentPart.bookDraftDocumentPart.bookDraft;
		let mappedImages = bookDraft.mappedImages;
		let mappedSrc = mappedImages[args.src];

		if (mappedSrc == null || args.src == 'null' || args.src == 'undefined') {

			mappedSrc = './img/' + config.default.images.MISSING_IMAGE_NAME;
			//mappedSrc = mappedImages[imageSrc];
		}
		//console.log(args.src);
		// if(mappedSrc==null){
		// 	mappedSrc = 'https://m.media-amazon.com/images/I/71cWEHLM9TL._AC_SL1500_.jpg';
		// }
		let wrapperNode = document.createElement("DIV");






		$(wrapperNode).mouseleave(function () {
			/*thisPopper = new Popper(node, propsDiv, {
				placement: 'bottom'
		   });
		   $('#image4Popper').data('node',node);
		   let editors = $( 'ql-editor' );
		   let max = 0;
		   editors.each(function(){
			 var z = parseInt( $( this ).css( "z-index" ), 10 );
			 max = Math.max(max,z);
		   })
		   $('#image4Popper').trigger('click');
		   $('#image4Popper').css("z-index", max + 1 );
		   $('.popperProps').hide();
		   $('#image4Popper').show();*/
		});




		// $(wrapperNode).mouseover(function () {
		// 	/*thisPopper = new Popper(node, propsDiv, {
		// 		placement: 'bottom'
		//    });
		//    $('#image4Popper').data('node',node);
		//    let editors = $( 'ql-editor' );
		//    let max = 0;
		//    editors.each(function(){
		// 	 var z = parseInt( $( this ).css( "z-index" ), 10 );
		// 	 max = Math.max(max,z);
		//    })
		//    $('#image4Popper').trigger('click');
		//    $('#image4Popper').css("z-index", max + 1 );
		//    $('.popperProps').hide();
		//    $('#image4Popper').show();*/

		// 	$(wrapperNode).css({ 'opacity': 0.5 });
		// 	$(propertiesClickerNode).show();

		// });

		// $(wrapperNode).mouseleave(function () {
		// 	/*thisPopper = new Popper(node, propsDiv, {
		// 		placement: 'bottom'
		//    });
		//    $('#image4Popper').data('node',node);
		//    let editors = $( 'ql-editor' );
		//    let max = 0;
		//    editors.each(function(){
		// 	 var z = parseInt( $( this ).css( "z-index" ), 10 );
		// 	 max = Math.max(max,z);
		//    })
		//    $('#image4Popper').trigger('click');
		//    $('#image4Popper').css("z-index", max + 1 );
		//    $('.popperProps').hide();
		//    $('#image4Popper').show();*/

		// 	$(wrapperNode).css({ 'opacity': '' });
		// 	$(propertiesClickerNode).hide();
		// });


		node.dataset.src = args.src;
		//node.dataset.src = mappedSrc;
		node.dataset.s3Src = args.s3Src;
		//node.dataset.s3Src = mappedSrc;
		node.dataset.type = args.type;
		node.dataset.size = args.size;
		node.dataset.alignment = args.alignment;
		let imageSize = '10em';
		switch (node.dataset.size) {

			case 'small':
				imageSize = '5em';
				break;

			case 'medium':
				imageSize = '10em';
				break;

			case 'large':
				imageSize = '15em';
				break;

			case 'xlarge':
				imageSize = '20em';
				break;
		}
		////console.log(imageSize);
		wrapperNode.setAttribute('style', 'position:relative;borderx:1px solid green;display:inline-block;flex-direction:column;width:' + imageSize);
		wrapperNode.setAttribute('id', 'popupProps');
		wrapperNode.setAttribute('name', 'popupProps');


		let innerDiv = document.createElement("div");
		innerDiv.setAttribute('style', 'display:flex; flex-direction:column');
		wrapperNode.appendChild(innerDiv);

		let topDiv = document.createElement("div");
		topDiv.setAttribute('style', 'display:flex; flex-direction:row');
		innerDiv.appendChild(topDiv);


		let imgDiv = document.createElement("div");
		imgDiv.setAttribute('style', 'position:relative');
		let img = document.createElement("img");
		img.setAttribute('id', blotImageDivId + 'Image');
		//img.src = config.default.images.RESIZER_GATEWAY+'/'+args.src;     
		//img.src = args.s3Src;     
		img.src = mappedSrc;
		//img.setAttribute('style', 'display:block; margin-left:.5em; margin-right:.5em;width:' + imageSize + '');
		img.setAttribute('style', 'display:block; width:' + imageSize + '');
		//img.setAttribute('width', imageSize);

		imgDiv.appendChild(img);

		// img.addEventListener('click', function (e) {
		// 	e.stopPropagation();
		// 	e.preventDefault();
		// 	////console.log(e);
		// 	$("#master_quillImageProcessor").attr("blotImageDivId", blotImageDivId);
		// 	$("#master_quillImageProcessor").click()

		// })


		let propertiesClickerNode = document.createElement("DIV");
		propertiesClickerNode.setAttribute('style', 'right:0px;top:0px');
		propertiesClickerNode.setAttribute('class', 'blot-control-background');
		$(propertiesClickerNode).click(function () {
			// thisPopper = new Popper(node, propsDiv, {
			// 	placement: 'top'
			// });
			// $('#image4Popper').data('node', node);
			// let editors = $('ql-editor');
			// let max = 0;
			// editors.each(function () {
			// 	var z = parseInt($(this).css("z-index"), 10);
			// 	max = Math.max(max, z);
			// })
			// $('#image4Popper').trigger('click');
			// $('#image4Popper').css("z-index", max + 1);
			// $('.popperProps').hide();
			// $('#image4Popper').show();

		});

		let propertiesClickerDiv = document.createElement("DIV");
		propertiesClickerDiv.innerHTML = '<svg class="MuiSvgIcon-root MuiSvgIcon-colorDisabled MuiSvgIcon-fontSizeSmall css-me1rrz-MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="ArrowUpwardIcon"><path d="m4 12 1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"></path></svg>';
		propertiesClickerNode.appendChild(propertiesClickerDiv);

		imgDiv.appendChild(propertiesClickerNode);

		let deleteClickerNode = document.createElement("DIV");
		deleteClickerNode.setAttribute('style', 'right:0px;bottom:0px');
		deleteClickerNode.setAttribute('class', 'blot-control-background');
		$(deleteClickerNode).click(function () {
			// thisPopper = new Popper(node, propsDiv, {
			// 	placement: 'top'
			// });
			// $('#image4Popper').data('node', node);
			// let editors = $('ql-editor');
			// let max = 0;
			// editors.each(function () {
			// 	var z = parseInt($(this).css("z-index"), 10);
			// 	max = Math.max(max, z);
			// })
			// $('#image4Popper').trigger('click');
			// $('#image4Popper').css("z-index", max + 1);
			// $('.popperProps').hide();
			// $('#image4Popper').show();

		});
		imgDiv.appendChild(deleteClickerNode);








		topDiv.appendChild(imgDiv);

		let captionDiv = this.createCaptionField({
			label: 'caption:',
			args: args,
			entity: args,
			imageSize,
			fieldName: 'caption',
			placeholder: '[caption]',
			value: args.caption,
			blurHandler: (blot, entity) => {
				node.dataset.caption = entity.caption;
			},
			changeHandler: null
		});

		captionDiv.setAttribute('style', 'width:' + imageSize + '');
		innerDiv.appendChild(captionDiv);

		/*let settingsDiv = document.createElement("div");
		settingsDiv.setAttribute('stylex', 'display:block; margin-leftx:auto; margin-rightx:auto; widthx:100');
		topDiv.appendChild(settingsDiv);*/

		let controlsDiv = document.createElement("div");
		controlsDiv.setAttribute('style', 'display:flex; flex-direction:column');

		let trashSvg = $('<svg class="MuiSvgIcon-root-102 MuiSvgIcon-colorPrimary-103" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19C6,20.1 6.9,21 8,21H16C17.1,21 18,20.1 18,19V7H6V19Z"></path></svg>');
		// $(trashSvg).click(function (e) {
		// 	e.stopPropagation();
		// 	e.preventDefault();
		// 	////console.log(e);
		// 	$("#master_quillImageDeleter").attr("blotImageDivId", blotImageDivId); $("#master_quillImageDeleter").click()




		// })
		$(controlsDiv).append(trashSvg);

		let cameraSvg = $('<svg class="MuiSvgIcon-root-102 MuiSvgIcon-colorPrimary-103" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M4,4H7L9,2H15L17,4H20C21.1,4 22,4.9 22,6V18C22,19.1 21.1,20 20,20H4C2.9,20 2,19.1 2,18V6C2,4.9 2.9,4 4,4M12,7C9.24,7 7,9.24 7,12C7,14.76 9.24,17 12,17C14.76,17 17,14.76 17,12C17,9.24 14.76,7 12,7M12,9C13.66,9 15,10.34 15,12C15,13.66 13.66,15 12,15C10.34,15 9,13.66 9,12C9,10.34 10.34,9 12,9Z"></path></svg>');
		$(controlsDiv).append(cameraSvg);

		let checkSvg = $('<svg class="MuiSvgIcon-root-102 MuiSvgIcon-colorPrimary-103" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12,2C17.52,2 22,6.48 22,12C22,17.52 17.52,22 12,22C6.48,22 2,17.52 2,12C2,6.48 6.48,2 12,2M11,16.5L18,9.5L16.59,8.09L11,13.67L7.91,10.59L6.5,12L11,16.5Z"></path></svg>');
		$(controlsDiv).append(checkSvg);

		let auxControlsDiv = document.createElement("div");
		auxControlsDiv.setAttribute('style', 'display:flex; flex-direction:row');

		let left = $('<div>LT</div>');
		$(left).attr('style', 'margin:3px 3px 3px 3px');
		$(left).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'left';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			////console.log(node.dataset);


		});
		$(auxControlsDiv).append(left);

		let center = $('<div>CR</div>');
		$(center).attr('style', 'margin:3px 3px 3px 3px');
		$(center).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'center';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			$(nodeParent).addClass('ql-align-center');
			////console.log(node.dataset);


		})
		$(auxControlsDiv).append(center);

		let right = $('<div>RT</div>');
		$(right).attr('style', 'margin:3px 3px 3px 3px');
		$(right).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'right';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			$(nodeParent).addClass('ql-align-right');
			////console.log(node.dataset);


		})
		$(auxControlsDiv).append(right);

		let floatLeft = $('<div>FL</div>');
		$(floatLeft).attr('style', 'margin:3px 3px 3px 3px');
		$(floatLeft).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'floatLeft';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			$(nodeParent).addClass('floatLeft');
			////console.log(node.dataset);


		})
		$(auxControlsDiv).append(floatLeft);

		let floatRight = $('<div>FR</div>');
		$(floatRight).attr('style', 'margin:3px 3px 3px 3px');
		$(floatRight).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.alignment = 'floatRight';
			let nodeParent = $(node);
			$(nodeParent).removeClass('ql-align-center');
			$(nodeParent).removeClass('ql-align-right');
			$(nodeParent).removeClass('floatRight');
			$(nodeParent).removeClass('floatLeft');
			$(nodeParent).addClass('floatRight');
			////console.log(node.dataset);


		})
		$(auxControlsDiv).append(floatRight);


		let sizeControlsDiv = document.createElement("div");
		sizeControlsDiv.setAttribute('style', 'display:flex; flex-direction:row');

		let small = $('<div>SM</div>');
		$(small).attr('style', 'margin:3px 3px 3px 3px');
		$(small).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.size = 'small';
			let nodeParent = $(node);
			////console.log(node.dataset);
			img.setAttribute('style', 'display:block; margin-left:auto; margin-right:auto;width:25px');



		});
		$(sizeControlsDiv).append(small);

		let medium = $('<div>MD</div>');
		$(medium).attr('style', 'margin:3px 3px 3px 3px');
		$(medium).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.size = 'medium';
			let nodeParent = $(node);
			////console.log(node.dataset);
			img.setAttribute('style', 'display:block; margin-left:auto; margin-right:auto;width:50px');



		});
		$(sizeControlsDiv).append(medium);




		let large = $('<div>LG</div>');
		$(large).attr('style', 'margin:3px 3px 3px 3px');
		$(large).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.size = 'large';
			let nodeParent = $(node);
			////console.log(node.dataset);
			img.setAttribute('style', 'display:block; margin-left:auto; margin-right:auto;width:75px');



		});
		$(sizeControlsDiv).append(large);



		let xlarge = $('<div>XL</div>');
		$(xlarge).attr('style', 'margin:3px 3px 3px 3px');
		$(xlarge).click(function (e) {
			e.stopPropagation();
			e.preventDefault();
			node.dataset.size = 'xlarge';
			let nodeParent = $(node);
			////console.log(node.dataset);
			img.setAttribute('style', 'display:block; margin-left:auto; margin-right:auto;width:100px');



		});
		$(sizeControlsDiv).append(xlarge);






		//topDiv.appendChild(controlsDiv);



		let display = 'none';
		if (node.dataset.includeCaption && node.dataset.includeCaption == 'true') {
			display = 'inline-block';
		}



		// let captionDiv = this.createCaptionField({
		// 	label: 'caption:',
		// 	args: args,
		// 	entity: args,
		// 	imageSize,
		// 	fieldName: 'caption',
		// 	placeholder:'[caption]',
		// 	value: args.caption,
		// 	blurHandler: (blot, entity) => {
		// 		node.dataset.caption = entity.caption;
		// 	},
		// 	changeHandler: null
		// });

		// innerDiv.appendChild(captionDiv);




		let popperAnchor = document.createElement("div");
		popperAnchor.setAttribute('id', blotImageDivId + 'PopperAnchor');
		//popperAnchor.setAttribute('style', 'display:none;');
		innerDiv.appendChild(popperAnchor);




		//innerDiv.appendChild(auxControlsDiv);
		//innerDiv.appendChild(sizeControlsDiv);




		/*let deleteButton = document.createElement("button");
		deleteButton.appendChild(document.createTextNode("delete"));
		deleteButton.addEventListener('click', function(e) {
			e.stopPropagation();
			e.preventDefault();
			////console.log(e);
			$("#master_quillImageDeleter").attr("blotImageDivId",blotImageDivId);$("#master_quillImageDeleter").click()
		
			
		    
		    
			})  
		wrapperNode.appendChild(deleteButton);*/

		let buildPopper = function () {
			////console.log('focus');
			let popperDiv = document.createElement("div");
			popperDiv.setAttribute('style', 'display:none;background-color:red;height:100px');
			popperDiv.setAttribute('id', blotImageDivId + 'popper');
			node.appendChild(popperDiv);
			$('#' + blotImageDivId + 'popper').html('<div>The popper</div>');


			popperDiv.appendChild(auxControlsDiv);
			popperDiv.appendChild(sizeControlsDiv);
			popperDiv.appendChild(controlsDiv);

			const refEl = $('#' + blotImageDivId + 'PopperAnchor');
			const popEl = $('#' + blotImageDivId + 'popper');

			let thisPopper = null;
			/*let thisPopper = new Popper(refEl, popEl, {
				 placement: 'bottom'
			});*/

			/*$( '#'+blotImageDivId ).mouseover(function(){
				 thisPopper = new Popper(refEl, popEl, {
					 placement: 'bottom'
				});
				$('#'+blotImageDivId+'popper').show();
			});

			$( '#'+blotImageDivId ).mouseleave(function(){
				$('#'+blotImageDivId+'popper').hide();
				thisPopper.destroy();
			});*/
		}
		setTimeout(() => {

			//$(wrapperNode).focus(function() {
			buildPopper();

			//});

		}, 500);


		return wrapperNode;
	}


	convertTOC(args, node) {

		let canEdit = true;
		//let userId = args.userStore.user.id;
		if (args.userStore) {

			//canEdit = args.stores.bookStore.currentBookDraft.mostRecentWritingDocumentPart.bookDraftDocumentPart.hasPermissionInBoxSet(userId,'edit');

		}

		// let contentNode = document.createElement("DIV");
		// contentNode.setAttribute('style', 'display:flex; flex-direction:column; background-color:#cccccc;height:100px');

		let blurbsNode = document.createElement("DIV");
		blurbsNode.setAttribute('style', 'display:flex; flex-direction:column; align-items:center; margin-bottom:1em');
		blurbsNode.setAttribute('class', 'blot-wrapper ');

		let deleteIcon = this.createDeleteIcon({ uuid: args.uuid });

		blurbsNode.appendChild(deleteIcon);

		let toggleIncludeVolumesDiv = this.createTOCCheckbox(args, node, 'includeVolumes', 'Include volumes');
		blurbsNode.appendChild(toggleIncludeVolumesDiv);

		let toggleIncludePartsDiv = this.createTOCCheckbox(args, node, 'includeParts', 'Include parts');
		blurbsNode.appendChild(toggleIncludePartsDiv);

		let toggleIncludeChaptersDiv = this.createTOCCheckbox(args, node, 'includeChapters', 'Include chapters');
		blurbsNode.appendChild(toggleIncludeChaptersDiv);

		blurbsNode.appendChild(this.createBlotFooter({
			args
		}));

		return blurbsNode;

		//xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
	}

	convertNotes(args, node) {

		let contentNode = document.createElement("DIV");
		contentNode.setAttribute('style', 'display:flex; flex-direction:column; background-color:#cccccc;height:100px');

		let notesDocumentPart = args.documentPart;
		if (notesDocumentPart) {


			contentNode.appendChild(document.createTextNode(notesDocumentPart.title));

			let bookDraft = args.documentPart.bookDraftDocumentPart.bookDraft;
			let parentBookDraftDocumentPart = bookDraft.getDocumentPartById(notesDocumentPart.bookDraftDocumentPart.parentPartId);
			contentNode.appendChild(document.createTextNode(parentBookDraftDocumentPart.documentPart.partType));
		}
		return contentNode;
	}

	createTOCCheckbox(args, node, opsPropertyName, label) {

		let checked = false;
		if (node.dataset[opsPropertyName] && node.dataset[opsPropertyName] == 'true') {
			checked = true
		}

		//let userId = args.userStore.user.id;
		let canEdit = true;
		if (args.userStore) {

			//canEdit = args.stores.bookStore.currentBookDraft.mostRecentWritingDocumentPart.bookDraftDocumentPart.hasPermissionInBoxSet(userId,'edit');

		}


		let toggleIncludePartsDiv = document.createElement("DIV");
		toggleIncludePartsDiv.setAttribute('class', 'blot-lineitem-checkbox-wrapper blot-content-centered ');
		toggleIncludePartsDiv.setAttribute('contenteditable', 'false');

		let toggleIncludePartsCheckbox = document.createElement("input");
		toggleIncludePartsCheckbox.setAttribute('type', 'checkbox');
		toggleIncludePartsCheckbox.setAttribute('class', 'blot-lineitem-checkbox blot-content-centered blot-text');

		if (!canEdit) {
			toggleIncludePartsCheckbox.setAttribute('disabled', '');
		}
		toggleIncludePartsCheckbox.checked = checked;
		toggleIncludePartsCheckbox.addEventListener('click', function (ev) {

			if (ev.target.checked) {
				node.dataset[opsPropertyName] = 'true';
			} else {
				node.dataset[opsPropertyName] = 'false';
			}

		})

		toggleIncludePartsDiv.appendChild(toggleIncludePartsCheckbox);
		toggleIncludePartsDiv.appendChild(document.createTextNode(label));

		return toggleIncludePartsDiv;

	}

	convertNewline(args) {


		//Create wrapper div
		let breakNode = document.createElement("BR");


		return breakNode;

	}


	convertAlsobys(args) {
		let documentPart = args.documentPart;

		let userId = '';

		let alsobys = {};
		try {

			alsobys = JSON.parse(args.alsobys);

		} catch (err) {
			//console.log(err);
		}

		////console.log(contributors);
		try {

			userId = args.userStore.user.id;

		} catch (err) {
			//console.log(err);
		}




		let canEdit = true;
		if (args.userStore) {

			//canEdit = args.stores.bookStore.currentBookDraft.mostRecentWritingDocumentPart.bookDraftDocumentPart.hasPermissionInBoxSet(userId,'edit');

		}
		////console.log(documentPart);


		//Create wrapper div
		let blurbsNode = document.createElement("DIV");
		//blurbsNode.setAttribute('style', 'display:flex; flex-direction:column');
		blurbsNode.setAttribute('class', 'blot-wrapper');

		let deleteIcon = this.createDeleteIcon({ uuid: args.uuid });

		blurbsNode.appendChild(deleteIcon);

		let contributorDivsNode = document.createElement("DIV");
		contributorDivsNode.setAttribute('class', 'blot-lineitem-alsoby-wrapper');
		blurbsNode.appendChild(contributorDivsNode);
		if (alsobys && alsobys.records) {

			//order the alsobys
			alsobys.records.sort(function (a, b) { return a.orderNumber - b.orderNumber });
			alsobys.records.forEach((record) => {

				let alsoby = record.item;

				let contributorDiv = this.createAlsoby(args, alsoby, alsobys);
				contributorDivsNode.appendChild(contributorDiv);
				/*let contributorDiv = document.createElement("div");
				contributorDiv.setAttribute('class', 'blot-lineitem');
				blurbsNode.appendChild(contributorDiv);

				let nameDiv = this.createTextField({
					args:args,
					entity:contributor,
					fieldName:'name',
					value:contributor.name,
					blurHandler:null, 
					changeHandler:null});

				contributorDiv.appendChild(nameDiv);

				let roleDiv = this.createTextField({
					args:args,
					entity:contributor,
					fieldName:'role',
					value:contributor.role,
					blurHandler:null, 
					changeHandler:null});
				
				contributorDiv.appendChild(roleDiv);

				if(canEdit){
						let removeBlurbDiv = document.createElement("DIV");
						removeBlurbDiv.innerHTML = '<svg class="MuiSvgIcon-root MuiSvgIcon-colorDisabled" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M9,3V4H4V6H5V19C5,20.1 5.9,21 7,21H17C18.1,21 19,20.1 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"></path></svg>';
						removeBlurbDiv.addEventListener('click', function(ev) {

							//new DeltaToDom().deleteBlurb(args,blurb,blurbsNode);
						    
							}) 
						contributorDiv.appendChild(removeBlurbDiv);
						}*/






			});
		}

		let footerCenterControls = <div></div>;
		if (canEdit) {


			footerCenterControls = this.createButton({
				title: '+ OWNED BOOKS',
				clickHandler: (e) => {

					e.stopPropagation();
					e.preventDefault();
					//console.log(e);
					$("#master_quillImageProcessor").attr("blotDivId", args.uuid);
					$("#master_quillBookSelector").click()





				}
			})
			/*blurbsNode.appendChild(
				this.createButton({
					title:'+ ALSOBY',
					clickHandler:()=>{
						 //new DeltaToDom().deleteBlurb(args,blurb,blurbsNode);
						 ////console.log('create a contributor');

							  let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
							let blot = Parchment.find(node);

							////console.log(blot);
							let newContributor = blot.addContributor({});

							let newContributorDiv = this.createAlsoby(args,newContributor.item,alsobys);
							contributorDivsNode.appendChild(newContributorDiv);
					}})
				)*/

			// blurbsNode.appendChild(
			// 	this.createButton({
			// 		title: '+ OWNED BOOKS',
			// 		clickHandler: (e) => {

			// 			e.stopPropagation();
			// 			e.preventDefault();
			// 			//console.log(e);
			// 			$("#master_quillImageProcessor").attr("blotDivId", args.uuid);
			// 			$("#master_quillBookSelector").click()





			// 		}
			// 	})
			// )
		}



		blurbsNode.appendChild(this.createBlotFooter({
			args,
			centerControls: footerCenterControls
		}));

		return blurbsNode;

	}


	convertBlurbs(args) {
		let documentPart = args.documentPart;
		let userId = '';

		let blurbs = {};
		try {

			blurbs = JSON.parse(args.blurbs);

		} catch (err) {
			////console.log(err);
		}

		////console.log(blurbs);
		try {

			userId = args.userStore.user.id;

		} catch (err) {
			////console.log(err);
		}




		let canEdit = true;
		if (args.userStore) {

			//canEdit = args.stores.bookStore.currentBookDraft.mostRecentWritingDocumentPart.bookDraftDocumentPart.hasPermissionInBoxSet(userId,'edit');

		}
		////console.log(documentPart);


		//Create wrapper div
		let blurbsNode = document.createElement("DIV");
		//blurbsNode.setAttribute('style', 'display:flex; flex-direction:column');
		blurbsNode.setAttribute('class', 'blot-wrapper');

		let deleteIcon = this.createDeleteIcon({ uuid: args.uuid });

		blurbsNode.appendChild(deleteIcon);

		let contributorDivsNode = document.createElement("DIV");
		contributorDivsNode.setAttribute('class', 'blurbs-content');
		blurbsNode.appendChild(contributorDivsNode);
		if (blurbs && blurbs.records) {

			blurbs.records.forEach((record) => {

				let contributor = record.item;

				let contributorDiv = this.createBlurb(args, contributor, blurbs);
				contributorDivsNode.appendChild(contributorDiv);







			});
		}
		let footerCenterControls = <span></span>;
		if (canEdit) {

			footerCenterControls = this.createButton({
				title: '+ BLURB',
				clickHandler: () => {
					//new DeltaToDom().deleteBlurb(args,blurb,blurbsNode);
					////console.log('create a contributor');

					let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
					let blot = Parchment.find(node);

					//console.log(blot);
					let newContributor = blot.addContributor();

					let newContributorDiv = this.createBlurb(args, newContributor.item, blurbs);
					contributorDivsNode.appendChild(newContributorDiv);
				}
			})



		}



		blurbsNode.appendChild(this.createBlotFooter({
			args,
			centerControls: footerCenterControls
		}));

		return blurbsNode;

	}


	createAlsoby(args, contributor, alsobys) {
		try {
			let that = this;
			//console.log(args);
			let allBooks = args.stores.bookStore.authoredBooks;
			let bookDraft = args.documentPart.bookDraftDocumentPart.bookDraft;
			let mappedImages = bookDraft.mappedImages;
			let mappedSrc = 'missingImage';
			////console.log(contributor);
			if (contributor.coverUrl != null && contributor.coverUrl != undefined && contributor.coverUrl != '') {
				mappedSrc = contributor.coverUrl;
			}
			let bookId = contributor.bookId;
			let book = null;
			try {
				book = allBooks.filter(f => f.id == bookId)[0];
				contributor.title = book.title;

				if (book.coverThumbnailUrl != null && book.coverThumbnailUrl != undefined && book.coverThumbnailUrl != '') {
					mappedSrc = book.coverThumbnailUrl;
				}


			} catch (err) {
				//console.log(err);
			}

			//book.subtitle = 'This is a subtitle';
			let contributorDiv = document.createElement("div");
			contributorDiv.setAttribute('class', 'blot-lineitem blot-lineitem-alsoby');
			contributorDiv.setAttribute('contenteditable', 'false');
			if (contributor == null) {
				return contributorDiv;
			}
			try {
				contributorDiv.setAttribute('id', 'contributor_' + contributor.id);
			} catch (err) {

			}




			let imageSrc = './img/' + config.default.images.MISSING_IMAGE_NAME;


			if (book != null) {

				if (mappedSrc.includes('&&&userImages')) {
					//mappedSrc = mappedSrc.split('&&&userImages')[0];
				}
				if (mappedImages[mappedSrc]) {

					imageSrc = mappedImages[mappedSrc];
				}
				if (imageSrc.includes('&&&userImages')) {
					imageSrc = imageSrc.split('&&&userImages')[0];
				}
				//console.log(imageSrc);
				let img = document.createElement("img");
				img.setAttribute('id', args.uuid + 'Image');
				img.setAttribute('style', 'max-width:4em;');
				img.setAttribute('class', 'blot-lineitem-alsoby-thumbnail');
				img.setAttribute('src', imageSrc);

				img.addEventListener('click', function (e) {
					e.stopPropagation();
					e.preventDefault();
					////console.log(e);
					// $("#master_quillImageProcessor").attr("blotImageDivId", args.uuid);
					// $("#master_quillImageProcessor").attr("alsoById", contributor.id);
					// $("#master_quillImageProcessor").attr("bookId", bookId);
					// $("#master_quillImageProcessor").click()

				})
				contributorDiv.appendChild(img);

			} else {

				if (mappedImages[mappedSrc]) {

					imageSrc = mappedImages[mappedSrc];
				}
				let img = document.createElement("img");
				img.setAttribute('id', args.uuid + 'Image');
				img.setAttribute('width', '50px');
				img.setAttribute('src', imageSrc);

				img.addEventListener('click', function (e) {
					e.stopPropagation();
					e.preventDefault();
					////console.log(e);
					// $("#master_quillImageProcessor").attr("blotImageDivId", args.uuid);
					// $("#master_quillImageProcessor").attr("alsoById", contributor.id);
					// $("#master_quillImageProcessor").attr("bookId", bookId);
					// $("#master_quillImageProcessor").click()

				})
				contributorDiv.appendChild(img);



			}



			let contentsDiv = document.createElement("div");
			contentsDiv.setAttribute('class', 'blot-lineitem-alsoby-contents');

			contributorDiv.appendChild(contentsDiv);

			let nameTextFieldResults = null;
			let subtitleTextFieldResults = null;

			let subtitleDiv = document.createElement("div");
			subtitleDiv.setAttribute('class', 'blot-lineitem-title-subtitle-div');


			if (book != null) {

				let wrapperSpan = document.createElement("span");
				wrapperSpan.setAttribute('class', 'blot-lineitem-alsoby-title');
				wrapperSpan.innerHTML = book.title;
				subtitleDiv.appendChild(wrapperSpan);



				if (book.subtitle != null && book.subtitle.trim() != '') {

					let subtitleWrapperSpan = document.createElement("span");
					subtitleWrapperSpan.setAttribute('class', 'blot-lineitem-alsoby-subtitle');
					subtitleWrapperSpan.innerHTML = book.subtitle;
					subtitleDiv.appendChild(subtitleWrapperSpan);


				}






				// nameTextFieldResults = this.createAutoTextField({
				// 	label: 'Title:',
				// 	args: args,
				// 	disabled: true,
				// 	entity: book,
				// 	fieldName: 'title',
				// 	placeholder: '[Book title]',
				// 	value: book.title,
				// 	blurHandler: (blot, entity) => {
				// 		//blot.updateFromDom(entity);
				// 	},
				// 	changeHandler: null
				// });

				// subtitleTextFieldResults = this.createAutoTextField({
				// 	label: 'Subtitle:',
				// 	args: args,
				// 	disabled: true,
				// 	entity: book,
				// 	fieldName: 'subTitle',
				// 	//placeholder: '[Book subtitle]',
				// 	value: null,
				// 	blurHandler: (blot, entity) => {
				// 		//blot.updateFromDom(entity);
				// 	},
				// 	changeHandler: null
				// });


			} else {


				// nameTextFieldResults = this.createAutoTextField({
				// 	label: 'Title:',
				// 	args: args,
				// 	entity: contributor,
				// 	fieldName: 'title',
				// 	placeholder: '[Book title]',
				// 	value: null,
				// 	blurHandler: (blot, entity) => {
				// 		blot.updateFromDom(entity);
				// 	},
				// 	changeHandler: null
				// });

				// subtitleTextFieldResults = this.createAutoTextField({
				// 	label: 'Subtitle:',
				// 	args: args,
				// 	entity: contributor,
				// 	fieldName: 'subtitle',
				// 	placeholder: '[Book subtitle]',
				// 	value: null,
				// 	blurHandler: (blot, entity) => {
				// 		blot.updateFromDom(entity);
				// 	},
				// 	changeHandler: null
				// });



			}




			// nameTextFieldResults.textarea.setAttribute('class', 'blot-lineitem-textarea blot-lineitem-textarea-centered');
			// let nameDiv = nameTextFieldResults.wrapperSpan;
			// subtitleDiv.appendChild(nameDiv);


			// if (book.subtitle != null && book.subtitle.trim() != '') {

			// 	subtitleTextFieldResults.textarea.setAttribute('class', 'blot-lineitem-textarea blot-lineitem-title-subtitle-textarea  blot-lineitem-textarea-centered');
			// 	subtitleDiv.appendChild(subtitleTextFieldResults.wrapperSpan);


			// }

			contentsDiv.appendChild(subtitleDiv);
			let checked = false;
			if (contributor.isStoreLink && contributor.isStoreLink == 'true') {
				checked = true;
			}

			let includeBookCheckbox = this.createCheckbox({
				label: 'Is store link',
				args: args,
				entity: contributor,
				fieldName: 'isStoreLink',
				checked: checked,
				blurHandler: null,
				changeHandler: (blot, entity) => {
					//console.log('handling this blur...');
					blot.updateFromDom(entity);
				}
			});

			includeBookCheckbox.setAttribute('style', 'margin-top:.5em');
			contentsDiv.appendChild(includeBookCheckbox);



			// let controlsDiv = this.createListControls({
			// 	moveUpHandler: (ev) => {
			// 		//console.log(contributor);
			// 		//if(contributor.orderNumber!=1){
			// 		let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
			// 		let blot = Parchment.find(node);

			// 		let alsobys = [];
			// 		if ((contributor.orderNumber) == 1) {

			// 			alsobys = blot.move(contributor.orderNumber - 1, blot.getContributors().length - 1);

			// 		} else {

			// 			alsobys = blot.move(contributor.orderNumber - 1, contributor.orderNumber - 2);

			// 		}

			// 		args.alsobys = JSON.stringify(alsobys);
			// 		blot.replaceWith('alsobys', args);
			// 		//}

			// 	},
			// 	moveDownHandler: (ev) => {
			// 		//console.log(contributor);

			// 		let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
			// 		let blot = Parchment.find(node);

			// 		//let contributors = blot.move(contributor.orderNumber-1,contributor.orderNumber);
			// 		let alsobys = [];
			// 		if ((contributor.orderNumber) == blot.getContributors().length) {

			// 			alsobys = blot.move(contributor.orderNumber - 1, 0);

			// 		} else {

			// 			alsobys = blot.move(contributor.orderNumber - 1, contributor.orderNumber);

			// 		}

			// 		args.alsobys = JSON.stringify(alsobys);
			// 		blot.replaceWith('alsobys', args);

			// 	},
			// 	deleteHandler: (ev) => {
			// 		////console.log('deleting...');
			// 		let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
			// 		let blot = Parchment.find(node);
			// 		if (blot.getContributors().length == 1) {
			// 			blot.remove();
			// 			return;
			// 		}
			// 		let newAlsoBys = blot.deleteContributor(contributor.id);
			// 		args.alsobys = newAlsoBys;
			// 		blot.replaceWith('alsobys', args);


			// 	}
			// });

			// contributorDiv.appendChild(controlsDiv);
			// contributorDiv.addEventListener("mouseenter", function (ev) {
			// 	//$('.blot-lineitem-controls').hide();
			// 	$(controlsDiv).show();
			// 	$(controlsDiv).focus();

			// });
			// contributorDiv.addEventListener("mouseleave", function (ev) {
			// 	//$(controlsDiv).hide();
			// 	$(controlsDiv).focus();

			// });




			contentsDiv.appendChild(this.createBlotListControls({
				args,
				moveUpHandler: (ev) => {
					//console.log(contributor);
					//if(contributor.orderNumber!=1){
					let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
					let blot = Parchment.find(node);

					let alsobys = [];
					if ((contributor.orderNumber) == 1) {

						alsobys = blot.move(contributor.orderNumber - 1, blot.getContributors().length - 1);

					} else {

						alsobys = blot.move(contributor.orderNumber - 1, contributor.orderNumber - 2);

					}

					args.alsobys = JSON.stringify(alsobys);
					blot.replaceWith('alsobys', args);
					//}

				},
				moveDownHandler: (ev) => {
					//console.log(contributor);

					let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
					let blot = Parchment.find(node);

					//let contributors = blot.move(contributor.orderNumber-1,contributor.orderNumber);
					let alsobys = [];
					if ((contributor.orderNumber) == blot.getContributors().length) {

						alsobys = blot.move(contributor.orderNumber - 1, 0);

					} else {

						alsobys = blot.move(contributor.orderNumber - 1, contributor.orderNumber);

					}

					args.alsobys = JSON.stringify(alsobys);
					blot.replaceWith('alsobys', args);

				},
				deleteHandler: (ev) => {
					////console.log('deleting...');
					let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
					let blot = Parchment.find(node);
					if (blot.getContributors().length == 1) {
						blot.remove();
						return;
					}
					let newAlsoBys = blot.deleteContributor(contributor.id);
					args.alsobys = newAlsoBys;
					blot.replaceWith('alsobys', args);


				}
			}));

			return contributorDiv;

		} catch (err) {
			//console.log(err);
			let errorDiv = document.createElement("div");
			errorDiv.appendChild(document.createTextNode("Error creating alsoby"));
			return errorDiv;
		}


	}

	createContributor(args, contributor, contributors) {

		//console.log(args);

		let { name, role } = contributor;

		let that = this;
		let contributorDiv = document.createElement("div");
		contributorDiv.setAttribute('class', 'blot-lineitem blot-contributor-lineitem');
		if (contributor == null) {
			return contributorDiv;
		}
		try {
			contributorDiv.setAttribute('id', 'contributor_' + contributor.id);
		} catch (err) {

		}



		//parentNode.appendChild(contributorDiv);
		let fieldsDiv = document.createElement("div");
		fieldsDiv.setAttribute('class', 'blot-lineitem-fields ');


		let nameTextFieldResults = this.createAutoTextField({
			label: 'Name:',
			args: args,
			entity: contributor,
			fieldName: 'name',
			placeholder: '[Contributor name]',
			value: contributor.name,
			blurHandler: (blot, entity) => {
				blot.updateContributor(entity);
			},
			changeHandler: null
		});

		nameTextFieldResults.textarea.setAttribute('class', 'blot-lineitem-textarea blot-content-centered');
		let nameDiv = nameTextFieldResults.wrapperSpan;
		fieldsDiv.appendChild(nameDiv);

		let roleTextFieldResults = this.createAutoTextField({
			label: 'Role:',
			args: args,
			entity: contributor,
			fieldName: 'role',
			placeholder: '[Contributor role]',
			value: contributor.role,
			blurHandler: (blot, entity) => {
				blot.updateContributor(entity);
			}
		});
		let roleDiv = roleTextFieldResults.wrapperSpan;
		roleTextFieldResults.textarea.setAttribute('class', 'blot-lineitem-textarea blot-content-centered');
		fieldsDiv.appendChild(roleDiv);

		contributorDiv.appendChild(fieldsDiv);



		// let controlsDiv = this.createListControls({
		// 	moveUpHandler: (ev) => {
		// 		//console.log(contributor);
		// 		//if(contributor.orderNumber!=1){
		// 		let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
		// 		let blot = Parchment.find(node);


		// 		if ((contributor.orderNumber) == 1) {

		// 			contributors = blot.move(contributor.orderNumber - 1, blot.getContributors().length - 1);

		// 		} else {

		// 			contributors = blot.move(contributor.orderNumber - 1, contributor.orderNumber - 2);

		// 		}

		// 		args.contributors = JSON.stringify(contributors);
		// 		blot.replaceWith('contributors', args);
		// 		//}

		// 	},
		// 	moveDownHandler: (ev) => {
		// 		//console.log(contributor);

		// 		let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
		// 		let blot = Parchment.find(node);

		// 		//let contributors = blot.move(contributor.orderNumber-1,contributor.orderNumber);

		// 		if ((contributor.orderNumber) == blot.getContributors().length) {

		// 			contributors = blot.move(contributor.orderNumber - 1, 0);

		// 		} else {

		// 			contributors = blot.move(contributor.orderNumber - 1, contributor.orderNumber);

		// 		}

		// 		args.contributors = JSON.stringify(contributors);
		// 		blot.replaceWith('contributors', args);

		// 	},
		// 	deleteHandler: (ev) => {
		// 		let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
		// 		let blot = Parchment.find(node);
		// 		if (blot.getContributors().length == 1) {
		// 			blot.remove();
		// 			return;
		// 		}

		// 		let newContributors = blot.deleteContributor(contributor.id);


		// 		args.contributors = JSON.stringify(newContributors);
		// 		blot.replaceWith('contributors', args);


		// 	}
		// });

		// contributorDiv.appendChild(controlsDiv);


		// contributorDiv.addEventListener("mouseenter", function (ev) {
		// 	//$('.blot-lineitem-controls').hide();
		// 	$(controlsDiv).show();
		// 	$(controlsDiv).focus();

		// });
		// contributorDiv.addEventListener("mouseleave", function (ev) {
		// 	//$(controlsDiv).hide();
		// 	$(controlsDiv).focus();

		// });

		contributorDiv.appendChild(this.createBlotListControls({
			args,
			moveUpHandler: (ev) => {
				//console.log(contributor);
				//if(contributor.orderNumber!=1){
				let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
				let blot = Parchment.find(node);


				if ((contributor.orderNumber) == 1) {

					contributors = blot.move(contributor.orderNumber - 1, blot.getContributors().length - 1);

				} else {

					contributors = blot.move(contributor.orderNumber - 1, contributor.orderNumber - 2);

				}

				args.contributors = JSON.stringify(contributors);
				blot.replaceWith('contributors', args);
				//}

			},
			moveDownHandler: (ev) => {
				//console.log(contributor);

				let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
				let blot = Parchment.find(node);

				//let contributors = blot.move(contributor.orderNumber-1,contributor.orderNumber);

				if ((contributor.orderNumber) == blot.getContributors().length) {

					contributors = blot.move(contributor.orderNumber - 1, 0);

				} else {

					contributors = blot.move(contributor.orderNumber - 1, contributor.orderNumber);

				}

				args.contributors = JSON.stringify(contributors);
				blot.replaceWith('contributors', args);

			},
			deleteHandler: (ev) => {
				let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
				let blot = Parchment.find(node);
				if (blot.getContributors().length == 1) {
					blot.remove();
					return;
				}

				let newContributors = blot.deleteContributor(contributor.id);


				args.contributors = JSON.stringify(newContributors);
				blot.replaceWith('contributors', args);


			}
		}));

		return contributorDiv;


	}

	createContributorOld(args, contributor, contributors) {

		let that = this;
		let contributorDiv = document.createElement("div");
		contributorDiv.setAttribute('class', 'blot-lineitem');
		if (contributor == null) {
			return contributorDiv;
		}
		try {
			contributorDiv.setAttribute('id', 'contributor_' + contributor.id);
		} catch (err) {

		}



		//parentNode.appendChild(contributorDiv);

		let nameDiv = this.createTextField({
			label: 'name:',
			args: args,
			entity: contributor,
			fieldName: 'name',
			value: contributor.name,
			blurHandler: (blot, entity) => {
				blot.updateContributor(entity);
			},
			changeHandler: null
		});

		contributorDiv.appendChild(nameDiv);

		let roleDiv = this.createTextField({
			label: 'role:',
			args: args,
			entity: contributor,
			fieldName: 'role',
			value: contributor.role,
			blurHandler: (blot, entity) => {
				blot.updateContributor(entity);
			},
			changeHandler: null
		});

		contributorDiv.appendChild(roleDiv);

		let removeBlurbDiv = document.createElement("DIV");
		removeBlurbDiv.innerHTML = '<svg class="MuiSvgIcon-root MuiSvgIcon-colorDisabled" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M9,3V4H4V6H5V19C5,20.1 5.9,21 7,21H17C18.1,21 19,20.1 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"></path></svg>';
		removeBlurbDiv.addEventListener('click', function (ev) {

			////console.log('deleting...');
			let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
			let blot = Parchment.find(node);

			let newContributors = blot.deleteContributor(contributor.id);


			args.contributors = JSON.stringify(newContributors);
			blot.replaceWith('contributors', args);


			/*let contributorDiv = $("#contributor_"+contributor.id)[0];
			let parentNode = contributorDiv.parentNode;
			parentNode.removeChild(contributorDiv);*/



		})
		contributorDiv.appendChild(removeBlurbDiv);






		let upDiv = document.createElement("span");
		upDiv.innerHTML = 'UP';
		if (contributor.orderNumber != 1) {
			upDiv.addEventListener('click', function (ev) {
				//console.log(contributor);

				let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
				let blot = Parchment.find(node);

				let newContributors = blot.move(contributor.orderNumber - 1, contributor.orderNumber - 2);

				args.contributors = JSON.stringify(newContributors);
				blot.replaceWith('contributors', args);

			});
		}

		contributorDiv.appendChild(upDiv);

		let dnDiv = document.createElement("span");
		dnDiv.innerHTML = 'DN';
		if (contributor.orderNumber != contributors.records.length) {
			dnDiv.addEventListener('click', function (ev) {
				//console.log(contributor);

				let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
				let blot = Parchment.find(node);

				let newContributors = blot.move(contributor.orderNumber - 1, contributor.orderNumber);

				args.contributors = JSON.stringify(newContributors);
				blot.replaceWith('contributors', args);

			});
		}

		contributorDiv.appendChild(dnDiv);

		let orderDiv = document.createElement("span");
		orderDiv.innerHTML = contributor.orderNumber;
		contributorDiv.appendChild(orderDiv);






		return contributorDiv;


	}

	createBlurb(args, contributor, blurbs) {

		////console.log(args);


		let that = this;
		let contributorDiv = document.createElement("div");
		contributorDiv.setAttribute('class', 'blot-lineitem');
		if (contributor == null) {
			return contributorDiv;
		}
		try {
			contributorDiv.setAttribute('id', 'contributor_' + contributor.id);
		} catch (err) {

		}



		//parentNode.appendChild(contributorDiv);
		let fieldsDiv = document.createElement("div");
		fieldsDiv.setAttribute('class', 'blot-lineitem-fields');

		let nameDiv = this.createBlockquoteField({
			label: 'comment:',
			args: args,
			entity: contributor,
			fieldName: 'comment',
			value: contributor.comment,
			blurHandler: (blot, entity) => {
				blot.updateContributor(entity);
			},
			changeHandler: null
		});

		fieldsDiv.appendChild(nameDiv);

		let roleDiv = this.createAttributionField({
			label: 'source:',
			args: args,
			entity: contributor,
			fieldName: 'source',
			value: contributor.source,
			blurHandler: (blot, entity) => {
				blot.updateContributor(entity);
			},
			changeHandler: null
		});

		fieldsDiv.appendChild(roleDiv);
		contributorDiv.appendChild(fieldsDiv);

		// let controlsDiv = this.createListControls({
		// 	moveUpHandler: (ev) => {
		// 		//console.log(contributor);
		// 		//if(contributor.orderNumber!=1){
		// 		let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
		// 		let blot = Parchment.find(node);

		// 		let newblurbs = [];
		// 		//let newblurbs = blot.move(contributor.orderNumber-1,contributor.orderNumber-2);

		// 		if ((contributor.orderNumber) == 1) {

		// 			newblurbs = blot.move(contributor.orderNumber - 1, blot.getContributors().length - 1);

		// 		} else {

		// 			newblurbs = blot.move(contributor.orderNumber - 1, contributor.orderNumber - 2);

		// 		}

		// 		args.blurbs = JSON.stringify(newblurbs);
		// 		blot.replaceWith('blurbs', args);
		// 		//}
		// 	},
		// 	moveDownHandler: (ev) => {
		// 		//console.log(contributor);

		// 		let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
		// 		let blot = Parchment.find(node);

		// 		let newblurbs = [];
		// 		//let newblurbs = blot.move(contributor.orderNumber-1,contributor.orderNumber);

		// 		if ((contributor.orderNumber) == blot.getContributors().length) {

		// 			newblurbs = blot.move(contributor.orderNumber - 1, 0);

		// 		} else {

		// 			newblurbs = blot.move(contributor.orderNumber - 1, contributor.orderNumber);

		// 		}

		// 		args.blurbs = JSON.stringify(newblurbs);
		// 		blot.replaceWith('blurbs', args);
		// 	},
		// 	deleteHandler: (ev) => {
		// 		let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
		// 		let blot = Parchment.find(node);

		// 		//console.log(blot);
		// 		//console.log(blot.getContributors().length)
		// 		if (blot.getContributors().length == 1) {
		// 			blot.remove();
		// 			return;
		// 		}
		// 		let newblurbs = blot.deleteContributor(contributor.id);


		// 		args.blurbs = JSON.stringify(newblurbs);
		// 		blot.replaceWith('blurbs', args);
		// 		//console.log(blot);

		// 		blot = Parchment.find(node);

		// 	}
		// });

		// contributorDiv.appendChild(controlsDiv);

		// contributorDiv.addEventListener("mouseenter", function (ev) {
		// 	//$('.blot-lineitem-controls').hide();
		// 	$(controlsDiv).show();
		// 	$(controlsDiv).focus();

		// });
		// contributorDiv.addEventListener("mouseleave", function (ev) {
		// 	//$(controlsDiv).hide();
		// 	$(controlsDiv).focus();

		// });

		let controlsContaienrDiv = document.createElement("div");
		controlsContaienrDiv.setAttribute('style', 'width:100%; display:flex; justify-content:flex-end');

		controlsContaienrDiv.appendChild(this.createBlotListControls({
			args,
			moveUpHandler: (ev) => {
				//console.log(contributor);
				//if(contributor.orderNumber!=1){
				let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
				let blot = Parchment.find(node);

				let newblurbs = [];
				//let newblurbs = blot.move(contributor.orderNumber-1,contributor.orderNumber-2);

				if ((contributor.orderNumber) == 1) {

					newblurbs = blot.move(contributor.orderNumber - 1, blot.getContributors().length - 1);

				} else {

					newblurbs = blot.move(contributor.orderNumber - 1, contributor.orderNumber - 2);

				}

				args.blurbs = JSON.stringify(newblurbs);
				blot.replaceWith('blurbs', args);
				//}
			},
			moveDownHandler: (ev) => {
				//console.log(contributor);

				let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
				let blot = Parchment.find(node);

				let newblurbs = [];
				//let newblurbs = blot.move(contributor.orderNumber-1,contributor.orderNumber);

				if ((contributor.orderNumber) == blot.getContributors().length) {

					newblurbs = blot.move(contributor.orderNumber - 1, 0);

				} else {

					newblurbs = blot.move(contributor.orderNumber - 1, contributor.orderNumber);

				}

				args.blurbs = JSON.stringify(newblurbs);
				blot.replaceWith('blurbs', args);
			},
			deleteHandler: (ev) => {
				let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
				let blot = Parchment.find(node);

				//console.log(blot);
				//console.log(blot.getContributors().length)
				if (blot.getContributors().length == 1) {
					blot.remove();
					return;
				}
				let newblurbs = blot.deleteContributor(contributor.id);


				args.blurbs = JSON.stringify(newblurbs);
				blot.replaceWith('blurbs', args);
				//console.log(blot);

				blot = Parchment.find(node);

			}
		}));

		contributorDiv.appendChild(controlsContaienrDiv);
		return contributorDiv;


	}

	createListControls({ moveUpHandler, moveDownHandler, deleteHandler }) {
		let controlsDiv = document.createElement("DIV");
		controlsDiv.setAttribute('class', 'blot-lineitem-controls');
		controlsDiv.setAttribute('stylex', 'display:none');


		var upDiv = document.createElement('div');
		//upDiv.innerHTML = '<svg><path fill="#6F6F6F" ng-attr-fill="{{icon.color}}" ng-attr-d="{{icon.data}}" d="M15,20H9V12H4.16L12,4.16L19.84,12H15V20Z"></path></svg>';
		upDiv.innerHTML = '<svg class="MuiSvgIcon-root MuiSvgIcon-colorDisabled" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M14,20H10V11L6.5,14.5L4.08,12.08L12,4.16L19.92,12.08L17.5,14.5L14,11V20Z"></path></svg>';

		upDiv.addEventListener('click', function (ev) {

			moveUpHandler(ev);

		});




		controlsDiv.appendChild(upDiv);

		var dnDiv = document.createElement('div');
		//upDiv.innerHTML = '<svg><path fill="#6F6F6F" ng-attr-fill="{{icon.color}}" ng-attr-d="{{icon.data}}" d="M15,20H9V12H4.16L12,4.16L19.84,12H15V20Z"></path></svg>';
		dnDiv.innerHTML = '<svg class="MuiSvgIcon-root MuiSvgIcon-colorDisabled" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10,4H14V13L17.5,9.5L19.92,11.92L12,19.84L4.08,11.92L6.5,9.5L10,13V4Z"></path></svg>';

		dnDiv.addEventListener('click', function (ev) {

			moveDownHandler(ev);

		});





		controlsDiv.appendChild(dnDiv);


		let removeBlurbDiv = document.createElement("DIV");
		removeBlurbDiv.innerHTML = '<svg class="MuiSvgIcon-root MuiSvgIcon-colorDisabled" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M9,3V4H4V6H5V19C5,20.1 5.9,21 7,21H17C18.1,21 19,20.1 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"></path></svg>';
		removeBlurbDiv.addEventListener('click', function (ev) {

			deleteHandler(ev);


		})
		controlsDiv.appendChild(removeBlurbDiv);



		return controlsDiv;


	}



	createBlotFooter({
		args,
		centerControls
	}) {
		let blurbFooter = document.createElement("DIV");

		blurbFooter.setAttribute('class', 'blot-footer-wrapper');
		blurbFooter.setAttribute('contenteditable', 'false');
		//let deleteIcon = $('<svg class="MuiSvgIcon-root MuiSvgIcon-colorDisabled MuiSvgIcon-fontSizeMedium css-1db085k-MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="HighlightOffIcon"><path d="M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg>');

		let leftContentDiv = document.createElement("DIV");
		//leftContentDiv.setAttribute('style', 'flex:4');
		leftContentDiv.setAttribute('class', 'blot-footer-section');

		blurbFooter.appendChild(leftContentDiv);

		let centerContentDiv = document.createElement("DIV");
		//centerContentDiv.setAttribute('style', 'flex:4; dispay:flex;justify-content:center');
		centerContentDiv.setAttribute('class', 'blot-footer-section');
		if (centerControls) {
			blurbFooter.appendChild(centerControls);
		}



		let deleteIcon = document.createElement("DIV");
		deleteIcon.setAttribute('style', 'flex:4; display:flex;justify-content:flex-end;width:2em');
		deleteIcon.setAttribute('class', 'blot-footer-section');

		// let deleteSpan = document.createElement("SPAN");
		// deleteSpan.innerHTML = '<svg fillx="#787878" style="color:white;width:2em" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="HighlightOffIcon"><path d="M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg>';
		// deleteSpan.addEventListener('click', function (ev) {

		// 	console.log('deleting...');
		// 	let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
		// 	let blot = Parchment.find(node);

		// 	blot.remove();


		// })
		// deleteIcon.appendChild(deleteSpan);
		blurbFooter.appendChild(deleteIcon);

		return blurbFooter;
	}

	createBlotListControls({
		args,
		moveUpHandler,
		moveDownHandler,
		deleteHandler
	}) {
		let wrapper = document.createElement("DIV");
		wrapper.setAttribute('class', 'blot-list-controls-wrapper');

		//let deleteIcon = $('<svg class="MuiSvgIcon-root MuiSvgIcon-colorDisabled MuiSvgIcon-fontSizeMedium css-1db085k-MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="HighlightOffIcon"><path d="M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg>');


		let upIcon = document.createElement("DIV");
		upIcon.setAttribute('class', 'blot-list-controls-wrapper-button');
		upIcon.innerHTML = '<svg class="MuiSvgIcon-root MuiSvgIcon-colorDisabled MuiSvgIcon-fontSizeSmall css-me1rrz-MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="ArrowUpwardIcon"><path d="m4 12 1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"></path></svg>';
		upIcon.addEventListener('click', function (ev) {

			if (moveUpHandler) {
				moveUpHandler();
			}


		})
		wrapper.appendChild(upIcon);

		let downIcon = document.createElement("DIV");
		downIcon.setAttribute('class', 'blot-list-controls-wrapper-button');
		downIcon.innerHTML = '<svg class="MuiSvgIcon-root MuiSvgIcon-colorDisabled MuiSvgIcon-fontSizeSmall css-me1rrz-MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="ArrowDownwardIcon"><path d="m20 12-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path></svg>';
		downIcon.addEventListener('click', function (ev) {

			if (moveDownHandler) {
				moveDownHandler();
			}


		})
		wrapper.appendChild(downIcon);

		let deleteIcon = document.createElement("DIV");
		deleteIcon.setAttribute('class', 'blot-list-controls-wrapper-button');
		deleteIcon.innerHTML = '<svg fill="#787878" class="MuiSvgIcon-root MuiSvgIcon-colorDisabled MuiSvgIcon-fontSizeMedium css-1db085k-MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="HighlightOffIcon"><path d="M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg>';
		deleteIcon.addEventListener('click', function (ev) {

			if (deleteHandler) {
				deleteHandler();
			}


		})
		wrapper.appendChild(deleteIcon);
		return wrapper;
	}

	convertContributors(args) {
		let documentPart = args.documentPart;
		let userId = '';

		let contributors = {};
		try {

			contributors = JSON.parse(args.contributors);

		} catch (err) {
			////console.log(err);
		}

		////console.log(contributors);
		try {

			userId = args.userStore.user.id;

		} catch (err) {
			////console.log(err);
		}




		let canEdit = true;
		if (args.userStore) {

			//canEdit = args.stores.bookStore.currentBookDraft.mostRecentWritingDocumentPart.bookDraftDocumentPart.hasPermissionInBoxSet(userId,'edit');

		}
		////console.log(documentPart);


		//Create wrapper div
		let blurbsNode = document.createElement("DIV");
		//blurbsNode.setAttribute('style', 'display:flex; flex-direction:column');
		blurbsNode.setAttribute('class', 'blot-wrapper');


		// let deleteIcon = document.createElement("DIV");
		// deleteIcon.setAttribute('style', 'flex:4; display:flex;justify-content:flex-end;width:2em');
		// deleteIcon.setAttribute('class', 'blot-footer-section');

		// let deleteSpan = document.createElement("SPAN");
		// deleteSpan.innerHTML = '<svg fillx="#787878" style="color:white;width:2em" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="HighlightOffIcon"><path d="M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg>';
		// deleteSpan.addEventListener('click', function (ev) {

		// 	console.log('deleting...');
		// 	let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
		// 	let blot = Parchment.find(node);

		// 	blot.remove();


		// })

		let deleteIcon = this.createDeleteIcon({ uuid: args.uuid });

		blurbsNode.appendChild(deleteIcon);

		// let blurbDelete = document.createElement("DIV");
		// blurbDelete.setAttribute('class', 'blot-delete-control');

		let contributorDivsNode = document.createElement("DIV");
		blurbsNode.appendChild(contributorDivsNode);

		if (contributors && contributors.records) {

			contributors.records.forEach((record) => {

				let contributor = record.item;

				let contributorDiv = this.createContributor(args, contributor, contributors);
				contributorDivsNode.appendChild(contributorDiv);



			});
		}

		let footerCenterControls = <div></div>;

		if (canEdit) {

			footerCenterControls = this.createButton({
				title: '+ CONTRIBUTOR',
				clickHandler: () => {
					//new DeltaToDom().deleteBlurb(args,blurb,blurbsNode);
					////console.log('create a contributor');

					let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
					let blot = Parchment.find(node);

					////console.log(blot);
					let newContributor = blot.addContributor();

					let newContributorDiv = this.createContributor(args, newContributor.item, contributors);
					contributorDivsNode.appendChild(newContributorDiv);
				}
			})


			// blurbsNode.appendChild(
			// 	this.createButton({
			// 		title: '+ CONTRIBUTOR',
			// 		clickHandler: () => {
			// 			//new DeltaToDom().deleteBlurb(args,blurb,blurbsNode);
			// 			////console.log('create a contributor');

			// 			let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
			// 			let blot = Parchment.find(node);

			// 			////console.log(blot);
			// 			let newContributor = blot.addContributor();

			// 			let newContributorDiv = this.createContributor(args, newContributor.item, contributors);
			// 			contributorDivsNode.appendChild(newContributorDiv);
			// 		}
			// 	})
			// )
		}


		//html+='<button id="addBlurbBtn" name="addBlurbBtn">ADD BLURB</button>';




		blurbsNode.appendChild(this.createBlotFooter(
			{
				args,
				centerControls: footerCenterControls
			}));

		return blurbsNode;

	}

	createDeleteIcon({ uuid }) {

		let deleteIcon = document.createElement("DIV");
		deleteIcon.setAttribute('style', 'flex:4; display:flex;justify-content:flex-end;width:2em;position:absolute;bottom:1em;right:1em');
		deleteIcon.setAttribute('class', 'blot-footer-section');


		let deleteSpan = document.createElement("SPAN");
		deleteSpan.innerHTML = '<svg fillx="#787878" style="color:white;width:2em" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="HighlightOffIcon"><path d="M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg>';
		deleteSpan.addEventListener('click', function (ev) {

			console.log('deleting...');
			let node = $("div").find("[data-uuid='" + uuid + "']")[0];
			let blot = Parchment.find(node);

			blot.remove();


		})

		deleteIcon.appendChild(deleteSpan);

		return deleteIcon;


	}
	createButton({ title, clickHandler }) {

		let addBlurbDiv = document.createElement("DIV");
		addBlurbDiv.setAttribute('style', 'display:flex;flex-direction:row;justify-content:center');
		addBlurbDiv.setAttribute('class', 'blot-lineitem-button');
		addBlurbDiv.setAttribute('contenteditable', 'false');
		addBlurbDiv.innerHTML = title;
		addBlurbDiv.addEventListener('click', function (ev) {
			////console.log('click');
			if (clickHandler) {
				clickHandler(ev);
			}

		})

		return addBlurbDiv;

	}






	createCheckbox({ args, entity, fieldName, checked, label = '', canEdit = true, changeHandler }) {

		let wrapperSpan = document.createElement("span");
		//wrapperSpan.setAttribute('style', 'display:flex;flex-direction:row;justify-content:flex-start;');
		wrapperSpan.setAttribute('class', 'blot-lineitem-checkbox-wrapper');

		let labelDiv = document.createElement("span");
		labelDiv.innerHTML = label;
		wrapperSpan.appendChild(labelDiv);

		let checkbox = document.createElement("input");
		checkbox.setAttribute('class', 'blot-lineitem-checkbox');
		if (!canEdit) {
			checkbox.setAttribute('disabled', '');
		}
		checkbox.setAttribute('type', 'checkbox');
		//if(checked){
		$(checkbox).prop('checked', checked);
		//}

		//checkbox.setAttribute('style', 'display:flex;flex-direction:row;justify-content:flex-start;');
		//checkbox.innerHTML = blurb.comment;
		//checkbox.innerHTML = value;
		checkbox.addEventListener('click', function (ev) {

			/*if(blurHandler){
				blurHandler();
			}*/

			//console.log(checkbox.value);
			if ($(this).is(":checked")) {
				//console.log("Checkbox is checked.");
				entity[fieldName] = 'true';
				//entity.useBookPublisherImage = 'true';
			} else {

				entity[fieldName] = 'false';
				//entity.useBookPublisherImage = 'true';
			}


			let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
			let blot = Parchment.find(node);
			if (changeHandler) {
				changeHandler(blot, entity);
			}


			/*entity[fieldName] = checkbox.value;

			let newValue = checkbox.value;
			let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
			let blot = Parchment.find(node);

			////console.log(blot);

			if(blurHandler){
				blurHandler(blot,entity);
			}*/



		});



		wrapperSpan.appendChild(checkbox);
		return wrapperSpan;

	}



	createCaptionField({ args, entity, label = '', fieldName, value, canEdit = true, blurHandler, changeHandler, imageSize }) {


		let { wrapperSpan, textarea } = this.createCaptionTextField({ args, entity, label, fieldName, placeholder: '[Caption]', value, canEdit, blurHandler, changeHandler, imageSize });

		textarea.setAttribute('class', 'blot-image-caption');

		return wrapperSpan;



	}

	createBlockquoteField({ args, entity, label = '', fieldName, value, canEdit = true, blurHandler, changeHandler }) {


		let { wrapperSpan, textarea } = this.createAutoTextField({ args, entity, label, fieldName, placeholder: '[Blurb text]', value, canEdit, blurHandler, changeHandler });

		textarea.setAttribute('class', 'blot-lineitem-textarea blot-blurb-content-italic');

		return wrapperSpan;



	}



	createAttributionField({ args, entity, label = '', fieldName, value, canEdit = true, blurHandler, changeHandler }) {


		let { wrapperSpan, textarea } = this.createAutoTextField({ args, entity, label, fieldName, placeholder: '[Blurb source]', value, canEdit, blurHandler, changeHandler });

		textarea.setAttribute('class', 'blot-lineitem-textarea blot-blurb-content-attribution');

		return wrapperSpan;





	}


	createAutoTextField({ args, entity, label = '', placeholder = '[write here]', fieldName, value, canEdit = true, blurHandler, changeHandler }) {

		let calculatedValue = entity[fieldName];
		if (calculatedValue == 'undefined' || calculatedValue == undefined) {
			calculatedValue = null;
		}
		if (value != null) {
			calculatedValue = value;
		}
		let wrapperSpan = document.createElement("div");
		//wrapperSpan.setAttribute('class', 'boundedblockquote');
		wrapperSpan.setAttribute('class', 'blot-blurb-content-italic');
		//wrapperSpan.setAttribute('contenteditable', 'boundedblockquote');
		let textarea = document.createElement("textarea");
		textarea.setAttribute('class', 'blot-lineitem-textarea blot');
		textarea.setAttribute('value', calculatedValue);
		textarea.setAttribute('placeholder', placeholder);
		textarea.setAttribute('rows', '1');
		textarea.value = calculatedValue;
		textarea.addEventListener('paste', function (ev) {
			//ev.preventDefault();
			ev.stopPropagation();
		});
		textarea.addEventListener('blur', function (ev) {

			entity[fieldName] = textarea.value;

			let newValue = textarea.value;
			let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
			let blot = Parchment.find(node);

			if (blurHandler) {
				blurHandler(blot, entity);
			}

		});
		textarea.addEventListener('keyup', function (ev) {

			//console.log(this);
			if (ev.keyCode == 13 && !ev.shiftKey) {
				// prevent default behavior
				ev.preventDefault();
			}
			//textarea.style.height = 'auto';
			textarea.style.height = (this.scrollHeight) + 'px';

		});

		textarea.addEventListener('keydown', function (ev) {

			//console.log(this);
			if (ev.keyCode == 13 && !ev.shiftKey) {
				// prevent default behavior
				ev.preventDefault();
			}
			//textarea.style.height = 'auto';
			textarea.style.height = (this.scrollHeight) + 'px';

		});

		textarea.addEventListener('keypress', function (ev) {

			//console.log(this);
			//textarea.style.height = 'auto';
			textarea.style.height = (this.scrollHeight) + 'px';

		});
		if (textarea.scrollHeight > 0) {
			textarea.style.height = (textarea.scrollHeight) + 'px';
		}


		/*setTimeout(function(){
			if(textarea.scrollHeight>0){
				textarea.style.height = (textarea.scrollHeight) + 'px';
			}
		}, 2000)*/


		var observer = new MutationObserver(function (mutations) {
			if (document.contains(textarea)) {
				if (textarea.scrollHeight > 0) {
					textarea.style.height = (textarea.scrollHeight) + 'px';
				}
				observer.disconnect();
			}
		});

		$(textarea).on();
		observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });


		wrapperSpan.appendChild(textarea);



		return { wrapperSpan, textarea }

	}

	createCaptionTextField({ args, entity, label = '', placeholder, fieldName, value, canEdit = true, blurHandler, changeHandler, disabled }) {

		let calculatedValue = entity[fieldName];
		if (calculatedValue == 'undefined' || calculatedValue == undefined) {
			calculatedValue = null;
		}
		if (value != null) {
			calculatedValue = value;
		}
		let wrapperSpan = document.createElement("div");
		//wrapperSpan.setAttribute('class', 'boundedblockquote');
		wrapperSpan.setAttribute('class', 'blot-caption-wrapper');
		wrapperSpan.setAttribute('contenteditable', 'false');
		//wrapperSpan.setAttribute('contenteditable', 'boundedblockquote');
		let textarea = document.createElement("textarea");
		textarea.setAttribute('class', 'blot-lineitem-textarea blot blot-content-centered');
		textarea.setAttribute('value', calculatedValue);
		if (placeholder) {
			textarea.setAttribute('placeholder', placeholder);
		}
		textarea.setAttribute('tabindex', -1);
		textarea.setAttribute('rows', '1');
		if (disabled === true) {
			textarea.setAttribute('disabled', 'true');
		}
		textarea.value = calculatedValue;
		textarea.addEventListener('paste', function (ev) {
			//ev.preventDefault();
			ev.stopPropagation();
		});
		textarea.addEventListener('blur', function (ev) {

			entity[fieldName] = textarea.value;

			let newValue = textarea.value;
			let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
			let blot = Parchment.find(node);

			if (blurHandler) {
				blurHandler(blot, entity);
			}

		});
		textarea.addEventListener('keyup', function (ev) {

			//console.log(this);
			if (ev.keyCode == 13 && !ev.shiftKey) {
				// prevent default behavior
				ev.preventDefault();
			}
			//textarea.style.height = 'auto';
			textarea.style.height = (this.scrollHeight) + 'px';

		});

		textarea.addEventListener('keydown', function (ev) {

			//console.log(this);
			if (ev.keyCode == 13 && !ev.shiftKey) {
				// prevent default behavior
				ev.preventDefault();
			}
			//textarea.style.height = 'auto';
			textarea.style.height = (this.scrollHeight) + 'px';

		});

		textarea.addEventListener('keypress', function (ev) {

			//console.log(this);
			//textarea.style.height = 'auto';
			textarea.style.height = (this.scrollHeight) + 'px';

		});
		if (textarea.scrollHeight > 0) {
			textarea.style.height = (textarea.scrollHeight) + 'px';
		}


		/*setTimeout(function(){
			if(textarea.scrollHeight>0){
				textarea.style.height = (textarea.scrollHeight) + 'px';
			}
		}, 2000)*/


		var observer = new MutationObserver(function (mutations) {
			if (document.contains(textarea)) {
				if (textarea.scrollHeight > 0) {
					textarea.style.height = (textarea.scrollHeight) + 'px';
				}
				observer.disconnect();
			}
		});

		$(textarea).on();
		observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });


		wrapperSpan.appendChild(textarea);



		return { wrapperSpan, textarea }

	}


	createTextField({ args, entity, label = '', fieldName, value, canEdit = true, blurHandler, changeHandler }) {

		let wrapperSpan = document.createElement("span");
		wrapperSpan.setAttribute('style', 'display:flex;flex-direction:row;justify-content:flex-start;');

		let labelDiv = document.createElement("span");
		labelDiv.innerHTML = label;
		wrapperSpan.appendChild(labelDiv);

		let commentDiv = document.createElement("input");
		if (!canEdit) {
			commentDiv.setAttribute('disabled', '');
		}
		commentDiv.setAttribute('type', 'text');
		commentDiv.setAttribute('value', entity[fieldName]);
		commentDiv.setAttribute('style', 'display:flex;flex-direction:row;justify-content:flex-start;');
		//commentDiv.innerHTML = blurb.comment;
		commentDiv.innerHTML = value;
		commentDiv.addEventListener('blur', function (ev) {

			/*if(blurHandler){
				blurHandler();
			}*/

			////console.log(commentDiv.value);
			entity[fieldName] = commentDiv.value;

			let newValue = commentDiv.value;
			let node = $("div").find("[data-uuid='" + args.uuid + "']")[0];
			let blot = Parchment.find(node);

			////console.log(blot);

			if (blurHandler) {
				blurHandler(blot, entity);
			}


			/*try{
				blot.updateContributor(entity);
			}catch(err){
				////console.log(err);
			}*/

			//new DeltaToDom().saveBlurb(args,blurb,blurbsNode);
		});
		commentDiv.addEventListener('change', function (ev) {

			//blurb.comment = commentDiv.value;
			//new DeltaToDom().saveBlurb(args,blurb,blurbsNode);


		})


		wrapperSpan.appendChild(commentDiv);
		return wrapperSpan;

	}








	convertBlurb(args) {
		let documentPart = args.documentPart;
		let userId = '';

		try {

			userId = args.userStore.user.id;

		} catch (err) {
			////console.log(err);
		}

		let book;
		try {
			book = args.documentPart.bookDraftDocumentPart.bookDraft.book;
		} catch (err) {
			////console.log(err);
		}

		let canEdit = true;
		if (args.userStore) {

			//canEdit = args.stores.bookStore.currentBookDraft.mostRecentWritingDocumentPart.bookDraftDocumentPart.hasPermissionInBoxSet(userId,'edit');

		}
		////console.log(documentPart);


		//Create wrapper div
		let blurbsNode = document.createElement("DIV");
		blurbsNode.setAttribute('style', 'display:flex; flex-direction:column; background-color:lightgrey');

		if (book) {

			book.blurbsJson.records.forEach((blurb) => {

				let commentDiv = document.createElement("textarea");
				if (!canEdit) {
					commentDiv.setAttribute('disabled', '');
				}
				commentDiv.setAttribute('style', 'display:flex;flex-direction:row;justify-content:flex-start; background-color:lightgrey');
				commentDiv.innerHTML = blurb.comment;
				commentDiv.addEventListener('blur', function (ev) {

					//new DeltaToDom().deleteBlurb(args,blurb,blurbsNode);
					////console.log('blur');
					blurb.comment = commentDiv.value;
					new DeltaToDom().saveBlurb(args, blurb, blurbsNode);
				});
				commentDiv.addEventListener('change', function (ev) {

					//new DeltaToDom().deleteBlurb(args,blurb,blurbsNode);
					//autosize(commentDiv);
					blurb.comment = commentDiv.value;
					//new DeltaToDom().updateBlurb(args,blurb);
					new DeltaToDom().saveBlurb(args, blurb, blurbsNode);


				})
				blurbsNode.appendChild(commentDiv);



				let sourceDiv = document.createElement("div");
				sourceDiv.setAttribute('style', 'display:flex;flex-direction:row;justify-content:flex-end; background-color:lightgrey');


				let sourceTextarea = document.createElement("textarea");
				//autosize(sourceTextarea);
				if (!canEdit) {
					sourceTextarea.setAttribute('disabled', '');
				}
				sourceTextarea.setAttribute('style', 'display:flex;flex-direction:row;justify-content:flex-end; background-color:lightgrey');
				sourceTextarea.innerHTML = blurb.source;
				sourceTextarea.addEventListener('blur', function (ev) {

					//new DeltaToDom().deleteBlurb(args,blurb,blurbsNode);
					////console.log('blur');
					blurb.source = sourceTextarea.value;
					new DeltaToDom().saveBlurb(args, blurb, blurbsNode);
				})


				sourceDiv.appendChild(sourceTextarea);

				if (canEdit) {
					let removeBlurbDiv = document.createElement("DIV");
					removeBlurbDiv.innerHTML = 'DEL';
					removeBlurbDiv.addEventListener('click', function (ev) {

						////console.log('delete contributor...');
						//new DeltaToDom().deleteBlurb(args,blurb,blurbsNode);
						this.saveDocumentPart(args.documentPart)
					})
					sourceDiv.appendChild(removeBlurbDiv);
				}


				blurbsNode.appendChild(sourceDiv);


			});

			if (canEdit) {
				let addBlurbDiv = document.createElement("DIV");
				addBlurbDiv.setAttribute('style', 'display:flex;flex-direction:row;justify-content:center');
				addBlurbDiv.setAttribute('class', 'blot-lineitem-button');
				addBlurbDiv.innerHTML = '+ BLURB';
				addBlurbDiv.addEventListener('click', function (ev) {

					new DeltaToDom().createBlurb(args, blurbsNode);

				})
				blurbsNode.appendChild(addBlurbDiv);
			}


			//html+='<button id="addBlurbBtn" name="addBlurbBtn">ADD BLURB</button>';
		} else {
			let noBookDiv = document.createElement("DIV");
			blurbsNode.appendChild(noBookDiv);
		}




		return blurbsNode;

	}





	deleteBlurb(args, blurb, blurbsNode) {


		////console.log(this);
		let that = this;
		let now = Date.now();
		let newSaveTime = Date.now() + 1000;

		let book;
		try {
			book = args.documentPart.bookDraftDocumentPart.bookDraft.book;
		} catch (err) {
			////console.log(err);
		}


		let currentField = JSON.parse(book['blurbs']);
		let sequence = currentField.records.length;
		//currentField.records.push({"sequence":sequence, "fullname":"Bob The Editor" , "role":"Editor" , "include":true});

		let newRecords = [];

		currentField.records.forEach((m) => {
			if (m.sequence != blurb.sequence) {
				newRecords.push(m);
			}
		});

		currentField.records = newRecords;
		book.blurbs = JSON.stringify(currentField);

		book.updatedTimestamp = now;

		let parentNode = blurbsNode.parentNode;
		while (parentNode.firstChild) {
			parentNode.removeChild(parentNode.firstChild);
		}

		let newBlurbsNode = new DeltaToDom().convertBlurb(args);
		parentNode.appendChild(newBlurbsNode);

		let modelValues = book.getKeys();
		modelValues.blurbs = book.blurbs;
		args.stores.jobsStore.updateModel('Books', modelValues, () => {

			args.stores.bookStore.recipeTimestamp = new Date().getTime();
			////console.log('save completed...')
		});


	}

	updateBlurb(args, blurb) {

		////console.log(blurb);

		let book;
		try {
			book = args.documentPart.bookDraftDocumentPart.bookDraft.book;
		} catch (err) {
			////console.log(err);
		}

		//book.updatedTimestamp = Date.now();

		let blurbsJson = JSON.parse(book['blurbs']);
		blurbsJson.records.forEach((m) => {
			if (m.sequence == blurb.sequence && (m.comment != blurb.comment || m.source != blurb.source)) {
				m.comment = blurb.comment;
				m.source = blurb.source;
				//hasChanged = true;
			}
		});

		book.blurbs = JSON.stringify(blurbsJson);
		book.updatedTimestamp = Date.now();
		args.stores.bookStore.recipeTimestamp = new Date().getTime();

	}

	saveBlurb(args, blurb, blurbsNode) {

		let hasChanged = false;
		////console.log(blurb);

		let book;
		try {
			book = args.documentPart.bookDraftDocumentPart.bookDraft.book;
		} catch (err) {
			////console.log(err);
		}

		book.updatedTimestamp = Date.now();

		let blurbsJson = JSON.parse(book['blurbs']);
		blurbsJson.records.forEach((m) => {
			if (m.sequence == blurb.sequence && (m.comment != blurb.comment || m.source != blurb.source)) {
				m.comment = blurb.comment;
				m.source = blurb.source;
				hasChanged = true;
			}
		});

		if (hasChanged) {

			book.blurbs = JSON.stringify(blurbsJson);


			let modelValues = book.getKeys();
			modelValues.blurbs = book.blurbs;
			args.stores.jobsStore.updateModel('Books', modelValues, () => {

				args.stores.bookStore.recipeTimestamp = new Date().getTime();
				////console.log('save completed...')
			});

		}

		////console.log(hasChanged);

	}


	saveDocumentPart(args) {

		let documentPart = args.documentPart;
		let hasChanged = false;
		////console.log(args);


		let modelValues = documentPart.getKeys();
		modelValues.content = documentPart.content;
		args.stores.jobsStore.updateModel(documentPart.modelType, modelValues, () => {

			args.stores.bookStore.recipeTimestamp = new Date().getTime();
			////console.log('save completed...')
		});





	}




}

//exports.DeltaToDom = DeltaToDom;