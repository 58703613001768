import React, { useState, useEffect } from "react";
import { inject, observer } from 'mobx-react'; import config from '../../../aws-exports';
import StandardUIDialog from "../../UI/StandardUIDialog";
import StandardUIButton from "../../UI/StandardUIButton";
import StandardUITextField from "../../UI/StandardUITextField";
import { useManagerCreateInvitation, useGetUserConnections, useManagerAcceptInvitation } from '../../../managers/ConnectionsManager';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const InviteConnection = (props) => {

    let [name, setName] = useState(null);
    let [email, setEmail] = useState(null);

    const { createInvitation, data: createInvitationData, loading: createInvitationLoading, error: createInvitationError } = useManagerCreateInvitation();


    const handleNameChange = (e) => {
        e.preventDefault();

        setName(e.target.value);
    }

    const handleEmailChange = (e) => {
        e.preventDefault();

        setEmail(e.target.value);
    }

    useEffect(() => {

        // if (createInvitationError) {
        //     //console.log(createInvitationError);


        //     let mappedErrorMessage = config.errorMessagesMappings[createInvitationError.message];

        //     props.stores.bookStore.snackbarMessage = mappedErrorMessage ? mappedErrorMessage : createInvitationError.message;
        //     props.stores.bookStore.snackbarMessageSeverity = 'error';
        //     props.stores.bookStore.showSnackbar = true;



        // }
    }, [createInvitationError]);


    return (
        <Box>
            <StandardUITextField label='Name' onChange={handleNameChange} value={name} />
            <StandardUITextField label='Email' onChange={handleEmailChange} value={email} />
            <StandardUIButton
                buttonAction='Invite'
                autoFocus
                onClick={() => {


                    try {
                        createInvitation({
                            label: name,
                            emailAddress: email
                        })
                    }
                    catch (err) {
                        console.log(err);
                    }

                    props.onSent();
                }}
            />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {createInvitationLoading && <CircularProgress />}
            </div>
        </Box>
    )
}

export default inject('stores')(observer(InviteConnection));