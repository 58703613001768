import React, { useEffect, useState } from 'react';
import { styled, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import BaseControl from "../BaseControl";
import { useTheme } from '@mui/material/styles';
import { Box, Checkbox } from "@mui/material";
import { v4 as uuid } from "uuid";
import { RecipeUtils } from '../../RecipeUtils';
function ToggleButtons(props) {

  const theme = useTheme();
  let baseControl = new BaseControl(props);

  const { classes, element, bookDraft, documentPart } = props;
  const [propValue, setPropValue] = React.useState(null);



  useEffect(() => {

    try {

      // let recipeParamName = 'global_headerTemplateSourceFor---PART_TYPE---s';
      // let headerTemplateSourceParam = baseControl.getRecipeParamByName(recipeParamName);

      // setHeadingSourceType(headerTemplateSourceParam.value);

      let currentRecipe = props.documentPart?.getRecipe().json;
      let paramPath = element.recipeParamPath;
      //let value = new RecipeUtils().getRecipeParamByPartType(null, currentRecipe, documentPart, paramPath);   
      let valueSource = new RecipeUtils().getRecipePropValueSource(null, currentRecipe, documentPart, paramPath);

      setPropValue(valueSource)

    } catch (err) {
      console.log(err);
    }
  }, [documentPart])


  let isVisible = baseControl.isVisible();
  if (!isVisible) {
    return <div style={{
      height: '0em'
    }}></div>
  }

  let item = documentPart;
  let boxSet = bookDraft.book.boxSet;

  let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();


  if (element.paramItem == 'BOOK') {
    item = bookDraft.book;
  }
  if (element.paramItem == 'BOXSET_MAIN_BOOK') {
    item = boxSet.book;
  }
  if (element.paramItem == 'BOXSET_BOOK') {
    item = boxSetBook.book;
  }
  if (element.paramItem == 'BOXSETBOOK') {
    item = boxSetBook;
  }



  let title = baseControl.convertTitle(element.title, documentPart);


  const handleChangeValue = (event, value) => {

    let currentRecipe = props.documentPart?.getRecipe().json;
    let paramPath = element.recipeParamPath;

    if (element.setValueSoure) {
      new RecipeUtils().setRecipePropValueSource(null, currentRecipe, documentPart, paramPath, value);
      let valueSource = new RecipeUtils().getRecipePropValueSource(null, currentRecipe, documentPart, paramPath);
      setPropValue(valueSource);
    } else {

    }


    props.stores.bookStore.recipeTimestamp = new Date().getTime();
    // setHeadingSourceType(value);

    // let currentRecipe = props.documentPart?.getRecipe();

    // let recipeParamName = 'global_headerTemplateSourceFor---PART_TYPE---s';
    // recipeParamName = baseControl.convertTitle(recipeParamName, props.documentPart);

    // let recipeParam = baseControl.getRecipeParamByName(recipeParamName);

    // recipeParam.value = value;

    // setTimeout(() => {

    //   props.stores.bookStore.recipeTimestamp = new Date().getTime();

    //   if (props.onUpdateRecipe) {
    //     props.onUpdateRecipe({
    //       recipe: currentRecipe,
    //       paramName: recipeParamName,
    //       paramValue: value
    //     })
    //   }

    // }, 10);




  };



  let hasHeadingSourceType = true;
  if (!documentPart ||
    documentPart?.partType.toLowerCase() == 'topbook' ||
    documentPart?.partType.toLowerCase() == 'book' ||
    documentPart?.partType.toLowerCase() == 'copyright' ||
    documentPart?.partType.toLowerCase() == 'toc' ||
    documentPart?.partType.toLowerCase() == 'fullpageimage') {
    hasHeadingSourceType = false;
  }

  return (

    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flex: 12
    }}>
      {hasHeadingSourceType &&
        <>
          <ToggleButtonGroup
            size="small"
            value={propValue}
            exclusive
            onChange={handleChangeValue}
            aria-label="text alignment"
          >
            {/* <ToggleButton value="common" aria-label="">

              Book Default
            </ToggleButton>

            <ToggleButton value="partTypeSpecific" aria-label="">

              {baseControl.convertTitle(" ---PART_TYPE--- SPECIFIC", documentPart)}
            </ToggleButton>

            <ToggleButton value="self" aria-label="">

              SELF
            </ToggleButton> */}

            {element.options.map((option) => {
              return <ToggleButton value={option.value} aria-label="">
                  {baseControl.convertTitle(option.label, documentPart)}
              </ToggleButton>
            })}
          </ToggleButtonGroup>






          {/* {(headerTemplateSourceValue && headerTemplateSourceValue === 'common') &&
            <Typography variant={'h7'} color={'gray'}>
              {baseControl.convertTitle("Using the book\'s default heading template for all ---PART_TYPE_LOWER---s.",documentPart)}</Typography>
          }
          {(headerTemplateSourceValue && headerTemplateSourceValue === 'partTypeSpecific') &&
            <Typography variant={'h7'} color={'gray'}>{baseControl.convertTitle("Using a unique heading template for all ---PART_TYPE_LOWER--- 's",documentPart)}</Typography>
          } */}
        </>
      }

    </Box>


  );
}


export default inject('stores')(observer(ToggleButtons));



