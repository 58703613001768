import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { Paper, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import StandardHelpLabel from '../../StandardHelpLabel';
import Checkbox from '@mui/material/Checkbox';
import StandardBlotImageSelector from '../../StandardBlotImageSelector';

const PublisherPopper = (props) => {

    let { open, onClose = () => { }, anchorEl, publisherBlot, documentPart } = props;

    let publisherJson = {};
    try{
        publisherJson = JSON.parse(publisherBlot?.domNode?.dataset?.publisher);
    }catch(err){
        //console.log(err);
    }
    

    const [includePublisherUrl, setIncludePublisherUrl] = useState(publisherJson.includePublisherUrl)

    useEffect(() => {

        let publisherJson = {};
        try{
            publisherJson = JSON.parse(publisherBlot?.domNode?.dataset?.publisher);
        }catch(err){
            //console.log(err);
        }


        setIncludePublisherUrl(publisherJson.includePublisherUrl);

    }, [publisherBlot?.domNode?.dataset?.publisher]);



    return (
        <Popper
            id={'publisherPopper'}
            open={open}
            onMouseLeave={() => {
                onClose();

            }}

            anchorEl={anchorEl}
            transition
            placement="bottom"
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper elevation={6}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '1em'
                        }}>
                            <StandardHelpLabel title={'Publisher Details'} />


                            <FormGroup>
                                <FormControlLabel control={<Checkbox 
                                    checked={includePublisherUrl} 
                                    onChange={(e) => {
                                    e.preventDefault();
                                    setIncludePublisherUrl(e.target.checked);
                                    publisherBlot.setIncludePublisherUrl(e.target.checked);


                                }} />} label="Include Publisher Url" />
                            </FormGroup>
                           
                            
                            <StandardBlotImageSelector
                                title={'Cover Image'}
                                entity={props.stores.bookStore.bookListBook}
                                imageKeyFieldName={'coverUrl'}
                                onImageUploaded={(key) => {
                                    publisherBlot.setLogoSrc(key);

                                    //documentPart.bookDraftDocumentPart.bookDraft.book.publisherLogoImageSrc = key;



                                }} />

                        </div>
                    </Paper>
                </Fade>
            )}
        </Popper>

    );
};


export default inject('stores')(observer(PublisherPopper));
