import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import StandardTabs from "../../StandardTabs";
import { useGetUserConnections, useGetUserGroups } from '../../../managers/ConnectionsManager';
import AvatarTableCell from "../../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton, { StandardListButton } from "../../StandardButton";
import BookEditRequestsList from "../../lists/BookEditRequestsList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../../managers/BooksManager';
import UserGroupsList from "../../lists/UserGroupsList";
import { useManagerCreateEditRequest } from '../../../managers/EditRequestsManager';
import StandardOkCancelDialog from "../StandardOkCancelDialog";
import RequestEditPanel from "./RequestEditPanel";
import EditDetails from "../../editing/EditDetails";
import EditChangeNotes from "../../editing/EditChangeNotes";


const EditRequestsPanel = (props) => {

  let user = props.stores.userStore.user;
  let { book } = props;

  let [showRequestEditDialog, setShowRequestEditDialog] = useState(false);

  const { createEditRequest, loading: createEditRequestLoading, error: createEditRequestError } = useManagerCreateEditRequest(props);

  let [selectedTab, setSelectedTab] = useState(0);


  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Grid container direction={'column'}>
      <Grid item>
        <StandardListButton
          variant="outlined"
          onClick={() => {
            setShowRequestEditDialog(true)
          }}>Request Edit</StandardListButton>

      </Grid>
      <Grid item>
        <BookEditRequestsList
          book={book}
          showSelectIcon
          onSelected={(userConnection) => {

            if (userConnection) {
              createEditRequest({
                book,
                userId: user.id,
                editorUserId: userConnection.connectedUser.id
              })
            }

          }}
        />

        <EditChangeNotes
          //allBlots={allBlots}
          documentPart={props.stores.bookStore.editingSelectedBookDraftDocumentPart?.documentPart}
          editRequest={props.editRequest}
          editBookDraft={props.editingBookDraft}
          originalBookDraft={props.originalBookDraft}
          //onInitTransfer={initTransferHandler}
          finalView={false}
        // onToggleFinalView={() => {
        //     setFinalView(!finalView)
        // }}
        />

        {/* <EditDetails
          documentPart={props.stores.bookStore.editingSelectedBookDraftDocumentPart?.documentPart}
          originalBookDraft={props.bookDraft}
          editBookDraft={props.editingBookDraft}
          editRequest={props.editRequest}
          showOriginal={props.showOriginal}
          onToggleLoadOriginal={() => {

            //console.log(editRequest);

            if (props.editRequest) {
              // lazyGetBookDraft({
              //   variables: {
              //     bookDraftId: editRequest.originalBookDraftId
              //   }
              // });

            }
          }} /> */}

        <StandardOkCancelDialog
          open={showRequestEditDialog}
          onClose={() => {
            setShowRequestEditDialog(false)
          }}
          onOk={() => {
            setShowRequestEditDialog(false)

          }}
          title={'Request Edit'}
          content={
            <RequestEditPanel book={book} />
          }
        />
      </Grid>
    </Grid>
  )
}

export default inject('stores')(observer(EditRequestsPanel));