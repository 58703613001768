import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import StandardDataTable from '../table/StandardDataTable';
import { useManagerLoadBoxSetContributors } from '../../managers/BoxsetsManager';
import CancelTableCell from '../table/CancelTableCell';
import StandardOkCancelDialog from './StandardOkCancelDialog';
import RemoveBoxsetContributorPanel from './RemoveBoxsetContributorPanel';
import AvatarTableCell from '../table/AvatarTableCell';
import config from '../../aws-exports';
import { useMediaQuery } from 'react-responsive';
import AddContributorAssembly from '../assemblies/AddContributorAssembly';
import StandardDashboardPaper from '../StandardPaper';
import OwnedBoxsetContributorsList from '../lists/OwnedBoxsetContributorsList';  

const OwnedBoxsetContributors = (props) => {


  // let boxset = props.stores.bookStore.boxSetListBoxSet;
  let boxset = props.boxset;

  const isXs = useMediaQuery({ maxWidth: 599 });
  const isSm = useMediaQuery({ maxWidth: 600 });
  const isMd = useMediaQuery({ maxWidth: 900 });
  const isLg = useMediaQuery({ maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1536 });

  let [showRemoveContributorDialog, setShowRemoveContributorDialog] = useState(false);
  let [selectedContributor, setSelectedContributor] = useState(false);

  let {
    boxsetContributors,
    loading: lazyLoadBoxSetContributorsLoading,
    error: lazyLoadBoxSetContributorsError,
    refetch: lazyLoadBoxSetContributorsRefetch
  } = useManagerLoadBoxSetContributors(props, boxset?.id);

  //console.log(boxsetContributors);

  return (
    <>
      {/* <StandardDashboardPaper sx={{
        position: 'relative',
        //height: '15em',
        borderRadius: '8px',
        //marginTop: '2em',
        padding: '.5em 1em 1em 1em'
      }}> */}
        {boxset &&
          <StandardDataTable
            title={'Contributors'}
            noDataMessage={'Contributors will show up here.'}
            headerActions={<AddContributorAssembly boxset={boxset} />}
            structure={{
              dataDef: {
                data: boxsetContributors,
                rowKeyDataFieldName: 'id'
              },
              columns: [
                {
                  title: 'User',
                  dataValueFunction: (boxsetContributor) => {
                    return <AvatarTableCell
                      bucketName={config.user_uploads_bucket_name}
                      s3Src={boxsetContributor?.contributor?.profilePicUrl}
                      title={boxsetContributor?.contributor?.name}
                      onClick={(e) => {
                        e.preventDefault();
                        //console.log(boxsetContributor)
                        // props.stores.bookStore.connectionsListConnection = userConnection;
                        // navigate("/connections/details");
                      }}
                    />

                  }
                },
                // {
                //   title: 'Pic',
                //   dataValueFunction: (boxsetContributor) => { return <Avatar sx={{ width: '1.5em', height: '1.5em' }} variant="square" alt="" src="https://www.adobe.com/express/create/cover/media_178ebed46ae02d6f3284c7886e9b28c5bb9046a02.jpeg?width=400&format=jpeg&optimize=medium" /> }
                // },
                // {
                //   title: 'Name',
                //   dataValueFunction: (boxsetContributor) => { return boxsetContributor.contributor.name }
                // },
                {
                  title: 'Remove',
                  dataValueFunction: (boxsetContributor) => {

                    return <CancelTableCell onClick={(e) => {
                      e.preventDefault();
                      setShowRemoveContributorDialog(true)
                      setSelectedContributor(boxsetContributor.contributor)

                    }} />
                  }
                },
              ]
            }}
          />
        }
        {/* {isXs && boxset &&
          <OwnedBoxsetContributorsList
            filteredBooks={[]}
            showSelectIcon
            onSelected={(boxsetContributor) => {

              if (boxsetContributor) {

                console.log(boxsetContributor)
                // props.stores.bookStore.bookListBook = book;

              }

            }}
          />
        } */}

        <StandardOkCancelDialog
          showOkButton={true}
          showCancelButton={true}
          open={showRemoveContributorDialog}
          onClose={() => {
            setShowRemoveContributorDialog(false)
            setSelectedContributor(null)
          }}
          onOk={() => {
            setShowRemoveContributorDialog(false)
            setSelectedContributor(null)

          }}
          title={'Remove Contributor'}
          content={
            <RemoveBoxsetContributorPanel boxset={boxset} contributor={selectedContributor} onRemoved={() => {
              setShowRemoveContributorDialog(false)
              setSelectedContributor(null)
              lazyLoadBoxSetContributorsRefetch();

            }} />
          }
        />

      {/* </StandardDashboardPaper> */}

    </>

  )
}

export default inject('stores')(observer(OwnedBoxsetContributors));