import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import BaseControl from "./BaseControl";

export const StyledTypography = styled(Typography)(() => ({

    // background: 'transparent',
    // boxShadow: 'none',
    //fontWeight: 'bold'



}));





function InfoHeading(props) {

    const theme = useTheme();

    const [title, setTitle] = useState();
    let baseControl = new BaseControl(props);
    const { classes, element, bookDraft, documentPart, boxSetBook, showProps = true, fieldName, s3FieldName, fileTypeFieldName, label } = props;

    
    
    useEffect(()=>{
        setTitle(baseControl.convertTitle(element.title, documentPart));
        // let headingSourceParam = baseControl.getRecipeParamByName('global_headerTemplateSourceFor---PART_TYPE---s');
        // console.log(headingSourceParam);
    
    },[element, bookDraft, documentPart, documentPart?.bookDraftDocumentPart.getBoxSetBook()?.recipeSource]);


    let isVisible = baseControl.isVisible();
    if (!isVisible) {
        return <div></div>
    }


    //let partTypeText = baseControl.convertTitle('---PART_TYPE---', documentPart);

    return (
        <StyledTypography variant={'h6'} color={'gray'}
            //textColor={theme.palette.books?.main} 
            {...props}>
            {title}
        </StyledTypography>


    )



    // return (

    //     <StyledTypography variant={'h6'} color={'secondary'}
    //         //textColor={theme.palette.books?.main} 
    //         {...props}>
    //         {title}{contextString}
    //     </StyledTypography>

    // );
}

export default inject('stores')(observer(InfoHeading));

