import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer } from '@mui/material';
import {  Grid } from "@mui/material";
import { inject, observer } from 'mobx-react';
import StandardAppBar from './StandardAppBar';
import StandardSideDrawer from './StandardDrawer';
import config from '../aws-exports';
import MainMenuContent2 from './menus/MainMenuContent2';
import { useMediaQuery } from 'react-responsive'




function AppWrapper(props) {


  const navigate = useNavigate();
  let [mainDrawerOpen, setMainDrawerOpen] = useState(false);

  let [client, setClient] = useState();

  const isXs = useMediaQuery({ maxWidth: 599 })
  const isSm = useMediaQuery({ maxWidth: 600 })
  const isMd = useMediaQuery({ maxWidth: 900 })
  const isLg = useMediaQuery({ maxWidth: 1200 })
  const isXl = useMediaQuery({ minWidth: 1536 })

  //*********************** See if the amplify context can get us what we need instead of importing aws-sdk */
  const AWS = require("aws-sdk");

  let bucketRegion = config.user_uploads_bucket_region;//"us-east-1";
  let IdentityPoolId = config.identity_pool_id

  const url = config.graphqlEndpoint;
  const region = config.aws_project_region;


  AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IdentityPoolId
    })
  });

   if(!props.stores.userStore.user){
    return <div>No user yet!</div>
  }
  return (
    <div className='APP-WRAPPER'>


      <StandardSideDrawer
        open={mainDrawerOpen}
        
        onClose={() => {
          setMainDrawerOpen(false)
        }}
        onRouteSelected={() => {
          setMainDrawerOpen(false);
        }} />


      <Drawer
        anchor={'right'}
        variant='temporary'
        open={props.stores.bookStore.showHelpDrawer}
        PaperProps={{
          width: '55%'
        }}
        sx={{
          width: '100%'
        }}
        onClose={() => {
          //setShowHelpDrawer(false)
          props.stores.bookStore.showHelpDrawer = false;
        }}
      >
        <div style={{
          width: '100%',
          height: '100%'
        }}>
          <iframe src={props.stores.bookStore.helpUrl} style={{
            width: '100%',
            height: '100%'
          }}></iframe>

        </div>
      </Drawer>


      <Grid sx={{
        height: '100%',
        width:'100%',
        //border: '5px solid yellow',
        display: 'flex',
        flexDirection: 'column',
        padding: '0em 0em 0em 0em'
      }}>


        <StandardAppBar
          onOpenMainDrawer={() => {
            //setMainDrawerOpen(true)
          }}
        />
        <div style={{
          display: 'flex',
          gap: '2em',
          flex: 2,
          height:'100%'
        }}>
          {!isXs &&
            <MainMenuContent2
              expanded={false}

            />
          }


          {props.main}
        </div>



      </Grid>
     
     
    </div>
  );
}

export default inject('stores')(observer(AppWrapper));
