import React, { useEffect, useState } from 'react';
import { Box, styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import BaseControl from "../BaseControl";
import { useTheme } from '@mui/material/styles';
import { v4 as uuid } from "uuid";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { RecipeUtils } from '../../RecipeUtils';

function SelfOverrideSwitch(props) {

  const theme = useTheme();
  let baseControl = new BaseControl(props);

  const { classes, element, bookDraft, documentPart } = props;




//   let isVisible = baseControl.isVisible();
//   if (!isVisible) {
//     return <div style={{
//       height: '0em'
//     }}></div>
//   }

  let item = documentPart;
  let properties_book = bookDraft.book;
  let boxSet = bookDraft.book.boxSet;

  let boxSetBook = documentPart.bookDraftDocumentPart.getBoxSetBook();
  let hideBookProperties = false;

  let canEdit = true;


  let isOwner = true;

  let overridenConfig = new RecipeUtils().getOverrideParam(documentPart,element.recipeParamPath);

  let overridden = false;
  if(overridenConfig){
    overridden = overridenConfig.override;
  }
  if (element.paramItem == 'BOOK') {
    item = bookDraft.book;
  }
  if (element.paramItem == 'BOXSET_MAIN_BOOK') {
    item = boxSet.book;
  }
  if (element.paramItem == 'BOXSET_BOOK') {
    item = boxSetBook.book;
  }
  if (element.paramItem == 'BOXSETBOOK') {
    item = boxSetBook;
  }

  //console.log('gonna call it...')


  let title = baseControl.convertTitle(element.title, documentPart);

  const label = { inputProps: { 'aria-label': title } };

  return (

    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}>
     

      <FormGroup>
        <FormControlLabel control={<Switch 
        checked={overridden}
        onChange={(event) => {

            let currentRecipe = documentPart?.getRecipe().json;
            new RecipeUtils().setOverrideParam(documentPart,element.recipeParamPath,event.target.checked);
            let overridenValue = new RecipeUtils().getRecipeParamByPartType(null, currentRecipe, documentPart, element.recipeParamPath);
            baseControl.updateRecipeProp(overridenValue);
            props.stores.bookStore.recipeTimestamp = new Date().getTime();
        }}
        />} label="Override" />
      </FormGroup>
     
    </Box>


  );
}


export default inject('stores')(observer(SelfOverrideSwitch));



