import React, { useEffect, useState } from 'react';
import { observable } from 'mobx';
import { useUpdateRecipeParam } from '../persistance/persistanceHooks';
import { BoxSet } from '../domain/bookModel';

class RecipeManager {

    constructor() {

    }



}

export function useUpdateRecipe(props) {

    const [actualUpdateRecipeParam, { data: updateRecipeParamData, loading: updateRecipeParamLoading, error: updateRecipeParamError }] = useUpdateRecipeParam();

    const updateRecipeParam = ({
        recipeId,
        paramPath,
        paramName,
        paramValue,
        paramAction,
        overrideActive,
        overridePartId,
        partTypeShortHand,
        scope
    }) => {
        actualUpdateRecipeParam({
            recipeId,
            paramPath,
            paramName,
            paramValue,
            paramAction,
            overrideActive,
            overridePartId,
            partTypeShortHand,
            scope
        })
    }

    return {
        updateRecipeParam,
        updateRecipeParamLoading,
        updateRecipeParamError
     };
}

export default new RecipeManager();