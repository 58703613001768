import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useAddBookDraftDocumentPart, useMoveBookDraftDocumentPart } from '../../persistance/persistanceHooks';
//import StandardTree from './StandardTree';
import { bookDraftTree2, partTypeIcons, wrapIcon } from './bookDraftTreeBuilder2';
import config from '../../config';
//import BoxSetContributedBookSelectorDialog from '../dialogs/BoxSetContributedBookSelectorDialog';
//import './antd-dark.css';
//import 'antd/dist/antd.variable.min.css';
import { Tree } from 'antd';
import { Menu, Dropdown } from 'antd';
import ContextMenu from './ContextMenu';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useUpdateItem } from '../../persistance/persistanceHooks';
import EntityManager from '../../domain/EntityManager';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import StandardDialog from '../StandardDialog';
import InsertBoxsetBookPanel from '../panels/InsertBoxsetBookPanel';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const TreeNode = (props) => {


    let { icon, title, childNodes, pojo, onMoved, selected, item, documentPart } = props;

  



    const theme = useTheme();

    let hasChildren = false;
    if (childNodes && childNodes.length > 0) {
        hasChildren = true;
    }


    const boxSX = {

        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        'min-width': 0
    };

    let selectedSx = {

    }

    let titleColor = theme.palette.standardTree.nodes.color.main;
    if (selected) {
        titleColor = theme.palette.standardTree.nodes.selected.color.main;
        
        selectedSx = {

            color: theme.palette.standardTree.nodes.selected.color.main,
            //backgroundColor: theme.palette.standardTree.nodes.selected.backgroundColor.main, 
            border: '1px solid ' + theme.palette.standardTree.nodes.selected.backgroundColor.main, 
            borderRadius: '.5em'
        };
    }

    if(documentPart && ( documentPart.partType == 'BOOK' || documentPart.partType == 'TOPBOOK' || documentPart.partType == 'ROOT')){
        titleColor = theme.palette.standardTree.heading.color.main;
    }
    return (

        <Box
            sx={{

                width: '100%'

            }}
        >
            <Box
                sx={{
                    "&:hover": {
                        border: "1px solid gray",
                        color: 'black',
                        //backgroundColor: 'gray',
                        borderRadius: '.5em'
                    },
                    ...selectedSx,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    //maxWidth: theme.components.standardTree.nodes.width,
                    //border: '1px solid tan',
                    width: '100%'
                }}
                onClick={(e) => {
                    if (props.onClick) props.onClick(e);
                }}
            >

                <Box sx={boxSX}

                >

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '1em'
                    }}>
                        {(hasChildren && !item.expanded) &&
                            <ArrowRightIcon
                                onClick={(e) => {
                                    e.preventDefault();
                                    //props.toggleExpanded();
                                    item.expanded = !item.expanded;
                                }}
                                sx={{
                                    color: theme.palette.standardTree.title.color.main,

                                }} />
                        }
                        {(hasChildren && item.expanded) &&
                            <ArrowDropDownIcon
                                onClick={(e) => {
                                    e.preventDefault();
                                    //props.toggleExpanded();
                                    item.expanded = !item.expanded;
                                }}
                                sx={{
                                    color: theme.palette.standardTree.title.color.main,

                                }} />
                        }
                        {/* { hasChildren &&
                            <ArrowDropDownIcon sx={{
                                color: theme.palette.standardTree.title.color.main,

                            }} />
                        } */}


                    </Box>

                    {props.icon}
                    {/* <MenuBookIcon
                        sx={{
                            color: theme.palette.standardTree.title.color.main,
                            marginRight: '.5em'
                        }} /> */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        //border: '1px solid red',
                        //width: '100%',
                        'min-width': 0
                        //height: '3em'

                    }}
                    // onClick={(e) => {
                    //     if (props.onClick) props.onClick(e);
                    // }}
                    >
                        <div
                            
                            style={{
                                height: '.5em',
                               
                            }}>

                        </div>

                        {/* Finally got Typography ellipsis to work.  */}
                        {/* https://leonardofaria.net/2020/07/18/using-flexbox-and-text-ellipsis-together/ */}
                        <Typography
                            //className={'treeNodeTitle'}
                            noWrap={true}
                            variant={theme.components.standardTree.nodes.variant}
                            color={titleColor}
                            sx={{

                                'white-space': 'nowrap',
                                //'font-weight':'bold',
                                //'border': '1px solid red',
                                //'width': '100%',
                                'min-width': 0,
                                'display': 'block',
                                'overflow': 'hidden',
                                'text-overflow': 'ellipsis',
                            }}
                        >{title}</Typography>


                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start'
                        }}>
                            <div
                               
                                style={{
                                    height: '.5em',
                                   
                                    color: 'red',
                                    width: '25%'
                                }}>

                            </div>
                            <div
                               
                                style={{
                                    height: '.5em',
                                   
                                    color: 'red',
                                    width: '75%'
                                }}>

                            </div>

                        </Box>













                    </Box>

                </Box>


                <MoreVertIcon onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();


                    if (props.onContextMenu) {
                        props.onContextMenu(e);
                    }
                }}
                    sx={{
                        color: theme.palette.standardTree.title.color.main
                    }} />
            </Box>

            <Box sx={{
                marginLeft: '1em'
                //width: theme.components.standardTree.nodes.width
            }}>
                {/* {item.expanded && 
                    <div>item.expanded</div>
                }
                {!item.expanded && 
                    <div>!item.expanded</div>
                } */}
                {item.expanded==true &&
                    childNodes
                }
            </Box>

            {/* <div
                ref={drop}
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                }}
            >
                <div style={{
                    border: '1px solid red'
                }}>
                    drop zone
                </div>

            </div> */}




        </Box>

    )
};


export default inject('stores')(observer(TreeNode));