import React, { useEffect, useState } from 'react';
import { useGetBuildRequests } from '../persistance/persistanceHooks';

const AWS = require("aws-sdk");

export function useGetBookDraftBuildRequests(props, bookDraftId) {

    const [buildRequests, setBuildRequests] = useState([]);

    const { loading, error, data, refetch, networkStatus, client } = useGetBuildRequests({bookDraftId});

    useEffect(() => {


        try {
            //console.log(data);
            if (data) {

                let buildRequests = [];

                data.listUserBuildRequests.items.filter(f => f.deletedAt == null).forEach((buildRequestData) => {

                    buildRequests.push(buildRequestData)

                });

                setBuildRequests(buildRequests);

            }
        } catch (err) {
            console.log(err);
        }

    }, [data]);

    return { buildRequests, loading, error, client };

}