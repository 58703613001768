import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import TableBoilerPlate from './TableBoilerPlate';
import StandardImageViewer from './StandardImageViewer';
import Feather from 'mdi-material-ui/Feather';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import { useGetUserConnections } from '../../managers/ConnectionsManager';
import { useOpenBookInWrite, useOpenBookInFormat } from '../../managers/BooksManager';
import config from '../../aws-exports';
import StandardRoundedAction from '../actions/StandardRoundedAction';
import ReactTimeAgo from 'react-time-ago';
import TablePopupMenu from '../menus/TablePopupMenu';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import AvatarTableCell from '../table/AvatarTableCell';

const ConnectionsTable = (props) => {

  const { userConnections, error, loading } = useGetUserConnections(props);
  const { openBookInWrite } = useOpenBookInWrite(props);
    const { openBookInFormat } = useOpenBookInFormat(props);
  
    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();

    const table = useMemo(() => {

        if (!userConnections) return [];

        
        return <TableBoilerPlate
            data={userConnections}
            error={error}
            loading={loading}
            columns={[
                {
                    title: 'User',
                    align: 'left',
                    data: (userConnection) => {
                        return <AvatarTableCell
                        bucketName={config.user_uploads_bucket_name}
                        s3Src={userConnection?.connectedUser?.profilePicUrl}
                        title={userConnection?.connectedUser?.name}
                        onClick={(e) => {
                          e.preventDefault();
                          //console.log(userConnection)
                          props.stores.bookStore.connectionsListConnection = userConnection;
                          //navigate("/connections/details");
                          
                        }}
                      />
                    }
                },
                {
                    title: 'Created',
                    align: 'center',
                    sortable: true,
                    sortable:true,
                    sortData: (userConnection)=>{  return userConnection.createdAt},
                    data: (userConnection) => {
                        return  userConnection.createdAt != null ? (
                            <ReactTimeAgo date={userConnection.createdAt} locale="en-US" />
                          ) : (
                            ''
                          )
                    }
                },
                {
                    title: '',
                    align: 'right',
                    sortable: false,
                    sortData: (userConnection)=>{  return userConnection.createdAt},
                    data: (userConnection) => {
                      return <TablePopupMenu
                      menuItems={[
                        <ListItemButton onClick={(e) => {
                          e.preventDefault();
                          //console.log(userConnection)
                          props.stores.bookStore.connectionsListConnection = userConnection;
                          navigate("/connections/details/sharedTo");
                          // openBookInWrite({
                          //   bookDraftId: book.primaryBookDraftId
                          // });
                        }} >
  
                          <ListItemIcon>
                            <ShareIcon sx={{ color: theme.palette.background.main }} />
                          </ListItemIcon>
                          <ListItemText primary="Shared With" />
                        </ListItemButton>,
                        <ListItemButton onClick={(e) => {
                          e.preventDefault();
                          //console.log(userConnection)
                          props.stores.bookStore.connectionsListConnection = userConnection;
                          navigate("/connections/details/sharedFrom");
                          // openBookInWrite({
                          //   bookDraftId: book.primaryBookDraftId
                          // });
                        }} >
  
                          <ListItemIcon>
                            <ShareIcon sx={{ color: theme.palette.background.main }} />
                          </ListItemIcon>
                          <ListItemText primary="Shared From" />
                        </ListItemButton>,
                        //   <ListItemButton onClick={(e) => {
                        //     e.preventDefault();
                        //     console.log(userConnection)
                        //     props.stores.bookStore.connectionsListConnection = userConnection;
                        //     navigate("/connections/details");
                        //     // openBookInWrite({
                        //     //   bookDraftId: book.primaryBookDraftId
                        //     // });
                        // }}   >
  
                        //     <ListItemIcon>
                        //       <SettingsIcon sx={{ color: theme.palette.background.main }} />
                        //     </ListItemIcon>
                        //     <ListItemText primary="Details" />
                        //   </ListItemButton>,
                        // <ListItemButton onClick={(e) => {
                        //   e.preventDefault();
                        //   console.log(userConnection)
                        //   props.stores.bookStore.connectionsListConnection = userConnection;
                        //   navigate("/connections/details/boxsets");
                        //   // openBookInWrite({
                        //   //   bookDraftId: book.primaryBookDraftId
                        //   // });
                        // }}   >
  
                        //   <ListItemIcon>
                        //     <ContentCopyIcon sx={{ color: theme.palette.background.main }} />
                        //   </ListItemIcon>
                        //   <ListItemText primary="Boxsets" />
                        // </ListItemButton>,
                        // <ListItemButton onClick={(e) => {
                        //   e.preventDefault();
                        //   console.log(userConnection)
                        //   props.stores.bookStore.connectionsListConnection = userConnection;
                        //   navigate("/connections/details/projects");
                        //   // openBookInWrite({
                        //   //   bookDraftId: book.primaryBookDraftId
                        //   // });
                        // }} >
  
                        //   <ListItemIcon>
                        //     <FileCabinet sx={{ color: theme.palette.background.main }} />
                        //   </ListItemIcon>
                        //   <ListItemText primary="Projects" />
                        // </ListItemButton>,
                        <ListItemButton onClick={(e) => {
                          e.preventDefault();
                          //console.log(userConnection)
                          props.stores.bookStore.connectionsListConnection = userConnection;
                          navigate("/connections/details/feedback");
                          // openBookInWrite({
                          //   bookDraftId: book.primaryBookDraftId
                          // });
                        }} >
  
                          <ListItemIcon>
                            <CommentIcon sx={{ color: theme.palette.background.main }} />
                          </ListItemIcon>
                          <ListItemText primary="Feedback" />
                        </ListItemButton>,
                        // <ListItemButton onClick={(e) => {
                        //   e.preventDefault();
                        //   console.log(userConnection)
                        //   props.stores.bookStore.connectionsListConnection = userConnection;
                        //   navigate("/connections/details/sharedTo");
                        //   // openBookInWrite({
                        //   //   bookDraftId: book.primaryBookDraftId
                        //   // });
                        // }} >
  
                        //   <ListItemIcon>
                        //     <CommentIcon sx={{ color: theme.palette.background.main }} />
                        //   </ListItemIcon>
                        //   <ListItemText primary="Styles" />
                        // </ListItemButton>,
                        <ListItemButton onClick={(e) => {
                          e.preventDefault();
                          console.log(userConnection)
                          props.stores.bookStore.connectionsListConnection = userConnection;
                          navigate("/connections/details/permissions");
                          // openBookInWrite({
                          //   bookDraftId: book.primaryBookDraftId
                          // });
                        }}  >
  
                          <ListItemIcon>
                            <CommentIcon sx={{ color: theme.palette.background.main }} />
                          </ListItemIcon>
                          <ListItemText primary="Permissions" />
                        </ListItemButton>,
                      ]}
                    />
                    }
                }
               
            ]} />

    }, [userConnections, error, loading]);

    return (
        <>

            {table}
        </>


    )
}
export default inject('stores')(observer(ConnectionsTable));