import React, { useEffect, useState } from 'react';
import { observable } from 'mobx';
import { useUpdateItem } from '../persistance/persistanceHooks';
import { enqueueSnackbar } from 'notistack'

export function useUpdateEntity(props) {

  const [actualUpdateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] = useUpdateItem();



  const updateItem = ({
    itemExpectedVersion,
    itemKey,
    itemType,
    fieldName,
    fieldValue
  }) => {
    actualUpdateItem({
      itemExpectedVersion,
      itemKey,
      itemType,
      fieldName,
      fieldValue
    }, () => {
      enqueueSnackbar('Entity updated', { variant: 'success' })
    }, (error) => {
      try {

        enqueueSnackbar(error.message, { variant: 'error' })
      } catch (err) {
        console.log(err);
      }

    })
  }

  return {
    updateItem,
    updateItemLoading,
    updateItemError
  };
}
