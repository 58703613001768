import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';


const LaurasTextField = (props) => {


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <TextField
            fullWidth
            label={props.label}
            variant="outlined"
            style={{ marginBottom: '1em' }}
        />
    )
}
export default inject('stores')(observer(LaurasTextField));