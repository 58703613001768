import React, { useState, useEffect } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useAddBookDraftDocumentPart, useMoveBookDraftDocumentPart } from '../../persistance/persistanceHooks';
//import StandardTree from './StandardTree';
import { bookDraftTree2 } from './bookDraftTreeBuilder2';
import config from '../../config';
//import BoxSetContributedBookSelectorDialog from '../dialogs/BoxSetContributedBookSelectorDialog';
//import './antd-dark.css';
//import 'antd/dist/antd.variable.min.css';
import { Tree } from 'antd';
import { Menu, Dropdown } from 'antd';
import ContextMenu from './ContextMenu';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useUpdateItem } from '../../persistance/persistanceHooks';
import EntityManager from '../../domain/EntityManager';
import { Box, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import StandardDialog from '../StandardDialog';
import InsertBoxsetBookPanel from '../panels/InsertBoxsetBookPanel';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TreeNode from './TreeNode';
// import { DndProvider } from 'react-dnd'
// import { useDrag } from 'react-dnd'
// import { useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
//import Example from '../dnd/example';
// import { Draggable } from '../dnd/Draggable';
// import { Box as DragBox } from '../dnd/Box';
import { useMediaQuery } from 'react-responsive'
import CameraOutline from 'mdi-material-ui/CameraOutline';
import AnimationOutline from 'mdi-material-ui/AnimationOutline';
import Database from 'mdi-material-ui/Database';
import Book from 'mdi-material-ui/Book';
import BookOutline from 'mdi-material-ui/BookOutline';
import BookOpenVariant from 'mdi-material-ui/BookOpenVariant';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import FileDocumentEdit from 'mdi-material-ui/FileDocumentEdit';
import FolderOutline from 'mdi-material-ui/FolderOutline';
import VideoOutline from 'mdi-material-ui/VideoOutline';
import StandardSkeleton from '../../StandardSkeleton';
import { StandardPaper } from '../StandardPaper';
import ScrollingGridContainer from '../ScrollingGridContainer';
import ScrollingGridItem from '../ScrollingGridItem';



const ItemTypes = {
    KNIGHT: 'knight'
}

const wrapIcon = (icon, theme) => {

    return <div style={{
    }}>
        <span style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '.5em',
            color: theme.palette.standardTree.nodes.color.main
        }}>{icon}</span>
    </div>


}

const BookDraftTree3 = (props) => {


    let { bookDraft, selectedId } = props;

    let userId = props.stores.userStore?.user?.id;

    let writingBookDraft = props.bookDraft;
    let writingBookDraft2 = props.bookDraft2;

    let [nodes, setNodes] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    let [contextSelectedBddp, setContextSelectedBddp] = useState();
    const [createDocumentPartBatch, { data: mutationData, loading: mutationLoading, error: mutationError }] = useAddBookDraftDocumentPart();
    const [showAddBookDialog, setShowAddBookDialog] = React.useState(null);

    const theme = useTheme();
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => setAnchorEl(null);



    const isXs = useMediaQuery({ maxWidth: 599 })
    const isSm = useMediaQuery({ maxWidth: 600 })
    const isMd = useMediaQuery({ maxWidth: 900 })
    const isLg = useMediaQuery({ maxWidth: 1200 })
    const isXl = useMediaQuery({ minWidth: 1536 })


    const partTypeIcons = {
        BOXSETS: wrapIcon(<AnimationOutline />, theme),
        BOXSET: wrapIcon(<AnimationOutline sx={{
            color: theme.palette.standardTree.heading.color.main
        }} />, theme),
        TOPBOOK: wrapIcon(<BookOutline color={theme.palette.text.main} />, theme),
        BOOKS: wrapIcon(<BookOutline />, theme),
        BOOK: wrapIcon(<BookOpenVariant sx={{
            color: theme.palette.standardTree.heading.color.main
        }} />, theme),
        VOLUME: wrapIcon(<FolderOutline />, theme),
        PART: wrapIcon(<FolderOutline />, theme),
        CHAPTER: wrapIcon(<FolderOutline />, theme),
        SCENE: wrapIcon(<VideoOutline />, theme),
        SNAPSHOTS: wrapIcon(<CameraOutline />, theme),
        SNAPSHOT: wrapIcon(<CameraOutline />, theme),
        TRASH: wrapIcon(<TrashCanOutline />, theme),
        RESEARCH: wrapIcon(<FolderOutline />, theme),
        FOLDER: wrapIcon(<FolderOutline />, theme),
        EDIT: wrapIcon(<AccountEdit />, theme),
        SNAPSHOT: wrapIcon(<CopyAllIcon />, theme),
        //PDF:wrapIcon(<FilePdf />), theme,
        //WEBPAGE:wrapIcon(<Web />, theme),
        DEFAULT: wrapIcon(<FileDocumentEdit />, theme),


        //DEFAULT:<span></span>

    }










    const onContextMenuSelected = (menuId) => {
        //console.log(menuId)
        if (contextSelectedBddp) {

            let partType = menuId;
            // let bookDraftId = writingBookDraft.id;
            // let bookId = writingBookDraft.book.id;

            let bookDraftId = contextSelectedBddp.bookDraft.id;
            let bookId = contextSelectedBddp.bookDraft.book.id;


            let parentPartId = contextSelectedBddp?.partId;
            let previousBddp = contextSelectedBddp.lastPart;
            let starterDeltaOps = [];
            let deltaOpsJson = [];
            //let starterEscapedDeltaOps = [];


            try {

                let starterTemplatesBookDraft = props.stores.bookStore.startersBookDraft;
                let starterTemplateDocumentPart = starterTemplatesBookDraft.getBookDraftDocumentPartByPartId(config.STARTER_TEMPLATES[partType]);
                //console.log(starterTemplateDocumentPart);
                deltaOpsJson = starterTemplateDocumentPart.documentPart.deltaOps;
                starterDeltaOps = starterTemplateDocumentPart.documentPart.deltaOps.map((m) => {
                    return JSON.stringify(m);
                })
                //console.log(starterDeltaOps);
                // starterEscapedDeltaOps = starterTemplateDocumentPart.documentPart.deltaOps.map((m) => {
                // 	let s = JSON.stringify(m);
                // 	//s = s.replace(/""/g,'\"');
                // 	//s = s.replace(/\"/g,'\\"');
                // 	return s;
                // })

                // deltaOpsJson = JSON.stringify(deltaOpsJson).replace('"', '\"');

            } catch (err) {
                //console.log(err);
            }

            if (partType == 'ADD_BOOK') {

                setShowAddBookDialog(true);


            }

            else if (menuId && menuId == 'DELETE') {
                alert('Delete this item');
            }
            else {
                createDocumentPartBatch({
                    userId,
                    partType,
                    bookDraftId,
                    bookId,
                    parentPartId,
                    starterDeltaOps,
                    prevPartId: previousBddp?.partId

                }, (newBddp) => {

                    writingBookDraft.addBookDraftDocumentPart({
                        data: newBddp
                    });
                    props.stores.bookStore.recipeTimestamp = Date.now();


                });
            }


        }
    }






    const onContextSelected = (id) => {
        let bddp = writingBookDraft.getBookDraftDocumentPartByPartId(id);
        if (!bddp) {
            bddp = writingBookDraft2.getBookDraftDocumentPartByPartId(id);
        }
        setContextSelectedBddp(bddp)
    }






    const createChildNodes = ({
        parentBddp
    }) => {

        //let rootChildBddps = bookDraft.bookDraftDocumentParts.filter((bddp) => bddp.parentPartId == parentBddp.partId);

        let childNodes = parentBddp.getSortedChildren().map((bddp) => {

            let grandChildNodes = createChildNodes({
                parentBddp: bddp,
            })

            // return <TreeNode title={bddp.documentPart.title} childNodes={grandChildNodes} />
            let icon = partTypeIcons[bddp.documentPart.partType] != null ? partTypeIcons[bddp.documentPart.partType] : partTypeIcons['DEFAULT'];

            let observableDocumentPart = new observable(bddp.documentPart)

            let documentPart = bookDraft.getDocumentPartById(bddp.partId);

            return <Box
                pojo={bddp}
                key={'dragged' + bddp.documentPart.id}
                name={bookDraft?.book.title}
                component={
                    <TreeNode
                        pojo={bddp}
                        icon={icon}
                        selected={(selectedId && selectedId == bddp.documentPart.id) ? true : false}
                        //expanded={bddp?.expanded}
                        item={bddp}
                        toggleExpanded={() => {
                            bddp.expanded = !bddp.expanded
                        }}
                        //key={'treeNode'+bddp.documentPart.id} 
                        title={documentPart.derivedTitle}
                        documentPart={bddp.documentPart}
                        onClick={(e) => {

                            //props.onDocumentPartSelected(bddp);

                            props.onDocumentPartSelected({
                                id: bddp.partId
                            });
                        }}
                        childNodes={grandChildNodes}
                        onMoved={({
                            dragged,
                            dropped,
                            position }) => {

                            bookDraft.moveBookDraftDocumentPart({
                                movedBddp: dragged,
                                targetBddp: dropped,
                                position
                            })

                        }}
                        onContextMenu={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            //console.log(e);
                            handleClick(e);
                            onContextSelected(bddp.partId)



                        }}
                    />} />

            //   let item = {
            //     key: bddp.partId,
            //     title: bddp.documentPart.derivedTitle,
            //     icon: icon,
            //     bddp,
            //     titleRender: (data) => {
            //       return createTitleComponent(bddp.documentPart)
            //     },
            //     children: []
            //   }

            //   parentItem.children.push(item);


        });

        return childNodes;

    }



    useEffect(() => {

        let newNodes = [];

        // newNodes.push(TreeNode({
        //     title: bookDraft?.book.title
        // }))

        if (bookDraft) {

            let rootBddp = bookDraft?.rootBookDraftDocumentPart;

            let rootChildNodes = createChildNodes({
                parentBddp: rootBddp,
            });

            // let rootNode = <Draggable  
            //     name={bookDraft?.book.title} 
            //     component={<TreeNode title={bookDraft?.book.title} childNodes={rootChildNodes} />} 
            //     onMoved={(args)=>{
            //         console.log(args);
            //     }}/>

            let isBoxSet = bookDraft?.book.boxSet != null ? true : false;
            let icon = isBoxSet ? partTypeIcons['BOXSET'] : partTypeIcons['BOOK'];

            let rootNode = <TreeNode
                title={bookDraft?.book.title}
                selected={(selectedId && selectedId == rootBddp.documentPart.id) ? true : false}
                item={rootBddp}
                documentPart={rootBddp.documentPart}
                toggleExpanded={() => {
                    rootBddp.expanded = !rootBddp.expanded
                }}
                onClick={(e) => {

                    props.onDocumentPartSelected({ id: rootBddp.partId });
                }}
                childNodes={rootChildNodes}
                // icon={<MenuBookIcon
                //     sx={{
                //         color: theme.palette.standardTree.heading.color.main,
                //         marginRight: '.5em'
                //     }} />}
                icon={icon}
                onMoved={({
                    dragged,
                    dropped,
                    position }) => {

                    bookDraft.moveBookDraftDocumentPart({
                        movedBddp: dragged,
                        targetBddp: rootBddp,
                        position
                    })


                }}
                onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    //console.log(e);
                    handleClick(e);
                    onContextSelected(rootBddp.partId)



                }} />

            newNodes.push(rootNode)

            setNodes(newNodes);

        }

    }, [bookDraft, bookDraft?.version, selectedId]);


    {/* {nodes} */ }


    let trashBinNode = <TreeNode
        title={'Trashbin'}
        selected={false}
        item={{
            partId: 'Trashbin',
            expanded: false
        }}
        toggleExpanded={() => {
            //rootBddp.expanded = !rootBddp.expanded
        }}
        onClick={(e) => {

            //props.onDocumentPartSelected({ id: rootBddp.partId });
        }}
        childNodes={[]}
        icon={<TrashCanOutline
            sx={{
                color: theme.palette.standardTree.title.color.main,
                marginRight: '.5em'
            }} />}
        onMoved={({
            dragged,
            dropped,
            position }) => {

        }}
        onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClick(e);
            //onContextSelected(rootBddp.partId)
        }}
    />

    let backend = HTML5Backend;

    if (isXs) {

        backend = TouchBackend;

    }

    if (!bookDraft) {

        return <StandardSkeleton />
    }
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%'
            //width: theme.components.standardTree.nodes.width,
            //border: '1px solid white'
        }}>
            <Paper focusable='false' tabIndex={-1} className='toolbar-wrapper' sx={{
                display: 'flex',
                justifyContent: 'space-around',
                'flex-direction': 'row',
                'flex-wrap': 'wrap',
                'alignItems': 'center',
                width: '100%',
                border: '1px solid gray',
                padding: '0em 0em .5em 0em'
            }}>
                <Typography variant={theme.palette.standardTree.heading.variant} color={theme.palette.standardTree.heading.color.main}>{props.heading}</Typography>


            </Paper>
            <ScrollingGridContainer container columns={100} direction={'row'}>

                <ScrollingGridItem xs={100} >

                    {/* <DndProvider backend={backend}> */}


                        <Box sx={{
                            flex: 1,
                            width: '100%',
                            // border: '1px solid red'
                        }}>
                            {nodes}

                        </Box>

                        <Box sx={{
                             width: '100%',
                            // border: '1px solid red'
                        }}>

                            {!props.hideTrashbin &&
                                trashBinNode
                            }

                        </Box>
                    {/* </DndProvider> */}


                    {/* {!isXs &&
                    <DndProvider backend={HTML5Backend}>


                        <Box sx={{
                            width: theme.components.standardTree.nodes.width,
                            // border: '1px solid red'
                        }}>
                            {nodes}

                        </Box>
                        <Box sx={{
                            width: theme.components.standardTree.nodes.width,
                            // border: '1px solid red'
                        }}>

                            {trashBinNode}

                        </Box>
                    </DndProvider>
                }


                {isXs &&
                    <DndProvider backend={TouchBackend}>


                        <Box sx={{
                            width: theme.components.standardTree.nodes.width,
                            // border: '1px solid red'
                        }}>
                            {nodes}

                        </Box>
                        <Box sx={{
                            width: theme.components.standardTree.nodes.width,
                            // border: '1px solid red'
                        }}>

                            {trashBinNode}

                        </Box>


                    </DndProvider>
                } */}




                    {open &&
                        <ContextMenu
                            anchorEl={anchorEl}
                            open={open}
                            bookDraft={bookDraft}
                            contextSelectedType={contextSelectedBddp.documentPart.partType}
                            onSelected={({
                                menuId,
                            }) => {

                                //console.log('menuId:'+menuId);
                                onContextMenuSelected(menuId);
                            }}
                            handleClose={() => {
                                setAnchorEl(null)
                            }} />
                    }

                    {/* <span style={{
                'text-overflow': 'ellipsis',
                'white-space': 'nowrap',
                'border': '1px solid red',
                'width': theme.components.standardTree.nodes.width,
                'display': 'block',
                'overflow': 'hidden'
            }}>asdasdasdas asdasdasdas asdasdasdas asdasdasdas asdasdasdas asdasdasdas </span> */}
                    <StandardDialog
                        title={"Add a Contributed Book"}
                        open={showAddBookDialog}
                        onClose={() => {

                            setShowAddBookDialog(false);

                        }}

                        dialogContent={<InsertBoxsetBookPanel book={writingBookDraft?.book} parentBddp={contextSelectedBddp} />}>

                    </StandardDialog>
                </ScrollingGridItem>
            </ScrollingGridContainer>
        </Box>

    )
};


export default inject('stores')(observer(BookDraftTree3));