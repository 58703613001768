import Quill from 'quill';
//import Parchment from 'parchment';
//import Popper from 'popper.js';
import { v4 as uuid }  from 'uuid';
const Parchment = Quill.import('parchment');
let Inline = Quill.import('blots/inline');
var $ = require("jquery");

let generateClassNames = function ({ accepted, rejected, preview, editorPinned, requestorPinned, editorMode = false }) {

  editorPinned = (editorPinned && editorPinned == 'true') ? true : false;
  if (!editorMode) {
    editorPinned = (requestorPinned && requestorPinned == 'true') ? true : false;
  }
  let acceptedClass = '';
  if (accepted && accepted == 'true') {
    if (preview && !editorPinned) {
      //acceptedClass = 'editingInsertFinalViewAccepted';
    } else {
      //acceptedClass = 'editingInsertBlotAccepted';
    }

  }
  else if (rejected && rejected == 'true') {


    if (preview && !editorPinned) {
      //acceptedClass = 'editingInsertFinalViewRejected';
    } else {
      //acceptedClass = 'editingInsertBlotRejected';
    }


  }
  else {

    //acceptedClass = 'editingInsertBlotPending';

    if (preview && !editorPinned) {
      //act as if it was accepted since we have nothing else from the author.
      //acceptedClass = 'editingInsertFinalViewAccepted';
    } else {
      //acceptedClass = 'editingInsertBlotPending';
    }

  }

  return 'editingBlot editingMovedPreviewBlot ' + acceptedClass;

}


class EditingMovedPreviewBlot extends Inline {

  static create(args) {
    console.log(args);
    let node = super.create(args);



    node.setAttribute('contenteditable', 'false');
    node.setAttribute('editorComment', args.editorComment);
    //node.setAttribute('uuid', args.uuid);

    //value = this.sanitize(value);
    //node.setAttribute('href', args.href);
    //node.setAttribute('id', args.href);
    //node.dataset.href = args.href;
    node.dataset.uuid = args.uuid;
    node.dataset.type = 'editingMovedPreviewBlot';



    return node;
  }

  static formats(domNode) {
    //return domNode.dataset.src
    //return domNode.getAttribute('href');

    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
      uuid: domNode.dataset.uuid,




    };
    //value.id = domNode.dataset.id;
    return value;
  }

  static value(domNode) {
    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
      uuid: domNode.dataset.uuid,
      type: 'editingMovedPreviewBlot',


    };
    //value.id = domNode.dataset.id;
    return value;
  }

  /*static sanitize(url) {  
    return sanitize(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
  }*/

  format(name, args) {
    if (name !== this.statics.blotName || !args) return super.format(name, args);
    //value = this.constructor.sanitize(value);
    //this.domNode.setAttribute('href', args.href);
    this.domNode.setAttribute('uuid', args.uuid);


  }

  
}
EditingMovedPreviewBlot.blotName = 'editingMovedPreviewBlot';
EditingMovedPreviewBlot.tagName = 'span';
EditingMovedPreviewBlot.className = 'editingMovedPreviewBlot';
//EditingDeleteBlot.SANITIZED_URL = 'about:blank';
//EditingDeleteBlot.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];





export { EditingMovedPreviewBlot as default };
