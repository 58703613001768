import React, { useState, useEffect } from "react";
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetSnapshots, useOpenBookInWrite, useOpenSnapshotInWrite } from '../../managers/BooksManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import SquareAvatar from "./SquareAvatar";
import DeleteIcon from '@mui/icons-material/Delete';
import StandardRoundedAction from "../actions/StandardRoundedAction";
import Feather from "mdi-material-ui/Feather";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import TablePopupMenu from "../menus/TablePopupMenu";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CommentIcon from '@mui/icons-material/Comment';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import ButtonTableCell from "../table/ButtonTableCell";
import DeleteSnapshotPanel from "../panels/DeleteSnapshotPanel";
import RestoreSnapshotPanel from "../panels/RestoreSnapshotPanel";
import StandardDialog from "../StandardDialog";
import EmptyContent from "../EmptyContent";
import StandardList from "./StandardList";
import CreateSnapshotAssembly from "../assemblies/CreateSnapshotAssembly";
import Scrollbars from "react-custom-scrollbars-2";
import ScrollingGridContainer from "../ScrollingGridContainer";
import ScrollingGridItem from "../ScrollingGridItem";

let missingBookImage = require('../../assets/images/image-not-found.png')

const BookSnapshotsList2 = (props) => {

  let user = props.stores.userStore.user;
  let { book } = props;

  let { listSnapshots, snapshots, error: listSnapshotsError, loading: listSnapshotsLoading } = useGetSnapshots();

  const { openBookInWrite } = useOpenBookInWrite(props);
  const { openSnapshotInWrite } = useOpenSnapshotInWrite(props, false);
  const [showRestoreSnapshotDialog, setShowRestoreSnapshotDialog] = React.useState(false);
  const [showDeleteSnapshotDialog, setShowDeleteSnapshotDialog] = React.useState(false);
  const [selectedSnapshot, setSelectedSnapshot] = React.useState(null);

  useEffect(() => {

    if (book != null) {
      listSnapshots({
        bookId: book.id,
        onCompleted: () => {

        }
      })
    }
  }, [book]);










  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();


  const createSecondaryActions = (snapshot) => {

    let secondaryActions = [];


    secondaryActions.push(<ButtonTableCell onClick={() => {
      setSelectedSnapshot(snapshot);
      setShowRestoreSnapshotDialog(true)
    }}>Restore</ButtonTableCell>);

    secondaryActions.push(<ButtonTableCell onClick={() => {
      openSnapshotInWrite({
        bookDraftId: book.primaryBookDraftId,
        snapshotId: snapshot.id
      });
    }}>Load</ButtonTableCell>);


    return secondaryActions;
  }


  return (
    <Box

      //xs={15} 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: theme.components.standardTree.nodes.width,
        height: '100%',
        width: '100%'
        //border: '1px solid white'
      }}
    >

      {/* <Box sx={{
        height: '5em',
        width: '100%'
        //border: '1px solid white'
      }}>
        This is the detail area
      </Box> */}
      <ScrollingGridContainer container columns={100} direction={'row'}>

        <ScrollingGridItem xs={100} >

          <Box sx={{
            marginBottom: '10em'
          }}>
            <StandardList
              title={'Snapshots'}
              headerActions={<CreateSnapshotAssembly book={book} />}
              component="div" disablePadding>
              {snapshots && snapshots.length > 0 ?
                snapshots.map((snapshot) => {

                  return <>

                    <ListItem
                      alignItems="flex-start"

                      secondaryAction={



                        <Box sx={{
                          display: 'flex'
                        }}>
                          {createSecondaryActions(snapshot)}

                        </Box>







                      }>
                      <ListItemAvatar
                        onClick={(e) => {
                          e.preventDefault();
                          props.onSelected(book);
                        }}>
                        <SquareAvatar
                          //bucketName={config.user_uploads_bucket_name}
                          s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + book?.coverUrl}
                          missingContent={<img src={missingBookImage} />}
                        />
                        {/* <Avatar alt="Remy Sharp" variant="square" src="https://local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm.s3.amazonaws.com/uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/c7cb5750-3627-11ed-914f-5d91b6c4c7eb_planetStrider.png">

              </Avatar> */}
                      </ListItemAvatar>
                      <ListItemText
                        primaryTypographyProps={{
                          noWrap: true
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          props.onSelected(snapshot);
                        }}
                        primary={snapshot?.name}
                        secondary={
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {snapshot?.description}
                            </Typography>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="caption"
                              color="text.secondary"
                            >
                              Words: 2,432
                            </Typography>
                            {/* {" — I'll be in your neighborhood doing errands this…"} */}
                          </Box>
                        }

                      />

                    </ListItem>
                    <Divider variant="inset" />
                  </>
                  // <Divider variant="inset"/>


                  // return <ListItem secondaryAction={createSecondaryActions(book)}>
                  //   <ListItemAvatar>
                  //     <AvatarTableCell
                  //       bucketName={config.user_uploads_bucket_name}
                  //       s3Src={book?.coverUrl}
                  //     //title={userConnection?.connectedUser?.name}
                  //     />
                  //   </ListItemAvatar>
                  //   <ListItemText primary={book?.title} secondary="" />

                  // </ListItem>
                })
                :
                <Box sx={{
                  marginTop: '3em'
                }}>
                  <EmptyContent tagline={'Snapshots will appear here.'} />
                </Box>
              }
              <StandardDialog
                open={showRestoreSnapshotDialog}
                onClose={() => {
                  setShowRestoreSnapshotDialog(false)
                }}
                onOk={() => {


                }}
                title={'Restore Snapshot'}
                dialogContent={
                  <RestoreSnapshotPanel
                    book={book}
                    snapshot={selectedSnapshot}
                    onClose={() => {
                      setShowRestoreSnapshotDialog(false)
                    }}
                    onRestored={() => {
                      setShowRestoreSnapshotDialog(false)
                      setSelectedSnapshot(null);
                      openBookInWrite({
                        bookDraftId: book.primaryBookDraftId
                      });
                    }} />
                }
              >
              </StandardDialog>

              <StandardDialog
                open={showDeleteSnapshotDialog}
                onClose={() => {
                  setShowDeleteSnapshotDialog(false)
                }}
                onOk={() => {


                }}
                title={'Delete Snapshot'}
                dialogContent={
                  <DeleteSnapshotPanel book={book} snapshot={selectedSnapshot}

                    onClose={() => {
                      setShowDeleteSnapshotDialog(false)
                    }}
                    onDeleted={() => {
                      setShowDeleteSnapshotDialog(false)
                      setSelectedSnapshot(null);

                    }} />
                }
              >
              </StandardDialog>

            </StandardList>
          </Box>

        </ScrollingGridItem>

      </ScrollingGridContainer>
    </Box>
  )
}

export default inject('stores')(observer(BookSnapshotsList2));