import React, { useState, useEffect } from "react";
import { inject, observer } from 'mobx-react';
import { useManagerCreateBook } from '../../../managers/BooksManager';
import { useDropzone } from 'react-dropzone';
import { useImportBookDraftDocument } from '../../../persistance/persistanceHooks';
import StorageManager, { useUploadBucketItem } from '../../../managers/StorageManager';
import config from '../../../aws-exports';
import StandardUIDialog from "../../UI/StandardUIDialog";
import StandardUIButton from "../../UI/StandardUIButton";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Typography from '@mui/material/Typography';
import StandardUITextField from "../../UI/StandardUITextField";
import { useManagerRollbackVersion } from "../../../managers/BooksManager";

const RollbackDocumentPart = (props) => {

    let user = props.stores.userStore.user;

    let {documentPart, targetRollbackVersion} = props;
    let [title, setTitle] = useState(null);
    let [uploadedFileS3Key, setUploadedFileS3Key] = useState(null);
    let [selectedFile, setSelectedFile] = useState(null);
    //let [uploadedFileName, setUploadedFileName] = useState(null);


    const { rollbackVersion, loading: rollbackVersionLoading, error: rollbackVersionError } = useManagerRollbackVersion(props);





    const handleRollback = () => {


        rollbackVersion({
            expectedVersion:documentPart.version,
            documentPartId:documentPart.id,
            rollbackVersion:targetRollbackVersion
        }, () => {
            if (props.onDeleted) {
                props.onDeleted();
            }

        })
        props.onRollbackComplete();


    }

    return (
        <StandardUIDialog
            open={props.open}
            onClose={props.onClose}
            title='Upload or Create Book'
            dialogContent={
                <div>
                    <Card
                        variant="outlined"
                        style={{ marginBottom: '3em' }}
                    >
                        <CardContent
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                        >

                            <Typography variant="body2">
                                Rollback to this version
                            </Typography>
                        </CardContent>
                    </Card>
                    {/* <StandardUITextField label='Title' onChange={handleTitleChange} />
                    <StandardUITextField label='Subtitle' />
                    <StandardUITextField label='Author' /> */}
                </div>
            }  
            cardActions={
                <StandardUIButton
                    buttonAction='Rollback'
                    autoFocus
                    disabled={false} onClick={(e) => {
                        e.preventDefault();
                        handleRollback();
                    }}
                />
            }
        />
    )
}

export default inject('stores')(observer(RollbackDocumentPart));