import React, { useState, useEffect } from "react";
import { Avatar, AvatarGroup, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import { useLoadBookEditRequests, useOpenEditRequestInEdit } from '../../managers/EditRequestsManager';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useGetBookShares, useManagerDeleteBookUserShare, useManagerDeleteBookUserGroupShare } from '../../managers/BooksManager';

const BookSharedWithList = (props) => {

  let user = props.stores.userStore.user;

  let { book } = props;

  const { sharedWithUsers, bookUserSharesLoading, bookUserSharesError, sharedWithUserGroups, bookUserGroupSharesLoading, bookUserGroupSharesError } = useGetBookShares(props, book);



  const createSecondaryActions = (editRequest) => {

    let secondaryActions = [];

    if (props.showSelectIcon) {
      secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
        // openEditRequestInEdit({
        //   editRequest
        // });
      }}>
        <AccountEdit />
      </IconButton>);
    }

    return secondaryActions;
  }


  let tableData = [];
  if (sharedWithUsers) {
    tableData = tableData.concat(sharedWithUsers.map((bookUserShare) => {

      return {
        name: bookUserShare.sharedWithUser?.name,
        profilePicUrl: bookUserShare.sharedWithUser?.profilePicUrl,
        type: 'User',
        createdAt: bookUserShare?.createdAt,
        id: bookUserShare.sharedWithUser.id
      }
    }))
  }


  if (sharedWithUserGroups) {

    tableData = tableData.concat(sharedWithUserGroups.map((bookUserGroupShare) => {

      return {
        name: bookUserGroupShare?.userGroup.name,
        profilePicUrl: bookUserGroupShare?.userGroup.profilePicUrl,
        type: 'Group',
        createdAt: bookUserGroupShare?.createdAt,
        id: bookUserGroupShare?.userGroup.id
      }
    }))
  }

  return (
    <AvatarGroup max={8}>
      {
        tableData.map((bookShare) => {

          return <Avatar
            sxx={{ width: '2.5em', height: '2.5em' }} alt=""
            src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + bookShare?.profilePicUrl} />
         
         
        })
      }
    </AvatarGroup>

  )


  // return (
  //   <List component="div" disablePadding>
  //     {
  //       tableData.map((bookShare) => {

  //         return <ListItem
  //           secondaryAction={createSecondaryActions(bookShare)}
  //         >
  //           <ListItemAvatar>
  //             <AvatarTableCell
  //               bucketName={config.user_uploads_bucket_name}
  //               s3Src={bookShare?.profilePicUrl}
  //               title={bookShare?.name}
  //             />
  //           </ListItemAvatar>
  //           {/* <ListItemText primary={userConnection?.connectedUser?.name} secondary="" /> */}

  //         </ListItem>
  //       })
  //     }
  //   </List>

  // )
}

export default inject('stores')(observer(BookSharedWithList));