import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { Paper, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import StandardHelpLabel from '../../StandardHelpLabel';
import Checkbox from '@mui/material/Checkbox';
import StandardBlotImageSelector from '../../StandardBlotImageSelector';

const ImagePopper = (props) => {

    let { open, onClose = () => { }, anchorEl, imageBlot, documentPart } = props;

    const [size, setSize] = useState(imageBlot?.domNode?.dataset?.size)
    const [alignment, setAlignment] = useState(imageBlot?.domNode?.dataset?.alignment)
    const [includeCaption, setIncludeCaption] = useState(imageBlot?.domNode?.dataset?.includeCaption)

    useEffect(() => {

        setSize(imageBlot?.domNode?.dataset?.size);

    }, [imageBlot?.domNode?.dataset?.size]);

    useEffect(() => {

        setAlignment(imageBlot?.domNode?.dataset?.alignment);

    }, [imageBlot?.domNode?.dataset?.alignment]);

    useEffect(() => {

        setIncludeCaption(imageBlot?.domNode?.dataset?.includeCaption);

    }, [imageBlot?.domNode?.dataset?.includeCaption]);

    return (
        <Popper
            id={'imagePopper'}
            open={open}
            onMouseLeave={() => {
                onClose();

            }}

            anchorEl={anchorEl}
            transition
            placement="bottom"
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper elevation={6}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '1.5em'
                        }}>
                            <StandardHelpLabel title={'Image Details'} />

                            {/* <div style={{ 
                                display: 'flex',
                                alignItems:'center',
                                justifyContent:'space-between'
                            }}>
                                <StandardTextField label="url" fullWidth id="filled-basic" variant="filled" size="small" sx={{

                                }}
                                    value={size}
                                    onChange={(e) => {
                                        // setHref(e.target.value);
                                        // imageBlot.setHref(e.target.value);
                                        
                                    }} />
                                <OpenInNewIcon color='primary' onClick={(e)=>{

                                    e.preventDefault();
                                    //window.open(href);
                                }}/>
                            </div> */}

                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Image Size</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={size}
                                    name="radio-buttons-group"
                                    onChange={(e, value) => {
                                        e.preventDefault();
                                        setSize(e.target.value);
                                        imageBlot.setSize(e.target.value);
                                        //documentPart.contentUpdatedTimestamp = new Date().getTime();

                                    }}
                                >
                                    <FormControlLabel value="small" control={<Radio />} label="Small" />
                                    <FormControlLabel value="medium" control={<Radio />} label="Medium" />
                                    <FormControlLabel value="large" control={<Radio />} label="Large" />
                                    <FormControlLabel value="xlarge" control={<Radio />} label="X-Large" />
                                </RadioGroup>
                            </FormControl>

                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Alignment</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={alignment}
                                    name="radio-buttons-group"
                                    onChange={(e, value) => {
                                        e.preventDefault();
                                        setAlignment(e.target.value);
                                        imageBlot.setAlignment(e.target.value);
                                        //documentPart.contentUpdatedTimestamp = new Date().getTime();

                                    }}
                                >
                                    <FormControlLabel value="floatLeft" control={<Radio />} label="Left" />
                                    <FormControlLabel value="center" control={<Radio />} label="Center" />
                                    <FormControlLabel value="floatRight" control={<Radio />} label="Right" />
                                </RadioGroup>
                            </FormControl>

                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={includeCaption=='true'?true:false} onChange={(e)=>{
                                    e.preventDefault();
                                    setIncludeCaption(e.target.checked);
                                    imageBlot.setIncludeCaption(e.target.checked);


                                }}/>} label="Include caption" />
                            </FormGroup>
                            {/* <ImageIcon color='primary' onClick={()=>{
                                
                            }}/> */}
                           
                            <StandardBlotImageSelector 
                                title={'Cover Image'} 
                                entity={props.stores.bookStore.bookListBook} 
                                imageKeyFieldName={'coverUrl'}
                                onImageUploaded={(key)=>{
                                    //console.log(key);
                                    imageBlot.setSrc(key);
                                    
                                }}/>

                        </div>
                    </Paper>
                </Fade>
            )}
        </Popper>

    );
};


export default inject('stores')(observer(ImagePopper));
