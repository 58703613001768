import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { action } from "mobx"
import QuillEditor from './QuillEditor';
import Delta from 'quill-delta';
import { Box, Grid, Paper } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { useAddBookDraftDocumentPart, useLazyGetDocumentPart, useTransferEditsToOriginalBatch } from '../../persistance/persistanceHooks';
import FullpageImageEditor from './FullpageImageEditor';
import { useTheme } from '@mui/material/styles';
import WordCount from './WordCount';
import ContentEditable from 'react-contenteditable'

const DocumentPartEditor = (props) => {

    const theme = useTheme();

    let { bookDraftDocumentPart, readOnly = false, userComments = [], disabled, onTitleChanged } = props;
    const [sceneEditors, setSceneEditors] = useState([]);
    const [restoring, setRestoring] = useState(false);
    const [documentPartEditors, setDocumentPartEditors] = useState([]);
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [childrenChangeTimestamp, setChildrenChangeTimestamp] = useState();

    const documentPartEditorId = 'documentPartEditor' + bookDraftDocumentPart?.partId;





    let userId = props.stores?.bookStore.userId;
    let stores = props.stores;

    const [createDocumentPartBatch, { data: mutationData, loading: mutationLoading, error: mutationError }] = useAddBookDraftDocumentPart();
    const [transferEditsToOriginalBatch, { data: transferEditsToOriginalBatchData, loading: transferEditsToOriginalBatchLoading, error: transferEditsToOriginalBatchError }] = useTransferEditsToOriginalBatch();



    const [lazyGetDocumentPartToRestore, { loading: lazyGetDocumentPartToRestoreLoading, error: lazyGetDocumentPartToRestoreError, data: lazyGetDocumentPartToRestoreData, refetch: lazyGetDocumentPartToRestoreRefetch }] = useLazyGetDocumentPart();


    let deltaOpsNoMobx = JSON.parse(JSON.stringify(bookDraftDocumentPart.documentPart.deltaOps));
    ////console.log(deltaOpsNoMobx);

    useEffect(() => {

        //console.log(transferEditsToOriginalBatchLoading);
        //console.log(transferEditsToOriginalBatchData);
        //console.log(transferEditsToOriginalBatchError);

    }, [transferEditsToOriginalBatchData, transferEditsToOriginalBatchLoading])


    useEffect(() => {

        //console.log(transferEditsToOriginalBatchError);

    }, [transferEditsToOriginalBatchError])


    useEffect(() => {

        let finalDeltaOps = null;
        bookDraftDocumentPart.documentPart.deltaOps.forEach((delta) => {

            let parsedDelta = delta;//JSON.parse(delta);
            if (!finalDeltaOps) {

                finalDeltaOps = new Delta(parsedDelta);

            } else {

                finalDeltaOps = finalDeltaOps.compose(new Delta(parsedDelta));

            }
        });



        let sceneEditors = [];
        let documentPartEditors = [];

        if (bookDraftDocumentPart.documentPart.partType != 'CHAPTER' &&
            bookDraftDocumentPart.documentPart.partType != 'ROOT' &&
            bookDraftDocumentPart.documentPart.partType != 'TOPBOOK' &&
            bookDraftDocumentPart.documentPart.partType != 'FULLPAGEIMAGE') {
            sceneEditors.push(
                <QuillEditor
                    editMode={props.editMode}
                    disabled={disabled}
                    key={'quillEditor:' + bookDraftDocumentPart.documentPart.id}
                    readOnly={readOnly}
                    documentPart={bookDraftDocumentPart.documentPart}
                    delta={finalDeltaOps}
                    userComments={userComments.filter(f => f.documentPartId == bookDraftDocumentPart.documentPart.id)}

                />);
        }


        if (bookDraftDocumentPart) {

            //bookDraftDocumentPart.bookDraft.bookDraftDocumentParts.forEach((childBddp, index) => {
            bookDraftDocumentPart.getSortedChildren().forEach((childBddp, index) => {

                if (childBddp.documentPart.partType == 'SCENE') {

                    sceneEditors.push(<QuillEditor
                        disabled={disabled}
                        editMode={props.editMode}
                        key={'quillEditor:' + childBddp.documentPart.id}
                        readOnly={readOnly}
                        documentPart={childBddp.documentPart}
                        delta={childBddp.documentPart.delta}
                        showBreak={index == 0 ? false : true}
                        userComments={userComments.filter(f => f.documentPartId == childBddp.documentPart.id)}
                    />);

                }
                else if (childBddp.documentPart.partType == 'FULLPAGEIMAGE') {

                    documentPartEditors.push(<FullpageImageEditor
                        disabled={disabled}
                        editMode={props.editMode}
                        key={'documentPartEditor:' + childBddp.documentPart.id}
                        bookDraftDocumentPart={childBddp}
                        readOnly={readOnly}
                        userComments={userComments}
                        onChange={() => {
                            //alert('changed');
                        }}
                    />);

                }
                else {

                    documentPartEditors.push(
                        <DocumentPartEditor
                            disabled={disabled}
                            stores={props.stores}
                            editMode={props.editMode}
                            key={'documentPartEditor:' + childBddp.documentPart.id}
                            bookDraftDocumentPart={childBddp}
                            readOnly={readOnly}
                            userComments={userComments}
                            // onTitleChanged={(title)=>{
                            //     childBddp.title = title;
                            // }}
                            onDocumentPartCreated={(bddp) => {

                                if (props.onDocumentPartCreated) {
                                    props.onDocumentPartCreated(bddp);
                                }
                            }}
                        />);

                }


            });
        }

        setSceneEditors(sceneEditors);
        setDocumentPartEditors(documentPartEditors);
    }, [userComments, childrenChangeTimestamp]);


    useEffect(() => {


        try {
            restoreDeltaOps();


            // if (lazyGetDocumentPartToRestoreData?.getDocumentPart) {
            //     let { getDocumentPart: retrievedDocumentPart } = lazyGetDocumentPartToRestoreData;
            //     //documentPart.isWrongVersion = false;
            //     //bookDraftDocumentPart.documentPart.version = retrievedDocumentPart.version;
            //     // bookDraftDocumentPart.documentPart.deltaOps = retrievedDocumentPart.deltaOps.map((m) => {
            //     //     return JSON.parse(m);
            //     // });

            //     bookDraftDocumentPart.documentPart.setDeltaOps(retrievedDocumentPart.deltaOps);

            //     setRestoring(false);
            //     // let delta = new Delta(bookDraftDocumentPart.documentPart.composedDeltaOps);
            //     // try {
            //     //     quillInstance.setContents(delta, 'silent');
            //     // } catch (err) {
            //     //     //console.log(err);
            //     // }



            // }

        } catch (err) {
            //console.log(err);
        }


    }, [lazyGetDocumentPartToRestoreData])


    const restoreDeltaOps = () => {

        //console.log('restoreDeltaOps()...');

        if (lazyGetDocumentPartToRestoreData?.getDocumentPart) {
            let { getDocumentPart: retrievedDocumentPart } = lazyGetDocumentPartToRestoreData;
            //documentPart.isWrongVersion = false;
            //bookDraftDocumentPart.documentPart.version = retrievedDocumentPart.version;
            // bookDraftDocumentPart.documentPart.deltaOps = retrievedDocumentPart.deltaOps.map((m) => {
            //     return JSON.parse(m);
            // });

            bookDraftDocumentPart.documentPart.setDeltaOps(retrievedDocumentPart.deltaOps);
            setRestoring(false);

            // let delta = new Delta(bookDraftDocumentPart.documentPart.composedDeltaOps);
            // try {
            //     quillInstance.setContents(delta, 'silent');
            // } catch (err) {
            //     //console.log(err);
            // }



        }
    }


    const createDocumentPart = ({
        partType,
        selectNewBddp = true
    }) => {

        let bookDraftId = bookDraftDocumentPart.bookDraft.id;
        let bookId = bookDraftDocumentPart.bookDraft.book.id;
        let parentPartId = bookDraftDocumentPart.documentPart.id;
        let previousBddp = bookDraftDocumentPart.lastPart;
        let starterDeltaOps = [];
        let deltaOpsJson = [];

        createDocumentPartBatch({
            userId,
            partType,
            bookDraftId,
            bookId,
            parentPartId,
            prevPartId: previousBddp?.partId,
            starterDeltaOps

        }, (newBddp) => {

            let bddp = bookDraftDocumentPart.bookDraft.addBookDraftDocumentPart({
                data: newBddp
            });

            setChildrenChangeTimestamp(Date.now());
            props.stores.bookStore.recipeTimestamp = Date.now();
            if (selectNewBddp) {
                props.onDocumentPartCreated(bddp);
            }


        });

    }



    //let contentEditable = null;

    return (
        <div style={{
            borderx: '1px solid green',
            //backgroundColor:theme.palette.background.default

        }} className={'documentPartEditorWrapper'}
            id={documentPartEditorId}
            name={documentPartEditorId} >
            <Paper sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                overFlow: 'hidden',
                marginBottom: '1.5em',
                paddingBottom: '2em',
                minHeight: '30em',
                justifyContent: 'center',
                //color:theme.palette.text.writing,
                color: theme.palette.write?.text?.color,
                backgroundColor: theme.palette.write?.background?.color,
                //border:'1px solid red'
            }}>
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    //border: '1px solid red',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end'
                }}>

                    <WordCount
                        bookDraftDocumentPart={bookDraftDocumentPart}
                        readOnly={false}
                    />

                </Box>
                <Grid container direction={'column'} sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>

                    <Grid item sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginBottom: '3em'
                        //border:'1px solid red'
                    }}>
                        <ContentEditable
                            style={{
                                color: theme.palette.write.bookTitle.color,
                                fontSize: '3em',
                                fontFamily: 'serif'
                            }}
                            onKeyDown={(e) => {
                                if (e.charCode == 13) {
                                    e.preventDefault()
                                }
                            }}
                            //innerRef={contentEditable}
                            html={bookDraftDocumentPart.documentPart.derivedTitle} // innerHTML of the editable div
                            //disabled={false}       // use true to disable editing
                            onChange={(e) => {
                                if (e.charCode == 13) {
                                    e.preventDefault();
                                    return;
                                }
                                bookDraftDocumentPart.documentPart.title = e.target.value;

                                //Not a true version change. Just forces the tree to update.
                                bookDraftDocumentPart.bookDraft.version++;
                                //props.stores.bookStore.recipeTimestamp = Date.now();
                                props.stores.bookStore.addPendingFormatChange();
                                // if(onTitleChanged){
                                //     onTitleChanged(e.target.value)
                                // }
                                // action(e => {
                                //     bookDraftDocumentPart.documentPart.title = e.target.value;
                                //     e.preventDefault();
                                // })



                            }} // handle innerHTML change
                        //tagName='article' // Use a custom HTML tag (uses a div by default)
                        />

                        {/* <Typography align={'center'} variant={bookDraftDocumentPart.documentPart.partType == 'TOPBOOK' ? "h3" : "h5"}

                            sx={{
                                color: theme.palette.write.bookTitle.color,
                                fontFamily: 'serif'
                            }}>
                            {bookDraftDocumentPart.documentPart.derivedTitle}
                        </Typography> */}


                        {bookDraftDocumentPart.documentPart.partType == 'TOPBOOK' &&
                            <>
                                <Typography variant={"h5"} align={'center'}

                                    sx={{
                                        color: theme.palette.write.bookTitle.color,
                                        fontFamily: 'serif',
                                        marginTop: '5em'
                                    }}>
                                    {'by'}
                                </Typography>

                                <Typography variant={"h5"} align={'center'}

                                    sx={{
                                        color: theme.palette.write.bookTitle.color,
                                        fontFamily: 'serif',
                                        marginTop: '1em'
                                    }}>
                                    {'Bob Jones'}
                                </Typography>
                            </>
                        }


                        <Typography variant="h7" color={'red'}>{errorMessage}</Typography>
                        <Typography variant="h7" color={'green'}>{successMessage}</Typography>
                    </Grid>
                </Grid>






                {props.editMode &&
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        <Button onClick={(e) => {
                            e.preventDefault();
                            //console.log('reset...');
                            setRestoring(true);
                            if (lazyGetDocumentPartToRestoreData?.getDocumentPart) {

                                //lazyGetDocumentPartToRestoreRefetch();
                                restoreDeltaOps();

                            } else {
                                lazyGetDocumentPartToRestore({
                                    variables: {
                                        id: bookDraftDocumentPart.documentPart.originalPartId
                                    }
                                });
                            }
                        }}>
                            Reset
                        </Button>
                        <Button onClick={(e) => {
                            e.preventDefault();
                            //console.log('reset...');
                            let editRequest = stores.bookStore.selectedSentEditRequest;
                            setErrorMessage(null);
                            setSuccessMessage(null);


                            transferEditsToOriginalBatch({
                                editRequestId: editRequest.id,
                                editPartId: bookDraftDocumentPart.documentPart.id,
                                editPartExpectedVersion: bookDraftDocumentPart.documentPart.versiom,
                                actionType: 'TRANSFER_EDITS'
                            }, () => {
                                //console.log('complete...');
                                setSuccessMessage('Transfer successful');
                            }, (errorMessages) => {
                                //console.log(errorMessages);
                                if (errorMessages && errorMessages.length == 1) {
                                    setErrorMessage(errorMessages[0]);
                                }

                            })




                        }}>
                            XFER
                        </Button>





                    </div>
                }
                {/* <div>{'userComments'+props.userComments.length}</div> */}

                {/* <Grid container direction={'column'} sx={{
                    justifyContent: 'center'
                }}>
                    {!readOnly &&

                        <Grid item sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>

                            <Typography variant="h6">{bookDraftDocumentPart.documentPart.totalNumWords}</Typography>

                        </Grid>
                    }
                    {!readOnly &&

                        <Grid item sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="h6">{bookDraftDocumentPart.documentPart.numWords}</Typography>
                        </Grid>

                    }
                </Grid> */}

                {!restoring &&

                    sceneEditors

                }
                {/* {readOnly &&
                    <div><Button variant="outlined" onClick={(e) => {

                        e.preventDefault();

                    }}>+ COMMENT</Button>
                    </div>
                } */}
                {(bookDraftDocumentPart.documentPart.partType == 'VOLUME' && !readOnly) &&


                    <Grid container direction={'column'} sx={{
                        justifyContent: 'center'
                    }}>

                        <Grid item sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>

                            <Button
                                sx={{
                                    //backgroundColor:theme.palette.write.controls.backgroundColor,
                                    color: theme.palette.write.controls.color,
                                    borderColor: theme.palette.write.controls.color
                                }}
                                disableElevation={true}
                                variant="outlined"
                                onClick={(e) => {

                                    e.preventDefault();
                                    createDocumentPart({
                                        partType: 'PART'
                                    })



                                }}>+ PART</Button>

                        </Grid>

                    </Grid>



                }
                {(bookDraftDocumentPart.documentPart.partType == 'PART' && !readOnly) &&


                    <Grid container direction={'column'} sx={{
                        justifyContent: 'center'
                    }}>

                        <Grid item sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>

                            <Button
                                sx={{
                                    //backgroundColor:theme.palette.write.controls.backgroundColor,
                                    color: theme.palette.write.controls.color,
                                    borderColor: theme.palette.write.controls.color
                                }}
                                disableElevation={true}
                                variant="outlined"
                                onClick={(e) => {

                                    e.preventDefault();

                                    //let partType = 'CHAPTER';
                                    createDocumentPart({
                                        partType: 'CHAPTER'
                                    })


                                }}>+ CHAPTER</Button>

                        </Grid>

                    </Grid>




                }
                {(bookDraftDocumentPart.documentPart.partType == 'CHAPTER' && !readOnly) &&

                    <Grid container direction={'column'} sx={{
                        justifyContent: 'center'
                    }}>

                        <Grid item sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>

                            <Button
                                sx={{
                                    //backgroundColor:theme.palette.write.controls.backgroundColor,
                                    color: theme.palette.write.controls.color,
                                    borderColor: theme.palette.write.controls.color
                                }}
                                disableElevation={true}
                                variant="outlined"
                                onClick={(e) => {

                                    e.preventDefault();
                                    createDocumentPart({
                                        partType: 'SCENE',
                                        selectNewBddp: false
                                    })




                                }}>+ SCENE</Button>

                        </Grid>

                    </Grid>






                }

            </Paper>
            {documentPartEditors}
        </div>
    );
};


export default inject('stores')(observer(DocumentPartEditor));
