import React, { useState, useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import ePub from 'epubjs';
import IconButton from '@mui/material/IconButton';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';
import FastRewindOutlinedIcon from '@mui/icons-material/FastRewindOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import { BuildContext } from './TemplateManager';
import { BuildManager } from './BuildManager';
import { v4 as uuid } from 'uuid';

let jQuery = require("jquery");


const EReader = (props) => {

    const viewerRef = useRef();
    const [viewerId, setViewerId] = useState('epubViewer_' + uuid());
    const [currentDocumentBody, setCurrentDocumentBody] = useState();
    const [imagesTimestamp, setImagesTimestamp] = useState();
    const [mappedImages, setMappedImages] = useState({});
    const [width, setWidth] = useState();
    const [height, setHeight] = useState(667);

    let { recipeTimestamp } = props.stores.bookStore;
    const { content } = props;
    const { documentPart, currentRecipe, currentInflatedTemplates } = props;

    let bookDraftId = '';

    const setSize = () => {

        if (viewerRef.current) {
            const newWidth = viewerRef.current.clientWidth;
            //console.log('newWidth:' + newWidth);
            setWidth(newWidth);

            const newHeight = viewerRef.current.clientHeight;
            //console.log('newHeight:' + newHeight);
            setHeight(newHeight);
        }

    }

    useEffect(() => {

        window.addEventListener('resize', function () {
            //console.log('resized...');

            //console.log(viewerRef);
            setSize();

        });



    }, []);





    let book;
    let rendition;
    let viewerWidth = 350;
    let viewerHeight = 600;

    const generateContent = () => {

        //console.log('generateContent()...');
        let bm = new BuildManager();


        

        let calculatedRecipe = documentPart?.getRecipe().json;

        let mergedBooks = props.stores.bookStore.authoredBooks




        try {
            mergedBooks = [].concat(mergedBooks);
            props.stores.bookStore.writingBookDraft.book.boxSet.boxSetBooks.forEach((bsb) => {
                mergedBooks.push(bsb.book);
            });


        } catch (err) {
            // console.log(err);
        }


        try {
            mergedBooks = [].concat(mergedBooks);
            props.stores.bookStore.writingBookDraft.bookDraftDocumentParts.forEach((bddp) => {

                if (bddp.documentPart.alsoByBooks.items.length > 0) {

                    mergedBooks = mergedBooks.concat(bddp.documentPart.alsoByBooks.items);

                }

            });


        } catch (err) {
            console.log(err);
        }



        // mergedBooks.forEach((book)=>{

        //     console.log(book.title);
        // })




        /* VERY TEMPORARY */
        // let mappedImages = {
        //     'https://m.media-amazon.com/images/I/71cWEHLM9TL._AC_SL1500_.jpg': 'https://m.media-amazon.com/images/I/71cWEHLM9TL._AC_SL1500_.jpg',
        //     'scroll1.png': '/epubPreviewer/OEBPS/images/scroll1.png'
        // };
        if (documentPart) {
            documentPart.bookDraftDocumentPart.bookDraft.mappedImages = mappedImages;
            documentPart.bookDraftDocumentPart.bookDraft.book.mappedImages = mappedImages;
        }






        //let mergedBooks = props.stores.bookStore.authoredBooks;

        // let book = {
        //     title:'someBook'
        // }

        let bookMerlinContext = {

        };

        let buildContext = new BuildContext({
            //epubVersion:3,
            epubPlatform: 'EPUB_PREVIEWER'
        });


        let param = calculatedRecipe?.params['defaultCommonHeaderTemplate'];
        let generatedContent = bm.generateBodyContent({
            book: documentPart?.bookDraftDocumentPart.bookDraft.book,
            epubType: 'EPUB_PREVIEWER',
            mappedImages: mappedImages,
            //documentPart:this.props.stores.bookStore.currentEditingChapterDocumentPart,
            documentPart: documentPart,
            //documentPartId:'chapter001',
            //partType:'CHAPTER',
            //content:"<p>This is the first paragraph</p>",
            //recipe: currentRecipe,
            recipe: calculatedRecipe,
            context: bookMerlinContext,
            buildContext: buildContext,
            inflatedTemplates: currentInflatedTemplates,
            //allBooks:this.props.stores.bookStore.authoredBooks,
            allBooks: mergedBooks,
            user: props.stores.bookStore.user,
            appendStyleToBody:true
        });



        let chapterBodyContent = generatedContent.bodyContent;

        // if(chapterBodyContent){

        //     setBodyContentReachable(true);
        // }
        //this.test_setContent(chapterBodyContent);

        if (generatedContent.parseError) {
            jQuery(currentDocumentBody).html(generatedContent.parseError);
        } else {
            jQuery(currentDocumentBody).html(chapterBodyContent);
        }


        //console.log(content);
    }

    useEffect(() => {

        jQuery(currentDocumentBody).html('<div>initial content</div>');
        generateContent();

    }, [currentDocumentBody]);


    useEffect(() => {

        generateContent();

    }, [content, documentPart, currentRecipe, recipeTimestamp, mappedImages]);

    useEffect(() => {

        //console.log('initializing ereader...')
        try {

            book.destroy();

        } catch (err) {

        }

        try {

            //book = ePub("./epubPreviewer/OEBPS/content.opf?timestamp=" + Date.now());
            book = ePub("./epubPreviewer/OEBPS/content.opf");

            rendition = book.renderTo(viewerId, {
                //manager: "continuous", 	//loads the next section ahead of time. This will interfere with 
                //trying to update the content dynamically
                manager: "default",
                //width: viewerWidth,
                //height: viewerHeight,
                width: '100%',//viewerWidth,
                height: viewerHeight,
                spread: "always"
            });

            registerThemes();

            registerEventListeners();



            //jQuery(currentDocumentBody).html('<div>initial content</div>');

        } catch (err) {
            //console.log(err)
        }
    }, [viewerId, height]);


    const registerThemes = () => {

        rendition.themes.register("sepia", {
            "body": {
                "background": "tan",
                "color": "black",
                "word-break": "break-word"
            }
        })
        //rendition.themes.select("sepia");

        rendition.themes.register("black", {
            "body": {
                "background": "black",
                "color": "white",
                "word-break": "break-word"
            }
        })
        //rendition.themes.select("dark");

        rendition.themes.register("white", {
            "body": {
                "background": "white",
                "color": "black",
                "word-break": "break-word"
            }
        })
        rendition.themes.select("sepia");

        var displayed = rendition.display();

    }


    const registerEventListeners = () => {

        let that = this;
        //epub.Hooks.register('beforeChapterDisplay')

        rendition.on("rendered", function (section) {

            var nextSection = section.next();
            var prevSection = section.prev();
            if (nextSection) {
                ////console.log('next');
            }
            if (prevSection) {
                ////console.log('prev');
            }




        });


        rendition.hooks.content.register(function (contents, view) {



            let directionMode = 'UNKNOWN';
            try {
                if (contents.content.baseURI.includes('prev.')) {
                    directionMode = 'prev';
                    that.props.onPrevious();
                }
                if (contents.content.baseURI.includes('next.')) {
                    directionMode = 'next';
                    that.props.onNext();
                }
            } catch (err) {
                //console.log(err);
            }


            let jqDocument = jQuery(contents.document)[0];



            rendition.display('content.xhtml');

            let views = rendition.views();

            setCurrentDocumentBody(jqDocument.body);

            //jQuery(currentDocumentBody).html('<div>set it</div>');

            //that.updateEpubProperties(jqDocument.body);

        })







        rendition.hooks.render.register(function (contents, view) {

        })



        var keyListener = function (e) {
            // Left Key
            if ((e.keyCode || e.which) == 37) {
                //rendition.prev();
            }
            // Right Key
            if ((e.keyCode || e.which) == 39) {
                //rendition.next();
            }
        };
        rendition.on("keyup", keyListener);
        document.addEventListener("keyup", keyListener, false);



    }


    const updateLocationByNavigation = () => {

        try {
            let currentLocation = rendition.currentLocation();
            ////console.log(rendition.epubcfi);
            if (currentLocation) {
                if (currentLocation.start != null) {
                    //currentEPubPreviewerHRefCFI = currentLocation.start.cfi;

                }

            }
        } catch (err) {
            ////console.log(err);
        }

    }






    return (

        <div style={{
            height: '100%',
            width: '100%',
            borderx: '3px solid red',
            position: 'relative',
            paddingTop: '25.25%',
            paddingBottom: '25.25%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <div style={{
                positionx: 'absolute',
                maxWidthx: '100%',
                //height: '100%',
                width: '100%',
                border: '1em solid black',
                borderRadius: '2em',
                //marginTop: '25%',
                flex: 2

            }}>

                {/* <div>{documentPart?.title}</div> */}
                <div ref={viewerRef}
                    class="screen" style={{
                        backgroundColorx: 'red',
                        positionx: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '100%',
                        borderRadius: '2em'
                    }}>

                    <div id={viewerId} className='format-device-screen-epubjs' class="spreads" style={{borderRadius: '2em'}}>

                    </div>
                    <div style={{
                        //position:'relative',
                        //bottom:'20em',
                        height: '10%',
                        //borderRadius:'2em',
                        backgroundColor: 'black',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingx: '0em 20% 0em 20%'
                    }}>

                        <IconButton> <FastRewindOutlinedIcon color={'primary'} fontSize={'large'} /></IconButton>
                        <IconButton onClick={(e) => {



                        }}> <ArrowLeftOutlinedIcon color={'primary'} fontSize={'large'} /></IconButton>
                        <IconButton onClick={(e) => {



                        }}> <ArrowRightOutlinedIcon color={'primary'} fontSize={'large'} /></IconButton>
                        <IconButton> <FastForwardOutlinedIcon color={'primary'} fontSize={'large'} /></IconButton>
                    </div>
                </div>

            </div>
        </div>

    );
};


export default inject('stores')(observer(EReader));
