import Quill from 'quill';
//import Parchment from 'parchment';
//import Popper from 'popper.js';
import { v4 as uuid }  from 'uuid';
const Parchment = Quill.import('parchment');
let Inline = Quill.import('blots/inline');
var $ = require("jquery");

let generateClassNames = function ({ accepted, rejected, preview, editorPinned, requestorPinned, editorMode = false }) {

  editorPinned = (editorPinned && editorPinned == 'true') ? true : false;
  if (!editorMode) {
    editorPinned = (requestorPinned && requestorPinned == 'true') ? true : false;
  }
  let acceptedClass = '';
  if (accepted && accepted == 'true') {
    if (preview && !editorPinned) {
      //acceptedClass = 'editingInsertFinalViewAccepted';
    } else {
      //acceptedClass = 'editingInsertBlotAccepted';
    }

  }
  else if (rejected && rejected == 'true') {


    if (preview && !editorPinned) {
      //acceptedClass = 'editingInsertFinalViewRejected';
    } else {
      //acceptedClass = 'editingInsertBlotRejected';
    }


  }
  else {

    //acceptedClass = 'editingInsertBlotPending';

    if (preview && !editorPinned) {
      //act as if it was accepted since we have nothing else from the author.
      //acceptedClass = 'editingInsertFinalViewAccepted';
    } else {
      //acceptedClass = 'editingInsertBlotPending';
    }

  }

  return 'editingBlot editingMovedDestinationBlot ' + acceptedClass;

}


class EditingMovedDestinationBlot extends Inline {

  static create(args) {
    //////console.log(args);
    let node = super.create(args);



    //node.setAttribute('contenteditable', 'false');
    node.setAttribute('editorComment', args.editorComment);
    //node.setAttribute('uuid', args.uuid);

    //value = this.sanitize(value);
    //node.setAttribute('href', args.href);
    //node.setAttribute('id', args.href);
    //node.dataset.href = args.href;
    node.dataset.uuid = args.uuid;
    node.dataset.type = 'editingMovedDestinationBlot';
    node.dataset.oppositeUuid = args.oppositeUuid;
    node.dataset.accepted = args.accepted;
    node.dataset.rejected = args.rejected;
    node.dataset.editorPinned = args.editorPinned;
    node.dataset.requestorPinned = args.requestorPinned;
    node.dataset.editorComment = args.editorComment;
    node.dataset.sourcePartId = args.sourcePartId;
    node.dataset.destinationPartId = args.destinationPartId;


    /*let acceptedClass = '';
    if(node.dataset.accepted && node.dataset.accepted=='true'){
      acceptedClass = 'editingInsertBlotAccepted';
    }
    if(node.dataset.rejected && node.dataset.rejected=='true'){
      acceptedClass = 'editingInsertBlotRejected';
    }

    let classNames = 'editingBlot editingInsertBlot '+acceptedClass;*/

    node.setAttribute('class', generateClassNames({
      accepted: node.dataset.accepted,
      rejected: node.dataset.rejected,
      preview: false,
      editorPinned: node.dataset.editorPinned,
      requestorPinned: node.dataset.requestorPinned,
    }));





    let propsDiv = $("#EditingPopper");

    let thisPopper = null;
    // $(node).dblclick(function () {
    //   thisPopper = new Popper(node, propsDiv, {
    //     placement: 'bottom'
    //   });
    //   $('#EditingPopper').data('linkNode', node);
    //   ////console.log(node);
    //   //$('#EditingPopperHrefField').val(node.getAttribute('href'));


    //   let editors = $('ql-editor');
    //   let max = 0;
    //   editors.each(function () {
    //     var z = parseInt($(this).css("z-index"), 10);
    //     max = Math.max(max, z);
    //   })
    //   $('#EditingPopper').css("z-index", max + 1);
    //   $('.popperProps').hide();
    //   $('#EditingPopper').show();

    // });




    // $('#EditingPopper').mouseleave(function () {
    //   $('#EditingPopper').hide();
    //   if (thisPopper) {
    //     thisPopper.destroy();
    //   }

    // });







    //node.setAttribute('target', '_blank');
    return node;
  }

  static formats(domNode) {
    //return domNode.dataset.src
    //return domNode.getAttribute('href');

    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
      uuid: domNode.dataset.uuid,
      accepted: domNode.dataset.accepted,
      rejected: domNode.dataset.rejected,
      editorPinned: domNode.dataset.editorPinned,
      requestorPinned: domNode.dataset.requestorPinned,
      editorComment: domNode.dataset.editorComment,
      sourcePartId: domNode.dataset.sourcePartId,
      destinationPartId: domNode.dataset.destinationPartId,
      oppositeUuid: domNode.dataset.oppositeUuid
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  static value(domNode) {
    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
      uuid: domNode.dataset.uuid,
      accepted: domNode.dataset.accepted,
      rejected: domNode.dataset.rejected,
      editorPinned: domNode.dataset.editorPinned,
      requestorPinned: domNode.dataset.requestorPinned,
      editorComment: domNode.dataset.editorComment,
      sourcePartId: domNode.dataset.sourcePartId,
      destinationPartId: domNode.dataset.destinationPartId,
      oppositeUuid: domNode.dataset.oppositeUuid
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  /*static sanitize(url) {  
    return sanitize(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
  }*/

  format(name, args) {
    if (name !== this.statics.blotName || !args) return super.format(name, args);
    //value = this.constructor.sanitize(value);
    //this.domNode.setAttribute('href', args.href);
    this.domNode.setAttribute('uuid', args.uuid);
    this.domNode.setAttribute('accepted', args.accepted);
    this.domNode.setAttribute('rejected', args.rejected);
    this.domNode.setAttribute('editorPinned', args.editorPinned);
    this.domNode.setAttribute('requestorPinned', args.requestorPinned);
    this.domNode.setAttribute('editorComment', args.editorComment);
    this.domNode.setAttribute('sourcePartId', args.sourcePartId);
    this.domNode.setAttribute('destinationPartId', args.destinationPartId);
    this.domNode.setAttribute('oppositeUuid', args.oppositeUuid);
  }

  toggleAccept(preview) {
    if (!this.domNode.dataset.accepted || this.domNode.dataset.accepted == 'true' || this.domNode.dataset.accepted == 'undefined') {
      this.domNode.dataset.accepted = 'false';
      //this.domNode.setAttribute('style', 'color:green;border:1px solid green');


    } else {
      this.domNode.dataset.accepted = 'true';
      this.domNode.dataset.rejected = 'false';
      //this.domNode.setAttribute('style', '');
    }
    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();

  }

  toggleReject(preview) {
    if (!this.domNode.dataset.rejected || this.domNode.dataset.rejected == 'true' || this.domNode.dataset.rejected == 'undefined') {
      this.domNode.dataset.rejected = 'false';
      //this.domNode.setAttribute('style', 'color:green;border:1px solid green');


    } else {
      this.domNode.dataset.rejected = 'true';
      this.domNode.dataset.accepted = 'false';
      //this.domNode.setAttribute('style', '');
    }
    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();

  }

  accept(preview) {
    this.domNode.dataset.accepted = 'true';
    this.domNode.dataset.rejected = 'false';

    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();
  }

  reject(preview) {
    this.domNode.dataset.accepted = 'false';
    this.domNode.dataset.rejected = 'true';

    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();
  }

  clearChoices(preview) {
    this.domNode.dataset.rejected = 'false';
    this.domNode.dataset.accepted = 'false';

    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();
  }

  show() {

  }

  hide() {


  }

  previewOn() {
    //$(this.domNode).removeClass();
    //$(this.domNode).removeClass('editingInsertBlotAccepted');
    //$(this.domNode).removeClass('editingInsertBlotUnAccepted');
    //$(this.domNode).removeClass('editingInsertBlotPreview');
    //$(this.domNode).addClass('editingInsertBlotPreview');
    //$(this.domNode).addClass('editingInsertBlotUnAccepted');
  }

  previewOff() {
    ////console.log('previewOff()...');
    $(this.domNode).removeClass();
    /*$(this.domNode).removeClass('editingBlot');
    $(this.domNode).removeClass('editingInsertBlot');
    $(this.domNode).removeClass('editingInsertBlotPreview');
    $(this.domNode).removeClass('editingInsertBlotAccepted');
    $(this.domNode).removeClass('editingInsertBlotUnAccepted');*/
    //$(this.domNode).removeClass('editingInsertBlotPreview');
    $(this.domNode).addClass(generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: false,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    }))

  }


  finalView(preview, editorMode, previewContent) {



    if (preview) {



      if (this.domNode.dataset.rejected != null && this.domNode.dataset.rejected == 'true') {
        this.domNode.setAttribute('style', 'display:none');
      }
    } else {
      this.domNode.setAttribute('style', '');
    }

    // if (preview) {

    //   this.domNode.setAttribute('style', 'display:none');

    // }else{

    //   this.domNode.setAttribute('style', '');

    // }


  }

  finalViewOld(preview, editorMode) {

    $(this.domNode).removeClass();
    $(this.domNode).addClass(generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
      editorMode: editorMode

    }))


  }

  generateClassNamesxxx(preview) {

    let acceptedClass = '';
    if (this.domNode.dataset.accepted && this.domNode.dataset.accepted == 'true') {
      if (preview) {
        acceptedClass = 'editingInsertFinalViewAccepted';
      } else {
        acceptedClass = 'editingInsertBlotAccepted';
      }

    }
    else if (this.domNode.dataset.rejected && this.domNode.dataset.rejected == 'true') {


      if (preview) {
        acceptedClass = 'editingInsertFinalViewRejected';
      } else {
        acceptedClass = 'editingInsertBlotRejected';
      }


    }
    else {

      acceptedClass = 'editingInsertBlotPending';

    }

    return 'editingBlot editingInsertBlot ' + acceptedClass;

  }

  toggleEditorPinned() {
    ////console.log(this.domNode.dataset.editorPinned);
    if (this.domNode.dataset.editorPinned && this.domNode.dataset.editorPinned == 'true') {
      this.domNode.dataset.editorPinned = 'false'
    } else {
      this.domNode.dataset.editorPinned = 'true'
    }
    ////console.log(this.domNode.dataset.editorPinned);
  }

  toggleRequestorPinned() {
    ////console.log(this.domNode.dataset.requestorPinned);
    if (this.domNode.dataset.requestorPinned && this.domNode.dataset.requestorPinned == 'true') {
      this.domNode.dataset.requestorPinned = 'false'
    } else {
      this.domNode.dataset.requestorPinned = 'true'
    }
    ////console.log(this.domNode.dataset.requestorPinned);
  }


  /*deleteAt() {
    return false
  }*/
}
EditingMovedDestinationBlot.blotName = 'editingMovedDestinationBlot';
EditingMovedDestinationBlot.tagName = 'span';
EditingMovedDestinationBlot.className = 'editingMovedDestinationBlot';
//EditingDeleteBlot.SANITIZED_URL = 'about:blank';
//EditingDeleteBlot.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];





export { EditingMovedDestinationBlot as default };
