import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useGetOwnedBooks, useOpenBookInWrite, useOpenBookInFormat, useGetSnapshots, useManagerCreateBook } from '../managers/BooksManager';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import RoutesHelper from '../helpers/RoutesHelper';
import StandardImageViewer from '../components/StandardImageViewer';
import StandardDetailTitle from '../components/StandardDetailTitle';
import config from '../aws-exports';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ShareIcon from '@mui/icons-material/Share';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import SettingsIcon from '@mui/icons-material/Settings';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import CommentIcon from '@mui/icons-material/Comment';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Feather from 'mdi-material-ui/Feather';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import StandardRoundedAction from '../components/actions/StandardRoundedAction';
import StandardDetailDescription from '../components/StandardDetailDescription';
import OwnedBooksMobileFooterBar from '../components/footers/OwnedBooksMobileFooterBar';
import WriteFooterBar from '../components/editor/WriteFooterBar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import StandardContentWithFooterContainer from '../components/StandardContentWithFooterContainer';
import StandardEntityImageSelector from '../components/StandardEntityImageSelector';
import ReactTimeAgo from 'react-time-ago';
import ListItem from '@mui/material/ListItem';
import { StandardListButton } from '../components/StandardButton';
import RequestEditPanel from '../components/panels/editing/RequestEditPanel';
import CreateSnapshotDialog from '../components/panels/CreateSnapshotDialog';
import StandardOkCancelDialog from '../components/panels/StandardOkCancelDialog';
import ShareBookPanel from '../components/panels/share/ShareBookPanel';
import StandardDialog from '../components/StandardDialog';
import CreateSnapshotPanel from '../components/panels/CreateSnapshotPanel';
import { useUpdateItem } from '../persistance/persistanceHooks';
import StandardSelect from '../components/StandardSelect';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DashboardCard from '../components/dashboard/DashboardCard';
import AppWrapper from '../components/AppWrapper';

const Books = (props) => {

  let book = props.stores.bookStore.bookListBook;

  const { ownedBooks, error } = useGetOwnedBooks(props);
  const { openBookInWrite } = useOpenBookInWrite(props);
  const { openBookInFormat } = useOpenBookInFormat(props);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');

  let [showCreateBookDialog, setShowCreateBookDialog] = useState(false);
  let [showDeleteBookDialog, setShowDeleteBookDialog] = useState(false);
  let [selectedBook, setSelectedBook] = useState(null);
  let [showShareDialog, setShowShareDialog] = useState(false);
  let [showCreateSnapshotDialog, setShowCreateSnapshotDialog] = useState(false);
  let [showRequestEditDialog, setShowRequestEditDialog] = useState(false);

  const [updateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] = useUpdateItem();

  const [selection, setSelection] = React.useState('English');

  const handleChange = (event) => {
    setSelection(event.target.value);
  };


  const isXs = useMediaQuery({ maxWidth: 599 });
  const isSm = useMediaQuery({ maxWidth: 600 });
  const isMd = useMediaQuery({ maxWidth: 900 });
  const isLg = useMediaQuery({ maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1536 });


  const isRoute = () => {
    return 'primary';
  }


  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {

    //console.log(ownedBooks);
    setOrder("desc");
    setOrderBy("createdAt");

  }, [ownedBooks]);

  const selectRoute = (route) => {

    navigate(route);
    props.onRouteSelected();

  }


  return (
    <>
      <AppWrapper main={<Box sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
        //gap:2
      }}>
        
        <Box sx={{
          flex: 12
        }}>


          <StandardContentWithFooterContainer
            smallFooterContent={<OwnedBooksMobileFooterBar />}>
            <>
              <Grid container direction={'column'}>
                <Grid item >
                  <Grid container sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                    marginBottom: '1em'
                  }}>
                    <DashboardCard
                      title={'Book Cover Images'}
                      color={theme.palette.books?.main}
                      hideIcon={true}
                      //icon={<MenuBookIcon 
                      sx={{
                        color: theme.palette.cardIcon?.main,
                        width: '100%'
                      }}>

                      <Grid item sx={{
                        display: 'flex',
                        flex: 6,
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}>

                        <StandardEntityImageSelector
                          //width={'20em'}
                          title={'Full page Image'}
                          disabled={false}
                          entity={book}
                          imageKeyFieldName={'coverUrl'}
                          onChange={() => {

                          }} />
                        <Typography variant={'h9'}>Cover</Typography>
                      </Grid>
                      <Grid item sx={{
                        display: 'flex',
                        flex: 6,
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}>
                        <StandardEntityImageSelector
                          //width={'20em'}
                          title={'Full page Image'}
                          disabled={false}
                          entity={book}
                          imageKeyFieldName={'coverThumbnailUrl'}
                          onChange={() => {

                          }} />
                        <Typography variant={'h9'}>Thumbnail</Typography>
                      </Grid>
                    </DashboardCard>


                  </Grid>
                </Grid>





              </Grid>



              {RoutesHelper.isRoute('/books/details', location.pathname) &&
                <>
                  <DashboardCard
                    title={'Book Details'}
                    color={theme.palette.books?.main}
                    hideIcon={true}
                    //icon={<MenuBookIcon 
                    sx={{
                      color: theme.palette.cardIcon?.main,
                      width: '100%'
                    }}>

                    <Grid container direction={'column'}>
                      <Grid item>
                        <TextField
                          sx={{ marginBottom: '0.5em' }}
                          key={'textField' + book?.id + 'title'}
                          item={book}
                          fieldName={book.title}
                          label={'Title'}
                          variant="standard"
                          size="small"
                          defaultValue={book.title}
                          fullWidth={true}
                          onChange={(e) => {

                            let newValue = e.target.value;
                            //handleChange(newValue);
                          }} />

                      </Grid>
                      <Grid item>
                        <TextField
                          sx={{ marginBottom: '0.5em' }}
                          key={'textField' + book?.id + 'subTitle'}
                          item={book}
                          fieldName={book.subTitle}
                          label={'Subtitle'}
                          variant="standard"
                          size="small"
                          defaultValue={book.subTitle}
                          fullWidth={true}
                          onChange={(e) => {

                            let newValue = e.target.value;
                            //handleChange(newValue);
                          }} />

                      </Grid>
                      <Grid item>
                        <TextField
                          sx={{ marginBottom: '0.5em' }}
                          key={'textField' + book?.id + 'authorName'}
                          item={book}
                          fieldName={book.authorName}
                          label={'Author'}
                          variant="standard"
                          size="small"
                          defaultValue={book.authorName}
                          fullWidth={true}
                          onChange={(e) => {

                            let newValue = e.target.value;
                            //handleChange(newValue);
                          }} />

                      </Grid>
                      <Grid item>
                        <TextField
                          sx={{ marginBottom: '0.5em' }}
                          key={'textField' + book?.id + 'authorName'}
                          item={book}
                          fieldName={book.authorName}
                          label={'eBook ISBN'}
                          variant="standard"
                          size="small"
                          defaultValue={book.authorName}
                          fullWidth={true}
                          onChange={(e) => {

                            let newValue = e.target.value;
                            //handleChange(newValue);
                          }} />

                      </Grid>
                      <Grid item>
                        <TextField
                          sx={{ marginBottom: '0.5em' }}
                          key={'textField' + book?.id + 'authorName'}
                          item={book}
                          fieldName={book.authorName}
                          label={'Print ISBN'}
                          variant="standard"
                          size="small"
                          defaultValue={book.authorName}
                          fullWidth={true}
                          onChange={(e) => {

                            let newValue = e.target.value;
                            //handleChange(newValue);
                          }} />

                      </Grid>
                      <Grid item sx={{ marginTop: '1em' }}>
                        <StandardSelect
                          inputLabel={'Language'}
                          content={<Select
                            value={selection}
                            onChange={handleChange}
                            label={props.selectLabel}
                          >
                            <MenuItem value={'Catalan'}>Catalan</MenuItem>
                            <MenuItem value={'Chinese (Simplified)'}>Chinese (Simplified)</MenuItem>
                            <MenuItem value={'Chinese (Traditional)'}>Chinese (Traditional)</MenuItem>
                            <MenuItem value={'Czech'}>Czech</MenuItem>
                            <MenuItem value={'Danish'}>Danish</MenuItem>
                            <MenuItem value={'Dutch'}>Dutch</MenuItem>
                            <MenuItem value={'English'}>English</MenuItem>
                            <MenuItem value={'Finnish'}>Finnish</MenuItem>
                            <MenuItem value={'French'}>French</MenuItem>
                            <MenuItem value={'German'}>German</MenuItem>
                            <MenuItem value={'Hindi'}>Hindi</MenuItem>
                            <MenuItem value={'Hungarian'}>Hungarian</MenuItem>
                            <MenuItem value={'Icelandic'}>Icelandic</MenuItem>
                            <MenuItem value={'Italian'}>Italian</MenuItem>
                            <MenuItem value={'Japanese'}>Japanese</MenuItem>
                            <MenuItem value={'Korean'}>Korean</MenuItem>
                            <MenuItem value={'Norwegian Bokmal'}>Norwegian Bokmal</MenuItem>
                            <MenuItem value={'Polish'}>Polish</MenuItem>
                            <MenuItem value={'Portuguese'}>Portuguese</MenuItem>
                            <MenuItem value={'Romanian'}>Romanian</MenuItem>
                            <MenuItem value={'Russian'}>Russian</MenuItem>
                            <MenuItem value={'Spanish'}>Spanish</MenuItem>
                            <MenuItem value={'Swedish'}>Swedish</MenuItem>
                            <MenuItem value={'Turkish'}>Turkish</MenuItem>
                            <MenuItem value={'Vietnamese'}>Vietnamese</MenuItem>
                          </Select>}

                        />

                      </Grid>

                      <Grid item sx={{ marginTop: '1em' }}>
                        <Typography>
                          Created: <ReactTimeAgo date={book?.createdAt} locale="en-US" />
                        </Typography>
                      </Grid>


                      {isXs && RoutesHelper.isRoute('/books/details', location.pathname) &&
                        <ListItem disablePadding>
                          <StandardListButton
                            sx={{
                              //backgroundColor:theme.palette.write.controls.backgroundColor,
                              color: theme.palette.write.controls.color,
                              borderColor: theme.palette.write.controls.color
                            }}
                            disableElevation={true}
                            variant="outlined"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              updateItem({
                                itemExpectedVersion: 1,
                                itemKey: book.id,
                                //itemSortKeyName: 'documentPartId',
                                //itemSortKeyValue: props.userComment.documentPartId,
                                itemType: 'Book',
                                fieldName: 'deletedAt',
                                fieldValue: 'now',
                                refetchQueries: ['listBooks'],
                                onCompleted: () => {

                                  //props.userComment.isPinned = !props.userComment.isPinned
                                  props.stores.bookStore.bookListBook = null;
                                }
                              })

                              props.stores.bookStore.bookListBook = null;
                              selectRoute("/books/list");

                            }}>Delete</StandardListButton>
                        </ListItem>
                      }
                    </Grid>
                  </DashboardCard>



                  <DashboardCard
                    title={'Store Details'}
                    color={theme.palette.books?.main}
                    hideIcon={true}
                    //icon={<MenuBookIcon 
                    sx={{
                      color: theme.palette.cardIcon?.main,
                      width: '100%'
                    }}>

                    <Grid container direction={'column'}>

                      <Grid item>
                        <TextField
                          sx={{ marginBottom: '0.5em' }}
                          key={'textField' + book?.id + 'authorName'}
                          item={book}
                          fieldName={book.authorName}
                          label={'Kindle Store Link'}
                          variant="standard"
                          size="small"
                          defaultValue={book.authorName}
                          fullWidth={true}
                          onChange={(e) => {

                            let newValue = e.target.value;
                            //handleChange(newValue);
                          }} />

                      </Grid>
                      <Grid item>
                        <TextField
                          sx={{ marginBottom: '0.5em' }}
                          key={'textField' + book?.id + 'authorName'}
                          item={book}
                          fieldName={book.authorName}
                          label={'Apple Store Link'}
                          variant="standard"
                          size="small"
                          defaultValue={book.authorName}
                          fullWidth={true}
                          onChange={(e) => {

                            let newValue = e.target.value;
                            //handleChange(newValue);
                          }} />

                      </Grid>
                      <Grid item>
                        <TextField
                          sx={{ marginBottom: '0.5em' }}
                          key={'textField' + book?.id + 'authorName'}
                          item={book}
                          fieldName={book.authorName}
                          label={'Google Store Link'}
                          variant="standard"
                          size="small"
                          defaultValue={book.authorName}
                          fullWidth={true}
                          onChange={(e) => {

                            let newValue = e.target.value;
                            //handleChange(newValue);
                          }} />

                      </Grid>
                      <Grid item>
                        <TextField
                          sx={{ marginBottom: '0.5em' }}
                          key={'textField' + book?.id + 'authorName'}
                          item={book}
                          fieldName={book.authorName}
                          label={'Kobo Store Link'}
                          variant="standard"
                          size="small"
                          defaultValue={book.authorName}
                          fullWidth={true}
                          onChange={(e) => {

                            let newValue = e.target.value;
                            //handleChange(newValue);
                          }} />

                      </Grid>
                      <Grid item>
                        <TextField
                          sx={{ marginBottom: '0.5em' }}
                          key={'textField' + book?.id + 'authorName'}
                          item={book}
                          fieldName={book.authorName}
                          label={'Nook Store Link'}
                          variant="standard"
                          size="small"
                          defaultValue={book.authorName}
                          fullWidth={true}
                          onChange={(e) => {

                            let newValue = e.target.value;
                            //handleChange(newValue);
                          }} />

                      </Grid>
                      <Grid item>
                        <TextField
                          sx={{ marginBottom: '0.5em' }}
                          key={'textField' + book?.id + 'authorName'}
                          item={book}
                          fieldName={book.authorName}
                          label={'BookBub Store Link'}
                          variant="standard"
                          size="small"
                          defaultValue={book.authorName}
                          fullWidth={true}
                          onChange={(e) => {

                            let newValue = e.target.value;
                            //handleChange(newValue);
                          }} />

                      </Grid>
                      <Grid item>
                        <TextField
                          sx={{ marginBottom: '0.5em' }}
                          key={'textField' + book?.id + 'authorName'}
                          item={book}
                          fieldName={book.authorName}
                          label={'Custom Store Link'}
                          variant="standard"
                          size="small"
                          defaultValue={book.authorName}
                          fullWidth={true}
                          onChange={(e) => {

                            let newValue = e.target.value;
                            //handleChange(newValue);
                          }} />

                      </Grid>



                      {/* {isXs && RoutesHelper.isRoute('/books/details', location.pathname) &&
                  <ListItem disablePadding>
                    <StandardListButton
                      sx={{
                        //backgroundColor:theme.palette.write.controls.backgroundColor,
                        color: theme.palette.write.controls.color,
                        borderColor: theme.palette.write.controls.color
                      }}
                      disableElevation={true}
                      variant="outlined"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        updateItem({
                          itemExpectedVersion: 1,
                          itemKey: book.id,
                          //itemSortKeyName: 'documentPartId',
                          //itemSortKeyValue: props.userComment.documentPartId,
                          itemType: 'Book',
                          fieldName: 'deletedAt',
                          fieldValue: 'now',
                          refetchQueries: ['listBooks'],
                          onCompleted: () => {

                            //props.userComment.isPinned = !props.userComment.isPinned
                            props.stores.bookStore.bookListBook = null;
                          }
                        })

                        props.stores.bookStore.bookListBook = null;
                        selectRoute("/books/list");

                      }}>Delete</StandardListButton>
                  </ListItem>
                } */}
                    </Grid>
                  </DashboardCard>
                </>
              }



              {!RoutesHelper.isRoute('/books/details', location.pathname) &&
                <Box sx={{
                  marginTop: '1em'
                }}>

                  <Outlet />
                </Box>

              }


              <StandardOkCancelDialog
                open={showShareDialog}
                onClose={() => {
                  setShowShareDialog(false)
                }}
                onOk={() => {
                  setShowShareDialog(false)

                }}
                title={'Share Book'}
                content={
                  <ShareBookPanel book={book} />
                }
              />


              <StandardOkCancelDialog
                open={showRequestEditDialog}
                onClose={() => {
                  setShowRequestEditDialog(false)
                }}
                onOk={() => {
                  setShowRequestEditDialog(false)

                }}
                title={'Request Edit'}
                content={
                  <RequestEditPanel book={book} />
                }
              />


              <StandardDialog
                open={showCreateSnapshotDialog}
                onClose={() => {
                  setShowCreateSnapshotDialog(false)
                }}
                onOk={() => {


                }}
                title={'Create Snapshot'}
                dialogContent={
                  <CreateSnapshotPanel book={book} onClose={() => {
                    setShowCreateSnapshotDialog(false)
                  }} />
                }
              >
                <CreateSnapshotPanel book={book} />
              </StandardDialog>
            </>
          </StandardContentWithFooterContainer>

        </Box>


      </Box>

      } />
    </>


  )
}

export default inject('stores')(observer(Books));