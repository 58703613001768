import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { v4 as uuid }  from 'uuid';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';


const ToolbarItem = (props) => {

    const theme = useTheme();

    const toolbarStyle = {
        width: '.75em',
        //height: '1em',
        margin: '5px',
        display: 'flex',
        justifyContent: 'center'
    }

    

    const handleToggle = (e) => {
        e.preventDefault();
    }

    return (
        <Box style={toolbarStyle}  sx={{color:theme.palette.smallIcons.color.main}} onClick={handleToggle}>
            {props.icon}
        </Box>
                
    );
};


export default inject('stores')(observer(ToolbarItem));
