//import { config as c2 }from "../config";
//let config = require('../config.js');
//'use strict';

/* Steps to modify this file before copying serverside
1. change:
	isProd = false to
	isProd = true;

2. change:
	export class DeltaToHtmlEPub to
	class DeltaToHtmlEPub

3. change:
	//exports.DeltaToHtmlEPub = DeltaToHtmlEPub; to
	exports.DeltaToHtmlEPub = DeltaToHtmlEPub;
*/
let isProd = false;
let attributesToTagMapOld = {
	'bold':'b',
	'strike':'s',
	'underline':'u',
	'italic':'i',
	'sub':'sub',
	'super':'sup',
	'ordered':'li'
}

let attributesToTagMap = {
	'bold':{
		prefix:'<b>',
		suffix:'</b>'
	},
	'strike':{
		prefix:'<span class="strike">',
		suffix:'</span>'
	},
	'underline':{
		prefix:'<span class="underline">',
		suffix:'</span>'
	},
	'italic':{
		prefix:'<i>',
		suffix:'</i>'
	},
	'sub':{
		prefix:'<sub>',
		suffix:'</sub>'
	},
	'super':{
		prefix:'<sup>',
		suffix:'</sup>'
	},
	'ordered':{
		prefix:'<li>',
		suffix:'</li>'
	},
}


export class DeltaToHtmlEPub {

	convert(delta,documentPart){
		//console.log(delta);
		////console.log(documentPart);

		let blocks = this.extractBlocks(delta,documentPart);

		let epubType = '';

		try{

			epubType = documentPart.bookDraftDocumentPart.bookDraft.book.epubType;

		}catch(err){
			//console.log(err);
		}
		//console.log(blocks);
		let html = '';

		blocks.forEach((block)=>{

			//html += '<p>'+block.embed+'</p>';
			let paragraphClasses = [];
			if(!block.embed){

			html += block.prefix;
			let listItemType = null;
			let listItemStarted = false;
			block.ops.forEach((op, index)=>{

				let prefix = '';
				let suffix = '';

				let isAList = this.containsListAttributeAhead(block.ops,index);
				if(isAList && !listItemStarted){
					html+='<li>';
					listItemStarted = true;
				}
				if(op.attributes && typeof op.insert === 'string'){

				Object.keys(op.attributes).forEach((attribute)=>{



						//////console.log(attribute);
						if(attribute=='script'){
							//////console.log('scriptType:'+m.attributes[attribute]);
							//tag = attributesToTagMap[m.attributes[attribute]];
							let scriptType = op.attributes[attribute];
							if(scriptType && scriptType=='super'){
								//prefix+='<sup style="font-size:xx-small; vertical-align:super;">';
								prefix+='<sup>';
								suffix = '</sup>' + suffix;
							}
							if(scriptType && scriptType=='sub'){
								prefix+='<sub>';
								suffix = '</sub>' + suffix;
							}

						}
						else if(attribute=='link2'){
							//epubType = 'nook'
							//////console.log('scriptType:'+m.attributes[attribute]);
							//tag = attributesToTagMap[m.attributes[attribute]];
							let args = op.attributes[attribute];

							let linkData = JSON.parse(args.linkData);
							let href = '';
							if(args.linkType=='store'){
								switch(epubType){

									case 'kindle':
										//href = 'https://www.amazon.com/dp/B0BC2L41GH'; Chris Fox book
										href = linkData.stores.kindle.url;
									break;

									case 'nook':
										//href = 'https://www.barnesandnoble.com/w/no-such-thing-as-werewolves-chris-fox/1121661617?ean=9781502918277'; //Chris Fox book
										href = linkData.stores.nook.url;
									break;

									case 'kobo':
										//href = 'https://www.amazon.com/dp/B0BC2L41GH'; Chris Fox book
										href = linkData.stores.kobo.url;
									break;

									case 'googlePlay':
										//href = 'https://www.amazon.com/dp/B0BC2L41GH'; Chris Fox book
										href = linkData.stores.googlePlay.url;
									break;

									case 'iBooks':
										//href = 'https://www.amazon.com/dp/B0BC2L41GH'; Chris Fox book
										href = linkData.stores.iBooks.url;
									break;

									case 'epub':
										//href = 'https://www.amazon.com/dp/B0BC2L41GH'; Chris Fox book
										href = linkData.stores.epub.url;
									break;
									
									case 'print':
										//href = 'https://www.amazon.com/dp/B0BC2L41GH'; Chris Fox book
										href = linkData.stores.print.url;
									break;
								}
							}
							//let link = 
							if(args){
								//prefix+='<span>'+href+':'+epubType+'</span>'+'<a href="'+href+'">';
								prefix+='<a href="'+href+'">';
								suffix = '</a>' + suffix;
							}


						}

						/*if(attribute=='align'){
							//////console.log('scriptType:'+m.attributes[attribute]);
							//tag = attributesToTagMap[m.attributes[attribute]];
							let alignType = op.attributes[attribute];
							if(alignType && alignType=='center'){
								prefix+='<div class="centered" ><span class="centered" >';
								suffix = '</span></div>' + suffix;
							}
							if(alignType && alignType=='right'){
								prefix+='<span class="right">';
								suffix = '</span>' + suffix;
							}
							if(alignType && alignType=='justify'){
								prefix+='<span class="justify">';
								suffix = '</span>' + suffix;
							}

						}*/
						else if(attribute=='list'){
							//suffix = suffix +'</li>';
						}
						else if(attribute=='bgcolor'){
							//suffix = suffix +'</li>';
							if(!op.styles){
								op.styles = new Array();
							}

							op.styles.push('background-color:'+op.attributes[attribute]);
						}
						else if(attribute=='fontColor'){
							//suffix = suffix +'</li>';
							if(!op.styles){
								op.styles = new Array();
							}

							op.styles.push('color:'+op.attributes[attribute]);
						}
						else if(attribute=='fontFamily'){
							//suffix = suffix +'</li>';
							if(!op.styles){
								op.styles = new Array();
							}

							//op.styles.push('font-family:'+op.attributes[attribute]);
							if(op.attributes[attribute]=='monospace'){
								prefix+='<span class="monospace">';
								suffix = '</span>' + suffix;
							}
							if(op.attributes[attribute]=='sans-serif'){
								prefix+='<span class="sansserif">';
								suffix = '</span>' + suffix;
							}
						}
						else if(attribute=='newline'){
							//suffix = suffix +'</li>';
							if(!op.styles){
								op.styles = new Array();
							}

							prefix+='<br/>';
							suffix = '' + suffix;
						}
						else if(attribute=='fontSize'){
							//suffix = suffix +'</li>';
							if(!op.styles){
								op.styles = new Array();
							}

							op.styles.push('font-size:'+op.attributes[attribute]);
						}
						else if(attribute=='fontVariant'){
							//suffix = suffix +'</li>';
							if(!op.styles){
								op.styles = new Array();
							}


							if(op.attributes[attribute]=='small-caps'){
								prefix+='<span class="smallcaps">';
								suffix = '</span>' + suffix;
							}

							//op.styles.push('font-variant:'+op.attributes[attribute]);
						}
						else{
							let tag = attributesToTagMap[attribute];
							if(tag){
								prefix+=tag.prefix;
								suffix = tag.suffix + suffix;
							}
						}
						/*else{
							let tag = attributesToTagMap[attribute];
							if(tag){
								prefix+='<'+tag+'>';
								suffix = '</'+tag+'>' + suffix;
							}
						}*/






					});


				}
				else if(op.insert && op.insert.footnote){
					/*
					let book;
					try{
						book = documentPart.bookDraftDocumentPart.bookDraft.book;
					}catch(err){
						////console.log(err);
					}
					let footnoteNumber = 101;
					footnoteNumber = book.calculateFootnoteNumber(documentPart,op.insert.footnote.id)
					*/
					let footnoteNumber = op.insert.footnote.id;
					prefix += '{{global_footnote(\''+footnoteNumber+'\' , sceneDocumentPart, buildContext  , \' ';
					suffix = '\')}}' + suffix;


					//prefix+='<span>101';
					//suffix = '</span>' + suffix;
				}

				if(op.styles && op.styles.length>0){
					let styleProperties = '';
					op.styles.forEach((style)=>{
						styleProperties += style +';'
					});
					html+=prefix+'<span style="'+styleProperties+'"> '+op.insert+'</span>'+suffix;
				}else{
					if(op.insert && op.insert!='undefined' && op.insert!=null){
						html+=prefix+op.insert+suffix;
					}
					
				}


				if( listItemStarted){
					//finish this list item if the list attribute is present
					if(op.attributes && op.attributes['list']){
						html+='</li>';
						listItemStarted = false;
					}

				}
			});


			html+=block.suffix;

		}else{
			switch(block.type){

				case 'alsobys':
					block.ops.forEach((op)=>{
						if(op.insert && op.insert.alsobys){
							html+=this.convertAlsobys(op.insert.alsobys);
						}


					});

				case 'contributors':
					block.ops.forEach((op)=>{
						if(op.insert && op.insert.contributors){
							html+=this.convertContributors(op.insert.contributors);
						}


					});

				case 'publisher':
					block.ops.forEach((op)=>{
						if(op.insert && op.insert.publisher){
							html+=this.convertPublisher(op.insert.publisher);
						}


					});

				case 'blurbs':
					block.ops.forEach((op)=>{
						if(op.insert && op.insert.blurbs){
							html+=this.convertBlurb(op.insert.blurbs);
						}


					});

				case 'image':
					////console.log(block.ops);
					block.ops.forEach((op)=>{
						if(op.insert && op.insert.image4){
							////console.log(op.insert.image4);
							html+=this.convertImage(op.insert.image4,documentPart);
						}


					});

				case 'decoratedBreak':
					////console.log(block.ops);
					block.ops.forEach((op)=>{
						if(op.insert && op.insert.decoratedBreak){
							////console.log(op.insert.decoratedBreak);
							html+=this.convertDecoratedBreak(op.insert.decoratedBreak);
						}


					});

				case 'tocs':
					////console.log(block.ops);
					block.ops.forEach((op)=>{
						if(op.insert && op.insert.tocs){
							////console.log(op.insert.tocs);
							//html+='<div>HERE</div>';
							html+=this.convertTOC(op.insert.tocs);
						}


					});
					//html+='This is an image'

				break;

				case 'notes':
					////console.log(block.ops);
					block.ops.forEach((op)=>{
						if(op.insert && op.insert.notes){
							////console.log(op.insert.notes);
							//html+='<div>HERE</div>';
							html+=this.convertNotes(op.insert.notes);
						}
					});
					//html+='This is an image'

				break;

				/*case 'footnote':
					////console.log(block.ops);
					block.ops.forEach((op)=>{
						if(op.insert && op.insert.footnote){
							////console.log(op.insert.footnote);
							//html+='<div>HERE</div>';
							//html+=this.convertNotes(op.insert.notes);
							html+='<span>1</span>';
						}


					});
					//html+='This is an image'

				break;*/

				default:

				break;
			}

		}


		});

		let book = documentPart.getBook();
		let year = new Date().getFullYear();
		html = html.split('---YEAR---').join(year);
		return html.split('---AUTHORNAME---').join(book.authorName); 
		//return html;
	}



	convertDecoratedBreak(args){
		//console.log(args);
		let html = '{{ global_decoratedBreak(global_ornamentalBreakImageId) }}';
		return html;
	}
	convertImage(args,documentPart){

		let imageSrc = args.src;
		try{
			let bookDraft = documentPart.bookDraftDocumentPart.bookDraft;
			imageSrc = bookDraft.mappedImages[args.src];
		}catch(err){
			////console.log(err);
		}


		if(!isProd){
			//let html = '{{ global_image("'+config.default.images.RESIZER_GATEWAY+'/'+args.src+'" , "'+args.caption+'" ,"'+args.alt+'" ,"'+args.alignment+'" ,"'+args.size+'","'+args.includeCaption+'") }}';
			let html = '{{ global_image("'+imageSrc+'" , "'+args.caption+'" ,"'+args.alt+'" ,"'+args.alignment+'" ,"'+args.size+'","'+args.includeCaption+'") }}';
			return html;
		}else{
			let html = '{{ global_image("'+imageSrc+'" , "'+args.caption+'" ,"'+args.alt+'" ,"'+args.alignment+'" ,"'+args.size+'","'+args.includeCaption+'") }}';
			return html;
		}

	}



	convertBlurb(args){
		//console.log(args);
		let html = '{{ global_blurbs(\''+args.blurbs+'\'  ) }}';
		//let html = '<div>also bys</div>';
		return html;
	}

	convertBlurbOld(args){
		let documentPart = args.documentPart;
		let book;
		try{
			book = args.documentPart.bookDraftDocumentPart.bookDraft.book;
		}catch(err){
			////console.log(err);
		}

		////console.log(documentPart);


		/*let html = '<div style="background-color:lightgrey; margin:\'10px 10px 10px 10px\'" >';
			html+='Here it is.'+documentPart.title;
			html+='</div>';
		return html;*/

		let html = '{{ content(documentPart.content) }}'

		return html;
	}

	convertPublisher(args){
		let html = '{{ global_publisher(\''+args.publisher+'\' , \''+args.includeParts+'\' ,\''+args.includeChapters+'\' ,\''+args.includeFrontMatter+'\' ,\''+args.includeBackMatter+'\') }}';
		return html;
	}

	convertAlsobys(args){
		//console.log(args);
		let html = '{{ global_alsobys(\''+args.alsobys+'\' , allBooks, book ) }}';
		//let html = '<div>also bys</div>';
		return html;
	}

	convertContributors(args){
		let html = '{{ global_contributors(\''+args.contributors+'\' , \''+args.includeParts+'\' ,\''+args.includeChapters+'\' ,\''+args.includeFrontMatter+'\' ,\''+args.includeBackMatter+'\') }}';
		return html;
	}

	convertTOC(args){
		//console.log('convertTOC()...');
		let html = '{{ global_toc("'+args.includeVolumes+'" , "'+args.includeParts+'" ,"'+args.includeChapters+'" ,"'+args.includeFrontMatter+'" ,"'+args.includeBackMatter+'") }}';
		return html;
	}

	convertNotes(args){
		let html = '{{ global_notes("'+args.includeVolumes+'" , "'+args.includeParts+'" ,"'+args.includeChapters+'" ,"'+args.includeFrontMatter+'" ,"'+args.includeBackMatter+', buildContext") }}';
		return html;
	}



	convertTOCOld(args){
		let documentPart = args.documentPart;
		let book;
		try{
			book = args.documentPart.bookDraftDocumentPart.bookDraft.book;
		}catch(err){
			////console.log(err);
		}

		////console.log(documentPart);


		/*let html = '<div style="background-color:lightgrey; margin:\'10px 10px 10px 10px\'" >';
			html+='Here it is.'+documentPart.title;
			html+='</div>';
		return html;*/

		let html = '{{ content(documentPart.content) }}'

		return html;
	}

	determineBlockType(m,blockHtml){
		/*let blockType = {
			start:'<p>',
			end:'</p>'
		}*/
		if(blockHtml.trim().length==0){
			return '';
		}

		let html = '<p>'+blockHtml+'</p>';

		 if(m.attributes){
				Object.keys(m.attributes).forEach((attribute)=>{
					if(attribute=='blockquote'){
						////console.log('before...')
						////console.log(blockHtml)
						blockHtml = blockHtml.replace("'","\'");
						////console.log(blockHtml);
						html='{{global_blockQuote(\''+blockHtml+'\')}}'


					}
				})
		}

		return html;
	}

	extractBlocks(delta,documentPart){

		let blocks = [];
		if(!delta){
			return blocks;
		}

		let currentBlock = null;
		delta.ops.forEach((op)=>{


			if(!currentBlock){
				currentBlock = this.createBlock();
				blocks.push(currentBlock);
			}

			if (typeof op.insert === 'string') {

				let count = 0;
				let substring = '';
				while(count<op.insert.length){

					if(op.insert.charCodeAt(count)=='10'){
						//////console.log(substring);
						//////console.log('found a return');
						//if(substring!='' && substring.charCodeAt(0)!='10'){
							currentBlock.ops.push({
								insert:substring,
								attributes:op.attributes
							})

						//}

						currentBlock = this.createBlock();
						blocks.push(currentBlock);

						substring='';
					}else{
						substring+=op.insert.charAt(count);
					}

					count++;

				}
				//////console.log('naked insert'+substring);
				if(substring.length>0){
					//let newBlock = this.createBlock();
					currentBlock.ops.push({
							insert:substring,
							attributes:op.attributes,

						})
					//blocks.push(newBlock);
				}

				//////console.log(blocks);
				/*let hasReturn = op.insert.match(/\r|\n/g);
				let match = op.insert.split(/\r|\n/g);
				////console.log(match);
				if (match) {
				    // Found one, look at `match` for details, in particular `match.index`
				}*/
			}
			else if (op.insert && op.insert.footnote) {
				currentBlock.ops.push(op);
			}
			else if (!op.insert) {
				currentBlock.ops.push(op);
			}
			else if(!op.delete){
				let newBlock = this.createBlock();
				newBlock.ops.push(op);
				blocks.push(newBlock);
				currentBlock = null;
			}


		});

		let cleanedBlocks = [];
		blocks.forEach((block)=>{
			if(block.ops.length>0){
				cleanedBlocks.push(block);
			}
		});

		//now wrap images with alignment blocks if present

		blocks = cleanedBlocks;
		//now set the type of each block
		blocks.forEach((block)=>{
			let type = 'p'; //the default type unless a different type is detected.
			//let embed = false;
			//let prefix = '<p><span XXXSTYLEXXX>';
			//let suffix = '</span></p>';

			let prefix = '<p class="standard XXXCLASSNAMEXXX XXXSTYLEXXX">';
			let suffix = '</p>';

			//let formattingPrefix = '<span XXXCLASSNAMEXXX XXXSTYLEXXX>';
			//let formattingSuffix = '</span>';

			let formattingPrefix = '';
			let formattingSuffix = '';


			let style = '';
			let classNames = {};
			block.ops.forEach((op)=>{

				/*if(op.insert.footnote){


					let book;
						try{
							book = documentPart.bookDraftDocumentPart.bookDraft.book;
						}catch(err){
							////console.log(err);
						}
					let footnoteNumber = 101;
					footnoteNumber = book.calculateFootnoteNumber(documentPart,op.insert.footnote.id)
					type = 'footnote';
					prefix = '{{global_footnote(\''+footnoteNumber+'\'  , documentPart , \' ';
					suffix = '\')}}';
				}*/
				if(op.attributes){
					Object.keys(op.attributes).forEach((attribute)=>{
						/*if(attribute=='bgcolor'){
							//type = 'bgcolor';
							//prefix = '{{global_blockQuote(\'';
							//suffix = '\')}}';
							style+='background-color:'+op.attributes['bgcolor']+';';

						}*/
						if(attribute=='fontVariant'){
							//type = 'bgcolor';
							//prefix = '{{global_blockQuote(\'';
							//suffix = '\')}}';
							//style+='font-variant:'+op.attributes['fontVariant']+';';
							//classNames.smallcaps =' smallcaps ' ;
						}
						if(attribute=='fontSize'){
							//type = 'bgcolor';
							//prefix = '{{global_blockQuote(\'';
							//suffix = '\')}}';
							//style+='font-size:'+op.attributes['fontSize']+';';

						}
						if(attribute=='blockquote' && op.attributes.blockquote==true){
							type = 'blockquote';
							prefix = '{{global_blockQuote(\'';
							suffix = '\' ,\'global_blockQuoteWrapperAlignment\' )}}';

						}
						if(attribute=='subhead' && op.attributes.subhead==true){
							type = 'subhead';
							prefix = '{{global_subhead(\'';
							suffix = '\')}}';

						}
						if(attribute=='decoratedBreakxxxxxx'){
							type = 'decoratedBreak';
							prefix = '{{global_decoratedBreak(\'';
							suffix = '\' ,\'global_ornamentalBreakImageId\' )}}';

						}
						if(attribute=='verse' && op.attributes.verse==true){
							type = 'verse';
							prefix = '{{global_verse(\'';
							suffix = '\')}}';

						}
						if(attribute=='attribution' && op.attributes.attribution==true){
							type = 'attribution';
							prefix = '{{global_attribution(\'';
							suffix = '\')}}';

						}

						if(attribute=='list'){
							type = 'list';
							//now check to see if there are any align attributes for the current op
							let listAlignmentClass = '';
							try{

								if(op.attributes && op.attributes.align && op.attributes.align=='left'){
									listAlignmentClass = 'left';
								}
								if(op.attributes && op.attributes.align && op.attributes.align=='center'){
									listAlignmentClass = 'centered';
								}
								if(op.attributes && op.attributes.align && op.attributes.align=='right'){
									listAlignmentClass = 'right';
								}
								if(op.attributes && op.attributes.align && op.attributes.align=='justify'){
									listAlignmentClass = 'justify';
								}

							}catch(err){

							}

							if(op.attributes && op.attributes.list=='bullet'){
								prefix = '<ul class="'+listAlignmentClass+'">';
								suffix = '</ul>';
							}
							if(op.attributes && op.attributes.list=='ordered'){
								prefix = '<ol class="'+listAlignmentClass+'">';
								suffix = '</ol>';
							}


						}
						if(attribute=='align' && op.attributes[attribute]=='left'){
							type = 'align';
							prefix = '<p class="standard"><span class="left" XXXSTYLEXXX>';
							suffix = '</span></p>';

						}
						if(attribute=='align' && op.attributes[attribute]=='center'){
							type = 'align';
							//prefix = '<p class="standard centered"><span class="centered" XXXSTYLEXXX>';
							//suffix = '</span></p>';
							prefix = '<p class="standard center  XXXSTYLEXXX">';
							suffix = '</p>';

						}
						if(attribute=='align' && op.attributes[attribute]=='right'){
							type = 'align';
							//prefix = '<p class="standard right"><span class="right" XXXSTYLEXXX>';
							//suffix = '</span></p>';
							prefix = '<p class="standard right  XXXSTYLEXXX">';
							suffix = '</p>';

						}
						if(attribute=='align' && op.attributes[attribute]=='justify'){
							type = 'align';
							//prefix = '<p class="standard justify"><span class="justify" XXXSTYLEXXX>';
							//suffix = '</span></p>';
							prefix = '<p class="standard justify  XXXSTYLEXXX">';
							suffix = '</p>';

						}
						/*if(attribute=='image4'){
							type = 'image4';
							prefix = '{{global_image(\'';
							suffix = '\')}}';

						}*/
					});
				}

				if(op.insert && op.insert.alsobys){
					type='alsobys';
					block.embed = true;
				}
				if(op.insert && op.insert.contributors){
					type='contributors';
					block.embed = true;
				}
				if(op.insert && op.insert.publisher){
					type='publisher';
					block.embed = true;
				}
				if(op.insert && op.insert.blurbs){
					type='blurbs';
					block.embed = true;
				}
				if(op.insert && op.insert.tocs){
					type='tocs';
					block.embed = true;
				}
				if(op.insert && op.insert.notes){
					type='notes';
					block.embed = true;
				}
				/*if(op.insert.footnote){
					type='footnote';
					block.embed = true;
					prefix = '<span>';
					suffix = '</span>';
				}*/
				if(op.insert && op.insert.image4){
					type='image';
					block.embed = true;
					prefix = '<span>';
					suffix = '</span>';
				}
				if(op.insert && op.insert.decoratedBreak){
					type='decoratedBreak';
					block.embed = true;
					prefix = '<div>';
					suffix = '</div>';
				}
			});

			block.type = type;
			block.prefix = prefix;
			block.suffix = suffix;
			//block.style = style;

			if(style && style.length>0){

				block.prefix = block.prefix + formattingPrefix.replace(new RegExp('XXXSTYLEXXX', 'g'), 'style="'+style+'"');
				block.suffix = formattingSuffix + block.suffix;
			}else{

				block.prefix = block.prefix + formattingPrefix.replace(new RegExp('XXXSTYLEXXX', 'g'), '');
				block.suffix = formattingSuffix + block.suffix;
			}

			block.prefix = block.prefix.replace(new RegExp('XXXSTYLEXXX', 'g'), '');

			let cn = '';
			Object.keys(classNames).forEach((className)=>{
				cn+=' '+className;
			});
			if(cn && cn.length>0){

				block.prefix = block.prefix.replace(new RegExp('XXXCLASSNAMEXXX', 'g'), 'class="'+cn+'"' );

			}
			else{

				block.prefix = block.prefix.replace(new RegExp('XXXCLASSNAMEXXX', 'g'), '');

			}

			//block.embed = embed;
		});

		/*let mergedAlignmentBlocks = [];
		blocks.forEach((block,index)=>{
			if(index>0){
				let previousBlock = blocks[index-1];

				if(block.type=='align' && block.ops[0].insert==''){
					//previousBlock.prefix = block.prefix+previousBlock.prefix;
					//previousBlock.suffix = previousBlock.suffix+block.suffix;
					try{
						previousBlock.ops[0].insert.image4.alignment=block.ops[0].attributes.align;
					}catch(err){

					}

				}

				mergedAlignmentBlocks.push(previousBlock);
			}
		});

		blocks = mergedAlignmentBlocks;*/

		//now process lists merging them into single blocks instead of the one block per
		//list item created in this first pass.

		////console.log('cleanedBlocks:');
		////console.log(cleanedBlocks);

		let mergedBlocks = [];
		let currentListBlock = null;

		blocks.forEach((block)=>{

			if(block.type=='list'){
				if(!currentListBlock){
					currentListBlock = this.createBlock();
					currentListBlock.type = 'list';
					currentListBlock.prefix = block.prefix;
					currentListBlock.suffix = block.suffix;

					mergedBlocks.push(currentListBlock);
				}

				block.ops.forEach((op)=>{
					currentListBlock.ops.push(op);
				})

			}
			else{

				if(currentListBlock){
					currentListBlock = null;
				}

				mergedBlocks.push(block);
			}

		});

		////console.log('mergedBlocks:');
		////console.log(mergedBlocks);

		////console.log(mergedBlocks);
		return mergedBlocks;

	}

	createBlock(){

		return {
			ops:[]
		}
	}

	containsListAttributeAhead(ops,startIndex){

		/*if(!ops || !startIndex){
			return false;
		}*/

		let isList = false;
		let containsListAttribute = false;

		//if the current op contains the list attribute then this is not a list entry
		let currentOp = ops[startIndex];
		if(currentOp.attributes){
				Object.keys(currentOp.attributes).forEach((attributeKey)=>{
					if(attributeKey == 'list'){
						containsListAttribute = true;
					}
				})
		}



		if(containsListAttribute){
			return isList;
		}

		ops.forEach((op,index)=>{

			if(index>startIndex && op && op.attributes){

				Object.keys(op.attributes).forEach((attributeKey)=>{
					if(attributeKey == 'list'){
						isList = true;
					}
				})
			}

		})

		return isList;
	}


}

//exports.DeltaToHtmlEPub = DeltaToHtmlEPub;
//export DeltaToHtmlEPub
