import Quill from 'quill';
//import Parchment from 'parchment';
import DeltaToDom from './DeltaToDom';
//import Popper from 'popper.js';
import config from '../../../aws-exports';

const Parchment = Quill.import('parchment');
var $ = require("jquery");

let BlockEmbed = Quill.import('blots/block/embed');
let Base = Quill.import('blots/embed');
let Inline = Quill.import('blots/inline');

function createImageContent(node, args, blot, blotImageDivId) {

  //console.log('createImageContent()...');
  let converter = new DeltaToDom();
  /*let html = converter.convertBlurb(args);
    node.innerHTML = html;*/

  let domNode = converter.convertImage4(args, node, blot, blotImageDivId);
  node.appendChild(domNode);






}

export default class ImageBlot4 extends Base {
  static create(args) {
    //console.log(args);
    let node = super.create();
    let blotImageDivId = 'blotImageDiv' + Date.now();
    node.setAttribute('id', blotImageDivId);
    node.setAttribute('src', 'https://m.media-amazon.com/images/I/71cWEHLM9TL._AC_SL1500_.jpg');

    //node.setAttribute('style', 'border:2px solid red');

    node.dataset.caption = args.caption;
    node.dataset.includeCaption = args.includeCaption;
    node.dataset.blotImageDivId = blotImageDivId;

    $(node).click(function () {

      $('#imagePopperTriggerDiv').data('blotNode', node);
      $('#imagePopperTriggerDiv').click();


    });

    let nodeParent = $(node);
    $(nodeParent).removeClass('ql-align-right');
    $(nodeParent).removeClass('ql-align-center');
    $(nodeParent).removeClass('floatRight');
    $(nodeParent).removeClass('floatLeft');
    $(nodeParent).addClass('popupSource');


    switch (args.alignment) {

      case 'center':
        $(nodeParent).addClass('ql-align-center');
        break;

      case 'right':
        //$(nodeParent).addClass('ql-align-right');
        $(nodeParent).addClass('ql-align-center');
        break;

      case 'floatLeft':
        $(nodeParent).addClass('floatLeft');
        break;

      case 'floatRight':
        $(nodeParent).addClass('floatRight');
        break;

      default:
        $(nodeParent).addClass('ql-align-center');
        break;
    }

    // Allow twitter library to modify our contents
    //twttr.widgets.createTweet(id, node);
    createImageContent(node, args, this, blotImageDivId);

    let propsDiv = $("#image4Popper");
    let thisPopper = null;


    return node;
  }

  static value(domNode) {
    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
      src: domNode.dataset.src,
      s3Src: domNode.dataset.s3Src,
      type: domNode.dataset.type,
      alt: 'alternate text',
      caption: domNode.dataset.caption,
      alignment: domNode.dataset.alignment,
      size: domNode.dataset.size,
      includeCaption: domNode.dataset.includeCaption
    };
    //value.id = domNode.dataset.id;
    return value;
  }


  /*deleteAt(index,length) {

    //let textBlot = Parchment.create('text', 'Test');
    //textBlot.insertAt(index, 'ough');
    return false;
  }*/

  setAlignment(alignment) {

    //console.log(alignment);
    this.domNode.dataset.alignment = alignment;

    $(this.domNode).removeClass('ql-align-center');
    $(this.domNode).removeClass('ql-align-right');
    $(this.domNode).removeClass('ql-align-justify');
    $(this.domNode).removeClass('floatRight');
    $(this.domNode).removeClass('floatLeft');

    switch (alignment) {

      case 'floatLeft':
        $(this.domNode).addClass('floatLeft');
        break;

      case 'center':
        $(this.domNode).addClass('ql-align-center');
        break;

      case 'floatRight':
        $(this.domNode).addClass('floatRight');
        break;
    }




  }

  setSize(size) {
    //console.log(size);
    this.domNode.dataset.size = size;

    let imageSize = '10em';
    switch (size) {

      case 'small':
        imageSize = '5em';
        break;

      case 'medium':
        imageSize = '10em';
        break;

      case 'large':
        imageSize = '15em';
        break;

      case 'xlarge':
        imageSize = '20em';
        break;
    }


    $(this.domNode).find("img").attr('style', 'display:block; margin-left:.5em; margin-right:.5em;width:' + imageSize);

  }

  setIncludeCaption(includeCaption) {
    //console.log(includeCaption);
    this.domNode.dataset.includeCaption = includeCaption;
    let blotImageDivId = $(this.domNode).data('blotImageDivId');
    //includeCaption = !(includeCaption && includeCaption == 'true') ? true : false;
    
    if (this.domNode.dataset.includeCaption && this.domNode.dataset.includeCaption == 'true') {
      $('#' + blotImageDivId + 'Caption').css('display', 'inline-block');
    } else {
      $('#' + blotImageDivId + 'Caption').css('display', 'none');
    }

  }

  setSrc(src) {
    //console.log(src);
    this.domNode.dataset.src = src;
   
    $(this.domNode).find("img").attr('src','https://'+config.user_uploads_bucket_name+".s3.amazonaws.com/"+src);

    
  }



}
ImageBlot4.blotName = 'image4';
ImageBlot4.tagName = 'span';
ImageBlot4.className = 'image4';