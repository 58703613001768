import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Divider, Grid, Stack, styled, Typography, Avatar, IconButton } from "@mui/material";
import { Paper } from "@mui/material";
import StandardDashboardPaper from "../StandardPaper";
import { StandardPaper } from "../StandardPaper";
import { useTheme } from '@mui/material/styles';
import { useGetOwnedBooks } from '../../managers/BooksManager';
import StandardBookCoverTitle from "../StandardBookCoverTitle";
import BookListItem from "../lists/BookListItem";
import Feather from "mdi-material-ui/Feather";
import StandardRoundedAction from "../actions/StandardRoundedAction";
import { useListMostRecentUserComments } from '../../persistance/persistanceHooks';
import UserComment from '../editor/UserComment';
import { UserComment as UserCommentPojo } from '../../domain/bookModel';

const FeedbackDashboard = (props) => {
    //export default function FeedbackDashboard(props) {

    const { ownedBooks, error } = useGetOwnedBooks(props);
    let [userCommentItems, setUserCommentItems] = useState([]);

    const { loading: listMostRecentUserCommentsLoading, error: listMostRecentUserCommentsError, data: listMostRecentUserCommentsData, refetch: listMostRecentUserCommentsRefetch } = useListMostRecentUserComments();

    const theme = useTheme();

    const createUserCommentItem = (data, documentPart) => {

        let { viewFavoritedComments, viewPinnedComments, viewCompletedComments, viewPendingComments } = props.stores.bookStore;


        let filteredComments = data.items;

        filteredComments = filteredComments.filter((f) => {

            if (viewFavoritedComments || viewPinnedComments || viewCompletedComments || viewPendingComments) {

                if (viewFavoritedComments && f.isFavorite) return f;
                if (viewPinnedComments && f.isPinned) return f;
                if (viewCompletedComments && f.completedAt) return f;
                if (viewPendingComments && !f.completedAt) return f;

            }
            else {
                return f;
            }


        });




        let userCommentItems = filteredComments.filter(f => f.documentPartId == documentPart.id).map((userComment) => {


            // return <UserComment ownerMode={props.ownerMode} userComment={userComment} onUpdated={() => {}} />
            return  userComment;

        });


        // documentPart.bookDraftDocumentPart.getSortedChildren().forEach((bddp) => {
        //   userCommentItems = userCommentItems.concat(createUserCommentItem(data, bddp.documentPart));
        // })


        return userCommentItems;

    }

    useEffect(() => {

        //console.log(listMostRecentUserCommentsData);



        if (listMostRecentUserCommentsData) {



            try {

                setUserCommentItems(listMostRecentUserCommentsData.listMostRecentUserComments.items.map((userComment) => {

                    return <UserComment 
                        showBookInfo={true} 
                        ownerMode={props.ownerMode} 
                        showWriteIcon={true}
                        userComment={userComment} 
                        onUpdated={() => { }} />
                }))

            } catch (err) {

            }


            



        }

    }, [listMostRecentUserCommentsData]);










    return (
        <div style={{ margin: '0.5em 0' }}>
            {/* <div style={{ width: '100%', backgroundColor: '#fff9a1', borderx: 'thin white solid', borderRadius: '8px', padding: '0.5em', marginBottom: '0.5em' }}>
                <div style={{
                    positionx: 'unset',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        textAlign: 'left',
                    }}>
                        <Avatar
                            sx={{ width: 30, height: 30, bgcolor: '#009688' }}
                        />
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            textAlign: 'left',
                            width: '100%'
                        }}>
                            <Typography
                                style={{ fontWeight: 'bold', paddingLeft: '0.5em', color: 'black' }}
                            >
                                Billy Bob
                            </Typography>
                           
                            <Typography style={{ padding: '0 0.5em', color: 'black' }}>*</Typography>
                            <Typography style={{ color: 'black' }}>30s ago</Typography>
                        </div>
                    </div>
                    <div style={{
                        textAlign: 'left',
                        paddingLeft: '2.25em',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        width: '100%',
                        alignItems: 'center'
                    }}
                    >
                        <Typography variant="body2" noWrap={true}
                            sx={{
                                // 'white-space': 'nowrap',
                                // //'border': '1px solid red',
                                // 'width': '100%',
                                // //'width': theme.components.standardTree.nodes.width,
                                // 'display': 'block',
                                // 'overflow': 'hidden',
                                // 'text-overflow': 'ellipsis',
                                'color': 'black',
                                'text-overflow': 'ellipsis',
                                'overflow': 'hidden',
                                // Addition lines for 2 line or multiline ellipsis
                                'display': '-webkit-box !important',
                                '-webkit-line-clamp': '1',
                                '-webkit-box-orient': 'vertical',
                                'white-space': 'normal',
                            }}>
                            This comment looks like a YouTube comment. Want to see what it looks like when the comment is longer.
                        </Typography>
                        <StandardRoundedAction icon={<Feather sx={{
                            color: theme.palette.standardActions.icon.color.main,

                        }} onClick={(e) => {
                            e.preventDefault();
                            // console.log(book)

                            // openBookInWrite({
                            // bookDraftId: book.primaryBookDraftId
                            // });
                        }} />} />
                       
                    </div>
                </div>
            </div> */}
            {userCommentItems}



        </div >
    );
}

export default inject('stores')(observer(FeedbackDashboard));