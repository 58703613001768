const config = require('../../aws-exports').default;




const layout = {

	boxset: {

		sections: [
			{
				title: 'BoxSet Details',
				elements: [
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_MAIN_BOOK',
						entityFieldName: 'title',
						label: 'Boxset Title',
						placeholder: 'Boxset Title',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_MAIN_BOOK',
						entityFieldName: 'subTitle',
						label: 'Boxset Subtitle',
						placeholder: 'Boxset Subtitle',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_MAIN_BOOK',
						entityFieldName: 'authorName',
						label: 'Author Name',
						placeholder: 'Author Name',
						canEdit: 'ITEM_OWNER'
					}
				]
			},
			{
				title: 'BoxSet Images',
				elements: [
					{
						type: 'SUBHEADING',
						title: 'Cover Image',
						addTopMargin: false
					},
					{
						type: 'IMAGE_SELECTOR',
						title: 'Cover Image',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_MAIN_BOOK',
						entityFieldName: 'coverUrl',
						entityTypeFieldName: 'coverUrlType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'SUBHEADING',
						title: 'Thumbnail Image',
						addTopMargin: false
					},
					{
						type: 'IMAGE_SELECTOR',
						title: 'Thumbnail Image',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_MAIN_BOOK',
						entityImageSourceFieldName: 'coverThumbnailUrl',
						entityImageMediaTypeFieldName: 'coverThumbnailUrlType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER'
					}
				]
			}

		]

	},
	boxsetBook: {

		sections: [

			// {
			// 	title: 'Contributed Book Theme',
			// 	elements: [
			// 		{
			// 			type: 'SUBHEADING',
			// 			title: 'Theme Source',
			// 			addTopMargin: false
			// 		},
			// 		{
			// 			type: 'TOGGLE_BUTTONS',
			// 			title: 'Select recipe source',
			// 			paramType: 'ENTITY',
			// 			paramItem: 'BOXSETBOOK',
			// 			entityFieldName: 'recipeSource',
			// 			options: [
			// 				{
			// 					label: 'Boxset',
			// 					value: 'boxSetRecipe',
			// 					selectedMessage: 'Use the boxset\'s theme for this contributed book.',

			// 				},
			// 				{
			// 					label: 'Book',
			// 					value: 'originalBookRecipe',
			// 					selectedMessage: 'Use the book\'s theme for this contributed book.',

			// 				},
			// 				{
			// 					label: 'Custom',
			// 					value: 'overrideBookRecipe',
			// 					selectedMessage: 'Use a custom theme for this contributed book.',

			// 				}
			// 			],
			// 		}
			// 	]
			// },
			// {
			// 	title: 'Contributed Book Cover Page Style',
			// 	elements: [
			// 		{
			// 			type: 'TEMPLATE_SELECTOR',
			// 			canEdit: 'ITEM_OWNER',
			// 			title: 'Book Template',
			// 			//configType:'LOOKUP',
			// 			configType: 'STANDARD',
			// 			//configDetails:'HEADER_BY_PART_TYPE',
			// 			recipeParamName: 'defaultBookContentTemplate',
			// 			templateSourceTag: 'TEMP_bookContent',
			// 			hideLabel: true

			// 		}
			// 	],
			// 	sectionVisibilityConditions: [{
			// 		visible: true,
			// 		conditionType: 'IS_INSERTED_BOOK_CONTENT'
			// 	}],
			// 	notVisibleHelperMessage:'This is for inserted books content'
			// },
			{
				title: 'Contributed Book Details',
				elements: [
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_BOOK',
						entityFieldName: 'title',
						label: 'Title',
						placeholder: 'Book Title',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_BOOK',
						entityFieldName: 'subTitle',
						label: 'Subtitle',
						placeholder: 'Boxset Subtitle',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_BOOK',
						entityFieldName: 'authorName',
						label: 'Author Name',
						placeholder: 'Author Name',
						canEdit: 'ITEM_OWNER'
					}
				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'IS_INSERTED_BOOK_CONTENT'
				}],
				notVisibleHelperMessage: 'This is for inserted books content'
			},
			{
				title: 'Contributed Book Images',
				elements: [
					{
						type: 'SUBHEADING',
						title: 'Cover Image',
						addTopMargin: false
					},
					{
						type: 'IMAGE_SELECTOR',
						title: 'Book Cover Image',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_BOOK',
						entityImageSourceFieldName: 'coverUrl',
						entityImageMediaTypeFieldName: 'coverUrlType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'SUBHEADING',
						title: 'Thumbnail Image',
						addTopMargin: false
					},
					{
						type: 'IMAGE_SELECTOR',
						title: 'Thumbnail Image',
						paramType: 'ENTITY',
						paramItem: 'BOXSET_BOOK',
						entityImageSourceFieldName: 'coverThumbnailUrl',
						entityImageMediaTypeFieldName: 'coverThumbnailUrlType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER'
					}
				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'IS_INSERTED_BOOK_CONTENT'
				}],
				notVisibleHelperMessage: 'This is for inserted books content'
			}

		]

	},
	book: {

		sections: [
			{
				title: 'Book Details',
				elements: [
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOOK',
						entityFieldName: 'title',
						label: 'Book Title',
						placeholder: 'Book Title',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Subtitle',
						paramType: 'ENTITY',
						paramItem: 'BOOK',
						entityFieldName: 'subTitle',
						label: 'Book Subtitle',
						placeholder: 'Book Subtitle',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'BOOK',
						entityFieldName: 'authorName',
						label: 'Author Name',
						placeholder: 'Author Name',
						canEdit: 'ITEM_OWNER'
					}
				]
			},
			{
				title: 'Book Images',
				elements: [
					{
						type: 'SUBHEADING',
						title: 'Cover Image111',
						addTopMargin: false
					},
					// {         
					// 	type: 'IMAGE_UPLOAD_SELECTOR',
					// 	title: 'Cover Image',
					// 	paramType: 'ENTITY',
					// 	paramItem: 'BOOK',
					// 	entityFieldName: 'coverUrl',
					// 	bucketName: config.user_uploads_bucket_name,
					// 	// entityImageSourceFieldName: 'coverUrl',
					// 	// entityImageMediaTypeFieldName: 'coverUrlType',
					// 	identitySource: 'ITEM_OWNER',
					// 	canEdit: 'ITEM_OWNER'
					// },
					{
						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Cover Image',
						paramType: 'ENTITY',
						paramItem: 'BOOK',
						entityFieldName: 'coverUrl',
						//bucketName: config.user_uploads_bucket_name,
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// fileSource: 'userImages',
						// level: 'public',
						//This allows us to pick from a gallery and to upload an image in a single control.
						hideGalleryControls: true,
						imageUploaderConfig: {
							type: 'IMAGE_SELECTOR',
							title: 'Book Common Image',
							paramType: 'ENTITY',
							paramItem: 'BOOK',
							bucketName: config.user_uploads_bucket_name,
							folder: 'folder1',
							fileSource: 'userImages',
							entityFieldName: 'coverUrl',
							//entityImageMediaTypeFieldName: 'headerImageType',
							identitySource: 'ITEM_OWNER',
							canEdit: 'ITEM_OWNER'
						}
					},
					// selectorConfig: {
					// 	type: 'IMAGE_POPUP_SELECTOR',
					// 	title: 'Title Image',
					// 	paramType: 'RECIPE',
					// 	paramItem: 'BOOK',
					// 	bucketName: config.common_content_bucket_name,
					// 	folder: 'public/headerThumbnails',
					// 	fileSource: 'commonImages',
					// 	level: 'public',
					// 	recipeParamName: 'global_common_headingTitleImageSrc',
					// 	recipeMediaTypeParamName: 'global_common_headingTitleImageMediaType',
					// 	identitySource: 'ITEM_OWNER',
					// 	canEdit: 'ITEM_OWNER',

					// 	//This allows us to pick from a gallery and to upload an image in a single control.
					// 	imageUploaderConfig: {
					// 		type: 'IMAGE_SELECTOR',
					// 		title: 'Book Common Image',
					// 		paramType: 'RECIPE',
					// 		//paramItem: 'BOOK',   
					// 		bucketName: config.user_uploads_bucket_name,
					// 		folder: 'folder1',
					// 		fileSource: 'userImages',
					// 		// entityImageSourceFieldName: 'global_common_headingImageSrc',
					// 		// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
					// 		recipeParamName: 'global_common_headingTitleImageSrc',
					// 		recipeMediaTypeParamName: 'global_common_headingImageMediaType',
					// 		identitySource: 'ITEM_OWNER',
					// 		canEdit: 'ITEM_OWNER'
					// 	}
					// }
					{
						type: 'SUBHEADING',
						title: 'Thumbnail Image2222',
						addTopMargin: false
					},
					// {
					// 	type: 'IMAGE_UPLOAD_SELECTOR',
					// 	title: 'Thumbnail Image',
					// 	paramType: 'ENTITY',
					// 	paramItem: 'BOOK',
					// 	entityFieldName: 'coverThumbnailUrl',
					// 	bucketName: config.user_uploads_bucket_name,
					// 	// entityImageSourceFieldName: 'coverThumbnailUrl',
					// 	// entityImageMediaTypeFieldName: 'coverThumbnailUrlType',
					// 	identitySource: 'ITEM_OWNER',
					// 	canEdit: 'ITEM_OWNER'
					// },
					{
						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Cover Thumbnail',
						paramType: 'ENTITY',
						paramItem: 'BOOK',
						entityFieldName: 'coverThumbnailUrl',
						//bucketName: config.user_uploads_bucket_name,
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// fileSource: 'userImages',
						// level: 'public',
						//This allows us to pick from a gallery and to upload an image in a single control.
						hideGalleryControls: true,
						imageUploaderConfig: {
							type: 'IMAGE_SELECTOR',
							title: 'Book Common Image',
							paramType: 'ENTITY',
							paramItem: 'BOOK',
							bucketName: config.user_uploads_bucket_name,
							folder: 'folder1',
							fileSource: 'userImages',
							entityFieldName: 'coverThumbnailUrl',
							//entityImageMediaTypeFieldName: 'headerImageType',
							identitySource: 'ITEM_OWNER',
							canEdit: 'ITEM_OWNER'


							// type: 'IMAGE_SELECTOR',
							// //title: 'Book Common Image',
							// paramType: 'RECIPE',    
							// //paramItem: 'BOOK',   
							// bucketName: config.user_uploads_bucket_name,
							// //folder: 'folder1',
							// fileSource: 'userImages',
							// // entityImageSourceFieldName: 'global_common_headingImageSrc',
							// // entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
							// // recipeParamName: 'global_common_headingTitleImageSrc',
							// // recipeMediaTypeParamName: 'global_common_headingImageMediaType',
							// identitySource: 'ITEM_OWNER',
							// canEdit: 'ITEM_OWNER'
						}





					},
				]
			}

		]

	},
	page: {
		sections: [
			{
				title: 'Contributed Book Internal Cover Page Style',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: 'Book Template',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultBookContentTemplate',
						templateSourceTag: 'TEMP_bookContent',
						hideLabel: true

					}
				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'PART_TYPES',
					partTypes: ['BOOK']

				}],
				notVisibleHelperMessage: 'This is for inserted books content'
			},
			{
				title: 'Page Details',
				elements: [
					{
						type: 'TEXTFIELD',
						title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'title',
						label: 'Title',
						placeholder: 'Title',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Subtitle',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'subtitle',
						label: 'Subtitle',
						placeholder: 'Subtitle',
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'TEXTFIELD',
						title: 'Author Name',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'authorName',
						label: 'Author Name',
						placeholder: 'Author Name',
						canEdit: 'ITEM_OWNER'
					}
				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'NOT_PART_TYPES',
					partTypes: ['TITLEPAGE', 'HALFTITLE', 'BOOK']

				}],
				notVisibleHelperMessage: 'The title, subtitle and authorname for these page types will come from the book or boxset values.'
			},
			{
				title: 'Page Visibility',
				elements: [
					{
						type: 'CHECKBOX',
						title: 'Exclude from book',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromBook',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Exclude from table of contents',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromToc',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Generic',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromGeneric',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Amazon',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromAmazon',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Apple',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromApple',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Google',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromGoogle',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Kobo',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromKobo',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Nook',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromNook',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Print',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromPrint',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: true,
						canEdit: 'ITEM_OWNER'
					},
				]
			},
			{
				title: 'Page Background',
				elements: [
					// {
					// 	type: 'SUBHEADING',
					// 	title: 'Background Image Source',
					// 	addTopMargin: false
					// },
					// {
					// 	type: 'BACKGROUND_IMAGE_SOURCE_TOGGLE_BUTTONS',
					// 	title: 'Select recipe source',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 	options: [
					// 		{
					// 			label: 'Book Common',
					// 			value: 'common',
					// 			selectedMessage: 'Using the book common heading NOT-style for all ---PART_TYPE---s.',

					// 		},
					// 		{
					// 			label: '---PART_TYPE---s Specific',
					// 			value: 'partTypeSpecific',
					// 			selectedMessage: 'Using a separate heading NOT-style for all ---PART_TYPE---s.',

					// 		}
					// 	],
					// },
					{
						type: 'SUBHEADING',
						title: 'Visibility',
						addTopMargin: false
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all heading background images',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_hideAllHeadingBackgroundImages',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}],
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all ---PART_TYPE_LOWER--- heading background images',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_hideAllHeadingBackgroundImages',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}],
					},

					{
						type: 'CHECKBOX',
						title: 'Hide this ---PART_TYPE_LOWER---\'s heading image',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'hideHeadingBackgroundImage',
						checkedValue: "true",
						unCheckedValue: "false",
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},


					{
						type: 'SUBHEADING',
						title: 'Background Image Source',
						addTopMargin: false
					},
					{
						type: 'IMAGE_SOURCE_SELECTOR',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingBackgroundImageSourceType',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
						imageSelectors: [
							{
								label: 'All Pages',
								key: 'book',
								selectedMessage: 'All ---PART_TYPE---s will use the books common heading background image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: 'Book Common Image',
									paramType: 'RECIPE',
									//paramItem: 'BOOK',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									// entityImageSourceFieldName: 'global_common_headingImageSrc',
									// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
									recipeParamName: 'global_common_headingBackgroundImageSrc',
									recipeMediaTypeParamName: 'global_common_headingImageMediaType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'RECIPE',
										//paramItem: 'BOOK',   
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										recipeParamName: 'global_common_headingBackgroundImageSrc',
										recipeMediaTypeParamName: 'global_common_headingImageMediaType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							},
							{
								label: 'All ---PART_TYPE---s',
								key: 'type',
								selectedMessage: 'All ---PART_TYPE---s will use this image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: '---PART_TYPE---s Specific Image',
									paramType: 'RECIPE',
									//paramItem: 'BOOK',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSrc',
									recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'RECIPE',
										//paramItem: 'BOOK',   
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										// entityImageSourceFieldName: 'global_common_headingImageSrc',
										// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
										recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSrc',
										recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							},
							{
								label: 'This Page',
								key: 'self',
								selectedMessage: 'Each ---PART_TYPE--- will provide a unique heading background image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: 'This ---PART_TYPE---\'s Specific Image',
									paramType: 'ENTITY',
									paramItem: 'DOCUMENTPART',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									entityImageSourceFieldName: 'headingBackgroundUrl',
									entityImageMediaTypeFieldName: 'headerImageType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'ENTITY',
										paramItem: 'DOCUMENTPART',
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										entityFieldName: 'headingBackgroundUrl',
										//entityImageMediaTypeFieldName: 'headerImageType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							}
						],
					},
					// {
					// 	type: 'IMAGE_SOURCE_SELECTOR',
					// 	title: '',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_common_headingBackgroundImageSourceType',
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'common'
					// 	}],
					// 	imageSelectors: [
					// 		{
					// 			label: 'All Pages',
					// 			key: 'book',
					// 			selectedMessage: 'All ---PART_TYPE---s will use the books common heading background image.',
					// 			selectorConfig: {
					// 				type: 'IMAGE_SELECTOR',
					// 				title: 'Book Common Image',
					// 				paramType: 'RECIPE',
					// 				//paramItem: 'BOOK',
					// 				bucketName: config.user_uploads_bucket_region,
					// 				folder: 'folder1',
					// 				// entityImageSourceFieldName: 'global_common_headingImageSrc',
					// 				// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
					// 				recipeParamName: 'global_common_headingBackgroundImageSrc',
					// 				recipeMediaTypeParamName: 'global_common_headingImageMediaType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER',
					// 				imageUploaderConfig: {
					// 					type: 'IMAGE_SELECTOR',
					// 					title: 'Book Common Image',
					// 					paramType: 'RECIPE',
					// 					//paramItem: 'BOOK',   
					// 					bucketName: config.user_uploads_bucket_name,
					// 					folder: 'folder1',
					// 					fileSource: 'userImages',
					// 					recipeParamName: 'global_common_headingBackgroundImageSrc',
					// 					recipeMediaTypeParamName: 'global_common_headingImageMediaType',
					// 					identitySource: 'ITEM_OWNER',
					// 					canEdit: 'ITEM_OWNER'
					// 				}
					// 			}
					// 		},
					// 		{
					// 			label: 'This Page',
					// 			key: 'self',
					// 			selectedMessage: 'Each ---PART_TYPE--- will provide a unique heading background image.',
					// 			selectorConfig: {
					// 				type: 'IMAGE_SELECTOR',
					// 				title: 'This ---PART_TYPE---\'s Specific Image',
					// 				paramType: 'ENTITY',
					// 				paramItem: 'DOCUMENTPART',
					// 				bucketName: config.user_uploads_bucket_region,
					// 				folder: 'folder1',
					// 				entityImageSourceFieldName: 'headingBackgroundUrl',
					// 				entityImageMediaTypeFieldName: 'headerImageType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER',
					// 				imageUploaderConfig: {
					// 					type: 'IMAGE_SELECTOR',
					// 					title: 'Book Common Image',
					// 					paramType: 'ENTITY',
					// 					paramItem: 'DOCUMENTPART',
					// 					bucketName: config.user_uploads_bucket_name,
					// 					folder: 'folder1',
					// 					fileSource: 'userImages',
					// 					entityFieldName: 'headingBackgroundUrl',
					// 					//entityImageMediaTypeFieldName: 'headerImageType',
					// 					identitySource: 'ITEM_OWNER',
					// 					canEdit: 'ITEM_OWNER'
					// 				}
					// 			}
					// 		}
					// 	],
					// },
					// {
					// 	type: 'IMAGE_SOURCE_SELECTOR',
					// 	title: '',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSourceType',
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'partTypeSpecific'
					// 	}],
					// 	imageSelectors: [
					// 		{
					// 			label: 'All ---PART_TYPE---s',
					// 			key: 'type',
					// 			selectedMessage: 'All ---PART_TYPE---s will use this image.',
					// 			selectorConfig: {
					// 				type: 'IMAGE_SELECTOR',
					// 				title: '---PART_TYPE---s Specific Image',
					// 				paramType: 'RECIPE',
					// 				//paramItem: 'BOOK',
					// 				bucketName: config.user_uploads_bucket_region,
					// 				folder: 'folder1',
					// 				recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSrc',
					// 				recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER',
					// 				imageUploaderConfig: {
					// 					type: 'IMAGE_SELECTOR',
					// 					title: 'Book Common Image',
					// 					paramType: 'RECIPE',
					// 					//paramItem: 'BOOK',   
					// 					bucketName: config.user_uploads_bucket_name,
					// 					folder: 'folder1',
					// 					fileSource: 'userImages',
					// 					// entityImageSourceFieldName: 'global_common_headingImageSrc',
					// 					// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
					// 					recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSrc',
					// 					recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
					// 					identitySource: 'ITEM_OWNER',
					// 					canEdit: 'ITEM_OWNER'
					// 				}
					// 			}
					// 		},
					// 		{
					// 			label: 'This ---PART_TYPE---',
					// 			key: 'self',
					// 			selectedMessage: 'Each ---PART_TYPE--- will provide a unique heading background image.',
					// 			selectorConfig: {
					// 				type: 'IMAGE_SELECTOR',
					// 				title: 'This ---PART_TYPE---\'s Specific Image',
					// 				paramType: 'ENTITY',
					// 				paramItem: 'DOCUMENTPART',
					// 				bucketName: config.user_uploads_bucket_region,
					// 				folder: 'folder1',
					// 				entityImageSourceFieldName: 'headingBackgroundUrl',
					// 				entityImageMediaTypeFieldName: 'headerImageType',
					// 				identitySource: 'ITEM_OWNER',
					// 				canEdit: 'ITEM_OWNER',
					// 				imageUploaderConfig: {
					// 					type: 'IMAGE_SELECTOR',
					// 					title: 'Book Common Image',
					// 					paramType: 'ENTITY',
					// 					paramItem: 'DOCUMENTPART',
					// 					bucketName: config.user_uploads_bucket_name,
					// 					folder: 'folder1',
					// 					fileSource: 'userImages',
					// 					entityFieldName: 'headingBackgroundUrl',
					// 					//entityImageMediaTypeFieldName: 'headerImageType',
					// 					identitySource: 'ITEM_OWNER',
					// 					canEdit: 'ITEM_OWNER'
					// 				}
					// 			}
					// 		}
					// 	],
					// },
					{
						type: 'SUBHEADING',
						title: 'Image Span ( Vellums title, rename this )',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Single Page', value: 'singlePage' },
							{ label: 'Double Page', value: 'doublePage' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},



					{
						type: 'SUBHEADING',
						title: 'Background Text Color ( probably just white or black )',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'White', value: 'white' },
							{ label: 'Black', value: 'black' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'CHECKBOX',
						title: 'Full Page Bleed ( title ?)',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromBook',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'SUBHEADING',
						title: 'Opacity',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'zero', value: 'zero' },
							{ label: '25%', value: '25' },
							{ label: '50%', value: '50' },
							{ label: '75%', value: '75' },
							{ label: '95%', value: '95' },
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Bottom Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Left Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Right Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},


				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'NOT_PART_TYPES',
					partTypes: ['TITLEPAGE', 'HALFTITLE', 'BOOK']

				}],
				notVisibleHelperMessage: 'The title, subtitle and authorname for these page types will come from the book or boxset values.'
			},

		]
	},
	background: {


		sections: [
			{
				title: 'Page Background',
				elements: [

					{
						type: 'SUBHEADING',
						title: 'Visibility',
						addTopMargin: false
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all heading background images',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_hideAllHeadingBackgroundImages',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'CHECKBOX',
						title: 'Hide all ---PART_TYPE_LOWER--- heading background images',
						paramType: 'RECIPE',
						recipeParamName: 'global_---PART_TYPE_LOWER---_hideAllHeadingBackgroundImages',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'partTypeSpecific'
						// }],
					},

					{
						type: 'CHECKBOX',
						title: 'Hide this ---PART_TYPE_LOWER---\'s heading image',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'hideHeadingBackgroundImage',
						checkedValue: "true",
						unCheckedValue: "false",
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},


					{
						type: 'SUBHEADING',
						title: 'Background Image Source',
						addTopMargin: false
					},
					{
						type: 'IMAGE_SOURCE_SELECTOR',
						title: '',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingBackgroundImageSourceType',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
						imageSelectors: [
							{
								label: 'All Pages',
								key: 'book',
								selectedMessage: 'All ---PART_TYPE---s will use the books common heading background image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: 'Book Common Image',
									paramType: 'RECIPE',
									//paramItem: 'BOOK',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									// entityImageSourceFieldName: 'global_common_headingImageSrc',
									// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
									recipeParamName: 'global_common_headingBackgroundImageSrc',
									recipeMediaTypeParamName: 'global_common_headingImageMediaType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'RECIPE',
										//paramItem: 'BOOK',   
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										recipeParamName: 'global_common_headingBackgroundImageSrc',
										recipeMediaTypeParamName: 'global_common_headingImageMediaType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							},
							{
								label: 'All ---PART_TYPE---s',
								key: 'type',
								selectedMessage: 'All ---PART_TYPE---s will use this image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: '---PART_TYPE---s Specific Image',
									paramType: 'RECIPE',
									//paramItem: 'BOOK',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSrc',
									recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'RECIPE',
										//paramItem: 'BOOK',   
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										// entityImageSourceFieldName: 'global_common_headingImageSrc',
										// entityImageMediaTypeFieldName: 'global_common_headingImageMediaType',
										recipeParamName: 'global_---PART_TYPE_LOWER---_headingBackgroundImageSrc',
										recipeMediaTypeParamName: 'global_---PART_TYPE_LOWER---_headingImageMediaType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							},
							{
								label: 'This Page',
								key: 'self',
								selectedMessage: 'Each ---PART_TYPE--- will provide a unique heading background image.',
								selectorConfig: {
									type: 'IMAGE_SELECTOR',
									title: 'This ---PART_TYPE---\'s Specific Image',
									paramType: 'ENTITY',
									paramItem: 'DOCUMENTPART',
									bucketName: config.user_uploads_bucket_region,
									folder: 'folder1',
									entityImageSourceFieldName: 'headingBackgroundUrl',
									entityImageMediaTypeFieldName: 'headerImageType',
									identitySource: 'ITEM_OWNER',
									canEdit: 'ITEM_OWNER',
									imageUploaderConfig: {
										type: 'IMAGE_SELECTOR',
										title: 'Book Common Image',
										paramType: 'ENTITY',
										paramItem: 'DOCUMENTPART',
										bucketName: config.user_uploads_bucket_name,
										folder: 'folder1',
										fileSource: 'userImages',
										entityFieldName: 'headingBackgroundUrl',
										//entityImageMediaTypeFieldName: 'headerImageType',
										identitySource: 'ITEM_OWNER',
										canEdit: 'ITEM_OWNER'
									}
								}
							}
						],
					},
					{
						type: 'SUBHEADING',
						title: 'Image Span ( Vellums title, rename this )',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Single Page', value: 'singlePage' },
							{ label: 'Double Page', value: 'doublePage' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},



					{
						type: 'SUBHEADING',
						title: 'Background Text Color ( probably just white or black )',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'White', value: 'white' },
							{ label: 'Black', value: 'black' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'CHECKBOX',
						title: 'Full Page Bleed ( title ?)',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'excludeFromBook',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'SUBHEADING',
						title: 'Opacity',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'zero', value: 'zero' },
							{ label: '25%', value: '25' },
							{ label: '50%', value: '50' },
							{ label: '75%', value: '75' },
							{ label: '95%', value: '95' },
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Bottom Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Left Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Right Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'RADIO_BUTTONS',
						//title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'none' },
							{ label: 'Small', value: 'small' },
							{ label: 'Medium', value: 'medium' },
							{ label: 'Large', value: 'large' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},


				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'NOT_PART_TYPES',
					partTypes: ['TITLEPAGE', 'HALFTITLE', 'BOOK']

				}],
				notVisibleHelperMessage: 'The title, subtitle and authorname for these page types will come from the book or boxset values.'
			},

		],
		visibilityConditions: [{
			visible: true,
			conditionType: 'HAS_HEADING'
		}],
		notVisibleHelperMessage: 'This is for content with headings'

	},
	heading: {


		sections: [


			{
				title: 'Heading Visibility',
				elements: [
					{
						type: 'PROPS_CHECKBOX',
						title: 'Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						//invertValue: true,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},

				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			},
			{
				title: 'Heading Style',
				elements: [


					// {
					// 	type: 'INFOHEADING',
					// 	title: '(For the book\'s common heading)',
					// 	addTopMargin: false,
					// 	visibilityConditions: [{
					// 		visible: true,
					// 		conditionType: 'RECIPE_PARAM_VALUE',
					// 		operator: 'eq',
					// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 		recipeParamValue: 'common'
					// 	}]
					// },
					{
						type: 'INFOHEADING',
						title: 'Consider using these info headings for more info',
						addTopMargin: false,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'partTypeSpecific'
						// }]
					},
					// {
					// 	type: 'PROPS_TEMPLATE_SELECTOR',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: 'DO_NOT_USE',
					// 	disableSave: false,
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	recipeParamPath: 'HEADING.TEMPLATE.CORE.ID',
					// 	doNotPersist:true,
					// 	setValueSoure: false,
					// 	recipeParamName: 'defaultDNUContentTemplate',
					// 	templateSourceTag: 'TEMP_headingCore',
					// 	hideLabel: false,
					// 	devModeOnly: false

					// },
					{
						type: 'PROPS_TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: 'DO_NOT_USE',
						disableSave: false,
						//configType:'LOOKUP',
						configType: 'STANDARD',
						recipeParamPath: 'HEADING.TEMPLATE.ID',
						setValueSoure: false,
						recipeParamName: 'defaultDNUContentTemplate',
						templateSourceTag: 'TEMP_headingOverride',
						hideLabel: false,
						devModeOnly: false,
						showResetButton: true

					},
					{
						type: 'SUBHEADING',
						title: 'Background Color',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_COLOR_PICKER',
						canEdit: 'ITEM_OWNER',
						title: 'Font Color',
						disableSave: false,
						recipeParamPath: 'HEADING.BACKGROUND.COLOR',
						hideLabel: false,
						devModeOnly: false
						//configType:'LOOKUP',
						//configType: 'STANDARD',

						//setValueSoure: false,
						// recipeParamName: 'defaultDNUContentTemplate',
						// templateSourceTag: 'TEMP_headingOverride',


					}

				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			},
			{
				title: 'Margin',
				elements: [
					{
						type: 'SUBHEADING',
						title: 'Top',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.MARGIN.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.MARGIN.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left',
						addTopMargin: false,
						recipeParamPath: 'HEADING.MARGIN.LEFT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.MARGIN.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right',
						addTopMargin: false,
						recipeParamPath: 'HEADING.MARGIN.RIGHT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.MARGIN.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
				]
			},
			{
				title: 'Padding',
				elements: [
					{
						type: 'SUBHEADING',
						title: 'Top',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.PADDING.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.PADDING.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.PADDING.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.PADDING.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
				]
			},
			{
				title: 'Border',
				elements: [

					{
						type: 'SUBHEADING',
						title: 'Top',
						addTopMargin: false,
						//showRecipeSourceContext: true
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.BORDER.TOP.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Bottom',
						addTopMargin: false,
						//showRecipeSourceContext: true
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.BORDER.BOTTOM.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					}, {
						type: 'SUBHEADING',
						title: 'Left',
						addTopMargin: false,
						//showRecipeSourceContext: true
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.BORDER.LEFT.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Right',
						addTopMargin: false,
						//showRecipeSourceContext: true
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.BORDER.RIGHT.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Thickness',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.BORDER.THICKNESS',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Thin', value: 'THIN' },
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Thick', value: 'THICK' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
				]
			},
			{
				title: 'Title',
				elements: [


					{
						type: 'INFOHEADING',
						title: '(For the book\'s common heading)',
						addTopMargin: false,
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'INFOHEADING',
						title: '(For the ---PART_TYPE_LOWER--- specific heading)',
						addTopMargin: false,
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'partTypeSpecific'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Visibility',
						addTopMargin: false,
						//showRecipeSourceContext: true
					},


					{
						type: 'PROPS_CHECKBOX',
						title: 'Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.TITLE.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Font',
						addTopMargin: true
					},
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Title Font',
						recipeParamPath: 'HEADING.TITLE.FONT.TYPE',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Font Color',
						addTopMargin: false
					},

					{
						type: 'PROPS_COLOR_PICKER',
						canEdit: 'ITEM_OWNER',
						title: 'Font Color',
						disableSave: false,
						recipeParamPath: 'HEADING.TITLE.FONT.COLOR',
						hideLabel: false,
						devModeOnly: false
						//configType:'LOOKUP',
						//configType: 'STANDARD',

						//setValueSoure: false,
						// recipeParamName: 'defaultDNUContentTemplate',
						// templateSourceTag: 'TEMP_headingOverride',


					},




					{
						type: 'SUBHEADING',
						title: 'Type',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.TITLE.TEXT_TRANSFORM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Uppercase', value: 'UPPERCASE' },
							{ label: 'Small Caps', value: 'SMALL_CAPS' },
							{ label: 'Lowercase', value: 'LOWERCASE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.TITLE.TEXT_SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Emphasis',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Italic',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.TITLE.FONT.ITALIC',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bold',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.TITLE.FONT.BOLD',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Underlined',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.TITLE.FONT.UNDERLINE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Overlined',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.TITLE.FONT.OVERLINE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Background Color',
						addTopMargin: false
					},

					{
						type: 'PROPS_COLOR_PICKER',
						canEdit: 'ITEM_OWNER',
						title: 'Background Color',
						disableSave: false,
						recipeParamPath: 'HEADING.TITLE.BACKGROUND.COLOR',
						hideLabel: false,
						devModeOnly: false
						//configType:'LOOKUP',
						//configType: 'STANDARD',

						//setValueSoure: false,
						// recipeParamName: 'defaultDNUContentTemplate',
						// templateSourceTag: 'TEMP_headingOverride',


					}





				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			},
			{
				title: 'Subtitle',
				elements: [

					{
						"type": "SUBHEADING",
						"title": "Visibility",
						"addTopMargin": false
					},
					{
						"type": "PROPS_CHECKBOX",
						"title": "Visible",
						"paramType": "RECIPE",
						"recipeParamPath": "HEADING.SUBTITLE.VISIBLE",
						"recipeParamDefaultValue": true,
						"checkedValue": true,
						"unCheckedValue": false,
						"invertValue": false,
						"canEdit": "ITEM_OWNER"

					},
					{
						type: 'SUBHEADING',
						title: 'Font',
						addTopMargin: true
					},
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Subtitle Font',
						recipeParamPath: 'HEADING.SUBTITLE.FONT.TYPE',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Font Color',
						addTopMargin: false
					},

					{
						type: 'PROPS_COLOR_PICKER',
						canEdit: 'ITEM_OWNER',
						title: 'Font Color',
						disableSave: false,
						recipeParamPath: 'HEADING.SUBTITLE.FONT.COLOR',
						hideLabel: false,
						devModeOnly: false
						//configType:'LOOKUP',
						//configType: 'STANDARD',

						//setValueSoure: false,
						// recipeParamName: 'defaultDNUContentTemplate',
						// templateSourceTag: 'TEMP_headingOverride',


					},
					{
						type: 'SUBHEADING',
						title: 'Type',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.SUBTITLE.TEXT_TRANSFORM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Uppercase', value: 'UPPERCASE' },
							{ label: 'Small Caps', value: 'SMALL_CAPS' },
							{ label: 'Lowercase', value: 'LOWERCASE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},




					{
						type: 'SUBHEADING',
						title: 'Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.SUBTITLE.TEXT_SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Emphasis',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Italic',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.SUBTITLE.FONT.ITALIC',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bold',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.SUBTITLE.FONT.BOLD',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Underlined',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.SUBTITLE.FONT.UNDERLINE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Overlined',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.SUBTITLE.FONT.OVERLINE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Background Color',
						addTopMargin: false
					},

					{
						type: 'PROPS_COLOR_PICKER',
						canEdit: 'ITEM_OWNER',
						title: 'Background Color',
						disableSave: false,
						recipeParamPath: 'HEADING.SUBTITLE.BACKGROUND.COLOR',
						hideLabel: false,
						devModeOnly: false
						//configType:'LOOKUP',
						//configType: 'STANDARD',

						//setValueSoure: false,
						// recipeParamName: 'defaultDNUContentTemplate',
						// templateSourceTag: 'TEMP_headingOverride',


					}

				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			},
			{
				title: 'Author',
				elements: [

					{
						"type": "SUBHEADING",
						"title": "Visibility",
						"addTopMargin": false
					},
					{
						"type": "PROPS_CHECKBOX",
						"title": "Visible",
						"paramType": "RECIPE",
						"recipeParamPath": "HEADING.AUTHOR.VISIBLE",
						"recipeParamDefaultValue": true,
						"checkedValue": true,
						"unCheckedValue": false,
						"invertValue": false,
						"canEdit": "ITEM_OWNER"

					},
					{
						type: 'SUBHEADING',
						title: 'Font',
						addTopMargin: true
					},
					{
						type: 'INFOHEADING',
						title: 'All pages',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Font Color',
						addTopMargin: false
					},

					{
						type: 'PROPS_COLOR_PICKER',
						canEdit: 'ITEM_OWNER',
						title: 'Font Color',
						disableSave: false,
						recipeParamPath: 'HEADING.AUTHOR.FONT.COLOR',
						hideLabel: false,
						devModeOnly: false
						//configType:'LOOKUP',
						//configType: 'STANDARD',

						//setValueSoure: false,
						// recipeParamName: 'defaultDNUContentTemplate',
						// templateSourceTag: 'TEMP_headingOverride',


					},
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Author Name Font',
						recipeParamPath: 'HEADING.AUTHOR.FONT.TYPE',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Type',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Author Name Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.AUTHOR.TEXT_TRANSFORM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Uppercase', value: 'UPPERCASE' },
							{ label: 'Small Caps', value: 'SMALL_CAPS' },
							{ label: 'Lowercase', value: 'LOWERCASE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},




					{
						type: 'SUBHEADING',
						title: 'Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.AUTHOR.TEXT_SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Emphasis',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Italic',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.AUTHOR.FONT.ITALIC',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bold',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.AUTHOR.FONT.BOLD',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Underlined',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.AUTHOR.FONT.UNDERLINE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Overlined',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.AUTHOR.FONT.OVERLINE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Background Color',
						addTopMargin: false
					},

					{
						type: 'PROPS_COLOR_PICKER',
						canEdit: 'ITEM_OWNER',
						title: 'Background Color',
						disableSave: false,
						recipeParamPath: 'HEADING.AUTHOR.BACKGROUND.COLOR',
						hideLabel: false,
						devModeOnly: false
						//configType:'LOOKUP',
						//configType: 'STANDARD',

						//setValueSoure: false,
						// recipeParamName: 'defaultDNUContentTemplate',
						// templateSourceTag: 'TEMP_headingOverride',


					}

				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			},
			{
				title: 'Numbering',
				elements: [
					{
						"type": "SUBHEADING",
						"title": "Visibility",
						"addTopMargin": false
					},
					{
						"type": "PROPS_CHECKBOX",
						"title": "Visible",
						"paramType": "RECIPE",
						"recipeParamPath": "HEADING.NUMBERING.VISIBLE",
						"recipeParamDefaultValue": true,
						"checkedValue": true,
						"unCheckedValue": false,
						"invertValue": false,
						"canEdit": "ITEM_OWNER"

					},
					{
						type: 'SUBHEADING',
						title: 'Location',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Numbering Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.NUMBERING.LOCATION',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Top', value: 'TOP' },
							{ label: 'Bottom', value: 'BOTTOM' },
							{ label: 'Left', value: 'LEFT' },
							{ label: 'Right', value: 'RIGHT' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Display Method',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.NUMBERING.DISPLAY.METHOD',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Roman', value: 'ROMAN' },
							{ label: 'Words', value: 'WORDS' },
							{ label: 'Numeric', value: 'NUMERIC' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Font',
						addTopMargin: true
					},
					{
						type: 'INFOHEADING',
						title: 'All pages',
						visibilityConditions: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Font Color',
						addTopMargin: false
					},

					{
						type: 'PROPS_COLOR_PICKER',
						canEdit: 'ITEM_OWNER',
						title: 'Font Color',
						disableSave: false,
						recipeParamPath: 'HEADING.NUMBERING.FONT.COLOR',
						hideLabel: false,
						devModeOnly: false
						//configType:'LOOKUP',
						//configType: 'STANDARD',

						//setValueSoure: false,
						// recipeParamName: 'defaultDNUContentTemplate',
						// templateSourceTag: 'TEMP_headingOverride',


					},
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Numbering Font',
						recipeParamPath: 'HEADING.NUMBERING.FONT.TYPE',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Type',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Numbering Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.NUMBERING.TEXT_TRANSFORM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Uppercase', value: 'UPPERCASE' },
							{ label: 'Small Caps', value: 'SMALL_CAPS' },
							{ label: 'Lowercase', value: 'LOWERCASE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.NUMBERING.TEXT_SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Emphasis',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Italic',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.NUMBERING.FONT.ITALIC',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bold',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.NUMBERING.FONT.BOLD',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Underlined',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.NUMBERING.FONT.UNDERLINE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Overlined',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'HEADING.NUMBERING.FONT.OVERLINE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Background Color',
						addTopMargin: false
					},

					{
						type: 'PROPS_COLOR_PICKER',
						canEdit: 'ITEM_OWNER',
						title: 'Background Color',
						disableSave: false,
						recipeParamPath: 'HEADING.NUMBERING.BACKGROUND.COLOR',
						hideLabel: false,
						devModeOnly: false
						//configType:'LOOKUP',
						//configType: 'STANDARD',

						//setValueSoure: false,
						// recipeParamName: 'defaultDNUContentTemplate',
						// templateSourceTag: 'TEMP_headingOverride',


					}

				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			},








			{
				title: 'Heading Image',
				elements: [
					{
						"type": "SUBHEADING",
						"title": "Visibility",
						"addTopMargin": false
					},
					{
						"type": "PROPS_CHECKBOX",
						"title": "Visible",
						"paramType": "RECIPE",
						"recipeParamPath": "HEADING.IMAGE.VISIBLE",
						"recipeParamDefaultValue": true,
						"checkedValue": true,
						"unCheckedValue": false,
						"invertValue": false,
						"canEdit": "ITEM_OWNER"

					},

					{
						type: 'SUBHEADING',
						title: 'Heading Image',
						addTopMargin: false
					},
					{

						type: 'PROPS_IMAGE_POPUP_SELECTOR',
						folder: 'public/headerThumbnails',
						fileSource: 'commonImages',
						level: 'public',
						recipeParamPath: 'HEADING.IMAGE.SRC',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						hideUploadControls: false,
						hideGalleryControls: false
						//bucketName: config.common_content_bucket_name,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.IMAGE.SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			},
			{
				title: 'Heading Title Images',
				elements: [
					{
						"type": "SUBHEADING",
						"title": "Visibility",
						"addTopMargin": false
					},
					{
						"type": "PROPS_CHECKBOX",
						"title": "Visible",
						"paramType": "RECIPE",
						"recipeParamPath": "HEADING.TITLE.IMAGE.VISIBLE",
						"recipeParamDefaultValue": true,
						"checkedValue": true,
						"unCheckedValue": false,
						"invertValue": false,
						"canEdit": "ITEM_OWNER"

					},
					{
						type: 'SUBHEADING',
						title: 'Title Image ( adjust image preview size by image useage--title images smaller then heading images etc )',
						addTopMargin: false
					},
					{

						type: 'PROPS_IMAGE_POPUP_SELECTOR',
						folder: 'public/headerThumbnails',
						fileSource: 'commonImages',
						level: 'public',
						recipeParamPath: 'HEADING.TITLE.IMAGE.SRC',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						hideUploadControls: false,
						hideGalleryControls: false
						//bucketName: config.common_content_bucket_name,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.TITLE.IMAGE.SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Placement',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'HEADING.TITLE.IMAGE.LOCATION',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Horizontal', value: 'HORIZONTAL' },
							{ label: 'Vertical', value: 'VERTICAL' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

				],
				sectionVisibilityConditions: [{
					visible: true,
					conditionType: 'HAS_HEADING'
				}],
				notVisibleHelperMessage: 'This is for content with headings'
			}

		],
		visibilityConditions: [{
			visible: true,
			conditionType: 'HAS_HEADING'
		}],
		notVisibleHelperMessage: 'This is for content with headings'

	},
	content: {

		sections: [
			{
				title: 'Numbering Visibility',
				elements: []
			},
			{
				title: 'Numbering Display Method',
				elements: []
			}

		]

	},
	text: {

		sections: [
			{
				title: 'First Paragraph',
				elements: [

					{
						type: 'INFOHEADING',
						title: 'Vellum has regular/first sentence small-caps/first line small-caps/dropcaps'
					},
					{
						type: 'SUBHEADING',
						title: 'Style',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					// {
					// 	type: 'TEMPLATE_SELECTOR',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: 'First paragraph',
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	recipeParamName: 'defaultChapterContentTemplate',
					// 	templateSourceTag: 'TEMP_chContent',
					// 	hideLabel: true

					// },
					{
						type: 'PROPS_TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: 'DO_NOT_USE',
						disableSave: false,
						//configType:'LOOKUP',
						configType: 'STANDARD',
						recipeParamPath: 'CONTENT.TEMPLATE.ID',
						doNotPersist: true,
						setValueSoure: false,
						//recipeParamName: 'defaultDNUContentTemplate',
						templateSourceTag: 'TEMP_chContent',
						hideLabel: false,
						devModeOnly: false

					},
					{
						type: 'SUBHEADING',
						title: 'Drop Cap Size',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},

					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.FIRSTPARAGRAPH.DROPCAP.SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: '2', value: 1 },
							{ label: '3', value: 2 },
							{ label: '4', value: 3 },
							{ label: '5', value: 4 }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					// {
					// 	type: 'RADIO_BUTTONS',
					// 	title: 'Title Text TypeDropcap Size',
					// 	paramType: 'RECIPE',
					// 	recipeParamName: 'global_paragraphAfterFirst_dropCapSize',
					// 	//paramItem:'BOXSETBOOK',
					// 	//entityFieldName:'recipeSource',
					// 	options: [
					// 		{ label: '2', value: '2' },
					// 		{ label: '3', value: '3' },
					// 		{ label: '4', value: '4' },
					// 		{ label: '5', value: '5' }
					// 	],
					// 	canEdit: 'ITEM_OWNER',
					// 	// visibilityConditions: [{
					// 	// 	visible: true,
					// 	// 	conditionType: 'RECIPE_PARAM_VALUE',
					// 	// 	operator: 'eq',
					// 	// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 	// 	recipeParamValue: 'common'
					// 	// }]
					// },
					{
						type: 'SUBHEADING',
						title: 'Drop Cap Font',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Title Font',
						recipeParamPath: 'CONTENT.FIRSTPARAGRAPH.DROPCAP.FONT',
						// recipeParamName: 'global_common_titleFont',
						// recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					}
				]
			},
			{
				title: 'Paragraph after first',
				elements: [
					// {
					// 	type: 'PARAGRAPH_AFTER_FIRST',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: 'Paragraph after first',
					// 	paramType: 'RECIPE',
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	isParagraphAfterFirstParam: true,
					// 	recipeParamName: 'global_paragraphAfterFirst_firstLineTextTransform',
					// 	recipeMediaTypeParamName: 'global_blockquote_symbolMediaType',
					// 	templateSourceTag: 'TEMP_chContent',
					// 	hideLabel: true
					// },

					{
						type: 'INFOHEADING',
						title: 'Vellum calls this "Paragraph after break" and have the same options of first paragraph ==> regular/first sentence small-caps/first line small-caps/dropcaps',
						visibilityConditionsx: [{
							visible: true,
							conditionType: 'RECIPE_PARAM_VALUE',
							operator: 'eq',
							recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
							recipeParamValue: 'common'
						}]
					},
					{
						type: 'SUBHEADING',
						title: 'Style',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: 'DO_NOT_USE',
						disableSave: false,
						//configType:'LOOKUP',
						configType: 'STANDARD',
						recipeParamPath: 'CONTENT.PARAAFTERFIRST.TEMPLATE.ID',
						doNotPersist: true,
						setValueSoure: false,
						//recipeParamName: 'defaultDNUContentTemplate',
						templateSourceTag: 'TEMP_chContent',
						hideLabel: false,
						devModeOnly: false

					},
					{

						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Paragraph After First',
						paramType: 'RECIPE',
						//paramItem: 'BOOK',
						bucketName: config.common_content_bucket_name,
						folder: 'public/paragraphAfterFirst',
						level: 'public',
						isParagraphAfterFirstParam: true,
						recipeParamName: 'global_paragraphAfterFirst_firstLineTextTransform',
						//recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						//hideUploadControls:true
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Drop Cap Size',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},

					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.PARAAFTERFIRST.DROPCAP.SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: '2', value: 1 },
							{ label: '3', value: 2 },
							{ label: '4', value: 3 },
							{ label: '5', value: 4 }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'RADIO_BUTTONS',
						title: 'Title Text TypeDropcap Size',
						paramType: 'RECIPE',
						recipeParamName: 'global_paragraphAfterFirst_dropCapSize',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: '2', value: '2' },
							{ label: '3', value: '3' },
							{ label: '4', value: '4' },
							{ label: '5', value: '5' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Drop Cap Font',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Title Font',
						recipeParamPath: 'CONTENT.PARAAFTERFIRST.DROPCAP.FONT',
						// recipeParamName: 'global_common_titleFont',
						// recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{

						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Drop Cap Font',
						paramType: 'RECIPE',
						paramItem: 'BOOK',
						bucketName: config.common_content_bucket_name,
						folder: 'public/fonts',
						level: 'public',
						isFontParam: true,
						recipeParamName: 'global_paragraphAfterFirst_dropCapFont',
						//recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},


				]
			},
			{
				title: 'Blockquote',
				elements: [
					// },
					{
						type: 'PROPS_TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						//title: 'DO_NOT_USE',
						disableSave: false,
						//configType:'LOOKUP',
						configType: 'STANDARD',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.TEMPLATE.ID',
						doNotPersist: true,
						setValueSoure: false,
						//recipeParamName: 'defaultDNUContentTemplate',
						templateSourceTag: 'TEMP_blockQuote',
						hideLabel: false,
						devModeOnly: false

					},
					// {
					// 	type: 'TEMPLATE_SELECTOR',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: '',
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	recipeParamName: 'defaultBlockQuoteTemplate',
					// 	templateSourceTag: 'TEMP_blockQuote',
					// 	hideLabel: true

					// },
					{
						type: 'SUBHEADING',
						title: 'Alignment',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.ALIGNMENT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Left', value: 'LEFT' },
							{ label: 'Center', value: 'CENTER' },
							{ label: 'Right', value: 'RIGHT' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Border Visibility',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Top Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.BORDER.TOP.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bottom Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.BORDER.BOTTOM.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Left Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.BORDER.LEFT.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Right Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.BORDER.RIGHT.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Border Thickness',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.BORDER.THICKNESS',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Thin', value: 'THIN' },
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Thick', value: 'THICK' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.MARGIN.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.MARGIN.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left Margin',
						addTopMargin: false,
						recipeParamPath: 'CONTENT.BLOCKQUOTE.MARGIN.LEFT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.MARGIN.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right Margin',
						addTopMargin: false,
						recipeParamPath: 'CONTENT.BLOCKQUOTE.MARGIN.RIGHT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.MARGIN.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.PADDING.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.PADDING.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.PADDING.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.PADDING.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Quote Visibility',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Open Quote Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.OPENQUOTE.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Close Quote Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.CLOSEQUOTE.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Blockquote Symbol',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.SYMBOL.TYPE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Text', value: 'TEXT' },
							{ label: 'Image', value: 'IMAGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{

						type: 'PROPS_IMAGE_POPUP_SELECTOR',
						folder: 'public/blockQuotes',
						fileSource: 'commonImages',
						level: 'public',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.SYMBOL.SRC',
						// recipeParamName: 'global_common_titleFont',
						// recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						hideUploadControls: true,
						hideGalleryControls: false
						//bucketName: config.common_content_bucket_name,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Quote Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.SYMBOL.SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Font',
						addTopMargin: true
					},
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Title Font',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.FONT.TYPE',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Type',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.TEXT_TRANSFORM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Uppercase', value: 'UPPERCASE' },
							{ label: 'Small Caps', value: 'SMALL_CAPS' },
							{ label: 'Lowercase', value: 'LOWERCASE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.TEXT_SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Emphasis',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Italics',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.FONT.ITALIC',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bold',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.BLOCKQUOTE.FONT.BOLD',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},

				]
			},
			{
				title: 'Attribution',
				elements: [
					{
						type: 'PROPS_TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						//title: 'DO_NOT_USE',
						disableSave: false,
						//configType:'LOOKUP',
						configType: 'STANDARD',
						recipeParamPath: 'CONTENT.ATTRIBUTION.TEMPLATE.ID',
						doNotPersist: true,
						setValueSoure: false,
						//recipeParamName: 'defaultDNUContentTemplate',
						templateSourceTag: 'TEMP_attribution',
						hideLabel: false,
						devModeOnly: false

					},
					// {
					// 	type: 'TEMPLATE_SELECTOR',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: '',
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	recipeParamName: 'defaultAttributionTemplate',
					// 	templateSourceTag: 'TEMP_attribution',
					// 	hideLabel: true

					// },
					{
						type: 'SUBHEADING',
						title: 'Alignment',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.ATTRIBUTION.ALIGNMENT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Left', value: 'LEFT' },
							{ label: 'Center', value: 'CENTER' },
							{ label: 'Right', value: 'RIGHT' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Border Visibility',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Top Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.ATTRIBUTION.BORDER.TOP.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bottom Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.ATTRIBUTION.BORDER.BOTTOM.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Left Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.ATTRIBUTION.BORDER.LEFT.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Right Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.ATTRIBUTION.BORDER.RIGHT.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Border Thickness',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.ATTRIBUTION.BORDER.THICKNESS',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Thin', value: 'THIN' },
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Thick', value: 'THICK' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.ATTRIBUTION.MARGIN.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.ATTRIBUTION.MARGIN.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left Margin',
						addTopMargin: false,
						recipeParamPath: 'CONTENT.ATTRIBUTION.MARGIN.LEFT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.ATTRIBUTION.MARGIN.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right Margin',
						addTopMargin: false,
						recipeParamPath: 'CONTENT.ATTRIBUTION.MARGIN.RIGHT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.ATTRIBUTION.MARGIN.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.ATTRIBUTION.PADDING.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.ATTRIBUTION.PADDING.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.ATTRIBUTION.PADDING.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.ATTRIBUTION.PADDING.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Prefix Visibility',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.ATTRIBUTION.PREFIX.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Font',
						addTopMargin: true
					},
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Title Font',
						recipeParamPath: 'CONTENT.ATTRIBUTION.FONT.TYPE',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Type',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.ATTRIBUTION.TEXT_TRANSFORM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Uppercase', value: 'UPPERCASE' },
							{ label: 'Small Caps', value: 'SMALL_CAPS' },
							{ label: 'Lowercase', value: 'LOWERCASE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.ATTRIBUTION.TEXT_SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Emphasis',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Italic',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.ATTRIBUTION.FONT.ITALIC',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bold',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.ATTRIBUTION.FONT.BOLD',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
				]
			},
			{
				title: 'Subhead',
				elements: [
					{
						type: 'PROPS_TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						//title: 'DO_NOT_USE',
						disableSave: false,
						//configType:'LOOKUP',
						configType: 'STANDARD',
						recipeParamPath: 'CONTENT.SUBHEAD.TEMPLATE.ID',
						doNotPersist: true,
						setValueSoure: false,
						//recipeParamName: 'defaultDNUContentTemplate',
						templateSourceTag: 'TEMP_subhead',
						hideLabel: false,
						devModeOnly: false

					},
					// {
					// 	type: 'TEMPLATE_SELECTOR',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: '',
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	recipeParamName: 'defaultSubheadTemplate',
					// 	templateSourceTag: 'TEMP_subhead',
					// 	hideLabel: true

					// },
					{
						type: 'SUBHEADING',
						title: 'Alignment',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.SUBHEAD.ALIGNMENT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Left', value: 'LEFT' },
							{ label: 'Center', value: 'CENTER' },
							{ label: 'Right', value: 'RIGHT' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Border Visibility',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Top Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.SUBHEAD.BORDER.TOP.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bottom Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.SUBHEAD.BORDER.BOTTOM.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Left Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.SUBHEAD.BORDER.LEFT.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Right Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.SUBHEAD.BORDER.RIGHT.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Border Thickness',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.SUBHEAD.BORDER.THICKNESS',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Thin', value: 'THIN' },
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Thick', value: 'THICK' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.SUBHEAD.MARGIN.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.SUBHEAD.MARGIN.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left Margin',
						addTopMargin: false,
						recipeParamPath: 'CONTENT.SUBHEAD.MARGIN.LEFT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.SUBHEAD.MARGIN.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right Margin',
						addTopMargin: false,
						recipeParamPath: 'CONTENT.SUBHEAD.MARGIN.RIGHT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.SUBHEAD.MARGIN.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.SUBHEAD.PADDING.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.SUBHEAD.PADDING.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.SUBHEAD.PADDING.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.SUBHEAD.PADDING.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Font',
						addTopMargin: true
					},
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Title Font',
						recipeParamPath: 'CONTENT.SUBHEAD.FONT.TYPE',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					// {
					// 	type: 'SUBHEADING',
					// 	title: 'Font Family',
					// 	addTopMargin: false,
					// 	// showRecipeSourceContext: true,
					// 	// showHeadingSourceContext: true
					// },
					// {
					// 	type: 'PROPS_RADIO_BUTTONS',
					// 	title: 'Title Text Type',
					// 	paramType: 'RECIPE',
					// 	recipeParamPath: 'CONTENT.SUBHEAD.FONT.FAMILY',
					// 	//recipeParamName: 'global_common_headingTitleTextTransform',
					// 	//paramItem:'BOXSETBOOK',
					// 	//entityFieldName:'recipeSource',
					// 	options: [
					// 		{ label: 'Serif', value: 'SERIF' },
					// 		{ label: 'Sans Serif', value: 'SANS_SERIF' },
					// 		{ label: 'Monospace', value: 'MONOSPACE' }
					// 	],
					// 	canEdit: 'ITEM_OWNER',
					// 	// visibilityConditions: [{
					// 	// 	visible: true,
					// 	// 	conditionType: 'RECIPE_PARAM_VALUE',
					// 	// 	operator: 'eq',
					// 	// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
					// 	// 	recipeParamValue: 'common'
					// 	// }]
					// },
					{
						type: 'SUBHEADING',
						title: 'Type',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.SUBHEAD.FONT.TEXT_TRANSFORM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Uppercase', value: 'UPPERCASE' },
							{ label: 'Small Caps', value: 'SMALL_CAPS' },
							{ label: 'Lowercase', value: 'LOWERCASE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Size',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.SUBHEAD.FONT.SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Emphasis',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Italics',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.SUBHEAD.FONT.ITALIC',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bold',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.SUBHEAD.FONT.BOLD',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
				]
			},
			{
				title: 'Verse',
				elements: [
					{
						type: 'PROPS_TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						//title: 'DO_NOT_USE',
						disableSave: false,
						//configType:'LOOKUP',
						configType: 'STANDARD',
						recipeParamPath: 'CONTENT.VERSE.TEMPLATE.ID',
						doNotPersist: true,
						setValueSoure: false,
						//recipeParamName: 'defaultDNUContentTemplate',
						templateSourceTag: 'TEMP_verse',
						hideLabel: false,
						devModeOnly: false

					},
					// {
					// 	type: 'TEMPLATE_SELECTOR',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: '',
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	recipeParamName: 'defaultVerseTemplate',
					// 	templateSourceTag: 'TEMP_verse',
					// 	hideLabel: true

					// },
					{
						type: 'SUBHEADING',
						title: 'Alignment',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.VERSE.ALIGNMENT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Left', value: 'LEFT' },
							{ label: 'Center', value: 'CENTER' },
							{ label: 'Right', value: 'RIGHT' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Border Visibility',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Top Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.VERSE.BORDER.TOP.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bottom Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.VERSE.BORDER.BOTTOM.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Left Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.VERSE.BORDER.LEFT.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Right Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.VERSE.BORDER.RIGHT.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Border Thickness',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.VERSE.BORDER.THICKNESS',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Thin', value: 'THIN' },
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Thick', value: 'THICK' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.VERSE.MARGIN.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.VERSE.MARGIN.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left Margin',
						addTopMargin: false,
						recipeParamPath: 'CONTENT.VERSE.MARGIN.LEFT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.VERSE.MARGIN.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right Margin',
						addTopMargin: false,
						recipeParamPath: 'CONTENT.VERSE.MARGIN.RIGHT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.VERSE.MARGIN.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.VERSE.PADDING.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.VERSE.PADDING.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.VERSE.PADDING.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.VERSE.PADDING.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Indentation',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Indented',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.VERSE.FONT.INDENTATION',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Font',
						addTopMargin: true
					},
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Title Font',
						recipeParamPath: 'CONTENT.VERSE.FONT.TYPE',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Type',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.VERSE.FONT.TEXT_TRANSFORM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Uppercase', value: 'UPPERCASE' },
							{ label: 'Small Caps', value: 'SMALL_CAPS' },
							{ label: 'Lowercase', value: 'LOWERCASE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Size',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'CONTENT.VERSE.FONT.SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Emphasis',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Italics',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.VERSE.FONT.ITALIC',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bold',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'CONTENT.VERSE.FONT.BOLD',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
				]
			},
			{
				title: 'Links',
				elements: [

				]
			}

		]
	},
	breaks: {

		sections: [
			{
				title: 'Decorated Breaks',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: 'First paragraph',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultOrnamentalBreaksTemplate',
						templateSourceTag: 'TEMP_ornBreak',
						hideLabel: true,
						devModeOnly: true

					},
					{
						type: 'IMAGE_POPUP_SELECTOR',
						title: 'Decorated Break',
						paramType: 'RECIPE',
						paramItem: 'BOOK',
						bucketName: config.common_content_bucket_name,
						folder: 'public/decoratedBreaks',
						level: 'public',
						recipeParamName: 'global_ornamentalBreakImageId',
						recipeMediaTypeParamName: 'global_ornamentalBreakImageMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER'
					},


					{
						type: 'CHECKBOX',
						title: 'Show scene decorated breaks',
						paramType: 'RECIPE',
						recipeParamName: 'global_showOrnamentalBreaks',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Show manual decorated breaks',
						paramType: 'RECIPE',
						recipeParamName: 'global_showOrnamentalBreaks',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					}
				]
			},

		]
	},
	images: {

		sections: [
			{
				title: 'Other Image Stuff Here',
				elements: []
			}

		]
	},
	blots: {
		sections: [
			{
				title: 'Blurbs',
				elements: [
					// {
					// 	type: 'TEMPLATE_SELECTOR',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: '',
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	recipeParamName: 'defaultBlurbsTemplate',
					// 	templateSourceTag: 'TEMP_blurbs',
					// 	hideLabel: true

					// },
					{
						type: 'PROPS_TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						//title: 'DO_NOT_USE',
						disableSave: false,
						//configType:'LOOKUP',
						configType: 'STANDARD',
						recipeParamPath: 'BLOTS.BLURBS.TEMPLATE.ID',
						doNotPersist: true,
						setValueSoure: false,
						//recipeParamName: 'defaultDNUContentTemplate',
						templateSourceTag: 'TEMP_blurbs',
						hideLabel: false,
						devModeOnly: false

					},
					{
						type: 'SUBHEADING',
						title: 'Border Visibility',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Top Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.BLURBS.BORDER.TOP.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bottom Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.BLURBS.BORDER.BOTTOM.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Left Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.BLURBS.BORDER.LEFT.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Right Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.BLURBS.BORDER.RIGHT.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Border Thickness',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.BORDER.THICKNESS',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Thin', value: 'THIN' },
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Thick', value: 'THICK' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.MARGIN.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.MARGIN.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left Margin',
						addTopMargin: false,
						recipeParamPath: 'BLOTS.BLURBS.MARGIN.LEFT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.MARGIN.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right Margin',
						addTopMargin: false,
						recipeParamPath: 'BLOTS.BLURBS.MARGIN.RIGHT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.MARGIN.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.PADDING.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.PADDING.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.PADDING.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right Padding',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.PADDING.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Quote Alignment',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.CONTENT.ALIGNMENT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Left', value: 'LEFT' },
							{ label: 'Center', value: 'CENTER' },
							{ label: 'Right', value: 'RIGHT' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Quote Visibility',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Open Quote Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.BLURBS.OPENQUOTE.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Close Quote Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.BLURBS.CLOSEQUOTE.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Quote Font',
						addTopMargin: true
					},
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Title Font',
						recipeParamPath: 'BLOTS.BLURBS.CONTENT.FONT.TYPE',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Quote Text Type',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.CONTENT.TEXT_TRANSFORM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Uppercase', value: 'UPPERCASE' },
							{ label: 'Small Caps', value: 'SMALL_CAPS' },
							{ label: 'Lowercase', value: 'LOWERCASE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Quote Text Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.CONTENT.TEXT_SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Quote Text Emphasis',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Italic',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.BLURBS.CONTENT.FONT.ITALIC',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bold',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.BLURBS.CONTENT.FONT.BOLD',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Attribution Alignment',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.SOURCE.ALIGNMENT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Left', value: 'LEFT' },
							{ label: 'Center', value: 'CENTER' },
							{ label: 'Right', value: 'RIGHT' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Attribution Prefix',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.BLURBS.SOURCE.PREFIX',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Attribution Font',
						addTopMargin: true
					},
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Title Font',
						recipeParamPath: 'BLOTS.BLURBS.SOURCE.FONT.TYPE',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Attribution Text Type',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.SOURCE.TEXT_TRANSFORM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Uppercase', value: 'UPPERCASE' },
							{ label: 'Small Caps', value: 'SMALL_CAPS' },
							{ label: 'Lowercase', value: 'LOWERCASE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Attribution Text Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.BLURBS.SOURCE.TEXT_SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Attribution Text Emphasis',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Italic',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.BLURBS.SOURCE.FONT.ITALIC',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bold',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.BLURBS.SOURCE.FONT.BOLD',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
				]
			},
			{
				title: 'Also Bys',
				elements: [
					// {
					// 	type: 'TEMPLATE_SELECTOR',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: '',
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	recipeParamName: 'defaultAlsobysTemplate',
					// 	templateSourceTag: 'TEMP_alsobys',
					// 	hideLabel: true

					// },
					{
						type: 'PROPS_TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						//title: 'DO_NOT_USE',
						disableSave: false,
						//configType:'LOOKUP',
						configType: 'STANDARD',
						recipeParamPath: 'BLOTS.ALSOBYS.TEMPLATE.ID',
						doNotPersist: true,
						setValueSoure: false,
						//recipeParamName: 'defaultDNUContentTemplate',
						templateSourceTag: 'TEMP_alsobys',
						hideLabel: false,
						devModeOnly: false

					},
					{
						type: 'SUBHEADING',
						title: 'Text Emphasis',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Italic',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.ALSOBYS.FONT.ITALIC',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bold',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.ALSOBYS.FONT.BOLD',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
				]
			},
			{
				title: 'Publisher',
				elements: [
					// {
					// 	type: 'TEMPLATE_SELECTOR',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: '',
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	recipeParamName: 'defaultPublisherTemplate',
					// 	templateSourceTag: 'TEMP_publisher',
					// 	hideLabel: true
					// },
					{
						type: 'PROPS_TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						//title: 'DO_NOT_USE',
						disableSave: false,
						//configType:'LOOKUP',
						configType: 'STANDARD',
						recipeParamPath: 'BLOTS.PUBLISHER.TEMPLATE.ID',
						doNotPersist: true,
						setValueSoure: false,
						//recipeParamName: 'defaultDNUContentTemplate',
						templateSourceTag: 'TEMP_publisher',
						hideLabel: false,
						devModeOnly: false

					},
					{
						type: 'SUBHEADING',
						title: 'Top Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.PUBLISHER.MARGIN.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.PUBLISHER.MARGIN.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left Margin',
						addTopMargin: false,
						recipeParamPath: 'BLOTS.PUBLISHER.MARGIN.LEFT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.PUBLISHER.MARGIN.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right Margin',
						addTopMargin: false,
						recipeParamPath: 'BLOTS.PUBLISHER.MARGIN.RIGHT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.PUBLISHER.MARGIN.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Font',
						addTopMargin: true
					},
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Title Font',
						recipeParamPath: 'BLOTS.PUBLISHER.FONT.TYPE',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Type',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.PUBLISHER.FONT.TEXT_TRANSFORM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Uppercase', value: 'UPPERCASE' },
							{ label: 'Small Caps', value: 'SMALL_CAPS' },
							{ label: 'Lowercase', value: 'LOWERCASE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.PUBLISHER.TEXT_SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Emphasis',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Italics',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.PUBLISHER.FONT.ITALIC',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bold',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.PUBLISHER.FONT.BOLD',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Image Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.PUBLISHER.IMAGE.SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
				]
			},
			{
				title: 'Contributors',
				elements: [
					// {
					// 	type: 'TEMPLATE_SELECTOR',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: '',
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	recipeParamName: 'defaultContributorsTemplate',
					// 	templateSourceTag: 'TEMP_contributors',
					// 	hideLabel: true

					// },
					{
						type: 'PROPS_TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						//title: 'DO_NOT_USE',
						disableSave: false,
						//configType:'LOOKUP',
						configType: 'STANDARD',
						recipeParamPath: 'BLOTS.CONTRIBUTOR.TEMPLATE.ID',
						doNotPersist: true,
						setValueSoure: false,
						//recipeParamName: 'defaultDNUContentTemplate',
						templateSourceTag: 'TEMP_contributors',
						hideLabel: false,
						devModeOnly: false

					},
					{
						type: 'SUBHEADING',
						title: 'Border Visibility',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bottom Visible',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.CONTRIBUTOR.BORDER.BOTTOM.VISIBLE',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'SUBHEADING',
						title: 'Top Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.CONTRIBUTOR.MARGIN.TOP',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Bottom Margin',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.CONTRIBUTOR.MARGIN.BOTTOM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Left Margin',
						addTopMargin: false,
						recipeParamPath: 'BLOTS.CONTRIBUTOR.MARGIN.LEFT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.CONTRIBUTOR.MARGIN.LEFT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},

					{
						type: 'SUBHEADING',
						title: 'Right Margin',
						addTopMargin: false,
						recipeParamPath: 'BLOTS.CONTRIBUTOR.MARGIN.RIGHT',
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.CONTRIBUTOR.MARGIN.RIGHT',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'None', value: 'NONE' },
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Font',
						addTopMargin: true
					},
					{

						type: 'PROPS_FONT_SELECTOR',
						title: 'Title Font',
						recipeParamPath: 'BLOTS.CONTRIBUTOR.FONT.TYPE',
						recipeParamName: 'global_common_titleFont',
						recipeMediaTypeParamName: 'global_common_titleFontMediaType',
						identitySource: 'ITEM_OWNER',
						canEdit: 'ITEM_OWNER',
						// paramType: 'RECIPE',
						// paramItem: 'BOOK',
						//bucketName: config.common_content_bucket_name,
						//folder: 'public/fonts',
						//level: 'public',
						//isFontParam: true,
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }],
					},
					{
						type: 'SUBHEADING',
						title: 'Type',
						addTopMargin: false,
						// showRecipeSourceContext: true,
						// showHeadingSourceContext: true
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						title: 'Title Text Type',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.CONTRIBUTOR.TEXT_TRANSFORM',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Normal', value: 'NORMAL' },
							{ label: 'Uppercase', value: 'UPPERCASE' },
							{ label: 'Small Caps', value: 'SMALL_CAPS' },
							{ label: 'Lowercase', value: 'LOWERCASE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Size',
						addTopMargin: false
					},
					{
						type: 'PROPS_RADIO_BUTTONS',
						paramType: 'RECIPE',
						recipeParamPath: 'BLOTS.CONTRIBUTOR.TEXT_SIZE',
						//recipeParamName: 'global_common_headingTitleTextTransform',
						//paramItem:'BOXSETBOOK',
						//entityFieldName:'recipeSource',
						options: [
							{ label: 'Small', value: 'SMALL' },
							{ label: 'Medium', value: 'MEDIUM' },
							{ label: 'Large', value: 'LARGE' }
						],
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [{
						// 	visible: true,
						// 	conditionType: 'RECIPE_PARAM_VALUE',
						// 	operator: 'eq',
						// 	recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 	recipeParamValue: 'common'
						// }]
					},
					{
						type: 'SUBHEADING',
						title: 'Emphasis',
						addTopMargin: false
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Italic',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.CONTRIBUTOR.FONT.ITALIC',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
					{
						type: 'PROPS_CHECKBOX',
						title: 'Bold',
						paramType: 'RECIPE',
						//recipeParamName: 'global_hideAllCommonTitles',
						//recipeParamName: 'global_common_hideAllHeadingTitles',
						recipeParamPath: 'BLOTS.CONTRIBUTOR.FONT.BOLD',
						checkedValue: true,
						unCheckedValue: false,
						invertValue: false,
						canEdit: 'ITEM_OWNER',
						// visibilityConditions: [
						// 	{
						// 		visible: true,
						// 		conditionType: 'RECIPE_PARAM_VALUE',
						// 		operator: 'eq',
						// 		recipeParamName: 'global_headerTemplateSourceFor---PART_TYPE---s',
						// 		recipeParamValue: 'common'
						// 	}
						// ]
					},
				]
			},
			{
				title: 'Contents',
				elements: [
					// {
					// 	type: 'TEMPLATE_SELECTOR',
					// 	canEdit: 'ITEM_OWNER',
					// 	title: '',
					// 	//configType:'LOOKUP',
					// 	configType: 'STANDARD',
					// 	//configDetails:'HEADER_BY_PART_TYPE',
					// 	recipeParamName: 'defaultTocTemplate',
					// 	templateSourceTag: 'TEMP_toc',
					// 	hideLabel: true

					// },
					{
						type: 'PROPS_TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						//title: 'DO_NOT_USE',
						disableSave: false,
						//configType:'LOOKUP',
						configType: 'STANDARD',
						recipeParamPath: 'BLOTS.CONTENTS.TEMPLATE.ID',
						doNotPersist: true,
						setValueSoure: false,
						//recipeParamName: 'defaultDNUContentTemplate',
						templateSourceTag: 'TEMP_toc',
						hideLabel: false,
						devModeOnly: false

					},
				]
			},
			{
				title: 'Social Media',
				elements: [
					{
						type: 'TEMPLATE_SELECTOR',
						canEdit: 'ITEM_OWNER',
						title: '',
						//configType:'LOOKUP',
						configType: 'STANDARD',
						//configDetails:'HEADER_BY_PART_TYPE',
						recipeParamName: 'defaultSocialSheetTemplate',
						templateSourceTag: 'TEMP_socialSheet',
						hideLabel: true

					},
					{
						type: 'CHECKBOX',
						title: 'Include Facebook',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeFacebook',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Twitter',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeTwitter',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Instagram',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeInstagram',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Youtube',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeYoutube',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Snapchat',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeSnapchat',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Pinterest',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includePinterest',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Amazon',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeAmazon',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Bookbub',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeBookbub',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					},
					{
						type: 'CHECKBOX',
						title: 'Include Goodreads',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'includeGoodreads',
						checkedValue: 'true',
						unCheckedValue: 'false',
						invertValue: false,
						canEdit: 'ITEM_OWNER'
					}

				]
			}

		]
	},
	styles: {

	}
}

export default layout;
//module.exports.layout = layout;