import React, { useState, useEffect } from 'react';
import { observable, action, computed, runInAction, extendObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { BuildContext } from './TemplateManager';
import { BuildManager } from './BuildManager';
import { useUpdateItem } from '../../persistance/persistanceHooks';
import SaveIcon from '@mui/icons-material/Save';

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { Box } from '@mui/system';
import FormatTextfield from './propertyElements/FormatTextfield';

//let beautify_js = require('js-beautify');
// require('codemirror/theme/material.css');
// require('codemirror/theme/neat.css');
// require('codemirror/mode/xml/xml.js');
// require('codemirror/mode/javascript/javascript.js');
// require('./codemirror-override.css');
//let entityManager = new EntityManager();
//import prettier from 'prettier';

const TemplateEditor = (props) => {

  const [templateContent, setTemplateContent] = useState();
  const [parseError, setParseError] = useState();
  const [editor, setEditor] = useState();
  const [saveTime, setSaveTime] = useState(-1);
  const [hasPendingUpdates, setHasPendingUpdates] = useState(false);



  const [updateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] = useUpdateItem();

  let content = '';
  let templatePreviewContent = '';
  let that = this;

  let bm = new BuildManager();

  let template = {};

  let inflatablesBookDraft = props.stores.bookStore.inflatablesBookDraft;

  //let templateBddp = inflatablesBookDraft?.getBookDraftDocumentPartByPartId(props.templateId);
  let { templateBddp } = props;

  if (!templateBddp) {
    //This is most likely the styles template that is not loaded with a bddp. 
    templateBddp = inflatablesBookDraft?.getBookDraftDocumentPartByPartId(props.templateId);
  }
  /*if(props.stores.bookStore.currentEditedTemplateId){
    template = props.stores.bookStore.currentInflatedTemplates[props.stores.bookStore.currentEditedTemplateId];
  }*/
  //let templateContent = 'NO CONTENT';

  const { classes, theme } = props;



  useEffect(() => {

    //let inflatablesBookDraft = props.stores.bookStore.inflatablesBookDraft;

    //let templateBddp = inflatablesBookDraft.getBookDraftDocumentPartByPartId(props.templateId);


    let content = templateBddp?.documentPart?.content;

    setTemplateContent(content);


  }, []);






  const updateContent = (newValue) => {
    //testContent(newValue);

    ////console.log(newValue);
    setParseError("");

    // try {

    // 	JSON.parse(newValue.replace(/\n/ig, '').replace(/\t/ig, ''));

    // } catch (err) {
    // 	//alert(err);
    // 	////console.log(err);
    // 	setState({
    // 		parseError: err.message
    // 	});
    // 	//return;
    // }




    //let inflatablesBookDraft = props.stores.bookStore.inflatablesBookDraft;

    //let templateBddp = inflatablesBookDraft.getBookDraftDocumentPartByPartId(props.templateId);
    templateBddp.documentPart.content = newValue;


    //templateObject.content = newValue;





    try {

      let templateObject = props.stores.bookStore.currentInflatedTemplates[props.templateId];

      let cleansed = newValue.replace(/\n/ig, '').replace(/\t/ig, '');
      let parsed = JSON.parse(cleansed);

      templateObject = observable(parsed);

      props.stores.bookStore.currentInflatedTemplates[props.templateId] = templateObject;



      //setTemplateContent(newValue);


      //props.stores.bookStore.recipeTimestamp = new Date().getTime();

    } catch (err) {
      ////console.log(err);
    }


    setTemplateContent(newValue);

    //props.stores.bookStore.recipeTimestamp = new Date().getTime();

    setSaveTime(Date.now() + 2000);



    return;




  }


  const doUpdatePreview = () => {
    let now = Date.now();
    let that = this;
    //////console.log('doSave():',now-this.state.saveTime);
    if (saveTime == -1) {
      return;
    }
    else if (now > saveTime) {

      setSaveTime(-1);
      setHasPendingUpdates(false);

      props.stores.bookStore.recipeTimestamp = new Date().getTime();


    } else {
      setTimeout(function () {
        doUpdatePreview()
      }
        , 100)
    }
  }


  useEffect(() => {

    if (saveTime > 0 && !hasPendingUpdates) {
      doUpdatePreview();
      setHasPendingUpdates(true);
    } else if (saveTime > 0) {

    }


  }, [saveTime]);

  const handleChange = name => event => {

    //console.log(event);
    return;
    let inflatablesBookDraft = props.stores.bookStore.inflatablesBookDraft;

    let templateBddp = inflatablesBookDraft.getBookDraftDocumentPartByPartId(props.templateId);


    let content = templateBddp.documentPart.content;

    setTemplateContent(content);


  }




  // handleChangePreviewTemplate = name => event => {

  // 	if (templatePreviewContent) {
  // 		templatePreviewContent.content = event.target.value;
  // 	}

  // 	if (props.stores.bookStore.currentEditedTemplateId) {

  // 		let template = props.stores.bookStore.currentInflatedTemplates[props.stores.bookStore.currentEditedTemplateId];
  // 		template.previewContent = event.target.value;
  // 		props.stores.bookStore.recipeTimestamp = new Date().getTime();
  // 	}



  // };

  const saveContent = () => {

    //console.log('save content...')
    let inflatablesBookDraft = props.stores.bookStore.inflatablesBookDraft;

    // let templateBddp = inflatablesBookDraft.getDocumentPartById(props.templateId);

    // let item = templateBddp.documentPart;
    // let modelValues = item.getKeys();
    // modelValues.content = templateBddp.documentPart.content;




    //let templateBddp = inflatablesBookDraft.getBookDraftDocumentPartByPartId(props.templateId);

    updateItem({
      itemExpectedVersion: 1,
      itemKey: props.templateId,
      //itemSortKeyName: 'documentPartId',
      //itemSortKeyValue: props.userComment.documentPartId,
      itemType: 'DocumentPart',
      fieldName: 'content',
      fieldValue: templateBddp.documentPart.content,
      onCompleted: () => {
        //console.log('save callback...')
        //props.userComment.isPinned = !props.userComment.isPinned
      }
    })






  }





  const updateEntity = ({
    item,
    fieldName
  }) => {

    let itemKey = item.id;
    if (item.modelType == 'BoxSetBook') {
      itemKey = item.boxSetId + ':::' + item.bookId
    }

    updateItem({
      itemExpectedVersion: item.version,
      itemKey: itemKey,
      itemType: item.modelType,
      fieldName: fieldName,
      fieldValue: item[fieldName]
    })


  }


  return (
    <>
      {/* <div>yo:{templateBddp?.documentPart.title}</div> */}
      <Box sx={{
        width: '100%',
        //border:'1px solid red',
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <FormatTextfield
          bookDraft={null}
          documentPart={templateBddp?.documentPart}
          element={{
						type: 'TEXTFIELD',
						//title: 'Title',
						paramType: 'ENTITY',
						paramItem: 'DOCUMENTPART',
						entityFieldName: 'title',
						label: 'Title',
						placeholder: 'Title',
						canEdit: 'ITEM_OWNER'
					}}
          canEdit={true}
          //onUpdateRecipe={onUpdateRecipe}
          onUpdateEntity={updateEntity}
          />

        <span>{props.templateId}</span><SaveIcon color={'disabled'} onClick={() => {
          saveContent();
        }} /> </Box>
      <AceEditor
        mode="java"
        theme="github"
        width={'100%'}
        height={'80%'}
        fontSize={17}
        onChange={(value, event) => {
          ////console.log(value);
          updateContent(value);
          //template.content = value;
        }}
        name="UNIQUE_ID_OF_DIV"
        value={templateContent}
        editorProps={{ $blockScrolling: true }}
      />
    </>

  )


}

export default inject('stores')(observer(TemplateEditor));