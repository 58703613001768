import React, { useEffect, useInsertionEffect, useState } from 'react';
import { styled, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import BaseControl from "./BaseControl";
import { useTheme } from '@mui/material/styles';
import { Box, Grid } from "@mui/material";
import { v4 as uuid } from "uuid";
import config from '../../../aws-exports';
import StandardDialog from '../../StandardDialog';
import S3FilesGallery from './S3FilesGallery';
import StandardImageViewer from '../../StandardImageViewer';
import UploadIcon from '@mui/icons-material/Upload';
import CollectionsIcon from '@mui/icons-material/Collections';
import { useDropzone } from 'react-dropzone';
import helpers from '../../../helpers/helpers';
import StorageManager, { useUploadBucketItem } from '../../../managers/StorageManager';

function FormatImageSelector(props) {

  let user = props.stores.userStore.user;
  const { classes, element, bookDraft, documentPart } = props;
  let baseControl = new BaseControl(props);

  const [showGallery, setShowGallery] = useState(false);
  const [s3Path, setS3Path] = useState(null);
  const [bucketName, setBucketName] = useState(null);
  const [changeTimestamp, setChangeTimestamp] = useState(Date.now());



  const theme = useTheme();

  let { uploadBucketItem } = useUploadBucketItem();

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    maxSize: 5000000
  });


  const calculateS3Path = (value) => {

    if (element.isFontParam) {

      return 'public/fonts/' + value + '.png';


    }
    if (element.isParagraphAfterFirstParam) {

      return 'public/paragraphAfterFirst/' + value + '.png';


    }


    return value;
  }

  const calculateBucketName = (fileSource) => {

    let workingBucketName = config.common_content_bucket_name;

    if (element.bucketName) {

      workingBucketName = element.bucketName;

    } else {

      if (fileSource && fileSource == 'userImages') {
        workingBucketName = config.user_uploads_bucket_name;
      }

    }



    return workingBucketName;


  }

  const convertParamValueToS3Key = (value, fileSource) => {

    if (element.isFontParam) {

      //return convertUrl('public/fonts/' + value + '.png', fileSource);
      setBucketName(config.common_content_bucket_name);
      return 'public/fonts/' + value + '.png';
    }
    else if (element.isParagraphAfterFirstParam) {

      //return convertUrl('public/paragraphAfterFirst/' + value + '.png', fileSource);
      setBucketName(config.common_content_bucket_name);
      return 'public/paragraphAfterFirst/' + value + '.png';

    }
    else {

      return value;
    }


  }

  useEffect(() => {

    if (element.paramType == 'ENTITY') {


      // let fileName = item[element.entityFieldName];
      // let fileSource = null;
      // if (fileName) {

      //   try {
      //     let splitParam = fileName.split('&&&');
      //     if (splitParam.length == 2) {
      //       fileName = splitParam[0];
      //       fileSource = splitParam[1];
      //     }
      //   } catch (err) {
      //     //console.log(err);
      //   }

      // }

      // setS3Path(calculateS3Path(fileName));
      // setBucketName(calculateBucketName(fileSource));

      let correctedValue = convertParamValueToS3Key(item[element.entityFieldName]);

      setS3Path(correctedValue);

    }

    if (element.paramType == 'RECIPE') {


      let recipeSrcParam = baseControl.getRecipeParamByName(element.recipeParamName);

      // if (recipeSrcParam) {

      //   let fileName = recipeSrcParam.value;
      //   let fileSource = null;
      //   if (fileName) {

      //     try {
      //       let splitParam = fileName.split('&&&');
      //       if (splitParam.length == 2) {
      //         fileName = splitParam[0];
      //         fileSource = splitParam[1];
      //       }
      //     } catch (err) {
      //       //console.log(err);
      //     }

      //   }

      //   setS3Path(calculateS3Path(fileName));
      //   setBucketName(calculateBucketName(fileSource));
      // }

      let correctedValue = convertParamValueToS3Key(recipeSrcParam?.value);

      setS3Path(correctedValue);


      //setS3Path(recipeSrcParam?.value);

    }

  }, [changeTimestamp, documentPart]);

  useEffect(() => {

    if (acceptedFiles) {
      const files = acceptedFiles.map(file => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      ));

      if (acceptedFiles.length > 0) {
        uploadFile(acceptedFiles[0]);
      }

    }


  }, [acceptedFiles]);


  const uploadFile = (file) => {


    let { element } = props;


    //let entity = item;

    let fileName = file.name;

    //let s3Key = helpers.createUserImageUploadS3Key(user.id, fileName);



    uploadBucketItem({
      bucketName: element.imageUploaderConfig.bucketName,
      file,
      userId: user.id,
      storageType: StorageManager.STORAGE_TYPE_USER_IMAGE
    }, (results, err) => {
      if (err == null) {

        processUpload(results.s3Key, element.imageUploaderConfig);
      }
    });

  };

  let isVisible = baseControl.isVisible();
  if (!isVisible) {
    return <div style={{
      height: '0em'
    }}></div>
  }


  let canEdit = true;


  let isOwner = true;


  // let bucketName = null;
  // let s3Path = null;

  let title = baseControl.convertTitle(element.title, documentPart);

  const label = { inputProps: { 'aria-label': title } };

  let item = baseControl.getEntityItem();







  let src = null;




  try {
    if (s3Path && s3Path.trim() == '') {
      s3Path = null;
    }
  } catch (err) {
    //console.log(err);
  }



  const processSelection = (s3Key) => {

    let finalValue = s3Key;
    let parts = s3Key.split('/');
    let finalPart = parts[parts.length - 1].split('.')[0];

    if (element.isFontParam || element.isParagraphAfterFirstParam) {

      // baseControl.updateValue({
      //   value: finalPart
      // });

      finalValue = finalPart.replace('paragraphAfterFirst-', '');
      //finalValue = finalPart;


    }

    if (element.fileSource != null && element.fileSource.trim() != '') {
      finalValue += '&&&' + element.fileSource;
    }

    setBucketName(element.bucketName);
    setS3Path(s3Key);

    baseControl.updateValue({
      value: finalValue
    });

    //setChangeTimestamp(Date.now());


  }


  const processUpload = (s3Key, imageUploaderConfig) => {

    let finalValue = s3Key;
    let parts = s3Key.split('/');
    let finalPart = parts[parts.length - 1].split('.')[0];

    if (imageUploaderConfig.isFontParam || imageUploaderConfig.isParagraphAfterFirstParam) {

      // baseControl.updateValue({
      //   value: finalPart
      // });
      finalValue = finalPart;


    }

    if (imageUploaderConfig.fileSource != null && imageUploaderConfig.fileSource.trim() != '') {
      finalValue += '&&&' + imageUploaderConfig.fileSource;
    }


    setBucketName(imageUploaderConfig.bucketName);
    setS3Path(finalValue);

    // let newProps = JSON.stringify(baseControl.props);
    // newProps = JSON.parse(newProps);
    // newProps.element = imageUploaderConfig;
    // let uploaderBaseControl = new BaseControl(newProps);
    // uploaderBaseControl.updateValue({
    //   value: finalValue
    // });

    baseControl.updateValue({
      value: finalValue,
      overrideElement:imageUploaderConfig
    });

    //setChangeTimestamp(Date.now());

  }


  const removeFileSource = (src) => {

    let correctedSrc = src;
    try {

      //correctedSrc = src.split("&&&")[0];

    }
    catch (err) {

    }

    return correctedSrc;
  }

  return (

    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      //border:'3px solid red',
      width: '100%'
    }}>

      {/* <div><Typography>FormatImageSelector: {title}</Typography></div>
      <div><Typography>bucketName: {bucketName}</Typography></div>
      <div><Typography>s3Path: {s3Path}</Typography></div> */}
      <StandardImageViewer
        bucketName={bucketName}
        s3Path={removeFileSource(s3Path)}
        height={'5em'}
        fit={'contain'}
        sx={{
          minHeight: '5em'
          //display: { sm: 'block', lg: 'block', md: 'block' } 
        }}
        style={{ borderRadius: 2 }}
        className="custom-class"
        onClick={(e) => {
          // e.preventDefault();
          // setShowGallery(true);

        }}
        showLoading
        errorIcon
      />
      {(!element.isFontParam && !element.isParagraphAfterFirstParam) &&
        <Grid container direction={'row'} sx={{
          justifyContent: 'center',
          marginTop: '1em',
          marginBottom: '1em'
        }} onClick={(e) => {
          e.preventDefault();
          open();
        }}>
          <UploadIcon sx={{
            marginRight: '.5em'
          }} />
          <Typography>Upload Image</Typography>
        </Grid>
      }


      {!element.hideGalleryControls &&
        <Grid container
          direction={'row'}
          sx={{
            justifyContent: 'center'
          }}
          onClick={(e) => {
            e.preventDefault();
            setShowGallery(true);

          }} >
          <CollectionsIcon sx={{
            marginRight: '.5em'
          }} />
          <Typography>Select from Gallery</Typography>
        </Grid>
      }
      <StandardDialog
        title={"Select a font"}
        open={showGallery}
        onClose={() => { setShowGallery(false) }}

        dialogContent={<S3FilesGallery
          bucketName={element.bucketName}
          folder={element.folder}
          onItemSelected={({ s3Key }) => {

            //console.log(s3Key);
            processSelection(s3Key);

          }}
        />}>

      </StandardDialog>


    </Box>


  );
}


export default inject('stores')(observer(FormatImageSelector));



