import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Button } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const BookDraftHistoryLineChart = (props) => {

    let { bookDraft } = props;

    const [labels, setLabels] = useState([]);
    const [values, setValues] = useState([]);
    const [rangeMode, setRangeMode] = useState('total');

    useEffect(() => {

        if (bookDraft) {

            let labels = [];
            let values = [];
            let now = new Date();
            let nowDay = now.getDay();
            let nowFullYear = now.getFullYear();
            let nowMonth = now.getMonth();
            bookDraft.bookDraftDocumentParts.forEach((bddp) => {

                bddp.documentPart.flattenedOps.forEach((fo) => {

                    let foDate = new Date(fo.createdAt);
                    labels.push('.');
                    values.push(fo.numWords);


                })

            })


            setLabels(labels);
            setValues(values);

        }

    }, [bookDraft])

    const options = {
        responsive: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
            },
        },
    };

    //const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: values,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            // {
            //     label: 'Dataset 2',
            //     data: [61, 30, 24, 76, 73, 13, 25],
            //     borderColor: 'rgb(53, 162, 235)',
            //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
            // },
        ],
    };


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column'
        }}>

            <Line options={options} data={data} style={{
                height: '10em'
            }} />

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap:1
            }}>
                <Button onClick={()=>{setRangeMode('all')}}>TO</Button>
                <Button onClick={()=>{setRangeMode('year')}}>YR</Button>
                <Button onClick={()=>{setRangeMode('month')}}>MO</Button>
                <Button onClick={()=>{setRangeMode('week')}}>WK</Button>
                <Button onClick={()=>{setRangeMode('day')}}>DY</Button>
            </div>
        </div>
    )
}

export default inject('stores')(observer(BookDraftHistoryLineChart));



