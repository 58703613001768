import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import config from '../../aws-exports';
import useMediaQuery from '@mui/material/useMediaQuery';
import StretchBox from '../boilerPlate/StretchBox';
import { Box, Button, SwipeableDrawer, Typography } from '@mui/material';
import ScrollBox from '../boilerPlate/ScrollBox';
import StandardUICard from '../UI/StandardUICard';
import StandardUICirclePlus from '../UI/StandardUICirclePlus';
import ListSharedGroups from './lists/ListSharedGroups';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import StandardUITextField from '../UI/StandardUITextField';
import StandardUIButton from '../UI/StandardUIButton';

const SharedGroupsPage = (props) => {

    let { book } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdAt');


    const [showDetailDrawer, setShowDetailDrawer] = useState(false);
    let [selectedUserGroup, setSelectedUserGroup] = useState(null);

    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setShowDetailDrawer(open);
            };


    return (

        <StretchBox flexDirection='row' sxProps={{ gap: isSmallScreen ? 0 : 2 }}>

            <StretchBox sxProps={{
                flex: isSmallScreen ? 12 : 4,
                position: 'relative'
            }}>
                {/* <BoxsetsTable onSelectBoxSet={
                    (boxset) => {
                        setSelectedBoxset(boxset)
                        setShowDetailDrawer(true)
                    }} /> */}

                <ScrollBox>
                    <StandardUICard
                        title='Invited To Groups'
                        showPagination={false}
                        showSearchField={true}
                        onSetOrdering={(orderBy, order)=>{ setOrder(order); setOrderBy(orderBy)}}
                        children={
                            <ListSharedGroups
                                selectedUserGroup={selectedUserGroup}
                                order={order}
                                orderBy={orderBy}
                                secondary={'Time ago'}
                                showShareIcon={false}
                                onListSelectGroup={(compositeUserGroup) => {
                                    setSelectedUserGroup(compositeUserGroup);
                                    setShowDetailDrawer(true)
                                }}

                            />
                        }
                    />






                </ScrollBox>


            </StretchBox>

            <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>

                    <StandardUICard
                        title='Details'
                        showPagination={false}
                        showSearchField={false}
                        // actions={
                        //     <Link underline='none' color='blue'
                        //         onClick={handleMoreDetailOpen}>
                        //         More
                        //     </Link>
                        // }
                        children={
                            <div>
                                <StandardUITextField label='Title' value={selectedUserGroup?.name} disabled />
                                <StandardUITextField label='Subtitle' value='Subtitle' disabled />
                                <Typography>Member Since</Typography>
                                <StandardUIButton
                                    sx={{ marginTop: '1em' }}
                                    buttonAction='Leave'
                                    // onClick={() => (
                                    //     setOpenOptOut(true)
                                    // )}
                                />
                            </div>
                        }
                    />
                    <StandardUICard
                        title='Shared Books'
                        showPagination={false}
                        showSearchField={false}
                        // actions={
                        //     <Link underline='none' color='blue'
                        //         onClick={handleMoreDetailOpen}>
                        //         More
                        //     </Link>
                        // }
                        children={
                            <div>shared books</div>
                        }
                    />


                </ScrollBox>
            </StretchBox>

            <SwipeableDrawer
                anchor={'right'}
                open={showDetailDrawer && isSmallScreen}
                onClose={toggleDrawer(false)}
                sx={{ width: '100%' }}
            //variant='persistent'
            //onOpen={setShowDetailDrawer(true)}
            >
                <div style={{ width: '100vw' }}>

                    <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                        <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2, ml: 0, mr: 0 }}>
                            <IconButton
                                style={{ display: 'flex', justifyContent: 'left' }}
                                onClick={() => {
                                    setShowDetailDrawer(false)
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography
                                variant={theme.landmarkText.variant}
                                gutterBottom
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                {selectedUserGroup?.name}
                            </Typography>
                            <StandardUICard
                                title='Details'
                                showPagination={false}
                                showSearchField={false}
                                // actions={
                                //     <Link underline='none' color='blue'
                                //         onClick={handleMoreDetailOpen}>
                                //         More
                                //     </Link>
                                // }
                                children={
                                    <div>
                                        <StandardUITextField label='Title' value={selectedUserGroup?.name} disabled />
                                        <StandardUITextField label='Subtitle' value='Subtitle' disabled />
                                        <Typography>Member Since</Typography>
                                        <StandardUIButton
                                            sx={{ marginTop: '1em' }}
                                            buttonAction='Leave'
                                            // onClick={() => (
                                            //     setOpenOptOut(true)
                                            // )}
                                        />
                                    </div>
                                }
                            />


                        </ScrollBox>
                    </StretchBox>
                </div>
            </SwipeableDrawer>


        </StretchBox>
    )
}
export default inject('stores')(observer(SharedGroupsPage));