import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from "@mui/material";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Paper, Box, Grid } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ChatIcon from '@mui/icons-material/Chat';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import SearchIcon from '@mui/icons-material/Search';
import PeopleIcon from '@mui/icons-material/People';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LanguageIcon from '@mui/icons-material/Language';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import FileCabinet from 'mdi-material-ui/FileCabinet';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from 'react-responsive'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import { useNavigate, useLocation } from "react-router-dom";
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import StandardDialog from '../StandardDialog';
import ExportsPanel from '../export/ExportsPanel';
import SnapshotsPanel from '../panels/SnapshotsPanel';
import UniverseGeneralPanel from '../universe/UniverseGeneralPanel';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TimelineIcon from '@mui/icons-material/Timeline';
import RoutesHelper from '../../helpers/RoutesHelper';
import FooterTitleIcon from './FooterTitleIcon';
import HandshakeIcon from '@mui/icons-material/Handshake';



const DISPLAY_MODES = {

    UNIVERSE_GENERAL: "UNIVERSE_GENERAL",
    OUTLINE_GENERAL: "OUTLINE_GENERAL",
    EDIT_REQUESTS: "EDIT_REQUESTS",
    SNAPSHOTS: "SNAPSHOTS"

}
const footerLeftIconGroupStyle = {
    display: 'flex',
    'flex-direction': 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    //width: '100%',
    flex: 3,
    borderx: '1px solid red'
}

const footerCenterIconGroupStyle = {
    display: 'flex',
    'flex-direction': 'row',
    'alignItems': 'center',
    justifyContent: 'center',
    alignItems: 'center',
    //width: '100%',
    flex: 3,
    borderx: '1px solid red'
}



const footerRightIconGroupStyle = {
    display: 'flex',
    'flex-direction': 'row',
    'alignItems': 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    //width: '100%',
    flex: 3,
    borderx: '1px solid red'
}

const footerIconStyle = {
    padding: '0em 1em 0em 1em'
}

const displayBig = {

    xs: 'none',
    sm: 'none',
    md: 'flex',
    lg: 'flex',
    xl: 'flex',
    tablet: 'flex',
    laptop: 'flex',
    desktop: 'flex',

}

const displaySmall = {

    xs: 'flex',
    sm: 'flex',
    md: 'none',
    lg: 'none',
    xl: 'none',
    tablet: 'none',
    laptop: 'none',
    desktop: 'none',

}

export const StandardTransparentBottomNavigation = styled(BottomNavigation)(() => ({

    background: 'transparent',
    boxShadow: 'none',
    //height: '2em'

}));



function ConnectionsMobileFooterBar(props) {

    const [value, setValue] = React.useState(0);
    const [showUniverse, setShowUniverse] = React.useState(false);
    const [showExport, setShowExport] = React.useState(false);
    const [showUniverses, setShowUniverses] = React.useState(false);
    const [showSnapshots, setShowSnapshots] = React.useState(false);



    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();

    const isRoute = (pathname) => {
        //if (pathname && pathname.includes(location.pathname) ) {
            if (location.pathname && location.pathname === pathname) {
                return theme.palette.secondary.main;
              }
  
        return 'inherit';
    }

    const isXs = useMediaQuery({ maxWidth: 599 })
    const isSm = useMediaQuery({ maxWidth: 600 })
    const isMd = useMediaQuery({ maxWidth: 900 })
    const isLg = useMediaQuery({ maxWidth: 1200 })
    const isXl = useMediaQuery({ minWidth: 1536 })

    const ref = React.useRef(null);

    const selected = false;
    const fontSize = 'small';

    const toggleSplitScreen = () => {
        props.stores.bookStore.writeSplitScreen = !props.stores.bookStore.writeSplitScreen;
    }

    const toggleLockScreen = () => {
        props.stores.bookStore.writeLockScreen = !props.stores.bookStore.writeLockScreen;
    }

    const projectIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<FileCabinet fontSize={fontSize} color={'inherit'} />} />
    const worldIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} onClick={(e) => { e.preventDefault(); setShowUniverses(true) }} icon={<LanguageIcon fontSize={fontSize} color={'inherit'} />} />
    const outlineIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<AccountTreeIcon fontSize={fontSize} color={'inherit'} />} />
    const statsIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<LeaderboardIcon fontSize={fontSize} color={'inherit'} />} />




    const settingsIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<SettingsIcon fontSize={fontSize} color={'inherit'} />} />
    const grammarIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<SpellcheckIcon fontSize={fontSize} color={'inherit'} />} />
    const searchIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<SearchIcon fontSize={fontSize} color={'inherit'} />} />
    const splitScreenIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} onClick={(e) => { e.preventDefault(); toggleSplitScreen() }} icon={<SplitscreenIcon fontSize={fontSize} color={'inherit'} />} />
    const lockViewsIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} onClick={(e) => { e.preventDefault(); toggleLockScreen() }} icon={<LockOutlinedIcon fontSize={fontSize} color={props.stores.bookStore.writeLockScreen ? theme.palette.books?.main : 'inherit'} />} />


    const textSnippetIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<TextSnippetIcon fontSize={fontSize} color={'inherit'} />} />
    const peopleIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<PeopleIcon fontSize={fontSize} color={'inherit'} />} />
    const chatIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<ChatIcon fontSize={fontSize} color={'inherit'} />} />
    const snapshotsIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} onClick={(e) => { e.preventDefault(); setShowSnapshots(true) }} icon={<CopyAllIcon fontSize={fontSize} color={'inherit'} />} />
    const boxsetsIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<ContentCopyIcon fontSize={fontSize} color={'inherit'} />} />
    const shareIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<ShareIcon fontSize={fontSize} color={'inherit'} />} />
    const editRequestsIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<AccountEdit fontSize={fontSize} color={'inherit'} />} />
    const exportIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} onClick={(e) => { e.preventDefault(); setShowExport(true) }} icon={<FileDownloadIcon fontSize={fontSize} color={'inherit'} />} />
    const menuBookIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<MenuBookIcon fontSize={fontSize} color={'inherit'} />} />
    const scheduleIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<ScheduleIcon fontSize={fontSize} color={'inherit'} />} />
    const timelineIcon = <BottomNavigationAction sx={{ color: theme.palette.smallIcons.color.main }} icon={<TimelineIcon fontSize={fontSize} color={'inherit'} />} />

    let centerIcons = []

    if (isXs) {

        centerIcons = [
            settingsIcon,
            shareIcon,
            shareIcon,
            // boxsetsIcon,
            // projectIcon,
            chatIcon,
            // chatIcon,
            chatIcon

        ];

        centerIcons = [
            <FooterTitleIcon title={'Details'} color={isRoute('/connections/details')}>
              <SettingsIcon
                fontSize={fontSize}
                //color={RoutesHelper.isRoute('/connections/details', location.pathname) ? 'secondary' : 'inherit'}
                onClick={(e) => { e.preventDefault(); navigate('/connections/details') }} />
            </FooterTitleIcon>,
      
            <FooterTitleIcon title={'Shared To'} color={isRoute('/connections/details/sharedTo')}>
              <ShareIcon
                fontSize={fontSize}
                //color={RoutesHelper.isRoute('/connections/details', location.pathname) ? 'secondary' : 'inherit'}
                onClick={(e) => { e.preventDefault(); navigate('/connections/details/sharedTo') }} />
            </FooterTitleIcon>,
      
            <FooterTitleIcon title={'Shared From'} color={isRoute('/connections/details/sharedFrom')}>
              <ShareIcon
                fontSize={fontSize}
                //color={RoutesHelper.isRoute('/connections/details', location.pathname) ? 'secondary' : 'inherit'}
                onClick={(e) => { e.preventDefault(); navigate('/connections/details/sharedFrom') }} />
            </FooterTitleIcon>,
      
            <FooterTitleIcon title={'Feedback'} color={isRoute('/connections/details/feedback')}>
              <ChatIcon
                fontSize={fontSize}
                //color={RoutesHelper.isRoute('/connections/details', location.pathname) ? 'secondary' : 'inherit'}
                onClick={(e) => { e.preventDefault(); navigate('/connections/details/feedback') }} />
            </FooterTitleIcon>,
      
            <FooterTitleIcon title={'Permissions'} color={isRoute('/connections/details/permissions')}>
              <HandshakeIcon
                fontSize={fontSize}
                //color={RoutesHelper.isRoute('/connections/details', location.pathname) ? 'secondary' : 'inherit'}
                onClick={(e) => { e.preventDefault(); navigate('/connections/details/permissions') }} />
            </FooterTitleIcon>,
            // <SettingsIcon fontSize={fontSize} color={RoutesHelper.isRoute('/connections/details', location.pathname) ? 'secondary' : 'inherit'} onClick={(e) => { e.preventDefault(); navigate('/connections/details') }} />,
            // <ShareIcon fontSize={fontSize} color={isRoute('/connections/details/sharedTo')} onClick={(e) => { e.preventDefault(); navigate('/connections/details/sharedTo') }} />,
            // <ShareIcon fontSize={fontSize} color={isRoute('/connections/details/sharedFrom')} onClick={(e) => { e.preventDefault(); navigate('/connections/details/sharedFrom') }} />,
            // // <ContentCopyIcon fontSize={fontSize} color={isRoute('/connections/details/boxsets')} onClick={(e) => { e.preventDefault(); navigate('/connections/details/boxsets') }} />,
            // // <FileCabinet fontSize={fontSize} color={isRoute('/connections/details/projects')} onClick={(e) => { e.preventDefault(); navigate('/connections/details/projects') }} />,
            // <ChatIcon fontSize={fontSize} color={isRoute('/connections/details/feedback')} onClick={(e) => { e.preventDefault(); navigate('/connections/details/feedback') }} />,
            // // <ChatIcon fontSize={fontSize} color={isRoute('/connections/details/styles')} onClick={(e) => { e.preventDefault(); navigate('/connections/details/styles') }} />,
            // <ChatIcon fontSize={fontSize} color={isRoute('/connections/details/permissions')} onClick={(e) => { e.preventDefault(); navigate('/connections/details/permissions') }} />,

        ];


    } else {

    }

    return (
        <Paper sx={{
            zIndex:9999,
            position: 'static',
            bottom: 0,
            left: 0,
            right: 0,
            background: 'transparent',
            boxShadow: 'none'
        }} elevation={0}>
            <StandardTransparentBottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >


                {isXs &&
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        //'flex-direction': 'row',
                        //'flex-wrap': 'wrap',
                        'alignItems': 'center',
                        //width: '100%',
                        //border: '1px solid blue',
                        padding: '0em 0.5em 0em 0.5em'
                    }}>
                        {centerIcons.map((icon) => {
                            return <Box>{icon}</Box>
                        })
                        }
                    </Box>

                }

                <StandardDialog open={showSnapshots} onClose={() => { setShowSnapshots(false) }} dialogContent={<SnapshotsPanel book={props.stores.bookStore.writingBookDraft?.book} />}>

                </StandardDialog>


                <StandardDialog open={showExport} onClose={() => { setShowExport(false) }} dialogContent={<ExportsPanel bookDraftId={props.stores.bookStore.writingBookDraftId} />}>

                </StandardDialog>

                <StandardDialog open={showUniverses} onClose={() => { setShowUniverses(false) }} dialogContent={<UniverseGeneralPanel bookDraftId={props.stores.bookStore.writingBookDraftId} />}>

                </StandardDialog>


            </StandardTransparentBottomNavigation >
        </Paper >
    );
}
export default inject('stores')(observer(ConnectionsMobileFooterBar));