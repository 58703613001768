import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from "@mui/material";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Paper, Box, Grid } from '@mui/material';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LanguageIcon from '@mui/icons-material/Language';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import FileCabinet from 'mdi-material-ui/FileCabinet';
import Drawer from '@mui/material/Drawer';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import WriteDetails from './WriteDetails';
import ReadDetails from './ReadDetails';

const DISPLAY_MODES = {

  UNIVERSE_GENERAL: "UNIVERSE_GENERAL",
  OUTLINE_GENERAL: "OUTLINE_GENERAL",
  EDIT_REQUESTS: "EDIT_REQUESTS",
  SNAPSHOTS: "SNAPSHOTS"

}
const footerLeftIconGroupStyle = {
  display: 'flex',
  'flex-direction': 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  //width: '100%',
  flex: 3,
  borderx: '1px solid red'
}

const footerCenterIconGroupStyle = {
  display: 'flex',
  'flex-direction': 'row',
  'alignItems': 'center',
  justifyContent: 'center',
  alignItems: 'center',
  //width: '100%',
  flex: 3,
  borderx: '1px solid red'
}



const footerRightIconGroupStyle = {
  display: 'flex',
  'flex-direction': 'row',
  'alignItems': 'center',
  justifyContent: 'flex-end',
  alignItems: 'center',
  //width: '100%',
  flex: 3,
  borderx: '1px solid red'
}

const footerIconStyle = {
  padding: '0em 1em 0em 1em'
}

const displayBig = {

  xs: 'none',
  sm: 'none',
  md: 'flex',
  lg: 'flex',
  xl: 'flex',
  tablet: 'flex',
  laptop: 'flex',
  desktop: 'flex',

}

const displaySmall = {

  xs: 'flex',
  sm: 'flex',
  md: 'none',
  lg: 'none',
  xl: 'none',
  tablet: 'none',
  laptop: 'none',
  desktop: 'none',

}

export const StandardTransparentBottomNavigation = styled(BottomNavigation)(() => ({

  background: 'transparent',
  boxShadow: 'none',
  //height: '2em',
  //width:'100%'

}));



function ReadFooterBar(props) {

  const [value, setValue] = React.useState(0);
  const [showTree, setShowTree] = React.useState(false);
  const [showReadDetails, setShowReadDetails] = React.useState(false);



  let { bookDraft, bookDraftDocumentPart } = props;

  const ref = React.useRef(null);

  const selected = false;
  const fontSize = 'small';


  let navigate = useNavigate();
  let location = useLocation();


  const projectIcon = <BottomNavigationAction icon={<FileCabinet fontSize={fontSize} color={'disabled'} />} />
  const worldIcon = <BottomNavigationAction onClick={(e) => { e.preventDefault(); setShowTree(true) }} icon={<LanguageIcon fontSize={fontSize} color={'disabled'} />} />
  const outlineIcon = <BottomNavigationAction icon={<AccountTreeIcon fontSize={fontSize} color={'disabled'} />} />
  const statsIcon = <BottomNavigationAction icon={<LeaderboardIcon fontSize={fontSize} color={'disabled'} />} />
  const moreIcon = <BottomNavigationAction icon={<MoreVertIcon fontSize={fontSize} color={'disabled'} />} />




  // const settingsIcon = <BottomNavigationAction icon={<SettingsIcon fontSize={fontSize} color={'disabled'} />} />
  // const grammarIcon = <BottomNavigationAction icon={<SpellcheckIcon fontSize={fontSize} color={'disabled'} />} />
  // const searchIcon = <BottomNavigationAction icon={<SearchIcon fontSize={fontSize} color={'disabled'} />} />


  // const lockViewsIcon = <BottomNavigationAction icon={<LockOutlinedIcon fontSize={fontSize} color={'disabled'} />} />
  // const textSnippetIcon = <BottomNavigationAction icon={<TextSnippetIcon fontSize={fontSize} color={'disabled'} />} />
  // const peopleIcon = <BottomNavigationAction icon={<PeopleIcon fontSize={fontSize} color={'disabled'} />} />
  // const chatIcon = <BottomNavigationAction icon={<ChatIcon fontSize={fontSize} color={'disabled'} />} />
  // const snapshotsIcon = <BottomNavigationAction icon={<CopyAllIcon fontSize={fontSize} color={'disabled'} />} />
  // const editRequestsIcon = <BottomNavigationAction icon={<AccountEdit fontSize={fontSize} color={'disabled'} />} />
  // const exportIcon = <BottomNavigationAction onClick={(e) => { e.preventDefault(); setShowExport(true) }} icon={<FileDownloadIcon fontSize={fontSize} color={'disabled'} />} />


  const isRoute = (pathname) => {
    //if (pathname && pathname.includes(location.pathname) ) {
    if (location.pathname && location.pathname.includes(pathname)) {
      return true;
    }

    return false;
  }


  return (
    <Paper sx={{
      position: 'static',
      bottom: 0,
      left: 0,
      right: 0,
      background: 'transparent',
      boxShadow: 'none',
      width: '100%'
    }} elevation={0}>
      <StandardTransparentBottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        {/* <BottomNavigationAction icon={<MenuIcon />} />
        <BottomNavigationAction icon={<MenuIcon />} />
        <BottomNavigationAction icon={<MenuIcon />} /> */}






        <Grid container stylex={footerLeftIconGroupStyle} spacing={1} direction={'row'} sx={{
          width: '100%',
          //border:'5px solid red',
          marginBottom: '.5em'
        }}>
          <Grid item onClick={() => {

            setShowTree(true);

          }}>  {projectIcon} </Grid>
          <Grid item>  {worldIcon} </Grid>
          <Grid item>  {outlineIcon} </Grid>
          <Grid item onClick={() => {

 
              setShowReadDetails(true);
            


          }}>  {moreIcon} </Grid>
          {/* <Grid item>  {statsIcon} </Grid> */}
        </Grid>





        {/* <StandardDialog open={showUniverse}>
          <UniverseGeneralPanel2/>
        </StandardDialog> */}

        {/* <StandardDialog open={showExport} onClose={()=>{setShowExport(false)}} dialogContent={<ExportsPanel bookDraftId={props.stores.bookStore.writingBookDraftId}/>}>
          
        </StandardDialog>

        <StandardDialog open={showUniverses} onClose={()=>{setShowUniverses(false)}} dialogContent={<UniverseGeneralPanel bookDraftId={props.stores.bookStore.writingBookDraftId}/>}>
          
        </StandardDialog> */}


      </StandardTransparentBottomNavigation >
      <Drawer
        PaperProps={{
          //sx: { width: "75%" },
        }}
        open={showTree}
        onClose={() => {
          setShowTree(false);
        }}>

        {props.tree}


      </Drawer>

   

      <Drawer
        anchor={'right'}
        PaperProps={{
          sx: { width: "75%" },
        }}
        open={showReadDetails}
        onClose={() => {
          setShowReadDetails(false);
        }}>

        <ReadDetails bookDraft={bookDraft} bookDraftDocumentPart={bookDraftDocumentPart} />


      </Drawer>


    </Paper >
  );
}
export default inject('stores')(observer(ReadFooterBar));