import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
// import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useGetPurchaseLink } from '../managers/PaymentManager';
import StretchBox from '../components/boilerPlate/StretchBox';
import ScrollBox from '../components/boilerPlate/ScrollBox';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      '10 users included',
      '2 GB of storage',
      'Help center access',
      'Email support',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'contained',
    product: 'BASIC',
    plan: 'MONTHLY'
  },
  {
    title: 'Author Pro',
    subheader: 'Most popular',
    price: '9.99',
    description: [
      '20 users included',
      '10 GB of storage',
      'Help center access',
      'Priority email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
    product: 'AUTHORPRO',
    plan: 'MONTHLY'
  },
  {
    title: 'Editor Pro',
    price: '4.99',
    description: [
      '50 users included',
      '30 GB of storage',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'contained',
    product: 'EDITORPRO',
    plan: 'MONTHLY'
  },

  {
    title: 'Free',
    price: '0',
    description: [
      '10 users included',
      '2 GB of storage',
      'Help center access',
      'Email support',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'contained',
    product: 'BASIC',
    plan: 'YEARLY'
  },
  {
    title: 'Author Pro',
    subheader: 'Most popular',
    price: '99',
    description: [
      '20 users included',
      '10 GB of storage',
      'Help center access',
      'Priority email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
    product: 'AUTHORPRO',
    plan: 'YEARLY'
  },
  {
    title: 'Editor Pro',
    price: '49',
    description: [
      '50 users included',
      '30 GB of storage',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'contained',
    product: 'EDITORPRO',
    plan: 'YEARLY'
  },
];

const footers = [

];




function PricingContent() {

  let [plan, setPlan] = React.useState('MONTHLY');
  let {
    lazyGetPurchaseLink, loading, error, data
  } = useGetPurchaseLink();


  const handleChange = (
    event,
    plan,
  ) => {
    setPlan(plan);
  };


  React.useEffect(() => {

    if (data) {
      console.log(data);

      try {

        document.location = JSON.parse(data.getPurchaseLink).checkoutUrl;
      } catch (err) {
        console.log(err);
      }
    }
  }, [data])

  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <StretchBox sxProps={{ 
        //flex: isSmallScreen ? 0 : 8 
        }}>
        <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>





          <Box style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            //justifyContent:'center'
          }}>


            {/* Hero unit */}
            <Container disableGutters maxWidth="sm" component="main"
              sx={{ pt: 8, pb: 6, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="text.primary"
                gutterBottom
              >
                Pricing
              </Typography>
              <Typography variant="h5" align="center" color="text.secondary" component="p">
                Quickly build an effective pricing table for your potential customers with
                this layout. It&apos;s built with default MUI components with little
                customization.
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={plan}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="MONTHLY">Monthly</ToggleButton>
                <ToggleButton value="YEARLY">Yearly</ToggleButton>
              </ToggleButtonGroup>


            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
              <Grid container spacing={5} alignItems="flex-end">
                {tiers.filter(tier => tier.plan == plan).map((tier) => (
                  // Enterprise card is full width at sm breakpoint
                  <Grid
                    item
                    key={tier.title}
                    xs={12}
                    sm={tier.title === 'Enterprise' ? 12 : 6}
                    md={4}
                  >
                    <Card>
                      <CardHeader
                        title={tier.title}
                        subheader={tier.subheader}
                        titleTypographyProps={{ align: 'center' }}
                        action={tier.title === 'Pro' ? null : null}
                        subheaderTypographyProps={{
                          align: 'center',
                        }}
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                              ? theme.palette.grey[200]
                              : theme.palette.grey[700],
                        }}
                      />
                      <CardContent>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'baseline',
                            mb: 2,
                          }}
                        >
                          <Typography component="h2" variant="h3" color="text.primary">
                            ${tier.price}
                          </Typography>
                          <Typography variant="h6" color="text.secondary">
                            /{plan == 'MONTHLY' ? 'mo' : 'yr'}
                          </Typography>
                        </Box>
                        <ul>
                          {tier.description.map((line) => (
                            <Typography
                              component="li"
                              variant="subtitle1"
                              align="center"
                              key={line}
                            >
                              {line}
                            </Typography>
                          ))}
                        </ul>
                      </CardContent>
                      <CardActions>
                        <Button fullWidth variant={tier.buttonVariant} onClick={() => {
                          //window.location.replace('http://localhost:3000/purchase?product=' + tier.product + '&plan=' + tier.plan);
                          //window.open('http://localhost:3000/purchase?product=' + tier.product + '&plan=' + tier.plan, '_blank');

                          lazyGetPurchaseLink({
                            variables: {
                              productId: tier.product,
                              planId: tier.plan
                            }
                          })
                        }}>
                          {tier.buttonText}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
        </ScrollBox>
      </StretchBox>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}