import React, { useState, useEffect, useContext} from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import StandardTabs from "../StandardTabs";
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton, { StandardListButton } from "../StandardButton";
import UserConnectionsList from "../lists/UserConnectionsList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../managers/BooksManager';
import UserGroupsList from "../lists/UserGroupsList";
import { useManagerAddBoxsetContributor, useManagerAddBoxsetContributedBook, useManagerLoadBoxSetContributors } from '../../managers/BoxsetsManager';
import { User } from "../../domain/bookModel";
import OwnedBooksList from "../lists/OwnedBooksList";
import { useGetOwnedBooks } from '../../managers/BooksManager';
import { useManagerShareBookWithUserGroup } from "../../managers/ConnectionsManager";
import Quill from 'quill';
import EditingContext from "../editor/EditingContext";

const Parchment = Quill.import('parchment');
const $ = require("jquery");

const AddAlsoByBookPanel = (props) => {

  const editingContext = useContext(EditingContext);
  const [alreadyAddedBookIds, setAlreadyAddedBookIds] = useState([]);

  let user = props.stores.userStore.user;
  const { selectedQuill, selectedDocumentPart } = editingContext;

  
  const { ownedBooks, error, loading } = useGetOwnedBooks(props);
  let { shareBookWithUserGroup, loading: shareBookWithUserGroupLoading, error: shareBookWithUserGroupError} = useManagerShareBookWithUserGroup(props);

  useEffect(()=>{

    let blotDivId = $("#master_quillImageProcessor").attr("blotDivId");
    let node = $('#' + blotDivId)[0];
    let blot = Parchment.find(node);
    let alsoByBooks = blot.getContributors();

    //console.log(alsoByBooks);
    let bookIds = Object.values(alsoByBooks).map((record)=>{

      return record.item.bookId;
    });

    //console.log(bookIds);
    setAlreadyAddedBookIds(bookIds);


  },[]);

  return (
    <Grid container direction={'column'}>
      <Grid item>
        <OwnedBooksList
          filteredBooks={ownedBooks.filter(ownedBook => !alreadyAddedBookIds.find(bookId => (bookId != ownedBook.id)))}
          //filteredBookIds={alreadyAddedBookIds}
          showSelectIcon
          onSelected={(book) => {

            if (book) {

             
              let blotDivId = $("#master_quillImageProcessor").attr("blotDivId");
              //console.log(blotDivId);

              let node = $('#' + blotDivId)[0];
              let blot = Parchment.find(node);
              //console.log(blot);
              //console.log(node.dataset.uuid);
              //console.log(node.dataset.alsobys);


              const { selectedQuill, selectedDocumentPart } = editingContext;
              let newContributor = blot.addContributor({ bookId: book.id });



              let alsobys = JSON.parse(node.dataset.alsobys);

              let newArgs = {
                  stores: props.stores,
                  userStore: props.stores.userStore,
                  documentPart: selectedDocumentPart,
                  uuid: node.dataset.uuid,
                  alsobys: JSON.stringify(alsobys)
              }



              blot.replaceWith('alsobys', newArgs);

              alsobys = JSON.parse(node.dataset.alsobys);

              alreadyAddedBookIds.push(book.id)
              setAlreadyAddedBookIds(alreadyAddedBookIds); 
              //updateAlsoBySelectedBooks(alsobys);



            }

          }}
        />
      </Grid>
     
    </Grid>
  )
}

export default inject('stores')(observer(AddAlsoByBookPanel));