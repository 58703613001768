import React, { useState, useEffect } from "react";
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import CircleAvatar from "./CircleAvatar";
import DeleteIcon from '@mui/icons-material/Delete';
import StandardRoundedAction from "../actions/StandardRoundedAction";
import Feather from "mdi-material-ui/Feather";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import TablePopupMenu from "../menus/TablePopupMenu";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CommentIcon from '@mui/icons-material/Comment';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import ButtonTableCell from "../table/ButtonTableCell";
import DeleteSnapshotPanel from "../panels/DeleteSnapshotPanel";
import RestoreSnapshotPanel from "../panels/RestoreSnapshotPanel";
import StandardDialog from "../StandardDialog";
import { useGetUserConnections } from '../../managers/ConnectionsManager';
import { useOpenUserInConnections } from '../../managers/ConnectionsManager';
import { useLoadBookEditRequests, useOpenEditRequestInEdit } from '../../managers/EditRequestsManager';
import EditorEditRequestController from '../editing/EditorEditRequestController';
import EditRequestHistory from "../editing/EditRequestHistory";
import { useGetBookShares, useManagerDeleteBookUserShare, useManagerDeleteBookUserGroupShare } from '../../managers/BooksManager';
import CancelTableCell from "../table/CancelTableCell";
import EmptyContent from "../EmptyContent";
import StandardList from "./StandardList";
import ShareBookAssembly from "../assemblies/ShareBookAssembly";

let missingBookImage = require('../../assets/images/image-not-found.png')

const BookSharesList = (props) => {

    let { book, bookShare } = props;

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');
    const [showCreateSnapshotDialog, setShowCreateSnapshotDialog] = React.useState(false);

    const { sharedWithUsers, bookUserSharesLoading, bookUserSharesError, sharedWithUserGroups, bookUserGroupSharesLoading, bookUserGroupSharesError } = useGetBookShares(props, book);
    const { deleteBookUserShare, loading, error } = useManagerDeleteBookUserShare(props);
    const { deleteBookUserGroupShare, loading: loadingDeleteBookUserGroupShare, error: errorDeleteBookUserGroupShare } = useManagerDeleteBookUserGroupShare(props);

    //let { } = useGetBookShares(props, book);



    let tableData = [];
    if (sharedWithUsers) {
        tableData = tableData.concat(sharedWithUsers.map((bookUserShare) => {

            return {
                name: bookUserShare.sharedWithUser?.name,
                profilePicUrl: bookUserShare.sharedWithUser?.profilePicUrl,
                type: 'User',
                createdAt: bookUserShare?.createdAt,
                id: bookUserShare.sharedWithUser.id
            }
        }))
    }

    if (sharedWithUserGroups) {

        tableData = tableData.concat(sharedWithUserGroups.map((bookUserGroupShare) => {

            return {
                name: bookUserGroupShare?.userGroup.name,
                profilePicUrl: bookUserGroupShare?.userGroup.profilePicUrl,
                type: 'Group',
                createdAt: bookUserGroupShare?.createdAt,
                id: bookUserGroupShare?.userGroup.id
            }
        }))
    }







    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();


    const createSecondaryActions = (bookUserShare) => {

        let secondaryActions = [];




        return secondaryActions;
    }

    return (
        <StandardList
            title='Shared With'
            component="div"
            disablePadding
            headerActions={<ShareBookAssembly book={book}/>}
            >

            {tableData && tableData.length > 0 ?
                tableData.map((bookShare) => {

                    return <>

                        <ListItem
                            alignItems="flex-start"

                            secondaryAction={

                                <CancelTableCell onClick={(e) => {
                                    e.preventDefault();

                                    if (bookShare.type == 'Group') {
                                        deleteBookUserGroupShare({
                                            bookId: book.id,
                                            userGroupId: bookShare.id
                                        });
                                    }
                                    if (bookShare.type == 'User') {
                                        deleteBookUserShare({
                                            bookId: book.id,
                                            sharedWithUserId: bookShare.id
                                        });
                                    }



                                }} />

                            }>
                            <ListItemAvatar
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelected(bookShare);
                                }}>
                                <CircleAvatar
                                    //bucketName={config.user_uploads_bucket_name}
                                    s3Src={bookShare?.profilePicUrl}
                                    missingContent={<img src={missingBookImage} />}
                                />
                                {/* <Avatar alt="Remy Sharp" variant="square" src="https://local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm.s3.amazonaws.com/uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/c7cb5750-3627-11ed-914f-5d91b6c4c7eb_planetStrider.png">

              </Avatar> */}
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    noWrap: true
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelected(bookShare);
                                }}
                                primary={bookShare?.name}
                                secondary={
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {bookShare.type}
                                        </Typography>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="caption"
                                            color="text.secondary"
                                        >
                                            Shared date
                                        </Typography>
                                        {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                    </Box>
                                }

                            />

                        </ListItem>
                        <Divider variant="inset" />
                    </>
                    // <Divider variant="inset"/>


                    // return <ListItem secondaryAction={createSecondaryActions(book)}>
                    //   <ListItemAvatar>
                    //     <AvatarTableCell
                    //       bucketName={config.user_uploads_bucket_name}
                    //       s3Src={book?.coverUrl}
                    //     //title={userConnection?.connectedUser?.name}
                    //     />
                    //   </ListItemAvatar>
                    //   <ListItemText primary={book?.title} secondary="" />

                    // </ListItem>
                })
                :
                <Box sx={{
                    marginTop: '3em'
                }}>
                    <EmptyContent tagline={'Users and groups you have shared this book with will appear here.'} />
                </Box>
            }



        </StandardList>

    )
}

export default inject('stores')(observer(BookSharesList));