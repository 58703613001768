import Quill from 'quill';
//import Parchment from 'parchment';
import DeltaToDom from './DeltaToDom';
//import Popper from 'popper.js';
import config from '../../../aws-exports';

const Parchment = Quill.import('parchment');
var $ = require("jquery");

let BlockEmbed = Quill.import('blots/block/embed');
let Base = Quill.import('blots/embed');
let Inline = Quill.import('blots/inline');

let generateClassNames = function ({ accepted, rejected, preview, editorPinned, requestorPinned, editorMode = false }) {

  editorPinned = (editorPinned && editorPinned == 'true') ? true : false;
  if (!editorMode) {
    editorPinned = (requestorPinned && requestorPinned == 'true') ? true : false;
  }
  let acceptedClass = '';
  if (accepted && accepted == 'true') {
    if (preview && !editorPinned) {
      acceptedClass = 'editingDeleteNewLineBlotFinalViewAccepted';
    } else {
      acceptedClass = 'editingDeleteNewLineBlotAccepted editingBlotHidden';
    }
  }

  else if (rejected && rejected == 'true') {


    if (preview && !editorPinned) {
      acceptedClass = 'editingDeleteNewLineBlotFinalViewRejected';
    } else {
      acceptedClass = 'editingDeleteNewLineBlotRejected';
    }


  }
  else {



    if (preview && !editorPinned) {
      //act as if it was accepted since we have nothing else from the author.
      acceptedClass = 'editingDeleteNewLineBlotFinalViewAccepted';
    } else {
      acceptedClass = 'editingDeleteNewLineBlotPending';
    }



  }



  return 'editingBlot editingDeleteBlot editingDeleteNewLineBlot ' + acceptedClass;


}

function createImageContent(node, args, blot, blotImageDivId) {

  //console.log('createImageContent()...');
  let converter = new DeltaToDom();
  /*let html = converter.convertBlurb(args);
    node.innerHTML = html;*/

  let domNode = converter.convertDeleteNewLineContent(args, node, blot, blotImageDivId);
  node.appendChild(domNode);






}

export default class EditingDeleteNewLineBlot extends Base {
  static create(args) {
    //console.log(args);
    let node = super.create();
    let blotImageDivId = 'blotImageDiv' + Date.now();
    node.setAttribute('id', blotImageDivId);
    //node.setAttribute('style', 'border:2px solid red');
    node.dataset.uuid = args.uuid;
    node.dataset.type = 'editingDeleteNewLineBlot';
    node.dataset.accepted = args.accepted;
    node.dataset.rejected = args.rejected;
    node.dataset.editorPinned = args.editorPinned;
    node.dataset.requestorPinned = args.requestorPinned;
    node.dataset.editorComment = args.editorComment;

    // Allow twitter library to modify our contents
    //twttr.widgets.createTweet(id, node);
    createImageContent(node, args, this, blotImageDivId);
    $(node).addClass('editingBlot');
    $(node).addClass('editingDeleteBlot');


    return node;
  }

  static value(domNode) {
    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
      uuid: domNode.dataset.uuid,
      type: 'editingDeleteNewLineBlot',
      accepted: domNode.dataset.accepted,
      rejected: domNode.dataset.rejected,
      editorPinned: domNode.dataset.editorPinned,
      requestorPinned: domNode.dataset.requestorPinned,
      editorComment: domNode.dataset.editorComment
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  accept(preview) {
    this.domNode.dataset.accepted = 'true';
    this.domNode.dataset.rejected = 'false';

    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();
  }

  reject(preview) {
    this.domNode.dataset.accepted = 'false';
    this.domNode.dataset.rejected = 'true';

    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();
  }

  toggleAccept(preview) {
    if (!this.domNode.dataset.accepted || this.domNode.dataset.accepted == 'false' || this.domNode.dataset.accepted == 'undefined') {
      this.domNode.dataset.accepted = 'true';
      this.domNode.dataset.rejected = 'false';
      //this.domNode.setAttribute('style', 'display:none');

    } else {
      this.domNode.dataset.accepted = 'false';
      //this.domNode.setAttribute('style', 'color:red;border:1px solid red;text-decoration:line-through;background-colorx:pink');
      //let classNames = this.generateClassNames();
      //this.domNode.setAttribute('class', classNames);
    }
    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();
  }

  toggleReject(preview) {
    if (!this.domNode.dataset.rejected || this.domNode.dataset.rejected == 'false' || this.domNode.dataset.rejected == 'undefined') {
      this.domNode.dataset.rejected = 'true';
      this.domNode.dataset.accepted = 'false';
      //this.domNode.setAttribute('style', 'display:none');

    } else {
      this.domNode.dataset.rejected = 'false';
      //this.domNode.setAttribute('style', 'color:red;border:1px solid red;text-decoration:line-through;background-colorx:pink');
      //let classNames = this.generateClassNames();
      //this.domNode.setAttribute('class', classNames);
    }
    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();
  }

  clearChoices(preview) {
    this.domNode.dataset.rejected = 'false';
    this.domNode.dataset.accepted = 'false';

    let classNames = generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    })
    this.domNode.setAttribute('class', classNames);
    this.previewOff();
  }

  previewOn() {
    
    $(this.domNode).removeClass('editingBlotHidden');
    $(this.domNode).removeClass('editingDeleteNewLineBlotPreview');
    $(this.domNode).addClass('editingDeleteNewLineBlotPreview');
  }

  previewOff() {
    $(this.domNode).removeClass();
    $(this.domNode).addClass(generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: false,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
    }));
    /*$(this.domNode).removeClass('editingBlot');
    $(this.domNode).removeClass('editingDeleteNewLineBlot');
    $(this.domNode).removeClass('editingDeleteNewLineBlotPreview');*/
    //$(this.domNode).removeClass('editingDeleteNewLineBlotPreview');

  }
  finalView(preview, editorMode) {

    //console.log('finalView')
    $(this.domNode).removeClass();
    $(this.domNode).addClass(generateClassNames({
      accepted: this.domNode.dataset.accepted,
      rejected: this.domNode.dataset.rejected,
      preview: preview,
      editorPinned: this.domNode.dataset.editorPinned,
      requestorPinned: this.domNode.dataset.requestorPinned,
      editorMode: editorMode

    }));

    let rejected = this.domNode.dataset.rejected;
    let accepted = this.domNode.dataset.accepted;

    let wrapperNode = $( this.domNode ).find( "span" );
    let imgs = $( wrapperNode ).find( "img" );
    let fakeNewLines = $( wrapperNode ).find( "br" );
    let fakeIndentSpans = $( wrapperNode ).find( ".indent-span" );
    $(wrapperNode).show();
    // $(fakeNewLines).hide();
    // $(fakeIndentSpans).hide();

    $(this.domNode).show();

    if(preview){
      
      if (rejected && rejected == 'true'){

        $(imgs).hide();
        $(fakeNewLines).show();
        $(fakeIndentSpans).show();
  
      }
      if (accepted && accepted == 'true'){
  
        $(imgs).hide();
        $(fakeNewLines).hide();
        $(fakeIndentSpans).hide();
  
      }
    }else{
      $(imgs).show();
      $(fakeNewLines).hide();
      $(fakeIndentSpans).hide();
    }
    
    $(wrapperNode).show();

  }


}
EditingDeleteNewLineBlot.blotName = 'editingDeleteNewLineBlot';
EditingDeleteNewLineBlot.tagName = 'span';
EditingDeleteNewLineBlot.className = 'editingDeleteNewLineBlot';