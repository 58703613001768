import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useGetUserConnections, useGetUserGroups, useGetUserInvitedToGroups, useManagerAcceptGroupInvitation } from '../../../managers/ConnectionsManager';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper, ListItemButton, ListItemText, ListItemIcon, Tab } from '@mui/material';
import config from '../../../aws-exports';
import useMediaQuery from '@mui/material/useMediaQuery';
import StandardTabs from '../../StandardTabs';
import OwnedBooksTable from '../../table/OwnedBooksTable';
import SharedBooksTable from '../../table/SharedBooksTable';
import OwnedBoxsetsTable from '../../table/OwnedBoxsetsTable';
import SharedBoxsetsTable from '../../table/SharedBoxsetsTable';
import StretchBox from '../StretchBox';
import ScrollBox from '../ScrollBox';
import BoxsetsTable from '../BoxsetsTable';
import OwnedBoxsetBooks from '../../panels/OwnedBoxsetBooks';
import OwnedBoxsetContributors from '../../panels/OwnedBoxsetContributors';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import OwnedBoxsetsTabContent from '../OwnedBoxsetsTabContent';
import AppWrapperDummy from '../../AppWrapperDummy';
import LaurasTabOneContent from '../../Laura/LaurasTabOneContent';
import LaurasBooks from '../../Laura/LaurasBooks';
import OwnedBooks from '../../listPages/OwnedBooks';
import SharedBooks from '../../listPages/SharedBooks';
import OwnedBoxsets from '../../listPages/OwnedBoxsets';
import SharedBoxsets from '../../listPages/SharedBoxsets';

const Library = (props) => {



  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [selectedTab, setSelectedTab] = React.useState('myBooksLive');


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let navigate = useNavigate();
  let location = useLocation();




  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  let tabsStyle={
    display:'flex',
    flexDirection:'column',
    gap:0
  }
  return (
    // <AppWrapperDummy>
      <StretchBox flexDirection='column'>



        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <StandardTabs
            value={selectedTab}
            onChange={(event, newValue) => {
              setSelectedTab(newValue);
            }}
            aria-label="basic tabs example">


            <Tab value='myBooksLive' label="My Books" {...a11yProps(0)} />
            <Tab value='sharedBooksLive' label="Shared Books" {...a11yProps(1)} />
            <Tab value='myBoxsetsLive' label="My Boxsets" {...a11yProps(2)} />
            <Tab value='sharedBoxsetsLive' label="Shared Boxsets" {...a11yProps(3)} />
            {/* <Tab value='laurasTab1' label="Lauras tab 1" {...a11yProps(4)} />
            <Tab value='laurasBooks' label="Lauras Books" {...a11yProps(5)} /> */}

            
            {/* <Tab value='myBooksLive' 
              label={
                <Box style={tabsStyle}>
                  <Typography variant="overline">My</Typography>
                  <Typography variant="overline">Books</Typography>
                </Box>
                } {...a11yProps(6)} />
            <Tab value='sharedBooksLive' label={
                <Box style={tabsStyle}>
                  <Typography variant="overline">Shared</Typography>
                  <Typography variant="overline">Books</Typography>
                </Box>
                } {...a11yProps(7)} />
            <Tab value='myBoxsetsLive' label={
                <Box style={tabsStyle}>
                  <Typography variant="overline">My</Typography>
                  <Typography variant="overline">Boxsets</Typography>
                </Box>
                } {...a11yProps(8)} />
            <Tab value='sharedBoxsetsLive' label={
                <Box style={tabsStyle}>
                  <Typography variant="overline">Shared</Typography>
                  <Typography variant="overline">Boxsets</Typography>
                </Box>
                } {...a11yProps(9)} /> */}



            {/* <Tab value='bpBoxsets' label="Boxsets BP" {...a11yProps(3)} />
            <Tab value='bpBoxsets2' label="Boxsets BP2" {...a11yProps(3)} /> */}
          </StandardTabs>
          {/* <Button variant="contained" sx={{
          color: '#ffffff',
          backgroundColor: theme.palette.secondary.main
        }}
          onClick={(e) => {
            e.preventDefault();

          }}>Create Boxset</Button> */}
        </Box>

        {/* <Box
        sx={{
          flex: '1',
          overflowY: 'auto',
          border: '4px solid blue',
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: '3px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            borderRadius: '3px',
          },
        }}
      > */}

        {/* <ScrollBox> */}


        {/* {selectedTab && selectedTab == 'myBooks' &&

          <OwnedBooksTable />


        }

        {selectedTab && selectedTab == 'sharedBooks' &&
          <SharedBooksTable />
        }

        {selectedTab && selectedTab == 'myBoxsets' &&
          <OwnedBoxsetsTabContent />
        }

        {selectedTab && selectedTab == 'sharedBoxsets' &&
          <SharedBoxsetsTable />
        }

        {selectedTab && selectedTab == 'laurasTab1' &&
          <LaurasTabOneContent />
        }

        {selectedTab && selectedTab == 'laurasBooks' &&
          <LaurasBooks />
        } */}

        {selectedTab && selectedTab == 'myBooksLive' &&
          <OwnedBooks />
        }

        {selectedTab && selectedTab == 'sharedBooksLive' &&
          <SharedBooks />
        }

        {selectedTab && selectedTab == 'myBoxsetsLive' &&
          <OwnedBoxsets />
        }

        {selectedTab && selectedTab == 'sharedBoxsetsLive' &&
          <SharedBoxsets />
        }

        {/* {selectedTab && selectedTab == 'bpBoxsets' &&
          // <TableBoilerPlate />
          <BoxsetsTable />
        }

        {selectedTab && selectedTab == 'bpBoxsets2' &&
          <OwnedBoxsetsTabContent />
        } */}



        {/* </Box> */}
        {/* </ScrollBox> */}
      </StretchBox>
    // </AppWrapperDummy>
  )
}
export default inject('stores')(observer(Library));