import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Box, Typography, useTheme } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetOwnedBooks } from '../../managers/BooksManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import StandardBookCoverTitle from "../StandardBookCoverTitle";
import Feather from "mdi-material-ui/Feather";
import FormatPaint from "@mui/icons-material/FormatPaint";

const BookListItem = (props) => {

  let user = props.stores.userStore.user;
  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();

  let { book } = props;

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between'
  }}>
      <StandardBookCoverTitle book={book} />
      <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end'
      }}>

          <IconButton aria-label="delete">
              <Feather sx={{ color: theme.palette.background.main }} onClick={(e) => {
                  e.preventDefault();
                  //console.log(book)
                 
                  // openBookInWrite({
                  //     bookDraftId: book.primaryBookDraftId
                  // });
              }} />
          </IconButton>

          <IconButton aria-label="delete">
              <FormatPaint sx={{ color: theme.palette.background.main }} onClick={(e) => {
                  e.preventDefault();
                 
                  // openBookInFormat({
                  //     bookDraftId: book.primaryBookDraftId
                  // });
              }} />
          </IconButton>

      </Box>
  </Box>
   

  )
}

export default inject('stores')(observer(BookListItem));