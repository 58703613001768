import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const LaurasButton = (props) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Button
                variant="contained"
                onClick={props.onClick}
            >
                {props.buttonAction}
            </Button>
        </div>
    )
}
export default inject('stores')(observer(LaurasButton));