import Quill from 'quill';
//import Parchment from 'parchment';
//import Popper from 'popper.js';
import { v4 as uuid }  from 'uuid';
const Parchment = Quill.import('parchment');
let Inline = Quill.import('blots/inline');
var $ = require("jquery");



class CurrentSelectionBlot extends Inline {

  static create(args) {

    let node = super.create(args);

    node.dataset.uuid = args.uuid;
    node.dataset.type = 'currentSelectionBlot';
    //node.setAttribute('target', '_blank');
    return node;
  }

  static formats(domNode) {
    //return domNode.dataset.src
    //return domNode.getAttribute('href');

    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
    uuid:domNode.dataset.uuid,
    type:'currentSelectionBlot',
    
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  static value(domNode) {
    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
    uuid:domNode.dataset.uuid,
    type:'currentSelectionBlot',
    
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  /*static sanitize(url) {  
    return sanitize(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
  }*/

  format(name, args) {
    if (name !== this.statics.blotName || !args) return super.format(name, args);
    //value = this.constructor.sanitize(value);
    //this.domNode.setAttribute('href', args.href);
    this.domNode.setAttribute('uuid', args.uuid);
    this.domNode.setAttribute('type', 'currentSelectionBlot');
    
  }

  // deleteAt() {
  //   return false
  // }











}
CurrentSelectionBlot.blotName = 'currentSelectionBlot';
CurrentSelectionBlot.tagName = 'span';
CurrentSelectionBlot.className = 'currentSelectionBlot';
//EditingDeleteBlot.SANITIZED_URL = 'about:blank';
//EditingDeleteBlot.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];





export { CurrentSelectionBlot as default };
