import React, { useState, useEffect } from "react";
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetSnapshots, useOpenBookInWrite, useOpenSnapshotInWrite } from '../../managers/BooksManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import DeleteIcon from '@mui/icons-material/Delete';
import StandardRoundedAction from "../actions/StandardRoundedAction";
import Feather from "mdi-material-ui/Feather";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import TablePopupMenu from "../menus/TablePopupMenu";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CommentIcon from '@mui/icons-material/Comment';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import ButtonTableCell from "../table/ButtonTableCell";
import DeleteSnapshotPanel from "../panels/DeleteSnapshotPanel";
import RestoreSnapshotPanel from "../panels/RestoreSnapshotPanel";
import StandardDialog from "../StandardDialog";
import { useGetUserConnections } from '../../managers/ConnectionsManager';
import { useOpenUserInConnections } from '../../managers/ConnectionsManager';
import { useLoadBookEditRequests, useOpenEditRequestInEdit } from '../../managers/EditRequestsManager';
import EditorEditRequestController from '../editing/EditorEditRequestController';
import EditRequestHistory from "../editing/EditRequestHistory";
import { useGetBookShares, useManagerDeleteBookUserShare, useManagerDeleteBookUserGroupShare } from '../../managers/BooksManager';
import CancelTableCell from "../table/CancelTableCell";
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useGetBookDraftBuildRequests } from "../../managers/ExportsManager";
import SquareAvatar from "../lists/SquareAvatar";
import ReactTimeAgo from 'react-time-ago'


let missingBookImage = require('../../assets/images/image-not-found.png')

const GeneratedDocsList = (props) => {

    let { buildRequest, bookDraftId } = props;

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');

    const GET_PRESIGNED_URL = gql`
    query getPresignedUrl($key: String!) {
      getPresignedUrl(key: $key)
              }
    `;

const { buildRequests, loading, error, client } = useGetBookDraftBuildRequests(props, bookDraftId);






    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();


    const createSecondaryActions = (generatedDoc) => {

        let secondaryActions = [];




        return secondaryActions;
    }

    return (
        <List component="div" disablePadding>
            {
                buildRequest.buildRequestGeneratedDocs.items.map((generatedDoc) => {

                    return <>

                        <ListItem
                            alignItems="flex-start"

                            secondaryAction={

                               <Box></Box>

                            }>
                            <ListItemAvatar
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelected(generatedDoc);
                                }}>
                                <SquareAvatar
                                    //bucketName={config.user_uploads_bucket_name}
                                    s3Src={generatedDoc?.profilePicUrl}
                                    missingContent={<img src={missingBookImage} />}
                                />
                                {/* <Avatar alt="Remy Sharp" variant="square" src="https://local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm.s3.amazonaws.com/uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/c7cb5750-3627-11ed-914f-5d91b6c4c7eb_planetStrider.png">

              </Avatar> */}
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    noWrap: true
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelected(generatedDoc);
                                }}
                                primary={generatedDoc.generatedDoc.fileName}
                                secondary={
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            <ReactTimeAgo date={buildRequest.completedAt} locale="en-US" />
                                        </Typography>
                                        {/* <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="caption"
                                            color="text.secondary"
                                        >
                                            Shared date
                                        </Typography> */}
                                        {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                    </Box>
                                }

                            />

                        </ListItem>
                        <Divider variant="inset"/>
                    </>
                
                })
            }
            
            

        </List>

    )
}

export default inject('stores')(observer(GeneratedDocsList));