import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import config from '../../aws-exports';
import Feather from "mdi-material-ui/Feather";
import FormatPaint from "@mui/icons-material/FormatPaint";
import AccountEdit from 'mdi-material-ui/AccountEdit';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

const StandardFirstColumnTitle = (props) => {

    let { bucketName, s3Src, title, details, showWriteIcon = false, showFormatIcon = false, showEditIcon = false, showReadIcon = false } = props;
    const theme = useTheme();

    let profilePicSrc = 'https://' + bucketName + ".s3.amazonaws.com/" + s3Src;

    let actions = [];

    if (showWriteIcon) {
        actions.push(<IconButton aria-label="delete">
            <Feather sx={{ color: theme.palette.background.main }} onClick={(e) => {
                e.preventDefault();
                // console.log(book)

                // openBookInWrite({
                //     bookDraftId: book.primaryBookDraftId
                // });
            }} />
        </IconButton>)
    }
    if (showFormatIcon) {
        actions.push(<IconButton aria-label="delete">
            <FormatPaint sx={{ color: theme.palette.background.main }} onClick={(e) => {
                e.preventDefault();
                // console.log(book)
                // openBookInWrite({
                //   bookDraftId: book.primaryBookDraftId
                // });
            }} />
        </IconButton>)
    }

    if (showEditIcon) {
        actions.push(<IconButton aria-label="delete">
            <AccountEdit sx={{ color: theme.palette.background.main }} onClick={(e) => {
                e.preventDefault();
                // console.log(book)
                // openBookInWrite({
                //   bookDraftId: book.primaryBookDraftId
                // });
            }} />
        </IconButton>)
    }

    if (showReadIcon) {
        actions.push(<IconButton aria-label="delete">
            <LocalLibraryIcon sx={{ color: theme.palette.background.main }} onClick={(e) => {
                e.preventDefault();
                // console.log(book)
                // openBookInWrite({
                //   bookDraftId: book.primaryBookDraftId
                // });
            }} />
        </IconButton>)
    }

    return (

        <Grid container sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            //border: '1px solid red'
        }}>
            <Grid item xs={12} >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        // cursor: 'pointer'
                    }}
                        onClick={props.onClick}>
                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>{title}</Typography>
                        <Typography variant="body2">{details}</Typography>
                    </div>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        {actions}
                    </Box>
                </div>
            </Grid>

        </Grid>

    )
}
export default inject('stores')(observer(StandardFirstColumnTitle));