import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
//import StandardListItem from '../StandardListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import StandardButton from '../StandardButton';
import { ListItem } from '@mui/material';
import Quill from 'quill';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Button } from '@mui/material';
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import ToggleButton from '@mui/material/ToggleButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import EditingInsertBlot from '../editor/blots/EditingInsertBlot';
import EditingInsertNewLineBlot2 from '../editor/blots/EditingInsertNewLineBlot2';
import EditingDeleteBlot from '../editor/blots/EditingDeleteBlot';
import EditingMovedTombstoneBlot from '../editor/blots/EditingMovedTombstoneBlot';
import EditingMovedDestinationBlot from '../editor/blots/EditingMovedDestinationBlot';
import DeltaManager from '../editor/DeltaManager';
import uuid from 'uuid';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';

const Delta = require('quill-delta');

const Parchment = Quill.import('parchment');

var $ = require("jquery");

const EditChangeListItem = (props) => {

    let { editChange } = props;
    let [editStatus, setEditStatus] = useState('');
    let [editChangedTimestamp, setEditChangedTimestamp] = useState();



    const leftColumn = <Avatar alt='' src={props.profileAvatar} />

    let actions = [];


    //console.log(editChange)

    let accepted = false;
    let rejected = false;

    // let acceptedVariant = 'outlined'

    // if (editChange.accepted && editChange.accepted == 'true') {
    //     editStatus = 'accepted';
    //     acceptedVariant = 'contained';
    // }

    // if (editChange.rejected && editChange.rejected == 'true') {
    //     editStatus = 'rejected';
    //     acceptedVariant = 'outlined';
    // }



    const deleteMovedBlot = ({
        sourceUuid,
        destinationUuid

    }) => {

        let editBookDraft = props.editBookDraft;
        //console.log(props.editChange);



        //*****************************************************
        //  Locate information about the tombstone blot
        //*****************************************************
        let tombstoneDocumentPart = editBookDraft.getBookDraftDocumentPartByPartId(props.editChange.sourcePartId).documentPart;

        let {
            beforeDelta: tombstoneBeforeDelta,
            foundDelta: tombstonefoundDelta,
        } = new DeltaManager().findBlot({
            //delta:finalDeltaOps,
            documentPart: tombstoneDocumentPart,
            uuid: sourceUuid
        });



        //*****************************************************
        //  Locate information about the destination blot
        //*****************************************************
        let destinationDocumentPart = editBookDraft.getBookDraftDocumentPartByPartId(props.editChange.destinationPartId).documentPart;
        let {
            beforeDelta: destinationBeforeDelta,
            foundDelta: destinationfoundDelta,
        } = new DeltaManager().findBlot({
            //delta:finalDeltaOps,
            documentPart: destinationDocumentPart,
            uuid: destinationUuid
        });

        //*****************************************************
        //  Process based on same or different documentParts
        //*****************************************************
        if (tombstoneDocumentPart.id == destinationDocumentPart.id) {

            //console.log('same documentpart deletion');
            let deleteTombstoneDelta = new Delta().retain(tombstoneBeforeDelta.length()).delete(tombstonefoundDelta.length());

            let deleteDestinationDelta = new Delta().retain(destinationBeforeDelta.length()).delete(destinationfoundDelta.length());

            let relocationIndex = tombstoneBeforeDelta.length();
            if (tombstoneBeforeDelta.length() > destinationBeforeDelta.length()) {

                relocationIndex = tombstoneBeforeDelta.length() - destinationfoundDelta.length();
                deleteTombstoneDelta = new Delta().retain(tombstoneBeforeDelta.length() - destinationfoundDelta.length()).delete(tombstonefoundDelta.length());
            }
            let relocateDestinationDelta = new Delta().retain(relocationIndex);
            relocateDestinationDelta = new Delta(relocateDestinationDelta.ops.concat(destinationfoundDelta.ops));

            let deltaOps = relocateDestinationDelta.ops;
            deltaOps.forEach((op) => {

                if (op.hasOwnProperty("attributes") && op.attributes.hasOwnProperty("editingMovedDestinationBlot")) {

                    delete op.attributes.editingMovedDestinationBlot;

                }

            })

            relocateDestinationDelta = new Delta(deltaOps);

            let finalDelta = deleteDestinationDelta.compose(deleteTombstoneDelta).compose(relocateDestinationDelta);
            //let finalDelta = deleteDestinationDelta.compose(deleteTombstoneDelta);

            props.stores.bookStore.postDocumentPartEvent({
                partId: tombstoneDocumentPart.id,
                delta: finalDelta,
                quillSourceType: 'user'
            })
            tombstoneDocumentPart.appendDelta(finalDelta);


        } else {

            let deleteTombstoneDelta = new Delta().retain(tombstoneBeforeDelta.length()).delete(tombstonefoundDelta.length());
            let deleteDestinationDelta = new Delta().retain(destinationBeforeDelta.length()).delete(destinationfoundDelta.length());


            let relocateDestinationDelta = new Delta().retain(tombstoneBeforeDelta.length());
            relocateDestinationDelta = new Delta(relocateDestinationDelta.ops.concat(destinationfoundDelta.ops));


            let deltaOps = relocateDestinationDelta.ops;
            deltaOps.forEach((op) => {

                if (op.hasOwnProperty("attributes") && op.attributes.hasOwnProperty("editingMovedDestinationBlot")) {

                    delete op.attributes.editingMovedDestinationBlot;

                }

            })

            relocateDestinationDelta = new Delta(deltaOps);

            deleteTombstoneDelta = deleteTombstoneDelta.compose(relocateDestinationDelta);

            props.stores.bookStore.postDocumentPartEvent({
                partId: tombstoneDocumentPart.id,
                delta: deleteTombstoneDelta,
                quillSourceType: 'api'
            })
            tombstoneDocumentPart.appendDelta(deleteTombstoneDelta);


            props.stores.bookStore.postDocumentPartEvent({
                partId: destinationDocumentPart.id,
                delta: deleteDestinationDelta,
                quillSourceType: 'api'
            })
            destinationDocumentPart.appendDelta(deleteDestinationDelta);




        }









    }




    const deleteEditInsertBlot = ({

    }) => {

        let {
            beforeDelta,
            foundDelta,
            documentPart
        } = new DeltaManager().findBookDraftBlot({
            //delta:finalDeltaOps,
            bookDraft: props.editBookDraft,
            uuid: props.blotId
        });


        let deleteBlotDelta = new Delta().retain(beforeDelta.length()).delete(foundDelta.length());

        props.stores.bookStore.postDocumentPartEvent({
            partId: documentPart.id,
            delta: deleteBlotDelta,
            quillSourceType: 'user'
        });

        documentPart.appendDelta(deleteBlotDelta);

        setEditChangedTimestamp(Date.now());

    }





    const deleteEditDeleteBlot = ({
        accepted,
        rejected
    }) => {

        let {
            beforeDelta,
            foundDelta,
            documentPart
        } = new DeltaManager().findBookDraftBlot({
            //delta:finalDeltaOps,
            bookDraft: props.editBookDraft,
            uuid: props.blotId
        });

        //console.log(beforeDelta);
        //console.log(foundDelta);
        //console.log(documentPart);

        foundDelta.ops.forEach((op) => {

            if (op.attributes) {

                op.attributes.editingDeleteBlot = null;

            }
        })
        let deleteBlotDelta = new Delta().retain(beforeDelta.length()).delete(foundDelta.length());
        //updateBlotDelta = new Delta(updateBlotDelta.ops.concat(foundDelta.ops));

        //console.log(deleteBlotDelta);



        props.stores.bookStore.postDocumentPartEvent({
            partId: documentPart.id,
            delta: deleteBlotDelta,
            quillSourceType: 'user'
        })


        let updateBlotDelta = new Delta().retain(beforeDelta.length());
        updateBlotDelta = new Delta(updateBlotDelta.ops.concat(foundDelta.ops));



        props.stores.bookStore.postDocumentPartEvent({
            partId: documentPart.id,
            delta: updateBlotDelta,
            quillSourceType: 'user'
        })


        //props.stores.bookStore.setBlotsChangedTimeout();
        let combinedBlotDelta = new Delta().retain(beforeDelta.length()).delete(foundDelta.length());
        combinedBlotDelta = new Delta(combinedBlotDelta.ops.concat(foundDelta.ops));
        documentPart.appendDelta(combinedBlotDelta);


        setEditChangedTimestamp(Date.now());
    }



    const setBlotStatus = ({
        accepted,
        rejected
    }) => {


        if (props.editChange.type == "editingMovedDestinationBlot" || props.editChange.type == "editingMovedTombstoneBlot") {

            //console.log(props.editChange);

            let {
                beforeDelta,
                foundDelta,
                documentPart
            } = new DeltaManager().findBookDraftBlot({
                //delta:finalDeltaOps,
                bookDraft: props.editBookDraft,
                uuid: props.editChange.uuid
            });


            let {
                beforeDelta: oppositeBeforeDelta,
                foundDelta: oppositeFoundDelta,
                documentPart: oppositeDocumentPart,
            } = new DeltaManager().findBookDraftBlot({
                //delta:finalDeltaOps,
                bookDraft: props.editBookDraft,
                uuid: props.editChange.oppositeUuid
            });


            doBlotStatusUpdate({
                accepted,
                rejected,
                beforeDelta,
                foundDelta,
                documentPart
            })


            doBlotStatusUpdate({
                accepted,
                rejected,
                beforeDelta: oppositeBeforeDelta,
                foundDelta: oppositeFoundDelta,
                documentPart: oppositeDocumentPart
            })

            props.stores.bookStore.setBlotsChangedTimestamp();
            setEditChangedTimestamp(Date.now());

        }
        else {

            let {
                beforeDelta,
                foundDelta,
                documentPart
            } = new DeltaManager().findBookDraftBlot({
                //delta:finalDeltaOps,
                bookDraft: props.editBookDraft,
                uuid: props.blotId
            });

            //console.log(beforeDelta);
            //console.log(foundDelta);
            //console.log(documentPart);

            doBlotStatusUpdate({
                accepted,
                rejected,
                beforeDelta,
                foundDelta,
                documentPart
            })



        }
        props.stores.bookStore.setBlotsChangedTimestamp();
        setEditChangedTimestamp(Date.now());


    }


    const doBlotStatusUpdate = ({
        accepted,
        rejected,
        beforeDelta,
        foundDelta,
        documentPart
    }) => {



        // let {
        //     beforeDelta,
        //     foundDelta,
        //     documentPart
        // } = new DeltaManager().findBookDraftBlot({
        //     //delta:finalDeltaOps,
        //     bookDraft: props.editBookDraft,
        //     uuid: props.blotId
        // });

        // console.log(beforeDelta);
        // console.log(foundDelta);
        // console.log(documentPart);

        let isDestinationBlot = false;

        let movedOnlyMode = (props.editChange.type == "editingMovedTombstoneBlot" || props.editChange.type == "editingMovedDestinationBlot") ? true : false;

        foundDelta.ops.forEach((op) => {



            if (op.attributes) {

                Object.keys(op.attributes).forEach((key) => {

                    if (key == 'editingMovedDestinationBlot') {

                        isDestinationBlot = true;

                    }
                });
                Object.keys(op.attributes).forEach((key) => {

                    let attribute = op.attributes[key];
                    if (!movedOnlyMode || (movedOnlyMode && (key == 'editingMovedTombstoneBlot' || key == 'editingMovedDestinationBlot'))) {
                        try {

                            if (attribute.accepted != null) {
                                attribute.accepted = accepted;
                            }
                            if (attribute.rejected != null) {
                                attribute.rejected = rejected;
                            }
                        } catch (err) {
                            //do nothing
                        }
                    }



                })

            }

            if (op.insert.editingInsertNewLineBlot2) {

                op.insert.editingInsertNewLineBlot2.accepted = accepted;
                op.insert.editingInsertNewLineBlot2.rejected = rejected;

            }

            if (op.insert.editingDeleteNewLineBlot) {

                op.insert.editingDeleteNewLineBlot.accepted = accepted;
                op.insert.editingDeleteNewLineBlot.rejected = rejected;

            }




        })
        let deleteBlotDelta = new Delta().retain(beforeDelta.length()).delete(foundDelta.length());
        //updateBlotDelta = new Delta(updateBlotDelta.ops.concat(foundDelta.ops));

        //console.log(deleteBlotDelta);



        props.stores.bookStore.postDocumentPartEvent({
            partId: documentPart.id,
            delta: deleteBlotDelta,
            quillSourceType: 'user'
        })


        let updateBlotDelta = new Delta().retain(beforeDelta.length());
        updateBlotDelta = new Delta(updateBlotDelta.ops.concat(foundDelta.ops));



        props.stores.bookStore.postDocumentPartEvent({
            partId: documentPart.id,
            delta: updateBlotDelta,
            quillSourceType: 'user'
        })


        //props.stores.bookStore.setBlotsChangedTimeout();
        let combinedBlotDelta = new Delta().retain(beforeDelta.length()).delete(foundDelta.length());
        combinedBlotDelta = new Delta(combinedBlotDelta.ops.concat(foundDelta.ops));
        documentPart.appendDelta(combinedBlotDelta);


        //setEditChangedTimestamp(Date.now());

    }

    useEffect(() => {

        //console.log(editChangedTimestamp);
        let node = $("div").find("[data-uuid='" + props.blotId + "']")[0];
        let blot = Parchment.find(node);
        //console.log(node.dataset.accepted);
        let newStatus = '';
        if (node && node.dataset.accepted && node.dataset.accepted == 'true') {
            newStatus = 'accepted';
        }

        if (node && node.dataset.rejected && node.dataset.rejected == 'true') {
            newStatus = 'rejected';
        }

        setEditStatus(newStatus);
        // console.log(node);
        // console.log(node.accepted);

    }, [editChangedTimestamp]);


    const scrollToEdit = ({
        blotId
    }) => {
        try {

            let node = $("div").find("[data-uuid='" + blotId + "']")[0];
            node.scrollIntoView({
                behavior: "smooth", // or "auto" or "instant"
                block: "end" // or "start" --> dont know why, but using "start" caused a horizontal scrollbar to appear ( which cause the toolbar to be clipped at the top )
            });
        } catch (err) {
            console.log(err);
        }
    };



    

    const handleToggleReject = (e) => {

        try {
            e.preventDefault();

            //---------------------------------------------/
            // FOllowing will update the blot AND trigger a text-change event in quill.
            //---------------------------------------------/
            // props.stores.bookStore.blotIsUpdating = true;
            // let node = $("div").find("[data-uuid='" + props.blotId + "']").each(function () {
            //     let blot = Parchment.find(this);
            //     blot.reject();
            // })
            // setEditChangedTimestamp(Date.now());

            if (editStatus == 'rejected') {
                setBlotStatus({
                    accepted: 'false',
                    rejected: 'false'
                });
            }
            else {
                setBlotStatus({
                    accepted: 'false',
                    rejected: 'true'
                });
            }





        } catch (err) {
            console.log(err);
        }

    }


    const handleToggleAccept = (e) => {

        try {
            e.preventDefault();

            //---------------------------------------------/
            // FOllowing will update the blot AND trigger a text-change event in quill.
            //---------------------------------------------/
            // props.stores.bookStore.blotIsUpdating = true;
            // let node = $("div").find("[data-uuid='" + props.blotId + "']").each(function () {
            //     let blot = Parchment.find(this);
            //     blot.accept();
            // })
            // setEditChangedTimestamp(Date.now());


            if (editStatus == 'accepted') {
                setBlotStatus({
                    accepted: 'false',
                    rejected: 'false'
                });
            }
            else {
                setBlotStatus({
                    accepted: 'true',
                    rejected: 'false'
                });
            }





        } catch (err) {
            console.log(err);
        }

    }

    return (
        <ListItem


            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '.5em'
            }}
            secondaryAction={
                <div>
                    {/* {actionItems} */}
                </div>
            }
            onClick={(e) => {
                e.stopPropagation();

                //onSelected();

            }}

            onMouseEnter={() => {
                // if (this.state.finalView && pinned == 'false') {
                //     return;
                // }
                try {


                    let nodes = $("div").find("[data-uuid='" + props.blotId + "']");
                    nodes.each(function (index) {
                        let blot = Parchment.find(this);
                        blot.previewOn();
                    });




                } catch (err) {
                    console.log(err);
                }

            }}

            onMouseLeave={() => {

                // if (this.state.finalView && pinned == 'false') {
                //     return;
                // }

                try {
                    /*let node = $("div").find("[data-uuid='" + m.uuid + "']")[0];
                    let blot = Parchment.find(node);
                    blot.previewOff();*/


                    let nodes = $("div").find("[data-uuid='" + props.blotId + "']");
                    nodes.each(function (index) {
                        let blot = Parchment.find(this);
                        blot.previewOff();
                    });



                } catch (err) {
                    console.log(err);
                }





            }}
        >
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>


                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: '1em',
                    alignItems: 'center',
                    paddingRight: '1em'
                }}>
                    {/* <LocationOnOutlinedIcon color='disabled' onClick={(e) => {

                        e.preventDefault();

                        scrollToEdit({
                            blotId: props.blotId
                        })


                    }} /> */}

                    {props.editChange.type == "editingInsertBlot" &&
                        <AddCircleOutlineOutlinedIcon color='disabled' onClick={(e) => {

                            e.preventDefault();

                            scrollToEdit({
                                blotId: props.blotId
                            })
                        }} />
                    }

                    {props.editChange.type == "editingInsertNewLineBlot2" &&
                        <AddCircleOutlineOutlinedIcon color='disabled' onClick={(e) => {

                            e.preventDefault();

                            scrollToEdit({
                                blotId: props.blotId
                            })
                        }} />
                    }

                    {props.editChange.type == "editingDeleteBlot" &&
                        <RemoveCircleOutlineOutlinedIcon color='disabled' onClick={(e) => {

                            e.preventDefault();

                            scrollToEdit({
                                blotId: props.blotId
                            })
                        }} />
                    }

                    {props.editChange.type == "editingDeleteNewLineBlot" &&
                        <RemoveCircleOutlineOutlinedIcon color='disabled' onClick={(e) => {

                            e.preventDefault();

                            scrollToEdit({
                                blotId: props.blotId
                            })
                        }} />
                    }

                    {props.editChange.type == "editingMovedTombstoneBlot" &&
                        <CopyAllOutlinedIcon color='disabled' onClick={(e) => {

                            e.preventDefault();

                            scrollToEdit({
                                blotId: props.blotId
                            })
                        }} />
                    }

                    {props.editChange.type == "editingMovedDestinationBlot" &&
                        <ContentPasteGoOutlinedIcon color='disabled' onClick={(e) => {

                            e.preventDefault();

                            scrollToEdit({
                                blotId: props.blotId
                            })
                        }} />
                    }






                    {props.editChange.type == "editingMovedTombstoneBlot" &&
                        <ContentPasteGoOutlinedIcon color='disabled' onClick={(e) => {

                            e.preventDefault();

                            //console.log(props.editChange);
                            scrollToEdit({
                                blotId: props.editChange.oppositeUuid
                            })
                        }} />
                    }

                    {props.editChange.type == "editingMovedDestinationBlot" &&
                        <CopyAllOutlinedIcon color='disabled' onClick={(e) => {

                            e.preventDefault();

                            scrollToEdit({
                                blotId: props.editChange.oppositeUuid
                            })
                        }} />
                    }

                </div>





                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: '.2em',
                    alignItems: 'center',
                }}>
                    {/* <span>{editStatus}</span> */}


                    <ToggleButtonGroup
                        value={editStatus}
                        exclusive
                        //onChange={handleAlignment}
                        aria-label="text alignment"
                    >
                        <ToggleButton size="small" value="accepted" aria-label="left aligned" onClick={handleToggleAccept}>
                            {/* <FormatAlignLeftIcon /> */}
                            <Typography variant={'h7'}>Accepted</Typography>
                        </ToggleButton>
                        <ToggleButton size="small" value="rejected" aria-label="right aligned" onClick={handleToggleReject}>
                            {/* <FormatAlignRightIcon /> */}
                            <Typography variant={'h7'}>Rejected</Typography>
                        </ToggleButton>
                    </ToggleButtonGroup>



{/* 
                    <Button size="small" variant={editStatus == 'accepted' ? 'contained' : 'outlined'} disableElevation onClick={(e) => {
                        try {
                            e.preventDefault();

                            //---------------------------------------------/
                            // FOllowing will update the blot AND trigger a text-change event in quill.
                            //---------------------------------------------/
                            // props.stores.bookStore.blotIsUpdating = true;
                            // let node = $("div").find("[data-uuid='" + props.blotId + "']").each(function () {
                            //     let blot = Parchment.find(this);
                            //     blot.accept();
                            // })
                            // setEditChangedTimestamp(Date.now());


                            if (editStatus == 'accepted') {
                                setBlotStatus({
                                    accepted: 'false',
                                    rejected: 'false'
                                });
                            }
                            else {
                                setBlotStatus({
                                    accepted: 'true',
                                    rejected: 'false'
                                });
                            }





                        } catch (err) {
                            //console.log(err);
                        }

                    }}>accept</Button>


                    <Button size="small" variant={editStatus == 'rejected' ? 'contained' : 'outlined'} disableElevationx onClick={(e) => {
                        try {
                            e.preventDefault();

                            //---------------------------------------------/
                            // FOllowing will update the blot AND trigger a text-change event in quill.
                            //---------------------------------------------/
                            // props.stores.bookStore.blotIsUpdating = true;
                            // let node = $("div").find("[data-uuid='" + props.blotId + "']").each(function () {
                            //     let blot = Parchment.find(this);
                            //     blot.reject();
                            // })
                            // setEditChangedTimestamp(Date.now());

                            if (editStatus == 'rejected') {
                                setBlotStatus({
                                    accepted: 'false',
                                    rejected: 'false'
                                });
                            }
                            else {
                                setBlotStatus({
                                    accepted: 'false',
                                    rejected: 'true'
                                });
                            }





                        } catch (err) {
                            console.log(err);
                        }
                    }}>reject</Button> */}

                    <HighlightOffOutlinedIcon color='disabled' onClick={(e) => {
                        try {
                            e.preventDefault();
                            e.stopPropagation();


                            props.stores.bookStore.blotIsUpdating = true;
                            let blotNode = $("div").find("[data-uuid='" + props.blotId + "']")[0];
                            //$("div").find("[data-uuid='" + props.blotId + "']").each(function () {

                            let blot = Parchment.find(blotNode);

                            if (blot instanceof EditingInsertBlot || blot instanceof EditingInsertNewLineBlot2) {

                                //blot.remove();
                                deleteEditInsertBlot({});
                            }
                            if (blot instanceof EditingDeleteBlot) {
                                //blot.format('editingDeleteBlot', null);
                                deleteEditDeleteBlot({});
                            }


                            if (blot instanceof EditingMovedDestinationBlot) {

                                let editBookDraft = props.editBookDraft;
                                //console.log(props.editChange);

                                let sourceUuid = props.editChange.oppositeUuid;
                                let destinationUuid = props.blotId;

                                deleteMovedBlot({
                                    sourceUuid,
                                    destinationUuid

                                });

                            }

                            if (blot instanceof EditingMovedTombstoneBlot) {

                                let editBookDraft = props.editBookDraft;
                                //console.log(props.editChange);

                                let sourceUuid = props.blotId;
                                let destinationUuid = props.editChange.oppositeUuid;

                                deleteMovedBlot({
                                    sourceUuid,
                                    destinationUuid

                                });
                                /*


                                //*****************************************************
                                //  Locate information about the tombstone blot
                                //*****************************************************
                                let tombstoneDocumentPart = editBookDraft.getBookDraftDocumentPartByPartId(props.editChange.sourcePartId).documentPart;
                                let {
                                    beforeDelta: tombstoneBeforeDelta,
                                    foundDelta: tombstonefoundDelta,
                                } = new DeltaManager().findBlot({
                                    //delta:finalDeltaOps,
                                    documentPart: tombstoneDocumentPart,
                                    uuid: props.blotId
                                });



                                //*****************************************************
                                //  Locate information about the destination blot
                                //*****************************************************
                                let destinationDocumentPart = editBookDraft.getBookDraftDocumentPartByPartId(props.editChange.destinationPartId).documentPart;
                                let {
                                    beforeDelta: destinationBeforeDelta,
                                    foundDelta: destinationfoundDelta,
                                } = new DeltaManager().findBlot({
                                    //delta:finalDeltaOps,
                                    documentPart: destinationDocumentPart,
                                    uuid: props.editChange.oppositeUuid
                                });

                                //*****************************************************
                                //  Process based on same or different documentParts
                                //*****************************************************
                                if (tombstoneDocumentPart.id == destinationDocumentPart.id) {

                                    console.log('same documentpart deletion');
                                    let deleteTombstoneDelta = new Delta().retain(tombstoneBeforeDelta.length()).delete(tombstonefoundDelta.length());

                                    let deleteDestinationDelta = new Delta().retain(destinationBeforeDelta.length()).delete(destinationfoundDelta.length());

                                    let relocationIndex = tombstoneBeforeDelta.length();
                                    if (tombstoneBeforeDelta.length() > destinationBeforeDelta.length()) {

                                        relocationIndex = tombstoneBeforeDelta.length() - destinationfoundDelta.length();
                                        deleteTombstoneDelta = new Delta().retain(tombstoneBeforeDelta.length() - destinationfoundDelta.length()).delete(tombstonefoundDelta.length());
                                    }
                                    let relocateDestinationDelta = new Delta().retain(relocationIndex);
                                    relocateDestinationDelta = new Delta(relocateDestinationDelta.ops.concat(destinationfoundDelta.ops));

                                    let deltaOps = relocateDestinationDelta.ops;
                                    deltaOps.forEach((op) => {

                                        if (op.hasOwnProperty("attributes") && op.attributes.hasOwnProperty("editingMovedDestinationBlot")) {

                                            delete op.attributes.editingMovedDestinationBlot;

                                        }

                                    })

                                    relocateDestinationDelta = new Delta(deltaOps);

                                    let finalDelta = deleteDestinationDelta.compose(deleteTombstoneDelta).compose(relocateDestinationDelta);
                                    //let finalDelta = deleteDestinationDelta.compose(deleteTombstoneDelta);

                                    props.stores.bookStore.postDocumentPartEvent({
                                        partId: tombstoneDocumentPart.id,
                                        delta: finalDelta,
                                        quillSourceType: 'user'
                                    })
                                    tombstoneDocumentPart.appendDelta(finalDelta);


                                } else {

                                    let deleteTombstoneDelta = new Delta().retain(tombstoneBeforeDelta.length()).delete(tombstonefoundDelta.length());
                                    let deleteDestinationDelta = new Delta().retain(destinationBeforeDelta.length()).delete(destinationfoundDelta.length());


                                    let relocateDestinationDelta = new Delta().retain(tombstoneBeforeDelta.length());
                                    relocateDestinationDelta = new Delta(relocateDestinationDelta.ops.concat(destinationfoundDelta.ops));


                                    let deltaOps = relocateDestinationDelta.ops;
                                    deltaOps.forEach((op) => {

                                        if (op.hasOwnProperty("attributes") && op.attributes.hasOwnProperty("editingMovedDestinationBlot")) {

                                            delete op.attributes.editingMovedDestinationBlot;

                                        }

                                    })

                                    relocateDestinationDelta = new Delta(deltaOps);

                                    deleteTombstoneDelta = deleteTombstoneDelta.compose(relocateDestinationDelta);

                                    props.stores.bookStore.postDocumentPartEvent({
                                        partId: tombstoneDocumentPart.id,
                                        delta: deleteTombstoneDelta,
                                        quillSourceType: 'api'
                                    })
                                    tombstoneDocumentPart.appendDelta(deleteTombstoneDelta);


                                    props.stores.bookStore.postDocumentPartEvent({
                                        partId: destinationDocumentPart.id,
                                        delta: deleteDestinationDelta,
                                        quillSourceType: 'api'
                                    })
                                    destinationDocumentPart.appendDelta(deleteDestinationDelta);




                                }




                                */


                            }

                            if (blot instanceof EditingMovedDestinationBlot) {

                                // let editBookDraft = props.editBookDraft;
                                // console.log(props.editChange);


                                // let destinationDocumentPart = editBookDraft.getBookDraftDocumentPartByPartId(props.editChange.sourcePartId).documentPart;
                                // let {
                                //     beforeDelta: destinationBeforeDelta,
                                //     foundDelta: destinationfoundDelta,
                                // } = new DeltaManager().findBlot({
                                //     //delta:finalDeltaOps,
                                //     documentPart: destinationDocumentPart,
                                //     uuid: props.blotId
                                // });

                                // console.log(destinationBeforeDelta);
                                // console.log(destinationfoundDelta);

                            }




                            //})
                            setEditChangedTimestamp(Date.now());

                            props.stores.bookStore.blotsChangedTimestamp = Date.now();

                            if (props.onDelete) {
                                props.onDelete(props.editChange);
                            }










                        } catch (err) {
                            //console.log(err);
                        }
                    }} />

                </div>
            </div>


        </ListItem>
    );
};


export default inject('stores')(observer(EditChangeListItem));