import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { v4 as uuid }  from 'uuid';
//import uuid from 'uuid';

export default {
    FRAGMENT_BOXSET_DETAILS: gql`
        fragment BoxSetDetails on BoxSet {
            id
            bookId
            ownerId
            version
            createdAt
            updatedAt
            deletedAt
            __typename

            # owner is probably not being returned by the api
            # owner {
            #     id
            #     cognitoId
            #     name
            #     profilePicUrl
            # }
        }`,

    FRAGMENT_BOOK_DETAILS: gql`
        fragment BookDetails on Book {
            id
            title
            subTitle
            authorName
            coverUrl
            coverUrlType
            coverThumbnailUrl
            coverThumbnailUrlType
            commonAllTitlepagesHeaderUrl
            commonAllHalftitlesHeaderUrl
            commonAllPartsHeaderUrl
            commonVolumeHeaderUrl
            commonPartHeaderUrl
            commonChapterHeaderUrl
            publisherLogoImageSrc
            publisherLogoImageType
            publisherLogoUrl
            appleStoreLinkUrl
            bookbubStoreLinkUrl
            customStoreLinkUrl
            googleStoreLinkUrl
            kindleStoreLinkUrl
            koboStoreLinkUrl
            nookStoreLinkUrl       
            appleIdentifier
            bookbubIdentifier
            customIdentifier
            googleIdentifier
            kindleIdentifier
            koboIdentifier
            nookIdentifier
            cognitoId
            boxSetId
            ownerId
            primaryBookDraftId
            owner {
                id
                cognitoId
                name
                profilePicUrl
                __typename
            }
            recipe {
				id
				__typename
				ownerId
				type
				json
				version
			}
            version
            createdAt
            updatedAt
            deletedAt
            __typename

        }`,

    FRAGMENT_BOOKDRAFT_DETAILS: gql`
        fragment BookDraftDetails on BookDraft {
            id
            bookDraftType
            bookId
            ownerId
            version
            createdAt
            updatedAt
            deletedAt
            __typename

        }`,

    FRAGMENT_BOOKDRAFT_DOCUMENTPART_DETAILS: gql`
        fragment BookDraftDocumentPartDetails on BookDraftDocumentPart {
            id
            partId
            bookDraftId
            parentPartId
            prevPartId
            originalParentPartId
            originalPrevPartId
            role
            ownerId
            version
            createdAt
            updatedAt
            deletedAt
            __typename
        }`,


    FRAGMENT_INFLATABLES_BOOKDRAFT_DOCUMENTPART_DETAILS: gql`
fragment BookDraftDocumentPartDetails on BookDraftDocumentPart {
    id
    partId
    bookDraftId
    parentPartId
    prevPartId
    originalParentPartId
    originalPrevPartId
    role
    ownerId
    version
    updatedAt
    deletedAt
    __typename
}`,

    FRAGMENT_DOCUMENTPART_DETAILS: gql`
        fragment DocumentPartDetails on DocumentPart {
            id
            ownerId
            cognitoId
            owner {
                id
                name
                cognitoId
            }
            partType
            title
            subtitle
            description
            authorName
            excludeFromBook
            webPageUrl
            bookDraftId
            bookId
            deltaOps
            content
            editorDelta
            editCompleted
            originalPartId
            notes
            synopsis
            receivedEditChangesAt
            editingNotes
            completed
            includeTitle
            includeSubtitle
            excludeFromToc
            headingUrl
            includeHeadingUrl
            headerImageType
            overrideCommonVolumeHeadingUrl
            overrideCommonPartHeadingUrl
            overrideCommonChapterHeadingUrl
            hideHeading
            hideHeadingImage
            hideHeadingTitleImage
            hideTitle
            hideSubtitle
            isNumbered
            includeAuthor		
            numberAll
            hideHeadingBackgroundImage
            headingBackgroundUrl
            hideAllHeadings
            hideAllSubtitles
            hideAllHeaderImages
            titleImageType
            titleImageUrl
            restartPartNumbering
            restartChapterNumbering
            hideAllAuthors
            boxSetOverrideRecipeId
            includeFacebook
            includeTwitter
            includeInstagram
            includePinterest
            includeAmazon
            includeBookbub
            includeGoodreads
            includeSnapchat
            includeYoutube
            insertedBookId
            version
            createdAt
            updatedAt
            deletedAt
            __typename
        }`,

    FRAGMENT_INFLATABLES_DOCUMENTPART_DETAILS: gql`
fragment DocumentPartDetails on DocumentPart {
    id
    ownerId
    cognitoId
    owner {
        id
        name
        cognitoId
    }
    partType
    title
    subtitle
    description
    authorName
    excludeFromBook
    webPageUrl
    bookDraftId
    bookId
    deltaOps
    content
    editorDelta
    editCompleted
    originalPartId
    notes
    synopsis
    receivedEditChangesAt
    editingNotes
    completed
    includeTitle
    includeSubtitle
    excludeFromToc
    headingUrl
    includeHeadingUrl
    headerImageType
    overrideCommonVolumeHeadingUrl
    overrideCommonPartHeadingUrl
    overrideCommonChapterHeadingUrl
    hideHeading
    hideHeadingImage
    hideHeadingTitleImage
    hideTitle
    hideSubtitle
    isNumbered
    includeAuthor		
    numberAll
    hideHeadingBackgroundImage
    headingBackgroundUrl
    hideAllHeadings
    hideAllSubtitles
    hideAllHeaderImages
    titleImageType
    titleImageUrl
    restartPartNumbering
    restartChapterNumbering
    hideAllAuthors
    boxSetOverrideRecipeId
    includeFacebook
    includeTwitter
    includeInstagram
    includePinterest
    includeAmazon
    includeBookbub
    includeGoodreads
    includeSnapchat
    includeYoutube
    insertedBookId
    version
    updatedAt
    deletedAt
    __typename

    
    


}`,

    FRAGMENT_RECIPE_DETAILS: gql`
        fragment RecipeDetails on Recipe {
            id
            updatedAt
            type
            title
            sharedByUserId
            ownerId
            json
            description
            version
            createdAt
            updatedAt
            deletedAt
            __typename

        }`,

}











