import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useGetSnapshots, useOpenBookInWrite, useOpenSnapshotInWrite } from '../../managers/BooksManager';
import ButtonTableCell from "../table/ButtonTableCell";
import DeleteSnapshotPanel from "../panels/DeleteSnapshotPanel";
import RestoreSnapshotPanel from "../panels/RestoreSnapshotPanel";
import StandardDialog from "../StandardDialog";

const BookSnapshotsList = (props) => {

  let user = props.stores.userStore.user;

  let { book } = props;

  const { openBookInWrite } = useOpenBookInWrite(props);
  const { openSnapshotInWrite } = useOpenSnapshotInWrite(props, false);
  const [showCreateSnapshotDialog, setShowCreateSnapshotDialog] = React.useState(false);
  const [showRestoreSnapshotDialog, setShowRestoreSnapshotDialog] = React.useState(false);
  const [showDeleteSnapshotDialog, setShowDeleteSnapshotDialog] = React.useState(false);
  const [selectedSnapshot, setSelectedSnapshot] = React.useState(null);


  let { listSnapshots, snapshots, error: listSnapshotsError, loading: listSnapshotsLoading } = useGetSnapshots();

  useEffect(() => {

    if (book != null) {
      listSnapshots({
        bookId: book.id,
        onCompleted: () => {

        }
      })
    }
  }, [book]);


  useEffect(() => {

    if (snapshots != null) {

      //console.log(snapshots);
    }
  }, [snapshots]);




  const createSecondaryActions = (snapshot) => {

    let secondaryActions = [];


    secondaryActions.push(<ButtonTableCell onClick={() => {
      setSelectedSnapshot(snapshot);
      setShowRestoreSnapshotDialog(true)
    }}>Restore</ButtonTableCell>);

    secondaryActions.push(<ButtonTableCell onClick={() => {
      openSnapshotInWrite({
        bookDraftId: book.primaryBookDraftId,
        snapshotId: snapshot.id
      });
    }}>Load</ButtonTableCell>);


    return secondaryActions;
  }

  return (
    <List component="div" disablePadding>
      {
        snapshots.map((snapshot) => {

          return <ListItem
            secondaryAction={createSecondaryActions(snapshot)}
          >
            <ListItemAvatar>
              <Typography>
                {snapshot.name}
              </Typography>
            </ListItemAvatar>
            {/* <ListItemText primary={userConnection?.connectedUser?.name} secondary="" /> */}

          </ListItem>
        })
      }
      <StandardDialog
        open={showRestoreSnapshotDialog}
        onClose={() => {
          setShowRestoreSnapshotDialog(false)
        }}
        onOk={() => {


        }}
        title={'Restore Snapshot'}
        dialogContent={
          <RestoreSnapshotPanel
            book={book}
            snapshot={selectedSnapshot}
            onClose={() => {
              setShowRestoreSnapshotDialog(false)
            }}
            onRestored={() => {
              setShowRestoreSnapshotDialog(false)
              setSelectedSnapshot(null);
              openBookInWrite({
                bookDraftId: book.primaryBookDraftId
              });
            }} />
        }
      >
      </StandardDialog>

      <StandardDialog
        open={showDeleteSnapshotDialog}
        onClose={() => {
          setShowDeleteSnapshotDialog(false)
        }}
        onOk={() => {


        }}
        title={'Delete Snapshot'}
        dialogContent={
          <DeleteSnapshotPanel book={book} snapshot={selectedSnapshot}

            onClose={() => {
              setShowDeleteSnapshotDialog(false)
            }}
            onDeleted={() => {
              setShowDeleteSnapshotDialog(false)
              setSelectedSnapshot(null);

            }} />
        }
      >
      </StandardDialog>
    </List>

  )
}

export default inject('stores')(observer(BookSnapshotsList));