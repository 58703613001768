import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import SharedWithPanel from '../../panels/connections/SharedWithPanel';
import ContributorsList from '../../lists/ContributorsList';
import ContributorsPanel from '../../panels/ContributorsPanel';

const WriteDetailsContributors = (props) => {

    const theme = useTheme();

    let { bookDraftDocumentPart,bookDraft, hideAddContributor} = props;


    let userId = props.stores?.bookStore.userId;

 


    return (    
        <Box container direction={'column'} sx={{
            justifyContent: 'center',
            //marginRight:'1em'
        }}>
          <ContributorsPanel book={bookDraft?.book} hideAddContributor={hideAddContributor}/>
         
        </Box>
    );
};


export default inject('stores')(observer(WriteDetailsContributors));
