import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import config from '../../aws-exports';
import StandardImageViewer from '../StandardImageViewer';
import ReactTimeAgo from 'react-time-ago';
import Feather from "mdi-material-ui/Feather";
import FormatPaint from "@mui/icons-material/FormatPaint";
import AccountEdit from 'mdi-material-ui/AccountEdit';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import StandardRoundedAction from '../actions/StandardRoundedAction';
import StandardTypographyContent1 from '../StandardTypographyContent1';
import StandardTypographyContent2 from '../StandardTypographyContent2';

const ImageTitleTableCell = (props) => {


  let {
    bucketName,
    s3Src, title,
    details,
    showWriteIcon = false,
    showFormatIcon = false,
    showEditIcon = false,
    showReadIcon = false,
    onWriteSelected = () => { },
    onFormatSelected = () => { },
    onEditSelected = () => { },
    onReadSelected = () => { }
  } = props;
  const theme = useTheme();

  let profilePicSrc = 'https://' + bucketName + ".s3.amazonaws.com/" + s3Src;

  let actions = [];

  if (showWriteIcon) {
    actions.push(<StandardRoundedAction icon={<Feather sx={{
      color: theme.palette.standardActions.icon.color.main,

    }} onClick={(e) => {
      e.stopPropagation();
      onWriteSelected();

    }} />} />)
  }
  if (showFormatIcon) {
    actions.push(<StandardRoundedAction icon={<FormatPaint sx={{
      color: theme.palette.standardActions.icon.color.main,

    }} onClick={(e) => {
      e.stopPropagation();
      onFormatSelected();

    }} />} />)
  }

  if (showEditIcon) {
    actions.push(<StandardRoundedAction icon={<AccountEdit sx={{
      color: theme.palette.standardActions.icon.color.main,

    }} onClick={(e) => {
      e.stopPropagation();
      onEditSelected();

    }} />} />)
  }

  if (showReadIcon) {
    actions.push(<StandardRoundedAction icon={<LocalLibraryIcon sx={{
      color: theme.palette.standardActions.icon.color.main,

    }} onClick={(e) => {
      e.stopPropagation();
      onReadSelected();

    }} />} />)
  }


  // return (

  //   <StandardImageViewer
  //     bucketName={config.user_uploads_bucket_name}
  //     //s3Path={'uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/b6f59120-18fe-11ed-b9d5-076ac854f9a5_planetStrider.png'}
  //     s3Path={s3Src}
  //     height={'5em'}
  //     fit={'contain'}
  //     sx={{
  //       minHeight: '5em'
  //       //display: { sm: 'block', lg: 'block', md: 'block' } 
  //     }}
  //     style={{ borderRadius: 2 }}
  //     className="custom-class"
  //     onClick={(e) => {
  //       // e.preventDefault();
  //       // setShowGallery(true);

  //     }}
  //     showLoading
  //     errorIcon

  //   />

  


  // )


  return (

    <Grid container sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      //alignItems: 'center',
      //border: '1px solid red',
      //width:'100%',
      cursor: 'pointer'
    }}>
      <Grid item xs={3} sx={{
        //paddingRight: '1em'
      }}>
        <StandardImageViewer
          bucketName={config.user_uploads_bucket_name}
          //s3Path={'uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/b6f59120-18fe-11ed-b9d5-076ac854f9a5_planetStrider.png'}
          s3Path={s3Src}
          height={'5em'}
          fit={'contain'}
          sx={{
            minHeight: '5em'
            //display: { sm: 'block', lg: 'block', md: 'block' } 
          }}
          style={{ borderRadius: 2 }}
          className="custom-class"
          onClick={(e) => {
            // e.preventDefault();
            // setShowGallery(true);

          }}
          showLoading
          errorIcon

        />

      </Grid>
      <Grid item xs={9} >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
            onClick={props.onClick}
          >
            {/* <Typography
              variant={theme.palette.text.content1.variant}
              sx={{
                color: theme.palette.text.content1.color,
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}>
              {title}
            </Typography> */}
            <StandardTypographyContent1>
              {title}
            </StandardTypographyContent1>



            {/* <Typography variant="body1" style={{ fontWeight: 'bold' }}>{title}</Typography> */}
            {/* <Typography variant="body2">
              {details}
              </Typography> */}
            <StandardTypographyContent2>
              {details}
            </StandardTypographyContent2>
          </div>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            {actions}
          </Box>
        </div>
      </Grid>

    </Grid>


  )
}
export default inject('stores')(observer(ImageTitleTableCell));