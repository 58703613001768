import React, { useState, useEffect } from "react";
import { Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import config from '../../../aws-exports';
import { useGetOwnedBooks, useOpenBookInWrite, useOpenBookInFormat, } from '../../../managers/BooksManager';
import ReactTimeAgo from 'react-time-ago';
import EmptyContent from "../../EmptyContent";
import Avatar from '@mui/material/Avatar';
import Feather from 'mdi-material-ui/Feather';
import IconButton from '@mui/material/IconButton';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import helpers from "../../../helpers/helpers";
import StandardImageViewer from "../../boilerPlate/StandardImageViewer";
import BookImageViewer from "../../boilerPlate/BookImageViewer";

const ListOwnedBooks = (props) => {

    let user = props.stores.userStore.user;
    let book = props.stores.bookStore.bookListBook;

    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    let { filteredBooks, filteredBookIds, onSelectBook, selectedBook, showWriteIcon = true, showFormatIcon = true, showAddIcon = false, s3Src, order = 'desc', orderBy = 'createdAt' } = props;

    const { ownedBooks, error, loading, refetch, networkStatus } = useGetOwnedBooks(props);
    const [availableBooks, setAvailableBooks] = useState([])
    const { openBookInWrite } = useOpenBookInWrite(props);
    const { openBookInFormat } = useOpenBookInFormat(props);

    // const [order, setOrder] = React.useState('asc');
    // const [orderBy, setOrderBy] = React.useState('createdAt');



    useEffect(() => {

        //console.log(ownedBooks);
        // setOrder("desc");
        // setOrderBy("createdAt");

    }, [ownedBooks]);

    //Always filter out the deleted books.
    let remainingBooks = ownedBooks?.filter(f => f.deletedAt == null);




    if (filteredBooks) {

        remainingBooks = remainingBooks.filter(ownedBook => !filteredBooks.find(rm => (rm.id === ownedBook.id)))

    }

    if (filteredBookIds) {

        remainingBooks = remainingBooks.filter(ownedBook => !filteredBookIds.find(bookId => (bookId === ownedBook.id)))

    }


    remainingBooks = helpers.stableSort(remainingBooks, order, orderBy);



    const generateSecondaryActions = (book) => {

        const secondaryActions = [];

        if (showWriteIcon) {
            secondaryActions.push(<IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    //console.log(book)

                    openBookInWrite({
                        bookDraftId: book.primaryBookDraftId
                    });
                }}>
                <Feather />
            </IconButton>)
        }

        if (showFormatIcon) {
            secondaryActions.push(<IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    openBookInFormat({
                        bookDraftId: book.primaryBookDraftId
                    });
                }}>
                <FormatPaintIcon />
            </IconButton>)
        }

        if (showAddIcon) {
            secondaryActions.push(<IconButton
                onClick={(e) => {
                    e.preventDefault();
                    props.onSelectBook(book);
                }}
            >
                <AddIcon />
            </IconButton>)
        }

        return secondaryActions;
    }




    return (
        <div>
            {/* <div>
                Error:{error ? error : 'no error'}
            </div>
            <div>
                Loading:{loading ? 'loading' : 'not loading'}
            </div>
            <div>
                networkStatus:{networkStatus}
            </div>
            <div>
                <button onClick={() => refetch()}>
                    Refetch!
                </button>
            </div> */}

            <List>
                {remainingBooks && remainingBooks.length > 0 ?
                    remainingBooks.map((book, index) => {

                        //console.log(index);
                        let selectedIndex = false;
                        if (book.id == selectedBook?.id) {
                            selectedIndex = index;
                        }
                        return <ListItem
                            selected={selectedIndex === index}
                            secondaryAction={
                                generateSecondaryActions(book)
                            }
                        >
                            {/* <ListItemAvatar> */}
                            {/* <Avatar variant="rounded"
                                    bucketName={config.user_uploads_bucket_name}
                                    s3Src={book?.coverUrl}
                                >
                                </Avatar> */}
                            {/* <StandardImageViewer
                                    bucketName={config.user_uploads_bucket_name}
                                    s3Path={book?.coverUrl}
                                    height={'5em'}
                                    fit={'contain'}
                                    sx={{
                                        minHeight: '5em'
                                        //display: { sm: 'block', lg: 'block', md: 'block' } 
                                    }}
                                    style={{ borderRadius: 2 }}
                                    className="custom-class"
                                    onClick={(e) => {
                                        // e.preventDefault();
                                        // setShowGallery(true);

                                    }}
                                    // showLoading
                                    // errorIcon

                                /> */}
                            <BookImageViewer s3Path={book?.coverUrl} />
                            {/* </ListItemAvatar> */}
                            <ListItemText
                                primary={book?.title}
                                secondary={book.createdAt != null ? <ReactTimeAgo date={book.createdAt} locale="en-US" /> : ''}
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onListSelectBook(book);
                                }}
                            />
                        </ListItem>
                    }
                    )
                    :
                    <EmptyContent tagline={'Create a book!'} loading={loading} />
                }
            </List>
        </div>
    )
}

export default inject('stores')(observer(ListOwnedBooks));