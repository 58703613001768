import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useLazyListAllBookDraftUserComments, useListAllBookDraftUserComments, useLazyListBookDraftUserComments } from '../../persistance/persistanceHooks';

const FeedbackList = (props) => {

  let user = props.stores.userStore.user;
  let [userCommentItems, setUserCommentItems] = useState([]);

  let { book } = props;

  const [listBookDraftUserComments, { loading: listBookDraftUserCommentsLoading, error: listBookDraftUserCommentsError, data: listBookDraftUserCommentsData, refetch: listBookDraftUserCommentsRefetch }] = useLazyListAllBookDraftUserComments();


  const [listUserBookDraftUserComments, { loading: listUserBookDraftUserCommentsLoading, error: listUserBookDraftUserCommentsError, data: listUserBookDraftUserCommentsData, refetch: listUserBookDraftUserCommentsRefetch }] = useLazyListBookDraftUserComments();
 
  useEffect(() => {

    if (props.ownerMode) {

      listBookDraftUserComments({
        variables: {
          bookDraftId: props.book.bookDraft.id
        }

      })
    } else {

      listUserBookDraftUserComments({
        variables: {
          bookDraftId: props.book.bookDraft.id
        }

      })


    }

  }, []);

  useEffect(() => {


    //console.log(listBookDraftUserCommentsData);
    if (listBookDraftUserCommentsData && props.documentPart) {

      // try {
      //   props.stores.bookStore.userComments.replace(listBookDraftUserCommentsData.listAllBookDraftUserComments.items.map((commentData) => {
      //     return new UserCommentPojo({ data: commentData })
      //   }));

      // } catch (err) {
      //   console.log(err);
      // }

      let commentItems = createUserCommentItem(listBookDraftUserCommentsData.listAllBookDraftUserComments, props.documentPart);



      let filteredPinnedOnly = commentItems.filter(f => f.isPinned);
      let filteredNoPinned = commentItems.filter(f => !f.isPinned);

      commentItems = filteredPinnedOnly.concat(filteredNoPinned);
      setUserCommentItems(commentItems.map((userComment) => {
        // return <UserComment ownerMode={props.ownerMode} userComment={userComment} onUpdated={() => { }} />

        return <div>{userComment.comment}</div>
      }))
      //setUserCommentItems(filteredPinnedOnly.concat(filteredNoPinned));



    }
  }, [
    listBookDraftUserCommentsData,
    // showComments,
    // props.documentPart,
    // props.stores.bookStore.viewFavoritedComments,
    // props.stores.bookStore.viewPinnedComments,
    // props.stores.bookStore.viewCompletedComments,
    // props.stores.bookStore.viewPendingComments
  ]);


  useEffect(() => {

    //userComments={userComments.filter(f => f.documentPartId == bookDraftDocumentPart.documentPart.id)}


    //console.log(listUserBookDraftUserCommentsData);
    if (listUserBookDraftUserCommentsData && props.documentPart) {

      //setUserComments(listBookDraftUserCommentsData.listAllBookDraftUserComments.items);
      //props.stores.bookStore.userComments = listBookDraftUserCommentsData.listAllBookDraftUserComments.items
      //props.stores.bookStore.setUserComments(listBookDraftUserCommentsData.listAllBookDraftUserComments.items);


      //setUserCommentItems(createUserCommentItem(listUserBookDraftUserCommentsData.listBookDraftUserComments,props.documentPart))
      let commentItems = createUserCommentItem(listUserBookDraftUserCommentsData.listBookDraftUserComments, props.documentPart);


      let filteredPinnedOnly = commentItems.filter(f => f.isPinned);
      let filteredNoPinned = commentItems.filter(f => !f.isPinned);

      commentItems = filteredPinnedOnly.concat(filteredNoPinned);
      setUserCommentItems(commentItems.map((userComment) => {
        // return <UserComment ownerMode={props.ownerMode} userComment={userComment} onUpdated={() => { }} />
        return <div>{userComment.comment}</div>
      }))









    }
  }, [
    listUserBookDraftUserCommentsData,
    props.documentPart]);

  const createUserCommentItem = (data, documentPart) => {

    let { viewFavoritedComments, viewPinnedComments, viewCompletedComments, viewPendingComments } = props.stores.bookStore;


    let filteredComments = data.items;

    filteredComments = filteredComments.filter((f) => {

      if (viewFavoritedComments || viewPinnedComments || viewCompletedComments || viewPendingComments) {

        if (viewFavoritedComments && f.isFavorite) return f;
        if (viewPinnedComments && f.isPinned) return f;
        if (viewCompletedComments && f.completedAt) return f;
        if (viewPendingComments && !f.completedAt) return f;

      }
      else {
        return f;
      }


    });




    let userCommentItems = filteredComments.filter(f => f.documentPartId == documentPart.id).map((userComment) => {


      // return <UserComment ownerMode={props.ownerMode} userComment={userComment} onUpdated={() => {}} />
      return userComment;

    });


    documentPart.bookDraftDocumentPart.getSortedChildren().forEach((bddp) => {
      userCommentItems = userCommentItems.concat(createUserCommentItem(data, bddp.documentPart));
    })


    return userCommentItems;

  }



  const createSecondaryActions = (editRequest) => {

    let secondaryActions = [];

    if (props.showSelectIcon) {
      secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
        // openEditRequestInEdit({
        //   editRequest
        // });
      }}>
        <AccountEdit />
      </IconButton>);
    }

    return secondaryActions;
  }

  return (
    <List component="div" disablePadding>
      {userCommentItems}
      {/* {
        listBookDraftUserComments.map((boxsetContributor) => {

          return <ListItem
            secondaryAction={createSecondaryActions(boxsetContributor)}
          >
            <ListItemAvatar>
              <AvatarTableCell
                bucketName={config.user_uploads_bucket_name}
                s3Src={boxsetContributor.contributor.profilePicUrl}
                title={boxsetContributor.contributor.name}
              />
            </ListItemAvatar>
           
           
          </ListItem>
        })
      } */}
    </List>

  )
}

export default inject('stores')(observer(FeedbackList));