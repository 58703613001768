import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import config from '../../aws-exports';
import useMediaQuery from '@mui/material/useMediaQuery';
import StretchBox from '../boilerPlate/StretchBox';
import { Box, Button, SwipeableDrawer, Typography } from '@mui/material';
import ScrollBox from '../boilerPlate/ScrollBox';
import StandardUICard from '../UI/StandardUICard';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import ListOwnedBoxsets from './lists/ListOwnedBoxsets';
import StandardUICirclePlus from '../UI/StandardUICirclePlus';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import CreateBoxset from './dialogs/CreateBoxset';
import Link from '@mui/material/Link';
import OwnedBookDetailCard from './listCards/OwnedBookDetailCard';
import BookDetails from './dialogs/BookDetails';
import BoxsetDetails from './dialogs/BoxsetDetails';
import ListBoxsetContributors from './lists/ListBoxsetContributors';
import ListBoxsetContributedBooks from './lists/ListBoxsetContributedBooks';
import OwnedBookExportCard from './listCards/OwnedBookExportCard';
import AddContributor from './dialogs/AddContributor';
import AddContributedBook from './dialogs/AddContributedBook';
import OwnedBoxsetDetailCard from './listCards/OwnedBoxsetDetailCard';

const OwnedBoxsets = (props) => {

    let { book, boxset, onSelectBoxset } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdAt');


    const [showCreateBoxset, setShowCreateBoxset] = useState(false);
    const [showMoreDetail, setShowMoreDetail] = useState(false);
    const [showAddContributor, setShowAddContributor] = useState(false);
    const [showAddContributedBook, setShowAddContributedBook] = useState(false);

    const handleOpenCreateBoxset = () => {
        setShowCreateBoxset(true);
    }

    const handleCloseCreateBoxset = () => {
        setShowCreateBoxset(false);
    }

    const handleMoreDetailOpen = () => {
        setShowMoreDetail(true);
    }

    const handleMoreDetailClose = () => {
        setShowMoreDetail(false);
    }

    const handleAddContributorOpen = () => {
        setShowAddContributor(true);
    }

    const handleAddContributorClose = () => {
        setShowAddContributor(false);
    }

    const handleAddContributedBookOpen = () => {
        setShowAddContributedBook(true);
    }

    const handleAddContributedBookClose = () => {
        setShowAddContributedBook(false);
    }

    const [showDetailDrawer, setShowDetailDrawer] = useState(false);
    let [selectedBoxset, setSelectedBoxset] = useState(null);

    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setShowDetailDrawer(open);
            };


    return (

        <StretchBox flexDirection='row' sxProps={{ gap: isSmallScreen ? 0 : 2 }}>

            <StretchBox sxProps={{
                flex: isSmallScreen ? 12 : 4,
                position: 'relative'
            }}>
                {/* <BoxsetsTable onSelectBoxSet={
                    (boxset) => {
                        setSelectedBoxset(boxset)
                        setShowDetailDrawer(true)
                    }} /> */}

                <ScrollBox>
                    {isSmallScreen ?
                        <StandardUICard
                            title='My Boxsets'
                            showPagination={false}
                            showSearchField={true}
                            onSetOrdering={(orderBy, order) => { setOrder(order); setOrderBy(orderBy) }}
                            children={
                                <ListOwnedBoxsets
                                    selectedBoxset={selectedBoxset}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectBoxset={(boxset) => {
                                        setSelectedBoxset(boxset)
                                        setShowDetailDrawer(true)
                                    }}
                                    // onClick={(event) => {
                                    //     setShowDetailDrawer(true)
                                    //     handleListItemClick(event, 0)
                                    // }}
                                    s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + book?.coverUrl}
                                    showFormatIcon={false}
                                />
                            }
                        />
                        :
                        <StandardUICard
                            title='My Boxsets'
                            showPagination={false}
                            showSearchField={true}
                            onSetOrdering={(orderBy, order) => { setOrder(order); setOrderBy(orderBy) }}
                            children={
                                <ListOwnedBoxsets
                                    selectedBoxset={selectedBoxset}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectBoxset={(boxset) => {
                                        setSelectedBoxset(boxset)
                                        setShowDetailDrawer(true)
                                    }}
                                    // onClick={(event) => {
                                    //     setShowDetailDrawer(true)
                                    //     handleListItemClick(event, 0)
                                    // }}
                                    s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + book?.coverUrl}

                                />
                            }
                        />
                    }




                </ScrollBox>
                <StandardUICirclePlus
                    onClick={handleOpenCreateBoxset}
                />


            </StretchBox>

            <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2 }}>


                    <StandardUICard
                        title='Details'
                        showPagination={false}
                        showSearchField={false}
                        // actions={
                        //     <Link underline='none' color='blue'
                        //         onClick={handleMoreDetailOpen}>
                        //         More
                        //     </Link>
                        // }
                        children={
                            <OwnedBoxsetDetailCard
                                selectedBoxset={selectedBoxset}
                                title={selectedBoxset?.book.title}
                            />
                        }
                    />
                    <StandardUICard
                        title='Contributors'
                        showPagination={true}
                        showSearchField={true}
                        actions={
                            <Fab color="primary" size='small'
                                onClick={handleAddContributorOpen}
                            >
                                <AddIcon />
                            </Fab>
                        }
                        children={
                            <ListBoxsetContributors boxset={selectedBoxset} />
                        }
                    />
                    <StandardUICard
                        title='Contributed Books'
                        showPagination={true}
                        showSearchField={true}
                        actions={
                            <Fab color="primary" size='small'
                                onClick={handleAddContributedBookOpen}
                            >
                                <AddIcon />
                            </Fab>
                        }
                        children={
                            <ListBoxsetContributedBooks boxset={selectedBoxset} />
                        }
                    />
                    <StandardUICard
                        title='Export'
                        showPagination={true}
                        showSearchField={false}
                        actions={
                            <Fab color="primary" size='small'
                            // onClick={handleExportBookOpen}
                            >
                                <AddIcon />
                            </Fab>
                        }
                        children={
                            <OwnedBookExportCard boxset={selectedBoxset} />
                        }
                    />


                </ScrollBox>
            </StretchBox>

            <CreateBoxset
                open={showCreateBoxset}
                onClose={handleCloseCreateBoxset}

            />

            <BoxsetDetails
                selectedBoxset={selectedBoxset}
                title={selectedBoxset?.book.title}
                open={showMoreDetail}
                onClose={handleMoreDetailClose}

            />

            <AddContributor
                boxset={selectedBoxset}
                open={showAddContributor}
                onClose={handleAddContributorClose}
            />

            {/* AddContributedBook */}
            <AddContributedBook
                boxset={selectedBoxset}
                open={showAddContributedBook}
                onClose={handleAddContributedBookClose}
            />


            <SwipeableDrawer
                anchor={'right'}
                open={showDetailDrawer && isSmallScreen}
                onClose={toggleDrawer(false)}
                sx={{ width: '100%' }}
            //variant='persistent'
            //onOpen={setShowDetailDrawer(true)}
            >
                <div style={{ width: '100vw' }}>

                    <StretchBox sxProps={{ flex: isSmallScreen ? 0 : 8 }}>
                        <ScrollBox sxProps={{ display: 'flex', flexDirection: 'column', flex: 8, gap: 2, ml: 0, mr: 0 }}>
                            <IconButton
                                style={{ display: 'flex', justifyContent: 'left' }}
                                onClick={() => {
                                    setShowDetailDrawer(false)
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography
                                variant={theme.landmarkText.variant}
                                gutterBottom
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                {selectedBoxset?.book.title}
                            </Typography>
                            <StandardUICard
                                title='Details'
                                showPagination={false}
                                showSearchField={false}
                                // actions={
                                //     <Link underline='none' color='blue'
                                //         onClick={handleMoreDetailOpen}>
                                //         More
                                //     </Link>
                                // }
                                children={
                                    <OwnedBoxsetDetailCard
                                        selectedBoxset={selectedBoxset}
                                        title={selectedBoxset?.book.title}
                                    />
                                }
                            />
                            <StandardUICard
                                title='Contributors'
                                showPagination={true}
                                showSearchField={true}
                                actions={
                                    <Fab color="primary" size='small'
                                        onClick={handleAddContributorOpen}
                                    >
                                        <AddIcon />
                                    </Fab>
                                }
                                children={
                                    <ListBoxsetContributors boxset={selectedBoxset} />
                                }
                            />
                            <StandardUICard
                                title='Contributed Books'
                                showPagination={true}
                                showSearchField={true}
                                actions={
                                    <Fab color="primary" size='small'
                                        onClick={handleAddContributedBookOpen}
                                    >
                                        <AddIcon />
                                    </Fab>
                                }
                                children={
                                    <ListBoxsetContributedBooks boxset={selectedBoxset} />
                                }
                            />
                            <StandardUICard
                                title='Export'
                                showPagination={true}
                                showSearchField={false}
                                actions={
                                    <Fab color="primary" size='small'
                                    // onClick={handleExportBookOpen}
                                    >
                                        <AddIcon />
                                    </Fab>
                                }
                                children={
                                    <OwnedBookExportCard boxset={selectedBoxset} />
                                }
                            />


                        </ScrollBox>
                    </StretchBox>
                </div>
            </SwipeableDrawer>


        </StretchBox>
    )
}
export default inject('stores')(observer(OwnedBoxsets));