import React, { useState, useEffect } from "react";
import { Grid, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import config from '../../../aws-exports';
import { useGetReceivedEditRequests, useOpenEditRequestInEdit } from '../../../managers/EditRequestsManager';
import { useGetUserReceivedEditRequests } from '../../../persistance/persistanceHooks';
import ShareIcon from '@mui/icons-material/Share';
import ReactTimeAgo from 'react-time-ago';
import EmptyContent from "../../EmptyContent";
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import helpers from '../../../helpers/helpers';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import BookImageViewer from "../../boilerPlate/BookImageViewer";
import { EditRequest } from '../../../domain/bookModel';

const ListReceivedRequests = (props) => {

    const { openEditRequestInEdit } = useOpenEditRequestInEdit(props);

    let userId = props.stores.userStore.user?.id;
    let { showEditIcon = true, order='desc', orderBy='createdAt' } = props;


    const [editRequests, setEditRequests] = useState([])
    const { loading, error, data, refetch } = useGetUserReceivedEditRequests({
        userId
    });


    // let selectedEditRequest = props.stores.bookStore.editRequestListReceivedEditRequest;

    let selectedEditRequest = props.selectedEditRequest;

    useEffect(() => {

        if (data) {


            data.getUser.editRequests.items.forEach((editRequest, index) => {

                //console.log(editRequest);
                let editRequestBook = editRequest.book;
                //return <div>a boxset</div>
                //return <BookListItem title={title} coverImage={'https://images-na.ssl-images-amazon.com/images/I/51EV7oJMlbL._SY291_BO1,204,203,200_QL40_FMwebp_.jpg'} />
                let selected = false;

                if (selectedEditRequest && selectedEditRequest.id == editRequest.id) {
                    props.stores.bookStore.editRequestListReceivedEditRequest = editRequest;
                }

                if (selectedEditRequest == null && index == 0) {
                    props.stores.bookStore.editRequestListReceivedEditRequest = editRequest;
                }




            });


            setEditRequests(data.getUser.editRequests.items.map((item) => {
                return new EditRequest({ data: item })
            }));
        }
    }, [data])



    let editRequestItems = [];


    try {
        if (data) {
            editRequestItems = editRequests.map((editRequest, index) => {

                let editRequestBook = editRequest.book;


                //console.log(index);
                let selectedIndex = null;
                if (editRequest.id == selectedEditRequest?.id) {
                    selectedIndex = index;
                }

                return <ListItem
                selected={selectedIndex===index}
                    secondaryAction={
                        generateSecondaryActions(editRequest)
                    }
                > <ListItemAvatar>
                        <Avatar variant="rounded"
                            bucketName={config.user_uploads_bucket_name}
                            s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + editRequest?.book?.coverUrl}
                        >
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={editRequest.book?.title}
                        secondary={editRequest.requestorUser?.name}
                        onClick={(e) => {
                            e.preventDefault();
                            props.onSelectRequest(editRequest);
                        }}

                    />
                </ListItem>


            });
        }
    } catch (err) {
        console.log(err);
    }





    const generateSecondaryActions = (editRequest) => {

        let secondaryActions = [];

        if (showEditIcon) {
            secondaryActions.push(<IconButton edge="end" aria-label="delete"
                onClick={(e) => {
                    e.preventDefault();
                    openEditRequestInEdit({
                        editRequest
                    });
                }}
            >
                <AccountEdit />
            </IconButton>);
        }

        return secondaryActions;
    }

    editRequestItems = helpers.stableSort(editRequestItems, order, orderBy);



    return (
        <div>
            <List>
                {
                
                // editRequestItems && editRequestItems.length > 0 ?
                //     editRequestItems

                    editRequests && editRequests.length > 0 ?
                        editRequests.map((editRequest, index) => {

                            //console.log(index);
                            let selectedIndex = null;
                            if (editRequest.id == selectedEditRequest?.id) {
                                selectedIndex = index;
                            }
                            return <ListItem
                            selected={selectedIndex===index}
                                secondaryAction={
                                    generateSecondaryActions(editRequest)
                                }
                            > <ListItemAvatar>
                                   <BookImageViewer s3Path={editRequest?.book?.coverUrl}/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={editRequest.book?.title}
                                    secondary={editRequest.requestorUser?.name}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.onSelectRequest(editRequest);
                                    }}

                                />
                            </ListItem>




                    }


                    )
                    :
                    <EmptyContent tagline={'Received edit requests will appear here.'} />
                }
            </List>
        </div>
    )
}

export default inject('stores')(observer(ListReceivedRequests));