import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Divider, Grid, Stack, styled, Typography } from "@mui/material";
import { Paper } from "@mui/material";
import StandardDashboardPaper from "../StandardPaper";
import { StandardPaper } from "../StandardPaper";
import { useTheme } from '@mui/material/styles';
import { useGetOwnedBooks } from '../../managers/BooksManager';
import StandardBookCoverTitle from "../StandardBookCoverTitle";
import BookListItem from "../lists/BookListItem";
//import faker from 'faker';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

const GoalsCardContent = (props) => {
//export default function GoalsCardContent(props) {

  const { ownedBooks, error } = useGetOwnedBooks(props);


  const theme = useTheme();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,

    scales: {
      xAxes: [{
        display: true,
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: true,
          labelString: 'Month'
        }
      }],
      yAxes: [{
        display: true,
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: true,
          labelString: 'Value'
        }
      }]
    },

    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Average Word Count',
      },

    },
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  const data = {
    labels,
    datasets: [
      {
        label: 'Book 1',
        data: [500, 1200, 601, 300, 450, 200, 250],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Book 2',
        data: [600, 1300, 401, 500, 650, 300, 150],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return (

    <Box>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: '0.5em' }}>Goal:</Typography>
        <Typography variant="body2">20,000</Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: '0.5em' }}>Written Today:</Typography>
        <Typography variant="body2">2,000</Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: '0.5em' }}>Written This Week:</Typography>
        <Typography variant="body2">5,000</Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: '0.5em' }}>Written This Month:</Typography>
        <Typography variant="body2">10,000</Typography>
      </div>
      <Line options={options} data={data} />
    </Box>
  );
}

export default inject('stores')(observer(GoalsCardContent));