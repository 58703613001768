import React, { useState, useEffect } from "react";
import { Grid, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import config from '../../../aws-exports';
import { useGetSharedBooks, useOpenBookInWrite, useOpenBookInRead, useOpenBookInFormat, useManagerCreateBook } from '../../../managers/BooksManager';
import ShareIcon from '@mui/icons-material/Share';
import StandardUISquareList from "../../UI/StandardUISquareList";
import ReactTimeAgo from 'react-time-ago';
import EmptyContent from "../../EmptyContent";
import Avatar from '@mui/material/Avatar';
import Feather from 'mdi-material-ui/Feather';
import IconButton from '@mui/material/IconButton';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ListItemButton from '@mui/material/ListItemButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import helpers from '../../../helpers/helpers';

const ListSharedBooks = (props) => {

    let { book, showReadIcon = true, s3Src, selectedBook, order='desc', orderBy='createdAt' } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { sharedBooks, error: getSharedWithBooksError, loading: getSharedWithBooksLoading } = useGetSharedBooks(props);

    const { openBookInRead } = useOpenBookInRead(props);


    let sortedSharedBook = [];
    if(sharedBooks!=null){
  
      sortedSharedBook = sharedBooks.map((sharedBook)=>{
  
        let compositeSharedBook = {
          object:sharedBook,
          title:sharedBook.title,
          createdAt:sharedBook.sharedAt
          
        }
        
  
        return compositeSharedBook;
      });
      sortedSharedBook = helpers.stableSort(sortedSharedBook, order, orderBy, props.stores.bookStore.sortOrder, props.stores.bookStore.sortOrderBy==='alpha'?'title':props.stores.bookStore.sortOrderBy);
  
    }

const generateSecondaryActions = (sharedBook) => {

    const secondaryActions = [];

    if (showReadIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
                //console.log(sharedBook)
                openBookInRead({
                    //bookDraftId: sharedBook.primaryBookDraftId,
                    sharedBook:sharedBook
                });

            }}>
            <ManageSearchIcon />
        </IconButton>)
    }
    return secondaryActions;
}
    

    
    return (
        <div>
            <List>
                {sharedBooks && sharedBooks.length > 0 ?
                    sortedSharedBook.map((compositeSharedBook, index) => {

                        
                  let sharedBook = compositeSharedBook.object;

                        //console.log(index);
                        let selectedIndex = null;
                        if (sharedBook.id == selectedBook?.id) {
                            selectedIndex = index;
                        }
                        return <ListItem
                        selected={selectedIndex===index}
                            secondaryAction={
                                generateSecondaryActions(sharedBook)
                            }
                        > <ListItemAvatar>
                                <Avatar variant="rounded"
                                    bucketName={config.user_uploads_bucket_name}
                                    s3Src={sharedBook?.coverUrl}
                                >
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={sharedBook?.title}
                                secondary={sharedBook.sharedAt != null ? <ReactTimeAgo date={sharedBook.sharedAt} locale="en-US" /> : ''}
                                // secondary={'Time ago'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelectBook(sharedBook);
                                  }}
                           />
                        </ListItem>




                    }


                    )
                    :
                    <EmptyContent tagline={'Books that have been shared with  you will appear here.'} />
                }
            </List>
        </div>
    )
}

export default inject('stores')(observer(ListSharedBooks));