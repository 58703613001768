import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { inject, observer } from 'mobx-react';
import Quill from 'quill';
//import Delta from 'quill-delta';
import { v4 as uuid } from 'uuid';
import EditingContext from './EditingContext';
import BlurbsBlot from './blots/BlurbsBlot';
import AlsobysBlot from './blots/AlsobysBlot';
import VerseBlot from './blots/VerseBlot';
import BookMerlinBlock from './blots/BookMerlinBlock';
import BookMerlinTextBlot from './blots/BookMerlinTextBlot';
import CharacterBlot from './blots/CharacterBlot';
import CurrentSelectionBlot from './blots/CurrentSelectionBlot';
import CommentBlot from './blots/CommentBlot';
import NoteBlot from './blots/NoteBlot';
import AttributionBlot from './blots/AttributionBlot';
import BlockQuoteBlot from './blots/BlockQuoteBlot';
import SubheadBlot from './blots/SubheadBlot';
import PublisherBlot from './blots/PublisherBlot';
import ContributorsBlot from './blots/ContributorsBlot';
import DecoratedBreakBlot2 from './blots/DecoratedBreakBlot2';
import EditingInsertBlot from './blots/EditingInsertBlot';
import EditingDeleteBlot from './blots/EditingDeleteBlot';
import EditingMovedTombstoneBlot from './blots/EditingMovedTombstoneBlot';
import EditingMovedDestinationBlot from './blots/EditingMovedDestinationBlot';
import EditingMovedPreviewBlot from './blots/EditingMovedPreviewBlot';
import EditingInsertNewLineBlot from './blots/EditingInsertNewLineBlot';
import EditingInsertNewLineBlot2 from './blots/EditingInsertNewLineBlot2';
import EditingDeleteNewLineBlot from './blots/EditingDeleteNewLineBlot';
import Link2 from './blots/Link2';
import ImageBlot4 from './blots/ImageBlot4';
import FullPageImageBlot from './blots/FullPageImageBlot';
import TOCBlot from './blots/TOCBlot';
import { useUpdateDocumentPartBatch, useLazyGetDocumentPart, useCreateSharedBookUserComment } from '../../persistance/persistanceHooks';
import Counter from './modules/Counter';
import { Box, Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import EntityManager from '../../domain/EntityManager';
import ChangeTracker from '../editor/modules/ChangeTracker';
import ChangeTracker2 from '../editor/modules/ChangeTracker2';
//import History from '../editor/modules/history';
import History2 from '../editor/modules/history2';
import Keyboard from '../editor/modules/keyboard';
import DeltaManager from './DeltaManager';
import UserCommentInput from './UserCommentInput';
import UserComment from './UserComment';
import { UserComment as UserCommentPojo } from '../../domain/bookModel';
import editingUtils from '../../helpers/editingUtils';
import EditingProcessor from '../../helpers/EditingProcessor';
import { useLanguageToolEval } from '../../persistance/persistanceHooks';
import Mark from "mark.js";
import { useTheme } from '@mui/material/styles';
import { showAllComments, showDocumentPartComments, showDocumentPartNotes } from '../../managers/BooksManager';
import ToolbarItem from './toolbar/ToolbarItem';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import ChatIcon from '@mui/icons-material/Chat';
import EditorToolTip from './EditorToolTip';
import AppContext, { AppContextProvider } from '../../AppContext';
import { enqueueSnackbar } from 'notistack'
//import ContextMenu from '../tree/ContextMenu';
let Block = Quill.import('blots/block');
let Delta = Quill.import('delta');

const editingProcessor = new EditingProcessor();
let editingInProcess = false;
let Inline = Quill.import('blots/inline');

var $ = require("jquery");
$.fn.mark = function (sv, opt) {
    new Mark(this.get()).mark(sv, opt);
    return this;
};
$.fn.markRegExp = function (regexp, opt) {
    new Mark(this.get()).markRegExp(regexp, opt);
    return this;
};
$.fn.markRanges = function (ranges, opt) {
    new Mark(this.get()).markRanges(ranges, opt);
    return this;
};
$.fn.unmark = function (opt) {
    new Mark(this.get()).unmark(opt);
    return this;
};
require('quill/dist/quill.snow.css')

const Clipboard = Quill.import('modules/clipboard');

class TabMitigatingClipboard extends Clipboard {
    convert(html = null) {
        var delta = super.convert(html);
        try {

            if (
                delta.ops.length > 0 &&
                delta.ops[0].hasOwnProperty("insert") &&
                delta.ops[0].insert.charAt(0) === "\t"
            ) {
                delta = delta.compose(new Delta().delete(1));
            }

        } catch (err) {
            //console.log(err);
        }

        return delta;
    }
}

class PlainClipboard extends Clipboard {
    convert(html = null) {
        /*if (typeof html === 'string') {
          this.container.innerHTML = html;
        }
        let text = this.container.innerText;
        this.container.innerHTML = '';
        let newDelta = new Delta().insert(text);
        //console.log(newDelta);
        //return newDelta;*/
        if (!this.options.stores.bookStore.isQuillInitialLoad) {
            this.options.stores.bookStore.mostRecentQuillClipboardSelection = this.quill.getSelection();
        }


        let delta = super.convert(html);
        ////console.log(delta);

        //props.stores.bookStore.mostRecentQuillClipboardDelta = delta;
        if (delta.ops.length == 1 &&
            Object.keys(delta.ops[0]).length == 0 &&
            delta.ops[0].hasOwnProperty('insert') &&
            delta.ops[0].insert.hasOwnProperty('break') &&
            delta.ops[0].insert.break == ''
        ) {

            this.quill.clipBoardDelta = null;
            return delta;

        }

        delta.ops.forEach((op) => {

            if (op.hasOwnProperty("insert") &&
                op.insert.length > 0
            ) {
                op.insert = op.insert.replace('\t', '');
            }

        })

        let newOps = [];
        delta.ops.forEach((op) => {

            if (op.hasOwnProperty("insert") && op.insert == '') {
                //do nothing
            } else {
                newOps.push(op);
            }

        })

        delta.ops = newOps;


        if (!this.options.stores.bookStore.isQuillInitialLoad) {
            this.options.stores.bookStore.mostRecentQuillClipboardDelta = delta;
            this.options.stores.bookStore.mostRecentQuillClipboardSelection = this.quill.getSelection();
        }

        return delta;
    }
}

Quill.register('modules/clipboard', PlainClipboard, true);


//Quill.register('modules/clipboard', TabMitigatingClipboard, true);





//Quill.register('modules/counter', Counter);
//Quill.register('modules/changeTracker2', ChangeTracker2);
//Quill.register('modules/history', History, true);
Quill.register('modules/history', History2, true);
Quill.register('modules/keyboard', Keyboard);


//let Inline = Quill.import('blots/inline');
const Parchment = Quill.import('parchment');

let FontSizeStyle = new Parchment.Attributor.Style('fontSizeBM', 'font-size', {
    scope: Parchment.Scope.INLINE,
    whitelist: [null, '0.75em', '1.5em', '2em']
});
Quill.register(FontSizeStyle, true);

let FontVariantStyle = new Parchment.Attributor.Style('fontVariant', 'font-variant', {
    scope: Parchment.Scope.INLINE,
    whitelist: ['small-caps']
});
Quill.register(FontVariantStyle, true);

let FontFamilyStyle = new Parchment.Attributor.Style('fontFamily', 'font-family', {
    scope: Parchment.Scope.INLINE,
    whitelist: ['serif', 'sans-serif', 'monospace']
});
Quill.register(FontFamilyStyle, true);
//Quill.register('modules/cursors', QuillCursors);

Quill.register(BlurbsBlot, true);
Quill.register(AlsobysBlot, true);
Quill.register(TOCBlot, true);
Quill.register(PublisherBlot, true);
Quill.register(ContributorsBlot, true);
Quill.register(DecoratedBreakBlot2, true);
Quill.register(EditingInsertBlot, true);
Quill.register(EditingDeleteBlot, true);
Quill.register(EditingMovedTombstoneBlot, true);
Quill.register(EditingMovedDestinationBlot, true);
Quill.register(EditingInsertNewLineBlot, true);
Quill.register(EditingInsertNewLineBlot2, true);
Quill.register(EditingDeleteNewLineBlot, true);
Quill.register(EditingMovedPreviewBlot, true);
Quill.register(CharacterBlot, true);
Quill.register(CommentBlot, true);
Quill.register(NoteBlot, true);
Quill.register(CurrentSelectionBlot, true);

// Quill.register(BookMerlinTextBlot, true);
// Quill.register(BookMerlinBlock, true);
Quill.register(VerseBlot, true);
Quill.register(AttributionBlot, true);
Quill.register(BlockQuoteBlot, true);
Quill.register(SubheadBlot, true);
Quill.register(Link2, true);
Quill.register(ImageBlot4, true);
Quill.register(FullPageImageBlot, true);

// Quill.register(DecoratedBreakBlot2, true);
// Quill.register(NotesBlot, true);
// Quill.register(ContributorsBlot, true);
// Quill.register(PublisherBlot, true);
// 
// Quill.register(EditingDeleteBlot, true);
// Quill.register(EditingFormatBlot, true);
// Quill.register(EditingInsertBlot, true);
// Quill.register(EditingMovedTombstoneBlot, true);
// Quill.register(EditingMovedDestinationBlot, true);
// Quill.register(EditingNewLineBlot, true);
// Quill.register(EditingInsertNewLineBlot, true);
// 

//https://github.com/quilljs/quill/issues/2312
Inline.order.push('editingInsertBlot');
Inline.order.push('editingDeleteBlot');
Inline.order.push('editingFormatBlot');
Inline.order.push('editingMovedTombstoneBlot');
Inline.order.push('editingMovedDestinationBlot');
Inline.order.push('editingNewLineBlot');
Inline.order.push('editingInsertNewLineBlot');
Inline.order.push('link2');


const QuillEditor = (props) => {

    const theme = useTheme();

    const userId = props.stores.userStore.user.id;

    const [updateDocumentPartBatch, { data: updateDocumentPartBatchData, loading: updateDocumentPartBatchLoading, error: updateDocumentPartBatchError }] = useUpdateDocumentPartBatch();
    const [lazyGetDocumentPartToRestore, { loading: lazyGetDocumentPartToRestoreLoading, error: lazyGetDocumentPartToRestoreError, data: lazyGetDocumentPartToRestoreData, refetch: lazyGetDocumentPartToRestoreRefetch }] = useLazyGetDocumentPart();
    const [languageToolEval, { data: languageToolEvalData, loading: languageToolEvalLoading, error: languageToolEvalError }] = useLanguageToolEval();
    const [createSharedBookUserComment, { data: createSharedBookUserCommentData, loading: createSharedBookUserCommentLoading, error: createSharedBookUserCommentError }] = useCreateSharedBookUserComment();


    const [contextMenuAnchorEl, setContextMenuAnchorEl] = React.useState(null);
    const [selectedText, setSelectedText] = React.useState(null);
    const [showAddComment, setShowAddComment] = React.useState(true);
    const [currentSelection, setCurrentSelection] = React.useState(null);


    const appContext = React.useContext(AppContext);


    const { documentPart, readOnly = false, disabled } = props;

    const contextMenuOpen = Boolean(contextMenuAnchorEl);


    const [editorId, setEditorId] = React.useState('editor' + uuid());
    //const [editorId, setEditorId] = React.useState('editor' + documentPart!=null?documentPart.id:uuid());
    const [previewEditorId, setPreviewEditorId] = React.useState('previewEditorId' + uuid());

    const [quillInstance, setQuillInstance] = React.useState(null);
    const [changeTrackerDeltas, setChangeTrackerDeltas] = React.useState(null);
    const [editsAdded, setEditsAdded] = React.useState(null);
    const [userCommentItems, setUserCommentItems] = React.useState([]);
    const [tooltipVisible, setTooltipVisible] = React.useState(false);


    const [parsedErrorMessage, setParsedErrorMessage] = useState();


    const [scrollingContainerId, setScrollingContainerId] = React.useState('scrollingContainer' + uuid());

    const editingContext = useContext(EditingContext);

    const { userComments } = props.stores.bookStore;

    const persistDelta = ({
        props,
        documentPart,
        delta
    }) => {

        props.stores.bookStore.addPendingDeltaOps({
            //userId,
            partId: documentPart.id,
            expectedVersion: documentPart.version,
            deltaOps: delta,
            allDeltaOps: documentPart.deltaOps,
            documentPart: documentPart,
            mutation: updateDocumentPartBatch
        }, ({
            finalDeltas
        }) => {

            //Step 2:   The bookStore will callback here so the quillEditor can complete the 
            //          save using a hook that the bookStore cannot use.
            ////console.log(finalDeltas);

            //console.log('back from saving.');
            updateDocumentPartBatch({
                expectedVersion: documentPart.version,
                documentPartId: documentPart.id,
                deltaOps: finalDeltas,
                appContext
            }, ()=>{
                enqueueSnackbar('DocumentPart saved', { variant: 'success' })
            }, (error)=>{
                enqueueSnackbar(error.msg, { variant: 'error' })
            })

            documentPart.version += 1;

        });

    }




    ////console.log(userComments);

    // const testProcessEditing = (quill, delta) => {

    //     console.log('testProcessEditing...');

    //     let finalFullDelta = null;
    //     let finalPartialDelta = null;

    //     if (editingUtils.isPasted({
    //         quill,
    //         bookStore: props.stores.bookStore,
    //         delta,
    //         documentPart
    //     })) {
    //         //console.log('it was pasted');

    //         finalPartialDelta = editingUtils.handlePasted({
    //             quill,
    //             bookStore: props.stores.bookStore,
    //             delta,
    //             documentPart
    //         });

    //         //finalFullDelta = delta.compose(finalPartialDelta);
    //         finalFullDelta = finalPartialDelta;

    //         props.stores.bookStore.mostRecentQuillClipboardDelta = null;
    //         props.stores.bookStore.mostRecentQuillClipboardSelection = null;
    //     }
    //     else if (editingUtils.isBackspace({
    //         quill,
    //         bookStore: props.stores.bookStore,
    //         delta,
    //         documentPart
    //     })) {

    //         //Have to call this twice since the indexes do not line up due to the character being deleted. 
    //         let { formatAhead } = editingUtils.getNearbyFormats({
    //             range: {
    //                 index: props.stores.bookStore.backspaceRange.index - 2,
    //                 length: 1
    //             },
    //             //formatType: 'editingDeleteBlot',
    //             quill
    //         });


    //         let { formatBehind } = editingUtils.getNearbyFormats({
    //             range: {
    //                 index: props.stores.bookStore.backspaceRange.index - 1,
    //                 length: 1
    //             },
    //             //formatType: 'editingDeleteBlot',
    //             quill
    //         });



    //         //*** If attempting to delete an insert blot, delete is like normal text deletion. 
    //         if (editingUtils.hasFormat(props.stores.bookStore.backspaceDelta, 'editingInsertBlot')) {

    //             finalPartialDelta = null;

    //             finalFullDelta = delta;

    //         } else {

    //             let blotUuid = uuid();
    //             if (formatAhead && formatAhead.editingDeleteBlot) {
    //                 blotUuid = formatAhead.editingDeleteBlot.uuid;
    //             }

    //             let deleteBlotDelta = new Delta([
    //                 {
    //                     "retain": props.stores.bookStore.backspaceRange.index - 1
    //                 },
    //                 {
    //                     //"retain": formattingLength + length,
    //                     "retain": props.stores.bookStore.backspaceDelta.length(),
    //                     "attributes": {
    //                         "editingDeleteBlot": {
    //                             "uuid": blotUuid,
    //                             "accepted": "undefined",
    //                             "rejected": "undefined",
    //                             "editorPinned": "undefined",
    //                             "requestorPinned": "undefined",
    //                             "editorComment": ""
    //                         }
    //                     }
    //                 }
    //             ]);



    //             let deleteDelta = new Delta([{
    //                 "retain": props.stores.bookStore.backspaceRange.index - 1
    //             }].concat(props.stores.bookStore.backspaceDelta.ops));

    //             //deleteOps = deleteOps.concat(deleteBlotDelta.ops);

    //             let finalBlotDelta = deleteDelta.compose(deleteBlotDelta);

    //             console.log(finalBlotDelta);



    //             quill.updateContents(finalBlotDelta, 'silent');
    //             props.stores.bookStore.setBlotsChangedTimestamp();

    //             finalPartialDelta = finalBlotDelta;

    //             finalFullDelta = delta.compose(finalPartialDelta);
    //             //finalPartialDelta = finalPartialDelta;
    //         }




    //     }
    //     else if (editingUtils.isDelete({
    //         quill,
    //         bookStore: props.stores.bookStore,
    //         delta,
    //         documentPart
    //     })) {


    //         // let isAllInsertBlot = editingUtils.isAllFormat({
    //         //     quill,
    //         //     range: props.stores.bookStore.deleteRange,
    //         //     formatType: 'editingInsertBlot'
    //         // })


    //         if (props.stores.bookStore.deleteFormats && props.stores.bookStore.deleteFormats.editingInsertBlot) {
    //             finalPartialDelta = null;

    //             finalFullDelta = delta;
    //         } else if (!editingUtils.hasFormat(props.stores.bookStore.deleteDelta, 'editingInsertBlot')) {
    //             let deleteBlotDelta = new Delta([
    //                 {
    //                     "retain": props.stores.bookStore.deleteRange.index
    //                 },
    //                 {
    //                     //"retain": formattingLength + length,
    //                     "retain": props.stores.bookStore.deleteDelta.length(),
    //                     "attributes": {
    //                         "editingDeleteBlot": {
    //                             "uuid": uuid(),
    //                             "accepted": "undefined",
    //                             "rejected": "undefined",
    //                             "editorPinned": "undefined",
    //                             "requestorPinned": "undefined",
    //                             "editorComment": ""
    //                         }
    //                     }
    //                 }
    //             ]);



    //             let deleteDelta = new Delta([{
    //                 "retain": props.stores.bookStore.deleteRange.index
    //             }].concat(props.stores.bookStore.deleteDelta.ops));

    //             //deleteOps = deleteOps.concat(deleteBlotDelta.ops);

    //             let finalBlotDelta = deleteDelta.compose(deleteBlotDelta);

    //             console.log(finalBlotDelta);



    //             quill.updateContents(finalBlotDelta, 'silent');
    //             props.stores.bookStore.setBlotsChangedTimestamp();

    //             finalPartialDelta = finalBlotDelta;

    //             finalFullDelta = delta.compose(finalPartialDelta);
    //             //finalPartialDelta = finalPartialDelta;
    //         }





    //     }
    //     else if (editingUtils.isKeyPressed({
    //         quill,
    //         bookStore: props.stores.bookStore,
    //         delta,
    //         documentPart
    //     })) {

    //         finalPartialDelta = editingUtils.handleKeyPressed({
    //             quill,
    //             bookStore: props.stores.bookStore,
    //             delta,
    //             documentPart
    //         });


    //         //NOTE: A null value for finalPartialDelta happens when a key is pressed next to or inside 
    //         //      text that is already an insert blot. In this case, quill automatically applies the existing 
    //         //      insert blot formatting so no new Deltas are necessary. 
    //         //      However, we still need to return the starting delta as the finalFullDelta so it can be saved after this 
    //         //      function returns to the text-changed handler. 
    //         if (!finalPartialDelta) {
    //             finalFullDelta = delta;
    //         } else {
    //             finalFullDelta = delta.compose(finalPartialDelta);
    //         }


    //     }


    //     props.stores.bookStore.mostRecentQuillKeyPress = null;
    //     props.stores.bookStore.mostRecentQuillKeyPressSelection = null;
    //     props.stores.bookStore.mostRecentQuillClipboardDelta = null;


    //     props.stores.bookStore.deleteHit = false;
    //     props.stores.bookStore.deleteRange = null;
    //     props.stores.bookStore.deleteDelta = null;
    //     props.stores.bookStore.deleteFormats = null;

    //     props.stores.bookStore.backspaceHit = false;
    //     props.stores.bookStore.backspaceRange = null;
    //     props.stores.bookStore.backspaceDelta = null;


    //     quill.mostRecentSelection = null;
    //     quill.mostRecentSelectionDelta = null;



    //     return {
    //         finalFullDelta,
    //         finalPartialDelta
    //     }


    // }

    // useEffect(() => {

    //     //console.log('comments changed.');
    //     try {



    //         let favSorted = userComments;
    //         if (props.stores.bookStore.viewFavoritedComments) {
    //             favSorted = userComments.filter(f => f.isFavorite == true);
    //         }
    //         let pinnedComments = favSorted.filter(f => f.isPinned == true);
    //         let unPinnedComments = favSorted.filter(f => !f.isPinned);

    //         let pinnedCommentItems = pinnedComments.map((userComment) => {

    //             //return <UserComment userComment={new UserCommentPojo( {data:userComment} )} />
    //             return <UserComment userComment={userComment} />
    //             // return <div style={{
    //             //     backgroundColor:'tan',
    //             //     margin:'1em 1em 1em 1em'
    //             // }}>{userComment.comment}</div>
    //         })

    //         let unPinnedCommentItems = unPinnedComments.map((userComment) => {

    //             return <UserComment userComment={userComment} />
    //             // return <div style={{
    //             //     backgroundColor:'tan',
    //             //     margin:'1em 1em 1em 1em'
    //             // }}>{userComment.comment}</div>
    //         })



    //         pinnedCommentItems = pinnedCommentItems.concat(unPinnedCommentItems);


    //         setUserCommentItems(pinnedCommentItems);
    //     } catch (err) {
    //         //console.log(err);
    //     }
    // }, [userComments, props.stores.bookStore.viewFavoritedComments]);

    useEffect(() => {

        editingContext.setTooltipVisible(false);
        let delta = new Delta(documentPart.composedDeltaOps);
        try {
            quillInstance.setContents(delta, 'silent');
        } catch (err) {
            //console.log(err);
        }

        showDocumentPartComments(
            props,
            {
                quill: quillInstance,
                partId: props.documentPart?.id
            });

        showDocumentPartNotes(
            props,
            {
                quill: quillInstance,
                partId: props.documentPart?.id
            });

    }, [props.stores.bookStore.showNotesInEditor,
    props.stores.bookStore.userComments,
    props.stores.bookStore.userCommentsTimestamp]);


    useEffect(() => {

        editingContext.setTooltipVisible(false);
        let delta = new Delta(documentPart.composedDeltaOps);
        try {
            quillInstance.setContents(delta, 'silent');
        } catch (err) {
            //console.log(err);
        }



        showDocumentPartNotes(
            props,
            {
                quill: quillInstance,
                partId: props.documentPart?.id
            });

    }, [props.stores.bookStore.showNotesInEditor,
    props.stores.bookStore.userNotes,
    props.stores.bookStore.userNotesTimestamp]);



    useEffect(() => {

        try {

            if (lazyGetDocumentPartToRestoreData?.getDocumentPart) {
                let { getDocumentPart: retrievedDocumentPart } = lazyGetDocumentPartToRestoreData;
                documentPart.isWrongVersion = false;
                documentPart.version = retrievedDocumentPart.version;
                documentPart.deltaOps = retrievedDocumentPart.deltaOps.map((m) => {
                    return JSON.parse(m);
                });


                let delta = new Delta(documentPart.composedDeltaOps);
                try {
                    quillInstance.setContents(delta, 'silent');
                } catch (err) {
                    //console.log(err);
                }



            }

        } catch (err) {
            //console.log(err);
        }


    }, [lazyGetDocumentPartToRestoreData])



    useEffect(() => {

        ////console.log(updateDocumentPartBatchError);
        let parsedErrorMessage = {};
        try {
            parsedErrorMessage = JSON.parse(updateDocumentPartBatchError.message);
            //console.log(parsedErrorMessage);


            //documentPart.version;
            documentPart.isWrongVersion = true;
            documentPart.serverSideVersion = parsedErrorMessage.version;



        } catch (err) {
            ////console.log(err);
        }
        setParsedErrorMessage(parsedErrorMessage);
    }, [updateDocumentPartBatchError]);


    // useEffect(()=>{

    //     if( updateDocumentPartBatchData?.updateDocumentPartBatch &&
    //         updateDocumentPartBatchData?.updateDocumentPartBatch.version){

    //         documentPart.version = updateDocumentPartBatchData?.updateDocumentPartBatch.version;

    //     }

    // },[updateDocumentPartBatchData]);


    useEffect(() => {

        //console.log('setting up quill')
        props.stores.bookStore.isQuillInitialLoad = true;



        let quill = new Quill('#' + editorId, {
            readOnly: readOnly,
            theme: 'snow',
            modules: {
                history: {
                    stores: props.stores,
                    documentPart: documentPart,
                    editMode: props.editMode,
                    userStore: props.stores.userStore,
                    userOnly: true //https://stackoverflow.com/questions/59653962/stop-undo-from-clearing-everything-in-the-editor-quill-quilljs
                },
                // counter: {
                   
                //     unit: 'word',
                //     callback: (words) => {
                       
                //         props.documentPart.numWords = words;
                //     }
                // },
                // changeTracker2: {
                //     stores: props.stores,
                //     callback: (e) => {
                //         console.log(e);

                //         let { allChangeDeltas, editsAdded } = e;

                //         // setChangeTrackerDeltas(allChangeDeltas);
                //         // setEditsAdded(editsAdded);

                //     }
                // },
                toolbar: false,
                clipboard: {
                    stores: props.stores,
                    matchVisual: false, //!!!!!!!!!! This resolves the extra newlines issue between blots
                    matchers: [
                        ['BR', () => {
                            var newDelta = new Delta();
                            newDelta.insert({ 'break': '' });
                            return newDelta;
                        }],
                        ['DIV', (node, delta) => {
                            ////console.log(node);
                            ////console.log(delta);
                            let replaceIds = function (obj) {
                                ////console.log(obj);
                                if (obj.id) {
                                    obj.id = uuid();
                                }
                                if (obj.uuid) {
                                    ////console.log(obj.uuid);

                                    obj.uuid = uuid();

                                    ////console.log(obj.uuid);
                                }

                                Object.keys(obj).forEach((key) => {

                                    let childObj = obj[key];
                                    if (childObj instanceof Object) {
                                        replaceIds(childObj);
                                    } else {

                                        try {
                                            childObj = JSON.parse(childObj);
                                            if (childObj instanceof Object) {
                                                replaceIds(childObj);
                                                obj[key] = JSON.stringify(childObj);
                                            }
                                        } catch (err) {
                                            //do nothing
                                            ////console.log(err);
                                        }

                                    }


                                })
                            }

                            //entityManager.unLoadDocumentParts(delta);
                            var newDelta = new Delta(JSON.parse(JSON.stringify(delta)));

                            try {

                                ////console.log(newDelta);
                                newDelta.ops.forEach((op) => {
                                    replaceIds(op);
                                });
                                ////console.log(newDelta);
                            } catch (err) {
                                //console.log(err);
                            }

                            try {

                                //entityManager.loadDocumentParts(newDelta, props);

                            } catch (err) {
                                //console.log(err);
                            }
                            //alert(delta);
                            //newDelta.insert({'break': ''});
                            return newDelta;
                        }],
                    ]
                },
                keyboard: {
                    stores: props.stores,
                    documentPart: documentPart,
                    editMode: props.editMode,
                    disabled: disabled,
                    saveDeltaCallback: ({
                        documentPart,
                        delta
                    }) => {

                        documentPart.appendDelta(delta);

                        persistDelta({
                            props,
                            documentPart,
                            delta
                        })


                    },
                    bindings: {
                        // linebreak: {
                        //   key: 13,
                        //   shiftKey: true,
                        // 	handler: function (range, context) {
                        // 	  var nextChar = this.quill.getText(range.index + 1, 1)
                        // 	  //var ee = this.quill.insertEmbed(range.index, 'break', true, 'user');
                        // 	  //this.quill.insertEmbed(range.index, 'newline', {}, Quill.sources.USER);
                        // 	  this.quill.insertText(range.index, ' ');
                        // 	  this.quill.setSelection(range.index , 1,Quill.sources.SILENT);
                        // 	  this.quill.format('newline', true);	
                        // 	  if (nextChar.length == 0) {
                        // 		// second line break inserts only at the end of parent element
                        // 		//var ee = this.quill.insertEmbed(range.index, 'break', true, 'user');
                        // 	  }
                        // 	  //this.quill.setSelection(range.index + 2,0, Quill.sources.SILENT);
                        // 	  let newRange = this.quill.getSelection();
                        // 	  this.quill.setSelection(newRange.index + 1, Quill.sources.SILENT);



                        // 	}
                        //   },
                        enter: {
                            key: 'enter',
                            handler: function (range, context) {

                                try {
                                    let lastDelta = new Delta(JSON.parse(documentPart.deltas[documentPart.deltaOps.length - 1]))
                                    let lastOp = lastDelta.ops[lastDelta.ops.length - 1];
                                    if (lastOp.insert) {

                                        let s = JSON.stringify(lastOp.insert).trim();
                                        //alert(s);
                                        let eachLine = s.split('\n\n');
                                        if (eachLine.length > 0) {
                                            //return false;
                                        }
                                        //alert('Lines found: ' + eachLine.length);
                                        for (var i = 0, l = eachLine.length; i < l; i++) {
                                            //alert('Line ' + (i+1) + ': ' + eachLine[i]);
                                        }



                                    }

                                } catch (err) {
                                    //console.log(err);
                                }
                                return true;

                            }
                        },
                        tab: {
                            key: 9,
                            handler: function () {
                                return false;
                            }
                        },
                        list: {
                            key: 9,
                            format: ['list'],
                            handler: function (range, context) {
                                return false;
                            }
                        },
                        image4: {
                            key: 'backspace',
                            //format: ['list'],
                            handler: function (range, context) {
                                //console.log(range);
                                if (range && range.index == 0 && range.length == 0) {
                                    return true;
                                }
                                props.stores.bookStore.backspaceHit = true;
                                props.stores.bookStore.backspaceRange = range;
                                props.stores.bookStore.backspaceDelta = quill.getContents(range.index - 1, 1);



                                if (editingUtils.hasFormat(range, 'editingDeleteBlot')) {
                                    //return false;
                                }

                                if (props.stores.bookStore.deleteFormats &&
                                    !props.stores.bookStore.deleteFormats.editingInsertBlot &&
                                    editingUtils.hasFormat(props.stores.bookStore.deleteDelta, 'editingInsertBlot')) {

                                    return false;
                                }

                                if (props.stores.bookStore.deleteFormats &&
                                    !props.stores.bookStore.deleteFormats.editingInsertBlot &&
                                    editingUtils.hasFormat(props.stores.bookStore.deleteDelta, 'editingDeleteBlot')) {

                                    return false;
                                }

                                return true;
                            }
                        },
                        // delete: {
                        //     key: 'delete',

                        //     handler: function (range, context) {

                        //         if (range && range.index == 0 && range.length == 0) {
                        //             //return true;
                        //         }

                        //         props.stores.bookStore.deleteRange = range;
                        //         props.stores.bookStore.deleteDelta = quill.getContents(range);
                        //         props.stores.bookStore.deleteHit = true;
                        //         props.stores.bookStore.deleteFormats = quill.getFormat(range);






                        //         if (props.editMode || props.stores.bookStore.forceEditMode) {

                        //             if (props.stores.bookStore.deleteFormats &&
                        //                 !props.stores.bookStore.deleteFormats.editingInsertBlot &&
                        //                 editingUtils.hasFormat(props.stores.bookStore.deleteDelta, 'editingInsertBlot')) {

                        //                 return false;
                        //             }

                        //             if (props.stores.bookStore.deleteFormats &&
                        //                 !props.stores.bookStore.deleteFormats.editingInsertBlot &&
                        //                 editingUtils.hasFormat(props.stores.bookStore.deleteDelta, 'editingDeleteBlot')) {

                        //                 return false;
                        //             }



                        //             // let { finalFullDelta, finalPartialDelta } = editingProcessor.processEditing(quill, props.stores.bookStore.deleteDelta, props, documentPart);
                        //             // console.log(finalFullDelta);
                        //             // console.log(finalPartialDelta);



                        //             return true;

                        //         }


                        //     }
                        // },
                        copyTombstone: {
                            key: 'q',
                            shortKey: true,
                            handler: function () {
                                //console.log('ctrl-q');
                                let deltaManager = new DeltaManager();
                                deltaManager.copySource({
                                    quill: quill,
                                    props
                                });


                                return false;
                            }
                        },
                        move: {
                            key: 'm',
                            shortKey: true,
                            handler: function () {
                                //console.log('ctrl-m');
                                let deltaManager = new DeltaManager();
                                deltaManager.prepareForMove({
                                    quill: quill,
                                    props,
                                    documentPart
                                });


                                //setContents(documentPart)

                                return false;
                            }
                        },
                        ctrlV: {
                            key: 'v',
                            shortKey: true,
                            handler: function (range, context) {


                                let selectedDelta = quill.getContents(range);

                                if (editingUtils.hasFormat(selectedDelta, 'editingInsertBlot')) {

                                    return false;
                                }

                                if (editingUtils.hasFormat(selectedDelta, 'editingDeleteBlot')) {

                                    return false;
                                }

                                return true;
                            }
                        }

                    }
                },
            },
            formats: [
                'bold',
                'underline',
                'italic',
                'strike',
                'blockquote',
                'attribution',
                'verse',
                'subhead',
                'fontSizeBM',
                'fontVariant',
                'fontFamily',
                'publisher',
                'contributors',
                'blurbs',
                'alsobys',
                'commentBlot',
                'noteBlot',
                //'link',
                'link2',
                //'list',
                'align',
                //'image',
                'image4',
                'fullPageImage',
                'tocs',
                'script',
                'editingDeleteBlot',
                'editingInsertBlot',
                'editingFormatBlot',
                'editingMovedTombstoneBlot',
                'editingMovedDestinationBlot',
                'editingNewLineBlot',
                //'editingInsertNewLineBlot',
                'editingInsertNewLineBlot2',
                'editingDeleteNewLineBlot',
                'decoratedBreak',
                'currentSelectionBlot'
                //'break'
                //'indent'
            ],
            placeholder: readOnly ? '' : 'Begin writing here...',
            scrollingContainer: '#' + scrollingContainerId
        });






        //https://codepen.io/quill/pen/qNJMYB
        quill.on(Quill.events.EDITOR_CHANGE, function (eventType, range) {

            let toolTipId = 'tooltip-controls' + editorId;
            let editorScroll = $('#' + editorId);
            //console.log(editorScroll);


            // if(tooltipVisible===true){
            //     return;
            // }
            // let windowSelection = window.getSelection();
            // console.log(windowSelection);

            //let currentSelection = quill.getSelection();
            // if (tooltipVisible===false) {

            //     setCurrentSelection(quill.getSelection());

            // }

            let editorOffset = $('#' + editorId).offset();
            //console.log(editorOffset);

            let documentPartEditorDivId = 'documentPartEditor' + documentPart.id;
            let documentPartEditorOffset = $('#' + documentPartEditorDivId);
            //console.log(documentPartEditorOffset);

            try {

                let currentFormat = quill.getFormat(range);
                if (!currentFormat || !currentFormat.commentBlot) {
                    editingContext.setUserCommentId(null);
                }

            } catch (err) {
                //do nothing
            }

            if (!editingContext.tooltipVisible) {

                //editingContext.setUserCommentId(null);

            }

            if (eventType !== Quill.events.SELECTION_CHANGE) return;
            if (range == null) return;
            if (range.length === 0) {

                editingContext.setTooltipVisible(false);


                $('#' + toolTipId).hide();
                let [block, offset] = quill.scroll.descendant(Block, range.index);
                if (block != null && block.domNode.firstChild instanceof HTMLBRElement) {
                    let lineBounds = quill.getBounds(range);
                    $('#sidebar-controls').removeClass('active').show().css({
                        left: lineBounds.left - 50,
                        top: lineBounds.top - 2
                    });
                } else {

                    $('.tooltip-controls', '#sidebar-controls').hide();
                    setShowAddComment(false);
                    $('#sidebar-controls').removeClass('active');
                }
            } else {

                editingContext.setTooltipVisible(true);

                //quill.disable();
                let selectedText = quill.getText(range);
                //console.log(selectedText);
                setSelectedText(selectedText);
                $('.tooltip-controls').hide();
                $('#sidebar-controls').hide();
                setShowAddComment(false);
                $('#sidebar-controls').removeClass('active');
                let rangeBounds = quill.getBounds(range);
                // $('#' + toolTipId).show().css({
                //     left: rangeBounds.left + rangeBounds.width / 2 - $('#' + toolTipId).outerWidth() / 2,
                //     top: rangeBounds.bottom + 10 + 650//documentPartEditorOffset.position().top//650
                // });

                let documentScrollTop = $(document).scrollTop();
                let documentPartEditorScrollTop = $('#editorScrollTop').scrollTop();
                let bookDraftEditorTopScrollDivTop = $('.bookDraftEditorTopScrollDiv').scrollTop();
                let documentPartEditorOffset = $('#' + editorId).position();

                let selection = window.getSelection();
                //let selectionPosition = $(selection.anchorNode).position();
                let toolTipHeight = $('.tooltip-controls').height();
                let toolTipWidth = $('.tooltip-controls').width();
                // $('.documentPartEditorWrapper').css({
                //     border: '2px solid blue'
                // })
                let editorWidth = $('.documentPartEditorWrapper').width();


                let leftPosition = rangeBounds.left + rangeBounds.width / 2 - $('#' + toolTipId).outerWidth() / 2;
                if (leftPosition < 0) leftPosition = 0;
                if ((leftPosition + toolTipWidth) > editorWidth) {
                    // $('#' + toolTipId).show().css({
                    //     left: '',
                    //     right: 0,
                    //     top: rangeBounds.top + bookDraftEditorTopScrollDivTop + documentPartEditorOffset.top - toolTipHeight - 20
                    // });
                } else {
                    // $('#' + toolTipId).show().css({
                    //     right: '',
                    //     left: leftPosition,
                    //     top: rangeBounds.top + bookDraftEditorTopScrollDivTop + documentPartEditorOffset.top - toolTipHeight - 20
                    // });

                }

                //$('.tooltip-comment-textarea').focus();
            }
        });






        quill.on('text-change', function (delta, oldDelta, source) {

            //console.log(delta);
            if (editingInProcess) {
                //return;
            }

            if (!props.editMode) {
                documentPart.appendDelta(delta);
            }




            setParsedErrorMessage(null);

            let partialDelta = delta;
            if (props.stores.bookStore.blotIsUpdating) {
                props.stores.bookStore.blotIsUpdating = false;

            } else {
                // if (props.editMode || props.stores.bookStore.forceEditMode) {


                //     if (source != 'api') {


                //         if(!editingInProcess){
                //             editingInProcess = true;
                //             let { finalFullDelta, finalPartialDelta } = editingProcessor.processEditing(quill, delta, props, documentPart);
                //             partialDelta = finalPartialDelta;
                //             console.log(finalFullDelta);
                //             console.log(finalPartialDelta);
                //         }
                //         editingInProcess = false;


                //     }
                // }

            }


            persistDelta({
                props,
                documentPart,
                delta
            })

            // //NOTE: The documentPart has a full delta applied since nothing has been applied up to this point. 
            // //      The initial 'delta' was not applied, nor were the editing deltas. 
            // //      The same applies to the call to 'addPendingDeltaOps'. The full delta needs to be peristed. 
            // //documentPart.appendDelta(delta);

            // //Step 1:   Save the pending deltas in the bookStore instead of the QuillEditor 
            // //          since it will live longer than QuillEditor(s).
            // props.stores.bookStore.addPendingDeltaOps({
            //     //userId,
            //     partId: documentPart.id,
            //     expectedVersion: documentPart.version,
            //     deltaOps: delta,
            //     allDeltaOps: documentPart.deltaOps,
            //     documentPart: documentPart,
            //     mutation: updateDocumentPartBatch
            // }, ({
            //     finalDeltas
            // }) => {

            //     //Step 2:   The bookStore will callback here so the quillEditor can complete the 
            //     //          save using a hook that the bookStore cannot use.
            //     ////console.log(finalDeltas);

            //     //console.log('back from saving.');
            //     updateDocumentPartBatch({
            //         expectedVersion: documentPart.version,
            //         documentPartId: documentPart.id,
            //         deltaOps: finalDeltas,
            //         appContext
            //     }, ()=>{
            //         alert('this is the success callback')
            //     }, ()=>{
            //         alert('this is the error callback')
            //     })

            //     documentPart.version += 1;



            // });


            //processChangeTrackerDeltas(quill);

        });



        quill.on('selection-change', function (range, oldRange, source) {
            //console.log('quill.selection-change...')
            //console.log(range);
            // let selectedContents = quill.getContents(range);
            // console.log(selectedContents);
            try {
                if (!editingContext.tooltipVisible) {

                    //editingContext.setUserCommentId(null);

                }
                // let lookingLeftDelta = quill.getContents({
                //     index: range.index - 1,
                //     length: 1
                // });
                // console.log(lookingLeftDelta);

                // let isLeftAdjacent = false;
                // let isRightAdjacent = false;

                // lookingLeftDelta.ops.forEach((op) => {

                //     if (op.insert && op.insert.editingInsertNewLineBlot2) {


                //         console.log(lookingLeftDelta);

                //         lookingLeftDelta.ops.forEach((op) => {

                //             if (op.insert && op.insert.editingInsertNewLineBlot2) {

                //                 isRightAdjacent = true;
                //                 console.log('isLeftAdjacent')


                //                 return;
                //             }
                //         });


                //         return;
                //     }
                // });


                // let lookingRightDelta = quill.getContents({
                //     index: range.index,
                //     length: 1
                // });
                // console.log(lookingRightDelta);



                // lookingRightDelta.ops.forEach((op) => {

                //     if (op.insert && op.insert.editingInsertNewLineBlot2) {


                //         console.log(lookingRightDelta);

                //         lookingRightDelta.ops.forEach((op) => {

                //             if (op.insert && op.insert.editingInsertNewLineBlot2) {

                //                 isLeftAdjacent = true;
                //                 console.log('isLeftAdjacent')


                //                 return;
                //             }
                //         });


                //         return;
                //     }
                // });


                // console.log('isRightAdjacent:' + isRightAdjacent);
                // console.log('isLeftAdjacent:' + isLeftAdjacent);

                // if (isLeftAdjacent) {

                //     quill.setSelection({
                //         index:range.index+1,
                //         length:0
                //     },'silent');

                //     quill.setSelection({
                //         index:range.index,
                //         length:0
                //     },'silent');
                // }






            } catch (err) {
                console.log(err);
            }
            if (source && (source == 'api' || source == 'silent')) {
                return;
            }
            if (props.stores.bookStore.activeSceneQuill != null && props.stores.bookStore.activeSceneQuill != quill) {
                //return;
            }
            //props.stores.bookStore.activeSceneQuill = quillInstance;
            props.stores.bookStore.activeSceneQuill = quill;
            props.stores.bookStore.mostRecentQuillSelection = range;

            quill.mostRecentSelection = null;
            quill.mostRecentSelectionDelta = null;
            if (range) {
                quill.mostRecentSelection = range;
                quill.mostRecentSelectionDelta = quill.getContents(range.index, range.length);
            }


            if (range) {
                //props.stores.bookStore.currentSelectionFormatting = observable(quill.getFormat(range));
                let formatting = quill.getFormat(range);
                ////console.log(formatting);
                editingContext.setFormatting(formatting);
                editingContext.setSelectedDocumentPart(documentPart);
                editingContext.setSelectedQuill(quill);
                editingContext.setSelectedEditorId(editorId);



            }
        });





        // if (props.delta) {
        //     let delta = new Delta(props.delta);
        //     try{
        //         quill.setContents(delta, 'silent');
        //     }catch(err){
        //         //console.log(err);
        //     }

        // }



        if (props.documentPart) {
            let delta = new Delta(props.documentPart.composedDeltaOps);
            //console.log(delta);
            try {
                EntityManager.loadDocumentParts(props.documentPart, delta, props);
                quill.setContents(delta, 'silent');
            } catch (err) {
                //console.log(err);
            }

        }

        setQuillInstance(quill);
        props.stores.bookStore.setQuillInstanceByPartId(quill, props.documentPart.id);
        //props.stores.bookStore.quillInstanceByPartId[props.documentPart.id] = quill;
        props.stores.bookStore.isQuillInitialLoad = false;

        props.stores.bookStore.setBlotsChangedTimeout();


        props.stores.bookStore.addDocumentPartSubscription({
            partId: props.documentPart.id,
            listenerId: editorId,
            commentsCallback: ({
                partId,
                sourceId,
                quillSourceType,
                userComment
            }) => {
                // console.log('comment');
                // quill.setSelection(userComment.partIndex, userComment.partLength, Quill.sources.SILENT);
                // let formatDelta1 = quill.format('commentBlot', { uuid: userComment.id }, Quill.sources.SILENT);
                // quill.setSelection(0, 0, Quill.sources.SILENT);
            },
            callback: ({
                partId,
                delta,
                sourceId,
                quillSourceType
            }) => {

                //////console.log('received a documentPart event');
                //console.log(delta);
                //console.log(quill.getContents());
                quill.updateContents(delta, quillSourceType);
                //quill.setContents(new Delta())






            }
        });

        showDocumentPartComments(
            props,
            {
                quill,
                partId: props.documentPart?.id
            });

        showDocumentPartNotes(
            props,
            {
                quill,
                partId: props.documentPart?.id
            });

        // $( '#'+editorId ).keydown(function() {
        //     //console.log('keydown');
        //   });

        // $('#'+editorId).on("keydown", function (e) {
        //     //console.log('keydown');
        //     let inputValue = $(this).val(); 
        //     //console.log(inputValue);
        //   });




        // return () => {

        //     //https://github.com/quilljs/quill/issues/662




        //     let selector = '.editor';
        //     console.log($(selector)[0]);


        //     var editors = $('body').find('.ql-editor');
        //     console.log(editors);



        //     if ($(selector)[0]) {
        //         var content = $(selector).find('.ql-editor').html();
        //         $(selector).html(content);

        //         $(selector).siblings('.ql-toolbar').remove();
        //         $(selector + " *[class*='ql-']").removeClass(function (index, class_name) {
        //             return (class_name.match(/(^|\s)ql-\S+/g) || []).join(' ');
        //         });

        //         $(selector + "[class*='ql-']").removeClass(function (index, class_name) {
        //             return (class_name.match(/(^|\s)ql-\S+/g) || []).join(' ');
        //         });
        //     }
        //     else {
        //         console.error('editor not exists');
        //     }


        // }

    }, [
        props.documentPart.reloadedTimestamp,
        //props.stores.bookStore.userCommentsTimestamp,
        //props.stores.bookStore.userComments
    ]);


    useEffect(() => {

        return () => {
            //console.log('unmounting QuillEditor:' + props.documentPart);
            $("." + editorId).remove();

            props.stores.bookStore.removeQuillInstanceByPartId(quillInstance, props.documentPart.id);

            props.stores.bookStore.removeDocumentPartSubscription({
                partId: props.documentPart.id,
                listenerId: editorId
            });


            //ReactDOM.unmountComponentAtNode(quillInstance);
            //setQuillInstance(null);

        }
    }, []);

    useEffect(() => {


        if (props.documentPart && quillInstance) {
            let delta = new Delta(props.documentPart.composedDeltaOps);
            try {
                EntityManager.loadDocumentParts(props.documentPart, delta, props);
                quillInstance.setContents(delta, 'silent');
            } catch (err) {
                //console.log(err);
            }

        }

        showDocumentPartComments(
            props,
            {
                quill: quillInstance,
                partId: documentPart?.id
            });

        showDocumentPartNotes(
            props,
            {
                quill: quillInstance,
                partId: documentPart?.id
            });





    }, [documentPart, documentPart.contentUpdatedTimestamp, documentPart.viewingVersion,props.stores.bookStore.showCommentsInEditor]);


    useEffect(() => {

        if (quillInstance) {
            let text = quillInstance.getText();
            try {

                text = text.trim();
                let wordCount = text.length > 0 ? text.split(/\s+/).length : 0;
                props.documentPart.numWords = wordCount;

            } catch (err) {
                ////console.log(err);
            }
        }




    }, [quillInstance]);




    const processChangeTrackerDeltas = (quill) => {
        if (!quill) {
            return;
        }
        let trackChangesModule = quill.getModule('changeTracker');
        let allChangeDeltas = changeTrackerDeltas == null ? [] : changeTrackerDeltas;

        if (allChangeDeltas.length > 0) {
            //props.stores.bookStore.editingChangeNotesTimestamp = Date.now();
        }


        //setChangeTrackerDeltas([]);


        if (allChangeDeltas.length == 0) {
            return;
        }
        allChangeDeltas.forEach((delta) => {
            if (delta.undo) {
                let length = props.documentPart.deltaOps.length;
                let undoneDeltaOps = props.documentPart.deltaOps.slice(0, length - 1);
                props.documentPart.deltaOps = undoneDeltaOps;
            }
            else {
                //props.documentPart.deltaOps.push(delta);
                //props.documentPart.finalDeltaOps = props.documentPart.finalDeltaOps.compose(delta);

                props.documentPart.appendDelta(delta, quillInstance);
                //props.stores.bookStore.editingChangeNotesTimestamp = Date.now();
                if (trackChangesModule.active) {

                    // entityManager.updateDocumentPart({
                    // 	documentPart: props.documentPart,
                    // 	itemKey: props.documentPart.id,
                    // 	itemType: props.documentPart.modelType,
                    // 	fieldName: 'title',
                    // 	fieldValue: delta
                    // }, () => {

                    // 	//console.log('item updated...');
                    // 	//props.stores.bookStore.editingChangeNotesTimestamp = Date.now();
                    // });

                }

            }


            //add entityManager.updateDocumentPart() here.

        })


        //quillInstance.enable();
        if (editsAdded && props.onEditsAdded) {
            props.onEditsAdded();
        }



        props.stores.bookStore.recipeTimestamp = Date.now();
        props.stores.bookStore.editingChangeNotesTimestamp = Date.now();
        if (props.onChange) {
            props.onChange(props.documentPart);

        }

        try {

            props.documentPart.updateEdits();
            setEditsAdded(false);

        } catch (err) {
            //console.log(err);
        }
    }

    //let editorClassNames = 'editor scene-editor-scrolling-container scene-wrapper primary-editor ' + props.stores.bookStore.isDarkTheme==true?' editor-dark':' editor-light';
    let editorClassNames = 'editor scene-editor-scrolling-container scene-wrapper primary-editor ';
    let darkModeClassName = props.stores.bookStore.isDarkTheme == true ? ' editor-dark' : ' editor-light';

    let combinedClass = editorClassNames + darkModeClassName;



    const fontSize = 'small';
    const boldIcon = <div className='tooltip-button'><ToolbarItem icon={<FormatBoldIcon fontSize={fontSize} color={editingContext.formatting.bold ? 'secondary' : 'disabled'} onClick={() => { if (disabled) return; /*toggleFormat('bold')*/ }} />} /></div>;
    const underlineIcon = <div className='tooltip-button'><ToolbarItem icon={<FormatUnderlinedIcon fontSize={fontSize} color={editingContext.formatting.underline ? 'secondary' : 'disabled'} onClick={() => { if (disabled) return;  /*toggleFormat('underline')*/ }} />} /></div>
    const italicIcon = <div className='tooltip-button'><ToolbarItem icon={<FormatItalicIcon fontSize={fontSize} color={editingContext.formatting.italic ? 'secondary' : 'disabled'} onClick={() => { if (disabled) return;  /*toggleFormat('italic')*/ }} />} /></div>
    const chatIcon = <div className='tooltip-button'><ToolbarItem icon={<ChatIcon fontSize={fontSize} color={'disabled'} onClick={() => {
        setShowAddComment(true);
        // setCurrentSelection(quillInstance.getSelection());

        // createSharedBookUserComment({
        //     documentPartId: documentPart.id,
        //     sharedBookId: book.id,
        //     sharedBookDraftId: bookDraft.id,
        //     commentorUserId: userId,
        //     comment: selectedText,
        //     partIndex: selection.index,
        //     partLength: selection.length,
        //     itemOwnerId: book.owner.id
        // }, () => {
        //     //props.stores.bookStore.userCommentsTimestamp = Date.now();
        // });




    }} />} /></div>;

 

    return (
        <div

            autoHide
            renderThumbHorizontal={() => {
                return <span />
            }}
            style={{ width: '100%', height: '100%', overFlow: 'hidden' }}>


            
            {/* Use the following for edit debugging....
            <div>id:{documentPart.id}</div>
            <div>originalPartId:{documentPart.originalPartId}</div>
            <div>current version:{documentPart.version}</div>
            <div>editMode:{props.editMode?'true':'false'}</div> */}

            {/* <div>{editorId}</div>
            <div>{tooltipVisible === true ? 'true' : 'false'}</div> */}
            <div style={{
                display:'none'
            }}>notes:{props.stores.bookStore.userNotes.slice().length}</div>
           {/* <div> {readOnly?'readOnly':'notReadOnly'}</div> */}
            {/* {tooltipVisible === true &&
                <EditorToolTip
                    readOnly={readOnly}
                    quill={quillInstance}
                    visible={tooltipVisible}
                    editorId={editorId}
                    documentPart={documentPart}
                    selectedText={selectedText}
                    selection={currentSelection}
                    widthElementClassName={'documentPartEditorWrapper'}
                />
            } */}


            {/* <Box id={"tooltip-controls" + editorId} focusable='false' tabIndex={-1} className='tooltip-controls'
                sx={{
                    display: 'none',
                    zIndex: 9999,
                    width: '20em'
                    //backgroundColor:theme.palette.write?.background?.color

                }}>
               
                <Box
                    className={'tooltip-controls-comment'}
                    sx={{
                        minWidth: '20em',
                        height: '10em'
                    }}>
                  
                  
                    <UserCommentInput
                        bookDraft={documentPart?.bookDraftDocumentPart.bookDraft}
                        documentPart={documentPart}
                        hideAddButton={false}
                        selection={currentSelection}
                        quill={quillInstance}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        'flex-direction': 'row',
                        'flex-wrap': 'wrap',
                        'alignItems': 'center',
                        //width: '100%',
                        border: '1px solid gray',
                        padding: '0em 0em .5em 0em',
                        zIndex: 9999,
                        border: '1px solid white'
                        //backgroundColor:'blue'

                    }}>
                    {boldIcon}
                    {underlineIcon}
                    {italicIcon}
                    {chatIcon}
                </Box>

            </Box> */}

            {/* 

            <div id={"tooltip-controls" + editorId} className='tooltip-controls' style={{
                zIndex: 9999
            }}>
                <Button>sup</Button>
                <button id="bold-button"><i class="fa fa-bold" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    console.log('create comment...')
                    console.log(documentPart.title);
                    const bookDraft = documentPart?.bookDraftDocumentPart.bookDraft;
                    const book = bookDraft?.book;
                    const commentUuid = uuid();

                    let selection = quillInstance.getSelection();

                    createSharedBookUserComment({
                        documentPartId: documentPart.id,
                        sharedBookId: book.id,
                        sharedBookDraftId: bookDraft.id,
                        commentorUserId: userId,
                        comment: selectedText,
                        partIndex: selection.index,
                        partLength: selection.length,
                        itemOwnerId: book.owner.id
                    }, () => {
                        //props.stores.bookStore.userCommentsTimestamp = Date.now();
                    });


                }}>Comment-net</i></button>
                <button id="bold-button"><i class="fa fa-bold" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    console.log('create comment...')
                    console.log(documentPart.title);
                    const bookDraft = documentPart?.bookDraftDocumentPart.bookDraft;
                    const book = bookDraft?.book;
                    const commentUuid = uuid();
                    let formatDelta1 = quillInstance.format('commentBlot', { uuid: commentUuid });
                    //let formatDelta2 = quillInstance.format('bold',true);
                    console.log(formatDelta1);

                    //let commentNodes = $("span").find("[data-type='commentBlot']");
                    //let commentNodes = $("[data-type='commentBlot']");
                    //$('[data-slide="0"]').addClass('demo');
                    let node = $("[data-uuid='" + commentUuid + "']")[0];
                    let blot = Parchment.find(node);

                    $('#commentPopperTriggerDiv').data('blotNode', node);
                    $('#commentPopperTriggerDiv').click();

                    $('.tooltip-controls').hide();

                    //console.log(formatDelta2);
                    // $(".editor").unmark({
                    //     done: function () {
                    //         $(".editor").mark(selectedText, {
                    //             element: 'span',
                    //             className: 'commentBlot',
                    //             separateWordSearch:false
                    //         });
                    //     }
                    // });


                    // createSharedBookUserComment({
                    //     documentPartId: documentPart.id,
                    //     sharedBookId: book.id,
                    //     sharedBookDraftId: bookDraft.id,
                    //     commentorUserId: userId,
                    //     comment: selectedText
                    // }, ()=>{
                    //     props.stores.bookStore.userCommentsTimestamp = Date.now();
                    // });


                }}>Comment</i></button>
                <button id="italic-button"><i class="fa fa-italic">I</i></button>
                <button id="link-button"><i class="fa fa-link">L</i></button>
                <button id="blockquote-button"><i class="fa fa-quote-right">"</i></button>
                <button id="header-1-button"><i class="fa fa-header"><sub>1</sub></i></button>
                <button id="header-2-button"><i class="fa fa-header"><sub>2</sub></i></button>
            </div> */}
            <div id="sidebar-controls">
                <button id="show-controls"><i class="fa fa-plus"></i></button>
                <span class="controls">
                    <button id="image-button"><i class="fa fa-camera"></i></button>
                    <button id="video-button"><i class="fa fa-play"></i></button>
                    <button id="tweet-button"><i class="fa fa-twitter"></i></button>
                    <button id="divider-button"><i class="fa fa-minus"></i></button>
                </span>
            </div>






            {documentPart.isWrongVersion &&
                <div style={{
                    backgroundColor: 'red',
                    color: 'white',
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center'
                }}
                    onClick={(e) => {
                        e.stopPropagation();
                        documentPart.isWrongVersion = false;
                    }}>wrong version:{documentPart.version}:{documentPart.serverSideVersion}<Button onClick={(e) => {
                        e.stopPropagation();
                        lazyGetDocumentPartToRestore({
                            variables: {
                                id: documentPart.id
                            }
                        });
                    }}>Restore</Button></div>
            }
            {/* <div style={{
                backgroundColor: 'red',
                color: 'white',
                display: 'flex',
                width: '100%',
                justifyContent: 'center'
            }}>

                {
                    friendlyErrorMessage
                }</div> */}

            {/* {props.showBreak &&

                <Grid container direction={'column'} sx={{
                    justifyContent: 'center'
                }}>

                    <Grid item sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>

                        <Typography variant="h5" sx={{
                            color: theme.palette.write.bookTitle.color
                        }}>***</Typography>

                    </Grid>
                </Grid>
            } */}

            {props.showBreak &&
                <div class="decoratedBreakWrapper" contenteditable="false">
                    <div class="decoratedBreak"
                        stylex={{
                            "width": "100%",
                            "text-align": "center",
                            "font-size": "2em",
                            "font-weigth": "bold"
                        }}>
                        <span class='decoratedBreakAsterik'>*</span>
                        <span class='decoratedBreakAsterik'>*</span>
                        <span class='decoratedBreakAsterik'>*</span>

                    </div>
                </div>
            }


            {/* <div>{!readOnly &&
                <Grid container direction={'column'} sx={{
                    justifyContent: 'center'
                }}>

                    <Grid item sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>

                        <Typography variant="h5"> {props.documentPart.numWords}</Typography>

                    </Grid>

                </Grid>


            }</div> */}

            {props.stores.bookStore.isDevMode &&

                <Grid container direction={'column'} sx={{
                    justifyContent: 'center'
                }}>

                    <Grid item sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>

                        <div onClick={(e) => {
                            e.preventDefault();
                            let lines = quillInstance.getLines();
                            lines.forEach((line) => {
                                let text = $(line.domNode).text();
                                $(line.domNode).css("background-color", "yellow");
                                //console.log(text);
                            })
                            //console.log(lines);

                            languageToolEval({
                                partId: documentPart.id,
                                sourceText: 'Some text tob be evaluated.'
                            });


                        }}>Get lines</div>

                    </Grid>

                    <Grid item sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div onClick={(e) => {
                            e.preventDefault();

                            //console.log('highlighting...');
                            //let context = document.querySelector($(".editor"));
                            // let instance = new Mark($(".editor"));
                            // instance.mark('Albert',{
                            //     element:'span',
                            //     className:'characterBlot'
                            // });

                            let characters = props.stores.bookStore.universeListUniverse.getAllPrecipitantsByType();
                            let characterTitles = characters.map((m) => { return m.title })
                            $(".editor").unmark({
                                done: function () {
                                    $(".editor").mark(characterTitles, {
                                        element: 'span',
                                        className: 'characterBlot'
                                    });
                                }
                            });



                            $('.characterBlot').click(function () {
                                //console.log('CharacterBlot...');

                                let text = $(this).text();
                                //console.log(text);

                                props.stores.bookStore.currentPrecipitantTitleForPopper = text;

                                $('#characterPopperTriggerDiv').data('blotNode', this);
                                $('#characterPopperTriggerDiv').click();


                            });
                        }}>Highlight Characters</div>


                    </Grid>

                    <Grid item sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div onClick={(e) => {
                            e.preventDefault();

                            //console.log('highlighting...');
                            //let context = document.querySelector($(".editor"));
                            // let instance = new Mark($(".editor"));
                            // instance.mark('Albert',{
                            //     element:'span',
                            //     className:'characterBlot'
                            // });

                            $(".editor").unmark({
                                done: function () {
                                    $(".editor").mark('comment', {
                                        element: 'span',
                                        className: 'commentBlot'
                                    });
                                }
                            });



                            $('.commentBlot').click(function () {
                                //console.log('commentBlot...');

                                $('#commentPopperTriggerDiv').data('blotNode', this);
                                $('#commentPopperTriggerDiv').click();


                            });
                        }}>Highlight Comments</div>


                    </Grid>
                </Grid>
            }

            {/* {props.stores.bookStore.isDarkTheme && 
                <div>isDark...:{editorClassNames}:{darkModeClassName}</div>
            }
            
            {!props.stores.bookStore.isDarkTheme && 
                <div>isLight...:{editorClassNames}:{darkModeClassName}</div>
            } */}
            {/* <div>{combinedClass}</div> */}
            <div className={combinedClass} style={{
                //border:'2px solid red'
            }}>
                <div id={editorId}
                    className={'editor scene-editor-scrolling-container scene-wrapper primary-editor '}
                    //className={'editor scene-editor-scrolling-container scene-wrapper primary-editor ' + darkModeClassName}
                    //className={combinedClass}
                    onBlur={() => {
                        //console.log('blurred...')
                    }}
                    style={{
                        overflow: 'hidden',
                        border: '0em'
                    }}
                    onKeyDown={(e) => {



                        if (!e.ctrlKey &&
                            e.key != 'Alt' &&
                            e.key != 'Delete' &&
                            e.key != 'Backspace' &&
                            e.key != 'Tab' &&
                            e.key != 'Escape') {

                            props.stores.bookStore.mostRecentQuillKeyPress = e.key;
                            props.stores.bookStore.mostRecentQuillKeyPressSelection = quillInstance.getSelection();

                        }

                    }}
                    onKeyPressedx={(e) => {
                        //console.log(e.key)

                    }}>


                </div>

            </div>

















            {/* <div style={{
                marginLeft: '5em'
            }}>
                {userCommentItems}
            </div> */}


            {/* {readOnly &&
                <UserCommentInput documentPart={documentPart} />
            } */}

            {/* {contextMenuOpen &&
                <ContextMenu
                    anchorEl={contextMenuAnchorEl}
                    open={contextMenuOpen}
                    contextSelectedType={'VOLUME'}
                    onSelected={({
                        menuId,
                    }) => {

                        //console.log('menuId:'+menuId);
                        //onContextMenuSelected(menuId);
                    }}
                    handleClose={() => {
                        setContextMenuAnchorEl(null)
                    }} />
            } */}
        </div>
    );
};


export default inject('stores')(observer(QuillEditor));
