import React, { useState, useEffect } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import StandardDialog from "../StandardDialog";
import StandardTextfield from "../StandardTextfield";
import StandardButton from "../StandardButton";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useManagerCreateBook } from '../../managers/BooksManager';
import { useDropzone } from 'react-dropzone';
import { useImportBookDraftDocument } from '../../persistance/persistanceHooks';
import StorageManager, { useUploadBucketItem } from '../../managers/StorageManager';
import config from '../../aws-exports';

const CreateBookDialog = (props) => {

  let user = props.stores.userStore.user;

  let [title, setTitle] = useState(null);
  let [uploadedFileS3Key, setUploadedFileS3Key] = useState(null);
  let [selectedFile, setSelectedFile] = useState(null);
  //let [uploadedFileName, setUploadedFileName] = useState(null);


  const [importBookDraftDocument, { data: importBookDraftDocumentData, loading: importBookDraftDocumentLoading, error: importBookDraftDocumentError }] = useImportBookDraftDocument();



  let {
    createBook,
    createBookLoading,
    createBookError
  } = useManagerCreateBook();


  const { getRootProps, getInputProps, open: openDropZone, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    maxSize: 5000000
  });

  let { uploadBucketItem } = useUploadBucketItem();

  useEffect(() => {

    //console.log(acceptedFiles);
    if (acceptedFiles) {
      // const files = acceptedFiles.map(file => (
      //   <li key={file.path}>
      //     {file.path} - {file.size} bytes
      //   </li>
      // ));

      if (acceptedFiles.length > 0) {
        //uploadFile(acceptedFiles[0]);

        let file = acceptedFiles[0];
        // let bucketName = config.user_uploads_bucket_name;
        // uploadBucketItem({
        //   bucketName: bucketName,
        //   file,
        //   userId: user.id,
        //   storageType: StorageManager.STORAGE_TYPE_WORD_IMPORT
        // }, (results, err) => {
        //   if (err == null) {

        //     //processUpload(results.s3Key, element.imageUploaderConfig);
        //     setUploadedFileName(file.name);
        //     setUploadedFileS3Key(results.s3Key);

        //   }
        // });


        setSelectedFile(file);



      }

    }


  }, [acceptedFiles]);




  useEffect(() => {

    if (uploadedFileS3Key) {
      importBookDraftDocument({
        title,
        s3Key: uploadedFileS3Key
      }, () => {
        props.onClose();
      });
    }
  }, [uploadedFileS3Key]);

  useEffect(() => {

    if (importBookDraftDocumentError) {

      //console.log(importBookDraftDocumentError);
    }
  }, [importBookDraftDocumentError]);






  const handleTitleChange = (e) => {
    e.preventDefault();

    setTitle(e.target.value);
  }

  const handleCreateBook = () => {

    if (selectedFile) {

      let file = selectedFile;
      let bucketName = config.user_uploads_bucket_name;
      uploadBucketItem({
        bucketName: bucketName,
        file,
        userId: user.id,
        storageType: StorageManager.STORAGE_TYPE_WORD_IMPORT
      }, (results, err) => {
        if (err == null) {

          //processUpload(results.s3Key, element.imageUploaderConfig);
          //setUploadedFileName(file.name);
          setUploadedFileS3Key(results.s3Key);

        }
      });

    } else {
      createBook({
        title,
        stores: props.stores
      }, (book) => {
        props.onBookCreated(book);

        //alert('created...');
        
        



      })
    }


    // if(uploadedFileS3Key!=null){

    //   importBookDraftDocument({
    //     title,
    //     s3Key: uploadedFileS3Key
    // });

    // }else{
    //   createBook({
    //     title,
    //     stores: props.stores
    //   }, () => {
    //     props.onClose();
    //   })
    // }


  }

  return (
    <StandardDialog
      open={props.open}
      onClose={props.onClose}
      dialogContent={
        <Grid container direction={'column'}>
          <Grid item style={{ marginBottom: '0.5em'}}>
            <StandardTextfield fullWidth label={'Title'} onChange={handleTitleChange} />
          </Grid>
          <Grid item>
            <Typography variant={'h6'}>{selectedFile && selectedFile.name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant={'h6'}>{importBookDraftDocumentLoading && <div>loading...</div>}</Typography>
          </Grid>

          <Grid item>
            <Grid container direction={'row'} style={{alignItems: 'center'}}>
              <Grid item>
                <IconButton onClick={(e) => {
                  e.preventDefault();

                  openDropZone();

                }}>
                  <UploadFileIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant={'h6'}>Import a word document</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

 

      }
      title={'Create a Book'}
      actions={[
        <StandardButton autoFocus onClick={props?.onClose}>
          Cancel
        </StandardButton>,
        <StandardButton
          autoFocus
          disabled={((title == null || title.trim().length == 0) || importBookDraftDocumentLoading) ? true : false} onClick={(e) => {
            e.preventDefault();
            handleCreateBook();
          }}>
          Ok
        </StandardButton>
      ]}>

    </StandardDialog>
  )
}

export default inject('stores')(observer(CreateBookDialog));