import React, { useState, useEffect } from "react";
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import SquareAvatar from "./SquareAvatar";
import DeleteIcon from '@mui/icons-material/Delete';
import StandardRoundedAction from "../actions/StandardRoundedAction";
import Feather from "mdi-material-ui/Feather";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import TablePopupMenu from "../menus/TablePopupMenu";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CommentIcon from '@mui/icons-material/Comment';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useGetSharedBooks, useOpenBookInWrite, useOpenBookInRead, useOpenBookInFormat, useManagerCreateBook } from '../../managers/BooksManager';
import { useMediaQuery } from 'react-responsive';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { useGetInvitedToBoxsets } from '../../managers/BoxsetsManager';
import EmptyContent from "../EmptyContent";
import helpers from '../../helpers/helpers';
import ReactTimeAgo from 'react-time-ago';
import StandardList from "./StandardList";

let missingBookImage = require('../../assets/images/image-not-found.png')

const SharedBoxsetsList = (props) => {

    let user = props.stores.userStore.user;

    let { book } = props;
    //const { ownedBoxsets, error } = useGetOwnedBoxsets(props);

    const { invitedToBoxsets, error, loading } = useGetInvitedToBoxsets(props, user?.id)


    const { openBookInWrite } = useOpenBookInWrite(props);
    const { openBookInFormat } = useOpenBookInFormat(props);
    const { openBookInRead } = useOpenBookInRead(props);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');

    let [showCreateBoxsetDialog, setShowCreateBoxsetDialog] = useState(false);
    let [showDeleteBoxsetDialog, setShowDeleteBoxsetDialog] = useState(false);
    let [selectedBoxset, setSelectedBoxset] = useState(null);

    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    const isRoute = () => {
        return 'primary';
    }


    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {


        setOrder("desc");
        setOrderBy("createdAt");

    }, [invitedToBoxsets]);


    const getBoxsetContributedBooks = (boxset) => {
        let allContributedBoxSetBooks = {};
        boxset.boxsetContributors?.map((boxsetContributor) => {

            boxsetContributor.contributedBoxsetBooks.map((bsb) => {
                allContributedBoxSetBooks[bsb.book.id] = bsb;
            });

        });

        return Object.values(allContributedBoxSetBooks);
    }




    const createSecondaryActions = (boxset) => {

        let secondaryActions = [];

        if (props.showSelectIcon) {
            secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {
                //console.log(boxset)
                props.onSelected(boxset);
            }}>
                <ShareIcon />
            </IconButton>);
        }

        return secondaryActions;
    }


    // invitedToBoxsets = helpers.stableSort(invitedToBoxsets, props.stores.bookStore.sortOrder, props.stores.bookStore.sortOrderBy==='alpha'?'title':props.stores.bookStore.sortOrderBy);


    let sortedInvitedToBoxsets = [];
    if(invitedToBoxsets!=null){
  
      sortedInvitedToBoxsets = invitedToBoxsets.map((boxset)=>{
  
        let compositeInvitedToBoxsets = {
          object:boxset,
          title:boxset.book.title,
          createdAt:boxset.sharedAt
          
        }
        
  
        return compositeInvitedToBoxsets;
      });
      sortedInvitedToBoxsets = helpers.stableSort(sortedInvitedToBoxsets, props.stores.bookStore.sortOrder, props.stores.bookStore.sortOrderBy==='alpha'?'title':props.stores.bookStore.sortOrderBy);
  
    }

    return (
        <StandardList
          // title={'My Books'}
          hideHeading={true}
          // headerActions={<CreateExportsAssembly book={book} />}
          component="div" disablePadding>
            {invitedToBoxsets && invitedToBoxsets.length > 0 ?
                sortedInvitedToBoxsets.map((compositeInvitedToBoxsets) => {

                  let boxset = compositeInvitedToBoxsets.object;

                    return <>

                        <ListItem
                            alignItems="flex-start"

                            secondaryAction={



                                <Box sx={{
                                    display: 'flex'
                                }}>
                                    <StandardRoundedAction
                                        icon={<LocalLibraryIcon sx={{ color: theme.palette.standardActions.icon.color.main }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                //console.log(boxset)
                                                openBookInRead({
                                                    bookDraftId: boxset.book.primaryBookDraftId
                                                });
                                            }} />} />


                                </Box>







                            }>
                            <ListItemAvatar
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelected(boxset);
                                }}>
                                <SquareAvatar
                                    //bucketName={config.user_uploads_bucket_name}
                                    s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + boxset.book?.coverUrl}
                                    missingContent={<img src={missingBookImage} />}
                                />
                                {/* <Avatar alt="Remy Sharp" variant="square" src="https://local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm.s3.amazonaws.com/uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/c7cb5750-3627-11ed-914f-5d91b6c4c7eb_planetStrider.png">

              </Avatar> */}
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    noWrap: true
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelected(book);
                                }}
                                primary={boxset.book.title}
                                secondary={
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {boxset?.owner?.name}
                                        </Typography>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="caption"
                                            color="text.secondary"
                                        >
                                            {boxset.sharedAt != null ? <ReactTimeAgo date={boxset.sharedAt} locale="en-US" /> : ''}
                                        </Typography>
                                        {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                    </Box>
                                }

                            />

                        </ListItem>
                        <Divider variant="inset"/>
                    </>
                    // <Divider variant="inset"/>


                    // return <ListItem secondaryAction={createSecondaryActions(book)}>
                    //   <ListItemAvatar>
                    //     <AvatarTableCell
                    //       bucketName={config.user_uploads_bucket_name}
                    //       s3Src={book?.coverUrl}
                    //     //title={userConnection?.connectedUser?.name}
                    //     />
                    //   </ListItemAvatar>
                    //   <ListItemText primary={book?.title} secondary="" />

                    // </ListItem>
                })
                :
                <Box sx={{
                  marginTop: '3em'
                }}>
                  <EmptyContent tagline={'Boxsets you have been invited to will appear here!'} />
                </Box>
                
            }
            {/* {ownedBooks.length}:
      {availableBooks.length} */}
        </StandardList>

    )
}

export default inject('stores')(observer(SharedBoxsetsList));