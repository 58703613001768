import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import TableBoilerPlate from './TableBoilerPlate';
import StandardImageViewer from './StandardImageViewer';
import Feather from 'mdi-material-ui/Feather';
import { useGetOwnedBoxsets } from '../../managers/BoxsetsManager';
import { useOpenBookInWrite, useOpenBookInFormat } from '../../managers/BooksManager';
import config from '../../aws-exports';
import StandardRoundedAction from '../actions/StandardRoundedAction';
import ReactTimeAgo from 'react-time-ago';
import { Avatar, AvatarGroup, Typography } from '@mui/material';
import imageNotFound from '../../assets/images/image-not-found.png'
import StandardDialog from '../StandardDialog';
import AddBoxsetContributorPanel from '../panels/AddBoxsetContributorPanel';
import StandardOkCancelDialog from '../panels/StandardOkCancelDialog';
import ContributorsPanel from '../panels/ContributorsPanel';
import OwnedBoxsetContributors from '../panels/OwnedBoxsetContributors';
import OwnedBoxsetBooks from '../panels/OwnedBoxsetBooks';

const BoxsetsTable = (props) => {

    const [showContributors, setShowContributors] = useState(false);
    const [showContributedBooks, setShowContributedBooks] = useState(false);
    const [selectedBoxset, setSelectedBoxset] = useState(false);
    const { ownedBoxsets, error, loading } = useGetOwnedBoxsets(props);
    const { openBookInWrite } = useOpenBookInWrite(props);
    const { openBookInFormat } = useOpenBookInFormat(props);

    const theme = useTheme();

    const table = useMemo(() => {

        if (!ownedBoxsets) return [];


        return <TableBoilerPlate
            data={ownedBoxsets}
            error={error}
            loading={loading}
            columns={[
                {
                    title: 'Cover',
                    align: 'left',
                    data: (boxset) => {
                        return <StandardImageViewer
                            s3Path={boxset.book.coverUrl}
                            bucketName={config.user_uploads_bucket_name}
                        />
                    }
                },
                {
                    title: 'Title',
                    align: 'left',
                    sortable: true,
                    sortData: (boxset) => { return boxset.book.title },
                    data: (boxset) => {
                        return <Typography onClick={(e) => {
                            e.preventDefault();
                            props.onSelectBoxSet(boxset)
                        }}>
                            {boxset.book.title}
                        </Typography>
                    }
                },
                {
                    title: 'Books',
                    align: 'left',
                    sortable: true,
                    sortData: (boxset) => { return boxset.book.title },
                    data: (boxset) => {


                        let allContributedBoxSetBooks = {};
                        boxset.boxsetContributors?.map((boxsetContributor) => {

                            boxsetContributor.contributedBoxsetBooks.map((bsb) => {
                                allContributedBoxSetBooks[bsb.book.id] = bsb;
                            });

                        });

                        allContributedBoxSetBooks = Object.values(allContributedBoxSetBooks);

                        return <AvatarGroup max={3} sx={{ width: '1.5em', height: '1.5em', cursor: 'pointer', alignItems: 'center', justifyContent: 'center' }}
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedBoxset(boxset);
                                setShowContributedBooks(true);
                                // console.log(boxset)
                                // props.stores.bookStore.boxSetListBoxSet = boxset;
                                // navigate("/boxsets/details/books");
                            }}>
                            {allContributedBoxSetBooks?.map((contributedBook) => (
                                <Avatar sx={{ width: '1.5em', height: '1.5em' }} variant="square" alt="" src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + contributedBook.book.coverUrl?.split('&&&')[0]} >
                                    <img style={{ width: '1.5em', height: '1.5em' }} src={imageNotFound} />
                                </Avatar>
                            ))}
                        </AvatarGroup>
                    }
                },
                {
                    title: 'Contributors',
                    align: 'left',
                    sortable: true,
                    sortData: (boxset) => { return boxset.book.title },
                    data: (boxset) => {
                        return <AvatarGroup max={3} sx={{ width: '1.5em', height: '1.5em', cursor: 'pointer', alignItems: 'center', justifyContent: 'center' }}
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedBoxset(boxset);
                                setShowContributors(true);
                                //   console.log(boxset)
                                //   props.stores.bookStore.boxSetListBoxSet = boxset;
                                //   navigate("/boxsets/details/contributors");

                            }}>
                            {boxset.boxsetContributors?.map((boxsetContributor) => (
                                <Avatar sx={{ width: '1.5em', height: '1.5em' }} alt="" src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + boxsetContributor.contributor.profilePicUrl} />
                            ))}
                        </AvatarGroup>
                    }
                },
                {
                    title: 'Write',
                    align: 'center',
                    data: (boxset) => {
                        return <StandardRoundedAction
                            icon={
                                <Feather
                                    sx={{
                                        color: theme.palette.standardActions.icon.color.main,
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openBookInWrite({
                                            bookDraftId: boxset.book.primaryBookDraftId,
                                        });
                                    }}
                                />
                            }
                        />
                    }
                },
                {
                    title: 'Format',
                    align: 'center',
                    data: (boxset) => {
                        return <StandardRoundedAction
                            icon={
                                <Feather
                                    sx={{
                                        color: theme.palette.standardActions.icon.color.main,
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openBookInWrite({
                                            bookDraftId: boxset.book.primaryBookDraftId,
                                        });
                                    }}
                                />
                            }
                        />
                    }
                },
                {
                    title: 'Created',
                    align: 'center',
                    sortable: true,
                    sortable: true,
                    sortData: (boxset) => { return boxset.createdAt },
                    data: (boxset) => {
                        return boxset.createdAt != null ? (
                            <ReactTimeAgo date={boxset.createdAt} locale="en-US" />
                        ) : (
                            ''
                        )
                    }
                }
            ]} />

    }, [ownedBoxsets, error, loading]);

    return (
        <>

            {table}

            <StandardOkCancelDialog
                open={showContributors}
                onClose={() => {
                    setShowContributors(false)
                }}
                onOk={() => {
                    setShowContributors(false)

                }}
                title={'Contributors'}
                content={
                    <>

                        <OwnedBoxsetContributors boxset={selectedBoxset} />
                    </>
                }
            />

            <StandardOkCancelDialog
                open={showContributedBooks}
                onClose={() => {
                    setShowContributedBooks(false)
                }}
                onOk={() => {
                    setShowContributedBooks(false)

                }}
                title={'Contributed Books'}
                content={
                    <>
                        <OwnedBoxsetBooks boxset={selectedBoxset} />
                    </>
                }
            />
        </>


    )
}
export default inject('stores')(observer(BoxsetsTable));