import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import StandardTabs from "../StandardTabs";
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton, { StandardListButton } from "../StandardButton";
import UserConnectionsList from "../lists/UserConnectionsList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../managers/BooksManager';
import UserGroupsList from "../lists/UserGroupsList";
import { useManagerAddBoxsetContributor, useManagerAddBoxsetContributedBook, useManagerLoadBoxSetContributors } from '../../managers/BoxsetsManager';
import { User } from "../../domain/bookModel";
import OwnedBooksList from "../lists/OwnedBooksList";
import { useManagerShareBookWithUserGroup } from "../../managers/ConnectionsManager";

const AddConnectionSharePanel = (props) => {

  let user = props.stores.userStore.user;
  let { userConnection } = props;

  const { shareBookWithUser, loading: shareBookLoading, error: sshareBookError } = useShareBookWithUser(props);
  
  return (
    <Grid container direction={'column'}>
      <Grid item>
        <OwnedBooksList
          filteredBooks={[]}
          showSelectIcon
          onSelected={(book) => {

            if (book) {
              shareBookWithUser({
                bookId: book.id,
                userId: userConnection.connectedUser.id
              }, 
              () => {
             
                //onSuccess
                if(props.onShareCreated){
                  props.onShareCreated();
                }
  
              }, () => {
               
                //onError
  
              })
            }

          }}
        />
      </Grid>
     
    </Grid>
  )
}

export default inject('stores')(observer(AddConnectionSharePanel));