import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useCreateSharedBookUserComment } from '../../persistance/persistanceHooks';
import { Button } from '@mui/material';

const UserCommentInput = (props) => {

    const [value, setValue] = useState("");
    const [createSharedBookUserComment, { data: createSharedBookUserCommentData, loading: createSharedBookUserCommentLoading, error: createSharedBookUserCommentError }] = useCreateSharedBookUserComment();

    const userId = props.stores.userStore.user.id;
    const documentPart = props.documentPart;
    const bookDraft = documentPart?.bookDraftDocumentPart.bookDraft;
    const book = bookDraft?.book;

    return (
        <div>
            {/* <div>
                {'bookDraftId:'+bookDraft.id}
            </div>
            <div>
                {'documentPartId:'+documentPart.id}
            </div> */}
            <div>{props?.selection?.index}:{props?.selection?.length}</div>
            <TextareaAutosize
                className={'tooltip-comment-textarea'}
                aria-label="empty textarea"
                placeholder="Add Comment"
                style={{ width: '100%', height: '100%' }}
                maxRows={5}
                minRows={7}
                value={value}
                onFocus={()=>{
                    //alert('');
                    // if(props.quill && props.selection){
                    //     props.quill.setSelection(props.selection);
                    // }
                }}
                onChange={(e)=>{
                    e.preventDefault();
                    setValue(e.target.value);

                }}
            />
            { (value && !props.hideAddButton ) &&
                <Button onClick={(e) => {
                    setValue(null);


                     // createSharedBookUserComment({
        //     documentPartId: documentPart.id,
        //     sharedBookId: book.id,
        //     sharedBookDraftId: bookDraft.id,
        //     commentorUserId: userId,
        //     comment: selectedText,
        //     partIndex: selection.index,
        //     partLength: selection.length,
        //     itemOwnerId: book.owner.id
        // }, () => {
        //     //props.stores.bookStore.userCommentsTimestamp = Date.now();
        // });


                    createSharedBookUserComment({
                        documentPartId: documentPart.id,
                        sharedBookId: book.id,
                        sharedBookDraftId: bookDraft.id,
                        commentorUserId: userId,
                        comment: value,
                        itemOwnerId:book.owner?.id,
                        partIndex: props.selection?.index,
                        partLength: props.selection?.length
                        //highlightedText:highlightedText
                    }, ()=>{
                        props.stores.bookStore.userCommentsTimestamp = Date.now();
                    });


                }}>Add</Button>
            }
        </div>

    );
};


export default inject('stores')(observer(UserCommentInput));