import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import { useGetUserConnections, useGetUserGroups } from '../../../managers/ConnectionsManager';
import ShareIcon from '@mui/icons-material/Share';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Avatar from '@mui/material/Avatar';
import CircleAvatar from "../../lists/CircleAvatar";
import UserAvatar from "../../lists/UserAvatar";
import helpers from '../../../helpers/helpers';
import ReactTimeAgo from 'react-time-ago';
import EmptyContent from "../../EmptyContent";


const UserListItem = (props) => {

    let user = props.user;
    //let user = props.stores.userStore.user;

    return (
        <ListItem
            selected={props.selected}
            // <ListItem
            //     selected={props.selectedIndex}
            sx={{ padding: ' 0.25em' }}
            secondaryAction={
                props.secondaryActions
            }
        >

            <ListItemAvatar>
                {/* <Avatar
                    bucketName={config.user_uploads_bucket_name}
                    s3Src={userConnection?.connectedUser?.profilePicUrl}
                >
                    
                </Avatar> */}
                {/* <CircleAvatar
                    //bucketName={config.user_uploads_bucket_name}
                    s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + userConnection?.connectedUser?.profilePicUrl}
                    missingContent={<img src={missingImage} />}
                /> */}

                <UserAvatar
                    s3Src={user?.profilePicUrl} />
            </ListItemAvatar>
            <ListItemText
                primary={user?.name}
                secondary={props.secondaryText}
                onClick={(e) => {
                    e.preventDefault();
                    props.onSelected(user);
                }}
            />

        </ListItem>

    )
}

export default inject('stores')(observer(UserListItem));