import {
    observable,
    action,
    computed,
    runInAction,
    extendObservable,
    makeObservable,
    makeAutoObservable,
} from 'mobx';
import Quill from 'quill';
import Delta from 'quill-delta';
import { v4 as uuid } from 'uuid';
import helpers from '../helpers/helpers';

var $ = require("jquery");

const { numberToRoman, romanToNumber } = require('big-roman');
let numberToWords = require('number-to-words');
let { DeltaToHtmlEPub } = require('../helpers/DeltaToHtmlEPub');

let validUrl = require('valid-url');

export class User {

    modelType = 'User';
    id;
    allowConnectionRequests = null;
    allowEditRequests = null;
    amazonId = null;
    amazonUrl = null;
    availableTags = null;
    bookbubId = null;
    bookbubUrl = null;
    businessName = null;
    cognitoId = null;
    country = null;
    createdAt = null;
    deletedAt = null;
    displayName = null;
    emailAddress = null;
    facebookId = null;
    facebookUrl = null;
    goodreadsId = null;
    goodreadsUrl = null;
    id = null;
    instagramId = null;
    instagramUrl = null;
    lastName = null;
    name = null;
    ownerId = null;
    pinterestId = null;
    pinterestUrl = null;
    planId = null;
    productId = null;
    profilePicUrl = null;
    snapchatId = null;
    snapchatUrl = null;
    state = null;
    trialPeriodStart = null;
    twitterId = null;
    twitterUrl = null;
    updatedAt = null;
    version = null;
    youtubeId = null;
    youtubeUrl = null;
    zipCode = null;
    __typename = "User";
    invitationCode = null;
    invitationCodeDate = null;
    invitationCodeUsed = null;
    userSubscriptions = [];


    constructor(args) {

        const { data } = args;
        Object.keys(data).forEach((fieldName) => {
            this[fieldName] = data[fieldName]
        })


        this.facebookUrl = null;
        this.twitterUrl = null;
        this.instagramUrl = null;
        this.youtubeUrl = null;
        this.snapchatUrl = null;
        this.pinterestUrl = null;
        this.amazonUrl = null;
        this.bookbubUrl = null;
        this.goodreadsUrl = null;
        this.userSubscriptions = data.userSubscriptions ? data.userSubscriptions.items : [];
        makeAutoObservable(this);
    }

}

export class BoxSetBook {

    modelType = 'BoxSetBook';
    boxSetId;
    bookId;
    book;
    recipeId;
    recipe;
    recipeSource;
    id;

    constructor({
        bookDraft,
        data }) {
        this.id = data.id;
        this.boxSetId = data.boxSetId;
        this.bookId = data.bookId;
        this.recipeId = data.recipeId;
        this.recipeSource = data.recipeSource;
        this.book = new Book({ bookDraft, data: data.book });
        if (data.recipe) {
            this.recipe = new Recipe({ data: data.recipe });
        }





        makeAutoObservable(this);



    }

}

export class BoxSetContributor {

    modelType = 'BoxSetContributor';
    boxset;
    contributor;
    contributedBoxsetBooks = [];


    constructor({
        boxset, data }) {
        this.boxset = data.boxset;

        this.contributedBoxsetBooks = data.contributedBooks?.items.map((bsb) => {
            return new BoxSetBook({
                bookDraft: boxset.book?.bookDraft,
                data: bsb
            });
        });

        this.contributor = new User({
            data: data.contributor
        });


        // this.boxSetBooks = data.boxSetBooks.items.map((bsb)=>{
        //     bsb.recipe = new Recipe({
        //         data:bsb.recipe
        //     })
        //     return bsb;
        // });


        makeAutoObservable(this);



    }

}

export class BoxSet {

    modelType = 'BoxSet';
    id;
    book;
    owner = null;
    createdAt;
    deletedAt;
    boxSetBooks = [];
    boxsetContributors = [];


    constructor({
        bookDraft,
        book, data }) {
        this.id = data.id;
        this.title = data.title;
        this.deletedAt = data.deletedAt;
        this.createdAt = data.createdAt;


        if (book) this.book = new Book({ data: book });
        try {

            if (data.owner) this.owner = new User({ data: data.owner });
            else if (data.book) this.book = new Book({ data: data.book });

        } catch (err) {
            console.log(err);
        }


        this.boxSetBooks = data.boxSetBooks?.items.map((bsb) => {
            return new BoxSetBook({
                bookDraft,
                data: bsb
            });
        });

        this.boxsetContributors = data.contributors?.items.map((contributor) => {
            return new BoxSetContributor({
                boxset: this,
                data: contributor
            });
        });


        // this.boxSetBooks = data.boxSetBooks.items.map((bsb)=>{
        //     bsb.recipe = new Recipe({
        //         data:bsb.recipe
        //     })
        //     return bsb;
        // });


        makeAutoObservable(this);



    }


    getBoxSetBook(bookId) {

        try {
            let bsb = this.boxSetBooks.filter(f => f.bookId == bookId)[0];
            //console.log(bsb);
            return bsb;
        } catch (err) {
            //console.log(err);
        }
    }



}

export class Book {

    modelType = 'Book';
    id;
    primaryBookDraftId = null;
    bookDraft = null;
    owner = null;
    title;
    subTitle;
    authorName;
    recipe = null;
    boxSet = null;
    createdAt = null;
    deletedAt = null;
    boxSetId = null;


    coverUrl = null;
    coverUrlType = null;
    coverThumbnailUrl = null;
    coverThumbnailUrlType = null;


    commonAllTitlepagesHeaderUrl = null;
    commonAllHalftitlesHeaderUrl = null;
    commonAllPartsHeaderUrl = null;
    commonVolumeHeaderUrl = null;
    commonPartHeaderUrl = null;
    commonChapterHeaderUrl = null;
    publisherLogoImageSrc = null;
    publisherLogoImageType = null;
    publisherLogoUrl = null;

    appleStoreLinkUrl = null;
    bookbubStoreLinkUrl = null;
    customStoreLinkUrl = null;
    googleStoreLinkUrl = null;
    kindleStoreLinkUrl = null;
    koboStoreLinkUrl = null;
    nookStoreLinkUrl = null;


    appleIdentifier = null;
    bookbubIdentifier = null;
    customIdentifier = null;
    googleIdentifier = null;
    kindleIdentifier = null;
    koboIdentifier = null;
    nookIdentifier = null;

    /*
    commonHeadingBackgroundImage = null;
    volumeHeadingBackgroundImage = null;
    */

    constructor({ bookDraft, data }) {
        this.bookDraft = bookDraft;
        this.id = data.id;
        this.boxSetId = data.boxSetId;
        this.primaryBookDraftId = data.primaryBookDraftId;
        this.title = data.title;
        this.subTitle = data.subTitle;
        this.authorName = data.authorName;
        this.createdAt = data.createdAt;
        this.deletedAt = data.deletedAt;
        this.coverUrl = data.coverUrl;
        this.coverUrlType = data.coverUrlType;
        this.coverThumbnailUrl = data.coverThumbnailUrl;
        this.coverThumbnailUrlType = data.coverThumbnailUrlType;

        this.commonAllTitlepagesHeaderUrl = data.commonAllTitlepagesHeaderUrl;
        this.commonAllHalftitlesHeaderUrl = data.commonAllHalftitlesHeaderUrl;
        this.commonAllPartsHeaderUrl = data.commonAllPartsHeaderUrl;
        this.commonVolumeHeaderUrl = data.commonVolumeHeaderUrl;
        this.commonPartHeaderUrl = data.commonPartHeaderUrl;
        this.commonChapterHeaderUrl = data.commonChapterHeaderUrl;
        this.publisherLogoImageSrc = data.publisherLogoImageSrc;
        this.publisherLogoImageType = data.publisherLogoImageType;
        this.publisherLogoUrl = data.publisherLogoUrl;

        this.appleStoreLinkUrl = data.appleStoreLinkUrl;
        this.bookbubStoreLinkUrl = data.bookbubStoreLinkUrl;
        this.customStoreLinkUrl = data.customStoreLinkUrl;
        this.googleStoreLinkUrl = data.googleStoreLinkUrl;
        this.kindleStoreLinkUrl = data.kindleStoreLinkUrl;
        this.koboStoreLinkUrl = data.koboStoreLinkUrl;
        this.nookStoreLinkUrl = data.nookStoreLinkUrl;


        this.appleIdentifier = data.appleIdentifier;
        this.bookbubIdentifier = data.bookbubIdentifier;
        this.customIdentifier = data.customIdentifier;
        this.googleIdentifier = data.googleIdentifier;
        this.kindleIdentifier = data.kindleIdentifier;
        this.koboIdentifier = data.koboIdentifier;
        this.nookIdentifier = data.nookIdentifier;



        if (data.owner) {

            this.owner = new User({
                data: data.owner
            });

        }
        if (data.recipe) {
            this.recipe = new Recipe({
                data: data.recipe
            });
        }
        if (data.boxSet) {
            this.boxSet = new BoxSet({
                bookDraft,
                data: data.boxSet
            });
            this.boxSet.book = this;
        }


        makeAutoObservable(this);

        // makeObservable(this, {

        //     title: observable

        // });

    }


    checkLinkUrl(url) {

        try {
            if (!validUrl.isUri(url)) {
                return null;
            }
        } catch (err) {
            return null;
        }

        return url;
    }

    generateStoreLinkUrl(book, epubType, storeId) {

        // return 'eeeeee';
        //change this later to automatically generate the appropriate link. 
        //also allow for affiliate information in the links. 
        // try {
        // 	if (!validUrl.isUri(storeId)) {
        // 		return null;
        // 	}
        // } catch (err) {
        // 	return null;
        // }
        //console.log(epubType);
        let storeLink = '';
        switch (epubType) {

            case 'iBooks':
                storeLink = storeId;
                break;

            case 'nook':
                storeLink = storeId;
                break;

            case 'googlePlay':
                storeLink = storeId;
                break;

            case 'kindle':
                storeLink = storeId;
                break;

            case 'kobo':
                storeLink = storeId;
                break;

            case 'epub':
                storeLink = storeId;
                break;

            case 'EPUB_PREVIEWER':
                storeLink = storeId;
                break;
        }

        //add code to ensure that https is present in the url. 
        return storeLink;

    }

    get isBoxset() {
        if (this.boxSetId != null) {
            return true;
        }

        return false;
    }



}

export class Recipe {

    modelType = 'Recipe';
    id;
    previewUrl;
    previewUrlType;
    version;
    ownerId;
    recipeId;
    json = {};
    title;
    description;
    sharedByUserId;
    sharedByUserProfilePic;
    sharedByUser;
    createdAt;
    updatedAt;
    deletedAt;
    lowerCaseMapping = {

    }

    constructor({ data }) {
        this.id = data.id;
        this.previewUrl = data.previewUrl;
        this.previewUrlType = data.previewUrlType;

        try {
            this.json = observable(JSON.parse(data.json));
        } catch (err) {
            //do nothing
        }

        this.recipeId = data.recipeId;

        try {
            Object.keys(this.json.params).forEach((key) => {

                this.lowerCaseMapping[key.toLowerCase()] = key

            });
        } catch (err) {
            console.log(err);
        }

        try {
            this.version = this.json.version;
        } catch (err) {
            console.log(err);
        }




        makeAutoObservable(this, {
            lowerCaseMapping: false
        });




    }


    getParamByLowerCase(lowerCaseParamId) {
        ////console.log('getParamByLowerCase():'+lowerCaseParamId);
        return this.json.params[this.lowerCaseMapping[lowerCaseParamId]];
    }

    mergeMaster(masterRecipe) {

        //return;
        if (!this.json) {
            //console.log('ERROR: Recipe without json.' + this.id);
            return;
        }
        let masterRecipeJson = JSON.parse(JSON.stringify(masterRecipe.json));
        //let headingParamMaster = masterRecipe.getParamByLowerCase('global_volume_headingTopMargin'.toLowerCase());



        try {
            Object.keys(masterRecipeJson.params).forEach((key) => {
                ////console.log(key);

                if (this.json.params[key]) {
                    //this means this param already exists in the users recipe.
                    //So copy it to the returned json.
                    masterRecipeJson.params[key] = this.json.params[key];
                }
            });
        } catch (err) {
            //console.log(err);
        }

        $.extend(true, masterRecipeJson, this.json);



        //Now that some of the params from the user recipe have overwritten params
        //from the master recipe we need to put back the param resourceMaps from the master.
        //Since the master was overwritten we need to start from the original json string.

        // let originalMasterRecipeJson = masterRecipe.json;
        // Object.keys(originalMasterRecipeJson.params).forEach((key) => {

        // 	//masterRecipeJson.params[key].resourcesMap = originalMasterRecipeJson.params[key].resourcesMap;

        // });

        this.json = observable(masterRecipeJson);

        let headingParamThis = this.getParamByLowerCase('global_volume_headingTopMargin'.toLowerCase());

        try {
            Object.keys(this.json.params).forEach((key) => {

                this.lowerCaseMapping[key.toLowerCase()] = key

            });
        } catch (err) {
            console.log(err);
        }



    }



}


export class BookDraft {

    modelType = 'BookDraft';
    id;
    bookDraftType;
    name;
    description;
    book = null;
    bookDraftDocumentParts = [];
    mappedImages = [];
    version;
    createdAt;
    deletedAt;



    constructor(data) {

        if (!data) {
            return;
        }
        this.id = data.id;
        this.bookDraftType = data.bookDraftType;
        this.version = data.version;
        this.name = data.name;
        this.description = data.description;
        this.createdAt = data.createdAt;
        this.deletedAt = data.deletedAt;
        if (data.book) {
            this.book = new Book({ bookDraft: this, data: data.book });
        }

        this.bookDraftDocumentParts = [];

        makeAutoObservable(this);

        // makeObservable(this, {
        //     id: observable,
        //     bookDraftDocumentParts: observable,
        //     rootBookDraftDocumentPart: computed

        // });

        let rootPartId = null;


        //Process Velocity templates differently since it may have missing documentPart entities. 
        //Fix the velocity templates later so that they have proper data structure.
        if (data.id == '7693e4e0-1770-11e8-8a4f-797a71458945') {

            let processedBookDraftDocumentParts = [];



            data?.bookDraftDocumentParts.items.forEach((bddp) => {

                try {

                    rootPartId = bddp.documentPart.id;
                    let newBddp = new BookDraftDocumentPart({
                        bookDraft: this,
                        data: bddp
                    });

                    processedBookDraftDocumentParts.push(newBddp);

                } catch (err) {
                    //console.log(err);
                }


            });


            this.bookDraftDocumentParts.replace(processedBookDraftDocumentParts)



        } else {
            try {

                this.bookDraftDocumentParts.replace(data.bookDraftDocumentParts.items.map((bddp) => {

                    rootPartId = bddp.documentPart.id;
                    let newBddp = new BookDraftDocumentPart({
                        bookDraft: this,
                        data: bddp
                    });

                    return newBddp;



                }));




            } catch (err) {
                console.log(err);
            }
        }



        //After initial processing of bddps, process inserted books
        try {


            this.bookDraftDocumentParts.forEach((bddp) => {


                if (bddp.documentPart.insertedBook) {

                    //console.log(bddp.documentPart.insertedBook);

                    let insertedBookPrimaryBookDraft = new BookDraft(bddp.documentPart.insertedBook.primaryBookDraft);


                    let insertedBook = new Book({ bookDraft: insertedBookPrimaryBookDraft, data: bddp.documentPart.insertedBook });
                    //let insertedBook = new Book({ bookDraft: this, data: bddp.documentPart.insertedBook });



                    insertedBookPrimaryBookDraft.book = insertedBook;



                    let insertedBookRootBddp = insertedBookPrimaryBookDraft.rootBookDraftDocumentPart;

                    insertedBookPrimaryBookDraft.bookDraftDocumentParts.forEach((insertedBddp) => {

                        //Need to let the bddp that it is a part of a boxSet
                        insertedBddp.boxSet = this.book.boxSet;
                        if (insertedBddp.documentPart.partType != 'ROOT' &&
                            insertedBddp.documentPart.partType != 'TOPBOOK') {

                            if (insertedBddp.parentPartId == insertedBookRootBddp.partId) {
                                insertedBddp.parentPartId = bddp.partId;
                            }

                            insertedBddp.insertedBook = insertedBook;
                            this.bookDraftDocumentParts.push(insertedBddp);
                        }
                    })

                    //console.log(insertedBook);

                }

            })
        }
        catch (err) {
            //console.log(err);
        }


    }

    calculateWords(){

        this.bookDraftDocumentParts.forEach((bddp)=>{

            bddp.documentPart.calculateWords();
        })
    }

    inflateAllRecipes({
        masterRecipe
    }) {

        //console.log(masterRecipe);
        let headingParamMaster = masterRecipe.getParamByLowerCase('global_chapter_hideAllHeadings'.toLowerCase());
        if (this.book.recipe) {
            let headingParamBookBefore = this.book.recipe.getParamByLowerCase('global_chapter_hideAllHeadings'.toLowerCase());
            this.book.recipe.mergeMaster(masterRecipe);
            let headingParamBookAfter = this.book.recipe.getParamByLowerCase('global_chapter_hideAllHeadings'.toLowerCase());
            //console.log(headingParamBookAfter);
        }

        try {
            this.book.boxSet.boxSetBooks.forEach((bsb) => {

                bsb.recipe.mergeMaster(masterRecipe);
                bsb.book.recipe.mergeMaster(masterRecipe);
            })
        } catch (err) {
            //console.log(err);
        }
        // if (recipeData && masterRecipeData) {

        // 	let recipe = new Recipe({ data: recipeData.getRecipe })

        // 	recipe.mergeMaster(props.stores.bookStore.masterRecipe);
        // 	props.stores.bookStore.setActiveRecipe(recipe);
        // 	props.stores.bookStore.writingBookDraft.book.recipe = props.stores.bookStore.activeRecipe;
        // }

    }




    addBookDraftDocumentPart({ data }) {

        let newBddp = new BookDraftDocumentPart({
            bookDraft: this,
            data
        });

        this.bookDraftDocumentParts.push(newBddp);

        return newBddp;

    }

    //This method is needed by the inflatables
    getDocumentPartById = (partId) => {

        if (this.bookDraftDocumentParts.filter((f) => f.partId == partId).length == 0) {

            console.log('error...');
        }
        try {
            let bddp = this.bookDraftDocumentParts.filter((f) => f.partId == partId)[0];
            let dp = null;
            if (bddp) {
                dp = bddp.documentPart;
            }
            //console.log(dp);
            return dp;
        } catch (err) {

        }

    }

    getBookDraftDocumentPartByPartId = (partId) => {

        try {
            return this.bookDraftDocumentParts.filter((f) => f.partId == partId)[0];
        } catch (err) {

        }

    }


    getBookDraftDocumentPartByPrevPartId = (parentPartId, prevPartId) => {

        //console.log('getBookDraftDocumentPartByPrevPartId()...')
        //Need parentPartId since more than one bddp can have null for prevPartId. 

        try {
            let childBddps = this.bookDraftDocumentParts.filter((f) => f.parentPartId == parentPartId);
            return childBddps.filter((f) => f.prevPartId == prevPartId)[0];
        } catch (err) {

        }






    }

    get lastChapterBddp() {

        return null;
    }

    get rootBookDraftDocumentPart() {

        try {

            return this.bookDraftDocumentParts.filter((bddp) => {

                ////console.log(bddp);
                if (bddp.documentPart.partType == 'ROOT' || bddp.documentPart.partType == 'TOPBOOK') {
                    return bddp;
                }
            })[0];



        } catch (err) {
            //console.log(err);
        }

    }

    moveBookDraftDocumentPart({ movedBddp, targetBddp, position }) {

        let moved_nextBddp = this.getBookDraftDocumentPartByPrevPartId(movedBddp.parentPartId, movedBddp.partId)
        let moved_prevBddp = this.getBookDraftDocumentPartByPartId(movedBddp.prevPartId)



        if (position && position == 'CHILD') {
            movedBddp.parentPartId = targetBddp.partId;
            this.version++;
            //this.book.title='changed'
        }

        if (position && position == 'BEFORE') {
            movedBddp.parentPartId = targetBddp.parentPartId;
            this.version++;
            //this.book.title='changed'
        }

        if (position && position == 'AFTER') {
            movedBddp.parentPartId = targetBddp.parentPartId;
            this.version++;
            //this.book.title='changed'
        }


        //alert(movedBddp?.documentPart.title);

    }




}

export class BookDraftDocumentPart {

    modelType = 'BookDraftDocumentPart';
    bookDraft;
    boxSet;
    id;
    partId;
    parentPartId;
    prevPartId;
    documentPart;
    children = [];
    expanded; //not persisted remotely. Posibly locally


    constructor(args) {
        makeAutoObservable(this);
        if (!args) return;

        let { bookDraft, data } = args;
        this.id = data.id;
        this.partId = data.partId;
        this.bookDraft = bookDraft;
        this.parentPartId = data.parentPartId;
        this.prevPartId = data.prevPartId;
        this.expanded = true;
        //this.children = children;
        //this.documentPart = new DocumentPart({title, type, bookDraftDocumentPart:this, partType});
        this.documentPart = new DocumentPart({
            bookDraftDocumentPart: this,
            data: data?.documentPart
        });

        ////console.log(this.documentPart)


    }


    get childBookDraftDocumentParts() {

        return this.bookDraft.bookDraftDocumentParts.filter((f) => f.parentPartId == this.partId);
    }

    getSortedChildren() {
        //console.log('getSortedChildren()...');
        let sortedChildren = [];
        let currentLastBddp;
        let unsortedChildren = this.bookDraft.bookDraftDocumentParts.filter((f) => f.parentPartId == this.partId);

        if (unsortedChildren.length == 0) {
            return sortedChildren;
        }

        let firstBddp = null;

        try {
            firstBddp = unsortedChildren.filter(f => f.prevPartId == null)[0];
        } catch (err) {
            //do nothing
        }


        //if we cannot find the first child, bail-out and return the unsorted. 
        //TODO: Add some error handling to fix this unsortable problem.
        if (!firstBddp) {
            return unsortedChildren;
        }


        //Else, lets try to sort what we have...
        sortedChildren.push(firstBddp);
        currentLastBddp = firstBddp;

        unsortedChildren.forEach((bddp) => {

            let nextBddp = null;
            try {
                nextBddp = unsortedChildren.filter(f => f.prevPartId == currentLastBddp.partId)[0];
            } catch (err) {
                //do nothing
            }


            if (nextBddp != null) {
                sortedChildren.push(nextBddp);
                currentLastBddp = nextBddp;
            }
        })

        if (unsortedChildren.length != sortedChildren.length) {
            return unsortedChildren;
        }

        return sortedChildren;
    }

    getBoxSetBook() {
        return this.documentPart.getBoxSetBook();
    }

    get lastPart() {

        let sortedChildren = this.getSortedChildren();

        if (sortedChildren.length > 0) {
            return sortedChildren[sortedChildren.length - 1];
        }

        return null;
    }



}


export class DocumentPart {

    modelType = 'DocumentPart';
    id;
    contentUpdatedTimestamp;
    version;
    isWrongVersion = false;
    serverSideVersion; //temporarily set client side when a wrong version condition is detected
    owner = null;
    ownerId = null;
    title = null;
    subtitle = null;
    synopsis = null;
    type = null;
    partType = null;
    bookDraftDocumentPart = null;
    insertedBook = null;
    content = null;
    contentJson = null;
    delta = [
        // { insert: 'Hello ' },
        // { insert: 'World!', attributes: { bold: true } },
        // { insert: 'Isnt this the best thing you have ever seen?\n' }
    ]
    processedDeltaOps = null; //new Delta("{\"ops\":[]}");
    deltaOps = [];
    flattenedOps = [] //These are used for num words reporting
    appliedTags = null;
    authorName = null;
    bookDraftId = null;
    insertedBookId = null;
    bookId = null;
    cognitoId = null;
    completed = null;
    coverUrl = null;
    updatedAt = null;
    createdAt = null;
    deletedAt = null;
    description = null;
    editCompleted = null;
    editingNotes = null;
    editorDelta = null;
    internalTitle = null;
    mostRecentDelta = null;
    notes = null;
    originalPartId = null;
    originalPartVersion = null;
    receivedEditChangesAt = null;
    sequenceNumber = 1;
    webPageUrl = null;


    //START formatting fields
    includeTitle = null;
    allowHyphenation = null;
    boxSetOverrideRecipeId = null;
    boxSetRecipeSource = null;
    excludeFromBook = null;
    excludeFromToc = 'false';
    fontFamily = null;
    fontSize = null;
    hideAllAuthors = null;
    hideAllHeaderImages = null;
    hideAllHeadings = null;
    hideAllSubtitles = null;
    hideHeading = null;
    hideHeadingImage = null;
    hideHeadingTitleImage = null;
    hideSubtitle = null;
    hideTitle = null;
    includeAmazon = null;
    includeAuthor = null;
    includeBookbub = null;
    includeFacebook = null;
    includeGoodreads = null;
    includeHeadingUrl = null;
    includeInstagram = null;
    includePinterest = null;
    includeSnapchat = null;
    includeSubtitle = null;
    includeTwitter = null;
    includeYoutube = null;
    indentParagraphs = null;
    isNumbered = null;
    justifyText = null;
    lineSpacing = null;
    numberAll = null;
    backgroundImageUrl = null;
    overrideCommonChapterHeadingUrl = null;
    overrideCommonHalftitleHeadingUrl = null;
    overrideCommonPartHeadingUrl = null;
    overrideCommonTitlepageHeadingUrl = null;
    overrideCommonVolumeHeadingUrl = null;
    restartChapterNumbering = null;
    restartPartNumbering = null;
    spaceParagraphs = null;
    numWords = 0;
    alsoByBooks = [];
    reloadedTimestamp;
    hideHeadingBackgroundImage = null;
    headingBackgroundUrl = null;
    titleImageType = null;
    titleImageUrl = null;
    headerImageType = null;
    headingUrl = null;
    viewingVersion = null;
    
    //END formatting fields
    constructor(args) {
        //console.log(args);
        let { bookDraftDocumentPart, data } = args;
        this.bookDraftDocumentPart = bookDraftDocumentPart;
        this.id = data.id;
        //this.title = data.title;
        this.realTitle = data.title;
        this.title = data.title;
        this.type = data.type;
        this.numWords = 0;
        


        if (this.excludeFromToc == null) {
            this.excludeFromToc = 'false';
        }

        this.content = data.content;
        this.insertedBook = data.insertedBook; //raw data added that is later processed within BookDraft

        if (data.alsoByBooks) {

            let books = [];
            data.alsoByBooks.items.forEach((alsoByBookData) => {

                this.alsoByBooks.push(new Book({ bookDraft: null, data: alsoByBookData }));

            })

            //this.alsoByBooks.replace(books);
        }


        //Any values set above this loop may be overwritten.
        Object.keys(data).forEach((fieldName) => {
            this[fieldName] = data[fieldName]
        })

        if (this.excludeFromToc == null) {
            this.excludeFromToc = 'false';
        }
        //this.includeHeadingUrl = true;

        if (data.partType == 'ROOT') {
            this.partType = 'TOPBOOK';
        } else {
            this.partType = data.partType;
        }

        if (data.includeTitle && data.includeTitle == 'false') {
            this.includeTitle = data.includeTitle;
        }
        if (data.includeSubtitle && data.includeSubtitle == 'false') {
            this.includeSubtitle = data.includeSubtitle;
        }
        if (data.includeAuthor && data.includeAuthor == 'false') {
            this.includeAuthor = data.includeAuthor;
        }
        if (this.hideHeading == null || this.hideHeading == '') {
            this.hideHeading = 'false';
        }
        if (this.hideHeadingImage == null || this.hideHeadingImage == '') {
            this.hideHeadingImage = 'false';
        }
        if (this.hideHeadingTitleImage == null || this.hideHeadingTitleImage == '') {
            this.hideHeadingTitleImage = 'false';
        }
        if (this.hideHeadingBackgroundImage == null || this.hideHeadingBackgroundImage == '') {
            this.hideHeadingBackgroundImage = 'false';
        }





        try {
            if (data.numWords != null) {
                this.numWords = parseInt(data.numWords);
            }

        } catch (err) {
            ////console.log(err);
        }
        if (data.contributors) {
            this.contributors = data.contributors;
        }

        if (data.blurbs) {
            this.blurbs = data.blurbs;
        }

        if (data.boxSetOverrideRecipe) {
            this.boxSetOverrideRecipe = new Recipe(data.boxSetOverrideRecipe);
        }




        this.setDeltaOps(data.deltaOps);
        // this.deltaOps = [new Delta("{\"ops\":[]}")];//	data.deltaOps;
        // if (data.deltaOps) {
        //     ////console.log(data.deltaOps);

        //     this.deltaOps = data.deltaOps;
        //     try {
        //         this.deltaOps = data.deltaOps.map((m) => {
        //             return JSON.parse(m);
        //         });
        //     } catch (err) {
        //         //console.log(err);
        //     }

        //     let finalDeltaOps = new Delta("{\"ops\":[]}");

        //     if (this.deltaOps) {

        //         try {
        //             this.deltaOps.forEach((delta) => {

        //                 let parsedDelta = delta;//JSON.parse(delta);
        //                 if (!finalDeltaOps) {

        //                     finalDeltaOps = new Delta(parsedDelta);

        //                 } else {

        //                     finalDeltaOps = finalDeltaOps.compose(new Delta(parsedDelta));

        //                 }
        //             });

        //         } catch (err) {
        //             //console.log(err);
        //         }

        //     }


        //     // let stringifiedDeltaOps = JSON.stringify(finalDeltaOps);
        //     // this.delta = stringifiedDeltaOps;
        //     // this.composedDelta = finalDeltaOps;




        // }

        makeAutoObservable(this, {
            deltaOps: false
        });

    }

    calculateWords(){

        this.flattenedOps = helpers.getFlattenedOps(this.deltaOps);

        //console.log(this.flattenedOps);


    }

    getCalculatedWords(){

        let totalWords = 0;
        this.flattenedOps.forEach((flattenedOp)=>{

            totalWords+=flattenedOp.numWords;
        })

        return totalWords;


    }


    /* Assumes that insertedBook info is attached to this instance */
    isInsertedBookContent() {

        try {
            if (this.bookDraftDocumentPart.insertedBook || this.partType == 'BOOK') {
                return true;
            }
        } catch (err) {
            console.log(err);
        }

        return false;
    }

    setDeltaOps(deltaOps) {

        this.deltaOps = [new Delta("{\"ops\":[]}")];//	data.deltaOps;
        if (deltaOps) {
            ////console.log(data.deltaOps);

            this.deltaOps = deltaOps;
            try {
                this.deltaOps = deltaOps.map((m) => {
                    return JSON.parse(m);
                });
            } catch (err) {
                //console.log(err);
            }

            let finalDeltaOps = new Delta("{\"ops\":[]}");

            // if (this.deltaOps) {

            //     try {
            //         this.deltaOps.forEach((delta) => {

            //             let parsedDelta = delta;//JSON.parse(delta);
            //             if (!finalDeltaOps) {

            //                 finalDeltaOps = new Delta(parsedDelta);

            //             } else {

            //                 finalDeltaOps = finalDeltaOps.compose(new Delta(parsedDelta));

            //             }
            //         });

            //     } catch (err) {
            //         //console.log(err);
            //     }

            // }


            // let stringifiedDeltaOps = JSON.stringify(finalDeltaOps);
            // this.delta = stringifiedDeltaOps;
            // this.composedDelta = finalDeltaOps;




        }

        //This guarantees that the next call to 'get composedDeltaOps' results in a recalculation.
        this.processedDeltaOps = null;

    }

    get derivedTitle() {
        if (this.partType && (this.partType == 'ROOT' || this.partType == 'TOPBOOK')) {
            return this.bookDraftDocumentPart.bookDraft.book.title;
        }
        if (this.partType && this.partType == 'BOOK') {
            return this.insertedBook ? this.insertedBook.title : 'INSERTED BOOK';
        }
        return this.title;
    }

    get composedDeltaOps() {

        if (this.processedDeltaOps == null) {

            let finalDeltaOps = new Delta("{\"ops\":[]}");

            if (this.viewingVersion != null) {

            }
            if (this.deltaOps) {

                try {
                    this.deltaOps.forEach((delta) => {

                        let parsedDelta = delta;//JSON.parse(delta);
                        if (this.viewingVersion && delta.version > this.viewingVersion) {
                            //do nothing
                        }
                        else{
                            if (!finalDeltaOps) {

                                finalDeltaOps = new Delta(parsedDelta);

                            } else {

                                finalDeltaOps = finalDeltaOps.compose(new Delta(parsedDelta));
                                //finalDeltaOps = new Delta(parsedDelta).compose(finalDeltaOps);

                            }
                        }

                    });

                } catch (err) {
                    //console.log(err);
                }

            }
            this.processedDeltaOps = finalDeltaOps;


        }

        return this.processedDeltaOps;

    }

    get composedDeltaOpsWithChildren() {



        let finalDeltaOps = new Delta("{\"ops\":[]}");

        if (this.deltaOps) {

            try {
                this.deltaOps.forEach((delta) => {

                    let parsedDelta = delta;//JSON.parse(delta);
                    if (!finalDeltaOps) {

                        finalDeltaOps = new Delta(parsedDelta);

                    } else {

                        finalDeltaOps = finalDeltaOps.compose(new Delta(parsedDelta));

                    }
                });

            } catch (err) {
                //console.log(err);
            }

        }
        //this.processedDeltaOps = finalDeltaOps;

        this.getSortedChildren().forEach((bddp) => {

            finalDeltaOps.concat(bddp.documentPart.composedDeltaOpsWithChildren());
        })


        return finalDeltaOps;

    }

    get blurbsJson() {

        if (!this.blurbs) {

            return { 'records': [] };

        }

        return JSON.parse(this.blurbs);
    }

    get contributorsJson() {

        if (!this.contributors) {

            return { 'records': [] };

        }

        return JSON.parse(this.contributors);
    }


    get numberAllBoolean() {
        return this.computeBoolean(this.numberAll);
    }

    get allowHyphenationBoolean() {
        return this.computeBoolean(this.allowHyphenation);
    }

    get justifyTextBoolean() {
        return this.computeBoolean(this.justifyText);
    }

    get spaceParagraphsBoolean() {
        return this.computeBoolean(this.spaceParagraphs);
    }

    get indentParagraphsBoolean() {
        return this.computeBoolean(this.indentParagraphs);
    }

    get hideAllAuthorsBoolean() {
        return this.computeBoolean(this.hideAllAuthors);
    }

    get hideAllHeaderImagesBoolean() {
        return this.computeBoolean(this.hideAllHeaderImages);
    }

    get hideAllHeadingsBoolean() {
        return this.computeBoolean(this.hideAllHeadings);
    }

    get hideAllSubtitlesBoolean() {
        return this.computeBoolean(this.hideAllSubtitles);
    }

    get isNumberedBoolean() {
        if (!this.isNumbered || this.isNumbered == "") {
            return true;
        }

        if (this.isNumbered && this.isNumbered == "false") {
            return false;
        }

        if (this.isNumbered && this.isNumbered == "true") {
            return true;
        }


        return false; //this is bad coding!!
    }

    computeBoolean(field) {

        if (!field || field == "") {
            return false;
        }

        if (field && field == "false") {
            return false;
        }

        if (field && field == "true") {
            return true;
        }


        return false; //this is bad coding!!
    }



    get isNumberedBoolean() {
        if (!this.isNumbered || this.isNumbered == "") {
            return true;
        }

        if (this.isNumbered && this.isNumbered == "false") {
            return false;
        }

        if (this.isNumbered && this.isNumbered == "true") {
            return true;
        }


        return false; //this is bad coding!!
    }

    get restartPartNumberingBoolean() {
        if (!this.restartPartNumbering || this.restartPartNumbering == "") {
            return false;
        }

        if (this.restartPartNumbering && this.restartPartNumbering == "false") {
            return false;
        }

        if (this.restartPartNumbering && this.restartPartNumbering == "true") {
            return true;
        }


        return false; //this is bad coding!!
    }



    get restartChapterNumberingBoolean() {
        if (!this.restartChapterNumbering || this.restartChapterNumbering == "") {
            return false;
        }

        if (this.restartChapterNumbering && this.restartChapterNumbering == "false") {
            return false;
        }

        if (this.restartChapterNumbering && this.restartChapterNumbering == "true") {
            return true;
        }


        return false; //this is bad coding!!
    }

    getSequenceArray() {

        try {
            ////console.log('8888888888888888888888888888888888888888888888888888');
            ////console.log(this.sequenceNumber);
            let ar = [... this.sequenceNumber.toString()];
            return ar;
        } catch (err) {
            ////console.log(err);
        }


    }

    get sequenceNumberAsRoman() {


        try {

            return numberToRoman(this.sequenceNumber);

        } catch (err) {
            return '';
        }
    }

    get sequenceNumberAsWords() {

        try {

            let s = numberToWords.toWords(this.sequenceNumber);
            s = s.replace('-', ' ');
            return s.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });


        } catch (err) {
            return '';
        }

    }

    getBook() {

        //console.log('getBook()...');
        // try {
        // 	return this.bookDraftDocumentPart.topBookBookDraftDocumentPart.boxSetBook.book;
        // } catch (err) {

        // }

        try {
            if (this.partType == 'BOOK') {

                // return this.bookDraftDocumentPart.boxSetBook.book;
                // return this.bookDraftDocumentPart.bookDraft.boxSet;
                return this.insertedBook;
            }
        } catch (err) {

            //console.log(err);
        }



        return this.bookDraftDocumentPart.bookDraft.book;
    }


    getRecipe() {

        if (this.bookDraftDocumentPart.boxSet) {
            let boxSetBook = this.getBoxSetBook();

            if (boxSetBook?.recipeSource == 'overrideBookRecipe') {

                return boxSetBook.recipe;

            } else if (boxSetBook?.recipeSource == 'originalBookRecipe') {

                return boxSetBook.book.recipe;
            }

            //boxSetRecipe
            return this.bookDraftDocumentPart.boxSet.book.recipe;
        }

        return this.bookDraftDocumentPart.bookDraft.book.recipe;

    }

    deltaToHtmlEPub() {
        let that = this;
        ////console.log('deltaToHtml()...');

        if (!this.delta) {

            return '';

        }


        let cfg = {};

        try {

            let finalDeltaOps = new Delta("{\"ops\":[]}");

            this.deltaOps.forEach((delta) => {

                let parsedDelta = delta;//JSON.parse(delta);
                if (!finalDeltaOps) {

                    finalDeltaOps = new Delta(parsedDelta);

                } else {

                    finalDeltaOps = finalDeltaOps.compose(new Delta(parsedDelta));

                }
            });






            //let	delta = JSON.parse(this.delta);
            finalDeltaOps.ops = finalDeltaOps.ops.map((op) => {
                if (op.insert && op.insert.blurbs) {
                    op.insert.blurbs.documentPart = that;
                }
                if (op.insert && op.insert.tocs) {
                    op.insert.tocs.documentPart = that;
                }
                if (op.insert && op.insert.notes) {
                    op.insert.notes.documentPart = that;
                }
                if (op.insert && op.insert.contributors) {
                    op.insert.contributors.documentPart = that;
                }
                return op;
            });


            let converter = new DeltaToHtmlEPub();
            let html = converter.convert(finalDeltaOps, this);
            ////console.log(html);
            return html;

        } catch (err) {
            //do nothing.
            ////console.log('error converting delta');
            //console.log(err);
        }

        return '';

    }


    getDeltaJson() {

        try {
            return JSON.parse(this.delta);
        } catch (err) {
            return {};
        }

    }

    getBoxSetBook() {

        //console.log('getBoxSetBook()...');
        let boxSetBook = null;
        try {

            let originalBookId = this.bookDraftDocumentPart.bookDraft.book.id;
            boxSetBook = this.bookDraftDocumentPart.boxSet.getBoxSetBook(originalBookId);

        } catch (err) {
            ////console.log(err);
        }

        try {

            if (!boxSetBook) {

                boxSetBook = this.bookDraftDocumentPart.bookDraft.book.boxSet.getBoxSetBook(this.insertedBookId);

            }


        } catch (err) {
            ////console.log(err);
        }


        return boxSetBook;


    }


    //The templates require this. They should have made this call on bddp
    getSortedChildren() {
        //console.log('getSortedChildren()...')
        //return this.bookDraftDocumentPart.bookDraft.bookDraftDocumentParts.filter((f) => f.parentPartId == this.partId);

        return this.bookDraftDocumentPart.getSortedChildren();
    }

    get totalChildrenNumWords() {

        //console.log('totalNumWords...')
        let total = this.numWords;

        // let children = this.childBookDraftDocumentParts;
        // children.forEach((childBddp)=>{

        //     total+=childBddp.totalNumWords;

        // })

        return total;

    }

    get totalNumWords() {

        //console.log('totalNumWords...')
        //let total = this.numWords;
        let total = 0;
        let combinedText = '';
        this.deltaOps.forEach((d)=>{

            //console.log(d)
            let delta = new Delta(d.ops);
            
            delta.ops.forEach((op)=>{

                if(op.insert && !(op.insert instanceof Object) ){
                    //console.log('its a string.')

                    
                    combinedText+=op.insert

                }
            })
        })

        // let children = this.childBookDraftDocumentParts;
        // children.forEach((childBddp)=>{

        //     total+=childBddp.totalNumWords;

        // })
        return helpers.countWords(combinedText);
        //return total;

    }
    appendDelta(delta, sourceQuill) {

        //console.log(delta);
        if (this.deltaOps == null) {

            this.deltaOps = [];

        }

        this.deltaOps.push(delta);

        if (this.processedDeltaOps == null) {

            this.processedDeltaOps = new Delta("{\"ops\":[]}");

        } else {

            this.processedDeltaOps = this.processedDeltaOps.compose(delta);

        }

        //this.realTimeUpdateMostRecentQuill = sourceQuill;

        /*if(forceUpdate && forceUpdate==true){
            this.forceUpdate = true;
        }*/
        //this.realTimeUpdateTimestamp = Date.now();



    }

}
export class UserComment {

    modelType = 'SharedBookUserComment';
    __typename = "SharedBookUserComment";
    id;
    isPinned;
    commentorUser = {};
    comment;
    partId;
    partIndex;
    partLength;
    partSelectedText;
    sharedBook = {};
    completedAt;
    deletedAt;
    readAt;
    isFavorite;
    commentorUserId;
    itemOwnerId;


    constructor(args) {

        const { data } = args;
        this.partId = data.partId;
        this.isPinned = data.isPinned;
        this.isFavorite = data.isFavorite;
        this.comment = '' + data.comment;
        this.completedAt = data.completedAt;
        this.readAt = data.readAt;
        this.deletedAt = data.deletedAt;
        this.partIndex = data.partIndex;
        this.partLength = data.partLength;
        this.commentorUserId = data.commentorUserId;
        this.partSelectedText = data.partSelectedText;
        this.itemOwnerId = data.itemOwnerId;



        Object.keys(data).forEach((fieldName) => {
            try {
                this[fieldName] = data[fieldName]
            } catch (err) {
                console.log(err);
            }

        })

        if (data.commentorUser) this.commentorUser = new User({ data: data.commentorUser });
        if (data.sharedBook) this.sharedBook = new Book({ data: data.sharedBook });



        makeAutoObservable(this);
    }

}


export class EditRequest {

    modelType = 'EditRequest';
    acceptedAt;
    bookId;
    closedAt;
    completedAt;
    createdAt;
    deletedAt;
    editBookDraftId;
    editorCanceledAt;
    editorComments;
    editorDeletedAt;
    editorUserId;
    id;
    isFavorite;
    locked;
    originalBookDraftId;
    ownerId;
    rejectedAt;
    requestedAt;
    requestorCanceledAt;
    requestorComments;
    requestorDeletedAt;
    requestorUserId;
    updatedAt;
    version;

    constructor({
        data }) {
        this.id = data.id;

        this.acceptedAt = data.acceptedAt;
        this.bookId = data.bookId;
        this.closedAt = data.closedAt;
        this.completedAt = data.completedAt;
        this.createdAt = data.createdAt;
        this.deletedAt = data.deletedAt;
        this.editBookDraftId = data.editBookDraftId;
        this.editorCanceledAt = data.editorCanceledAt;
        this.editorComments = data.editorComments;
        this.editorDeletedAt = data.editorDeletedAt;
        this.editorUserId = data.editorUserId;
        this.isFavorite = data.isFavorite;
        this.locked = data.locked;
        this.originalBookDraftId = data.originalBookDraftId;
        this.ownerId = data.ownerId;
        this.rejectedAt = data.rejectedAt;
        this.requestedAt = data.requestedAt;
        this.requestorCanceledAt = data.requestorCanceledAt;
        this.requestorComments = data.requestorComments;
        this.requestorDeletedAt = data.requestorDeletedAt;
        this.requestorUserId = data.requestorUserId;
        this.updatedAt = data.updatedAt;
        this.version = data.version;

        this.requestorUser = null;
        this.editorUser = new User({ data: data.editorUser });
        if(data.requestorUser){
            this.requestorUser = new User({ data: data.requestorUser });
        }
        
        this.book = data.book; //do not always need a pojo


        makeAutoObservable(this);



    }

}



export class Universe {

    modelType = 'Universe';
    id;
    title;
    profilePicUrl;
    universePrecipitants = [];
    createdAt;
    updatedAt;
    deletedAt;
    version;

    constructor({ data }) {
        this.id = data.id;
        this.title = data.title;
        this.deletedAt = data.deletedAt;
        this.profilePicUrl = data.profilePicUrl;
        this.universePrecipitants = data.universePrecipitants;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.deletedAt = data.deletedAt;
        this.version = data.version;

        makeAutoObservable(this);
    }

    getSortedChildren = () => {

        return this.universePrecipitants.filter(f => f.parentId == null);
    }

    getPrecipitantByMappingId = (universePrecipitantId) => {

        try {
            return this.universePrecipitants.filter(f => f.id == universePrecipitantId)[0].precipitant;
        } catch (err) {
            console.log(err);
        }
    }

    getAllPrecipitantsByType = (typeId) => {

        try {
            return this.universePrecipitants.map((f) => { return f.precipitant });
        } catch (err) {
            console.log(err);
        }
    }

    getPrecipitantByTitle = (title) => {

        try {
            return this.universePrecipitants.filter(f => f.precipitant.title == title)[0].precipitant;
        } catch (err) {
            console.log(err);
        }
    }


}

export class UniversePrecipitant {

    modelType = 'UniversePrecipitant';
    id;
    universeId;
    parentId;
    prevId;
    precipitantId;
    description;
    ownerId;
    universe;
    precipitant;
    childItems = [];
    createdAt;
    updatedAt;
    deletedAt;
    version;

    constructor({ data }) {
        this.id = data.id;
        this.universeId = data.universeId;
        this.parentId = data.parentId;
        this.prevId = data.prevId;
        this.precipitantId = data.precipitantId;
        this.description = data.description;
        this.ownerId = data.ownerId;
        this.universe = data.universe;
        this.precipitant = data.precipitant;
        this.childItems = data.childItems ? data.childItems : [];
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.deletedAt = data.deletedAt;
        this.version = data.version;

        makeAutoObservable(this);
    }

    getSortedChildren = () => {

        return this.universe.universePrecipitants.filter(f => f.parentId == this.id);
    }

}

export class Precipitant {

    modelType = 'Precipitant';
    id;
    title;
    description;
    ownerId;
    itemTypeId;
    itemType;
    subType;
    universes = [];
    createdAt;
    updatedAt;
    deletedAt;
    version;


    constructor({ data }) {
        this.id = data.id;
        this.title = data.title;
        this.description = data.description;
        this.ownerId = data.ownerId;
        this.itemTypeId = data.itemTypeId;
        this.itemType = data.itemType;
        this.subType = data.subType;
        this.universes = data.universes;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.deletedAt = data.deletedAt;
        this.version = data.version;

        makeAutoObservable(this);
    }

}

export class ItemType {

    modelType = 'ItemType';
    id;
    title;
    context;
    description;
    ownerId;
    attrTypes;
    createdAt;
    updatedAt;
    deletedAt;
    version;
    itemTypeAttrTypes = [];


    constructor({ data }) {
        this.id = data.id;
        this.title = data.title;
        this.context = data.context;
        this.description = data.description;
        this.ownerId = data.ownerId;
        this.attrTypes = data.attrTypes;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.deletedAt = data.deletedAt;
        this.version = data.version;


        makeAutoObservable(this);
    }

    appendAttrType(attrType) {

        let lastItemTypeAttrType = null;

        if (this.itemTypeAttrTypes.length > 0) {
            lastItemTypeAttrType = this.itemTypeAttrTypes[this.itemTypeAttrTypes.length - 1];
        }


        let itemTypeAttrType = new ItemTypeAttrType({
            data: {
                id: uuid(),
                itemTypeId: this.id,
                attrTypeId: attrType.id,
                itemType: this,
                attrType: attrType,
                prevAttrId: lastItemTypeAttrType?.id
            }
        })

        this.itemTypeAttrTypes.push(itemTypeAttrType);


    }

}

export class AttrType {



    modelType = 'AttrType';
    id;
    title;
    controlType; //Textfield, textarea, checkbox etc.
    description;
    ownerId;
    createdAt;
    updatedAt;
    deletedAt;
    version;

    constructor({ data }) {
        this.id = data.id;
        this.title = data.title;
        this.controlType = data.controlType;
        this.description = data.description;
        this.ownerId = data.ownerId;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.deletedAt = data.deletedAt;
        this.version = data.version;


        makeAutoObservable(this);
    }

}
AttrType.USER_IMAGE = 'USER_IMAGE';
AttrType.TEXTFIELD = 'TEXTFIELD';
AttrType.TEXTAREA = 'TEXTAREA';



export class ItemTypeAttrType {

    modelType = 'ItemTypeAttrType';
    itemTypeId;
    attrTypeId;
    itemType;
    attrType;
    prevAttrId;
    ownerId;
    createdAt;
    updatedAt;
    deletedAt;
    version;

    constructor({ data }) {
        this.id = data.id;
        this.itemTypeId = data.itemTypeId;
        this.attrTypeId = data.attrTypeId;
        this.itemType = data.itemType;
        this.attrType = data.attrType;
        this.prevAttrId = data.prevAttrId;
        this.ownerId = data.ownerId;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.deletedAt = data.deletedAt;
        this.version = data.version;


        makeAutoObservable(this);
    }

}


export class Outline {

    modelType = 'Outline';
    id;
    title;
    profilePicUrl;
    outlinePlotParts = [];
    createdAt;
    updatedAt;
    deletedAt;
    version;

    constructor({ data }) {
        this.id = data.id;
        this.title = data.title;
        this.deletedAt = data.deletedAt;
        this.profilePicUrl = data.profilePicUrl;
        this.outlinePlotParts = data.outlinePlotParts ? data.outlinePlotParts : [];
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.deletedAt = data.deletedAt;
        this.version = data.version;

        makeAutoObservable(this);
    }

    getSortedChildren = () => {

        return this.outlinePlotParts.filter(f => f.parentId == null);
    }

    getPlotPartByMappingId = (outlinePlotPartId) => {

        try {
            return this.outlinePlotParts.filter(f => f.id == outlinePlotPartId)[0].plotPart;
        } catch (err) {
            console.log(err);
        }
    }

    getAllPlotPartsByType = (typeId) => {

        try {
            return this.outlinePlotParts.map((f) => { return f.plotPart });
        } catch (err) {
            console.log(err);
        }
    }

    getPlotPartByTitle = (title) => {

        try {
            return this.outlinePlotParts.filter(f => f.plotPart.title == title)[0].plotPart;
        } catch (err) {
            console.log(err);
        }
    }


}

export class OutlinePlotPart {

    modelType = 'OutlinePlotPart';
    id;
    outlineId;
    parentId;
    prevId;
    plotPartId;
    description;
    ownerId;
    outline;
    plotPart;
    childItems = [];
    createdAt;
    updatedAt;
    deletedAt;
    version;

    constructor({ data }) {
        this.id = data.id;
        this.outlineId = data.outlineId;
        this.parentId = data.parentId;
        this.prevId = data.prevId;
        this.plotPartId = data.plotPartId;
        this.description = data.description;
        this.ownerId = data.ownerId;
        this.outline = data.outline;
        this.plotPart = data.plotPart;
        this.childItems = data.childItems ? data.childItems : [];
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.deletedAt = data.deletedAt;
        this.version = data.version;

        makeAutoObservable(this);
    }

    getSortedChildren = () => {

        return this.outline.outlinePlotParts.filter(f => f.parentId == this.id);
    }

}

export class PlotPart {

    modelType = 'PlotPart';
    id;
    title;
    description;
    ownerId;
    itemTypeId;
    itemType;
    subType;
    outlines = [];
    createdAt;
    updatedAt;
    deletedAt;
    version;


    constructor({ data }) {
        this.id = data.id;
        this.title = data.title;
        this.description = data.description;
        this.ownerId = data.ownerId;
        this.itemTypeId = data.itemTypeId;
        this.itemType = data.itemType;
        this.subType = data.subType;
        this.outlines = data.outlines;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.deletedAt = data.deletedAt;
        this.version = data.version;

        makeAutoObservable(this);
    }

}




