import React, { useState, useEffect } from "react";
import { Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import SnapshotsTable from "../table/SnapshotsTable";
import StandardDialog from "../StandardDialog";
import CreateSnapshotPanel from "./CreateSnapshotPanel";
import { StandardListButton } from "../StandardButton";
import BookSnapshotsList from "../lists/BookSnapshotsList";



const SnapshotsPanel = (props) => {

  let user = props.stores.userStore.user;
  let { book } = props;

  let [showCreateSnapshotDialog, setShowCreateSnapshotDialog] = useState(false);


  return (
    <Grid container direction={'column'}>
      <StandardListButton 
        variant={'outlined'}
        onClick={() => {
        setShowCreateSnapshotDialog(true)
      }}>Create Snapshot</StandardListButton>

      {/* <SnapshotsTable book={book} hideTitleBlock={true} /> */}
      <BookSnapshotsList book={book}/>
      <StandardDialog
        open={showCreateSnapshotDialog}
        onClose={() => {
          setShowCreateSnapshotDialog(false)
        }}
        onOk={() => {


        }}
        title={'Create Snapshot'}
        dialogContent={
          <CreateSnapshotPanel book={book} onClose={() => {
            setShowCreateSnapshotDialog(false)
          }} />
        }
      >
        <CreateSnapshotPanel book={book} />
      </StandardDialog>
    </Grid>

  )
}

export default inject('stores')(observer(SnapshotsPanel));