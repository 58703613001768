import { Grid, styled } from "@mui/material";
import React, { useState } from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';

const StyledGrid = styled(Grid)({
	//height: '100%',
	overflowX: 'hidden',
	//border: '3px solid blue',
	boxSizing: 'border-box',
  padding:'0em .5em 0em .5em'
});

const ScrollingGridItem = (props) => {

  return (
    <StyledGrid {...props} item sx={{
        // height: '100%',
        // overflowx: 'hidden',
        // border: '3px solid blue',
        // boxSizing: 'border-box',
        //flex: 3
    }}>
        <Scrollbars
            autoHide
            renderThumbHorizontal={() => {
                return <span />
            }}
            style={{ width: '100%', height: '100%', overFlow: 'hidden',
            overflowX:'hidden' }}>

            {props.children}

        </Scrollbars>
    </StyledGrid>
  );
};

export default ScrollingGridItem;