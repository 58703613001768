import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import { useLoadBookEditRequests, useOpenEditRequestInEdit, useOpenEditRequestInWrite } from '../../managers/EditRequestsManager';
import AccountEdit from 'mdi-material-ui/AccountEdit';

const BookEditRequestsList = (props) => {

  let user = props.stores.userStore.user;

  let { book } = props;
  


  let { loading: getBookEditRequestsLoading, error: getBookEditRequestsError, data: getBookEditRequestsData } = useLoadBookEditRequests(props, book?.id)
  const { openEditRequestInEdit } = useOpenEditRequestInEdit(props);
  const { openEditRequestInWrite } = useOpenEditRequestInWrite(props);

  let tableData = [];
  if (getBookEditRequestsData?.getBook.editRequests) {
    tableData = getBookEditRequestsData?.getBook.editRequests.items;
  }


  const createSecondaryActions = (editRequest) => {

    let secondaryActions = [];

    if (props.showSelectIcon) {
      secondaryActions.push(<IconButton edge="end" aria-label="delete" onClick={(e) => {

        
        if(editRequest && editRequest.requestorUserId==user.id){
          openEditRequestInWrite({
            editRequest
        });
        }
       

        if(editRequest && editRequest.editorUserId==user.id){
          openEditRequestInEdit({
            editRequest
        });
        }



        
      }}>
        <AccountEdit />
      </IconButton>);
    }

    return secondaryActions;
  }

  return (
    <List component="div" disablePadding>
      {
        tableData.map((editRequest) => {

          return <ListItem
            secondaryAction={createSecondaryActions(editRequest)}
          >
            <ListItemAvatar>
              <AvatarTableCell
                bucketName={config.user_uploads_bucket_name}
                s3Src={editRequest.editorUser?.profilePicUrl}
                title={editRequest.editorUser?.name}
              />
            </ListItemAvatar>
            {/* <ListItemText primary={userConnection?.connectedUser?.name} secondary="" /> */}

          </ListItem>
        })
      }
    </List>

  )
}

export default inject('stores')(observer(BookEditRequestsList));