import React from 'react';
import { useQuery, gql } from '@apollo/client';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import config from '../../aws-exports';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';

import { useGetOwnedBoxsets } from '../../managers/BoxsetsManager';
import StandardImageViewer from './StandardImageViewer';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Feather from 'mdi-material-ui/Feather';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  list: {
    flex: '1',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'grey',
      borderRadius: '4px',
    },
  },
};

function ListBoilerPlate(props) {
  const { ownedBoxsets, error, loading } = useGetOwnedBoxsets(props);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :</p>;

  return (
    <div style={styles.container}>
      <List style={styles.list}>
        {ownedBoxsets.map((boxset) => (
          <ListItem key={boxset.id}
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
                <Feather/>
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar variant="square">
                <StandardImageViewer
                  s3Path={boxset.book.coverUrl}
                  bucketName={config.user_uploads_bucket_name}
                />

              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={boxset.book.title} secondary={`Author: ${boxset.book.author}`} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default ListBoilerPlate;
