import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { NestedMenuItem, IconMenuItem } from 'mui-nested-menu';
import { Typography } from '@mui/material';
import { Menu, MenuItem, Button } from '@mui/material';
import FeedIcon from '@mui/icons-material/Feed';
import VideocamIcon from '@mui/icons-material/Videocam';
import { useTheme } from '@mui/material/styles';
import BookOpenVariant from 'mdi-material-ui/BookOpenVariant';
import FileDocumentEdit from 'mdi-material-ui/FileDocumentEdit';
import FolderOutline from 'mdi-material-ui/FolderOutline';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';


const ContextMenu = (props) => {

  let { selectedId, contextSelectedType, bookDraft } = props;


  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();

  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClosex = () => setAnchorEl(null);

  const handleClose = () => {
    props.handleClose();
  }
  //const handleNodeClick = (event) => setAnchorEl(node);

  //console.log('ContextMenu...');

  let isBoxSet = bookDraft?.book.boxSet != null ? true : false;

  let showFrontMatterOptions = false;
  if (contextSelectedType == 'ROOT' || contextSelectedType == 'TOPBOOK' || contextSelectedType == 'VOLUME' || contextSelectedType == 'PART') {
    showFrontMatterOptions = true;
  }
  return (
   
      <Menu
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        open={props.open}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      // transformOrigin={{
      //   vertical: "top",
      //   horizontal: "left",
      // }}
      >


        {showFrontMatterOptions &&
          <NestedMenuItem
            //leftIcon={<span>+</span>}
            // rightIcon={<span>+</span>}
            label={'Front Matter'}
            parentMenuOpen={props.open}

          >

            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'COPYRIGHT'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={<span>+</span>}
              label={'Add Copyright'}
            />








            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'PROLOGUE'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={<span>+</span>}
              label={'Add Prologue'}
            />



            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'EPIGRAPH'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={}
              label={'Add Epigraph'}
            />

            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'ACKNOWLEDGEMENTS'
                });

                ////handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={<span>+</span>}
              label={'Add Acknowledgements'}
            />

            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'INTRODUCTION'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={<span>+</span>}
              label={'Add Introduction'}
            />

            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'DEDICATION'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={<span>+</span>}
              label={'Add Dedication'}
            />

            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'TITLEPAGE'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={<span>+</span>}
              label={'Add Title Page'}
            />

            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'HALFTITLE'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={<span>+</span>}
              label={'Add Halftitle'}
            />

            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'PREFACE'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={<span>+</span>}
              label={'Add Preface'}
            />



            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'FOREWORD'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={<span>+</span>}
              label={'Add Foreword'}
            />

            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'BLURBS'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={<span>+</span>}
              label={'Add Blurbs'}
            />
            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'TOC'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={<span>+</span>}
              label={'Add Contents'}
            />






          </NestedMenuItem>
        }
        {(contextSelectedType == 'ROOT' || contextSelectedType == 'TOPBOOK') &&
          <IconMenuItem
            onClick={(e) => {
              //console.log(e);
              props.onSelected({
                menuId: 'VOLUME'
              });

              //handleClose();
            }}
            leftIcon={<FolderOutline color={theme.palette.background.main} />}
            //rightIcon={}
            label={'Add Volume'}
          />
        }
        {(contextSelectedType == 'ROOT' || contextSelectedType == 'TOPBOOK' || contextSelectedType == 'VOLUME') &&
          <IconMenuItem
            onClick={(e) => {
              //console.log(e);
              props.onSelected({
                menuId: 'PART'
              });

              //handleClose();
            }}
            leftIcon={<FolderOutline color={theme.palette.background.main} />}
            //rightIcon={}
            label={'Add Part'}
          />
        }
        {(contextSelectedType == 'ROOT' || contextSelectedType == 'TOPBOOK' || contextSelectedType == 'VOLUME' || contextSelectedType == 'PART') &&
          <IconMenuItem
            onClick={(e) => {
              //console.log(e);
              props.onSelected({
                menuId: 'CHAPTER'
              });

              //handleClose();
            }}
            leftIcon={<FolderOutline color={theme.palette.background.main} />}
            //rightIcon={}
            label={'Add Chapter'}
          />
        }
        {(contextSelectedType == 'ROOT' || contextSelectedType == 'TOPBOOK' || contextSelectedType == 'VOLUME' || contextSelectedType == 'PART') &&
          <IconMenuItem
            onClick={(e) => {
              //console.log(e);
              props.onSelected({
                menuId: 'FULLPAGEIMAGE'
              });

              //handleClose();
            }}
            leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
            //rightIcon={}
            label={'Add Full Page Image'}
          />
        }
        {(contextSelectedType == 'ROOT' || contextSelectedType == 'TOPBOOK' || contextSelectedType == 'VOLUME' || contextSelectedType == 'PART') &&
          <IconMenuItem
            onClick={(e) => {
              //console.log(e);
              props.onSelected({
                menuId: 'UNTYPED'
              });

              //handleClose();
            }}
            leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
            //rightIcon={}
            label={'Add Untyped'}
          />
        }
        {/* {contextSelectedType == 'VOLUME' &&
          <IconMenuItem
            onClick={(e) => {
              //console.log(e);
              props.onSelected({
                menuId: 'CHAPTER'
              });

              //handleClose();
            }}
            leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
            //rightIcon={}
            label={'Add Chapter'}
          />
        } */}
        {contextSelectedType == 'CHAPTER' &&
          <IconMenuItem
            onClick={(e) => {
              //console.log(e);
              props.onSelected({
                menuId: 'SCENE'
              });

              //handleClose();
            }}
            leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
            //rightIcon={}
            label={'Add Scene'}
          />
        }



        {/* {contextSelectedType == 'FOLDER' &&
          <IconMenuItem
            onClick={(e) => {
              //console.log(e);
              props.onSelected({
                menuId: 'TEMPLATE'
              });

              //handleClose();
            }}
            leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
            //rightIcon={}
            label={'Add Template'}
          />
        } */}

        {contextSelectedType == 'FOLDER' &&
          <>
            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'FOLDER'
                });

                //handleClose();
              }}
              leftIcon={<VideocamIcon color='primary' />}
              //rightIcon={}
              label={'Add Folder'}
            />

            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'TEMPLATE'
                });

                handleClose();
              }}
              leftIcon={<VideocamIcon color='primary' />}
              //rightIcon={}
              label={'Add Template'}
            />
          </>

        }



        {isBoxSet && (contextSelectedType == 'ROOT' || contextSelectedType == 'TOPBOOK') &&
          <IconMenuItem

            onClick={(e) => {
              //console.log(e);
              props.onSelected({
                menuId: 'ADD_BOOK'
              });

              //handleClose();
            }}
            leftIcon={<BookOpenVariant sx={{
              color: theme.palette.background.main
            }} />}
            //rightIcon={}
            label={'Insert Contributed Book'}
          />
        }

        {(contextSelectedType == 'ROOT' || contextSelectedType == 'TOPBOOK' || contextSelectedType == 'VOLUME' || contextSelectedType == 'PART') &&
          <NestedMenuItem
            //leftIcon={<span>+</span>}
            // rightIcon={<span>+</span>}
            label={'Back Matter'}
            parentMenuOpen={props.open}

          >




            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'AFTERWORD'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={}
              label={'Add Afterword'}
            />

            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'EPILOGUE'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={<span>+</span>}
              label={'Add Epilogue'}
            />

            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'ABOUTAUTHOR'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={}
              label={'Add About Author'}
            />

            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'ALSOBY'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={}
              label={'Add Alsoby'}
            />

            <IconMenuItem
              onClick={(e) => {
                //console.log(e);
                props.onSelected({
                  menuId: 'NOTES'
                });

                //handleClose();
              }}
              leftIcon={<FileDocumentEdit color={theme.palette.background.main} />}
              //rightIcon={}
              label={'Add Notes'}
            />


          </NestedMenuItem>
        }



        {(contextSelectedType != 'ROOT' && contextSelectedType != 'TOPBOOK') &&
          <IconMenuItem
            onClick={(e) => {
              //console.log(e);
              props.onSelected({
                menuId: 'DELETE'
              });

              //handleClose();
            }}
            leftIcon={<TrashCanOutline color={theme.palette.background.main} />}
            //rightIcon={}
            label={'Delete'}
          />
        }




      </Menu>



    
  );
};


export default inject('stores')(observer(ContextMenu));