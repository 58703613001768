import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import SnapshotsPanel from '../../panels/SnapshotsPanel';
import BookExportsList from '../../lists/BookExportsList';
import BookSnapshotsList2 from '../../lists/BookSnapshotsList2';
import OwnedBookSnapshotCard from '../../listPages/listCards/OwnedBookSnapshotCard';
import CreateSnapshot from '../../listPages/dialogs/CreateSnapshot';
import { StandardListButton } from '../../StandardButton';

const WriteDetailsSnapshots = (props) => {

    const theme = useTheme();

    let { bookDraftDocumentPart, bookDraft } = props;
    const [showCreateSnapshot, setShowCreateSnapshot] = useState(false);


    let userId = props.stores?.bookStore.userId;




    return (
        <Box container direction={'column'} sx={{
            justifyContent: 'center',
            //marginRight:'1em'
        }}>
            {/* <SnapshotsPanel book={bookDraft?.book} /> */}
            {/* <BookSnapshotsList2 book={bookDraft?.book} /> */}
            <StandardListButton
                variant="outlined"
                onClick={() => {
                    setShowCreateSnapshot(true)
                }}>Create Snapshot</StandardListButton>

            <OwnedBookSnapshotCard book={bookDraft?.book} />
            <CreateSnapshot
                sx={{ width: '100%' }}
                book={bookDraft?.book}
                open={showCreateSnapshot}
                onClose={() => {
                    setShowCreateSnapshot(false);
                }} />
        </Box>
    );
};


export default inject('stores')(observer(WriteDetailsSnapshots));
