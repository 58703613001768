import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Image from 'mui-image'
import { Typography } from '@mui/material';
import StandardHelpLabel from './StandardHelpLabel';
import Button from '@mui/material/Button';
import { useDropzone } from 'react-dropzone';
import { useUpdateItem } from '../persistance/persistanceHooks';
import config from '../aws-exports';
import BaseControl from './formatting/propertyElements/BaseControl';
import missingImage from '../assets/images/image-not-found.png';
import helpers from '../helpers/helpers';
import SquareAvatar from './lists/SquareAvatar';

let missingBookImage = require('../assets/images/image-not-found.png')
let AWS = require("aws-sdk");

const StandardEntityImageSelector = (props) => {

    //console.log(props);
    let userId = props.stores.userStore.user?.id;
    const [updateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] = useUpdateItem();

    let { entity, imageKeyFieldName, showInformation = false, documentPart } = props;

    let baseControl = new BaseControl(props);

    let convertedImageKeyFieldName = imageKeyFieldName;
    if (documentPart) {

        convertedImageKeyFieldName = baseControl.convertTitle(imageKeyFieldName, documentPart);

    }

    // const item = {
    //     img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    //     title: 'Breakfast',
    //     rows: 2,
    //     cols: 2,
    // }

    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        maxFiles: 1,
        maxSize: 5000000
    });


    //let bucketName = "local-bookmerlincicd8-useruploadedcontents3bucket-1l2gynpa7rn0k";
    let bucketName = config.user_uploads_bucket_name;
    let bucketRegion = config.user_uploads_bucket_region;//"us-east-1";
    let IdentityPoolId = config.identity_pool_id
    //let IdentityPoolId = "us-east-1:df19ecd0-c12a-4c26-bcec-be786f62f103";

    AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: IdentityPoolId
        })
    });

    let s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: bucketName }
    });


    const uploadFile = (file) => {
        
        // let folder = 'uploads/'+userId+'/images';
        // console.log(folder);
        // let fileName = file.name;
        // let folderKey = folder+'/';//encodeURIComponent(folder) + "/";

        let { element } = props;
        


        let fileName = file.name;
        //let fileKey = folderKey + fileName;
        let s3Key = helpers.createUserImageUploadS3Key(userId, fileName);

        //console.log(s3Key);

        // Use S3 ManagedUpload class as it supports multipart uploads
        let upload = new AWS.S3.ManagedUpload({
            params: {
                Bucket: bucketName,
                Key: s3Key,
                Body: file,
                ACL: 'public-read'
            }
        });

        let promise = upload.promise();

        //console.log('updatingItem here...')
        promise.then(
            function (data) {
                //console.log("Successfully uploaded photo.");
                let modifiedValue = s3Key;
                if(element && element.fileSource && element.fileSource!=''){
                    modifiedValue = modifiedValue+'&&&'+element.fileSource
                }


                entity[convertedImageKeyFieldName] = modifiedValue;

                //console.log(entity);
                updateItem({
                    itemKey: entity.id,
                    itemType: entity.modelType ? entity.modelType : entity.__typename,
                    fieldName: convertedImageKeyFieldName,
                    fieldValue: modifiedValue
                })

                if (props.onChange) {
                    props.stores.bookStore.recipeTimestamp = Date.now();
                    props.onChange();
                }


                //viewAlbum(albumName);
            },
            function (err) {
                //console.log(err);
                //return alert("There was an error uploading your photo: ", err.message);
            }
        );
    }



    const getFolderContents = (folder) => {
        s3.listObjects({
            Bucket: bucketName,
            Prefix: folder + '/',
            Delimiter: "/"
        }, function (err, data) {
            if (err) {
                return alert("There was an error listing your albums: " + err.message);
            } else {
                // let albums = data.CommonPrefixes.map(function (commonPrefix) {
                //     let prefix = commonPrefix.Prefix;
                //     let albumName = decodeURIComponent(prefix.replace("/", ""));
                //     return <div>{albumName}</div>
                // });

                let files = data.Contents.filter(f => f.Key != folder + '/');
                //console.log(data);
                //console.log(files);
                // let message = albums.length
                //   ? getHtml([
                //       "<p>Click on an album name to view it.</p>",
                //       "<p>Click on the X to delete the album.</p>"
                //     ])
                //   : "<p>You do not have any albums. Please Create album.";
                // let htmlTemplate = [
                //   "<h2>Albums</h2>",
                //   message,
                //   "<ul>",
                //   getHtml(albums),
                //   "</ul>",
                //   "<button onclick=\"createAlbum(prompt('Enter Album Name:'))\">",
                //   "Create New Album",
                //   "</button>"
                // ];
                // document.getElementById("app").innerHTML = getHtml(htmlTemplate);
            }
        });
    }




    useEffect(() => {

        if (acceptedFiles) {
            const files = acceptedFiles.map(file => (
                <li key={file.path}>
                    {file.path} - {file.size} bytes
                </li>
            ));

            if (acceptedFiles.length > 0) {
                uploadFile(acceptedFiles[0]);
            }

        }


    }, [acceptedFiles]);

    const getEntitySrc = (()=>{

        let src = null;
       
        try{
           

            let fileName = entity[convertedImageKeyFieldName];
            let fileSource = null;
            if(fileName){
    
              try{
                let splitParam = fileName.split('&&&');
                if(splitParam.length==2){
                  fileName=splitParam[0];
                  fileSource=splitParam[1];
                }
              }catch(err){
                console.log(err);
              }
              
            }




            src = 'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + fileName;
           
            return src;
        }catch(err){

            console.log(err);
        }
        
        
       return src;
    })

    let src = getEntitySrc();

    // let src = null;
    // if (entity) {
    //     src = 'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + entity[convertedImageKeyFieldName];
    // }

    let imageStyle = {
        maxWidth: '5em',
        maxHeight: '5em',
        overflow: 'hidden'
    };

    return (
        <div sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', height: '100%' }}>
            {/*https://www.npmjs.com/package/mui-image*/}


            {showInformation &&
                <StandardHelpLabel title={convertedImageKeyFieldName} />
            }
            {/* <Button onClick={() => {
                getFolderContents('folder1');
            }}>
                list
            </Button> */}
            {/* <Button type="button" onClick={open}>
                Open File Dialog
            </Button> */}
            <input {...getInputProps()} />
            <SquareAvatar
                  bucketName={config.user_uploads_bucket_name}
                  s3Src={src}
                  missingContent={<img src={missingBookImage}/>}
                  onClick={()=>{
                    if(!props.disabled){
                        open();
                    }
                    
                }}
                />


            {/* <Image
                width={props.width}
                className="custom-class"
                onClick={()=>{
                    if(!props.disabled){
                        open();
                    }
                    
                }}
                //sx={imageStyle}
                //src={entity?`https://local-bookmerlincicd8-useruploadedcontents3bucket-1l2gynpa7rn0k.s3.amazonaws.com/${entity[imageKeyFieldName]}`:null}
                //src={entity?`https://${config.user_uploads_bucket_name}/${entity[imageKeyFieldName]}`:null}
                src={src}

                showLoading
                
                errorIcon={<img src={missingImage} style={imageStyle}/>} /> */}


            {/* <img 
            onClick={open}
            src={src} 
            width='50px'
            height='50px'
            
            /> */}



            {/*<ImageListItem key={item.img}>
                <img
                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                />
    </ImageListItem>*/}

        </div>
    );
};


export default inject('stores')(observer(StandardEntityImageSelector));
