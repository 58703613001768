import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LaurasUserList from './LaurasUserList';

const LaurasBooksFeedback = (props) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <LaurasUserList
                avatarType='LH'
                showRemoveIcon={false}
                showReadIcon={true}
                primary='Laura Hamel'
                secondary={
                    <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                        A comment from a user on this book. We will probably wrap this and then only show two or three lines of text.
                    </Typography>
                }
            />
            <LaurasUserList
                avatarType='JH'
                showRemoveIcon={false}
                showReadIcon={true}
                primary='Jeff Hamel'
                secondary={
                    <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                        A comment from a user on this book. We will probably wrap this and then only show two or three lines of text. 
                        But for now we will continue this comment to see how long comments look and how it will wrap if it does wrap.
                    </Typography>
                }
            />
            <LaurasUserList
                avatarType='BH'
                showRemoveIcon={false}
                showReadIcon={true}
                primary='Barb Hamel'
                secondary={
                    <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                        A comment from a user on this book. We will probably wrap this and then only show two or three lines of text. 
                        But for now we will continue this comment to see how long comments look and how it will wrap if it does wrap. 
                        And now try to make three lines of text so that we can also see that. As far as your fake book, I have not read 
                        the fake content so I cannot give feedback.
                    </Typography>
                }
            />
        </div>
    )
}
export default inject('stores')(observer(LaurasBooksFeedback));