import Quill from 'quill';
//import Parchment from 'parchment';
import DeltaToDom  from './DeltaToDom';
import { v4 as uuid }  from 'uuid';
const Parchment = Quill.import('parchment');
var $ = require("jquery");

let BlockEmbed = Quill.import('blots/block/embed');


function createContent(node,args){
  	
  	let converter = new DeltaToDom();
	  /*let html = converter.convertBlurb(args);
  	node.innerHTML = html;*/

  	let domNode = converter.convertPublisher(args,node);
  	node.appendChild(domNode);

  	/*let addBlurbButton = document.getElementById('addBlurbBtn');
  	addBlurbButton.addEventListener('click', function(ev) {

      ////console.log(this);

  	})*/

  }

export default class PublisherBlot extends BlockEmbed {
  static create(args) {
    let node = super.create();
    node.dataset.uuid = args.uuid;
    node.dataset.publisher  = args.publisher;
    node.setAttribute('id', args.uuid);

    $(node).click(function () {

      $('#publisherPopperTriggerDiv').data('blotNode', node);
      $('#publisherPopperTriggerDiv').click();


    });

   
    createContent(node,args);
    return node;
  }

  static value(domNode) {
  	let value = {};
  	value.uuid = domNode.dataset.uuid;
    value.publisher = domNode.dataset.publisher;//'{contributors:[{name:"bob"}]}'
    return value;
  }


  updateFromDom(json){
    //console.log(json);
    ////console.log(this.domNode.dataset.publisher);
    
    let publisher = JSON.parse(this.domNode.dataset.publisher);

    
    publisher.name = json.name;
    //publisher.url = json.url;
    publisher.url = json.url;

    publisher.useBookPublisherImage = json.useBookPublisherImage;
    publisher.includePublisherUrl = json.includePublisherUrl;
    publisher.includePublisherLogoLink = json.includePublisherLogoLink;



    let publisherString = JSON.stringify(publisher);
    ////console.log(publisherString);
    this.domNode.dataset.publisher = publisherString;
    
    

  }

  setIncludePublisherUrl(b){

    let publisher = JSON.parse(this.domNode.dataset.publisher);

    publisher.includePublisherUrl = b;
    let publisherString = JSON.stringify(publisher);
    ////console.log(publisherString);
    this.domNode.dataset.publisher = publisherString;

  }

  setLogoSrc(src){

    let publisher = JSON.parse(this.domNode.dataset.publisher);

    publisher.url = src;
    let publisherString = JSON.stringify(publisher);
    ////console.log(publisherString);
    this.domNode.dataset.publisher = publisherString;

  }


  /*deleteAt(index,length) {

    //let textBlot = Parchment.create('text', 'Test');
    //textBlot.insertAt(index, 'ough');
    return false;
  }*/

  deleteAt() {
    return false
  }
  
}
PublisherBlot.blotName = 'publisher';
PublisherBlot.tagName = 'div';
PublisherBlot.className = 'blot-wrapper-publisher';