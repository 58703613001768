import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import StandardDataTable from './StandardDataTable';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useGetUserConnections, useGetUserGroups, useGetUserInvitedToGroups, useManagerAcceptGroupInvitation } from '../../managers/ConnectionsManager';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper, ListItemButton, ListItemText, ListItemIcon, Tab } from '@mui/material';
import config from '../../aws-exports';
import ReactTimeAgo from 'react-time-ago';
import AvatarTableCell from './AvatarTableCell';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CommentIcon from '@mui/icons-material/Comment';
import FileCabinet from 'mdi-material-ui/FileCabinet';
import StandardRoundedAction from '../actions/StandardRoundedAction';
import SettingsIcon from '@mui/icons-material/Settings';
import TablePopupMenu from '../menus/TablePopupMenu';
import { useMediaQuery } from 'react-responsive';
import CampaignIcon from '@mui/icons-material/Campaign';
import GppGoodIcon from '@mui/icons-material/GppGood';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ConnectionsList from '../lists/ConnectionsList';
import SendInviteAssembly from '../assemblies/SendInviteAssembly';
import StandardTabs from '../StandardTabs';
import StandardDashboardPaper from '../StandardPaper';
import CreateGroupAssembly from '../assemblies/CreateGroupAssembly';
import PeopleIcon from '@mui/icons-material/People';
import SharedGroupsTable from './SharedGroupsTable';
import YourGroupsTable from './YourGroupsTable';
import ConnectionsTable from './ConnectionsTable';
import ConnectionsTabContent from '../boilerPlate/ConnectionsTabContent';
import StretchBox from '../boilerPlate/StretchBox';
import MyGroupsTabContent from '../boilerPlate/MyGroupsTabContent';
import SharedGroupsTabContent from '../boilerPlate/SharedGroupsTabContent';
import ConnectionsPage from '../listPages/ConnectionsPage';
import MyGroupsPage from '../listPages/MyGroupsPage';
import SharedGroupsPage from '../listPages/SharedGroupsPage';

const ConnectionsAndGroups = (props) => {

  const { userConnections, error: getUserConnectionsError } = useGetUserConnections(props);
  const { userGroups, error: getUserUserGroupsError, loading: getUserUserGroupsLoading } = useGetUserGroups(props);
  const { userGroups: sharedGroups, loading: getUserInvitedToUserGroupsLoading, error: getUserInvitedToUserGroupsError, getUserInvitedToUserGroupsRefetch } = useGetUserInvitedToGroups(props);
  const { acceptGroupInvitation, loading: acceptGroupInvitationLoading, error: acceptGroupInvitationError } = useManagerAcceptGroupInvitation(props);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [selectedTab, setSelectedTab] = React.useState('connectionsLive');


  const isXs = useMediaQuery({ maxWidth: 599 })
  const isSm = useMediaQuery({ maxWidth: 600 })
  const isMd = useMediaQuery({ maxWidth: 900 })
  const isLg = useMediaQuery({ maxWidth: 1200 })
  const isXl = useMediaQuery({ minWidth: 1536 })


  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {


    setOrder("desc");
    setOrderBy("createdAt");

  }, [userConnections]);


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  return (

    <StretchBox flexDirection='column'>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <StandardTabs
          value={selectedTab}
          onChange={(event, newValue) => {
            setSelectedTab(newValue);
          }}
          aria-label="basic tabs example">
          {/* <Tab value='connections' label="Connections" {...a11yProps(0)} />
          <Tab value='myGroups' label="My Groups" {...a11yProps(1)} />
          <Tab value='sharedGroups' label="Shared Groups" {...a11yProps(2)} /> */}
          <Tab value='connectionsLive' label="Connections" {...a11yProps(3)} />
          <Tab value='myGroupsLive' label="My Groups" {...a11yProps(4)} />
          <Tab value='sharedGroupsLive' label="Shared Groups" {...a11yProps(5)} />
        </StandardTabs>

      </Box>
      {/* {selectedTab && selectedTab == 'connections' &&
        // <ConnectionsTable />
        <ConnectionsTabContent />
      }

      {selectedTab && selectedTab == 'myGroups' &&
        <MyGroupsTabContent />
      }

      {selectedTab && selectedTab == 'sharedGroups' &&
        <SharedGroupsTabContent />
      } */}

      {selectedTab && selectedTab == 'connectionsLive' &&
        <ConnectionsPage />
      }

      {selectedTab && selectedTab == 'myGroupsLive' &&
        <MyGroupsPage />
      }

      {selectedTab && selectedTab == 'sharedGroupsLive' &&
        <SharedGroupsPage />
      }



      {/* </Box> */}
      {/* </ScrollBox> */}
    </StretchBox>


  )
}
export default inject('stores')(observer(ConnectionsAndGroups));