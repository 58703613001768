import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { styled, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Feather from 'mdi-material-ui/Feather';
import { ApolloConsumer } from '@apollo/client';
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import CommentIcon from '@mui/icons-material/Comment';
import { Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DashboardCard from '../dashboard/DashboardCard';
import StandardButton, { StandardRoundedButton, StandardListButton } from '../StandardButton';
import MostRecentCardContent from '../dashboard/MostRecentCardContent';
import GoalsCardContent from '../dashboard/GoalsCardContent';
import FeedbackCardContent from '../dashboard/FeedbackCardContent';
import { useGetOwnedBooks, useOpenBookInWrite, useOpenBookInFormat, useGetSnapshots, useManagerCreateBook } from '../../managers/BooksManager';
import BookListItem from "../lists/BookListItem";
import ImageTitleTableCell from '../table/ImageTitleTableCell';
import AvatarTableCell from '../table/AvatarTableCell';
import config from '../../aws-exports';
import { useGetReceivedEditRequests, useOpenEditRequestInEdit } from '../../managers/EditRequestsManager';
import { useGetUserConnections } from '../../managers/ConnectionsManager';
import FeedbackDashboard from '../dashboard/FeedbackDashboard';
import { render } from '@testing-library/react';
import CreateBookDialog from '../panels/CreateBookDialog';
import StandardOkCancelDialog from '../panels/StandardOkCancelDialog';
import CreateBookAssembly from '../assemblies/CreateBookAssembly';

const StandardDrawer = styled(Drawer)(() => {


})


function OwnedBooksListMenuContent(props) {
    
    let book = props.stores.bookStore.bookListBook;
    let { showWriteIcon = true, showFormatIcon = true, onWriteSelected = () => { }, onFormatSelected = () => { } } = props;

    let navigate = useNavigate();
    let location = useLocation();
    const { openBookInWrite } = useOpenBookInWrite(props);

    const theme = useTheme();
    let [openBooks, setOpenBooks] = useState(true);
    let [showCreateBookDialog, setShowCreateBookDialog] = useState(false);

    const { ownedBooks, error } = useGetOwnedBooks(props);
    let mostRecentBooks = [];
    if (ownedBooks && ownedBooks.length > 0) {

        mostRecentBooks = ownedBooks.slice(0, 1).map((book) => {
            return <ImageTitleTableCell
                bucketName={config.user_uploads_bucket_name}
                title={book?.title}
                s3Src={book?.coverUrl}
                details={'Opened'}
                showWriteIcon={true}
                showFormatIcon={true}
                onClick={(e) => {
                    e.preventDefault();
                    //console.log(book)
                    props.stores.bookStore.bookListBook = book;
                    navigate("/books/details");
                    // openBookInWrite({
                    //   bookDraftId: book.primaryBookDraftId
                    // });
                }}
                onWriteSelected={(e) => {
                  
                  e.preventDefault();
                  //console.log(book)
                  //navigate("/write");
                  openBookInWrite({
                      bookDraftId: book.primaryBookDraftId
                  });
                }}
            />


        })
    }

    let currentGoals = [];
    if (ownedBooks && ownedBooks.length > 1) {

        currentGoals = ownedBooks.slice(0, 2).map((book) => {
            return <ImageTitleTableCell
                bucketName={config.user_uploads_bucket_name}
                title={book?.title}
                s3Src={book?.coverUrl}
                details={'You are 75% to your goal'}
                showWriteIcon={true}
            />


        })
    }

    const { userConnections } = useGetUserConnections(props);
    let mostRecentFeedBack = [];
    if (userConnections && userConnections.length > 1) {

        mostRecentFeedBack = userConnections.slice(0, 3).map((userConnection) => {
            return <AvatarTableCell
                bucketName={config.user_uploads_bucket_name}
                s3Src={userConnection?.connectedUser?.profilePicUrl}
                title={userConnection?.connectedUser?.name}
                details={'Brief view of comment'}
                showWriteIcon={true}
            />


        })
    }


    const { editRequests } = useGetReceivedEditRequests(props);
    let mostRecentEditRequest = [];
    if (editRequests && editRequests.length > 1) {

        mostRecentEditRequest = editRequests.slice(0, 2).map((editRequest) => {
            return <ImageTitleTableCell
                bucketName={config.user_uploads_bucket_name}
                title={editRequest.book?.title}
                s3Src={editRequest?.book?.coverUrl}
                details={'Editor:' + ' ' + editRequest.editorUser?.name}
                showEditIcon={true}
            />


        })
    }

    const subItemsIndent = 8;
    const subSubItemsIndent = 0;


    const isRoute = (pathname) => {
        //if (pathname && pathname.includes(location.pathname) ) {
        if (location.pathname && location.pathname.includes(pathname)) {
            return 'primary';
        }

        return 'inherit';
    }

    const handleSelection = () => {

    }

    const onLogout = event => {
        event.preventDefault();
        props.stores.bookStore.loggedIn = false;

        Auth.signOut()
            .then(data => {
                //console.log(data);

                props.stores.bookStore.loggedIn = false;
                //props.stores.bookStore.reset();

            })
            .catch(err => {
                console.log(err);

            })
    };

    const selectRoute = (route) => {

        navigate(route);
        props.onRouteSelected();

    }

    return (
        <>
            {/* <Grid container direction={'column'}
                sx={{
                    // justifyContent:'space',
                    // alignItems:'center',
                    // width:'100%'
                }}> */}

        <Grid container sx={{
            display:'flex',
            flexDirection:'column',
            //border:'1px solid red',
            gap:'2em'
        }}>
            <Grid item xs={12}>
                <DashboardCard title={'Most Recent'} color={theme.palette.secondary.main} icon={<MenuBookIcon sx={{ color: '#ffffff' }} />} >
                    {mostRecentBooks}
                </DashboardCard>
            </Grid>

            <Grid item xs={12}>
                <DashboardCard title={'Goals'} color={theme.palette.secondary.main} icon={<Feather sx={{ color: '#ffffff' }} />}>
                    <StandardListButton
                        variant="outlined"
                        onClick={() => {
                            // setShowCreateBookDialog(true)
                        }} >Create Goal</StandardListButton>
                </DashboardCard>
            </Grid>

            <Grid item xs={12}>
                <DashboardCard title={'Feedback'} color={theme.palette.secondary.main} icon={<CommentIcon sx={{ color: '#ffffff' }} />} >
                    <Box>
                        <FeedbackDashboard />
                    </Box>
                </DashboardCard>
            </Grid>

            <Grid item xs={12}>
                <DashboardCard title={'Editing'} color={theme.palette.secondary.main} icon={<EditIcon sx={{ color: '#ffffff' }} />}>
                    {mostRecentEditRequest > 1 ? { mostRecentEditRequest } : <Typography>Edit requests you have sent will appear here.</Typography>}
                </DashboardCard>
            </Grid>

            {/* <Grid item xs={12}>
                <DashboardCard title={'Feedback'} color={theme.palette.feedback?.main} icon={<CommentIcon sx={{ color: theme.palette.secondary.main }} />}>
                    <FeedbackCardContent />
                </DashboardCard>
            </Grid> */}

            <StandardOkCancelDialog
                open={showCreateBookDialog}
                onClose={() => {
                    setShowCreateBookDialog(false)
                }}
                onOk={() => {
                    setShowCreateBookDialog(false)

                }}
                title={'Create a Book'}
                content={
                    <CreateBookDialog
                        open={showCreateBookDialog}
                        onClose={(book) => {
                            setShowCreateBookDialog(false);

                        }}

                        onBookCreated={(book) => {
                            setShowCreateBookDialog(false);

                            openBookInWrite({
                                bookDraftId: book.primaryBookDraftId
                            });

                        }}


                    />
                }
            />

            {/* <Grid item xs={12} >
                <CreateBookAssembly book={book}/>
                 <StandardListButton
                    variant="outlined"
                    onClick={() => {
                        setShowCreateBookDialog(true)
                    }} >Create Book</StandardListButton>
            </Grid> */}
            {/* </Grid> */}

            </Grid>

        </>


    );
}

export default inject('stores')(observer(OwnedBooksListMenuContent));