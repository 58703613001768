import Quill from 'quill';
//import ReactQuill, { Quill } from 'react-quill';
let Block = Quill.import('blots/block');

let Parchment = Quill.import("parchment");

class BlockQuoteBlot extends Block { }
BlockQuoteBlot.blotName = 'blockquote';
BlockQuoteBlot.tagName = 'blockquote';
BlockQuoteBlot.className = 'boundedblockquote';

export default BlockQuoteBlot ;
