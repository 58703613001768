import React, { useState, useEffect } from "react";
import { Grid, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import config from '../../../aws-exports';
import { useGetInvitedToBoxsets } from '../../../managers/BoxsetsManager';
import ShareIcon from '@mui/icons-material/Share';
import StandardUISquareList from "../../UI/StandardUISquareList";
import ReactTimeAgo from 'react-time-ago';
import EmptyContent from "../../EmptyContent";
import Avatar from '@mui/material/Avatar';
import Feather from 'mdi-material-ui/Feather';
import IconButton from '@mui/material/IconButton';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ListItemButton from '@mui/material/ListItemButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import helpers from '../../../helpers/helpers';
import { useGetSharedBooks, useOpenBookInWrite, useOpenBookInRead, useOpenBookInFormat, useManagerCreateBook } from '../../../managers/BooksManager';

const ListSharedBoxsets = (props) => {

    let { book, boxset, showReadIcon = true, s3Src, selectedBoxset, order='desc', orderBy='createdAt' } = props;
    let user = props.stores.userStore.user;
    const { invitedToBoxsets, error, loading } = useGetInvitedToBoxsets(props, user?.id)
    const { openBookInRead } = useOpenBookInRead(props);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    useEffect(() => {



    }, [invitedToBoxsets]);


    const getBoxsetContributedBooks = (boxset) => {
        let allContributedBoxSetBooks = {};
        boxset.boxsetContributors?.map((boxsetContributor) => {

            boxsetContributor.contributedBoxsetBooks.map((bsb) => {
                allContributedBoxSetBooks[bsb.book.id] = bsb;
            });

        });

        return Object.values(allContributedBoxSetBooks);
    }


    const generateSecondaryActions = (boxset) => {

    const secondaryActions = [];

    if (showReadIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
                openBookInRead({
                    // bookDraftId: boxset.book.primaryBookDraftId
                    sharedBook:boxset.book
                });
            }}>
            <ManageSearchIcon />
        </IconButton>)
    }
    return secondaryActions;
}

    let sortedInvitedToBoxsets = [];
    if(invitedToBoxsets!=null){
  
      sortedInvitedToBoxsets = invitedToBoxsets.map((boxset)=>{
  
        let compositeInvitedToBoxsets = {
          object:boxset,
          title:boxset.book.title,
          createdAt:boxset.sharedAt
          
        }
        
  
        return compositeInvitedToBoxsets;
      });
      sortedInvitedToBoxsets = helpers.stableSort(sortedInvitedToBoxsets, order, orderBy, props.stores.bookStore.sortOrder, props.stores.bookStore.sortOrderBy==='alpha'?'title':props.stores.bookStore.sortOrderBy);
  
    }

   

    return (
        <div>
            <List>
                {invitedToBoxsets && invitedToBoxsets.length > 0 ?
                    sortedInvitedToBoxsets.map((compositeInvitedToBoxsets, index) => {

                        
                  let boxset = compositeInvitedToBoxsets.object;

                        //console.log(index);
                        let selectedIndex = null;
                        if (boxset.id == selectedBoxset?.id) {
                            selectedIndex = index;
                        }
                        return <ListItem
                        selected={selectedIndex===index}
                            secondaryAction={
                                generateSecondaryActions(boxset)
                            }
                        > <ListItemAvatar>
                                <Avatar variant="rounded"
                                    bucketName={config.user_uploads_bucket_name}
                                    s3Src={boxset?.coverUrl}
                                >
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={boxset.book?.title}
                                secondary={boxset?.owner?.name}
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onSelectBoxset(boxset);
                                  }}
                          
                           />
                        </ListItem>




                    }


                    )
                    :
                    <EmptyContent tagline={'Boxsets you have been invited to will appear here.'} />
                }
            </List>
        </div>
    )
}

export default inject('stores')(observer(ListSharedBoxsets));