import EditChangeListItem from './EditChangeListItem';
import Quill from 'quill';

var $ = require("jquery");
const Parchment = Quill.import('parchment');

export function extractJQueryBlotData(editBookDraft) {

    //console.log('extractJQueryBlotData()...');
    let alreadyMapped = {

    };
    let allLineItems = {};
    let editNodes = $(".editingBlot");
    $(editNodes).each(function (index) {

        let change = this.dataset;
        let node = $("div").find("[data-uuid='" + change.uuid + "']")[0];
        let blot = Parchment.find(node);

        let blots = [];
        $("div").find("[data-uuid='" + change.uuid + "']").each(() => {
            let blot = Parchment.find(node);
            if (blot != null) {
                blots.push(blot);
            }
        })


        if (alreadyMapped[change.uuid] == null) {
            alreadyMapped[change.uuid] = change;
            allLineItems[change.uuid] =
                <EditChangeListItem key={change.uuid} editBookDraft={editBookDraft} editChange={change} blotId={change.uuid} blot={blot} blots={blots} onDelete={(change) => {
                    extractJQueryBlotData();
                }} />
        }
    });

    //setAllBlots(allLineItems);
    return allLineItems;

}