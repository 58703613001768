import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import Quill from 'quill';
import StarIcon from '@mui/icons-material/Star';
//import { useLazyListAllBookDraftUserComments, useListAllBookDraftUserComments, useLazyListBookDraftUserComments } from '../../persistance/persistanceHooks';
import { UserComment as UserCommentPojo } from '../../domain/bookModel';
import UserNote from './UserNote';
import { Box, Paper } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import UserCommentInput from './UserCommentInput';
import UserComment from './UserComment';
import ToolbarItem from './toolbar/ToolbarItem';
import Scrollbars from 'react-custom-scrollbars-2';
import { useTheme } from '@mui/material/styles';


var $ = require("jquery");

const DocumentPartUserNotes = (props) => {

  let { showConnectionIcon = false, showBookInfo, onShowComments = () => { }, onHideComments = () => { } } = props;
  let { showComments } = props.stores.bookStore;
  let [userCommentItems, setUserCommentItems] = useState([]);

  const theme = useTheme();

  // let { 
  //   loading: listUserBookDraftUserCommentsLoading, 
  //   error: listUserBookDraftUserCommentsError, 
  //   refetch: listUserBookDraftUserCommentsRefetch,
  //   userComments:userCommentsData } = useLoadBookReaderComments(props, { bookDraftId:props.book.bookDraft.id})

  // useEffect(()=>{

  //   console.log(userCommentsData);
  //   if(userCommentsData){
  //     setUserCommentItems(userCommentsData.map((userComment)=>{

  //       return <UserComment ownerMode={false} showBookInfo={false} userComment={userComment} onUpdated={() => { }} />

  //     }))
  //   }
  // },[userCommentsData])

  const createUserCommentItem = (data, documentPart) => {

    let { viewFavoritedComments, viewPinnedComments, viewCompletedComments, viewPendingComments } = props.stores.bookStore;


    let filteredComments = data;

    let userCommentItems = filteredComments.filter(f => f.deletedAt == null).filter(f => f.documentPartId == documentPart.id).map((userComment) => {


      // return <UserComment ownerMode={props.ownerMode} userComment={userComment} onUpdated={() => {}} />
      return userComment;

    });


     
    userCommentItems = userCommentItems.sort(function (a, b) { return a.partIndex - b.partIndex });


    documentPart.bookDraftDocumentPart.getSortedChildren().forEach((bddp) => {
      userCommentItems = userCommentItems.concat(createUserCommentItem(data, bddp.documentPart));
    })


    return userCommentItems;

  }












  useEffect(() => {


    try {

      // setUserCommentItems(props.stores.bookStore.userNotes.filter(f => f.documentPartId == props.documentPart.id).map((userComment) => {

      //   return <UserComment ownerMode={false} showBookInfo={false} userComment={userComment} onUpdated={() => { }} />

      // }))

      let commentItems = createUserCommentItem(props.stores.bookStore.userNotes.slice(), props.documentPart).map((userComment) => {

        return <UserNote ownerMode={false} showBookInfo={false} userComment={userComment} onUpdated={() => { }} />

      });
      setUserCommentItems(commentItems);

    } catch (err) {
      //do nothing
      console.log(err);
    }


  }, [
    props.stores.bookStore.userNotes,
    props.stores.bookStore.userNotesTimestamp,
    props.documentPart])



  const fontSize = 'small';




  const showCommentsIcon = <ToolbarItem
    icon={<VisibilityOutlinedIcon
      fontSize={fontSize}
      sx={{
        color: props.stores.bookStore.showNotesInEditor ? theme.palette.secondary.main : 'disabled'
      }}
      //color={props.stores.bookStore.viewPinnedComments ? theme.palette.secondary.main : 'disabled'} 
      onClick={(e) => {
        e.preventDefault();
        props.stores.bookStore.showNotesInEditor = !props.stores.bookStore.showNotesInEditor
      }} />} />;

  const pushPinIcon = <ToolbarItem
    icon={<PushPinIcon
      fontSize={fontSize}
      sx={{
        color: props.stores.bookStore.viewPinnedComments ? theme.palette.secondary.main : 'disabled'
      }}
      //color={props.stores.bookStore.viewPinnedComments ? theme.palette.secondary.main : 'disabled'} 
      onClick={(e) => {
        e.preventDefault();
        props.stores.bookStore.viewPinnedComments = !props.stores.bookStore.viewPinnedComments
      }} />} />;

  const starIcon = <ToolbarItem
    icon={<StarIcon
      fontSize={fontSize}
      sx={{
        color: props.stores.bookStore.viewFavoritedComments ? theme.palette.secondary.main : 'disabled'
      }}
      //color={props.stores.bookStore.viewFavoritedComments ? theme.palette.secondary.main : 'disabled'} 
      onClick={(e) => {
        e.preventDefault();
        props.stores.bookStore.viewFavoritedComments = !props.stores.bookStore.viewFavoritedComments
      }} />} />;

  const completedIcon = <ToolbarItem
    icon={<CheckCircleOutlineIcon
      fontSize={fontSize}
      sx={{
        color: props.stores.bookStore.viewCompletedComments ? theme.palette.secondary.main : 'disabled'
      }}
      //color={props.stores.bookStore.viewCompletedComments ? theme.palette.secondary.main : 'disabled'}
      onClick={(e) => {
        e.preventDefault();

        if (props.stores.bookStore.viewCompletedComments) {
          props.stores.bookStore.viewCompletedComments = false;
        }

        else if (!props.stores.bookStore.viewCompletedComments) {
          props.stores.bookStore.viewCompletedComments = true;
          props.stores.bookStore.viewPendingComments = false;
        }


      }} />} />;

  const pendingIcon = <ToolbarItem
    icon={<PendingOutlinedIcon
      fontSize={fontSize}

      sx={{
        color: props.stores.bookStore.viewPendingComments ? theme.palette.secondary.main : 'disabled'
      }}
      //color={props.stores.bookStore.viewPendingComments ? theme.palette.secondary.main : 'disabled'} 
      onClick={(e) => {
        e.preventDefault();
        if (props.stores.bookStore.viewPendingComments) {
          props.stores.bookStore.viewPendingComments = false;
        }

        else if (!props.stores.bookStore.viewPendingComments) {
          props.stores.bookStore.viewPendingComments = true;
          props.stores.bookStore.viewCompletedComments = false;
        }
      }} />} />;


  return (
    <div style={{
      height: '100%'
    }}>

      {/* <button onClick={() => {
        //showAllComments(props, commentItems);
      }}>
        RENDER
      </button> */}
      {/* {props.stores.bookStore.userNotesTimestamp} */}
      {/* <div>notes:{props.stores.bookStore.userNotes.length}</div> */}
      {props.ownerMode &&
        <div focusable='false' tabIndex={-1} id='master-toolbar' style={{
          'width': '100%',
          marginBottom: '0em'
        }}>

          <Box focusable='false' tabIndex={-1} className='toolbar-wrapper' sx={{
            display: 'flex',
            justifyContent: 'space-around',
            'flex-direction': 'row',
            'flex-wrap': 'wrap',
            'alignItems': 'center',
            width: '100%',
            borderx: '1px solid gray',
            padding: '0em 0em .5em 0em'
          }}>
            {showCommentsIcon}
            {pushPinIcon}
            {starIcon}
            {completedIcon}
            {pendingIcon}

          </Box>


        </div>
      }

      {userCommentItems}





      {/* <UserCommentInput documentPart={props.documentPart} /> */}

    </div>
  );
}

export default inject('stores')(observer(DocumentPartUserNotes));
