import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SortIcon from '@mui/icons-material/Sort';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';


const LaurasCard = (props) => {

    let { showPagination = true, showSearchField = false } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [anchorEl, setAnchorEl] = useState(null);

     const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
     };

     const handleClose = () => {
          setAnchorEl(null);
     };

    const cardPagination = [];

    if (showPagination) {
        cardPagination.push(
            <Divider />,
            <Pagination count={5} color="primary" />
        )
    }

    const cardSearch = [];

    if (showSearchField) {
        cardSearch.push(
            <FormControl variant="outlined" fullWidth style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    variant='outlined'
                    fullWidth
                />
                <IconButton onClick={handleClick}>
                    <SortIcon />
                </IconButton>

            </FormControl>
        )
    }


    return (
        <div>
            <Card sx={{ minWidth: 275 }} variant="outlined" >
                <CardHeader
                    title={props.title}
                    action={props.actions}
                />
                <div
                    style={{ paddingLeft: '1em' }}
                >
                    {cardSearch}
                </div>

                <CardContent>
                    {props.heading}
                    {props.children}
                    {cardPagination}
                </CardContent>
            </Card>
            
            <Popover
                    open={anchorEl}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                    }}
               >
                    <Typography sx={{ p: 2 }}>A - Z</Typography>
                    <Typography sx={{ p: 2 }}>Z - A</Typography>
                    <Typography sx={{ p: 2 }}>Newest</Typography>
                    <Typography sx={{ p: 2 }}>Oldest</Typography>
               </Popover>
        </div>
    )
}
export default inject('stores')(observer(LaurasCard));