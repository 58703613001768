import editingUtils from './editingUtils';
import Quill from 'quill';
//import Delta from 'quill-delta';
import { v4 as uuid } from 'uuid';
let Delta = Quill.import('delta');
//const editingUtils = require('./editingUtils');

export default class EditingProcessor {

    

    processEditing = (quill, delta, props, documentPart) => {

        //console.log('testProcessEditing...');
        
        let finalFullDelta = null;
        let finalPartialDelta = null;
    
        if (editingUtils.isPasted({
            quill,
            bookStore: props.stores.bookStore,
            delta,
            documentPart
        })) {
            //console.log('it was pasted');
    
            finalPartialDelta = editingUtils.handlePasted({
                quill,
                bookStore: props.stores.bookStore,
                delta,
                documentPart
            });
    
            //finalFullDelta = delta.compose(finalPartialDelta);
            finalFullDelta = finalPartialDelta;
    
            props.stores.bookStore.mostRecentQuillClipboardDelta = null;
            props.stores.bookStore.mostRecentQuillClipboardSelection = null;
        }





        else if (editingUtils.isBackspace({
            quill,
            bookStore: props.stores.bookStore,
            delta,
            documentPart
        })) {
    
            //Have to call this twice since the indexes do not line up due to the character being deleted. 
            let { formatAhead } = editingUtils.getNearbyFormats({
                range: {
                    index: props.stores.bookStore.backspaceRange.index - 2,
                    length: 1
                },
                //formatType: 'editingDeleteBlot',
                quill
            });
    
    
            let { formatBehind } = editingUtils.getNearbyFormats({
                range: {
                    index: props.stores.bookStore.backspaceRange.index - 1,
                    length: 1
                },
                //formatType: 'editingDeleteBlot',
                quill
            });
    
    
    
            //*** If attempting to delete an insert blot, delete is like normal text deletion. 
            if (editingUtils.hasFormat(props.stores.bookStore.backspaceDelta, 'editingInsertBlot')) {
    
                finalPartialDelta = null;
    
                finalFullDelta = delta;
    
            } else {
    
                let blotUuid = uuid.v1();
                if (formatAhead && formatAhead.editingDeleteBlot) {
                    blotUuid = formatAhead.editingDeleteBlot.uuid;
                }
    
                let deleteBlotDelta = new Delta([
                    {
                        "retain": props.stores.bookStore.backspaceRange.index - 1
                    },
                    {
                        //"retain": formattingLength + length,
                        "retain": props.stores.bookStore.backspaceDelta.length(),
                        "attributes": {
                            "editingDeleteBlot": {
                                "uuid": blotUuid,
                                "accepted": "undefined",
                                "rejected": "undefined",
                                "editorPinned": "undefined",
                                "requestorPinned": "undefined",
                                "editorComment": ""
                            }
                        }
                    }
                ]);
    
    
    
                let deleteDelta = new Delta([{
                    "retain": props.stores.bookStore.backspaceRange.index - 1
                }].concat(props.stores.bookStore.backspaceDelta.ops));
    
                //deleteOps = deleteOps.concat(deleteBlotDelta.ops);
    
                let finalBlotDelta = deleteDelta.compose(deleteBlotDelta);
    
                //console.log(finalBlotDelta);
    
    
    
                quill.updateContents(finalBlotDelta, 'user');
                props.stores.bookStore.setBlotsChangedTimestamp();
    
                finalPartialDelta = finalBlotDelta;
    
                finalFullDelta = delta.compose(finalPartialDelta);
                //finalPartialDelta = finalPartialDelta;
            }
    
    
    
    
        }


        
        else if (editingUtils.isDelete({
            quill,
            bookStore: props.stores.bookStore,
            delta,
            documentPart
        })) {
    
    
            let parts = editingUtils.extractParts({
                quill,
                range:quill.getSelection()
            });
    
    
            if (props.stores.bookStore.deleteFormats && props.stores.bookStore.deleteFormats.editingInsertBlot) {
                finalPartialDelta = null;
    
                finalFullDelta = delta;
            } else if (!editingUtils.hasFormat(props.stores.bookStore.deleteDelta, 'editingInsertBlot')) {

               //1. Create the insert the deleted content delta. 
               let insertDeletedDelta = new Delta([
                   {
                       "retain":props.stores.bookStore.deleteRange.index
                   }
               ].concat(props.stores.bookStore.deleteDelta.ops))

               //2. Create the update delta
               let deleteBlotDelta = new Delta([
                {
                    "retain": props.stores.bookStore.deleteRange.index
                },
                {
                    //"retain": formattingLength + length,
                    "retain": props.stores.bookStore.deleteDelta.length(),
                    "attributes": {
                        "editingDeleteBlot": {
                            "uuid": uuid(),
                            "accepted": "undefined",
                            "rejected": "undefined",
                            "editorPinned": "undefined",
                            "requestorPinned": "undefined",
                            "editorComment": ""
                        }
                    }
                }
            ]);
    
            
            //2. Combine the deleted delta and the editingDeleteBlot delta.
            let finalPartialDelta = insertDeletedDelta.compose(deleteBlotDelta);
    
                quill.updateContents(finalPartialDelta,Quill.sources.USER);
                props.stores.bookStore.setBlotsChangedTimestamp();
    
                //finalPartialDelta = null;
    
                finalFullDelta = new Delta(delta.ops.concat(finalPartialDelta.ops)); //delta.compose(finalPartialDelta);
                //finalPartialDelta = finalPartialDelta;
            }
    
    
    
    
    
        }
        else if (editingUtils.isKeyPressed({
            quill,
            bookStore: props.stores.bookStore,
            delta,
            documentPart
        })) {
    
            finalPartialDelta = editingUtils.handleKeyPressed({
                quill,
                bookStore: props.stores.bookStore,
                delta,
                documentPart
            });
    
    
            //NOTE: A null value for finalPartialDelta happens when a key is pressed next to or inside 
            //      text that is already an insert blot. In this case, quill automatically applies the existing 
            //      insert blot formatting so no new Deltas are necessary. 
            //      However, we still need to return the starting delta as the finalFullDelta so it can be saved after this 
            //      function returns to the text-changed handler. 
            if (!finalPartialDelta) {
                finalFullDelta = delta;
            } else {
                finalFullDelta = delta.compose(finalPartialDelta);
            }
    
    
        }
    
    
        props.stores.bookStore.mostRecentQuillKeyPress = null;
        props.stores.bookStore.mostRecentQuillKeyPressSelection = null;
        props.stores.bookStore.mostRecentQuillClipboardDelta = null;
    
    
        props.stores.bookStore.deleteHit = false;
        props.stores.bookStore.deleteRange = null;
        props.stores.bookStore.deleteDelta = null;
        props.stores.bookStore.deleteFormats = null;
    
        props.stores.bookStore.backspaceHit = false;
        props.stores.bookStore.backspaceRange = null;
        props.stores.bookStore.backspaceDelta = null;
    
    
        quill.mostRecentSelection = null;
        quill.mostRecentSelectionDelta = null;
    
    
    
        return {
            finalFullDelta,
            finalPartialDelta
        }
    
    
    }
}
