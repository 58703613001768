import React, { useState, useEffect } from "react";
import { inject, observer } from 'mobx-react';
import { Box, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import config from '../../../aws-exports';
import StandardUIDialog from "../../UI/StandardUIDialog";
import ListOwnedBooks from "../lists/ListOwnedBooks";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../../managers/BooksManager';
import CircularProgress from '@mui/material/CircularProgress';


const ConnectionShareBook = (props) => {

    let user = props.stores.userStore.user;
    let { userConnection } = props;

    const { shareBookWithUser, loading: shareBookLoading, error: sshareBookError } = useShareBookWithUser(props);



    return (
        <StandardUIDialog
            open={props.open}
            onClose={props.onClose}
            title='Share Book'
            dialogContent={
                <Grid>
                    <ListOwnedBooks
                        showWriteIcon={false}
                        showFormatIcon={false}
                        showAddIcon={true}
                        filteredBooks={[]}
                        showSelectIcon
                        onSelectBook={(book) => {

                            if (book) {
                                shareBookWithUser({
                                    bookId: book.id,
                                    userId: userConnection.connectedUser.id
                                },
                                    () => {

                                        //onSuccess
                                        if (props.onShareCreated) {
                                            props.onShareCreated();
                                        }

                                    }, () => {

                                        //onError

                                    })
                            }

                        }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {shareBookLoading && <CircularProgress />}
                    </div>
                </Grid>
            }
        // cardActions={

        // }
        />
    )
}

export default inject('stores')(observer(ConnectionShareBook));