import React, { useState, useEffect } from "react";
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText, Tab, Typography, Link, Collapse, Button } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import { useGetSnapshots, useOpenBookInWrite, useOpenSnapshotInWrite } from '../../managers/BooksManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton from "../StandardButton";
import SquareAvatar from "./SquareAvatar";
import DeleteIcon from '@mui/icons-material/Delete';
import StandardRoundedAction from "../actions/StandardRoundedAction";
import Feather from "mdi-material-ui/Feather";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import TablePopupMenu from "../menus/TablePopupMenu";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CommentIcon from '@mui/icons-material/Comment';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import ButtonTableCell from "../table/ButtonTableCell";
import DeleteSnapshotPanel from "../panels/DeleteSnapshotPanel";
import RestoreSnapshotPanel from "../panels/RestoreSnapshotPanel";
import StandardDialog from "../StandardDialog";
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useGetBookDraftBuildRequests } from "../../managers/ExportsManager";
import GeneratedDocsList from "../table/GeneratedDocsList";
import { useMediaQuery } from 'react-responsive';
import StandardOkCancelDialog from "../panels/StandardOkCancelDialog";
import ExportDialog from "../panels/ExportDialog";
import ReactTimeAgo from "react-time-ago";
import GoogleIcon from '@mui/icons-material/Google';
import DescriptionIcon from '@mui/icons-material/Description';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EmptyContent from "../EmptyContent";
import ListExpandIcon from "./ListExpandIcon";
import StandardList from "./StandardList";
import CreateExportsAssembly from "../assemblies/CreateExportsAssembly";

let missingBookImage = require('../../assets/images/image-not-found.png')

const BookExportsList = (props) => {

  const isXs = useMediaQuery({ maxWidth: 599 });
  const isSm = useMediaQuery({ maxWidth: 600 });
  const isMd = useMediaQuery({ maxWidth: 900 });
  const isLg = useMediaQuery({ maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1536 });

  let [buildTypes, setBuildTypes] = useState({});
  let { bookDraftId } = props;
  let [showGenFilesPopover, setShowGenFilesPopover] = useState(false);
  let [expandedItemId, setExpandedItemId] = useState(null);
  let [epubCheckResults, setEpubCheckResults] = useState(null);
  let [showEpubCheckResults, setShowEpubCheckResults] = useState(null);
  let [showExportDialog, setShowExportDialog] = useState(false);
  let book = props.stores.bookStore.bookListBook;

  const { buildRequests, loading, error, client } = useGetBookDraftBuildRequests(props, bookDraftId);

  const CREATE_BOOK = gql` mutation RequestbuildBatch(
        $bookDraftId: String!,
        $buildTypes: [String]!
    ) {
        requestbuildBatch(input: {
            bookDraftId: $bookDraftId, 
            buildTypes: $buildTypes
        }) {
            id
            ownerId
            buildTypes
            completedAt
            createdAt

        }
    }`;


  const [
    requestbuildBatch,
    { data: mutationData, loading: mutationLoading, error: mutationError }] =
    useMutation(CREATE_BOOK);



  const GET_PRESIGNED_URL = gql`
    query getPresignedUrl($key: String!) {
      getPresignedUrl(key: $key)
              }
    `;


  useEffect(() => {

    //console.log(buildRequests)

  }, [buildRequests]);



  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();


  const createSecondaryActions = (buildRequests) => {

    let secondaryActions = [];





    return secondaryActions;
  }



  return (
    <>

      <Box sx={{
        height: '100%'
      }}>


        {/* <Box sx={{
          display: 'flex',
          flexDirection: 'column'
        }}>


          <Box sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            {isXs &&
              <StandardButton onClick={(e) => {


                e.preventDefault();
                setShowExportDialog(true)

                


              }}>Export</StandardButton>
            }
          </Box>
        </Box> */}
        <StandardList
          title={'Exports'}
          headerActions={<CreateExportsAssembly 
            //book={book}
            bookDraftId={book?.primaryBookDraftId}
            />}
          >
          {buildRequests && buildRequests.length > 0 ?
            buildRequests.map((buildRequest) => {

              let buildTypes = buildRequest.buildTypes.split(',');
              buildTypes = buildTypes.map((s) => {
                return s.replace('\"', '')
              });
              //console.log(buildTypes);






              let genDocs2 = buildRequest.buildRequestGeneratedDocs.items.map((item) => {

                //console.log(item);
                let key = item.generatedDoc.fileName;

                let epubCheckResultItems = [<div>No Epubcheck errors</div>];
                if (item.generatedDoc.epubCheckResults) {
                  epubCheckResultItems = item.generatedDoc.epubCheckResults.split('&&&###&&&').map((epubCheckResult) => {

                    return <div>{epubCheckResult}</div>
                  })
                }

                return <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <GoogleIcon />
                  </ListItemIcon>
                  <Link href="#" color="inherit" underline="always">
                    {item.generatedDoc.subType}
                  </Link>
                </ListItemButton>

              });

              let genDocs = buildRequest.buildRequestGeneratedDocs.items.map((item) => {

                let key = item.generatedDoc.fileName;
                let epubCheckResultItems = [<div>No Epubcheck errors</div>];
                if (item.generatedDoc.epubCheckResults) {
                  epubCheckResultItems = item.generatedDoc.epubCheckResults.split('&&&###&&&').map((epubCheckResult) => {

                    return <div>{epubCheckResult}</div>
                  })
                }
                return <div onClick={async () => {

                  let results = await client.query({
                    query: GET_PRESIGNED_URL,
                    variables: { key: key },
                    fetchPolicy: 'network-only',

                    //data: data
                  });

                  //console.log(results);
                  //console.log(JSON.parse(results.data.getPresignedUrl).url)
                  let finalUrl = null;

                  try {
                    finalUrl = JSON.parse(results.data.getPresignedUrl).url;
                  } catch (err) {
                    //console.log(err);
                  }
                  window.open(finalUrl, '_blank');
                }}>




                  {/* <p>{item.generatedDoc.type}</p> */}
                  {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}> */}
                  {/* <StandardLabel title={item.generatedDoc.type} /> */}
                  {/* <GoogleIcon style={{ margin: '0 0.5em 0 0' }} color={'primary'}/>
                    <DescriptionIcon style={{ margin: '0 0.5em 0 1em' }} color={'primary'}/>
                    <a hrefx="_blank" target="_blank" >{item.generatedDoc.fileName}</a>
                    <img src='https://images-na.ssl-images-amazon.com/images/I/51EV7oJMlbL._SY291_BO1,204,203,200_QL40_FMwebp_.jpg' style={{ width: '1em', height: 'auto', margin: '0 0.5em 0 1em' }} />
                    <a hrefx="_blank">Cover Image</a>
                  </div> */}

                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'left' }}>
                    {/* <StandardLabel title={item.generatedDoc.type} /> */}
                    <div style={{ flex: 2, justifyContent: 'flex-start', alignItems: 'left' }}>
                      <GoogleIcon style={{ margin: '0 0.5em 0 0' }} color={'primary'} />
                    </div>
                    <div style={{ flex: 5, justifyContent: 'flex-start', alignItems: 'left' }}>
                      <DescriptionIcon style={{ margin: '0 0.5em 0 1em' }} color={'primary'} />
                      <a hrefx="_blank" target="_blank" >{item.generatedDoc.fileName}</a>
                    </div>
                    <div style={{ flex: 5, justifyContent: 'flex-start', alignItems: 'left' }}>
                      <img src='https://images-na.ssl-images-amazon.com/images/I/51EV7oJMlbL._SY291_BO1,204,203,200_QL40_FMwebp_.jpg' style={{ width: '1em', height: 'auto', margin: '0 0.5em 0 1em' }} />
                      <a hrefx="_blank">Cover Image</a>
                    </div>
                  </div>

                  <div>
                    {epubCheckResultItems}
                  </div>
                </div>
              })



              return <>

                <ListItem
                  alignItems="flex-start"

                  secondaryAction={

                    <ListExpandIcon
                      expanded={expandedItemId === buildRequest.id}
                      onToggleExpand={() => {
                        if (expandedItemId === buildRequest.id) {
                          setExpandedItemId(null);
                        } else {
                          setExpandedItemId(buildRequest.id);
                        }
                      }}
                      actionIcon={null}>

                    </ListExpandIcon>



                  }>
                  <ListItemAvatar
                    onClick={(e) => {
                      e.preventDefault();
                      props.onSelected(buildRequest);
                    }}>
                    <SquareAvatar
                      //bucketName={config.user_uploads_bucket_name}
                      s3Src={'https://' + config.user_uploads_bucket_name + ".s3.amazonaws.com/" + buildRequest?.coverUrl}
                      missingContent={<img src={missingBookImage}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   console.log(buildRequest)
                      //   setShowGenFilesPopover(true)
                      // }} 
                      />}
                    />
                    {/* <Avatar alt="Remy Sharp" variant="square" src="https://local-bookmerlincicd8-useruploadedcontents3bucket-rg5crpg8jjlm.s3.amazonaws.com/uploads/e7c35407-1a89-4a62-a819-3cef45dc7cae/images/c7cb5750-3627-11ed-914f-5d91b6c4c7eb_planetStrider.png">

              </Avatar> */}
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      noWrap: true
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      //props.onSelected(buildRequests);
                      // if (expandedItemId === buildRequest.id) {
                      //   setExpandedItemId(null);
                      // } else {
                      //   setExpandedItemId(buildRequest.id);
                      // }

                    }}
                    primary={buildRequest?.title}
                    secondary={
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          <ReactTimeAgo date={buildRequest?.createdAt} locale="en-US" />
                        </Typography>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="caption"
                          color="text.secondary"
                        >
                          Book
                        </Typography>
                        {/* {" — I'll be in your neighborhood doing errands this…"} */}
                      </Box>
                    }

                  />
                  {/* {expandedItemId === buildRequest.id ? <ExpandLess /> : <ExpandMore />} */}
                </ListItem>
                <Collapse in={expandedItemId === buildRequest.id} timeout="auto" unmountOnExit>
                  <Button onClick={() => {

                    //setEpubCheckResults(epubCheckResultItems);
                    setShowEpubCheckResults(true);
                  }}>Debug</Button>
                  {genDocs2}
                </Collapse>
                <Divider variant="inset"/>

                <StandardDialog
                  open={showGenFilesPopover}
                  onClose={() => {
                    setShowGenFilesPopover(false)
                  }}
                  onOk={() => {


                  }}
                  title={'Generated Files'}
                  dialogContent={
                    <GeneratedDocsList bookDraftId={bookDraftId} buildRequest={buildRequest} />
                  }
                >
                </StandardDialog>

                <StandardOkCancelDialog
                  open={showExportDialog}
                  onClose={() => {
                    setShowExportDialog(false)
                  }}
                  onOk={(e) => {
                    e.preventDefault();

                    let actualBuildTypes = [];
                    Object.keys(buildTypes).forEach((key) => {

                      if (buildTypes[key] == true) {
                        actualBuildTypes.push(key);
                      }
                    });

                    //console.log(actualBuildTypes);
                    requestbuildBatch({
                      variables: {
                        bookDraftId: bookDraftId,
                        //buildTypes: ["epub","nook"],
                        buildTypes: actualBuildTypes
                      },
                      refetchQueries: ['listUserBuildRequests']

                    });
                    setShowExportDialog(false);
                  }}
                  title={'Export'}
                  content={
                    <ExportDialog bookDraftId={book?.primaryBookDraftId} />

                  }
                />

                <StandardDialog
                  open={showEpubCheckResults}
                  onClose={() => {
                    setShowEpubCheckResults(false)
                  }}
                  onOk={() => {


                  }}
                  title={'Epubcheck results'}
                  dialogContent={
                    <div>results here</div>
                  }
                >
                </StandardDialog>

              </>
              // <Divider variant="inset"/>


              // return <ListItem secondaryAction={createSecondaryActions(book)}>
              //   <ListItemAvatar>
              //     <AvatarTableCell
              //       bucketName={config.user_uploads_bucket_name}
              //       s3Src={book?.coverUrl}
              //     //title={userConnection?.connectedUser?.name}
              //     />
              //   </ListItemAvatar>
              //   <ListItemText primary={book?.title} secondary="" />

              // </ListItem>
            })
            :
            <Box sx={{
              marginTop: '3em'
            }}>
              <EmptyContent tagline={'Export your book!'} />
            </Box>

          }


        </StandardList>
      </Box>
    </>
  )
}

export default inject('stores')(observer(BookExportsList));