import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../aws-exports';
import StandardTabs from "../StandardTabs";
import { useManagerCreateInvitation, useGetUserConnections, useGetUserGroups } from '../../managers/ConnectionsManager';
import AvatarTableCell from "../table/AvatarTableCell";
import ShareIcon from '@mui/icons-material/Share';
import StandardButton, { StandardListButton } from "../StandardButton";
import UserConnectionsList from "../lists/UserConnectionsList";
import { useGetBookShares, useShareBookWithUser, useShareBookWithGroup } from '../../managers/BooksManager';
import UserGroupsList from "../lists/UserGroupsList";
import { useManagerAddBoxsetContributor, useManagerLoadBoxSetContributors } from '../../managers/BoxsetsManager';
import { User } from "../../domain/bookModel";
import SendInvitationPanel from "./connections/SendInvitationPanel";


const AddBoxsetContributorPanel = (props) => {

  let user = props.stores.userStore.user;
  let { boxset } = props;
  let [name, setName] = useState('Bob');
  let [email, setEmail] = useState('jshamel+test0001@gmail.com');

  const { createInvitation, data: createInvitationData, loading: createInvitationLoading, error: createInvitationError } = useManagerCreateInvitation();

  const handleNameChange = (e) => {
    e.preventDefault();

    setName(e.target.value);
  }

  const handleEmailChange = (e) => {
    e.preventDefault();

    setEmail(e.target.value);
  }

  let [selectedTab, setSelectedTab] = useState(0);


  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  const { addBoxsetContributor, loading: createBoxSetContributorLoading, error: createBoxSetContributorError } = useManagerAddBoxsetContributor(props);

  let {
    boxsetContributors,
    loading: lazyLoadBoxSetContributorsLoading,
    error: lazyLoadBoxSetContributorsError
  } = useManagerLoadBoxSetContributors(props, boxset?.id);


  return (
    <Grid container direction={'column'}>
      <Grid item>
        {/* <Typography>Share this book...</Typography> */}
        <StandardTabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Connections" {...a11yProps(0)} />
          <Tab label="Invite" {...a11yProps(1)} />
        </StandardTabs>
        {selectedTab == 0 &&
          <Grid item>
            <UserConnectionsList
              filteredConnections={boxsetContributors.map((m) => {
                return new User({ data: m.contributor })
              })}
              showSelectIcon
              onSelected={(userConnection) => {

                if (userConnection) {
                  addBoxsetContributor({
                    contributorId: userConnection.connectedUser.id,
                    boxsetId: boxset.id,
                  })
                }

              }}
            />
            <StandardListButton onClick={() => {
              addBoxsetContributor({
                contributorId: user.id,
                boxsetId: boxset.id,
              })
            }}>Add Self</StandardListButton>
          </Grid>
        }
        {selectedTab == 1 &&

          <SendInvitationPanel
            name={name}
            email={email}
            onNameChange={handleNameChange}
            onEmailChange={handleEmailChange} />

        }
      </Grid>
    </Grid>
  )
}

export default inject('stores')(observer(AddBoxsetContributorPanel));