import React, { useEffect, useState } from 'react';
import { observable } from 'mobx';
import Quill from 'quill';
import {
  useGetBooks,
  useGetBookDraft,
  useGetRecipe,
  useLazyListSnapshots,
  useCreateBook,
  useCreateBoxSet,
  useUpdateItem,
  useListBookContributedToBoxSets,
  useLazyListBookUserShares,
  useListBookUserGroupShares,
  useShareBook,
  useShareBookWithUserGroup,
  useDeleteBookUserShare,
  useDeleteBookUserGroupShare,
  useGetSharedWithBooks,
  useCreateSnapshot,
  useRestoreSnapshot,
  useLazyListAllBookDraftUserComments,
  useListAllBookDraftUserComments,
  useLazyListBookDraftUserComments,
  useListBookDraftNotes,
  useMoveBookDraftDocumentPart,
  useRollbackDocumentPartBatch,
  useListBookShares
} from '../persistance/persistanceHooks';

import {
  useLoadStarters,
  useLoadVelocity,
  useLoadInflatables
} from '../persistance/persistanceMacros';
import { useNavigate, useLocation } from "react-router-dom";
import { Book, BookDraft, Recipe, BoxSet, UserComment as UserCommentPojo } from '../domain/bookModel';
import { enqueueSnackbar } from 'notistack'

let masterRecipeJson = require('../assets/json/recipes.json');

var $ = require("jquery");

class BooksManager {

  constructor() {

  }



}


const resetBooksAndSelections = (props) => {

  props.stores.bookStore.clearDocumentPartSubscriptions();
  props.stores.bookStore.writingBookDraft = null;
  props.stores.bookStore.writingBookDraftId = null;
  props.stores.bookStore.writingSelectedBookDraftDocumentPart = null;

  props.stores.bookStore.rightWritingBookDraft = null;
  props.stores.bookStore.rightWritingBookDraftId = null;
  props.stores.bookStore.rightWritingSelectedBookDraftDocumentPart = null;

  props.stores.bookStore.writeSplitScreen = false;

  props.stores.bookStore.showCommentsInEditor = false;

}




export function useGetSharedBooks(props) {

  const [books, setBooks] = useState([]);

  const { loading: getSharedWithBooksLoading, error: getSharedWithBooksError, data: getSharedWithBooksData } = useGetSharedWithBooks({
    //userId: props.stores.userStore.user.id
  });

  useEffect(() => {


    try {
      //console.log(getSharedWithBooksData);
      if (getSharedWithBooksData) {

        let books = {};

        getSharedWithBooksData.getUser.sharedBooks.items.map((sharedBook) => {


          let book = new observable(new Book({
            data: sharedBook.book
          }))

          book.sharedAt = sharedBook.createdAt;
          //console.log(book);
          books[book.id] = book;

        });



        getSharedWithBooksData.getUser.userGroupUsers.items.forEach((userGroupUser) => {

          let userGroup = userGroupUser.userGroup;

          userGroup.sharedBooks.items.map((groupBookShare) => {


            let book = new observable(new Book({
              data: groupBookShare.sharedBook
            }))
            book.sharedAt = groupBookShare.createdAt;

            book.userGroup = userGroup;
            books[book.id] = book;


          });

        })





        setBooks(Object.values(books));


      }
    } catch (err) {
      console.log(err);
    }

  }, [getSharedWithBooksData]);

  return { sharedBooks: books, error: getSharedWithBooksError, loading: getSharedWithBooksLoading };
}


export function useGetOwnedBooks(props) {

  const [books, setBooks] = useState([]);

  const { loading, error, data, refetch, networkStatus, client } = useGetBooks();

  //props.stores.bookStore.authoredBooks = [];

  useEffect(() => {


    try {
      //console.log(data);
      if (data) {

        let books = [];

        //data.listBooks.items.filter(f => f.deletedAt == null).forEach((bookData) => {
        data.listBooks.items.forEach((bookData) => {

          let book = new observable(new Book({
            data: bookData
          }))
          //console.log(book);
          books.push(book);

        });

        setBooks(books);

        //The following exist for blots. Find a better way later. 
        props.stores.bookStore.authoredBooks = books;

      }
    } catch (err) {
      console.log(err);
    }

  }, [data, loading, error]);

  return { ownedBooks: books, error, loading, refetch, networkStatus, client };
}





export function useOpenBookInRead(props) {

  let navigate = useNavigate();
  let location = useLocation();

  const openBookInRead = ({
    bookDraftId,
    sharedBook
  }) => {

    resetBooksAndSelections(props);
    props.stores.bookStore.showCommentsInEditor = true;
    props.stores.bookStore.sharedBookDraft = null;
    //props.stores.bookStore.sharedBookDraftId = bookDraftId;
    props.stores.bookStore.sharedBook = sharedBook;




    navigate("/read");

  }

  return { openBookInRead };
}


export function useLoadBookReaderComments(props, { bookDraftId }) {

  let [userComments, setUserComments] = useState([]);
  const [listUserBookDraftUserComments, { loading: listUserBookDraftUserCommentsLoading, error: listUserBookDraftUserCommentsError, data: listUserBookDraftUserCommentsData, refetch: listUserBookDraftUserCommentsRefetch }] = useLazyListBookDraftUserComments();

  useEffect(() => {

    listUserBookDraftUserComments({
      variables: {
        bookDraftId: bookDraftId
      }

    })
  }, [bookDraftId]);

  useEffect(() => {

    if (listUserBookDraftUserCommentsData) {

      //setUserComments(listUserBookDraftUserCommentsData.listBookDraftUserComments.items.filter(f=>f.deletedAt==null).map((commentData)=> new UserCommentPojo({ data: commentData })));

      props.stores.bookStore.userComments.replace(listUserBookDraftUserCommentsData.listBookDraftUserComments.items.filter(f => f.deletedAt == null)).map((commentData) => observable(new UserCommentPojo({ data: commentData })));

    }


  }, [listUserBookDraftUserCommentsData]);



  return {
    loading: listUserBookDraftUserCommentsLoading,
    error: listUserBookDraftUserCommentsError,
    refetch: listUserBookDraftUserCommentsRefetch,
    userComments
  }
}





//Loads all comments for a given bookDraft
export function useLoadBookWriteComments(props, { bookDraftId }) {

  let [userComments, setUserComments] = useState([]);
  const { loading: listBookDraftUserCommentsLoading, error: listBookDraftUserCommentsError, data: listBookDraftUserCommentsData, refetch: listBookDraftUserCommentsRefetch } = useListAllBookDraftUserComments({
    bookDraftId: bookDraftId
  });

  // useEffect(() => {

  //   listUserBookDraftUserComments({
  //     variables: {
  //       bookDraftId: bookDraftId
  //     }

  //   })
  // }, [bookDraftId]);

  useEffect(() => {

    if (listBookDraftUserCommentsData) {

      //setUserComments(listUserBookDraftUserCommentsData.listBookDraftUserComments.items.filter(f=>f.deletedAt==null).map((commentData)=> new UserCommentPojo({ data: commentData })));

      props.stores.bookStore.userComments.replace(listBookDraftUserCommentsData.listAllBookDraftUserComments.items.filter(f => f.deletedAt == null)).map((commentData) => observable(new UserCommentPojo({ data: commentData })));

    }


  }, [listBookDraftUserCommentsData]);



  return {
    loading: listBookDraftUserCommentsLoading,
    error: listBookDraftUserCommentsError,
    refetch: listBookDraftUserCommentsRefetch,
    userComments
  }
}






export function useLoadBookWriteNotes(props, { bookDraftId }) {

  let [userComments, setUserComments] = useState([]);
  const { loading: listBookDraftUserNotesLoading, error: listBookDraftUserNotesError, data: listBookDraftUserNotesData, refetch: listBookDraftUserNotesRefetch } = useListBookDraftNotes({
    bookDraftId: bookDraftId
  });

  // useEffect(() => {

  //   listUserBookDraftUserComments({
  //     variables: {
  //       bookDraftId: bookDraftId
  //     }

  //   })
  // }, [bookDraftId]);

  useEffect(() => {

    if (listBookDraftUserNotesData && listBookDraftUserNotesData.listAllBookDraftNotes) {

      //setUserComments(listUserBookDraftUserCommentsData.listBookDraftUserNotes.items.filter(f=>f.deletedAt==null).map((commentData)=> new UserCommentPojo({ data: commentData })));

      props.stores.bookStore.userNotes.replace(listBookDraftUserNotesData.listAllBookDraftNotes.items.filter(f => f.deletedAt == null)).map((commentData) => observable(new UserCommentPojo({ data: commentData })));
      props.stores.bookStore.userNotesTimestamp = Date.now();
    }


  }, [listBookDraftUserNotesData]);



  return {
    loading: listBookDraftUserNotesLoading,
    error: listBookDraftUserNotesError,
    refetch: listBookDraftUserNotesRefetch,
    userComments
  }
}





export function showDocumentPartNotes(props, { quill, partId }) {

  if (props.stores.bookStore.showNotesInEditor && partId) {
    try {
      props.stores.bookStore.userNotes.slice().filter(f => f.documentPartId == partId).forEach((userComment) => {

        /********** The following causes scrolling away from the top of the editor since using .setSelection */
        // quill.setSelection(userComment.partIndex, userComment.partLength, Quill.sources.SILENT);
        // let formatDelta1 = quill.format('commentBlot', { uuid: userComment.id }, Quill.sources.SILENT);
        // quill.setSelection(0, 0, Quill.sources.SILENT);


        /******** Following does not cause unwanted scrolling */
        quill.formatText(userComment.partIndex, userComment.partLength, {
          'noteBlot': { uuid: userComment.id }
        },
          Quill.sources.SILENT);



      })

    } catch (err) {
      console.log(err);
    }
  }

}


export function showDocumentPartComments(props, { quill, partId }) {

  if (props.stores.bookStore.showCommentsInEditor && partId) {
    try {
      props.stores.bookStore.userComments.filter(f => f.documentPartId == partId).forEach((userComment) => {

        /********** The following causes scrolling away from the top of the editor since using .setSelection */
        // quill.setSelection(userComment.partIndex, userComment.partLength, Quill.sources.SILENT);
        // let formatDelta1 = quill.format('commentBlot', { uuid: userComment.id }, Quill.sources.SILENT);
        // quill.setSelection(0, 0, Quill.sources.SILENT);


        /******** Following does not cause unwanted scrolling */
        quill.formatText(userComment.partIndex, userComment.partLength, {
          'commentBlot': { uuid: userComment.id }
        },
          Quill.sources.SILENT);



      })

    } catch (err) {
      console.log(err);
    }
  }

}






// export function useLoadBookComments(props) {



//   const [listBookDraftUserComments, { loading: listBookDraftUserCommentsLoading, error: listBookDraftUserCommentsError, data: listBookDraftUserCommentsData, refetch: listBookDraftUserCommentsRefetch }] = useLazyListAllBookDraftUserComments();


//   const [listUserBookDraftUserComments, { loading: listUserBookDraftUserCommentsLoading, error: listUserBookDraftUserCommentsError, data: listUserBookDraftUserCommentsData, refetch: listUserBookDraftUserCommentsRefetch }] = useLazyListBookDraftUserComments();


//   const loadBookComments = ({ ownerMode, bookDraftId }) => {
//     if (ownerMode) {

//       listBookDraftUserComments({
//         variables: {
//           bookDraftId: bookDraftId
//         }

//       })
//     } else {

//       listUserBookDraftUserComments({
//         variables: {
//           bookDraftId: bookDraftId
//         }

//       })


//     }
//   }




//   useEffect(() => {

//     //if (ownerMode) {

//     if (listBookDraftUserCommentsData) props.stores.bookStore.userComments.replace(listBookDraftUserCommentsData.listAllBookDraftUserComments.items.filter(f=>f.deletedAt==null));
//     // return {
//     //   loadBookComments,
//     //   loading: listBookDraftUserCommentsLoading,
//     //   error: listBookDraftUserCommentsError,
//     //   data: listBookDraftUserCommentsData,
//     //   refetch: listBookDraftUserCommentsRefetch
//     // }
//     // } else {

//     if (listUserBookDraftUserCommentsData) props.stores.bookStore.userComments.replace(listUserBookDraftUserCommentsData.listBookDraftUserComments.items.filter(f=>f.deletedAt==null));

//     // return {
//     //   loadBookComments,
//     //   loading: listUserBookDraftUserCommentsLoading,
//     //   error: listUserBookDraftUserCommentsError,
//     //   data: listUserBookDraftUserCommentsData,
//     //   refetch: listUserBookDraftUserCommentsRefetch
//     // }


//     //}

//   }, [listBookDraftUserCommentsData, listUserBookDraftUserCommentsData]);


//   return {
//     loadBookComments
//   }

// }






export function showDocumentPartComment(props, { quill, partId, selection, userComment }) {


  try {


    quill.formatText(selection.index, selection.length, {
      'commentBlot': { uuid: userComment.id }
    },
      Quill.sources.SILENT);



  } catch (err) {
    console.log(err);
  }

}

export function showAllComments(props, userComments) {

  try {

    userComments.forEach((userComment) => {

      props.stores.bookStore.postDocumentPartCommentsEvent({
        partId: userComment.documentPartId,
        userComment,
        //sourceId,
        //quillSourceType
      })



      let partQuillInstances = props.stores.bookStore.getQuillInstancesByPartId(userComment.documentPartId);
      try {
        partQuillInstances.forEach((quill) => {
          //if(userComment.partIndex && userComment.partLength && userComment.partLength>0){


          // quill.setSelection(userComment.partIndex, userComment.partLength, Quill.sources.SILENT);
          // let formatDelta1 = quill.format('commentBlot', { uuid: userComment.id }, Quill.sources.SILENT);
          // quill.setSelection(0, 0, Quill.sources.SILENT);


          //}
        })
      } catch (err) {
        console.log(err);
      }
      $('.tooltip-controls').hide();
      $('#sidebar-controls').hide();
      $('#sidebar-controls').removeClass('active');

    });

  } catch (err) {
    console.log(err);
  }




}

export function useOpenCommentInWrite(props) {

  let navigate = useNavigate();
  let location = useLocation();

  const openCommentInWrite = ({
    bookDraftId,
    userCommentId
  }) => {

    resetBooksAndSelections(props);

    props.stores.bookStore.scrollToUserCommentId = userCommentId;

    props.stores.bookStore.writingBookDraft = null;
    props.stores.bookStore.writingBookDraftId = bookDraftId;

    props.stores.bookStore.rightWritingBookDraft = null;
    props.stores.bookStore.rightWritingBookDraftId = null;
    props.stores.bookStore.rightWritingSelectedBookDraftDocumentPart = null;

    props.stores.bookStore.writeSplitScreen = false;

    navigate("/write");

  }

  return { openCommentInWrite };
}

export function useOpenBookInWrite(props) {

  let navigate = useNavigate();
  let location = useLocation();

  const openBookInWrite = ({
    bookDraftId
  }) => {

    resetBooksAndSelections(props);

    props.stores.bookStore.writingBookDraft = null;
    props.stores.bookStore.writingBookDraftId = bookDraftId;

    props.stores.bookStore.rightWritingBookDraft = null;
    props.stores.bookStore.rightWritingBookDraftId = null;
    props.stores.bookStore.rightWritingSelectedBookDraftDocumentPart = null;

    props.stores.bookStore.writeSplitScreen = false;

    navigate("/write");

  }

  return { openBookInWrite };
}




export function useOpenSnapshotInWrite(props, resetWritingBookDraft) {

  let navigate = useNavigate();
  let location = useLocation();

  const openSnapshotInWrite = ({
    bookDraftId,
    snapshotId
  }) => {

    //resetBooksAndSelections(props);

    if (resetWritingBookDraft) {
      props.stores.bookStore.writingBookDraft = null;
    }
    props.stores.bookStore.writingBookDraftId = bookDraftId;

    //props.stores.bookStore.rightWritingBookDraft = null;
    props.stores.bookStore.rightWritingBookDraftId = snapshotId;
    props.stores.bookStore.rightWritingSelectedBookDraftDocumentPart = null;

    props.stores.bookStore.writeSplitScreen = true;
    navigate("/write");

  }

  return { openSnapshotInWrite };
}

export function useCloseSnapshotInWrite(props) {

  let navigate = useNavigate();
  let location = useLocation();

  const closeSnapshotInWrite = () => {

    //resetBooksAndSelections(props);
    props.stores.bookStore.rightWritingBookDraft = null;
    props.stores.bookStore.rightWritingBookDraftId = null;
    props.stores.bookStore.rightWritingSelectedBookDraftDocumentPart = null;

    props.stores.bookStore.writeSplitScreen = false;
    //navigate("/write");

  }

  return { closeSnapshotInWrite };
}

export function useOpenBookInFormat(props) {

  let navigate = useNavigate();
  let location = useLocation();

  const openBookInFormat = ({
    bookDraftId
  }) => {
    resetBooksAndSelections(props);
    props.stores.bookStore.writingBookDraft = null;
    props.stores.bookStore.writingBookDraftId = bookDraftId;

    props.stores.bookStore.rightWritingBookDraft = null;
    props.stores.bookStore.rightWritingBookDraftId = null;
    props.stores.bookStore.rightWritingSelectedBookDraftDocumentPart = null;

    props.stores.bookStore.writeSplitScreen = false;
    navigate("/format");

  }

  return { openBookInFormat };
}





export function useGetSnapshots(props, bookId) {

  const [snapshots, setSnapshots] = useState([]);

  const [listSnapshotsQuery, { loading: listSnapshotsLoading, error: listSnapshotsError, data: listSnapshotsData, refetch: listSnapshotsRefetch }] = useLazyListSnapshots();


  const listSnapshots = ({
    bookId,
    onCompleted
  }) => {

    try {
      listSnapshotsQuery({
        variables: {
          bookId: bookId
        },
        onCompleted: (listSnapshotsData) => {

          //console.log('onCompleted...');
          //console.log(listSnapshotsData);

          let bookDrafts = [];

          if (listSnapshotsData?.listSnapshots) {


            listSnapshotsData?.listSnapshots.items.filter(f => f.deletedAt == null).map((snapshot) => {

              try {
                let snapshotPojo = observable(new BookDraft({
                  ...snapshot
                }))

                bookDrafts.push(snapshotPojo);
              } catch (err) {
                console.log(err);
              }


            });

          }

          onCompleted(bookDrafts);



        }

      });

    } catch (err) {
      console.log(err);
    }

  }







  useEffect(() => {

    //console.log(listSnapshotsData);
    let bookDrafts = [];

    if (listSnapshotsData?.listSnapshots) {


      listSnapshotsData?.listSnapshots.items.filter(f => f.deletedAt == null).map((snapshot) => {

        let snapshotPojo = observable(new BookDraft({
          ...snapshot
        }))

        bookDrafts.push(snapshotPojo);

      });

    }

    setSnapshots(bookDrafts);

  }, [listSnapshotsData]);

  return { listSnapshots, snapshots, error: listSnapshotsError, loading: listSnapshotsLoading };
}




export function useLoadBookDraftForWrite(props, bookDraftId) {


  const [loadStarters] = useLoadStarters({
    bookStore: props.stores.bookStore,
    loadIntoStore: true
  });


  const {
    loading: getBookDraftLoading,
    error: getBookDraftError,
    data: getBookDraftData,
    refetch: getBookDraftRefetch
  } = useGetBookDraft({
    bookDraftId: bookDraftId
  });



  const { loading: masterRecipeLoading, error: masterRecipeError, data: masterRecipeData, refetch: masterRecipeRefetch } = useGetRecipe({
    recipeId: 'MASTER_RECIPE'
  });


  useEffect(() => {

    loadStarters();

  }, []);

  useEffect(() => {

    if (masterRecipeData) {

      //cleanMasterRecipe(masterRecipeData.getRecipe);

      props.stores.bookStore.setMasterRecipe(new Recipe({ data: masterRecipeData.getRecipe }));


      if (masterRecipeData && props.stores.bookStore.writingBookDraft) {

        props.stores.bookStore.writingBookDraft.inflateAllRecipes({
          masterRecipe: props.stores.bookStore.masterRecipe
        });
      }

    }

    //setWritingBookDraft(props.stores.bookStore.writingBookDraft);
    //let inflatablesBookDraft = props.stores.bookStore.inflatablesBookDraft;

  }, [masterRecipeData, props.stores.bookStore.writingBookDraft]);


  useEffect(() => {

    if (getBookDraftError) {
      //console.log(getBookDraftError);
    }
    if (getBookDraftData) {

      try {

        props.stores.bookStore.setWritingBookDraft(new BookDraft(getBookDraftData.getBookDraft))

        // setSelectedBddp(props.stores.bookStore.writingBookDraft.rootBookDraftDocumentPart);
        // setWritingBookDraft(props.stores.bookStore.writingBookDraft);
      } catch (err) {

        //console.log(err);
      }

    }
    //console.log(data);

  }, [getBookDraftData]);

  return {
    bookDraft: props.stores.bookStore.writingBookDraft,
    getBookDraftLoading,
    refetch: getBookDraftRefetch
  };
}


//Loads the bookDraft and all supporting items ( recipes, templates etc. )
export function useLoadBookDraftForFormat(props, bookDraftId) {

  //console.log(bookDraftId);

  const [loadStarters] = useLoadStarters({
    bookStore: props.stores.bookStore,
    loadIntoStore: true
  });

  const [loadVelocity] = useLoadVelocity({
    bookStore: props.stores.bookStore,
    loadIntoStore: true
  });

  const [loadInflatables] = useLoadInflatables({
    bookStore: props.stores.bookStore,
    loadIntoStore: true
  });


  const { loading: recipeLoading, error: recipeError, data: recipeData, refetch: recipeRefetch } = useGetRecipe({
    recipeId: props.stores.bookStore.writingBookDraft?.book?.recipe?.id
  });

  const { loading: masterRecipeLoading, error: masterRecipeError, data: masterRecipeData, refetch: masterRecipeRefetch } = useGetRecipe({
    recipeId: 'MASTER_RECIPE'
  });


  const {
    loading: getBookDraftLoading,
    error: getBookDraftError,
    data: getBookDraftData,
    refetch: getBookDraftRefetch
  } = useGetBookDraft({
    bookDraftId: bookDraftId
  });


  useEffect(() => {

    loadStarters();
    loadVelocity();
    loadInflatables();

  }, []);




  useEffect(() => {

    if (getBookDraftError) {
      //console.log(getBookDraftError);
    }
    if (getBookDraftData) {

      try {

        props.stores.bookStore.setWritingBookDraft(new BookDraft(getBookDraftData.getBookDraft))
        // setSelectedBddp(props.stores.bookStore.writingBookDraft.rootBookDraftDocumentPart);
        // setWritingBookDraft(props.stores.bookStore.writingBookDraft);
      } catch (err) {

        console.log(err);
      }

    }
    //console.log(data);

  }, [getBookDraftData]);










  useEffect(() => {

    if (masterRecipeData) {

      //cleanMasterRecipe(masterRecipeData.getRecipe);

      props.stores.bookStore.setMasterRecipe(new Recipe({ data: masterRecipeData.getRecipe }));

      //console.log(masterRecipeData.getRecipe);
      if (masterRecipeData && props.stores.bookStore.writingBookDraft) {

        props.stores.bookStore.writingBookDraft.inflateAllRecipes({
          masterRecipe: props.stores.bookStore.masterRecipe
        });
      }

    }

    //setWritingBookDraft(props.stores.bookStore.writingBookDraft);
    //let inflatablesBookDraft = props.stores.bookStore.inflatablesBookDraft;

  }, [masterRecipeData, props.stores.bookStore.writingBookDraft]);



  useEffect(() => {

    if (getBookDraftError) {
      //console.log(getBookDraftError);
    }
    if (getBookDraftData) {

      try {

        props.stores.bookStore.setWritingBookDraft(new BookDraft(getBookDraftData.getBookDraft))
        // setSelectedBddp(props.stores.bookStore.writingBookDraft.rootBookDraftDocumentPart);
        // setWritingBookDraft(props.stores.bookStore.writingBookDraft);
      } catch (err) {

        console.log(err);
      }

    }
    //console.log(data);

  }, [getBookDraftData]);


  return {
    bookDraft: props.stores.bookStore.writingBookDraft,
    getBookDraftLoading,
    startersBookDraft: props.stores.bookStore.startersBookDraft,
    inflatableTemplates: props.stores.bookStore.currentInflatedTemplates,
    velocityBookDraft: props.stores.bookStore.velocityBookDraft,
    masterRecipe: props.stores.bookStore.masterRecipe
  };
}








export function useLoadSnapshotForWrite(props, bookDraftId) {

  const {
    loading: getBookDraftLoading,
    error: getBookDraftError,
    data: getBookDraftData,
    refetch: getBookDraftRefetch
  } = useGetBookDraft({
    bookDraftId: bookDraftId
  });

  useEffect(() => {

    if (getBookDraftError) {
      //console.log(getBookDraftError);
    }
    if (getBookDraftData) {

      try {

        //props.stores.bookStore.setRightWritingBookDraft(new BookDraft(getBookDraftData.getBookDraft))

        props.stores.bookStore.rightWritingBookDraft = new BookDraft(getBookDraftData.getBookDraft);

        // setSelectedBddp(props.stores.bookStore.writingBookDraft.rootBookDraftDocumentPart);
        // setWritingBookDraft(props.stores.bookStore.writingBookDraft);
      } catch (err) {

        console.log(err);
      }

    }
    //console.log(data);

  }, [getBookDraftData]);

  return {
    snapshotBookDraft: props.stores.bookStore.rightWritingBookDraft,
    snapshotGetBookDraftLoading: getBookDraftLoading
  };
}




export function useLoadBookDraftForRead(props, bookDraftId) {

  const {
    loading: getBookDraftLoading,
    error: getBookDraftError,
    data: getBookDraftData,
    refetch: getBookDraftRefetch
  } = useGetBookDraft({
    bookDraftId: bookDraftId
  });



  // const { loading: masterRecipeLoading, error: masterRecipeError, data: masterRecipeData, refetch: masterRecipeRefetch } = useGetRecipe({
  //   recipeId: 'MASTER_RECIPE'
  // });


  // useEffect(() => {

  //   if (masterRecipeData) {

  //     //cleanMasterRecipe(masterRecipeData.getRecipe);

  //     props.stores.bookStore.setMasterRecipe(new Recipe({ data: masterRecipeData.getRecipe }));


  //     if (masterRecipeData && props.stores.bookStore.writingBookDraft) {

  //       props.stores.bookStore.writingBookDraft.inflateAllRecipes({
  //         masterRecipe: props.stores.bookStore.masterRecipe
  //       });
  //     }

  //   }

  //   //setWritingBookDraft(props.stores.bookStore.writingBookDraft);
  //   //let inflatablesBookDraft = props.stores.bookStore.inflatablesBookDraft;

  // }, [masterRecipeData, props.stores.bookStore.writingBookDraft]);


  useEffect(() => {

    if (getBookDraftError) {
      //console.log(getBookDraftError);
    }
    if (getBookDraftData) {

      try {

        props.stores.bookStore.setSharedBookDraft(new BookDraft(getBookDraftData.getBookDraft))
        // setSelectedBddp(props.stores.bookStore.writingBookDraft.rootBookDraftDocumentPart);
        // setWritingBookDraft(props.stores.bookStore.writingBookDraft);
      } catch (err) {

        console.log(err);
      }

    }
    //console.log(data);

  }, [getBookDraftData]);

  return {
    bookDraft: props.stores.bookStore.sharedBookDraft,
    getBookDraftLoading
  };
}



export function useInitMasterRecipe(props) {


  const { loading: masterRecipeLoading, error: masterRecipeError, data: masterRecipeData, refetch: masterRecipeRefetch } = useGetRecipe({
    recipeId: 'MASTER_RECIPE'
  });


  useEffect(() => {

    if (masterRecipeData) {

      //cleanMasterRecipe(masterRecipeData.getRecipe);

      props.stores.bookStore.setMasterRecipe(new Recipe({ data: masterRecipeData.getRecipe }));


      if (masterRecipeData && props.stores.bookStore.writingBookDraft) {

        props.stores.bookStore.writingBookDraft.inflateAllRecipes({
          masterRecipe: props.stores.bookStore.masterRecipe
        });
      }

    }

    //setWritingBookDraft(props.stores.bookStore.writingBookDraft);
    //let inflatablesBookDraft = props.stores.bookStore.inflatablesBookDraft;

  }, [masterRecipeData]);



  return {};
}







export function useManagerDeleteSnapshot(props) {


  const [updateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] = useUpdateItem();
  const [listSnapshotsQuery, { loading: listSnapshotsLoading, error: listSnapshotsError, data: listSnapshotsData, refetch: listSnapshotsRefetch }] = useLazyListSnapshots();


  const wrapper = ({
    book,
    snapshotId
  }, callback) => {


    try {


      updateItem({
        itemExpectedVersion: 1,
        itemKey: snapshotId,
        itemType: 'BookDraft',
        fieldName: 'deletedAt',
        fieldValue: 'now'

      }, () => {
        enqueueSnackbar('Snapshot deletedAt updated', { variant: 'success' });

        listSnapshotsQuery({
          variables: {
            bookId: book.id
          },
          onCompleted: (listSnapshotsData) => {

            //console.log('onCompleted...');

            if (callback) {
              callback()
            }



          }

        });

      }, (error) => {
        enqueueSnackbar(error.msg, { variant: 'error' })
      }


      );

    } catch (err) {
      console.log(err);
    }

  }

  return {
    deleteSnapshot: wrapper,
    loading: updateItemLoading,
    error: updateItemError
  };
}

export function useManagerRollbackVersion(props) {




  //let [ newPrimaryBookDraftJson, setNewPrimaryBookDraftJson] = useState();  
  const [rollbackVersionMutation, { data: rollbackVersionData, loading: rollbackVersionLoading, error: rollbackVersionError }] = useRollbackDocumentPartBatch();


  const wrapper = ({
    expectedVersion,
    documentPartId,
    rollbackVersion
  }, callback) => {


    try {

      rollbackVersionMutation({
        expectedVersion,
        documentPartId,
        rollbackVersion
      }, callback);

    } catch (err) {
      console.log(err);
    }

  }


  useEffect(() => {

    try {

      // if (restoreSnapshotData?.restoreSnapshotBatch) {

      //   let newBoookDraftJson = restoreSnapshotData?.restoreSnapshotBatch;

      //   book.primaryBookDraftId = newBoookDraftJson.newPrimaryBookDraftId;

      //   props.stores.bookStore.writingBookDraft = null;
      //   props.stores.bookStore.writingBookDraftId = book.primaryBookDraftId;
      //   navigate("/write");



      // }
    } catch (err) {
      console.log(err);
    }


  }, [rollbackVersionData]);

  return {
    rollbackVersion: wrapper,
    rollbackVersionLoading,
    rollbackVersionError
  };
}

export function useManagerRestoreSnapshot(props, book) {


  let navigate = useNavigate();
  let location = useLocation();

  //let [ newPrimaryBookDraftJson, setNewPrimaryBookDraftJson] = useState();
  const [restoreSnapshot, { data: restoreSnapshotData, loading: restoreSnapshotLoading, error: restoreSnapshotError }] = useRestoreSnapshot();


  const wrapper = ({
    snapshotBookDraftId
  }, callback) => {


    try {

      restoreSnapshot({
        snapshotBookDraftId: snapshotBookDraftId
      }, callback);

    } catch (err) {
      console.log(err);
    }

  }


  useEffect(() => {

    try {

      if (restoreSnapshotData?.restoreSnapshotBatch) {

        let newBoookDraftJson = restoreSnapshotData?.restoreSnapshotBatch;

        book.primaryBookDraftId = newBoookDraftJson.newPrimaryBookDraftId;

        props.stores.bookStore.writingBookDraft = null;
        props.stores.bookStore.writingBookDraftId = book.primaryBookDraftId;
        navigate("/write");



      }
    } catch (err) {
      console.log(err);
    }


  }, [restoreSnapshotData]);

  return {
    restoreSnapshot: wrapper,
    restoreSnapshotLoading,
    restoreSnapshotError
  };
}





export function useManagerCreateSnapshot(props) {


  const [createSnapshot, { data: createSnapshotData, loading: createSnapshotLoading, error: createSnapshotError }] = useCreateSnapshot();


  const wrapper = ({
    title,
    description,
    bookDraftId
  }, callback) => {
    try {
      createSnapshot({
        copiedBookDraftId: bookDraftId,
        title,
        description
      }, callback);

    } catch (err) {
      console.log(err);
    }

  }



  return {
    createSnapshot: wrapper,
    createSnapshotLoading,
    createSnapshotError
  };
}












export function useManagerCreateBook(props, bookDraftId) {

  const [createBook, { data: createBookData, loading: createBookLoading, error: createBookError }] = useCreateBook();


  let backupMasterRecipe = new Recipe({ data: masterRecipeJson });

  const createBookWrapper = ({
    title,
    stores
  }, callback) => {
    try {


      createBook({
        user: stores.userStore.user,
        title: title,
        //masterRecipe: stores.bookStore.masterRecipe,
        masterRecipe: backupMasterRecipe


      }, (book) => {
        //console.log(book);
        if (callback) {
          callback(book)
        }

      });

    } catch (err) {
      console.log(err);
    }

  }


  useEffect(() => {


    if (createBookData) {

      try {



      } catch (err) {

        console.log(err);
      }

    }
    //console.log(data);

  }, []);

  return {
    createBook: createBookWrapper,
    createBookLoading,
    createBookError
  };
}


export function useManagerCreateBoxset() {

  const [createBoxset, { data, loading, error }] = useCreateBoxSet();



  const createBoxsetWrapper = ({
    title,
    stores
  }, callback) => {
    try {


      createBoxset({
        user: stores.userStore.user,
        title: title,
        masterRecipe: stores.bookStore.masterRecipe

      }, (boxset) => {
        //console.log(boxset);
        if (callback) {
          callback()
        }
      });


    } catch (err) {
      console.log(err);
    }

  }


  useEffect(() => {


    if (data) {

      try {


      } catch (err) {

        console.log(err);
      }

    }
    //console.log(data);

  }, []);

  return {
    createBoxset: createBoxsetWrapper,
    createBoxsetLoading: loading,
    createBoxsetError: error
  };
}



export function useManagerDeleteBook() {

  const [updateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] = useUpdateItem();

  const deleteBookWrapper = ({
    bookId,
    stores
  }, callback) => {
    try {

      updateItem({
        itemExpectedVersion: 1,
        itemKey: bookId,
        //itemSortKeyName: 'documentPartId',
        //itemSortKeyValue: props.userComment.documentPartId,
        itemType: 'Book',
        fieldName: 'deletedAt',
        fieldValue: 'now',
        refetchQueries: ['listBooks'],
        // onCompleted: () => {

        //   if(callback){
        //     callback();
        //   }
        //   //props.userComment.isPinned = !props.userComment.isPinned
        //   //stores.bookStore.bookListBook = null;
        // }
      }, () => {
        if (callback) {
          callback();
        }
      })

    } catch (err) {
      console.log(err);
    }

  }



  return {
    deleteBook: deleteBookWrapper
  }
}


export function useManagerDeleteBookUserGroupShare(props) {

  const [wrappedMutation, { data, loading, error }] = useDeleteBookUserGroupShare();

  const wrapper = ({
    bookId,
    userGroupId
  }, callback) => {
    try {

      wrappedMutation({
        bookId: bookId,
        userGroupId
      });

    } catch (err) {
      console.log(err);
    }

  }



  return {
    deleteBookUserGroupShare: wrapper,
    loading,
    error
  }
}

export function useManagerDeleteBookUserShare(props) {

  const [wrappedMutation, { data, loading, error }] = useDeleteBookUserShare();

  const wrapper = ({
    bookId,
    sharedWithUserId
  }, callback) => {
    try {

      wrappedMutation({
        bookId: bookId,
        sharedWithUserId
      });

    } catch (err) {
      console.log(err);
    }

  }



  return {
    deleteBookUserShare: wrapper,
    loading,
    error
  }
}

export function useShareBookWithUser(props) {

  const [shareBook, { data: shareBookData, loading: shareBookLoading, error: sshareBookError }] = useShareBook(props);

  const wrapper = ({
    bookId,
    userId
  },
    callback
  ) => {
    try {

      shareBook({
        bookId: bookId,
        sharedWithUserId: userId
      },
        () => {
          if (callback) {

            callback();

          }
        },
        () => {

        });

    } catch (err) {
      console.log(err);
    }

  }



  return {
    shareBookWithUser: wrapper,
    loading: shareBookLoading,
    error: sshareBookError
  }
}


export function useShareBookWithGroup(props) {

  const [shareBookWithUserGroup, { data: shareBookWithUserGroupData, loading: shareBookWithUserGroupLoading, error: shareBookWithUserGroupError }] = useShareBookWithUserGroup();

  const wrapper = ({
    bookId,
    userGroupId
  }, callback) => {
    try {

      shareBookWithUserGroup({
        bookId: bookId,
        userGroupId: userGroupId
      });

    } catch (err) {
      console.log(err);
    }

  }



  return {
    shareBookWithGroup: wrapper,
    loading: shareBookWithUserGroupLoading,
    error: shareBookWithUserGroupError
  }
}




export function useGetBookContributedToBoxsets(props, book) {

  const [boxsets, setBoxsets] = useState([]);

  const { loading: listBookContributedToBoxSetsLoading, error: listBookContributedToBoxSetsError, data: listBookContributedToBoxSetsData } = useListBookContributedToBoxSets({
    id: book?.id
  });


  useEffect(() => {

    //console.log(listBookContributedToBoxSetsData);
    let bookDrafts = [];

    if (listBookContributedToBoxSetsData) {


      let boxsetPojos = listBookContributedToBoxSetsData.getBook.contributedToBoxSets.items.map((boxSetBook) => {

        let { boxset } = boxSetBook;

        return observable(new BoxSet({
          bookDraft: book.bookDraft,
          book,
          data: boxset
        }))

      });

      setBoxsets(boxsetPojos);

    }



  }, [listBookContributedToBoxSetsData]);

  return { boxsets, loading: listBookContributedToBoxSetsLoading, error: listBookContributedToBoxSetsError };
}



export function useGetBookShares(props, book) {

  const [sharedWithUsers, setSharedWithUsers] = useState([]);
  const [sharedWithUserGroups, setSharedWithUserGroups] = useState([]);

  const [lazyListBookUserShares, { loading: bookUserSharesLoading, error: bookUserSharesError, data: lazyListBookUserSharesData, refetch: lazyListBookUserSharesRefetch }] = useLazyListBookUserShares();
  const { loading: bookUserGroupSharesLoading, error: bookUserGroupSharesError, data: listBookUserGroupSharesData, refetch: listBookUserGroupSharesRefetch } = useListBookUserGroupShares({
    bookId: book?.id
  });


  useEffect(() => {

    if (book) {
      lazyListBookUserShares({
        variables: {
          bookId: book.id
        }

      });
    }
  }, [book]);



  useEffect(() => {

    //console.log(lazyListBookUserSharesData);
    if (lazyListBookUserSharesData) {
      let items = lazyListBookUserSharesData?.listBookUserShares.items.map((m) => {

        return m;
      });

      setSharedWithUsers(items);


    }
  }, [lazyListBookUserSharesData]);

  useEffect(() => {

    //console.log(listBookUserGroupSharesData);
    if (listBookUserGroupSharesData) {
      let items = listBookUserGroupSharesData?.listBookUserGroupShares.items.map((m) => {

        return m;
      });

      setSharedWithUserGroups(items);


    }
  }, [listBookUserGroupSharesData]);


  return { sharedWithUsers, bookUserSharesLoading, bookUserSharesError, sharedWithUserGroups, bookUserGroupSharesLoading, bookUserGroupSharesError };
}


export function useGetAllBookShares(props, book) {

  const [sharedWithItems, setSharedWithItems] = useState([]);
  // const [lazyListBookUserShares, { loading: bookUserSharesLoading, error: bookUserSharesError, data: lazyListBookUserSharesData, refetch: lazyListBookUserSharesRefetch }] = useLazyListBookUserShares();
  // const { loading: bookUserGroupSharesLoading, error: bookUserGroupSharesError, data: listBookUserGroupSharesData, refetch: listBookUserGroupSharesRefetch } = useListBookUserGroupShares({
  //   bookId: book?.id
  // });

  const { loading: sharedWithLoading, error: sharedWithError, data: sharedWithData, refetch: sharedWithRefetch } = useListBookShares({
    bookId: book?.id
  });




  // useEffect(() => {

  //   if (book) {
  //     lazyListBookUserShares({
  //       variables: {
  //         bookId: book.id
  //       }

  //     });
  //   }
  // }, [book]);



  // useEffect(() => {

  //   //console.log(lazyListBookUserSharesData);
  //   if (lazyListBookUserSharesData) {
  //     let items = lazyListBookUserSharesData?.listBookUserShares.items.map((m) => {

  //       return m;
  //     });

  //     setSharedWithUsers(items);


  //   }
  // }, [lazyListBookUserSharesData]);

  useEffect(() => {

    //console.log(listBookUserGroupSharesData);
    if (sharedWithData) {

      try {

        let items = sharedWithData?.listBookShares.items.map((m) => {

          return m;
        });

        setSharedWithItems(items);

      } catch (err) {
        console.log(err);
      }


    } else {
      setSharedWithItems([]);
    }
  }, [sharedWithData]);


  return { sharedWithItems, sharedWithLoading, sharedWithError, sharedWithError };
}


export function useMoveDocumentPart(props) {


  const [wrappedMoveDocumentPartBatch,
    { data: data,
      loading: loading,
      error: error }] = useMoveBookDraftDocumentPart();

  const wrapper = ({
    userId,
    bookDraft,
    moveInfo,
    // bookDraftExpectedVersion,
    // movedPartId,
    // movedPartExpectedVersion,
    // newParentId,
    // newPrevId
  }, callback) => {
    try {


      //console.log('move it');


      //helper function to process moved and affected nodes.
      //This updates the in-memory bddps ( mobx ) and gives us
      //the data we need to create an optimistic response.
      const processNodeMoveInfo = (allMoveNodeInfo) => {

        let modifiedBddps = {}
        allMoveNodeInfo.forEach((nodeInfo) => {

          let bddp = bookDraft.getBookDraftDocumentPartByPartId(nodeInfo.id);

          if (nodeInfo.newParentId != 'UNCHANGED') {

            bddp.parentPartId = nodeInfo.newParentId;
            modifiedBddps[bddp.id] = bddp;
          }
          if (nodeInfo.newPrevId != 'UNCHANGED') {

            bddp.prevPartId = nodeInfo.newPrevId;
            modifiedBddps[bddp.id] = bddp;
          }

        });

        return modifiedBddps;


      }

      //helper functions ...
      const generateMovedNodeMoveInfo = ({ bookDraft, movedBddp, newPrevBddp, moveInfo }) => {


        //console.log('generateMovedNodeMoveInfo:...');
        return {
          id: moveInfo.dragSourceId,
          newParentId: moveInfo.dropTargetId,
          newPrevId: newPrevBddp ? newPrevBddp.partId : null,
          oldParentId: movedBddp.parentPartId,
          oldPrevId: movedBddp.prevPartId,
          title: movedBddp.documentPart.derivedTitle,
          description: 'MOVED_BDDP'
        }
      }

      const generateOldNextNodeMoveInfo = ({ bookDraft, movedBddp, newPrevBddp, moveInfo }) => {

        //Find any bddp that is the old next for the movedBddp
        let oldNextBddp = bookDraft.getBookDraftDocumentPartByPrevPartId(movedBddp.parentPartId, movedBddp.partId);
        if (oldNextBddp) {
          return {
            id: oldNextBddp.partId,
            newParentId: 'UNCHANGED',
            newPrevId: movedBddp.prevPartId,
            oldParentId: oldNextBddp.parentPartId,
            oldPrevId: oldNextBddp.prevPartId,
            title: oldNextBddp.documentPart.derivedTitle,
            description: 'OLD_NEXT_BDDP'
          }
        }


      }

      const getNewNextNodeNodeInfo = ({ bookDraft, movedBddp, newParentBddp, newPrevBddp, moveInfo }) => {

        let newNextBddp = null;

        if (newPrevBddp) {
          newNextBddp = bookDraft.getBookDraftDocumentPartByPrevPartId(newPrevBddp.parentPartId, newPrevBddp.partId);
        } else {
          newNextBddp = bookDraft.getBookDraftDocumentPartByPrevPartId(newParentBddp.partId, null);
        }

        if (newNextBddp) {
          return {
            id: newNextBddp.partId,
            newParentId: 'UNCHANGED',
            newPrevId: movedBddp.partId,
            oldParentId: newNextBddp.parentPartId,
            oldPrevId: newNextBddp.prevPartId,
            title: newNextBddp.documentPart.derivedTitle,
            description: 'NEW_NEXT_BDDP'
          }
        }

      }


      let allMoveNodeInfo = [];

      let movedBddp = bookDraft.getBookDraftDocumentPartByPartId(moveInfo.dragSourceId);
      let newParentBddp = bookDraft.getBookDraftDocumentPartByPartId(moveInfo.dropTargetId);
      let newPrevBddp = null;
      if (moveInfo.relativeIndex > 0) newPrevBddp = newParentBddp.getSortedChildren()[moveInfo.relativeIndex - 1]

      let movedNodeMoveInfo = generateMovedNodeMoveInfo({ bookDraft, movedBddp, newPrevBddp, moveInfo })
      let oldNextNodeMoveInfo = generateOldNextNodeMoveInfo({ bookDraft, movedBddp, newPrevBddp, moveInfo })
      let newNextNodeNodeInfo = getNewNextNodeNodeInfo({ bookDraft, movedBddp, newParentBddp, newPrevBddp, moveInfo })

      allMoveNodeInfo.push(movedNodeMoveInfo);
      if (oldNextNodeMoveInfo) allMoveNodeInfo.push(oldNextNodeMoveInfo);
      if (newNextNodeNodeInfo) allMoveNodeInfo.push(newNextNodeNodeInfo);

      //console.log(allMoveNodeInfo);
      let modifiedBddps = processNodeMoveInfo(allMoveNodeInfo);

      let originalVersion = bookDraft?.version;
      bookDraft.version = bookDraft?.version + 1;


      let bookDraftExpectedVersion = originalVersion;
      let movedPartId = movedBddp.partId;
      let movedPartExpectedVersion = movedBddp.documentPart.version;
      let newParentId = newParentBddp.partId;
      let newPrevId = newPrevBddp != null ? newPrevBddp.partId : null;


      let optimisticResponse = {
        moveDocumentPartBatch: Object.values(modifiedBddps).map((bddp) => {
          return {
            id: bddp.id,
            __typename: "BookDraftDocumentPart",
            parentPartId: bddp.parentPartId,
            prevPartId: bddp.prevPartId,
            originalParentPartId: 'optimistic'
          }
        })
      }


      wrappedMoveDocumentPartBatch({
        userId,
        bookDraftId: bookDraft.id,
        bookDraftExpectedVersion,
        movedPartId,
        movedPartExpectedVersion,
        parentPartId: newParentId,
        prevPartId: newPrevId,
        optimisticResponse: optimisticResponse
      }, () => {
        enqueueSnackbar('DocumentPart moved', { variant: 'success' })
      }, (error) => {
        try {

          enqueueSnackbar(error.message, { variant: 'error' })
        } catch (err) {
          console.log(err);
        }

      });

    } catch (err) {
      console.log(err);
    }

  }



  return {
    moveDocumentPart: wrapper,
    loading: loading,
    error: error
  }
}


export function moveBookDraftDocumentPartUtil({ bookDraftId, movedPartId, newParentId, newPrevId }) {

  let movedBddp = bookDraftId.getBookDraftDocumentPartByPartId(movedPartId);
  let newParentBddp = bookDraftId.getBookDraftDocumentPartByPartId(newParentId);
  let newPrevBddp = bookDraftId.getBookDraftDocumentPartByPartId(newPrevId);


  // const nodeIndex = treeData.findIndex(node => node.id === movedPartId);

  // if (nodeIndex === -1) {
  //   console.log('Node not found.');
  //   return;
  // }

  // const node = treeData[nodeIndex];
  // const oldParentId = node.parentId;
  // const oldPrevId = node.prevId;

  // node.parentId = newParentId;
  // node.prevId = newPrevId;

  // if (newPrevId !== null) {
  //   const prevSiblingIndex = treeData.findIndex(node => node.id === newPrevId);
  //   if (prevSiblingIndex !== -1 && treeData[prevSiblingIndex].parentId === newParentId) {
  //     treeData[prevSiblingIndex].prevId = movedPartId;
  //   }
  // }

  // if (newPrevId === null && newParentId !== null) {
  //   const parentIndex = treeData.findIndex(node => node.id === newParentId);
  //   if (parentIndex !== -1) {
  //     treeData[parentIndex].parentId = movedPartId;
  //   }
  // }

  // if (oldPrevId === null && oldParentId !== null) {
  //   const oldParentIndex = treeData.findIndex(node => node.id === oldParentId);
  //   if (oldParentIndex !== -1) {
  //     treeData[oldParentIndex].parentId = oldParentId;
  //   }
  // }

  // const children = treeData.filter(node => node.parentId === movedPartId);
  // children.forEach(child => {
  //   child.parentId = newParentId;
  // });

  //console.log('Tree updated successfully.');
}



export default new BooksManager();