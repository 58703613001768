import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import CameraOutline from 'mdi-material-ui/CameraOutline';
import AnimationOutline from 'mdi-material-ui/AnimationOutline';
import BookOutline from 'mdi-material-ui/BookOutline';
import BookOpenVariant from 'mdi-material-ui/BookOpenVariant';
import TrashCanOutline from 'mdi-material-ui/TrashCanOutline';
import FileDocumentEdit from 'mdi-material-ui/FileDocumentEdit';
import FolderOutline from 'mdi-material-ui/FolderOutline';
import VideoOutline from 'mdi-material-ui/VideoOutline';
import AccountEdit from 'mdi-material-ui/AccountEdit';
import CopyAllIcon from '@mui/icons-material/CopyAll';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    //padding: grid * 2,
    margin: `0 00px 00px 0`,

    display: "inline-flex",
    width: "100%",
    padding: "0px",

    // change background colour if dragging
    //background: isDragging ? "lightgreen" : "grey",
    display: "inline-flex",
    padding: "0px",
    margin: "0 0px 0px 0",
    //border: "1px solid yellow",
    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    //background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    margin: "0px 0"
});



const wrapIcon = (icon, theme) => {

    return <div style={{
    }}>
        <span style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '.5em',
            color: theme.palette.standardTree.nodes.color.main
        }}>{icon}</span>
    </div>


}

//export default class ChildItems extends React.Component {
const ChildItems = (props) => {
    
    const theme = useTheme();

    const partTypeIcons = {
        BOXSETS: wrapIcon(<AnimationOutline />, theme),
        BOXSET: wrapIcon(<AnimationOutline sx={{
            color: theme.palette.standardTree.heading.color.main
        }} />, theme),
        TOPBOOK: wrapIcon(<BookOutline color={theme.palette.text.main} />, theme),
        BOOKS: wrapIcon(<BookOutline />, theme),
        BOOK: wrapIcon(<BookOpenVariant sx={{
            color: theme.palette.standardTree.heading.color.main
        }} />, theme),
        VOLUME: wrapIcon(<FolderOutline />, theme),
        PART: wrapIcon(<FolderOutline />, theme),
        CHAPTER: wrapIcon(<FolderOutline />, theme),
        SCENE: wrapIcon(<VideoOutline />, theme),
        SNAPSHOTS: wrapIcon(<CameraOutline />, theme),
        SNAPSHOT: wrapIcon(<CameraOutline />, theme),
        TRASH: wrapIcon(<TrashCanOutline />, theme),
        RESEARCH: wrapIcon(<FolderOutline />, theme),
        FOLDER: wrapIcon(<FolderOutline />, theme),
        EDIT: wrapIcon(<AccountEdit />, theme),
        SNAPSHOT: wrapIcon(<CopyAllIcon />, theme),
        //PDF:wrapIcon(<FilePdf />), theme,
        //WEBPAGE:wrapIcon(<Web />, theme),
        DEFAULT: wrapIcon(<FileDocumentEdit />, theme),


        //DEFAULT:<span></span>

    }
    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
  
        return (
            <Droppable droppableId={props.type} type={`droppableSubItem`}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {props.subItems.map((item, index) => (

                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <div style={{ display: "flex" }}>
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <span
                                                {...provided.dragHandleProps}
                                                style={{
                                                    display: "block",
                                                    margin: "0 0px",
                                                    //border: "1px solid #000"
                                                }}
                                            >
                                                {partTypeIcons[item.bddp.documentPart.partType] != null ? partTypeIcons[item.bddp.documentPart.partType] : partTypeIcons['DEFAULT']}
                                            </span>
                                            
                                            <Typography
                                                //className={'treeNodeTitle'}
                                                noWrap={true}
                                                variant={theme.components.standardTree.nodes.variant}
                                                //color={titleColor}
                                                sx={{

                                                    'white-space': 'nowrap',
                                                    //'font-weight':'bold',
                                                    //'border': '1px solid red',
                                                    //'width': '100%',
                                                    'min-width': 0,
                                                    'display': 'block',
                                                    'overflow': 'hidden',
                                                    'text-overflow': 'ellipsis',
                                                }}
                                            >{item.content}:{index}</Typography>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Draggable>
                            
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    
};

export default ChildItems;
