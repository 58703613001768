import Quill from 'quill';
//import Parchment from 'parchment';
//import Popper from 'popper.js';
import { v4 as uuid }  from 'uuid';
const Parchment = Quill.import('parchment');
let Inline = Quill.import('blots/inline');
var $ = require("jquery");

let generateClassNames = function({accepted,rejected,preview,editorPinned,requestorPinned,editorMode=false}){

    editorPinned = (editorPinned && editorPinned=='true')?true:false;
    if(!editorMode){
      editorPinned = (requestorPinned && requestorPinned=='true')?true:false;
    }
    let acceptedClass = '';
    if(accepted && accepted=='true'){
      if(preview && !editorPinned){
        acceptedClass = 'editingDeleteFinalViewAccepted';
      }else{
        acceptedClass = 'editingDeleteBlotAccepted editingBlotHidden';
      }
    }

    else if(rejected && rejected=='true'){
      
      
      if(preview && !editorPinned){
        acceptedClass = 'editingDeleteFinalViewRejected';
      }else{
        acceptedClass = 'editingDeleteBlotRejected';
      }


    }
    else{

      

      if(preview && !editorPinned){
        //act as if it was accepted since we have nothing else from the author.
        acceptedClass = 'editingDeleteFinalViewAccepted';
      }else{
        acceptedClass = 'editingDeleteBlotPending';
      }



    }



    return 'editingBlot editingDeleteBlot '+acceptedClass;


  }

class EditingDeleteBlot extends Inline {

  static create(args) {
    //////console.log(args);

    
    let node = super.create(args);
    //node.setAttribute('style', display+'color:red;border:1px solid red;text-decoration:line-through;background-colorx:pink');
    
    //node.setAttribute('uuid', args.uuid);
    
    //value = this.sanitize(value);
    //node.setAttribute('href', args.href);
    //node.setAttribute('id', args.href);
    //node.dataset.href = args.href;
    node.dataset.uuid = args.uuid;
    node.dataset.type = 'editingDeleteBlot';
    node.dataset.accepted = args.accepted;
    node.dataset.rejected = args.rejected;
    node.dataset.editorPinned = args.editorPinned;
    node.dataset.requestorPinned = args.requestorPinned;
    node.dataset.editorComment = args.editorComment;

    
    /*let acceptedClass = '';
    if(node.dataset.accepted && node.dataset.accepted=='true'){
      acceptedClass = 'editingDeleteBlotAccepted editingBlotHidden';
    }
    if(node.dataset.rejected && node.dataset.rejected=='true'){
      acceptedClass = 'editingDeleteBlotRejected';
    }


    let classNames = 'editingBlot editingDeleteBlot '+acceptedClass;

    node.setAttribute('class', classNames);*/

     node.setAttribute('class', generateClassNames({
      accepted:node.dataset.accepted,
      rejected:node.dataset.rejected,
      preview:false,
      editorPinned:node.dataset.editorPinned,
      requestorPinned:node.dataset.requestorPinned,
    }));
    //node.setAttribute('contenteditable', 'false');
    node.setAttribute('type', 'editingDeleteBlot');
    node.setAttribute('editorComment', args.editorComment);


let propsDiv = $("#EditingPopper");

    let thisPopper = null;

    $(node).dblclick(function () {
      
      $('#editPopperTriggerDiv').data('blotNode', node);
      $('#editPopperTriggerDiv').click();
      return node;
      

    });


    // $(node).bind("mouseenter", function(e) {
    //   console.log('mouseenter...');
    //   $('#editPopperTriggerDiv').data('blotNode', node);
    //   $('#editPopperTriggerDiv').click();
    //   //console.log(this);
    //   return node;
    // });







    $( '#EditingPopper' ).mouseleave(function(){
      $('#EditingPopper').hide();
      if(thisPopper){
        thisPopper.destroy();
      }
      
    });













    //node.setAttribute('target', '_blank');
    return node;
  }

  static formats(domNode) {
    //return domNode.dataset.src
    //return domNode.getAttribute('href');

    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
    uuid:domNode.dataset.uuid,
    type:'editingDeleteBlot',
    accepted:domNode.dataset.accepted,
    rejected:domNode.dataset.rejected,
    editorPinned:domNode.dataset.editorPinned,
    requestorPinned:domNode.dataset.requestorPinned,
    editorComment:domNode.dataset.editorComment
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  static value(domNode) {
    let value = {
      //src:'https://www.mathsisfun.com/calculus/images/diff-eq-1.svg', 
    uuid:domNode.dataset.uuid,
    type:'editingDeleteBlot',
    accepted:domNode.dataset.accepted,
    rejected:domNode.dataset.rejected,
    editorPinned:domNode.dataset.editorPinned,
    requestorPinned:domNode.dataset.requestorPinned,
    editorComment:domNode.dataset.editorComment
    };
    //value.id = domNode.dataset.id;
    return value;
  }

  /*static sanitize(url) {  
    return sanitize(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
  }*/

  format(name, args) {
    if (name !== this.statics.blotName || !args) return super.format(name, args);
    //value = this.constructor.sanitize(value);
    //this.domNode.setAttribute('href', args.href);
    this.domNode.setAttribute('uuid', args.uuid);
    this.domNode.setAttribute('type', 'editingDeleteBlot');
    this.domNode.setAttribute('accepted', args.accepted);
    this.domNode.setAttribute('rejected', args.rejected);
    this.domNode.setAttribute('editorPinned', args.editorPinned);
    this.domNode.setAttribute('requestorPinned', args.requestorPinned);
    this.domNode.setAttribute('editorComment', args.editorComment);
  }

  // deleteAt() {
  //   return false
  // }

  accept(preview){
        this.domNode.dataset.accepted = 'true';
        this.domNode.dataset.rejected = 'false';
       
      let classNames = generateClassNames({
        accepted:this.domNode.dataset.accepted,
        rejected:this.domNode.dataset.rejected,
        preview:preview,
        editorPinned:this.domNode.dataset.editorPinned,
        requestorPinned:this.domNode.dataset.requestorPinned,
      })
      this.domNode.setAttribute('class', classNames);
      this.previewOff();
  }

  reject(preview){
        this.domNode.dataset.accepted = 'false';
        this.domNode.dataset.rejected = 'true';
       
      let classNames = generateClassNames({
        accepted:this.domNode.dataset.accepted,
        rejected:this.domNode.dataset.rejected,
        preview:preview,
        editorPinned:this.domNode.dataset.editorPinned,
        requestorPinned:this.domNode.dataset.requestorPinned,
      })
      this.domNode.setAttribute('class', classNames);
      this.previewOff();
  }

  toggleAccept(preview){
     if(!this.domNode.dataset.accepted || this.domNode.dataset.accepted=='false'|| this.domNode.dataset.accepted=='undefined'){
        this.domNode.dataset.accepted = 'true';
        this.domNode.dataset.rejected = 'false';
        //this.domNode.setAttribute('style', 'display:none');
        
      }else{
        this.domNode.dataset.accepted = 'false';
        //this.domNode.setAttribute('style', 'color:red;border:1px solid red;text-decoration:line-through;background-colorx:pink');
        //let classNames = this.generateClassNames();
        //this.domNode.setAttribute('class', classNames);
      }
      let classNames = generateClassNames({
        accepted:this.domNode.dataset.accepted,
        rejected:this.domNode.dataset.rejected,
        preview:preview,
        editorPinned:this.domNode.dataset.editorPinned,
        requestorPinned:this.domNode.dataset.requestorPinned,
      })
      this.domNode.setAttribute('class', classNames);
      this.previewOff();
  }

  toggleReject(preview){
     if(!this.domNode.dataset.rejected || this.domNode.dataset.rejected=='false' || this.domNode.dataset.rejected=='undefined'){
        this.domNode.dataset.rejected = 'true';
        this.domNode.dataset.accepted = 'false';
        //this.domNode.setAttribute('style', 'display:none');
        
      }else{
        this.domNode.dataset.rejected = 'false';
        //this.domNode.setAttribute('style', 'color:red;border:1px solid red;text-decoration:line-through;background-colorx:pink');
        //let classNames = this.generateClassNames();
        //this.domNode.setAttribute('class', classNames);
      }
      let classNames = generateClassNames({
        accepted:this.domNode.dataset.accepted,
        rejected:this.domNode.dataset.rejected,
        preview:preview,
        editorPinned:this.domNode.dataset.editorPinned,
        requestorPinned:this.domNode.dataset.requestorPinned,
      })
      this.domNode.setAttribute('class', classNames);
      this.previewOff();
  }

  clearChoices(preview){
        this.domNode.dataset.rejected = 'false';
        this.domNode.dataset.accepted = 'false';
       
      let classNames = generateClassNames({
        accepted:this.domNode.dataset.accepted,
        rejected:this.domNode.dataset.rejected,
        preview:preview,
        editorPinned:this.domNode.dataset.editorPinned,
        requestorPinned:this.domNode.dataset.requestorPinned,
      })
      this.domNode.setAttribute('class', classNames);
      this.previewOff();
  }

  previewOn(){
    $(this.domNode).removeClass('editingBlotHidden');
    $(this.domNode).removeClass('editingDeleteBlotPreview');
    $(this.domNode).addClass('editingDeleteBlotPreview');
  }

  previewOff(){
    $(this.domNode).removeClass();
    $(this.domNode).addClass(generateClassNames({
        accepted:this.domNode.dataset.accepted,
        rejected:this.domNode.dataset.rejected,
        preview:false,
      editorPinned:this.domNode.dataset.editorPinned,
      requestorPinned:this.domNode.dataset.requestorPinned,
      }));
    /*$(this.domNode).removeClass('editingBlot');
    $(this.domNode).removeClass('editingDeleteBlot');
    $(this.domNode).removeClass('editingDeleteBlotPreview');*/
    //$(this.domNode).removeClass('editingDeleteBlotPreview');
     
  }
  /*show(){

    this.domNode.setAttribute('style', 'color:red;border:1px solid red;text-decoration:line-through;background-color:pink');
  }

  hide(){
    if(!this.domNode.dataset.accepted || this.domNode.dataset.accepted=='false'){
        this.domNode.setAttribute('style', 'color:red;border:1px solid red;text-decoration:line-through;background-colorx:pink');
        
      }else{
        this.domNode.setAttribute('style', 'display:none');
      }
  }*/

  finalView(preview,editorMode){

    $(this.domNode).removeClass();
    $(this.domNode).addClass(generateClassNames({
      accepted:this.domNode.dataset.accepted,
      rejected:this.domNode.dataset.rejected,
      preview:preview,
      editorPinned:this.domNode.dataset.editorPinned,
      requestorPinned:this.domNode.dataset.requestorPinned,
      editorMode:editorMode

    }))


  }

  generateClassNamesxxxx(preview){

    let acceptedClass = '';
    if(this.domNode.dataset.accepted && this.domNode.dataset.accepted=='true'){
      //acceptedClass = 'editingDeleteBlotAccepted editingBlotHidden';
      if(preview){
        acceptedClass = 'editingDeleteFinalViewAccepted';
      }else{
        acceptedClass = 'editingDeleteBlotAccepted editingBlotHidden';
      }
    }

    else if(this.domNode.dataset.rejected && this.domNode.dataset.rejected=='true'){
      
      
      if(preview){
        acceptedClass = 'editingDeleteFinalViewRejected';
      }else{
        acceptedClass = 'editingDeleteBlotRejected';
      }


    }
    else{

      acceptedClass = 'editingDeleteBlotPending';

    }



    return 'editingBlot editingDeleteBlot '+acceptedClass;

  }

    toggleEditorPinned(){
    ////console.log(this.domNode.dataset.editorPinned);
    if(this.domNode.dataset.editorPinned && this.domNode.dataset.editorPinned=='true'){
      this.domNode.dataset.editorPinned='false'
    }else{
      this.domNode.dataset.editorPinned='true'
    }
    ////console.log(this.domNode.dataset.editorPinned);
  }

 toggleRequestorPinned(){
    ////console.log(this.domNode.dataset.requestorPinned);
    if(this.domNode.dataset.requestorPinned && this.domNode.dataset.requestorPinned=='true'){
      this.domNode.dataset.requestorPinned='false'
    }else{
      this.domNode.dataset.requestorPinned='true'
    }
    ////console.log(this.domNode.dataset.requestorPinned);
  }

}
EditingDeleteBlot.blotName = 'editingDeleteBlot';
EditingDeleteBlot.tagName = 'span';
EditingDeleteBlot.className = 'editingDeleteBlot';
//EditingDeleteBlot.SANITIZED_URL = 'about:blank';
//EditingDeleteBlot.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];





export { EditingDeleteBlot as default };
