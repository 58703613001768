import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Avatar, Typography, Button, IconButton, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import StandardButton from '../StandardButton';
import StandardDialog from '../StandardDialog';
import CreateSnapshotPanel from '../panels/CreateSnapshotPanel';
import StandardOkCancelDialog from '../panels/StandardOkCancelDialog';
import SendInvitationPanel from '../panels/connections/SendInvitationPanel';
import { useManagerCreateInvitation, useGetUserConnections, useManagerAcceptInvitation } from '../../managers/ConnectionsManager';
import config from '../../config';

const SendInviteAssembly = (props) => {
    
    let [showInviteDialog, setShowInviteDialog] = useState(false);
    let [name, setName] = useState('Bob');
    let [email, setEmail] = useState('jshamel+test0001@gmail.com');

    const { createInvitation, data: createInvitationData, loading: createInvitationLoading, error: createInvitationError } = useManagerCreateInvitation();


    const handleNameChange = (e) => {
        e.preventDefault();

        setName(e.target.value);
    }

    const handleEmailChange = (e) => {
        e.preventDefault();

        setEmail(e.target.value);
    }    

    useEffect(() => {

        // if (createInvitationError) {
        //     //console.log(createInvitationError);


        //     let mappedErrorMessage = config.errorMessagesMappings[createInvitationError.message];

        //     props.stores.bookStore.snackbarMessage = mappedErrorMessage ? mappedErrorMessage : createInvitationError.message;
        //     props.stores.bookStore.snackbarMessageSeverity = 'error';
        //     props.stores.bookStore.showSnackbar = true;



        // }
    }, [createInvitationError]);


    const isXs = useMediaQuery({ maxWidth: 599 });
    const isSm = useMediaQuery({ maxWidth: 600 });
    const isMd = useMediaQuery({ maxWidth: 900 });
    const isLg = useMediaQuery({ maxWidth: 1200 });
    const isXl = useMediaQuery({ minWidth: 1536 });

    const theme = useTheme();

    return (
        <>
            <Box sx={{
                display: 'flex',
                //justifyContent: 'flex-end',
                //width: '100%',
                //border:'1px solid yellow'
            }}>
                <Button variant="contained" sx={{
                    color: '#ffffff',
                    backgroundColor: theme.palette.secondary.main
                }}
                onClick={(e)=>{
                    e.preventDefault();
                    setShowInviteDialog(true);
                }}>Send Invitation</Button>
                {/* <StandardButton variant="contained" sx={{
                    color: '#ffffff',
                    backgroundColor: theme.palette.secondary.main
                }}
                >Create Snapshot</StandardButton> */}

            </Box>

            <StandardOkCancelDialog
                showOkButton
                showCancelButton
                open={showInviteDialog}
                onClose={() => {
                    setShowInviteDialog(false)
                }}
                onOk={() => {


                    try {
                        createInvitation({
                            label: name,
                            emailAddress: email
                        })
                        setShowInviteDialog(false);
                    }
                    catch (err) {
                        console.log(err);
                    }


                }}
                title={'Send Invitation'}
                content={
                    <SendInvitationPanel
                        name={name}
                        email={email}
                        onNameChange={handleNameChange}
                        onEmailChange={handleEmailChange} />
                }
            />
        </>


    )
}

export default inject('stores')(observer(SendInviteAssembly));