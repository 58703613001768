import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { inject, observer } from 'mobx-react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ReactTimeAgo from 'react-time-ago';
import RollbackDocumentPart from "../listPages/dialogs/RollbackDocumentPart";
import DocumentPartHistoryLineChart from "../charts/DocumentPartHistoryLineChart";
import BookDraftHistoryLineChart from "../charts/BookDraftHistoryLineChart";
// import { useManagerRollbackVersion } from '../../managers/BooksManager';


const TimedViewPanel = (props) => {

  let user = props.stores.userStore.user;
  let { book, bookDraftDocumentPart } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState([]);
  const [stepper, setStepper] = React.useState();
  const [rollbackVersion, setRollbackVersion] = React.useState();
  const [showRollbackDialog, setShowRollbackDialog] = React.useState(false);


  useEffect(() => {

    if (bookDraftDocumentPart) {
      let calculatedSteps = bookDraftDocumentPart.documentPart.deltaOps.map((op) => {

        //console.log(op);
        let label = null;
        if (op.createdAt) label = <ReactTimeAgo date={op.createdAt} locale="en-US" />
        return {
          label: label,
          version: op.version,
          description: <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div>
              version:{op.version}
            </div>
            <div>
              {/* {JSON.stringify(op.ops)} */}
            </div>
          </div>
        }
      })

      setSteps(calculatedSteps);



      // let stepper = <Stepper activeStep={activeStep} orientation="vertical">
      //       {calculatedSteps.map((step, index) => (
      //         <Step key={step.label}>
      //           <StepLabel
      //           // optional={
      //           //   index === 2 ? (
      //           //     <Typography variant="caption">Last step</Typography>
      //           //   ) : null
      //           // }
      //           >
      //             {step.label}
      //             <Button
      //               variant="contained"
      //               onClick={() => { handleNext(step, index) }}
      //               sx={{ mt: 1, mr: 1 }}
      //             >
      //               {'View'}
      //             </Button> 
      //             <Button
      //               variant="contained"
      //               onClick={() => { handleRollback(step, index) }}
      //               sx={{ mt: 1, mr: 1 }}
      //             >
      //               {'Rollback'}
      //             </Button>
      //           </StepLabel>
      //           <StepContent>
      //             <Typography>{step.description}</Typography>
      //             {/* <Box sx={{ mb: 2 }}>
      //               <div>
      //                 <Button
      //                   variant="contained"
      //                   onClick={handleNext}
      //                   sx={{ mt: 1, mr: 1 }}
      //                 >
      //                   {'Close'}
      //                 </Button>

      //               </div>
      //             </Box> */}
      //           </StepContent>
      //         </Step>
      //       ))}
      //     </Stepper>

      //     setStepper(stepper);


    }


  }, [bookDraftDocumentPart?.documentPart]);

  if (!bookDraftDocumentPart) {
    return <div>Nothing selected</div>
  }






  const handleRollback = (step, index) => {

    setRollbackVersion(step.version);
    setShowRollbackDialog(true);
  };

  const handleNext = (step, index) => {

    bookDraftDocumentPart.documentPart.processedDeltaOps = null;
    bookDraftDocumentPart.documentPart.viewingVersion = step.version;
    setActiveStep(index);
    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    //setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    //setActiveStep(0);
  };

  return (
    <Grid container direction={'column'}>
      <Grid item>
        {bookDraftDocumentPart?.partId}
      </Grid>
      <Grid>
        <BookDraftHistoryLineChart bookDraft={book.bookDraft} />
        <DocumentPartHistoryLineChart bookDraftDocumentPart={bookDraftDocumentPart} />
      </Grid>
      <Grid item>
        <Box sx={{ maxWidth: 400 }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                // optional={
                //   index === 2 ? (
                //     <Typography variant="caption">Last step</Typography>
                //   ) : null
                // }
                >
                  {step.label}
                  <Button
                    variant="contained"
                    onClick={() => { handleNext(step, index) }}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {'View'}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => { handleRollback(step, index) }}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {'Rollback'}
                  </Button>
                </StepLabel>
                <StepContent>
                  <Typography>{step.description}</Typography>
                  {/* <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {'Close'}
                      </Button>
                      
                    </div>
                  </Box> */}
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>All steps completed - you&apos;re finished</Typography>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
            </Paper>
          )}
        </Box>


      </Grid>
      <RollbackDocumentPart
        open={showRollbackDialog}
        documentPart={bookDraftDocumentPart.documentPart}
        targetRollbackVersion={rollbackVersion}
        onClose={() => {
          setRollbackVersion(null);
          setShowRollbackDialog(false)
        }}

        onRollbackComplete={(book) => {
          setRollbackVersion(null);
          setShowRollbackDialog(false);

          // openBookInWrite({
          //     bookDraftId: book.primaryBookDraftId
          // });

        }}
      />
    </Grid>
  )
}

export default inject('stores')(observer(TimedViewPanel));