import { styled } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';


export const StandardTransparentTabs = styled(Tabs)(({theme}) => ({

    background: 'transparent',
    boxShadow: 'none',
    //color: "#d75b51",
    "& button.Mui-selected": {
        
        color: theme.palette.books?.main
      },


}));


export default function StandardUITabs(props) {

    const theme = useTheme();

    return (

        <StandardTransparentTabs
            //textColor={theme.palette.books?.main} 
            {...props}
            variant="scrollable"
            scrollButtons="auto">
            {props.children}
        </StandardTransparentTabs>

    );
}

