import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import config from '../../../aws-exports';
import ShareIcon from '@mui/icons-material/Share';
import { useManagerCreateSnapshot } from '../../../managers/BooksManager';
import StandardUITextField from "../../UI/StandardUITextField";
import StandardUIButton from "../../UI/StandardUIButton";
import StandardUIDialog from "../../UI/StandardUIDialog";

const CreateSnapshot = (props) => {

    let user = props.stores.userStore.user;
    let { book } = props;

    let [title, setTitle] = useState();
    let [description, setDescription] = useState();

    const { createSnapshot, createSnapshotLoading, createSnapshotError } = useManagerCreateSnapshot(props);

    return (

        <StandardUIDialog
            open={props.open}
            onClose={props.onClose}
            book={props.book}
            title='Create Snapshot'
            dialogContent={
                <Box>
                    <StandardUITextField label={'Title'}
                        onChange={(e) => {
                            setTitle(e.target.value);
                        }} />
                    <StandardUITextField label={'Description'}
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }} />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {createSnapshotLoading && <CircularProgress />}
                    </div>
                </Box>

            }
            cardActions={<StandardUIButton
                buttonAction='Create'
                autoFocus
                disabled={createSnapshotLoading ? true : false}
                onClick={(e) => {
                    e.preventDefault();
                    createSnapshot({
                        title,
                        description,
                        bookDraftId: book.primaryBookDraftId
                    }, () => {
                        props?.onClose();
                    })
                }}
            />}
        />

    )
}

export default inject('stores')(observer(CreateSnapshot));