import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LaurasUserList from './LaurasUserList';

const LaurasBooksEdit = (props) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
           <LaurasUserList
                avatarType='LH'
                showRemoveIcon={false}
                showReadIcon={true}
                primary='Laura Hamel'
                secondary=' Since date'
            />
        </div>
    )
}
export default inject('stores')(observer(LaurasBooksEdit));