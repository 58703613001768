import React, { useEffect, useState } from 'react';
import { observable } from 'mobx';
import {
  useGetBoxSets,
  useCreateBoxSet,
  useUpdateItem,
  useAddBoxSetContributedBook,
  useCreateBoxSetContributor,
  useLazyLoadBoxSetContributors,
  useListContributingToBoxSets,
  useRemoveBoxSetContributor,
  useRemoveBoxSetContributedBook,
  useAddBookDraftDocumentPart
} from '../persistance/persistanceHooks';
import { BoxSet, User } from '../domain/bookModel';
import { useNavigate, useLocation } from "react-router-dom";
import apiErrorCodes from '../api-error-codes';

class BoxsetsManager {

  constructor() {

  }



}


function preProcessGraphqlErrors(e, props){

	let errorMessages = e.graphQLErrors.map((gqlError) => {
		return gqlError.message;
	})

	let nonSystemErrors = errorMessages.filter(f=>f!=apiErrorCodes.ERROR_SYSTEM_ERROR);
	let systemErrors = errorMessages.filter(f=>f==apiErrorCodes.ERROR_SYSTEM_ERROR);

	if(systemErrors.length>0){
		props.stores.bookStore.showSnackMessage('An error', 'success');
	}

	return nonSystemErrors;
	

}

export function useManagerInsertBoxsetBook(props) {


  const [createDocumentPartBatch, { data: mutationData, loading: mutationLoading, error: mutationError }] = useAddBookDraftDocumentPart();

  const wrapper = ({
    userId,
    book,
    boxset,
    parentBddp
  }, callback) => {

    try {

      let partType = 'BOOK';
      let role = 'BOXSET_BOOK';
      let bookId = book.id;
      let insertedBookId = book.id;
      let bookDraftId = boxset.book.primaryBookDraftId;
      let parentPartId = parentBddp?.partId;
      let starterDeltaOps = [];
      let deltaOpsJson = [];
      let previousBddp = null;


      createDocumentPartBatch({
        userId,
        role,
        partType,
        bookDraftId,
        bookId,
        insertedBookId,
        parentPartId,
        prevPartId: previousBddp?.partId,
        starterDeltaOps

      }, (newBddp) => {


        //console.log(newBddp);
        if (callback) {
          callback();
        }
        // props.onBookInserted();
      });

    } catch (err) {
      console.log(err);
    }

  }



  return {
    insertBoxsetBook: wrapper,
    loading: mutationLoading,
    error: mutationError
  }
}


export function useManagerRemoveBoxSetContributedBook(props) {


  const [removeBoxSetContributedBook, { data: removeBoxSetContributedBookData, loading: removeBoxSetContributedBookLoading, error: removeBoxSetContributedBookError }] = useRemoveBoxSetContributedBook(props);
  const [lazyLoadBoxSetContributors, { loading: lazyLoadBoxSetContributorsLoading, error: lazyLoadBoxSetContributorsError, data: lazyLoadBoxSetContributorsData, refetch: lazyLoadBoxSetContributorsRefetch }] = useLazyLoadBoxSetContributors(props);

  const wrapper = ({
    bookId,
    boxsetId
  },
    callback,
    onError) => {

    try {

      removeBoxSetContributedBook({
        boxSetId: boxsetId,
        bookId: bookId
      },
        () => {
          //setRemoveContributorSuccessMessage('Contributor removed!');

          lazyLoadBoxSetContributors({
            variables: {
              boxSetId: boxsetId
            },
            // onCompleted:()=>{
            //   if(callback){
            //     callback();
            //   }
            // },
            // onError:()=>{
            //   if(callback){
            //     callback();
            //   }
            // }

          }).then(({ data }) => {
            if (callback) {
              callback();
            }
          })
            .catch(e => {
              if (onError) {
                
                let errorMessages = preProcessGraphqlErrors(e, props)
    
                onError(errorMessages);
    
              }
            });;

         

          //console.log('this was successful');
        },
        (errors) => {
          console.log(errors);
          //console.log('this was an error');
          if(onError){
            onError(errors);
          }
          // let errorStr = '';
          // if (errors) {
          //   errors.forEach((error) => {
          //     errorStr += ' : ' + error
          //   })
          // }
          //setRemoveContributorErrorMessage(errorStr);
        })

    } catch (err) {
      console.log(err);
    }

  }



  return {
    removeBoxSetContributedBook: wrapper,
    loading: removeBoxSetContributedBookLoading,
    error: removeBoxSetContributedBookError
  }
}

export function useManagerRemoveBoxsetContributor(props) {


  const [removeBoxSetContributor, { data: removeBoxSetContributorData, loading: removeBoxSetContributorLoading, error: removeBoxSetContributorError }] = useRemoveBoxSetContributor();
  const [lazyLoadBoxSetContributors, { loading: lazyLoadBoxSetContributorsLoading, error: lazyLoadBoxSetContributorsError, data: lazyLoadBoxSetContributorsData, refetch: lazyLoadBoxSetContributorsRefetch }] = useLazyLoadBoxSetContributors();

  const wrapper = ({
    contributorId,
    boxsetId
  }, callback) => {

    try {

      removeBoxSetContributor({
        boxSetId: boxsetId,
        contributorId: contributorId
      }, () => {
        //setRemoveContributorSuccessMessage('Contributor removed!');

        lazyLoadBoxSetContributors({
          variables: {
            boxSetId: boxsetId
          }

        });

        // if(callback){
        //   callback();
        // }
      },
        (errors) => {
          console.log(errors);
          let errorStr = '';
          if (errors) {
            errors.forEach((error) => {
              errorStr += ' : ' + error
            })
          }
          //setRemoveContributorErrorMessage(errorStr);
        })

    } catch (err) {
      console.log(err);
    }

  }



  return {
    removeBoxSetContributor: wrapper,
    loading: removeBoxSetContributorLoading,
    error: removeBoxSetContributorError
  }
}

export function useManagerLoadBoxSetContributors(props, boxsetId) {

  const [boxsetContributors, setBoxsetContributors] = useState([]);

  const [lazyLoadBoxSetContributors, { loading: lazyLoadBoxSetContributorsLoading, error: lazyLoadBoxSetContributorsError, data: lazyLoadBoxSetContributorsData, refetch: lazyLoadBoxSetContributorsRefetch }] = useLazyLoadBoxSetContributors();


  useEffect(() => {

    try {

      lazyLoadBoxSetContributors({
        variables: {
          boxSetId: boxsetId
        }

      });


    } catch (err) {
      console.log(err);
    }

  }, [boxsetId]);




  useEffect(() => {


    try {
      //console.log(lazyLoadBoxSetContributorsData);
      if (lazyLoadBoxSetContributorsData) {

        try {

          // let contributors = lazyLoadBoxSetContributorsData.getBoxSet.contributors.items.map((boxsetContributor) => {

          //   return new User({ data: boxsetContributor.contributor })

          // });

          // setBoxsetContributors(contributors);


          setBoxsetContributors(lazyLoadBoxSetContributorsData.getBoxSet.contributors.items);



        } catch (err) {
          console.log(err);
        }
        // let boxSets = [];
        // let items = data.listBoxSets.items.filter(f => f.deletedAt == null).map((boxSetData, index) => {
        //   try {

        //     console.log(boxSetData);

        //     let boxSet = new observable(new BoxSet({
        //       data: boxSetData,
        //       book: boxSetData.book
        //     }))
        //     console.log(boxSet);
        //     boxSets.push(boxSet);
        //   } catch (err) {
        //     console.log(err);
        //   }
        // });

        // setOwnedBoxsets(boxSets);


      }
    } catch (err) {
      console.log(err);
    }

  }, [lazyLoadBoxSetContributorsData]);

  return {
    boxsetContributors,
    loading: lazyLoadBoxSetContributorsLoading,
    error: lazyLoadBoxSetContributorsError,
    refetch: lazyLoadBoxSetContributorsRefetch
  };
}


export function useManagerAddBoxsetContributedBook(props) {


  const [addBoxSetContributedBook, { data: addBoxSetContributedBookData, loading: addBoxSetContributedBookLoading, error: addBoxSetContributedBookError }] = useAddBoxSetContributedBook();

  const wrapper = ({
    bookId,
    boxsetId
  }, callback) => {
    try {

      addBoxSetContributedBook({
        bookId: bookId,
        boxSetId: boxsetId
      });

    } catch (err) {
      console.log(err);
    }

  }



  return {
    addBoxSetContributedBook: wrapper,
    loading: addBoxSetContributedBookLoading,
    error: addBoxSetContributedBookError
  }
}



export function useManagerAddBoxsetContributor(props) {


  const [createBoxSetContributor, { data: createBoxSetContributorData, loading: createBoxSetContributorLoading, error: createBoxSetContributorError }] = useCreateBoxSetContributor();

  const wrapper = ({
    contributorId,
    boxsetId
  }, callback) => {

    try {

      createBoxSetContributor({
        contributorId: contributorId,
        boxSetId: boxsetId
      });

    } catch (err) {
      console.log(err);
    }

  }



  return {
    addBoxsetContributor: wrapper,
    loading: createBoxSetContributorLoading,
    error: createBoxSetContributorError
  }
}

export function useGetInvitedToBoxsets(props, userId) {

  const [boxsets, setBoxsets] = useState([]);


  const { loading, error, data, refetch } = useListContributingToBoxSets({
    id: userId
  });


  useEffect(() => {


    try {
      //console.log(data);
      if (data) {

        let boxSets = [];
        let items = data.getUser.contributingToBoxSets.items.filter(f => f.deletedAt == null).map((boxSetContributor, index) => {
          try {

            //console.log(boxSetContributor);
            if (boxSetContributor.boxset.owner.id != userId) {
              let boxSet = new observable(new BoxSet({
                data: boxSetContributor.boxset,
                book: boxSetContributor.boxset.book
              }))
              boxSet.sharedAt = boxSetContributor.createdAt;
              //console.log(boxSet);
              boxSets.push(boxSet);

            }
          } catch (err) {
            console.log(err);
          }
        });

        setBoxsets(boxSets);

      }
    } catch (err) {
      console.log(err);
    }

  }, [data]);

  return { invitedToBoxsets: boxsets, error };
}


export function useGetOwnedBoxsets(props) {

  const [ownedBoxsets, setOwnedBoxsets] = useState([]);

  const { loading, error, data, refetch, networkStatus } = useGetBoxSets();


  useEffect(() => {


    try {
      //console.log(data);
      if (data) {

        let boxSets = [];
        //let items = data.listBoxSets.items.filter(f => f.deletedAt == null).map((boxSetData, index) => {
        let items = data.listBoxSets.items.map((boxSetData, index) => {
          try {

            //console.log(boxSetData);

            let boxSet = new observable(new BoxSet({
              data: boxSetData,
              book: boxSetData.book
            }))
            //console.log(boxSet);
            boxSets.push(boxSet);
          } catch (err) {
            console.log(err);
          }
        });

        setOwnedBoxsets(boxSets);
        //props.stores.bookStore.authoredBoxSets.replace(boxSets);
        // props.stores.bookStore.authoredBoxSets.filter(f => f.deletedAt == null).forEach((boxSet, index) => {

        // })
      }
    } catch (err) {
      console.log(err);
    }

  }, [data]);

  return { ownedBoxsets, error, loading };
}


export function useManagerDeleteBoxset() {


  const [updateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] = useUpdateItem();

  const deleteBoxsetWrapper = ({
    bookId,
    stores
  }, callback) => {
    try {

      updateItem({
        itemExpectedVersion: 1,
        itemKey: bookId,
        //itemSortKeyName: 'documentPartId',
        //itemSortKeyValue: props.userComment.documentPartId,
        itemType: 'BoxSet',
        fieldName: 'deletedAt',
        fieldValue: 'now',
        refetchQueries: ['listBoxSets'],

      }, () => {
        if (callback) {
          callback();
        }
      })

    } catch (err) {
      console.log(err);
    }

  }



  return {
    deleteBoxset: deleteBoxsetWrapper
  }
}


export function useOpenBoxsetInBoxsetDetails(props) {

  let navigate = useNavigate();
  let location = useLocation();

  const openBoxsetInBoxsetDetails = ({
    boxset
  }) => {
    props.stores.bookStore.boxSetListBoxSet = boxset;
    navigate("/boxsets/details");
  }

  return { openBoxsetInBoxsetDetails };
}



export default new BoxsetsManager();