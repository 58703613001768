import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import StretchBox from '../boilerPlate/StretchBox';
import ScrollBox from '../boilerPlate/ScrollBox';
import { Box, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";

const StandardUIDialog = (props) => {


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));



    return (
        <Dialog
            sx={{
                width: '100%'
            }}
            open={props.open}
            onClose={props.onClose}>
            <div
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: 'solid thick #009688', marginBottom: '1em' }}>

                <DialogTitle
                // style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1em 0', width: '100%' }}
                // variant={theme.landmarkText.variant}
                >
                    {props.title}
                </DialogTitle>
                <IconButton
                    onClick={props.onClose}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogContent
            // style={{ 
            //     display: 'flex', 
            //     flexDirection: 'column', 
            //     justifyContent: 'center', 
            //     alignItems: 'center', 
            //     width: '100%'
            //  }}
            >
                <StretchBox >
                    <ScrollBox >
                        <Box >
                            {props.dialogContent}
                        </Box>
                    </ScrollBox>
                </StretchBox>
            </DialogContent>
            <CardActions
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
            >
                {props.cardActions}
            </CardActions>
        </Dialog>
    )
}
export default inject('stores')(observer(StandardUIDialog));