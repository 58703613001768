import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import EditChangeListItem from './EditChangeListItem';
import { extractJQueryBlotData } from './BlotDOMUtils';
import EditingMovedTombstoneBlot from '../editor/blots/EditingMovedTombstoneBlot';
import EditingMovedDestinationBlot from '../editor/blots/EditingMovedDestinationBlot';
import Quill from 'quill';
import DeltaManager from '../editor/DeltaManager';


const Delta = require('quill-delta');
const Parchment = Quill.import('parchment');

var $ = require("jquery");

const EditChangeNotes = (props) => {

    let { finalView } = props;
    //let [finalView, setFinalView] = useState(false);
    let [allBlots, setAllBlots] = useState([]);


    useEffect(() => {

        let editLineItems = extractJQueryBlotData(props.editBookDraft);

        //console.log(editLineItems);

        setAllBlots(editLineItems);

    }, [props.stores.bookStore.blotsChangedTimestamp, props.documentPart]);


    useEffect(() => {

        //Do not search through all .ql-container classes. This will also return the preview edits
        //let nodes = $('.ql-container').find('.editingBlot');

        /********************************************************************
            Remove existing preview content all regular quill instances.
        *********************************************************************/
        $(".primary-editor").find("[data-is-preview='true']").remove();




        /********************************************************************
            Find all editing blot nodes in all regular quill instances.
        *********************************************************************/
        let nodes = $('.primary-editor').find('.editingBlot');

        let alreadyMapped = {


        };
        let editorMode = true;

        nodes.each(function () {
            let blot = Parchment.find(this);
            let editChange = this.dataset;
            // console.log(blot);
            // console.log(editChange);


            if (alreadyMapped[editChange.uuid] == null) {

                alreadyMapped[editChange.uuid] = editChange;





                /********************************************************************
                    Process tombstone blots first. This will move the content from 
                    the previewQuill editor to the tombstones location, if it was
                    rejected. Later, we'll process all remaining blots
                    ( inserts, deletes etc.)
                *********************************************************************/
                if (blot instanceof EditingMovedTombstoneBlot) {

                    let editBookDraft = props.editBookDraft;
                    let sourceUuid = editChange.uuid;
                    let destinationUuid = editChange.oppositeUuid;

                    previewMovedBlot({
                        sourceUuid,
                        destinationUuid,
                        editChange,
                        blot

                    });

                }


            }


        });



        /********************************************************************
            Now that all tombstone blots are processed, process all remaining.
            If destination blots are not processed after all tombstone blots,
            they do not get updated properly.
        *********************************************************************/

        nodes = $('.primary-editor').find('.editingBlot');
        nodes.each(function () {
            let blot = Parchment.find(this);
            let editChange = this.dataset;
            // console.log(blot);
            // console.log(editChange);
            if (!(blot instanceof EditingMovedTombstoneBlot)) {

                try {
                    blot.finalView(finalView, editorMode);
                } catch (err) {
                    console.log(err);
                }

            }



        });




    }, [finalView]);


    
    const previewMovedBlot = ({
        sourceUuid,
        destinationUuid,
        editChange,
        blot

    }) => {

        let editBookDraft = props.editBookDraft;
        let editorMode = true;
        //console.log(props.editChange);



        //*****************************************************
        //  Locate information about the tombstone blot
        //*****************************************************
        let tombstoneDocumentPart = editBookDraft.getBookDraftDocumentPartByPartId(editChange.sourcePartId).documentPart;

        let {
            beforeDelta: tombstoneBeforeDelta,
            foundDelta: tombstonefoundDelta,
        } = new DeltaManager().findBlot({
            //delta:finalDeltaOps,
            documentPart: tombstoneDocumentPart,
            uuid: sourceUuid
        });



        //*****************************************************
        //  Locate information about the destination blot
        //*****************************************************
        let destinationDocumentPart = editBookDraft.getBookDraftDocumentPartByPartId(editChange.destinationPartId).documentPart;
        let {
            beforeDelta: destinationBeforeDelta,
            foundDelta: destinationfoundDelta,
        } = new DeltaManager().findBlot({
            //delta:finalDeltaOps,
            documentPart: destinationDocumentPart,
            uuid: destinationUuid
        });


        /******************************************************
          Load the destination blot content into the preview 
          editor
        *******************************************************/
        props.stores.bookStore.previewQuill.setContents(new Delta());
        props.stores.bookStore.previewQuill.updateContents(destinationfoundDelta);


        /******************************************************
          Update all blots in the previewEditor to show their 
          final views before being copied into the working
          quill editors
        *******************************************************/
        let nodes = $('#previewEditor').find('.editingBlot');
        nodes.each(function () {
            let blot = Parchment.find(this);
            let editChange = this.dataset;

            if (!(blot instanceof EditingMovedTombstoneBlot) && !(blot instanceof EditingMovedDestinationBlot)) {

                try {
                    blot.finalView(finalView, editorMode);
                } catch (err) {
                    console.log(err);
                }

            }

        });



        /******************************************************
          Select all of the content in the preview editor
        *******************************************************/
        let renderedDestinationContent = $("#previewEditor").find("[data-type='editingMovedDestinationBlot']");



        /******************************************************
          Next get the parents of each destination blot. This 
          is because a single tombstone blot may have regular
          newlines within them. When this happens the matching
          content ( loaded into the previewer ) is attached
          to multiple <p> elements. We then need to do a 
          text match test to move each destination 
          content fragment to the correct tombstone fragment.
        *******************************************************/
        let mappedContent = [];
        let renderedDestinationContentParents = $("#previewEditor").find(".editingBlot").parent().filter('p');



        /******************************************************
          Select all of the content in the preview editor
        *******************************************************/
        renderedDestinationContentParents.toArray().forEach((parentNode) => {

            /******************************************************
             Clone this parent node and get rid of inserted text. 
             This is so we can compare this parent's final text
             with the tombstone's text. This is how we match 
             the fragments together.
            *******************************************************/
            let parentClone = $(parentNode).clone();
            $(parentClone).find('.editingInsertBlot').remove();

            let parentText = $(parentClone).text();

            /******************************************************
             Clean the parentNode, NOT the cloned node. The cloned
             parent node is altered to allow the text test. 
             The original parentNode content will be copied into 
             the tombstone ( if it was rejected ).

             Cleaning means to get rid of all edit styling like
             grey, green red borders etc. 

             Have to use styling attribute here because quill strips
             out classnames that do not match blots.
            *******************************************************/
            $(parentNode).find('.editingBlot').attr('style', 'border:0px');

            mappedContent.push({
                convertedText: parentText,
                content: [$(parentNode).children().toArray()]

            })
        })



        /******************************************************
         Next process each tombstone node. Match its text
         to the mapped destination fragments from above. 
        *******************************************************/
        let tombstoneNodes = $("div").find("[data-uuid='" + sourceUuid + "']").toArray();

        tombstoneNodes.forEach((tombstoneNode) => {

            let tombstoneText = $(tombstoneNode).text();

            let toBeRendered = null;
            mappedContent.forEach((m) => {
                if (m.convertedText == tombstoneText) {
                    toBeRendered = m.content;
                }
            })

            let tombstoneBlot = Parchment.find(tombstoneNode);
            tombstoneBlot.finalView(finalView, editorMode, toBeRendered);
        })


    }




    return (
       
        <div>

            {Object.values(allBlots).map((EditChangeListItem)=>{

                return EditChangeListItem;

            })
            
            }
        </div>

    );
};


export default inject('stores')(observer(EditChangeNotes));
