import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Feather from 'mdi-material-ui/Feather';
import IconButton from '@mui/material/IconButton';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import LaurasButton from './LaurasButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ListItemButton from '@mui/material/ListItemButton';

const LaurasBookList = (props) => {

    let { showWriteIcon = true, showFormatIcon = true, showRestoreButton = false, showReadIcon = false } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const secondaryActions = [];

    if (showWriteIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <Feather />
        </IconButton>)
    }

    if (showFormatIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <FormatPaintIcon />
        </IconButton>)
    }

    if (showReadIcon) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <ManageSearchIcon />
        </IconButton>)
    }

    if (showRestoreButton) {
        secondaryActions.push(<IconButton
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <LaurasButton
                buttonAction='Restore'
            />
        </IconButton>)
    }


    return (
        <List>
            {isSmallScreen ?


                <ListItemButton
                    sx={{ paddingLeft: ' 0.25em' }}
                    onClick={props.onClick}
                    selected={props.selected}
                >
                    <ListItem
                        secondaryAction={
                            secondaryActions
                        }
                    >
                        <ListItemAvatar>
                            <Avatar variant="rounded">
                                {props.avatarType}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={props.primary}
                            secondary={props.secondary}
                        />
                    </ListItem>
                </ListItemButton>

                :
                <ListItemButton
                    onClick={props.onClick}
                    selected={props.selected}
                >
                    <ListItem
                        secondaryAction={
                            secondaryActions
                        }
                    >
                        <ListItemAvatar>
                            <Avatar variant="rounded">
                                {props.avatarType}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={props.primary}
                            secondary={props.secondary}
                        />
                    </ListItem>
                </ListItemButton>
            }

        </List>
    )
}
export default inject('stores')(observer(LaurasBookList));