import React, { useState, useEffect } from "react";
import { Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import { inject, observer } from 'mobx-react';
import { useManagerCreateInvitation, useGetUserConnections, useGetUserGroups } from '../../../managers/ConnectionsManager';
import StandardUIDialog from "../../UI/StandardUIDialog";
import ListConnections from "../lists/ListConnections";
import StandardUITabs from "../../UI/StandardUITabs";
import StretchBox from '../../boilerPlate/StretchBox';
import ScrollBox from '../../boilerPlate/ScrollBox';
import { useManagerCreateEditRequest } from '../../../managers/EditRequestsManager';
import InviteConnection from "./InviteConnection";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


const SendEditRequest = (props) => {

    let user = props.stores.userStore.user;
    let { book, selectedUserConnection } = props;


    const { createEditRequest, loading: createEditRequestLoading, error: createEditRequestError } = useManagerCreateEditRequest(props);
    let [name, setName] = useState('Bob');
    let [email, setEmail] = useState('jshamel+test0001@gmail.com');

    const { createInvitation, data: createInvitationData, loading: createInvitationLoading, error: createInvitationError } = useManagerCreateInvitation();

    const handleNameChange = (e) => {
        e.preventDefault();

        setName(e.target.value);
    }

    const handleEmailChange = (e) => {
        e.preventDefault();

        setEmail(e.target.value);
    }

    let [selectedTab, setSelectedTab] = useState(0);


    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    return (

        <StandardUIDialog
            open={props.open}
            onClose={props.onClose}
            book={props.book}
            title='Send Edit Request'
            dialogContent={
                    <Box>
                        <StandardUITabs value={selectedTab} onChange={handleChange}>
                            <Tab label="Connections" {...a11yProps(0)} />
                            <Tab label="Invite" {...a11yProps(1)} />
                        </StandardUITabs>
                        {selectedTab == 0 &&
                        <div>
                            <ListConnections
                                selectedUserConnection={selectedUserConnection}
                                filteredConnections={[]}
                                showRequestButton
                                onSelectUser={(userConnection) => {

                                    if (userConnection) {
                                        createEditRequest({
                                            book,
                                            userId: user.id,
                                            editorUserId: userConnection.connectedUser.id
                                        })
                                    }

                                }}

                            />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {createEditRequestLoading && <CircularProgress />}
                            </div>
                        </div>
                        }
                        {selectedTab == 1 &&
                        <div>
                            <InviteConnection />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {createInvitationLoading && <CircularProgress />}
                            </div>
                        </div>
                        }
                    </Box>
            }
        />

    )
}

export default inject('stores')(observer(SendEditRequest));