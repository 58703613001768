import { Box, Divider, Grid, Stack, styled, Typography } from "@mui/material";
import { Paper } from "@mui/material";
import StandardDashboardPaper from "../StandardPaper";
import { StandardPaper } from "../StandardPaper";
import { useTheme } from '@mui/material/styles';

export default function FormatPropertiesCard(props) {

    let { title, count } = props;
    const theme = useTheme();

    return (

        <StandardDashboardPaper sx={{
            position: 'relative',
            //height: '15em',
            borderRadius: '8px',
            marginTop: '2em',
            padding:'.5em 1em 1em 1em'
        }}>
            {/* <StandardPaper variant='elevation' elevation={1} sx={{
                width: '35%',
                height: '3em',
                position: 'absolute',
                top: '-1em',
                right: '1em',
                backgroundColor: props.color,
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            }}>
               <Typography variant={'h6'} color={theme.palette.secondary.main}>{props.title}</Typography> 
            </StandardPaper> */}
            <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                //marginLeft: '1em',
                marginRight: '1em',
                columns: '100'
            }}>
                <Box sx={{
                    flex: 70
                }}>
                    <Typography variant={'h6'} sx={{ color: theme.palette.secondary.main }}> {props.title} </Typography>
                </Box>
            </Stack>

            {props.children}
            <Stack sx={{
                display:'flex',
                flexDirection:'column',
                height:'100%',
                marginLeft:'1em',
                marginRight:'1em',
                columns:'100'
            }}>
                <Box sx={{
                    flex:70
                }}>
                    
                </Box>
                <Divider sx={{

                }} />
                <Box sx={{
                    flex:30
                }}></Box>
            </Stack>
        </StandardDashboardPaper>
    );
}
