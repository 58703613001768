import React, { useState, useEffect, useContext } from 'react';
import { inject, observer } from 'mobx-react';
import DocumentPartEditor from './DocumentPartEditor';
import PreviewQuillEditor from './PreviewQuillEditor';
import FullpageImageEditor from './FullpageImageEditor';
import { v4 as uuid } from 'uuid';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Toolbar from './toolbar/Toolbar';
import { EditingContextProvider } from './EditingContext';
import { useListBookDraftUserComments } from '../../persistance/persistanceHooks';
import { Grid, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StandardSkeleton from '../../StandardSkeleton';
import EditingContext from './EditingContext';
import { useGetMappedImages } from '../../managers/StorageManager';
//import { showAllComments, useLoadBookComments } from '../../managers/BooksManager';
import EditorToolTip from './EditorToolTip';

var $ = require("jquery");
const { ResourceExtractor } = require('../../helpers/RequiredResources');

const BookDraftEditor = (props) => {

    const theme = useTheme();
    const editingContext = useContext(EditingContext);
    // let { loadBookComments,
    //     loading: listBookDraftUserCommentsLoading,
    //     error: listBookDraftUserCommentsError,
    //     data: listBookDraftUserCommentsData,
    //     refetch: listBookDraftUserCommentsRefetch } = useLoadBookComments(props);

    let [formatting, setFormatting] = useState({});
    let [selectedQuill, setSelectedQuill] = useState(null);
    let [userCommentId, setUserCommentId] = useState(null);


    let [selectedEditorId, setSelectedEditorId] = useState(null);
    let [selectedDocumentPart, setSelectedDocumentPart] = useState(null);
    let [documentPartEditors, setDocumentPartEditors] = useState([]);
    const [mappedImages, setMappedImages] = useState({});
    const [tooltipVisible, setTooltipVisible] = React.useState(false);
    // let [userComments,setUserComments] = useState([]);

    let { bookDraft, readOnly = false, editMode, disabled } = props;

    let userComments = [];




    const scrollToTop = () => {
        try {

            let node = $(".editorScrollTop")[0];
            node.scrollIntoView({
                behavior: "smooth", // or "auto" or "instant"
                block: "end" // or "start" --> dont know why, but using "start" caused a horizontal scrollbar to appear ( which cause the toolbar to be clipped at the top )
            });
        } catch (err) {
            console.log(err);
        }
    };


    const scrollToComment = ({
        blotId
    }) => {
        try {

            let node = $("div").find("[data-uuid='" + blotId + "']")[0];
            node.scrollIntoView({
                behavior: "smooth", // or "auto" or "instant"
                block: "end" // or "start" --> dont know why, but using "start" caused a horizontal scrollbar to appear ( which cause the toolbar to be clipped at the top )
            });
        } catch (err) {
            console.log(err);
        }
    };


    // useEffect(() => {

    //     if (bookDraft) {

    //         loadBookComments({
    //             ownerMode: false,
    //             bookDraftId: bookDraft?.id
    //         });
    //     }

    // }, [
    //     props.stores.bookStore.showCommentsInEditor,
    //     //bookDraft?.id,
    //     bookDraft,
    //     props.stores.bookStore.userCommentsTimestamp
    // ]);

    useEffect(() => {
        //let calculatedRecipe = props.selectedBddp?.documentPart?.getRecipe()?.json;
        let calculatedRecipe = bookDraft?.book?.recipe.json;
        let mergedBooks = props.stores.bookStore.authoredBooks

        if (bookDraft && calculatedRecipe) {


            new ResourceExtractor().extractBookDraftRequiredResources(
                bookDraft.book,
                calculatedRecipe,
                bookDraft.bookDraftDocumentParts.map((bddp) => { return bddp.documentPart }),
                props.stores.bookStore.currentInflatedTemplates,
                mergedBooks, (mappedImages) => {

                    //console.log(mappedImages);
                    setMappedImages(mappedImages);

                    try {


                        bookDraft.mappedImages = mappedImages;
                        bookDraft.book.mappedImages = mappedImages;


                    } catch (err) {
                        //console.log(err);
                    }

                });

            // let currentInflatedTemplates = props.stores.bookStore.currentInflatedTemplates;
            // new ResourceExtractor().getAllResources({
            //     recipeJson: bookDraft?.book.recipe.json,//calculatedRecipe,
            //     inflatedTemplates: currentInflatedTemplates,
            //     //allBooks:stores.bookStore.authoredBooks,
            //     allBooks: mergedBooks,
            //     bookDraft: bookDraft
            // }, (mappedImages) => {

            //     console.log(mappedImages);
            //     setMappedImages(mappedImages);

            //     bookDraft.mappedImages = mappedImages;
            //     bookDraft.book.mappedImages = mappedImages;
            // });




        }


    }, [
        //bookDraft,
        // props.selectedBddp,
        // props.stores.bookStore.authoredBooks,
        // props.selectedBddp?.documentPart.bookDraftDocumentPart.bookDraft,
        //imagesTimestamp
    ]);



    // const { loading: listBookDraftUserCommentsLoading, error: listBookDraftUserCommentsError, data: listBookDraftUserCommentsData } = useListBookDraftUserComments({
    //     bookDraftId: bookDraft?.id
    // });


    // useEffect(()=>{

    //     //console.log(listBookDraftUserCommentsData);
    //     if(listBookDraftUserCommentsData){

    //         setUserComments(listBookDraftUserCommentsData.listBookDraftUserComments.items);

    //     }
    // },[listBookDraftUserCommentsData]);

    let contextValues = {
        formatting,
        setFormatting,

        selectedQuill,
        setSelectedQuill,

        selectedDocumentPart,
        setSelectedDocumentPart,


        selectedEditorId,
        setSelectedEditorId,

        tooltipVisible,
        setTooltipVisible,

        userCommentId,
        setUserCommentId
    }


    const addEditors = (parentBddp, editors) => {

        if (parentBddp.documentPart.partType == 'FULLPAGEIMAGE') {
            editors.push(<FullpageImageEditor
                editMode={editMode}
                userComments={userComments}
                key={'documentPartEditor:' + parentBddp.documentPart.id}
                readOnly={readOnly}
                disabled={disabled}
                bookDraftDocumentPart={parentBddp}
                onChange={() => {
                    //alert('changed');
                }}
            />);
        } else {
            editors.push(<DocumentPartEditor
                stores={props.stores}
                editMode={editMode}
                userComments={userComments}
                key={'documentPartEditor:' + parentBddp.documentPart.id}
                readOnly={readOnly}
                disabled={disabled}
                bookDraftDocumentPart={parentBddp}
                onDocumentPartCreated={(bddp) => {
                    //console.log(bddp);
                    if (props.onDocumentPartCreated) {

                        props.onDocumentPartCreated(bddp);

                    }
                }} />);
        }


        try {
            ////console.log(parentBddp);
            let children = parentBddp.childBookDraftDocumentParts;
            // children.forEach((bddp)=>{
            //     //documentPartEditors.push(<DocumentPartEditor bookDraftDocumentPart={props.selectedBddp}/>);
            //     if(bddp.documentPart.partType!='CHAPTER' && bddp.documentPart.partType!='SCENE'){
            //         addEditors(bddp, editors);
            //     }

            // })
        } catch (err) {
            //console.log(err);
        }


    }

    useEffect(() => {
        //console.log('rendered...')
        try {



            //setDocumentPartEditors([]);
            let newEditors = [];
            if (props.selectedBddp) {

                addEditors(props.selectedBddp, newEditors);

            }


            setDocumentPartEditors(newEditors);




            if (props.stores.bookStore.scrollToUserCommentId) {
                scrollToComment({
                    blotId: props.stores.bookStore.scrollToUserCommentId
                })
            }


        } catch (err) {
            //console.log(err);
        }


        try {


            props.selectedBddp.documentPart.bookDraftDocumentPart.bookDraft.mappedImages = mappedImages;
            props.selectedBddp.documentPart.bookDraftDocumentPart.bookDraft.book.mappedImages = mappedImages;


        } catch (err) {
            //console.log(err);
        }



    }, [
        props.selectedBddp,
        //bookDraft,
        //bookDraft?.bookDraftDocumentParts.length,
        //mappedImages
    ])

    // let documentPartEditorsx = [];
    // if(props.selectedBddp){
    //     //documentPartEditors.push(<DocumentPartEditor bookDraftDocumentPart={props.selectedBddp}/>);

    //     addEditors(props.selectedBddp,documentPartEditorsx);

    // }

    if (!bookDraft) {

        return <StandardSkeleton />
    }
    return (
        <div style={{
            borderx: '1px solid red',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            overFlow: 'hidden'
        }} onClick={()=>{
            editingContext.setTooltipVisible(false);
        }}>


            {/* <div>userComments:{props.stores.bookStore.userComments && props.stores.bookStore.userComments.length}</div> */}
            <EditingContextProvider value={contextValues}>





                {/*selectedDocumentPart && 
                <div>{selectedDocumentPart.title}</div>
            */}
                {/* <div>{'userComments'+userComments.length}</div> */}

                {!readOnly &&
                    <Toolbar />
                }

                {readOnly &&
                    <Toolbar disabled={true} />
                }



                <div style={{

                    //marginTop: readOnly ? '0.0em' : '0.5em',
                    paddingTop: '0.5em'
                }}>

                </div>
                <Grid container direction={'row'} sx={{
                    justifyContent: 'space-between',
                    width: '100%'
                }}>

                    <Grid item sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>



                    </Grid>
                    <Grid item sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>

                        <Typography variant="h7">{props.subToolbarTitle}</Typography>

                    </Grid>
                    <Grid item sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>

                        {props.showCloseIcon &&
                            <Typography variant="h7" onClick={() => {
                                if (props.onClose) {
                                    props.onClose();
                                }
                            }} >close</Typography>
                        }

                    </Grid>


                    {/* <Grid item sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>

                        <Typography variant="h5">bookDraft version:{bookDraft?.version}</Typography>

                    </Grid> */}
                </Grid>

                {/* <div>{props.selectedBddp && props.selectedBddp.bookDraft.bookDraftDocumentParts.length}</div> */}
                <div style={{
                    flex: 1,
                    heightx: "calc(100% - 3em)",
                    //backgroundColor:'transparent'
                }}>
                    <Scrollbars
                        renderView={props => <div {...props} className="bookDraftEditorTopScrollDiv" />}
                        onScroll={(event) => {
                            // console.log(event);
                            // console.log($(event.target).scrollTop());
                            //setScrollTop
                        }}
                        autoHide
                        renderThumbHorizontal={() => {
                            return <span />
                        }}

                        style={{
                            width: '100%',
                            height: '100%',
                            overFlow: 'hidden',
                            // marginTop: readOnly ? '0.0em' : '0.5em', 
                            // paddingTop: '0.5em' 
                        }}>

                        <div id='editorScrollTop' style={{
                            overflow: 'hidden',
                            //marginTop: readOnly ? '0.0em' : '0.5em'
                        }}>
                            <PreviewQuillEditor
                                key={'quillEditor:' + uuid()}

                            />
                            {(!props.selectedBddp && props.missingDocumentPartRenderer) &&
                                props.missingDocumentPartRenderer()
                            }
                            {tooltipVisible &&
                                <EditorToolTip
                                    quill={selectedQuill}
                                    readOnly={readOnly}
                                    visible={true}
                                    userCommentId={userCommentId}
                                    //editorId={editorId}
                                    documentPart={selectedDocumentPart}
                                    //selectedText={selectedText}
                                    selection={selectedQuill?.getSelection()}
                                    widthElementClassName={'documentPartEditorWrapper'}
                                />
                            }
                            {documentPartEditors}
                        </div>
                    </Scrollbars>
                </div>
            </EditingContextProvider>
        </div>
    );
};


export default inject('stores')(observer(BookDraftEditor));
