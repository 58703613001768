import React, { useState, useEffect, useContext } from 'react';
import { inject, observer } from 'mobx-react';
import ToolbarItem from './ToolbarItem';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import SubscriptIcon from '@mui/icons-material/Subscript';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import LinkIcon from '@mui/icons-material/Link';
import ImageIcon from '@mui/icons-material/Image';
//import FormatFontIcon from '@mui/icons-material/FormatFontIcon';
import FormatFont from 'mdi-material-ui/FormatFont';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ToolbarPopup from './ToolbarPopup';
import { Box, Paper, Typography } from '@mui/material';
import EditingContext from '../EditingContext';
import { v4 as uuid } from 'uuid';
import Quill from 'quill';



import StandardDialog from '../../StandardDialog';
//import BookSelectorDialog from '../../dialogs/BookSelectorDialog';
import NotesIcon from '@mui/icons-material/Notes';
import LinkPopper from './LinkPopper';
import CharacterPopper from './CharacterPopper';
import CommentPopper from './CommentPopper';
import ImagePopper from './ImagePopper';
import EditPopper from './EditPopper';
import PublisherPopper from './PublisherPopper';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import PasswordIcon from '@mui/icons-material/Password';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useTheme } from '@mui/material/styles';
import AddAlsoByBookPanel from '../../panels/AddAlsoByBookPanel';
import StandardOkCancelDialog from '../../panels/StandardOkCancelDialog';

const Tooltip = Quill.import('ui/tooltip');

let ListBlot = Quill.import('formats/list');

const Parchment = Quill.import('parchment');
const $ = require("jquery");

const displayBig = {

    xs: 'none',
    sm: 'none',
    md: 'flex',
    lg: 'flex',
    xl: 'flex',
    tablet: 'flex',
    laptop: 'flex',
    desktop: 'flex',

}

const displaySmall = {

    xs: 'flex',
    sm: 'flex',
    md: 'none',
    lg: 'none',
    xl: 'none',
    tablet: 'none',
    laptop: 'none',
    desktop: 'none',

}

const Toolbar = (props) => {

    const theme = useTheme();

    const [selectedAlignment, setSelectedAlignment] = React.useState(0);
    const [alsobyDialogOpen, setAlsobyDialogOpen] = React.useState(false);
    const [alreadySelectedBookIds, setAlreadySelectedBookIds] = React.useState([]);

    const [linkPopperOpen, setLinkPopperOpen] = useState(false);
    const [linkPopperAnchorEl, setLinkPopperAnchorEl] = useState();
    const [linkPopperBlot, setLinkPopperBlot] = useState();

    const [imagePopperOpen, setImagePopperOpen] = useState(false);
    const [imagePopperAnchorEl, setImagePopperAnchorEl] = useState();
    const [imagePopperBlot, setImagePopperBlot] = useState();

    const [editPopperOpen, setEditPopperOpen] = useState(false);
    const [editPopperAnchorEl, setEditPopperAnchorEl] = useState();
    const [editPopperBlot, setEditPopperBlot] = useState();

    const [publisherPopperOpen, setPublisherPopperOpen] = useState(false);
    const [publisherPopperAnchorEl, setPublisherPopperAnchorEl] = useState();
    const [publisherPopperBlot, setPublisherPopperBlot] = useState();

    const [characterPopperOpen, setCharacterPopperOpen] = useState(false);
    const [characterPopperAnchorEl, setCharacterPopperAnchorEl] = useState();
    const [characterPopperBlot, setCharacterPopperBlot] = useState();

    const [commentPopperOpen, setCommentPopperOpen] = useState(false);
    const [commentPopperAnchorEl, setCommentPopperAnchorEl] = useState();
    const [commentPopperBlot, setCommentPopperBlot] = useState();


    let { disabled } = props;

    const editingContext = useContext(EditingContext);
    const selected = false;
    const fontSize = 'small';


    
    useEffect(() => {
        editingContext.setFormatting({});
    }, [props.stores.bookStore.writingSelectedBookDraftDocumentPart])

    const selectAlignment = (index) => {
        //console.log(index); 
        if(disabled) return; 
        setSelectedAlignment(index);
    }


    // const getBlotAtCursor = () =>{
    // 	let quill = props.stores.bookStore.activeSceneQuill;
    // 	let range = quill.getSelection();
    // 	////console.log(range);
    // 	if (!range) {
    // 		return;
    // 	}

    // 	let elementMouseIsOver = document.elementFromPoint(global_mouse_x, global_mouse_y);

    // 	////console.log(elementMouseIsOver);

    // 	try {
    // 		let currentBlot = Quill.find(elementMouseIsOver);
    // 		////console.log(currentBlot);

    // 		let parentBlot = currentBlot.parent;
    // 		////console.log(parentBlot)

    // 		let grandParentBlot = parentBlot.parent;
    // 		////console.log(grandParentBlot);
    // 	} catch (e) {

    // 	}

    // }


    const clearFormat = () => {
        if(disabled) return; 
        let quill = props.stores.bookStore.activeSceneQuill;
        let range = quill.getSelection();

        if (!range) {
            return;
        }

        quill.removeFormat(range);
        editingContext.setFormatting({});
        return;

    }

    const toggleFormat = (type, subType) => {

        if(disabled) return; 
        //console.log(type);
        //console.log(subType);
        let quill = props.stores.bookStore.activeSceneQuill;
        let dirtySelectionFormatting = props.stores.bookStore.currentSelectionFormatting;


        let selectionFormatting = {};

        if (dirtySelectionFormatting) {

            Object.keys(dirtySelectionFormatting).forEach((key) => {
                if (key != 'editingInsertBlot') {
                    selectionFormatting[key] = dirtySelectionFormatting[key];
                }
            });
        }



        if (!quill) {
            return;
        }
        var range = quill.getSelection();
        if (!range) {

            range = quill.selection.savedRange;
            //return;
        }

        let format = quill.getFormat(range)

        let currentBlot = props.stores.bookStore.currentBlot;
        ////console.log(currentBlot);
        //let listDomNode = currentBlot.domNode;

        let [listBlot, offset] = quill.scroll.descendant(ListBlot, range.index);
        ////console.log(listBlot)

        if (type == 'script') {

            if (format[type] && format[type] == subType) {
                quill.format(type, false)
            } else {
                quill.format(type, subType);
            }

        } else if (type == 'list') {

            //let blot = Parchment.find(event.target);
            if (listBlot instanceof ListBlot) {
                let index = listBlot.offset(quill.scroll);
                let length = listBlot.length(quill.scroll);
                quill.setSelection(index, length);

                ////console.log(index);
            }
            quill.format('align', false);
            //return;
            if (format[type] && format[type] == subType) {
                quill.format(type, false)
            } else {
                quill.format(type, subType);
            }

        } else if (type == 'link2') {




            let linkData = {
                external:{
                    url:''
                },
                internal:{
                    partId:''
                },
                stores:{
                    kindle:{
                        url:'https://www.barnesandnoble.com/w/no-such-thing-as-werewolves-chris-fox/1121661617?ean=9781502918277',
                        active:false
                    },
                    google:{
                        url:'https://play.google.com/store/books/details/Erik_Seedhouse_SpaceX?id=5VVDAAAAQBAJ',
                        active:false
                    },
                    kobo:{
                        url:'https://www.kobo.com/us/en/ebook/unknown-realms',
                        active:false
                    },
                    nook:{
                        url:'https://www.barnesandnoble.com/w/no-such-thing-as-werewolves-chris-fox/1121661617?ean=9781502918277',
                        active:false
                    },
                    apple:{
                        url:'https://books.apple.com/book/id1111222222222',
                        active:false
                    },
                    generic:{
                        url:'https://spacex.com',
                        active:false
                    }
                }
            };
    
            // selectedQuill.insertEmbed(range.index + 0, 'blurbs', {
            //     uuid: blotId,
            //     blurbs: JSON.stringify(blurbs),
            //     stores: props.stores,
            //     userStore: props.userStore,
            //     documentPart: selectedDocumentPart
            // }, Quill.sources.USER);





            if (format.link2) {
                quill.format(type, false)
            } else {
                quill.format(type, { href: 'https://www.bookmerlin.com', linkType: 'web', linkData: JSON.stringify(linkData)})
            }

        } else if (type == 'editingDeleteBlot') {
            if (format.editingDeleteBlot) {
                quill.format(type, false)
            } else {
                quill.format(type, {
                    editorComment: 'This is the toolbar supplied editor comment',
                    uuid: uuid()
                })
            }

        }
        else if (type == 'editingInsertBlot') {
            if (format.editingInsertBlot) {
                quill.format(type, false)
            } else {
                quill.format(type, {
                    editorComment: 'This is the toolbar supplied editor comment',
                    uuid: uuid()
                })
            }

        }


        else if (type == 'footnote') {
            let documentPart = null;
            if (props.stores.bookStore.activeSceneQuill) {

                let bookDraftDocumentPart = props.stores.bookStore.currentSceneDocumentPart;

                if (bookDraftDocumentPart) {
                    documentPart = bookDraftDocumentPart.documentPart;
                }
                if (!documentPart) {

                    documentPart = props.stores.bookStore.currentBookDraft.mostRecentWritingDocumentPart;
                }

            }
            if (format.footnote) {
                quill.format(type, false)
            } else {

                //quill.format(type,{footnote:'Set from toolbar', id:uuid()})
                quill.insertText(range.index, '1x', { footnote: 'The footnote text', id: uuid(), documentPartId: documentPart.id });
            }

        } else if (type == 'align') {
            //let currentBlot = props.stores.bookStore.currentBlot;
            //////console.log(currentBlot);
            //let listDomNode = currentBlot.domNode;


            //let blot = Parchment.find(event.target);
            if (listBlot instanceof ListBlot) {
                let index = listBlot.offset(quill.scroll);
                let length = listBlot.length(quill.scroll);
                quill.setSelection(index, length);
                ////console.log(index);
            }

            if (subType && subType == 'left') {
                quill.format(type, false);
            } else {
                if (format[type] && format[type] == subType) {
                    quill.format(type, false)
                } else {
                    quill.format(type, subType);
                }
            }


        }
        else if (type == 'font') {

            if (format[type] && format[type] == subType) {
                quill.format(type, false)
            } else {
                quill.format(type, subType);
            }



        }
        else if (type == 'color') {

            if (format[type] && format[type] == subType) {
                quill.format(type, false)
            } else {
                quill.format(type, subType);
            }



        }
        else if (type == 'alignTest') {

            if (format[type] && format[type] == subType) {
                quill.format(type, false)
            } else {
                quill.format(type, subType);
            }



        }
        else if (type == 'background') {

            if (format[type] && format[type] == subType) {
                quill.format(type, false)
            } else {
                quill.format(type, subType);
            }



        }
        else if (type == 'size') {

            if (format[type] && format[type] == subType) {
                quill.format(type, false)
            } else {
                quill.format(type, subType);
            }



        }
        else if (type == 'fontColor') {

            if (format[type] && format[type] == subType) {
                quill.format(type, false)
            } else {
                quill.format(type, subType);
            }



        }
        else if (type == 'bgcolor') {

            if (format[type] && format[type] == subType) {
                quill.format(type, false)
            } else {
                quill.format(type, subType);
            }



        }
        else if (type == 'fontSizeBM') {

            if (format[type] && format[type] == subType) {
                quill.format(type, false)
            } else {
                quill.format(type, subType);
            }



        }
        else if (type == 'fontVariant') {

            if (format[type] && format[type] == subType) {
                quill.format(type, false)
            } else {
                quill.format(type, subType);
            }



        }
        else if (type == 'fontFamily') {

            if ((format[type] && format[type] == subType) || !subType) {
                quill.format(type, false)
            } else {
                quill.format(type, subType);
            }



        }
        else {
            //getBlotAtCursor();
            quill.format(type, !format[type]);
        }


        props.stores.bookStore.currentSelectionFormatting = quill.getFormat(range);
        props.stores.bookStore.toolbarCurrentSelectionFormatting = quill.getFormat(range);

        editingContext.setFormatting(props.stores.bookStore.currentSelectionFormatting);

        props.stores.bookStore.recipeTimestamp = new Date().getTime();
    }

    const updateAlsoBySelectedBooks = (alsobys) => {
        if(disabled) return; 
        let alsoBySelectedBookIds = [];

        alsobys.records.forEach((alsoby) => {
            //console.log(alsoby.item.bookId)
            alsoBySelectedBookIds.push(alsoby.item.bookId)
        })
        setAlreadySelectedBookIds(alsoBySelectedBookIds)

    }




    const handleInsertImage = () => {
        if(disabled) return; 
        const { selectedQuill, selectedDocumentPart } = editingContext;

        const range = selectedQuill.getSelection();




        let imageArgs = {
            //src: null,
            type: 'jpeg',
            alt: 'Image',
            size: 'large',
            caption: 'caption',
            alignment: 'center',
            stores: props.stores,
            includeCaption: 'true',
            userStore: props.stores.userStore,
            documentPart: props.stores.bookStore.writingSelectedBookDraftDocumentPart.documentPart
            //documentPart: documentPart
        }

        selectedQuill.insertEmbed(range.index, 'image4', imageArgs, Quill.sources.USER);

        
        // selectedQuill.insertEmbed(range.index + 0, 'alsobys', {
        //     uuid: blotId,
        //     alsobys: JSON.stringify(alsobys),
        //     stores: props.stores,
        //     userStore: props.stores.userStore,
        //     documentPart: selectedDocumentPart
        // }, Quill.sources.USER);

        //selectedQuill.insertText(range.index, '\n', Quill.sources.SILENT);
        selectedQuill.setSelection(range.index + 2, Quill.sources.SILENT);

    }


    const handleInsertFullpageImage = () => {
        if(disabled) return; 
        const { selectedQuill, selectedDocumentPart } = editingContext;

        const range = selectedQuill.getSelection();




        let imageArgs = {
            src: 'https://m.media-amazon.com/images/I/71cWEHLM9TL._AC_SL1500_.jpg',
            type: 'jpeg',
            alt: 'Image',
            stores: props.stores,
            userStore: props.stores.userStore,
            documentPart: props.stores.bookStore.writingSelectedBookDraftDocumentPart.documentPart
            //documentPart: documentPart
        }

        selectedQuill.insertEmbed(range.index, 'fullPageImage', imageArgs, Quill.sources.USER);



    }

    const handleInsertAlsobys = () => {
        if(disabled) return; 
        const { selectedQuill, selectedDocumentPart } = editingContext;

        let blotId = uuid();

        const range = selectedQuill.getSelection();


        let alsobys = {
            records: [
                // {

                //     item: {
                //         orderNumber: 1,
                //         id: uuid(),
                //         bookId: 'book1',
                //         title: "",
                //         isStoreLink: 'false',
                //         subtitle: "",
                //         coverUrl: "",
                //         coverUrlType: "",
                //         kindleIdentifier: "1",
                //         appleIdentifier: "2",
                //         nookIdentifier: "3",
                //         koboIdentifier: "4",
                //         googleIdentifier: "5",
                //         customIdentifier: "6",
                //         include: 'true'
                //     }

                // }
            ]
        };


        selectedQuill.insertEmbed(range.index + 0, 'alsobys', {
            uuid: blotId,
            alsobys: JSON.stringify(alsobys),
            stores: props.stores,
            userStore: props.stores.userStore,
            documentPart: selectedDocumentPart
        }, Quill.sources.USER);

        selectedQuill.setSelection(range.index + 1, Quill.sources.SILENT);

    }

    const handleInsertDecoratedBreak = () => {
        if(disabled) return; 
        //console.log('handleInsertDecoratedBreak....')
        const { selectedQuill, selectedDocumentPart } = editingContext;

        const range = selectedQuill.getSelection();

        let args = {};
        selectedQuill.insertEmbed(range.index, 'decoratedBreak', args, Quill.sources.USER);
        //selectedQuill.insertText(range.index, '\n', Quill.sources.USER); //NO! causes endless newlines
        selectedQuill.setSelection(range.index + 2, Quill.sources.SILENT);


    }


    const handleInsertPublisher = () => {

        if(disabled) return; 
        //console.log('handleInsertPublisher....')
        const { selectedQuill, selectedDocumentPart } = editingContext;

        let blotId = uuid();

        const range = selectedQuill.getSelection();

        let publisher = {
            name: '',
            url: '',
            includePublisherUrl: true,
            includePublisherLogoLink: true,

        }



        selectedQuill.insertEmbed(range.index + 0, 'publisher', {
            uuid: blotId,
            publisher: JSON.stringify(publisher),
            stores: props.stores,
            userStore: props.userStore,
            documentPart: selectedDocumentPart
        }, Quill.sources.USER);

        selectedQuill.setSelection(range.index + 1, Quill.sources.SILENT);

    }

    const handleInsertContributors = () => {

        if(disabled) return; 
        //console.log('handleInsertContributors....')
        const { selectedQuill, selectedDocumentPart } = editingContext;

        let blotId = uuid();

        const range = selectedQuill.getSelection();

        let contributors = {
            records: [{
                item:
                {
                    id: uuid(),
                    orderNumber: 1,
                    name: 'Contributor Name',
                    role: 'Contributor Role'
                }
            }]
        };

        selectedQuill.insertEmbed(range.index + 0, 'contributors', {
            uuid: blotId,
            contributors: JSON.stringify(contributors),
            stores: props.stores,
            userStore: props.userStore,
            documentPart: selectedDocumentPart
        }, Quill.sources.USER);

        selectedQuill.setSelection(range.index + 1, Quill.sources.SILENT);

    }

    const handleInsertTOC = () => {
        if(disabled) return; 
        const { selectedQuill, selectedDocumentPart } = editingContext;

        const range = selectedQuill.getSelection();


        let blotId = uuid();

        selectedQuill.insertEmbed(range.index + 0, 'tocs', {
            uuid: blotId,
            includeVolumes: 'true',
            includeParts: 'true',
            includeChapters: 'true',
            includeFrontMatter: 'true',
            includeBackMatter: 'true',
            stores: props.stores,
            userStore: props.userStore,
            documentPart: selectedDocumentPart
        }, Quill.sources.USER);

        selectedQuill.setSelection(range.index + 1, Quill.sources.SILENT);
    }

    const handleInsertBlurbs = () => {

        if(disabled) return; 
        const { selectedQuill, selectedDocumentPart } = editingContext;

        let blotId = uuid();

        const range = selectedQuill.getSelection();

        let blurbs = {
            records: [{
                item: {
                    id: uuid(),
                    orderNumber: 1,
                    comment: 'This is a great book!',
                    source: 'Fan Reader'
                }
            }]
        };

        selectedQuill.insertEmbed(range.index + 0, 'blurbs', {
            uuid: blotId,
            blurbs: JSON.stringify(blurbs),
            stores: props.stores,
            userStore: props.userStore,
            documentPart: selectedDocumentPart
        }, Quill.sources.USER);

        selectedQuill.insertText(range.index + 1, '\n', Quill.sources.USER);

        selectedQuill.setSelection(range.index + 2, Quill.sources.SILENT);




    }

    const boldIcon = <ToolbarItem icon={<FormatBoldIcon fontSize={fontSize} color={editingContext.formatting.bold ? 'secondary' : 'disabled'} onClick={() => { if(disabled) return; toggleFormat('bold') }} />} />;

    const underlineIcon = <ToolbarItem icon={<FormatUnderlinedIcon fontSize={fontSize} color={editingContext.formatting.underline ? 'secondary' : 'disabled'} onClick={() => {if(disabled) return;  toggleFormat('underline') }} />} />
    const italicIcon = <ToolbarItem icon={<FormatItalicIcon fontSize={fontSize} color={editingContext.formatting.italic ? 'secondary' : 'disabled'} onClick={() => {if(disabled) return;  toggleFormat('italic') }} />} />
    const strikeIcon = <ToolbarItem icon={<FormatStrikethroughIcon fontSize={fontSize} color={editingContext.formatting.strike ? 'secondary' : 'disabled'} onClick={() => {if(disabled) return;  toggleFormat('strike') }} />} />
    const subIcon = <ToolbarItem icon={<SubscriptIcon fontSize={fontSize} color={(editingContext.formatting.script && editingContext.formatting.script == 'sub') ? 'secondary' : 'disabled'} onClick={() => {if(disabled) return;  toggleFormat('script', 'sub') }} />} />
    const superIcon = <ToolbarItem icon={<SuperscriptIcon fontSize={fontSize} color={(editingContext.formatting.script && editingContext.formatting.script == 'super') ? 'secondary' : 'disabled'} onClick={() => {if(disabled) return;  toggleFormat('script', 'super') }} />} />
    const alignmentIcon = <ToolbarItem icon={
        <ToolbarPopup
            onClick={(index) => {
                if(disabled) return; 
            }}
            //selectedIndex={selectedAlignment}
            style={{
                'height': '100%'
            }}

            button={<div>
                {!editingContext.formatting.align &&
                    <FormatAlignLeftIcon fontSize={fontSize} color={'secondary'} />
                }
                {(editingContext.formatting.align && editingContext.formatting.align == 'center') &&
                    <FormatAlignCenterIcon fontSize={fontSize} color={'secondary'} />
                }
                {(editingContext.formatting.align && editingContext.formatting.align == 'right') &&
                    <FormatAlignRightIcon fontSize={fontSize} color={'secondary'} />
                }
                {(editingContext.formatting.align && editingContext.formatting.align == 'justify') &&
                    <FormatAlignJustifyIcon fontSize={fontSize} color={'secondary'} />
                }
            </div>
            }
            icons={[
                <FormatAlignLeftIcon fontSize={fontSize} color={(!editingContext.formatting.align) ? 'secondary' : 'disabled'} onClick={(e) => { e.preventDefault(); if(disabled) return; toggleFormat('align', 'left') }} />,
                <FormatAlignCenterIcon fontSize={fontSize} color={(editingContext.formatting.align && editingContext.formatting.align == 'center') ? 'secondary' : 'disabled'} onClick={(e) => { e.preventDefault(); if(disabled) return; toggleFormat('align', 'center') }} />,
                <FormatAlignRightIcon fontSize={fontSize} color={(editingContext.formatting.align && editingContext.formatting.align == 'right') ? 'secondary' : 'disabled'} onClick={(e) => { e.preventDefault(); if(disabled) return; toggleFormat('align', 'right') }} />,
                <FormatAlignJustifyIcon fontSize={fontSize} color={(editingContext.formatting.align && editingContext.formatting.align == 'justify') ? 'secondary' : 'disabled'} onClick={(e) => { e.preventDefault();if(disabled) return;  toggleFormat('align', 'justify') }} />
            ]} />}
        onClick={(e) => {
            e.preventDefault();
            if(disabled) return; 
        }}
    />

    const fontSizeIcon = <ToolbarItem icon={
        <ToolbarPopup
            onClick={(index) => {
                if(disabled) return; 
            }}

            style={{
                'height': '100%'
            }}

            button={<FormatSizeIcon fontSize={fontSize} color={selected ? 'secondary' : 'disabled'} />}
            icons={[
                <Typography variant="button" display="block" color={(editingContext.formatting.fontSizeBM && editingContext.formatting.fontSizeBM == '0.75em') ? 'secondary' : 'disabled'} onClick={() => { if(disabled) return; toggleFormat('fontSizeBM', '0.75em') }}>SM</Typography>,
                <Typography variant="button" display="block" color={(!editingContext.formatting.fontSizeBM) ? 'secondary' : 'disabled'} onClick={() => {  if(disabled) return; toggleFormat('fontSizeBM', null) }}>NM</Typography>,
                <Typography variant="button" display="block" color={(editingContext.formatting.fontSizeBM && editingContext.formatting.fontSizeBM == '1.5em') ? 'secondary' : 'disabled'} onClick={() => {  if(disabled) return; toggleFormat('fontSizeBM', '1.5em') }}>LG</Typography>,
                <Typography variant="button" display="block" color={(editingContext.formatting.fontSizeBM && editingContext.formatting.fontSizeBM == '2em') ? 'secondary' : 'disabled'} onClick={() => {  if(disabled) return; toggleFormat('fontSizeBM', '2em') }}>XL</Typography>
            ]} />}
    />

    const fontTypeIcon = <ToolbarItem icon={
        <ToolbarPopup
            onClick={(index) => {
                if(disabled) return; 
            }}

            style={{
                'height': '100%'
            }}

            button={<FormatFont fontSize={fontSize} color={selected ? 'secondary' : 'disabled'} />}
            icons={[

                <Typography variant="subtitle2" display="block"
                    color={(!editingContext.formatting.fontFamily) ? 'secondary' : 'disabled'}
                    //onClick={this.toggleFormat.bind(this, 'fontFamily', 'sans-serif')}
                    onClick={() => {  if(disabled) return; toggleFormat('fontFamily', false) }}
                >sans serif</Typography>,

                <Typography variant="subtitle2" display="block"
                    color={(editingContext.formatting.fontFamily && editingContext.formatting.fontFamily == 'serif') ? 'secondary' : 'disabled'}
                    onClick={() => {  if(disabled) return; toggleFormat('fontFamily', 'serif') }}
                >serif</Typography>,

                <Typography variant="subtitle2" display="block"
                    color={(editingContext.formatting.fontFamily && editingContext.formatting.fontFamily == 'monospace') ? 'secondary' : 'disabled'}
                    //onClick={this.toggleFormat.bind(this, 'fontFamily', 'monospace')}
                    onClick={() => {  if(disabled) return; toggleFormat('fontFamily', 'monospace') }}
                >monospace</Typography>,

                <hr />,

                <Typography variant="subtitle2" display="block"
                    color={(!editingContext.formatting.fontVariant) ? 'secondary' : 'disabled'}
                    //onClick={this.toggleFormat.bind(this, 'fontVariant', null)}
                    onClick={() => {  if(disabled) return; toggleFormat('fontVariant', null) }}
                >Normal</Typography>,


                <Typography variant="subtitle2" display="block"
                    color={(editingContext.formatting.fontVariant && editingContext.formatting.fontVariant == 'small-caps') ? 'secondary' : 'disabled'}
                    //onClick={this.toggleFormat.bind(this, 'fontVariant', 'small-caps')}
                    onClick={() => {  if(disabled) return; toggleFormat('fontVariant', 'small-caps') }}
                >small-caps</Typography>


            ]} />}
    />


    const fontStyleIcon = <ToolbarItem icon={
        <ToolbarPopup
            onClick={(index) => {
                if(disabled) return; 
            }}

            style={{
                'height': '100%'
            }}

            button={<NotesIcon fontSize={fontSize} color={selected ? 'secondary' : 'disabled'} />}
            icons={[

                <Typography variant="subtitle2" display="block"
                    color={(editingContext.formatting.blockquote) ? 'secondary' : 'disabled'}
                    //onClick={this.toggleFormat.bind(this, 'fontFamily', 'sans-serif')}
                    onClick={() => { if(disabled) return;  toggleFormat('blockquote') }}
                >Blockquote</Typography>,

                <Typography variant="subtitle2" display="block"
                    color={(editingContext.formatting.attribution) ? 'secondary' : 'disabled'}
                    onClick={() => {  if(disabled) return; toggleFormat('attribution') }}
                >Attribution</Typography>,

                <Typography variant="subtitle2" display="block"
                    color={(editingContext.formatting.verse) ? 'secondary' : 'disabled'}
                    //onClick={this.toggleFormat.bind(this, 'fontFamily', 'monospace')}
                    onClick={() => {  if(disabled) return; toggleFormat('verse') }}
                >Verse</Typography>,



                <Typography variant="subtitle2" display="block"
                    color={(editingContext.formatting.subhead) ? 'secondary' : 'disabled'}
                    //onClick={this.toggleFormat.bind(this, 'fontVariant', null)}
                    onClick={() => { if(disabled) return;  toggleFormat('subhead') }}
                >Subhead</Typography>,





            ]} />}
    />

    const linkIcon = <ToolbarItem icon={<LinkIcon fontSize={fontSize} color={editingContext.formatting.link2 ? 'secondary' : 'disabled'} onClick={() => { if(disabled) return;  toggleFormat('link2') }} />} />
    const imageIcon = <ToolbarItem icon={<ImageIcon fontSize={fontSize} color={'disabled'} onClick={() => {  if(disabled) return; handleInsertImage() }} />} />



    const blotsIcon = <ToolbarItem icon={
        <ToolbarPopup
            onClick={(index) => {
                if(disabled) return; 
            }}

            style={{
                'height': '100%'
            }}

            button={<WidgetsIcon fontSize={fontSize} color={selected ? 'secondary' : 'disabled'} />}
            icons={[
                <span onClick={handleInsertBlurbs} style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'secondary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>+ Blurbs</Typography></span>,
                <span onClick={handleInsertTOC} style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'secondary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>+ TOC</Typography></span>,

                // <span style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'secondary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>Add Notes</Typography></span>,

                // <span style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'secondary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>Add Decorated Break</Typography></span>,
                <span onClick={handleInsertContributors} style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'secondary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>Add Contributors</Typography></span>,
                <span onClick={handleInsertPublisher} style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'secondary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>Add Publisher</Typography></span>,
                <span onClick={handleInsertAlsobys} style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'secondary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>Add Alsobys</Typography></span>,
                // <span onClick={handleInsertFullpageImage} style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'secondary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>Add Fullpage Image</Typography></span>

            ]} />}
    />


    const clearIcon = <ToolbarItem icon={<FormatClearIcon fontSize={fontSize} color={'disabled'} onClick={() => {  if(disabled) return; clearFormat() }} />} />
    const decoratedBreakIcon = <ToolbarItem icon={<PasswordIcon fontSize={fontSize} color={'disabled'} onClick={handleInsertDecoratedBreak} />} />

    const moreIcon = <ToolbarItem icon={<MoreVertIcon fontSize={fontSize} color={'disabled'} onClick={handleInsertDecoratedBreak} />} />
    const miscIcon = <ToolbarItem icon={<FullscreenIcon fontSize={fontSize} color={props.stores.bookStore.distractionFree ? 'secondary' : 'disabled'} onClick={() => {
        if(disabled) return; 
        props.stores.bookStore.distractionFree = !props.stores.bookStore.distractionFree;
    }} />} />




    const smallMoreIcon = <ToolbarItem icon={
        <ToolbarPopup
            onClick={(index) => {
                if(disabled) return; 
            }}

            style={{
                'height': '100%'
            }}

            button={<MoreVertIcon fontSize={fontSize} color={selected ? 'secondary' : 'disabled'} />}
            icons={[
                fontSizeIcon,
                fontTypeIcon,
                fontStyleIcon,
                linkIcon,
                imageIcon,
                blotsIcon,
                clearIcon,
                decoratedBreakIcon
            ]} />}
    />


    return (

        <Paper sx={{
            display: 'flex',
            backgroundColor: theme.palette.write?.background?.color,

        }} >


            {/* <BookSelectorDialog
                open={alsobyDialogOpen}
                excludedBookIds={alreadySelectedBookIds}
                onClose={() => {

                    setAlsobyDialogOpen(false);

                }}
                onBookSelected={(book) => {

                    //console.log(book);

                    let blotDivId = $("#master_quillImageProcessor").attr("blotDivId");
                    //console.log(blotDivId);

                    let node = $('#' + blotDivId)[0];
                    let blot = Parchment.find(node);
                    //console.log(blot);
                    //console.log(node.dataset.uuid);
                    //console.log(node.dataset.alsobys);


                    const { selectedQuill, selectedDocumentPart } = editingContext;
                    let newContributor = blot.addContributor({ bookId: book.id });



                    let alsobys = JSON.parse(node.dataset.alsobys);

                    let newArgs = {
                        stores: props.stores,
                        userStore: props.stores.userStore,
                        documentPart: selectedDocumentPart,
                        uuid: node.dataset.uuid,
                        alsobys: JSON.stringify(alsobys)
                    }



                    blot.replaceWith('alsobys', newArgs);

                    alsobys = JSON.parse(node.dataset.alsobys);

                    updateAlsoBySelectedBooks(alsobys);





                }} /> */}

            {/* <StandardDialog
                title={"Create new book selector dialog here"}
                open={alsobyDialogOpen}
                onClose={() => { setAlsobyDialogOpen(false) }}
                dialogContent={

                    <span>"Create new book selector dialog here"</span>

                }>

            </StandardDialog> */}

            <StandardOkCancelDialog
                open={alsobyDialogOpen}
                onClose={() => { setAlsobyDialogOpen(false) }}
                onOk={() => {
                    setAlsobyDialogOpen(false)

                }}
                title={'Add Also By'}
                content={
                    <AddAlsoByBookPanel
                    
                        // onShareCreated={() => {
                        //     setShowShareBookDialog(false)
                        // }}
                    />


                }
            />





            <div focusable='false' tabIndex={-1} id='master-toolbar' style={{
                'width': '100%'
            }}>

                <Box focusable='false' tabIndex={-1} className='toolbar-wrapper' sx={{
                    display: displaySmall,
                    justifyContent: 'space-around',
                    'flex-direction': 'row',
                    'flex-wrap': 'wrap',
                    'alignItems': 'center',
                    width: '100%',
                    border: '1px solid gray',
                    //padding: '0em 0em .5em 0em'
                }}>
                    {boldIcon}
                    {underlineIcon}
                    {italicIcon}
                    {strikeIcon}
                    {subIcon}
                    {superIcon}
                    {alignmentIcon}
                    {/* {fontSizeIcon}
                    {fontTypeIcon}
                    {fontStyleIcon} */}
                    {/* {linkIcon}
                    {imageIcon} */}
                    {/* {blotsIcon} */}
                    {/* {clearIcon}
                    {decoratedBreakIcon} */}
                    {smallMoreIcon}


                </Box>

                <Box focusable='false' tabIndex={-1} className='toolbar-wrapper' sx={{
                    display: displayBig,
                    justifyContent: 'space-around',
                    'flex-direction': 'row',
                    'flex-wrap': 'wrap',
                    'alignItems': 'center',
                    width: '100%',
                    border: '1px solid gray',
                    padding: '0em 0em 0em 0em'
                }}>

                    {boldIcon}
                    {underlineIcon}
                    {italicIcon}
                    {strikeIcon}
                    {subIcon}
                    {superIcon}
                    {alignmentIcon}
                    {fontSizeIcon}
                    {fontTypeIcon}
                    {fontStyleIcon}
                    {linkIcon}
                    {imageIcon}
                    {blotsIcon}
                    {clearIcon}
                    {decoratedBreakIcon}
                    {moreIcon}
                    {miscIcon}



                </Box>
            </div>
            <input style={{ 'display': 'none' }} id={'master_quillImageProcessor'} onClick={() => {
                let blotImageDivId = $("#master_quillImageProcessor").attr("blotImageDivId");
                this.updateImageBlot(blotImageDivId);

            }} />

            <input style={{ 'display': 'none' }} id={'master_quillBookSelector'} onClick={(e) => {

                e.stopPropagation();
                e.preventDefault();



                let blotDivId = $("#master_quillImageProcessor").attr("blotDivId");

                let node = $('#' + blotDivId)[0];
                let blot = Parchment.find(node);
                //console.log(blot);
                //console.log(node.dataset.uuid);
                //console.log(node.dataset.alsobys);

                let alsobys = JSON.parse(node.dataset.alsobys);

                updateAlsoBySelectedBooks(alsobys);

                setAlsobyDialogOpen(true);
                /*let allBooks = props.stores.bookStore.authoredBooks;

                let blotDivId = $("#master_quillImageProcessor").attr("blotDivId");

                let node = $('#' + blotDivId)[0];

                ////console.log(node.dataset.uuid);
                //console.log(node.dataset.alsobys);

                let json = JSON.parse(node.dataset.alsobys);

                let bookIds = json.records.filter(f => f.item.bookId != null).map((m) => m.item.bookId);
                //console.log(bookIds);

                let availableBooks = [];

                allBooks.forEach((book) => {


                    let inList = bookIds.filter(id => id == book.id);

                    if (inList.length == 0) {
                        availableBooks.push(book);
                    }

                })

                //console.log(availableBooks);
                this.setState({
                    showSelectBookDialogue: true,
                    availableBooks: availableBooks
                });*/
            }} />

            <div id='commentPopperTriggerDiv' onClick={(e) => {
                e.preventDefault();
                //console.log('popper click');
                let quill = props.stores.bookStore.activeSceneQuill;
                let linkEl = $('#commentPopperTriggerDiv').data("blotNode");
                
                setCommentPopperAnchorEl(linkEl);
                //setCommentPopperOpen(true);
                let blot = Parchment.find(linkEl);
                //setCommentPopperBlot(blot);

                editingContext.setTooltipVisible(true);
                //editingContext.setTooltipCommentBlot(blot);
                editingContext.setUserCommentId(blot?.domNode?.dataset?.uuid);
                

            }} />
            <div id='characterPopperTriggerDiv' onClick={(e) => {
                e.preventDefault();
                //console.log('popper click');
                let quill = props.stores.bookStore.activeSceneQuill;
                let linkEl = $('#characterPopperTriggerDiv').data("blotNode");
                setCharacterPopperAnchorEl(linkEl);
                setCharacterPopperOpen(true);
                let blot = Parchment.find(linkEl);
                //console.log(blot);
                setCharacterPopperBlot(blot);


            }} />
            <div id='linkPopperTriggerDiv' onClick={(e) => {
                e.preventDefault();
                //console.log('popper click');
                let quill = props.stores.bookStore.activeSceneQuill;
                let linkEl = $('#linkPopperTriggerDiv').data("blotNode");
                setLinkPopperAnchorEl(linkEl);
                setLinkPopperOpen(true);
                let blot = Parchment.find(linkEl);
                //console.log(blot);
                setLinkPopperBlot(blot);


            }} />
            <div id='imagePopperTriggerDiv' onClick={(e) => {
                e.preventDefault();
                //console.log('popper click');
                let quill = props.stores.bookStore.activeSceneQuill;
                let linkEl = $('#imagePopperTriggerDiv').data("blotNode");
                setImagePopperAnchorEl(linkEl);
                setImagePopperOpen(true);
                let blot = Parchment.find(linkEl);
                //console.log(blot);
                setImagePopperBlot(blot);


            }} />
            <div id='editPopperTriggerDiv'

                onMouseEnter={(e) => {

                    //console.log('onMouseEnter');

                }}

                onClick={(e) => {
                    e.preventDefault();
                    //console.log('popper click');
                    let quill = props.stores.bookStore.activeSceneQuill;
                    let linkEl = $('#editPopperTriggerDiv').data("blotNode");
                    setEditPopperAnchorEl(linkEl);
                    setEditPopperOpen(true);
                    let blot = Parchment.find(linkEl);
                    //console.log(blot);
                    setEditPopperBlot(blot);


                }} />
            <div id='publisherPopperTriggerDiv' onClick={(e) => {
                e.preventDefault();
                //console.log('popper click');
                let quill = props.stores.bookStore.activeSceneQuill;
                let linkEl = $('#publisherPopperTriggerDiv').data("blotNode");
                setPublisherPopperAnchorEl(linkEl);
                setPublisherPopperOpen(true);
                let blot = Parchment.find(linkEl);
                //console.log(blot);
                setPublisherPopperBlot(blot);


            }} />


            {/* <Button onClick={(e) => {
                setLinkPopperAnchorEl(e.currentTarget);
            }}>Popper</Button> */}
            {commentPopperOpen &&
                <CommentPopper
                    documentPart={props.stores.bookStore.writingSelectedBookDraftDocumentPart?.documentPart}
                    open={commentPopperOpen}
                    onClose={() => {
                        setCommentPopperOpen(false);
                        setCommentPopperAnchorEl(null);
                        //setLinkPopperOpen(false);

                    }}
                    anchorEl={commentPopperAnchorEl}
                    characterBlot={commentPopperBlot}
                />
            }
            {characterPopperOpen &&
                <CharacterPopper
                    documentPart={props.stores.bookStore.writingSelectedBookDraftDocumentPart?.documentPart}
                    open={characterPopperOpen}
                    onClose={() => {
                        setCharacterPopperOpen(false);
                        setCharacterPopperAnchorEl(null);
                        //setLinkPopperOpen(false);

                    }}
                    anchorEl={characterPopperAnchorEl}
                    characterBlot={characterPopperBlot}
                />
            }
            {linkPopperOpen &&
                <LinkPopper
                    documentPart={props.stores.bookStore.writingSelectedBookDraftDocumentPart?.documentPart}
                    open={linkPopperOpen}
                    onClose={() => {
                        setLinkPopperOpen(false);
                        setLinkPopperAnchorEl(null);
                        //setLinkPopperOpen(false);

                    }}
                    anchorEl={linkPopperAnchorEl}
                    linkBlot={linkPopperBlot}
                />
            }
            {imagePopperOpen &&
                <ImagePopper
                    documentPart={props.stores.bookStore.writingSelectedBookDraftDocumentPart?.documentPart}
                    open={imagePopperOpen}
                    onClose={() => {
                        setImagePopperOpen(false);
                        setImagePopperAnchorEl(null);
                        //setLinkPopperOpen(false);

                    }}
                    anchorEl={imagePopperAnchorEl}
                    imageBlot={imagePopperBlot}
                />
            }
            {publisherPopperOpen &&
                <PublisherPopper
                    documentPart={props.stores.bookStore.writingSelectedBookDraftDocumentPart?.documentPart}
                    open={publisherPopperOpen}
                    onClose={() => {
                        setPublisherPopperOpen(false);
                        setPublisherPopperAnchorEl(null);
                        //setLinkPopperOpen(false);

                    }}
                    anchorEl={publisherPopperAnchorEl}
                    publisherBlot={publisherPopperBlot}
                />
            }
            {editPopperOpen &&
                <EditPopper
                    documentPart={props.stores.bookStore.writingSelectedBookDraftDocumentPart?.documentPart}
                    open={editPopperOpen}
                    onClose={() => {
                        setEditPopperOpen(false);
                        setEditPopperAnchorEl(null);
                        //setLinkPopperOpen(false);

                    }}
                    anchorEl={editPopperAnchorEl}
                    editBlot={editPopperBlot}
                />
            }


            {/* <Popper
                id={'linkPopper'}
                open={linkPopperOpen}
                onMouseLeave={()=>{
                    setLinkPopperAnchorEl(null);
                    //setLinkPopperOpen(false);

                }}
                onClose={()=>{
                    setLinkPopperAnchorEl(null);
                    //setLinkPopperOpen(false);

                }}
                anchorEl={linkPopperAnchorEl}
                transition
                placement="bottom-start"
            >
                {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                        <div style={{
                            display:'flex',
                            flexDirection:'column'
                        }}>
                            <div style={{ display:'flex'}}>key:value</div>
                            <div style={{ display:'flex'}}>key:value</div>
                        </div>
                    </Paper>
                </Fade>
                )}
            </Popper> */}

        </Paper>
    );
};


export default inject('stores')(observer(Toolbar));
