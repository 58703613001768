import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Box from '@mui/material/Box';
import Image from 'mui-image'
import { gql, useLazyQuery } from '@apollo/client';
import screenshot from '../../assets/images/screenshot1.png';
import { BookDraft, Book, Recipe } from '../../domain/bookModel';
import { Button } from '@mui/material';
import { useUpdateItem, useDeleteBookMerlinRecipe } from '../../persistance/persistanceHooks';
// import AutoUpdateField from './propertyElements/AutoUpdateField';
import Delete from '@mui/icons-material/Delete';
import StandardEntityImageSelector from '../StandardEntityImageSelector';
import ApplyRecipeDialog from './ApplyRecipeDialog';
// import ApplyRecipeDialog from '../dialogs/ApplyRecipeDialog';

const BookMerlinStyle = (props) => {

  const [updateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] = useUpdateItem();
  const [deleteRecipe, { data: deleteRecipeData, loading: deleteRecipeLoading, error: deleteRecipeError }] = useDeleteBookMerlinRecipe();

  const [showSetDialog, setShowSetDialog] = useState(false);

  let getRecipe_QUERY = gql`query getRecipe($recipeId: ID!) {
		getRecipe(id: $recipeId) {
		  updatedAt
		  type
		  title
		  ownerId
		  json
		  id
		  description
		  createdAt
		  deletedAt
		  version
		}
	  }`;

  const [lazyGetRecipe, { loading: lazyGetRecipeLoading, error: lazyGetRecipeError, data: lazyGetRecipeData }] = useLazyQuery(getRecipe_QUERY, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    //console.log('loaded it');

    if (lazyGetRecipeData && lazyGetRecipeData.getRecipe) {

      //Do not set this new recipe to the book!
      //Set the json from this recipe to the book's recipe. 
      //Will need to also set the bookStore version of this recipe. 
      //REFACTOR the duality problem from the book's recipe and the bookStore's recipe. 

      let recipe = new Recipe({ data: lazyGetRecipeData.getRecipe })
      recipe.mergeMaster(props.stores.bookStore.masterRecipe);

      //props.stores.bookStore.writingBookDraft.book.recipe.json = recipe.json;
      props.currentRecipe.json = recipe.json;
      props.stores.bookStore.recipeTimestamp = new Date().getTime();

      if (props.onUpdateRecipe) {
        props.onUpdateRecipe({
          //recipe: props.stores.bookStore.writingBookDraft.book.recipe
          recipe: props.currentRecipe
        })
      }

    }




  }, [lazyGetRecipeData, lazyGetRecipeLoading]);



  const item = {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  }

  const updateEntity = ({
    item,
    fieldName
  }) => {

    //console.log('updating the entity...');
    //console.log(item);
    //console.log(fieldName);
    let fieldValue = item[fieldName];
    if (fieldValue instanceof Object) {
      fieldValue = JSON.stringify(fieldValue)
    }
    updateItem({
      itemExpectedVersion: item.version,
      itemKey: item.id,
      itemType: 'Recipe',
      fieldName: fieldName,
      fieldValue: fieldValue
    })

  }

  return (

    <div style={{


    }}>

      {props.documentPart &&
        <ApplyRecipeDialog
          open={showSetDialog}
          onClose={() => {
            setShowSetDialog(false);
          }}
          documentPart={props.documentPart}
          title={'Apply Style'}
          deleteMessage={'Confirm you want to unshare this book below.'}
          confirmationText={'unshare it'}
          useValidation={false}
          forceConfirmation={false}
          onOK={() => {
            props.lazyGetRecipe({
              variables: {
                recipeId: props.recipe.id
              },

            })
          }}
          content={''}
        >

        </ApplyRecipeDialog>
      }
      <div style={{
        //display:displayBig,
        //flexDirection:'column',,
        border: '.5em solid black',
        borderRadius: '1em'

      }}>
        <StandardEntityImageSelector
          showInformation={false}
          title={'Cover Image'}
          entity={props.recipe}
          imageKeyFieldName={'previewUrl'}
          width={'100%'} />
      </div>


      {/* <div style={{
        display: 'flex',
        padding: '0em 2em 0em 2em',
        alignItems: 'center'
      }}>


        <AutoUpdateField
          //item={this.props.stores.bookStore.currentBookDraft.book}
          item={props.recipe}
          fieldName={'title'}
          label={'Title'}
          helperText='Title'
          onChange={() => {

            updateEntity({
              item: props.recipe,
              fieldName: 'title'
            })

          }}


          defaultValue=''
          disabled={false} />

        <Button onClick={() => {

          console.log('set...');
          setShowSetDialog(true);
          // props.lazyGetRecipe({
          //   variables: {
          //     recipeId: props.recipe.id
          //   },
           
          // })
        }}>Set</Button>
        <Delete color={'primary'} aria-label="Comments" onClick={(e) => {
          e.stopPropagation();
          deleteRecipe({
            id: props.recipe.id,
            expectedVersion: 1
          });

        }} />
      </div> */}

      <Button onClick={() => {

        console.log('set...');
        setShowSetDialog(true);
        // props.lazyGetRecipe({
        //   variables: {
        //     recipeId: props.recipe.id
        //   },

        // })
      }}>Set</Button>
      <Delete color={'primary'} aria-label="Comments" onClick={(e) => {
        e.stopPropagation();
        deleteRecipe({
          id: props.recipe.id,
          expectedVersion: 1
        });

      }} />

    </div>

  );
};


export default inject('stores')(observer(BookMerlinStyle));